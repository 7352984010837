export const wordsLesson247 = [
  [
    {
      id: 1,
      word: 'activity',
      transcription: 'ækˈtɪvɪti',
      translation: 'деятельность',
    },
    {
      id: 2,
      word: 'authority',
      transcription: 'ɔːˈθɒrɪti',
      translation: 'власть',
    },
    {
      id: 3,
      word: 'broadcast',
      transcription: 'ˈbrɔːdkɑːst',
      translation: 'транслировать',
    },
    {
      id: 4,
      word: 'chewing gum',
      transcription: 'ˈʧuːɪŋ ɡʌm',
      translation: 'жевательная резинка',
    },
    {
      id: 5,
      word: 'curious',
      transcription: 'ˈkjʊərɪəs',
      translation: 'любопытный',
    },
    {
      id: 6,
      word: 'diary',
      transcription: 'ˈdaɪəri',
      translation: 'дневник',
    },
    {
      id: 7,
      word: 'exclude',
      transcription: 'ɪksˈkluːd',
      translation: 'исключать',
    },
    {
      id: 8,
      word: 'grammatical',
      transcription: 'ɡrəˈmætɪkəl',
      translation: 'грамматический',
    },
    {
      id: 9,
      word: 'honey',
      transcription: 'ˈhʌni',
      translation: 'мед',
    },
    {
      id: 10,
      word: 'identify',
      transcription: 'aɪˈdɛntɪfaɪ',
      translation: 'идентифицировать',
    },
    {
      id: 11,
      word: 'imitate',
      transcription: 'ˈɪmɪteɪt',
      translation: 'имитировать',
    },
    {
      id: 12,
      word: 'inappropriate',
      transcription: 'ˌɪnəˈprəʊprɪɪt',
      translation: 'несоответствующий',
    },
    {
      id: 13,
      word: 'mislead',
      transcription: 'mɪsˈliːd',
      translation: 'ввести в заблуждение',
    },
    {
      id: 14,
      word: 'modify',
      transcription: 'ˈmɒdɪfaɪ',
      translation: 'модифицировать',
    },
    {
      id: 15,
      word: 'motherland',
      transcription: 'ˈmʌðəlænd',
      translation: 'родина',
    },
    {
      id: 16,
      word: 'Muslim',
      transcription: 'ˈmʊslɪm',
      translation: 'мусульманин',
    },
    {
      id: 17,
      word: 'oven',
      transcription: 'ˈʌvn',
      translation: 'духовка',
    },
    {
      id: 18,
      word: 'pan',
      transcription: 'pæn',
      translation: 'кастрюля, сковорода',
    },
    { id: 19, word: 'per', transcription: 'pɜː', translation: 'в', },
    {
      id: 20,
      word: 'pour',
      transcription: 'pɔː',
      translation: 'наливать',
    },
    {
      id: 21,
      word: 'prize',
      transcription: 'praɪz',
      translation: 'приз',
    },
    {
      id: 22,
      word: 'propose',
      transcription: 'prəˈpəʊz',
      translation: 'предлагать',
    },
    {
      id: 23,
      word: 'reader',
      transcription: 'ˈriːdə',
      translation: 'читатель',
    },
    {
      id: 24,
      word: 'splitting',
      transcription: 'ˈsplɪtɪŋ',
      translation: 'раскалывающий',
    },
    {
      id: 25,
      word: 'swear',
      transcription: 'sweə',
      translation: 'клясться, ругаться',
    },
    {
      id: 26,
      word: 'twentieth',
      transcription: 'ˈtwɛntɪəθ',
      translation: 'двадцатый',
    },
    {
      id: 27,
      word: 'wherever',
      transcription: 'weərˈɛvə',
      translation: 'где бы ни',
    },
    {
      id: 28,
      word: 'yawn',
      transcription: 'jɔːn',
      translation: 'зевать',
    },
    {
      id: 29,
      word: 'water',
      transcription: 'ˈwɔːtə',
      translation: 'поливать',
    },
  ],
];
