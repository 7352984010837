export const contentLesson43 = [
  [
    {
      title: "Урок 43. Past Simple: was / were. Special Question",
    },
    [
      {
        id: 1,
        expression: "Кто был твоим учителем?",
        transcription: "huː wəz jə ˈtiːʧə?",
        translation: "Who was your teacher?",
      },
      {
        id: 2,
        expression: "Где ты был вчера?",
        transcription: "weə wə jʊ ˈjɛstədeɪ?",
        translation: "Where were you yesterday?",
      },
      {
        id: 3,
        expression: "Кто был ее учителем?",
        transcription: "huː wəz hə ˈtiːʧə?",
        translation: "Who was her teacher?",
      },
      {
        id: 4,
        expression: "Почему это было так необычно?",
        transcription: "waɪ wəz ɪt səʊ ʌnˈjuːʒʊəl?",
        translation: "Why was it so unusual?",
      },
      {
        id: 5,
        expression: "Как он был?",
        transcription: "haʊ wəz hiː?",
        translation: "How was he?",
      },
      {
        id: 6,
        expression: "Кто был твоим тренером?",
        transcription: "huː wəz jə ˈtreɪnə?",
        translation: "Who was your trainer?",
      },
      {
        id: 7,
        expression: "Где он был прошлой ночью?",
        transcription: "weə wəz hi lɑːst naɪt?",
        translation: "Where was he last night?",
      },
      {
        id: 8,
        expression: "Почему он был там?",
        transcription: "waɪ wəz hi ðeə?",
        translation: "Why was he there?",
      },
      {
        id: 9,
        expression: "Где это было?",
        transcription: "weə wəz ɪt?",
        translation: "Where was it?",
      },
      {
        id: 10,
        expression: "Кто был прав?",
        transcription: "huː wəz raɪt?",
        translation: "Who was right?",
      },
      {
        id: 11,
        expression: "Почему это было неправильно?",
        transcription: "waɪ wəz ɪt rɒŋ? / waɪ wəz ɪt ˌɪnkəˈrɛkt?",
        translation: "Why was it wrong? / Why was it incorrect?",
      },
      {
        id: 12,
        expression: "Где ты был вчера?",
        transcription: "weə wə jʊ ˈjɛstədeɪ?",
        translation: "Where were you yesterday?",
      },
      {
        id: 13,
        expression: "Почему она была так расстроена?",
        transcription: "waɪ wəz ʃi səʊ ʌpˈsɛt?",
        translation: "Why was she so upset?",
      },
      {
        id: 14,
        expression: "Почему ты был таким уставшим?",
        transcription: "waɪ wə jʊ səʊ ˈtaɪəd?",
        translation: "Why were you so tired?",
      },
      {
        id: 15,
        expression: "Что было лучше?",
        transcription: "wɒt wəz ˈbɛtə?",
        translation: "What was better?",
      },
      {
        id: 16,
        expression: "Когда вы были в Италии в последний раз?",
        transcription: "wɛn wə jʊ ɪn ˈɪtəli lɑːst taɪm?",
        translation: "When were you in Italy last time?",
      },
      {
        id: 17,
        expression: "Почему это было так скучно?",
        transcription: "waɪ wəz ɪt səʊ ˈbɔːrɪŋ?",
        translation: "Why was it so boring?",
      },
      {
        id: 18,
        expression: "Когда ты был за границей в последний раз?",
        transcription: "wɛn wə jʊ əˈbrɔːd lɑːst taɪm?",
        translation: "When were you abroad last time?",
      },
      {
        id: 19,
        expression: "Когда ты был дома?",
        transcription: "wɛn wə jʊ ət həʊm?",
        translation: "When were you at home?",
      },
      {
        id: 20,
        expression: "Сколько это стоило?",
        transcription: "haʊ mʌʧ wəz ɪt? / haʊ mʌʧ dɪd ɪt kɒst?",
        translation: "How much was it? / How much did it cost?",
      },
      {
        id: 21,
        expression: "Почему он был такой грустный?",
        transcription: "waɪ wəz hi səʊ sæd?",
        translation: "Why was he so sad?",
      },
      {
        id: 22,
        expression: "Когда ты был в этом городе в последний раз?",
        transcription: "wɛn wə jʊ ɪn ðɪs ˈsɪti lɑːst taɪm?",
        translation: "When were you in this city last time?",
      },
      {
        id: 23,
        expression: "Где ты был в прошлом году?",
        transcription: "weə wə jʊ lɑːst jɪə?",
        translation: "Where were you last year?",
      },
      {
        id: 24,
        expression: "Почему это было так дешево?",
        transcription: "waɪ wəz ɪt səʊ ʧiːp?",
        translation: "Why was it so cheap?",
      },
      {
        id: 25,
        expression: "Кто был с тобой?",
        transcription: "huː wəz wɪð juː?",
        translation: "Who was with you?",
      },
      {
        id: 26,
        expression: "Они были хорошими студентами?",
        transcription: "wə ðeɪ gʊd ˈstjuːdənts?",
        translation: "Were they good students?",
      },
      {
        id: 27,
        expression: "Почему это было так важно?",
        transcription: "waɪ wəz ɪt səʊ ɪmˈpɔːtənt?",
        translation: "Why was it so important?",
      },
      {
        id: 28,
        expression: "Где он был вчера утром?",
        transcription: "weə wəz hi ˈjɛstədeɪ ˈmɔːnɪŋ?",
        translation: "Where was he yesterday morning?",
      },
      {
        id: 29,
        expression: "Как у нее были дела?",
        transcription: "haʊ wəz ʃiː?",
        translation: "How was she?",
      },
      {
        id: 30,
        expression: "Почему это было неправильно?",
        transcription: "waɪ wəz ɪt ˌɪnkəˈrɛkt? / waɪ wəz ɪt rɒŋ?",
        translation: "Why was it incorrect? / Why was it wrong?",
      },
      {
        id: 31,
        expression: "Кто был не прав?",
        transcription: "huː wəz rɒŋ?",
        translation: "Who was wrong?",
      },
      {
        id: 32,
        expression: "Что было так сложно для тебя?",
        transcription: "wɒt wəz səʊ ˈdɪfɪkəlt fə juː?",
        translation: "What was so difficult for you?",
      },
      {
        id: 33,
        expression: "Как это было?",
        transcription: "haʊ wəz ɪt?",
        translation: "How was it?",
      },
      {
        id: 34,
        expression: "Что было так необычно для тебя?",
        transcription: "wɒt wəz səʊ ʌnˈjuːʒʊəl fə juː?",
        translation: "What was so unusual for you?",
      },
      {
        id: 35,
        expression: "Когда ты был болен?",
        transcription: "wɛn wə jʊ ɪl?",
        translation: "When were you ill?",
      },
      {
        id: 36,
        expression: "Почему это было так дорого?",
        transcription: "waɪ wəz ɪt səʊ ɪksˈpɛnsɪv?",
        translation: "Why was it so expensive?",
      },
      {
        id: 37,
        expression: "Кто был с твоим братом?",
        transcription: "huː wəz wɪð jə ˈbrʌðə?",
        translation: "Who was with your brother?",
      },
      {
        id: 38,
        expression: "Почему ты был так счастлив?",
        transcription: "waɪ wə jʊ səʊ ˈhæpi?",
        translation: "Why were you so happy?",
      },
      {
        id: 39,
        expression: "Когда ты был в России в последний раз?",
        transcription: "wɛn wə jʊ ɪn ˈrʌʃə lɑːst taɪm?",
        translation: "When were you in Russia last time?",
      },
      {
        id: 40,
        expression: "Почему он был так зол?",
        transcription: "waɪ wəz hi səʊ ˈæŋgri?",
        translation: "Why was he so angry?",
      },
      {
        id: 41,
        expression: "Когда ты был там в последний раз?",
        transcription: "wɛn wə jʊ ðeə lɑːst taɪm?",
        translation: "When were you there last time?",
      },
      {
        id: 42,
        expression: "Как долго это было?",
        transcription: "haʊ lɒŋ wəz ɪt?",
        translation: "How long was it?",
      },
      {
        id: 43,
        expression: "Сколько было времени?",
        transcription: "wɒt taɪm wəz ɪt?",
        translation: "What time was it?",
      },
      {
        id: 44,
        expression: "Как далеко это было?",
        transcription: "haʊ fɑː wəz ɪt?",
        translation: "How far was it?",
      },
      {
        id: 45,
        expression: "Какой ответ был правильный?",
        transcription: "wɪʧ ˈɑːnsə wəz kəˈrɛkt (raɪt)?",
        translation: "Which answer was correct (right)?",
      },
      {
        id: 46,
        expression: "Что было в углу?",
        transcription: "wɒt wəz ɪn ðə ˈkɔːnə?",
        translation: "What was in the corner?",
      },
      {
        id: 47,
        expression: "Ты тоже был на вечеринке?",
        transcription: "wə jʊ ət ðə ˈpɑːti, tuː? / wə jʊ ˈɔːlsəʊ ət ðə ˈpɑːti?",
        translation:
          "Were you at the party, too? / Were you also at the party?",
      },
      {
        id: 48,
        expression: "Сколько ей было лет?",
        transcription: "haʊ əʊld wəz ʃiː?",
        translation: "How old was she?",
      },
      {
        id: 49,
        expression: "Когда она была во дворе?",
        transcription: "wɛn wəz ʃi ɪn ðə jɑːd?",
        translation: "When was she in the yard?",
      },
      {
        id: 50,
        expression: "Что было в классе?",
        transcription: "wɒt wəz ɪn ðə ˈklɑːsrʊm?",
        translation: "What was in the classroom?",
      },
      {
        id: 51,
        expression: "Что было на письменном столе?",
        transcription: "wɒt wəz ɒn ðə dɛsk?",
        translation: "What was on the desk?",
      },
      {
        id: 52,
        expression: "Она тоже была в том месте?",
        transcription:
          "wəz ʃi ɪn ðət pleɪs, tuː? / wəz ʃi ˈɔːlsəʊ ɪn ðət pleɪs?",
        translation:
          "Was she in that place, too? / Was she also in that place?",
      },
      {
        id: 53,
        expression: "Что было за коробкой?",
        transcription: "wɒt wəz bɪˈhaɪnd ðə bɒks?",
        translation: "What was behind the box?",
      },
      {
        id: 54,
        expression: "Что было в том углу?",
        transcription: "wɒt wəz ɪt ðət ˈkɔːnə?",
        translation: "What was it that corner?",
      },
      {
        id: 55,
        expression: "Сколько ему было лет?",
        transcription: "haʊ əʊld wəz hiː?",
        translation: "How old was he?",
      },
      {
        id: 56,
        expression: "Кто был в классе?",
        transcription: "huː wəz ɪn ðə ˈklɑːsrʊm?",
        translation: "Who was in the classroom?",
      },
      {
        id: 57,
        expression: "Как часто ты был там?",
        transcription: "haʊ ˈɒf(ə)n wə jʊ ðeə?",
        translation: "How often were you there?",
      },
      {
        id: 58,
        expression: "Он тоже там был?",
        transcription: "wəz hi ðeə, tuː? / wəz hi ˈɔːlsəʊ ðeə?",
        translation: "Was he there, too? / Was he also there?",
      },
      {
        id: 59,
        expression: "Она тоже там была?",
        transcription: "wəz ʃi ðeə, tuː? / wəz ʃi ˈɔːlsəʊ ðeə?",
        translation: "Was she there, too? / Was she also there?",
      },
      {
        id: 60,
        expression: "Он был там вчера?",
        transcription: "wəz hi ðeə ˈjɛstədeɪ?",
        translation: "Was he there yesterday?",
      },
      {
        id: 61,
        expression: "Когда ты был во дворе?",
        transcription: "wɛn wə jʊ ɪn ðə jɑːd?",
        translation: "When were you in the yard?",
      },
      {
        id: 62,
        expression: "Когда ты был в саду?",
        transcription: "wɛn wə jʊ ɪn ðə ˈgɑːdn?",
        translation: "When were you in the garden?",
      },
      {
        id: 63,
        expression: "Что было на столе?",
        transcription: "wɒt wəz ɒn ðə ˈteɪbl?",
        translation: "What was on the table?",
      },
      {
        id: 64,
        expression: "Кто был на столе?",
        transcription: "huː wəz ɒn ðə ˈteɪbl?",
        translation: "Who was on the table?",
      },
      {
        id: 65,
        expression: "Что было на стуле?",
        transcription: "wɒt wəz ɒn ðə ʧeə?",
        translation: "What was on the chair?",
      },
      {
        id: 66,
        expression: "Какая у него была работа?",
        transcription: "wɒt wəz ɪz ʤɒb?",
        translation: "What was his job?",
      },
      {
        id: 67,
        expression: "Какой была ее работа?",
        transcription: "wɒt wəz hə ʤɒb?",
        translation: "What was her job?",
      },
      {
        id: 68,
        expression: "Кто был ее другом?",
        transcription: "huː wəz hə frɛnd?",
        translation: "Who was her friend?",
      },
      {
        id: 69,
        expression: "Чья идея была лучше?",
        transcription: "huːz aɪˈdɪə wəz ˈbɛtə?",
        translation: "Whose idea was better?",
      },
      {
        id: 70,
        expression: "Чья идея лучше?",
        transcription: "huːz aɪˈdɪə ˈbɛtə?",
        translation: "Whose idea better?",
      },
      {
        id: 71,
        expression: "Кто был в парке?",
        transcription: "huː wəz ɪn ðə pɑːk?",
        translation: "Who was in the park?",
      },
      {
        id: 72,
        expression: "Кто был в доме?",
        transcription: "huː wəz ɪn ðə haʊs?",
        translation: "Who was in the house?",
      },
      {
        id: 73,
        expression: "Что было на дереве?",
        transcription: "wɒt wəz ɒn ðə triː?",
        translation: "What was on the tree?",
      },
      {
        id: 74,
        expression: "Во сколько ты был там?",
        transcription: "wɒt taɪm wə jʊ ðeə?",
        translation: "What time were you there?",
      },
      {
        id: 75,
        expression: "Во сколько ты был здесь?",
        transcription: "wɒt taɪm wə jʊ hɪə?",
        translation: "What time were you here?",
      },
      {
        id: 76,
        expression: "Во сколько он был дома?",
        transcription: "wɒt taɪm wəz hi ət həʊm?",
        translation: "What time was he at home?",
      },
      {
        id: 77,
        expression: "Чья книга была интереснее?",
        transcription: "huːz bʊk wəz mɔːr ˈɪntrɪstɪŋ?",
        translation: "Whose book was more interesting?",
      },
      {
        id: 78,
        expression: "Которая книга была там?",
        transcription: "wɪʧ bʊk wəz ðeə?",
        translation: "Which book was there?",
      },
      {
        id: 79,
        expression: "Какой был ее ответ?",
        transcription: "wɒt wəz hər ˈɑːnsə?",
        translation: "What was her answer?",
      },
      {
        id: 80,
        expression: "Какой ответ был правильным?",
        transcription: "wɪʧ ˈɑːnsə wəz kəˈrɛkt (raɪt)?",
        translation: "Which answer was correct (right)?",
      },
      {
        id: 81,
        expression: "Какой вопрос был правильным?",
        transcription: "wɪʧ ˈkwɛsʧən wəz kəˈrɛkt (raɪt)?",
        translation: "Which question was correct (right)?",
      },
      {
        id: 82,
        expression: "Какие ответы были неправильными?",
        transcription: "wɪʧ ˈɑːnsəz wər ˌɪnkəˈrɛkt (rɒŋ)?",
        translation: "Which answers were incorrect (wrong)?",
      },
      {
        id: 83,
        expression: "Сколько тебе было лет?",
        transcription: "haʊ əʊld wə juː?",
        translation: "How old were you?",
      },
      {
        id: 84,
        expression: "Какой билет был дороже?",
        transcription: "wɪʧ ˈtɪkɪt wəz mɔːr ɪksˈpɛnsɪv?",
        translation: "Which ticket was more expensive?",
      },
      {
        id: 85,
        expression: "Кто был во дворе?",
        transcription: "huː wəz ɪn ðə jɑːd?",
        translation: "Who was in the yard?",
      },
      {
        id: 86,
        expression: "Насколько это было трудно для тебя?",
        transcription: "haʊ ˈdɪfɪkəlt wəz ɪt fə juː?",
        translation: "How difficult was it for you?",
      },
      {
        id: 87,
        expression: "Он был на вечеринке?",
        transcription: "wəz hi ət ðə ˈpɑːti?",
        translation: "Was he at the party?",
      },
      {
        id: 88,
        expression: "Что было в коробке?",
        transcription: "wɒt wəz ɪn ðə bɒks?",
        translation: "What was in the box?",
      },
      {
        id: 89,
        expression: "Насколько это было эффективно?",
        transcription: "haʊ ɪˈfɛktɪv wəz ɪt?",
        translation: "How effective was it?",
      },
      {
        id: 90,
        expression: "Кто был за тем деревом?",
        transcription: "huː wəz bɪˈhaɪnd ðət triː?",
        translation: "Who was behind that tree?",
      },
      {
        id: 91,
        expression: "Кто был в лесу?",
        transcription: "huː wəz ɪn ðə ˈfɒrɪst?",
        translation: "Who was in the forest?",
      },
      {
        id: 92,
        expression: "Что было на полу?",
        transcription: "wɒt wəz ɒn ðə flɔː?",
        translation: "What was on the floor?",
      },
      {
        id: 93,
        expression: "Кто был в саду?",
        transcription: "huː wəz ɪn ðə ˈgɑːdn?",
        translation: "Who was in the garden?",
      },
      {
        id: 94,
        expression: "Ты был также с ними?",
        transcription: "wə jʊ ˈɔːlsəʊ wɪð ðɛm?",
        translation: "Were you also with them?",
      },
      {
        id: 95,
        expression: "Что было за той коробкой?",
        transcription: "wɒt wəz bɪˈhaɪnd ðət bɒks?",
        translation: "What was behind that box?",
      },
      {
        id: 96,
        expression: "Кто был за дверью?",
        transcription: "huː wəz bɪˈhaɪnd ðə dɔː?",
        translation: "Who was behind the door?",
      },
      {
        id: 97,
        expression: "Насколько это было интересно?",
        transcription: "haʊ ˈɪntrɪstɪŋ wəz ɪt?",
        translation: "How interesting was it?",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Was / were в специальных вопросах в Past Simple</h3>
<p>Образуется по формуле:</p>
<p><span>вопросительное слово + were / was + подлежащее + ...?</span></p>
<p>When were you married? - Когда ты был(а) женат (замужем)?</p>
<p>Who was your father? - Кем был твой отец?</p>
</div>`,
    },
  ],
];
