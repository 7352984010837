export const contentLesson155 = [
  [
    {
      title: 'Урок 155. Dependent prepositions. Revising',
    },
    [
      {
        id: 1,
        expression: 'Какое твое отношение к этому?',
        transcription: 'wɒts jɔːr ˈætɪtjuːd tuː ɪt?',
        translation: "What's your attitude to it?",
      },
      {
        id: 2,
        expression: 'Я подожду тебя здесь',
        transcription: 'aɪl weɪt fɔː juː hɪə',
        translation: "I'll wait for you here",
      },
      {
        id: 3,
        expression: 'Я так рад за тебя',
        transcription: 'aɪm səʊ glæd fɔː juː',
        translation: "I'm so glad for you",
      },
      {
        id: 4,
        expression: 'Ей не хватает опыта работы',
        transcription: 'ʃiː læks wɜːk ɪksˈpɪərɪəns',
        translation: 'She lacks work experience',
      },
      {
        id: 5,
        expression: 'Какой человек повлиял на тебя больше всего?',
        transcription: 'wɒt ˈpɜːsn ˈɪnflʊənst juː məʊst ɒv ɔːl?',
        translation: 'What person influenced you most of all?',
      },
      {
        id: 6,
        expression: 'У него очень негативное отношение к курению',
        transcription: 'hiː hæz ə ˈvɛri ˈnɛgətɪv ˈætɪtjuːd tuː ˈsməʊkɪŋ',
        translation: 'He has a very negative attitude to smoking',
      },
      {
        id: 7,
        expression: 'У него нехватка опыта',
        transcription: 'hiː hæz ə læk ɒv ɪksˈpɪərɪəns',
        translation: 'He has a lack of experience',
      },
      {
        id: 8,
        expression: 'У них нехватка профессионализма',
        transcription: 'ðeɪ hæv ə læk ɒv prəˈfɛʃnəlɪzm',
        translation: 'They have a lack of professionalism',
      },
      {
        id: 9,
        expression: 'Она извинилась за опоздание',
        transcription: 'ʃiː əˈpɒləʤaɪzd fɔː ˈbiːɪŋ leɪt',
        translation: 'She apologised for being late',
      },
      {
        id: 10,
        expression: 'Она страдает от этой боли',
        transcription: 'ʃiː ˈsʌfəz frɒm ðɪs peɪn',
        translation: 'She suffers from this pain',
      },
      {
        id: 11,
        expression: 'Я сделал это по нескольким причинам',
        transcription: 'aɪ dɪd ɪt fɔː ˈsɛvrəl ˈriːznz',
        translation: 'I did it for several reasons',
      },
      {
        id: 12,
        expression: 'Мои родители не одобряют решение моего брата',
        transcription: 'maɪ ˈpeərənts dəʊnt əˈpruːv ɒv maɪ ˈbrʌðəz dɪˈsɪʒən',
        translation: "My parents don't approve of my brother's decision",
      },
      {
        id: 13,
        expression: 'Как ты вошел в здание?',
        transcription: 'haʊ dɪd juː ˈɛntə ðə ˈbɪldɪŋ?',
        translation: 'How did you enter the building?',
      },
      {
        id: 14,
        expression: 'Ему не хватает уверенности',
        transcription: 'hiː læks ˈkɒnfɪdəns',
        translation: 'He lacks confidence',
      },
      {
        id: 15,
        expression: 'Я разделил свою презентацию на три части',
        transcription: 'aɪ dɪˈvaɪdɪd maɪ ˌprɛzɛnˈteɪʃən ˈɪntuː θriː pɑːts',
        translation: 'I divided my presentation into three parts',
      },
      {
        id: 16,
        expression: 'Какое твое отношение к этому?',
        transcription: 'wɒts jɔːr ˈætɪtjuːd tuː ɪt?',
        translation: "What's your attitude to it?",
      },
      {
        id: 17,
        expression: 'В чем разница между этими двумя вещами?',
        transcription: 'wɒts ðə ˈdɪfrəns bɪˈtwiːn ðiːz tuː θɪŋz?',
        translation: "What's the difference between these two things?",
      },
      {
        id: 18,
        expression: 'У него нехватка уверенности',
        transcription: 'hiː hæz ə læk ɒv ˈkɒnfɪdəns',
        translation: 'He has a lack of confidence',
      },
      {
        id: 19,
        expression: 'Чем она обеспокоена?',
        transcription: 'wɒts ʃiː ˈwʌri əˈbaʊt?',
        translation: "What's she worry about?",
      },
      {
        id: 20,
        expression: 'В супе не хватает соли',
        transcription: 'ðə suːp læks sɒlt',
        translation: 'The soup lacks salt',
      },
      {
        id: 21,
        expression: 'Мы едем домой',
        transcription: 'wɪə ˈgəʊɪŋ həʊm',
        translation: "We're going home",
      },
      {
        id: 22,
        expression: 'Ты подождешь меня?',
        transcription: 'wɪl juː weɪt fɔː miː?',
        translation: 'Will you wait for me?',
      },
      {
        id: 23,
        expression: 'Чем он увлекается?',
        transcription: 'wɒts hiː kiːn ɒn?',
        translation: "What's he keen on?",
      },
      {
        id: 24,
        expression: 'Это лучшее решение этих проблем',
        transcription: 'ɪts ðə bɛst səˈluːʃən tuː ðiːz ˈprɒbləmz',
        translation: "It's the best solution to these problems",
      },
      {
        id: 25,
        expression: 'Ты злишься на него?',
        transcription: 'ɑː juː ˈæŋgri wɪð hɪm?',
        translation: 'Are you angry with him?',
      },
      {
        id: 26,
        expression: 'Им не хватает профессионализма',
        transcription: 'ðeɪ læk prəˈfɛʃnəlɪzm',
        translation: 'They lack professionalism',
      },
      {
        id: 27,
        expression: 'Он извинился за опоздание',
        transcription: 'hiː əˈpɒləʤaɪzd fɔː ˈkʌmɪŋ leɪt',
        translation: 'He apologised for coming late',
      },
      {
        id: 28,
        expression: 'Как ты относишься к курению?',
        transcription: 'wɒts jɔːr ˈætɪtjuːd tuː ˈsməʊkɪŋ?',
        translation: "What's your attitude to smoking?",
      },
      {
        id: 29,
        expression: 'Он отказался сделать это без причины',
        transcription: 'hiː ˌriːˈfjuːzd tuː duː ɪt fɔː nəʊ ˈriːzn',
        translation: 'He refused to do it for no reason',
      },
      {
        id: 30,
        expression: 'Какой ответ на этот вопрос?',
        transcription: 'wɒts ði ˈɑːnsə tuː ðɪs ˈkwɛsʧən?',
        translation: "What's the answer to this question?",
      },
      {
        id: 31,
        expression: 'Это зависит от погоды',
        transcription: 'ɪt dɪˈpɛndz ɒn ðə ˈwɛðə',
        translation: 'It depends on the weather',
      },
      {
        id: 32,
        expression: 'Она страдает от бессонницы',
        transcription: 'ʃiː ˈsʌfəz frɒm ɪnˈsɒmnɪə',
        translation: 'She suffers from insomnia',
      },
      {
        id: 33,
        expression: 'Она думает о вступлении в фитнес-клуб',
        transcription: 'ʃiːz ˈθɪŋkɪŋ əˈbaʊt ˈʤɔɪnɪŋ ə ˈfɪtnɪs klʌb',
        translation: "She's thinking about joining a fitness club",
      },
      {
        id: 34,
        expression: 'В чем причина этого?',
        transcription: 'wɒts ðə ˈriːzn fɔːr ɪt?',
        translation: "What's the reason for it?",
      },
      {
        id: 35,
        expression: 'Это лучший ответ на тот вопрос',
        transcription: 'ɪts ðə bɛst ˈɑːnsə tuː ðæt ˈkwɛsʧən',
        translation: "It's the best answer to that question",
      },
      {
        id: 36,
        expression: 'Успех этого проекта зависит от нас',
        transcription: 'ðə səkˈsɛs ɒv ðɪs ˈprɒʤɛkt dɪˈpɛndz ɒn ʌs',
        translation: 'The success of this project depends on us',
      },
      {
        id: 37,
        expression: 'Он ссылается на это письмо',
        transcription: 'hiː rɪˈfɜːz tuː ðɪs ˈlɛtə',
        translation: 'He refers to this letter',
      },
      {
        id: 38,
        expression: 'Какая была цель твоего визита?',
        transcription: 'wɒt wɒz ðə ˈpɜːpəs ɒv jɔː ˈvɪzɪt?',
        translation: 'What was the purpose of your visit?',
      },
      {
        id: 39,
        expression: 'Он хочет представить ее своей семье',
        transcription: 'hiː wɒnts tuː ˌɪntrəˈdjuːs hɜː tuː hɪz ˈfæmɪli',
        translation: 'He wants to introduce her to his family',
      },
      {
        id: 40,
        expression: 'Ты злишься из-за этой ситуации?',
        transcription: 'ɑː juː ˈæŋgri əˈbaʊt ðɪs ˌsɪtjʊˈeɪʃən?',
        translation: 'Are you angry about this situation?',
      },
      {
        id: 41,
        expression: 'Какая цель его визита?',
        transcription: 'wɒts ðə ˈpɜːpəs ɒv hɪz ˈvɪzɪt?',
        translation: "What's the purpose of his visit?",
      },
      {
        id: 42,
        expression: 'Какие ответы на эти вопросы?',
        transcription: 'wɒt ɑː ði ˈɑːnsəz tuː ðiːz ˈkwɛsʧənz?',
        translation: 'What are the answers to these questions?',
      },
      {
        id: 43,
        expression: 'Какая была причина его отказа?',
        transcription: 'wɒt wɒz ðə ˈriːzn fɔː hɪz rɪˈfjuːzəl?',
        translation: 'What was the reason for his refusal?',
      },
      {
        id: 44,
        expression: 'Из скольких глав состоит эта книга?',
        transcription: 'haʊ ˈmɛni ˈʧæptəz dʌz ðɪs bʊk kənˈsɪst ɒv?',
        translation: 'How many chapters does this book consist of?',
      },
      {
        id: 45,
        expression:
          'Это один из самых эффективных способов изучения иностранных языков',
        transcription:
          'ɪts wʌn ɒv ðə məʊst ɪˈfɛktɪv weɪz ɒv ˈlɜːnɪŋ ˈfɒrɪn ˈlæŋgwɪʤɪz',
        translation:
          "It's one of the most effective ways of learning foreign languages",
      },
      {
        id: 46,
        expression:
          'Я прихожу к выводу, что это один из самых эффективных способов изучения иностранных языков',
        transcription:
          'aɪ kʌm tuː ðə kənˈkluːʒən ðæt ɪts wʌn ɒv ðə məʊst ɪˈfɛktɪv weɪ ɒv ˈlɜːnɪŋ ˈfɔːrɪŋ ˈlæŋgwɪʤɪz',
        translation:
          "I come to the conclusion that it's one of the most effective way of learning foreing languages",
      },
      {
        id: 47,
        expression: 'Я думаю, что это лучшее решение такой сложной проблемы',
        transcription:
          'aɪ θɪŋk ɪts ðə bɛst səˈluːʃən tuː sʌʧ ə ˈdɪfɪkəlt ˈprɒbləm',
        translation:
          "I think it's the best solution to such a difficult problem",
      },
      {
        id: 48,
        expression:
          'У него займет некоторое время, чтобы восстановиться после операции',
        transcription: 'ˈɪtl teɪk hɪm ˈsʌmtaɪm tuː rɪˈkʌvə frɒm ði ˌɒpəˈreɪʃən',
        translation: "It'll take him sometime to recover from the operation",
      },
      {
        id: 49,
        expression: 'Мне кажется, это хорошая альтернатива тому предложению',
        transcription: 'ɪt siːmz tuː miː ɪts ə gʊd ɔːlˈtɜːnətɪv tuː ðæt ˈɒfə',
        translation: "It seems to me it's a good alternative to that offer",
      },
      {
        id: 50,
        expression: 'Спрос на этот продукт очень высок',
        transcription: 'ðə dɪˈmɑːnd fɔː ðɪs ˈprɒdʌkt ɪz ˈvɛri haɪ',
        translation: 'The demand for this product is very high',
      },
      {
        id: 51,
        expression: 'В чем преимущество этого метода?',
        transcription: 'wɒts ði ədˈvɑːntɪʤ ɒv ðɪs ˈmɛθəd',
        translation: "What's the advantage of this method",
      },
      {
        id: 52,
        expression: 'Я думаю, это лучший способ изучения английского',
        transcription: 'aɪ θɪŋk ðə bɛst weɪ ɒv ˈlɜːnɪŋ ˈɪŋglɪʃ',
        translation: 'I think the best way of learning English',
      },
      {
        id: 53,
        expression: 'Из скольких слайдов состоит презентация?',
        transcription: 'haʊ mʌʧ slaɪdz dʌz ðɪs ˌprɛzɛnˈteɪʃən kənˈsɪst ɒv?',
        translation: 'How much slides does this presentation consist of?',
      },
      {
        id: 54,
        expression: 'Какая была ее реакция на эти новости?',
        transcription: 'wɒt wɒz ðə ri(ː)ˈækʃən tuː ðɪs njuːz?',
        translation: 'What was the reaction to this news?',
      },
      {
        id: 55,
        expression: 'Никто еще не ответил на эту жалобу',
        transcription: 'ˈnəʊbədi ˈhæznt rɪsˈpɒndɪd tuː ðɪs kəmˈpleɪnt jɛt',
        translation: "Nobody hasn't responded to this complaint yet",
      },
      {
        id: 56,
        expression: 'Он подал заявку на работу, но он не получил ее',
        transcription: 'hiː əˈplaɪd fɔː ðə ʤɒb bʌt hiː dɪdnt gɛt ɪt',
        translation: "He applied for the job but he didn't get it",
      },
      {
        id: 57,
        expression: 'Какая была твоя реакция на это?',
        transcription: 'wɒt wɒz jɔː ri(ː)ˈækʃən tuː ɪt?',
        translation: 'What was your reaction to it?',
      },
      {
        id: 58,
        expression: 'Это хорошая альтернатива этому методу',
        transcription: 'ɪts ə gʊd ɔːlˈtɜːnətɪv tuː ðɪs ˈmɛθəd?',
        translation: "It's a good alternative to this method?",
      },
      {
        id: 59,
        expression: 'У них недостаток смелости, чтобы сделать это',
        transcription: 'ðeɪ hæv ə læk ɒv ˈkʌrɪʤ tuː duː ɪt',
        translation: 'They have a lack of courage to do it',
      },
      {
        id: 60,
        expression: 'Ей не хватает смелости сделать это',
        transcription: 'ʃiː læks ˈkʌrɪʤ tuː duː ɪt',
        translation: 'She lacks courage to do it',
      },
      {
        id: 61,
        expression: 'Им не хватает профессионализма, чтобы сделать это',
        transcription: 'ðeɪ hæv ə læk ɒv prəˈfɛʃnəlɪzm tuː duː ɪt',
        translation: 'They have a lack of professionalism to do it',
      },
      {
        id: 62,
        expression: 'Я не вижу никаких преимуществ в этом',
        transcription: 'aɪ dəʊnt siː ˈɛni ədˈvɑːntɪʤɪz ɒv ɪt',
        translation: "I don't see any advantages of it",
      },
      {
        id: 63,
        expression: 'Я не вижу никаких преимуществ этого метода',
        transcription: 'aɪ dəʊnt siː ˈɛni ədˈvɑːntɪʤɪz ɒv ðɪs ˈmɛθəd',
        translation: "I don't see any advantages of this method",
      },
      {
        id: 64,
        expression: 'Я вижу так много недостатков того курса',
        transcription: 'aɪ siː səʊ ˈmɛni ˌdɪsədˈvɑːntɪʤɪz ɒv ðæt kɔːs',
        translation: 'I see so many disadvantages of that course',
      },
      {
        id: 65,
        expression: 'Я думаю, это лучшее решение этой проблемы',
        transcription: 'aɪ θɪŋk ɪts ðə bɛst səˈluːʃən tuː ðɪs ˈprɒbləm',
        translation: "I think it's the best solution to this problem",
      },
      {
        id: 66,
        expression: 'Я не думаю, что это хорошее решение этой проблемы',
        transcription: 'aɪ dəʊnt θɪŋk ðæt ɪts ə gʊd səˈluːʃən tuː ðɪs ˈprɒbləm',
        translation: "I don't think that it's a good solution to this problem",
      },
      {
        id: 67,
        expression:
          'Она считает, что это лучшее решение такой сложной проблемы',
        transcription:
          'ʃiː θɪŋks ðæt ɪts ðə bɛst səˈluːʃən tuː sʌʧ ə ˈdɪfɪkəlt ˈprɒbləm',
        translation:
          "She thinks that it's the best solution to such a difficult problem",
      },
      {
        id: 68,
        expression: 'Это правильный ответ на этот вопрос',
        transcription: 'ɪts ðə kəˈrɛkt ˈɑːnsə tuː ðɪs ˈkwɛsʧən',
        translation: "It's the correct answer to this question",
      },
      {
        id: 69,
        expression: 'Какой правильный ответ на этот вопрос?',
        transcription: 'wɪʧ ɪz ðə kəˈrɛkt ˈɑːnsə tuː ðɪs ˈkwɛsʧən?',
        translation: 'Which is the correct answer to this question?',
      },
      {
        id: 70,
        expression: 'Это самый плохой ответ на тот вопрос',
        transcription: 'ɪts ðə wɜːst ˈɑːnsə tuː ðæt ˈkwɛsʧən',
        translation: "It's the worst answer to that question",
      },
      {
        id: 71,
        expression: 'Какая причина ее отказа?',
        transcription: 'wɒts ðə ˈriːzn fɔː hɜː rɪˈfjuːzəl?',
        translation: "What's the reason for her refusal?",
      },
      {
        id: 72,
        expression: 'Какая была причина его смерти?',
        transcription: 'wɒt wɒz ðə kɔːz ɒv hɪz dɛθ?',
        translation: 'What was the cause of his death?',
      },
      {
        id: 73,
        expression: 'Это было причиной этой катастрофы',
        transcription: 'ɪt wɒz ðə kɔːz ɒv ðɪs dɪˈzɑːstə',
        translation: 'It was the cause of this disaster',
      },
      {
        id: 74,
        expression: 'Она все еще восстанавливается после операции',
        transcription: 'ʃiːz stɪl rɪˈkʌvərɪŋ frɒm ði ˌɒpəˈreɪʃən',
        translation: "She's still recovering from the operation",
      },
      {
        id: 75,
        expression: 'Она уже восстановилась после операции',
        transcription: 'ʃiːz ɔːlˈrɛdi rɪˈkʌvəd frɒm ði ˌɒpəˈreɪʃən',
        translation: "She's already recovered from the operation",
      },
      {
        id: 76,
        expression: 'Она все еще восстанавливается после этой аварии',
        transcription: 'ʃiːz stɪl rɪˈkʌvərɪŋ frɒm ðɪs ˈæksɪdənt',
        translation: "She's still recovering from this accident",
      },
      {
        id: 77,
        expression: 'Ответ на этот вопрос правильный?',
        transcription: 'ɪz ði ˈɑːnsə tuː ðɪs ˈkwɛsʧən raɪt?',
        translation: 'Is the answer to this question right?',
      },
      {
        id: 78,
        expression: 'Это правильный ответ на этот вопрос',
        transcription: 'ɪts ðə raɪt ˈɑːnsə tuː ðɪs ˈkwɛsʧən',
        translation: "It's the right answer to this question",
      },
      {
        id: 79,
        expression: 'Ответ на этот вопрос довольно хороший',
        transcription: 'ði ˈɑːnsə tuː ðɪs ˈkwɛsʧən ɪz kwaɪt gʊd',
        translation: 'The answer to this question is quite good',
      },
      {
        id: 80,
        expression: 'Он часто ссылается на эти цитаты',
        transcription: 'hiː ˈɒf(ə)n rɪˈfɜːz tuː ðiːz kwəʊts',
        translation: 'He often refers to these quotes',
      },
      {
        id: 81,
        expression: 'Он всегда ссылается на эти источники',
        transcription: 'hiː ˈɔːlweɪz rɪˈfɜːz tuː ðiːz ˈsɔːsɪz',
        translation: 'He always refers to these sources',
      },
      {
        id: 82,
        expression: 'Она ссылалась на эту цитату?',
        transcription: 'dɪd ʃiː rɪˈfɜː tuː ðɪs kwəʊt?',
        translation: 'Did she refer to this quote?',
      },
      {
        id: 83,
        expression: 'Я вижу некоторые преимущества работы здесь',
        transcription: 'aɪ siː sʌm ədˈvɑːntɪʤɪz ɒv ˈwɜːkɪŋ hɪə',
        translation: 'I see some advantages of working here',
      },
      {
        id: 84,
        expression: 'Я не вижу никаких недостатков работы здесь',
        transcription: 'aɪ siː sʌm ədˈvɑːntɪʤɪz ɒv ˈwɜːkɪŋ hɪə',
        translation: "I don't see any disadvantages of working here",
      },
      {
        id: 85,
        expression: 'Есть какие-либо преимущества того метода?',
        transcription: 'ɑː ðeər ˈɛni ədˈvɑːntɪʤɪz ɒv ðæt ˈmɛθəd?',
        translation: 'Are there any advantages of that method?',
      },
      {
        id: 86,
        expression: 'Ответ на этот вопрос неправильный',
        transcription: 'ði ˈɑːnsə tuː ðɪs ˈkwɛsʧən ˈɪznt kəˈrɛkt',
        translation: "The answer to this question isn't correct",
      },
      {
        id: 87,
        expression: 'Он не ответил на этот вопрос',
        transcription: 'hiː dɪdnt ˈɑːnsə ðɪs ˈkwɛsʧən',
        translation: "He didn't answer this question",
      },
      {
        id: 88,
        expression: 'Я уже ответил на этот вопрос',
        transcription: 'aɪv ɔːlˈrɛdi ˈɑːnsəd ðɪs ˈkwɛsʧən',
        translation: "I've already answered this question",
      },
      {
        id: 89,
        expression: 'Он не видит никаких преимуществ жизни за границей',
        transcription: 'hiː dʌznt siː ˈɛni ədˈvɑːntɪʤɪz ɒv ˈlɪvɪŋ əˈbrɔːd',
        translation: "He doesn't see any advantages of living abroad",
      },
      {
        id: 90,
        expression: 'Он извинился передо мной на следующий день',
        transcription: 'hiː əˈpɒləʤaɪzd tuː miː ðə nɛkst deɪ',
        translation: 'He apologised to me the next day',
      },
      {
        id: 91,
        expression: 'Какая была ее реакция на твои слова?',
        transcription: 'wɒt wɒz hɜː ri(ː)ˈækʃən tuː jɔː wɜːdz?',
        translation: 'What was her reaction to your words?',
      },
      {
        id: 92,
        expression: 'Я еще не нашел ответ на этот вопрос',
        transcription: 'aɪ hævnt faʊnd ən ˈɑːnsə tuː ðɪs ˈkwɛsʧən jɛt',
        translation: "I haven't found an answer to this question yet",
      },
      {
        id: 93,
        expression: 'Это было причиной пожара',
        transcription: 'ɪt wɒz ðə kɔːz ɒv ðə ˈfaɪə',
        translation: 'It was the cause of the fire',
      },
      {
        id: 94,
        expression: 'Он еще не восстановился от этой болезни',
        transcription: 'hiː ˈhæznt rɪˈkʌvəd frɒm ðɪs ˈɪlnɪs jɛt',
        translation: "He hasn't recovered from this illness yet",
      },
      {
        id: 95,
        expression: 'Спрос на этот продукт стал ниже',
        transcription: 'ðə dɪˈmɑːnd fɔː ðɪs ˈprɒdʌkt gɒt ˈləʊə',
        translation: 'The demand for this product got lower',
      },
      {
        id: 96,
        expression: 'Ответ на этот вопрос неверный',
        transcription: 'ði ˈɑːnsə tuː ðɪs ˈkwɛsʧən ɪz ˌɪnkəˈrɛkt',
        translation: 'The answer to this question is incorrect',
      },
      {
        id: 97,
        expression: 'Это очень эффективный способ изучения английского языка',
        transcription: 'ɪts ə ˈvɛri ɪˈfɛktɪv weɪ ɒv ˈlɜːnɪŋ ˈɪŋglɪʃ',
        translation: "It's a very effective way of learning English",
      },
      {
        id: 98,
        expression: 'Это неправильный ответ на этот вопрос',
        transcription: 'ɪts ən ˌɪnkəˈrɛkt ˈɑːnsə tuː ðɪs ˈkwɛsʧən',
        translation: "It's an incorrect answer to this question",
      },
      {
        id: 99,
        expression: 'На что он ссылается?',
        transcription: 'wɒt dʌz hiː rɪˈfɜː tuː?',
        translation: 'What does he refer to?',
      },
      {
        id: 100,
        expression: 'Почему ты настаиваешь на том, чтобы сделать это?',
        transcription: 'waɪ duː juː ɪnˈsɪst ɒn ˈdu(ː)ɪŋ ɪt?',
        translation: 'Why do you insist on doing it?',
      },
      {
        id: 101,
        expression: 'Им не хватает смелости, чтобы сделать это',
        transcription: 'ðeɪ læk ˈkʌrɪʤ tuː duː ɪt',
        translation: 'They lack courage to do it',
      },
      {
        id: 102,
        expression: 'С этим трудно не согласиться',
        transcription: 'ɪts ˈdɪfɪkəlt nɒt tuː əˈgriː wɪð ɪt',
        translation: "It's difficult not to agree with it",
      },
      {
        id: 103,
        expression: 'Он настоял на том, чтобы сделать это',
        transcription: 'hiː ɪnˈsɪstɪd ɒn ˈdu(ː)ɪŋ ɪt',
        translation: 'He insisted on doing it',
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Продолжаем изучать зависимые предлоги</h3>
</div>`,
    },
  ],
];
