export const wordsLesson228 = [
  [
    {
      id: 1,
      word: 'definite',
      transcription: 'ˈdɛfɪnɪt',
      translation: 'определенный',
    },
    {
      id: 2,
      word: 'moreover',
      transcription: 'mɔːˈrəʊvə',
      translation: 'более того',
    },
  ],
];
