export const wordsLesson1 = [
  [
    {
      id: 1,
      word: 'I',
      transcription: 'aɪ',
      translation: 'я',
    },
    {
      id: 2,
      word: 'speak',
      transcription: 'spiːk',
      translation: 'говорить',
    },
    {
      id: 3,
      word: 'English',
      transcription: 'ˈɪŋglɪʃ',
      translation: 'английский',
    },
    {
      id: 4,
      word: 'can',
      transcription: 'kæn',
      translation: 'мочь',
    },
    {
      id: 5,
      word: 'understand',
      transcription: 'ˌʌndəˈstænd',
      translation: 'понимать',
    },
    {
      id: 6,
      word: 'you',
      transcription: 'juː',
      translation: 'ты, тебе',
    },
    {
      id: 7,
      word: 'it',
      transcription: 'ɪt',
      translation: 'это',
    },
    {
      id: 8,
      word: 'very',
      transcription: 'ˈvɛri',
      translation: 'очень',
    },
    {
      id: 9,
      word: 'well',
      transcription: 'wɛl',
      translation: 'хорошо',
    },
    {
      id: 10,
      word: 'work',
      transcription: 'wɜːk',
      translation: 'работа, работать',
    },
    {
      id: 11,
      word: 'study',
      transcription: 'ˈstʌdi',
      translation: 'учиться',
    },
    {
      id: 12,
      word: 'and',
      transcription: 'ænd',
      translation: 'и',
    },
    {
      id: 13,
      word: 'learn',
      transcription: 'lɜːn',
      translation: 'учиться, изучать',
    },
    {
      id: 14,
      word: 'like',
      transcription: 'laɪk',
      translation: 'нравиться',
    },
    {
      id: 15,
      word: 'this',
      transcription: 'ðɪs',
      translation: 'этот',
    },
    {
      id: 16,
      word: 'online',
      transcription: 'ˈɒnˌlaɪn',
      translation: 'онлайн',
    },
    {
      id: 17,
      word: 'place',
      transcription: 'pleɪs',
      translation: 'место',
    },
    {
      id: 18,
      word: 'city',
      transcription: 'ˈsɪti',
      translation: 'город',
    },
    {
      id: 19,
      word: 'live',
      transcription: 'lɪv',
      translation: 'жить',
    },
    {
      id: 20,
      word: 'in',
      transcription: 'ɪn',
      translation: 'в',
    },
    {
      id: 21,
      word: 'Russia',
      transcription: 'ˈrʌʃə',
      translation: 'Россия',
    },
    {
      id: 22,
      word: 'New York',
      transcription: 'njuː jɔːk',
      translation: 'Нью-Йорк',
    },
    {
      id: 23,
      word: 'country',
      transcription: 'ˈkʌntri',
      translation: 'страна',
    },
    {
      id: 24,
      word: 'we',
      transcription: 'wiː',
      translation: 'мы',
    },
    {
      id: 25,
      word: 'they',
      transcription: 'ðeɪ',
      translation: 'они',
    },
    {
      id: 26,
      word: 'help',
      transcription: 'hɛlp',
      translation: 'помощь, помогать',
    },
    {
      id: 27,
      word: 'me',
      transcription: 'miː',
      translation: 'меня, мне',
    },
    {
      id: 28,
      word: 'really',
      transcription: 'ˈrɪəli',
      translation: 'очень, действительно',
    },
    {
      id: 29,
      word: 'want',
      transcription: 'wɒnt',
      translation: 'хотеть',
    },
    {
      id: 30,
      word: 'lesson',
      transcription: 'ˈlɛsn',
      translation: 'урок',
    },
    {
      id: 31,
      word: 'video',
      transcription: 'ˈvɪdɪəʊ',
      translation: 'видео',
    },
    {
      id: 32,
      word: 'program',
      transcription: 'ˈprəʊgræm',
      translation: 'программа',
    },
    {
      id: 33,
      word: 'see',
      transcription: 'siː',
      translation: 'видеть',
    },
    {
      id: 34,
      word: 'my',
      transcription: 'maɪ',
      translation: 'мой',
    },
    {
      id: 35,
      word: 'result',
      transcription: 'rɪˈzʌlt',
      translation: 'результат',
    },
    {
      id: 36,
      word: 'Russian',
      transcription: 'ˈrʌʃ(ə)n',
      translation: 'русский',
    },
    {
      id: 37,
      word: 'know',
      transcription: 'nəʊ',
      translation: 'знать',
    },
    {
      id: 38,
      word: 'go',
      transcription: 'gəʊ',
      translation: 'идти, ехать',
    },
    {
      id: 39,
      word: 'to',
      transcription: 'tuː',
      translation: 'в, к',
    },
    {
      id: 40,
      word: 'school',
      transcription: 'skuːl',
      translation: 'школа',
    },
    {
      id: 41,
      word: 'often',
      transcription: 'ˈɒf(ə)n',
      translation: 'часто',
    },
    {
      id: 42,
      word: 'here',
      transcription: 'hɪə',
      translation: 'здесь, сюда',
    },
    {
      id: 43,
      word: 'there',
      transcription: 'ðeə',
      translation: 'там, туда',
    },
    {
      id: 44,
      word: 'language',
      transcription: 'ˈlæŋgwɪʤ',
      translation: 'язык',
    },
    {
      id: 45,
      word: 'every',
      transcription: 'ˈɛvri',
      translation: 'каждый',
    },
    {
      id: 46,
      word: 'day',
      transcription: 'deɪ',
      translation: 'день',
    },
    {
      id: 47,
      word: 'do',
      transcription: 'duː',
      translation: 'делать',
    },
    {
      id: 48,
      word: 'travel',
      transcription: 'ˈtrævl',
      translation: 'путешествовать',
    },
    {
      id: 49,
      word: 'year',
      transcription: 'jɪə',
      translation: 'год',
    },
    {
      id: 50,
      word: 'travelling',
      transcription: 'ˈtrævlɪŋ',
      translation: 'путешествие',
    },
    {
      id: 51,
      word: 'game',
      transcription: 'geɪm',
      translation: 'игра',
    },
    {
      id: 52,
      word: 'play',
      transcription: 'pleɪ',
      translation: 'играть',
    },
    {
      id: 53,
      word: 'football',
      transcription: 'ˈfʊtbɔːl',
      translation: 'футбол',
    },
    {
      id: 54,
      word: 'so',
      transcription: 'səʊ',
      translation: 'так, такой',
    },
    {
      id: 55,
      word: 'also',
      transcription: 'ˈɔːlsəʊ',
      translation: 'также, тоже',
    },
    {
      id: 56,
      word: 'think',
      transcription: 'θɪŋk',
      translation: 'думать',
    },
    {
      id: 57,
      word: 'about',
      transcription: 'əˈbaʊt',
      translation: 'о, об',
    },
    {
      id: 58,
      word: 'sometimes',
      transcription: 'ˈsʌmtaɪmz',
      translation: 'иногда',
    },
    {
      id: 59,
      word: 'love',
      transcription: 'lʌv',
      translation: 'любить, любовь',
    },
    {
      id: 60,
      word: 'song',
      transcription: 'sɒŋ',
      translation: 'песня',
    },
    {
      id: 61,
      word: 'music',
      transcription: 'ˈmjuːzɪk',
      translation: 'музыка',
    },
    {
      id: 62,
      word: 'read',
      transcription: 'riːd',
      translation: 'читать',
    },
    {
      id: 63,
      word: 'book',
      transcription: 'bʊk',
      translation: 'книга',
    },
    {
      id: 64,
      word: 'more',
      transcription: 'mɔː',
      translation: 'больше, более',
    },
    {
      id: 65,
      word: 'practice',
      transcription: 'ˈpræktɪs',
      translation: 'практика',
    },
    {
      id: 66,
      word: 'these',
      transcription: 'ðiːz',
      translation: 'эти',
    },
    {
      id: 67,
      word: 'usually',
      transcription: 'ˈjuːʒʊəli',
      translation: 'обычно',
    },
    {
      id: 68,
      word: 'buy',
      transcription: 'baɪ',
      translation: 'купить',
    },
    {
      id: 69,
      word: 'thing',
      transcription: 'θɪŋ',
      translation: 'вещь',
    },
    {
      id: 70,
      word: 'university',
      transcription: 'ˌjuːnɪˈvɜːsɪti',
      translation: 'университет',
    },
  ],
];
