export const wordsLesson209 = [
  [
    {
      id: 1,
      word: 'advertisement',
      transcription: 'ədˈvɜːtɪsmənt',
      translation: 'рекламное объявление',
    },
    {
      id: 2,
      word: 'anybody',
      transcription: 'ˈɛnɪˌbɒdi',
      translation: 'кто-нибудь',
    },
    {
      id: 3,
      word: 'assistance',
      transcription: 'əˈsɪstəns',
      translation: 'помощь',
    },
    {
      id: 4,
      word: 'contribution',
      transcription: 'ˌkɒntrɪˈbjuːʃᵊn',
      translation: 'вклад',
    },
    {
      id: 5,
      word: 'cooperation',
      transcription: 'kəʊˌɒpəˈreɪʃᵊn',
      translation: 'сотрудничество',
    },
    {
      id: 6,
      word: 'cuisine',
      transcription: 'kwi(ː)ˈziːn',
      translation: 'кухня',
    },
    {
      id: 7,
      word: 'envy',
      transcription: 'ˈɛnvi',
      translation: 'зависть',
    },
    {
      id: 8,
      word: 'fruitful',
      transcription: 'ˈfruːtfʊl',
      translation: 'плодотворный',
    },
    {
      id: 9,
      word: 'jealousy',
      transcription: 'ˈʤɛləsi',
      translation: 'ревность',
    },
    {
      id: 10,
      word: 'meaningless',
      transcription: 'ˈmiːnɪŋlɪs',
      translation: 'бессмысленный',
    },
    {
      id: 11,
      word: 'obsessed',
      transcription: 'əbˈsɛst',
      translation: 'одержимый',
    },
    {
      id: 12,
      word: 'occupied',
      transcription: 'ˈɒkjʊpaɪd',
      translation: 'занятый',
    },
    {
      id: 13,
      word: 'prosperity',
      transcription: 'prɒsˈpɛrɪti',
      translation: 'процветание',
    },
    {
      id: 14,
      word: 'reform',
      transcription: 'ˌriːˈfɔːm',
      translation: 'реформа',
    },
    {
      id: 15,
      word: 'relationship',
      transcription: 'rɪˈleɪʃənʃɪp',
      translation: 'отношения',
    },
    {
      id: 16,
      word: 'resistance',
      transcription: 'rɪˈzɪstəns',
      translation: 'сопротивление',
    },
    {
      id: 17,
      word: 'substantial',
      transcription: 'səbˈstænʃəl',
      translation: 'существенный',
    },
    {
      id: 18,
      word: 'sympathy',
      transcription: 'ˈsɪmpəθi',
      translation: 'сочувствие',
    },
    {
      id: 19,
      word: 'undervalue',
      transcription: 'ˌʌndəˈvæljuː',
      translation: 'недооценивать',
    },
    {
      id: 20,
      word: 'unjustly',
      transcription: 'ʌnˈʤʌstli',
      translation: 'несправедливо',
    },
    {
      id: 21,
      word: 'wave',
      transcription: 'weɪv',
      translation: 'волна, махать',
    },
  ],
];
