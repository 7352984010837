export const wordsLesson52 = [
  [
    {
      id: 1,
      word: 'advanced',
      transcription: 'ədˈvɑːnst',
      translation: 'продвинутый',
   },
    {
      id: 2,
      word: 'baby',
      transcription: 'ˈbeɪbi',
      translation: 'малыш',
    },
    {
      id: 3,
      word: 'basic',
      transcription: 'ˈbeɪsɪk',
      translation: 'основной',
    },
    {
      id: 4,
      word: 'black',
      transcription: 'blæk',
      translation: 'черный',
    },
    {
      id: 5,
      word: 'blond',
      transcription: 'blɒnd',
      translation: 'светлый',
    },
    {
      id: 6,
      word: 'blue',
      transcription: 'bluː',
      translation: 'синий',
    },
    {
      id: 7,
      word: 'building',
      transcription: 'ˈbɪldɪŋ',
      translation: 'здание',
    },
    {
      id: 8,
      word: 'cold',
      transcription: 'kəʊld',
      translation: 'холодный',
    },
    {
      id: 9,
      word: 'colour',
      transcription: 'ˈkʌlə',
      translation: 'цвет',
    },
    {
      id: 10,
      word: 'device',
      transcription: 'dɪˈvaɪs',
      translation: 'устройство',
    },
    {
      id: 11,
      word: 'effect',
      transcription: 'ɪˈfɛkt',
      translation: 'эффект',
    },
    {
      id: 12,
      word: 'financial',
      transcription: 'faɪˈnænʃəl',
      translation: 'финансовый',
    },
    {
      id: 13,
      word: 'fresh',
      transcription: 'frɛʃ',
      translation: 'свежий',
    },
    {
      id: 14,
      word: 'fruit',
      transcription: 'fruːt',
      translation: 'фрукт, фрукты',
    },
    {
      id: 15,
      word: 'green',
      transcription: 'griːn',
      translation: 'зеленый',
    },
    {
      id: 16,
      word: 'intermediate',
      transcription: 'ˌɪntəˈmiːdiət',
      translation: 'средний',
    },
    {
      id: 17,
      word: 'low',
      transcription: 'ləʊ',
      translation: 'низкий',
    },
    {
      id: 18,
      word: 'pencil',
      transcription: 'ˈpɛnsl',
      translation: 'карандаш',
    },
    {
      id: 19,
      word: 'pleasant',
      transcription: 'ˈplɛznt',
      translation: 'приятный',
    },
    {
      id: 20,
      word: 'positive',
      transcription: 'ˈpɒzətɪv',
      translation: 'позитивный',
    },
    {
      id: 21,
      word: 'profitable',
      transcription: 'ˈprɒfɪtəbl',
      translation: 'прибыльный',
    },
    {
      id: 22,
      word: 'short',
      transcription: 'ʃɔːt',
      translation: 'короткий',
    },
    {
      id: 23,
      word: 'small',
      transcription: 'smɔːl',
      translation: 'маленький',
    },
    {
      id: 24,
      word: 'strong',
      transcription: 'strɒŋ',
      translation: 'сильный',
    },
    {
      id: 25,
      word: 'town',
      transcription: 'taʊn',
      translation: 'городок',
    },
    {
      id: 26,
      word: 'vegetables',
      transcription: 'ˈvɛʤtəb(ə)lz',
      translation: 'овощи',
    },
    {
      id: 27,
      word: 'voice',
      transcription: 'vɔɪs',
      translation: 'голос',
    },
    {
      id: 28,
      word: 'warm',
      transcription: 'wɔːm',
      translation: 'теплый',
    },
  ],
];
