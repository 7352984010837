export const wordsLesson298 = [
  [
    {
      id: 1,
      word: 'anti-virus',
      transcription: 'ˈænti-ˈvaɪərəs',
      translation: 'антивирус',
    },
    {
      id: 2,
      word: 'beggar',
      transcription: 'ˈbɛɡə',
      translation: 'нищий, попрошайка',
    },
    {
      id: 3,
      word: 'clarification',
      transcription: 'ˌklærɪfɪˈkeɪʃᵊn',
      translation: 'разъяснение',
    },
    {
      id: 4,
      word: 'continuation',
      transcription: 'kənˌtɪnjuˈeɪʃᵊn',
      translation: 'продолжение',
    },
    {
      id: 5,
      word: 'encouragement',
      transcription: 'ɪnˈkʌrɪʤmənt',
      translation: 'поддержка, ободрение',
    },
    {
      id: 6,
      word: 'faithfulness',
      transcription: 'ˈfeɪθfʊlnəs',
      translation: 'верность',
    },
    {
      id: 7,
      word: 'fantasy',
      transcription: 'ˈfæntəsi',
      translation: 'фантазия',
    },
    {
      id: 8,
      word: 'fist',
      transcription: 'fɪst',
      translation: 'кулак',
    },
    {
      id: 9,
      word: 'immoral',
      transcription: 'ɪˈmɒrəl',
      translation: 'аморальный',
    },
    {
      id: 10,
      word: 'imperfect',
      transcription: 'ɪmˈpɜːfɪkt',
      translation: 'несовершенный',
    },
    {
      id: 11,
      word: 'improper',
      transcription: 'ɪmˈprɒpə',
      translation: 'неуместный',
    },
    {
      id: 12,
      word: 'imprudent',
      transcription: 'ɪmˈpruːdənt',
      translation: 'неосторожный',
    },
    {
      id: 13,
      word: 'irrational',
      transcription: 'ɪˈræʃᵊnᵊl',
      translation: 'иррациональный',
    },
    {
      id: 14,
      word: 'irregularity',
      transcription: 'ɪˌrɛɡjəˈlærəti',
      translation: 'нерегулярность',
    },
    {
      id: 15,
      word: 'modernize',
      transcription: 'ˈmɒdənaɪz',
      translation: 'модернизировать',
    },
    {
      id: 16,
      word: 'novelty',
      transcription: 'ˈnɒvəlti',
      translation: 'новизна',
    },
    {
      id: 17,
      word: 'participation',
      transcription: 'pɑːˌtɪsɪˈpeɪʃᵊn',
      translation: 'участие',
    },
    {
      id: 18,
      word: 'publication',
      transcription: 'ˌpʌblɪˈkeɪʃᵊn',
      translation: 'публикация',
    },
    {
      id: 19,
      word: 'retake',
      transcription: 'riːˈteɪk',
      translation: 'переснимать',
    },
    {
      id: 20,
      word: 'self-esteem',
      transcription: 'sɛlf-ɪsˈtiːm',
      translation: 'оценка',
    },
    {
      id: 21,
      word: 'simplify',
      transcription: 'ˈsɪmplɪfaɪ',
      translation: 'упрощать',
    },
    {
      id: 22,
      word: 'software',
      transcription: 'ˈsɒftweə',
      translation: 'программное обеспечение',
    },
    {
      id: 23,
      word: 'staggered',
      transcription: 'ˈstæɡəd',
      translation: 'пораженный (удивленный)',
    },
    {
      id: 24,
      word: 'stubbornness',
      transcription: 'ˈstʌbənnəs',
      translation: 'упрямство',
    },
    {
      id: 25,
      word: 'unambitious',
      transcription: 'ˌʌnæmˈbɪʃəs',
      translation: 'не амбициозный',
    },
    {
      id: 26,
      word: 'well-developed',
      transcription: 'wɛl-dɪˈvɛləpt',
      translation: 'хорошо развитый',
    },
    {
      id: 27,
      word: 'width',
      transcription: 'wɪdθ',
      translation: 'ширина',
    },
  ],
];
