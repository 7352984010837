export const contentLesson140 = [
  [
    {
      title: 'Урок 140. 230 Adjectives. Part 2',
    },
    [
      {
        id: 1,
        expression: 'Это был очень серьезный финансовый кризис',
        transcription: 'ɪt wəz ə ˈvɛri ˈsɪərɪəs faɪˈnænʃəl ˈkraɪsɪs',
        translation: 'It was a very serious financial crisis',
      },
      {
        id: 2,
        expression: 'Она не сумасшедшая',
        transcription: 'ʃi ˈɪznt ˈkreɪzi',
        translation: "She isn't crazy",
      },
      {
        id: 3,
        expression: 'Я показал отличный результат',
        transcription: 'aɪ ʃəʊd ən ˈɛksələnt rɪˈzʌlt',
        translation: 'I showed an excellent result',
      },
      {
        id: 4,
        expression: 'Это была уникальная возможность',
        transcription: 'ɪt wəz ə juːˈniːk ˌɒpəˈtjuːnɪti',
        translation: 'It was a unique opportunity',
      },
      {
        id: 5,
        expression: 'Там было очень тепло',
        transcription: 'ɪt wəz ˈvɛri wɔːm ðeə',
        translation: 'It was very warm there',
      },
      {
        id: 6,
        expression: 'Я постараюсь быть точнее',
        transcription: 'aɪl traɪ tə bi mɔːr ɪgˈzækt',
        translation: "I'll try to be more exact",
      },
      {
        id: 7,
        expression: 'Он очень креативный человек',
        transcription: 'hiːz ə ˈvɛri kri(ː)ˈeɪtɪv ˈpɜːsn',
        translation: "He's a very creative person",
      },
      {
        id: 8,
        expression: 'Какой правильный ответ?',
        transcription: 'wɒts ðə kəˈrɛkt ˈɑːnsə?',
        translation: "What's the correct answer?",
      },
      {
        id: 9,
        expression: 'Это очень понятное объяснение',
        transcription: 'ɪts ə ˈvɛri klɪər ˌɛkspləˈneɪʃən',
        translation: "It's a very clear explanation",
      },
      {
        id: 10,
        expression: 'Это было очень внезапное решение',
        transcription: 'ɪt wəz ə ˈvɛri ˈsʌdn dɪˈsɪʒən',
        translation: 'It was a very sudden decision',
      },
      {
        id: 11,
        expression: 'Это образовательная программа',
        transcription: 'ɪts ən ˌɛdju(ː)ˈkeɪʃənl ˈprəʊgræm',
        translation: "It's an educational program",
      },
      {
        id: 12,
        expression: 'Эти объяснения вполне ясны',
        transcription: 'ðiːz ˌɛkspləˈneɪʃənz ə kwaɪt klɪə',
        translation: 'These explanations are quite clear',
      },
      {
        id: 13,
        expression: 'Торт такой сладкий',
        transcription: 'ðə keɪk s səʊ swiːt',
        translation: 'The cake is so sweet',
      },
      {
        id: 14,
        expression: 'Это техническая проблема',
        transcription: 'ɪts ə ˈtɛknɪkəl ˈprɒbləm',
        translation: "It's a technical problem",
      },
      {
        id: 15,
        expression: 'Эти слова очень редкие',
        transcription: 'ðiːz wɜːdz ə ˈvɛri reə',
        translation: 'These words are very rare',
      },
      {
        id: 16,
        expression: 'Это была маленькая техническая проблема',
        transcription: 'ɪt wəz ə smɔːl ˈtɛknɪkəl ˈprɒbləm',
        translation: 'It was a small technical problem',
      },
      {
        id: 17,
        expression: 'У моего друга отличные результаты',
        transcription: 'maɪ frɛnd həz ˈɛksələnt rɪˈzʌlts',
        translation: 'My friend has excellent results',
      },
      {
        id: 18,
        expression: 'Он чувствует себя больным',
        transcription: 'hi fiːlz sɪk',
        translation: 'He feels sick',
      },
      {
        id: 19,
        expression: 'Это очень редкое слово',
        transcription: 'ɪts ə ˈvɛri reə wɜːd',
        translation: "It's a very rare word",
      },
      {
        id: 20,
        expression: 'Я рад тебя видеть',
        transcription: 'aɪm glæd tə siː juː',
        translation: "I'm glad to see you",
      },
      {
        id: 21,
        expression: 'Я чувствовал себя чрезвычайно уставшим',
        transcription: 'aɪ fɛlt ɪksˈtriːmli ˈtaɪəd',
        translation: 'I felt extremely tired',
      },
      {
        id: 22,
        expression: 'Это отрицательное предложение',
        transcription: 'ɪts ə ˈnɛgətɪv ˈsɛntəns',
        translation: "It's a negative sentence",
      },
      {
        id: 23,
        expression: 'Это правильный ответ',
        transcription: 'ɪts ðə kəˈrɛkt ˈɑːnsə',
        translation: "It's the correct answer",
      },
      {
        id: 24,
        expression: 'Она больна в данный момент',
        transcription: 'ʃiːz sɪk (ɪl) ət ðə ˈməʊmənt',
        translation: "She's sick (ill) at the moment",
      },
      {
        id: 25,
        expression: 'Те объяснения недостаточно понятны',
        transcription: 'ðəʊz ˌɛkspləˈneɪʃənz ɑːnt klɪər ɪˈnʌf',
        translation: "Those explanations aren't clear enough",
      },
      {
        id: 26,
        expression: 'У нее были очень серьезные финансовые проблемы',
        transcription: 'ʃi həd ˈvɛri ˈsɪərɪəs faɪˈnænʃəl ˈprɒbləmz',
        translation: 'She had very serious financial problems',
      },
      {
        id: 27,
        expression: 'Он очень дружелюбный человек',
        transcription: 'hiːz ə ˈvɛri ˈfrɛndli ˈpɜːsn',
        translation: "He's a very friendly person",
      },
      {
        id: 28,
        expression: 'Машина зеленая',
        transcription: 'ðə kɑː z griːn',
        translation: 'The car is green',
      },
      {
        id: 29,
        expression: 'Я не был готов к тем изменениям',
        transcription: 'aɪ wɒznt ˈrɛdi fə ðəʊz ˈʧeɪnʤɪz',
        translation: "I wasn't ready for those changes",
      },
      {
        id: 30,
        expression: 'Я очень рад своим результатам',
        transcription: 'aɪm ˈvɛri ˈhæpi əˈbaʊt maɪ rɪˈzʌlts',
        translation: "I'm very happy about my results",
      },
      {
        id: 31,
        expression: 'У нее белая машина',
        transcription: 'ʃi həz ə waɪt kɑː',
        translation: 'She has a white car',
      },
      {
        id: 32,
        expression: 'Он купил синюю машину',
        transcription: 'hi bɔːt ə bluː kɑː',
        translation: 'He bought a blue car',
      },
      {
        id: 33,
        expression: 'Это самый счастливый день в моей жизни',
        transcription: 'ɪts ðə ˈhæpɪɪst deɪ ɪn maɪ laɪf',
        translation: "It's the happiest day in my life",
      },
      {
        id: 34,
        expression: 'Он дал мне отрицательный ответ',
        transcription: 'hi geɪv mi ə ˈnɛgətɪv ˈɑːnsə',
        translation: 'He gave me a negative answer',
      },
      {
        id: 35,
        expression: 'Мне нужна черная ручка',
        transcription: 'aɪ niːd ə blæk pɛn',
        translation: 'I need a black pen',
      },
      {
        id: 36,
        expression: 'Я совсем не удивлен',
        transcription: 'aɪm nɒt səˈpraɪzd ət ɔːl',
        translation: "I'm not surprised at all",
      },
      {
        id: 37,
        expression: 'Мы увидели внезапное изменение в его поведении',
        transcription: 'wi sɔː ə ˈsʌdn ʧeɪnʤ ɪn ɪz bɪˈheɪvjə',
        translation: 'We saw a sudden change in his behaviour',
      },
      {
        id: 38,
        expression: 'Она без ума от этого певца',
        transcription: 'ʃiːz ˈkreɪzi əˈbaʊt ðɪs ˈsɪŋə',
        translation: "She's crazy about this singer",
      },
      {
        id: 39,
        expression: 'Они такие дружелюбные люди',
        transcription: 'ðeə sʌʧ ˈfrɛndli ˈpiːpl',
        translation: "They're such friendly people",
      },
      {
        id: 40,
        expression: 'Они такие дружелюбные',
        transcription: 'ðeə səʊ ˈfrɛndli',
        translation: "They're so friendly",
      },
      {
        id: 41,
        expression: 'Будет такой теплый день завтра',
        transcription: 'ˈɪtl bi sʌʧ ə wɔːm deɪ təˈmɒrəʊ',
        translation: "It'll be such a warm day tomorrow",
      },
      {
        id: 42,
        expression: 'Я ужасно занят в данный момент',
        transcription: 'aɪm ˈtɛrəbli ˈbɪzi ət ðə ˈməʊmənt',
        translation: "I'm terribly busy at the moment",
      },
      {
        id: 43,
        expression: 'Эта комната намного больше той',
        transcription: 'ðɪs ruːm z mʌʧ ˈbɪgə ðən ðət wʌn',
        translation: 'This room is much bigger than that one',
      },
      {
        id: 44,
        expression: 'Это уникальная возможность учить английский здесь',
        transcription: 'ɪts ə juːˈniːk ˌɒpəˈtjuːnɪti tə lɜːn ˈɪŋglɪʃ hɪə',
        translation: "It's a unique opportunity to learn English here",
      },
      {
        id: 45,
        expression: 'С моей точки зрения, это возможное решение этой проблемы',
        transcription:
          'frəm maɪ pɔɪnt əv vjuː ɪts ə ˈpɒsəbl səˈluːʃən tə ðɪs ˈprɒbləm',
        translation:
          "From my point of view it's a possible solution to this problem",
      },
      {
        id: 46,
        expression: 'Я был так рад его видеть',
        transcription: 'aɪ wəz səʊ glæd tə siː hɪm',
        translation: 'I was so glad to see him',
      },
      {
        id: 47,
        expression: 'Курение запрещено в общественных местах?',
        transcription: 'ɪz ˈsməʊkɪŋ fəˈbɪdn ɪn ˈpʌblɪk ˈpleɪsɪz?',
        translation: 'Is smoking forbidden in public places?',
      },
      {
        id: 48,
        expression: 'Их офис расположен с правой стороны',
        transcription: 'ðeər ˈɒfɪs ɪz ləʊˈkeɪtɪd ɪn ðə raɪt saɪd',
        translation: 'Their office is located in the right side',
      },
      {
        id: 49,
        expression:
          'Все были шокированы, когда они узнали, что полиция нашла его мертвым',
        transcription:
          'ˈɛvrɪθɪŋ wəz ʃɒkt wɛn ðeɪ njuː ðət ðə pəˈliːs həd faʊnd ɪm dɛd',
        translation:
          'Everything was shocked when they knew that the police had found him dead',
      },
      {
        id: 50,
        expression: 'Это такая сладкая конфета',
        transcription: 'ɪts sʌʧ ə swiːt ˈkændi',
        translation: "It's such a sweet candy",
      },
      {
        id: 51,
        expression: 'Это было короткое расстояние',
        transcription: 'ɪt wəz ə ʃɔːt ˈdɪstəns',
        translation: 'It was a short distance',
      },
      {
        id: 52,
        expression: 'Эти цифры недостаточно точные',
        transcription: 'ðiːz ˈfɪgəz ɑːnt ɪgˈzækt ɪˈnʌf',
        translation: "These figures aren't exact enough",
      },
      {
        id: 53,
        expression: 'Я готов к тесту',
        transcription: 'aɪm ˈrɛdi fə ðə tɛst',
        translation: "I'm ready for the test",
      },
      {
        id: 54,
        expression: 'У него гораздо ниже уровень',
        transcription: 'hi həz ə mʌʧ ˈləʊə ˈlɛvl',
        translation: 'He has a much lower level',
      },
      {
        id: 55,
        expression: 'Это был ужасный результат',
        transcription: 'ɪt wəz ə ˈtɛrəbl rɪˈzʌlt',
        translation: 'It was a terrible result',
      },
      {
        id: 56,
        expression: 'Это такая уникальная возможность',
        transcription: 'ɪts sʌʧ ə juːˈniːk ˌɒpəˈtjuːnɪti',
        translation: "It's such a unique opportunity",
      },
      {
        id: 57,
        expression: 'Этот шанс такой уникальный',
        transcription: 'ðɪs ʧɑːns ɪz səʊ juːˈniːk',
        translation: 'This chance is so unique',
      },
      {
        id: 58,
        expression: 'Это была такая уникальная возможность для меня',
        transcription: 'ɪt wəz sʌʧ ə juːˈniːk ˌɒpəˈtjuːnɪti fə miː',
        translation: 'It was such a unique opportunity for me',
      },
      {
        id: 59,
        expression: 'Здесь гораздо теплее',
        transcription: 'ɪts mʌʧ ˈwɔːmə hɪə',
        translation: "It's much warmer here",
      },
      {
        id: 60,
        expression: 'Там действительно теплее',
        transcription: 'ɪts ˈrɪəli ˈwɔːmə ðeə',
        translation: "It's really warmer there",
      },
      {
        id: 61,
        expression: 'Здесь так тепло',
        transcription: 'ɪts səʊ wɔːm hɪə',
        translation: "It's so warm here",
      },
      {
        id: 62,
        expression: 'Я был очень удивлен слышать это',
        transcription: 'aɪ wəz ˈvɛri səˈpraɪzd tə hɪər ɪt',
        translation: 'I was very surprised to hear it',
      },
      {
        id: 63,
        expression: 'Я был очень удивлен получить это сообщение',
        transcription: 'aɪ wəz ˈvɛri səˈpraɪzd tə rɪˈsiːv (gɛt) ðɪs ˈmɛsɪʤ',
        translation: 'I was very surprised to receive (get) this message',
      },
      {
        id: 64,
        expression: 'Я был действительно шокирован этой новостью',
        transcription: 'aɪ wəz ˈrɪəli ʃɒkt baɪ ðɪs njuːz',
        translation: 'I was really shocked by this news',
      },
      {
        id: 65,
        expression: 'Это был очень теплый день',
        transcription: 'ɪt wəz ə ˈvɛri wɔːm deɪ',
        translation: 'It was a very warm day',
      },
      {
        id: 66,
        expression: 'Это был такой дождливый день',
        transcription: 'ɪt wəz sʌʧ ə ˈreɪni deɪ',
        translation: 'It was such a rainy day',
      },
      {
        id: 67,
        expression: 'Была очень хорошая погода',
        transcription: 'ɪt wəz ˈvɛri gʊd ˈwɛðə',
        translation: 'It was very good weather',
      },
      {
        id: 68,
        expression: 'Здание расположено с левой стороны',
        transcription: 'ðə ˈbɪldɪŋ z ˈsɪtjʊeɪtɪd ɒn ðə lɛft saɪd',
        translation: 'The building is situated on the left side',
      },
      {
        id: 69,
        expression: 'Мой офис располагается рядом с этим зданием',
        transcription: 'maɪ ˈɒfɪs ɪz ˈsɪtjʊeɪtɪd nɪə ðɪs ˈbɪldɪŋ',
        translation: 'My office is situated near this building',
      },
      {
        id: 70,
        expression: 'Эта компания находится в нашем городе',
        transcription: 'ðɪs ˈkʌmpəni z ləʊˈkeɪtɪd ɪn ˈaʊə ˈsɪti',
        translation: 'This company is located in our city',
      },
      {
        id: 71,
        expression: 'Это почти невозможно',
        transcription: 'ɪts ˈɔːlməʊst ɪmˈpɒsəbl',
        translation: "It's almost impossible",
      },
      {
        id: 72,
        expression: 'Это действительно невозможно',
        transcription: 'ɪts ˈrɪəli ɪmˈpɒsəbl',
        translation: "It's really impossible",
      },
      {
        id: 73,
        expression: 'Это возможно или нет?',
        transcription: 'ɪz ɪt ˈpɒsəbl ɔː nɒt?',
        translation: 'Is it possible or not?',
      },
      {
        id: 74,
        expression: 'Мы все знаем, что она очень творческий человек',
        transcription: 'wi ɔːl nəʊ ðət ʃiːz ə ˈvɛri kri(ː)ˈeɪtɪv ˈpɜːsn',
        translation: "We all know that she's a very creative person",
      },
      {
        id: 75,
        expression: 'Я знаю, что она очень талантливая женщина',
        transcription: 'aɪ nəʊ ðət ʃiːz ə ˈvɛri ˈtæləntɪd ˈwʊmən',
        translation: "I know that she's a very talented woman",
      },
      {
        id: 76,
        expression: 'Мы все знаем, что она очень надежный человек',
        transcription: 'wi ɔːl nəʊ ðət ʃiːz ə ˈvɛri rɪˈlaɪəbl ˈpɜːsn',
        translation: "We all know that she's a very reliable person",
      },
      {
        id: 77,
        expression: 'Это будет уникальный шанс для меня',
        transcription: 'ˈɪtl bi ə juːˈniːk ʧɑːns fə miː',
        translation: "It'll be a unique chance for me",
      },
      {
        id: 78,
        expression: 'Это был действительно уникальный шанс для меня',
        transcription: 'ɪt wəz ə ˈrɪəli juːˈniːk ʧɑːns fə miː',
        translation: 'It was a really unique chance for me',
      },
      {
        id: 79,
        expression: 'Это будет уникальная возможность',
        transcription: 'ˈɪtl bi ə juːˈniːk ˌɒpəˈtjuːnɪti',
        translation: "It'll be a unique opportunity",
      },
      {
        id: 80,
        expression: 'У нее более низкий уровень',
        transcription: 'ʃi həz ə ˈləʊə ˈlɛvl',
        translation: 'She has a lower level',
      },
      {
        id: 81,
        expression: 'У нее самый низкий уровень',
        transcription: 'ʃi həz ðə ˈləʊɪst ˈlɛvl',
        translation: 'She has the lowest level',
      },
      {
        id: 82,
        expression: 'У нее более высокий уровень',
        transcription: 'ʃi həz ə ˈhaɪə ˈlɛvl',
        translation: 'She has a higher level',
      },
      {
        id: 83,
        expression: 'Какое хорошее предложение!',
        transcription: 'wɒt ə gʊd ˈɒfə!',
        translation: 'What a good offer!',
      },
      {
        id: 84,
        expression: 'Это хорошее предложение?',
        transcription: 'ɪz ɪt ə gʊd ˈɒfə?',
        translation: 'Is it a good offer?',
      },
      {
        id: 85,
        expression: 'Это лучшее предложение!',
        transcription: 'ɪts ðə bɛst ˈɒfə!',
        translation: "It's the best offer!",
      },
      {
        id: 86,
        expression: 'Это было горькое разочарование для меня',
        transcription: 'ɪt wəz ə ˈbɪtə ˌdɪsəˈpɔɪntmənt fə miː',
        translation: 'It was a bitter disappointment for me',
      },
      {
        id: 87,
        expression: 'Какая блестящая идея!',
        transcription: 'wɒt ə ˈbrɪljənt aɪˈdɪə!',
        translation: 'What a brilliant idea!',
      },
      {
        id: 88,
        expression: 'Я не думаю, что это была такая большая ошибка',
        transcription: 'aɪ dəʊnt θɪŋk ðət ɪt wəz sʌʧ ə bɪg mɪsˈteɪk',
        translation: "I don't think that it was such a big mistake",
      },
      {
        id: 89,
        expression: 'Это самый короткий путь',
        transcription: 'ɪts ðə ˈʃɔːtɪst weɪ',
        translation: "It's the shortest way",
      },
      {
        id: 90,
        expression: 'Это была пустая комната',
        transcription: 'ɪt wəz ən ˈɛmpti ruːm',
        translation: 'It was an empty room',
      },
      {
        id: 91,
        expression: 'Это возможное решение этой проблемы',
        transcription: 'ɪts ə ˈpɒsəbl səˈluːʃən tə ðɪs ˈprɒbləm',
        translation: "It's a possible solution to this problem",
      },
      {
        id: 92,
        expression: 'Как часто ты пользуешься общественным транспортом?',
        transcription: 'haʊ ˈɒf(ə)n dʊ jʊ juːz ˈpʌblɪk ˈtrænspɔːt?',
        translation: 'How often do you use public transport?',
      },
      {
        id: 93,
        expression: 'Это одна из самых популярных образовательных программ',
        transcription:
          'ɪts wʌn əv ðə məʊst ˈpɒpjʊlər ˌɛdju(ː)ˈkeɪʃənl ˈprəʊgræmz',
        translation: "It's one of the most popular educational programs",
      },
      {
        id: 94,
        expression: 'Он чувствовал себя чрезвычайно уставшим в конце дня',
        transcription: 'hi fɛlt ɪksˈtriːmli ˈtaɪəd ət ði ɛnd əv ðə deɪ',
        translation: 'He felt extremely tired at the end of the day',
      },
      {
        id: 95,
        expression: 'Она предпочитает горькую правду',
        transcription: 'ʃi priˈfɜːz ˈbɪtə truːθ',
        translation: 'She prefers bitter truth',
      },
      {
        id: 96,
        expression: 'Какой солнечный день!',
        transcription: 'wɒt ə ˈsʌni deɪ!',
        translation: 'What a sunny day!',
      },
      {
        id: 97,
        expression: 'Стакан был пуст',
        transcription: 'ðə glɑːs wəz ˈɛmpti',
        translation: 'The glass was empty',
      },
      {
        id: 98,
        expression: 'Какая хорошая идея!',
        transcription: 'wɒt ə gʊd aɪˈdɪə!',
        translation: 'What a good idea!',
      },
      {
        id: 99,
        expression: 'Ее брат холост',
        transcription: 'hə ˈbrʌðə z ˈsɪŋgl',
        translation: 'Her brother is single',
      },
      {
        id: 100,
        expression: 'Он одинок',
        transcription: 'hiːz ˈsɪŋgl',
        translation: "He's single",
      },
      {
        id: 101,
        expression: 'Я сдал свой последний экзамен',
        transcription: 'aɪ pɑːst maɪ ˈfaɪnl ɪgˈzæm',
        translation: 'I passed my final exam',
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Закрепление пройденного материала</h3>
</div>`,
    },
  ],
];
