export const wordsLesson265 = [
  [
    {
      id: 1,
      word: 'go ahead with',
      transcription: 'ɡəʊ əˈhɛd wɪð',
      translation: 'приступить',
    },
    {
      id: 2,
      word: 'answer back',
      transcription: 'ˈɑːnsə bæk',
      translation: 'грубить',
    },
    {
      id: 3,
      word: 'leap at',
      transcription: 'liːp æt',
      translation: 'ухватиться за',
    },
    {
      id: 4,
      word: 'leave aside',
      transcription: 'liːv əˈsaɪd',
      translation: 'оставить в стороне',
    },
    {
      id: 5,
      word: 'move out',
      transcription: 'muːv aʊt',
      translation: 'съезжать',
    },
    {
      id: 6,
      word: 'bring in',
      transcription: 'brɪŋ ɪn',
      translation: 'привлекать',
    },
    {
      id: 7,
      word: 'meet up',
      transcription: 'miːt ʌp',
      translation: 'встречаться',
    },
    {
      id: 8,
      word: 'drop by',
      transcription: 'drɒp baɪ',
      translation: 'заходить',
    },
    {
      id: 9,
      word: 'run down',
      transcription: 'rʌn daʊn',
      translation: 'сбить',
    },
    {
      id: 10,
      word: 'hang about',
      transcription: 'hæŋ əˈbaʊt',
      translation: 'слоняться без дела',
    },
    {
      id: 11,
      word: 'head for',
      transcription: 'hɛd fɔː',
      translation: 'направляться',
    },
    {
      id: 12,
      word: 'come round',
      transcription: 'kʌm raʊnd',
      translation: 'заходить, заглянуть',
    },
    {
      id: 13,
      word: 'stand up for',
      transcription: 'stænd ʌp fɔː',
      translation: 'отстаивать',
    },
    {
      id: 14,
      word: 'advisor',
      transcription: 'ədˈvaɪzə',
      translation: 'консультант',
    },
    {
      id: 15,
      word: 'aside',
      transcription: 'əˈsaɪd',
      translation: 'в сторону',
    },
    {
      id: 16,
      word: 'cyclist',
      transcription: 'ˈsaɪklɪst',
      translation: 'велосипедист',
    },
    {
      id: 17,
      word: 'disadvantaged',
      transcription: 'ˌdɪsədˈvɑːntɪʤd',
      translation: 'малообеспеченный',
    },
    {
      id: 18,
      word: 'highway',
      transcription: 'ˈhaɪweɪ',
      translation: 'шоссе',
    },
    {
      id: 19,
      word: 'pride',
      transcription: 'praɪd',
      translation: 'гордость',
    },
    {
      id: 20,
      word: 'unsurprising',
      transcription: 'ˌʌnsəˈpraɪzɪŋ',
      translation: 'неудивительный',
    },
  ],
];
