export const contentLesson57 = [
  [
    {
      title: "Урок 57. Определенный артикль the",
    },
    [
      {
        id: 1,
        expression: "Ребенок дома",
        transcription: "ðə ʧaɪld z ət həʊm",
        translation: "The child is at home",
      },
      {
        id: 2,
        expression: "Вода в этой бутылке",
        transcription: "ðə ˈwɔːtə z ɪn ðɪs ˈbɒtl",
        translation: "The water is in this bottle",
      },
      {
        id: 3,
        expression: "Эта девушка моя подруга",
        transcription: "ðɪs gɜːl z maɪ frɛnd",
        translation: "This girl is my friend",
      },
      {
        id: 4,
        expression: "Много людей приходит сюда каждый день",
        transcription: "ˈmɛni ˈpiːpl kʌm hɪər ˈɛvri deɪ",
        translation: "Many people come here every day",
      },
      {
        id: 5,
        expression: "Еда свежая",
        transcription: "ðə fuːd z frɛʃ",
        translation: "The food is fresh",
      },
      {
        id: 6,
        expression: "Игра такая скучная",
        transcription: "ðə geɪm z səʊ ˈbɔːrɪŋ",
        translation: "The game is so boring",
      },
      {
        id: 7,
        expression: "Книга очень тонкая",
        transcription: "ðə bʊk s ˈvɛri θɪn",
        translation: "The book is very thin",
      },
      {
        id: 8,
        expression: "Это солнечный день",
        transcription: "ɪts ə ˈsʌni deɪ",
        translation: "It's a sunny day",
      },
      {
        id: 9,
        expression: "Комната слишком маленькая",
        transcription: "ðə ruːm z tuː smɔːl",
        translation: "The room is too small",
      },
      {
        id: 10,
        expression: "День такой солнечный",
        transcription: "ðə deɪ z səʊ ˈsʌni",
        translation: "The day is so sunny",
      },
      {
        id: 11,
        expression: "Это маленькая комната",
        transcription: "ɪts ə smɔːl ruːm",
        translation: "It's a small room",
      },
      {
        id: 12,
        expression: "Экзамен слишком сложный",
        transcription: "ði ɪgˈzæm z tuː ˈdɪfɪkəlt",
        translation: "The exam is too difficult",
      },
      {
        id: 13,
        expression: "Это тонкая книга",
        transcription: "ɪts ə θɪn bʊk",
        translation: "It's a thin book",
      },
      {
        id: 14,
        expression: "Это скучная игра",
        transcription: "ɪts ə ˈbɔːrɪŋ geɪm",
        translation: "It's a boring game",
      },
      {
        id: 15,
        expression: "Мужчина толстый",
        transcription: "ðə mæn z fæt",
        translation: "The man is fat",
      },
      {
        id: 16,
        expression: "Это очень сложная ситуация",
        transcription: "ɪts ə ˈvɛri ˈdɪfɪkəlt ˌsɪtjʊˈeɪʃən",
        translation: "It's a very difficult situation",
      },
      {
        id: 17,
        expression: "Идеи очень интересные",
        transcription: "ði aɪˈdɪəz ər ˈɪntrɪstɪŋ",
        translation: "The ideas are interesting",
      },
      {
        id: 18,
        expression: "Студент очень амбициозен",
        transcription: "ðə ˈstjuːdənt s ˈvɛri æmˈbɪʃəs",
        translation: "The student is very ambitious",
      },
      {
        id: 19,
        expression: "Результаты блестящие",
        transcription: "ðə rɪˈzʌlts ə ˈbrɪljənt",
        translation: "The results are brilliant",
      },
      {
        id: 20,
        expression: "Ситуация очень сложная",
        transcription: "ðə ˌsɪtjʊˈeɪʃən z ˈvɛri ˈdɪfɪkəlt",
        translation: "The situation is very difficult",
      },
      {
        id: 21,
        expression: "Женщина очень стройная",
        transcription: "ðə ˈwʊmən zː ˈvɛri slɪm",
        translation: "The woman is very slim",
      },
      {
        id: 22,
        expression: "Тест очень простой",
        transcription: "ðə tɛst s ˈsɪmpl",
        translation: "The test is simple",
      },
      {
        id: 23,
        expression: "Путь слишком длинный",
        transcription: "ðə weɪ z tuː lɒŋ",
        translation: "The way is too long",
      },
      {
        id: 24,
        expression: "Это блестящий результат",
        transcription: "ɪts ə ˈbrɪljənt rɪˈzʌlt",
        translation: "It's a brilliant result",
      },
      {
        id: 25,
        expression: "Вопросы очень хорошие",
        transcription: "ðə ˈkwɛsʧənz ə ˈvɛri gʊd",
        translation: "The questions are very good",
      },
      {
        id: 26,
        expression: "Студенты очень амбициозны",
        transcription: "ðə ˈstjuːdənts ə ˈvɛri æmˈbɪʃəs",
        translation: "The students are very ambitious",
      },
      {
        id: 27,
        expression: "Урок чрезвычайно полезен",
        transcription: "ðə ˈlɛsn z ɪksˈtriːmli ˈjuːsfʊl",
        translation: "The lesson is extremely useful",
      },
      {
        id: 28,
        expression: "Она очень стройная девушка",
        transcription: "ʃiːz ə ˈvɛri slɪm gɜːl",
        translation: "She's a very slim girl",
      },
      {
        id: 29,
        expression: "Шутка очень смешная",
        transcription: "ðə ʤəʊk s ˈvɛri ˈfʌni",
        translation: "The joke is very funny",
      },
      {
        id: 30,
        expression: "Программа действительно скучная",
        transcription: "ðə ˈprəʊgræm z ˈrɪəli ˈbɔːrɪŋ",
        translation: "The program is really boring",
      },
      {
        id: 31,
        expression: "Видео такие скучные",
        transcription: "ðə ˈvɪdɪəʊz ə səʊ ˈbɔːrɪŋ",
        translation: "The videos are so boring",
      },
      {
        id: 32,
        expression: "Мужчины очень толстые",
        transcription: "ðə mɛn ə ˈvɛri fæt",
        translation: "The men are very fat",
      },
      {
        id: 33,
        expression: "Это очень интересная идея",
        transcription: "ɪts ə ˈvɛri ˈɪntrɪstɪŋ aɪˈdɪə",
        translation: "It's a very interesting idea",
      },
      {
        id: 34,
        expression: "Уроки очень полезны",
        transcription: "ðə ˈlɛsnz ə ˈvɛri ˈjuːsfʊl",
        translation: "The lessons are very useful",
      },
      {
        id: 35,
        expression: "Место такое красивое",
        transcription: "ðə pleɪs ɪz səʊ ˈlʌvli",
        translation: "The place is so lovely",
      },
      {
        id: 36,
        expression: "Результат отличный",
        transcription: "ðə rɪˈzʌlt s ˈɛksələnt",
        translation: "The result is excellent",
      },
      {
        id: 37,
        expression: "Ребенок в школе",
        transcription: "ðə ʧaɪld z ət skuːl",
        translation: "The child is at school",
      },
      {
        id: 38,
        expression: "Еда очень вкусная",
        transcription: "ðə fuːd z ˈvɛri ˈteɪsti",
        translation: "The food is very tasty",
      },
      {
        id: 39,
        expression: "Это дождливый день",
        transcription: "ɪts ə ˈreɪni deɪ",
        translation: "It's a rainy day",
      },
      {
        id: 40,
        expression: "Путь очень короткий",
        transcription: "ðə weɪ z ˈvɛri ʃɔːt",
        translation: "The way is very short",
      },
      {
        id: 41,
        expression: "День дождливый",
        transcription: "ðə deɪ z ˈreɪni",
        translation: "The day is rainy",
      },
      {
        id: 42,
        expression: "Видео такое неинформативное",
        transcription: "ðə ˈvɪdɪəʊ z səʊ ˌʌnɪnˈfɔːmətɪv",
        translation: "The video is so uninformative",
      },
      {
        id: 43,
        expression: "Это очень простой тест",
        transcription: "ɪts ə ˈvɛri ˈsɪmpl tɛst",
        translation: "It's a very simple test",
      },
      {
        id: 44,
        expression: "Цена слишком высокая",
        transcription: "ðə praɪs ɪz tuː haɪ",
        translation: "The price is too high",
      },
      {
        id: 45,
        expression: "Это очень красивая церковь",
        transcription: "ɪts ə ˈvɛri ˈbjuːtəfʊl ʧɜːʧ",
        translation: "It's a very beautiful church",
      },
      {
        id: 46,
        expression: "Женщины очень стройные",
        transcription: "ðə ˈwɪmɪn ə ˈvɛri slɪm",
        translation: "The women are very slim",
      },
      {
        id: 47,
        expression:
          "Я думаю, что настоящее счастье намного больше, чем просто деньги",
        transcription: "aɪ θɪŋk ðət rɪəl ˈhæpɪnɪs ɪz mʌʧ mɔː ðən ʤəst ˈmʌni",
        translation: "I think that real happiness is much more than just money",
      },
      {
        id: 48,
        expression: "Он очень амбициозный студент",
        transcription: "hiːz ə ˈvɛri æmˈbɪʃəs ˈstjuːdənt",
        translation: "He's a very ambitious student",
      },
      {
        id: 49,
        expression: "У тебя блестящие идеи как всегда",
        transcription: "jʊ həv ˈbrɪljənt aɪˈdɪəz əz ˈɔːlweɪz (ˈjuːʒʊəl)",
        translation: "You have brilliant ideas as always (usual)",
      },
      {
        id: 50,
        expression: "Многие люди говорят, что здоровье важнее денег",
        transcription: "ˈmɛni ˈpiːpl seɪ ðət hɛlθ s mɔːr ɪmˈpɔːtənt ðən ˈmʌni",
        translation: "Many people say that health is more important than money",
      },
      {
        id: 51,
        expression: "Он очень опытный учитель",
        transcription: "hiːz ə ˈvɛri ɪksˈpɪərɪənst ˈtiːʧə",
        translation: "He's a very experienced teacher",
      },
      {
        id: 52,
        expression: "Дорога пустая",
        transcription: "ðə rəʊd z ˈɛmpti",
        translation: "The road is empty",
      },
      {
        id: 53,
        expression: "Я вижу, ты полон энтузиазма",
        transcription: "aɪ siː jʊə fʊl əv ɪnˈθjuːzɪæzm",
        translation: "I see you're full of enthusiasm",
      },
      {
        id: 54,
        expression: "Это очень узкая улица",
        transcription: "ɪts ə ˈvɛri ˈnærəʊ striːt",
        translation: "It's a very narrow street",
      },
      {
        id: 55,
        expression: "Дети в кровати",
        transcription: "ðə ˈʧɪldrən ər ɪn bɛd",
        translation: "The children are in bed",
      },
      {
        id: 56,
        expression: "Земля круглая",
        transcription: "ði ɜːθ s raʊnd",
        translation: "The Earth is round",
      },
      {
        id: 57,
        expression: "Еда восхитительная",
        transcription: "ðə fuːd z dɪˈlɪʃəs",
        translation: "The food is delicious",
      },
      {
        id: 58,
        expression: "Она очень опытный врач",
        transcription: "ʃiːz ə ˈvɛri ɪksˈpɪərɪənst ˈdɒktə",
        translation: "She's a very experienced doctor",
      },
      {
        id: 59,
        expression: "Церковь очень красивая",
        transcription: "ðə ʧɜːʧ ɪz ˈbjuːtəfʊl",
        translation: "The church is beautiful",
      },
      {
        id: 60,
        expression: "Малыш в кровати",
        transcription: "ðə ˈbeɪbi z ɪn bɛd",
        translation: "The baby is in bed",
      },
      {
        id: 61,
        expression: "Малыш лежит в кровати",
        transcription: "ðə ˈbeɪbi laɪz ɪn bɛd",
        translation: "The baby lies in bed",
      },
      {
        id: 62,
        expression: "Этот малыш в кровати",
        transcription: "ðɪs ˈbeɪbi z ɪn bɛd",
        translation: "This baby is in bed",
      },
      {
        id: 63,
        expression: "Стакан пустой",
        transcription: "ðə glɑːs ɪz ˈɛmpti",
        translation: "The glass is empty",
      },
      {
        id: 64,
        expression: "Мой стакан пустой",
        transcription: "maɪ glɑːs ɪz ˈɛmpti",
        translation: "My glass is empty",
      },
      {
        id: 65,
        expression: "Этот стакан пустой",
        transcription: "ðɪs glɑːs ɪz ˈɛmpti",
        translation: "This glass is empty",
      },
      {
        id: 66,
        expression: "Я вижу, что ты очень опытный",
        transcription: "aɪ siː ðət jʊə ˈvɛri ɪksˈpɪərɪənst",
        translation: "I see that you're very experienced",
      },
      {
        id: 67,
        expression: "Я вижу, что они очень опытные",
        transcription: "aɪ siː ðət ðeə ˈvɛri ɪksˈpɪərɪənst",
        translation: "I see that they're very experienced",
      },
      {
        id: 68,
        expression: "Я знаю, что она очень опытная",
        transcription: "aɪ nəʊ ðət ʃiːz ˈvɛri ɪksˈpɪərɪənst",
        translation: "I know that she's very experienced",
      },
      {
        id: 69,
        expression: "Это неинформативное видео",
        transcription: "ɪts ən ˌʌnɪnˈfɔːmətɪv ˈvɪdɪəʊ",
        translation: "It's an uninformative video",
      },
      {
        id: 70,
        expression: "Это информативное видео",
        transcription: "ɪts ən ɪnˈfɔːmətɪv ˈvɪdɪəʊ",
        translation: "It's an informative video",
      },
      {
        id: 71,
        expression: "Это неинформативный урок",
        transcription: "ɪts ən ˌʌnɪnˈfɔːmətɪv ˈlɛsn",
        translation: "It's an uninformative lesson",
      },
      {
        id: 72,
        expression: "Это пустая бутылка",
        transcription: "ɪts ən ˈɛmpti ˈbɒtl",
        translation: "It's an empty bottle",
      },
      {
        id: 73,
        expression: "Это пустой стакан",
        transcription: "ɪts ən ˈɛmpti glɑːs",
        translation: "It's an empty glass",
      },
      {
        id: 74,
        expression: "Это полная бутылка",
        transcription: "ɪts ə fʊl ˈbɒtl",
        translation: "It's a full bottle",
      },
      {
        id: 75,
        expression: "Счастье - больше, чем деньги",
        transcription: "ˈhæpɪnɪs ɪz mɔː ðən ˈmʌni",
        translation: "Happiness is more than money",
      },
      {
        id: 76,
        expression: "Счастье - это действительно больше, чем деньги",
        transcription: "ˈhæpɪnɪs ɪz ˈrɪəli mɔː ðən ˈmʌni",
        translation: "Happiness is really more than money",
      },
      {
        id: 77,
        expression: "Работа полна ошибок",
        transcription: "ðə wɜːk s fʊl əv mɪsˈteɪks",
        translation: "The work is full of mistakes",
      },
      {
        id: 78,
        expression: "Эта работа полна ошибок",
        transcription: "ðɪs wɜːk s fʊl əv mɪsˈteɪks",
        translation: "This work is full of mistakes",
      },
      {
        id: 79,
        expression: "Эти документы полны ошибок",
        transcription: "ðiːz ˈdɒkjʊmənts ə fʊl əv mɪsˈteɪks",
        translation: "These documents are full of mistakes",
      },
      {
        id: 80,
        expression: "Те дети в кровати",
        transcription: "ðəʊz ˈʧɪldrən ər ɪn bɛd",
        translation: "Those children are in bed",
      },
      {
        id: 81,
        expression: "Он очень профессиональный врач",
        transcription: "hiːz ə ˈvɛri prəˈfɛʃənl ˈdɒktə",
        translation: "He's a very professional doctor",
      },
      {
        id: 82,
        expression: "Они очень профессиональные врачи",
        transcription: "ðeə ˈvɛri prəˈfɛʃənl ˈdɒktəz",
        translation: "They're very professional doctors",
      },
      {
        id: 83,
        expression: "Он очень хороший врач",
        transcription: "hiːz ə ˈvɛri gʊd ˈdɒktə",
        translation: "He's a very good doctor",
      },
      {
        id: 84,
        expression: "Любовь - это прекрасное чувство",
        transcription: "lʌv z ə ˈwʌndəfʊl ˈfiːlɪŋ",
        translation: "Love is a wonderful feeling",
      },
      {
        id: 85,
        expression: "Любовь - это хорошее чувство",
        transcription: "lʌv z ə gʊd ˈfiːlɪŋ",
        translation: "Love is a good feeling",
      },
      {
        id: 86,
        expression: "Любовь - это действительно прекрасное чувство",
        transcription: "lʌv z ə ˈrɪəli ˈwʌndəfʊl ˈfiːlɪŋ",
        translation: "Love is a really wonderful feeling",
      },
      {
        id: 87,
        expression: "Полиция там",
        transcription: "ðə pəˈliːs ə ðeə",
        translation: "The police are there",
      },
      {
        id: 88,
        expression: "Дети дома",
        transcription: "ðə ˈʧɪldrən ər ət həʊm",
        translation: "The children are at home",
      },
      {
        id: 89,
        expression: "Деньги больше, чем бумага",
        transcription: "ˈmʌni z mɔː ðən ˈpeɪpə",
        translation: "Money is more than paper",
      },
      {
        id: 90,
        expression: "Это широкая улица",
        transcription: "ɪts ə waɪd striːt",
        translation: "It's a wide street",
      },
      {
        id: 91,
        expression: "Я знаю, что компьютеры очень старые",
        transcription: "aɪ nəʊ ðət kəmˈpjuːtəz ə ˈvɛri əʊld",
        translation: "I know that computers are very old",
      },
      {
        id: 92,
        expression: "Дорога полна машин",
        transcription: "ðə rəʊd z fʊl əv kɑːz",
        translation: "The road is full of cars",
      },
      {
        id: 93,
        expression: "Улица очень узкая",
        transcription: "ðə striːt s ˈvɛri ˈnærəʊ",
        translation: "The street is very narrow",
      },
      {
        id: 94,
        expression: "Тест сложный для понимания",
        transcription: "ðə tɛst s ˈdɪfɪkəlt fər ˌʌndəˈstændɪŋ",
        translation: "The test is difficult for understanding",
      },
      {
        id: 95,
        expression: "Это чрезвычайно полезный урок",
        transcription: "ɪts ən ɪksˈtriːmli ˈjuːsfʊl ˈlɛsn",
        translation: "It's an extremely useful lesson",
      },
      {
        id: 96,
        expression: "Ответ очевиден",
        transcription: "ði ˈɑːnsə z ˈɒbvɪəs",
        translation: "The answer is obvious",
      },
      {
        id: 97,
        expression: "Я полон энергии",
        transcription: "aɪm fʊl əv ˈɛnəʤi",
        translation: "I'm full of energy",
      },
      {
        id: 98,
        expression: "Это старая машина",
        transcription: "ɪts ən əʊld kɑː",
        translation: "It's an old car",
      },
      {
        id: 99,
        expression: "Эта улица очень широкая",
        transcription: "ðɪs striːt s ˈvɛri waɪd",
        translation: "This street is very wide",
      },
      {
        id: 100,
        expression: "Они очень опытные работники",
        transcription: "ðeə ˈvɛri ɪksˈpɪərɪənst ˈwɜːkəz",
        translation: "They're very experienced workers",
      },
      {
        id: 101,
        expression: "Полиция здесь",
        transcription: "ðə pəˈliːs ə hɪə",
        translation: "The police are here",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Когда ставится the</h3>
<p>1. Он ставится перед существительными, вне зависимости от числа, если речь идет о чем-то конкретном:</p>
<p>Children like chocolate - Дети любят шоколад (имеются в виду вообще все дети).</p>
<p>The children like reading books - Дети любят читать книги (речь идет уже о конкретных детях).</p>
<p>The child is at school today - Ребенок сегодня в школе.</p>
<p>The children are at home now - Дети дома сейчас.</p>
<p>2. Определенный артикль не ставится, если перед ним есть указательное местоимение:</p>
<p>This boy is my friend - Этот мальчик - мой друг.</p>
<p>К устойчивым выражениям:</p>
<h3>many</h3>
<p>Это наречие используется с исчисляемыми существительными во множественном числе:</p>
<p>Many people like waking up early - Много людей любит вставать рано.</p>
</div>`,
    },
  ],
];
