export const wordsLesson25 = [
  [
    {
      id: 1,
      word: 'advice',
      transcription: 'ədˈvaɪs',
      translation: 'совет',
    },
    {
      id: 2,
      word: 'back',
      transcription: 'bæk',
      translation: 'назад',
    },
    {
      id: 3,
      word: 'breakfast',
      transcription: 'ˈbrɛkfəst',
      translation: 'завтрак',
    },
    {
      id: 4,
      word: 'bring',
      transcription: 'brɪŋ',
      translation: 'приносить',
    },
    {
      id: 5,
      word: 'dinner',
      transcription: 'ˈdɪnə',
      translation: 'ужин',
    },
    {
      id: 6,
      word: 'first',
      transcription: 'fɜːst',
      translation: 'первый',
    },
    {
      id: 7,
      word: 'follow',
      transcription: 'ˈfɒləʊ',
      translation: 'следовать',
    },
    {
      id: 8,
      word: 'great',
      transcription: 'greɪt',
      translation: 'огромный, большой',
    },
    {
      id: 9,
      word: 'lunch',
      transcription: 'lʌnʧ',
      translation: 'обед',
    },
    {
      id: 10,
      word: 'museum',
      transcription: 'mju(ː)ˈzɪəm',
      translation: 'музей',
    },
    {
      id: 11,
      word: 'pleasure',
      transcription: 'ˈplɛʒə',
      translation: 'удовольствие',
    },
    {
      id: 12,
      word: 'podcast',
      transcription: 'ˈpɒdkɑːst',
      translation: 'подкаст',
    },
    {
      id: 13,
      word: 'present',
      transcription: 'ˈprɛznt',
      translation: 'подарок',
    },
    {
      id: 14,
      word: 'project',
      transcription: 'ˈprɒʤɛkt',
      translation: 'проект',
    },
    {
      id: 15,
      word: 'recommendation',
      transcription: 'ˌrɛkəmɛnˈdeɪʃən',
      translation: 'рекомендация',
    },
    {
      id: 16,
      word: 'sandwich',
      transcription: 'ˈsænwɪʤ',
      translation: 'бутерброд',
    },
    {
      id: 17,
      word: 'second',
      transcription: 'ˈsɛkənd',
      translation: 'второй',
    },
    {
      id: 18,
      word: 'theatre',
      transcription: 'ˈθɪətə',
      translation: 'театр',
    },
    {
      id: 19,
      word: 'third',
      transcription: 'θɜːd',
      translation: 'третий',
    },
    {
      id: 20,
      word: 'tomorrow',
      transcription: 'təˈmɒrəʊ',
      translation: 'завтра',
    },
    {
      id: 21,
      word: 'will',
      transcription: 'wɪl',
      translation: 'будет, буду',
    },
  ],
];
