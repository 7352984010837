export const wordsLesson71 = [
  [
    {
      id: 1,
      word: 'air',
      transcription: 'eə',
      translation: 'воздух',
    },
    {
      id: 2,
      word: 'bowling',
      transcription: 'ˈbəʊlɪŋ',
      translation: 'боулинг',
    },
    {
      id: 3,
      word: 'breathe',
      transcription: 'briːð',
      translation: 'дышать',
    },
    {
      id: 4,
      word: 'calories',
      transcription: 'ˈkæləriz',
      translation: 'калории',
    },
    {
      id: 5,
      word: 'charity',
      transcription: 'ˈʧærɪti',
      translation: 'благотворительность',
    },
    {
      id: 6,
      word: 'draw',
      transcription: 'drɔː',
      translation: 'рисовать',
    },
    {
      id: 7,
      word: 'fond of',
      transcription: 'fɒnd ɒv',
      translation: 'любить, обожать',
    },
    {
      id: 8,
      word: 'golf',
      transcription: 'gɒlf',
      translation: 'гольф',
    },
    {
      id: 9,
      word: 'grandfather',
      transcription: 'ˈgrændˌfɑːðə',
      translation: 'дедушка',
    },
    {
      id: 10,
      word: 'grandmother',
      transcription: 'ˈgrænˌmʌðə',
      translation: 'бабушка',
    },
    {
      id: 11,
      word: 'judo',
      transcription: 'ˈʤuːdəʊ',
      translation: 'дзюдо',
    },
    {
      id: 12,
      word: 'karate',
      transcription: 'kəˈrɑːti',
      translation: 'карате',
    },
    {
      id: 13,
      word: 'keen on',
      transcription: 'kiːn ɒn',
      translation: 'увлекаться',
    },
    {
      id: 14,
      word: 'mind',
      transcription: 'maɪnd',
      translation: 'ум',
    },
    {
      id: 15,
      word: 'nothing',
      transcription: 'ˈnʌθɪŋ',
      translation: 'ничего',
    },
    {
      id: 16,
      word: 'particularly',
      transcription: 'pəˈtɪkjʊləli',
      translation: 'особенно',
    },
    {
      id: 17,
      word: 'soccer',
      transcription: 'ˈsɒkə',
      translation: 'футбол',
    },
    {
      id: 18,
      word: 'spare',
      transcription: 'speə',
      translation: 'свободный',
    },
  ],
];
