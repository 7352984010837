export const wordsLesson241 = [
  [
    {
      id: 1,
      word: 'moving',
      transcription: 'ˈmuːvɪŋ',
      translation: 'трогательный',
    },
    {
      id: 2,
      word: 'applaud',
      transcription: 'əˈplɔːd',
      translation: 'аплодировать',
    },
    {
      id: 3,
      word: 'atrocious',
      transcription: 'əˈtrəʊʃəs',
      translation: 'отвратительный',
    },
    {
      id: 4,
      word: 'band',
      transcription: 'bænd',
      translation: 'группа',
    },
    {
      id: 5,
      word: 'basket',
      transcription: 'ˈbɑːskɪt',
      translation: 'корзина',
    },
    {
      id: 6,
      word: 'bless',
      transcription: 'blɛs',
      translation: 'благословлять',
    },
    {
      id: 7,
      word: 'ceremony',
      transcription: 'ˈsɛrɪməni',
      translation: 'церемония',
    },
    {
      id: 8,
      word: 'Christian',
      transcription: 'ˈkrɪsʧən',
      translation: 'христианин, христианский',
    },
    {
      id: 9,
      word: 'Christianity',
      transcription: 'ˌkrɪstiˈænəti',
      translation: 'христианство',
    },
    {
      id: 10,
      word: 'cock',
      transcription: 'kɒk',
      translation: 'петух',
    },
    {
      id: 11,
      word: 'convert',
      transcription: 'ˈkɒnvɜːt',
      translation: 'конвертировать, преобразовывать',
    },
    {
      id: 12,
      word: 'cow',
      transcription: 'kaʊ',
      translation: 'корова',
    },
    {
      id: 13,
      word: 'dare',
      transcription: 'deə',
      translation: 'осмеливаться',
    },
    {
      id: 14,
      word: 'dinosaur',
      transcription: 'ˈdaɪnəʊsɔː',
      translation: 'динозавр',
    },
    {
      id: 15,
      word: 'duck',
      transcription: 'dʌk',
      translation: 'утка',
    },
    {
      id: 16,
      word: 'farmer',
      transcription: 'ˈfɑːmə',
      translation: 'фермер',
    },
    {
      id: 17,
      word: 'fifth',
      transcription: 'fɪfθ',
      translation: 'пятый',
    },
    {
      id: 18,
      word: 'fishing',
      transcription: 'ˈfɪʃɪŋ',
      translation: 'рыбалка',
    },
    {
      id: 19,
      word: 'goose (geese)',
      transcription: 'ɡuːs (ɡiːs)',
      translation: 'гусь (гуси)',
    },
    {
      id: 20,
      word: 'goat',
      transcription: 'ɡəʊt',
      translation: 'козел',
    },
    {
      id: 21,
      word: 'guide',
      transcription: 'ɡaɪd',
      translation: 'сопровождать, направлять, гид',
    },
    {
      id: 22,
      word: 'hen',
      transcription: 'hɛn',
      translation: 'курица',
    },
    {
      id: 23,
      word: 'humiliate',
      transcription: 'hjuːˈmɪlieɪt',
      translation: 'унижать',
    },
    {
      id: 24,
      word: 'idolize',
      transcription: 'ˈaɪdəlaɪz',
      translation: 'боготворить',
    },
    {
      id: 25,
      word: 'initially',
      transcription: 'ɪˈnɪʃəli',
      translation: 'первоначально',
    },
    {
      id: 26,
      word: 'insult',
      transcription: 'ˈɪnsʌlt',
      translation: 'оскорблять',
    },
    {
      id: 27,
      word: 'item',
      transcription: 'ˈaɪtəm',
      translation: 'пункт',
    },
    {
      id: 28,
      word: 'kilometre',
      transcription: 'ˈkɪləʊˌmiːtə',
      translation: 'километр',
    },
    {
      id: 29,
      word: 'madness',
      transcription: 'ˈmædnɪs',
      translation: 'безумие',
    },
    {
      id: 30,
      word: 'miraculously',
      transcription: 'mɪˈrækjʊləsli',
      translation: 'чудом, чудесным образом',
    },
    {
      id: 31,
      word: 'optimize',
      transcription: 'ˈɒptɪmaɪz',
      translation: 'оптимизировать',
    },
    {
      id: 32,
      word: 'percentage',
      transcription: 'pəˈsɛntɪʤ',
      translation: 'процент',
    },
    {
      id: 33,
      word: 'reflect',
      transcription: 'rɪˈflɛkt',
      translation: 'отражать',
    },
    {
      id: 34,
      word: 'rugby',
      transcription: 'ˈrʌɡbi',
      translation: 'регби',
    },
    {
      id: 35,
      word: 'sheep',
      transcription: 'ʃiːp',
      translation: 'овца, овцы',
    },
    {
      id: 36,
      word: 'truck',
      transcription: 'trʌk',
      translation: 'грузовик',
    },
    {
      id: 37,
      word: 'whatever',
      transcription: 'wɒtˈɛvə',
      translation: 'что бы ни',
    },
    {
      id: 38,
      word: 'whisper',
      transcription: 'ˈwɪspə',
      translation: 'шептать',
    },
  ],
];
