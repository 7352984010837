export const wordsLesson200 = [
  [
    {
      id: 1,
      word: 'disapprove',
      transcription: 'ˌdɪsəˈpruːv',
      translation: 'не одобрять',
    },
    {
      id: 2,
      word: 'discomfort',
      transcription: 'dɪsˈkʌmfət',
      translation: 'дискомфорт',
    },
    {
      id: 3,
      word: 'endless',
      transcription: 'ˈɛndlɪs',
      translation: 'бесконечный',
    },
    {
      id: 4,
      word: 'handwriting',
      transcription: 'ˈhændˌraɪtɪŋ',
      translation: 'почерк',
    },
    {
      id: 5,
      word: 'harmless',
      transcription: 'ˈhɑːmlɪs',
      translation: 'безвредный',
    },
    {
      id: 6,
      word: 'illegible',
      transcription: 'ɪˈlɛʤəbl',
      translation: 'неразборчивый',
    },
    {
      id: 7,
      word: 'impolite',
      transcription: 'ˌɪmpəˈlaɪt',
      translation: 'невежливый',
    },
    {
      id: 8,
      word: 'inaccurate',
      transcription: 'ɪnˈækjʊrɪt',
      translation: 'неточный',
    },
    {
      id: 9,
      word: 'indecisive',
      transcription: 'ɪndɪˈsaɪsɪv',
      translation: 'нерешительный',
   },
    {
      id: 10,
      word: 'indifferent',
      transcription: 'ɪnˈdɪfrəntˌ',
      translation: 'безразличный',
    },
    {
      id: 11,
      word: 'inefficient',
      transcription: 'ˌɪnɪˈfɪʃənt',
      translation: 'неэффективный',
    },
    {
      id: 12,
      word: 'invaluable',
      transcription: 'ɪnˈvæljʊəbl',
      translation: 'бесценный',
    },
    {
      id: 13,
      word: 'irreplaceable',
      transcription: 'ˌɪrɪˈpleɪsəbl',
      translation: 'незаменимый',
    },
    {
      id: 14,
      word: 'irresponsible',
      transcription: 'ˌɪrɪsˈpɒnsəbl',
      translation: 'безответственный',
    },
    {
      id: 15,
      word: 'misunderstand',
      transcription: 'ˌmɪsʌndəˈstænd',
      translation: 'неправильно понять',
    },
    {
      id: 16,
      word: 'misunderstanding',
      transcription: 'ˌmɪsʌndəˈstændɪŋ',
      translation: 'недопонимание',
    },
    {
      id: 17,
      word: 'signature',
      transcription: 'ˈsɪgnɪʧə',
      translation: 'подпись',
    },
    {
      id: 18,
      word: 'suffering',
      transcription: 'ˈsʌfərɪŋ',
      translation: 'страдание',
    },
    {
      id: 19,
      word: 'unaware',
      transcription: 'ˌʌnəˈweə',
      translation: 'не осведомлен',
    },
    {
      id: 20,
      word: 'unimaginable',
      transcription: 'ˌʌnɪˈmæʤɪnəbl',
      translation: 'невообразимый',
    },
    {
      id: 21,
      word: 'unwillingly',
      transcription: 'ʌnˈwɪlɪŋli',
      translation: 'не охотно',
    },
  ],
];
