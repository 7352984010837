export const contentLesson45 = [
  [
    {
      title: "Урок 45. 150 verbs. Part 1",
    },
    [
      {
        id: 1,
        expression: "Он действительно любит спорт",
        transcription: "hi ˈrɪəli lʌvz spɔːt",
        translation: "He really loves sport",
      },
      {
        id: 2,
        expression: "Она чувствует себя намного лучше",
        transcription: "ʃi fiːlz mʌʧ ˈbɛtə",
        translation: "She feels much better",
      },
      {
        id: 3,
        expression: "Он любит петь",
        transcription: "hi lʌvz ˈsɪŋɪŋ",
        translation: "He loves singing",
      },
      {
        id: 4,
        expression: "Он заплатил за себя",
        transcription: "hi peɪd fə hɪmˈsɛlf",
        translation: "He paid for himself",
      },
      {
        id: 5,
        expression: "Мне нужно послушать этот урок еще раз",
        transcription: "aɪ niːd tə ˈlɪsn tə ðɪs ˈlɛsn wʌn mɔː taɪm",
        translation: "I need to listen to this lesson one more time",
      },
      {
        id: 6,
        expression: "Наша встреча начнется скоро",
        transcription: "ˈaʊə ˈmiːtɪŋ wɪl stɑːt suːn",
        translation: "Our meeting will start soon",
      },
      {
        id: 7,
        expression: "Я достигну этой цели",
        transcription: "aɪl əˈʧiːv ðɪs eɪm",
        translation: "I'll achieve this aim",
      },
      {
        id: 8,
        expression: "Давай пойдем туда прямо сейчас!",
        transcription: "lɛts gəʊ ðeə raɪt naʊ",
        translation: "Let's go there right now!",
      },
      {
        id: 9,
        expression: "Он предпочитает работать из дома",
        transcription: "hi priˈfɜːz tə wɜːk frəm həʊm",
        translation: "He prefers to work from home",
      },
      {
        id: 10,
        expression: "Я проснулся очень рано",
        transcription: "aɪ wəʊk ʌp ˈvɛri ˈɜːli",
        translation: "I woke up very early",
      },
      {
        id: 11,
        expression: "Она часто слушает музыку",
        transcription: "ʃi ˈɒf(ə)n ˈlɪsnz tə ˈmjuːzɪk",
        translation: "She often listens to music",
      },
      {
        id: 12,
        expression: "Я заплатил за себя",
        transcription: "aɪ peɪd fə maɪˈsɛlf",
        translation: "I paid for myself",
      },
      {
        id: 13,
        expression: "Я нашел свои ключи там",
        transcription: "aɪ faʊnd maɪ kiːz ðeə",
        translation: "I found my keys there",
      },
      {
        id: 14,
        expression: "Пошли!",
        transcription: "lɛts gəʊ!",
        translation: "Let's go!",
      },
      {
        id: 15,
        expression: "Он взял эти деньги",
        transcription: "hi tʊk ðɪs ˈmʌni",
        translation: "He took this money",
      },
      {
        id: 16,
        expression: "Она заплатит за себя",
        transcription: "ʃiːl peɪ fə hɜːˈsɛlf",
        translation: "She'll pay for herself",
      },
      {
        id: 17,
        expression: "Эта встреча началась час назад",
        transcription: "ðɪs ˈmiːtɪŋ bɪˈgæn (ˈstɑːtɪd) ən ˈaʊər əˈgəʊ",
        translation: "This meeting began (started) an hour ago",
      },
      {
        id: 18,
        expression: "Где ты это нашел?",
        transcription: "weə dɪd jʊ faɪnd ɪt?",
        translation: "Where did you find it?",
      },
      {
        id: 19,
        expression: "Это кажется таким странным",
        transcription: "ɪt siːmz səʊ streɪnʤ",
        translation: "It seems so strange",
      },
      {
        id: 20,
        expression: "Они любили друг друга",
        transcription: "ðeɪ lʌvd iːʧ ˈʌðə",
        translation: "They loved each other",
      },
      {
        id: 21,
        expression: "Когда ты прилетишь назад?",
        transcription: "wɛn wɪl jʊ flaɪ bæk?",
        translation: "When will you fly back?",
      },
      {
        id: 22,
        expression: "Тебе нужно заплатить за себя",
        transcription: "jʊ niːd tə peɪ fə jɔːˈsɛlf",
        translation: "You need to pay for yourself",
      },
      {
        id: 23,
        expression: "Она любила его и он любил ее",
        transcription: "ʃi lʌvd ɪm ənd hi lʌvd hɜː",
        translation: "She loved him and he loved her",
      },
      {
        id: 24,
        expression: "Давай обсудим другую вещь!",
        transcription: "lɛts dɪsˈkʌs əˈnʌðə θɪŋ!",
        translation: "Let's discuss another thing!",
      },
      {
        id: 25,
        expression: "Она взяла своего сына с собой",
        transcription: "ʃi tʊk hə sʌn wɪð hɜː",
        translation: "She took her son with her",
      },
      {
        id: 26,
        expression: "Они производят игрушки",
        transcription: "ðeɪ ˈprɒdjuːs tɔɪz",
        translation: "They produce toys",
      },
      {
        id: 27,
        expression: "Я послушал эту песню",
        transcription: "aɪ ˈlɪsnd tə ðɪs sɒŋ",
        translation: "I listened to this song",
      },
      {
        id: 28,
        expression: "Она хочет учиться в другой стране",
        transcription: "ʃi wɒnts tə ˈstʌdi ɪn əˈnʌðə ˈkʌntri",
        translation: "She wants to study in another country",
      },
      {
        id: 29,
        expression: "Она чувствовала себя такой счастливой",
        transcription: "ʃi fɛlt səʊ ˈhæpi",
        translation: "She felt so happy",
      },
      {
        id: 30,
        expression: "Я люблю готовить",
        transcription: "aɪ lʌv ˈkʊkɪŋ",
        translation: "I love cooking",
      },
      {
        id: 31,
        expression: "Давай поговорим об этом позже!",
        transcription: "lɛts tɔːk əˈbaʊt ɪt ˈleɪtə!",
        translation: "Let's talk about it later!",
      },
      {
        id: 32,
        expression: "Она любит танцевать",
        transcription: "ʃi lʌvz ˈdɑːnsɪŋ",
        translation: "She loves dancing",
      },
      {
        id: 33,
        expression: "Я действительно люблю иностранные языки",
        transcription: "aɪ ˈrɪəli lʌv ˈfɒrɪn ˈlæŋgwɪʤɪz",
        translation: "I really love foreign languages",
      },
      {
        id: 34,
        expression: "Когда ты обычно просыпаешься?",
        transcription: "wɛn dʊ jʊ ˈjuːʒʊəli weɪk ʌp?",
        translation: "When do you usually wake up?",
      },
      {
        id: 35,
        expression: "Он любил ее, и она любила его",
        transcription: "hi lʌvd hər ənd ʃi lʌvd hɪm",
        translation: "He loved her and she loved him",
      },
      {
        id: 36,
        expression: "Что они будут производить?",
        transcription: "wɒt wɪl ðeɪ ˈprɒdjuːs?",
        translation: "What will they produce?",
      },
      {
        id: 37,
        expression: "Он действительно ненавидел это",
        transcription: "hi ˈrɪəli ˈheɪtɪd ɪt",
        translation: "He really hated it",
      },
      {
        id: 38,
        expression: "Он так сильно любил ее",
        transcription: "hi lʌvd hə səʊ mʌʧ",
        translation: "He loved her so much",
      },
      {
        id: 39,
        expression: "Я послушаю этот подкаст еще раз",
        transcription: "aɪl ˈlɪsn tə ðɪs ˈpɒdkɑːst wʌn mɔː taɪm",
        translation: "I'll listen to this podcast one more time",
      },
      {
        id: 40,
        expression: "Я ненавижу эту музыку",
        transcription: "aɪ heɪt ðɪs ˈmjuːzɪk",
        translation: "I hate this music",
      },
      {
        id: 41,
        expression: "Когда ты будешь гулять в парке в следующий раз?",
        transcription: "wɛn wɪl jʊ wɔːk ɪn pɑːk nɛkst taɪm?",
        translation: "When will you walk in park next time?",
      },
      {
        id: 42,
        expression: "Что он имеет в виду?",
        transcription: "wɒt dəz hi miːn?",
        translation: "What does he mean?",
      },
      {
        id: 43,
        expression: "Он хочет покинуть этот город",
        transcription: "hi wɒnts tə liːv ðɪs ˈsɪti",
        translation: "He wants to leave this city",
      },
      {
        id: 44,
        expression: "Я забыл запереть дверь",
        transcription: "aɪ fəˈgɒt tə lɒk ðə dɔː",
        translation: "I forgot to lock the door",
      },
      {
        id: 45,
        expression: "Что он хотел сказать?",
        transcription: "wɒt dɪd hi wɒnt tə seɪ?",
        translation: "What did he want to say?",
      },
      {
        id: 46,
        expression: "Он полетел в Соединенные Штаты",
        transcription: "hi fluː tə ðə jʊˈnaɪtɪd steɪts",
        translation: "He flew to the United States",
      },
      {
        id: 47,
        expression: "Какие ошибки ты сделал?",
        transcription: "wɒt mɪsˈteɪks dɪd jʊ meɪk?",
        translation: "What mistakes did you make?",
      },
      {
        id: 48,
        expression: "Это означало две вещи",
        transcription: "ɪt mɛnt tuː θɪŋz",
        translation: "It meant two things",
      },
      {
        id: 49,
        expression: "Она согласилась поехать туда вместе",
        transcription: "ʃi əˈgriːd tə gəʊ ðeə təˈgɛðə",
        translation: "She agreed to go there together",
      },
      {
        id: 50,
        expression: "Я знаю, что я достигну своей цели рано или поздно",
        transcription: "aɪ nəʊ aɪl əˈʧiːv maɪ eɪm ˈsuːnər ɔː ˈleɪtə",
        translation: "I know I'll achieve my aim sooner or later",
      },
      {
        id: 51,
        expression: "Он предпочитает есть домашнюю еду",
        transcription: "hi priˈfɜːz tʊ iːt ˈhəʊmˈmeɪd fuːd",
        translation: "He prefers to eat homemade food",
      },
      {
        id: 52,
        expression: "Что ты хочешь делать прямо сейчас?",
        transcription: "wɒt dʊ jʊ wɒnt tə dʊ raɪt naʊ?",
        translation: "What do you want to do right now?",
      },
      {
        id: 53,
        expression: "Мы согласились сделать это снова",
        transcription: "wi əˈgriːd tə dʊ ɪt əˈgɛn",
        translation: "We agreed to do it again",
      },
      {
        id: 54,
        expression: "Я хочу полететь в Америку",
        transcription: "aɪ wɒnt tə flaɪ tʊ əˈmɛrɪkə",
        translation: "I want to fly to America",
      },
      {
        id: 55,
        expression: "Я нашел деньги на улице",
        transcription: "aɪ faʊnd ˈmʌni ɪn ðə striːt",
        translation: "I found money in the street",
      },
      {
        id: 56,
        expression: "Он запер дверь",
        transcription: "hi lɒkt ðə dɔː",
        translation: "He locked the door",
      },
      {
        id: 57,
        expression: "Он открыл дверь",
        transcription: "hi ˈəʊpənd ðə dɔː",
        translation: "He opened the door",
      },
      {
        id: 58,
        expression: "Я гулял в парке каждые выходные",
        transcription: "aɪ wɔːkt ɪn ðə pɑːk ˈɛvri ˈwiːkˈɛnd",
        translation: "I walked in the park every weekend",
      },
      {
        id: 59,
        expression: "Она гуляет в парке каждый день",
        transcription: "ʃi wɔːks ɪn ðə pɑːk ˈɛvri deɪ",
        translation: "She walks in the park every day",
      },
      {
        id: 60,
        expression: "Мы гуляем в парке каждое утро",
        transcription: "wi wɔːk ɪn ðə pɑːk ˈɛvri ˈmɔːnɪŋ",
        translation: "We walk in the park every morning",
      },
      {
        id: 61,
        expression:
          "Она не делает ошибок, потому что у нее достаточно практики",
        transcription: "ʃi dʌznt meɪk mɪsˈteɪks bɪˈkəz ʃi həz ɪˈnʌf ˈpræktɪs",
        translation:
          "She doesn't make mistakes because she has enough practice",
      },
      {
        id: 62,
        expression:
          "Они не делают ошибок, потому что у них достаточно практики",
        transcription: "ðeɪ dəʊnt meɪk mɪsˈteɪks bɪˈkəz ðeɪ həv ɪˈnʌf ˈpræktɪs",
        translation:
          "They don't make mistakes because they have enough practice",
      },
      {
        id: 63,
        expression:
          "Он не делает ошибок, потому что у него достаточно практики",
        transcription: "hi dʌznt meɪk mɪsˈteɪks bɪˈkəz hi həz ɪˈnʌf ˈpræktɪs",
        translation: "He doesn't make mistakes because he has enough practice",
      },
      {
        id: 64,
        expression: "Ты будешь запирать дверь?",
        transcription: "wɪl jʊ lɒk ðə dɔː?",
        translation: "Will you lock the door?",
      },
      {
        id: 65,
        expression: "Ты будешь запирать эту дверь?",
        transcription: "wɪl jʊ lɒk ðɪs dɔː?",
        translation: "Will you lock this door?",
      },
      {
        id: 66,
        expression: "Я думаю, он согласится сделать это",
        transcription: "aɪ θɪŋk hiːl əˈgriː tə dʊ ɪt",
        translation: "I think he'll agree to do it",
      },
      {
        id: 67,
        expression: "Она думает, он согласится сделать это",
        transcription: "ʃi θɪŋks hiːl əˈgriː tə dʊ ɪt",
        translation: "She thinks he'll agree to do it",
      },
      {
        id: 68,
        expression: "Я думаю, она не согласиться сделать это",
        transcription: "aɪ θɪŋk ʃi wəʊnt əˈgriː tə dʊ ɪt",
        translation: "I think she won't agree to do it",
      },
      {
        id: 69,
        expression: "Я на это надеюсь",
        transcription: "aɪ həʊp səʊ",
        translation: "I hope so",
      },
      {
        id: 70,
        expression: "Она на это надеется",
        transcription: "ʃi həʊps səʊ",
        translation: "She hopes so",
      },
      {
        id: 71,
        expression: "Я надеюсь, что это так",
        transcription: "aɪ həʊp ðət ɪts səʊ",
        translation: "I hope that it's so",
      },
      {
        id: 72,
        expression: "Она полетит в Лондон на следующей неделе",
        transcription: "ʃiːl flaɪ tə ˈlʌndən nɛkst wiːk",
        translation: "She'll fly to London next week",
      },
      {
        id: 73,
        expression: "Он полетит в Москву в следующем месяце",
        transcription: "hiːl flaɪ tə ˈmɒskəʊ nɛkst mʌnθ",
        translation: "He'll fly to Moscow next month",
      },
      {
        id: 74,
        expression: "Я полечу в Германию на следующей неделе",
        transcription: "aɪl flaɪ tə ˈʤɜːməni nɛkst wiːk",
        translation: "I'll fly to Germany next week",
      },
      {
        id: 75,
        expression: "Она перепрыгнула через веревку",
        transcription: "ʃi ʤʌmpt ˈəʊvə ðə rəʊp",
        translation: "She jumped over the rope",
      },
      {
        id: 76,
        expression: "Она перепрыгнула через эту веревку",
        transcription: "ʃi ʤʌmpt ˈəʊvə ðɪs rəʊp",
        translation: "She jumped over this rope",
      },
      {
        id: 77,
        expression: "Она оставила его сумку дома",
        transcription: "ʃi lɛft ɪz bæg ət həʊm",
        translation: "She left his bag at home",
      },
      {
        id: 78,
        expression: "Она оставила мою сумку там",
        transcription: "ʃi lɛft maɪ bæg ðeə",
        translation: "She left my bag there",
      },
      {
        id: 79,
        expression: "Тот мужчина перепрыгнул через реку",
        transcription: "ðæt mæn ʤʌmpt ˈəʊvə ðə ˈrɪvə",
        translation: "That man jumped over the river",
      },
      {
        id: 80,
        expression: "Те мужчины перепрыгнули через реку",
        transcription: "ðəʊz mɛn ʤʌmpt ˈəʊvə ðə ˈrɪvə",
        translation: "Those men jumped over the river",
      },
      {
        id: 81,
        expression: "Что ты предпочитаешь делать в свое свободное время?",
        transcription: "wɒt dʊ jʊ priˈfɜː tə dʊ ɪn jə friː taɪm?",
        translation: "What do you prefer to do in your free time?",
      },
      {
        id: 82,
        expression: "Я верю, что я достигну этой цели",
        transcription: "aɪ bɪˈliːv ðət aɪl əˈʧiːv ðɪs eɪm",
        translation: "I believe that I'll achieve this aim",
      },
      {
        id: 83,
        expression: "Я надеялся, что это было действительно так",
        transcription: "aɪ həʊpt ðət ɪt wəz ˈrɪəli səʊ",
        translation: "I hoped that it was really so",
      },
      {
        id: 84,
        expression: "Я предпочитаю горячий чай",
        transcription: "aɪ priˈfɜː hɒt tiː",
        translation: "I prefer hot tea",
      },
      {
        id: 85,
        expression: "Он нашел это на улице",
        transcription: "hi faʊnd ɪt ɪn ðə striːt",
        translation: "He found it in the street",
      },
      {
        id: 86,
        expression: "Какие товары они производили?",
        transcription: "wɒt gʊdz dɪd ðeɪ ˈprɒdjuːs?",
        translation: "What goods did they produce?",
      },
      {
        id: 87,
        expression: "Он перепрыгнул через забор",
        transcription: "hi ʤʌmpt ˈəʊvə ðə fɛns",
        translation: "He jumped over the fence",
      },
      {
        id: 88,
        expression: "Я надеюсь, что это может произойти скоро",
        transcription: "aɪ həʊp ðət ɪt kən ˈhæpən suːn",
        translation: "I hope that it can happen soon",
      },
      {
        id: 89,
        expression: "Я думаю, я буду гулять в парке завтра",
        transcription: "aɪ θɪŋk aɪl wɔːk ɪn ðə pɑːk təˈmɒrəʊ",
        translation: "I think I'll walk in the park tomorrow",
      },
      {
        id: 90,
        expression: "Я хотел найти работу",
        transcription: "aɪ ˈwɒntɪd tə faɪnd ə ʤɒb",
        translation: "I wanted to find a job",
      },
      {
        id: 91,
        expression: "Он согласился подписать этот контракт",
        transcription: "hi əˈgriːd tə saɪn ðɪs ˈkɒntrækt",
        translation: "He agreed to sign this contract",
      },
      {
        id: 92,
        expression: "Он оставил свою сумку там",
        transcription: "hi lɛft ɪz bæg ðeə",
        translation: "He left his bag there",
      },
      {
        id: 93,
        expression: "Когда ты покинешь эту страну?",
        transcription: "wɛn wɪl jʊ liːv ðɪs ˈkʌntri?",
        translation: "When will you leave this country?",
      },
      {
        id: 94,
        expression: "Она не хотела покупать эту вещь в другом месте",
        transcription: "ʃi dɪdnt wɒnt tə baɪ ðɪs θɪŋ ɪn əˈnʌðə pleɪs",
        translation: "She didn't want to buy this thing in another place",
      },
      {
        id: 95,
        expression: "Она сделала ошибку",
        transcription: "ʃi meɪd ə mɪsˈteɪk",
        translation: "She made a mistake",
      },
    ],
    {
      theory: `<div class="theory-block">
<p>Устойчивое выражение:</p>
<h3>listen to</h3>
<p>- слушать что-то или кого-то</p>
<p>He likes listening to music - Ему нравится слушать музыку</p>
</div>`,
    },
  ],
];
