export const wordsLesson24 = [
  [
    {
      id: 1,
      word: 'almost',
      transcription: 'ˈɔːlməʊst',
      translation: 'почти',
    },
    {
      id: 2,
      word: 'begin',
      transcription: 'bɪˈgɪn',
      translation: 'начинать',
    },
    {
      id: 3,
      word: 'build',
      transcription: 'bɪld',
      translation: 'строить',
    },
    {
      id: 4,
      word: 'cheaply',
      transcription: 'ˈʧiːpli',
      translation: 'дешево',
    },
    {
      id: 5,
      word: 'cup',
      transcription: 'kʌp',
      translation: 'чашка',
    },
    {
      id: 6,
      word: 'easily',
      transcription: 'ˈiːzɪli',
      translation: 'легко',
    },
    {
      id: 7,
      word: 'expensively',
      transcription: 'ɪksˈpɛnsɪvli',
      translation: 'дорого',
    },
    {
      id: 8,
      word: 'faster',
      transcription: 'ˈfɑːstə',
      translation: 'быстрее',
    },
    {
      id: 9,
      word: 'glass',
      transcription: 'glɑːs',
      translation: 'стакан',
    },
    {
      id: 10,
      word: 'goods',
      transcription: 'gʊdz',
      translation: 'товар',
    },
    {
      id: 11,
      word: 'grammar',
      transcription: 'ˈgræmə',
      translation: 'грамматика',
    },
    {
      id: 12,
      word: 'hate',
      transcription: 'heɪt',
      translation: 'ненавидеть',
    },
    {
      id: 13,
      word: 'improve',
      transcription: 'ɪmˈpruːv',
      translation: 'улучшать',
    },
    {
      id: 14,
      word: 'kind',
      transcription: 'kaɪnd',
      translation: 'род, добрый',
    },
    {
      id: 15,
      word: 'listening',
      transcription: 'ˈlɪsnɪŋ',
      translation: 'слушание, аудирование',
    },
    {
      id: 16,
      word: 'morning',
      transcription: 'ˈmɔːnɪŋ',
      translation: 'утро',
    },
    {
      id: 17,
      word: 'offer',
      transcription: 'ˈɒfə',
      translation: 'предложение',
    },
    {
      id: 18,
      word: 'product',
      transcription: 'ˈprɒdʌkt',
      translation: 'продукт',
    },
    {
      id: 19,
      word: 'rarely',
      transcription: 'ˈreəli',
      translation: 'редко',
    },
    {
      id: 20,
      word: 'rule',
      transcription: 'ruːl',
      translation: 'правило',
    },
    {
      id: 21,
      word: 'run',
      transcription: 'rʌn',
      translation: 'бегать',
    },
    {
      id: 22,
      word: 'slide',
      transcription: 'slaɪd',
      translation: 'слайд',
    },
    {
      id: 23,
      word: 'soon',
      transcription: 'suːn',
      translation: 'скоро',
    },
    {
      id: 24,
      word: 'speaking',
      transcription: 'ˈspiːkɪŋ',
      translation: 'речь',
    },
    {
      id: 25,
      word: 'stand up',
      transcription: 'stænd ʌp',
      translation: 'встать',
    },
    {
      id: 26,
      word: 'stand',
      transcription: 'stænd',
      translation: 'стоять',
    },
    {
      id: 27,
      word: 'wait for',
      transcription: 'weɪt fɔː',
      translation: 'ждать',
    },
  ],
];
