export const wordsLesson216 = [
  [
    {
      id: 1,
      word: 'safety',
      transcription: 'ˈseɪfti',
      translation: 'безопасность',
    },
    {
      id: 2,
      word: 'adaptation',
      transcription: 'ˌædæpˈteɪʃᵊn',
      translation: 'адаптация',
    },
    {
      id: 3,
      word: 'amazement',
      transcription: 'əˈmeɪzmənt',
      translation: 'изумление',
    },
    {
      id: 4,
      word: 'analysis',
      transcription: 'əˈnæləsɪs',
      translation: 'анализ',
    },
    {
      id: 5,
      word: 'attach',
      transcription: 'əˈtæʧ',
      translation: 'прикреплять',
    },
    {
      id: 6,
      word: 'bureaucracy',
      transcription: 'bjʊəˈrɒkrəsi',
      translation: 'бюрократия',
    },
    {
      id: 7,
      word: 'cease',
      transcription: 'siːs',
      translation: 'прекратить',
    },
    {
      id: 8,
      word: 'compassion',
      transcription: 'kəmˈpæʃᵊn',
      translation: 'сострадание',
    },
    {
      id: 9,
      word: 'concerned',
      transcription: 'kənˈsɜːnd',
      translation: 'обеспокоен',
    },
    {
      id: 10,
      word: 'corruption',
      transcription: 'kəˈrʌpʃᵊn',
      translation: 'коррупция',
    },
    {
      id: 11,
      word: 'disobedience',
      transcription: 'ˌdɪsəˈbiːdjəns',
      translation: 'непослушание',
    },
    {
      id: 12,
      word: 'distinguish',
      transcription: 'dɪsˈtɪŋɡwɪʃ',
      translation: 'отличать',
    },
    {
      id: 13,
      word: 'eventually',
      transcription: 'ɪˈvɛnʧəli',
      translation: 'в конце концов',
    },
    {
      id: 14,
      word: 'inequality',
      transcription: 'ˌɪnɪˈkwɒləti',
      translation: 'неравенство',
    },
    {
      id: 15,
      word: 'marvel',
      transcription: 'ˈmɑːvəl',
      translation: 'восхищаться',
    },
    {
      id: 16,
      word: 'object',
      transcription: 'ˈɒbʤɪkt',
      translation: 'возражать',
    },
    {
      id: 17,
      word: 'objection',
      transcription: 'əbˈʤɛkʃᵊn',
      translation: 'возражение',
    },
    {
      id: 18,
      word: 'rapidity',
      transcription: 'rəˈpɪdɪti',
      translation: 'быстрота',
    },
    {
      id: 19,
      word: 'teaching',
      transcription: 'ˈtiːʧɪŋ',
      translation: 'преподавание',
    },
    {
      id: 20,
      word: 'unsurprisingly',
      transcription: 'ˌʌnsəˈpraɪzɪŋli',
      translation: 'неудивительно',
    },
    {
      id: 21,
      word: 'wisdom',
      transcription: 'ˈwɪzdəm',
      translation: 'мудрость',
    },
  ],
];
