export const contentLesson48 = [
  [
    {
      title: "Урок 48. 150 verbs. Part 4",
    },
    [
      {
        id: 1,
        expression: "Это может изменить твою жизнь",
        transcription: "ɪt kən ʧeɪnʤ jə laɪf",
        translation: "It can change your life",
      },
      {
        id: 2,
        expression: "Почему это происходит со мной?",
        transcription: "waɪ dəz ɪt ˈhæpən tə miː?",
        translation: "Why does it happen to me?",
      },
      {
        id: 3,
        expression: "Одна авария произошла вчера",
        transcription: "wʌn ˈæksɪdənt ˈhæpənd ˈjɛstədeɪ",
        translation: "One accident happened yesterday",
      },
      {
        id: 4,
        expression: "У него не будет этого шанса",
        transcription: "hi wəʊnt həv ðɪs ʧɑːns",
        translation: "He won't have this chance",
      },
      {
        id: 5,
        expression: "Я действительно поверил ему",
        transcription: "aɪ ˈrɪəli bɪˈliːvd hɪm",
        translation: "I really believed him",
      },
      {
        id: 6,
        expression: "Она не пьет пиво",
        transcription: "ʃi dʌznt drɪŋk bɪə",
        translation: "She doesn't drink beer",
      },
      {
        id: 7,
        expression: "Как он преподавал английский?",
        transcription: "haʊ dɪd hi tiːʧ ˈɪŋglɪʃ?",
        translation: "How did he teach English?",
      },
      {
        id: 8,
        expression: "Они не хотели его убивать",
        transcription: "ðeɪ dɪdnt wɒnt tə kɪl hɪm",
        translation: "They didn't want to kill him",
      },
      {
        id: 9,
        expression: "Посмотри на эту фотографию!",
        transcription: "lʊk ət ðɪs ˈfəʊtəʊ!",
        translation: "Look at this photo!",
      },
      {
        id: 10,
        expression: "У них так много вопросов",
        transcription: "ðeɪ həv səʊ ˈmɛni ˈkwɛsʧənz",
        translation: "They have so many questions",
      },
      {
        id: 11,
        expression: "Он рекомендовал несколько книг",
        transcription: "hi ˌrɛkəˈmɛndɪd səm bʊks",
        translation: "He recommended some books",
      },
      {
        id: 12,
        expression: "Мы продолжаем этот курс",
        transcription: "wi kənˈtɪnju(ː) ðɪs kɔːs",
        translation: "We continue this course",
      },
      {
        id: 13,
        expression: "Этот преступник не убивал их",
        transcription: "ðɪs ˈkrɪmɪnl dɪdnt kɪl ðɛm",
        translation: "This criminal didn't kill them",
      },
      {
        id: 14,
        expression: "У меня было мало свободного времени на это",
        transcription: "aɪ həd ˈlɪtl friː taɪm fər ɪt",
        translation: "I had little free time for it",
      },
      {
        id: 15,
        expression: "Не потеряй это!",
        transcription: "dəʊnt luːz ɪt!",
        translation: "Don't lose it!",
      },
      {
        id: 16,
        expression: "Тебе нужно подумать об этом снова",
        transcription: "jʊ niːd tə θɪŋk əˈbaʊt ɪt əˈgɛn",
        translation: "You need to think about it again",
      },
      {
        id: 17,
        expression: "Она профессионально преподает математику",
        transcription: "ʃi ˈtiːʧəz mæθs prəˈfɛʃnəli",
        translation: "She teachers maths professionally",
      },
      {
        id: 18,
        expression: "Она может потерять это очень легко",
        transcription: "ʃi kən luːz ɪt ˈvɛri ˈiːzɪli",
        translation: "She can lose it very easily",
      },
      {
        id: 19,
        expression: "Он преподаст нам урок",
        transcription: "hiːl tiːʧ əs ə ˈlɛsn",
        translation: "He'll teach us a lesson",
      },
      {
        id: 20,
        expression: "Тот человек посмотрел на нее вдруг",
        transcription: "ðæt mæn lʊkt ət hə ˈsʌdnli",
        translation: "That man looked at her suddenly",
      },
      {
        id: 21,
        expression: "У тебя будет шанс",
        transcription: "juːl həv ə ʧɑːns",
        translation: "You'll have a chance",
      },
      {
        id: 22,
        expression: "Когда ты встретил ее?",
        transcription: "wɛn dɪd jʊ miːt hɜː?",
        translation: "When did you meet her?",
      },
      {
        id: 23,
        expression: "Я не пил алкоголь",
        transcription: "aɪ dɪdnt drɪŋk ˈælkəhɒl",
        translation: "I didn't drink alcohol",
      },
      {
        id: 24,
        expression: "Она спела свою любимую песню",
        transcription: "ʃi sæŋ hə ˈfeɪvərɪt sɒŋ",
        translation: "She sang her favourite song",
      },
      {
        id: 25,
        expression: "Ты исправил все эти ошибки?",
        transcription: "dɪd jʊ kəˈrɛkt ɔːl ðiːz mɪsˈteɪks?",
        translation: "Did you correct all these mistakes?",
      },
      {
        id: 26,
        expression: "Где мы встретимся?",
        transcription: "weə wɪl wi miːt?",
        translation: "Where will we meet?",
      },
      {
        id: 27,
        expression: "Когда мы продолжим?",
        transcription: "wɛn wɪl wi kənˈtɪnju(ː)?",
        translation: "When will we continue?",
      },
      {
        id: 28,
        expression: "Наш учитель поправил меня",
        transcription: "ˈaʊə ˈtiːʧə kəˈrɛktɪd miː",
        translation: "Our teacher corrected me",
      },
      {
        id: 29,
        expression: "Я исправлю эту ошибку",
        transcription: "aɪl kəˈrɛkt ðɪs mɪsˈteɪk",
        translation: "I'll correct this mistake",
      },
      {
        id: 30,
        expression: "Мы встретились в кафе",
        transcription: "wi mɛt ɪn ə ˈkæfeɪ",
        translation: "We met in a cafe",
      },
      {
        id: 31,
        expression: "Они сравнили свои результаты",
        transcription: "ðeɪ kəmˈpeəd ðeə rɪˈzʌlts",
        translation: "They compared their results",
      },
      {
        id: 32,
        expression: "Эти вещи меняются очень быстро",
        transcription: "ðiːz θɪŋz ʧeɪnʤ ˈvɛri ˈkwɪkli",
        translation: "These things change very quickly",
      },
      {
        id: 33,
        expression: "Не переживай об этом так сильно!",
        transcription: "dəʊnt ˈwʌri əˈbaʊt ɪt səʊ mʌʧ!",
        translation: "Don't worry about it so much!",
      },
      {
        id: 34,
        expression: "Она так красиво поет",
        transcription: "ʃi sɪŋz səʊ ˈbjuːtəfʊli",
        translation: "She sings so beautifully",
      },
      {
        id: 35,
        expression: "Она потеряла это в лесу",
        transcription: "ʃi lɒst ɪt ɪn ðə ˈfɒrɪst",
        translation: "She lost it in the forest",
      },
      {
        id: 36,
        expression: "Я не рекомендую этот отель",
        transcription: "aɪ dəʊnt ˌriːˈkɒmɛnd ðɪs həʊˈtɛl",
        translation: "I don't recommend this hotel",
      },
      {
        id: 37,
        expression: "Не смейся!",
        transcription: "dəʊnt lɑːf!",
        translation: "Don't laugh!",
      },
      {
        id: 38,
        expression: "Это случилось с ним",
        transcription: "ɪt ˈhæpənd tə hɪm",
        translation: "It happened to him",
      },
      {
        id: 39,
        expression: "Он рекомендовал этот сайт?",
        transcription: "dɪd hi ˌriːˈkɒmɛnd ðɪs ˈwɛbˌsaɪt?",
        translation: "Did he recommend this website?",
      },
      {
        id: 40,
        expression: "Ему нужно продолжить",
        transcription: "hi niːdz tə kənˈtɪnju(ː)",
        translation: "He needs to continue",
      },
      {
        id: 41,
        expression: "У тебя были какие-либо проблемы с этим?",
        transcription: "dɪd jʊ həv ˈɛni ˈprɒbləmz wɪð ɪt?",
        translation: "Did you have any problems with it?",
      },
      {
        id: 42,
        expression: "Она сохранила этот секрет",
        transcription: "ʃi kɛpt ðɪs ˈsiːkrɪt",
        translation: "She kept this secret",
      },
      {
        id: 43,
        expression: "Я думаю, он изменит свою точку зрения позже",
        transcription: "aɪ θɪŋk hiːl ʧeɪnʤ ɪz pɔɪnt əv vjuː ˈleɪtə",
        translation: "I think he'll change his point of view later",
      },
      {
        id: 44,
        expression: "Я понял, что это было слишком дорого",
        transcription: "aɪ ˌʌndəˈstʊd ðət ɪt wəz tuː ɪksˈpɛnsɪv",
        translation: "I understood that it was too expensive",
      },
      {
        id: 45,
        expression: "Я хочу подвинуть этот стол",
        transcription: "aɪ wɒnt tə muːv ðɪs ˈteɪbl",
        translation: "I want to move this table",
      },
      {
        id: 46,
        expression:
          "Он пьет слишком много алкоголя, и это очень вредно для его здоровья",
        transcription:
          "hi drɪŋks tuː mʌʧ ˈælkəhɒl ənd ɪt ˈvɛri bæd (ˈhɑːmfʊl) fə hɪz ˈhɛlθi",
        translation:
          "He drinks too much alcohol and it very bad (harmful) for his healthy",
      },
      {
        id: 47,
        expression: "Он понял тот урок лучше меня",
        transcription: "hi ˌʌndəˈstʊd ðət ˈlɛsn ˈbɛtə ðən miː",
        translation: "He understood that lesson better than me",
      },
      {
        id: 48,
        expression: "Те мальчики смеялись над ней",
        transcription: "ðəʊz bɔɪz lɑːft ət hɜː",
        translation: "Those boys laughed at her",
      },
      {
        id: 49,
        expression: "Она не думает, что он сдержит свое обещание",
        transcription: "ʃi dʌznt θɪŋk ðət hiːl kiːp ɪz ˈprɒmɪs",
        translation: "She doesn't think that he'll keep his promise",
      },
      {
        id: 50,
        expression: "Я решил сделать это чуть позже",
        transcription: "aɪ dɪˈsaɪdɪd tə dʊ ɪt ə bɪt ˈleɪtə",
        translation: "I decided to do it a bit later",
      },
      {
        id: 51,
        expression: "Они двигаются очень быстро",
        transcription: "ðeɪ muːv ˈvɛri ˈkwɪkli",
        translation: "They move very quickly",
      },
      {
        id: 52,
        expression: "Я не хочу переезжать в другую страну",
        transcription: "aɪ dəʊnt wɒnt tə muːv tʊ əˈnʌðə ˈkʌntri",
        translation: "I don't want to move to another country",
      },
      {
        id: 53,
        expression: "Ты можешь сравнить эти два ресторана",
        transcription: "jʊ kən kəmˈpeə ðiːz tuː ˈrɛstrɒnts",
        translation: "You can compare these two restaurants",
      },
      {
        id: 54,
        expression: "Мой друг переехал в США",
        transcription: "maɪ frɛnd muːvd tə ðə juː-ɛs-eɪ",
        translation: "My friend moved to the USA",
      },
      {
        id: 55,
        expression: "Эта цена включает в себя эти услуги",
        transcription: "ðɪs praɪs ɪnˈkluːdz ðiːz ˈsɜːvɪsɪz",
        translation: "This price includes these services",
      },
      {
        id: 56,
        expression: "Мне нужно решить это сейчас",
        transcription: "aɪ niːd tə dɪˈsaɪd ɪt naʊ",
        translation: "I need to decide it now",
      },
      {
        id: 57,
        expression: "Ему нужно решить это сейчас",
        transcription: "hi niːdz tə dɪˈsaɪd ɪt naʊ",
        translation: "He needs to decide it now",
      },
      {
        id: 58,
        expression: "Мне нужно сказать это завтра",
        transcription: "aɪ niːd tə seɪ ɪt təˈmɒrəʊ",
        translation: "I need to say it tomorrow",
      },
      {
        id: 59,
        expression: "Я не смеюсь над этим",
        transcription: "aɪ dəʊnt lɑːf ət ɪt",
        translation: "I don't laugh at it",
      },
      {
        id: 60,
        expression: "Мы не смеемся над ним",
        transcription: "wi dəʊnt lɑːf ət hɪm",
        translation: "We don't laugh at him",
      },
      {
        id: 61,
        expression: "Она не смеется",
        transcription: "ʃi dʌznt lɑːf",
        translation: "She doesn't laugh",
      },
      {
        id: 62,
        expression:
          "Я понимаю, что мне нужно намного больше денег, если я хочу купить это",
        transcription:
          "aɪ ˌʌndəˈstænd ðət aɪ niːd mʌʧ mɔː ˈmʌni ɪf aɪ wɒnt tə baɪ ɪt",
        translation:
          "I understand that I need much more money if I want to buy it",
      },
      {
        id: 63,
        expression:
          "Он понимает, что ему нужно больше денег, если он хочет купить новую машину",
        transcription:
          "hi ˌʌndəˈstændz ðət hi niːdz mʌʧ mɔː ˈmʌni ɪf hi wɒnts tə baɪ ə njuː kɑː",
        translation:
          "He understands that he needs much more money if he wants to buy a new car",
      },
      {
        id: 64,
        expression: "Я видел, что они жили так счастливо",
        transcription: "aɪ sɔː ðət ðeɪ lɪvd səʊ ˈhæpɪli",
        translation: "I saw that they lived so happily",
      },
      {
        id: 65,
        expression: "Я понимаю, что мы живем так счастливо",
        transcription: "aɪ ˌʌndəˈstænd ðət wi lɪv səʊ ˈhæpɪli",
        translation: "I understand that we live so happily",
      },
      {
        id: 66,
        expression: "Я видел, что они жили так счастливо",
        transcription: "aɪ sɔː ðət ðeɪ lɪvd səʊ ˈhæpɪli",
        translation: "I saw that they lived so happily",
      },
      {
        id: 67,
        expression: "Они скоро переедут в США",
        transcription: "ðeɪl muːv tə juː-ɛs-eɪ suːn",
        translation: "They'll move to USA soon",
      },
      {
        id: 68,
        expression: "Она скоро переедет в Англию",
        transcription: "ʃiːl muːv tʊ ˈɪŋglənd suːn",
        translation: "She'll move to England soon",
      },
      {
        id: 69,
        expression: "Он не переедет в эту страну",
        transcription: "hi wəʊnt muːv tə ðɪs ˈkʌntri",
        translation: "He won't move to this country",
      },
      {
        id: 70,
        expression: "Он держит свои деньги в банке",
        transcription: "hi kiːps ɪz ˈmʌni ɪn ə bæŋk",
        translation: "He keeps his money in a bank",
      },
      {
        id: 71,
        expression: "Она держит свои деньги дома",
        transcription: "ʃi kiːps hə ˈmʌni ət həʊm",
        translation: "She keeps her money at home",
      },
      {
        id: 72,
        expression: "Он не держит свои деньги в банке",
        transcription: "hi dʌznt kiːp ɪz ˈmʌni ɪn ə bæŋk",
        translation: "He doesn't keep his money in a bank",
      },
      {
        id: 73,
        expression: "Ты можешь сравнить эти цены",
        transcription: "jʊ kən kəmˈpeə ðiːz ˈpraɪsɪz",
        translation: "You can compare these prices",
      },
      {
        id: 74,
        expression: "Мы можем сравнивать эти товары",
        transcription: "wi kən kəmˈpeə ðiːz gʊdz",
        translation: "We can compare these goods",
      },
      {
        id: 75,
        expression: "Он может сравнить эти цены",
        transcription: "hi kən kəmˈpeə ðiːz ˈpraɪsɪz",
        translation: "He can compare these prices",
      },
      {
        id: 76,
        expression: "Они включат это в счет",
        transcription: "ðeɪl ɪnˈkluːd ɪt ɪn ðə bɪl",
        translation: "They'll include it in the bill",
      },
      {
        id: 77,
        expression: "Мы включим это в счет",
        transcription: "wiːl ɪnˈkluːd ɪt ɪn ðə bɪl",
        translation: "We'll include it in the bill",
      },
      {
        id: 78,
        expression: "Он не включит это в твой счет",
        transcription: "hi wəʊnt ɪnˈkluːd ɪt ɪn jə bɪl",
        translation: "He won't include it in your bill",
      },
      {
        id: 79,
        expression: "Тебе нужно быть более осторожным, когда ты водишь",
        transcription: "jʊ niːd tə bi mɔː ˈkeəfʊl wɛn jʊ draɪv",
        translation: "You need to be more careful when you drive",
      },
      {
        id: 80,
        expression: "Нам нужно быть более осторожными, когда мы водим",
        transcription: "wi niːd tə bi mɔː ˈkeəfʊl wɛn wi draɪv",
        translation: "We need to be more careful when we drive",
      },
      {
        id: 81,
        expression: "Мне нужно быть более осторожным, когда я говорю",
        transcription: "aɪ niːd tə bi mɔː ˈkeəfʊl wɛn aɪ seɪ",
        translation: "I need to be more careful when I say",
      },
      {
        id: 82,
        expression: "Он учит нас английскому, и мне это действительно нравится",
        transcription: "hi ˈtiːʧɪz əs ˈɪŋglɪʃ ənd aɪ ˈrɪəli laɪk ɪt",
        translation: "He teaches us English and I really like it",
      },
      {
        id: 83,
        expression: "Она учит нас испанскому, и мне это действительно нравится",
        transcription: "ʃi ˈtiːʧɪz əs ˈspænɪʃ ənd aɪ ˈrɪəli laɪk ɪt",
        translation: "She teaches us Spanish and I really like it",
      },
      {
        id: 84,
        expression:
          "Ты учишь меня английскому, и мне это действительно нравится",
        transcription: "jʊ tiːʧ mi ˈɪŋglɪʃ ənd aɪ ˈrɪəli laɪk ɪt",
        translation: "You teach me English and I really like it",
      },
      {
        id: 85,
        expression: "Они включат это в цену",
        transcription: "ðeɪl ɪnˈkluːd ɪt ɪn ðə praɪs",
        translation: "They'll include it in the price",
      },
      {
        id: 86,
        expression: "Я хочу сравнить эти два приложения",
        transcription: "aɪ wɒnt tə kəmˈpeə ðiːz tuː ˌæplɪˈkeɪʃ(ə)nz",
        translation: "I want to compare these two applications",
      },
      {
        id: 87,
        expression: "Ему нужно быть осторожнее",
        transcription: "hi niːdz tə bi mɔː ˈkeəfʊl",
        translation: "He needs to be more careful",
      },
      {
        id: 88,
        expression: "Мы решили остаться дома",
        transcription: "wi dɪˈsaɪdɪd tə steɪ ət həʊm",
        translation: "We decided to stay at home",
      },
      {
        id: 89,
        expression: "Я действительно верю, что это возможно",
        transcription: "aɪ ˈrɪəli bɪˈliːv ðət ɪts ˈpɒsəbl",
        translation: "I really believe that it's possible",
      },
      {
        id: 90,
        expression: "Та цена включает в себя ужин и завтрак",
        transcription: "ðæt praɪs ɪnˈkluːdz ˈbrɛkfəst ənd ˈdɪnə",
        translation: "That price includes breakfast and dinner",
      },
      {
        id: 91,
        expression: "Она понимает это лучше, чем я",
        transcription: "ʃi ˌʌndəˈstændz ɪt ˈbɛtə ðən miː",
        translation: "She understands it better than me",
      },
      {
        id: 92,
        expression: "Не смейся над ним!",
        transcription: "dəʊnt lɑːf ət hɪm!",
        translation: "Don't laugh at him!",
      },
      {
        id: 93,
        expression: "Она изменила свое решение в конце",
        transcription: "ʃi ʧeɪnʤd hə dɪˈsɪʒən ɪn ði ɛnd",
        translation: "She changed her decision in the end",
      },
      {
        id: 94,
        expression: "Она хочет учиться в Соединенных Штатах",
        transcription: "ʃi wɒnts tə ˈstʌdi ɪn ðə jʊˈnaɪtɪd steɪts",
        translation: "She wants to study in the United States",
      },
      {
        id: 95,
        expression: "Ты жил в США?",
        transcription: "dɪd jʊ lɪv ɪn ðə juː-ɛs-eɪ?",
        translation: "Did you live in the USA?",
      },
      {
        id: 96,
        expression: "Она хочет жить в Соединенных Штатах?",
        transcription: "dəz ʃi wɒnt tə lɪv ɪn ðə jʊˈnaɪtɪd steɪts?",
        translation: "Does she want to live in the United States?",
      },
      {
        id: 97,
        expression: "Я посмотрел на экран",
        transcription: "aɪ lʊkt ət ðə skriːn",
        translation: "I looked at the screen",
      },
      {
        id: 98,
        expression: "Она очень быстро двигается, когда танцует",
        transcription: "ʃi muːvz ˈvɛri ˈkwɪkli wɛn ʃi ˈdɑːnsɪz",
        translation: "She moves very quickly when she dances",
      },
      {
        id: 99,
        expression: "Посмотри на экран!",
        transcription: "lʊk ət ðə skriːn!",
        translation: "Look at the screen!",
      },
      {
        id: 100,
        expression: "Она заплакала вдруг",
        transcription: "ʃi kraɪd ˈsʌdnli",
        translation: "She cried suddenly",
      },
      {
        id: 101,
        expression: "Он зарабатывал очень мало",
        transcription: "hi ɜːnd ˈvɛri ˈlɪtl",
        translation: "He earned very little",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Закрепление пройденного материала</h3>
</div>`,
    },
  ],
];
