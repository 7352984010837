export const wordsLesson295 = [
  [
    {
      id: 1,
      word: 'applied',
      transcription: 'əˈplaɪd',
      translation: 'прикладной',
    },
    {
      id: 2,
      word: 'availability',
      transcription: 'əˌveɪləˈbɪləti',
      translation: 'доступность',
    },
    {
      id: 3,
      word: 'brilliantly',
      transcription: 'ˈbrɪljəntli',
      translation: 'блестяще',
    },
    {
      id: 4,
      word: 'commerce',
      transcription: 'ˈkɒmɜːs',
      translation: 'коммерция',
    },
    {
      id: 5,
      word: 'completion',
      transcription: 'kəmˈpliːʃᵊn',
      translation: 'завершение',
    },
    {
      id: 6,
      word: 'countless',
      transcription: 'ˈkaʊntləs',
      translation: 'бесчисленный',
    },
    {
      id: 7,
      word: 'density',
      transcription: 'ˈdɛnsɪti',
      translation: 'плотность',
    },
    {
      id: 8,
      word: 'diversity',
      transcription: 'daɪˈvɜːsəti',
      translation: 'разнообразие',
    },
    {
      id: 9,
      word: 'dramatically',
      transcription: 'drəˈmætɪkᵊli',
      translation: 'резко, драматически',
    },
    {
      id: 10,
      word: 'encounter',
      transcription: 'ɪnˈkaʊntə',
      translation: 'сталкиваться',
    },
    {
      id: 11,
      word: 'equality',
      transcription: 'iˈkwɒləti',
      translation: 'равенство',
    },
    {
      id: 12,
      word: 'essence',
      transcription: 'ˈɛsns',
      translation: 'сущность',
    },
    {
      id: 13,
      word: 'excursion',
      transcription: 'ɪksˈkɜːʃᵊn',
      translation: 'экскурсия',
    },
    {
      id: 14,
      word: 'explicit',
      transcription: 'ɪksˈplɪsɪt',
      translation: 'четкий',
    },
    {
      id: 15,
      word: 'extinction',
      transcription: 'ɪksˈtɪŋkʃᵊn',
      translation: 'вымирание',
    },
    {
      id: 16,
      word: 'forum',
      transcription: 'ˈfɔːrəm',
      translation: 'форум',
    },
    {
      id: 17,
      word: 'healthily',
      transcription: 'ˈhɛlθɪli',
      translation: 'здорово, правильно',
    },
    {
      id: 18,
      word: 'misfortune',
      transcription: 'mɪsˈfɔːʧən',
      translation: 'беда',
    },
    {
      id: 19,
      word: 'PE',
      transcription: 'piː-iː',
      translation: 'физкультура',
    },
    {
      id: 20,
      word: 'pedagogy',
      transcription: 'ˈpɛdəɡɒʤi',
      translation: 'педагогика',
    },
    {
      id: 21,
      word: 'rehearsal',
      transcription: 'rɪˈhɜːsəl',
      translation: 'репетиция',
    },
    {
      id: 22,
      word: 'repetition',
      transcription: 'ˌrɛpɪˈtɪʃᵊn',
      translation: 'повторение',
    },
    {
      id: 23,
      word: 'sponsor',
      transcription: 'ˈspɒnsə',
      translation: 'спонсор',
    },
    {
      id: 24,
      word: 'sponsorship',
      transcription: 'ˈspɒnsəʃɪp',
      translation: 'спонсорство',
    },
    {
      id: 25,
      word: 'steadily',
      transcription: 'ˈstɛdɪli',
      translation: 'постоянство, неуклонно',
    },
    {
      id: 26,
      word: 'substantially',
      transcription: 'səbˈstænʃəli',
      translation: 'существенно',
    },
    {
      id: 27,
      word: 'subtle',
      transcription: 'ˈsʌtᵊl',
      translation: 'тонкий, едва различимый',
    },
    {
      id: 28,
      word: 'triumph',
      transcription: 'ˈtraɪəmf',
      translation: 'триумф',
    },
    {
      id: 29,
      word: 'understandably',
      transcription: 'ˌʌndəˈstændəbli',
      translation: 'понятно',
    },
    {
      id: 30,
      word: 'unfavourable',
      transcription: 'ʌnˈfeɪvərəbᵊl',
      translation: 'неприятный',
    },
    {
      id: 31,
      word: 'whatsoever',
      transcription: 'ˌwɒtsəʊˈɛvə',
      translation: 'вообще, совершенно',
    },
  ],
];
