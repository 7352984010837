export const wordsLesson279 = [
  [
    {
      id: 1,
      word: 'accepted',
      transcription: 'əkˈsɛptɪd',
      translation: 'приятный, признанный',
    },
    {
      id: 2,
      word: 'commercially',
      transcription: 'kəˈmɜːʃəli',
      translation: 'коммерческий',
    },
    {
      id: 3,
      word: 'comparatively',
      transcription: 'kəmˈpærətɪvli',
      translation: 'сравнительно',
    },
    {
      id: 4,
      word: 'contagious',
      transcription: 'kənˈteɪʤəs',
      translation: 'заразный',
    },
    {
      id: 5,
      word: 'debated',
      transcription: 'dɪˈbeɪtɪd',
      translation: 'обсуждаемый',
    },
    {
      id: 6,
      word: 'densely',
      transcription: 'ˈdɛnsli',
      translation: 'густо, плотно',
    },
    {
      id: 7,
      word: 'enormously',
      transcription: 'ɪˈnɔːməsli',
      translation: 'чрезвычайно',
    },
    {
      id: 8,
      word: 'fiercely',
      transcription: 'ˈfɪəsli',
      translation: 'жестко',
    },
    {
      id: 9,
      word: 'genetically',
      transcription: 'ʤɪˈnɛtɪkᵊli',
      translation: 'генетически',
    },
    {
      id: 10,
      word: 'hotly',
      transcription: 'ˈhɒtli',
      translation: 'горячо',
    },
    {
      id: 11,
      word: 'immensely',
      transcription: 'ɪˈmɛnsli',
      translation: 'чрезвычайно',
    },
    {
      id: 12,
      word: 'memorable',
      transcription: 'ˈmɛmᵊrəbᵊl',
      translation: 'памятный',
    },
    {
      id: 13,
      word: 'merely',
      transcription: 'ˈmɪəli',
      translation: 'просто',
    },
    {
      id: 14,
      word: 'modified',
      transcription: 'ˈmɒdɪfaɪd',
      translation: 'модифицированный',
    },
    {
      id: 15,
      word: 'outlook',
      transcription: 'ˈaʊtlʊk',
      translation: 'взгляд',
    },
    {
      id: 16,
      word: 'populated',
      transcription: 'ˈpɒpjəleɪtɪd',
      translation: 'населенный',
    },
    {
      id: 17,
      word: 'relaxed',
      transcription: 'rɪˈlækst',
      translation: 'расслабленный',
    },
    {
      id: 18,
      word: 'respected',
      transcription: 'rɪsˈpɛktɪd',
      translation: 'уважаемый',
    },
    {
      id: 19,
      word: 'severely',
      transcription: 'sɪˈvɪəli',
      translation: 'сильно',
    },
    {
      id: 20,
      word: 'straightforward',
      transcription: 'streɪtˈfɔːwəd',
      translation: 'прямой',
    },
    {
      id: 21,
      word: 'superficial',
      transcription: 'ˈsuːpəˈfɪʃəl',
      translation: 'поверхностный',
    },
    {
      id: 22,
      word: 'throughout',
      transcription: 'θruːˈaʊt',
      translation: 'во всем',
    },
    {
      id: 23,
      word: 'unconvincing',
      transcription: 'ˌʌnkənˈvɪnsɪŋ',
      translation: 'неубедительный',
    },
    {
      id: 24,
      word: 'viable',
      transcription: 'ˈvaɪəbᵊl',
      translation: 'жизнеспособный',
    },
  ],
];
