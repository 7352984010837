export const wordsLesson207 = [
  [
    {
      id: 1,
      word: 'brighten',
      transcription: 'ˈbraɪtn',
      translation: 'украшать',
    },
    {
      id: 2,
      word: 'committee',
      transcription: 'kəˈmɪti',
      translation: 'комитет',
    },
    {
      id: 3,
      word: 'height',
      transcription: 'haɪt',
      translation: 'рост, высота',
    },
    {
      id: 4,
      word: 'leaflet',
      transcription: 'ˈliːflɪt',
      translation: 'листовка',
    },
    {
      id: 5,
      word: 'profound',
      transcription: 'prəˈfaʊnd',
      translation: 'глубокий',
    },
    {
      id: 6,
      word: 'pullover',
      transcription: 'ˈpʊlˌəʊvə',
      translation: 'пуловер, свитер',
    },
    {
      id: 7,
      word: 'shoelaces',
      transcription: 'ˈʃuːleɪsɪz',
      translation: 'шнурки',
    },
    {
      id: 8,
      word: 'tough',
      transcription: 'tʌf',
      translation: 'жесткий',
    },
    {
      id: 9,
      word: 'bring about',
      transcription: 'brɪŋ əˈbaʊt',
      translation: 'вызывать',
    },
    {
      id: 10,
      word: 'take back',
      transcription: 'teɪk bæk',
      translation: 'забрать',
    },
    {
      id: 11,
      word: 'brighten up',
      transcription: 'ˈbraɪtn ʌp',
      translation: 'просветлеть, скрашивать',
    },
    {
      id: 12,
      word: 'come on',
      transcription: 'kʌm ɒn',
      translation: 'ну же',
    },
    {
      id: 13,
      word: 'do up',
      transcription: 'duː ʌp',
      translation: 'застегивать, ремонтировать',
    },
    {
      id: 14,
      word: 'go back',
      transcription: 'gəʊ bæk',
      translation: 'возвращаться',
    },
    {
      id: 15,
      word: 'come through',
      transcription: 'kʌm θruː',
      translation: 'проходить',
    },
    {
      id: 16,
      word: 'give off',
      transcription: 'gɪv ɒf',
      translation: 'выделять, издавать',
    },
    {
      id: 17,
      word: 'eat out',
      transcription: 'iːt aʊt',
      translation: 'питаться вне дома',
    },
    {
      id: 18,
      word: 'bring forward',
      transcription: 'brɪŋ ˈfɔːwəd',
      translation: 'выдвинуть',
    },
    {
      id: 19,
      word: 'go against',
      transcription: 'gəʊ əˈgɛnst',
      translation: 'идти против',
    },
    {
      id: 20,
      word: 'move on',
      transcription: 'muːv ɒn',
      translation: 'двигаться дальше',
    },
    {
      id: 21,
      word: 'take in',
      transcription: 'teɪk ɪn',
      translation: 'обманывать',
    },
    {
      id: 22,
      word: 'open up',
      transcription: 'ˈəʊpən ʌp',
      translation: 'раскрывать',
    },
    {
      id: 23,
      word: 'stand up for',
      transcription: 'stænd ʌp fɔː',
      translation: 'встать на защиту',
    },
    {
      id: 24,
      word: 'come to',
      transcription: 'kʌm tuː',
      translation: 'прийти к',
    },
    {
      id: 25,
      word: 'go under',
      transcription: 'gəʊ ˈʌndə',
      translation: 'разориться',
    },
  ],
];
