export const wordsLesson86 = [
  [
    {
      id: 1,
      word: 'ability',
      transcription: 'əˈbɪlɪti',
      translation: 'способность',
    },
    {
      id: 2,
      word: 'advise',
      transcription: 'ədˈvaɪz',
      translation: 'советовать',
    },
    {
      id: 3,
      word: 'album',
      transcription: 'ˈælbəm',
      translation: 'альбом',
    },
    {
      id: 4,
      word: 'although',
      transcription: 'ɔːlˈðəʊ',
      translation: 'хотя',
    },
    {
      id: 5,
      word: 'analytical',
      transcription: 'ˌænəˈlɪtɪkəl',
      translation: 'аналитический',
    },
    {
      id: 6,
      word: 'chapter',
      transcription: 'ˈʧæptə',
      translation: 'глава',
    },
    {
      id: 7,
      word: 'majority',
      transcription: 'məˈʤɒrɪti',
      translation: 'большинство',
    },
    {
      id: 8,
      word: 'midnight',
      transcription: 'ˈmɪdnaɪt',
      translation: 'полночь',
    },
    {
      id: 9,
      word: 'paint',
      transcription: 'peɪnt',
      translation: 'покрасить',
    },
    {
      id: 10,
      word: 'recovery',
      transcription: 'rɪˈkʌvəri',
      translation: 'восстановление, выздоровление',
    },
    {
      id: 11,
      word: 'release',
      transcription: 'rɪˈliːs',
      translation: 'выпускать, релиз',
    },
    {
      id: 12,
      word: 'rude',
      transcription: 'ruːd',
      translation: 'грубый',
    },
    {
      id: 13,
      word: 'rudely',
      transcription: 'ˈruːdli',
      translation: 'грубо',
    },
    {
      id: 14,
      word: 'speedy',
      transcription: 'ˈspiːdi',
      translation: 'быстрый',
    },
    {
      id: 15,
      word: 'subtitles',
      transcription: 'ˈsʌbˌtaɪtlz',
      translation: 'субтитры',
    },
    {
      id: 16,
      word: 'type',
      transcription: 'taɪp',
      translation: 'печатать, тип',
    },
    {
      id: 17,
      word: 'war',
      transcription: 'wɔː',
      translation: 'война',
    },
  ],
];
