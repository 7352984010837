export const contentLesson28 = [
  [
    {
      title: 'Урок 28. Future Simple. Special Question',
    },
    [
      {
        id: 1,
        expression: 'Что ты будешь делать?',
        transcription: 'wɒt wɪl jʊ duː?',
        translation: 'What will you do?',
      },
      {
        id: 2,
        expression: 'Почему это будет необходимо?',
        transcription: 'waɪ wɪl ɪt bi ˈnɛsɪsəri?',
        translation: 'Why will it be necessary?',
      },
      {
        id: 3,
        expression: 'Когда ты окончишь университет?',
        transcription: 'wɛn wɪl jʊ ˈgrædjʊət frəm ˌjuːnɪˈvɜːsɪti?',
        translation: 'When will you graduate from university?',
      },
      {
        id: 4,
        expression: 'Когда она доберется до того места?',
        transcription: 'wɛn wɪl ʃi gɛt tə ðət pleɪs?',
        translation: 'When will she get to that place?',
      },
      {
        id: 5,
        expression: 'Что ты будешь делать?',
        transcription: 'wɒt wɪl jʊ duː?',
        translation: 'What will you do?',
      },
      {
        id: 6,
        expression: 'Что будет на завтрак?',
        transcription: 'wɒt wɪl bi fə ˈbrɛkfəst?',
        translation: 'What will be for breakfast?',
      },
      {
        id: 7,
        expression: 'Это будет за деньги?',
        transcription: 'wɪl ɪt bi fə ˈmʌni?',
        translation: 'Will it be for money?',
      },
      {
        id: 8,
        expression: 'Кто будет с тобой?',
        transcription: 'huː wɪl bi wɪð juː?',
        translation: 'Who will be with you?',
      },
      {
        id: 9,
        expression: 'Как ты это сделаешь?',
        transcription: 'haʊ wɪl jʊ dʊ ɪt?',
        translation: 'How will you do it?',
      },
      {
        id: 10,
        expression: 'Когда ты будешь играть в футбол?',
        transcription: 'wɛn wɪl jʊ pleɪ ˈfʊtbɔːl?',
        translation: 'When will you play football?',
      },
      {
        id: 11,
        expression: 'Когда это будет возможно?',
        transcription: 'wɛn wɪl ɪt bi ˈpɒsəbl?',
        translation: 'When will it be possible?',
      },
      {
        id: 12,
        expression: 'Кто еще это поддержит?',
        transcription: 'huː ɛls wɪl səˈpɔːt ɪt?',
        translation: 'Who else will support it?',
      },
      {
        id: 13,
        expression: 'Почему это будет необходимо?',
        transcription: 'waɪ wɪl ɪt bi ˈnɛsɪsəri?',
        translation: 'Why will it be necessary?',
      },
      {
        id: 14,
        expression: 'Когда ты будешь готов?',
        transcription: 'wɛn wɪl jʊ bi ˈrɛdi?',
        translation: 'When will you be ready?',
      },
      {
        id: 15,
        expression: 'Когда ты будешь свободен?',
        transcription: 'wɛn wɪl jʊ bi friː?',
        translation: 'When will you be free?',
      },
      {
        id: 16,
        expression: 'Что станет дешевле?',
        transcription: 'wɒt wɪl bɪˈkʌm ˈʧiːpə?',
        translation: 'What will become cheaper?',
      },
      {
        id: 17,
        expression: 'Почему он откажется?',
        transcription: 'waɪ wɪl hi ˌriːˈfjuːz?',
        translation: 'Why will he refuse?',
      },
      {
        id: 18,
        expression: 'Что станет дороже?',
        transcription: 'wɒt wɪl bɪˈkʌm mɔːr ɪksˈpɛnsɪv?',
        translation: 'What will become more expensive?',
      },
      {
        id: 19,
        expression: 'Кто проиграет?',
        transcription: 'huː wɪl luːz?',
        translation: 'Who will lose?',
      },
      {
        id: 20,
        expression: 'Ты купишь компьютер?',
        transcription: 'wɪl jʊ baɪ ə kəmˈpjuːtə?',
        translation: 'Will you buy a computer?',
      },
      {
        id: 21,
        expression: 'Почему это будет так важно?',
        transcription: 'waɪ wɪl ɪt bi səʊ ɪmˈpɔːtənt?',
        translation: 'Why will it be so important?',
      },
      {
        id: 22,
        expression: 'Твои друзья пойдут туда с тобой?',
        transcription: 'wɪl jə frɛndz gəʊ ðeə wɪð juː?',
        translation: 'Will your friends go there with you?',
      },
      {
        id: 23,
        expression: 'Когда он пойдет в университет?',
        transcription: 'wɛn wɪl hi gəʊ tə ˌjuːnɪˈvɜːsɪti?',
        translation: 'When will he go to university?',
      },
      {
        id: 24,
        expression: 'Ты продашь свою старую машину?',
        transcription: 'wɪl jʊ sɛl jər əʊld kɑː?',
        translation: 'Will you sell your old car?',
      },
      {
        id: 25,
        expression: 'Твой друг будет там?',
        transcription: 'wɪl jə frɛnd bi ðeə?',
        translation: 'Will your friend be there?',
      },
      {
        id: 26,
        expression: 'Когда ты поедешь в Соединенные Штаты?',
        transcription: 'wɛn wɪl jʊ gəʊ tə ðə jʊˈnaɪtɪd steɪts?',
        translation: 'When will you go to the United States?',
      },
      {
        id: 27,
        expression: 'Почему это случится?',
        transcription: 'waɪ wɪl ɪt ˈhæpən?',
        translation: 'Why will it happen?',
      },
      {
        id: 28,
        expression: 'Кто будет работать с ним?',
        transcription: 'huː wɪl wɜːk wɪð hɪm?',
        translation: 'Who will work with him?',
      },
      {
        id: 29,
        expression: 'Что случится?',
        transcription: 'wɒt wɪl ˈhæpən?',
        translation: 'What will happen?',
      },
      {
        id: 30,
        expression: 'Кто будет работать с тобой?',
        transcription: 'huː wɪl wɜːk wɪð juː?',
        translation: 'Who will work with you?',
      },
      {
        id: 31,
        expression: 'Кто поддержит эту идею?',
        transcription: 'huː wɪl səˈpɔːt ðɪs aɪˈdɪə?',
        translation: 'Who will support this idea?',
      },
      {
        id: 32,
        expression: 'Когда мы встретимся?',
        transcription: 'wɛn wɪl wi miːt?',
        translation: 'When will we meet?',
      },
      {
        id: 33,
        expression: 'Где ты будешь завтра?',
        transcription: 'weə wɪl jʊ bi təˈmɒrəʊ?',
        translation: 'Where will you be tomorrow?',
      },
      {
        id: 34,
        expression: 'Что будет на обед?',
        transcription: 'wɒt wɪl bi fə lʌnʧ?',
        translation: 'What will be for lunch?',
      },
      {
        id: 35,
        expression: 'Что мы будем делать в этой ситуации?',
        transcription: 'wɒt wɪl wi dʊ ɪn ðɪs ˌsɪtjʊˈeɪʃən?',
        translation: 'What will we do in this situation?',
      },
      {
        id: 36,
        expression: 'Что будет на ужин?',
        transcription: 'wɒt wɪl bi fə ˈdɪnə?',
        translation: 'What will be for dinner?',
      },
      {
        id: 37,
        expression: 'Когда ты пойдешь в школу?',
        transcription: 'wɛn wɪl jʊ gəʊ tə skuːl?',
        translation: 'When will you go to school?',
      },
      {
        id: 38,
        expression: 'Кто победит?',
        transcription: 'huː wɪl wɪn?',
        translation: 'Who will win?',
      },
      {
        id: 39,
        expression: 'Что будет бесплатно?',
        transcription: 'wɒt wɪl bi friː?',
        translation: 'What will be free?',
      },
      {
        id: 40,
        expression: 'Когда ты вернешься?',
        transcription:
          'wɛn wɪl jʊ kʌm bæk? / wɛn wɪl jʊ bi bæk? / wɛn wɪl jʊ rɪˈtɜːn?',
        translation:
          'When will you come back? / When will you be back? / When will you return?',
      },
      {
        id: 41,
        expression: 'Что будет лучше?',
        transcription: 'wɒt wɪl bi ˈbɛtə?',
        translation: 'What will be better?',
      },
      {
        id: 42,
        expression: 'Кому ты позвонишь?',
        transcription: 'huː(ɛm) wɪl jʊ kɔːl?',
        translation: 'Who(m) will you call?',
      },
      {
        id: 43,
        expression: 'Что ты скачаешь?',
        transcription: 'wɒt wɪl jʊ ˌdaʊnˈləʊd?',
        translation: 'What will you download?',
      },
      {
        id: 44,
        expression: 'Когда она поедет в Канаду?',
        transcription: 'wɛn wɪl ʃi gəʊ tə ˈkænədə?',
        translation: 'When will she go to Canada?',
      },
      {
        id: 45,
        expression: 'Во сколько ты вернешься домой?',
        transcription: 'wɒt taɪm wɪl jʊ kʌm bæk həʊm?',
        translation: 'What time will you come back home?',
      },
      {
        id: 46,
        expression: 'Когда ты поедешь на море?',
        transcription: 'wɛn wɪl jʊ gəʊ tə ðə siː?',
        translation: 'When will you go to the sea?',
      },
      {
        id: 47,
        expression: 'Когда ты будешь готов поговорить об этом?',
        transcription: 'wɛn wɪl jʊ bi ˈrɛdi tə tɔːk əˈbaʊt ɪt?',
        translation: 'When will you be ready to talk about it?',
      },
      {
        id: 48,
        expression: 'Когда ты окончишь университет?',
        transcription: 'wɛn wɪl jʊ ˈgrædjʊət frəm ˌjuːnɪˈvɜːsɪti?',
        translation: 'When will you graduate from university?',
      },
      {
        id: 49,
        expression: 'Когда они поедут на море в следующий раз?',
        transcription: 'wɛn wɪl ðeɪ gəʊ tə ðə siː nɛkst taɪm?',
        translation: 'When will they go to the sea next time?',
      },
      {
        id: 50,
        expression: 'Какой ответ ты выберешь?',
        transcription: 'wɪʧ ˈɑːnsə wɪl jʊ ʧuːz?',
        translation: 'Which answer will you choose?',
      },
      {
        id: 51,
        expression: 'Когда ты доберешься до работы?',
        transcription: 'wɛn wɪl jʊ gɛt tə wɜːk?',
        translation: 'When will you get to work?',
      },
      {
        id: 52,
        expression: 'Когда ты пойдешь в отпуск?',
        transcription: 'wɛn wɪl jʊ gəʊ ɒn ˈhɒlədeɪ?',
        translation: 'When will you go on holiday?',
      },
      {
        id: 53,
        expression: 'Чью книгу ты возьмешь?',
        transcription: 'huːz bʊk wɪl jʊ teɪk?',
        translation: 'Whose book will you take?',
      },
      {
        id: 54,
        expression: 'Когда она поедет на море в следующий раз?',
        transcription: 'wɛn wɪl ʃi gəʊ tə ðə siː nɛkst taɪm?',
        translation: 'When will she go to the sea next time?',
      },
      {
        id: 55,
        expression: 'Когда ты будешь готов обсудить это со мной?',
        transcription: 'wɛn wɪl jʊ bi ˈrɛdi tə dɪsˈkʌs ɪt wɪð miː?',
        translation: 'When will you be ready to discuss it with me?',
      },
      {
        id: 56,
        expression: 'Сколько денег это будет стоить?',
        transcription: 'haʊ mʌʧ ˈmʌni wɪl ɪt kɒst?',
        translation: 'How much money will it cost?',
      },
      {
        id: 57,
        expression: 'Кто там будет присутствовать?',
        transcription: 'huː wɪl bi ˈprɛznt ðeə?',
        translation: 'Who will be present there?',
      },
      {
        id: 58,
        expression: 'Как долго это будет длиться?',
        transcription: 'haʊ lɒŋ wɪl ɪt lɑːst?',
        translation: 'How long will it last?',
      },
      {
        id: 59,
        expression: 'Когда они доберутся до школы?',
        transcription: 'wɛn wɪl ðeɪ gɛt tə skuːl?',
        translation: 'When will they get to school?',
      },
      {
        id: 60,
        expression: 'Когда ты пойдешь на пляж?',
        transcription: 'wɛn wɪl jʊ gəʊ tə ðə biːʧ?',
        translation: 'When will you go to the beach?',
      },
      {
        id: 61,
        expression: 'Когда ты поедешь в эту страну?',
        transcription: 'wɛn wɪl jʊ gəʊ tə ðɪs ˈkʌntri?',
        translation: 'When will you go to this country?',
      },
      {
        id: 62,
        expression: 'Почему он пойдет в парк?',
        transcription: 'waɪ wɪl hi gəʊ tə ðə pɑːk?',
        translation: 'Why will he go to the park?',
      },
      {
        id: 63,
        expression: 'Когда ты доберешься сюда?',
        transcription: 'wɛn wɪl jʊ gɛt hɪə?',
        translation: 'When will you get here?',
      },
      {
        id: 64,
        expression: 'Когда она доберется до этого места?',
        transcription: 'wɛn wɪl ʃi gɛt tə ðɪs pleɪs?',
        translation: 'When will she get to this place?',
      },
      {
        id: 65,
        expression: 'Когда мы доберемся туда?',
        transcription: 'wɛn wɪl wi gɛt ðeə?',
        translation: 'When will we get there?',
      },
      {
        id: 66,
        expression: 'Как часто ты будешь ходить в спортзал?',
        transcription: 'haʊ ˈɒf(ə)n wɪl jʊ gəʊ tə ðə ʤɪm?',
        translation: 'How often will you go to the gym?',
      },
      {
        id: 67,
        expression: 'Как часто она будет ходить в спортзал?',
        transcription: 'haʊ ˈɒf(ə)n wɪl ʃi gəʊ tə ðə ʤɪm?',
        translation: 'How often will she go to the gym?',
      },
      {
        id: 68,
        expression: 'Во сколько ты вернешься?',
        transcription: 'wɒt taɪm wɪl jʊ kʌm bæk?',
        translation: 'What time will you come back?',
      },
      {
        id: 69,
        expression: 'Когда он придет домой?',
        transcription: 'wɛn wɪl hi kʌm həʊm?',
        translation: 'When will he come home?',
      },
      {
        id: 70,
        expression: 'Во сколько она вернется домой?',
        transcription: 'wɒt taɪm wɪl ʃi kʌm bæk həʊm?',
        translation: 'What time will she come back home?',
      },
      {
        id: 71,
        expression: 'Те новости действительно удивят его?',
        transcription: 'wɪl ðət njuːz ˈrɪəli səˈpraɪz hɪm?',
        translation: 'Will that news really surprise him?',
      },
      {
        id: 72,
        expression: 'Эта информация действительно удивит ее?',
        transcription: 'wɪl ðɪs ˌɪnfəˈmeɪʃən ˈrɪəli səˈpraɪz hɜː?',
        translation: 'Will this information really surprise her?',
      },
      {
        id: 73,
        expression: 'Эти книги действительно удивят тебя?',
        transcription: 'wɪl ðiːz bʊks ˈrɪəli səˈpraɪz juː?',
        translation: 'Will these books really surprise you?',
      },
      {
        id: 74,
        expression: 'Как ты решишь эту проблему?',
        transcription: 'haʊ wɪl jʊ sɒlv ðɪs ˈprɒbləm?',
        translation: 'How will you solve this problem?',
      },
      {
        id: 75,
        expression: 'Как она решит эту проблему?',
        transcription: 'haʊ wɪl ʃi sɒlv ðɪs ˈprɒbləm?',
        translation: 'How will she solve this problem?',
      },
      {
        id: 76,
        expression: 'Как ты решишь эти проблемы?',
        transcription: 'haʊ wɪl jʊ sɒlv ðiːz ˈprɒbləmz?',
        translation: 'How will you solve these problems?',
      },
      {
        id: 77,
        expression: 'Сколько людей будет там?',
        transcription: 'haʊ ˈmɛni ˈpiːpl wɪl bi ðeə?',
        translation: 'How many people will be there?',
      },
      {
        id: 78,
        expression: 'Сколько детей будет там?',
        transcription: 'haʊ ˈmɛni ˈʧɪldrən wɪl bi ðeə?',
        translation: 'How many children will be there?',
      },
      {
        id: 79,
        expression: 'Какое время будет более удобным для тебя?',
        transcription: 'wɒt taɪm wɪl bi mɔː kənˈviːniənt fə juː?',
        translation: 'What time will be more convenient for you?',
      },
      {
        id: 80,
        expression: 'Какое время будет более удобным для него?',
        transcription: 'wɒt taɪm wɪl bi mɔː kənˈviːniənt fə hɪm?',
        translation: 'What time will be more convenient for him?',
      },
      {
        id: 81,
        expression: 'Какое время будет более полезным для нас?',
        transcription: 'wɒt taɪm wɪl bi mɔː ˈjuːsfʊl fər ʌs?',
        translation: 'What time will be more useful for us?',
      },
      {
        id: 82,
        expression: 'Сколько это будет стоить?',
        transcription: 'haʊ mʌʧ wɪl ɪt kɒst?',
        translation: 'How much will it cost?',
      },
      {
        id: 83,
        expression: 'Сколько денег они будут стоить?',
        transcription: 'haʊ mʌʧ ˈmʌni wɪl ðeɪ kɒst?',
        translation: 'How much money will they cost?',
      },
      {
        id: 84,
        expression: 'Когда он окончит университет?',
        transcription: 'wɛn wɪl hi ˈgrædjʊət frəm ˌjuːnɪˈvɜːsɪti?',
        translation: 'When will he graduate from university?',
      },
      {
        id: 85,
        expression: 'Когда она окончит университет?',
        transcription: 'wɛn wɪl ʃi ˈgrædjʊət frəm ˌjuːnɪˈvɜːsɪti?',
        translation: 'When will she graduate from university?',
      },
      {
        id: 86,
        expression: 'Когда ты окончишь этот университет?',
        transcription: 'wɛn wɪl jʊ ˈgrædjʊət frəm ðɪs ˌjuːnɪˈvɜːsɪti?',
        translation: 'When will you graduate from this university?',
      },
      {
        id: 87,
        expression: 'Кто будет отсутствовать?',
        transcription: 'huː wɪl bi ˈæbsənt?',
        translation: 'Who will be absent?',
      },
      {
        id: 88,
        expression: 'Когда ты мне перезвонишь?',
        transcription: 'wɛn wɪl jʊ kɔːl mi bæk?',
        translation: 'When will you call me back?',
      },
      {
        id: 89,
        expression: 'Эта новость удивит их?',
        transcription: 'wɪl ðɪs njuːz səˈpraɪz ðɛm?',
        translation: 'Will this news surprise them?',
      },
      {
        id: 90,
        expression: 'Когда ты доберешься домой?',
        transcription: 'wɛn wɪl jʊ gɛt həʊm?',
        translation: 'When will you get home?',
      },
      {
        id: 91,
        expression: 'В какое время ты будешь там?',
        transcription: 'wɒt taɪm wɪl jʊ bi ðeə?',
        translation: 'What time will you be there?',
      },
      {
        id: 92,
        expression: 'В какое время ты придешь на работу?',
        transcription: 'wɒt taɪm wɪl jʊ kʌm tə wɜːk?',
        translation: 'What time will you come to work?',
      },
      {
        id: 93,
        expression: 'Что будет за деньги?',
        transcription: 'wɒt wɪl bi fə ˈmʌni?',
        translation: 'What will be for money?',
      },
      {
        id: 94,
        expression: 'В какое время это будет?',
        transcription: 'wɒt taɪm wɪl ɪt biː?',
        translation: 'What time will it be?',
      },
      {
        id: 95,
        expression: 'Как регулярно ты будешь тренироваться?',
        transcription: 'haʊ ˈrɛgjʊləli wɪl jʊ treɪn?',
        translation: 'How regularly will you train?',
      },
      {
        id: 96,
        expression: 'Когда они решат ту проблему?',
        transcription: 'wɛn wɪl ðeɪ sɒlv ðɪs ˈprɒbləm?',
        translation: 'When will they solve this problem?',
      },
      {
        id: 97,
        expression: 'Когда ты будешь отсутствовать?',
        transcription: 'wɛn wɪl jʊ bi ˈæbsənt?',
        translation: 'When will you be absent?',
      },
      {
        id: 98,
        expression: 'Когда она доберется до того места?',
        transcription: 'wɛn wɪl ʃi gɛt tə ðət pleɪs?',
        translation: 'When will she get to that place?',
      },
      {
        id: 99,
        expression: 'Почему он будет отсутствовать?',
        transcription: 'waɪ wɪl hi bi ˈæbsənt?',
        translation: 'Why will he be absent?',
      },
      {
        id: 100,
        expression: 'Когда ты окончишь школу?',
        transcription: 'wɛn wɪl jʊ ˈfɪnɪʃ skuːl?',
        translation: 'When will you finish school?',
      },
      {
        id: 101,
        expression: 'Сколько денег ты на это потратишь?',
        transcription: 'haʊ mʌʧ ˈmʌni wɪl jʊ spɛnd ɒn ɪt?',
        translation: 'How much money will you spend on it?',
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Специальные вопросы в Future Simple</h3>
<p>Общая формула такая: </p>
<p><span>вопросительное слово + will + подлежащее</span></p>
<p>Например: </p>
<p>When will you come home? - Когда ты придешь домой?</p>
<p>Why will it be so hard? - Почему это будет так трудно?</p>
<p>Перейдем к устойчивым выражениям.</p>
<h3>graduate from</h3>
<p>- закончить (университет, ВУЗ)</p>
<p>When will graduate from university? - Когда ты закончишь университет?</p>
<h3>get to</h3>
<p>- добраться куда-то</p>
<p>When will you get to home? - Когда ты доберешься до дома?</p>
</div>`,
    },
  ],
];
