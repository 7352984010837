export const wordsLesson178 = [
  [
    {
      id: 1,
      word: 'educate',
      transcription: 'ˈɛʤu(ː)keɪt',
      translation: 'образовывать, воспитывать',
    },
    {
      id: 2,
      word: 'statistics',
      transcription: 'stəˈtɪstɪks',
      translation: 'статистика',
    },
  ],
];
