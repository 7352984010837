export const contentLesson182 = [
  [
    {
      title:
        'Урок 182. 450 Adjectives with different tenses and structures. Part 1',
    },
    [
      {
        id: 1,
        expression: 'Он очень лоялен по отношению к ним',
        transcription: 'hi z ˈvɛri ˈlɔɪəl tə ðɛm',
        translation: 'He is very loyal to them',
      },
      {
        id: 2,
        expression: 'Они ищут трудолюбивых и талантливых людей',
        transcription: 'ðeə ˈlʊkɪŋ fə ˈhɑːdˌwɜːkɪŋ ənd ˈtæləntɪd ˈpiːpl',
        translation: "They're looking for hard-working and talented people",
      },
      {
        id: 3,
        expression: 'Я совсем этим не обеспокоен',
        transcription: 'aɪm nɒt ˈwʌrid əˈbaʊt ɪt ət ɔːl',
        translation: "I'm not worried about it at all",
      },
      {
        id: 4,
        expression: 'Что за глупый вопрос?',
        transcription: 'wɒt ə ˈfuːlɪʃ ˈkwɛsʧən?',
        translation: 'What a foolish question?',
      },
      {
        id: 5,
        expression: 'У тебя такая мощная организация',
        transcription: 'jʊ həv sʌʧ ə ˈpaʊəfʊl ˌɔːgənaɪˈzeɪʃən',
        translation: 'You have such a powerful organisation',
      },
      {
        id: 6,
        expression: 'У нее очень мелодичный голос',
        transcription: 'ʃi həz ə ˈvɛri mɪˈlɒdɪk vɔɪs',
        translation: 'She has a very melodic voice',
      },
      {
        id: 7,
        expression: 'Это чрезвычайно важное решение',
        transcription: 'ɪts ən ɪksˈtriːmli ɪmˈpɔːtənt dɪˈsɪʒən',
        translation: "It's an extremely important decision",
      },
      {
        id: 8,
        expression: 'Он взял пустой стакан',
        transcription: 'hi tʊk ən ˈɛmpti glɑːs',
        translation: 'He took an empty glass',
      },
      {
        id: 9,
        expression: 'Улицы были пустые',
        transcription: 'ðə striːts wər ˈɛmpti',
        translation: 'The streets were empty',
      },
      {
        id: 10,
        expression: 'Ответ кажется правильным',
        transcription: 'ði ˈɑːnsə siːmz raɪt',
        translation: 'The answer seems right',
      },
      {
        id: 11,
        expression: 'Какая интересная идея',
        transcription: 'wɒt ən ˈɪntrɪstɪŋ aɪˈdɪə',
        translation: 'What an interesting idea',
      },
      {
        id: 12,
        expression: 'Ему нужен белый карандаш',
        transcription: 'hi niːdz ə waɪt ˈpɛnsl',
        translation: 'He needs a white pencil',
      },
      {
        id: 13,
        expression: 'Это очень мелодичный язык',
        transcription: 'ɪts ə ˈvɛri mɪˈlɒdɪk ˈlæŋgwɪʤ',
        translation: "It's a very melodic language",
      },
      {
        id: 14,
        expression: 'У него очень влиятельные связи',
        transcription: 'hi həz ˈvɛri ˈpaʊəfʊl (ˌɪnflʊˈɛnʃəl) kəˈnɛkʃənz',
        translation: 'He has very powerful (influential) connections',
      },
      {
        id: 15,
        expression: 'Это было очень отважное решение',
        transcription: 'ɪt wəz ə ˈvɛri kəˈreɪʤəs dɪˈsɪʒən',
        translation: 'It was a very courageous decision',
      },
      {
        id: 16,
        expression: 'Это общий принцип',
        transcription: 'ɪts ə ˈʤɛnərəl ˈprɪnsəpl',
        translation: "It's a general principle",
      },
      {
        id: 17,
        expression: 'Дом довольно старый',
        transcription: 'ðə haʊs ɪz kwaɪt əʊld',
        translation: 'The house is quite old',
      },
      {
        id: 18,
        expression: 'Это такая нестабильная ситуация',
        transcription: 'ɪts sʌʧ ən ʌnˈsteɪbl ˌsɪtjʊˈeɪʃən',
        translation: "It's such an unstable situation",
      },
      {
        id: 19,
        expression: 'Это была чрезвычайно важная встреча',
        transcription: 'ɪt wəz ən ɪksˈtriːmli ɪmˈpɔːtənt ˈmiːtɪŋ',
        translation: 'It was an extremely important meeting',
      },
      {
        id: 20,
        expression: 'Он более трудолюбивый человек',
        transcription: 'hiːz ə mɔː ˈhɑːdˌwɜːkɪŋ ˈpɜːsn',
        translation: "He's a more hard-working person",
      },
      {
        id: 21,
        expression: 'Это такая сложная проблема',
        transcription: 'ɪts sʌʧ ə ˈkɒmplɛks ˈprɒbləm',
        translation: "It's such a complex problem",
      },
      {
        id: 22,
        expression: 'Время такое драгоценное',
        transcription: 'taɪm z səʊ ˈprɛʃəs',
        translation: 'Time is so precious',
      },
      {
        id: 23,
        expression: 'Это неофициальный вопрос',
        transcription: 'ɪts ən ɪnˈfɔːml (ˌʌnəˈfɪʃəl) ˈkwɛsʧən',
        translation: "It's an informal (unofficial) question",
      },
      {
        id: 24,
        expression: 'Это международная организация',
        transcription: 'ɪts ən ˌɪntə(ː)ˈnæʃənl ˌɔːgənaɪˈzeɪʃən',
        translation: "It's an international organisation",
      },
      {
        id: 25,
        expression: 'Результат был довольно хорошим',
        transcription: 'ðə rɪˈzʌlt wəz ˈprɪti gʊd',
        translation: 'The result was pretty good',
      },
      {
        id: 26,
        expression: 'Это такая хорошая идея',
        transcription: 'ɪts sʌʧ ə gʊd aɪˈdɪə',
        translation: "It's such a good idea",
      },
      {
        id: 27,
        expression: 'Это такой прекрасный день',
        transcription: 'ɪts sʌʧ ə ˈlʌvli deɪ',
        translation: "It's such a lovely day",
      },
      {
        id: 28,
        expression: 'Он такой отважный человек',
        transcription: 'hiːz sʌʧ ə kəˈreɪʤəs ˈpɜːsn',
        translation: "He's such a courageous person",
      },
      {
        id: 29,
        expression: 'Это был исторический момент',
        transcription: 'ɪt wəz ə hɪsˈtɒrɪkəl ˈməʊmənt',
        translation: 'It was a historical moment',
      },
      {
        id: 30,
        expression: 'У нас неформальные отношения',
        transcription: 'wi həv ɪnˈfɔːml rɪˈleɪʃənz',
        translation: 'We have informal relations',
      },
      {
        id: 31,
        expression: 'Это драгоценный камень',
        transcription: 'ɪts ə ˈprɛʃəs stəʊn',
        translation: "It's a precious stone",
      },
      {
        id: 32,
        expression: 'В комнате очень сухо',
        transcription: 'ɪts ˈvɛri draɪ ɪn ðə ruːm',
        translation: "It's very dry in the room",
      },
      {
        id: 33,
        expression: 'Я в основном пользуюсь электронным калькулятором',
        transcription: 'aɪ ˈməʊstli juːz ən ɪlɛkˈtrɒnɪk ˈkælkjʊleɪtə',
        translation: 'I mostly use an electronic calculator',
      },
      {
        id: 34,
        expression: 'Чего ты боишься?',
        transcription: 'wɒt ə jʊ ˈfraɪtnd ɒv?',
        translation: 'What are you frightened of?',
      },
      {
        id: 35,
        expression: 'Там очень сухо',
        transcription: 'ɪts ˈvɛri draɪ ðeə',
        translation: "It's very dry there",
      },
      {
        id: 36,
        expression: 'Твой английский идеален',
        transcription: 'jər ˈɪŋglɪʃ ɪz ˈpɜːfɪkt',
        translation: 'Your English is perfect',
      },
      {
        id: 37,
        expression:
          'Они ожидают быстрого экономического роста в следующем году',
        transcription: 'ðeɪ ɪksˈpɛkt ˈræpɪd ˌiːkəˈnɒmɪk grəʊθ nɛkst jɪə',
        translation: 'They expect rapid economic growth next year',
      },
      {
        id: 38,
        expression: 'Я пришел туда позже, чем обычно',
        transcription: 'aɪ keɪm ðeə ˈleɪtə ðən ˈjuːʒʊəl',
        translation: 'I came there later than usual',
      },
      {
        id: 39,
        expression: 'Мой любимый цвет белый',
        transcription: 'maɪ ˈfeɪvərɪt ˈkʌlə z waɪt',
        translation: 'My favourite colour is white',
      },
      {
        id: 40,
        expression: 'Мы не можем отрицать этот исторический факт',
        transcription: 'wi kɑːnt dɪˈnaɪ ðɪs hɪsˈtɒrɪkəl fækt',
        translation: "We can't deny this historical fact",
      },
      {
        id: 41,
        expression: 'Начальник лоялен к своим сотрудникам',
        transcription: 'ðə bɒs ɪz ˈlɔɪəl tə ɪz ˌɛmplɔɪˈiːz',
        translation: 'The boss is loyal to his employees',
      },
      {
        id: 42,
        expression: 'Я в таком восторге от этого предложения',
        transcription: 'aɪm səʊ ɪkˈsaɪtɪd əˈbaʊt ðɪs ˈɒfə',
        translation: "I'm so excited about this offer",
      },
      {
        id: 43,
        expression: 'К счастью, я выбрал правильный ответ',
        transcription: 'ˈlʌkɪli, aɪ ʧəʊz ðə kəˈrɛkt ˈɑːnsə',
        translation: 'Luckily, I chose the correct answer',
      },
      {
        id: 44,
        expression: 'Эти меры достаточно эффективны',
        transcription: 'ðiːz ˈmɛʒəz ər ɪˈfɛktɪv ɪˈnʌf',
        translation: 'These measures are effective enough',
      },
      {
        id: 45,
        expression:
          'Эта группа ученых пытается использовать комплексный подход',
        transcription:
          'ðɪs gruːp əv ˈsaɪəntɪsts traɪz tə juːz ə ˈkɒmplɛks əˈprəʊʧ',
        translation: 'This group of scientists tries to use a complex approach',
      },
      {
        id: 46,
        expression: 'Я вижу, что вы всегда полны энергии',
        transcription: 'aɪ siː ðət jʊər ˈɔːlweɪz fʊl əv ˈɛnəʤi',
        translation: "I see that you're always full of energy",
      },
      {
        id: 47,
        expression: 'Тот метод кажется достаточно эффективным',
        transcription: 'ðæt ˈmɛθəd siːmz ɪˈfɛktɪv ɪˈnʌf',
        translation: 'That method seems effective enough',
      },
      {
        id: 48,
        expression: 'Я могу рассказать тебе всю историю, если ты не возражаешь',
        transcription: 'aɪ kən tɛl jʊ ðə həʊl ˈstɔːri ɪf jʊ dəʊnt maɪnd',
        translation: "I can tell you the whole story if you don't mind",
      },
      {
        id: 49,
        expression: 'Это идеальное решение этой проблемы',
        transcription: 'ɪts ə ˈpɜːfɪkt səˈluːʃən tə ðɪs ˈprɒbləm',
        translation: "It's a perfect solution to this problem",
      },
      {
        id: 50,
        expression: 'Мы жили в период политической стабильности',
        transcription: 'wi lɪvd ɪn ə ˈpɪərɪəd əv pəˈlɪtɪkəl stəˈbɪlɪti',
        translation: 'We lived in a period of political stability',
      },
      {
        id: 51,
        expression: 'Они решили не делать это по политическим причинам',
        transcription: 'ðeɪ dɪˈsaɪdɪd nɒt tə dʊ ɪt fə pəˈlɪtɪkəl ˈriːznz',
        translation: 'They decided not to do it for political reasons',
      },
      {
        id: 52,
        expression: 'Город известен своими музеями',
        transcription: 'ðə ˈsɪti z ˈfeɪməs fər ɪts mju(ː)ˈzɪəmz',
        translation: 'The city is famous for its museums',
      },
      {
        id: 53,
        expression: 'Она в таком восторге от этих новостей',
        transcription: 'ʃi fiːlz səʊ ɪkˈsaɪtɪd əˈbaʊt ðɪs njuːz',
        translation: 'She feels so excited about this news',
      },
      {
        id: 54,
        expression: 'Они использовали в основном искусственные материалы',
        transcription: 'ðeɪ juːzd ˈməʊstli ˌɑːtɪˈfɪʃ(ə)l məˈtɪərɪəlz',
        translation: 'They used mostly artificial materials',
      },
      {
        id: 55,
        expression: 'Это общее правило существует',
        transcription: 'ðɪs ˈʤɛnərəl ruːl ɪgˈzɪsts',
        translation: 'This general rule exists',
      },
      {
        id: 56,
        expression: 'Я вижу, что он всегда полон энтузиазма',
        transcription: 'aɪ siː ðət hiːz ˈɔːlweɪz fʊl əv ɪnˈθjuːzɪæzm',
        translation: "I see that he's always full of enthusiasm",
      },
      {
        id: 57,
        expression: 'Она всегда полна энергии',
        transcription: 'ʃiːz ˈɔːlweɪz fʊl əv ˈɛnəʤi',
        translation: "She's always full of energy",
      },
      {
        id: 58,
        expression: 'Я знаю, что он всегда полон идей',
        transcription: 'aɪ nəʊ ðət hiːz ˈɔːlweɪz fʊl əv aɪˈdɪəz',
        translation: "I know that he's always full of ideas",
      },
      {
        id: 59,
        expression: 'Я действительно смущен этим',
        transcription: 'aɪm ˈrɪəli kənˈfjuːzd əˈbaʊt ɪt',
        translation: "I'm really confused about it",
      },
      {
        id: 60,
        expression: 'Я был так смущен его решением',
        transcription: 'aɪ wəz səʊ kənˈfjuːzd əˈbaʊt ɪz dɪˈsɪʒən',
        translation: 'I was so confused about his decision',
      },
      {
        id: 61,
        expression: 'Ты действительно смущен этим?',
        transcription: 'ɑː jʊ ˈrɪəli kənˈfjuːzd əˈbaʊt ɪt?',
        translation: 'Are you really confused about it?',
      },
      {
        id: 62,
        expression:
          'Большинство детей действительно любит электронные гаджеты в наши дни',
        transcription:
          'məʊst ˈʧɪldrən ˈrɪəli laɪk ɪlɛkˈtrɒnɪk ˈgæʤɪts ˈnaʊədeɪz',
        translation: 'Most children really like electronic gadgets nowadays',
      },
      {
        id: 63,
        expression: 'Его дети так сильно любят электронные гаджеты',
        transcription: 'hɪz kɪdz laɪk ɪlɛkˈtrɒnɪk ˈgæʤɪts səʊ mʌʧ',
        translation: 'His kids like electronic gadgets so much',
      },
      {
        id: 64,
        expression: 'Большинство детей часто используют электронные гаджеты',
        transcription: 'məʊst kɪdz ˈɒf(ə)n juːz ɪlɛkˈtrɒnɪk ˈgæʤɪts',
        translation: 'Most kids often use electronic gadgets',
      },
      {
        id: 65,
        expression: 'Женщина боялась говорить',
        transcription: 'ðə ˈwʊmən wəz ˈfraɪtnd tə spiːk',
        translation: 'The woman was frightened to speak',
      },
      {
        id: 66,
        expression: 'Мне страшно об этом говорить',
        transcription: 'aɪm ˈfraɪtnd tə tɔːk əˈbaʊt ɪt',
        translation: "I'm frightened to talk about it",
      },
      {
        id: 67,
        expression: 'Эта женщина не боялась говорить об этом',
        transcription: 'ðɪs ˈwʊmən wɒznt ˈfraɪtnd tə spiːk əˈbaʊt ɪt',
        translation: "This woman wasn't frightened to speak about it",
      },
      {
        id: 68,
        expression: 'Он опоздал на встречу',
        transcription: 'hi wəz leɪt fə ðə ˈmiːtɪŋ',
        translation: 'He was late for the meeting',
      },
      {
        id: 69,
        expression: 'Она опоздала на урок',
        transcription: 'ʃi wəz leɪt fə ðə ˈlɛsn',
        translation: 'She was late for the lesson',
      },
      {
        id: 70,
        expression: 'Почему ты опоздал на эту встречу?',
        transcription: 'waɪ wə jʊ leɪt fə ðɪs ˈmiːtɪŋ?',
        translation: 'Why were you late for this meeting?',
      },
      {
        id: 71,
        expression: 'Ребенок абсолютно здоров',
        transcription: 'ðə kɪd z ˈæbsəluːtli ˈhɛlθi',
        translation: 'The kid is absolutely healthy',
      },
      {
        id: 72,
        expression: 'Этот малыш действительно здоров',
        transcription: 'ðə ˈbeɪbi z ˈrɪəli ˈhɛlθi',
        translation: 'The baby is really healthy',
      },
      {
        id: 73,
        expression: 'Малыш здоров?',
        transcription: 'ɪz ðə ˈbeɪbi ˈhɛlθi?',
        translation: 'Is the baby healthy?',
      },
      {
        id: 74,
        expression: 'В основном ими используются искусственные материалы',
        transcription:
          'ðeɪ juːz ˈməʊstli ˌɑːtɪˈfɪʃ(ə)l məˈtɪərɪəlz / ˈməʊstli ˌɑːtɪˈfɪʃ(ə)l məˈtɪərɪəlz ə juːzd baɪ ðɛm',
        translation:
          'They use mostly artificial materials / Mostly artificial materials are used by them',
      },
      {
        id: 75,
        expression: 'Эти искусственные материалы используются ими?',
        transcription: 'ɑː ðiːz ˌɑːtɪˈfɪʃ(ə)l məˈtɪərɪəlz juːzd baɪ ðɛm?',
        translation: 'Are these artificial materials used by them?',
      },
      {
        id: 76,
        expression: 'Они обычно используют натуральные материалы',
        transcription: 'ðeɪ ˈjuːʒʊəli juːz ˈnæʧrəl məˈtɪərɪəlz',
        translation: 'They usually use natural materials',
      },
      {
        id: 77,
        expression: 'Я не думаю, что это будет немедленный успех',
        transcription: 'aɪ dəʊnt θɪŋk ðət ˈɪtl bi ən ɪˈmiːdiət səkˈsɛs',
        translation: "I don't think that it'll be an immediate success",
      },
      {
        id: 78,
        expression: 'Я думаю, что это будет огромный успех',
        transcription: 'aɪ θɪŋk ðət ˈɪtl bi ə hjuːʤ (greɪt) səkˈsɛs',
        translation: "I think that it'll be a huge (great) success",
      },
      {
        id: 79,
        expression: 'Она в хорошей физической форме, несмотря на свой возраст',
        transcription:
          'ʃiːz ˈfɪzɪkəli fɪt ɪn spaɪt əv hər eɪʤ / ʃiːz ˈfɪzɪkəli fɪt dɪsˈpaɪt hər eɪʤ',
        translation:
          "She's physically fit in spite of her age / She's physically fit despite her age",
      },
      {
        id: 80,
        expression: 'Она физически здорова, несмотря на свой возраст',
        transcription: 'ʃiːz ˈfɪzɪkəli ˈhɛlθi dɪsˈpaɪt hər eɪʤ',
        translation: "She's physically healthy despite her age",
      },
      {
        id: 81,
        expression: 'Начальник очень лоялен к своим сотрудникам',
        transcription: 'ðə bɒs ɪz ˈvɛri ˈlɔɪəl tə ɪz ˌɛmplɔɪˈiːz',
        translation: 'The boss is very loyal to his employees',
      },
      {
        id: 82,
        expression: 'Наш начальник очень лоялен ко всем нам',
        transcription: 'ˈaʊə bɒs ɪz ˈvɛri ˈlɔɪəl tʊ ɔːl əv ʌs',
        translation: 'Our boss is very loyal to all of us',
      },
      {
        id: 83,
        expression: 'Их начальник всегда лоялен к ним',
        transcription: 'ðeə bɒs ɪz ˈɔːlweɪz ˈlɔɪəl tə ðɛm',
        translation: 'Their boss is always loyal to them',
      },
      {
        id: 84,
        expression: 'Он был болен, но теперь он здоров',
        transcription: 'hi wəz ɪl bət hi z ˈhɛlθi naʊ',
        translation: 'He was ill but he is healthy now',
      },
      {
        id: 85,
        expression: 'Как ты относишься к пластической хирургии?',
        transcription: 'wɒt s jər ˈætɪtjuːd tə ˈplæstɪk ˈsɜːʤəri?',
        translation: 'What is your attitude to plastic surgery?',
      },
      {
        id: 86,
        expression: 'Компания старается следовать международным стандартам',
        transcription: 'ðə ˈkʌmpəni traɪz tə ˈfɒləʊ ˌɪntə(ː)ˈnæʃənl ˈstændədz',
        translation: 'The company tries to follow international standards',
      },
      {
        id: 87,
        expression: 'Сотрудники действительно беспокоятся о потере работы',
        transcription: 'ði ˌɛmplɔɪˈiːz ə ˈrɪəli ˈwʌrid əˈbaʊt ˈluːzɪŋ ðeə ʤɒb',
        translation: 'The employees are really worried about losing their job',
      },
      {
        id: 88,
        expression: 'Она решила не делать пластическую операцию',
        transcription: 'ʃi dɪˈsaɪdɪd nɒt tə həv ə ˈplæstɪk ˌɒpəˈreɪʃən',
        translation: 'She decided not to have a plastic operation',
      },
      {
        id: 89,
        expression: 'Город известен своими небоскребами',
        transcription: 'ðə ˈsɪti z ˈfeɪməs fər ɪts ˈskaɪˌskreɪpəz',
        translation: 'The city is famous for its skyscrapers',
      },
      {
        id: 90,
        expression: 'Какой правильный ответ на этот вопрос?',
        transcription: 'wɒts ðə kəˈrɛkt ˈɑːnsə tə ðɪs ˈkwɛsʧən?',
        translation: "What's the correct answer to this question?",
      },
      {
        id: 91,
        expression: 'Ситуация кажется очень нестабильной в наше время',
        transcription: 'ðə ˌsɪtjʊˈeɪʃən siːmz ˈvɛri ʌnˈsteɪbl ˈnaʊədeɪz',
        translation: 'The situation seems very unstable nowadays',
      },
      {
        id: 92,
        expression:
          'Я абсолютно уверен, что это единственно правильное решение',
        transcription: 'aɪm ˈæbsəluːtli ʃʊə ðət ɪts ði ˈəʊnli raɪt dɪˈsɪʒən',
        translation: "I'm absolutely sure that it's the only right decision",
      },
      {
        id: 93,
        expression:
          'Я чувствую, что я делаю быстрый прогресс, изучая английский здесь',
        transcription:
          'aɪ fiːl ðət aɪ meɪk ˈræpɪd ˈprəʊgrəs ˈlɜːnɪŋ ˈɪŋglɪʃ hɪə',
        translation: 'I feel that I make rapid progress learning English here',
      },
      {
        id: 94,
        expression: 'Я стараюсь держать себя в форме',
        transcription: 'aɪ traɪ tə kiːp fɪt',
        translation: 'I try to keep fit',
      },
      {
        id: 95,
        expression: 'Это не прекрасное место?',
        transcription: 'ˈɪznt ɪt ə ˈlʌvli pleɪs?',
        translation: "Isn't it a lovely place?",
      },
      {
        id: 96,
        expression:
          'Это заставляет меня чувствовать себя действительно смущенным',
        transcription: 'ɪt meɪks mi fiːl ˈrɪəli kənˈfjuːzd',
        translation: 'It makes me feel really confused',
      },
      {
        id: 97,
        expression: 'Это был немедленный успех',
        transcription: 'ɪt wəz ən ɪˈmiːdiət səkˈsɛs',
        translation: 'It was an immediate success',
      },
      {
        id: 98,
        expression: 'Он сказал мне всю правду',
        transcription: 'hi təʊld mi ðə həʊl truːθ',
        translation: 'He told me the whole truth',
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Закрепление пройденного материала</h3>
</div>`,
    },
  ],
];
