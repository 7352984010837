export const contentLesson110 = [
  [
    {
      title: "Урок 110. Present Continuous",
    },
    [
      {
        id: 1,
        expression: "Я пою сейчас",
        transcription: "aɪm ˈsɪŋɪŋ",
        translation: "I'm singing",
      },
      {
        id: 2,
        expression: "Мы смотрим телевизор сейчас",
        transcription: "wɪə ˈwɒʧɪŋ ˌtiːˈviː",
        translation: "We're watching TV",
      },
      {
        id: 3,
        expression: "Он принимает душ сейчас",
        transcription: "hiːz ˈteɪkɪŋ ə ˈʃaʊə",
        translation: "He's taking a shower",
      },
      {
        id: 4,
        expression: "Я танцую сейчас",
        transcription: "aɪm ˈdɑːnsɪŋ",
        translation: "I'm dancing",
      },
      {
        id: 5,
        expression: "Мы играем на стадионе сейчас",
        transcription: "wɪə ˈpleɪɪŋ ət ðə ˈsteɪdiəm",
        translation: "We're playing at the stadium",
      },
      {
        id: 6,
        expression: "Он танцует сейчас",
        transcription: "hiːz ˈdɑːnsɪŋ",
        translation: "He's dancing",
      },
      {
        id: 7,
        expression: "Она учит русский сейчас",
        transcription: "ʃiːz ˈlɜːnɪŋ ˈrʌʃ(ə)n",
        translation: "She's learning Russian",
      },
      {
        id: 8,
        expression: "Она принимает душ",
        transcription: "ʃiːz ˈteɪkɪŋ ə ˈʃaʊə",
        translation: "She's taking a shower",
      },
      {
        id: 9,
        expression: "Я слушаю тебя",
        transcription: "aɪm ˈlɪsnɪŋ tə juː",
        translation: "I'm listening to you",
      },
      {
        id: 10,
        expression: "Я думаю об этом очень серьезно сейчас",
        transcription: "aɪm ˈθɪŋkɪŋ əˈbaʊt ɪt ˈvɛri ˈsɪərɪəsli naʊ",
        translation: "I'm thinking about it very seriously now",
      },
      {
        id: 11,
        expression: "Он находится на работе",
        transcription: "hiːz ˈsteɪɪŋ ət wɜːk",
        translation: "He's staying at work",
      },
      {
        id: 12,
        expression: "Я сейчас иду в парк",
        transcription: "aɪm ˈgəʊɪŋ tə ðə pɑːk naʊ",
        translation: "I'm going to the park now",
      },
      {
        id: 13,
        expression: "Он изучает биологию сейчас",
        transcription: "hiːz ˈstʌdiɪŋ baɪˈɒləʤi naʊ",
        translation: "He's studying biology now",
      },
      {
        id: 14,
        expression: "Он плавает в пруду",
        transcription: "hiːz ˈswɪmɪŋ ɪn ðə pɒnd",
        translation: "He's swimming in the pond",
      },
      {
        id: 15,
        expression: "Мы играем в футбол",
        transcription: "wɪə ˈpleɪɪŋ ˈfʊtbɔːl",
        translation: "We're playing football",
      },
      {
        id: 16,
        expression: "Они разговаривают и смеются",
        transcription: "ðeə ˈtɔːkɪŋ ənd ˈlɑːfɪŋ",
        translation: "They're talking and laughing",
      },
      {
        id: 17,
        expression: "Это происходит в данный момент",
        transcription: "ɪts ˈhæpnɪŋ ət ðə ˈməʊmənt",
        translation: "It's happening at the moment",
      },
      {
        id: 18,
        expression: "Я сейчас разговариваю с тобой",
        transcription: "aɪm ˈtɔːkɪŋ tə jʊ naʊ",
        translation: "I'm talking to you now",
      },
      {
        id: 19,
        expression: "Я жду твоего ответа",
        transcription: "aɪm ˈweɪtɪŋ fə jər ˈɑːnsə",
        translation: "I'm waiting for your answer",
      },
      {
        id: 20,
        expression: "Мы лежим на пляже",
        transcription: "wɪə ˈlaɪɪŋ ɒn ðə biːʧ",
        translation: "We're lying on the beach",
      },
      {
        id: 21,
        expression: "Я сейчас еду на велосипеде",
        transcription: "aɪm ˈraɪdɪŋ ə baɪk naʊ",
        translation: "I'm riding a bike now",
      },
      {
        id: 22,
        expression: "Они играют на стадионе",
        transcription: "ðeə ˈpleɪɪŋ ət ðə ˈsteɪdiəm",
        translation: "They're playing at the stadium",
      },
      {
        id: 23,
        expression: "Он поет",
        transcription: "hiːz ˈsɪŋɪŋ",
        translation: "He's singing",
      },
      {
        id: 24,
        expression: "Она пишет письмо сейчас",
        transcription: "ʃiːz ˈraɪtɪŋ ə ˈlɛtə naʊ",
        translation: "She's writing a letter now",
      },
      {
        id: 25,
        expression: "Я сижу дома",
        transcription: "aɪm ˈsteɪɪŋ ət həʊm",
        translation: "I'm staying at home",
      },
      {
        id: 26,
        expression: "Он принимает душ",
        transcription: "hiːz ˈteɪkɪŋ ə ˈʃaʊə",
        translation: "He's taking a shower",
      },
      {
        id: 27,
        expression: "Я иду домой",
        transcription: "aɪm ˈgəʊɪŋ həʊm",
        translation: "I'm going home",
      },
      {
        id: 28,
        expression: "Она танцует",
        transcription: "ʃiːz ˈdɑːnsɪŋ",
        translation: "She's dancing",
      },
      {
        id: 29,
        expression: "Я иду на работу",
        transcription: "aɪm ˈgəʊɪŋ tə wɜːk",
        translation: "I'm going to work",
      },
      {
        id: 30,
        expression: "Она что-то говорит",
        transcription: "ʃiːz ˈseɪɪŋ ˈsʌmθɪŋ",
        translation: "She's saying something",
      },
      {
        id: 31,
        expression: "Она поет так громко",
        transcription: "ʃiːz ˈsɪŋɪŋ səʊ ˈlaʊdli",
        translation: "She's singing so loudly",
      },
      {
        id: 32,
        expression: "Я учу английский сейчас",
        transcription: "aɪm ˈlɜːnɪŋ ˈɪŋglɪʃ naʊ",
        translation: "I'm learning English now",
      },
      {
        id: 33,
        expression: "Ты едешь слишком быстро",
        transcription: "jʊə ˈdraɪvɪŋ tuː fɑːst",
        translation: "You're driving too fast",
      },
      {
        id: 34,
        expression: "Мы смотрим фильм",
        transcription: "wɪə ˈwɒʧɪŋ ə ˈmuːvi",
        translation: "We're watching a movie",
      },
      {
        id: 35,
        expression: "Я сейчас смотрю это видео",
        transcription: "aɪm ˈwɒʧɪŋ ðɪs ˈvɪdɪəʊ naʊ",
        translation: "I'm watching this video now",
      },
      {
        id: 36,
        expression: "Я лежу на солнечном пляже",
        transcription: "aɪm ˈlaɪɪŋ ɒn ə ˈsʌni biːʧ",
        translation: "I'm lying on a sunny beach",
      },
      {
        id: 37,
        expression: "Я жду тебя",
        transcription: "aɪm ˈweɪtɪŋ fə juː",
        translation: "I'm waiting for you",
      },
      {
        id: 38,
        expression: "Он ест гамбургер",
        transcription: "hiːz ˈiːtɪŋ ə ˈhæmbɜːgə",
        translation: "He's eating a hamburger",
      },
      {
        id: 39,
        expression: "Я пью чай",
        transcription: "aɪm ˈdrɪŋkɪŋ tiː",
        translation: "I'm drinking tea",
      },
      {
        id: 40,
        expression: "Он тратит время впустую",
        transcription: "hiːz ˈweɪstɪŋ taɪm",
        translation: "He's wasting time",
      },
      {
        id: 41,
        expression: "Я жду твоего звонка",
        transcription: "aɪm ˈweɪtɪŋ fə jə kɔːl",
        translation: "I'm waiting for your call",
      },
      {
        id: 42,
        expression: "Она готовит торт",
        transcription: "ʃiːz ˈkʊkɪŋ ə keɪk",
        translation: "She's cooking a cake",
      },
      {
        id: 43,
        expression: "Она сейчас изучает химию",
        transcription: "ʃiːz ˈlɜːnɪŋ ˈkɛmɪstri naʊ",
        translation: "She's learning chemistry now",
      },
      {
        id: 44,
        expression: "Я сейчас гуляю в парке",
        transcription: "aɪm ˈwɔːkɪŋ ɪn ðə pɑːk naʊ",
        translation: "I'm walking in the park now",
      },
      {
        id: 45,
        expression: "Он пьет кофе",
        transcription: "hiːz ˈdrɪŋkɪŋ ˈkɒfi",
        translation: "He's drinking coffee",
      },
      {
        id: 46,
        expression: "Он ждет тебя сейчас",
        transcription: "hiːz ˈweɪtɪŋ fə jʊ naʊ",
        translation: "He's waiting for you now",
      },
      {
        id: 47,
        expression: "Он делает пиццу сейчас",
        transcription: "hiːz ˈmeɪkɪŋ ˈpiːtsə naʊ",
        translation: "He's making pizza now",
      },
      {
        id: 48,
        expression: "Она сейчас убирает дом",
        transcription: "ʃiːz ˈkliːnɪŋ ðə haʊs naʊ",
        translation: "She's cleaning the house now",
      },
      {
        id: 49,
        expression: "Цены становятся все выше и выше",
        transcription: "ðə ˈpraɪsɪz ə ˈgɛtɪŋ ˈhaɪər ənd ˈhaɪə",
        translation: "The prices are getting higher and higher",
      },
      {
        id: 50,
        expression: "Дети играют в футбол",
        transcription: "ðə ˈʧɪldrən ə ˈpleɪɪŋ ˈfʊtbɔːl",
        translation: "The children are playing football",
      },
      {
        id: 51,
        expression: "Он отдыхает после работы",
        transcription: "hiːz ˈhævɪŋ ə rɛst ˈɑːftə wɜːk",
        translation: "He's having a rest after work",
      },
      {
        id: 52,
        expression: "Эта область становится все более и более загрязненной",
        transcription: "ðɪs ˈeərɪə z ˈgɛtɪŋ mɔːr ənd mɔː pəˈluːtɪd",
        translation: "This area is getting more and more polluted",
      },
      {
        id: 53,
        expression: "Солнце светит ярко",
        transcription: "ðə sʌn z ˈʃaɪnɪŋ ˈbraɪtli",
        translation: "The sun is shining brightly",
      },
      {
        id: 54,
        expression: "Он лежит на солнечном пляже и болтает со своей женой",
        transcription: "hiːz ˈlaɪɪŋ ɒn ə ˈsʌni biːʧ ənd ˈʧætɪŋ wɪð ɪz waɪf",
        translation: "He's lying on a sunny beach and chatting with his wife",
      },
      {
        id: 55,
        expression: "Я пытаюсь как-то адаптироваться к этим условиям",
        transcription: "aɪm ˈtraɪɪŋ tʊ əˈdæpt tə ðiːz kənˈdɪʃənz ˈsʌmhaʊ",
        translation: "I'm trying to adapt to these conditions somehow",
      },
      {
        id: 56,
        expression: "Он ест шоколадный торт",
        transcription: "hiːz ˈiːtɪŋ ə ˈʧɒkəlɪt keɪk",
        translation: "He's eating a chocolate cake",
      },
      {
        id: 57,
        expression: "Наша жизнь становится все дороже и дороже",
        transcription: "ˈaʊə laɪf s ˈgɛtɪŋ mɔːr ənd mɔːr ɪksˈpɛnsɪv",
        translation: "Our life is getting more and more expensive",
      },
      {
        id: 58,
        expression: "Качество этих продуктов становится все лучше и лучше",
        transcription:
          "ðə ˈkwɒlɪti əv ðiːz ˈprɒdʌkts ɪz ˈgɛtɪŋ ˈbɛtər ənd ˈbɛtə",
        translation:
          "The quality of these products is getting better and better",
      },
      {
        id: 59,
        expression: "Город становится все более и более загрязненным",
        transcription: "ðə ˈsɪti z ˈgɛtɪŋ mɔːr ənd mɔː pəˈluːtɪd",
        translation: "The city is getting more and more polluted",
      },
      {
        id: 60,
        expression: "Мой английский становится все лучше и лучше",
        transcription: "maɪ ˈɪŋglɪʃ ɪz ˈgɛtɪŋ ˈbɛtər ənd ˈbɛtə",
        translation: "My English is getting better and better",
      },
      {
        id: 61,
        expression: "Идет сильный дождь",
        transcription: "ɪts ˈreɪnɪŋ ˈhɛvɪli",
        translation: "It's raining heavily",
      },
      {
        id: 62,
        expression: "Она ест шоколад",
        transcription: "ʃiːz ˈiːtɪŋ ˈʧɒkəlɪt",
        translation: "She's eating chocolate",
      },
      {
        id: 63,
        expression: "Она убирает комнату сейчас",
        transcription: "ʃiːz ˈkliːnɪŋ ðə ruːm naʊ",
        translation: "She's cleaning the room now",
      },
      {
        id: 64,
        expression: "Она моет руки сейчас",
        transcription: "ʃiːz ˈwɒʃɪŋ hə hændz naʊ",
        translation: "She's washing her hands now",
      },
      {
        id: 65,
        expression: "Она делает свое домашнее задание сейчас",
        transcription: "ʃiːz ˈdu(ː)ɪŋ hə ˈhəʊmˌwɜːk naʊ",
        translation: "She's doing her homework now",
      },
      {
        id: 66,
        expression: "Она отдыхает после школы",
        transcription: "ʃiːz ˈhævɪŋ ə rɛst ˈɑːftə skuːl",
        translation: "She's having a rest after school",
      },
      {
        id: 67,
        expression: "Она отдыхает в данный момент",
        transcription: "ʃiːz ˈhævɪŋ ə rɛst ət ðə ˈməʊmənt",
        translation: "She's having a rest at the moment",
      },
      {
        id: 68,
        expression: "Я сейчас отдыхаю",
        transcription: "aɪm ˈhævɪŋ ə rɛst naʊ",
        translation: "I'm having a rest now",
      },
      {
        id: 69,
        expression: "Она лежит на солнечном пляже и болтает со своим мужем",
        transcription: "ʃiːz ˈlaɪɪŋ ɒn ə ˈsʌni biːʧ ənd ˈʧætɪŋ wɪð hə ˈhʌzbənd",
        translation:
          "She's lying on a sunny beach and chatting with her husband",
      },
      {
        id: 70,
        expression: "Она лежит на пляже и разговаривает со своим мужем",
        transcription: "ʃi ˈlaɪɪŋ ɒn ðə biːʧ ənd ˈtɔːkɪŋ tə hə ˈhʌzbənd",
        translation: "She's lying on the beach and talking to her husband",
      },
      {
        id: 71,
        expression: "Она лежит на кровати и болтает со своими друзьями",
        transcription: "ʃiːz ˈlaɪɪŋ ɪn bɛd ənd ˈʧætɪŋ wɪð hə frɛndz",
        translation: "She's lying in bed and chatting with her friends",
      },
      {
        id: 72,
        expression: "Я очень усердно работаю в эти дни",
        transcription: "aɪm ˈwɜːkɪŋ ˈvɛri hɑːd ðiːz deɪz",
        translation: "I'm working very hard these days",
      },
      {
        id: 73,
        expression: "Он сейчас так много работает",
        transcription: "hiːz ˈwɜːkɪŋ səʊ mʌʧ naʊ",
        translation: "He's working so much now",
      },
      {
        id: 74,
        expression: "Я сейчас работаю над этим проектом",
        transcription: "aɪm ˈwɜːkɪŋ ɒn ðɪs ˈprɒʤɛkt naʊ",
        translation: "I'm working on this project now",
      },
      {
        id: 75,
        expression: "Я отдыхаю после работы",
        transcription: "aɪm ˈhævɪŋ ə rɛst ˈɑːftə wɜːk",
        translation: "I'm having a rest after work",
      },
      {
        id: 76,
        expression: "Я отдыхаю в данный момент",
        transcription: "aɪm ˈhævɪŋ ə rɛst ət ðə ˈməʊmənt",
        translation: "I'm having a rest at the moment",
      },
      {
        id: 77,
        expression: "Женщина разговаривает по телефону",
        transcription: "ðə ˈwʊmən z ˈtɔːkɪŋ ɒn ðə fəʊn",
        translation: "The woman is talking on the phone",
      },
      {
        id: 78,
        expression: "Женщина разговаривает со своим мужем",
        transcription: "ðə ˈwʊmən z ˈtɔːkɪŋ tə hə ˈhʌzbənd",
        translation: "The woman is talking to her husband",
      },
      {
        id: 79,
        expression: "Я разговариваю по телефону",
        transcription: "aɪm ˈtɔːkɪŋ ɒn ðə fəʊn",
        translation: "I'm talking on the phone",
      },
      {
        id: 80,
        expression: "Дети разговаривают и смеются",
        transcription: "ðə ˈʧɪldrən ə ˈtɔːkɪŋ ənd ˈlɑːfɪŋ",
        translation: "The children are talking and laughing",
      },
      {
        id: 81,
        expression: "Мои друзья поют и танцуют",
        transcription: "maɪ frɛndz ə ˈsɪŋɪŋ ənd ˈdɑːnsɪŋ",
        translation: "My friends are singing and dancing",
      },
      {
        id: 82,
        expression: "Они разговаривают друг с другом",
        transcription: "ðeə ˈtɔːkɪŋ tʊ iːʧ ˈʌðə",
        translation: "They're talking to each other",
      },
      {
        id: 83,
        expression: "Он тренируется очень интенсивно в эти дни",
        transcription: "hiːz ˈtreɪnɪŋ ˈvɛri ɪnˈtɛnsɪvli ðiːz deɪz",
        translation: "He's training very intensively these days",
      },
      {
        id: 84,
        expression: "Они тренируются очень интенсивно в этом месяце",
        transcription: "ðeə ˈtreɪnɪŋ ˈvɛri ɪnˈtɛnsɪvli ðɪs mʌnθ",
        translation: "They're training very intensively this month",
      },
      {
        id: 85,
        expression: "Он пытается тренироваться очень интенсивно",
        transcription: "hiːz ˈtraɪɪŋ tə treɪn ˈvɛri ɪnˈtɛnsɪvli",
        translation: "He's trying to train very intensively",
      },
      {
        id: 86,
        expression: "Идет сильный снег",
        transcription: "ɪts ˈsnəʊɪŋ ˈhɛvɪli",
        translation: "It's snowing heavily",
      },
      {
        id: 87,
        expression: "Сейчас идет снег",
        transcription: "ɪts ˈsnəʊɪŋ naʊ",
        translation: "It's snowing now",
      },
      {
        id: 88,
        expression: "Все делают это сейчас",
        transcription: "ˈɛvrɪwʌn z ˈdu(ː)ɪŋ ɪt naʊ",
        translation: "Everyone is doing it now",
      },
      {
        id: 89,
        expression: "Я делаю это сейчас",
        transcription: "aɪm ˈdu(ː)ɪŋ ɪt naʊ",
        translation: "I'm doing it now",
      },
      {
        id: 90,
        expression: "Они делают это сейчас",
        transcription: "ðeə ˈdu(ː)ɪŋ ɪt naʊ",
        translation: "They're doing it now",
      },
      {
        id: 91,
        expression: "Я делаю свою домашнюю работу в данный момент",
        transcription: "aɪm ˈdu(ː)ɪŋ maɪ ˈhəʊmˌwɜːk ət ðə ˈməʊmənt",
        translation: "I'm doing my homework at the moment",
      },
      {
        id: 92,
        expression: "Его отец сейчас работает в саду",
        transcription: "hɪz ˈfɑːðə z ˈwɜːkɪŋ ɪn ðə ˈgɑːdn naʊ",
        translation: "His father is working in the garden now",
      },
      {
        id: 93,
        expression: "Некоторые люди становятся все богаче и богаче",
        transcription: "sʌm ˈpiːpl ə ˈgɛtɪŋ ˈrɪʧər ənd ˈrɪʧə",
        translation: "Some people are getting richer and richer",
      },
      {
        id: 94,
        expression: "Я разговариваю по телефону в данный момент",
        transcription: "aɪm ˈtɔːkɪŋ ɒn ðə fəʊn ət ðə ˈməʊmənt",
        translation: "I'm talking on the phone at the moment",
      },
      {
        id: 95,
        expression: "Я сижу дома на этой неделе",
        transcription: "aɪm ˈsteɪɪŋ ət həʊm ðɪs wiːk",
        translation: "I'm staying at home this week",
      },
      {
        id: 96,
        expression: "Мальчик смеется над ней",
        transcription: "ðə bɔɪ z ˈlɑːfɪŋ ət hɜː",
        translation: "The boy is laughing at her",
      },
      {
        id: 97,
        expression: "Эта профессия становится все более и более популярной",
        transcription: "ðɪs prəˈfɛʃən z ˈgɛtɪŋ mɔːr ənd mɔː ˈpɒpjʊlə",
        translation: "This profession is getting more and more popular",
      },
      {
        id: 98,
        expression: "Мы обсуждаем это в настоящее время",
        transcription: "wɪə dɪsˈkʌsɪŋ ɪt ət ˈprɛznt",
        translation: "We're discussing it at present",
      },
      {
        id: 99,
        expression: "Мой уровень становится все выше и выше",
        transcription: "maɪ ˈlɛvl z ˈgɛtɪŋ ˈhaɪər ənd ˈhaɪə",
        translation: "My level is getting higher and higher",
      },
      {
        id: 100,
        expression: "Большинство людей становится все беднее и беднее",
        transcription: "məʊst ˈpiːpl ə ˈgɛtɪŋ ˈpʊərər ənd ˈpʊərər",
        translation: "Most people are getting poorer and poorer",
      },
      {
        id: 101,
        expression: "Девочка плачет",
        transcription: "ðə gɜːl z ˈkraɪɪŋ",
        translation: "The girl is crying",
      },
      {
        id: 102,
        expression: "Идет дождь",
        transcription: "ɪts ˈreɪnɪŋ",
        translation: "It's raining",
      },
      {
        id: 103,
        expression: "Малыш спит",
        transcription: "ðə ˈbeɪbi z ˈsliːpɪŋ",
        translation: "The baby is sleeping",
      },
      {
        id: 104,
        expression: "Идет снег",
        transcription: "ɪts ˈsnəʊɪŋ",
        translation: "It's snowing",
      },
      {
        id: 105,
        expression: "Все покупают это сейчас",
        transcription: "ˈɛvrɪwʌn z ˈbaɪɪŋ ɪt naʊ",
        translation: "Everyone is buying it now",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Present Continuous - Настоящее продолженное время</h3>
<p>Время Present Continuous используется для описания действий или процессов, происходящих в данный момент, сейчас. Общая формула построения утвердительных предложений в Present Continuous выглядит так:</p>
<p><span>подлежащее + форма глагола to be в настоящем времени + глагол+ing + ...</span></p>
<p>She's signing - Она поет сейчас.</p>
<p>We're watching TV - Мы смотрим телевизор сейчас.</p>
<p>He's taking a shower - Он принимает душ сейчас.</p>
</div>`,
    },
  ],
];
