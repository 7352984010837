export const contentLesson47 = [
  [
    {
      title: "Урок 47. 150 verbs. Part 3",
    },
    [
      {
        id: 1,
        expression: "Я купил те брюки",
        transcription: "aɪ bɔːt ðəʊz ˈtraʊzəz",
        translation: "I bought those trousers",
      },
      {
        id: 2,
        expression: "Я помню все",
        transcription: "aɪ rɪˈmɛmbər ˈɛvrɪθɪŋ",
        translation: "I remember everything",
      },
      {
        id: 3,
        expression: "Я купил эти джинсы",
        transcription: "aɪ bɔːt ðiːz ʤiːnz",
        translation: "I bought these jeans",
      },
      {
        id: 4,
        expression: "Это приносит ему слишком мало денег",
        transcription: "ɪt brɪŋz ɪm tuː ˈlɪtl ˈmʌni",
        translation: "It brings him too little money",
      },
      {
        id: 5,
        expression: "Что он предложил тебе?",
        transcription: "wɒt dɪd hi ˈɒfə juː?",
        translation: "What did he offer you?",
      },
      {
        id: 6,
        expression: "Я не помнил его имени",
        transcription: "aɪ dɪdnt rɪˈmɛmbə hɪz neɪm",
        translation: "I didn't remember his name",
      },
      {
        id: 7,
        expression: "Это действительно мотивировало тебя?",
        transcription: "dɪd ɪt ˈrɪəli ˈməʊtɪveɪt juː?",
        translation: "Did it really motivate you?",
      },
      {
        id: 8,
        expression: "Ты также можешь скачать это",
        transcription: "jʊ kən ˈɔːlsəʊ ˌdaʊnˈləʊd ɪt",
        translation: "You can also download it",
      },
      {
        id: 9,
        expression: "Ты можешь сесть сейчас",
        transcription: "jʊ kən sɪt daʊn naʊ",
        translation: "You can sit down now",
      },
      {
        id: 10,
        expression: "Он продал все",
        transcription: "hi səʊld ˈɛvrɪθɪŋ",
        translation: "He sold everything",
      },
      {
        id: 11,
        expression: "Она побеждает каждый раз",
        transcription: "ʃi wɪnz ˈɛvri taɪm",
        translation: "She wins every time",
      },
      {
        id: 12,
        expression: "Где ты скачиваешь эти программы?",
        transcription: "weə dʊ jʊ ˌdaʊnˈləʊd ðiːz ˈprəʊgræmz?",
        translation: "Where do you download these programs?",
      },
      {
        id: 13,
        expression: "Он откроет коробку?",
        transcription: "wɪl hi ˈəʊpən ðə bɒks?",
        translation: "Will he open the box?",
      },
      {
        id: 14,
        expression: "Он контролировал эту компанию",
        transcription: "hi kənˈtrəʊld ðɪs ˈkʌmpəni",
        translation: "He controlled this company",
      },
      {
        id: 15,
        expression: "Тебе нужно контролировать все",
        transcription: "jʊ niːd tə kənˈtrəʊl ˈɛvrɪθɪŋ",
        translation: "You need to control everything",
      },
      {
        id: 16,
        expression: "Это действительно мотивирует их",
        transcription: "ɪt ˈrɪəli ˈməʊtɪveɪts ðɛm",
        translation: "It really motivates them",
      },
      {
        id: 17,
        expression: "Ему нужно поспать чуть-чуть",
        transcription: "hi niːdz tə sliːp ə bɪt",
        translation: "He needs to sleep a bit",
      },
      {
        id: 18,
        expression: "Садись!",
        transcription: "sɪt daʊn!",
        translation: "Sit down!",
      },
      {
        id: 19,
        expression: "Я часто вставал поздно",
        transcription: "aɪ ˈɒf(ə)n gɒt ʌp leɪt",
        translation: "I often got up late",
      },
      {
        id: 20,
        expression: "Я открыл коробку",
        transcription: "aɪ ˈəʊpənd ðə bɒks",
        translation: "I opened the box",
      },
      {
        id: 21,
        expression: "Он убежал прочь",
        transcription: "hi ræn əˈweɪ",
        translation: "He ran away",
      },
      {
        id: 22,
        expression: "Когда он обычно встает?",
        transcription: "wɛn dəz hi ˈjuːʒʊəli gɛt ʌp?",
        translation: "When does he usually get up?",
      },
      {
        id: 23,
        expression: "Мы открыли посылку",
        transcription: "wi ˈəʊpənd ðə ˈpɑːsl",
        translation: "We opened the parcel",
      },
      {
        id: 24,
        expression: "Я могу бежать быстрее",
        transcription: "aɪ kən rʌn ˈfɑːstə",
        translation: "I can run faster",
      },
      {
        id: 25,
        expression: "Я хочу спать",
        transcription: "aɪ wɒnt tə sliːp",
        translation: "I want to sleep",
      },
      {
        id: 26,
        expression: "Я окончил университет два года назад",
        transcription: "aɪ ˈgrædjʊeɪtɪd frəm ˌjuːnɪˈvɜːsɪti tuː jɪəz əˈgəʊ",
        translation: "I graduated from university two years ago",
      },
      {
        id: 27,
        expression: "Они победят?",
        transcription: "wɪl ðeɪ wɪn?",
        translation: "Will they win?",
      },
      {
        id: 28,
        expression: "Я скачал ту программу",
        transcription: "aɪ ˌdaʊnˈləʊdɪd ðət ˈprəʊgræm",
        translation: "I downloaded that program",
      },
      {
        id: 29,
        expression: "Он не спал прошлой ночью",
        transcription: "hi dɪdnt sliːp lɑːst naɪt",
        translation: "He didn't sleep last night",
      },
      {
        id: 30,
        expression: "Ты закончишь это вовремя?",
        transcription: "wɪl jʊ ˈfɪnɪʃ ɪt ɒn taɪm?",
        translation: "Will you finish it on time?",
      },
      {
        id: 31,
        expression: "Они контролируют этот процесс",
        transcription: "ðeɪ kənˈtrəʊl ðɪs ˈprəʊsɛs",
        translation: "They control this process",
      },
      {
        id: 32,
        expression: "Ты споешь эту песню?",
        transcription: "wɪl jʊ sɪŋ ðɪs sɒŋ?",
        translation: "Will you sing this song?",
      },
      {
        id: 33,
        expression: "Она продала свой старый дом",
        transcription: "ʃi səʊld hər əʊld haʊs",
        translation: "She sold her old house",
      },
      {
        id: 34,
        expression: "Я купил тебе эту книгу",
        transcription: "aɪ bɔːt jʊ ðɪs bʊk",
        translation: "I bought you this book",
      },
      {
        id: 35,
        expression: "Когда он вернется?",
        transcription: "wɛn wɪl hi rɪˈtɜːn? / wɛn wɪl hi kʌm bæk?",
        translation: "When will he return? / When will he come back?",
      },
      {
        id: 36,
        expression: "Он может убежать",
        transcription: "hi kən rʌn əˈweɪ",
        translation: "He can run away",
      },
      {
        id: 37,
        expression: "Когда ты откроешь эту посылку?",
        transcription: "wɛn wɪl jʊ ˈəʊpən ðɪs ˈpɑːsl",
        translation: "When will you open this parcel?",
      },
      {
        id: 38,
        expression: "Он каждый раз говорит эту вещь",
        transcription: "hi sɛz ðɪs θɪŋ ˈɛvri taɪm",
        translation: "He says this thing every time",
      },
      {
        id: 39,
        expression: "Я закончил эту работу вовремя",
        transcription: "aɪ ˈfɪnɪʃt ðɪs wɜːk ɒn taɪm",
        translation: "I finished this work on time",
      },
      {
        id: 40,
        expression: "Мне завтра нужно встать очень рано",
        transcription: "aɪ niːd tə gɛt ʌp ˈvɛri ˈɜːli təˈmɒrəʊ",
        translation: "I need to get up very early tomorrow",
      },
      {
        id: 41,
        expression: "Я хотел бы выпить стакан сока",
        transcription: "aɪd laɪk tə drɪŋk ə glɑːs əv ʤuːs",
        translation: "I'd like to drink a glass of juice",
      },
      {
        id: 42,
        expression: "Он стал лучшим актером",
        transcription: "hi bɪˈkeɪm ðə bɛst ˈæktə",
        translation: "He became the best actor",
      },
      {
        id: 43,
        expression: "Я не думаю, что они победят",
        transcription: "aɪ dəʊnt θɪŋk ðət ðeɪl wɪn",
        translation: "I don't think that they'll win",
      },
      {
        id: 44,
        expression: "Мне кажется, эта песня станет очень популярной",
        transcription: "ɪt siːmz tə mi ðɪs sɒŋ wɪl bɪˈkʌm ˈvɛri ˈpɒpjʊlə",
        translation: "It seems to me this song will become very popular",
      },
      {
        id: 45,
        expression:
          "Он будет работать намного усерднее, потому что ему нужны эти деньги",
        transcription: "hiːl wɜːk mʌʧ ˈhɑːdə bɪˈkəz hi niːdz ðɪs ˈmʌni",
        translation: "He'll work much harder because he needs this money",
      },
      {
        id: 46,
        expression: "Ты можешь положить эту коробку на землю",
        transcription: "jʊ kən pʊt ðɪs bɒks ɒn ðə graʊnd",
        translation: "You can put this box on the ground",
      },
      {
        id: 47,
        expression: "Я забыл закрыть окно",
        transcription: "aɪ fəˈgɒt tə kləʊs ðə dɔː",
        translation: "I forgot to close the door",
      },
      {
        id: 48,
        expression: "Я постараюсь запомнить это",
        transcription: "aɪl traɪ tə rɪˈmɛmbər ɪt",
        translation: "I'll try to remember it",
      },
      {
        id: 49,
        expression: "Они хотели бы съесть пирог",
        transcription: "ðeɪ wəd laɪk tʊ iːt ə paɪ",
        translation: "They would like to eat a pie",
      },
      {
        id: 50,
        expression: "Когда она окончит университет?",
        transcription: "wɛn wɪl ʃi ˈgrædjʊət frəm ˌjuːnɪˈvɜːsɪti?",
        translation: "When will she graduate from university?",
      },
      {
        id: 51,
        expression: "Я видел, что это не работало",
        transcription: "aɪ sɔː ðət ɪt dɪdnt wɜːk",
        translation: "I saw that it didn't work",
      },
      {
        id: 52,
        expression: "Она забыла закрыть дверь",
        transcription: "ʃi fəˈgɒt tə kləʊs ðə dɔː",
        translation: "She forgot to close the door",
      },
      {
        id: 53,
        expression: "Мы могли бы сделать это сейчас",
        transcription: "wi kəd dʊ ɪt raɪt naʊ",
        translation: "We could do it right now",
      },
      {
        id: 54,
        expression: "Я не думаю, что это действительно работает",
        transcription: "aɪ dəʊnt θɪŋk ðət ɪt ˈrɪəli wɜːks",
        translation: "I don't think that it really works",
      },
      {
        id: 55,
        expression: "Я не забуду ее доброту",
        transcription: "aɪ wəʊnt fəˈgɛt hə ˈkaɪndnɪs",
        translation: "I won't forget her kindness",
      },
      {
        id: 56,
        expression: "Он мог бы понять все очень легко",
        transcription: "hi kəd ˌʌndəˈstænd ˈɛvrɪθɪŋ ˈvɛri ˈiːzɪli",
        translation: "He could understand everything very easily",
      },
      {
        id: 57,
        expression: "Они могли бы понять это очень легко",
        transcription: "ðeɪ kəd ˌʌndəˈstænd ɪt ˈvɛri ˈiːzɪli",
        translation: "They could understand it very easily",
      },
      {
        id: 58,
        expression: "Ты мог бы сделать все очень легко",
        transcription: "jʊ kəd dʊ ˈɛvrɪθɪŋ ˈvɛri ˈiːzɪli",
        translation: "You could do everything very easily",
      },
      {
        id: 59,
        expression: "Я бы купил другую одежду",
        transcription: "aɪd baɪ ˈʌðə kləʊðz",
        translation: "I'd buy other clothes",
      },
      {
        id: 60,
        expression: "Я бы купил другую вещь",
        transcription: "aɪd baɪ əˈnʌðə θɪŋ",
        translation: "I'd buy another thing",
      },
      {
        id: 61,
        expression: "Я хотел бы купить другую одежду",
        transcription: "aɪd laɪk tə baɪ ˈʌðə kləʊðz",
        translation: "I'd like to buy other clothes",
      },
      {
        id: 62,
        expression: "Она вчера резала рыбу",
        transcription: "ʃi kʌt fɪʃ ˈjɛstədeɪ",
        translation: "She cut fish yesterday",
      },
      {
        id: 63,
        expression: "Он порезался",
        transcription: "hi kʌt hɪmˈsɛlf",
        translation: "He cut himself",
      },
      {
        id: 64,
        expression: "Она часто резала рыбу",
        transcription: "ʃi ˈɒf(ə)n kʌt fɪʃ",
        translation: "She often cut fish",
      },
      {
        id: 65,
        expression: "Он не подписал этот контракт",
        transcription: "hi dɪdnt saɪn ðɪs ˈkɒntrækt",
        translation: "He didn't sign this contract",
      },
      {
        id: 66,
        expression: "Он не подписал эти контракты",
        transcription: "hi dɪdnt saɪn ðiːz ˈkɒntrækts",
        translation: "He didn't sign these contracts",
      },
      {
        id: 67,
        expression: "Я не подписал этот документ",
        transcription: "aɪ dɪdnt saɪn ðɪs ˈdɒkjʊmənt",
        translation: "I didn't sign this document",
      },
      {
        id: 68,
        expression: "Он мог бы прийти к тебе завтра",
        transcription: "hi kəd kʌm tə jʊ təˈmɒrəʊ",
        translation: "He could come to you tomorrow",
      },
      {
        id: 69,
        expression: "Он мог бы прийти ко мне завтра",
        transcription: "hi kəd kʌm tə mi təˈmɒrəʊ",
        translation: "He could come to me tomorrow",
      },
      {
        id: 70,
        expression: "Они могли бы прийти к нам на следующей неделе",
        transcription: "ðeɪ kəd kʌm tʊ əs nɛkst wiːk",
        translation: "They could come to us next week",
      },
      {
        id: 71,
        expression: "Я думаю, я куплю это в следующем году",
        transcription: "aɪ θɪŋk aɪl baɪ ɪt nɛkst jɪə",
        translation: "I think I'll buy it next year",
      },
      {
        id: 72,
        expression: "Она думает, она купит это в следующем году",
        transcription: "ʃi θɪŋks ʃiːl baɪ ɪt nɛkst jɪə",
        translation: "She thinks she'll buy it next year",
      },
      {
        id: 73,
        expression: "Они думают, они купят это в следующем году",
        transcription: "ðeɪ θɪŋk ðeɪl baɪ ɪt nɛkst jɪə",
        translation: "They think they'll buy it next year",
      },
      {
        id: 74,
        expression: "Он обычно возвращается домой слишком поздно",
        transcription: "hi ˈjuːʒʊəli rɪˈtɜːnz həʊm tuː leɪt",
        translation: "He usually returns home too late",
      },
      {
        id: 75,
        expression: "Она обычно возвращается домой слишком поздно",
        transcription: "ʃi ˈjuːʒʊəli rɪˈtɜːnz həʊm tuː leɪt",
        translation: "She usually returns home too late",
      },
      {
        id: 76,
        expression: "Я обычно возвращаюсь домой слишком поздно",
        transcription: "aɪ ˈjuːʒʊəli rɪˈtɜːn həʊm tuː leɪt",
        translation: "I usually return home too late",
      },
      {
        id: 77,
        expression: "Я думаю, он предложит тебе что-то особенное",
        transcription: "aɪ θɪŋk hiːl ˈɒfə jʊ ˈsʌmθɪŋ ˈspɛʃəl",
        translation: "I think he'll offer you something special",
      },
      {
        id: 78,
        expression: "Я думаю, они предложат мне что-то",
        transcription: "aɪ θɪŋk ðeɪl ˈɒfə mi ˈsʌmθɪŋ",
        translation: "I think they'll offer me something",
      },
      {
        id: 79,
        expression: "Я думаю, она предложит тебе что-то особенное",
        transcription: "aɪ θɪŋk ʃiːl ˈɒfə jʊ ˈsʌmθɪŋ ˈspɛʃəl",
        translation: "I think she'll offer you something special",
      },
      {
        id: 80,
        expression: "Она хотела бы съесть торт",
        transcription: "ʃiːd laɪk tʊ iːt ə keɪk",
        translation: "She'd like to eat a cake",
      },
      {
        id: 81,
        expression: "Она хотела бы съесть этот торт",
        transcription: "ʃiːd laɪk tʊ iːt ðɪs keɪk",
        translation: "She'd like to eat this cake",
      },
      {
        id: 82,
        expression: "Я не понимаю, почему он бежит так медленно",
        transcription: "aɪ dəʊnt ˌʌndəˈstænd waɪ hi rʌnz səʊ ˈsləʊli",
        translation: "I don't understand why he runs so slowly",
      },
      {
        id: 83,
        expression: "Они не понимают, почему он бежит так медленно",
        transcription: "ðeɪ dəʊnt ˌʌndəˈstænd waɪ hi rʌnz səʊ ˈsləʊli",
        translation: "They don't understand why he runs so slowly",
      },
      {
        id: 84,
        expression: "Он не понимает, почему он бегает так медленно",
        transcription: "hi dʌznt ˌʌndəˈstænd waɪ hi rʌnz səʊ ˈsləʊli",
        translation: "He doesn't understand why he runs so slowly",
      },
      {
        id: 85,
        expression: "Она хотела бы купить другое платье",
        transcription: "ʃiːd laɪk tə baɪ əˈnʌðə drɛs",
        translation: "She'd like to buy another dress",
      },
      {
        id: 86,
        expression: "Это не приносит людям счастья",
        transcription: "ɪt dʌznt brɪŋ ˈpiːpl ˈhæpɪnɪs",
        translation: "It doesn't bring people happiness",
      },
      {
        id: 87,
        expression: "Он окончил тот университет",
        transcription: "hi ˈgrædjʊeɪtɪd frəm ðət ˌjuːnɪˈvɜːsɪti",
        translation: "He graduated from that university",
      },
      {
        id: 88,
        expression: "Тебе нужно подписать здесь",
        transcription: "jʊ niːd tə saɪn hɪə",
        translation: "You need to sign here",
      },
      {
        id: 89,
        expression: "Она хочет стать художником",
        transcription: "ʃi wɒnts tə bɪˈkʌm ən ˈɑːtɪst",
        translation: "She wants to become an artist",
      },
      {
        id: 90,
        expression: "Ты подпишешь те документы?",
        transcription: "wɪl jʊ saɪn ðəʊz ˈdɒkjʊmənts?",
        translation: "Will you sign those documents?",
      },
      {
        id: 91,
        expression: "Я думаю, это позже принесет ему больше денег",
        transcription: "aɪ θɪŋk ˈɪtl brɪŋ ɪm mɔː ˈmʌni ˈleɪtə",
        translation: "I think it'll bring him more money later",
      },
      {
        id: 92,
        expression: "Он хотел бы обсудить это с тобой",
        transcription: "hiːd laɪk tə dɪsˈkʌs ɪt wɪð juː",
        translation: "He'd like to discuss it with you",
      },
      {
        id: 93,
        expression: "Он решил стать певцом",
        transcription: "hi dɪˈsaɪdɪd tə bɪˈkʌm ə ˈsɪŋə",
        translation: "He decided to become a singer",
      },
      {
        id: 94,
        expression: "Это принесло мне настоящее счастье",
        transcription: "ɪt brɔːt mi rɪəl ˈhæpɪnɪs",
        translation: "It brought me real happiness",
      },
      {
        id: 95,
        expression: "Я положу это здесь",
        transcription: "aɪl pʊt ɪt hɪə",
        translation: "I'll put it here",
      },
      {
        id: 96,
        expression: "Я не думаю, что он продаст это",
        transcription: "aɪ dəʊnt θɪŋk ðət hiːl sɛl ɪt",
        translation: "I don't think that he'll sell it",
      },
      {
        id: 97,
        expression: "Я положил это на землю",
        transcription: "aɪ pʊt ɪt ɒn ðə graʊnd",
        translation: "I put it on the ground",
      },
      {
        id: 98,
        expression: "Он порезался вчера",
        transcription: "hi kʌt hɪmˈsɛlf ˈjɛstədeɪ",
        translation: "He cut himself yesterday",
      },
      {
        id: 99,
        expression: "Он пел свою любимую песню",
        transcription: "hi sæŋ ɪz ˈfeɪvərɪt sɒŋ",
        translation: "He sang his favorite song",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Закрепление пройденного материала</h3>
</div>`,
    },
  ],
];
