export const wordsLesson248 = [
  [
    {
      id: 1,
      word: 'aeroplane',
      transcription: 'ˈeərəpleɪn',
      translation: 'самолет',
    },
    {
      id: 2,
      word: 'approvingly',
      transcription: 'əˈpruːvɪŋli',
      translation: 'одобрительно',
    },
    {
      id: 3,
      word: 'backpack',
      transcription: 'ˈbækˌpæk',
      translation: 'рюкзак',
    },
    {
      id: 4,
      word: 'candle',
      transcription: 'ˈkændl',
      translation: 'свеча',
    },
    {
      id: 5,
      word: 'cheerfully',
      transcription: 'ˈʧɪəfʊli',
      translation: 'весело',
    },
    {
      id: 6,
      word: 'cooperate',
      transcription: 'kəʊˈɒpəˌreɪt',
      translation: 'сотрудничать',
    },
    {
      id: 7,
      word: 'correspondence',
      transcription: 'ˌkɒrɪsˈpɒndəns',
      translation: 'переписка',
    },
    {
      id: 8,
      word: 'fourth',
      transcription: 'fɔːθ',
      translation: 'четвертый',
    },
    {
      id: 9,
      word: 'hesitate',
      transcription: 'ˈhɛzɪteɪt',
      translation: 'колебаться, стесняться',
    },
    {
      id: 10,
      word: 'imply',
      transcription: 'ɪmˈplaɪ',
      translation: 'означать',
    },
    {
      id: 11,
      word: 'justify',
      transcription: 'ˈʤʌstɪfaɪ',
      translation: 'оправдывать',
    },
    {
      id: 12,
      word: 'king',
      transcription: 'kɪŋ',
      translation: 'король',
    },
    {
      id: 13,
      word: 'kneel',
      transcription: 'niːl',
      translation: 'опускаться на колени',
    },
    {
      id: 14,
      word: 'maintain',
      transcription: 'meɪnˈteɪn',
      translation: 'поддерживать',
    },
    {
      id: 15,
      word: 'ninth',
      transcription: 'naɪnθ',
      translation: 'девятый',
    },
    {
      id: 16,
      word: 'nod',
      transcription: 'nɒd',
      translation: 'кивать',
    },
    {
      id: 17,
      word: 'rush',
      transcription: 'rʌʃ',
      translation: 'спешить',
    },
    {
      id: 18,
      word: 'sacrifice',
      transcription: 'ˈsækrɪfaɪs',
      translation: 'жертвовать',
    },
    {
      id: 19,
      word: 'sadden',
      transcription: 'ˈsædn',
      translation: 'печалить',
    },
    {
      id: 20,
      word: 'scale',
      transcription: 'skeɪl',
      translation: 'весы',
    },
    {
      id: 21,
      word: 'scare',
      transcription: 'skeə',
      translation: 'пугать',
    },
    {
      id: 22,
      word: 'tenth',
      transcription: 'tɛnθ',
      translation: 'десятый',
    },
    {
      id: 23,
      word: 'unnaturally',
      transcription: 'ʌnˈnæʧrəli',
      translation: 'неестественно',
    },
    {
      id: 24,
      word: 'violate',
      transcription: 'ˈvaɪəleɪt',
      translation: 'нарушать',
    },
    {
      id: 25,
      word: 'means',
      transcription: 'miːnz',
      translation: 'средства',
    },
    {
      id: 26,
      word: 'rule',
      transcription: 'ruːl',
      translation: 'править',
    },
  ],
];
