export const contentLesson33 = [
  [
    {
      title: "Урок 33. Past Simple. Special Question",
    },
    [
      {
        id: 1,
        expression: "Что ты видел там?",
        transcription: "wɒt dɪd jʊ siː ðeə?",
        translation: "What did you see there?",
      },
      {
        id: 2,
        expression: "Как ты выучил английский?",
        transcription: "haʊ dɪd jʊ lɜːn ˈɪŋglɪʃ?",
        translation: "How did you learn English?",
      },
      {
        id: 3,
        expression: "Когда ты вернулся домой?",
        transcription: "wɛn dɪd jʊ kʌm həʊm? / wɛn dɪd jʊ rɪˈtɜːn həʊm?",
        translation: "When did you come home? / When did you return home?",
      },
      {
        id: 4,
        expression: "Что ты ел на ужин?",
        transcription: "wɒt dɪd jʊ iːt fə ˈdɪnə?",
        translation: "What did you eat for dinner?",
      },
      {
        id: 5,
        expression: "Что ты сказал?",
        transcription: "wɒt dɪd jʊ seɪ?",
        translation: "What did you say?",
      },
      {
        id: 6,
        expression: "Когда ты вернулся домой?",
        transcription: "wɛn dɪd jʊ kʌm bæk həʊm? / wɛn dɪd jʊ rɪˈtɜːn həʊm?",
        translation: "When did you come back home? / When did you return home?",
      },
      {
        id: 7,
        expression: "Что вы обсуждали?",
        transcription: "wɒt dɪd jʊ dɪsˈkʌs?",
        translation: "What did you discuss?",
      },
      {
        id: 8,
        expression: "Когда ты закончил свою работу?",
        transcription: "wɛn dɪd jʊ ˈfɪnɪʃ jə wɜːk?",
        translation: "When did you finish your work?",
      },
      {
        id: 9,
        expression: "Почему ты пришел домой так поздно?",
        transcription:
          "waɪ dɪd jʊ kʌm həʊm səʊ leɪt? / wɒt dɪd jʊ rɪˈtɜːn həʊm səʊ leɪt?",
        translation:
          "Why did you come home so late? / What did you return home so late?",
      },
      {
        id: 10,
        expression: "Как ты понял это?",
        transcription: "haʊ dɪd jʊ ˌʌndəˈstænd ɪt?",
        translation: "How did you understand it?",
      },
      {
        id: 11,
        expression: "Где ты работал раньше?",
        transcription: "weə dɪd jʊ wɜːk ˈɜːlɪə?",
        translation: "Where did you work earlier?",
      },
      {
        id: 12,
        expression: "Почему ты пошел в то место?",
        transcription: "waɪ dɪd jʊ gəʊ tə ðət pleɪs?",
        translation: "Why did you go to that place?",
      },
      {
        id: 13,
        expression: "Как ты выучил английский?",
        transcription: "haʊ dɪd jʊ lɜːn ˈɪŋglɪʃ?",
        translation: "How did you learn English?",
      },
      {
        id: 14,
        expression: "Что ты видел там?",
        transcription: "wɒt dɪd jʊ siː ðeə?",
        translation: "What did you see there?",
      },
      {
        id: 15,
        expression: "Ты видел своих друзей вчера?",
        transcription: "dɪd jʊ siː jə frɛndz ˈjɛstədeɪ?",
        translation: "Did you see your friends yesterday?",
      },
      {
        id: 16,
        expression: "Когда это началось?",
        transcription: "wɛn dɪd ɪt stɑːt?",
        translation: "When did it start?",
      },
      {
        id: 17,
        expression: "Где ты жил раньше?",
        transcription: "weə dɪd jʊ lɪv ˈɜːlɪə?",
        translation: "Where did you live earlier?",
      },
      {
        id: 18,
        expression: "Кого ты видел там?",
        transcription: "huː dɪd jʊ siː ðeə?",
        translation: "Who did you see there?",
      },
      {
        id: 19,
        expression: "Что ты делал вчера?",
        transcription: "wɒt dɪd jʊ dʊ ˈjɛstədeɪ?",
        translation: "What did you do yesterday?",
      },
      {
        id: 20,
        expression: "Что они тебя спросили?",
        transcription: "wɒt dɪd ðeɪ ɑːsk juː?",
        translation: "What did they ask you?",
      },
      {
        id: 21,
        expression: "Как ты улучшил свою речь?",
        transcription: "haʊ dɪd jʊ ɪmˈpruːv jə spiːʧ?",
        translation: "How did you improve your speech?",
      },
      {
        id: 22,
        expression: "Что ты делал прошлым летом?",
        transcription: "wɒt dɪd jʊ dʊ lɑːst ˈsʌmə?",
        translation: "What did you do last summer?",
      },
      {
        id: 23,
        expression: "Когда она звонила тебе в последний раз?",
        transcription: "wɛn dɪd ʃi kɔːl jʊ lɑːst taɪm?",
        translation: "When did she call you last time?",
      },
      {
        id: 24,
        expression: "Почему ты сделал это?",
        transcription: "waɪ dɪd jʊ dʊ ɪt?",
        translation: "Why did you do it?",
      },
      {
        id: 25,
        expression: "Ты посетил Италию в прошлом году?",
        transcription: "dɪd jʊ ˈvɪzɪt ˈɪtəli lɑːst jɪə?",
        translation: "Did you visit Italy last year?",
      },
      {
        id: 26,
        expression: "Почему ты купил этот свитер?",
        transcription: "waɪ dɪd jʊ baɪ ðɪs ˈswɛtə?",
        translation: "Why did you buy this sweater?",
      },
      {
        id: 27,
        expression: "Что ты купил?",
        transcription: "wɒt dɪd jʊ baɪ?",
        translation: "What did you buy?",
      },
      {
        id: 28,
        expression: "Когда он вернулся домой?",
        transcription: "wɛn dɪd hi kʌm bæk həʊm? / wɛn dɪd hi rɪˈtɜːn həʊm?",
        translation: "When did he come back home? / When did he return home?",
      },
      {
        id: 29,
        expression: "Что ты оставил дома?",
        transcription: "wɒt dɪd jʊ liːv ət həʊm?",
        translation: "What did you leave at home?",
      },
      {
        id: 30,
        expression: "Где ты играл?",
        transcription: "weə dɪd jʊ pleɪ?",
        translation: "Where did you play?",
      },
      {
        id: 31,
        expression: "Как это началось?",
        transcription: "haʊ dɪd ɪt stɑːt? / haʊ dɪd ɪt bɪˈgɪn?",
        translation: "How did it start? / How did it begin?",
      },
      {
        id: 32,
        expression: "Что она ответила?",
        transcription: "wɒt dɪd ʃi ˈɑːnsə?",
        translation: "What did she answer?",
      },
      {
        id: 33,
        expression: "Что ты делал прошлой зимой?",
        transcription: "wɒt dɪd jʊ dʊ lɑːst ˈwɪntə?",
        translation: "What did you do last winter?",
      },
      {
        id: 34,
        expression: "Что он ответил тебе?",
        transcription: "wɒt dɪd hi ˈɑːnsə juː?",
        translation: "What did he answer you?",
      },
      {
        id: 35,
        expression: "Что ты выбрал?",
        transcription: "wɒt dɪd jʊ ʧuːz?",
        translation: "What did you choose?",
      },
      {
        id: 36,
        expression: "Что он сказал тебе?",
        transcription: "wɒt dɪd hi tɛl juː?",
        translation: "What did he tell you?",
      },
      {
        id: 37,
        expression: "Когда ты последний раз его видел?",
        transcription: "wɛn dɪd jʊ siː ɪm lɑːst taɪm?",
        translation: "When did you see him last time?",
      },
      {
        id: 38,
        expression: "Почему ты поверил этому?",
        transcription: "waɪ dɪd jʊ bɪˈliːv ɪt?",
        translation: "Why did you believe it?",
      },
      {
        id: 39,
        expression: "Как она себя чувствовала?",
        transcription: "haʊ dɪd ʃi fiːl?",
        translation: "How did she feel?",
      },
      {
        id: 40,
        expression: "Когда ты получил это письмо?",
        transcription: "wɛn dɪd jʊ gɛt ðɪs ˈlɛtə?",
        translation: "When did you get this letter?",
      },
      {
        id: 41,
        expression: "Где вы встретились?",
        transcription: "weə dɪd jʊ miːt?",
        translation: "Where did you meet?",
      },
      {
        id: 42,
        expression: "Почему это стало так популярно?",
        transcription: "waɪ dɪd ɪt bɪˈkʌm səʊ ˈpɒpjʊlə?",
        translation: "Why did it become so popular?",
      },
      {
        id: 43,
        expression: "Где ты нашел эту информацию?",
        transcription: "weə dɪd jʊ faɪnd ðɪs ˌɪnfəˈmeɪʃən?",
        translation: "Where did you find this information?",
      },
      {
        id: 44,
        expression: "Когда ты получил этот ответ?",
        transcription: "wɛn dɪd jʊ gɛt ðɪs ˈɑːnsə?",
        translation: "When did you get this answer?",
      },
      {
        id: 45,
        expression: "Как регулярно ты делал эти упражнения?",
        transcription: "haʊ ˈrɛgjʊləli dɪd jʊ dʊ ðiːz ˈɛksəsaɪzɪz?",
        translation: "How regularly did you do these exercises?",
      },
      {
        id: 46,
        expression: "Что ей было нужно?",
        transcription: "wɒt dɪd ʃi niːd?",
        translation: "What did she need?",
      },
      {
        id: 47,
        expression: "Когда ты прибыл домой?",
        transcription: "wɛn dɪd jʊ əˈraɪv həʊm?",
        translation: "When did you arrive home?",
      },
      {
        id: 48,
        expression: "Что ты делал на выходных?",
        transcription: "wɒt dɪd jʊ dʊ ət ðə ˈwiːkˈɛnd?",
        translation: "What did you do at the weekend?",
      },
      {
        id: 49,
        expression: "Сколько денег это им принесло?",
        transcription: "haʊ mʌʧ ˈmʌni dɪd ɪt brɪŋ ðɛm?",
        translation: "How much money did it bring them?",
      },
      {
        id: 50,
        expression: "Во сколько ты проснулся?",
        transcription: "wɒt taɪm dɪd jʊ weɪk ʌp?",
        translation: "What time did you wake up?",
      },
      {
        id: 51,
        expression: "В какие игры ты играл?",
        transcription: "wɒt geɪmz dɪd jʊ pleɪ?",
        translation: "What games did you play?",
      },
      {
        id: 52,
        expression: "Что у тебя было на ужин?",
        transcription: "wɒt dɪd jʊ həv fə ˈdɪnə?",
        translation: "What did you have for dinner?",
      },
      {
        id: 53,
        expression: "Ты получил это письмо или нет?",
        transcription: "dɪd jʊ gɛt ðɪs ˈlɛtər ɔː nɒt?",
        translation: "Did you get this letter or not?",
      },
      {
        id: 54,
        expression: "Сколько у тебя было свободного времени?",
        transcription: "haʊ mʌʧ friː taɪm dɪd jʊ hæv?",
        translation: "How much free time did you have?",
      },
      {
        id: 55,
        expression: "Ты получил это электронное письмо или нет?",
        transcription: "dɪd jʊ gɛt ðɪs ˈiːmeɪl ɔː nɒt?",
        translation: "Did you get this email or not?",
      },
      {
        id: 56,
        expression: "Что ты делал на прошлых выходных?",
        transcription: "wɒt dɪd jʊ dʊ lɑːst ˈwiːkˈɛnd?",
        translation: "What did you do last weekend?",
      },
      {
        id: 57,
        expression: "Что тебе нужно было сделать?",
        transcription: "wɒt dɪd jʊ niːd tə duː?",
        translation: "What did you need to do?",
      },
      {
        id: 58,
        expression: "Какой отель ты выбрал?",
        transcription: "wɪʧ həʊˈtɛl dɪd jʊ ʧuːz?",
        translation: "Which hotel did you choose?",
      },
      {
        id: 59,
        expression: "Почему ты решил изучать английский язык?",
        transcription: "waɪ dɪd jʊ dɪˈsaɪd tə lɜːn ˈɪŋglɪʃ?",
        translation: "Why did you decide to learn English?",
      },
      {
        id: 60,
        expression: "Как часто ты тренировался?",
        transcription: "haʊ ˈɒf(ə)n dɪd jʊ treɪn?",
        translation: "How often did you train?",
      },
      {
        id: 61,
        expression: "Как регулярно она тренировалась?",
        transcription: "haʊ ˈrɛgjʊləli dɪd ʃi treɪn?",
        translation: "How regularly did she train?",
      },
      {
        id: 62,
        expression: "Сколько он заплатил?",
        transcription: "haʊ mʌʧ dɪd hi peɪ?",
        translation: "How much did he pay?",
      },
      {
        id: 63,
        expression: "Сколько она заплатила за это?",
        transcription: "haʊ mʌʧ dɪd ʃi peɪ fər ɪt?",
        translation: "How much did she pay for it?",
      },
      {
        id: 64,
        expression: "Сколько ты заплатил?",
        transcription: "haʊ mʌʧ dɪd jʊ peɪ?",
        translation: "How much did you pay?",
      },
      {
        id: 65,
        expression: "Что он ел на обед?",
        transcription: "wɒt dɪd hi iːt fə lʌnʧ?",
        translation: "What did he eat for lunch?",
      },
      {
        id: 66,
        expression: "Что он ел на завтрак?",
        transcription: "wɒt dɪd hi iːt fə ˈbrɛkfəst?",
        translation: "What did he eat for breakfast?",
      },
      {
        id: 67,
        expression: "Что ты ел на ужин?",
        transcription: "wɒt dɪd jʊ iːt fə ˈdɪnə?",
        translation: "What did you eat for dinner?",
      },
      {
        id: 68,
        expression: "Сколько у тебя было денег с собой?",
        transcription: "haʊ mʌʧ ˈmʌni dɪd jʊ həv wɪð juː?",
        translation: "How much money did you have with you?",
      },
      {
        id: 69,
        expression: "Сколько у тебя было денег?",
        transcription: "haʊ mʌʧ ˈmʌni dɪd jʊ hæv?",
        translation: "How much money did you have?",
      },
      {
        id: 70,
        expression: "Сколько у тебя было свободного времени?",
        transcription: "haʊ mʌʧ friː taɪm dɪd jʊ hæv?",
        translation: "How much free time did you have?",
      },
      {
        id: 71,
        expression: "Как часто ты ходил в спортивный зал?",
        transcription: "haʊ ˈɒf(ə)n dɪd jʊ gəʊ tə ðə ʤɪm?",
        translation: "How often did you go to the gym?",
      },
      {
        id: 72,
        expression: "Когда ты ходил в спортивный зал?",
        transcription: "wɛn dɪd jʊ gəʊ tə ðə ʤɪm?",
        translation: "When did you go to the gym?",
      },
      {
        id: 73,
        expression: "Во сколько ты пришел на работу?",
        transcription: "wɒt taɪm dɪd jʊ kʌm tə wɜːk?",
        translation: "What time did you come to work?",
      },
      {
        id: 74,
        expression: "Когда ты пришел на работу?",
        transcription: "wɛn dɪd jʊ kʌm tə wɜːk?",
        translation: "When did you come to work?",
      },
      {
        id: 75,
        expression: "Что ты ел на завтрак?",
        transcription: "wɒt dɪd jʊ iːt fə ˈbrɛkfəst?",
        translation: "What did you eat for breakfast?",
      },
      {
        id: 76,
        expression: "Что ты решил делать?",
        transcription: "wɒt dɪd jʊ dɪˈsaɪd tə duː?",
        translation: "What did you decide to do?",
      },
      {
        id: 77,
        expression: "Что он решил делать?",
        transcription: "wɒt dɪd hi dɪˈsaɪd tə duː?",
        translation: "What did he decide to do?",
      },
      {
        id: 78,
        expression: "Что ты решил купить?",
        transcription: "wɒt dɪd jʊ dɪˈsaɪd tə baɪ?",
        translation: "What did you decide to buy?",
      },
      {
        id: 79,
        expression: "Как часто ты играл в футбол?",
        transcription: "haʊ ˈɒf(ə)n dɪd jʊ pleɪ ˈfʊtbɔːl?",
        translation: "How often did you play football?",
      },
      {
        id: 80,
        expression: "Как ты сюда добрался?",
        transcription: "haʊ dɪd jʊ gɛt hɪə?",
        translation: "How did you get here?",
      },
      {
        id: 81,
        expression: "Почему ты решил остаться дома?",
        transcription: "waɪ dɪd jʊ dɪˈsaɪd tə steɪ ət həʊm?",
        translation: "Why did you decide to stay at home?",
      },
      {
        id: 82,
        expression: "Сколько ошибок он сделал?",
        transcription: "haʊ ˈmɛni mɪsˈteɪks dɪd hi meɪk?",
        translation: "How many mistakes did he make?",
      },
      {
        id: 83,
        expression: "Сколько ты заплатил за это?",
        transcription: "haʊ mʌʧ dɪd jʊ peɪ fər ɪt?",
        translation: "How much did you pay for it?",
      },
      {
        id: 84,
        expression: "Во сколько ты пришел домой?",
        transcription: "wɒt taɪm dɪd jʊ kʌm həʊm?",
        translation: "What time did you come home?",
      },
      {
        id: 85,
        expression: "Которая песня тебе понравилась больше?",
        transcription: "wɪʧ sɒŋ dɪd jʊ laɪk mɔː?",
        translation: "Which song did you like more?",
      },
      {
        id: 86,
        expression: "Сколько ты работал?",
        transcription: "haʊ mʌʧ dɪd jʊ wɜːk?",
        translation: "How much did you work?",
      },
      {
        id: 87,
        expression: "Как ты обычно проводил свое свободное время?",
        transcription: "haʊ dɪd jʊ ˈjuːʒʊəli spɛnd jə friː taɪm?",
        translation: "How did you usually spend your free time?",
      },
      {
        id: 88,
        expression: "Что ты хотел сделать?",
        transcription: "wɒt dɪd jʊ wɒnt tə duː?",
        translation: "What did you want to do?",
      },
      {
        id: 89,
        expression: "Какую книгу ты прочитал?",
        transcription: "wɒt bʊk dɪd jʊ riːd?",
        translation: "What book did you read?",
      },
      {
        id: 90,
        expression: "Сколько ошибок ты сделал?",
        transcription: "haʊ ˈmɛni mɪsˈteɪks dɪd jʊ meɪk?",
        translation: "How many mistakes did you make?",
      },
      {
        id: 91,
        expression: "Как ты жил на эти деньги?",
        transcription: "haʊ dɪd jʊ lɪv ɒn ðɪs ˈmʌni?",
        translation: "How did you live on this money?",
      },
      {
        id: 92,
        expression: "Сколько это стоило?",
        transcription: "haʊ mʌʧ dɪd ɪt kɒst?",
        translation: "How much did it cost?",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Специальные вопросы в Past Simple</h3>
<p>Задаются по формуле:</p>
<p><span>вопросительное слово + did + подлежащее + глагол в первой форме</span></p>
<p>What did he answer you? - Что он тебе ответил?</p>
<p>К устойчивым выражениям:</p>
<h3>return home</h3>
<p>- возвращаться домой</p>
<p>When did you return home? - Когда ты вернулся домой?</p>
<h3>for breakfast / lunch / dinner</h3>
<p>- на завтрак / на обед / на ужин</p>
<p>What did you eat for breakfast? - Что ты ел на завтрак?</p>
</div>`,
    },
  ],
];
