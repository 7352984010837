export const contentLesson131 = [
  [
    {
      title: 'Урок 131. 360 Verbs. Part 5',
    },
    [
      {
        id: 1,
        expression: 'Они снизили расходы',
        transcription: 'ðeɪ rɪˈdjuːst ðə kɒst',
        translation: 'They reduced the cost',
      },
      {
        id: 2,
        expression: 'Это пахло действительно замечательно',
        transcription: 'ɪt smɛlt ˈrɪəli ˈwʌndəfʊl',
        translation: 'It smelt really wonderful',
      },
      {
        id: 3,
        expression: 'Ты можешь поделиться этой информацией с нами?',
        transcription: 'kən jʊ ʃeə ðɪs ˌɪnfəˈmeɪʃən wɪð ʌs?',
        translation: 'Can you share this information with us?',
      },
      {
        id: 4,
        expression: 'Почему ты бросил свою работу?',
        transcription: 'waɪ dɪd jʊ kwɪt jə ʤɒb?',
        translation: 'Why did you quit your job?',
      },
      {
        id: 5,
        expression:
          'Она пожертвовала свою старую одежду на благотворительность',
        transcription: 'ʃi dəʊˈneɪtɪd hər əʊld kləʊðz tə ˈʧærɪti',
        translation: 'She donated her old clothes to charity',
      },
      {
        id: 6,
        expression: 'Какие вещи больше тебя мотивируют?',
        transcription: 'wɒt θɪŋz ˈməʊtɪveɪt jʊ mɔː?',
        translation: 'What things motivate you more?',
      },
      {
        id: 7,
        expression: 'Он создал очень прибыльный бизнес',
        transcription: 'hi sɛt ʌp ə ˈvɛri ˈprɒfɪtəbl ˈbɪznɪs',
        translation: 'He set up a very profitable business',
      },
      {
        id: 8,
        expression: 'Кто запер дверь?',
        transcription: 'huː lɒkt ðə dɔː?',
        translation: 'Who locked the door?',
      },
      {
        id: 9,
        expression: 'Сколько ты весишь?',
        transcription: 'haʊ mʌʧ dʊ jʊ weɪ?',
        translation: 'How much do you weigh?',
      },
      {
        id: 10,
        expression: 'Когда он увидел нас, он поблагодарил меня за мою помощь',
        transcription: 'wɛn hi sɔː ʌs, hi θæŋkt mi fə maɪ hɛlp',
        translation: 'When he saw us, he thanked me for my help',
      },
      {
        id: 11,
        expression: 'Мне пришлось заполнить эту форму',
        transcription: 'aɪ həd tə fɪl ɪn ðə fɔːm',
        translation: 'I had to fill in the form',
      },
      {
        id: 12,
        expression: 'Он выразил свои сомнения',
        transcription: 'hi ɪksˈprɛst ɪz daʊts',
        translation: 'He expressed his doubts',
      },
      {
        id: 13,
        expression: 'Он владеет этим бизнесом',
        transcription: 'hi əʊnz ðɪs ˈbɪznɪs',
        translation: 'He owns this business',
      },
      {
        id: 14,
        expression: 'Нам нужно заполнить пробелы',
        transcription: 'wi niːd tə fɪl ɪn ðə gæps',
        translation: 'We need to fill in the gaps',
      },
      {
        id: 15,
        expression: 'Он не согласен с этим решением',
        transcription:
          'hi dʌznt əˈgriː wɪð ðɪs dɪˈsɪʒən / hi ˌdɪsəˈgriːz wɪð ðɪs dɪˈsɪʒən',
        translation:
          "He doesn't agree with this decision / He disagrees with this decision",
      },
      {
        id: 16,
        expression: 'Это так хорошо пахнет',
        transcription: 'ɪt smɛlz səʊ gʊd',
        translation: 'It smells so good',
      },
      {
        id: 17,
        expression: 'Тебе нравится нырять?',
        transcription: 'dʊ jʊ laɪk ˈdaɪvɪŋ?',
        translation: 'Do you like diving?',
      },
      {
        id: 18,
        expression: 'Он не может сказать этого сейчас',
        transcription: 'hi kɑːnt seɪ ɪt naʊ',
        translation: "He can't say it now",
      },
      {
        id: 19,
        expression: 'Я очень ценю твою дружбу',
        transcription:
          'aɪ ˈvæljuː jə ˈfrɛndʃɪp ə lɒt / aɪ ˈrɪəli ˈvæljuː jə ˈfrɛndʃɪp',
        translation:
          'I value your friendship a lot / I really value your friendship',
      },
      {
        id: 20,
        expression: 'Он жертвует миллионы на благотворительность',
        transcription: 'hi dəʊˈneɪts ˈmɪljənz tə ˈʧærɪti',
        translation: 'He donates millions to charity',
      },
      {
        id: 21,
        expression: 'Я люблю тебя всем сердцем',
        transcription: 'aɪ lʌv jʊ wɪð ɔːl maɪ hɑːt',
        translation: 'I love you with all my heart',
      },
      {
        id: 22,
        expression: 'Я разделяю твою точку зрения',
        transcription: 'aɪ ʃeə jʊ pɔɪnt əv vjuː',
        translation: 'I share you point of view',
      },
      {
        id: 23,
        expression: 'Он не пьет кофе',
        transcription: 'hi dʌznt drɪŋk ˈkɒfi',
        translation: "He doesn't drink coffee",
      },
      {
        id: 24,
        expression: 'Я обожаю проводить время со своими друзьями',
        transcription: 'aɪ ɪnˈʤɔɪ ˈspɛndɪŋ taɪm wɪð maɪ frɛndz',
        translation: 'I enjoy spending time with my friends',
      },
      {
        id: 25,
        expression: 'Она подмела пол',
        transcription: 'ʃi swɛpt ðə flɔː',
        translation: 'She swept the floor',
      },
      {
        id: 26,
        expression: 'Он любим и уважаем',
        transcription: 'hi z lʌvd ənd rɪsˈpɛktɪd',
        translation: 'He is loved and respected',
      },
      {
        id: 27,
        expression: 'Он приветствовал гостей',
        transcription: 'hi ˈwɛlkəmd ðə gɛsts',
        translation: 'He welcomed the guests',
      },
      {
        id: 28,
        expression: 'Я хотел бросить свою работу',
        transcription: 'aɪ ˈwɒntɪd tə kwɪt maɪ ʤɒb',
        translation: 'I wanted to quit my job',
      },
      {
        id: 29,
        expression: 'Какая речь вдохновляет тебя больше',
        transcription: 'wɪʧ spiːʧ ɪnˈspaɪəz jʊ mɔː?',
        translation: 'Which speech inspires you more?',
      },
      {
        id: 30,
        expression: 'Она приветствовала посетителей',
        transcription: 'ʃi ˈwɛlkəmd ðə ˈvɪzɪtəz',
        translation: 'She welcomed the visitors',
      },
      {
        id: 31,
        expression: 'Почему они так много ссорятся?',
        transcription: 'waɪ dʊ ðeɪ ˈkwɒrəl səʊ mʌʧ?',
        translation: 'Why do they quarrel so much?',
      },
      {
        id: 32,
        expression: 'Я могу сделать это вместо тебя',
        transcription: 'aɪ kən dʊ ɪt ɪnˈstɛd əv juː',
        translation: 'I can do it instead of you',
      },
      {
        id: 33,
        expression: 'Она выразила свою точку зрения',
        transcription: 'ʃi ɪksˈprɛst hə pɔɪnt əv vjuː',
        translation: 'She expressed her point of view',
      },
      {
        id: 34,
        expression: 'Я действительно восхищаюсь такими людьми',
        transcription: 'aɪ ˈrɪəli ədˈmaɪə sʌʧ ˈpiːpl',
        translation: 'I really admire such people',
      },
      {
        id: 35,
        expression: 'Я действительно люблю кататься на велосипеде',
        transcription: 'aɪ ˈrɪəli laɪk ˈsaɪklɪŋ / aɪm ˈrɪəli fɒnd əv ˈsaɪklɪŋ',
        translation: "I really like cycling / I'm really fond of cycling",
      },
      {
        id: 36,
        expression: 'Мне нужно установить будильник',
        transcription: 'aɪ niːd tə sɛt ði əˈlɑːm klɒk',
        translation: 'I need to set the alarm clock',
      },
      {
        id: 37,
        expression: 'Я хотел бы поблагодарить тебя за все',
        transcription: 'aɪd laɪk tə θæŋk jʊ fər ˈɛvrɪθɪŋ',
        translation: "I'd like to thank you for everything",
      },
      {
        id: 38,
        expression: 'Ты владеешь этим домом или арендуешь его?',
        transcription: 'dʊ jʊ əʊn ðɪs haʊs ɔː rɛnt ɪt?',
        translation: 'Do you own this house or rent it?',
      },
      {
        id: 39,
        expression: 'Он интересуется дайвингом',
        transcription: 'hiːz ˈɪntrɪstɪd ɪn ˈdaɪvɪŋ',
        translation: "He's interested in diving",
      },
      {
        id: 40,
        expression: 'Я ценю твои советы и поддержку',
        transcription: 'aɪ ˈvæljuː jər ədˈvaɪsɪz ənd səˈpɔːt',
        translation: 'I value your advices and support',
      },
      {
        id: 41,
        expression:
          'Компания пытается мотивировать своих работников различными бонусами',
        transcription:
          'ðə ˈkʌmpəni traɪz tə ˈməʊtɪveɪt ɪts ˌɛmplɔɪˈiːz wɪð ˈdɪfrənt ˈbəʊnəsɪz',
        translation:
          'The company tries to motivate its employees with different bonuses',
      },
      {
        id: 42,
        expression: 'Если он не поторопится, он опоздает',
        transcription: 'ɪf hi dʌznt ˈhʌri ʌp, hiːl bi leɪt',
        translation: "If he doesn't hurry up, he'll be late",
      },
      {
        id: 43,
        expression: 'Он преодолел все эти трудности на своем пути',
        transcription: 'hi ˌəʊvəˈkeɪm ɔːl ðiːz ˈdɪfɪkəltiz ɒn ɪz weɪ',
        translation: 'He overcame all these difficulties on his way',
      },
      {
        id: 44,
        expression: 'Было очень жаль видеть, что они вели себя так аморально',
        transcription:
          'ɪt wəz ə rɪəl ˈpɪti tə siː ðət ðeɪ wə bɪˈheɪvɪŋ səʊ ɪˈmɒrəli',
        translation:
          'It was a real pity to see that they were behaving so immorally',
      },
      {
        id: 45,
        expression: 'Я вспомнил, что я оставил ключи дома',
        transcription: 'aɪ rɪˈmɛmbəd ðət aɪd lɛft ðə kiːz ət həʊm',
        translation: "I remembered that I'd left the keys at home",
      },
      {
        id: 46,
        expression: 'Он сказал мне, что он еще не скачал эту программу',
        transcription:
          'hi təʊld mi ðət hi ˈhædnt ˌdaʊnˈləʊdɪd ðɪs ˈprəʊgræm jɛt',
        translation: "He told me that he hadn't downloaded this program yet",
      },
      {
        id: 47,
        expression: 'Я буду сидеть дома послезавтра в это время',
        transcription: 'aɪl bi ˈsteɪɪŋ ət həʊm ðɪs taɪm ðə deɪ ˈɑːftə təˈmɒrəʊ',
        translation: "I'll be staying at home this time the day after tomorrow",
      },
      {
        id: 48,
        expression: 'Я всегда восхищался такими людьми',
        transcription: 'aɪv ˈɔːlweɪz ədˈmaɪəd ðɪs kaɪnd əv ˈpiːpl',
        translation: "I've always admired this kind of people",
      },
      {
        id: 49,
        expression: 'Я осознал, что я что-то оставил дома',
        transcription: 'aɪ ˈrɪəlaɪzd ðət aɪd lɛft ˈsʌmθɪŋ ət həʊm',
        translation: "I realised that I'd left something at home",
      },
      {
        id: 50,
        expression: 'Сколько ты сейчас весишь?',
        transcription: 'haʊ mʌʧ dʊ jʊ weɪ naʊ?',
        translation: 'How much do you weigh now?',
      },
      {
        id: 51,
        expression: 'Я улыбнулся ей, и она улыбнулась в ответ',
        transcription: 'aɪ smaɪld ət hər ənd ʃi smaɪld bæk',
        translation: 'I smiled at her and she smiled back',
      },
      {
        id: 52,
        expression: 'Ей наконец удалось преодолеть свой страх',
        transcription: 'ʃi ˈfaɪnəli ˈmænɪʤd tʊ ˌəʊvəˈkʌm hə fɪə',
        translation: 'She finally managed to overcome her fear',
      },
      {
        id: 53,
        expression: 'Когда я позвонил ей, она спала',
        transcription: 'wɛn aɪ kɔːld hɜː, ʃi wəz ˈsliːpɪŋ',
        translation: 'When I called her, she was sleeping',
      },
      {
        id: 54,
        expression: 'Он знал, что было бесполезно жаловаться на это',
        transcription: 'hi njuː ðət ɪt wəz ˈjuːslɪs tə kəmˈpleɪn əˈbaʊt ɪt',
        translation: 'He knew that it was useless to complain about it',
      },
      {
        id: 55,
        expression: 'Мы живем в этом отеле две недели',
        transcription: 'wiːv biːn ˈsteɪɪŋ ɪn ðɪs həʊˈtɛl fə tuː wiːks',
        translation: "We've been staying in this hotel for two weeks",
      },
      {
        id: 56,
        expression: 'Он ведет себя как ребенок',
        transcription: 'hi bɪˈheɪvz laɪk ə ʧaɪld',
        translation: 'He behaves like a child',
      },
      {
        id: 57,
        expression: 'Они ведут себя как дети',
        transcription: 'ðeɪ bɪˈheɪv laɪk ˈʧɪldrən',
        translation: 'They behave like children',
      },
      {
        id: 58,
        expression: 'Она вела себя как взрослая',
        transcription: 'ʃi bɪˈheɪvd laɪk ən ˈædʌlt',
        translation: 'She behaved like an adult',
      },
      {
        id: 59,
        expression: 'Она сказала это улыбаясь',
        transcription: 'ʃi sɛd ɪt ˈsmaɪlɪŋ',
        translation: 'She said it smiling',
      },
      {
        id: 60,
        expression: 'Она сказала это с улыбкой',
        transcription: 'ʃi sɛd ɪt wɪð ə smaɪl',
        translation: 'She said it with a smile',
      },
      {
        id: 61,
        expression: 'Она сказала это плача',
        transcription: 'ʃi sɛd ɪt ˈkraɪɪŋ',
        translation: 'She said it crying',
      },
      {
        id: 62,
        expression: 'Я скучаю по тебе так сильно сейчас',
        transcription: 'aɪ mɪs jʊ səʊ mʌʧ naʊ',
        translation: 'I miss you so much now',
      },
      {
        id: 63,
        expression: 'Я люблю тебя так сильно',
        transcription: 'aɪ lʌv jʊ səʊ mʌʧ',
        translation: 'I love you so much',
      },
      {
        id: 64,
        expression: 'Я жду тебя сейчас',
        transcription: 'aɪm ˈweɪtɪŋ fə jʊ naʊ',
        translation: "I'm waiting for you now",
      },
      {
        id: 65,
        expression: 'Ты хотел бы что-нибудь выпить?',
        transcription: 'wəd jʊ laɪk ˈsʌmθɪŋ tə drɪŋk?',
        translation: 'Would you like something to drink?',
      },
      {
        id: 66,
        expression: 'Ты хотел бы что-нибудь съесть?',
        transcription: 'wəd jʊ laɪk ˈsʌmθɪŋ tʊ iːt?',
        translation: 'Would you like something to eat?',
      },
      {
        id: 67,
        expression: 'Что ты предпочитаешь пить?',
        transcription: 'wɒt dʊ jʊ priˈfɜː tə drɪŋk?',
        translation: 'What do you prefer to drink?',
      },
      {
        id: 68,
        expression: 'Я осознал, что я совершил ошибку',
        transcription: 'aɪ ˈrɪəlaɪzd ðət aɪd meɪd ə mɪsˈteɪk',
        translation: "I realised that I'd made a mistake",
      },
      {
        id: 69,
        expression: 'Я осознал, что я совершил так много ошибок',
        transcription: 'aɪ ˈrɪəlaɪzd ðət aɪd meɪd səʊ ˈmɛni mɪsˈteɪks',
        translation: "I realised that I'd made so many mistakes",
      },
      {
        id: 70,
        expression: 'Я думал, что я совершил много ошибок',
        transcription: 'aɪ θɔːt ðət aɪd meɪd səʊ ˈmɛni mɪsˈteɪks',
        translation: "I thought that I'd made so many mistakes",
      },
      {
        id: 71,
        expression: 'Какая вещь мотивирует тебя больше всего?',
        transcription: 'wɒt θɪŋ ˈməʊtɪveɪt jʊ məʊst əv ɔːl?',
        translation: 'What thing motivate you most of all?',
      },
      {
        id: 72,
        expression: 'Эта вещь мотивирует меня больше всего',
        transcription: 'ðɪs θɪŋ ˈməʊtɪveɪts mi məʊst əv ɔːl',
        translation: 'This thing motivates me most of all',
      },
      {
        id: 73,
        expression: 'Кто мотивирует тебя больше всего?',
        transcription: 'huː ˈməʊtɪveɪts jʊ məʊst əv ɔːl?',
        translation: 'Who motivates you most of all?',
      },
      {
        id: 74,
        expression: 'Все файлы были скачаны',
        transcription: 'ɔːl ðə faɪlz wə ˌdaʊnˈləʊdɪd',
        translation: 'All the files were downloaded',
      },
      {
        id: 75,
        expression: 'Все эти файлы были удалены',
        transcription: 'ɔːl ðiːz faɪlz wə dɪˈliːtɪd',
        translation: 'All these files were deleted',
      },
      {
        id: 76,
        expression: 'Все письма были написаны',
        transcription: 'ɔːl ðə ˈlɛtəz wə ˈrɪtn',
        translation: 'All the letters were written',
      },
      {
        id: 77,
        expression: 'Это также производится в Китае?',
        transcription: 'ɪz ɪt ˈɔːlsəʊ prəˈdjuːst ɪn ˈʧaɪnə?',
        translation: 'Is it also produced in China?',
      },
      {
        id: 78,
        expression: 'Это было произведено в Китае',
        transcription: 'ɪt wəz prəˈdjuːst ɪn ˈʧaɪnə',
        translation: 'It was produced in China',
      },
      {
        id: 79,
        expression: 'Я смогу заработать там больше денег',
        transcription: 'aɪl bi ˈeɪbl tʊ ɜːn mɔː ˈmʌni ðeə',
        translation: "I'll be able to earn more money there",
      },
      {
        id: 80,
        expression: 'Я не смогу заработать так много денег',
        transcription: 'aɪ wəʊnt bi ˈeɪbl tʊ ɜːn səʊ mʌʧ ˈmʌni',
        translation: "I won't be able to earn so much money",
      },
      {
        id: 81,
        expression: 'Я смогу одолжить тебе эти деньги',
        transcription: 'aɪl bi ˈeɪbl tə lɛnd jʊ ðɪs ˈmʌni',
        translation: "I'll be able to lend you this money",
      },
      {
        id: 82,
        expression: 'Это, возможно, изменит твой образ мышления',
        transcription: 'ɪt meɪ ʧeɪnʤ jə weɪ əv ˈθɪŋkɪŋ',
        translation: 'It may change your way of thinking',
      },
      {
        id: 83,
        expression: 'Это изменит его образ мышления',
        transcription: 'ˈɪtl ʧeɪnʤ ɪz weɪ əv ˈθɪŋkɪŋ',
        translation: "It'll change his way of thinking",
      },
      {
        id: 84,
        expression: 'Это, возможно, изменит твое мнение',
        transcription: 'ɪt meɪ ʧeɪnʤ jər əˈpɪnjən',
        translation: 'It may change your opinion',
      },
      {
        id: 85,
        expression: 'Это также производится в Китае',
        transcription: 'ɪt s ˈɔːlsəʊ prəˈdjuːst ɪn ˈʧaɪnə',
        translation: 'It is also produced in China',
      },
      {
        id: 86,
        expression: 'Он пожаловался на это менеджеру',
        transcription: 'hi kəmˈpleɪnd əˈbaʊt ɪt tə ðə ˈmænɪʤə',
        translation: 'He complained about it to the manager',
      },
      {
        id: 87,
        expression:
          'Это общеизвестный факт, что большинство вещей производятся в Китае',
        transcription:
          'ɪts ə wɛl-nəʊn fækt ðət məʊst θɪŋz ə prəˈdjuːst ɪn ˈʧaɪnə',
        translation:
          "It's a well-known fact that most things are produced in China",
      },
      {
        id: 88,
        expression: 'Я вспомнил, что я забыл сказать что-то',
        transcription: 'aɪ rɪˈmɛmbəd ðət aɪd fəˈgɒtn tə seɪ ˈsʌmθɪŋ',
        translation: "I remembered that I'd forgotten to say something",
      },
      {
        id: 89,
        expression: 'Пока она подметала пол, я готовил',
        transcription: 'waɪl ʃi wəz ˈswiːpɪŋ ðə flɔː, aɪ wəz ˈkʊkɪŋ',
        translation: 'While she was sweeping the floor, I was cooking',
      },
      {
        id: 90,
        expression: 'Пока я катался на велосипеде, он катался на роликах',
        transcription: 'waɪl aɪ wəz ˈsaɪklɪŋ, hi wəz ˈrəʊləˌbleɪdɪŋ',
        translation: 'While I was cycling, he was rollerblading',
      },
      {
        id: 91,
        expression: 'Ты хочешь что-нибудь выпить?',
        transcription: 'dʊ jʊ wɒnt ˈsʌmθɪŋ tə drɪŋk?',
        translation: 'Do you want something to drink?',
      },
      {
        id: 92,
        expression: 'Это, возможно, приведет к новым протестам',
        transcription: 'ɪt meɪ liːd tə njuː ˈprəʊtɛsts',
        translation: 'It may lead to new protests',
      },
      {
        id: 93,
        expression: 'Он зарабатывает слишком мало денег в настоящее время',
        transcription: 'hi ɜːnz tuː ˈlɪtl ˈmʌni ət ˈprɛznt',
        translation: 'He earns too little money at present',
      },
      {
        id: 94,
        expression: 'Я не собираюсь жаловаться на это',
        transcription: 'aɪm nɒt ˈgəʊɪŋ tə kəmˈpleɪn əˈbaʊt ɪt',
        translation: "I'm not going to complain about it",
      },
      {
        id: 95,
        expression: 'Я всегда обожал это делать',
        transcription: 'aɪv ˈɔːlweɪz ɪnˈʤɔɪd ˈdu(ː)ɪŋ ɪt',
        translation: "I've always enjoyed doing it",
      },
      {
        id: 96,
        expression: 'Это было сказано снова и снова',
        transcription: 'ɪt wəz sɛd əˈgɛn ənd əˈgɛn',
        translation: 'It was said again and again',
      },
      {
        id: 97,
        expression: 'Я думаю, что это будет сказано скоро',
        transcription: 'aɪ θɪŋk ˈɪtl bi sɛd suːn',
        translation: "I think it'll be said soon",
      },
      {
        id: 98,
        expression: 'Она мне еще не звонила',
        transcription: 'ʃi ˈhæznt kɔːld mi jɛt',
        translation: "She hasn't called me yet",
      },
      {
        id: 99,
        expression: 'Они стараются не ссориться',
        transcription: 'ðeɪ traɪ nɒt tə ˈkwɒrəl',
        translation: 'They try not to quarrel',
      },
      {
        id: 100,
        expression: 'Почему дверь была заперта?',
        transcription: 'waɪ wəz ðə dɔː lɒkt?',
        translation: 'Why was the door locked?',
      },
      {
        id: 101,
        expression: 'Я сделаю это, если не возражаешь',
        transcription: 'aɪl dʊ ɪt ɪf jʊ dəʊnt maɪnd',
        translation: "I'll do it if you don't mind",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Закрепление пройденного материала</h3>
</div>`,
    },
  ],
];
