export const wordsLesson289 = [
  [
    {
      id: 1,
      word: 'achievable',
      transcription: 'əˈʧiːvəbᵊl',
      translation: 'достижимый',
    },
    {
      id: 2,
      word: 'affect',
      transcription: 'əˈfɛkt',
      translation: 'влиять',
    },
    {
      id: 3,
      word: 'capriciously',
      transcription: 'kəˈprɪʃəsli',
      translation: 'капризно',
    },
    {
      id: 4,
      word: 'consistency',
      transcription: 'kənˈsɪstənsi',
      translation: 'последовательность',
    },
    {
      id: 5,
      word: 'consistent',
      transcription: 'kənˈsɪstənt',
      translation: 'последовательный',
    },
    {
      id: 6,
      word: 'convict',
      transcription: 'ˈkɒnvɪkt',
      translation: 'осуждать',
    },
    {
      id: 7,
      word: 'creatively',
      transcription: 'kriˈeɪtɪvli',
      translation: 'творчески',
    },
    {
      id: 8,
      word: 'determine',
      transcription: 'dɪˈtɜːmɪn',
      translation: 'определять',
    },
    {
      id: 9,
      word: 'distribution',
      transcription: 'ˌdɪstrɪˈbjuːʃᵊn',
      translation: 'распределение',
    },
    {
      id: 10,
      word: 'doubtless',
      transcription: 'ˈdaʊtləs',
      translation: 'несомненно',
    },
    {
      id: 11,
      word: 'elimination',
      transcription: 'ɪˌlɪmɪˈneɪʃᵊn',
      translation: 'ликвидация',
    },
    {
      id: 12,
      word: 'endure',
      transcription: 'ɪnˈdjʊə',
      translation: 'терпеть',
    },
    {
      id: 13,
      word: 'fearlessly',
      transcription: 'ˈfɪələsli',
      translation: 'бесстрашно',
    },
    {
      id: 14,
      word: 'flexibility',
      transcription: 'ˌflɛksəˈbɪləti',
      translation: 'гибкость',
    },
    {
      id: 15,
      word: 'foremost',
      transcription: 'ˈfɔːməʊst',
      translation: 'прежде всего',
    },
    {
      id: 16,
      word: 'fruitfully',
      transcription: 'ˈfruːtfʊli',
      translation: 'плодотворно',
    },
    {
      id: 17,
      word: 'guru',
      transcription: 'ˈɡʊruː',
      translation: 'гуру',
    },
    {
      id: 18,
      word: 'highlight',
      transcription: 'ˈhaɪˌlaɪt',
      translation: 'подчеркивать',
    },
    {
      id: 19,
      word: 'incomparable',
      transcription: 'ɪnˈkɒmpərəbᵊl',
      translation: 'несравнимый',
    },
    {
      id: 20,
      word: 'inconsistent',
      transcription: 'ˌɪnkənˈsɪstənt',
      translation: 'противоречивый',
    },
    {
      id: 21,
      word: 'incurable',
      transcription: 'ɪnˈkjʊərəbᵊl',
      translation: 'неизлечимый',
    },
    {
      id: 22,
      word: 'indifferently',
      transcription: 'ɪnˈdɪfrəntli',
      translation: 'равнодушно',
    },
    {
      id: 23,
      word: 'inexpensively',
      transcription: 'ˌɪnɪksˈpɛnsɪvli',
      translation: 'недорого',
    },
    {
      id: 24,
      word: 'innovative',
      transcription: 'ɪnˈnɒvətɪv',
      translation: 'инновационный',
    },
    {
      id: 25,
      word: 'intolerable',
      transcription: 'ɪnˈtɒlərəbᵊl',
      translation: 'невыносимо',
    },
    {
      id: 26,
      word: 'irregularly',
      transcription: 'ɪˈrɛɡjələli',
      translation: 'нерегулярно',
    },
    {
      id: 27,
      word: 'meteorological',
      transcription: 'ˌmiːtiərəˈlɒʤɪkᵊl',
      translation: 'метеорологический',
    },
    {
      id: 28,
      word: 'misspell',
      transcription: 'ˈmɪsˈspɛl',
      translation: 'неправильно написать',
    },
    {
      id: 29,
      word: 'overnight',
      transcription: 'ˌəʊvəˈnaɪt',
      translation: 'в одночасье, мгновенный',
    },
    {
      id: 30,
      word: 'pollution',
      transcription: 'pəˈluːʃᵊn',
      translation: 'загрязнение',
    },
    {
      id: 31,
      word: 'realist',
      transcription: 'ˈrɪəlɪst',
      translation: 'реалист',
    },
    {
      id: 32,
      word: 'realistically',
      transcription: 'rɪəˈlɪstɪkᵊli',
      translation: 'реалистично, реально',
    },
    {
      id: 33,
      word: 'restore',
      transcription: 'rɪsˈtɔː',
      translation: 'восстановить',
    },
    {
      id: 34,
      word: 'selfishness',
      transcription: 'ˈsɛlfɪʃnəs',
      translation: 'эгоизм',
    },
    {
      id: 35,
      word: 'shoplifting',
      transcription: 'ˈʃɒpˌlɪftɪŋ',
      translation: 'магазинная кража',
    },
    {
      id: 36,
      word: 'starvation',
      transcription: 'stɑːˈveɪʃᵊn',
      translation: 'голодание',
    },
    {
      id: 37,
      word: 'trait',
      transcription: 'treɪt',
      translation: 'черта',
    },
    {
      id: 38,
      word: 'unafraid',
      transcription: 'ˌʌnəˈfreɪd',
      translation: 'бесстрашный',
    },
    {
      id: 39,
      word: 'unearth',
      transcription: 'ʌnˈɜːθ',
      translation: 'раскапывать',
    },
    {
      id: 40,
      word: 'unequal',
      transcription: 'ʌnˈiːkwəl',
      translation: 'неравный',
    },
    {
      id: 41,
      word: 'variety',
      transcription: 'vəˈraɪəti',
      translation: 'разнообразие',
    },
    {
      id: 42,
      word: 'stress',
      transcription: 'strɛs',
      translation: 'подчеркивать',
    },
    {
      id: 43,
      word: 'respect',
      transcription: 'rɪsˈpɛkt',
      translation: 'отношение',
    },
  ],
];
