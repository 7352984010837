export const wordsLesson285 = [
  [
    {
      id: 1,
      word: 'relieved',
      transcription: 'rɪˈliːvd',
      translation: 'рад',
    },
    {
      id: 2,
      word: 'anyhow',
      transcription: 'ˈɛnɪhaʊ',
      translation: 'во всяком случае',
    },
    {
      id: 3,
      word: 'colossal',
      transcription: 'kəˈlɒsl',
      translation: 'колоссальный',
    },
    {
      id: 4,
      word: 'commitment',
      transcription: 'kəˈmɪtmənt',
      translation: 'приверженность',
    },
    {
      id: 5,
      word: 'dedication',
      transcription: 'ˌdɛdɪˈkeɪʃᵊn',
      translation: 'преданность',
    },
    {
      id: 6,
      word: 'edition',
      transcription: 'ɪˈdɪʃᵊn',
      translation: 'издание',
    },
    {
      id: 7,
      word: 'emphasis',
      transcription: 'ˈɛmfəsɪs',
      translation: 'акцент',
    },
    {
      id: 8,
      word: 'frustrating',
      transcription: 'frʌsˈtreɪtɪŋ',
      translation: 'разочаровывающий',
    },
    {
      id: 9,
      word: 'gentlemen',
      transcription: 'ˈʤɛntᵊlmən',
      translation: 'джентльмен',
    },
    {
      id: 10,
      word: 'outdated',
      transcription: 'aʊtˈdeɪtɪd',
      translation: 'устаревший',
    },
    {
      id: 11,
      word: 'painstaking',
      transcription: 'ˈpeɪnzˌteɪkɪŋ',
      translation: 'кропотливая',
    },
    {
      id: 12,
      word: 'relief',
      transcription: 'rɪˈliːf',
      translation: 'облегчение',
    },
    {
      id: 13,
      word: 'scheme',
      transcription: 'skiːm',
      translation: 'схема',
    },
    {
      id: 14,
      word: 'seemingly',
      transcription: 'ˈsiːmɪŋli',
      translation: 'казалось бы',
    },
    {
      id: 15,
      word: 'ventilation',
      transcription: 'ˌvɛntɪˈleɪʃᵊn',
      translation: 'вентиляция',
    },
    {
      id: 16,
      word: 'wholly',
      transcription: 'ˈhəʊli',
      translation: 'совершенно',
    },
  ],
];
