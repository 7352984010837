export const wordsLesson56 = [
  [
    {
      id: 1,
      word: 'accountant',
      transcription: 'əˈkaʊntənt',
      translation: 'бухгалтер',
    },
    {
      id: 2,
      word: 'bedroom',
      transcription: 'ˈbɛdru(ː)m',
      translation: 'спальня',
    },
    {
      id: 3,
      word: 'bright',
      transcription: 'braɪt',
      translation: 'яркий',
    },
    {
      id: 4,
      word: 'chess',
      transcription: 'ʧɛs',
      translation: 'шахматы',
    },
    {
      id: 5,
      word: 'college',
      transcription: 'ˈkɒlɪʤ',
      translation: 'колледж',
    },
    {
      id: 6,
      word: 'comment',
      transcription: 'ˈkɒmɛnt',
      translation: 'комментарий',
    },
    {
      id: 7,
      word: 'disappointed',
      transcription: 'ˌdɪsəˈpɔɪntɪd',
      translation: 'разочарованный',
    },
    {
      id: 8,
      word: 'diving',
      transcription: 'ˈdaɪvɪŋ',
      translation: 'дайвинг',
    },
    {
      id: 9,
      word: 'drawing',
      transcription: 'ˈdrɔːɪŋ',
      translation: 'рисование',
    },
    {
      id: 10,
      word: 'episode',
      transcription: 'ˈɛpɪsəʊd',
      translation: 'эпизод',
    },
    {
      id: 11,
      word: 'expression',
      transcription: 'ɪksˈprɛʃən',
      translation: 'выражение',
    },
    {
      id: 12,
      word: 'fifteen',
      transcription: 'ˈfɪfˈtiːn',
      translation: 'пятнадцать',
    },
    {
      id: 13,
      word: 'gardening',
      transcription: 'ˈgɑːdnɪŋ',
      translation: 'садоводство',
    },
    {
      id: 14,
      word: 'genius',
      transcription: 'ˈʤiːniəs',
      translation: 'гений',
    },
    {
      id: 15,
      word: 'heavy',
      transcription: 'ˈhɛvi',
      translation: 'тяжелый',
    },
    {
      id: 16,
      word: 'hobby',
      transcription: 'ˈhɒbi',
      translation: 'хобби',
    },
    {
      id: 17,
      word: 'humour',
      transcription: 'ˈhjuːmə',
      translation: 'юмор',
    },
    {
      id: 18,
      word: 'jacket',
      transcription: 'ˈʤækɪt',
      translation: 'куртка',
    },
    {
      id: 19,
      word: 'lamp',
      transcription: 'læmp',
      translation: 'лампа',
    },
    {
      id: 20,
      word: 'learning',
      transcription: 'ˈlɜːnɪŋ',
      translation: 'изучение',
    },
    {
      id: 21,
      word: 'lecture',
      transcription: 'ˈlɛkʧə',
      translation: 'лекция',
    },
    {
      id: 22,
      word: 'main',
      transcription: 'meɪn',
      translation: 'главный',
    },
    {
      id: 23,
      word: 'nurse',
      transcription: 'nɜːs',
      translation: 'медсестра',
    },
    {
      id: 24,
      word: 'prison',
      transcription: 'ˈprɪzn',
      translation: 'тюрьма',
    },
    {
      id: 25,
      word: 'resource',
      transcription: 'rɪˈsɔːs',
      translation: 'ресурс',
    },
    {
      id: 26,
      word: 'sense',
      transcription: 'sɛns',
      translation: 'чувство',
    },
    {
      id: 27,
      word: 'shocked',
      transcription: 'ʃɒkt',
      translation: 'шокированный',
    },
    {
      id: 28,
      word: 'skiing',
      transcription: 'ˈskiːɪŋ',
      translation: 'катание на лыжах',
    },
    {
      id: 29,
      word: 'suit',
      transcription: 'sjuːt',
      translation: 'костюм',
    },
    {
      id: 30,
      word: 'surprised',
      transcription: 'səˈpraɪzd',
      translation: 'удивленный',
    },
    {
      id: 31,
      word: 'taste',
      transcription: 'teɪst',
      translation: 'вкус',
    },
    {
      id: 32,
      word: 'thief',
      transcription: 'θiːf',
      translation: 'вор',
    },
    {
      id: 33,
      word: 'towel',
      transcription: 'ˈtaʊəl',
      translation: 'полотенце',
    },
  ],
];
