export const contentLesson79 = [
  [
    {
      title: "Урок 79. Future Simple. Question",
    },
    [
      {
        id: 1,
        expression: "Ты пойдешь в кино со мной?",
        transcription: "wɪl jʊ gəʊ tə ðə ˈsɪnəmə wɪð miː?",
        translation: "Will you go to the cinema with me?",
      },
      {
        id: 2,
        expression: "Ты будешь пробовать выучить английский?",
        transcription: "wɪl jʊ traɪ tə lɜːn ˈɪŋglɪʃ?",
        translation: "Will you try to learn English?",
      },
      {
        id: 3,
        expression: "Это будет дешево?",
        transcription: "wɪl ɪt bi ʧiːp?",
        translation: "Will it be cheap?",
      },
      {
        id: 4,
        expression: "Ты пойдешь туда пешком?",
        transcription: "wɪl jʊ gəʊ ðeər ɒn fʊt?",
        translation: "Will you go there on foot?",
      },
      {
        id: 5,
        expression: "Лекция будет очень скучной?",
        transcription: "wɪl ðə ˈlɛsn bi ˈvɛri ˈbɔːrɪŋ?",
        translation: "Will the lesson be very boring?",
      },
      {
        id: 6,
        expression: "Ты к нам присоединишься?",
        transcription: "wɪl jʊ ʤɔɪn ʌs?",
        translation: "Will you join us?",
      },
      {
        id: 7,
        expression: "Она скоро прибудет?",
        transcription: "wɪl ʃi əˈraɪv suːn?",
        translation: "Will she arrive soon?",
      },
      {
        id: 8,
        expression: "Мой уровень станет намного выше?",
        transcription: "wɪl maɪ ˈlɛvl gɛt mʌʧ ˈbɛtə?",
        translation: "Will my level get much better?",
      },
      {
        id: 9,
        expression: "Ты будешь готовиться к этому тесту?",
        transcription: "wɪl jʊ prɪˈpeə fə ðɪs tɛst?",
        translation: "Will you prepare for this test?",
      },
      {
        id: 10,
        expression: "У него будет более хорошая работа?",
        transcription: "wɪl hi həv ə ˈbɛtə ʤɒb?",
        translation: "Will he have a better job?",
      },
      {
        id: 11,
        expression: "Ты купишь старую машину?",
        transcription: "wɪl jʊ baɪ ən əʊld kɑː?",
        translation: "Will you buy an old car?",
      },
      {
        id: 12,
        expression: "Ты возьмешь такси?",
        transcription: "wɪl jʊ teɪk ə ˈtæksi?",
        translation: "Will you take a taxi?",
      },
      {
        id: 13,
        expression: "Ты свяжешься со мной позже?",
        transcription: "wɪl jʊ ˈkɒntækt mi ˈleɪtə?",
        translation: "Will you contact me later?",
      },
      {
        id: 14,
        expression: "Это будет слишком дорого?",
        transcription: "wɪl ɪt bi tuː ɪksˈpɛnsɪv?",
        translation: "Will it be too expensive?",
      },
      {
        id: 15,
        expression: "Ты будешь в офисе завтра?",
        transcription: "wɪl jʊ bi ɪn ði ˈɒfɪs təˈmɒrəʊ?",
        translation: "Will you be in the office tomorrow?",
      },
      {
        id: 16,
        expression: "Ты откроешь дверь?",
        transcription: "wɪl jʊ ˈəʊpən ðə dɔː?",
        translation: "Will you open the door?",
      },
      {
        id: 17,
        expression: "Он скоро вернется домой?",
        transcription: "wɪl hi rɪˈtɜːn həʊm suːn?",
        translation: "Will he return home soon?",
      },
      {
        id: 18,
        expression: "Это будет лучше?",
        transcription: "wɪl ɪt bi ˈbɛtə?",
        translation: "Will it be better?",
      },
      {
        id: 19,
        expression: "Урок будет очень полезным?",
        transcription: "wɪl ðə ˈlɛsn bi ˈvɛri ˈjuːsfʊl?",
        translation: "Will the lesson be very useful?",
      },
      {
        id: 20,
        expression: "Ты пойдешь в кино со мной?",
        transcription: "wɪl jʊ gəʊ tə ðə ˈsɪnəmə wɪð miː?",
        translation: "Will you go to the cinema with me?",
      },
      {
        id: 21,
        expression: "Вы будете жить в другом месте?",
        transcription: "wɪl jʊ lɪv ɪn əˈnʌðə pleɪs?",
        translation: "Will you live in another place?",
      },
      {
        id: 22,
        expression: "Ты посмотришь на это?",
        transcription: "wɪl jʊ lʊk ət ɪt?",
        translation: "Will you look at it?",
      },
      {
        id: 23,
        expression: "Курс будет очень интенсивным?",
        transcription: "wɪl ðə kɔːs bi ˈvɛri ɪnˈtɛnsɪv?",
        translation: "Will the course be very intensive?",
      },
      {
        id: 24,
        expression: "Мы пойдем в парк вместе?",
        transcription: "wɪl wi gəʊ tə ðə pɑːk təˈgɛðə?",
        translation: "Will we go to the park together?",
      },
      {
        id: 25,
        expression: "Ты закроешь окно?",
        transcription: "wɪl jʊ kləʊs ðə ˈwɪndəʊ?",
        translation: "Will you close the window?",
      },
      {
        id: 26,
        expression: "Она купит старую квартиру?",
        transcription: "wɪl ʃi baɪ ən əʊld əˈpɑːtmənt?",
        translation: "Will she buy an old apartment?",
      },
      {
        id: 27,
        expression: "Мой английский скоро станет лучше?",
        transcription: "wɪl maɪ ˈɪŋglɪʃ bɪˈkʌm ˈbɛtə suːn?",
        translation: "Will my English become better soon?",
      },
      {
        id: 28,
        expression: "У меня будет стабильный доход?",
        transcription: "wɪl aɪ həv ə ˈsteɪbl ˈɪnkʌm?",
        translation: "Will I have a stable income?",
      },
      {
        id: 29,
        expression: "Она продаст свою квартиру?",
        transcription: "wɪl ʃi sɛl hər əˈpɑːtmənt?",
        translation: "Will she sell her apartment?",
      },
      {
        id: 30,
        expression: "Он купит новый дом?",
        transcription: "wɪl hi baɪ ə njuː haʊs?",
        translation: "Will he buy a new house?",
      },
      {
        id: 31,
        expression: "Это будет выгодная покупка?",
        transcription: "wɪl ɪt bi ə ˈbɑːgɪn?",
        translation: "Will it be a bargain?",
      },
      {
        id: 32,
        expression: "Тебе понадобится дополнительная практика?",
        transcription: "wɪl jʊ niːd ˈɛkstrə ˈpræktɪs?",
        translation: "Will you need extra practice?",
      },
      {
        id: 33,
        expression: "Мы полетим туда на самолете?",
        transcription: "wɪl wi gəʊ (flaɪ) ðeə baɪ pleɪn?",
        translation: "Will we go (fly) there by plane?",
      },
      {
        id: 34,
        expression: "Курс будет довольно коротким?",
        transcription: "wɪl ðə kɔːs bi kwaɪt (ˈprɪti) ʃɔːt?",
        translation: "Will the course be quite (pretty) short?",
      },
      {
        id: 35,
        expression: "Ты придешь домой рано?",
        transcription: "wɪl jʊ kʌm həʊm ˈɜːli?",
        translation: "Will you come home early?",
      },
      {
        id: 36,
        expression: "Ты посмотришь на эту страницу?",
        transcription: "wɪl jʊ lʊk ət ðɪs peɪʤ?",
        translation: "Will you look at this page?",
      },
      {
        id: 37,
        expression: "Ты поедешь в тот город на поезде?",
        transcription: "wɪl jʊ gəʊ tə ðət ˈsɪti baɪ treɪn?",
        translation: "Will you go to that city by train?",
      },
      {
        id: 38,
        expression: "Он поедет туда на автобусе?",
        transcription: "wɪl hi gəʊ ðeə baɪ bʌs?",
        translation: "Will he go there by bus?",
      },
      {
        id: 39,
        expression: "Ты будешь готовиться к тому экзамену?",
        transcription: "wɪl jʊ prɪˈpeə fə ðət ɪgˈzæm?",
        translation: "Will you prepare for that exam?",
      },
      {
        id: 40,
        expression: "Мы пойдем туда пешком?",
        transcription: "wɪl wi gəʊ ðeər ɒn fʊt?",
        translation: "Will we go there on foot?",
      },
      {
        id: 41,
        expression: "У тебя будет хорошо оплачиваемая работа?",
        transcription: "wɪl jʊ həv ə wɛl-peɪd ʤɒb?",
        translation: "Will you have a well-paid job?",
      },
      {
        id: 42,
        expression: "Это принесет ему большие деньги?",
        transcription: "wɪl ɪt brɪŋ ɪm bɪg ˈmʌni?",
        translation: "Will it bring him big money?",
      },
      {
        id: 43,
        expression: "Ты будешь искать место лучше?",
        transcription: "wɪl jʊ lʊk fər ə ˈbɛtə pleɪs?",
        translation: "Will you look for a better place?",
      },
      {
        id: 44,
        expression: "Это будет пустой тратой времени?",
        transcription: "wɪl ɪt bi ə weɪst əv taɪm?",
        translation: "Will it be a waste of time?",
      },
      {
        id: 45,
        expression: "Ты продолжишь это делать?",
        transcription: "wɪl jʊ kənˈtɪnju(ː) ˈdu(ː)ɪŋ ɪt?",
        translation: "Will you continue doing it?",
      },
      {
        id: 46,
        expression:
          "Ты пойдешь в спортзал, чтобы попытаться быть в хорошей форме?",
        transcription: "wɪl jʊ gəʊ tə ðə ʤɪm tə traɪ tə bi ɪn gʊd ʃeɪp?",
        translation: "Will you go to the gym to try to be in good shape?",
      },
      {
        id: 47,
        expression:
          "Ты будешь делать какие-либо физические упражнения, чтобы поддерживать себя в форме?",
        transcription: "wɪl jʊ dʊ ˈɛni ˈfɪzɪkəl ˈɛksəsaɪzɪz tə kiːp fɪt?",
        translation: "Will you do any physical exercises to keep fit?",
      },
      {
        id: 48,
        expression: "Ты будешь посещать те курсы?",
        transcription: "wɪl jʊ əˈtɛnd ðəʊz ˈkɔːsɪz?",
        translation: "Will you attend those courses?",
      },
      {
        id: 49,
        expression: "Ты поедешь в отпуск на следующей неделе?",
        transcription: "wɪl jʊ gəʊ ɒn ˈhɒlədeɪ nɛkst wiːk?",
        translation: "Will you go on holiday next week?",
      },
      {
        id: 50,
        expression: "Ты будешь что-то делать, чтобы изменить эту ситуацию?",
        transcription: "wɪl jʊ dʊ ˈɛnɪθɪŋ tə ʧeɪnʤ ðɪs ˌsɪtjʊˈeɪʃən?",
        translation: "Will you do anything to change this situation?",
      },
      {
        id: 51,
        expression: "Ты будешь продолжать работать здесь?",
        transcription: "wɪl jʊ kənˈtɪnju(ː) ˈwɜːkɪŋ hɪə?",
        translation: "Will you continue working here?",
      },
      {
        id: 52,
        expression:
          "Ты будешь делать какие-либо физические упражнения, чтобы быть в хорошей форме?",
        transcription: "wɪl jʊ dʊ ˈɛni ˈfɪzɪkəl ˈɛksəsaɪzɪz tə bi ɪn gʊd ʃeɪp?",
        translation: "Will you do any physical exercises to be in good shape?",
      },
      {
        id: 53,
        expression: "Будет дорого жить в этом отеле?",
        transcription: "wɪl ɪt bi ɪksˈpɛnsɪv tə lɪv ɪn ðɪs həʊˈtɛl?",
        translation: "Will it be expensive to live in this hotel?",
      },
      {
        id: 54,
        expression: "Он будет искать квартиру?",
        transcription: "wɪl hi lʊk fər ən əˈpɑːtmənt?",
        translation: "Will he look for an apartment?",
      },
      {
        id: 55,
        expression: "Это легко будет понять?",
        transcription: "wɪl ɪt bi ˈiːzi tʊ ˌʌndəˈstænd?",
        translation: "Will it be easy to understand?",
      },
      {
        id: 56,
        expression:
          "Мне нужна будет дополнительная практика, чтобы иметь более хороший результат?",
        transcription: "wɪl aɪ niːd ˈɛkstrə ˈpræktɪs tə həv ə ˈbɛtə rɪˈzʌlt?",
        translation: "Will I need extra practice to have a better result?",
      },
      {
        id: 57,
        expression: "Он также будет посещать эти курсы?",
        transcription: "wɪl hi ˈɔːlsəʊ əˈtɛnd ðiːz ˈkɔːsɪz?",
        translation: "Will he also attend these courses?",
      },
      {
        id: 58,
        expression: "Это будет очень выгодная сделка?",
        transcription: "wɪl ɪt bi ə kəmˈpliːt ˈbɑːgɪn?",
        translation: "Will it be a complete bargain?",
      },
      {
        id: 59,
        expression: "Это будет последняя выгодная сделка?",
        transcription: "wɪl ɪt bi ðə lɑːst kəmˈpliːt ˈbɑːgɪn?",
        translation: "Will it be the last complete bargain?",
      },
      {
        id: 60,
        expression: "Это будет хорошая сделка?",
        transcription: "wɪl ɪt bi ə gʊd ˈbɑːgɪn?",
        translation: "Will it be a good bargain?",
      },
      {
        id: 61,
        expression: "Ты будешь искать новую работу?",
        transcription: "wɪl jʊ lʊk fər ə njuː ʤɒb?",
        translation: "Will you look for a new job?",
      },
      {
        id: 62,
        expression: "Он будет искать новую работу?",
        transcription: "wɪl hi lʊk fər ə njuː ʤɒb?",
        translation: "Will he look for a new job?",
      },
      {
        id: 63,
        expression: "Они будут искать новый дом?",
        transcription: "wɪl ðeɪ lʊk fər ə njuː haʊs?",
        translation: "Will they look for a new house?",
      },
      {
        id: 64,
        expression: "Это будет полной тратой времени?",
        transcription: "wɪl ɪt bi ə kəmˈpliːt weɪst əv taɪm?",
        translation: "Will it be a complete waste of time?",
      },
      {
        id: 65,
        expression: "Это будет настоящая трата времени?",
        transcription: "wɪl ɪt bi ə rɪəl weɪst əv taɪm?",
        translation: "Will it be a real waste of time?",
      },
      {
        id: 66,
        expression: "Ты будешь продолжать изучать английский здесь?",
        transcription: "wɪl jʊ kənˈtɪnju(ː) ˈlɜːnɪŋ ˈɪŋglɪʃ hɪə?",
        translation: "Will you continue learning English here?",
      },
      {
        id: 67,
        expression: "Она будет продолжать изучать английский там?",
        transcription: "wɪl ʃi kənˈtɪnju(ː) ˈlɜːnɪŋ ˈɪŋglɪʃ ðeə?",
        translation: "Will she continue learning English there?",
      },
      {
        id: 68,
        expression:
          "Ты будешь продолжать изучать английский в следующем месяце?",
        transcription: "wɪl jʊ kənˈtɪnju(ː) ˈlɜːnɪŋ ˈɪŋglɪʃ nɛkst mʌnθ?",
        translation: "Will you continue learning English next month?",
      },
      {
        id: 69,
        expression: "Она будет искать лучшую работу?",
        transcription: "wɪl ʃi lʊk fə ðə bɛst ʤɒb?",
        translation: "Will she look for the best job?",
      },
      {
        id: 70,
        expression: "Ты будешь искать лучшую работу?",
        transcription: "wɪl jʊ lʊk fə ðə bɛst ʤɒb?",
        translation: "Will you look for the best job?",
      },
      {
        id: 71,
        expression: "Она будет искать работу получше?",
        transcription: "wɪl ʃi lʊk fər ə ˈbɛtə ʤɒb?",
        translation: "Will she look for a better job?",
      },
      {
        id: 72,
        expression: "Ты будешь свободен на выходных?",
        transcription: "wɪl jʊ bi friː ət ðə ˈwiːkˈɛnd?",
        translation: "Will you be free at the weekend?",
      },
      {
        id: 73,
        expression: "Ты будешь свободен в эту пятницу?",
        transcription: "wɪl jʊ bi friː ðɪs ˈfraɪdeɪ?",
        translation: "Will you be free this Friday?",
      },
      {
        id: 74,
        expression: "Она будет свободна на выходных?",
        transcription: "wɪl ʃi bi friː ət ðə ˈwiːkˈɛnd?",
        translation: "Will she be free at the weekend?",
      },
      {
        id: 75,
        expression: "Ты будешь пытаться найти работу получше?",
        transcription: "wɪl jʊ traɪ tə faɪnd ə ˈbɛtə ʤɒb?",
        translation: "Will you try to find a better job?",
      },
      {
        id: 76,
        expression: "Ты будешь пытаться найти хорошую работу?",
        transcription: "wɪl jʊ traɪ tə faɪnd ə gʊd ʤɒb?",
        translation: "Will you try to find a good job?",
      },
      {
        id: 77,
        expression: "Ты будешь пытаться найти лучшую работу?",
        transcription: "wɪl jʊ traɪ tə faɪnd ðə bɛst ʤɒb?",
        translation: "Will you try to find the best job?",
      },
      {
        id: 78,
        expression: "Ты сможешь сделать это?",
        transcription: "wɪl jʊ bi ˈeɪbl tə dʊ ɪt?",
        translation: "Will you be able to do it?",
      },
      {
        id: 79,
        expression: "Они смогут сделать это?",
        transcription: "wɪl ðeɪ bi ˈeɪbl tə dʊ ɪt?",
        translation: "Will they be able to do it?",
      },
      {
        id: 80,
        expression: "Она сможет сделать это в следующем месяце?",
        transcription: "wɪl ʃi bi ˈeɪbl tə dʊ ɪt nɛkst mʌnθ?",
        translation: "Will she be able to do it next month?",
      },
      {
        id: 81,
        expression:
          "Ты будешь тренироваться с личным тренером, чтобы поддерживать себя в форме?",
        transcription: "wɪl jʊ treɪn wɪð ə ˈpɜːsnl ˈtreɪnə tə kiːp fɪt?",
        translation: "Will you train with a personal trainer to keep fit?",
      },
      {
        id: 82,
        expression:
          "Ты будешь тренироваться со своим тренером, чтобы поддерживать себя в форме?",
        transcription: "wɪl jʊ treɪn wɪð jə ˈtreɪnə tə kiːp fɪt?",
        translation: "Will you train with your trainer to keep fit?",
      },
      {
        id: 83,
        expression:
          "Она будет тренироваться с этим тренером, чтобы поддерживать себя в форме?",
        transcription: "wɪl ʃi treɪn wɪð ðɪs ˈtreɪnə tə kiːp fɪt?",
        translation: "Will she train with this trainer to keep fit?",
      },
      {
        id: 84,
        expression: "Ты свяжешься со мной позже?",
        transcription: "wɪl jʊ gɛt ɪn tʌʧ wɪð miː ˈleɪtə?",
        translation: "Will you get in touch with me later?",
      },
      {
        id: 85,
        expression: "Она свяжется с тобой позже?",
        transcription: "wɪl ʃi gɛt ɪn tʌʧ wɪð jʊ ˈleɪtə?",
        translation: "Will she get in touch with you later?",
      },
      {
        id: 86,
        expression: "Она свяжется со мной позже?",
        transcription: "wɪl ʃi gɛt ɪn tʌʧ wɪð mi ˈleɪtə?",
        translation: "Will she get in touch with me later?",
      },
      {
        id: 87,
        expression: "У меня будет самый высокий уровень?",
        transcription: "wɪl aɪ həv ðə ˈhaɪɪst ˈlɛvl?",
        translation: "Will I have the highest level?",
      },
      {
        id: 88,
        expression: "Ты продолжишь посещать эти курсы?",
        transcription: "wɪl jʊ kənˈtɪnju(ː) əˈtɛndɪŋ ðiːz ˈkɔːsɪz?",
        translation: "Will you continue attending these courses?",
      },
      {
        id: 89,
        expression: "Это будет действительно выгодная покупка?",
        transcription: "wɪl ɪt bi ə rɪəl ˈbɑːgɪn?",
        translation: "Will it be a real bargain?",
      },
      {
        id: 90,
        expression: "Ты пойдешь на пикник с нами?",
        transcription: "wɪl jʊ gəʊ ɒn ə ˈpɪknɪk wɪð ʌs?",
        translation: "Will you go on a picnic with us?",
      },
      {
        id: 91,
        expression: "Ты будешь более тщательно готовиться к этому тесту?",
        transcription: "wɪl jʊ prɪˈpeə fə ðɪs tɛst mɔː ˈθʌrəli?",
        translation: "Will you prepare for this test more thoroughly?",
      },
      {
        id: 92,
        expression: "Будет легко найти это место?",
        transcription: "wɪl ɪt bi ˈiːzi tə faɪnd ðɪs pleɪs?",
        translation: "Will it be easy to find this place?",
      },
      {
        id: 93,
        expression:
          "Мне нужна будет дополнительная практика, чтобы иметь лучше результаты?",
        transcription: "wɪl aɪ niːd əˈdɪʃənl ˈpræktɪs tə həv ˈbɛtə rɪˈzʌlts?",
        translation: "Will I need additional practice to have better results?",
      },
      {
        id: 94,
        expression: "У меня будет гораздо выше уровень?",
        transcription: "wɪl aɪ həv ə mʌʧ ˈhaɪə ˈlɛvl?",
        translation: "Will I have a much higher level?",
      },
      {
        id: 95,
        expression: "Ты свяжешься со мной?",
        transcription: "wɪl jʊ gɛt ɪn tʌʧ wɪð miː?",
        translation: "Will you get in touch with me?",
      },
      {
        id: 96,
        expression:
          "Тебе нужны будут дополнительные деньги, чтобы купить ту квартиру?",
        transcription: "wɪl jʊ niːd əˈdɪʃənl ˈmʌni tə baɪ ðət əˈpɑːtmənt?",
        translation: "Will you need additional money to buy that apartment?",
      },
      {
        id: 97,
        expression: "Он сможет помочь нам?",
        transcription: "wɪl hi bi ˈeɪbl tə hɛlp ʌs?",
        translation: "Will he be able to help us?",
      },
      {
        id: 98,
        expression: "Ты попытаешься учить английский интенсивнее?",
        transcription: "wɪl jʊ traɪ tə lɜːn ˈɪŋglɪʃ mɔːr ɪnˈtɛnsɪvli?",
        translation: "Will you try to learn English more intensively?",
      },
      {
        id: 99,
        expression: "Я смогу выучить английский через год?",
        transcription: "wɪl aɪ bi ˈeɪbl tə lɜːn ˈɪŋglɪʃ ɪn ə jɪə?",
        translation: "Will I be able to learn English in a year?",
      },
      {
        id: 100,
        expression: "Ты будешь бегать трусцой более регулярно?",
        transcription: "wɪl jʊ gəʊ ˈʤɒgɪŋ mɔː ˈrɛgjʊləli?",
        translation: "Will you go jogging more regularly?",
      },
      {
        id: 101,
        expression: "У меня скоро будет более высокий уровень?",
        transcription: "wɪl aɪ həv ə ˈhaɪə ˈlɛvl suːn?",
        translation: "Will I have a higher level soon?",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Закрепление пройденного материала</h3>
</div>`,
    },
  ],
];
