export const contentLesson69 = [
  [
    {
      title: "Урок 69. Present Simple: verb + глагол to be: Special Question",
    },
    [
      {
        id: 1,
        expression: "Сколько у тебя вопросов?",
        transcription: "haʊ ˈmɛni ˈkwɛsʧənz dʊ jʊ hæv?",
        translation: "How many questions do you have?",
      },
      {
        id: 2,
        expression: "Почему он учит английский?",
        transcription: "waɪ dəz hi lɜːn ˈɪŋglɪʃ?",
        translation: "Why does he learn English?",
      },
      {
        id: 3,
        expression: "Почему эти студенты такие счастливые?",
        transcription: "waɪ ə ðiːz ˈstjuːdənts səʊ ˈhæpi?",
        translation: "Why are these students so happy?",
      },
      {
        id: 4,
        expression: "Какой твой адрес?",
        transcription: "wɒts jər əˈdrɛs?",
        translation: "What's your address?",
      },
      {
        id: 5,
        expression: "Мой английский звучит намного лучше, чем раньше?",
        transcription: "dəz maɪ ˈɪŋglɪʃ saʊnd mʌʧ ˈbɛtə ðən bɪˈfɔː?",
        translation: "Does my English sound much better than before?",
      },
      {
        id: 6,
        expression: "Сколько вопросов ты обычно задаешь?",
        transcription: "haʊ ˈmɛni ˈkwɛsʧənz dʊ jʊ ˈjuːʒʊəli meɪk?",
        translation: "How many questions do you usually make?",
      },
      {
        id: 7,
        expression: "Как часто ты ходишь на пляж?",
        transcription: "haʊ ˈɒf(ə)n dʊ jʊ gəʊ tə ðə biːʧ?",
        translation: "How often do you go to the beach?",
      },
      {
        id: 8,
        expression: "Почему эта тема интересна тебе?",
        transcription:
          "waɪ dəz ðɪs ˈtɒpɪk ˈɪntrɪst juː? / waɪ z ðɪs ˈtɒpɪk ˈɪntrɪstɪŋ fə juː?",
        translation:
          "Why does this topic interest you? / Why is this topic interesting for you?",
      },
      {
        id: 9,
        expression: "Сколько лет твоим родителям?",
        transcription: "haʊ əʊld ə jʊ ˈpeərənts?",
        translation: "How old are you parents?",
      },
      {
        id: 10,
        expression: "Что означает это слово?",
        transcription: "waɪ dəz ðɪs wɜːd miːn?",
        translation: "Why does this word mean?",
      },
      {
        id: 11,
        expression: "Сколько у тебя вопросов?",
        transcription: "haʊ ˈmɛni ˈkwɛsʧənz dʊ jʊ hæv?",
        translation: "How many questions do you have?",
      },
      {
        id: 12,
        expression: "Почему эта работа такая стрессовая для тебя?",
        transcription: "waɪ z ðɪs ʤɒb səʊ ˈstrɛsf(ə)l fə juː?",
        translation: "Why is this job so stressful for you?",
      },
      {
        id: 13,
        expression: "В чем проблема?",
        transcription: "wɒts ðə ˈprɒbləm?",
        translation: "What's the problem?",
      },
      {
        id: 14,
        expression: "Почему эта вещь кажется такой важной?",
        transcription: "waɪ dəz ðɪs θɪŋ siːm səʊ ɪmˈpɔːtənt?",
        translation: "Why does this thing seem so important?",
      },
      {
        id: 15,
        expression: "Почему это неправильно?",
        transcription: "waɪ z ɪt rɒŋ? / waɪ z ɪt ˌɪnkəˈrɛkt?",
        translation: "Why is it wrong? / Why is it incorrect?",
      },
      {
        id: 16,
        expression: "Почему ты так много думаешь об этом?",
        transcription: "waɪ dʊ jʊ θɪŋk əˈbaʊt ɪt səʊ mʌʧ?",
        translation: "Why do you think about it so much?",
      },
      {
        id: 17,
        expression: "Где работают твои родители?",
        transcription: "weə dʊ jə ˈpeərənts wɜːk?",
        translation: "Where do your parents work?",
      },
      {
        id: 18,
        expression: "Где работает твоя мама?",
        transcription: "weə dəz jə ˈmʌðə wɜːk?",
        translation: "Where does your mother work?",
      },
      {
        id: 19,
        expression: "Что означают эти слова?",
        transcription: "wɒt dʊ ðiːz wɜːdz miːn?",
        translation: "What do these words mean?",
      },
      {
        id: 20,
        expression: "Что дальше?",
        transcription: "wɒts nɛkst?",
        translation: "What's next?",
      },
      {
        id: 21,
        expression: "Насколько это рискованно?",
        transcription: "haʊ ˈrɪski z ɪt?",
        translation: "How risky is it?",
      },
      {
        id: 22,
        expression: "Кто следующий?",
        transcription: "huːz nɛkst?",
        translation: "Who's next?",
      },
      {
        id: 23,
        expression: "Какой твой адрес?",
        transcription: "wɒts jər əˈdrɛs?",
        translation: "What's your address?",
      },
      {
        id: 24,
        expression: "Какая у твоего отца работа?",
        transcription: "wɒts jə ˈfɑːðəz ʤɒb?",
        translation: "What's your father's job?",
      },
      {
        id: 25,
        expression: "Какой его совет?",
        transcription: "wɒts ɪz ədˈvaɪs?",
        translation: "What's his advice?",
      },
      {
        id: 26,
        expression: "Как часто он путешествует?",
        transcription: "haʊ ˈɒf(ə)n dəz hi ˈtrævl?",
        translation: "How often does he travel?",
      },
      {
        id: 27,
        expression: "Аэропорт рядом?",
        transcription: "ɪz ði ˈeəpɔːt nɪə?",
        translation: "Is the airport near?",
      },
      {
        id: 28,
        expression: "Какой у нее адрес?",
        transcription: "wɒts hər əˈdrɛs?",
        translation: "What's her address?",
      },
      {
        id: 29,
        expression: "Какие их рекомендации?",
        transcription: "wɒt ə ðeə ˌrɛkəmɛnˈdeɪʃənz?",
        translation: "What are their recommendations?",
      },
      {
        id: 30,
        expression: "Сколько лет твоей маме?",
        transcription: "haʊ əʊld z jə ˈmʌðə?",
        translation: "How old is your mother?",
      },
      {
        id: 31,
        expression: "Какая у твоей мамы работа?",
        transcription: "wɒts jə ˈmʌðəz ʤɒb?",
        translation: "What's your mother's job?",
      },
      {
        id: 32,
        expression: "Насколько глубокое озеро?",
        transcription: "haʊ diːp s ðə leɪk?",
        translation: "How deep is the lake?",
      },
      {
        id: 33,
        expression: "Почему тебе нравится этот отель?",
        transcription: "waɪ dʊ jʊ laɪk ðɪs həʊˈtɛl?",
        translation: "Why do you like this hotel?",
      },
      {
        id: 34,
        expression: "Что в посылке?",
        transcription: "wɒts ɪn ðə ˈpɑːsl?",
        translation: "What's in the parcel?",
      },
      {
        id: 35,
        expression: "Этот отель рядом с этим местом?",
        transcription: "ɪz ðɪs həʊˈtɛl nɪə ðɪs pleɪs?",
        translation: "Is this hotel near this place?",
      },
      {
        id: 36,
        expression: "Насколько опасно это?",
        transcription: "haʊ ˈdeɪnʤrəs ɪz ɪt?",
        translation: "How dangerous is it?",
      },
      {
        id: 37,
        expression: "Насколько красивый этот город?",
        transcription: "haʊ ˈbjuːtəfʊl z ðɪs ˈsɪti?",
        translation: "How beautiful is this city?",
      },
      {
        id: 38,
        expression: "Насколько это важно для тебя?",
        transcription: "haʊ ɪmˈpɔːtənt s ɪt fə juː?",
        translation: "How important is it for you?",
      },
      {
        id: 39,
        expression: "Что означает это выражение?",
        transcription: "wɒt dəz ðɪs ɪksˈprɛʃən miːn?",
        translation: "What does this expression mean?",
      },
      {
        id: 40,
        expression: "Почему это тебе так интересно?",
        transcription: "waɪ z ɪt səʊ ˈɪntrɪstɪŋ fə juː?",
        translation: "Why is it so interesting for you?",
      },
      {
        id: 41,
        expression: "Сколько лет твоему отцу?",
        transcription: "haʊ əʊld z jə ˈfɑːðə?",
        translation: "How old is your father?",
      },
      {
        id: 42,
        expression: "Где работает твой отец?",
        transcription: "weə dəz jə ˈfɑːðə wɜːk?",
        translation: "Where does your father work?",
      },
      {
        id: 43,
        expression: "Мой английский намного лучше, чем раньше?",
        transcription: "ɪz maɪ ˈɪŋglɪʃ mʌʧ ˈbɛtə ðən bɪˈfɔː?",
        translation: "Is my English much better than before?",
      },
      {
        id: 44,
        expression: "Что означает эта фраза?",
        transcription: "wɒt dəz ðɪs freɪz miːn?",
        translation: "What does this phrase mean?",
      },
      {
        id: 45,
        expression: "Как зовут мальчика?",
        transcription: "wɒts ðə bɔɪz neɪm?",
        translation: "What's the boy's name?",
      },
      {
        id: 46,
        expression:
          "Почему тебе нужно гораздо больше времени, чтобы полностью это закончить?",
        transcription: "wɒt dʊ jʊ niːd mʌʧ mɔː taɪm tə ˈfɪnɪʃ ɪt kəmˈpliːtli?",
        translation: "What do you need much more time to finish it completely?",
      },
      {
        id: 47,
        expression: "Почему тебе нужно сделать это как можно скорее?",
        transcription: "waɪ dʊ jʊ niːd tə dʊ ɪt əz suːn əz ˈpɒsəbl?",
        translation: "Why do you need to do it as soon as possible?",
      },
      {
        id: 48,
        expression: "Как ты следишь за своим здоровьем?",
        transcription: "haʊ dʊ jʊ lʊk ˈɑːftə jə hɛlθ?",
        translation: "How do you look after your health?",
      },
      {
        id: 49,
        expression: "Как долго он добирается до школы?",
        transcription: "haʊ lɒŋ dəz hi gɛt tə skuːl?",
        translation: "How long does he get to school?",
      },
      {
        id: 50,
        expression: "Почему он так заинтересован в этом проекте?",
        transcription: "waɪ z hi səʊ ˈɪntrɪstɪd ɪn ðɪs ˈprɒʤɛkt?",
        translation: "Why is he so interested in this project?",
      },
      {
        id: 51,
        expression: "Почему ты предпочитаешь изучать английский язык сам?",
        transcription: "waɪ dʊ jʊ priˈfɜː tə lɜːn ˈɪŋglɪʃ baɪ jɔːˈsɛlf?",
        translation: "Why do you prefer to learn English by yourself?",
      },
      {
        id: 52,
        expression: "Сколько раз в неделю ты ходишь в бассейн?",
        transcription: "haʊ ˈmɛni taɪmz ə wiːk dʊ jʊ gəʊ tə ðə ˈswɪmɪŋ puːl?",
        translation: "How many times a week do you go to the swimming pool?",
      },
      {
        id: 53,
        expression: "Почему он предпочитает изучать английский сам?",
        transcription: "waɪ dəz hi priˈfɜː tə lɜːn ˈɪŋglɪʃ baɪ hɪmˈsɛlf?",
        translation: "Why does he prefer to learn English by himself?",
      },
      {
        id: 54,
        expression: "Как долго она добирается домой?",
        transcription: "haʊ lɒŋ dəz ʃi gɛt həʊm?",
        translation: "How long does she get home?",
      },
      {
        id: 55,
        expression: "Какие у девочки интересы?",
        transcription:
          "wɒt ˈɪntrɪsts dəz ðə gɜːl hæv? / wɒt ə ðə gɜːlz ˈɪntrɪsts?",
        translation:
          "What interests does the girl have? / What are the girl's interests?",
      },
      {
        id: 56,
        expression: "Какой у тебя уровень английского?",
        transcription: "wɒts jər ˈɪŋglɪʃ ˈlɛvl?",
        translation: "What's your English level?",
      },
      {
        id: 57,
        expression: "Как часто ты читаешь этот журнал?",
        transcription: "haʊ ˈɒf(ə)n dʊ jʊ riːd ðət ˌmægəˈziːn?",
        translation: "How often do you read that magazine?",
      },
      {
        id: 58,
        expression: "Что в коробке?",
        transcription: "wɒts ɪn ðə bɒks?",
        translation: "What's in the box?",
      },
      {
        id: 59,
        expression: "Как зовут твоего друга?",
        transcription: "wɒts jə frɛndz neɪm?",
        translation: "What's your friend's name?",
      },
      {
        id: 60,
        expression: "Как долго идет урок?",
        transcription: "haʊ lɒŋ dəz ðə ˈlɛsn lɑːst? / haʊ lɒŋ z ðə ˈlɛsn?",
        translation: "How long does the lesson last? / How long is the lesson?",
      },
      {
        id: 61,
        expression: "Как долго идет фильм?",
        transcription: "haʊ lɒŋ dəz ðə ˈmuːvi lɑːst? / haʊ lɒŋ z ðə ˈmuːvi?",
        translation: "How long does the movie last? / How long is the movie?",
      },
      {
        id: 62,
        expression: "Как долго идут эти уроки?",
        transcription: "haʊ lɒŋ dʊ ðiːz ˈlɛsnz lɑːst? / haʊ lɒŋ ə ðiːz ˈlɛsnz?",
        translation:
          "How long do these lessons last? / How long are these lessons?",
      },
      {
        id: 63,
        expression: "Как зовут девочек?",
        transcription: "wɒt ə ðə gɜːlz neɪmz?",
        translation: "What are the girls' names?",
      },
      {
        id: 64,
        expression: "Как зовут девочку?",
        transcription: "wɒts ðə gɜːlz neɪm?",
        translation: "What's the girl's name?",
      },
      {
        id: 65,
        expression: "Как зовут женщину?",
        transcription: "wɒts ðə ˈwʊmənz neɪm?",
        translation: "What's the woman's name?",
      },
      {
        id: 66,
        expression: "Какие у мальчика хобби?",
        transcription: "wɒt ˈhɒbiz dəz ðə bɔɪ hæv? / wɒt ə ðə bɔɪz ˈhɒbiz?",
        translation:
          "What hobbies does the boy have? / What are the boy's hobbies?",
      },
      {
        id: 67,
        expression: "Какое у мальчика хобби?",
        transcription: "wɒts ðə bɔɪz ˈhɒbi?",
        translation: "What's the boy's hobby?",
      },
      {
        id: 68,
        expression: "Какие у того мальчика хобби?",
        transcription: "wɒt ə ðət bɔɪz ˈhɒbiz?",
        translation: "What are that boy's hobbies?",
      },
      {
        id: 69,
        expression: "Как он обычно туда добирается?",
        transcription: "haʊ dəz hi ˈjuːʒʊəli gɛt ðeə?",
        translation: "How does he usually get there?",
      },
      {
        id: 70,
        expression: "Как она обычно туда добирается?",
        transcription: "haʊ dəz ʃi ˈjuːʒʊəli gɛt ðeə?",
        translation: "How does she usually get there?",
      },
      {
        id: 71,
        expression: "Как ты обычно туда добираешься?",
        transcription: "haʊ dʊ jʊ ˈjuːʒʊəli gɛt ðeə?",
        translation: "How do you usually get there?",
      },
      {
        id: 72,
        expression: "Как долго идут уроки?",
        transcription: "haʊ lɒŋ dʊ ðə ˈlɛsnz lɑːst? / haʊ lɒŋ ə ðə ˈlɛsnz?",
        translation:
          "How long do the lessons last? / How long are the lessons?",
      },
      {
        id: 73,
        expression: "Как долго идет этот урок?",
        transcription: "haʊ lɒŋ dəz ðɪs ˈlɛsn lɑːst? / haʊ lɒŋ z ðɪs ˈlɛsn?",
        translation:
          "How long does this lesson last? / How long is this lesson?",
      },
      {
        id: 74,
        expression: "Какой у него уровень английского?",
        transcription: "wɒts ɪz ˈɪŋglɪʃ ˈlɛvl?",
        translation: "What's his English level?",
      },
      {
        id: 75,
        expression: "Какой у нее уровень английского?",
        transcription: "wɒts hər ˈɪŋglɪʃ ˈlɛvl?",
        translation: "What's her English level?",
      },
      {
        id: 76,
        expression: "Как зовут эту девочку?",
        transcription: "wɒts ðɪs gɜːlz neɪm?",
        translation: "What's this girl's name?",
      },
      {
        id: 77,
        expression: "Как ты добираешься до работы?",
        transcription: "haʊ dʊ jʊ gɛt tə wɜːk?",
        translation: "How do you get to work?",
      },
      {
        id: 78,
        expression: "Как он добирается до работы?",
        transcription: "haʊ dəz hi gɛt tə wɜːk?",
        translation: "How does he get to work?",
      },
      {
        id: 79,
        expression: "Как ты добираешься до школы?",
        transcription: "haʊ dʊ jʊ gɛt tə skuːl?",
        translation: "How do you get to school?",
      },
      {
        id: 80,
        expression: "Как долго идут курсы?",
        transcription: "haʊ lɒŋ ə ðə ˈkɔːsɪz?",
        translation: "How long are the courses?",
      },
      {
        id: 81,
        expression: "Как долго идут эти курсы?",
        transcription: "haʊ lɒŋ ə ðiːz ˈkɔːsɪz?",
        translation: "How long are these courses?",
      },
      {
        id: 82,
        expression: "Как долго идет этот курс?",
        transcription: "haʊ lɒŋ z ðɪs kɔːs?",
        translation: "How long is this course?",
      },
      {
        id: 83,
        expression: "Как ты добираешься домой после работы?",
        transcription: "haʊ dʊ jʊ gɛt həʊm ˈɑːftə wɜːk?",
        translation: "How do you get home after work?",
      },
      {
        id: 84,
        expression: "Как она добирается домой после работы?",
        transcription: "haʊ dəz ʃi gɛt həʊm ˈɑːftə wɜːk?",
        translation: "How does she get home after work?",
      },
      {
        id: 85,
        expression: "Как ты возвращаешься домой?",
        transcription:
          "haʊ dʊ jʊ kʌm bæk həʊm? / haʊ dʊ jʊ gɛt bæk həʊm? / haʊ dʊ jʊ rɪˈtɜːn həʊm?",
        translation:
          "How do you come back home? / How do you get back home? / How do you return home?",
      },
      {
        id: 86,
        expression: "Сколько раз в неделю он ходит на работу?",
        transcription: "haʊ ˈmɛni taɪmz ə wiːk dəz hi gəʊ tə wɜːk?",
        translation: "How many times a week does he go to work?",
      },
      {
        id: 87,
        expression: "Что в сумке?",
        transcription: "wɒts ɪn ðə bæg?",
        translation: "What's in the bag?",
      },
      {
        id: 88,
        expression: "Какие их потребности?",
        transcription: "wɒt ə ðeə niːdz?",
        translation: "What are their needs?",
      },
      {
        id: 89,
        expression: "Как долго ты добираешься до работы?",
        transcription: "haʊ lɒŋ dʊ jʊ gɛt tə wɜːk?",
        translation: "How long do you get to work?",
      },
      {
        id: 90,
        expression: "Почему он изучает английский самостоятельно?",
        transcription: "waɪ dəz hi lɜːn ˈɪŋglɪʃ baɪ hɪmˈsɛlf?",
        translation: "Why does he learn English by himself?",
      },
      {
        id: 91,
        expression: "Сколько раз в неделю ты ходишь в спортзал?",
        transcription: "haʊ ˈmɛni taɪmz ə wiːk dʊ jʊ gəʊ tə ðə ʤɪm?",
        translation: "How many times a week do you go to the gym?",
      },
      {
        id: 92,
        expression: "Как долго ты добираешься в университет?",
        transcription: "haʊ lɒŋ dʊ jʊ gɛt tə ˌjuːnɪˈvɜːsɪti?",
        translation: "How long do you get to university?",
      },
      {
        id: 93,
        expression: "Как часто ты бегаешь?",
        transcription: "haʊ ˈɒf(ə)n dʊ jʊ gəʊ ˈʤɒgɪŋ?",
        translation: "How often do you go jogging?",
      },
      {
        id: 94,
        expression: "Почему ты предпочитаешь делать это самостоятельно?",
        transcription:
          "waɪ dʊ jʊ priˈfɜː tə dʊ ɪt baɪ jɔːˈsɛlf? / waɪ dʊ jʊ priˈfɜː tə dʊ ɪt ɒn jər əʊn?",
        translation:
          "Why do you prefer to do it by yourself? / Why do you prefer to do it on your own?",
      },
      {
        id: 95,
        expression: "Насколько дорогие эти часы?",
        transcription: "haʊ ɪksˈpɛnsɪv z ðɪs wɒʧ?",
        translation: "How expensive is this watch?",
      },
      {
        id: 96,
        expression: "Какой у тебя уровень?",
        transcription: "wɒts jə ˈlɛvl?",
        translation: "What's your level?",
      },
      {
        id: 97,
        expression: "Какие у мальчиков хобби?",
        transcription: "wɒt ə ðə bɔɪz ˈhɒbiz?",
        translation: "What are the boys' hobbies?",
      },
      {
        id: 98,
        expression: "Насколько это полезно?",
        transcription: "haʊ ˈjuːsfʊl z ɪt?",
        translation: "How useful is it?",
      },
      {
        id: 99,
        expression: "Насколько это эффективно?",
        transcription: "haʊ ɪˈfɛktɪv z ɪt?",
        translation: "How effective is it?",
      },
      {
        id: 100,
        expression: "Как зовут мальчиков?",
        transcription: "wɒt ə ðə bɔɪz neɪmz?",
        translation: "What are the boys' names?",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Закрепление пройденного материала</h3>
</div>`,
    },
  ],
];
