export const contentLesson127 = [
  [
    {
      title: 'Урок 127. 360 Verbs. Part 1',
    },
    [
      {
        id: 1,
        expression: 'Товары были доставлены вовремя',
        transcription: 'ðə gʊdz wə dɪˈlɪvəd ɒn taɪm',
        translation: 'The goods were delivered on time',
      },
      {
        id: 2,
        expression: 'Что задержало тебя?',
        transcription: 'wɒt dɪˈleɪd juː?',
        translation: 'What delayed you?',
      },
      {
        id: 3,
        expression: 'Я положил это на стол',
        transcription: 'aɪ leɪd ɪt ɒn ðə ˈteɪbl',
        translation: 'I laid it on the table',
      },
      {
        id: 4,
        expression: 'Ему пришлось продать свой бизнес',
        transcription: 'hi həd tə sɛl ɪz ˈbɪznɪs',
        translation: 'He had to sell his business',
      },
      {
        id: 5,
        expression: 'Он пришел на вечеринку или нет?',
        transcription: 'dɪd hi kʌm tə ðə ˈpɑːti ɔː nɒt?',
        translation: 'Did he come to the party or not?',
      },
      {
        id: 6,
        expression: 'Она убрала комнату',
        transcription: 'ʃi kliːnd ðə ruːm',
        translation: 'She cleaned the room',
      },
      {
        id: 7,
        expression: 'Этот дом скоро будет продан',
        transcription: 'ðɪs haʊs wɪl bi səʊld suːn',
        translation: 'This house will be sold soon',
      },
      {
        id: 8,
        expression: 'Я не думаю, что он согласится сделать это',
        transcription: 'aɪ dəʊnt θɪŋk ðət hiːl əˈgriː tə dʊ ɪt',
        translation: "I don't think that he'll agree to do it",
      },
      {
        id: 9,
        expression: 'Когда она открыла дверь, он играл на гитаре',
        transcription: 'wɛn ʃi ˈəʊpənd ðə dɔː, hi wəz ˈpleɪɪŋ ðə gɪˈtɑː',
        translation: 'When she opened the door, he was playing the guitar',
      },
      {
        id: 10,
        expression: 'Я вырос в Соединенных Штатах',
        transcription: 'aɪ gruː ʌp ɪn ðə jʊˈnaɪtɪd steɪts',
        translation: 'I grew up in the United States',
      },
      {
        id: 11,
        expression: 'Если ты умножишь десять на пять, ты получишь пятьдесят',
        transcription: 'ɪf jʊ ˈmʌltɪplaɪ tɛn baɪ faɪv, juːl gɛt ˈfɪfti',
        translation: "If you multiply ten by five, you'll get fifty",
      },
      {
        id: 12,
        expression: 'Она согласилась сделать это в конце',
        transcription: 'ʃi əˈgriːd tə dʊ ɪt ɪn ði ɛnd',
        translation: 'She agreed to do it in the end',
      },
      {
        id: 13,
        expression: 'Я абсолютно уверен, что Бог существует',
        transcription: 'aɪm ˈæbsəluːtli ʃʊə ðət gɒd ɪgˈzɪsts',
        translation: "I'm absolutely sure that God exists",
      },
      {
        id: 14,
        expression: 'Как они разрешили этот конфликт?',
        transcription: 'haʊ dɪd ðeɪ rɪˈzɒlv ðɪs ˈkɒnflɪkt?',
        translation: 'How did they resolve this conflict?',
      },
      {
        id: 15,
        expression: 'Что тебя напугало больше всего?',
        transcription: 'wɒt ˈfraɪtnd jʊ məʊst əv ɔːl?',
        translation: 'What frightened you most of all?',
      },
      {
        id: 16,
        expression: 'Когда ты мне это покажешь?',
        transcription: 'wɛn wɪl jʊ ʃəʊ ɪt tə miː?',
        translation: 'When will you show it to me?',
      },
      {
        id: 17,
        expression: 'Как он обычно добирается до работы?',
        transcription: 'haʊ dəz hi ˈjuːʒʊəli gɛt tə wɜːk?',
        translation: 'How does he usually get to work?',
      },
      {
        id: 18,
        expression: 'Та собака его укусила',
        transcription: 'ðæt dɒg bɪt hɪm',
        translation: 'That dog bit him',
      },
      {
        id: 19,
        expression: 'Его очень сильно избили',
        transcription: 'hi wəz ˈbiːtn ˈvɛri ˈbædli',
        translation: 'He was beaten very badly',
      },
      {
        id: 20,
        expression: 'Он пнул дверь',
        transcription: 'hi kɪkt ðə dɔː',
        translation: 'He kicked the door',
      },
      {
        id: 21,
        expression: 'Он взобрался на гору',
        transcription: 'hi klaɪmd ʌp ðə ˈmaʊntɪn',
        translation: 'He climbed up the mountain',
      },
      {
        id: 22,
        expression: 'Это было показано по телевизору',
        transcription: 'ɪt wəz ʃəʊn ɒn ˌtiːˈviː',
        translation: 'It was shown on TV',
      },
      {
        id: 23,
        expression: 'Когда это будет доставлено?',
        transcription: 'wɛn wɪl ɪt bi dɪˈlɪvəd?',
        translation: 'When will it be delivered?',
      },
      {
        id: 24,
        expression: 'Он умеет кататься на велосипеде?',
        transcription: 'kən hi raɪd ə ˈbaɪsɪkl?',
        translation: 'Can he ride a bicycle?',
      },
      {
        id: 25,
        expression: 'Все файлы были удалены',
        transcription: 'ɔːl ðə faɪlz wə dɪˈliːtɪd',
        translation: 'All the files were deleted',
      },
      {
        id: 26,
        expression: 'Я удалил ненужные документы',
        transcription: 'aɪ dɪˈliːtɪd ʌnˈnɛsɪsəri ˈdɒkjʊmənts',
        translation: 'I deleted unnecessary documents',
      },
      {
        id: 27,
        expression: 'Я думаю, что они проиграют',
        transcription: 'aɪ θɪŋk ðət ðeɪl luːz',
        translation: "I think that they'll lose",
      },
      {
        id: 28,
        expression: 'Это ранило ее чувства',
        transcription: 'ɪt hɜːt hə ˈfiːlɪŋz',
        translation: 'It hurt her feelings',
      },
      {
        id: 29,
        expression: 'Они решили снизить цену',
        transcription: 'ðeɪ dɪˈsaɪdɪd tə ˈdiːkriːs ðə praɪs',
        translation: 'They decided to decrease the price',
      },
      {
        id: 30,
        expression: 'Я забыл позвонить ему',
        transcription: 'aɪ fəˈgɒt tə kɔːl hɪm',
        translation: 'I forgot to call him',
      },
      {
        id: 31,
        expression: 'Он пнул мяч',
        transcription: 'hi kɪkt ðə bɔːl',
        translation: 'He kicked the ball',
      },
      {
        id: 32,
        expression: 'Он вырос в маленькой деревне',
        transcription: 'hi gruː ʌp ɪn ə smɔːl ˈvɪlɪʤ',
        translation: 'He grew up in a small village',
      },
      {
        id: 33,
        expression: 'Они часто спорят без причины',
        transcription: 'ðeɪ ˈɒf(ə)n ˈɑːgjuː fə nəʊ ˈriːzn',
        translation: 'They often argue for no reason',
      },
      {
        id: 34,
        expression: 'Его рейс задержали',
        transcription: 'hɪz flaɪt wəz dɪˈleɪd',
        translation: 'His flight was delayed',
      },
      {
        id: 35,
        expression: 'Человек внезапно исчез',
        transcription: 'ðə mæn ˌdɪsəˈpɪəd ˈsʌdnli',
        translation: 'The man disappeared suddenly',
      },
      {
        id: 36,
        expression: 'Ты одолжишь ему эти деньги?',
        transcription: 'wɪl jʊ lɛnd ɪm ðɪs ˈmʌni?',
        translation: 'Will you lend him this money?',
      },
      {
        id: 37,
        expression: 'Это означало следующую вещь',
        transcription: 'ɪt mɛnt ðə ˈfɒləʊɪŋ θɪŋ',
        translation: 'It meant the following thing',
      },
      {
        id: 38,
        expression: 'Что все это значит сейчас?',
        transcription: 'wɒt dəz ɪt ɔːl miːn naʊ?',
        translation: 'What does it all mean now?',
      },
      {
        id: 39,
        expression: 'Она скоро собирается купить новую квартиру',
        transcription: 'ʃiːz ˈgəʊɪŋ tə baɪ ə njuː əˈpɑːtmənt suːn',
        translation: "She's going to buy a new apartment soon",
      },
      {
        id: 40,
        expression: 'Это было куплено в другом месте',
        transcription: 'ɪt wəz bɔːt ɪn əˈnʌðə pleɪs',
        translation: 'It was bought in another place',
      },
      {
        id: 41,
        expression:
          'Я учу английский, чтобы общаться с другими людьми, которые также могут говорить на нем',
        transcription:
          'aɪ lɜːn ˈɪŋglɪʃ tə kəˈmjuːnɪkeɪt wɪð ˈʌðə ˈpiːpl huː kən ˈɔːlsəʊ spiːk ɪt',
        translation:
          'I learn English to communicate with other people who can also speak it',
      },
      {
        id: 42,
        expression: 'Когда он вошел в комнату, они спорили о чем-то',
        transcription:
          'wɛn hi ˈɛntəd ðə ruːm, ðeɪ wər ˈɑːgjuːɪŋ əˈbaʊt ˈsʌmθɪŋ',
        translation:
          'When he entered the room, they were arguing about something',
      },
      {
        id: 43,
        expression: 'Она повредила спину, пока она играла в теннис',
        transcription: 'ʃi hɜːt hə bæk waɪl ʃi wəz ˈpleɪɪŋ ˈtɛnɪs',
        translation: 'She hurt her back while she was playing tennis',
      },
      {
        id: 44,
        expression:
          'Он поднялся по лестнице, чтобы посмотреть, что там происходило',
        transcription: 'hi klaɪmd ʌp ðə ˈlædə tə siː wɒt wəz ˈgəʊɪŋ ɒn ðeə',
        translation: 'He climbed up the ladder to see what was going on there',
      },
      {
        id: 45,
        expression: 'Собака хотела его укусить, но он сумел убежать от ее',
        transcription:
          'ðə dɒg ˈwɒntɪd tə baɪt ɪm bət hi ˈmænɪʤd tʊ ɪsˈkeɪp frəm ɪt',
        translation:
          'The dog wanted to bite him but he managed to escape from it',
      },
      {
        id: 46,
        expression: 'Он думает, что никто не заметит, если он сделает эту вещь',
        transcription: 'hi θɪŋks ðət nəʊ wʌn wɪl ˈnəʊtɪs ɪt ɪf hi dəz ðɪs θɪŋ',
        translation:
          'He thinks that no one will notice it if he does this thing',
      },
      {
        id: 47,
        expression: 'Я стараюсь измерять свой прогресс на регулярной основе',
        transcription: 'aɪ traɪ tə ˈmɛʒə maɪ ˈprəʊgrəs ɒn ə ˈrɛgjʊlə ˈbeɪsɪs',
        translation: 'I try to measure my progress on a regular basis',
      },
      {
        id: 48,
        expression:
          'Если у тебя не будет достаточно практики, ты очень быстро это забудешь',
        transcription:
          'ɪf jʊ dəʊnt həv ɪˈnʌf ˈpræktɪs juːl fəˈgɛt ɪt ˈvɛri ˈkwɪkli',
        translation:
          "If you don't have enough practice you'll forget it very quickly",
      },
      {
        id: 49,
        expression: 'Они сидели на полу и болтали о чем-то',
        transcription: 'ðeɪ wə ˈsɪtɪŋ ɒn ðə flɔːr ənd ˈʧætɪŋ əˈbaʊt ˈsʌmθɪŋ',
        translation:
          'They were sitting on the floor and chatting about something',
      },
      {
        id: 50,
        expression: 'Мы будем гулять вместе завтра в это время',
        transcription: 'wiːl bi ˈwɔːkɪŋ təˈgɛðə ðɪs taɪm təˈmɒrəʊ',
        translation: "We'll be walking together this time tomorrow",
      },
      {
        id: 51,
        expression: 'Не все зависит от меня сейчас',
        transcription: 'nɒt ˈɛvrɪθɪŋ dɪˈpɛndz ɒn mi naʊ',
        translation: 'Not everything depends on me now',
      },
      {
        id: 52,
        expression: 'Что бы ты сделал на моем месте?',
        transcription: 'wɒt wəd jʊ dʊ ɪn maɪ pleɪs?',
        translation: 'What would you do in my place?',
      },
      {
        id: 53,
        expression: 'Что бы ты сделал в этой ситуации?',
        transcription: 'wɒt wəd jʊ dʊ ɪn ðɪs ˌsɪtjʊˈeɪʃən?',
        translation: 'What would you do in this situation?',
      },
      {
        id: 54,
        expression: 'Я получил так много сообщений на этой неделе',
        transcription: 'aɪv gɒt səʊ ˈmɛni ˈmɛsɪʤɪz ðɪs wiːk',
        translation: "I've got so many messages this week",
      },
      {
        id: 55,
        expression: 'Когда я открыл глаза, я увидел солнце',
        transcription: 'wɛn aɪ ˈəʊpənd maɪ aɪz, aɪ sɔː ðə sʌn',
        translation: 'When I opened my eyes, I saw the sun',
      },
      {
        id: 56,
        expression: 'Он сказал, что купил новую машину',
        transcription: 'hi sɛd ðət hiːd bɔːt ə njuː kɑː',
        translation: "He said that he'd bought a new car",
      },
      {
        id: 57,
        expression: 'Он сказал, что сделал это раньше',
        transcription: 'hi sɛd ðət hiːd dʌn ɪt bɪˈfɔː',
        translation: "He said that he'd done it before",
      },
      {
        id: 58,
        expression: 'Она сказала, что она продала свою машину',
        transcription: 'ʃi sɛd ðət ʃiːd səʊld hə kɑː',
        translation: "She said that she'd sold her car",
      },
      {
        id: 59,
        expression: 'Цена была немного снижена',
        transcription: 'ðə praɪs wəz diːˈkriːst ə ˈlɪtl',
        translation: 'The price was decreased a little',
      },
      {
        id: 60,
        expression: 'Цена снижена снова',
        transcription: 'ðə praɪs ɪz diːˈkriːst əˈgɛn',
        translation: 'The price is decreased again',
      },
      {
        id: 61,
        expression: 'Цена была немного увеличена',
        transcription: 'ðə praɪs wəz ɪnˈkriːst ə ˈlɪtl',
        translation: 'The price was increased a little',
      },
      {
        id: 62,
        expression: 'Текст был переведен на английский',
        transcription: 'ðə tɛkst wəz trænsˈleɪtɪd ˈɪntə ˈɪŋglɪʃ',
        translation: 'The text was translated into English',
      },
      {
        id: 63,
        expression: 'Текст был переведен с русского на английский',
        transcription: 'ðə tɛkst wəz trænsˈleɪtɪd frəm ˈɪŋglɪʃ ˈɪntə ˈrʌʃ(ə)n',
        translation: 'The text was translated from English into Russian',
      },
      {
        id: 64,
        expression: 'Этот текст переведен на английский',
        transcription: 'ðɪs tɛkst s trænsˈleɪtɪd ˈɪntə ˈɪŋglɪʃ',
        translation: 'This text is translated into English',
      },
      {
        id: 65,
        expression: 'Завтра в это время я буду читать этот отчет',
        transcription: 'ðɪs taɪm təˈmɒrəʊ aɪl bi ˈriːdɪŋ ðɪs rɪˈpɔːt',
        translation: "This time tomorrow I'll be reading this report",
      },
      {
        id: 66,
        expression: 'Завтра в это время я буду лежать на пляже',
        transcription: 'ðɪs taɪm təˈmɒrəʊ aɪl baɪ ˈlaɪɪŋ ɒn ðə biːʧ',
        translation: "This time tomorrow I'll by lying on the beach",
      },
      {
        id: 67,
        expression: 'Завтра в это время я буду плавать в океане',
        transcription: 'ðɪs taɪm təˈmɒrəʊ aɪl bi ˈswɪmɪŋ ɪn ði ˈəʊʃən',
        translation: "This time tomorrow I'll be swimming in the ocean",
      },
      {
        id: 68,
        expression: 'Мы гуляем в парке около получаса',
        transcription: 'wiːv biːn ˈwɜːkɪŋ ɪn ðə pɑːk fər əˈbaʊt hɑːf ən ˈaʊə',
        translation: "We've been working in the park for about half an hour",
      },
      {
        id: 69,
        expression: 'Я болтаю со своими друзьями около двух часов',
        transcription: 'aɪv biːn ˈʧætɪŋ wɪð maɪ frɛndz fər əˈbaʊt tuː ˈaʊəz',
        translation: "I've been chatting with my friends for about two hours",
      },
      {
        id: 70,
        expression: 'Он делает свое домашнее задание около трех часов',
        transcription: 'hiːz biːn ˈdu(ː)ɪŋ ɪz ˈhəʊmˌwɜːk fər əˈbaʊt θriː ˈaʊəz',
        translation: "He's been doing his homework for about three houres",
      },
      {
        id: 71,
        expression: 'Я перевел это с русского на английский',
        transcription: 'aɪ trænsˈleɪtɪd ɪt frəm ˈrʌʃ(ə)n ˈɪntə ˈɪŋglɪʃ',
        translation: 'I translated it from Russian into English',
      },
      {
        id: 72,
        expression: 'Это переведено с английского на русский',
        transcription: 'ɪt s trænsˈleɪtɪd frəm ˈɪŋglɪʃ ˈɪntə ˈrʌʃ(ə)n',
        translation: 'It is translated from English into Russian',
      },
      {
        id: 73,
        expression: 'Это переведено на английский?',
        transcription: 'ɪz ɪt trænsˈleɪtɪd ˈɪntə ˈɪŋglɪʃ?',
        translation: 'Is it translated into English?',
      },
      {
        id: 74,
        expression: 'Как им удалось исчезнуть?',
        transcription: 'haʊ dɪd ðeɪ ˈmænɪʤ tə ˌdɪsəˈpɪə?',
        translation: 'How did they manage to disappear?',
      },
      {
        id: 75,
        expression: 'Как им удалось сбежать из этого здания?',
        transcription: 'haʊ dɪd ðeɪ ˈmænɪʤ tʊ ɪsˈkeɪp frəm ðɪs ˈbɪldɪŋ?',
        translation: 'How did they manage to escape from this building?',
      },
      {
        id: 76,
        expression: 'Как им удалось разрешить этот конфликт?',
        transcription: 'haʊ dɪd ðeɪ ˈmænɪʤ tə rɪˈzɒlv ðɪs ˈkɒnflɪkt?',
        translation: 'How did they manage to resolve this conflict?',
      },
      {
        id: 77,
        expression: 'Я бы сделал другую вещь на твоем месте',
        transcription: 'aɪ wəd dʊ əˈnʌðə θɪŋ ɪn jə pleɪs',
        translation: 'I would do another thing in your place',
      },
      {
        id: 78,
        expression: 'Я бы сделал такую же вещь на твоем месте',
        transcription: 'aɪ wəd dʊ ðə seɪm θɪŋ ɪn jə pleɪs',
        translation: 'I would do the same thing in your place',
      },
      {
        id: 79,
        expression: 'Я верю, что решение этой проблемы существует',
        transcription: 'aɪ bɪˈliːv ðət ə səˈluːʃən tə ðɪs ˈprɒbləm ɪgˈzɪsts',
        translation: 'I believe that a solution to this problem exists',
      },
      {
        id: 80,
        expression: 'Я верю, что Бог существует',
        transcription: 'aɪ bɪˈliːv ðət gɒd ɪgˈzɪsts',
        translation: 'I believe that God exists',
      },
      {
        id: 81,
        expression: 'Я уверен, что Бог действительно существует',
        transcription: 'aɪm ʃʊə ðət gɒd ˈrɪəli ɪgˈzɪsts',
        translation: "I'm sure that God really exists",
      },
      {
        id: 82,
        expression:
          'Тебе нужно умножить двадцать на пять, чтобы получить эту цифру',
        transcription:
          'jʊ niːd tə ˈmʌltɪplaɪ ˈtwɛnti baɪ faɪv tə gɛt ðɪs ˈfɪgə',
        translation: 'You need to multiply twenty by five to get this figure',
      },
      {
        id: 83,
        expression:
          'Тебе нужно умножить десять на пять, чтобы получить пятьдесят',
        transcription: 'jʊ niːd tə ˈmʌltɪplaɪ tɛn baɪ faɪv tə gɛt ˈfɪfti',
        translation: 'You need to multiply ten by five to get fifty',
      },
      {
        id: 84,
        expression: 'Она заметила, что кто-то украл ее сумку',
        transcription: 'ʃi ˈnəʊtɪst ðət ˈsʌmwʌn həd ˈstəʊlən hə bæg',
        translation: 'She noticed that someone had stolen her bag',
      },
      {
        id: 85,
        expression: 'Когда я смогу достичь следующего уровня?',
        transcription: 'wɛn wɪl aɪ bi ˈeɪbl tə riːʧ ðə nɛkst ˈlɛvl?',
        translation: 'When will I be able to reach the next level?',
      },
      {
        id: 86,
        expression: 'Мне нужно измерить размер комнаты сейчас',
        transcription: 'aɪ niːd tə ˈmɛʒə ðə saɪz əv ðə ruːm naʊ',
        translation: 'I need to measure the size of the room now',
      },
      {
        id: 87,
        expression: 'Как был разрешен этот конфликт?',
        transcription: 'haʊ wəz ðɪs ˈkɒnflɪkt rɪˈzɒlvd?',
        translation: 'How was this conflict resolved?',
      },
      {
        id: 88,
        expression: 'Он сказал, что положил это на стол',
        transcription: 'hi sɛd ðət hiːd laɪd ɪt ɒn ðə ˈteɪbl',
        translation: "He said that he'd lied it on the table",
      },
      {
        id: 89,
        expression: 'Я никогда не ездил на верблюде',
        transcription: 'aɪv ˈnɛvə ˈrɪdn ə ˈkæməl',
        translation: "I've never ridden a camel",
      },
      {
        id: 90,
        expression: 'Кто только что пришел к тебе?',
        transcription: 'huː həz ʤəst kʌm tə juː?',
        translation: 'Who has just come to you?',
      },
      {
        id: 91,
        expression: 'Я читаю эту статью около десяти минут',
        transcription: 'aɪv biːn ˈriːdɪŋ ðɪs ˈɑːtɪkl fər əˈbaʊt tɛn ˈmɪnɪts',
        translation: "I've been reading this article for about ten minutes",
      },
      {
        id: 92,
        expression: 'Я сяду в кресло, если ты не возражаешь',
        transcription: 'aɪl sɪt ɪn ən ˈɑːmˈʧeər ɪf jʊ dəʊnt maɪnd',
        translation: "I'll sit in an armchair if you don't mind",
      },
      {
        id: 93,
        expression: 'Она провела весь день, готовя и убирая',
        transcription: 'ʃi spɛnt ɔːl deɪ ˈkʊkɪŋ ənd ˈkliːnɪŋ',
        translation: 'She spent all day cooking and cleaning',
      },
      {
        id: 94,
        expression: 'Что от нас зависит сейчас?',
        transcription: 'wɒt dɪˈpɛndz ɒn əs naʊ?',
        translation: 'What depends on us now?',
      },
      {
        id: 95,
        expression: 'Ты уже достиг этого уровня?',
        transcription: 'həv jʊ ɔːlˈrɛdi riːʧt ðɪs ˈlɛvl?',
        translation: 'Have you already reached this level?',
      },
      {
        id: 96,
        expression: 'Это никогда не пугало меня',
        transcription: 'ɪts ˈnɛvə ˈfraɪtnd miː',
        translation: "It's never frightened me",
      },
      {
        id: 97,
        expression: 'Почему он был избит?',
        transcription: 'waɪ wəz hi ˈbiːtn?',
        translation: 'Why was he beaten?',
      },
      {
        id: 98,
        expression: 'Эти деньги одолжили ему',
        transcription: 'ðɪs ˈmʌni wəz lɛnt tə hɪm',
        translation: 'This money was lent to him',
      },
      {
        id: 99,
        expression: 'Я в основном общаюсь с ним по электронной почте',
        transcription: 'aɪ ˈməʊstli kəˈmjuːnɪkeɪt wɪð ɪm baɪ ˈiːmeɪl',
        translation: 'I mostly communicate with him by email',
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Закрепление пройденного материала</h3>
</div>`,
    },
  ],
];
