export const wordsLesson252 = [
  [
    {
      id: 1,
      word: 'back down',
      transcription: 'bæk daʊn',
      translation: 'отступать',
    },
    {
      id: 2,
      word: 'live up to',
      transcription: 'lɪv ʌp tuː',
      translation: 'оправдывать [ожидания]',
    },
    {
      id: 3,
      word: 'account for',
      transcription: 'əˈkaʊnt fɔː',
      translation: 'объяснять',
    },
    {
      id: 4,
      word: 'brush up on',
      transcription: 'brʌʃ ʌp ɒn',
      translation: 'освежать',
    },
    {
      id: 5,
      word: 'spark off',
      transcription: 'spɑːk ɒf',
      translation: 'приводить, вызывать',
    },
    {
      id: 6,
      word: 'come down to',
      transcription: 'kʌm daʊn tuː',
      translation: 'сводиться к',
    },
    {
      id: 7,
      word: 'boil down to',
      transcription: 'bɔɪl daʊn tuː',
      translation: 'сводиться к',
    },
    {
      id: 8,
      word: 'carried away',
      transcription: 'ˈkærid əˈweɪ',
      translation: 'увлечен',
    },
    {
      id: 9,
      word: 'occur to',
      transcription: 'əˈkɜː tuː',
      translation: 'приходить в голову',
    },
    {
      id: 10,
      word: 'fall through',
      transcription: 'fɔːl θruː',
      translation: 'провалиться',
    },
    {
      id: 11,
      word: 'slog away',
      transcription: 'slɒɡ əˈweɪ',
      translation: 'усердно трудиться',
    },
    {
      id: 12,
      word: 'gobble up',
      transcription: 'ˈɡɒbᵊl ʌp',
      translation: 'проглотить, сожрать',
    },
    {
      id: 13,
      word: 'turn down',
      transcription: 'tɜːn daʊn',
      translation: 'отклонять, убавлять',
    },
    {
      id: 14,
      word: 'iron out',
      transcription: 'ˈaɪən aʊt',
      translation: 'сглаживать, улаживать, устранять',
    },
    {
      id: 15,
      word: 'look up',
      transcription: 'lʊk ʌp',
      translation: 'налаживаться',
    },
    {
      id: 16,
      word: 'astonishment',
      transcription: 'əsˈtɒnɪʃmənt',
      translation: 'удивление',
    },
    {
      id: 17,
      word: 'balanced',
      transcription: 'ˈbælənst',
      translation: 'сбалансированный',
    },
    {
      id: 18,
      word: 'biscuit',
      transcription: 'ˈbɪskɪt',
      translation: 'печенье',
    },
    {
      id: 19,
      word: 'disappearance',
      transcription: 'ˌdɪsəˈpɪərəns',
      translation: 'исчезновение',
    },
    {
      id: 20,
      word: 'fatty',
      transcription: 'ˈfæti',
      translation: 'жирный',
    },
    {
      id: 21,
      word: 'heated',
      transcription: 'ˈhiːtɪd',
      translation: 'жаркий',
    },
    {
      id: 22,
      word: 'inability',
      transcription: 'ˌɪnəˈbɪlɪti',
      translation: 'неспособность',
    },
    {
      id: 23,
      word: 'missing',
      transcription: 'ˈmɪsɪŋ',
      translation: 'отсутствующий',
    },
    {
      id: 24,
      word: 'overheat',
      transcription: 'ˌəʊvəˈhiːt',
      translation: 'перегреваться',
    },
    {
      id: 25,
      word: 'ringer',
      transcription: 'ˈrɪŋə',
      translation: 'звонок',
    },
    {
      id: 26,
      word: 'spendings',
      transcription: 'ˈspɛndɪŋz',
      translation: 'траты',
    },
    {
      id: 27,
      word: 'strike',
      transcription: 'straɪk',
      translation: 'поражать, удар',
    },
    {
      id: 28,
      word: 'unlocked',
      transcription: 'ʌnˈlɒkt',
      translation: 'не запертая',
    },
    {
      id: 29,
      word: 'unsolved',
      transcription: 'ʌnˈsɒlvd',
      translation: 'нерешенный',
    },
    {
      id: 30,
      word: 'via',
      transcription: 'ˈvaɪə',
      translation: 'через',
    },
  ],
];
