export const contentLesson142 = [
  [
    {
      title: 'Урок 142. 230 Adjectives. Part 4',
    },
    [
      {
        id: 1,
        expression: 'Это незаконно',
        transcription: 'ɪts ɪˈliːgəl / ɪt ˈɪznt ˈliːgəl',
        translation: "It's illegal / It isn't legal",
      },
      {
        id: 2,
        expression: 'Он такой милый человек',
        transcription: 'hiːz sʌʧ ə naɪs ˈpɜːsn',
        translation: "He's such a nice person",
      },
      {
        id: 3,
        expression: 'Это специальная цена',
        transcription: 'ɪts ə ˈspɛʃəl praɪs',
        translation: "It's a special price",
      },
      {
        id: 4,
        expression: 'Они беспокоятся о своей национальной безопасности',
        transcription: 'ðeə ˈwʌrid əˈbaʊt ðeə ˈnæʃənl sɪˈkjʊərɪti',
        translation: "They're worried about their national security",
      },
      {
        id: 5,
        expression: 'Она живет в доме поменьше',
        transcription: 'ʃi lɪvz ɪn ə ˈsmɔːlə haʊs',
        translation: 'She lives in a smaller house',
      },
      {
        id: 6,
        expression: 'Он действительно сердится на него',
        transcription: 'hiːz ˈrɪəli ˈæŋgri wɪð hɪm',
        translation: "He's really angry with him",
      },
      {
        id: 7,
        expression: 'Они такие профессиональные учителя',
        transcription: 'ðeə sʌʧ prəˈfɛʃənl ˈtiːʧəz',
        translation: "They're such professional teachers",
      },
      {
        id: 8,
        expression: 'Она обычная женщина',
        transcription: 'ʃiːz ən ˈɔːdnri ˈwʊmən',
        translation: "She's an ordinary woman",
      },
      {
        id: 9,
        expression: 'Он живет в обычных условиях',
        transcription: 'hi lɪvz ɪn ˈɔːdnri kənˈdɪʃənz',
        translation: 'He lives in ordinary conditions',
      },
      {
        id: 10,
        expression: 'Это неофициальный ответ',
        transcription: 'ɪts ɪnˈfɔːml ˈɑːnsə',
        translation: "It's informal answer",
      },
      {
        id: 11,
        expression: 'Здесь гораздо громче',
        transcription: 'ɪts mʌʧ ˈlaʊdə hɪə',
        translation: "It's much louder here",
      },
      {
        id: 12,
        expression: 'Музыка такая громкая',
        transcription: 'ðə ˈmjuːzɪk s səʊ laʊd',
        translation: 'The music is so loud',
      },
      {
        id: 13,
        expression: 'Она всегда полна энергии',
        transcription: 'ʃiːz ˈɔːlweɪz fʊl əv ˈɛnəʤi',
        translation: "She's always full of energy",
      },
      {
        id: 14,
        expression: 'Это правильный глагол',
        transcription: 'ɪts ə ˈrɛgjʊlə vɜːb',
        translation: "It's a regular verb",
      },
      {
        id: 15,
        expression: 'Они очень милые люди',
        transcription: 'ðeə ˈvɛri naɪs ˈpiːpl',
        translation: "They're very nice people",
      },
      {
        id: 16,
        expression: 'Она действительно злится на это',
        transcription: 'ʃiːz ˈrɪəli ˈæŋgri əˈbaʊt ɪt',
        translation: "She's really angry about it",
      },
      {
        id: 17,
        expression: 'Это была такая сложная проблема',
        transcription: 'ɪt wəz sʌʧ ə ˈdɪfɪkəlt ˈprɒbləm',
        translation: 'It was such a difficult problem',
      },
      {
        id: 18,
        expression: 'Шутка была совсем не смешная',
        transcription: 'ðə ʤəʊk wɒznt ˈfʌni ət ɔːl',
        translation: "The joke wasn't funny at all",
      },
      {
        id: 19,
        expression: 'Река очень глубокая',
        transcription: 'ðə ˈrɪvə z ˈvɛri diːp',
        translation: 'The river is very deep',
      },
      {
        id: 20,
        expression: 'Он очень терпеливый человек',
        transcription: 'hiːz ə ˈvɛri ˈpeɪʃənt ˈpɜːsn',
        translation: "He's a very patient person",
      },
      {
        id: 21,
        expression: 'Его машина более новая',
        transcription: 'hɪz kɑː z ˈnjuːə',
        translation: 'His car is newer',
      },
      {
        id: 22,
        expression: 'Она в безопасном месте сейчас',
        transcription: 'ʃiːz ɪn ə seɪf pleɪs naʊ',
        translation: "She's in a safe place now",
      },
      {
        id: 23,
        expression: 'Это более популярная песня',
        transcription: 'ɪts ə mɔː ˈpɒpjʊlə sɒŋ',
        translation: "It's a more popular song",
      },
      {
        id: 24,
        expression: 'Это было специальное предложение',
        transcription: 'ɪt wəz ə ˈspɛʃəl ˈɒfə',
        translation: 'It was a special offer',
      },
      {
        id: 25,
        expression: 'Она такая мудрая женщина',
        transcription: 'ʃiːz sʌʧ ə waɪz ˈwʊmən',
        translation: "She's such a wise woman",
      },
      {
        id: 26,
        expression: 'У него широкие плечи',
        transcription: 'hi həz brɔːd ˈʃəʊldəz',
        translation: 'He has broad shoulders',
      },
      {
        id: 27,
        expression: 'Ее английский безупречен',
        transcription: 'hər ˈɪŋglɪʃ ɪz ˈpɜːfɪkt',
        translation: 'Her English is perfect',
      },
      {
        id: 28,
        expression: 'Он был в очень сложной ситуации',
        transcription: 'hi wəz ɪn ə ˈvɛri ˈdɪfɪkəlt ˌsɪtjʊˈeɪʃən',
        translation: 'He was in a very difficult situation',
      },
      {
        id: 29,
        expression: 'Это такая смешная шутка',
        transcription: 'ɪts sʌʧ ə ˈfʌni ʤəʊk',
        translation: "It's such a funny joke",
      },
      {
        id: 30,
        expression: 'Это было такое мудрое решение',
        transcription: 'ɪt wəz sʌʧ ə waɪz dɪˈsɪʒən',
        translation: 'It was such a wise decision',
      },
      {
        id: 31,
        expression: 'Он был нашим специальным гостем',
        transcription: 'hi wəz ˈaʊə ˈspɛʃəl gɛst',
        translation: 'He was our special guest',
      },
      {
        id: 32,
        expression: 'Это очень популярный тренд в наши дни',
        transcription: 'ɪts ə ˈvɛri ˈpɒpjʊlə trɛnd ˈnaʊədeɪz',
        translation: "It's a very popular trend nowadays",
      },
      {
        id: 33,
        expression: 'У мальчика широкие плечи',
        transcription: 'ðə bɔɪ həz brɔːd ˈʃəʊldəz',
        translation: 'The boy has broad shoulders',
      },
      {
        id: 34,
        expression: 'То место было полно людей',
        transcription: 'ðæt pleɪs wəz fʊl əv ˈpiːpl',
        translation: 'That place was full of people',
      },
      {
        id: 35,
        expression: 'Она чувствует себя такой несчастной',
        transcription: 'ʃi fiːlz səʊ ʌnˈhæpi',
        translation: 'She feels so unhappy',
      },
      {
        id: 36,
        expression: 'Это идеальный пример',
        transcription: 'ɪts ə ˈpɜːfɪkt ɪgˈzɑːmpl',
        translation: "It's a perfect example",
      },
      {
        id: 37,
        expression: 'Он национальный герой',
        transcription: 'hiːz ə ˈnæʃənl ˈhɪərəʊ',
        translation: "He's a national hero",
      },
      {
        id: 38,
        expression: 'Тест был таким сложным',
        transcription: 'ðə tɛst wəz səʊ ˈdɪfɪkəlt',
        translation: 'The test was so difficult',
      },
      {
        id: 39,
        expression: 'Это такой сложный тест',
        transcription: 'ɪts sʌʧ ə ˈdɪfɪkəlt tɛst',
        translation: "It's such a difficult test",
      },
      {
        id: 40,
        expression: 'Она сейчас может чувствовать себя в безопасности',
        transcription: 'ʃi kən fiːl seɪf naʊ',
        translation: 'She can feel safe now',
      },
      {
        id: 41,
        expression: 'Эта песня не так популярна, как та',
        transcription: 'ðɪs sɒŋ ˈɪznt əz ˈpɒpjʊlər əz ðət wʌn',
        translation: "This song isn't as popular as that one",
      },
      {
        id: 42,
        expression: 'Он один из самых богатых людей в мире',
        transcription: 'hiːz wʌn əv ðə ˈrɪʧɪst ˈpiːpl ɪn ðə wɜːld',
        translation: "He's one of the richest people in the world",
      },
      {
        id: 43,
        expression: 'Нам также нужно учитывать политические факторы',
        transcription: 'wi ˈɔːlsəʊ niːd tə kənˈsɪdə pəˈlɪtɪkəl ˈfæktəz',
        translation: 'We also need to consider political factors',
      },
      {
        id: 44,
        expression: 'Я абсолютно уверен, что делаю правильную вещь',
        transcription: 'aɪm ˈæbsəluːtli ʃʊə ðət aɪm ˈdu(ː)ɪŋ ðə raɪt θɪŋ',
        translation: "I'm absolutely sure that I'm doing the right thing",
      },
      {
        id: 45,
        expression: 'Это неформальный способ сказать это по-английски',
        transcription: 'ɪts ən ɪnˈfɔːml weɪ əv ˈseɪɪŋ ɪt ɪn ˈɪŋglɪʃ',
        translation: "It's an informal way of saying it in English",
      },
      {
        id: 46,
        expression: 'Я знаю, что мне нужно быть более терпеливым',
        transcription: 'aɪ nəʊ ðət aɪ niːd tə bi mɔː ˈpeɪʃənt',
        translation: 'I know that I need to be more patient',
      },
      {
        id: 47,
        expression: 'Он может говорить на трех иностранных языках',
        transcription: 'hi kən spiːk θriː ˈfɒrɪn ˈlæŋgwɪʤɪz',
        translation: 'He can speak three foreign languages',
      },
      {
        id: 48,
        expression: 'Я абсолютно уверен, что Бог действительно существует',
        transcription: 'aɪm ˈæbsəluːtli ʃʊə ðət gɒd ˈrɪəli ɪgˈzɪsts',
        translation: "I'm absolutely sure that God really exists",
      },
      {
        id: 49,
        expression: 'Какая была общая сумма?',
        transcription: 'wɒt wəz ðə ˈtəʊtl sʌm?',
        translation: 'What was the total sum?',
      },
      {
        id: 50,
        expression: 'Он мой любимый видео блогер',
        transcription: 'hiːz maɪ ˈfeɪvərɪt ˈvɪdɪəʊ ˈblɒgə',
        translation: "He's my favorite video blogger",
      },
      {
        id: 51,
        expression: 'То, что они сделали, было незаконно',
        transcription: 'wɒt ðeɪ dɪd wəz ɪˈliːgəl',
        translation: 'What they did was illegal',
      },
      {
        id: 52,
        expression: 'Он один из самых богатых людей на нашей планете',
        transcription: 'hiːz wʌn əv ðə ˈrɪʧɪst ˈpiːpl ɪn ˈaʊə ˈplænɪt',
        translation: "He's one of the richest people in our planet",
      },
      {
        id: 53,
        expression: 'Общая сумма была двести долларов',
        transcription: 'ðə ˈtəʊtl sʌm wəz tuː ˈhʌndrəd ˈdɒləz',
        translation: 'The total sum was two hundred dollars',
      },
      {
        id: 54,
        expression: 'Это такое грязное место',
        transcription: 'ɪts sʌʧ ə ˈdɜːti pleɪs',
        translation: "It's such a dirty place",
      },
      {
        id: 55,
        expression: 'Он гораздо более богатый человек',
        transcription: 'hiːz ə mʌʧ ˈrɪʧə ˈpɜːsn',
        translation: "He's a much richer person",
      },
      {
        id: 56,
        expression: 'Я стараюсь делать это на регулярной основе',
        transcription: 'aɪ traɪ tə dʊ ɪt ɒn ə ˈrɛgjʊlə ˈbeɪsɪs',
        translation: 'I try to do it on a regular basis',
      },
      {
        id: 57,
        expression: 'Я использую это приложение на регулярной основе',
        transcription: 'aɪ juːz ðɪs ˌæplɪˈkeɪʃ(ə)n ɒn ə ˈrɛgjʊlə ˈbeɪsɪs',
        translation: 'I use this application on a regular basis',
      },
      {
        id: 58,
        expression: 'Я стараюсь делать это так часто, как я могу',
        transcription: 'aɪ traɪ tə dʊ ɪt əz ˈɒf(ə)n əz aɪ kæn',
        translation: 'I try to do it as often as I can',
      },
      {
        id: 59,
        expression: 'Это неформальный способ сказать это',
        transcription: 'ɪts ən ɪnˈfɔːml weɪ əv ˈseɪɪŋ ɪt',
        translation: "It's an informal way of saying it",
      },
      {
        id: 60,
        expression: 'Это неформальный способ сделать это',
        transcription: 'ɪts ən ɪnˈfɔːml weɪ əv ˈdu(ː)ɪŋ ɪt',
        translation: "It's an informal way of doing it",
      },
      {
        id: 61,
        expression: 'Он обычно носит неформальную одежду',
        transcription: 'hi ˈjuːʒʊəli weəz ɪnˈfɔːml kləʊðz',
        translation: 'He usually wears informal clothes',
      },
      {
        id: 62,
        expression: 'Это такое захватывающее событие',
        transcription: 'ɪts sʌʧ ən ɪkˈsaɪtɪŋ (ˈθrɪlɪŋ) ɪˈvɛnt',
        translation: "It's such an exciting (thrilling) event",
      },
      {
        id: 63,
        expression: 'Это событие было таким захватывающим',
        transcription: 'ðɪs ɪˈvɛnt wəz səʊ ɪkˈsaɪtɪŋ',
        translation: 'This event was so exciting',
      },
      {
        id: 64,
        expression: 'Это такое важное событие',
        transcription: 'ɪts sʌʧ ən ɪmˈpɔːtənt ɪˈvɛnt',
        translation: "It's such an important event",
      },
      {
        id: 65,
        expression: 'Эта новость такая захватывающая',
        transcription: 'ðɪs njuːz ɪz səʊ ɪkˈsaɪtɪŋ',
        translation: 'This news is so exciting',
      },
      {
        id: 66,
        expression: 'Эта новость такая шокирующая',
        transcription: 'ðɪs njuːz ɪz səʊ ʃɒkt',
        translation: 'This news is so shocked',
      },
      {
        id: 67,
        expression: 'Те новости такие захватывающие',
        transcription: 'ðæt njuːz ɪz səʊ ɪkˈsaɪtɪŋ',
        translation: 'That news is so exciting',
      },
      {
        id: 68,
        expression: 'Его дом меньше, чем ее',
        transcription: 'hɪz haʊs ɪz ˈsmɔːlə ðət hɜːz',
        translation: 'His house is smaller that hers',
      },
      {
        id: 69,
        expression: 'Его дом больше моего',
        transcription: 'hɪz haʊs ɪz ˈbɪgə ðən maɪn',
        translation: 'His house is bigger than mine',
      },
      {
        id: 70,
        expression: 'Его дом дороже, чем ее?',
        transcription: 'ɪz ɪz haʊs mɔːr ɪksˈpɛnsɪv ðən hɜːz?',
        translation: 'Is his house more expensive than hers?',
      },
      {
        id: 71,
        expression: 'Ты заплатил всю сумму сразу?',
        transcription: 'dɪd jʊ peɪ ðə ˈtəʊtl sʌm ət wʌns?',
        translation: 'Did you pay the total sum at once?',
      },
      {
        id: 72,
        expression: 'Ты потратил эту сумму сразу?',
        transcription: 'dɪd jʊ spɛnd ðɪs sʌm ət wʌns?',
        translation: 'Did you spend this sum at once?',
      },
      {
        id: 73,
        expression: 'Ты сделал это сразу?',
        transcription: 'dɪd jʊ dʊ ɪt ət wʌns?',
        translation: 'Did you do it at once?',
      },
      {
        id: 74,
        expression: 'Он более профессиональный тренер',
        transcription: 'hiːz ə mɔː prəˈfɛʃənl ˈtreɪnə',
        translation: "He's a more professional trainer",
      },
      {
        id: 75,
        expression: 'Он профессиональный спортсмен',
        transcription: 'hiːz ə prəˈfɛʃənl ˈspɔːtsmən',
        translation: "He's a professional sportsman",
      },
      {
        id: 76,
        expression: 'Он тренирует их действительно профессионально',
        transcription: 'hi treɪnz ðəm ˈrɪəli prəˈfɛʃnəli',
        translation: 'He trains them really professionally',
      },
      {
        id: 77,
        expression: 'Это одна из самых мощных организаций',
        transcription: 'ɪts wʌn əv ðə məʊst ˈpaʊəfʊl ˌɔːgənaɪˈzeɪʃən',
        translation: "It's one of the most powerful organisation",
      },
      {
        id: 78,
        expression: 'Это один из самых полезных уроков',
        transcription: 'ɪts wʌn əv ðə məʊst ˈjuːsfʊl ˈlɛsnz',
        translation: "It's one of the most useful lessons",
      },
      {
        id: 79,
        expression: 'Это самая богатая организация',
        transcription: 'ɪts ðə ˈrɪʧɪst ˌɔːgənaɪˈzeɪʃən',
        translation: "It's the richest organisation",
      },
      {
        id: 80,
        expression: 'Он отказался сделать это по политическим причинам',
        transcription: 'hi ˌriːˈfjuːzd tə dʊ ɪt fə pəˈlɪtɪkəl ˈriːznz',
        translation: 'He refused to do it for political reasons',
      },
      {
        id: 81,
        expression: 'Он отказался сделать это по другой причине',
        transcription: 'hi ˌriːˈfjuːzd tə dʊ ɪt fər əˈnʌðə ˈriːzn',
        translation: 'He refused to do it for another reason',
      },
      {
        id: 82,
        expression: 'Он сделал это без причины',
        transcription: 'hi dɪd ɪt fə nəʊ ˈriːzn',
        translation: 'He did it for no reason',
      },
      {
        id: 83,
        expression: 'Он гораздо более профессиональный тренер',
        transcription: 'hiːz ə mʌʧ mɔː prəˈfɛʃənl ˈtreɪnə',
        translation: "He's a much more professional trainer",
      },
      {
        id: 84,
        expression: 'Она действительно профессиональный музыкант',
        transcription: 'ʃiːz ə ˈrɪəli prəˈfɛʃənl mju(ː)ˈzɪʃən',
        translation: "She's a really professional musician",
      },
      {
        id: 85,
        expression: 'Она делала это действительно профессионально',
        transcription: 'ʃi dɪd ɪt ˈrɪəli prəˈfɛʃnəli',
        translation: 'She did it really professionally',
      },
      {
        id: 86,
        expression: 'Кто твой любимый певец?',
        transcription: 'huː z jə ˈfeɪvərɪt ˈsɪŋə?',
        translation: 'Who is your favorite singer?',
      },
      {
        id: 87,
        expression: 'На скольких иностранных языках он говорит?',
        transcription: 'haʊ ˈmɛni ˈfɒrɪn ˈlæŋgwɪʤɪz dəz hi spiːk?',
        translation: 'How many foreign languages does he speak?',
      },
      {
        id: 88,
        expression: 'Эта река глубже, чем та',
        transcription: 'ðɪs ˈrɪvə z ˈdiːpə ðən ðət wʌn',
        translation: 'This river is deeper than that one',
      },
      {
        id: 89,
        expression: 'Новости были такими захватывающими',
        transcription: 'ðə njuːz wəz səʊ ɪkˈsaɪtɪŋ',
        translation: 'The news was so exciting',
      },
      {
        id: 90,
        expression: 'Это очень мощная организация',
        transcription: 'ɪts ə ˈvɛri ˈpaʊəfʊl ˌɔːgənaɪˈzeɪʃən',
        translation: "It's a very powerful organisation",
      },
      {
        id: 91,
        expression: 'Мы учим как правильные, так и неправильные глаголы',
        transcription: 'wi lɜːn bəʊθ ˈrɛgjʊlər ənd ɪˈrɛgjʊlə vɜːbz',
        translation: 'We learn both regular and irregular verbs',
      },
      {
        id: 92,
        expression: 'Это мое любимое блюдо',
        transcription: 'ɪts maɪ ˈfeɪvərɪt dɪʃ',
        translation: "It's my favorite dish",
      },
      {
        id: 93,
        expression: 'То, что они делают, незаконно',
        transcription: 'wɒt ðeɪ dʊ z ɪˈliːgəl',
        translation: 'What they do is illegal',
      },
      {
        id: 94,
        expression: 'Эта река не такая глубокая, как та',
        transcription: 'ðɪs ˈrɪvər ˈɪznt əz diːp əz ðət wʌn',
        translation: "This river isn't as deep as that one",
      },
      {
        id: 95,
        expression: 'Разные вещи обсуждались вчера',
        transcription: 'ˈdɪfrənt θɪŋz wə dɪsˈkʌst ˈjɛstədeɪ',
        translation: 'Different things were discussed yesterday',
      },
      {
        id: 96,
        expression: 'Небо серое',
        transcription: 'ðə skaɪ z greɪ',
        translation: 'The sky is grey',
      },
      {
        id: 97,
        expression: 'Он более богатый человек',
        transcription: 'hiːz ə ˈrɪʧə ˈpɜːsn',
        translation: "He's a richer person",
      },
      {
        id: 98,
        expression: 'У него седые волосы',
        transcription: 'hi həz greɪ heə',
        translation: 'He has grey hair',
      },
      {
        id: 99,
        expression: 'Она маленькая девочка',
        transcription: 'ʃiːz ə ˈlɪtl gɜːl',
        translation: "She's a little girl",
      },
      {
        id: 100,
        expression: 'Какая общая сумма?',
        transcription: 'wɒts ðə ˈtəʊtl sʌm?',
        translation: "What's the total sum?",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Закрепление пройденного материала</h3>
</div>`,
    },
  ],
];
