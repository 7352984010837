export const wordsLesson260 = [
  [
    {
      id: 1,
      word: 'let in',
      transcription: 'lɛt ɪn',
      translation: 'впускать',
    },
    {
      id: 2,
      word: 'add up to',
      transcription: 'æd ʌp tuː',
      translation: 'способствовать',
    },
    {
      id: 3,
      word: 'hold out',
      transcription: 'həʊld aʊt',
      translation: 'протягивать, питать',
    },
    {
      id: 4,
      word: 'knock down',
      transcription: 'nɒk daʊn',
      translation: 'сбивать с ног',
    },
    {
      id: 5,
      word: 'turn out',
      transcription: 'tɜːn aʊt',
      translation: 'оказаться',
    },
    {
      id: 6,
      word: 'turn in',
      transcription: 'tɜːn ɪn',
      translation: 'лечь спать',
    },
    {
      id: 7,
      word: 'turn on',
      transcription: 'tɜːn ɒn',
      translation: 'включать',
    },
    {
      id: 8,
      word: 'run into',
      transcription: 'rʌn ˈɪntuː',
      translation: 'столкнуться',
    },
    {
      id: 9,
      word: 'run off',
      transcription: 'rʌn ɒf',
      translation: 'сбегать',
    },
    {
      id: 10,
      word: 'turn up',
      transcription: 'tɜːn ʌp',
      translation: 'сделать громче',
    },
    {
      id: 11,
      word: 'bankrupt',
      transcription: 'ˈbæŋkrʌpt',
      translation: 'банкрот',
    },
    {
      id: 12,
      word: 'commute',
      transcription: 'kəˈmjuːt',
      translation: 'ехать, добираться',
    },
    {
      id: 13,
      word: 'delight',
      transcription: 'dɪˈlaɪt',
      translation: 'восторг',
    },
    {
      id: 14,
      word: 'ensure',
      transcription: 'ɪnˈʃʊə',
      translation: 'убедиться',
    },
    {
      id: 15,
      word: 'freezing',
      transcription: 'ˈfriːzɪŋ',
      translation: 'холодно, ледяной',
    },
    {
      id: 16,
      word: 'opponent',
      transcription: 'əˈpəʊnənt',
      translation: 'соперник',
    },
    {
      id: 17,
      word: 'unbearable',
      transcription: 'ʌnˈbeərəbᵊl',
      translation: 'невыносимый',
    },
    {
      id: 18,
      word: 'safe',
      transcription: 'seɪf',
      translation: 'сейф, безопасный',
    },
  ],
];
