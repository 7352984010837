export const wordsLesson74 = [
  [
    {
      id: 1,
      word: 'add',
      transcription: 'æd',
      translation: 'добавлять',
    },
    {
      id: 2,
      word: 'belt',
      transcription: 'bɛlt',
      translation: 'ремень',
    },
    {
      id: 3,
      word: 'businessmen',
      transcription: 'ˈbɪznɪsmən',
      translation: 'бизнесмены',
    },
    {
      id: 4,
      word: 'clearly',
      transcription: 'ˈklɪəli',
      translation: 'ясно',
    },
    {
      id: 5,
      word: 'daily',
      transcription: 'ˈdeɪli',
      translation: 'ежедневно',
    },
    {
      id: 6,
      word: 'deliver',
      transcription: 'dɪˈlɪvə',
      translation: 'доставлять',
    },
    {
      id: 7,
      word: 'dishes',
      transcription: 'ˈdɪʃɪz',
      translation: 'посуда',
    },
    {
      id: 8,
      word: 'express',
      transcription: 'ɪksˈprɛs',
      translation: 'выражать',
    },
    {
      id: 9,
      word: 'fill in',
      transcription: 'fɪl ɪn',
      translation: 'заполнять',
    },
    {
      id: 10,
      word: 'form',
      transcription: 'fɔːm',
      translation: 'форма',
    },
    {
      id: 11,
      word: 'gap',
      transcription: 'gæp',
      translation: 'пробел, пропуск',
    },
    {
      id: 12,
      word: 'half',
      transcription: 'hɑːf',
      translation: 'половина',
    },
    {
      id: 13,
      word: 'heart',
      transcription: 'hɑːt',
      translation: 'сердце',
    },
    {
      id: 14,
      word: 'inform',
      transcription: 'ɪnˈfɔːm',
      translation: 'информировать, сообщать',
    },
    {
      id: 15,
      word: 'law',
      transcription: 'lɔː',
      translation: 'закон',
    },
    {
      id: 16,
      word: 'let',
      transcription: 'lɛt',
      translation: 'позволять',
    },
    {
      id: 17,
      word: 'miss',
      transcription: 'mɪs',
      translation: 'пропускать, скучать',
    },
    {
      id: 18,
      word: 'receive',
      transcription: 'rɪˈsiːv',
      translation: 'получать',
    },
    {
      id: 19,
      word: 'reduce',
      transcription: 'rɪˈdjuːs',
      translation: 'сокращать, уменьшать',
    },
    {
      id: 20,
      word: 'star',
      transcription: 'stɑː',
      translation: 'звезда',
    },
    {
      id: 21,
      word: 'urgent',
      transcription: 'ˈɜːʤənt',
      translation: 'срочный',
    },
  ],
];
