export const contentLesson190 = [
  [
    {
      title:
        'Урок 190. 450 Adjectives with different tenses and structures. Part 9',
    },
    [
      {
        id: 1,
        expression: 'Он замолчал на мгновение',
        transcription: 'hiː wɒz ˈsaɪlənt fɔːr ə ˈməʊmənt',
        translation: 'He was silent for a moment',
      },
      {
        id: 2,
        expression: 'Это захватывающая возможность для нас',
        transcription: 'ɪts ən ɪkˈsaɪtɪŋ ˌɒpəˈtjuːnɪti fɔːr ʌs',
        translation: "It's an exciting opportunity for us",
      },
      {
        id: 3,
        expression: 'Это живой организм',
        transcription: 'ɪts ə ˈlɪvɪŋ ˈɔːgənɪzm',
        translation: "It's a living organism",
      },
      {
        id: 4,
        expression: 'Что в этом такого смешного?',
        transcription: 'wɒt ɪz səʊ ˈfʌni əˈbaʊt ɪt?',
        translation: 'What is so funny about it?',
      },
      {
        id: 5,
        expression: 'Тебе подходят эти условия?',
        transcription: 'ɑː ðiːz kənˈdɪʃənz ˈsjuːtəbl fɔː juː?',
        translation: 'Are these conditions suitable for you?',
      },
      {
        id: 6,
        expression: 'Он очень ценный работник',
        transcription: 'hiːz ə ˈvɛri ˈvæljʊəbl ˌɛmplɔɪˈiː',
        translation: "He's a very valuable employee",
      },
      {
        id: 7,
        expression: 'Ты можешь увидеть это в южной части штата',
        transcription: 'juː kæn siː ɪt ɪn ðə ˈsʌðən pɑːt ɒv ðə steɪt',
        translation: 'You can see it in the southern part of the state',
      },
      {
        id: 8,
        expression: 'Тебе нравится желтый цвет?',
        transcription: 'duː juː laɪk ˈjɛləʊ ˈkʌlə?',
        translation: 'Do you like yellow colour?',
      },
      {
        id: 9,
        expression: 'У меня другая точка зрения на эту тему',
        transcription: 'aɪ hæv ə ˈdɪfrənt pɔɪnt ɒv vjuː ɒn ðɪs ˈtɒpɪk',
        translation: 'I have a different point of view on this topic',
      },
      {
        id: 10,
        expression: 'Это действительно необходимо сделать?',
        transcription: 'ɪz ɪt ˈrɪəli ˈnɛsɪsəri tuː duː ɪt?',
        translation: 'Is it really necessary to do it?',
      },
      {
        id: 11,
        expression: 'Это очень дикое место',
        transcription: 'ɪts ə ˈvɛri waɪld pleɪs',
        translation: "It's a very wild place",
      },
      {
        id: 12,
        expression: 'Эти условия жизни недостаточно удовлетворительные',
        transcription: 'ðiːz ˈlɪvɪŋ kənˈdɪʃənz ɑːnt ˌsætɪsˈfæktəri ɪˈnʌf',
        translation: "These living conditions aren't satisfactory enough",
      },
      {
        id: 13,
        expression: 'Ты можешь встретить диких животных в том месте',
        transcription: 'juː kæn miːt waɪld ˈænɪməlz ɪn ðæt pleɪs',
        translation: 'You can meet wild animals in that place',
      },
      {
        id: 14,
        expression: 'У этого человека очень широкие плечи',
        transcription: 'ðɪs mæn hæz ˈvɛri brɔːd ˈʃəʊldəz',
        translation: 'This man has very broad shoulders',
      },
      {
        id: 15,
        expression: 'Лучше не делать этого',
        transcription: 'ɪts ˈbɛtə nɒt tuː duː ɪt',
        translation: "It's better not to do it",
      },
      {
        id: 16,
        expression: 'Ты очень везучий человек',
        transcription: 'jʊə ˈvɛri ˈlʌki ˈpɜːsn',
        translation: "You're very lucky person",
      },
      {
        id: 17,
        expression: 'У него короткие вьющиеся волосы',
        transcription: 'hiː hæz ˈvɛri ʃɔːt ˈkɜːli heə',
        translation: 'He has very short curly hair',
      },
      {
        id: 18,
        expression: 'Они написали очень подробный отчет',
        transcription: 'ðeɪ rəʊt ə ˈvɛri ˈdiːteɪld rɪˈpɔːt',
        translation: 'They wrote a very detailed report',
      },
      {
        id: 19,
        expression: 'Это очень долгий и сложный процесс',
        transcription: 'ɪts ə ˈvɛri lɒŋ ænd ˈdɪfɪkəlt (ˈkɒmplɪkeɪtɪd) ˈprəʊsɛs',
        translation: "It's a very long and difficult (complicated) process",
      },
      {
        id: 20,
        expression: 'Страдают невинные люди',
        transcription: 'ˈɪnəsənt ˈpiːpl ˈsʌfə',
        translation: 'Innocent people suffer',
      },
      {
        id: 21,
        expression: 'Диван был таким неудобным',
        transcription: 'ðə ˈsəʊfə wɒz səʊ ʌnˈkʌmfətəbl',
        translation: 'The sofa was so uncomfortable',
      },
      {
        id: 22,
        expression: 'У нее короткие волнистые волосы',
        transcription: 'ʃiː hæz ʃɔːt ˈweɪvi heə',
        translation: 'She has short wavy hair',
      },
      {
        id: 23,
        expression: 'Он нарисовал прямую линию',
        transcription: 'hiː druː ə dɪˈrɛkt laɪn',
        translation: 'He drew a direct line',
      },
      {
        id: 24,
        expression: 'Это прямой рейс?',
        transcription: 'ɪz ɪt dɪˈrɛkt flaɪt?',
        translation: 'Is it direct flight?',
      },
      {
        id: 25,
        expression: 'На улицах было тихо',
        transcription: 'ðə striːts wɜː ˈsaɪlənt',
        translation: 'The streets were silent',
      },
      {
        id: 26,
        expression: 'Это гораздо более легкий тест',
        transcription: 'ɪts ə mʌʧ ˈiːzɪə tɛst',
        translation: "It's a much easier test",
      },
      {
        id: 27,
        expression: 'Эта задача не такая легкая, как та',
        transcription: 'ðɪs tɑːsk ˈɪznt æz ˈiːzi æz ðæt wʌn',
        translation: "This task isn't as easy as that one",
      },
      {
        id: 28,
        expression: 'Мне не нравится громкая музыка',
        transcription: 'aɪ dəʊnt laɪk laʊd ˈmjuːzɪk',
        translation: "I don't like loud music",
      },
      {
        id: 29,
        expression: 'Это был особый случай',
        transcription: 'ɪt wɒz pəˈtɪkjʊlə keɪs',
        translation: 'It was particular case',
      },
      {
        id: 30,
        expression: 'Он работает персональным тренером',
        transcription: 'hiː wɜːks æz ə ˈpɜːsnl ˈtreɪnə',
        translation: 'He works as a personal trainer',
      },
      {
        id: 31,
        expression: 'Еда содержит только натуральные ингредиенты',
        transcription: 'ðə fuːd kənˈteɪnz ˈəʊnli ˈnæʧrəl ɪnˈgriːdiənts',
        translation: 'The food contains only natural ingredients',
      },
      {
        id: 32,
        expression: 'Это такая романтическая песня',
        transcription: 'ɪts sʌʧ ə rəʊˈmæntɪk sɒŋ',
        translation: "It's such a romantic song",
      },
      {
        id: 33,
        expression: 'Это расположено в южной части города',
        transcription: 'ɪts ləʊˈkeɪtɪd ɪn ðə ˈsʌðən pɑːt ɒv ðə ˈsɪti',
        translation: "It's located in the southern part of the city",
      },
      {
        id: 34,
        expression: 'Он чувствовал себя действительно плохо вчера',
        transcription: 'hiː fɛlt ˈrɪəli bæd ˈjɛstədeɪ',
        translation: 'He felt really bad yesterday',
      },
      {
        id: 35,
        expression: 'Он больше доверяет традиционной медицине',
        transcription: 'hiː trʌst trəˈdɪʃənl ˈmɛdsɪn mɔː',
        translation: 'He trust traditional medicine more',
      },
      {
        id: 36,
        expression: 'Это решение лучше?',
        transcription: 'ɪz ɪt ə ˈbɛtə dɪˈsɪʒən?',
        translation: 'Is it a better decision?',
      },
      {
        id: 37,
        expression: 'Она чувствует себя там так некомфортно',
        transcription: 'ʃiː fiːlz səʊ ʌnˈkʌmfətəbl ðeə',
        translation: 'She feels so uncomfortable there',
      },
      {
        id: 38,
        expression: 'Это моя личная точка зрения',
        transcription: 'ɪts maɪ ˈpɜːsnl pɔɪnt ɒv vjuː',
        translation: "It's my personal point of view",
      },
      {
        id: 39,
        expression: 'Тебе подходит эта дата?',
        transcription: 'ɪz ðɪs ˈdeɪtə ˈsjuːtəbl fɔː juː?',
        translation: 'Is this data suitable for you?',
      },
      {
        id: 40,
        expression: 'Новости такие захватывающие',
        transcription: 'ðə njuːz ɪz səʊ ɪkˈsaɪtɪŋ',
        translation: 'The news is so exciting',
      },
      {
        id: 41,
        expression: 'Я знаю, что смогу решить все эти проблемы довольно легко',
        transcription:
          'aɪ nəʊ ðæt aɪl biː ˈeɪbl tuː sɒlv ɔːl ðiːz ˈprɒbləmz ˈprɪti ˈiːzɪli',
        translation:
          "I know that I'll be able to solve all these problems pretty easily",
      },
      {
        id: 42,
        expression: 'Ты звучишь очень естественно, когда говоришь по-английски',
        transcription: 'juː saʊnd ˈvɛri ˈnæʧrəl wɛn juː spiːk ˈɪŋglɪʃ',
        translation: 'You sound very natural when you speak English',
      },
      {
        id: 43,
        expression: 'Эта информация используется в образовательных целях',
        transcription:
          'ðɪs ˌɪnfəˈmeɪʃən ɪz juːzd fɔːr ˌɛdju(ː)ˈkeɪʃənl ˈpɜːpəsɪz',
        translation: 'This information is used for educational purposes',
      },
      {
        id: 44,
        expression: 'Я должен сказать, что это ошибочное мнение',
        transcription: 'aɪ hæv tuː seɪ ðæt ɪts ə mɪsˈteɪkən əˈpɪnjən',
        translation: "I have to say that it's a mistaken opinion",
      },
      {
        id: 45,
        expression: 'Я получил очень ценный опыт, работая там',
        transcription: 'aɪ gɒt ˈvɛri ˈvæljʊəbl ɪksˈpɪərɪəns ˈwɜːkɪŋ ðeə',
        translation: 'I got very valuable experience working there',
      },
      {
        id: 46,
        expression:
          'Я думаю, это стоит около двух тысяч долларов, если я не ошибаюсь',
        transcription:
          'aɪ θɪŋk ɪt kɒsts əˈbaʊt tuː ˈθaʊzənd ˈdɒləz ɪf aɪm nɒt mɪsˈteɪkən',
        translation:
          "I think it costs about two thousand dollars if I'm not mistaken",
      },
      {
        id: 47,
        expression: 'Мы получили очень подробный ответ на этот вопрос',
        transcription: 'wiː gɒt ə ˈvɛri ˈdiːteɪld ˈɑːnsə tuː ðɪs ˈkwɛsʧən',
        translation: 'We got a very detailed answer to this question',
      },
      {
        id: 48,
        expression: 'Он отказался сделать это без особой причины',
        transcription: 'hiː ˌriːˈfjuːzd tuː duː ɪt fɔː nəʊ pəˈtɪkjʊlə ˈriːzn',
        translation: 'He refused to do it for no particular reason',
      },
      {
        id: 49,
        expression: 'Я не думаю, что это решение действительно несправедливо',
        transcription: 'aɪ dəʊnt θɪŋk ðæt ðɪs dɪˈsɪʒən ɪz ˈrɪəli ʌnˈfeə',
        translation: "I don't think that this decision is really unfair",
      },
      {
        id: 50,
        expression: 'Компания продает широкий ассортимент продукции',
        transcription: 'ðə ˈkʌmpəni sɛlz ə brɔːd reɪnʤ ɒv ˈprɒdʌkts',
        translation: 'The company sells a broad range of products',
      },
      {
        id: 51,
        expression: 'Какой был средний балл?',
        transcription: 'wɒt wɒz ði ˈævərɪʤ greɪd (skɔː, ˈrɪˈmɑːk)?',
        translation: 'What was the average grade (score, remark)?',
      },
      {
        id: 52,
        expression: 'Это самая забавная вещь, которую я когда-либо слышал',
        transcription: 'ɪts ðə ˈfʌnɪɪst θɪŋ aɪv ˈɛvə hɜːd',
        translation: "It's the funniest thing I've ever heard",
      },
      {
        id: 53,
        expression:
          'Он в основном предпочитает смотреть образовательные программы',
        transcription:
          'hiː ˈməʊstli priˈfɜːz tuː wɒʧ ˌɛdju(ː)ˈkeɪʃənl ˈprəʊgræmz',
        translation: 'He mostly prefers to watch educational programs',
      },
      {
        id: 54,
        expression: 'Это традиционный подход к этой проблеме',
        transcription: 'ɪts ə trəˈdɪʃənl əˈprəʊʧ tuː ðɪs ˈprɒbləm',
        translation: "It's a traditional approach to this problem",
      },
      {
        id: 55,
        expression: 'В среднем, результат удовлетворительный',
        transcription: 'ɒn ˈævərɪʤ, ðə rɪˈzʌlt ɪz ˌsætɪsˈfæktəri',
        translation: 'On average, the result is satisfactory',
      },
      {
        id: 56,
        expression: 'На кого он злится?',
        transcription: 'huː ɪz hiː ˈæŋgri wɪð?',
        translation: 'Who is he angry with?',
      },
      {
        id: 57,
        expression: 'Из-за чего она злится?',
        transcription: 'wɒt ɪz ʃiː ˈæŋgri əˈbaʊt?',
        translation: 'What is she angry about?',
      },
      {
        id: 58,
        expression: 'Почему он так зол?',
        transcription: 'waɪ ɪz hiː səʊ ˈæŋgri?',
        translation: 'Why is he so angry?',
      },
      {
        id: 59,
        expression: 'Он желает нам помочь?',
        transcription: 'ɪz hiː ˈwɪlɪŋ tuː hɛlp ʌs?',
        translation: 'Is he willing to help us?',
      },
      {
        id: 60,
        expression: 'Она хочет нам помочь?',
        transcription: 'dʌz ʃiː wɒnt tuː hɛlp ʌs?',
        translation: 'Does she want to help us?',
      },
      {
        id: 61,
        expression: 'Он желает это сделать?',
        transcription: 'ɪz hiː ˈwɪlɪŋ tuː duː ɪt?',
        translation: 'Is he willing to do it?',
      },
      {
        id: 62,
        expression: 'Я думаю, в этом нет ничего плохого',
        transcription: 'aɪ θɪŋk ðeər ɪz ˈnʌθɪŋ bæd əˈbaʊt ɪt',
        translation: 'I think there is nothing bad about it',
      },
      {
        id: 63,
        expression: 'Мне кажется, в этом есть что-то плохое',
        transcription: 'ɪt siːmz tuː miː ðeər ɪz ˈsʌmθɪŋ bæd əˈbaʊt ɪt',
        translation: 'It seems to me there is something bad about it',
      },
      {
        id: 64,
        expression: 'Она говорит, в этом не было ничего плохого',
        transcription: 'ʃiː sɛz ðeə wɒz ˈnʌθɪŋ bæd əˈbaʊt ɪt',
        translation: 'She says there was nothing bad about it',
      },
      {
        id: 65,
        expression: 'Многие люди мечтают иметь пассивный доход',
        transcription: 'ˈmɛni ˈpiːpl driːm əˈbaʊt ˈhævɪŋ ə ˈpæsɪv ˈɪnkʌm',
        translation: 'Many people dream about having a passive income',
      },
      {
        id: 66,
        expression: 'Большинство людей мечтают иметь стабильный доход',
        transcription: 'məʊst ˈpiːpl driːm əˈbaʊt ˈhævɪŋ ə ˈsteɪbl ˈɪnkʌm',
        translation: 'Most people dream about having a stable income',
      },
      {
        id: 67,
        expression: 'Многие люди мечтают иметь пассивный доход',
        transcription: 'ˈmɛni ˈpiːpl driːm əˈbaʊt ˈhævɪŋ ə ˈpæsɪv ˈɪnkʌm',
        translation: 'Many people dream about having a passive income',
      },
      {
        id: 68,
        expression: 'Она услышала громкий шум на улице',
        transcription: 'ʃiː hɜːd ə laʊd nɔɪz ɪn ðə striːt',
        translation: 'She heard a loud noise in the street',
      },
      {
        id: 69,
        expression: 'Она услышала громкий шум за окном',
        transcription: 'ʃiː hɜːd ə laʊd nɔɪz ˌaʊtˈsaɪd ðə ˈwɪndəʊ',
        translation: 'She heard a loud noise outside the window',
      },
      {
        id: 70,
        expression: 'Она услышала что-то странное на улице',
        transcription: 'ʃiː hɜːd ˈsʌmθɪŋ streɪnʤ ɪn ðə striːt',
        translation: 'She heard something strange in the street',
      },
      {
        id: 71,
        expression: 'Компания не смогла оплатить свои счета',
        transcription: 'ðə ˈkʌmpəni wɒz ʌnˈeɪbl tuː peɪ ɪts bɪlz',
        translation: 'The company was unable to pay its bills',
      },
      {
        id: 72,
        expression: 'Компания может оплачивать свои счета',
        transcription: 'ðə ˈkʌmpəni ɪz ˈeɪbl tuː peɪ ɪts bɪlz',
        translation: 'The company is able to pay its bills',
      },
      {
        id: 73,
        expression: 'Может ли эта компания оплачивать свои счета?',
        transcription: 'ɪf ðɪs ˈkʌmpəni ˈeɪbl tuː peɪ ɪts bɪlz?',
        translation: 'If this company able to pay its bills?',
      },
      {
        id: 74,
        expression: 'Она желает, чтобы он был более романтичным',
        transcription: 'ʃiː ˈwɪʃɪz hiː wɜː mɔː rəʊˈmæntɪk',
        translation: 'She wishes he were more romantic',
      },
      {
        id: 75,
        expression: 'Она попросила его быть более романтичным',
        transcription: 'ʃiː ɑːskt hɪm tuː biː mɔː rəʊˈmæntɪk',
        translation: 'She asked him to be more romantic',
      },
      {
        id: 76,
        expression: 'Я желаю, чтобы он был повежливее',
        transcription: 'aɪ wɪʃ hiː wɜː mɔː pəˈlaɪt',
        translation: 'I wish he were more polite',
      },
      {
        id: 77,
        expression: 'У него совершенно другое мнение',
        transcription: 'hiː hæz ə kəmˈpliːtli ˈdɪfrənt əˈpɪnjən',
        translation: 'He has a completely different opinion',
      },
      {
        id: 78,
        expression: 'У него такое же мнение',
        transcription: 'hiː hæz ðə seɪm əˈpɪnjən',
        translation: 'He has the same opinion',
      },
      {
        id: 79,
        expression: 'У него совершенно другая точка зрения',
        transcription: 'hiː hæz ə kəmˈpliːtli ˈdɪfrənt pɔɪnt ɒv vjuː',
        translation: 'He has a completely different point of view',
      },
      {
        id: 80,
        expression: 'Его часы менее дорогие, чем ее',
        transcription: 'hɪz wɒʧ ɪz lɛs ɪksˈpɛnsɪv ðæn hɜːz',
        translation: 'His watch is less expensive than hers',
      },
      {
        id: 81,
        expression: 'Его часы дешевле, чем ее',
        transcription: 'hɪz wɒʧ ɪz ˈʧiːpə ðæn hɜːz',
        translation: 'His watch is cheaper than hers',
      },
      {
        id: 82,
        expression: 'Его машина дороже, чем ее',
        transcription: 'hɪz kɑːr ɪz mɔːr ɪksˈpɛnsɪv ðæn hɜːz',
        translation: 'His car is more expensive than hers',
      },
      {
        id: 83,
        expression: 'В целом, этот проект успешный',
        transcription: 'ˈəʊvərɔːl, ðɪs ˈprɒʤɛkt ɪz səkˈsɛsfʊl',
        translation: 'Overall, this project is successful',
      },
      {
        id: 84,
        expression: 'В целом, этот фильм интересный',
        transcription: 'ˈəʊvərɔːl, ðɪs ˈmuːvi ɪz ˈɪntrɪstɪŋ',
        translation: 'Overall, this movie is interesting',
      },
      {
        id: 85,
        expression: 'В целом, эта книга очень скучная',
        transcription: 'ˈəʊvərɔːl, ðɪs bʊk ɪz ˈvɛri ˈbɔːrɪŋ',
        translation: 'Overall, this book is very boring',
      },
      {
        id: 86,
        expression: 'Он невероятно везучий с точки зрения денег',
        transcription: 'hiːz ɪnˈkrɛdəbli ˈlʌki ɪn tɜːmz ɒv ˈmʌni',
        translation: "He's incredibly lucky in terms of money",
      },
      {
        id: 87,
        expression: 'Я думаю, это более хороший выход из этой ситуации',
        transcription: 'aɪ θɪŋk ɪts ə ˈbɛtə weɪ aʊt ɒv ðɪs ˌsɪtjʊˈeɪʃən',
        translation: "I think it's a better way out of this situation",
      },
      {
        id: 88,
        expression: 'В целом, он очень успешный бизнесмен',
        transcription: 'ˈəʊvərɔːl, hiːz ə ˈvɛri səkˈsɛsfʊl ˈbɪznɪsmən',
        translation: "Overall, he's a very successful businessman",
      },
      {
        id: 89,
        expression: 'Я сделал все необходимые приготовления',
        transcription: 'aɪ meɪd ɔːl ðə ˈnɛsɪsəri əˈreɪnʤmənts',
        translation: 'I made all the necessary arrangements',
      },
      {
        id: 90,
        expression:
          'Это кажется несправедливым, но они ничего не могут с этим сделать',
        transcription: 'ɪt siːmz ʌnˈfeə bʌt ðeɪ kæn duː ˈnʌθɪŋ əˈbaʊt ɪt',
        translation: 'It seems unfair but they can do nothing about it',
      },
      {
        id: 91,
        expression: 'Эта вещь не такая дорогая, как та',
        transcription: 'ðɪs θɪŋ ˈɪznt æz ɪksˈpɛnsɪv æz ðæt wʌn',
        translation: "This thing isn't as expensive as that one",
      },
      {
        id: 92,
        expression: 'Они были убеждены, что она была невиновна',
        transcription: 'ðeɪ wɜː kənˈvɪnst ðæt ʃiː wɒz ˈɪnəsənt',
        translation: 'They were convinced that she was innocent',
      },
      {
        id: 93,
        expression: 'Они увидели желтый забор перед ними',
        transcription: 'ðeɪ sɔː ə ˈjɛləʊ fɛns ɪn frʌnt ɒv ðɛm',
        translation: 'They saw a yellow fence in front of them',
      },
      {
        id: 94,
        expression: 'Власти ничего не могут изменить в данный момент',
        transcription:
          'ði ɔːˈθɒrɪtiz ɑːr ʌnˈeɪbl tuː ʧeɪnʤ ˈɛnɪθɪŋ æt ðə ˈməʊmənt',
        translation:
          'The authorities are unable to change anything at the moment',
      },
      {
        id: 95,
        expression: 'Это объяснение кажется совершенно разумным',
        transcription: 'ðɪs ˌɛkspləˈneɪʃən siːmz ˈpɜːfɪktli ˈriːznəbl',
        translation: 'This explanation seems perfectly reasonable',
      },
      {
        id: 96,
        expression: 'Его мечта - иметь небольшой пассивный доход',
        transcription: 'hɪz driːm ɪz tuː hæv ə smɔːl ˈpæsɪv ˈɪnkʌm',
        translation: 'His dream is to have a small passive income',
      },
      {
        id: 97,
        expression:
          'Я действительно не думаю, что этот парень способен сделать это',
        transcription: 'aɪ dɒn`tiː ˈrɪəli θɪŋk ðæt ðɪs gaɪ ɪz ˈeɪbl tuː duː ɪt',
        translation: "I don't really think that this guy is able to do it",
      },
      {
        id: 98,
        expression: 'Я не думаю, что эта цена действительно разумная',
        transcription: 'aɪ dəʊnt θɪŋk ðæt ðɪs praɪs ɪz ˈrɪəli ˈriːznəbl',
        translation: "I don't think that this price is really reasonable",
      },
      {
        id: 99,
        expression: 'Я хочу сделать исключение для тебя',
        transcription: 'aɪm ˈwɪlɪŋ tuː meɪk ən ɪkˈsɛpʃən fɔː juː',
        translation: "I'm willing to make an exception for you",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Закрепление пройденного материала</h3>
</div>`,
    },
  ],
];
