export const contentLesson139 = [
  [
    {
      title: 'Урок 139. 230 Adjectives. Part 1',
    },
    [
      {
        id: 1,
        expression: 'Она такая милая девушка',
        transcription: 'ʃiːz sʌʧ ə ˈprɪti gɜːl',
        translation: "She's such a pretty girl",
      },
      {
        id: 2,
        expression: 'Она живет в старом доме',
        transcription: 'ʃi lɪvz ɪn ən əʊld haʊs',
        translation: 'She lives in an old house',
      },
      {
        id: 3,
        expression: 'Женщина беременна',
        transcription: 'ðə ˈwʊmən z ˈprɛgnənt',
        translation: 'The woman is pregnant',
      },
      {
        id: 4,
        expression: 'Он такой известный музыкант',
        transcription: 'hiːz sʌʧ ə ˈfeɪməs mju(ː)ˈzɪʃən',
        translation: "He's such a famous musician",
      },
      {
        id: 5,
        expression: 'Она беременна?',
        transcription: 'ɪz ʃi ˈprɛgnənt?',
        translation: 'Is she pregnant?',
      },
      {
        id: 6,
        expression:
          'Нам также нужно рассматривать различные экономические факторы',
        transcription:
          'wi ˈɔːlsəʊ niːd tə kənˈsɪdə ˈdɪfrənt ˌiːkəˈnɒmɪk ˈfæktəz',
        translation: 'We also need to consider different economic factors',
      },
      {
        id: 7,
        expression: 'Вы такие умные люди',
        transcription: 'jʊə sʌʧ smɑːt ˈpiːpl',
        translation: "You're such smart people",
      },
      {
        id: 8,
        expression: 'Он может бежать очень быстро',
        transcription: 'hi kən rʌn ˈvɛri fɑːst',
        translation: 'He can run very fast',
      },
      {
        id: 9,
        expression: 'Этот дом такой старый',
        transcription: 'ðɪs haʊs ɪz səʊ əʊld',
        translation: 'This house is so old',
      },
      {
        id: 10,
        expression: 'Я также хочу посетить другие страны и города',
        transcription: 'aɪ ˈɔːlsəʊ wɒnt tə ˈvɪzɪt ˈʌðə ˈkʌntriz ənd ˈsɪtiz',
        translation: 'I also want to visit other countries and cities',
      },
      {
        id: 11,
        expression: 'Он выиграл золотую медаль',
        transcription: 'hi wʌn ə gəʊld ˈmɛdl',
        translation: 'He won a gold medal',
      },
      {
        id: 12,
        expression: 'Они такие добрые люди',
        transcription: 'ðeə sʌʧ kaɪnd ˈpiːpl',
        translation: "They're such kind people",
      },
      {
        id: 13,
        expression: 'Это такой старый дом',
        transcription: 'ɪts sʌʧ ən əʊld haʊs',
        translation: "It's such an old house",
      },
      {
        id: 14,
        expression: 'Они такие успешные бизнесмены',
        transcription: 'ðeə sʌʧ səkˈsɛsfʊl ˈbɪznɪsmən',
        translation: "They're such successful businessmen",
      },
      {
        id: 15,
        expression: 'Он живет в другом месте',
        transcription: 'hi lɪvz ɪn əˈnʌðə pleɪs',
        translation: 'He lives in another place',
      },
      {
        id: 16,
        expression: 'Она больна сейчас',
        transcription: 'ʃiːz ɪl naʊ',
        translation: "She's ill now",
      },
      {
        id: 17,
        expression: 'Мне так жаль',
        transcription: 'aɪm səʊ ˈsɒri',
        translation: "I'm so sorry",
      },
      {
        id: 18,
        expression: 'Забор желтый',
        transcription: 'ðə fɛns ɪz ˈjɛləʊ',
        translation: 'The fence is yellow',
      },
      {
        id: 19,
        expression: 'Она слушает ежедневные новости',
        transcription: 'ʃi ˈlɪsnz tə ˈdeɪli njuːz',
        translation: 'She listens to daily news',
      },
      {
        id: 20,
        expression: 'Та улица намного шире',
        transcription: 'ðæt striːt s mʌʧ ˈwaɪdə',
        translation: 'That street is much wider',
      },
      {
        id: 21,
        expression: 'Это такая интересная идея',
        transcription: 'ɪts sʌʧ ən ˈɪntrɪstɪŋ aɪˈdɪə',
        translation: "It's such an interesting idea",
      },
      {
        id: 22,
        expression: 'У нее очень милое лицо',
        transcription: 'ʃi həz ə ˈvɛri ˈprɪti feɪs',
        translation: 'She has a very pretty face',
      },
      {
        id: 23,
        expression: 'Он очень успешный бизнесмен',
        transcription: 'hiːz ə ˈvɛri səkˈsɛsfʊl ˈbɪznɪsmən',
        translation: "He's a very successful businessman",
      },
      {
        id: 24,
        expression: 'Он больной человек',
        transcription: 'hiːz ə ɪl ˈpɜːsn',
        translation: "He's a ill person",
      },
      {
        id: 25,
        expression: 'Это стандартная процедура',
        transcription: 'ɪts ə ˈstændəd prəˈsiːʤə',
        translation: "It's a standard procedure",
      },
      {
        id: 26,
        expression: 'Он дал мне прямой ответ',
        transcription: 'hi geɪv mi ə dɪˈrɛkt ˈɑːnsə',
        translation: 'He gave me a direct answer',
      },
      {
        id: 27,
        expression: 'Я действительно сожалел об этом',
        transcription: 'aɪ wəz ˈrɪəli ˈsɒri əˈbaʊt ɪt',
        translation: 'I was really sorry about it',
      },
      {
        id: 28,
        expression: 'Это моя личная точка зрения',
        transcription: 'ɪts maɪ ˈpɜːsnl pɔɪnt əv vjuː',
        translation: "It's my personal point of view",
      },
      {
        id: 29,
        expression: 'Он очень быстрый бегун',
        transcription: 'hiːz ə ˈvɛri fɑːst ˈrʌnə',
        translation: "He's a very fast runner",
      },
      {
        id: 30,
        expression: 'Это ежедневная газета',
        transcription: 'ɪts ə ˈdeɪli ˈnjuːzˌpeɪpə',
        translation: "It's a daily newspaper",
      },
      {
        id: 31,
        expression: 'Это интересная теория',
        transcription: 'ɪts ən ˈɪntrɪstɪŋ ˈθɪəri',
        translation: "It's an interesting theory",
      },
      {
        id: 32,
        expression: 'Он очень религиозный человек',
        transcription: 'hiːz ə ˈvɛri rɪˈlɪʤəs ˈpɜːsn',
        translation: "He's a very religious person",
      },
      {
        id: 33,
        expression: 'Город известен своей историей',
        transcription: 'ðə ˈsɪti z ˈfeɪməs fər ɪts ˈhɪstəri',
        translation: 'The city is famous for its history',
      },
      {
        id: 34,
        expression: 'Я оставил свой мобильный телефон дома',
        transcription: 'aɪ lɛft maɪ sɛl ət həʊm',
        translation: 'I left my cell at home',
      },
      {
        id: 35,
        expression: 'Она купила новое красное платье',
        transcription: 'ʃi bɔːt ə njuː rɛd drɛs',
        translation: 'She bought a new red dress',
      },
      {
        id: 36,
        expression: 'Ты так добр ко мне',
        transcription: 'jʊə səʊ kaɪnd tə miː',
        translation: "You're so kind to me",
      },
      {
        id: 37,
        expression: 'Она очень позитивный человек',
        transcription: 'ʃiːz ə ˈvɛri ˈpɒzətɪv ˈpɜːsn',
        translation: "She's a very positive person",
      },
      {
        id: 38,
        expression: 'Он выиграл серебряную медаль',
        transcription: 'hi wʌn ə ˈsɪlvə ˈmɛdl',
        translation: 'He won a silver medal',
      },
      {
        id: 39,
        expression: 'Карандаш коричневый',
        transcription: 'ðə ˈpɛnsl z braʊn',
        translation: 'The pencil is brown',
      },
      {
        id: 40,
        expression: 'Это такой скучный фильм',
        transcription: 'ɪts sʌʧ ə ˈbɔːrɪŋ ˈmuːvi',
        translation: "It's such a boring movie",
      },
      {
        id: 41,
        expression: 'Я не вполне уверен, что я выбрал правильный ответ',
        transcription: 'aɪm nɒt kwaɪt ʃʊə ðət aɪ ʧəʊz ðə raɪt ˈɑːnsə',
        translation: "I'm not quite sure that I chose the right answer",
      },
      {
        id: 42,
        expression: 'Это гораздо более серьезная проблема',
        transcription: 'ɪts ə mʌʧ mɔː ˈsɪərɪəs ˈprɒbləm',
        translation: "It's a much more serious problem",
      },
      {
        id: 43,
        expression: 'Как хорошо вы знаете друг друга?',
        transcription: 'haʊ wɛl dʊ jʊ nəʊ iːʧ ˈʌðə?',
        translation: 'How well do you know each other?',
      },
      {
        id: 44,
        expression: 'У него такие выдающиеся академические достижения',
        transcription: 'hi həz sʌʧ aʊtˈstændɪŋ ˌækəˈdɛmɪk əˈʧiːvmənts',
        translation: 'He has such outstanding academic achievements',
      },
      {
        id: 45,
        expression: 'Его академические достижения действительно выдающиеся',
        transcription: 'hɪz ˌækəˈdɛmɪk əˈʧiːvmənts ə ˈrɪəli aʊtˈstændɪŋ',
        translation: 'His academic achievements are really outstanding',
      },
      {
        id: 46,
        expression: 'Он чувствует себя намного увереннее',
        transcription: 'hi fiːlz mʌʧ mɔː ˈkɒnfɪdənt',
        translation: 'He feels much more confident',
      },
      {
        id: 47,
        expression: 'У него очень приятная улыбка',
        transcription: 'hi həz ə ˈvɛri ˈplɛznt smaɪl',
        translation: 'He has a very pleasant smile',
      },
      {
        id: 48,
        expression: 'Это самое старое здание в деревне',
        transcription: 'ɪts ði ˈəʊldɪst ˈbɪldɪŋ ɪn ðə ˈvɪlɪʤ',
        translation: "It's the oldest building in the village",
      },
      {
        id: 49,
        expression: 'Я думаю, это естественная реакция',
        transcription: 'aɪ θɪŋk ɪts ə ˈnæʧrəl ri(ː)ˈækʃən',
        translation: "I think it's a natural reaction",
      },
      {
        id: 50,
        expression: 'Это такая древняя крепость',
        transcription: 'ɪts sʌʧ ən ˈeɪnʃ(ə)nt ˈfɔːtrɪs',
        translation: "It's such an ancient fortress",
      },
      {
        id: 51,
        expression: 'Он такой успешный бизнесмен',
        transcription: 'hiːz sʌʧ ə səkˈsɛsfʊl ˈbɪznɪsmən',
        translation: "He's such a successful businessman",
      },
      {
        id: 52,
        expression: 'Это более серьезная проблема',
        transcription: 'ɪts ə mɔː ˈsɪərɪəs ˈprɒbləm',
        translation: "It's a more serious problem",
      },
      {
        id: 53,
        expression: 'Это огромный успех',
        transcription: 'ɪts ə hjuːʤ səkˈsɛs',
        translation: "It's a huge success",
      },
      {
        id: 54,
        expression: 'Это очень серьезная проблема',
        transcription: 'ɪts ə ˈvɛri ˈsɪərɪəs ˈprɒbləm',
        translation: "It's a very serious problem",
      },
      {
        id: 55,
        expression: 'Это более скучная статья',
        transcription: 'ɪts ə mɔː ˈbɔːrɪŋ ˈɑːtɪkl',
        translation: "It's a more boring article",
      },
      {
        id: 56,
        expression: 'Он один из самых умных людей в мире',
        transcription: 'hiːz wʌn əv ðə ˈsmɑːtɪst ˈpiːpl ɪn ðə wɜːld',
        translation: "He's one of the smartest people in the world",
      },
      {
        id: 57,
        expression: 'Он один из самых умных людей, которых я когда-либо знал',
        transcription: 'hiːz wʌn əv ðə ˈsmɑːtɪst ˈpiːpl wɪʧ aɪv ˈɛvə nəʊn',
        translation: "He's one of the smartest people which I've ever known",
      },
      {
        id: 58,
        expression: 'Он один из самых талантливых людей в мире',
        transcription: 'hiːz wʌn əv ðə məʊst ˈtæləntɪd ˈpiːpl ɪn ðə wɜːld',
        translation: "He's one of the most talented people in the world",
      },
      {
        id: 59,
        expression: 'Это очень серьезная социальная проблема',
        transcription: 'ɪts ə ˈvɛri ˈsɪərɪəs ˈsəʊʃəl ˈprɒbləm',
        translation: "It's a very serious social problem",
      },
      {
        id: 60,
        expression: 'Это очень важное решение',
        transcription: 'ɪts ə ˈvɛri ɪmˈpɔːtənt dɪˈsɪʒən',
        translation: "It's a very important decision",
      },
      {
        id: 61,
        expression: 'Это действительно серьезная проблема в наши дни',
        transcription: 'ɪts ə ˈrɪəli ˈsɪərɪəs ˈprɒbləm ˈnaʊədeɪz',
        translation: "It's a really serious problem nowadays",
      },
      {
        id: 62,
        expression: 'Он такой везучий человек',
        transcription: 'hiːz sʌʧ ə ˈlʌki ˈpɜːsn',
        translation: "He's such a lucky person",
      },
      {
        id: 63,
        expression: 'Он такой везучий',
        transcription: 'hiːz səʊ ˈlʌki',
        translation: "He's so lucky",
      },
      {
        id: 64,
        expression: 'Он такой хороший человек',
        transcription: 'hiːz sʌʧ ə gʊd ˈpɜːsn',
        translation: "He's such a good person",
      },
      {
        id: 65,
        expression: 'Каждый из этих вопросов заслуживает нашего внимания',
        transcription: 'iːʧ əv ðiːz ˈkwɛsʧənz dɪˈzɜːvz ˈaʊər əˈtɛnʃ(ə)n',
        translation: 'Each of these questions deserves our attention',
      },
      {
        id: 66,
        expression: 'Все эти вопросы заслуживают твоего внимания',
        transcription: 'ɔːl ðiːz ˈkwɛsʧənz dɪˈzɜːv jər əˈtɛnʃ(ə)n',
        translation: 'All these questions deserve your attention',
      },
      {
        id: 67,
        expression: 'Каждая из этих проблем заслуживала нашего внимания',
        transcription: 'iːʧ əv ðiːz ˈprɒbləmz dɪˈzɜːvd ˈaʊər əˈtɛnʃ(ə)n',
        translation: 'Each of these problems deserved our attention',
      },
      {
        id: 68,
        expression: 'Это такое приятное чувство',
        transcription: 'ɪts sʌʧ ə ˈplɛznt ˈfiːlɪŋ',
        translation: "It's such a pleasant feeling",
      },
      {
        id: 69,
        expression: 'Он такой популярный актер',
        transcription: 'hiːz sʌʧ ə ˈpɒpjʊlər ˈæktə',
        translation: "He's such a popular actor",
      },
      {
        id: 70,
        expression: 'Это такое необычное чувство',
        transcription: 'ɪts sʌʧ ən ʌnˈjuːʒʊəl ˈfiːlɪŋ',
        translation: "It's such an unusual feeling",
      },
      {
        id: 71,
        expression: 'Это такой древний город',
        transcription: 'ɪts sʌʧ ən ˈeɪnʃ(ə)nt taʊn',
        translation: "It's such an ancient town",
      },
      {
        id: 72,
        expression: 'Этот город такой древний',
        transcription: 'ðɪs taʊn z səʊ ˈeɪnʃ(ə)nt',
        translation: 'This town is so ancient',
      },
      {
        id: 73,
        expression: 'Это такой старый город',
        transcription: 'ɪts sʌʧ ən əʊld taʊn',
        translation: "It's such an old town",
      },
      {
        id: 74,
        expression: 'Я уверен, что это правильное решение',
        transcription: 'aɪm ʃʊə ðət ɪts ðə raɪt dɪˈsɪʒən',
        translation: "I'm sure that it's the right decision",
      },
      {
        id: 75,
        expression: 'Я уверен, что это твое лучшее решение',
        transcription: 'aɪm ʃʊə ðət ɪts jə bɛst dɪˈsɪʒən',
        translation: "I'm sure that it's your best decision",
      },
      {
        id: 76,
        expression: 'Я не уверен, что это правильный ответ',
        transcription: 'aɪm nɒt ʃʊə ðət ɪts ðə raɪt ˈɑːnsə',
        translation: "I'm not sure that it's the right answer",
      },
      {
        id: 77,
        expression: 'Она более известная актриса',
        transcription: 'ʃiːz ə mɔː ˈfeɪməs ˈæktrɪs',
        translation: "She's a more famous actress",
      },
      {
        id: 78,
        expression: 'Она очень популярная актриса',
        transcription: 'ʃiːz ə ˈvɛri ˈpɒpjʊlər ˈæktrɪs',
        translation: "She's a very popular actress",
      },
      {
        id: 79,
        expression: 'Она самая известная актриса в мире',
        transcription: 'ʃiːz ðə məʊst ˈfeɪməs ˈæktrɪs ɪn ðə wɜːld',
        translation: "She's the most famous actress in the world",
      },
      {
        id: 80,
        expression: 'Он купил кожаный чехол',
        transcription: 'hi bɔːt ə ˈlɛðə keɪs',
        translation: 'He bought a leather case',
      },
      {
        id: 81,
        expression: 'Он купил маленькую красную машину',
        transcription: 'hi bɔːt ə smɔːl rɛd kɑː',
        translation: 'He bought a small red car',
      },
      {
        id: 82,
        expression:
          'Я чувствую себя гораздо увереннее, когда говорю по-английски',
        transcription: 'aɪ fiːl mʌʧ mɔː ˈkɒnfɪdənt wɛn aɪ spiːk ˈɪŋglɪʃ',
        translation: 'I feel much more confident when I speak English',
      },
      {
        id: 83,
        expression:
          'Я чувствую себя очень уверенно, когда говорю на своем родном языке',
        transcription:
          'aɪ fiːl ˈvɛri ˈkɒnfɪdənt wɛn aɪ spiːk maɪ ˈneɪtɪv ˈlæŋgwɪʤ',
        translation: 'I feel very confident when I speak my native language',
      },
      {
        id: 84,
        expression:
          'Я чувствую себя лучше, когда говорю по-английски без ошибок',
        transcription: 'aɪ fiːl ˈbɛtə wɛn aɪ spiːk ˈɪŋglɪʃ wɪˈðaʊt mɪsˈteɪks',
        translation: 'I feel better when I speak English without mistakes',
      },
      {
        id: 85,
        expression: 'Он гораздо более известный музыкант',
        transcription: 'hiːz ə mʌʧ mɔː ˈfeɪməs mju(ː)ˈzɪʃən',
        translation: "He's a much more famous musician",
      },
      {
        id: 86,
        expression: 'Она такой добрый человек',
        transcription: 'ʃiːz sʌʧ ə kaɪnd ˈpɜːsn',
        translation: "She's such a kind person",
      },
      {
        id: 87,
        expression: 'Я думаю, это настоящий феномен двадцать первого века',
        transcription:
          'aɪ θɪŋk ɪts ə rɪəl fɪˈnɒmɪnən əv ðə ˈtwɛnti fɜːst ˈsɛnʧʊri',
        translation:
          "I think it's a real phenomenon of the twenty first century",
      },
      {
        id: 88,
        expression: 'Я действительно заинтересован в этой теме',
        transcription: 'aɪm ˈrɪəli ˈɪntrɪstɪd ɪn ðɪs ˈtɒpɪk',
        translation: "I'm really interested in this topic",
      },
      {
        id: 89,
        expression: 'Это такая большая социальная проблема',
        transcription: 'ɪts sʌʧ ə bɪg ˈsəʊʃəl ˈprɒbləm',
        translation: "It's such a big social problem",
      },
      {
        id: 90,
        expression: 'Он носит кожаный ремень',
        transcription: 'hi weəz ə ˈlɛðə bɛlt',
        translation: 'He wears a leather belt',
      },
      {
        id: 91,
        expression: 'Это было огромным успехом',
        transcription: 'ɪt wəz ə hjuːʤ səkˈsɛs',
        translation: 'It was a huge success',
      },
      {
        id: 92,
        expression: 'Он более успешный предприниматель',
        transcription: 'hiːz ə mɔː səkˈsɛsfʊl ˌɒntrəprəˈnɜː',
        translation: "He's a more successful entrepreneur",
      },
      {
        id: 93,
        expression: 'Мы можем дышать свежим воздухом там',
        transcription: 'wi kən brɛθ frɛʃ eə ðeə',
        translation: 'We can breath fresh air there',
      },
      {
        id: 94,
        expression: 'Каждый из них это понимает',
        transcription: 'iːʧ əv ðəm ˌʌndəˈstændz ɪt',
        translation: 'Each of them understands it',
      },
      {
        id: 95,
        expression: 'Каждый из нас знает это',
        transcription: 'iːʧ əv əs nəʊz ɪt',
        translation: 'Each of us knows it',
      },
      {
        id: 96,
        expression: 'Он не сумасшедший',
        transcription: 'hi ˈɪznt mæd',
        translation: "He isn't mad",
      },
      {
        id: 97,
        expression: 'Это очень древний город',
        transcription: 'ɪts ə ˈvɛri ˈeɪnʃ(ə)nt taʊn',
        translation: "It's a very ancient town",
      },
      {
        id: 98,
        expression: 'Ты такой везучий',
        transcription: 'jʊə səʊ ˈlʌki',
        translation: "You're so lucky",
      },
      {
        id: 99,
        expression: 'Здесь очень сухо',
        transcription: 'ɪts ˈvɛri draɪ hɪə',
        translation: "It's very dry here",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Закрепление пройденного материала</h3>
</div>`,
    },
  ],
];
