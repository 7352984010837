export const contentLesson42 = [
  [
    {
      title: "Урок 42. Past Simple: was / were. Question",
    },
    [
      {
        id: 1,
        expression: "Она была замужем?",
        transcription: "wəz ʃi ˈmærɪd?",
        translation: "Was she married?",
      },
      {
        id: 2,
        expression: "Ты был готов?",
        transcription: "wə jʊ ˈrɛdi?",
        translation: "Were you ready?",
      },
      {
        id: 3,
        expression: "Это было нормально для тебя?",
        transcription: "wəz ɪt ˈnɔːməl fə juː?",
        translation: "Was it normal for you?",
      },
      {
        id: 4,
        expression: "Он был прав?",
        transcription: "wəz hi raɪt?",
        translation: "Was he right?",
      },
      {
        id: 5,
        expression: "Это было действительно дорого?",
        transcription: "wəz ɪt ˈrɪəli ɪksˈpɛnsɪv?",
        translation: "Was it really expensive?",
      },
      {
        id: 6,
        expression: "Это было вчера?",
        transcription: "wəz ɪt ˈjɛstədeɪ?",
        translation: "Was it yesterday?",
      },
      {
        id: 7,
        expression: "Он был действительно уверен?",
        transcription: "wəz hi ˈrɪəli ʃʊə?",
        translation: "Was he really sure?",
      },
      {
        id: 8,
        expression: "Это было очень далеко?",
        transcription: "wəz ɪt ˈvɛri fɑː?",
        translation: "Was it very far?",
      },
      {
        id: 9,
        expression: "Это было странно?",
        transcription: "wəz ɪt streɪnʤ?",
        translation: "Was it strange?",
      },
      {
        id: 10,
        expression: "Это было действительно важно?",
        transcription: "wəz ɪt ˈrɪəli ɪmˈpɔːtənt?",
        translation: "Was it really important?",
      },
      {
        id: 11,
        expression: "Это было в прошлом веке?",
        transcription: "wəz ɪt lɑːst ˈsɛnʧʊri?",
        translation: "Was it last century?",
      },
      {
        id: 12,
        expression: "Ты был готов?",
        transcription: "wə jʊ ˈrɛdi?",
        translation: "Were you ready?",
      },
      {
        id: 13,
        expression: "Это было очень популярно?",
        transcription: "wəz ɪt ˈvɛri ˈpɒpjʊlə?",
        translation: "Was it very popular?",
      },
      {
        id: 14,
        expression: "Он был холост?",
        transcription: "wəz hi ˈsɪŋgl?",
        translation: "Was he single?",
      },
      {
        id: 15,
        expression: "Это была игра?",
        transcription: "wəz ɪt ə geɪm?",
        translation: "Was it a game?",
      },
      {
        id: 16,
        expression: "Ты был уверен?",
        transcription: "wə jʊ ʃʊə?",
        translation: "Were you sure?",
      },
      {
        id: 17,
        expression: "Это было на прошлой неделе?",
        transcription: "wəz ɪt lɑːst wiːk?",
        translation: "Was it last week?",
      },
      {
        id: 18,
        expression: "Он был твоим лучшим другом?",
        transcription: "wəz hi jə bɛst frɛnd?",
        translation: "Was he your best friend?",
      },
      {
        id: 19,
        expression: "Ты был действительно счастлив?",
        transcription: "wə jʊ ˈrɪəli ˈhæpi?",
        translation: "Were you really happy?",
      },
      {
        id: 20,
        expression: "Они были в школе?",
        transcription: "wə ðeɪ ət skuːl?",
        translation: "Were they at school?",
      },
      {
        id: 21,
        expression: "Это было действительно интересно тебе?",
        transcription: "wəz ɪt ˈrɪəli ˈɪntrɪstɪŋ fə juː?",
        translation: "Was it really interesing for you?",
      },
      {
        id: 22,
        expression: "Ты был в университете?",
        transcription: "wə jʊ ət ˌjuːnɪˈvɜːsɪti?",
        translation: "Were you at university?",
      },
      {
        id: 23,
        expression: "Она была замужем?",
        transcription: "wəz ʃi ˈmærɪd?",
        translation: "Was she married?",
      },
      {
        id: 24,
        expression: "Это было действительно возможно?",
        transcription: "wəz ɪt ˈrɪəli ˈpɒsəbl?",
        translation: "Was it really possible?",
      },
      {
        id: 25,
        expression: "Ты был очень занят в пятницу?",
        transcription: "wə jʊ ˈvɛri ˈbɪzi ɒn ˈfraɪdeɪ?",
        translation: "Were you very busy on Friday?",
      },
      {
        id: 26,
        expression: "Это было дорого?",
        transcription: "wəz ɪt ɪksˈpɛnsɪv?",
        translation: "Was it expensive?",
      },
      {
        id: 27,
        expression: "Они были очень хорошими учителями?",
        transcription: "wə ðeɪ ˈvɛri gʊd ˈtiːʧəz?",
        translation: "Were they very good teachers?",
      },
      {
        id: 28,
        expression: "Это было интересно?",
        transcription: "wəz ɪt ˈɪntrɪstɪŋ?",
        translation: "Was it interesting?",
      },
      {
        id: 29,
        expression: "Ты был оптимистичен?",
        transcription: "wə jʊ ˌɒptɪˈmɪstɪk?",
        translation: "Were you optimistic?",
      },
      {
        id: 30,
        expression: "Он был действительно зол?",
        transcription: "wəz hi ˈrɪəli ˈæŋgri?",
        translation: "Was he really angry?",
      },
      {
        id: 31,
        expression: "Это было два века назад?",
        transcription: "wəz ɪt tuː ˈsɛnʧʊriz əˈgəʊ?",
        translation: "Was it two centuries ago?",
      },
      {
        id: 32,
        expression: "Она была пессимистична?",
        transcription: "wəz ʃi ˌpɛsɪˈmɪstɪk?",
        translation: "Was she pessimistic?",
      },
      {
        id: 33,
        expression: "Ты действительно был так оптимистичен?",
        transcription: "wə jʊ ˈrɪəli səʊ ˌɒptɪˈmɪstɪk?",
        translation: "Were you really so optimistic?",
      },
      {
        id: 34,
        expression: "Это было действительно полезно для тебя?",
        transcription: "wəz ɪt ˈrɪəli ˈjuːsfʊl fə juː?",
        translation: "Was it really useful for you?",
      },
      {
        id: 35,
        expression: "Это было очень забавно?",
        transcription: "wəz ɪt ˈvɛri ˈfʌni?",
        translation: "Was it very funny?",
      },
      {
        id: 36,
        expression: "Они были очень бедные?",
        transcription: "wə ðeɪ ˈvɛri pʊə?",
        translation: "Were they very poor?",
      },
      {
        id: 37,
        expression: "Я был прав?",
        transcription: "wəz aɪ raɪt?",
        translation: "Was I right?",
      },
      {
        id: 38,
        expression: "Это было действительно так?",
        transcription: "wəz ɪt ˈrɪəli səʊ?",
        translation: "Was it really so?",
      },
      {
        id: 39,
        expression: "Он был очень занят в прошлый понедельник?",
        transcription: "wəz hi ˈvɛri ˈbɪzi lɑːst ˈmʌndeɪ?",
        translation: "Was he very busy last Monday?",
      },
      {
        id: 40,
        expression: "Он был слишком пессимистичен?",
        transcription: "wəz hi tuː ˌpɛsɪˈmɪstɪk?",
        translation: "Was he too pessimistic?",
      },
      {
        id: 41,
        expression: "Это действительно было так сложно?",
        transcription: "wəz ɪt ˈrɪəli səʊ ˈdɪfɪkəlt?",
        translation: "Was it really so difficult?",
      },
      {
        id: 42,
        expression: "Она болела на прошлой неделе?",
        transcription: "wəz ʃi ɪl lɑːst wiːk?",
        translation: "Was she ill last week?",
      },
      {
        id: 43,
        expression: "Это была здоровая еда?",
        transcription: "wəz ɪt ˈhɛlθi fuːd?",
        translation: "Was it healthy food?",
      },
      {
        id: 44,
        expression: "Это был неправильный ответ?",
        transcription: "wəz ɪt ðə rɒŋ ˈɑːnsə?",
        translation: "Was it the wrong answer?",
      },
      {
        id: 45,
        expression: "Ты был рад этому?",
        transcription: "wə jʊ ˈhæpi əˈbaʊt ɪt?",
        translation: "Were you happy about it?",
      },
      {
        id: 46,
        expression: "Он был в отпуске в прошлом месяце?",
        transcription: "wəz hi ɒn ˈhɒlədeɪ lɑːst mʌnθ?",
        translation: "Was he on holiday last month?",
      },
      {
        id: 47,
        expression: "Он был в аэропорту?",
        transcription: "wəz hi ət ði ˈeəpɔːt?",
        translation: "Was he at the airport?",
      },
      {
        id: 48,
        expression: "Ты действительно был уставший?",
        transcription: "wə jʊ ˈrɪəli ˈtaɪəd?",
        translation: "Were you really tired?",
      },
      {
        id: 49,
        expression: "Это было хорошо или плохо?",
        transcription: "wəz ɪt gʊd ɔː bæd?",
        translation: "Was it good or bad?",
      },
      {
        id: 50,
        expression: "Ты был в музее?",
        transcription: "wə jʊ ɪn ðə mju(ː)ˈzɪəm?",
        translation: "Were you in the museum?",
      },
      {
        id: 51,
        expression: "Это было действительно так ужасно?",
        transcription: "wəz ɪt ˈrɪəli səʊ ˈtɛrəbl?",
        translation: "Was it really so terrible?",
      },
      {
        id: 52,
        expression: "Это было дорого или нет?",
        transcription: "wəz ɪt ɪksˈpɛnsɪv ɔː nɒt?",
        translation: "Was it expensive or not?",
      },
      {
        id: 53,
        expression: "Они были очень профессиональны?",
        transcription: "wə ðeɪ ˈvɛri prəˈfɛʃənl?",
        translation: "Were they very professional?",
      },
      {
        id: 54,
        expression: "Она действительно боялась этого?",
        transcription: "wəz ʃi ˈrɪəli əˈfreɪd əv ɪt?",
        translation: "Was she really afraid of it?",
      },
      {
        id: 55,
        expression: "Это был правильный ответ?",
        transcription: "wəz ɪt ðə kəˈrɛkt ˈɑːnsə?",
        translation: "Was it the correct answer?",
      },
      {
        id: 56,
        expression: "Они часто бывали в том месте?",
        transcription: "wə ðeɪ ˈɒf(ə)n ɪn ðət pleɪs?",
        translation: "Were they often in that place?",
      },
      {
        id: 57,
        expression: "Он был в ванной?",
        transcription: "wəz hi ɪn ðə ˈbɑːθru(ː)m?",
        translation: "Was he in the bathroom?",
      },
      {
        id: 58,
        expression: "Ты был в гостиной?",
        transcription: "wə jʊ ɪn ðə ˈlɪvɪŋ ruːm?",
        translation: "Were you in the living room?",
      },
      {
        id: 59,
        expression: "Ты был на кухне?",
        transcription: "wə jʊ ɪn ðə ˈkɪʧɪn?",
        translation: "Were you in the kitchen?",
      },
      {
        id: 60,
        expression: "Он был в комнате?",
        transcription: "wəz hi ɪn ðə ruːm?",
        translation: "Was he in the room?",
      },
      {
        id: 61,
        expression: "Они были в своей комнате?",
        transcription: "wə ðeɪ ɪn ðeə ruːm?",
        translation: "Were they in their room?",
      },
      {
        id: 62,
        expression: "Она была на кухне?",
        transcription: "wəz ʃi ɪn ðə ˈkɪʧɪn?",
        translation: "Was she in the kitchen?",
      },
      {
        id: 63,
        expression: "Он боялся?",
        transcription: "wəz hi əˈfreɪd?",
        translation: "Was he afraid?",
      },
      {
        id: 64,
        expression: "Она действительно боялась?",
        transcription: "wəz ʃi ˈrɪəli əˈfreɪd?",
        translation: "Was she really afraid?",
      },
      {
        id: 65,
        expression: "Они боялись?",
        transcription: "wə ðeɪ əˈfreɪd?",
        translation: "Were they afraid?",
      },
      {
        id: 66,
        expression: "Он был в гостиной?",
        transcription: "wəz hi ɪn ðə ˈlɪvɪŋ ruːm?",
        translation: "Was he in the living room?",
      },
      {
        id: 67,
        expression: "Ты был в театре?",
        transcription: "wə jʊ ɪn ðə ˈθɪətə?",
        translation: "Were you in the theatre?",
      },
      {
        id: 68,
        expression: "Ты был в зоопарке?",
        transcription: "wə jʊ ɪn ðə zuː?",
        translation: "Were you in the zoo?",
      },
      {
        id: 69,
        expression: "Он был в своем саду?",
        transcription: "wəz hi ɪn ɪz ˈgɑːdn?",
        translation: "Was he in his garden?",
      },
      {
        id: 70,
        expression: "Это была хорошая еда?",
        transcription: "wəz ɪt gʊd fuːd?",
        translation: "Was it good food?",
      },
      {
        id: 71,
        expression: "Они были хорошими друзьями?",
        transcription: "wə ðeɪ gʊd frɛndz?",
        translation: "Were they good friends?",
      },
      {
        id: 72,
        expression: "Ты был в отпуске на прошлой неделе?",
        transcription: "wə jʊ ɒn ˈhɒlədeɪ lɑːst wiːk?",
        translation: "Were you on holiday last week?",
      },
      {
        id: 73,
        expression: "Они были в отпуске в прошлом году?",
        transcription: "wə ðeɪ ɒn ˈhɒlədeɪ lɑːst jɪə?",
        translation: "Were they on holiday last year?",
      },
      {
        id: 74,
        expression: "Он был дома на прошлой неделе?",
        transcription: "wəz hi ət həʊm lɑːst wiːk?",
        translation: "Was he at home last week?",
      },
      {
        id: 75,
        expression: "Это был правильный ответ?",
        transcription: "wəz ɪt ðə kəˈrɛkt (raɪt) ˈɑːnsə?",
        translation: "Was it the correct (right) answer?",
      },
      {
        id: 76,
        expression: "Это был правильный вопрос?",
        transcription: "wəz ɪt ðə kəˈrɛkt (raɪt) ˈkwɛsʧən?",
        translation: "Was it the correct (right) question?",
      },
      {
        id: 77,
        expression: "Они были хорошими работниками?",
        transcription: "wə ðeɪ gʊd ˈwɜːkəz?",
        translation: "Were they good workers?",
      },
      {
        id: 78,
        expression: "Он был хорошим тренером?",
        transcription: "wəz hi ə gʊd ˈtreɪnə?",
        translation: "Was he a good trainer?",
      },
      {
        id: 79,
        expression: "Мы были хорошими учителями?",
        transcription: "wə wi gʊd ˈtiːʧəz?",
        translation: "Were we good teachers?",
      },
      {
        id: 80,
        expression: "Это было слишком легко для тебя?",
        transcription: "wəz ɪt tuː ˈiːzi fə juː?",
        translation: "Was it too easy for you?",
      },
      {
        id: 81,
        expression: "Она была в театре?",
        transcription: "wəz ʃi ɪn ðə ˈθɪətə?",
        translation: "Was she in the theatre?",
      },
      {
        id: 82,
        expression: "Ты рад был своим результатам?",
        transcription: "wə jʊ ˈhæpi əˈbaʊt jə rɪˈzʌlts?",
        translation: "Were you happy about your results?",
      },
      {
        id: 83,
        expression: "Она была в отпуске?",
        transcription: "wəz ʃi ɒn ˈhɒlədeɪ?",
        translation: "Was she on holiday?",
      },
      {
        id: 84,
        expression: "Ты был уставшим?",
        transcription: "wə jʊ ˈtaɪəd?",
        translation: "Were you tired?",
      },
      {
        id: 85,
        expression: "Вы часто были вместе?",
        transcription: "wə jʊ ˈɒf(ə)n təˈgɛðə?",
        translation: "Were you often together?",
      },
      {
        id: 86,
        expression: "Это было дешево или нет?",
        transcription: "wəz ɪt ʧiːp ɔː nɒt?",
        translation: "Was it cheap or not?",
      },
      {
        id: 87,
        expression: "Она была в музее вчера?",
        transcription: "wəz ʃi ɪn ðə mju(ː)ˈzɪəm ˈjɛstədeɪ?",
        translation: "Was she in the museum yesterday?",
      },
      {
        id: 88,
        expression: "Ты был в саду?",
        transcription: "wə jʊ ɪn ðə ˈgɑːdn?",
        translation: "Were you in the garden?",
      },
      {
        id: 89,
        expression: "Это было рядом с этим местом?",
        transcription: "wəz ɪt nɪə ðət pleɪs?",
        translation: "Was it near that place?",
      },
      {
        id: 90,
        expression: "Ты был готов к этому?",
        transcription: "wə jʊ ˈrɛdi fər ɪt?",
        translation: "Were you ready for it?",
      },
      {
        id: 91,
        expression: "Это было далеко отсюда?",
        transcription: "wəz ɪt fɑː frəm hɪə?",
        translation: "Was it far from here?",
      },
      {
        id: 92,
        expression: "Ты был в аэропорту?",
        transcription: "wə jʊ ət ði ˈeəpɔːt?",
        translation: "Were you at the airport?",
      },
      {
        id: 93,
        expression: "Это было эффективно или нет?",
        transcription: "wəz ɪt ɪˈfɛktɪv ɔː nɒt?",
        translation: "Was it effective or not?",
      },
      {
        id: 94,
        expression: "Ты был в ванной?",
        transcription: "wə jʊ ɪn ðə ˈbɑːθru(ː)m?",
        translation: "Were you in the bathroom?",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Was / were в вопросительной форме в Past Simple</h3>
<p>Образуется по формуле:</p>
<p><span>were / was + подлежащее + ...?</span></p>
<p>Were you married? - Ты был(а) женат (замужем)?</p>
<p>Was I ready? - Я был готов?</p>
</div>`,
    },
  ],
];
