export const wordsLesson37 = [
  [
    {
      id: 1,
      word: 'armchair',
      transcription: 'ˈɑːmˈʧeə',
      translation: 'кресло',
    },
    {
      id: 2,
      word: 'article',
      transcription: 'ˈɑːtɪkl',
      translation: 'статья',
    },
    {
      id: 3,
      word: 'beautifully',
      transcription: 'ˈbjuːtəfʊli',
      translation: 'прекрасно, красиво',
    },
    {
      id: 4,
      word: 'break',
      transcription: 'breɪk',
      translation: 'ломать',
    },
    {
      id: 5,
      word: 'camel',
      transcription: 'ˈkæməl',
      translation: 'верблюд',
    },
    {
      id: 6,
      word: 'chair',
      transcription: 'ʧeə',
      translation: 'стул',
    },
    {
      id: 7,
      word: 'cut',
      transcription: 'kʌt',
      translation: 'резать',
    },
    {
      id: 8,
      word: 'favourite',
      transcription: 'ˈfeɪvərɪt',
      translation: 'любимый',
    },
    {
      id: 9,
      word: 'finger',
      transcription: 'ˈfɪŋgə',
      translation: 'палец',
    },
    {
      id: 10,
      word: 'flower',
      transcription: 'ˈflaʊə',
      translation: 'цветок',
    },
    {
      id: 11,
      word: 'fly',
      transcription: 'flaɪ',
      translation: 'летать',
    },
    {
      id: 12,
      word: 'himself',
      transcription: 'hɪmˈsɛlf',
      translation: 'сам (мужской род)',
    },
    {
      id: 13,
      word: 'keep',
      transcription: 'kiːp',
      translation: 'хранить, держать',
    },
    {
      id: 14,
      word: 'loudly',
      transcription: 'ˈlaʊdli',
      translation: 'громко',
    },
    {
      id: 15,
      word: 'pen',
      transcription: 'pɛn',
      translation: 'ручка',
    },
    {
      id: 16,
      word: 'perfectly',
      transcription: 'ˈpɜːfɪktli',
      translation: 'идеально',
    },
    {
      id: 17,
      word: 'promise',
      transcription: 'ˈprɒmɪs',
      translation: 'обещание, обещать',
    },
    {
      id: 18,
      word: 'sing',
      transcription: 'sɪŋ',
      translation: 'петь',
    },
    {
      id: 19,
      word: 'the United Kingdom',
      transcription: 'ðə jʊˈnaɪtɪd ˈkɪŋdəm',
      translation: 'Соединенное Королевство',
    },
    {
      id: 20,
      word: 'sit down',
      transcription: 'sɪt daʊn',
      translation: 'садиться',
    },
    {
      id: 21,
      word: 'break down',
      transcription: 'breɪk daʊn',
      translation: 'ломаться',
    },
  ],
];
