export const wordsLesson243 = [
  [
    {
      id: 1,
      word: 'beetroot',
      transcription: 'ˈbiːtruːt',
      translation: 'свекла',
    },
    {
      id: 2,
      word: 'bend',
      transcription: 'bɛnd',
      translation: 'сгибать, изгиб',
    },
    {
      id: 3,
      word: 'carrot',
      transcription: 'ˈkærət',
      translation: 'морковь',
    },
    {
      id: 4,
      word: 'connect',
      transcription: 'kəˈnɛkt',
      translation: 'соединять',
    },
    {
      id: 5,
      word: 'crew',
      transcription: 'kruː',
      translation: 'экипаж',
    },
    {
      id: 6,
      word: 'declare',
      transcription: 'dɪˈkleə',
      translation: 'объявлять',
    },
    {
      id: 7,
      word: 'decorate',
      transcription: 'ˈdɛkəreɪt',
      translation: 'украшать',
    },
    {
      id: 8,
      word: 'dialogue',
      transcription: 'ˈdaɪəlɒɡ',
      translation: 'диалог',
    },
    {
      id: 9,
      word: 'encourage',
      transcription: 'ɪnˈkʌrɪʤ',
      translation: 'воодушевлять, поощрять',
    },
    {
      id: 10,
      word: 'eraser',
      transcription: 'ɪˈreɪzə',
      translation: 'ластик',
    },
    {
      id: 11,
      word: 'fraud',
      transcription: 'frɔːd',
      translation: 'мошенничество',
    },
    {
      id: 12,
      word: 'google',
      transcription: 'ˈɡuːɡəl',
      translation: 'гуглить',
    },
    {
      id: 13,
      word: 'involve',
      transcription: 'ɪnˈvɒlv',
      translation: 'включать, подразумевать',
    },
    {
      id: 14,
      word: 'location',
      transcription: 'ləʊˈkeɪʃᵊn',
      translation: 'расположение',
    },
    {
      id: 15,
      word: 'mix',
      transcription: 'mɪks',
      translation: 'смешивать',
    },
    {
      id: 16,
      word: 'moderately',
      transcription: 'ˈmɒdərɪtli',
      translation: 'умеренно',
    },
    {
      id: 17,
      word: 'moderation',
      transcription: 'ˌmɒdəˈreɪʃᵊn',
      translation: 'умеренность',
    },
    {
      id: 18,
      word: 'onion',
      transcription: 'ˈʌnjən',
      translation: 'лук',
    },
    {
      id: 19,
      word: 'overtake',
      transcription: 'ˌəʊvəˈteɪk',
      translation: 'обгонять',
    },
    {
      id: 20,
      word: 'panic',
      transcription: 'ˈpænɪk',
      translation: 'паника',
    },
    {
      id: 21,
      word: 'permit',
      transcription: 'ˈpɜːmɪt',
      translation: 'разрешать',
    },
    {
      id: 22,
      word: 'prosecute',
      transcription: 'ˈprɒsɪkjuːt',
      translation: 'преследовать в судебном порядке',
    },
    {
      id: 23,
      word: 'rob',
      transcription: 'rɒb',
      translation: 'грабить',
    },
    {
      id: 24,
      word: 'rub',
      transcription: 'rʌb',
      translation: 'стирать [ластиком], смахивать',
    },
    {
      id: 25,
      word: 'select',
      transcription: 'sɪˈlɛkt',
      translation: 'выбирать',
    },
    {
      id: 26,
      word: 'shoot',
      transcription: 'ʃuːt',
      translation: 'стрелять',
    },
  ],
];
