export const wordsLesson55 = [
  [
    {
      id: 1,
      word: 'boss',
      transcription: 'bɒs',
      translation: 'начальник, босс',
    },
    {
      id: 2,
      word: 'carefully',
      transcription: 'ˈkeəfli',
      translation: 'осторожно',
    },
    {
      id: 3,
      word: 'credit card',
      transcription: 'ˈkrɛdɪt kɑːd',
      translation: 'кредитная карта',
    },
    {
      id: 4,
      word: 'elder sister',
      transcription: 'ˈɛldə ˈsɪstə',
      translation: 'старшая сестра',
    },
    {
      id: 5,
      word: 'following',
      transcription: 'ˈfɒləʊɪŋ',
      translation: 'следующий',
    },
    {
      id: 6,
      word: 'freelancer',
      transcription: 'ˈfriːˌlɑːnsə',
      translation: 'фрилансер',
    },
    {
      id: 7,
      word: 'greatly',
      transcription: 'ˈgreɪtli',
      translation: 'очень сильно',
    },
    {
      id: 8,
      word: 'hang',
      transcription: 'hæŋ',
      translation: 'висеть, вешать',
    },
    {
      id: 9,
      word: 'inspire',
      transcription: 'ɪnˈspaɪə',
      translation: 'вдохновлять',
    },
    {
      id: 10,
      word: 'interrupt',
      transcription: 'ˌɪntəˈrʌpt',
      translation: 'перебивать',
    },
    {
      id: 11,
      word: 'lots of',
      transcription: 'lɒts ɒv',
      translation: 'много',
    },
    {
      id: 12,
      word: 'opinion',
      transcription: 'əˈpɪnjən',
      translation: 'мнение',
    },
    {
      id: 13,
      word: 'others',
      transcription: 'ˈʌðəz',
      translation: 'другие',
    },
    {
      id: 14,
      word: 'patient',
      transcription: 'ˈpeɪʃənt',
      translation: 'пациент',
    },
    {
      id: 15,
      word: 'picture',
      transcription: 'ˈpɪkʧə',
      translation: 'картина',
    },
    {
      id: 16,
      word: 'poster',
      transcription: 'ˈpəʊstə',
      translation: 'плакат',
    },
    {
      id: 17,
      word: 'sale',
      transcription: 'seɪl',
      translation: 'продажа, распродажа',
    },
    {
      id: 18,
      word: 'shoes',
      transcription: 'ʃuːz',
      translation: 'обувь',
    },
    {
      id: 19,
      word: 'store',
      transcription: 'stɔː',
      translation: 'магазин',
    },
    {
      id: 20,
      word: 'online store',
      transcription: 'ˈɒnˌlaɪn stɔː',
      translation: 'интернет-магазин',
    },
    {
      id: 21,
      word: 'such',
      transcription: 'sʌʧ',
      translation: 'такой, такие',
    },
    {
      id: 22,
      word: 'teenager',
      transcription: 'ˈtiːnˌeɪʤə',
      translation: 'подросток',
    },
    {
      id: 23,
      word: 'vet',
      transcription: 'vɛt',
      translation: 'ветеринар',
    },
    {
      id: 24,
      word: 'wall',
      transcription: 'wɔːl',
      translation: 'стена',
    },
    {
      id: 25,
      word: 'way',
      transcription: 'weɪ',
      translation: 'путь',
    },
    {
      id: 26,
      word: 'weak',
      transcription: 'wiːk',
      translation: 'слабый',
    },
    {
      id: 27,
      word: 'younger',
      transcription: 'ˈjʌŋə',
      translation: 'младший',
    },
  ],
];
