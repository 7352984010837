export const wordsLesson3 = [
  [
    {
      id: 1,
      word: 'apple',
      transcription: 'ˈæpl',
      translation: 'яблоко',
    },
    {
      id: 2,
      word: 'boy',
      transcription: 'bɔɪ',
      translation: 'мальчик',
    },
    {
      id: 3,
      word: 'brother',
      transcription: 'ˈbrʌðə',
      translation: 'брат',
    },
    {
      id: 4,
      word: 'bus',
      transcription: 'bʌs',
      translation: 'автобус',
    },
    {
      id: 5,
      word: 'car',
      transcription: 'kɑː',
      translation: 'машина',
    },
    {
      id: 6,
      word: 'free',
      transcription: 'friː',
      translation: 'свободный, бесплатный',
    },
    {
      id: 7,
      word: 'girl',
      transcription: 'gɜːl',
      translation: 'девочка',
    },
    {
      id: 8,
      word: 'have',
      transcription: 'hæv',
      translation: 'иметь',
    },
    {
      id: 9,
      word: 'hotel',
      transcription: 'həʊˈtɛl',
      translation: 'отель',
    },
    {
      id: 10,
      word: 'house',
      transcription: 'haʊs',
      translation: 'дом',
    },
    {
      id: 11,
      word: 'job',
      transcription: 'ʤɒb',
      translation: 'работа',
    },
    {
      id: 12,
      word: 'man',
      transcription: 'mæn',
      translation: 'мужчина',
    },
    {
      id: 13,
      word: 'money',
      transcription: 'ˈmʌni',
      translation: 'деньги',
    },
    {
      id: 14,
      word: 'one',
      transcription: 'wʌn',
      translation: 'один',
    },
    {
      id: 15,
      word: 'orange',
      transcription: 'ˈɒrɪnʤ',
      translation: 'апельсин',
    },
    {
      id: 16,
      word: 'question',
      transcription: 'ˈkwɛsʧən',
      translation: 'вопрос',
    },
    {
      id: 17,
      word: 'sister',
      transcription: 'ˈsɪstə',
      translation: 'сестра',
    },
    {
      id: 18,
      word: 'some',
      transcription: 'sʌm',
      translation: 'некоторые, немного',
    },
    {
      id: 19,
      word: 'teacher',
      transcription: 'ˈtiːʧə',
      translation: 'учитель',
    },
    {
      id: 20,
      word: 'time',
      transcription: 'taɪm',
      translation: 'время',
    },
    {
      id: 21,
      word: 'two',
      transcription: 'tuː',
      translation: 'два',
    },
    {
      id: 22,
      word: 'woman',
      transcription: 'ˈwʊmən',
      translation: 'женщина',
    },
    {
      id: 23,
      word: 'your',
      transcription: 'jɔː',
      translation: 'твой, ваш',
    },
    {
      id: 24,
      word: 'bank',
      transcription: 'bæŋk',
      translation: 'банк',
    },
    {
      id: 25,
      word: 'coffee',
      transcription: 'ˈkɒfi',
      translation: 'кофе',
    },
    {
      id: 26,
      word: 'computer',
      transcription: 'kəmˈpjuːtə',
      translation: 'компьютер',
    },
    {
      id: 27,
      word: 'different',
      transcription: 'ˈdɪfrənt',
      translation: 'различный',
    },
    {
      id: 28,
      word: 'drink',
      transcription: 'drɪŋk',
      translation: 'пить',
    },
    {
      id: 29,
      word: 'eat',
      transcription: 'iːt',
      translation: 'есть',
    },
    {
      id: 30,
      word: 'exercise',
      transcription: 'ˈɛksəsaɪz',
      translation: 'упражнение',
    },
    {
      id: 31,
      word: 'family',
      transcription: 'ˈfæmɪli',
      translation: 'семья',
    },
    {
      id: 32,
      word: 'fish',
      transcription: 'fɪʃ',
      translation: 'рыба',
    },
    {
      id: 33,
      word: 'juice',
      transcription: 'ʤuːs',
      translation: 'сок',
    },
    {
      id: 34,
      word: 'milk',
      transcription: 'mɪlk',
      translation: 'молоко',
    },
    {
      id: 35,
      word: 'motivation',
      transcription: 'ˌməʊtɪˈveɪʃən',
      translation: 'мотивация',
    },
    {
      id: 36,
      word: 'progress',
      transcription: 'ˈprəʊgrəs',
      translation: 'прогресс',
    },
    {
      id: 37,
      word: 'sport',
      transcription: 'spɔːt',
      translation: 'спорт',
    },
    {
      id: 38,
      word: 'student',
      transcription: 'ˈstjuːdənt',
      translation: 'студент',
    },
    {
      id: 39,
      word: 'taxi',
      transcription: 'ˈtæksi',
      translation: 'такси',
    },
    {
      id: 40,
      word: 'tea',
      transcription: 'tiː',
      translation: 'чай',
    },
    {
      id: 41,
      word: 'water',
      transcription: 'ˈwɔːtə',
      translation: 'вода',
    },
  ],
];
