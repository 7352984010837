export const contentLesson186 = [
  [
    {
      title:
        'Урок 186. 450 Adjectives with different tenses and structures. Part 5',
    },
    [
      {
        id: 1,
        expression: 'Рабочие очень умелые',
        transcription: 'ðə ˈwɜːkəz ə ˈvɛri ˈskɪlf(ə)l',
        translation: 'The workers are very skillful',
      },
      {
        id: 2,
        expression:
          'Я чувствую настоящий прогресс после выполнения этих упражнений',
        transcription:
          'aɪ fiːl rɪəl ˈprəʊgrəs ˈɑːftə ˈdu(ː)ɪŋ ðiːz ˈɛksəsaɪzɪz',
        translation: 'I feel real progress after doing these exercises',
      },
      {
        id: 3,
        expression: 'Он так устал от этого',
        transcription: 'hiːz səʊ ˈtaɪəd əv ɪt',
        translation: "He's so tired of it",
      },
      {
        id: 4,
        expression: 'Он очень умный человек',
        transcription: 'hiːz ə ˈvɛri ɪnˈtɛlɪʤənt ˈpɜːsn',
        translation: "He's a very intelligent person",
      },
      {
        id: 5,
        expression: 'У него есть реальная возможность победить',
        transcription: 'hi həz ə rɪəl ˌɒpəˈtjuːnɪti (ˌpɒsəˈbɪlɪti) tə wɪn',
        translation: 'He has a real opportunity (possibility) to win',
      },
      {
        id: 6,
        expression: 'Фильм действительно скучный',
        transcription: 'ðə ˈmuːvi z ˈrɪəli dʌl',
        translation: 'The movie is really dull',
      },
      {
        id: 7,
        expression: 'Это такая скучная книга',
        transcription: 'ɪts sʌʧ ə dʌl bʊk',
        translation: "It's such a dull book",
      },
      {
        id: 8,
        expression: 'Это такая вкусная еда',
        transcription: 'ɪts sʌʧ ˈteɪsti fuːd',
        translation: "It's such tasty food",
      },
      {
        id: 9,
        expression: 'Это была очень вкусная еда',
        transcription: 'ɪt wəz ˈvɛri ˈteɪsti fuːd',
        translation: 'It was very tasty food',
      },
      {
        id: 10,
        expression: 'Рад тебя видеть',
        transcription: 'glæd tə siː juː',
        translation: 'Glad to see you',
      },
      {
        id: 11,
        expression: 'Погода сегодня действительно ужасная',
        transcription: 'ðə ˈwɛðə z ˈrɪəli ˈɔːfʊl təˈdeɪ',
        translation: 'The weather is really awful today',
      },
      {
        id: 12,
        expression: 'Это главная проблема',
        transcription: 'ɪts ðə meɪn ˈprɒbləm',
        translation: "It's the main problem",
      },
      {
        id: 13,
        expression: 'Сегодня дождливо',
        transcription: 'ɪts ˈreɪni təˈdeɪ',
        translation: "It's rainy today",
      },
      {
        id: 14,
        expression: 'Нож очень острый',
        transcription: 'ðə naɪf s ˈvɛri ʃɑːp',
        translation: 'The knife is very sharp',
      },
      {
        id: 15,
        expression: 'Какое элегантное платье!',
        transcription: 'wɒt ən ˈɛlɪgənt drɛs!',
        translation: 'What an elegant dress!',
      },
      {
        id: 16,
        expression: 'Он хранит свои деньги в секретном месте',
        transcription: 'hi kiːps ɪz ˈmʌni ɪn ə ˈsiːkrɪt pleɪs',
        translation: 'He keeps his money in a secret place',
      },
      {
        id: 17,
        expression: 'Она обычно носит очень элегантную одежду',
        transcription: 'ʃi ˈjuːʒʊəli weəz ˈvɛri ˈɛlɪgənt kləʊðz',
        translation: 'She usually wares very elegant clothes',
      },
      {
        id: 18,
        expression: 'Тебе нужно быть более реалистичным',
        transcription: 'jʊ niːd tə bi mɔː rɪəˈlɪstɪk',
        translation: 'You need to be more realistic',
      },
      {
        id: 19,
        expression: 'Ты такой щедрый',
        transcription: 'jʊə səʊ ˈʤɛnərəs',
        translation: "You're so generous",
      },
      {
        id: 20,
        expression: 'Какой ужасный запах!',
        transcription: 'wɒt ən ˈɔːfʊl smɛl!',
        translation: 'What an awful smell!',
      },
      {
        id: 21,
        expression: 'Он очень опытный работник',
        transcription: 'hiːz ə ˈvɛri ɪksˈpɪərɪənst (ˈskɪlf(ə)l) ˈwɜːkə',
        translation: "He's a very experienced (skillful) worker",
      },
      {
        id: 22,
        expression: 'Завтра будет дождливо',
        transcription: 'ˈɪtl bi ˈreɪni təˈmɒrəʊ',
        translation: "It'll be rainy tomorrow",
      },
      {
        id: 23,
        expression: 'Это правда!',
        transcription: 'ðæts truː! / ɪts truː!',
        translation: "That's true! / It's true!",
      },
      {
        id: 24,
        expression: 'Я успел на последний автобус',
        transcription: 'aɪ kɔːt ðə lɑːst bʌs',
        translation: 'I caught the last bus',
      },
      {
        id: 25,
        expression: 'Яблоко спелое?',
        transcription: 'ɪz ði ˈæpl raɪp?',
        translation: 'Is the apple ripe?',
      },
      {
        id: 26,
        expression: 'Я хочу быть реалистом',
        transcription: 'aɪ wɒnt tə bi rɪəˈlɪstɪk / aɪ wɒnt tə bi ə ˈrɪəlɪst',
        translation: 'I want to be realistic / I want to be a realist',
      },
      {
        id: 27,
        expression: 'Я нахожу это здание очень уродливым',
        transcription: 'aɪ faɪnd ðɪs ˈbɪldɪŋ ˈvɛri ˈʌgli',
        translation: 'I find this building very ugly',
      },
      {
        id: 28,
        expression: 'Эта информация осталась секретной',
        transcription: 'ðɪs ˌɪnfəˈmeɪʃən rɪˈmeɪnd ˈsiːkrɪt',
        translation: 'This information remained secret',
      },
      {
        id: 29,
        expression: 'Стол круглый',
        transcription: 'ðə ˈteɪbl z raʊnd',
        translation: 'The table is round',
      },
      {
        id: 30,
        expression: 'Она чувствует себя больной',
        transcription: 'ʃi fiːlz sɪk',
        translation: 'She feels sick',
      },
      {
        id: 31,
        expression: 'Это вполне рациональное решение',
        transcription: 'ɪts kwaɪt ə ˈræʃənl dɪˈsɪʒən',
        translation: "It's quite a rational decision",
      },
      {
        id: 32,
        expression: 'Это великолепная идея',
        transcription: 'ɪts ə ˈsplɛndɪd aɪˈdɪə',
        translation: "It's a splendid idea",
      },
      {
        id: 33,
        expression: 'Почему он такой нетерпеливый?',
        transcription: 'waɪ z hi səʊ ɪmˈpeɪʃənt?',
        translation: 'Why is he so impatient?',
      },
      {
        id: 34,
        expression: 'У нее голубые глаза и светлые волосы',
        transcription: 'ʃi həz bluː aɪz ənd blɒnd heə',
        translation: 'She has blue eyes and blond hair',
      },
      {
        id: 35,
        expression: 'Какое уродливое здание!',
        transcription: 'wɒt ən ˈʌgli ˈbɪldɪŋ!',
        translation: 'What an ugly building!',
      },
      {
        id: 36,
        expression: 'Нож недостаточно острый',
        transcription: 'ðə naɪf ˈɪznt ʃɑːp ɪˈnʌf',
        translation: "The knife isn't sharp enough",
      },
      {
        id: 37,
        expression: 'Она просто маленькая девочка',
        transcription: 'ʃiːz ʤəst ə ˈlɪtl gɜːl',
        translation: "She's just a little girl",
      },
      {
        id: 38,
        expression: 'Плод спелый',
        transcription: 'ðə fruːt s raɪp',
        translation: 'The fruit is ripe',
      },
      {
        id: 39,
        expression: 'Я так рад тебя видеть',
        transcription: 'aɪm səʊ glæd tə siː juː',
        translation: "I'm so glad to see you",
      },
      {
        id: 40,
        expression: 'Небо голубое',
        transcription: 'ðə skaɪ z bluː',
        translation: 'The sky is blue',
      },
      {
        id: 41,
        expression: 'Я не знаю, правда это или ложь',
        transcription: 'aɪ dəʊnt nəʊ ˈwɛðər ɪts truː ɔː fɔːls',
        translation: "I don't know whether it's true or false",
      },
      {
        id: 42,
        expression: 'Это одна из главных социальных проблем',
        transcription: 'ɪts wʌn əv ðə meɪn ˈsəʊʃəl ˈprɒbləmz',
        translation: "It's one of the main social problems",
      },
      {
        id: 43,
        expression: 'Я думаю, что есть простое решение этой проблемы',
        transcription: 'aɪ θɪŋk ðət ðə z ə ˈsɪmpl səˈluːʃən tə ðɪs ˈprɒbləm',
        translation: 'I think that there is a simple solution to this problem',
      },
      {
        id: 44,
        expression: 'Мне кажется, что существует простое решение этой проблемы',
        transcription:
          'ɪt siːmz tə mi ðət ə ˈsɪmpl səˈluːʃən tə ðɪs ˈprɒbləm ɪgˈzɪsts',
        translation:
          'It seems to me that a simple solution to this problem exists',
      },
      {
        id: 45,
        expression: 'Это общеизвестный факт, что Земля круглая',
        transcription: 'ɪts ə wɛl-nəʊn fækt ðət ði ɜːθ s raʊnd',
        translation: "It's a well-known fact that the Earth is round",
      },
      {
        id: 46,
        expression:
          'У меня есть великолепная возможность попрактиковать свой английский здесь',
        transcription:
          'aɪ həv ə ˈsplɛndɪd ˌɒpəˈtjuːnɪti tə ˈpræktɪs maɪ ˈɪŋglɪʃ hɪə',
        translation:
          'I have a splendid opportunity to practise my English here',
      },
      {
        id: 47,
        expression: 'Она устала делать одни и те же вещи снова и снова',
        transcription: 'ʃiːz ˈtaɪəd əv ˈdu(ː)ɪŋ ðə seɪm θɪŋz əˈgɛn ənd əˈgɛn',
        translation: "She's tired of doing the same things again and again",
      },
      {
        id: 48,
        expression: 'Я хочу сказать тебе, что еда там была восхитительной',
        transcription: 'aɪ wɒnt tə tɛl jʊ ðət ðə fuːd wəz dɪˈlɪʃəs ðeə',
        translation: 'I want to tell you that the food was delicious there',
      },
      {
        id: 49,
        expression: 'Он болен неделю',
        transcription: 'hiːz biːn sɪk fər ə wiːk',
        translation: "He's been sick for a week",
      },
      {
        id: 50,
        expression: 'Ты очень покладистый (легкий на подъем) человек',
        transcription: 'jʊə ˈvɛri ˈiːzɪˌgəʊɪŋ ˈpɜːsn',
        translation: "You're very easygoing person",
      },
      {
        id: 51,
        expression: 'Большинство моих друзей очень покладисты',
        transcription: 'ðə məʊst əv maɪ frɛndz ə ˈvɛri ˈiːzɪˌgəʊɪŋ',
        translation: 'The most of my friends are very easygoing',
      },
      {
        id: 52,
        expression: 'Это очень медленный химический процесс',
        transcription: 'ɪts ə ˈvɛri sləʊ ˈkɛmɪkəl ˈprəʊsɛs',
        translation: "It's a very slow chemical process",
      },
      {
        id: 53,
        expression: 'Я хотел бы отметить следующее',
        transcription: 'aɪd laɪk tə pɔɪnt aʊt ðə ˈfɒləʊɪŋ',
        translation: "I'd like to point out the following",
      },
      {
        id: 54,
        expression: 'Позволь мне сказать тебе следующую вещь',
        transcription: 'lɛt mi tɛl jʊ ðə ˈfɒləʊɪŋ θɪŋ',
        translation: 'Let me tell you the following thing',
      },
      {
        id: 55,
        expression: 'Он слышал это в ежедневных новостях',
        transcription: 'hi hɑːt ɪt ɪn ˈdeɪli njuːz',
        translation: 'He heart it in daily news',
      },
      {
        id: 56,
        expression: 'Сейчас так жарко',
        transcription: 'ɪts səʊ hɒt naʊ',
        translation: "It's so hot now",
      },
      {
        id: 57,
        expression: 'На улице так холодно',
        transcription: 'ɪts səʊ kəʊld ˌaʊtˈsaɪd',
        translation: "It's so cold outside",
      },
      {
        id: 58,
        expression: 'Вчера было так жарко',
        transcription: 'ɪt wəz səʊ hɒt ˈjɛstədeɪ',
        translation: 'It was so hot yesterday',
      },
      {
        id: 59,
        expression: 'Он такой же высокий, как и он',
        transcription: 'hiːz əz tɔːl əz hɪm',
        translation: "He's as tall as him",
      },
      {
        id: 60,
        expression: 'Она не такая высокая, как она',
        transcription: 'ʃi ˈɪznt əz tɔːl əz hɜː',
        translation: "She isn't as tall as her",
      },
      {
        id: 61,
        expression: 'Он так же хорош в теннисе, как и он',
        transcription: 'hiːz əz gʊd ət ˈtɛnɪs əz hɪm',
        translation: "He's as good at tennis as him",
      },
      {
        id: 62,
        expression: 'Я полон решимости сделать это',
        transcription: 'aɪm dɪˈtɜːmɪnd tə dʊ ɪt',
        translation: "I'm determined to do it",
      },
      {
        id: 63,
        expression: 'Я полон решимости купить эту вещь',
        transcription: 'aɪm dɪˈtɜːmɪnd tə baɪ ðɪs θɪŋ',
        translation: "I'm determined to buy this thing",
      },
      {
        id: 64,
        expression: 'Я полон решимости найти решение этой проблемы',
        transcription: 'aɪm dɪˈtɜːmɪnd tə faɪnd ə səˈluːʃən tə ðɪs ˈprɒbləm',
        translation: "I'm determined to find a solution to this problem",
      },
      {
        id: 65,
        expression: 'Он более слабый мальчик',
        transcription: 'hiːz ə ˈwiːkə bɔɪ',
        translation: "He's a weaker boy",
      },
      {
        id: 66,
        expression: 'Он слабый мальчик',
        transcription: 'hiːz wiːk bɔɪ',
        translation: "He's weak boy",
      },
      {
        id: 67,
        expression: 'Он здоровый мальчик',
        transcription: 'hiːz ə ˈhɛlθi bɔɪ',
        translation: "He's a healthy boy",
      },
      {
        id: 68,
        expression: 'На улице холодно?',
        transcription: 'ɪz ɪt kəʊld ˌaʊtˈsaɪd?',
        translation: 'Is it cold outside?',
      },
      {
        id: 69,
        expression: 'На улице тепло?',
        transcription: 'ɪz ɪt wɔːm ˌaʊtˈsaɪd?',
        translation: 'Is it warm outside?',
      },
      {
        id: 70,
        expression: 'На улице очень холодно',
        transcription: 'ɪts ˈvɛri kəʊld ˌaʊtˈsaɪd',
        translation: "It's very cold outside",
      },
      {
        id: 71,
        expression: 'Это химический элемент',
        transcription: 'ɪts ə ˈkɛmɪkəl ˈɛlɪmənt',
        translation: "It's a chemical element",
      },
      {
        id: 72,
        expression: 'Это основной элемент',
        transcription: 'ɪts ðə meɪn ˈɛlɪmənt',
        translation: "It's the main element",
      },
      {
        id: 73,
        expression: 'Это простой элемент',
        transcription: 'ɪts ə ˈsɪmpl ˈɛlɪmənt',
        translation: "It's a simple element",
      },
      {
        id: 74,
        expression: 'Это ежедневная газета',
        transcription: 'ɪts ə ˈdeɪli ˈnjuːzˌpeɪpə',
        translation: "It's a daily newspaper",
      },
      {
        id: 75,
        expression: 'Это еженедельная газета',
        transcription: 'ɪts ə ˈwiːkli ˈnjuːzˌpeɪpə',
        translation: "It's a weekly newspaper",
      },
      {
        id: 76,
        expression: 'Это ежемесячная газета',
        transcription: 'ɪts ə ˈmʌnθli ˈnjuːzˌpeɪpə',
        translation: "It's a monthly newspaper",
      },
      {
        id: 77,
        expression: 'У тебя есть какое-либо свободное время?',
        transcription: 'dʊ jʊ həv ˈɛni speə taɪm?',
        translation: 'Do you have any spare time?',
      },
      {
        id: 78,
        expression: 'У тебя есть свободное время?',
        transcription: 'dʊ jʊ həv friː taɪm?',
        translation: 'Do you have free time?',
      },
      {
        id: 79,
        expression: 'У меня нет никакого свободного времени',
        transcription: 'aɪ həv nəʊ speə taɪm',
        translation: 'I have no spare time',
      },
      {
        id: 80,
        expression: 'Я вижу, что он умный парень',
        transcription: 'aɪ siː ðət hiːz ən ɪnˈtɛlɪʤənt gaɪ',
        translation: "I see that he's an intelligent guy",
      },
      {
        id: 81,
        expression: 'Я знаю, что она очень умная девочка',
        transcription: 'aɪ nəʊ ðət ʃiːz ə ˈvɛri smɑːt gɜːl',
        translation: "I know that she's a very smart girl",
      },
      {
        id: 82,
        expression: 'Я вижу, что он очень образованный человек',
        transcription: 'aɪ siː ðət hiːz ə ˈvɛri ˈɛʤu(ː)keɪtɪd ˈpɜːsn',
        translation: "I see that he's a very educated person",
      },
      {
        id: 83,
        expression: 'Тот мальчик выше этого',
        transcription: 'ðæt bɔɪ z ˈtɔːlə ðən ðɪs wʌn',
        translation: 'That boy is taller than this one',
      },
      {
        id: 84,
        expression: 'Тот мальчик моложе этого',
        transcription: 'ðæt bɔɪ z ˈjʌŋə ðən ðɪs wʌn',
        translation: 'That boy is younger than this one',
      },
      {
        id: 85,
        expression: 'Этот мальчик старше того',
        transcription: 'ðɪs bɔɪ z ˈəʊldə ðən ðət wʌn',
        translation: 'This boy is older than that one',
      },
      {
        id: 86,
        expression: 'Это самый рациональный способ решения этой проблемы',
        transcription: 'ɪts ðə məʊst ˈræʃənl weɪ əv ˈsɒlvɪŋ ðɪs ˈprɒbləm',
        translation: "It's the most rational way of solving this problem",
      },
      {
        id: 87,
        expression: 'Ему действительно надоела эта система образования',
        transcription: 'hiːz ˈrɪəli bɔːd wɪð ðɪs ˈsɪstɪm əv ˌɛdju(ː)ˈkeɪʃən',
        translation: "He's really bored with this system of education",
      },
      {
        id: 88,
        expression: 'Он самый щедрый человек, которого я когда-либо встречал',
        transcription: 'hiːz ðə məʊst ˈʤɛnərəs ˈpɜːsn aɪv ˈɛvə mɛt',
        translation: "He's the most generous person I've ever met",
      },
      {
        id: 89,
        expression: 'Он жил в Великобритании, когда он был маленьким',
        transcription: 'hi lɪvd ɪn ðə juː-keɪ wɛn hi wəz ˈlɪtl',
        translation: 'He lived in the UK when he was little',
      },
      {
        id: 90,
        expression: 'Я полон решимости продолжать делать это',
        transcription: 'aɪm dɪˈtɜːmɪnd tə kənˈtɪnju(ː) ˈdu(ː)ɪŋ ɪt',
        translation: "I'm determined to continue doing it",
      },
      {
        id: 91,
        expression: 'Он становился все более и более нетерпеливым',
        transcription: 'hi wəz ˈgɛtɪŋ (bɪˈkʌmɪŋ) mɔːr ənd mɔːr ɪmˈpeɪʃənt',
        translation: 'He was getting (becoming) more and more impatient',
      },
      {
        id: 92,
        expression: 'Он знает, что это его последний шанс',
        transcription: 'hi nəʊz ðət ɪts ɪz lɑːst ʧɑːns',
        translation: "He knows that it's his last chance",
      },
      {
        id: 93,
        expression: 'Я хочу сказать, что еда вкусная',
        transcription: 'aɪ wɒnt tə seɪ ðət ðə fuːd z dɪˈlɪʃəs',
        translation: 'I want to say that the food is delicious',
      },
      {
        id: 94,
        expression: 'По моему мнению, это лучшее решение этой проблемы',
        transcription: 'ɪn maɪ əˈpɪnjən, ɪts ðə bɛst səˈluːʃən tə ðɪs ˈprɒbləm',
        translation: "In my opinion, it's the best solution to this problem",
      },
      {
        id: 95,
        expression: 'Ей действительно надоела эта ежедневная рутина',
        transcription: 'hiːz ˈrɪəli bɔːd wɪð ðɪs ˈdeɪli ruːˈtiːn',
        translation: "He's really bored with this daily routine",
      },
      {
        id: 96,
        expression: 'У меня недостаточно свободного времени на это',
        transcription: 'aɪ dəʊnt həv ɪˈnʌf speə taɪm fər ɪt',
        translation: "I don't have enough spare time for it",
      },
      {
        id: 97,
        expression: 'Этот человек слабее того',
        transcription: 'ðɪs mæn z ˈwiːkə ðən ðət wʌn',
        translation: 'This man is weaker than that one',
      },
      {
        id: 98,
        expression: 'Это один из самых жарких летних дней',
        transcription: 'ɪts wʌn əv ðə ˈhɒtɪst ˈsʌmə deɪ',
        translation: "It's one of the hottest summer day",
      },
      {
        id: 99,
        expression: 'На мой взгляд, он лучший из лучших',
        transcription: 'ɪn maɪ vjuː, hiːz ðə bɛst əv ðə bɛst',
        translation: "In my view, he's the best of the best",
      },
      {
        id: 100,
        expression: 'Насколько холодно сегодня?',
        transcription: 'haʊ kəʊld z ɪt təˈdeɪ?',
        translation: 'How cold is it today?',
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Закрепление пройденного материала</h3>
</div>`,
    },
  ],
];
