export const wordsLesson249 = [
  [
    {
      id: 1,
      word: 'bone',
      transcription: 'bəʊn',
      translation: 'кость',
    },
    {
      id: 2,
      word: 'clothing',
      transcription: 'ˈkləʊðɪŋ',
      translation: 'одежда',
    },
    {
      id: 3,
      word: 'coast',
      transcription: 'kəʊst',
      translation: 'берег',
    },
    {
      id: 4,
      word: 'congress',
      transcription: 'ˈkɒŋɡrɛs',
      translation: 'конгресс',
    },
    {
      id: 5,
      word: 'convince',
      transcription: 'kənˈvɪns',
      translation: 'убеждать',
    },
    {
      id: 6,
      word: 'cough',
      transcription: 'kɒf',
      translation: 'кашель, кашлять',
    },
    {
      id: 7,
      word: 'development',
      transcription: 'dɪˈvɛləpmənt',
      translation: 'развитие',
    },
    {
      id: 8,
      word: 'exclaim',
      transcription: 'ɪksˈkleɪm',
      translation: 'восклицать',
    },
    {
      id: 9,
      word: 'federal',
      transcription: 'ˈfɛdərəl',
      translation: 'федеральный',
    },
    {
      id: 10,
      word: 'formula',
      transcription: 'ˈfɔːmjʊlə',
      translation: 'формула',
    },
    {
      id: 11,
      word: 'gonna',
      transcription: 'ˈɡɒnə',
      translation: 'собираться',
    },
    {
      id: 12,
      word: 'indicate',
      transcription: 'ˈɪndɪkeɪt',
      translation: 'указывать',
    },
    {
      id: 13,
      word: 'lean',
      transcription: 'liːn',
      translation: 'наклоняться',
    },
    {
      id: 14,
      word: 'omelette',
      transcription: 'ˈɒmlɪt',
      translation: 'омлет',
    },
    {
      id: 15,
      word: 'promotion',
      transcription: 'prəˈməʊʃᵊn',
      translation: 'продвижение',
    },
    {
      id: 16,
      word: 'recognize',
      transcription: 'ˈrɛkəɡnaɪz',
      translation: 'узнавать',
    },
    {
      id: 17,
      word: 'refugee',
      transcription: 'ˌrɛfjʊˈʤiː',
      translation: 'беженец',
    },
    {
      id: 18,
      word: 'semester',
      transcription: 'sɪˈmɛstə',
      translation: 'семестр',
    },
    {
      id: 19,
      word: 'signal',
      transcription: 'ˈsɪɡnl',
      translation: 'сигналить, сигнал',
    },
    {
      id: 20,
      word: 'transform',
      transcription: 'trænsˈfɔːm',
      translation: 'превращаться',
    },
    {
      id: 21,
      word: 'vomit',
      transcription: 'ˈvɒmɪt',
      translation: 'рвать, тошнить',
    },
    {
      id: 22,
      word: 'wood',
      transcription: 'wʊd',
      translation: 'дерево',
    },
  ],
];
