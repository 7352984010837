export const wordsLesson270 = [
  [
    {
      id: 1,
      word: 'fall down',
      transcription: 'fɔːl daʊn',
      translation: 'упасть',
    },
    {
      id: 2,
      word: 'bear with',
      transcription: 'beə wɪð',
      translation: 'потерпеть',
    },
    {
      id: 3,
      word: 'help out',
      transcription: 'hɛlp aʊt',
      translation: 'выручить',
    },
    {
      id: 4,
      word: 'wash up',
      transcription: 'wɒʃ ʌp',
      translation: 'мыть посуду',
    },
    {
      id: 5,
      word: 'slip up',
      transcription: 'slɪp ʌp',
      translation: 'ошибаться',
    },
    {
      id: 6,
      word: 'branch out',
      transcription: 'brɑːnʧ aʊt',
      translation: 'разветвляться',
    },
    {
      id: 7,
      word: 'send out',
      transcription: 'sɛnd aʊt',
      translation: 'рассылать',
    },
    {
      id: 8,
      word: 'balloon',
      transcription: 'bəˈluːn',
      translation: 'воздушный шар',
    },
    {
      id: 9,
      word: 'CV',
      transcription: 'siː-viː',
      translation: 'резюме',
    },
    {
      id: 10,
      word: 'electronics',
      transcription: 'ɪlɛkˈtrɒnɪks',
      translation: 'электроника',
    },
    {
      id: 11,
      word: 'fake',
      transcription: 'feɪk',
      translation: 'подделка',
    },
    {
      id: 12,
      word: 'grab',
      transcription: 'ɡræb',
      translation: 'схватить',
    },
    {
      id: 13,
      word: 'pavement',
      transcription: 'ˈpeɪvmənt',
      translation: 'тротуар',
    },
    {
      id: 14,
      word: 'prepayment',
      transcription: 'priːˈpeɪmənt',
      translation: 'предоплата',
    },
    {
      id: 15,
      word: 'rotten',
      transcription: 'ˈrɒtn',
      translation: 'гнилой',
    },
    {
      id: 16,
      word: 'scent',
      transcription: 'sɛnt',
      translation: 'запах',
    },
    {
      id: 17,
      word: 'straightaway',
      transcription: 'ˈstreɪtəweɪ',
      translation: 'немедленно, сразу',
    },
    {
      id: 18,
      word: 'stumble',
      transcription: 'ˈstʌmbᵊl',
      translation: 'спотыкаться',
    },
    {
      id: 19,
      word: 'unfamiliar',
      transcription: 'ˌʌnfəˈmɪliə',
      translation: 'незнакомый',
    },
    {
      id: 20,
      word: 'within',
      transcription: 'wɪˈðɪn',
      translation: 'в течение',
    },
  ],
];
