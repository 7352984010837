export const contentLesson83 = [
  [
    {
      title: "Урок 83. Past Simple. Question",
    },
    [
      {
        id: 1,
        expression: "Он пришел сюда очень быстро?",
        transcription: "dɪd hiː kʌm hɪə ˈvɛri ˈkwɪkli?",
        translation: "Did he come here very quickly?",
      },
      {
        id: 2,
        expression: "Ты работал в той компании?",
        transcription: "dɪd juː wɜːk ɪn ðæt ˈkʌmpəni?",
        translation: "Did you work in that company?",
      },
      {
        id: 3,
        expression: "Это был удивительный факт?",
        transcription: "wɒz ɪt ə səˈpraɪzɪŋ fækt?",
        translation: "Was it a surprising fact?",
      },
      {
        id: 4,
        expression: "Они были дома?",
        transcription: "wɜː ðeɪ æt həʊm?",
        translation: "Were they at home?",
      },
      {
        id: 5,
        expression: "Ты говорил с ней прошлой ночью?",
        transcription: "dɪd juː tɔːk tuː hɜː lɑːst naɪt?",
        translation: "Did you talk to her last night?",
      },
      {
        id: 6,
        expression: "Я все правильно сказал?",
        transcription: "dɪd aɪ seɪ ˈɛvrɪθɪŋ kəˈrɛktli?",
        translation: "Did I say everything correctly?",
      },
      {
        id: 7,
        expression: "Ты был в Соединенных Штатах в прошлом году?",
        transcription: "wɜː juː ɪn ðə jʊˈnaɪtɪd steɪts lɑːst jɪə?",
        translation: "Were you in the United States last year?",
      },
      {
        id: 8,
        expression: "Она делала работу по дому?",
        transcription: "dɪd ʃiː duː haʊs wɜːk?",
        translation: "Did she do housework?",
      },
      {
        id: 9,
        expression: "У него были серьезные финансовые проблемы?",
        transcription: "dɪd hiː hæv ˈsɪərɪəs faɪˈnænʃəl ˈprɒbləmz?",
        translation: "Did he have serious financial problems?",
      },
      {
        id: 10,
        expression: "Вы ходили вместе гулять?",
        transcription: "dɪd juː gəʊ fɔːr ə wɔːk təˈgɛðə?",
        translation: "Did you go for a walk together?",
      },
      {
        id: 11,
        expression: "Это был важный фактор?",
        transcription: "wɒz ɪt ən ɪmˈpɔːtənt ˈfæktə?",
        translation: "Was it an important factor?",
      },
      {
        id: 12,
        expression: "Тебе понравился фильм?",
        transcription: "dɪd juː laɪk ðə ˈmuːvi?",
        translation: "Did you like the movie?",
      },
      {
        id: 13,
        expression: "Ты покормил собаку?",
        transcription: "dɪd juː fiːd ðə dɒg?",
        translation: "Did you feed the dog?",
      },
      {
        id: 14,
        expression: "Их совет был полезен?",
        transcription: "wɒz ðeər ədˈvaɪs ˈjuːsfʊl?",
        translation: "Was their advice useful?",
      },
      {
        id: 15,
        expression: "Дверь была закрыта?",
        transcription: "wɒz ðə dɔː kləʊzd?",
        translation: "Was the door closed?",
      },
      {
        id: 16,
        expression: "Ты посещал Англию в прошлом году?",
        transcription: "dɪd juː ˈvɪzɪt ˈɪŋglənd lɑːst jɪə?",
        translation: "Did you visit England last year?",
      },
      {
        id: 17,
        expression: "Ты установил эту программу?",
        transcription: "dɪd juː ɪnˈstɔːl ðɪs ˈprəʊgræm?",
        translation: "Did you install this program?",
      },
      {
        id: 18,
        expression: "Тебе понравился отель?",
        transcription: "dɪd juː laɪk ðə həʊˈtɛl?",
        translation: "Did you like the hotel?",
      },
      {
        id: 19,
        expression: "Уроки были бесплатными?",
        transcription: "wɜː ðə ˈlɛsnz friː?",
        translation: "Were the lessons free?",
      },
      {
        id: 20,
        expression: "Ты обожал это видео?",
        transcription: "dɪd juː ɪnˈʤɔɪ ðɪs ˈvɪdɪəʊ?",
        translation: "Did you enjoy this video?",
      },
      {
        id: 21,
        expression: "Ты отменил эту встречу?",
        transcription: "dɪd juː ˈkænsəl ðɪs ˈmiːtɪŋ?",
        translation: "Did you cancel this meeting?",
      },
      {
        id: 22,
        expression: "Отель был достаточно хороший?",
        transcription: "wɒz ðə həʊˈtɛl gʊd ɪˈnʌf?",
        translation: "Was the hotel good enough?",
      },
      {
        id: 23,
        expression: "Фильм был действительно смешным?",
        transcription: "wɒz ðə ˈmuːvi ˈrɪəli ˈfʌni?",
        translation: "Was the movie really funny?",
      },
      {
        id: 24,
        expression: "Ты сделал работу по дому?",
        transcription: "dɪd juː duː ˈhaʊswɜːk?",
        translation: "Did you do housework?",
      },
      {
        id: 25,
        expression: "Тебе понравилась песня?",
        transcription: "dɪd juː laɪk ðə sɒŋ?",
        translation: "Did you like the song?",
      },
      {
        id: 26,
        expression: "Это был идеальный дом?",
        transcription: "wɒz ɪt ən aɪˈdɪəl haʊs?",
        translation: "Was it an ideal house?",
      },
      {
        id: 27,
        expression: "Это был долгий разговор?",
        transcription: "wɒz ɪt ə lɒŋ ˌkɒnvəˈseɪʃən?",
        translation: "Was it a long conversation?",
      },
      {
        id: 28,
        expression: "Ты покормил кота?",
        transcription: "dɪd juː fiːd ðə kæt?",
        translation: "Did you feed the cat?",
      },
      {
        id: 29,
        expression: "У него была вечеринка?",
        transcription: "dɪd hiː hæv ə ˈpɑːti?",
        translation: "Did he have a party?",
      },
      {
        id: 30,
        expression: "Дверь была открыта?",
        transcription: "wɒz ðə dɔːr ˈəʊpən?",
        translation: "Was the door open?",
      },
      {
        id: 31,
        expression: "Ты ходил плавать?",
        transcription: "dɪd juː gəʊ ˈswɪmɪŋ?",
        translation: "Did you go swimming?",
      },
      {
        id: 32,
        expression: "Ты был за границей в прошлом месяце?",
        transcription: "wɜː juː əˈbrɔːd lɑːst mʌnθ?",
        translation: "Were you abroad last month?",
      },
      {
        id: 33,
        expression: "Тест был довольно легким?",
        transcription: "wɒz ðə tɛst kwaɪt ˈiːzi?",
        translation: "Was the test quite easy?",
      },
      {
        id: 34,
        expression: "Он отменил урок?",
        transcription: "dɪd hiː ˈkænsəl ðə ˈlɛsn?",
        translation: "Did he cancel the lesson?",
      },
      {
        id: 35,
        expression: "Ты что-нибудь купил?",
        transcription: "dɪd juː baɪ ˈɛnɪθɪŋ?",
        translation: "Did you buy anything?",
      },
      {
        id: 36,
        expression: "Ты обожал этот урок?",
        transcription: "dɪd juː ɪnˈʤɔɪ ðɪs ˈlɛsn?",
        translation: "Did you enjoy this lesson?",
      },
      {
        id: 37,
        expression: "Это был легкий тест?",
        transcription: "wɒz ɪt ən ˈiːzi tɛst?",
        translation: "Was it an easy test?",
      },
      {
        id: 38,
        expression: "Ты ездил в Соединенные Штаты в прошлом месяце?",
        transcription: "dɪd juː gəʊ tuː ðə jʊˈnaɪtɪd steɪts lɑːst mʌnθ?",
        translation: "Did you go to the United States last month?",
      },
      {
        id: 39,
        expression: "Тебе понравилось приложение?",
        transcription: "dɪd juː laɪk ði ˌæplɪˈkeɪʃ(ə)n?",
        translation: "Did you like the application?",
      },
      {
        id: 40,
        expression: "Ты ел в ресторане на прошлой неделе?",
        transcription: "dɪd juː iːt ɪn ə ˈrɛstrɒnt lɑːst wiːk?",
        translation: "Did you eat in a restaurant last week?",
      },
      {
        id: 41,
        expression: "Это было важное решение?",
        transcription: "wɒz ɪt ən ɪmˈpɔːtənt dɪˈsɪʒən?",
        translation: "Was it an important decision?",
      },
      {
        id: 42,
        expression: "Дети были дома?",
        transcription: "wɜː ðə ˈʧɪldrən æt həʊm?",
        translation: "Were the children at home?",
      },
      {
        id: 43,
        expression: "Это было действительно хорошее предложение?",
        transcription: "wɒz ɪt ˈrɪəli ə gʊd ˈɒfə?",
        translation: "Was it really a good offer?",
      },
      {
        id: 44,
        expression: "Ты с ним говорил?",
        transcription: "dɪd juː tɔːk tuː hɪm?",
        translation: "Did you talk to him?",
      },
      {
        id: 45,
        expression: "Ты нашел ответ на этот вопрос?",
        transcription: "dɪd juː faɪnd ən ˈɑːnsə tuː ðɪs ˈkwɛsʧən?",
        translation: "Did you find an answer to this question?",
      },
      {
        id: 46,
        expression: "Студенты были рады своим результатам?",
        transcription: "wɜː ðə ˈstjuːdənts ˈhæpi əˈbaʊt ðeə rɪˈzʌlts?",
        translation: "Were the students happy about their results?",
      },
      {
        id: 47,
        expression: "Ты старался больше не думать об этом?",
        transcription: "dɪd juː traɪ nɒt tuː θɪŋk əˈbaʊt ɪt?",
        translation: "Did you try not to think about it?",
      },
      {
        id: 48,
        expression:
          "Это для тебя было действительно привлекательное предложение?",
        transcription: "wɒz ɪt ˈrɪəli ən əˈtræktɪv ˈɒfə fɔː juː?",
        translation: "Was it really an attractive offer for you?",
      },
      {
        id: 49,
        expression: "Ты зарезервировал эту комнату для меня?",
        transcription: "dɪd juː rɪˈzɜːv ðɪs ruːm fɔː miː?",
        translation: "Did you reserve this room for me?",
      },
      {
        id: 50,
        expression: "Она была действительно шокирована?",
        transcription: "wɒz ʃiː ˈrɪəli ʃɒkt?",
        translation: "Was she really shocked?",
      },
      {
        id: 51,
        expression: "Ты был удовлетворен этим результатом?",
        transcription: "wɜː juː ˈsætɪsfaɪd wɪð ðɪs rɪˈzʌlt?",
        translation: "Were you satisfied with this result?",
      },
      {
        id: 52,
        expression: "Ты заметил это в конце разговора?",
        transcription: "dɪd juː ˈnəʊtɪs ɪt æt ði ɛnd ɒv ðə ˌkɒnvəˈseɪʃən?",
        translation: "Did you notice it at the end of the conversation?",
      },
      {
        id: 53,
        expression: "Это было привлекательное предложение для тебя?",
        transcription: "wɒz ɪt ən əˈtræktɪv ˈɒfə fɔː juː?",
        translation: "Was it an attractive offer for you?",
      },
      {
        id: 54,
        expression: "Вы хорошо провели время вместе?",
        transcription: "dɪd juː hæv ə gʊd taɪm təˈgɛðə?",
        translation: "Did you have a good time together?",
      },
      {
        id: 55,
        expression: "Предложение было привлекательным для тебя?",
        transcription: "wɒz ði ˈɒfər əˈtræktɪv fɔː juː?",
        translation: "Was the offer attractive for you?",
      },
      {
        id: 56,
        expression: "Ты обедал в ресторане?",
        transcription: "dɪd juː hæv lʌnʧ ɪn ə ˈrɛstrɒnt?",
        translation: "Did you have lunch in a restaurant?",
      },
      {
        id: 57,
        expression: "Ты забронировал билеты?",
        transcription: "dɪd juː bʊk ðə ˈtɪkɪts?",
        translation: "Did you book the tickets?",
      },
      {
        id: 58,
        expression: "Ты зарезервировал эти места?",
        transcription: "dɪd juː rɪˈzɜːv ðiːz siːts?",
        translation: "Did you reserve these seats?",
      },
      {
        id: 59,
        expression: "Ты родился в США?",
        transcription: "wɜː juː bɔːn ɪn ðə juː.ɛs.?",
        translation: "Were you born in the U.S.?",
      },
      {
        id: 60,
        expression: "Ты зарезервировал столик?",
        transcription: "dɪd juː rɪˈzɜːv ə ˈteɪbl?",
        translation: "Did you reserve a table?",
      },
      {
        id: 61,
        expression: "Ты зарезервировал тот столик?",
        transcription: "dɪd juː rɪˈzɜːv ðæt ˈteɪbl?",
        translation: "Did you reserve that table?",
      },
      {
        id: 62,
        expression: "Он зарезервировал два столика?",
        transcription: "dɪd hiː rɪˈzɜːv tuː ˈteɪblz?",
        translation: "Did he reserve two tables?",
      },
      {
        id: 63,
        expression: "Она родилась в Англии?",
        transcription: "wɒz ʃiː bɔːn ɪn ˈɪŋglənd?",
        translation: "Was she born in England?",
      },
      {
        id: 64,
        expression: "Он родился в Англии?",
        transcription: "wɒz hiː bɔːn ɪn ˈɪŋglənd?",
        translation: "Was he born in England?",
      },
      {
        id: 65,
        expression: "Ты родился в Соединенных Штатах?",
        transcription: "wɜː juː bɔːn ɪn ðə jʊˈnaɪtɪd steɪts?",
        translation: "Were you born in the United States?",
      },
      {
        id: 66,
        expression: "Он отдыхал вчера утром?",
        transcription: "dɪd hiː hæv ə rɛst ˈjɛstədeɪ ˈmɔːnɪŋ?",
        translation: "Did he have a rest yesterday morning?",
      },
      {
        id: 67,
        expression: "Он отдыхал на прошлой неделе?",
        transcription: "dɪd hiː hæv ə rɛst lɑːst wiːk?",
        translation: "Did he have a rest last week?",
      },
      {
        id: 68,
        expression: "Они отдыхали вчера?",
        transcription: "dɪd ðeɪ hæv ə rɛst ˈjɛstədeɪ?",
        translation: "Did they have a rest yesterday?",
      },
      {
        id: 69,
        expression: "Ты забронировал отель?",
        transcription: "dɪd juː bʊk ðə həʊˈtɛl?",
        translation: "Did you book the hotel?",
      },
      {
        id: 70,
        expression: "Ты забронировал тот отель?",
        transcription: "dɪd juː bʊk ðæt həʊˈtɛl?",
        translation: "Did you book that hotel?",
      },
      {
        id: 71,
        expression: "Он забронировал билет?",
        transcription: "dɪd hiː bʊk ðə ˈtɪkɪt?",
        translation: "Did he book the ticket?",
      },
      {
        id: 72,
        expression: "Ты понял это в конце?",
        transcription: "dɪd juː ˌʌndəˈstænd ɪt ɪn ði ɛnd?",
        translation: "Did you understand it in the end?",
      },
      {
        id: 73,
        expression: "Ты понял это в конце разговора?",
        transcription: "dɪd juː ˌʌndəˈstænd ɪt æt ði ɛnd ɒv ðə ˌkɒnvəˈseɪʃən?",
        translation: "Did you understand it at the end of the conversation?",
      },
      {
        id: 74,
        expression: "Ты сказал это в конце?",
        transcription: "dɪd juː seɪ ɪt ɪn ði ɛnd?",
        translation: "Did you say it in the end?",
      },
      {
        id: 75,
        expression: "Было действительно необходимо сделать это?",
        transcription: "wɒz ɪt ˈrɪəli ˈnɛsɪsəri tuː duː ɪt?",
        translation: "Was it really necessary to do it?",
      },
      {
        id: 76,
        expression: "Было необходимо сделать это?",
        transcription: "wɒz ɪt ˈnɛsɪsəri tuː duː ɪt?",
        translation: "Was it necessary to do it?",
      },
      {
        id: 77,
        expression: "Было важно сделать это?",
        transcription: "wɒz ɪt ɪmˈpɔːtənt tuː duː ɪt?",
        translation: "Was it important to do it?",
      },
      {
        id: 78,
        expression: "Это случилось в конце фильма?",
        transcription: "dɪd ɪt ˈhæpən æt ði ɛnd ɒv ðə ˈmuːvi?",
        translation: "Did it happen at the end of the movie?",
      },
      {
        id: 79,
        expression: "Это случилось в конце концов?",
        transcription: "dɪd ɪt ˈhæpən ɪn ði ɛnd?",
        translation: "Did it happen in the end?",
      },
      {
        id: 80,
        expression: "Это случилось в конце встречи?",
        transcription: "dɪd ɪt ˈhæpən æt ði ɛnd ɒv ðə ˈmiːtɪŋ?",
        translation: "Did it happen at the end of the meeting?",
      },
      {
        id: 81,
        expression: "Ты решил остаться дома?",
        transcription: "dɪd juː dɪˈsaɪd tuː steɪ æt həʊm?",
        translation: "Did you decide to stay at home?",
      },
      {
        id: 82,
        expression: "Ты решил остаться там?",
        transcription: "dɪd juː dɪˈsaɪd tuː steɪ ðeə?",
        translation: "Did you decide to stay there?",
      },
      {
        id: 83,
        expression: "Ты решил остаться на работе?",
        transcription: "dɪd juː dɪˈsaɪd tuː steɪ æt wɜːk?",
        translation: "Did you decide to stay at work?",
      },
      {
        id: 84,
        expression: "Он пришел в конце концов?",
        transcription: "dɪd hiː kʌm ɪn ði ɛnd?",
        translation: "Did he come in the end?",
      },
      {
        id: 85,
        expression: "Это случилось в конце этой встречи?",
        transcription: "dɪd ɪt ˈhæpən æt ði ɛnd ɒv ðɪs ˈmiːtɪŋ?",
        translation: "Did it happen at the end of this meeting?",
      },
      {
        id: 86,
        expression: "Он родился в Соединенных Штатах?",
        transcription: "wɒz hiː bɔːn ɪn ðə jʊˈnaɪtɪd steɪts?",
        translation: "Was he born in the United States?",
      },
      {
        id: 87,
        expression: "Она родилась в Соединенных Штатах?",
        transcription: "wɒz ʃiː bɔːn ɪn ðə jʊˈnaɪtɪd steɪts?",
        translation: "Was she born in the United States?",
      },
      {
        id: 88,
        expression: "Ты самостоятельно забронировал ту квартиру?",
        transcription: "dɪd juː bʊk ðæt əˈpɑːtmənt baɪ jɔːˈsɛlf?",
        translation: "Did you book that apartment by yourself?",
      },
      {
        id: 89,
        expression: "Он услышал это в конце конференции?",
        transcription: "dɪd hiː hɪər ɪt æt ði ɛnd ɒv ðə ˈkɒnfərəns?",
        translation: "Did he hear it at the end of the conference?",
      },
      {
        id: 90,
        expression: "Он заранее забронировал столик?",
        transcription:
          "dɪd hiː rɪˈzɜːv ə ˈteɪbl ɪn ədˈvɑːns? / dɪd hiː rɪˈzɜːv ə ˈteɪbl ɪn bɪˈfɔːhænd?",
        translation:
          "Did he reserve a table in advance? / Did he reserve a table in beforehand?",
      },
      {
        id: 91,
        expression: "Они отлично провели время вместе?",
        transcription: "dɪd ðeɪ hæv ə greɪt taɪm təˈgɛðə?",
        translation: "Did they have a great time together?",
      },
      {
        id: 92,
        expression: "Новости были действительно шокирующими?",
        transcription: "wɒz ðə njuːz ˈrɪəli ˈʃɒkɪŋ?",
        translation: "Was the news really shocking?",
      },
      {
        id: 93,
        expression: "Ты заметил это в начале разговора?",
        transcription: "dɪd juː ˈnəʊtɪs ɪt æt ðə bɪˈgɪnɪŋ ɒv ðə ˌkɒnvəˈseɪʃən?",
        translation: "Did you notice it at the beginning of the conversation?",
      },
      {
        id: 94,
        expression: "Ты заранее забронировал эту квартиру?",
        transcription: "dɪd juː rɪˈzɜːv ðɪs əˈpɑːtmənt ɪn ədˈvɑːns?",
        translation: "Did you reserve this apartment in advance?",
      },
      {
        id: 95,
        expression: "Ты знал это в начале?",
        transcription: "dɪd juː nəʊ ɪt ɪn ðə bɪˈgɪnɪŋ?",
        translation: "Did you know it in the beginning?",
      },
      {
        id: 96,
        expression: "Ты решил не покупать это?",
        transcription: "dɪd juː dɪˈsaɪd nɒt tuː baɪ ɪt?",
        translation: "Did you decide not to buy it?",
      },
      {
        id: 97,
        expression: "Ты нашел ответ на свой вопрос?",
        transcription: "dɪd juː faɪnd ən ˈɑːnsə tuː jɔː ˈkwɛsʧən?",
        translation: "Did you find an answer to your question?",
      },
      {
        id: 98,
        expression: "Это был удивительный факт?",
        transcription: "wɒz ɪt ə səˈpraɪzɪŋ fækt?",
        translation: "Was it a surprising fact?",
      },
      {
        id: 99,
        expression: "Он был действительно удивлен?",
        transcription: "wɒz hiː ˈrɪəli səˈpraɪzd?",
        translation: "Was he really surprised?",
      },
      {
        id: 100,
        expression: "Это было действительно удивительно?",
        transcription: "wɒz ɪt ˈrɪəli səˈpraɪzɪŋ?",
        translation: "Was it really surprising?",
      },
      {
        id: 101,
        expression: "Ты забронировал рейс?",
        transcription: "dɪd juː bʊk ðə flaɪt?",
        translation: "Did you book the flight?",
      },
      {
        id: 102,
        expression: "Ты отдыхал?",
        transcription: "dɪd juː hæv ə rɛst?",
        translation: "Did you have a rest?",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Закрепление пройденного материала</h3>
</div>`,
    },
  ],
];
