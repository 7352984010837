export const wordsLesson102 = [
  [
    {
      id: 1,
      word: 'tired of',
      transcription: 'ˈtaɪəd ɒv',
      translation: 'устал от чего-то или кого-то',
    },
    {
      id: 2,
      word: 'excited about',
      transcription: 'ɪkˈsaɪtɪd əˈbaʊt',
      translation: 'в восторге от чего-то или кого-то',
    },
    {
      id: 3,
      word: 'crazy about / mad about',
      transcription: 'ˈkreɪzi əˈbaʊt / mæd əˈbaʊt',
      translation: 'без ума от чего-то или кого-то',
    },
    {
      id: 4,
      word: 'famous for',
      transcription: 'ˈfeɪməs fɔː',
      translation: 'известен чем-то',
    },
    {
      id: 5,
      word: 'happy for / happy about',
      transcription: 'ˈhæpi fɔː / ˈhæpi əˈbaʊt',
      translation: 'рад за кого-то / рад чему-то',
    },
    {
      id: 6,
      word: 'satisfied with',
      transcription: 'ˈsætɪsfaɪd wɪð',
      translation: 'удовлетворен чем-то',
    },
    {
      id: 7,
      word: 'disappointed about / with / by / at',
      transcription: 'ˌdɪsəˈpɔɪntɪd əˈbaʊt / wɪð / baɪ / æt',
      translation: 'разочарован чем-то',
    },
    {
      id: 8,
      word: 'typical of',
      transcription: 'ˈtɪpɪk(ə)l ɒv',
      translation: 'типично для чего-то или кого-то',
    },
    {
      id: 9,
      word: 'surprised by / at',
      transcription: 'səˈpraɪzd baɪ / æt',
      translation: 'удивлен чем-то или кем-то',
    },
    {
      id: 10,
      word: 'different from',
      transcription: 'ˈdɪfrənt frɒm',
      translation: 'отличный от чего-то или кого-то',
    },
    {
      id: 11,
      word: 'shocked by',
      transcription: 'ʃɒkt baɪ',
      translation: 'шокирован чем-то или кем-то',
    },
    {
      id: 12,
      word: 'baseball',
      transcription: 'ˈbeɪsbɔːl',
      translation: 'бейсбол',
    },
    {
      id: 13,
      word: 'bitterly',
      transcription: 'ˈbɪtəli',
      translation: 'горько',
    },
    {
      id: 14,
      word: 'castle',
      transcription: 'ˈkɑːsl',
      translation: 'замок',
    },
    {
      id: 15,
      word: 'death',
      transcription: 'dɛθ',
      translation: 'смерть',
    },
    {
      id: 16,
      word: 'differ',
      transcription: 'ˈdɪfə',
      translation: 'отличаться',
    },
    {
      id: 17,
      word: 'generation',
      transcription: 'ˌʤɛnəˈreɪʃən',
      translation: 'поколение',
    },
    {
      id: 18,
      word: 'mess',
      transcription: 'mɛs',
      translation: 'беспорядок',
    },
    {
      id: 19,
      word: 'nightclub',
      transcription: 'ˈnaɪtklʌb',
      translation: 'ночной клуб',
    },
    {
      id: 20,
      word: 'nightlife',
      transcription: 'ˈnaɪtlaɪf',
      translation: 'ночная жизнь',
    },
    {
      id: 21,
      word: 'numerous',
      transcription: 'ˈnjuːmərəs',
      translation: 'многочисленный',
    },
    {
      id: 22,
      word: 'obviously',
      transcription: 'ˈɒbvɪəsli',
      translation: 'очевидно',
    },
    {
      id: 23,
      word: 'purchase',
      transcription: 'ˈpɜːʧəs',
      translation: 'покупка, покупать',
    },
    {
      id: 24,
      word: 'sky',
      transcription: 'skaɪ',
      translation: 'небо',
    },
    {
      id: 25,
      word: 'skyscraper',
      transcription: 'ˈskaɪˌskreɪpə',
      translation: 'небоскреб',
    },
  ],
];
