export const contentLesson97 = [
  [
    {
      title: "Урок 97. 270 Verbs in Different Tenses. Part 5",
    },
    [
      {
        id: 1,
        expression: "Мне нужно закрыть шторы",
        transcription: "aɪ niːd tə kləʊs ðə ˈkɜːtnz",
        translation: "I need to close the curtains",
      },
      {
        id: 2,
        expression: "Он владеет этой компанией?",
        transcription: "dəz hi əʊn ðɪs ˈkʌmpəni?",
        translation: "Does he own this company?",
      },
      {
        id: 3,
        expression: "Он ее не поцеловал",
        transcription: "hi dɪdnt kɪs hɜː",
        translation: "He didn't kiss her",
      },
      {
        id: 4,
        expression: "Я спрятал это под подушку",
        transcription: "aɪ hɪd ɪt ˈʌndə ðə ˈpɪləʊ",
        translation: "I hid it under the pillow",
      },
      {
        id: 5,
        expression: "Они внезапно напали на своего врага",
        transcription: "ðeɪ əˈtækt ðeər ˈɛnɪmi ˈsʌdnli",
        translation: "They attacked their enemy suddenly",
      },
      {
        id: 6,
        expression: "Она спрятала письмо",
        transcription: "ʃi hɪd ðə ˈlɛtə",
        translation: "She hid the letter",
      },
      {
        id: 7,
        expression: "Это случилось со мной так неожиданно",
        transcription: "ɪt ˈhæpənd tə mi səʊ ˌʌnɪksˈpɛktɪdli",
        translation: "It happened to me so unexpectedly",
      },
      {
        id: 8,
        expression: "Когда это произойдет?",
        transcription: "wɛn wɪl ɪt ˈhæpən?",
        translation: "When will it happen?",
      },
      {
        id: 9,
        expression: "Она закрыла шторы",
        transcription: "ʃi kləʊzd ðə ˈkɜːtnz",
        translation: "She closed the curtains",
      },
      {
        id: 10,
        expression: "Ты можешь закрыть дверь",
        transcription: "jʊ kən kləʊs ðə dɔː",
        translation: "You can close the door",
      },
      {
        id: 11,
        expression: "Я нашел то предложение очень привлекательным",
        transcription: "aɪ faʊnd ðət ˈɒfə ˈvɛri əˈtræktɪv",
        translation: "I found that offer very attractive",
      },
      {
        id: 12,
        expression: "Он испытывал очень серьезные трудности",
        transcription: "hi ɪksˈpɪərɪənst ˈvɛri ˈsɪərɪəs ˈdɪfɪkəltiz",
        translation: "He experienced very serious difficulties",
      },
      {
        id: 13,
        expression: "Я готов заказать это",
        transcription: "aɪm ˈrɛdi tʊ ˈɔːdər ɪt",
        translation: "I'm ready to order it",
      },
      {
        id: 14,
        expression: "Они говорят, что курение убивает",
        transcription: "ðeɪ seɪ ðət ˈsməʊkɪŋ kɪlz",
        translation: "They say that smoking kills",
      },
      {
        id: 15,
        expression: "Я повесил свое пальто на крючок",
        transcription: "aɪ hʌŋ maɪ kəʊt ɒn ə hʊk",
        translation: "I hung my coat on a hook",
      },
      {
        id: 16,
        expression: "Не трать свое время впустую!",
        transcription: "dəʊnt weɪst jə taɪm!",
        translation: "Don't waste your time!",
      },
      {
        id: 17,
        expression: "Как ты находишь то предложение?",
        transcription: "haʊ dʊ jʊ faɪnd ðət ˈɒfə?",
        translation: "How do you find that offer?",
      },
      {
        id: 18,
        expression: "Что ты будешь заказывать?",
        transcription: "wɒt wɪl jʊ ˈɔːdə?",
        translation: "What will you order?",
      },
      {
        id: 19,
        expression: "Ты будешь продавать свою машину?",
        transcription: "wɪl jʊ sɛl jə kɑː?",
        translation: "Will you sell your car?",
      },
      {
        id: 20,
        expression: "Когда ты прибудешь на работу?",
        transcription: "wɛn wɪl jʊ əˈraɪv ət wɜːk?",
        translation: "When will you arrive at work?",
      },
      {
        id: 21,
        expression: "Не подписывай эти бумаги!",
        transcription: "dəʊnt saɪn ðiːz ˈpeɪpəz!",
        translation: "Don't sign these papers!",
      },
      {
        id: 22,
        expression: "В какие игры ты предпочитаешь играть?",
        transcription: "wɒt geɪmz dʊ jʊ priˈfɜː tə pleɪ?",
        translation: "What games do you prefer to play?",
      },
      {
        id: 23,
        expression: "Какую музыку ты предпочитаешь?",
        transcription: "wɒt ˈmjuːzɪk dʊ jʊ priˈfɜː?",
        translation: "What music do you prefer?",
      },
      {
        id: 24,
        expression: "Я повесил свою куртку на крючок",
        transcription: "aɪ hʌŋ maɪ ˈʤækɪt ɒn ə hʊk",
        translation: "I hung my jacket on a hook",
      },
      {
        id: 25,
        expression: "Я не испытывал таких трудностей",
        transcription: "aɪ dɪdnt ɪksˈpɪərɪəns sʌʧ ˈdɪfɪkəltiz",
        translation: "I didn't experience such difficulties",
      },
      {
        id: 26,
        expression: "Ты готов заказать?",
        transcription: "ɑː jʊ ˈrɛdi tʊ ˈɔːdə?",
        translation: "Are you ready to order?",
      },
      {
        id: 27,
        expression: "Кто напал на них?",
        transcription: "huː əˈtækt ðɛm?",
        translation: "Who attacked them?",
      },
      {
        id: 28,
        expression: "Никогда не трать впустую свое время!",
        transcription: "ˈnɛvə weɪst jə taɪm!",
        translation: "Never waste your time!",
      },
      {
        id: 29,
        expression: "Он будет петь сегодня вечером?",
        transcription: "wɪl hi sɪŋ təˈnaɪt?",
        translation: "Will he sing tonight?",
      },
      {
        id: 30,
        expression: "Он не хотел убивать их",
        transcription: "hi dɪdnt wɒnt tə kɪl ðɛm",
        translation: "He didn't want to kill them",
      },
      {
        id: 31,
        expression: "Я хотел бы спеть эту песню",
        transcription: "aɪd laɪk tə sɪŋ ðɪs sɒŋ",
        translation: "I'd like to sing this song",
      },
      {
        id: 32,
        expression: "Они протестировали наши знания",
        transcription: "ðeɪ ˈtɛstɪd ˈaʊə ˈnɒlɪʤ",
        translation: "They tested our knowledge",
      },
      {
        id: 33,
        expression: "Они пели очень красиво",
        transcription: "ðeɪ sæŋ ˈvɛri ˈbjuːtəfʊli",
        translation: "They sang very beautifully",
      },
      {
        id: 34,
        expression: "Я надеюсь выиграть",
        transcription: "aɪ həʊp tə wɪn",
        translation: "I hope to win",
      },
      {
        id: 35,
        expression: "Они владеют этим бизнесом",
        transcription: "ðeɪ əʊn ðɪs ˈbɪznɪs",
        translation: "They own this business",
      },
      {
        id: 36,
        expression: "Он не заботится об этом",
        transcription: "hi dʌznt keər əˈbaʊt ɪt",
        translation: "He doesn't care about it",
      },
      {
        id: 37,
        expression: "Они протестировали все машины",
        transcription: "ðeɪ ˈtɛstɪd ɔːl ðə məˈʃiːnz",
        translation: "They tested all the machines",
      },
      {
        id: 38,
        expression: "Он описал эту проблему",
        transcription: "hi dɪsˈkraɪbd ðɪs ˈprɒbləm",
        translation: "He described this problem",
      },
      {
        id: 39,
        expression: "Эти аварии часто случаются",
        transcription: "ðiːz ˈæksɪdənts ˈɒf(ə)n ˈhæpən",
        translation: "These accidents often happen",
      },
      {
        id: 40,
        expression: "Это очень сильно мотивирует меня",
        transcription: "ɪt ˈməʊtɪveɪts mi ˈgreɪtli (səʊ mʌʧ)",
        translation: "It motivates me greatly (so much)",
      },
      {
        id: 41,
        expression: "Они подписали очень важный контракт",
        transcription: "ðeɪ saɪnd ə ˈvɛri ɪmˈpɔːtənt ˈkɒntrækt",
        translation: "They signed a very important contract",
      },
      {
        id: 42,
        expression: "Я заказал стакан сока и два сэндвича",
        transcription: "aɪ ˈɔːdəd ə glɑːs əv ʤuːs ənd tuː ˈsænwɪʤɪz",
        translation: "I ordered a glass of juice and two sandwiches",
      },
      {
        id: 43,
        expression: "Она подготовит все к его дню рождения",
        transcription: "ʃiːl prɪˈpeər ˈɛvrɪθɪŋ fə hɪz ˈbɜːθdeɪ",
        translation: "She'll prepare everything for his birthday",
      },
      {
        id: 44,
        expression: "Я надеюсь, ты сможешь прийти к нам",
        transcription: "aɪ həʊp juːl bi ˈeɪbl tə kʌm tʊ ʌs",
        translation: "I hope you'll be able to come to us",
      },
      {
        id: 45,
        expression: "Я решил прекратить тратить время впустую",
        transcription: "aɪ dɪˈsaɪdɪd tə stɒp ˈweɪstɪŋ taɪm",
        translation: "I decided to stop wasting time",
      },
      {
        id: 46,
        expression: "Он надеется заработать эту сумму денег к следующему году",
        transcription: "hi həʊps tʊ ɜːn ðɪs sʌm əv ˈmʌni baɪ nɛkst jɪə",
        translation: "He hopes to earn this sum of money by next year",
      },
      {
        id: 47,
        expression: "Я заказал чашку кофе и сэндвич с сыром",
        transcription: "aɪ ˈɔːdəd ə kʌp əv ˈkɒfi ənd ə ʧiːz ˈsænwɪʤ",
        translation: "I ordered a cup of coffee and a cheese sandwich",
      },
      {
        id: 48,
        expression:
          "Они пытались скрыть это от полиции, но это было безуспешно",
        transcription:
          "ðeɪ traɪd tə haɪd ɪt frəm ðə pəˈliːs bət ɪt wəz wɪˈðaʊt səkˈsɛs",
        translation:
          "They tried to hide it from the police but it was without success",
      },
      {
        id: 49,
        expression: "Он продал свою машину за пятнадцать тысяч долларов",
        transcription: "hi səʊld ɪz kɑː fə ˈfɪfˈtiːn ˈθaʊzənd ˈdɒləz",
        translation: "He sold his car for fifteen thousand dollars",
      },
      {
        id: 50,
        expression: "Я видел ее там, но мы не разговаривали",
        transcription: "aɪ sɔː hə ðeə bət wi dɪdnt spiːk",
        translation: "I saw her there but we didn't speak",
      },
      {
        id: 51,
        expression: "Я действительно обожаю заниматься спортом",
        transcription: "aɪ ˈrɪəli ɪnˈʤɔɪ ˈpleɪɪŋ spɔːt",
        translation: "I really enjoy playing sport",
      },
      {
        id: 52,
        expression: "Кого это волнует?",
        transcription: "huː keəz əˈbaʊt ɪt?",
        translation: "Who cares about it?",
      },
      {
        id: 53,
        expression: "Он предпочитает кофе чаю",
        transcription: "hi priˈfɜːz ˈkɒfi tə tiː",
        translation: "He prefers coffee to tea",
      },
      {
        id: 54,
        expression: "Я предпочитаю не курить",
        transcription: "aɪ priˈfɜː nɒt tə sməʊk",
        translation: "I prefer not to smoke",
      },
      {
        id: 55,
        expression: "Я могу описать ее внешность",
        transcription: "aɪ kən dɪsˈkraɪb hər əˈpɪərəns",
        translation: "I can describe her appearance",
      },
      {
        id: 56,
        expression: "Он рассказал мне всю правду",
        transcription: "hi təʊld mi ɔːl ðə truːθ",
        translation: "He told me all the truth",
      },
      {
        id: 57,
        expression: "Он скрыл всю правду",
        transcription: "hi hɪd ɔːl ðə truːθ",
        translation: "He hid all the truth",
      },
      {
        id: 58,
        expression: "Ты можешь рассказать ему правду?",
        transcription: "kən jʊ tɛl ɪm ðə truːθ?",
        translation: "Can you tell him the truth?",
      },
      {
        id: 59,
        expression: "Они не сказали мне, как это сделать",
        transcription: "ðeɪ dɪdnt tɛl mi haʊ tə dʊ ɪt",
        translation: "They didn't tell me how to do it",
      },
      {
        id: 60,
        expression: "Я не знаю, как это сделать",
        transcription: "aɪ dəʊnt nəʊ haʊ tə dʊ ɪt",
        translation: "I don't know how to do it",
      },
      {
        id: 61,
        expression: "Ты сказал ему, как это сделать?",
        transcription: "dɪd jʊ tɛl ɪm haʊ tə dʊ ɪt?",
        translation: "Did you tell him how to do it?",
      },
      {
        id: 62,
        expression: "Он предпочитает не курить",
        transcription: "hi priˈfɜːz nɒt tə sməʊk",
        translation: "He prefers not to smoke",
      },
      {
        id: 63,
        expression: "Он хочет бросить курить",
        transcription: "hi wɒnts tə stɒp ˈsməʊkɪŋ",
        translation: "He wants to stop smoking",
      },
      {
        id: 64,
        expression: "Я предпочитаю больше не курить",
        transcription: "aɪ priˈfɜː nɒt tə sməʊk ˌɛniˈmɔː",
        translation: "I prefer not to smoke anymore",
      },
      {
        id: 65,
        expression: "Что его больше всего удивило?",
        transcription: "wɒt səˈpraɪzd ɪm məʊst əv ɔːl?",
        translation: "What surprised him most of all?",
      },
      {
        id: 66,
        expression: "Кто тебя больше всего удивляет?",
        transcription: "wɒt səˈpraɪzɪz jʊ məʊst əv ɔːl?",
        translation: "What surprises you most of all?",
      },
      {
        id: 67,
        expression: "Что тебя удивило в этой ситуации?",
        transcription: "wɒt səˈpraɪzd jʊ ɪn ðɪs ˌsɪtjʊˈeɪʃən?",
        translation: "What surprised you in this situation?",
      },
      {
        id: 68,
        expression: "Я предпочитаю чай кофе",
        transcription: "aɪ priˈfɜː tiː tə ˈkɒfi",
        translation: "I prefer tea to coffee",
      },
      {
        id: 69,
        expression: "Она предпочитает кофе чаю",
        transcription: "ʃi priˈfɜːz ˈkɒfi tə tiː",
        translation: "She prefers coffee to tea",
      },
      {
        id: 70,
        expression: "Он предпочитает мясо рыбе",
        transcription: "hi priˈfɜːz miːt tə fɪʃ",
        translation: "He prefers meat to fish",
      },
      {
        id: 71,
        expression: "Кого действительно волнует?",
        transcription: "huː ˈrɪəli keəz?",
        translation: "Who really cares?",
      },
      {
        id: 72,
        expression: "Кого действительно волнует та вещь?",
        transcription: "huː ˈrɪəli keəz əˈbaʊt ðɪs θɪŋ?",
        translation: "Who really cares about this thing?",
      },
      {
        id: 73,
        expression: "Тебе нужно подписать там",
        transcription: "jʊ niːd tə saɪn ðeə",
        translation: "You need to sign there",
      },
      {
        id: 74,
        expression: "Тебе нужно подписать этот документ",
        transcription: "jʊ niːd tə saɪn ðɪs ˈdɒkjʊmənt",
        translation: "You need to sign this document",
      },
      {
        id: 75,
        expression: "Тебе не нужно подписывать ту бумагу",
        transcription: "jʊ dəʊnt niːd tə saɪn ðət ˈpeɪpə",
        translation: "You don't need to sign that paper",
      },
      {
        id: 76,
        expression: "Он не готовился к своим экзаменам",
        transcription: "hi dɪdnt prɪˈpeə fə hɪz ɪgˈzæmz",
        translation: "He didn't prepare for his exams",
      },
      {
        id: 77,
        expression: "Я не готовился к этому тесту",
        transcription: "aɪ dɪdnt prɪˈpeə fə ðɪs tɛst",
        translation: "I didn't prepare for this test",
      },
      {
        id: 78,
        expression: "Тебе нужно подготовиться к экзамену",
        transcription: "jʊ niːd tə prɪˈpeə fə ði ɪgˈzæm",
        translation: "You need to prepare for the exam",
      },
      {
        id: 79,
        expression: "Я стараюсь не тратить время впустую",
        transcription: "aɪ traɪ nɒt tə weɪst taɪm",
        translation: "I try not to waste time",
      },
      {
        id: 80,
        expression: "Я стараюсь не тратить впустую свое время на это",
        transcription: "aɪ traɪ nɒt tə weɪst maɪ taɪm ɒn ɪt",
        translation: "I try not to waste my time on it",
      },
      {
        id: 81,
        expression: "Я постараюсь не тратить впустую там свои деньги",
        transcription: "aɪl traɪ nɒt tə weɪst maɪ ˈmʌni ðeə",
        translation: "I'll try not to waste my money there",
      },
      {
        id: 82,
        expression: "Мне действительно нравится заниматься спортом",
        transcription: "aɪ ˈrɪəli laɪk ˈpleɪɪŋ spɔːt",
        translation: "I really like playing sport",
      },
      {
        id: 83,
        expression: "Мне действительно нравилось играть в футбол",
        transcription: "aɪ ˈrɪəli laɪkt ˈpleɪɪŋ ˈfʊtbɔːl",
        translation: "I really liked playing football",
      },
      {
        id: 84,
        expression: "Мне действительно нравится плавать в море",
        transcription: "aɪ ˈrɪəli laɪk ˈswɪmɪŋ ɪn ðə siː",
        translation: "I really like swimming in the sea",
      },
      {
        id: 85,
        expression: "Она повесила картину на стену",
        transcription: "ʃi hʌŋ ðə ˈpeɪntɪŋ ɒn ðə wɔːl",
        translation: "She hung the painting on the wall",
      },
      {
        id: 86,
        expression: "Как ты готовился к тесту?",
        transcription: "haʊ dɪd jʊ prɪˈpeə fə ðə tɛst?",
        translation: "How did you prepare for the test?",
      },
      {
        id: 87,
        expression: "Команда играла очень профессионально",
        transcription: "ðə tiːm pleɪd ˈvɛri prəˈfɛʃnəli",
        translation: "The team played very professionally",
      },
      {
        id: 88,
        expression: "Кого действительно волнует эта вещь?",
        transcription: "huː ˈrɪəli keəz əˈbaʊt ðɪs θɪŋ?",
        translation: "Who really cares about this thing?",
      },
      {
        id: 89,
        expression: "Она решила не продавать свой дом",
        transcription: "ʃi dɪˈsaɪdɪd nɒt tə sɛl hə haʊs",
        translation: "She decided not to sell her house",
      },
      {
        id: 90,
        expression: "Ты можешь описать его внешность?",
        transcription: "kən jʊ dɪsˈkraɪb ɪz əˈpɪərəns",
        translation: "Can you describe his appearance",
      },
      {
        id: 91,
        expression: "Почти невозможно поверить в это",
        transcription: "ɪts ˈɔːlməʊst ɪmˈpɒsəbl tə bɪˈliːv ɪt",
        translation: "It's almost impossible to believe it",
      },
      {
        id: 92,
        expression: "Я бы не смеялся над этим",
        transcription: "aɪ ˈwʊdnt tə lɑːf ət ɪt",
        translation: "I wouldn't to laugh at it",
      },
      {
        id: 93,
        expression: "Трудно поверить в это",
        transcription: "ɪts ˈdɪfɪkəlt tə bɪˈliːv ɪt",
        translation: "It's difficult to believe it",
      },
      {
        id: 94,
        expression: "Могу я поговорить с ней?",
        transcription: "kən aɪ spiːk (tɔːk) tuː (wɪð) hɜː?",
        translation: "Can I speak (talk) to (with) her?",
      },
      {
        id: 95,
        expression: "Я не могу закрыть глаза на это",
        transcription: "aɪ kɑːnt kləʊs maɪ aɪz ɒn ɪt",
        translation: "I can't close my eyes on it",
      },
      {
        id: 96,
        expression: "Не рассказывай ей об этом!",
        transcription: "dəʊnt tɛl hər əˈbaʊt ɪt!",
        translation: "Don't tell her about it!",
      },
      {
        id: 97,
        expression: "Ему нужно подписать здесь",
        transcription: "hi niːdz tə saɪn hɪə",
        translation: "He needs to sign here",
      },
      {
        id: 98,
        expression: "Могу я поговорить с ним?",
        transcription: "kən aɪ spiːk tə hɪm?",
        translation: "Can I speak to him?",
      },
      {
        id: 99,
        expression: "Они скрыли правду",
        transcription: "ðeɪ hɪd ðə truːθ",
        translation: "They hid the truth",
      },
      {
        id: 100,
        expression: "Они переехали в Америку",
        transcription: "ðeɪ muːvd tʊ əˈmɛrɪkə",
        translation: "They moved to America",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Закрепление пройденного материала</h3>
</div>`,
    },
  ],
];
