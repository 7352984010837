export const wordsLesson242 = [
  [
    {
      id: 1,
      word: 'adopt',
      transcription: 'əˈdɒpt',
      translation: 'усыновить, удочерить',
    },
    {
      id: 2,
      word: 'arrange',
      transcription: 'əˈreɪnʤ',
      translation: 'договариваться',
    },
    {
      id: 3,
      word: 'besides',
      transcription: 'bɪˈsaɪdz',
      translation: 'кроме того',
    },
    {
      id: 4,
      word: 'bookcase',
      transcription: 'ˈbʊkkeɪs',
      translation: 'книжный шкаф',
    },
    {
      id: 5,
      word: 'brain',
      transcription: 'breɪn',
      translation: 'мозг',
    },
    {
      id: 6,
      word: 'CD',
      transcription: 'siː-diː',
      translation: 'компакт-диск',
    },
    {
      id: 7,
      word: 'civil',
      transcription: 'ˈsɪvl',
      translation: 'гражданский',
    },
    {
      id: 8,
      word: 'confess',
      transcription: 'kənˈfɛs',
      translation: 'признаться',
    },
    {
      id: 9,
      word: 'convey',
      transcription: 'kənˈveɪ',
      translation: 'передавать, доносить',
    },
    {
      id: 10,
      word: 'eighth',
      transcription: 'eɪtθ',
      translation: 'восьмой',
    },
    {
      id: 11,
      word: 'explore',
      transcription: 'ɪksˈplɔː',
      translation: 'исследовать',
    },
    {
      id: 12,
      word: 'finance',
      transcription: 'faɪˈnæns',
      translation: 'финансы',
    },
    {
      id: 13,
      word: 'goal',
      transcription: 'ɡəʊl',
      translation: 'цель',
    },
    {
      id: 14,
      word: 'granddaughter',
      transcription: 'ˈɡrænˌdɔːtə',
      translation: 'внучка',
    },
    {
      id: 15,
      word: 'grandson',
      transcription: 'ˈɡrænsʌn',
      translation: 'внук',
    },
    {
      id: 16,
      word: 'inclusive',
      transcription: 'ɪnˈkluːsɪv',
      translation: 'включено',
    },
    {
      id: 17,
      word: 'legitimate',
      transcription: 'lɪˈʤɪtɪmɪt',
      translation: 'законный',
    },
    {
      id: 18,
      word: 'male',
      transcription: 'meɪl',
      translation: 'мужской',
    },
    {
      id: 19,
      word: 'minimum',
      transcription: 'ˈmɪnɪməm',
      translation: 'минимум, минимальный',
    },
    {
      id: 20,
      word: 'mommy',
      transcription: 'ˈmɒmi',
      translation: 'мамочка',
    },
    {
      id: 21,
      word: 'paste',
      transcription: 'peɪst',
      translation: 'вставлять',
    },
    {
      id: 22,
      word: 'pink',
      transcription: 'pɪŋk',
      translation: 'розовый',
    },
    {
      id: 23,
      word: 'prescribe',
      transcription: 'prɪsˈkraɪb',
      translation: 'прописывать',
    },
    {
      id: 24,
      word: 'sand',
      transcription: 'sænd',
      translation: 'песок',
    },
    {
      id: 25,
      word: 'sore',
      transcription: 'sɔː',
      translation: 'больной, воспаленный',
    },
    {
      id: 26,
      word: 'spread',
      transcription: 'sprɛd',
      translation: 'распространяться',
    },
    {
      id: 27,
      word: 'structure',
      transcription: 'ˈstrʌkʧə',
      translation: 'структура',
    },
    {
      id: 28,
      word: 'target',
      transcription: 'ˈtɑːɡɪt',
      translation: 'цель',
    },
    {
      id: 29,
      word: 'throat',
      transcription: 'θrəʊt',
      translation: 'горло',
    },
    {
      id: 30,
      word: 'tear',
      transcription: 'teə',
      translation: 'рвать',
    },
    {
      id: 31,
      word: 'undo',
      transcription: 'ʌnˈduː',
      translation: 'расстегивать',
    },
    {
      id: 32,
      word: 'wanna',
      transcription: 'ˈwɒnə',
      translation: 'хотеть',
    },
  ],
];
