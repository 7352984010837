export const contentLesson152 = [
  [
    {
      title: 'Урок 152. Dependent prepositions (adjectives). Part 2',
    },
    [
      {
        id: 1,
        expression: 'Я готов к этому',
        transcription: 'aɪm ˈrɛdi fər ɪt',
        translation: "I'm ready for it",
      },
      {
        id: 2,
        expression: 'Она замужем за моим другом',
        transcription: 'ʃiːz ˈmærɪd tə maɪ frɛnd',
        translation: "She's married to my friend",
      },
      {
        id: 3,
        expression: 'Он виновен в убийстве',
        transcription: 'hiːz ˈgɪlti əv ˈmɜːdə',
        translation: "He's guilty of murder",
      },
      {
        id: 4,
        expression: 'Руководство осведомлено об этих проблемах',
        transcription: 'ðə ˈmænɪʤmənt ər əˈweər əv ðiːz ˈprɒbləmz',
        translation: 'The management are aware of these problems',
      },
      {
        id: 5,
        expression: 'Ему не было стыдно за такое поведение',
        transcription: 'hi wɒznt əˈʃeɪmd əv sʌʧ bɪˈheɪvjə',
        translation: "He wasn't ashamed of such behaviour",
      },
      {
        id: 6,
        expression: 'Он был виновен в этом преступлении',
        transcription: 'hi wəz ˈgɪlti əv ðɪs kraɪm',
        translation: 'He was guilty of this crime',
      },
      {
        id: 7,
        expression: 'Это очень вредно для твоего здоровья',
        transcription: 'ɪts ˈvɛri ˈhɑːmfʊl tə jə hɛlθ',
        translation: "It's very harmful to your health",
      },
      {
        id: 8,
        expression: 'Руководство было осведомлено об этих трудностях',
        transcription: 'ðə ˈmænɪʤmənt wər əˈweər əv ðiːz ˈdɪfɪkəltiz',
        translation: 'The managment were aware of these difficulties',
      },
      {
        id: 9,
        expression: 'Это может быть очень вредно для твоего здоровья',
        transcription: 'ɪt kən bi ˈvɛri ˈhɑːmfʊl tə jə hɛlθ',
        translation: 'It can be very harmful to your health',
      },
      {
        id: 10,
        expression: 'Ему должно быть стыдно за это',
        transcription: 'hi məst bi əˈʃeɪmd əv ɪt',
        translation: 'He must be ashamed of it',
      },
      {
        id: 11,
        expression: 'Он не был виновен в преступлении',
        transcription: 'hi wɒznt ˈgɪlti əv ðə kraɪm',
        translation: "He wasn't guilty of the crime",
      },
      {
        id: 12,
        expression: 'Я еще не готов к этому',
        transcription: 'aɪm nɒt ˈrɛdi fər ɪt jɛt',
        translation: "I'm not ready for it yet",
      },
      {
        id: 13,
        expression: 'Это очень эгоистично с его стороны',
        transcription: 'ɪts ˈvɛri ˈsɛlfɪʃ əv hɪm',
        translation: "It's very selfish of him",
      },
      {
        id: 14,
        expression: 'Он занят своим домашним заданием',
        transcription: 'hiːz ˈbɪzi wɪð ɪz ˈhəʊmˌwɜːk',
        translation: "He's busy with his homework",
      },
      {
        id: 15,
        expression: 'Это очень жестоко с его стороны',
        transcription: 'ɪts ˈvɛri krʊəl əv hɪm',
        translation: "It's very cruel of him",
      },
      {
        id: 16,
        expression: 'Она знает об этих рисках',
        transcription: 'ʃiːz əˈweər əv ðiːz rɪsks',
        translation: "She's aware of these risks",
      },
      {
        id: 17,
        expression: 'Я не рад этим результатам',
        transcription: 'aɪm nɒt ˈhæpi əˈbaʊt ðiːz rɪˈzʌlts',
        translation: "I'm not happy about these results",
      },
      {
        id: 18,
        expression: 'Я был поражен красотой этого места',
        transcription: 'aɪ wəz əˈmeɪzd baɪ ðə ˈbjuːti əv ðɪs pleɪs',
        translation: 'I was amazed by the beauty of this place',
      },
      {
        id: 19,
        expression: 'Он действительно рад этому',
        transcription: 'hiːz ˈrɪəli ˈhæpi əˈbaʊt ɪt',
        translation: "He's really happy about it",
      },
      {
        id: 20,
        expression: 'Ей действительно стыдно за это',
        transcription: 'ʃiːz ˈrɪəli əˈʃeɪmd əv ɪt',
        translation: "She's really ashamed of it",
      },
      {
        id: 21,
        expression: 'Тебе нужно быть более осторожным с этим',
        transcription: 'jʊ niːd tə bi mɔː ˈkeəfʊl wɪð ɪt',
        translation: 'You need to be more careful with it',
      },
      {
        id: 22,
        expression: 'Они действительно были вдохновлены его словами',
        transcription: 'ðeɪ wə ˈrɪəli ɪnˈspaɪəd baɪ ɪz wɜːdz',
        translation: 'They were really inspired by his words',
      },
      {
        id: 23,
        expression: 'Это очень вредно для детей',
        transcription: 'ɪts ˈvɛri ˈhɑːmfʊl tə ˈʧɪldrən',
        translation: "It's very harmful to children",
      },
      {
        id: 24,
        expression: 'Эти две вещи тесно связаны друг с другом',
        transcription: 'ðiːz tuː θɪŋz ə ˈkləʊsli kəˈnɛktɪd wɪð iːʧ ˈʌðə',
        translation: 'These two things are closely connected with each other',
      },
      {
        id: 25,
        expression: 'Он очень занят этой работой',
        transcription: 'hiːz ˈvɛri ˈbɪzi wɪð ðɪs wɜːk',
        translation: "He's very busy with this work",
      },
      {
        id: 26,
        expression: 'Он очень увлечен этой идеей',
        transcription: 'hiːz ˈvɛri ɪnˌθjuːzɪˈæstɪk əˈbaʊt ðɪs aɪˈdɪə',
        translation: "He's very enthusiastic about this idea",
      },
      {
        id: 27,
        expression: 'Я был поражен результатами',
        transcription: 'aɪ wəz əˈmeɪzd baɪ ðə rɪˈzʌlts',
        translation: 'I was amazed by the results',
      },
      {
        id: 28,
        expression: 'Что с ним не так?',
        transcription: 'wɒts rɒŋ wɪð hɪm?',
        translation: "What's wrong with him?",
      },
      {
        id: 29,
        expression: 'Что-то не так с двигателем',
        transcription: 'ˈsʌmθɪŋ z rɒŋ wɪð ði ˈɛnʤɪn',
        translation: 'Something is wrong with the engine',
      },
      {
        id: 30,
        expression: 'Он очень успешен в бизнесе',
        transcription: 'hiːz ˈvɛri səkˈsɛsfʊl ɪn ˈbɪznɪs',
        translation: "He's very successful in business",
      },
      {
        id: 31,
        expression: 'Город всегда переполнен туристами',
        transcription: 'ðə ˈsɪti z ˈɔːlweɪz ˈkraʊdɪd wɪð ˈtʊərɪsts',
        translation: 'The city is always crowded with tourists',
      },
      {
        id: 32,
        expression: 'Я полностью удовлетворен этими результатами',
        transcription: 'aɪm ˈfʊli ˈsætɪsfaɪd wɪð ðiːz rɪˈzʌlts',
        translation: "I'm fully satisfied with these results",
      },
      {
        id: 33,
        expression: 'Наш начальник очень дружелюбен со своими сотрудниками',
        transcription: 'ˈaʊə bɒs ɪz ˈfrɛndli wɪð ɪz ˌɛmplɔɪˈiːz',
        translation: 'Our boss is friendly with his employees',
      },
      {
        id: 34,
        expression: 'Я осведомлен об этих изменениях',
        transcription: 'aɪm əˈweər əv ðiːz ˈʧeɪnʤɪz',
        translation: "I'm aware of these changes",
      },
      {
        id: 35,
        expression: 'Эта книга подходит для начинающих',
        transcription: 'ðɪs bʊk s ˈsjuːtəbl fə bɪˈgɪnəz',
        translation: 'This book is suitable for beginners',
      },
      {
        id: 36,
        expression: 'Кто ответственный за все это?',
        transcription: 'huː z rɪsˈpɒnsəbl fər ɔːl ðɪs?',
        translation: 'Who is responsible for all this?',
      },
      {
        id: 37,
        expression: 'Я был действительно вдохновлен этой речью',
        transcription: 'aɪ wəz ˈrɪəli ɪnˈspaɪəd baɪ ðɪs spiːʧ',
        translation: 'I was really inspired by this speech',
      },
      {
        id: 38,
        expression: 'Эта программа не подходит для детей',
        transcription: 'ðɪs ˈprəʊgræm ˈɪznt ˈsjuːtəbl fə ˈʧɪldrən',
        translation: "This program isn't suitable for children",
      },
      {
        id: 39,
        expression: 'Это место переполнено туристами',
        transcription: 'ðɪs pleɪs ɪz ˈkraʊdɪd wɪð ˈtʊərɪsts',
        translation: 'This place is crowded with tourists',
      },
      {
        id: 40,
        expression: 'Он очень жесток с этим беднягой',
        transcription: 'hi ɛs ˈvɛri krʊəl wɪð ðɪs pʊə mæn',
        translation: "He's very cruel with this poor man",
      },
      {
        id: 41,
        expression: 'Что-то не так с лифтом',
        transcription: 'ˈsʌmθɪŋ z rɒŋ wɪð ði ˈɛlɪveɪtə',
        translation: 'Something is wrong with the elevator',
      },
      {
        id: 42,
        expression: 'Я был поражен моими результатами',
        transcription: 'aɪ wəz əˈmeɪzd baɪ maɪ rɪˈzʌlts',
        translation: 'I was amazed by my results',
      },
      {
        id: 43,
        expression: 'Я был действительно вдохновлен достижениями моего друга',
        transcription: 'aɪ wəz ˈrɪəli ɪnˈspaɪəd baɪ maɪ frɛndz əˈʧiːvmənts',
        translation: "I was really inspired by my friend's achievements",
      },
      {
        id: 44,
        expression: 'Он боится неизвестности',
        transcription: 'hiːz skeəd əv ði ʌnˈnəʊn',
        translation: "He's scared of the unknown",
      },
      {
        id: 45,
        expression: 'Он женат на ней пять лет',
        transcription: 'hiːz biːn ˈmærɪd tə hə fə faɪv jɪəz',
        translation: "He's been married to her for five years",
      },
      {
        id: 46,
        expression:
          'Эти материалы подходят для начинающих, а также для более высоких уровней',
        transcription:
          'ðiːz məˈtɪərɪəlz ə ˈsjuːtəbl fə bɪˈgɪnəz ənd ˈhaɪə ˈlɛvlz əz wɛl',
        translation:
          'These materials are suitable for beginners and higher levels as well',
      },
      {
        id: 47,
        expression:
          'Часто говорится, что курение очень вредно для здоровья людей',
        transcription:
          'ɪts ˈɒf(ə)n sɛd ðət ˈsməʊkɪŋ z ˈvɛri ˈhɑːmfʊl tə ˈpiːplz hɛlθ',
        translation:
          "It's often said that smoking is very harmful to people's health",
      },
      {
        id: 48,
        expression: 'Он недоволен результатами теста',
        transcription:
          'hiːz ˌdɪsˈsætɪsfaɪd wɪð ðə rɪˈzʌlts əv ðə tɛst / hi ˈɪznt ˈsætɪsfaɪd wɪð ðə rɪˈzʌlts əv ðə tɛst',
        translation:
          "He's dissatisfied with the results of the test / He isn't satisfied with the results of the test",
      },
      {
        id: 49,
        expression: 'Она замужем за ним более тридцати лет',
        transcription: 'ʃiːz biːn ˈmærɪd tə ɪm fər ˈəʊvə ˈθɜːti jɪəz',
        translation: "She's been married to him for over thirty years",
      },
      {
        id: 50,
        expression: 'Он был действительно вдохновлен достижениями своих друзей',
        transcription: 'hi wəz ˈrɪəli ɪnˈspaɪəd baɪ ɪz frɛndz əˈʧiːvmənts',
        translation: "He was really inspired by his friends' achievements",
      },
      {
        id: 51,
        expression: 'Я недоволен качеством этого сервиса',
        transcription: 'aɪm ˌdɪsˈsætɪsfaɪd wɪð ðə ˈkwɒlɪti əv ðɪs ˈsɜːvɪs',
        translation: "I'm dissatisfied with the quality of this service",
      },
      {
        id: 52,
        expression: 'Она очень подозрительна к нему',
        transcription: 'ʃiːz ˈvɛri səsˈpɪʃəs əv hɪm',
        translation: "She's very suspicious of him",
      },
      {
        id: 53,
        expression: 'Это близко к этому месту',
        transcription: 'ɪt s kləʊs tə ðɪs pleɪs',
        translation: 'It is close to this place',
      },
      {
        id: 54,
        expression: 'Чем ты обожаешь заниматься?',
        transcription: 'wɒt ə jʊ fɒnd əv ˈdu(ː)ɪŋ?',
        translation: 'What are you fond of doing?',
      },
      {
        id: 55,
        expression: 'Он очень вежлив с ней',
        transcription: 'hiːz ˈvɛri pəˈlaɪt tə hɜː',
        translation: "He's very polite to her",
      },
      {
        id: 56,
        expression: 'Чем ты интересуешься?',
        transcription:
          'wɒt ə jʊ ˈɪntrɪstɪd ɪn? / wɒt ˈɪntrɪsts juː? / wɒts ˈɪntrɪstɪŋ fə juː?',
        translation:
          "What are you interested in? / What interests you? / What's interesting for you?",
      },
      {
        id: 57,
        expression: 'Она очень подозрительно относится к другим людям',
        transcription: 'ʃiːz ˈvɛri səsˈpɪʃəs əv ˈʌðə ˈpiːpl',
        translation: "She's very suspicious of other people",
      },
      {
        id: 58,
        expression: 'Я думаю, что-то с ним не так',
        transcription: 'aɪ θɪŋk ˈsʌmθɪŋ z rɒŋ wɪð hɪm',
        translation: 'I think something is wrong with him',
      },
      {
        id: 59,
        expression: 'Я думаю, что-то не так с этой вещью',
        transcription: 'aɪ θɪŋk ˈsʌmθɪŋ z rɒŋ wɪð ðɪs θɪŋ',
        translation: 'I think something is wrong with this thing',
      },
      {
        id: 60,
        expression: 'С ней что-то не так?',
        transcription: 'ɪz ˈɛnɪθɪŋ rɒŋ wɪð hɜː?',
        translation: 'Is anything wrong with her?',
      },
      {
        id: 61,
        expression: 'Она живет близко к нему',
        transcription: 'ʃi lɪvz kləʊs tə hɪm',
        translation: 'She lives close to him',
      },
      {
        id: 62,
        expression: 'Она живет ближе к тому парку',
        transcription: 'ʃi lɪvz ˈkləʊsə tə ðət pɑːk',
        translation: 'She lives closer to that park',
      },
      {
        id: 63,
        expression: 'Они живут близко ко мне',
        transcription: 'ðeɪ lɪv kləʊs tə miː',
        translation: 'They live close to me',
      },
      {
        id: 64,
        expression: 'Чем ты обеспокоен?',
        transcription: 'wɒt ə jʊ ˈwʌrid əˈbaʊt?',
        translation: 'What are you worried about?',
      },
      {
        id: 65,
        expression: 'Я не беспокоюсь о своих результатах',
        transcription: 'aɪ dəʊnt ˈwʌri əˈbaʊt maɪ rɪˈzʌlts',
        translation: "I don't worry about my results",
      },
      {
        id: 66,
        expression: 'Ты обеспокоен результатом этого теста?',
        transcription: 'ɑː jʊ ˈwʌrid əˈbaʊt ðə rɪˈzʌlt əv ðɪs tɛst?',
        translation: 'Are you worried about the result of this test?',
      },
      {
        id: 67,
        expression: 'Чем ты увлекаешься?',
        transcription: 'wɒt ə jʊ kiːn ɒn (fɒnd ɒv)?',
        translation: 'What are you keen on (fond of)?',
      },
      {
        id: 68,
        expression: 'Я увлекаюсь чтением на английском',
        transcription: 'aɪm kiːn ɒn (fɒnd ɒv) ˈriːdɪŋ ɪn ˈɪŋglɪʃ',
        translation: "I'm keen on (fond of) reading in English",
      },
      {
        id: 69,
        expression: 'Ты увлекаешься музыкой?',
        transcription: 'ɑː jʊ kiːn ɒn ˈmjuːzɪk?',
        translation: 'Are you keen on music?',
      },
      {
        id: 70,
        expression: 'Он ничего не боится',
        transcription: 'hi ˈɪznt əˈfreɪd (skeəd) əv ˈɛnɪθɪŋ',
        translation: "He isn't afraid (scared) of anything",
      },
      {
        id: 71,
        expression: 'Я боюсь темноты',
        transcription: 'aɪm əˈfreɪd (skeəd) əv ˈdɑːknɪs',
        translation: "I'm afraid (scared) of darkness",
      },
      {
        id: 72,
        expression: 'Она боится неизвестности',
        transcription: 'ʃiːz skeəd əv ði ʌnˈnəʊn',
        translation: "She's scared of the unknown",
      },
      {
        id: 73,
        expression: 'Чего ты боишься?',
        transcription: 'wɒt ə jʊ skeəd ɒv?',
        translation: 'What are you scared of?',
      },
      {
        id: 74,
        expression: 'Я действительно боюсь пауков',
        transcription: 'aɪm ˈrɪəli əˈfreɪd əv ˈspaɪdəz',
        translation: "I'm really afraid of spiders",
      },
      {
        id: 75,
        expression: 'Ты боишься пауков?',
        transcription: 'ɑː jʊ əˈfreɪd əv ˈspaɪdəz?',
        translation: 'Are you afraid of spiders?',
      },
      {
        id: 76,
        expression: 'За что ей стыдно?',
        transcription: 'wɒt s ʃi əˈʃeɪmd ɒv?',
        translation: 'What is she ashamed of?',
      },
      {
        id: 77,
        expression: 'Ей стыдно за свое поведение',
        transcription: 'ʃiːz əˈʃeɪmd əv hə bɪˈheɪvjə',
        translation: "She's ashamed of her behaviour",
      },
      {
        id: 78,
        expression: 'Ей стыдно за это?',
        transcription: 'ɪz ʃi əˈʃeɪmd əv ɪt?',
        translation: 'Is she ashamed of it?',
      },
      {
        id: 79,
        expression: 'За кем она замужем?',
        transcription: 'huː z ʃi ˈmærɪd tʊ?',
        translation: 'Who is she married to?',
      },
      {
        id: 80,
        expression: 'Она замужем за моим братом',
        transcription: 'ʃiːz ˈmærɪd tə maɪ ˈbrʌðə',
        translation: "She's married to my brother",
      },
      {
        id: 81,
        expression: 'На ком он женат?',
        transcription: 'huː z hi ˈmærɪd tʊ?',
        translation: 'Who is he married to?',
      },
      {
        id: 82,
        expression: 'Чего он боялся?',
        transcription: 'wɒt wəz hi ˈfraɪtnd ɒv?',
        translation: 'What was he frightened of?',
      },
      {
        id: 83,
        expression: 'Чего он боится?',
        transcription: 'wɒt s hi ˈfraɪtnd ɒv?',
        translation: 'What is he frightened of?',
      },
      {
        id: 84,
        expression: 'Он боялся этого?',
        transcription: 'wəz hi ˈfraɪtnd əv ɪt?',
        translation: 'Was he frightened of it?',
      },
      {
        id: 85,
        expression: 'На что она злится?',
        transcription: 'wɒt s ʃi ˈæŋgri əˈbaʊt?',
        translation: 'What is she angry about?',
      },
      {
        id: 86,
        expression: 'Она злится на меня?',
        transcription: 'ɪz ʃi ˈæŋgri wɪð miː?',
        translation: 'Is she angry with me?',
      },
      {
        id: 87,
        expression: 'Я больше не злюсь на тебя',
        transcription: 'aɪm nɒt ˈæŋgri wɪð jʊ ˌɛniˈmɔː',
        translation: "I'm not angry with you anymore",
      },
      {
        id: 88,
        expression: 'Тебе нужно быть более вежливым с ним',
        transcription: 'jʊ niːd tə bi mɔː pəˈlaɪt tə hɪm',
        translation: 'You need to be more polite to him',
      },
      {
        id: 89,
        expression: 'Она действительно боится неизвестности',
        transcription: 'ʃiːz ˈrɪəli əˈfreɪd əv ði ʌnˈnəʊn',
        translation: "She's really afraid of the unknown",
      },
      {
        id: 90,
        expression: 'Я был полностью удовлетворен результатами того теста',
        transcription: 'aɪ wəz ˈfʊli ˈsætɪsfaɪd wɪð ðə rɪˈzʌlts əv ðət tɛst',
        translation: 'I was fully satisfied with the results of that test',
      },
      {
        id: 91,
        expression: 'Это очень эгоистично с его стороны вести себя так',
        transcription: 'ɪts ˈvɛri ˈsɛlfɪʃ əv ɪm tə bɪˈheɪv səʊ',
        translation: "It's very selfish of him to behave so",
      },
      {
        id: 92,
        expression: 'Он очень подозрителен к другим',
        transcription: 'hiːz ˈvɛri səsˈpɪʃəs əv ˈʌðəz',
        translation: "He's very suspicious of others",
      },
      {
        id: 93,
        expression:
          'Это очень эгоистично с его стороны игнорировать потребности других людей',
        transcription: 'ɪts ˈvɛri ˈsɛlfɪʃ əv ɪm tʊ ɪgˈnɔːr ˈʌðə ˈpiːplz niːdz',
        translation: "It's very selfish of him to ignore other people's needs",
      },
      {
        id: 94,
        expression: 'Не принимай это близко к сердцу!',
        transcription: 'dəʊnt teɪk ɪt tə jə hɑːt!',
        translation: "Don't take it to your heart!",
      },
      {
        id: 95,
        expression: 'Я вижу, ты в восторге от этого проекта',
        transcription:
          'aɪ siː jʊər ɪkˈsaɪtɪd (ˈvɛri ɪnˌθjuːzɪˈæstɪk) əˈbaʊt ðɪs ˈprɒʤɛkt',
        translation:
          "I see you're excited (very enthusiastic) about this project",
      },
      {
        id: 96,
        expression: 'Я не удовлетворен качеством этих продуктов',
        transcription: 'aɪm nɒt ˈsætɪsfaɪd wɪð ðə ˈkwɒlɪti əv ðiːz ˈprɒdʌkts',
        translation: "I'm not satisfied with the quality of these products",
      },
      {
        id: 97,
        expression: 'Тебе следует быть более вежливым со своими коллегами',
        transcription: 'jʊ ʃəd bi mɔː pəˈlaɪt tə jə ˈkɒliːgz',
        translation: 'You should be more polite to your colleagues',
      },
      {
        id: 98,
        expression: 'На ком он женат?',
        transcription: 'huː z hi ˈmærɪd tʊ?',
        translation: 'Who is he married to?',
      },
      {
        id: 99,
        expression: 'Что с этим не так?',
        transcription: 'wɒts rɒŋ wɪð ɪt?',
        translation: "What's wrong with it?",
      },
      {
        id: 100,
        expression: 'Чем они удивлены?',
        transcription: 'wɒt ə ðeɪ səˈpraɪzd baɪ?',
        translation: 'What are they surprised by?',
      },
      {
        id: 101,
        expression: 'Из-за чего он злится?',
        transcription: 'wɒt s hi ˈæŋgri əˈbaʊt?',
        translation: 'What is he angry about?',
      },
      {
        id: 102,
        expression: 'Что ты любишь?',
        transcription: 'wɒt ə jʊ fɒnd ɒv?',
        translation: 'What are you fond of?',
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Продолжаем изучать зависимые предлоги</h3>
</div>`,
    },
  ],
];
