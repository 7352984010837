export const wordsLesson257 = [
  [
    {
      id: 1,
      word: 'clean up',
      transcription: 'kliːn ʌp',
      translation: 'убираться',
    },
    {
      id: 2,
      word: 'be into',
      transcription: 'biː ˈɪntuː',
      translation: 'интересоваться',
    },
    {
      id: 3,
      word: 'fall behind',
      transcription: 'fɔːl bɪˈhaɪnd',
      translation: 'отставать',
    },
    {
      id: 4,
      word: 'catch up',
      transcription: 'kæʧ ʌp',
      translation: 'догнать',
    },
    {
      id: 5,
      word: 'hand in',
      transcription: 'hænd ɪn',
      translation: 'подавать',
    },
    {
      id: 6,
      word: 'read up on',
      transcription: 'riːd ʌp ɒn',
      translation: 'читать дополнительный материал',
    },
    {
      id: 7,
      word: 'draw up',
      transcription: 'drɔː ʌp',
      translation: 'составлять',
    },
    {
      id: 8,
      word: 'knock off',
      transcription: 'nɒk ɒf',
      translation: 'заканчивать, прерываться',
    },
    {
      id: 9,
      word: 'slack off',
      transcription: 'slæk ɒf',
      translation: 'расслабляться, снижать темп работы',
    },
    {
      id: 10,
      word: 'weigh up',
      transcription: 'weɪ ʌp',
      translation: 'взвешивать',
    },
    {
      id: 11,
      word: 'come up',
      transcription: 'kʌm ʌp',
      translation: 'подойти, происходить',
    },
    {
      id: 12,
      word: 'call back',
      transcription: 'kɔːl bæk',
      translation: 'перезвонить',
    },
    {
      id: 13,
      word: 'annoyance',
      transcription: 'əˈnɔɪəns',
      translation: 'раздражение',
    },
    {
      id: 14,
      word: 'berserk',
      transcription: 'bəˈzɜːk',
      translation: 'бешеный',
    },
    {
      id: 15,
      word: 'pros and cons',
      transcription: 'prəʊz ænd kɒnz',
      translation: 'за и против',
    },
    {
      id: 16,
      word: 'deadline',
      transcription: 'ˈdɛdlaɪn',
      translation: 'крайний срок',
    },
    {
      id: 17,
      word: 'deceive',
      transcription: 'dɪˈsiːv',
      translation: 'обманывать',
    },
    {
      id: 18,
      word: 'inseparable',
      transcription: 'ɪnˈsɛpərəbᵊl',
      translation: 'неотъемлемый',
    },
    {
      id: 19,
      word: 'option',
      transcription: 'ˈɒpʃᵊn',
      translation: 'вариант',
    },
    {
      id: 20,
      word: 'persuasive',
      transcription: 'pəˈsweɪsɪv',
      translation: 'убедительный',
    },
    {
      id: 21,
      word: 'resignation',
      transcription: 'ˌrɛzɪɡˈneɪʃᵊn',
      translation: 'отставка',
    },
    {
      id: 22,
      word: 'straight',
      transcription: 'streɪt',
      translation: 'прямой',
    },
    {
      id: 23,
      word: 'update',
      transcription: 'ʌpˈdeɪt',
      translation: 'обновление',
    },
    {
      id: 24,
      word: 'yoga',
      transcription: 'ˈjəʊɡə',
      translation: 'йога',
    },
  ],
];
