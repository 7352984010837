export const contentLesson99 = [
  [
    {
      title: "Урок 99. 270 Verbs in Different Tenses. Part 7",
    },
    [
      {
        id: 1,
        expression: "Я проанализировал эти цифры",
        transcription: "aɪ ˈænəlaɪzd ðiːz ˈfɪgəz",
        translation: "I analysed these figures",
      },
      {
        id: 2,
        expression: "Когда они доставят это?",
        transcription: "wɛn wɪl ðeɪ dɪˈlɪvər ɪt?",
        translation: "When will they deliver it?",
      },
      {
        id: 3,
        expression: "Она посмотрела на него, и он улыбнулся ей",
        transcription: "ʃi lʊkt ət ɪm ənd hi smaɪld ət hɜː",
        translation: "She looked at him and he smiled at her",
      },
      {
        id: 4,
        expression: "Мы пошли туда и ловили рыбу",
        transcription: "wi wɛnt ðeər ənd kɔːt fɪʃ",
        translation: "We went there and caught fish",
      },
      {
        id: 5,
        expression: "Она борется за права человека и справедливость",
        transcription: "ʃi faɪts fə ˈhjuːmən raɪts ənd ˈʤʌstɪs",
        translation: "She fights for human rights and justice",
      },
      {
        id: 6,
        expression:
          "Компания предоставляет отличные условия для своих сотрудников",
        transcription:
          "ðə ˈkʌmpəni prəˈvaɪdz ˈɛksələnt kənˈdɪʃənz fər ɪts ˌɛmplɔɪˈiːz",
        translation:
          "The company provides excellent conditions for its employees",
      },
      {
        id: 7,
        expression: "Она стала настоящей знаменитостью двадцать лет спустя",
        transcription: "ʃi bɪˈkeɪm ə rɪəl sɪˈlɛbrɪti ˈtwɛnti jɪəz ˈleɪtə",
        translation: "She became a real celebrity twenty years later",
      },
      {
        id: 8,
        expression:
          "Я очень сильно улучшил свой английский с помощью этого метода",
        transcription:
          "aɪ ɪmˈpruːvd maɪ ˈɪŋglɪʃ ˈgreɪtli wɪð ðə hɛlp əv ðɪs ˈmɛθəd",
        translation:
          "I improved my English greatly with the help of this method",
      },
      {
        id: 9,
        expression: "Он согласился сделать это?",
        transcription: "dɪd hi əˈgriː tə dʊ ɪt?",
        translation: "Did he agree to do it?",
      },
      {
        id: 10,
        expression: "Эта организация борется за права человека",
        transcription: "ðɪs ˌɔːgənaɪˈzeɪʃən faɪts fə ˈhjuːmən raɪts",
        translation: "This organisation fights for human rights",
      },
      {
        id: 11,
        expression: "Ты последуешь этим рекомендациям?",
        transcription: "wɪl jʊ ˈfɒləʊ ðiːz ˌrɛkəmɛnˈdeɪʃənz?",
        translation: "Will you follow these recommendations?",
      },
      {
        id: 12,
        expression: "Она внезапно потеряла контроль",
        transcription: "ʃi lɒst kənˈtrəʊl ˈsʌdnli",
        translation: "She lost control suddenly",
      },
      {
        id: 13,
        expression: "Наконец, я получил твое сообщение",
        transcription: "aɪ gɒt jə ˈmɛsɪʤ ət lɑːst",
        translation: "I got your message at last",
      },
      {
        id: 14,
        expression: "Он все очень тщательно проанализировал",
        transcription: "hi ˈænəlaɪzd ˈɛvrɪθɪŋ ˈvɛri ˈθʌrəli",
        translation: "He analysed everything very thoroughly",
      },
      {
        id: 15,
        expression: "Я хочу общаться с людьми, которые живут в разных странах",
        transcription:
          "aɪ wɒnt tə kəˈmjuːnɪkeɪt wɪð ˈpiːpl huː lɪv ɪn ˈdɪfrənt ˈkʌntriz",
        translation:
          "I want to communicate with people who live in different countries",
      },
      {
        id: 16,
        expression: "Заполни строки!",
        transcription: "fɪl ɪn ðə laɪnz!",
        translation: "Fill in the lines!",
      },
      {
        id: 17,
        expression: "Я съел бутерброд",
        transcription: "aɪ ɛt ə ˈsænwɪʤ",
        translation: "I ate a sandwich",
      },
      {
        id: 18,
        expression: "Он знал все",
        transcription: "hi njuː ˈɛvrɪθɪŋ",
        translation: "He knew everything",
      },
      {
        id: 19,
        expression: "Я получил твое письмо",
        transcription: "aɪ rɪˈsiːvd (gɒt) jə ˈlɛtə",
        translation: "I received (got) your letter",
      },
      {
        id: 20,
        expression: "Она может это заметить",
        transcription: "ʃi kən ˈnəʊtɪs ɪt",
        translation: "She can notice it",
      },
      {
        id: 21,
        expression: "Я последую этому совету",
        transcription: "aɪl ˈfɒləʊ ðɪs ədˈvaɪs",
        translation: "I'll follow this advice",
      },
      {
        id: 22,
        expression: "Он стал известным только позже",
        transcription: "hi bɪˈkeɪm ˈfeɪməs ˈəʊnli ˈleɪtə",
        translation: "He became famous only later",
      },
      {
        id: 23,
        expression: "Мы в основном общались по электронной почте",
        transcription: "wi ˈməʊstli kəˈmjuːnɪkeɪtɪd baɪ ˈiːmeɪl",
        translation: "We mostly communicated by email",
      },
      {
        id: 24,
        expression: "Как ты обычно добирался до работы?",
        transcription: "haʊ dɪd jʊ ˈjuːʒʊəli gɛt tə wɜːk?",
        translation: "How did you usually get to work?",
      },
      {
        id: 25,
        expression: "Они боролись за справедливость",
        transcription: "ðeɪ fɔːt fə ˈʤʌstɪs",
        translation: "They fought for justice",
      },
      {
        id: 26,
        expression: "Ты хочешь есть?",
        transcription: "dʊ jʊ wɒnt tʊ iːt?",
        translation: "Do you want to eat?",
      },
      {
        id: 27,
        expression: "Я принял их приглашение",
        transcription: "aɪ əkˈsɛptɪd ðeər ˌɪnvɪˈteɪʃən",
        translation: "I accepted their invitation",
      },
      {
        id: 28,
        expression: "Они перестали сражаться",
        transcription: "ðeɪ stɒpt ˈfaɪtɪŋ",
        translation: "They stopped fighting",
      },
      {
        id: 29,
        expression: "Это может испортить наши планы",
        transcription: "ɪt kən spɔːɪl ˈaʊə plænz",
        translation: "It can spoil our plans",
      },
      {
        id: 30,
        expression: "Конечно, я уважаю его точку зрения",
        transcription: "ˈsɜːtnli, aɪ rɪsˈpɛkt ɪz pɔɪnt əv vjuː",
        translation: "Certainly, I respect his point of view",
      },
      {
        id: 31,
        expression: "Они доставили мою посылку вчера",
        transcription: "ðeɪ dɪˈlɪvəd maɪ ˈpɑːsl ˈjɛstədeɪ",
        translation: "They delivered my parcel yesterday",
      },
      {
        id: 32,
        expression: "Я улыбнулся ей",
        transcription: "aɪ smaɪld ət hɜː",
        translation: "I smiled at her",
      },
      {
        id: 33,
        expression: "Они боролись за свою свободу и независимость",
        transcription: "ðeɪ fɔːt fə ˈfriːdəm ənd ˌɪndɪˈpɛndəns",
        translation: "They fought for freedom and independence",
      },
      {
        id: 34,
        expression: "Мне нужно заполнить пробелы сейчас",
        transcription: "aɪ niːd tə fɪl ɪn ðə gæps naʊ",
        translation: "I need to fill in the gaps now",
      },
      {
        id: 35,
        expression: "Ты принял его предложение?",
        transcription: "dɪd jʊ əkˈsɛpt ɪz ˈɒfə?",
        translation: "Did you accept his offer?",
      },
      {
        id: 36,
        expression: "Я уважаю этого человека",
        transcription: "aɪ rɪsˈpɛkt ðɪs ˈpɜːsn",
        translation: "I respect this person",
      },
      {
        id: 37,
        expression: "Они поймали вора?",
        transcription: "dɪd ðeɪ kæʧ ðə θiːf?",
        translation: "Did they catch the thief?",
      },
      {
        id: 38,
        expression: "Не теряй терпение!",
        transcription: "dəʊnt luːz ˈpeɪʃəns!",
        translation: "Don't lose patience!",
      },
      {
        id: 39,
        expression: "Ты заметил их реакцию?",
        transcription: "dɪd jʊ ˈnəʊtɪs ðeə ri(ː)ˈækʃən?",
        translation: "Did you notice their reaction?",
      },
      {
        id: 40,
        expression: "Ты получил мое электронное письмо?",
        transcription: "dɪd jʊ rɪˈsiːv maɪ ˈiːmeɪl?",
        translation: "Did you receive my email?",
      },
      {
        id: 41,
        expression:
          "Я постараюсь сделать все возможное, чтобы достичь следующего уровня английского языка как можно скорее",
        transcription:
          "aɪl traɪ tə dʊ maɪ bɛst tə riːʧ ðə nɛkst ˈɪŋglɪʃ ˈlɛvl əz suːn əz ˈpɒsəbl",
        translation:
          "I'll try to do my best to reach the next English level as soon as possible",
      },
      {
        id: 42,
        expression: "Она на специальной диете, так что она не ест много",
        transcription: "ʃiːz ɒn ə ˈspɛʃəl ˈdaɪət səʊ ʃiː dʌznt iːt mʌʧ",
        translation: "She's on a special diet so she doesn't eat much",
      },
      {
        id: 43,
        expression: "Я согласен с утверждением, что наш успех зависит от нас",
        transcription:
          "aɪ əˈgriː wɪð ðə ˈsteɪtmənt ðət ˈaʊə səkˈsɛs dɪˈpɛndz ɒn ʌs",
        translation:
          "I agree with the statement that our success depends on us",
      },
      {
        id: 44,
        expression: "Определенно, я рекомендую посетить это место",
        transcription: "ˈdɛfɪnɪtli, aɪ ˌrɛkəˈmɛnd ˈvɪzɪtɪŋ ðɪs pleɪs",
        translation: "Definitely, I recommend visiting this place",
      },
      {
        id: 45,
        expression: "Эта больница предоставляет лучший медицинский уход",
        transcription: "ðɪs ˈhɒspɪtl prəˈvaɪdz ðə bɛst ˈmɛdɪkəl keə",
        translation: "This hospital provides the best medical care",
      },
      {
        id: 46,
        expression: "Учитель попросил мальчиков перестать говорить",
        transcription: "ðə ˈtiːʧər ɑːskt ðə bɔɪz tə stɒp ˈtɔːkɪŋ",
        translation: "The teacher asked the boys to stop talking",
      },
      {
        id: 47,
        expression: "Плохая погода испортила наши планы",
        transcription: "bæd ˈwɛðə spɔɪlt ˈaʊə plænz",
        translation: "Bad weather spoilt our plans",
      },
      {
        id: 48,
        expression: "Она простила его, только позже",
        transcription: "ʃi fəˈgeɪv ɪm ˈəʊnli ˈleɪtə",
        translation: "She forgave him only later",
      },
      {
        id: 49,
        expression: "Я забыл, что мне нужно было сделать это",
        transcription: "aɪ fəˈgɒt ðət aɪ ˈniːdɪd tə dʊ ɪt",
        translation: "I forgot that I needed to do it",
      },
      {
        id: 50,
        expression: "Ей было трудно простить его",
        transcription: "ɪt wəz ˈdɪfɪkəlt fə hə tə fəˈgɪv hɪm",
        translation: "It was difficult for her to forgive him",
      },
      {
        id: 51,
        expression: "Почему она начала плакать?",
        transcription: "waɪ dɪd ʃi stɑːt ˈkraɪɪŋ?",
        translation: "Why did she start crying?",
      },
      {
        id: 52,
        expression: "Я бы отказался это сделать",
        transcription: "aɪd ˌriːˈfjuːz tə dʊ ɪt",
        translation: "I'd refuse to do it",
      },
      {
        id: 53,
        expression: "Я сделал все возможное, чтобы достичь этой цели",
        transcription: "aɪ dɪd maɪ bɛst tʊ əˈʧiːv ðɪs eɪm",
        translation: "I did my best to achieve this aim",
      },
      {
        id: 54,
        expression: "Мне удалось улучшить свои результаты",
        transcription: "aɪ ˈmænɪʤd tʊ ɪmˈpruːv maɪ rɪˈzʌlts",
        translation: "I managed to improve my results",
      },
      {
        id: 55,
        expression: "Я ожидал лучшего результата",
        transcription: "aɪ ɪksˈpɛktɪd ə ˈbɛtə rɪˈzʌlt",
        translation: "I expected a better result",
      },
      {
        id: 56,
        expression: "Он упал с лошади",
        transcription: "hi fɛl ɒf ə hɔːs",
        translation: "He fell off a horse",
      },
      {
        id: 57,
        expression: "Он упал на землю",
        transcription: "hi fɛl tə ðə graʊnd",
        translation: "He fell to the ground",
      },
      {
        id: 58,
        expression: "Она упала с этой лошади",
        transcription: "ʃi fɛl ɒf ðɪs hɔːs",
        translation: "She fell off this horse",
      },
      {
        id: 59,
        expression: "Урок длился около двух часов",
        transcription: "ðə ˈlɛsn ˈlɑːstɪd fər əˈbaʊt tuː ˈaʊəz",
        translation: "The lesson lasted for about two hours",
      },
      {
        id: 60,
        expression: "Этот фильм длился около двух часов",
        transcription: "ðɪs ˈmuːvi ˈlɑːstɪd fər əˈbaʊt tuː ˈaʊəz",
        translation: "This movie lasted for about two hours",
      },
      {
        id: 61,
        expression: "Урок начался два часа назад",
        transcription: "ðə ˈlɛsn ˈstɑːtɪd tuː ˈaʊəz əˈgəʊ",
        translation: "The lesson started two hours ago",
      },
      {
        id: 62,
        expression: "Он ненавидит вставать так рано",
        transcription: "hi heɪts ˈgɛtɪŋ ʌp səʊ ˈɜːli",
        translation: "He hates getting up so early",
      },
      {
        id: 63,
        expression: "Он ненавидит приходить домой так поздно",
        transcription: "hi heɪts ˈkʌmɪŋ həʊm səʊ leɪt",
        translation: "He hates coming home so late",
      },
      {
        id: 64,
        expression: "Я ненавижу просыпаться так рано",
        transcription: "aɪ heɪt ˈweɪkɪŋ ʌp səʊ ˈɜːli",
        translation: "I hate waking up so early",
      },
      {
        id: 65,
        expression: "Ты придешь к нам сегодня?",
        transcription: "wɪl jʊ kʌm tʊ əs təˈdeɪ?",
        translation: "Will you come to us today?",
      },
      {
        id: 66,
        expression: "Я скоро к тебе приду",
        transcription: "aɪl kʌm tə jʊ suːn",
        translation: "I'll come to you soon",
      },
      {
        id: 67,
        expression: "Она пришла к нему вчера?",
        transcription: "dɪd ʃi kʌm tə ɪm ˈjɛstədeɪ?",
        translation: "Did she come to him yesterday?",
      },
      {
        id: 68,
        expression: "Определенно, я рекомендую сходить в то место",
        transcription: "ˈdɛfɪnɪtli, aɪ ˌrɛkəˈmɛnd ˈgəʊɪŋ tə ðət pleɪs",
        translation: "Definitely, I recommend going to that place",
      },
      {
        id: 69,
        expression: "Конечно, я рекомендую сходить в это место",
        transcription: "əv kɔːs, aɪ ˌrɛkəˈmɛnd ˈvɪzɪtɪŋ ðət pleɪs",
        translation: "Of course, I recommend visiting that place",
      },
      {
        id: 70,
        expression: "Определенно, я рекомендую посетить то место",
        transcription: "ˈdɛfɪnɪtli, aɪ ˌrɛkəˈmɛnd ˈvɪzɪtɪŋ ðət pleɪs",
        translation: "Definitely, I recommend visiting that place",
      },
      {
        id: 71,
        expression: "Я ожидаю, что мне придется сделать это однажды",
        transcription: "aɪ ɪksˈpɛkt ðət aɪl həv tə dʊ ɪt wʌn deɪ",
        translation: "I expect that I'll have to do it one day",
      },
      {
        id: 72,
        expression: "Я думаю, что я сделаю это однажды",
        transcription: "aɪ θɪŋk ðət aɪl dʊ ɪt wʌn deɪ",
        translation: "I think that I'll do it one day",
      },
      {
        id: 73,
        expression: "Я ожидаю, что смогу сделать это однажды",
        transcription: "aɪ ɪksˈpɛkt ðət aɪl bi ˈeɪbl tə dʊ ɪt wʌn deɪ",
        translation: "I expect that I'll be able to do it one day",
      },
      {
        id: 74,
        expression: "Я думаю, что ему следует прекратить пить алкоголь",
        transcription: "aɪ θɪŋk ðət hi ʃəd stɒp ˈdrɪŋkɪŋ ˈælkəhɒl",
        translation: "I think that he should stop drinking alcohol",
      },
      {
        id: 75,
        expression: "Я думаю, тебе следует прекратить курить",
        transcription: "aɪ θɪŋk jʊ ʃəd stɒp ˈsməʊkɪŋ",
        translation: "I think you should stop smoking",
      },
      {
        id: 76,
        expression: "Я думаю, что ей следует прекратить пить алкоголь",
        transcription: "aɪ θɪŋk ðət ʃi ʃəd stɒp ˈdrɪŋkɪŋ ˈælkəhɒl",
        translation: "I think that she should stop drinking alcohol",
      },
      {
        id: 77,
        expression: "Наш курс длился более трех месяцев",
        transcription: "ˈaʊə kɔːs ˈlɑːstɪd mɔː ðən θriː mʌnθs",
        translation: "Our course lasted more than three months",
      },
      {
        id: 78,
        expression: "Этот курс длился три месяца",
        transcription: "ðɪs kɔːs ˈlɑːstɪd fə θriː mʌnθs",
        translation: "This course lasted for three months",
      },
      {
        id: 79,
        expression: "Этот курс будет длиться более двух месяцев",
        transcription: "ðɪs kɔːs wɪl lɑːst fə mɔː ðən tuː mʌnθs",
        translation: "This course will last for more than two months",
      },
      {
        id: 80,
        expression: "Ты жаловался на эту вещь?",
        transcription: "dɪd jʊ kəmˈpleɪn əˈbaʊt ðɪs θɪŋ?",
        translation: "Did you complain about this thing?",
      },
      {
        id: 81,
        expression: "Он жаловался на эту вещь?",
        transcription: "dɪd hi kəmˈpleɪn əˈbaʊt ðɪs θɪŋ?",
        translation: "Did he complain about this thing?",
      },
      {
        id: 82,
        expression: "Я часто жаловался на эту вещь",
        transcription: "aɪ ˈɒf(ə)n kəmˈpleɪnd əˈbaʊt ðɪs θɪŋ",
        translation: "I often complained about this thing",
      },
      {
        id: 83,
        expression: "Он прыгнул в озеро",
        transcription: "hi ʤʌmpt ˈɪntə ðə leɪk",
        translation: "He jumped into the lake",
      },
      {
        id: 84,
        expression: "Он прыгнул в реку",
        transcription: "hi ʤʌmpt ˈɪntə ðə ˈrɪvə",
        translation: "He jumped into the river",
      },
      {
        id: 85,
        expression: "Он переплыл озеро",
        transcription: "hi swæm əˈkrɒs ðə leɪk",
        translation: "He swam across the lake",
      },
      {
        id: 86,
        expression: "Письма внезапно исчезли",
        transcription: "ðə ˈlɛtəz ˌdɪsəˈpɪəd ɔːl əv ə ˈsʌdn",
        translation: "The letters disappeared all of a sudden",
      },
      {
        id: 87,
        expression: "Он часто жаловался на что-то",
        transcription: "hi ˈɒf(ə)n kəmˈpleɪnd əˈbaʊt ˈsʌmθɪŋ",
        translation: "He often complained about something",
      },
      {
        id: 88,
        expression: "Я сделал бы это немного по-другому",
        transcription: "aɪd dʊ ɪt ə ˈlɪtl bɪt ˈdɪfrəntli",
        translation: "I'd do it a little bit differently",
      },
      {
        id: 89,
        expression: "Его тетрадь упала со стола",
        transcription: "hɪz ˈkɒpɪbʊk fɛl ɒf ðə ˈteɪbl",
        translation: "His copybook fell off the table",
      },
      {
        id: 90,
        expression:
          "Я постараюсь сделать все возможное, чтобы добиться этой цели",
        transcription: "aɪl traɪ tə dʊ maɪ bɛst tʊ əˈʧiːv ðɪs eɪm",
        translation: "I'll try to do my best to achieve this aim",
      },
      {
        id: 91,
        expression: "Ты знаешь, как добраться до этого места?",
        transcription: "dʊ jʊ nəʊ haʊ tə gɛt tə ðɪs pleɪs?",
        translation: "Do you know how to get to this place?",
      },
      {
        id: 92,
        expression: "Она не могла перестать плакать",
        transcription: "ʃi ˈkʊdnt stɒp ˈkraɪɪŋ",
        translation: "She couldn't stop crying",
      },
      {
        id: 93,
        expression: "Она ест очень мало, потому что она на диете",
        transcription: "ʃi iːts ˈvɛri ˈlɪtl bɪˈkəz ʃiːz ɒn ə ˈdaɪət",
        translation: "She eats very little because she's on a diet",
      },
      {
        id: 94,
        expression: "Ему следует заботиться о своем здоровье",
        transcription: "hi ʃəd lʊk ˈɑːftə hɪz hɛlθ",
        translation: "He should look after his health",
      },
      {
        id: 95,
        expression: "Я перестал думать об этом",
        transcription: "aɪ stɒpt ˈθɪŋkɪŋ əˈbaʊt ɪt",
        translation: "I stopped thinking about it",
      },
      {
        id: 96,
        expression: "Еда пахнет ужасно",
        transcription: "ðə fuːd smɛlz ˈɔːfʊl",
        translation: "The food smells awful",
      },
      {
        id: 97,
        expression: "Он исчез внезапно",
        transcription: "hi ˌdɪsəˈpɪəd ɔːl əv ə ˈsʌdn",
        translation: "He disappeared all of a sudden",
      },
      {
        id: 98,
        expression: "Я забыл, что говорить",
        transcription: "aɪ fəˈgɒt wɒt tə seɪ",
        translation: "I forgot what to say",
      },
      {
        id: 99,
        expression: "Он прыгнул в бассейн",
        transcription: "hi ʤʌmpt ˈɪntə ðə puːl",
        translation: "He jumped into the pool",
      },
      {
        id: 100,
        expression: "Это пахнет очень хорошо",
        transcription: "ɪt smɛlz ˈvɛri gʊd",
        translation: "It smells very good",
      },
      {
        id: 101,
        expression: "Он страдает от этой болезни",
        transcription: "hi ˈsʌfəz frəm ðɪs dɪˈziːz",
        translation: "He suffers from this disease",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Закрепление пройденного материала</h3>
</div>`,
    },
  ],
];
