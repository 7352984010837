export const wordsLesson101 = [
  [
    {
      id: 1,
      word: 'angry about something',
      transcription: 'ˈæŋgri əˈbaʊt ˈsʌmθɪŋ',
      translation: 'зол на что-то',
    },
    {
      id: 2,
      word: 'angry with someone',
      transcription: 'ˈæŋgri wɪð ˈsʌmwʌn',
      translation: 'зол на кого-то',
    },
    {
      id: 3,
      word: 'afraid of / afraid to do something',
      transcription: 'əˈfreɪd ɒv / əˈfreɪd tə dʊ ˈsʌmθɪŋ',
      translation: 'бояться чего-то / бояться делать что-то',
    },
    {
      id: 4,
      word: 'proud of',
      transcription: 'praʊd ɒv',
      translation: 'гордиться чем-то / xtv-nj',
    },
    {
      id: 5,
      word: 'sorry about',
      transcription: 'ˈsɒri əˈbaʊt / fɔː',
      translation: 'сожалеть о чем-то',
    },
    {
      id: 6,
      word: 'interested in',
      transcription: 'ˈɪntrɪstɪd ɪn',
      translation: 'быть заинтересованным в чем-то',
    },
    {
      id: 7,
      word: 'good at',
      transcription: 'gʊd æt',
      translation: 'хорош в чем-то',
    },
    {
      id: 8,
      word: 'bad at',
      transcription: 'bæd æt',
      translation: 'плох в чем-то',
    },
    {
      id: 9,
      word: 'full of',
      transcription: 'fʊl ɒv',
      translation: 'полон чего-то',
    },
    {
      id: 10,
      word: 'fond of',
      transcription: 'fɒnd ɒv',
      translation: 'любить, обожать что-то',
    },
    {
      id: 11,
      word: 'ready for / ready to do something',
      transcription: 'ˈrɛdi fɔː / ˈrɛdi tə dʊ ˈsʌmθɪŋ',
      translation: 'готов для, готов к чему-то / готов делать что-то',
    },
    {
      id: 12,
      word: 'addicted to',
      transcription: 'əˈdɪktɪd tʊ',
      translation: 'зависеть от чего-то',
    },
    {
      id: 13,
      word: 'married to',
      transcription: 'ˈmærɪd tʊ',
      translation: 'женат на, замужем за',
    },
    {
      id: 14,
      word: "it's very kind of you",
      transcription: 'ɪts ˈvɛri kaɪnd əv juː',
      translation: 'Это очень любезно с твоей стороны',
    },
    {
      id: 15,
      word: "it's very nice to you",
      transcription: 'ɪts ˈvɛri naɪs tə juː',
      translation: 'Это очень мило с твоей стороны',
    },
    {
      id: 16,
      word: 'someone is kind to (me)',
      transcription: 'ˈsʌmwʌn z kaɪnd tʊ (miː)',
      translation: 'Кто-то добр ко (мне)',
    },
    {
      id: 17,
      word: 'someone is nice to (me)',
      transcription: 'ˈsʌmwʌn z naɪs tʊ (miː)',
      translation: 'Кто-то мил ко мне',
    },
    {
      id: 18,
      word: 'keen on',
      transcription: 'kiːn ɒn',
      translation: 'увлекаться чем-то',
    },
    {
      id: 19,
      word: 'fed up with',
      transcription: 'fɛd ʌp wɪð',
      translation: 'сыт по горло чем-то',
    },
    {
      id: 20,
      word: 'darkness',
      transcription: 'ˈdɑːknɪs',
      translation: 'темнота',
    },
    {
      id: 21,
      word: 'drugs',
      transcription: 'drʌgz',
      translation: 'наркотики',
    },
    {
      id: 22,
      word: 'gambling',
      transcription: 'ˈgæmblɪŋ',
      translation: 'азартные игры',
    },
    {
      id: 23,
      word: 'ghost',
      transcription: 'gəʊst',
      translation: 'привидение',
    },
    {
      id: 24,
      word: 'heavily',
      transcription: 'ˈhɛvɪli',
      translation: 'сильно',
    },
    {
      id: 25,
      word: 'nice',
      transcription: 'naɪs',
      translation: 'мило',
    },
    {
      id: 26,
      word: 'optimism',
      transcription: 'ˈɒptɪmɪzm',
      translation: 'оптимизм',
    },
    {
      id: 27,
      word: 'pity',
      transcription: 'ˈpɪti',
      translation: 'жаль',
    },
    {
      id: 28,
      word: 'ruin',
      transcription: 'rʊɪn',
      translation: 'разрушать',
    },
    {
      id: 29,
      word: 'spider',
      transcription: 'ˈspaɪdə',
      translation: 'паук',
    },
    {
      id: 30,
      word: 'vacancy',
      transcription: 'ˈveɪkənsi',
      translation: 'вакансия',
    },
  ],
];
