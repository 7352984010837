export const contentLesson188 = [
  [
    {
      title:
        'Урок 188. 450 Adjectives with different tenses and structures. Part 7',
    },
    [
      {
        id: 1,
        expression: 'Это выражение звучит более формально',
        transcription: 'ðɪs ɪksˈprɛʃən saʊndz mɔː ˈfɔːməl',
        translation: 'This expression sounds more formal',
      },
      {
        id: 2,
        expression: 'Они хотят видеть более точные цифры',
        transcription: 'ðeɪ wɒnt tə siː mɔːr ɪgˈzækt ˈfɪgəz',
        translation: 'They want to see more exact figures',
      },
      {
        id: 3,
        expression: 'Площадь около пятидесяти квадратных метров',
        transcription:
          'ði ˈeərɪə z əˈraʊnd (əˈbaʊt, əˈprɒksɪmɪtli) ˈfɪfti skweə ˈmiːtəz',
        translation:
          'The area is around (about, approximately) fifty square metres',
      },
      {
        id: 4,
        expression: 'У нее очень узкая талия',
        transcription: 'ʃi həz ə ˈvɛri ˈnærəʊ weɪst',
        translation: 'She has a very narrow waist',
      },
      {
        id: 5,
        expression: 'Они провели клиническое исследование',
        transcription: 'ðeɪ kənˈdʌktɪd (ˈkærid aʊt) ˈklɪnɪkəl rɪˈsɜːʧ',
        translation: 'They conducted (carried out) clinical research',
      },
      {
        id: 6,
        expression: 'Я стараюсь есть свежие фрукты и овощи',
        transcription: 'aɪ traɪ tʊ iːt frɛʃ fruːt ənd ˈvɛʤtəb(ə)lz',
        translation: 'I try to eat fresh fruit and vegetables',
      },
      {
        id: 7,
        expression: 'Она хочет чувствовать себя более независимой',
        transcription: 'ʃi wɒnts tə fiːl mɔːr ˌɪndɪˈpɛndənt',
        translation: 'She wants to feel more independent',
      },
      {
        id: 8,
        expression: 'Он испытал очень неприятное чувство',
        transcription: 'hi ɪksˈpɪərɪənst ə ˈvɛri ʌnˈplɛznt ˈfiːlɪŋ',
        translation: 'He experienced a very unpleasant feeling',
      },
      {
        id: 9,
        expression: 'Я хотел бы быть более точным',
        transcription: 'aɪd laɪk tə bi mɔːr ɪgˈzækt',
        translation: "I'd like to be more exact",
      },
      {
        id: 10,
        expression: 'Это находится перед этим зданием',
        transcription:
          'ɪts ɪn frʌnt əv ðɪs ˈbɪldɪŋ / ɪts ləʊˈkeɪtɪd (ˈsɪtjʊeɪtɪd) ɪn frʌnt əv ðɪs ˈbɪldɪŋ',
        translation:
          "It's in front of this building / It's located (situated) in front of this building",
      },
      {
        id: 11,
        expression: 'Это стандартная процедура',
        transcription: 'ɪts ə ˈstændəd prəˈsiːʤə',
        translation: "It's a standard procedure",
      },
      {
        id: 12,
        expression: 'У него сильный характер',
        transcription: 'hi həz ə strɒŋ ˈkærɪktə',
        translation: 'He has a strong character',
      },
      {
        id: 13,
        expression: 'Комната около двадцати квадратных метров',
        transcription: 'ðə ruːm z əˈraʊnd ˈtwɛnti skweə ˈmiːtəz',
        translation: 'The room is around twenty square metres',
      },
      {
        id: 14,
        expression: 'Этот вопрос кажется довольно философским',
        transcription: 'ðɪs ˈɪʃuː siːmz kwaɪt ˌfɪləˈsɒfɪkəl',
        translation: 'This issue seems quite philosophical',
      },
      {
        id: 15,
        expression: 'Это очень узкая дорога',
        transcription: 'ɪts ə ˈvɛri ˈnærəʊ rəʊd',
        translation: "It's a very narrow road",
      },
      {
        id: 16,
        expression: 'Это пульт дистанционного управления',
        transcription: 'ɪts ə rɪˈməʊt kənˈtrəʊl',
        translation: "It's a remote control",
      },
      {
        id: 17,
        expression: 'Что не так?',
        transcription: 'wɒts rɒŋ?',
        translation: "What's wrong?",
      },
      {
        id: 18,
        expression: 'Он очень молод и наивен',
        transcription: 'hiːz ˈvɛri jʌŋ ənd nɑːˈiːv',
        translation: "He's very young and naive",
      },
      {
        id: 19,
        expression: 'Это был научный эксперимент',
        transcription: 'ɪt wəz ə ˌsaɪənˈtɪfɪk ɪksˈpɛrɪmənt',
        translation: 'It was a scientific experiment',
      },
      {
        id: 20,
        expression: 'Это ложь!',
        transcription: 'ðæts fɔːls!',
        translation: "That's false!",
      },
      {
        id: 21,
        expression: 'Учитель недостаточно строгий',
        transcription: 'ðə ˈtiːʧər ˈɪznt strɪkt ɪˈnʌf',
        translation: "The teacher isn't strict enough",
      },
      {
        id: 22,
        expression: 'Мальчик такой худой',
        transcription: 'ðə bɔɪ z səʊ θɪn (slɪm)',
        translation: 'The boy is so thin (slim)',
      },
      {
        id: 23,
        expression: 'Это твое окончательное решение?',
        transcription: 'ɪz ɪt jə ˈfaɪnl dɪˈsɪʒən?',
        translation: 'Is it your final decision?',
      },
      {
        id: 24,
        expression: 'Он очень строгий учитель',
        transcription: 'hiːz ə ˈvɛri strɪkt ˈtiːʧə',
        translation: "He's a very strict teacher",
      },
      {
        id: 25,
        expression: 'Он выглядел очень бледным и худым',
        transcription: 'hi lʊkt ˈvɛri peɪl ənd θɪn',
        translation: 'He looked very pale and thin',
      },
      {
        id: 26,
        expression: 'Погода была действительно ужасная',
        transcription: 'ðə ˈwɛðə wəz ˈrɪəli ˈtɛrəbl',
        translation: 'The weather was really terrible',
      },
      {
        id: 27,
        expression: 'Это философский вопрос',
        transcription: 'ɪts ə ˌfɪləˈsɒfɪkəl ˈɪʃuː',
        translation: "It's a philosophical issue",
      },
      {
        id: 28,
        expression: 'Он очень позитивный человек',
        transcription: 'hiːz ə ˈvɛri ˈpɒzətɪv ˈpɜːsn',
        translation: "He's a very positive person",
      },
      {
        id: 29,
        expression: 'Какое красивое место!',
        transcription: 'wɒt ə ˈbjuːtəfʊl pleɪs!',
        translation: 'What a beautiful place!',
      },
      {
        id: 30,
        expression: 'Это огромное здание',
        transcription: 'ɪts ən ɪˈnɔːməs ˈbɪldɪŋ',
        translation: "It's an enormous building",
      },
      {
        id: 31,
        expression: 'Она купила очень красивое красное платье',
        transcription: 'ʃi bɔːt ə ˈvɛri ˈbjuːtəfʊl rɛd drɛs',
        translation: 'She bought a very beautiful red dress',
      },
      {
        id: 32,
        expression: 'У нее такой красивый голос',
        transcription: 'ʃi həz sʌʧ ə ˈbjuːtəfʊl vɔɪs',
        translation: 'She has such a beautiful voice',
      },
      {
        id: 33,
        expression: 'Качество этого продукта ниже стандарта',
        transcription: 'ðə ˈkwɒlɪti əv ðɪs ˈprɒdʌkt s bɪˈləʊ ˈstændəd',
        translation: 'The quality of this product is below standard',
      },
      {
        id: 34,
        expression: 'Их поведение кажется таким необычным',
        transcription: 'ðeə bɪˈheɪvjə siːmz səʊ ʌnˈjuːʒʊəl',
        translation: 'Their behaviour seems so unusual',
      },
      {
        id: 35,
        expression: 'Эти условия совсем неприемлемы',
        transcription: 'ðiːz kənˈdɪʃənz ɑːnt əkˈsɛptəbl ət ɔːl',
        translation: "These conditions aren't acceptable at all",
      },
      {
        id: 36,
        expression: 'Это был клинический эксперимент',
        transcription: 'ɪt wəz ə ˈklɪnɪkəl ɪksˈpɛrɪmənt',
        translation: 'It was a clinical experiment',
      },
      {
        id: 37,
        expression: 'Это очень редкое слово',
        transcription: 'ɪts ə ˈvɛri reə wɜːd',
        translation: "It's a very rare word",
      },
      {
        id: 38,
        expression: 'У нее такой сильный акцент',
        transcription: 'ʃi həz sʌʧ ə strɒŋ ˈæksənt',
        translation: 'She has such a strong accent',
      },
      {
        id: 39,
        expression: 'Во сколько ты завтра свободен?',
        transcription: 'wɒt taɪm ə jʊ friː təˈmɒrəʊ?',
        translation: 'What time are you free tomorrow?',
      },
      {
        id: 40,
        expression: 'Офис находится в западной части города',
        transcription: 'ði ˈɒfɪs ɪz ləʊˈkeɪtɪd ɪn ðə ˈwɛstən pɑːt əv ðə ˈsɪti',
        translation: 'The office is located in the western part of the city',
      },
      {
        id: 41,
        expression: 'Он предоставил нам дополнительную информацию',
        transcription: 'hi prəˈvaɪdɪd əs wɪð əˈdɪʃənl ˌɪnfəˈmeɪʃən',
        translation: 'He provided us with additional information',
      },
      {
        id: 42,
        expression: 'Это одно из самых выдающихся недавних открытий',
        transcription: 'ɪts wʌn əv ðə məʊst aʊtˈstændɪŋ ˈriːsnt dɪsˈkʌvəriz',
        translation: "It's one of the most outstanding recent discoveries",
      },
      {
        id: 43,
        expression: 'Я думаю, это потребует дополнительных ресурсов',
        transcription: 'aɪ θɪŋk ˈɪtl niːd əˈdɪʃənl rɪˈsɔːsɪz',
        translation: "I think it'll need additional resources",
      },
      {
        id: 44,
        expression: 'Часто трудно быть достаточно объективным',
        transcription: 'ɪts ˈɒf(ə)n ˈdɪfɪkəlt tə bi əbˈʤɛktɪv',
        translation: "It's often difficult to be objective",
      },
      {
        id: 45,
        expression:
          'Он пытается быть более позитивным, даже если сейчас это не так легко',
        transcription:
          'hi traɪz tə bi mɔː ˈpɒzətɪv ˈiːvən ðəʊ ɪt ˈɪznt səʊ ˈiːzi naʊ',
        translation:
          "He tries to be more positive even though it isn't so easy now",
      },
      {
        id: 46,
        expression: 'Я стараюсь быть настолько объективным, насколько возможно',
        transcription: 'aɪ traɪ tə bi əz əbˈʤɛktɪv əz ˈpɒsəbl',
        translation: 'I try to be as objective as possible',
      },
      {
        id: 47,
        expression:
          'Этому также следует быть доступным для людей, которые живут в отдаленных местах',
        transcription:
          'ɪt ʃəd ˈɔːlsəʊ bi əˈveɪləbl fə ˈpiːpl huː lɪv ɪn rɪˈməʊt ˈpleɪsɪz',
        translation:
          'It should also be available for people who live in remote places',
      },
      {
        id: 48,
        expression: 'Недавний опрос показал следующее',
        transcription: 'ə ˈriːsnt ˈsɜːveɪ ʃəʊd ðə ˈfɒləʊɪŋ',
        translation: 'A recent survey showed the following',
      },
      {
        id: 49,
        expression: 'Он не мотивирован работать так интенсивно',
        transcription: 'hi ˈɪznt ˈməʊtɪveɪtɪd tə wɜːk səʊ ɪnˈtɛnsɪvli',
        translation: "He isn't motivated to work so intensively",
      },
      {
        id: 50,
        expression: 'Я не думаю, что это действительно приемлемо',
        transcription: 'aɪ dəʊnt θɪŋk ðət ɪts ˈrɪəli əkˈsɛptəbl',
        translation: "I don't think that it's really acceptable",
      },
      {
        id: 51,
        expression: 'Я не думаю, что это законно делать',
        transcription: 'aɪ dəʊnt θɪŋk ðət ɪts ˈliːgəl tə dʊ ɪt',
        translation: "I don't think that it's legal to do it",
      },
      {
        id: 52,
        expression: 'Он очень эгоистичный и высокомерный человек',
        transcription: 'hiːz ə ˈvɛri ˈsɛlfɪʃ ənd ˈærəʊgənt ˈpɜːsn',
        translation: "He's a very selfish and arrogant person",
      },
      {
        id: 53,
        expression: 'Это расположено в западной части штата',
        transcription: 'ɪts ləʊˈkeɪtɪd ɪn ðə ˈwɛstən pɑːt əv ðə steɪt',
        translation: "It's located in the western part of the state",
      },
      {
        id: 54,
        expression: 'Ты знаешь, правда это или ложь?',
        transcription: 'dʊ jʊ nəʊ ˈwɛðər ɪts truː əv fɔːls?',
        translation: "Do you know whether it's true of false?",
      },
      {
        id: 55,
        expression: 'Это одно из величайших научных открытий',
        transcription: 'ɪts wʌn əv ðə ˈgreɪtɪst ˌsaɪənˈtɪfɪk dɪsˈkʌvəriz',
        translation: "It's one of the greatest scientific discoveries",
      },
      {
        id: 56,
        expression: 'Было ужасно видеть это',
        transcription: 'ɪt wəz ˈtɛrəbl tə siː ɪt',
        translation: 'It was terrible to see it',
      },
      {
        id: 57,
        expression: 'Было здорово видеть это',
        transcription: 'ɪt wəz greɪt tə siː ɪt',
        translation: 'It was great to see it',
      },
      {
        id: 58,
        expression: 'Было ужасно делать это',
        transcription: 'ɪt wəz ˈtɛrəbl tə dʊ ɪt',
        translation: 'It was terrible to do it',
      },
      {
        id: 59,
        expression: 'Они сидели в переднем ряду',
        transcription: 'ðeɪ sæt ɪn ðə frʌnt rəʊ',
        translation: 'They sat in the front row',
      },
      {
        id: 60,
        expression: 'Они сидели в первом ряду',
        transcription: 'ðeɪ sæt ɪn ðə fɜːst rəʊ',
        translation: 'They sat in the first row',
      },
      {
        id: 61,
        expression: 'Они сидели в последнем ряду',
        transcription: 'ðeɪ sæt ɪn ðə lɑːst rəʊ',
        translation: 'They sat in the last row',
      },
      {
        id: 62,
        expression: 'Чего он боялся?',
        transcription: 'wɒt wəz hi əˈfreɪd ɒv?',
        translation: 'What was he afraid of?',
      },
      {
        id: 63,
        expression: 'Он действительно боится этого?',
        transcription: 'ɪz hi ˈrɪəli əˈfreɪd əv ɪt?',
        translation: 'Is he really afraid of it?',
      },
      {
        id: 64,
        expression: 'Кого он боялся?',
        transcription: 'huː wəz hi əˈfreɪd ɒv?',
        translation: 'Who was he afraid of?',
      },
      {
        id: 65,
        expression: 'Кого она ревнует?',
        transcription: 'huː z ʃi ˈʤɛləs ɒv?',
        translation: 'Who is she jealous of?',
      },
      {
        id: 66,
        expression: 'Он очень ревнивый человек',
        transcription: 'hiːz ə ˈvɛri ˈʤɛləs ˈpɜːsn',
        translation: "He's a very jealous person",
      },
      {
        id: 67,
        expression: 'Ты ее ревнуешь?',
        transcription: 'ɑː jʊ ˈʤɛləs əv hɜː?',
        translation: 'Are you jealous of her?',
      },
      {
        id: 68,
        expression: 'Это очень эгоистично с его стороны',
        transcription: 'ɪts ˈvɛri ˈsɛlfɪʃ əv hɪm',
        translation: "It's very selfish of him",
      },
      {
        id: 69,
        expression: 'Это было действительно эгоистично с твоей стороны',
        transcription: 'ɪt wəz ˈrɪəli ˈsɛlfɪʃ əv jɔː',
        translation: 'It was really selfish of your',
      },
      {
        id: 70,
        expression: 'Она действительно эгоистичный человек',
        transcription: 'ʃiːz ə ˈrɪəli ˈsɛlfɪʃ ˈpɜːsn',
        translation: "She's a really selfish person",
      },
      {
        id: 71,
        expression: 'Боюсь, что ты ошибаешься',
        transcription: 'aɪm əˈfreɪd ðət jʊə mɪsˈteɪkən',
        translation: "I'm afraid that you're mistaken",
      },
      {
        id: 72,
        expression: 'Я думаю, что ты ошибался',
        transcription: 'aɪ θɪŋk ðət jʊ wə mɪsˈteɪkən',
        translation: 'I think that you were mistaken',
      },
      {
        id: 73,
        expression: 'Я ошибаюсь?',
        transcription: 'əm aɪ mɪsˈteɪkən?',
        translation: 'Am I mistaken?',
      },
      {
        id: 74,
        expression: 'Он купил букет красных роз',
        transcription: 'hi bɔːt ə bʌnʧ əv rɛd ˈrəʊzɪz',
        translation: 'He bought a bunch of red roses',
      },
      {
        id: 75,
        expression: 'Он принес букет белых роз',
        transcription: 'hi brɔːt ə bʌnʧ əv waɪt ˈrəʊzɪz',
        translation: 'He brought a bunch of white roses',
      },
      {
        id: 76,
        expression: 'Он дал мне букет розовых роз',
        transcription: 'hi geɪv mi ə bʌnʧ əv pɪŋk ˈrəʊzɪz',
        translation: 'He gave me a bunch of pink roses',
      },
      {
        id: 77,
        expression: 'У каждого есть свои законные права',
        transcription: 'ˈɛvrɪwʌn həz ðeə ˈliːgəl raɪts',
        translation: 'Everyone has their legal rights',
      },
      {
        id: 78,
        expression: 'Каждый имеет определенные права',
        transcription: 'ˈɛvrɪwʌn həz ˈsɜːtn raɪts',
        translation: 'Everyone has certain rights',
      },
      {
        id: 79,
        expression: 'У всех нас есть свои законные права',
        transcription: 'wi ɔːl həv ˈaʊə ˈliːgəl raɪts',
        translation: 'We all have our legal rights',
      },
      {
        id: 80,
        expression: 'Я сыт по горло этим постоянным шумом',
        transcription: 'aɪm fɛd ʌp wɪð ðɪs ˈkɒnstənt nɔɪz',
        translation: "I'm fed up with this constant noise",
      },
      {
        id: 81,
        expression: 'Я сыт по горло этой работой',
        transcription: 'aɪm fɛd ʌp wɪð ðɪs wɜːk',
        translation: "I'm fed up with this work",
      },
      {
        id: 82,
        expression: 'Я сыт по горло твоим поведением',
        transcription: 'aɪm fɛd ʌp wɪð jə bɪˈheɪvjə',
        translation: "I'm fed up with your behaviour",
      },
      {
        id: 83,
        expression: 'Было немного необычно видеть его там',
        transcription: 'ɪt wəz ə ˈlɪtl ʌnˈjuːʒʊəl tə siː ɪm ðeə',
        translation: 'It was a little unusual to see him there',
      },
      {
        id: 84,
        expression: 'Было действительно необычно видеть его здесь',
        transcription: 'ɪt wəz ˈrɪəli ʌnˈjuːʒʊəl tə siː ɪm hɪə',
        translation: 'It was really unusual to see him here',
      },
      {
        id: 85,
        expression: 'Было немного необычно встретить тебя там',
        transcription: 'ɪt wəz ə bɪt ʌnˈjuːʒʊəl tə miːt jʊ ðeə',
        translation: 'It was a bit unusual to meet you there',
      },
      {
        id: 86,
        expression: 'Ситуация не сильно улучшилась в последние годы',
        transcription: 'ðə ˌsɪtjʊˈeɪʃən ˈhæznt ɪmˈpruːvd mʌʧ ɪn ˈriːsnt jɪəz',
        translation: "The situation hasn't improved much in recent years",
      },
      {
        id: 87,
        expression: 'Очень неприятно видеть это',
        transcription: 'ɪts ˈvɛri ʌnˈplɛznt tə siː ɪt',
        translation: "It's very unpleasant to see it",
      },
      {
        id: 88,
        expression: 'В комнате недостаточно свежего воздуха',
        transcription: 'ðər ˈɪznt ɪˈnʌf frɛʃ eər ɪn ðə ruːm',
        translation: "There isn't enough fresh air in the room",
      },
      {
        id: 89,
        expression: 'Это иногда происходит в редких случаях',
        transcription: 'ɪt ˈsʌmtaɪmz teɪks pleɪs ɪn reə ˈkeɪsɪz',
        translation: 'It sometimes takes place in rare cases',
      },
      {
        id: 90,
        expression:
          'Конечно, я желаю, чтобы у меня было больше свободного времени',
        transcription: 'əv kɔːs, aɪ wɪʃ aɪ həd mɔː friː taɪm',
        translation: 'Of course, I wish I had more free time',
      },
      {
        id: 91,
        expression: 'Я не вижу в этом ничего плохого',
        transcription: 'aɪ siː ˈnʌθɪŋ rɒŋ əˈbaʊt ɪt',
        translation: 'I see nothing wrong about it',
      },
      {
        id: 92,
        expression: 'Ему пришлось заплатить огромную сумму денег',
        transcription: 'hi həd tə peɪ ən ɪˈnɔːməs sʌm əv ˈmʌni',
        translation: 'He had to pay an enormous sum of money',
      },
      {
        id: 93,
        expression: 'Чем он любит заниматься в свободное время?',
        transcription: 'wɒt dəz hi laɪk ˈdu(ː)ɪŋ ɪn ɪz friː taɪm?',
        translation: 'What does he like doing in his free time?',
      },
      {
        id: 94,
        expression:
          'Он чувствует себя очень мотивированным и готов работать сверхурочно',
        transcription:
          'hi fiːlz ˈvɛri ˈməʊtɪveɪtɪd ənd hiːz ˈrɛdi tə wɜːk ˈəʊvətaɪm',
        translation: "He feels very motivated and he's ready to work overtime",
      },
      {
        id: 95,
        expression: 'Он сыт по горло постоянными ошибками',
        transcription: 'hiːz fɛd ʌp wɪð ˈmeɪkɪŋ ˈkɒnstənt mɪsˈteɪks',
        translation: "He's fed up with making constant mistakes",
      },
      {
        id: 96,
        expression: 'Она очень ревнует своего мужа',
        transcription: 'ʃiːz ˈvɛri ˈʤɛləs əv hə ˈhʌzbənd',
        translation: "She's very jealous of her husband",
      },
      {
        id: 97,
        expression: 'Я еще не принял окончательное решение',
        transcription: 'aɪ hævnt meɪd ðə ˈfaɪnl dɪˈsɪʒən jɛt',
        translation: "I haven't made the final decision yet",
      },
      {
        id: 98,
        expression: 'Страна стала независимой от других стран',
        transcription: 'ðə ˈkʌntri bɪˈkeɪm ˌɪndɪˈpɛndənt əv ˈʌðə ˈkʌntriz',
        translation: 'The country became independent of other countries',
      },
      {
        id: 99,
        expression: 'Это очень типично для людей помладше',
        transcription: 'ɪts ˈvɛri ˈtɪpɪk(ə)l əv ˈjʌŋə ˈpiːpl',
        translation: "It's very typical of younger people",
      },
      {
        id: 100,
        expression: 'Это кресло намного удобнее',
        transcription: 'ðɪs ˈɑːmˈʧeə z mʌʧ mɔː ˈkʌmf(ə)təbl',
        translation: 'This armchair is much more comfortable',
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Закрепление пройденного материала</h3>
</div>`,
    },
  ],
];
