export const contentLesson164 = [
  [
    {
      title: 'Урок 164. Passive Voice in the Main Tenses',
    },
    [
      {
        id: 1,
        expression: 'Эта проблема обсуждается очень часто',
        transcription: 'ðɪs ˈprɒbləm z dɪsˈkʌst ˈvɛri ˈɒf(ə)n',
        translation: 'This problem is discussed very often',
      },
      {
        id: 2,
        expression: 'Этот файл был удален им',
        transcription: 'ðɪs faɪl wəz dɪˈliːtɪd baɪ hɪm',
        translation: 'This file was deleted by him',
      },
      {
        id: 3,
        expression: 'Меня любят',
        transcription: 'aɪm lʌvd',
        translation: "I'm loved",
      },
      {
        id: 4,
        expression: 'Эти документы подписывают каждый день',
        transcription: 'ðiːz ˈdɒkjʊmənts ə saɪnd ˈɛvri deɪ',
        translation: 'These documents are signed every day',
      },
      {
        id: 5,
        expression: 'Эту машину делают в Англии',
        transcription: 'ðɪs kɑː z meɪd ɪn ˈɪŋglənd',
        translation: 'This car is made in England',
      },
      {
        id: 6,
        expression: 'Деньги были украдены',
        transcription: 'ðə ˈmʌni wəz ˈstəʊlən',
        translation: 'The money was stolen',
      },
      {
        id: 7,
        expression: 'Преступники были арестованы',
        transcription: 'ðə ˈkrɪmɪnlz wər əˈrɛstɪd',
        translation: 'The criminals were arrested',
      },
      {
        id: 8,
        expression: 'Это будет сделано вовремя',
        transcription: 'ɪt wɪl bi dʌn ɒn taɪm',
        translation: 'It will be done on time',
      },
      {
        id: 9,
        expression: 'Эта проблема будет решена',
        transcription: 'ðɪs ˈprɒbləm wɪl bi sɒlvd',
        translation: 'This problem will be solved',
      },
      {
        id: 10,
        expression: 'Ее кошелек был найден',
        transcription: 'hə pɜːs wəz faʊnd',
        translation: 'Her purse was found',
      },
      {
        id: 11,
        expression: 'Новое исследование было проведено этими учеными',
        transcription:
          'ə njuː rɪˈsɜːʧ wəz kənˈdʌktɪd (ˈkærid aʊt) baɪ ðiːz ˈsaɪəntɪsts',
        translation:
          'A new research was conducted (carried out) by these scientists',
      },
      {
        id: 12,
        expression: 'Говорят, что это может произойти рано или поздно',
        transcription: 'ɪts sɛd ðət ɪt kən ˈhæpən ˈsuːnər ɔː ˈleɪtə',
        translation: "It's said that it can happen sooner or later",
      },
      {
        id: 13,
        expression: 'Где эти товары сделаны?',
        transcription: 'weər ə ðiːz gʊdz meɪd?',
        translation: 'Where are these goods made?',
      },
      {
        id: 14,
        expression: 'Эта книга была прочитана мной',
        transcription: 'ðɪs bʊk wəz riːd baɪ miː',
        translation: 'This book was read by me',
      },
      {
        id: 15,
        expression: 'Ее видели на вечеринке',
        transcription: 'ʃi wəz siːn ət ðə ˈpɑːti',
        translation: 'She was seen at the party',
      },
      {
        id: 16,
        expression: 'Считается, что такая вероятность существует',
        transcription:
          'ɪt s bɪˈliːvd (ɪt s kənˈsɪdəd), ðæt sʌʧ ə ˌpɒsəˈbɪlɪti ɪgˈzɪsts',
        translation:
          'It is believed (it is considered), that such a possibility exists',
      },
      {
        id: 17,
        expression: 'Эти работники были заменены',
        transcription: 'ðiːz ˈwɜːkəz wə rɪˈpleɪst',
        translation: 'These workers were replaced',
      },
      {
        id: 18,
        expression: 'Это будет показано по телевизору',
        transcription: 'ɪt wɪl bi ʃəʊn ɒn ˌtiːˈviː',
        translation: 'It will be shown on TV',
      },
      {
        id: 19,
        expression: 'Ему было дано предупреждение',
        transcription: 'hi wəz ˈgɪvn ə ˈwɔːnɪŋ',
        translation: 'He was given a warning',
      },
      {
        id: 20,
        expression: 'Он был приглашен на вечеринку',
        transcription: 'hi wəz ɪnˈvaɪtɪd tə ðə ˈpɑːti',
        translation: 'He was invited to the party',
      },
      {
        id: 21,
        expression: 'Мне показали дорогу до ближайшего супермаркета',
        transcription: 'aɪ wəz ʃəʊn ðə weɪ tə ðə ˈnɪərɪst ˈsjuːpəˌmɑːkɪt',
        translation: 'I was shown the way to the nearest supermarket',
      },
      {
        id: 22,
        expression: 'Это говорят везде',
        transcription: 'ɪt s sɛd ˈɛvrɪweə',
        translation: 'It is said everywhere',
      },
      {
        id: 23,
        expression: 'Все пассажиры были спасены',
        transcription: 'ɔːl ðə ˈpæsɪnʤəz wə ˈrɛskjuːd (seɪvd)',
        translation: 'All the passengers were rescued (saved)',
      },
      {
        id: 24,
        expression: 'Где это сделано?',
        transcription: 'weə wəz ɪt meɪd?',
        translation: 'Where was it made?',
      },
      {
        id: 25,
        expression: 'Письмо было получено им',
        transcription: 'ðə ˈlɛtə wəz rɪˈsiːvd baɪ hɪm',
        translation: 'The letter was received by him',
      },
      {
        id: 26,
        expression: 'Эта книга была написана очень известным писателем',
        transcription: 'ðɪs bʊk wəz ˈrɪtn baɪ ə ˈvɛri ˈfeɪməs ˈraɪtə',
        translation: 'This book was written by a very famous writer',
      },
      {
        id: 27,
        expression: 'Эти обвинения отвергаются',
        transcription: 'ðiːz ˌækju(ː)ˈzeɪʃ(ə)nz ə dɪˈnaɪd',
        translation: 'These accusations are denied',
      },
      {
        id: 28,
        expression: 'Деньги были украдены',
        transcription: 'ðə ˈmʌni wəz ˈstəʊlən',
        translation: 'The money was stolen',
      },
      {
        id: 29,
        expression: 'Все участники были награждены',
        transcription: 'ɔːl ðə pɑːˈtɪsɪpənts wər əˈwɔːdɪd',
        translation: 'All the participants were awarded',
      },
      {
        id: 30,
        expression: 'Эта деталь была заменена',
        transcription: 'ðɪs ˈdiːteɪl wəz rɪˈpleɪst',
        translation: 'This detail was replaced',
      },
      {
        id: 31,
        expression: 'Компьютеры продаются в разных магазинах',
        transcription: 'kəmˈpjuːtəz ə səʊld ɪn ˈdɪfrənt ʃɒps (stɔːz)',
        translation: 'Computers are sold in different shops (stores)',
      },
      {
        id: 32,
        expression: 'Письма были доставлены',
        transcription: 'ðə ˈlɛtəz wə dɪˈlɪvəd',
        translation: 'The letters were delivered',
      },
      {
        id: 33,
        expression: 'Это сделано в Японии?',
        transcription: 'ɪz ɪt meɪd ɪn ʤəˈpæn?',
        translation: 'Is it made in Japan?',
      },
      {
        id: 34,
        expression: 'Победители были награждены',
        transcription: 'ðə ˈwɪnəz wər əˈwɔːdɪd',
        translation: 'The winners were awarded',
      },
      {
        id: 35,
        expression: 'Эта книга была дана ей',
        transcription: 'ðɪs bʊk wəz ˈgɪvn tə hɜː',
        translation: 'This book was given to her',
      },
      {
        id: 36,
        expression: 'Его часто хвалят за это',
        transcription: 'hi z ˈɒf(ə)n preɪzd fər ɪt',
        translation: 'He is often praised for it',
      },
      {
        id: 37,
        expression: 'Там видели двух мужчин',
        transcription: 'tuː mɛn wə siːn ðeə',
        translation: 'Two men were seen there',
      },
      {
        id: 38,
        expression: 'Его бумажник был украден',
        transcription: 'hɪz ˈwɒlɪt wəz ˈstəʊlən',
        translation: 'His wallet was stolen',
      },
      {
        id: 39,
        expression: 'Дом был построен год назад',
        transcription: 'ðə haʊs wəz bɪlt ə jɪər əˈgəʊ',
        translation: 'The house was built a year ago',
      },
      {
        id: 40,
        expression: 'Первый ответ был выбран',
        transcription: 'ðə fɜːst ˈɑːnsə wəz ˈʧəʊzn',
        translation: 'The first answer was chosen',
      },
      {
        id: 41,
        expression: 'Это было упомянуто в новостях',
        transcription: 'ɪt wəz ˈmɛnʃənd ɪn ðə njuːz',
        translation: 'It was mentioned in the news',
      },
      {
        id: 42,
        expression: 'Это часто показывают по телевизору',
        transcription: 'ɪt s ˈɒf(ə)n ʃəʊn ɒn ˌtiːˈviː',
        translation: 'It is often shown on TV',
      },
      {
        id: 43,
        expression: 'Эта ошибка была исправлена',
        transcription: 'ðɪs mɪsˈteɪk wəz kəˈrɛktɪd',
        translation: 'This mistake was corrected',
      },
      {
        id: 44,
        expression: 'Не все участники были награждены',
        transcription: 'nɒt ɔːl ðə pɑːˈtɪsɪpənts wər əˈwɔːdɪd',
        translation: 'Not all the participants were awarded',
      },
      {
        id: 45,
        expression: 'Ошибки были исправлены',
        transcription: 'ðə mɪsˈteɪks wə kəˈrɛktɪd',
        translation: 'The mistakes were corrected',
      },
      {
        id: 46,
        expression: 'Моя домашняя работа была сделана',
        transcription: 'maɪ ˈhəʊmˌwɜːk wəz dʌn',
        translation: 'My homework was done',
      },
      {
        id: 47,
        expression: 'Ключи были найдены позже',
        transcription: 'ðə kiːz wə faʊnd ˈleɪtə',
        translation: 'The keys were found later',
      },
      {
        id: 48,
        expression: 'Где производятся эти товары?',
        transcription: 'weər ə ðiːz gʊdz prəˈdjuːst?',
        translation: 'Where are these goods produced?',
      },
      {
        id: 49,
        expression: 'Несколько новых экспериментов были проведены ими',
        transcription:
          'sʌm njuː ɪksˈpɛrɪmənts wə kənˈdʌktɪd (ˈkærid aʊt) baɪ ðɛm',
        translation:
          'Some new experiments were conducted (carried out) by them',
      },
      {
        id: 50,
        expression: 'Ни одна из этих ошибок не была исправлена',
        transcription: 'nʌn (nəʊ wʌn) əv ðiːz mɪsˈteɪks wɜː (wɒz) kəˈrɛktɪd',
        translation: 'None (no one) of these mistakes were (was) corrected',
      },
      {
        id: 51,
        expression: 'Ожидается, что он одобрит эту идею',
        transcription: 'ɪts ɪksˈpɛktɪd ðət hiːl əˈpruːv əv ðɪs aɪˈdɪə',
        translation: "It's expected that he'll approve of this idea",
      },
      {
        id: 52,
        expression: 'Проект был завершен на днях',
        transcription: 'ðə ˈprɒʤɛkt wəz kəmˈpliːtɪd ði ˈʌðə deɪ',
        translation: 'The project was completed the other day',
      },
      {
        id: 53,
        expression: 'Известно, что он против этого решения',
        transcription: 'ɪts nəʊn ðət hiːz əˈgɛnst ðɪs dɪˈsɪʒən',
        translation: "It's known that he's against this decision",
      },
      {
        id: 54,
        expression: 'Сообщается, что два человека были ранены во время аварии',
        transcription:
          'ɪt s rɪˈpɔːtɪd ðət tuː ˈpiːpl wər ˈɪnʤəd ɪn ði ˈæksɪdənt',
        translation:
          'It is reported that two people were injured in the accident',
      },
      {
        id: 55,
        expression: 'Научное исследование было проведено этой группой ученых',
        transcription:
          'ʃi ˌsaɪənˈtɪfɪk rɪˈsɜːʧ wəz kənˈdʌktɪd (ˈkærid aʊt) baɪ ðɪs gruːp əv ˈsaɪəntɪsts',
        translation:
          'She scientific research was conducted (carried out) by this group of scientists',
      },
      {
        id: 56,
        expression: 'Эту страну ежегодно посещают миллионы туристов',
        transcription:
          'ðɪs ˈkʌntri z ˈvɪzɪtɪd baɪ ˈmɪljənz əv ˈtʊərɪsts ˈjɪəli',
        translation: 'This country is visited by millions of tourists yearly',
      },
      {
        id: 57,
        expression: 'Город ежемесячно посещают тысячи туристов',
        transcription: 'ðə ˈsɪti z ˈvɪzɪtɪd baɪ ˈθaʊzəndz əv ˈtʊərɪsts ˈmʌnθli',
        translation: 'The city is visited by thousands of tourists monthly',
      },
      {
        id: 58,
        expression: 'Новый словарь был показан мне',
        transcription: 'ə njuː ˈdɪkʃ(ə)n(ə)ri wəz ʃəʊn tə miː',
        translation: 'A new dictionary was shown to me',
      },
      {
        id: 59,
        expression: 'Сообщается, что никто не был ранен в аварии',
        transcription: 'ɪts rɪˈpɔːtɪd ðət nəʊ wʌn wəz ˈɪnʤəd ɪn ði ˈæksɪdənt',
        translation: "It's reported that no one was injured in the accident",
      },
      {
        id: 60,
        expression: 'Слишком мало делается, чтобы улучшить ситуацию',
        transcription: 'tuː ˈlɪtl z dʌn tʊ ɪmˈpruːv ðə ˌsɪtjʊˈeɪʃən',
        translation: 'Too little is done to improve the situation',
      },
      {
        id: 61,
        expression: 'Результаты этого конкурса будут объявлены завтра',
        transcription: 'ðə rɪˈzʌlts əv ðɪs ˈkɒntɛst wɪl bi əˈnaʊnst təˈmɒrəʊ',
        translation: 'The results of this contest will be announced tomorrow',
      },
      {
        id: 62,
        expression: 'Ничего не было исправлено',
        transcription: 'ˈnʌθɪŋ wəz kəˈrɛktɪd',
        translation: 'Nothing was corrected',
      },
      {
        id: 63,
        expression: 'Работа была окончена на днях',
        transcription: 'ðə wɜːk wəz ˈfɪnɪʃt ði ˈʌðə deɪ',
        translation: 'The work was finished the other day',
      },
      {
        id: 64,
        expression: 'Все будет сделано должным образом',
        transcription: 'ˈɛvrɪθɪŋ wɪl bi dʌn ˈprɒpəli',
        translation: 'Everything will be done properly',
      },
      {
        id: 65,
        expression: 'Недостаточно делается для разрешения этого конфликта',
        transcription: 'nɒt ɪˈnʌf s dʌn tə rɪˈzɒlv ðɪs ˈkɒnflɪkt',
        translation: 'Not enough is done to resolve this conflict',
      },
      {
        id: 66,
        expression: 'Слишком мало было сделано для разрешения этого конфликта',
        transcription: 'tuː ˈlɪtl wəz dʌn fə rɪˈzɒlvɪŋ ðɪs ˈkɒnflɪkt',
        translation: 'Too little was done for resolving this conflict',
      },
      {
        id: 67,
        expression: 'Недостаточно было сделано для улучшения ситуации',
        transcription: 'nɒt ɪˈnʌf wəz dʌn fər ɪmˈpruːvɪŋ ðə ˌsɪtjʊˈeɪʃən',
        translation: 'Not enough was done for improving the situation',
      },
      {
        id: 68,
        expression: 'Интервью проводилось в прошлом месяце',
        transcription: 'ði ˈɪntəvjuː wəz kənˈdʌktɪd lɑːst mʌnθ',
        translation: 'The interview was conducted last month',
      },
      {
        id: 69,
        expression: 'Та встреча была проведена в прошлом году',
        transcription: 'ðæt ˈmiːtɪŋ wəz kənˈdʌktɪd lɑːst jɪə',
        translation: 'That meeting was conducted last year',
      },
      {
        id: 70,
        expression: 'Концерт проводится каждый месяц',
        transcription: 'ðə ˈkɒnsə(ː)t s kənˈdʌktɪd ˈɛvri mʌnθ',
        translation: 'The concert is conducted every month',
      },
      {
        id: 71,
        expression: 'Ожидается, что они победят',
        transcription: 'ɪt s ɪksˈpɛktɪd ðət ðeɪl wɪn',
        translation: "It is expected that they'll win",
      },
      {
        id: 72,
        expression: 'Ожидается, что они найдут решение этой проблемы',
        transcription:
          'ɪts ɪksˈpɛktɪd ðət ðeɪl faɪnd ə səˈluːʃən tə ðɪs ˈprɒbləm',
        translation:
          "It's expected that they'll find a solution to this problem",
      },
      {
        id: 73,
        expression: 'Ожидается, что он сдаст свой последний экзамен',
        transcription: 'ɪts ɪksˈpɛktɪd ðət hiːl pɑːs ɪz ˈfaɪnl ɪgˈzæm',
        translation: "It's expected that he'll pass his final exam",
      },
      {
        id: 74,
        expression: 'Эти ботинки разработаны в Италии',
        transcription: 'ðiːz ʃuːz ə dɪˈzaɪnd ɪn ˈɪtəli',
        translation: 'These shoes are designed in Italy',
      },
      {
        id: 75,
        expression: 'Такая одежда делается в Испании',
        transcription: 'sʌʧ kləʊðz ə meɪd ɪn speɪn',
        translation: 'Such clothes are made in Spain',
      },
      {
        id: 76,
        expression: 'Эта одежда разработана в Италии',
        transcription: 'ðiːz kləʊðz ə dɪˈzaɪnd ɪn ˈɪtəli',
        translation: 'These clothes are designed in Italy',
      },
      {
        id: 77,
        expression: 'На английском говорят почти везде',
        transcription: 'ˈɪŋglɪʃ ɪz ˈspəʊkən ˈɔːlməʊst ˈɛvrɪweə',
        translation: 'English is spoken almost everywhere',
      },
      {
        id: 78,
        expression: 'На английском говорят во многих странах',
        transcription: 'ˈɪŋglɪʃ ɪz ˈspəʊkən ɪn ˈmɛni ˈkʌntriz',
        translation: 'English is spoken in many countries',
      },
      {
        id: 79,
        expression: 'На испанском говорят в некоторых странах',
        transcription: 'ˈspænɪʃ ɪz ˈspəʊkən ɪn səm ˈkʌntriz',
        translation: 'Spanish is spoken in some countries',
      },
      {
        id: 80,
        expression: 'Почему рейс задержали?',
        transcription: 'waɪ wəz ðə flaɪt dɪˈleɪd?',
        translation: 'Why was the flight delayed?',
      },
      {
        id: 81,
        expression: 'Почему твой рейс отменили?',
        transcription: 'waɪ wəz jə flaɪt ˈkænsəld?',
        translation: 'Why was your flight cancelled?',
      },
      {
        id: 82,
        expression: 'Этот рейс был задержан?',
        transcription: 'wəz ðɪs flaɪt dɪˈleɪd?',
        translation: 'Was this flight delayed?',
      },
      {
        id: 83,
        expression: 'Сообщение было отправлено ей',
        transcription: 'ðə ˈmɛsɪʤ wəz sɛnt tə hɜː',
        translation: 'The message was sent to her',
      },
      {
        id: 84,
        expression: 'Мое сообщение было отправлено ему',
        transcription: 'maɪ ˈmɛsɪʤ wəz sɛnt tə hɪm',
        translation: 'My message was sent to him',
      },
      {
        id: 85,
        expression: 'Посылка не была отправлена ей',
        transcription: 'ðə ˈpɑːsl wɒznt sɛnt tə hɜː',
        translation: "The parcel wasn't sent to her",
      },
      {
        id: 86,
        expression: 'За что его критикуют?',
        transcription: 'wɒt s hi ˈkrɪtɪsaɪzd fɔː?',
        translation: 'What is he criticised for?',
      },
      {
        id: 87,
        expression: 'Его критикуют за его поведение',
        transcription: 'hiːz ˈkrɪtɪsaɪzd fə hɪz bɪˈheɪvjə',
        translation: "He's criticised for his behaviour",
      },
      {
        id: 88,
        expression: 'Его критикуют за его грубое поведение?',
        transcription: 'ɪz hi ˈkrɪtɪsaɪzd fə hɪz ruːd bɪˈheɪvjə?',
        translation: 'Is he criticised for his rude behaviour?',
      },
      {
        id: 89,
        expression: 'Их часто винят в этом',
        transcription: 'ðeər ˈɒf(ə)n bleɪmd fər ɪt',
        translation: "They're often blamed for it",
      },
      {
        id: 90,
        expression: 'Их винят в этом?',
        transcription: 'ɑː ðeɪ bleɪmd fər ɪt?',
        translation: 'Are they blamed for it?',
      },
      {
        id: 91,
        expression: 'В чем их обвиняют?',
        transcription: 'wɒt ə ðeɪ bleɪmd fɔː?',
        translation: 'What are they blamed for?',
      },
      {
        id: 92,
        expression: 'Я сомневаюсь, что все сделано должным образом',
        transcription: 'aɪ daʊt ðət ˈɛvrɪθɪŋ z dʌn ˈprɒpəli',
        translation: 'I doubt that everything is done properly',
      },
      {
        id: 93,
        expression: 'Я надеюсь, что все делается должным образом',
        transcription: 'aɪ həʊp ðət ˈɛvrɪθɪŋ z dʌn ˈprɒpəli',
        translation: 'I hope that everything is done properly',
      },
      {
        id: 94,
        expression: 'Я сомневаюсь, что все было сделано должным образом',
        transcription: 'aɪ daʊt ðət ˈɛvrɪθɪŋ wəz dʌn ˈprɒpəli',
        translation: 'I doubt that everything was done properly',
      },
      {
        id: 95,
        expression: 'Ничего не было забыто',
        transcription: 'ˈnʌθɪŋ wəz fəˈgɒtn',
        translation: 'Nothing was forgotten',
      },
      {
        id: 96,
        expression: 'Мне показали новый словарь',
        transcription: 'aɪ wəz ʃəʊn ə njuː ˈdɪkʃ(ə)n(ə)ri',
        translation: 'I was shown a new dictionary',
      },
      {
        id: 97,
        expression: 'Его часто критикуют за это',
        transcription: 'hiːz ˈɒf(ə)n ˈkrɪtɪsaɪzd fər ɪt',
        translation: "He's often criticised for it",
      },
      {
        id: 98,
        expression: 'Встреча состоялась в прошлый понедельник',
        transcription:
          'ðə ˈmiːtɪŋ wəz hɛld lɑːst ˈmʌndeɪ / ðə ˈmiːtɪŋ tʊk pleɪs lɑːst ˈmʌndeɪ',
        translation:
          'The meeting was held last Monday / The meeting took place last Monday',
      },
      {
        id: 99,
        expression: 'На испанском говорят в Испании и многих других странах',
        transcription: 'ˈspænɪʃ ɪz ˈspəʊkən ɪn speɪn ənd ˈmɛni ˈʌðə ˈkʌntriz',
        translation: 'Spanish is spoken in Spain and many other countries',
      },
      {
        id: 100,
        expression: 'Это было сделано на днях',
        transcription: 'ɪt wəz dʌn ði ˈʌðə deɪ',
        translation: 'It was done the other day',
      },
      {
        id: 101,
        expression: 'На немецком говорят в Германии и некоторых других странах',
        transcription: 'ˈʤɜːmən z ˈspəʊkən ɪn ˈʤɜːməni ənd səm ˈʌðə ˈkʌntriz',
        translation: 'German is spoken in Germany and some other countries',
      },
      {
        id: 102,
        expression: 'Все было сделано должным образом',
        transcription: 'ˈɛvrɪθɪŋ wəz dʌn ˈprɒpəli',
        translation: 'Everything was done properly',
      },
      {
        id: 103,
        expression: 'Я уверен, что это будет сделано',
        transcription: 'aɪm ʃʊə ðət ˈɪtl bi dʌn',
        translation: "I'm sure that it'll be done",
      },
      {
        id: 104,
        expression: 'За что они были наказаны?',
        transcription: 'wɒt wə ðeɪ ˈpʌnɪʃt fɔː?',
        translation: 'What were they punished for?',
      },
      {
        id: 105,
        expression: 'Что было объявлено?',
        transcription: 'wɒt wəz əˈnaʊnst?',
        translation: 'What was announced?',
      },
      {
        id: 106,
        expression: 'Его забрали в больницу',
        transcription: 'hi wəz ˈteɪkən tə ˈhɒspɪtl',
        translation: 'He was taken to hospital',
      },
      {
        id: 107,
        expression: 'За что их критикуют?',
        transcription: 'wɒt ə ðeɪ ˈkrɪtɪsaɪzd fɔː?',
        translation: 'What are they criticised for?',
      },
      {
        id: 108,
        expression: 'Когда дом был построен?',
        transcription: 'wɛn wəz ðə haʊs bɪlt?',
        translation: 'When was the house built?',
      },
      {
        id: 109,
        expression: 'Встреча состоится в следующий четверг',
        transcription: 'ðə ˈmiːtɪŋ wɪl bi hɛld nɛkst ˈθɜːzdeɪ',
        translation: 'The meeting will be held next Thursday',
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Закрепление пройденного материала</h3>
</div>`,
    },
  ],
];
