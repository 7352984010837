export const wordsLesson278 = [
  [
    {
      id: 1,
      word: 'astoundingly',
      transcription: 'əsˈtaʊndɪŋli',
      translation: 'поразительно',
    },
    {
      id: 2,
      word: 'chronic',
      transcription: 'ˈkrɒnɪk',
      translation: 'хронический',
    },
    {
      id: 3,
      word: 'contaminated',
      transcription: 'kənˈtæmɪneɪtɪd',
      translation: 'загрязненный',
    },
    {
      id: 4,
      word: 'critical',
      transcription: 'ˈkrɪtɪkəl',
      translation: 'критически',
    },
    {
      id: 5,
      word: 'desperately',
      transcription: 'ˈdɛspərɪtli',
      translation: 'отчаянный',
    },
    {
      id: 6,
      word: 'excessively',
      transcription: 'ɪkˈsɛsɪvli',
      translation: 'чрезмерно',
    },
    {
      id: 7,
      word: 'extraordinarily',
      transcription: 'ɪksˈtrɔːdnrɪli',
      translation: 'необычно',
    },
    {
      id: 8,
      word: 'fantastically',
      transcription: 'fænˈtæstɪkəli',
      translation: 'фантастически',
    },
    {
      id: 9,
      word: 'guitarist',
      transcription: 'ɡɪˈtɑːrɪst',
      translation: 'гитарист',
    },
    {
      id: 10,
      word: 'incomprehensible',
      transcription: 'ɪnkɒmprɪˈhɛnsəbᵊl',
      translation: 'непостижимый',
    },
    {
      id: 11,
      word: 'landscape',
      transcription: 'ˈlænskeɪp',
      translation: 'пейзаж',
    },
    {
      id: 12,
      word: 'naughty',
      transcription: 'ˈnɔːti',
      translation: 'непослушный',
    },
    {
      id: 13,
      word: 'plot',
      transcription: 'plɒt',
      translation: 'сюжет',
    },
    {
      id: 14,
      word: 'priced',
      transcription: 'praɪst',
      translation: 'оцененный',
    },
    {
      id: 15,
      word: 'remarkably',
      transcription: 'rɪˈmɑːkəbli',
      translation: 'замечательно',
    },
    {
      id: 16,
      word: 'snake',
      transcription: 'sneɪk',
      translation: 'змея',
    },
    {
      id: 17,
      word: 'strikingly',
      transcription: 'ˈstraɪkɪŋli',
      translation: 'поразительно',
    },
    {
      id: 18,
      word: 'terrifically',
      transcription: 'təˈrɪfɪkᵊli',
      translation: 'ужасно',
    },
    {
      id: 19,
      word: 'uncommonly',
      transcription: 'ʌnˈkɒmənli',
      translation: 'необыкновенно',
    },
    {
      id: 20,
      word: 'unqualified',
      transcription: 'ʌnˈkwɒlɪfaɪd',
      translation: 'неквалифицированный',
    },
    {
      id: 21,
      word: 'unusually',
      transcription: 'ʌnˈjuːʒʊəli',
      translation: 'необычно',
    },
  ],
];
