export const contentLesson90 = [
  [
    {
      title: "Урок 90. Past Simple. 85 Irregular Verbs. Part 1",
    },
    [
      {
        id: 1,
        expression: "Я преодолел все эти трудности на своем пути",
        transcription: "aɪ ˌəʊvəˈkeɪm ɔːl ðiːz ˈdɪfɪkəltiz ɒn maɪ weɪ",
        translation: "I overcame all these difficulties on my way",
      },
      {
        id: 2,
        expression: "Сколько ты заплатил за эту услугу?",
        transcription: "haʊ mʌʧ dɪd jʊ peɪ fə ðɪs ˈsɜːvɪs?",
        translation: "How much did you pay for this service?",
      },
      {
        id: 3,
        expression: "Он взял детей с собой",
        transcription: "hi tʊk ðə ˈʧɪldrən wɪð hɪm",
        translation: "He took the children with him",
      },
      {
        id: 4,
        expression: "Это скоро стало ясно",
        transcription: "ɪt bɪˈkeɪm klɪə suːn",
        translation: "It became clear soon",
      },
      {
        id: 5,
        expression: "Она выросла в маленьком городке",
        transcription: "ʃi gruː ʌp ɪn ə smɔːl taʊn",
        translation: "She grew up in a small town",
      },
      {
        id: 6,
        expression: "Я положил это рядом со своим компьютером",
        transcription: "aɪ pʊt ɪt nɪə maɪ kəmˈpjuːtə",
        translation: "I put it near my computer",
      },
      {
        id: 7,
        expression: "Они поймали того вора",
        transcription: "ðeɪ kɔːt ðət θiːf",
        translation: "They caught that thief",
      },
      {
        id: 8,
        expression: "Я снял три доллара со своего счета",
        transcription: "aɪ wɪðˈdruː θriː ˈdɒləz frəm maɪ əˈkaʊnt",
        translation: "I withdrew three dollars from my account",
      },
      {
        id: 9,
        expression: "Я повесил это на стену",
        transcription: "aɪ hʌŋ ɪt ɒn ðə wɔːl",
        translation: "I hung it on the wall",
      },
      {
        id: 10,
        expression: "Я заплатил за билеты",
        transcription: "aɪ peɪd fə ðə ˈtɪkɪts",
        translation: "I paid for the tickets",
      },
      {
        id: 11,
        expression: "Он хотел стать спортсменом",
        transcription: "hi ˈwɒntɪd tə bɪˈkʌm ə ˈspɔːtsmən",
        translation: "He wanted to become a sportsman",
      },
      {
        id: 12,
        expression: "Он сделал большую ошибку",
        transcription: "hi meɪd ə bɪg mɪsˈteɪk",
        translation: "He made a big mistake",
      },
      {
        id: 13,
        expression: "Во сколько ты ушел из дома?",
        transcription: "wɒt taɪm dɪd jʊ liːv həʊm?",
        translation: "What time did you leave home?",
      },
      {
        id: 14,
        expression: "Полиция поймала тех преступников",
        transcription: "ðə pəˈliːs kɔːt ðəʊz ˈkrɪmɪnlz",
        translation: "The police caught those criminals",
      },
      {
        id: 15,
        expression: "Он вырос за границей",
        transcription: "hi gruː ʌp əˈbrɔːd",
        translation: "He grew up abroad",
      },
      {
        id: 16,
        expression: "Я оплатил счет",
        transcription: "aɪ peɪd ðə bɪl",
        translation: "I paid the bill",
      },
      {
        id: 17,
        expression: "Я побежал домой",
        transcription: "aɪ ræn həʊm",
        translation: "I ran home",
      },
      {
        id: 18,
        expression: "Он вышел из комнаты",
        transcription: "hi lɛft ðə ruːm",
        translation: "He left the room",
      },
      {
        id: 19,
        expression: "Я ударил по мячу",
        transcription: "aɪ hɪt ðə bɔːl",
        translation: "I hit the ball",
      },
      {
        id: 20,
        expression: "Я поймал мяч",
        transcription: "aɪ kɔːt ðə bɔːl",
        translation: "I caught the ball",
      },
      {
        id: 21,
        expression: "Я забыл взять свои ключи",
        transcription: "aɪ fəˈgɒt tə teɪk maɪ kiːz",
        translation: "I forgot to take my keys",
      },
      {
        id: 22,
        expression: "Это привело его к успеху",
        transcription: "ɪt lɛd ɪm tə səkˈsɛs",
        translation: "It led him to success",
      },
      {
        id: 23,
        expression: "Она прислала мне сообщение",
        transcription: "ʃi sɛnt mi ə ˈmɛsɪʤ",
        translation: "She sent me a message",
      },
      {
        id: 24,
        expression: "Я думал иначе",
        transcription: "aɪ θɔːt ˈʌðəwaɪz / aɪ θɔːt ˈdɪfrəntli",
        translation: "I thought otherwise / I thought differently",
      },
      {
        id: 25,
        expression: "Я снял все деньги",
        transcription: "aɪ wɪðˈdruː ɔːl ðə ˈmʌni",
        translation: "I withdrew all the money",
      },
      {
        id: 26,
        expression: "Это дало мне эту возможность",
        transcription: "ɪt geɪv mi ðɪs ˌɒpəˈtjuːnɪti",
        translation: "It gave me this opportunity",
      },
      {
        id: 27,
        expression: "Я услышал странный шум",
        transcription: "aɪ hɜːd ə streɪnʤ nɔɪz",
        translation: "I heard a strange noise",
      },
      {
        id: 28,
        expression: "Я положил это на полку",
        transcription: "aɪ pʊt ɪt ɒn ðə ʃɛlf",
        translation: "I put it on the shelf",
      },
      {
        id: 29,
        expression: "Она сделала очень большую ошибку",
        transcription: "ʃi meɪd ə ˈvɛri bɪg mɪsˈteɪk",
        translation: "She made a very big mistake",
      },
      {
        id: 30,
        expression: "Я не слышал ничего",
        transcription: "aɪ dɪdnt hɪər ˈɛnɪθɪŋ / aɪ hɜːd ˈnʌθɪŋ",
        translation: "I didn't hear anything / I heard nothing",
      },
      {
        id: 31,
        expression: "Он очень сильно ударил в дверь",
        transcription: "hi hɪt ðə dɔː ˈvɛri ˈstrɒŋli",
        translation: "He hit the door very strongly",
      },
      {
        id: 32,
        expression: "Я вырос в России",
        transcription: "aɪ gruː ʌp ɪn ˈrʌʃə",
        translation: "I grew up in Russia",
      },
      {
        id: 33,
        expression: "Он потратил все свои деньги на это",
        transcription: "hi spɛnt ɔːl ɪz ˈmʌni ɒn ɪt",
        translation: "He spent all his money on it",
      },
      {
        id: 34,
        expression: "Я предпочитал есть здоровую еду",
        transcription: "aɪ prɪˈfɜːd tʊ iːt ˈhɛlθi fuːd",
        translation: "I preferred to eat healthy food",
      },
      {
        id: 35,
        expression: "Дорога вела к тому городу",
        transcription: "ðə rəʊd lɛd tə ðət ˈsɪti",
        translation: "The road led to that city",
      },
      {
        id: 36,
        expression: "Мы не могли ничего слышать",
        transcription: "wi ˈkʊdnt hɪər ˈɛnɪθɪŋ",
        translation: "We couldn't hear anything",
      },
      {
        id: 37,
        expression: "Он вырос в деревне",
        transcription: "hi gruː ʌp ɪn ə ˈvɪlɪʤ",
        translation: "He grew up in a village",
      },
      {
        id: 38,
        expression: "Она приняла этот подарок",
        transcription: "ʃi tʊk ðɪs gɪft",
        translation: "She took this gift",
      },
      {
        id: 39,
        expression: "Я побежал к их дому",
        transcription: "aɪ ræn tə ðeə haʊs",
        translation: "I ran to their house",
      },
      {
        id: 40,
        expression: "Я повесил картину на стену",
        transcription: "aɪ hʌŋ ðə ˈpɪkʧər ɒn ðə wɔːl",
        translation: "I hung the picture on the wall",
      },
      {
        id: 41,
        expression:
          "Это было очень сложно, но мне удалось преодолеть все эти трудности",
        transcription:
          "ɪt wəz ˈvɛri ˈdɪfɪkəlt bət aɪ ˈmænɪʤd tʊ ˌəʊvəˈkʌm ɔːl ðiːz ˈdɪfɪkəltiz",
        translation:
          "It was very difficult but I managed to overcome all these difficulties",
      },
      {
        id: 42,
        expression: "Я потратил две тысячи долларов на новый компьютер",
        transcription: "aɪ spɛnt tuː ˈθaʊzənd ˈdɒləz ɒn ə njuː kəmˈpjuːtə",
        translation: "I spent two thousand dollars on a new computer",
      },
      {
        id: 43,
        expression:
          "Мне пришлось снять все деньги со своего счета, чтобы купить это",
        transcription:
          "aɪ həd tə wɪðˈdrɔː ɔːl maɪ ˈmʌni frəm maɪ əˈkaʊnt tə baɪ ɪt",
        translation: "I had to withdraw all my money from my account to buy it",
      },
      {
        id: 44,
        expression: "Если я тебя правильно понял, ты хочешь сделать следующее",
        transcription:
          "ɪf aɪ ˌʌndəˈstʊd jʊ ˈraɪtli (kəˈrɛktli), jʊ wɒnt tə dʊ ðə ˈfɒləʊɪŋ",
        translation:
          "If I understood you rightly (correctly), you want to do the following",
      },
      {
        id: 45,
        expression:
          "Я пытался выучить два иностранных языка в одно и то же время, но потом я перестал это делать",
        transcription:
          "aɪ traɪd tə lɜːn tuː ˈfɒrɪn ˈlæŋgwɪʤɪz ət ðə seɪm taɪm bət ðɛn aɪ stɒpt ˈdu(ː)ɪŋ ɪt",
        translation:
          "I tried to learn two foreign languages at the same time but then I stopped doing it",
      },
      {
        id: 46,
        expression: "Он стал самым богатым человеком в нашей стране",
        transcription: "hi bɪˈkeɪm ðə ˈrɪʧɪst ˈpɜːsn ɪn ˈaʊə ˈkʌntri",
        translation: "He became the richest person in our country",
      },
      {
        id: 47,
        expression: "Он украл чей-то бумажник, но затем полиция поймала его",
        transcription: "hi stəʊl ˈsʌmwʌnz ˈwɒlɪt bət ðɛn ðə pəˈliːs kɔːt hɪm",
        translation: "He stole someone's wallet but then the police caught him",
      },
      {
        id: 48,
        expression: "Я прекрасно понимал, насколько это было важно",
        transcription: "aɪ ˈpɜːfɪktli ˌʌndəˈstʊd haʊ ɪmˈpɔːtənt ɪt wɒz",
        translation: "I perfectly understood how important it was",
      },
      {
        id: 49,
        expression: "Они спрятали это сокровище",
        transcription: "ðeɪ hɪd ðɪs ˈtrɛʒə",
        translation: "They hid this treasure",
      },
      {
        id: 50,
        expression: "Я учил английский в основном сам",
        transcription: "aɪ lɜːnt ˈɪŋglɪʃ ˈməʊstli ɒn maɪ əʊn",
        translation: "I learnt English mostly on my own",
      },
      {
        id: 51,
        expression: "Никто не знал, как ответить на этот вопрос",
        transcription: "nəʊ wʌn njuː haʊ tʊ ˈɑːnsə ðɪs ˈkwɛsʧən",
        translation: "No one knew how to answer this question",
      },
      {
        id: 52,
        expression: "Она потратила пятьсот долларов на новое платье",
        transcription: "ʃi spɛnt faɪv ˈhʌndrəd ˈdɒləz ɒn ə njuː drɛs",
        translation: "She spent five hundred dollars on a new dress",
      },
      {
        id: 53,
        expression: "Я бежал как можно быстрее",
        transcription: "aɪ ræn əz fɑːst əz ˈpɒsəbl",
        translation: "I ran as fast as possible",
      },
      {
        id: 54,
        expression: "Я знал, что это было возможно",
        transcription: "aɪ njuː ðət ɪt wəz ˈpɒsəbl",
        translation: "I knew that it was possible",
      },
      {
        id: 55,
        expression: "Он стал самым богатым человеком в мире",
        transcription: "hi bɪˈkeɪm ðə ˈrɪʧɪst ˈpɜːsn ɪn ðə wɜːld",
        translation: "He became the richest person in the world",
      },
      {
        id: 56,
        expression: "Он дал мне, что я хотел",
        transcription: "hi geɪv mi wɒt aɪ ˈwɒntɪd",
        translation: "He gave me what I wanted",
      },
      {
        id: 57,
        expression: "Я дал ему, что он хотел",
        transcription: "aɪ geɪv ɪm wɒt hi ˈwɒntɪd",
        translation: "I gave him what he wanted",
      },
      {
        id: 58,
        expression: "Он дал мне, что мне было нужно",
        transcription: "hi geɪv mi wɒt aɪ ˈniːdɪd",
        translation: "He gave me what I needed",
      },
      {
        id: 59,
        expression: "Я учу английский сам",
        transcription: "aɪ lɜːn ˈɪŋglɪʃ ɒn maɪ əʊn",
        translation: "I learn English on my own",
      },
      {
        id: 60,
        expression: "Они выучили английский самостоятельно",
        transcription: "ðeɪ lɜːnt ˈɪŋglɪʃ baɪ ðəmˈsɛlvz",
        translation: "They learnt English by themselves",
      },
      {
        id: 61,
        expression: "Она изучает английский сама",
        transcription: "ʃi lɜːnz ˈɪŋglɪʃ baɪ hɜːˈsɛlf",
        translation: "She learns English by herself",
      },
      {
        id: 62,
        expression: "Я думаю, я понял тебя правильно",
        transcription: "aɪ θɪŋk aɪ ˌʌndəˈstʊd jʊ kəˈrɛktli",
        translation: "I think I understood you correctly",
      },
      {
        id: 63,
        expression: "Я думаю, что понял тебя правильно",
        transcription: "aɪ θɪŋk ðət aɪ ˌʌndəˈstʊd jʊ kəˈrɛktli",
        translation: "I think that I understood you correctly",
      },
      {
        id: 64,
        expression: "Она думает, она поняла меня правильно",
        transcription: "ʃi θɪŋks ʃi ˌʌndəˈstʊd mi kəˈrɛktli",
        translation: "She thinks she understood me correctly",
      },
      {
        id: 65,
        expression: "Этот человек спрятал сокровище в пещере",
        transcription: "ðɪs mæn hɪd ðə ˈtrɛʒər ɪn ə keɪv",
        translation: "This man hid the treasure in a cave",
      },
      {
        id: 66,
        expression: "Эти люди спрятали сокровище в этой пещере",
        transcription: "ðiːz ˈpiːpl hɪd ðə ˈtrɛʒər ɪn ðɪs keɪv",
        translation: "These people hid the treasure in this cave",
      },
      {
        id: 67,
        expression: "Он спрятал сокровище в пещере",
        transcription: "hi hɪd ðə ˈtrɛʒər ɪn ə keɪv",
        translation: "He hid the treasure in a cave",
      },
      {
        id: 68,
        expression: "Я многому у него научился",
        transcription: "aɪ lɜːnt ə lɒt frəm hɪm",
        translation: "I learnt a lot from him",
      },
      {
        id: 69,
        expression: "Я многому у тебя научился",
        transcription: "aɪ lɜːnt ə lɒt frəm juː",
        translation: "I learnt a lot from you",
      },
      {
        id: 70,
        expression: "Она многому научилась у меня",
        transcription: "ʃi lɜːnt ə lɒt frəm miː",
        translation: "She learnt a lot from me",
      },
      {
        id: 71,
        expression: "Я знал, как ответить на тот вопрос",
        transcription: "aɪ njuː haʊ tʊ ˈɑːnsə ðət ˈkwɛsʧən",
        translation: "I knew how to answer that question",
      },
      {
        id: 72,
        expression: "Я знаю, как ответить на этот вопрос",
        transcription: "aɪ nəʊ haʊ tʊ ˈɑːnsə ðɪs ˈkwɛsʧən",
        translation: "I know how to answer this question",
      },
      {
        id: 73,
        expression: "Я не знал, как ответить на тот вопрос",
        transcription: "aɪ dɪdnt nəʊ haʊ tʊ ˈɑːnsə ðət ˈkwɛsʧən",
        translation: "I didn't know how to answer that question",
      },
      {
        id: 74,
        expression: "Ветер подул внезапно",
        transcription: "ðə wɪnd bluː ɔːl əv ə ˈsʌdn",
        translation: "The wind blew all of a sudden",
      },
      {
        id: 75,
        expression: "Сильный ветер подул внезапно",
        transcription: "ə strɒŋ wɪnd bluː ɔːl əv ðə ˈsʌdn",
        translation: "A strong wind blew all of the sudden",
      },
      {
        id: 76,
        expression: "Холодный ветер подул внезапно",
        transcription: "ə kəʊld wɪnd bluː ɔːl əv ðə ˈsʌdn",
        translation: "A cold wind blew all of the sudden",
      },
      {
        id: 77,
        expression: "Я все сразу понял",
        transcription: "aɪ ˌʌndəˈstʊd ˈɛvrɪθɪŋ ət wʌns",
        translation: "I understood everything at once",
      },
      {
        id: 78,
        expression: "Я не сразу это понял",
        transcription: "aɪ dɪdnt ˌʌndəˈstænd ɪt ət wʌns",
        translation: "I didn't understand it at once",
      },
      {
        id: 79,
        expression: "Я сразу это понял",
        transcription: "aɪ ˌʌndəˈstʊd ɪt ət wʌns",
        translation: "I understood it at once",
      },
      {
        id: 80,
        expression: "Они спрятали сокровище под землей",
        transcription: "ðeɪ hɪd ðə ˈtrɛʒər ˈʌndə ðə graʊnd",
        translation: "They hid the treasure under the ground",
      },
      {
        id: 81,
        expression: "Я спрятал сокровище в пещере",
        transcription: "aɪ hɪd ðə ˈtrɛʒər ɪn ə keɪv",
        translation: "I hid the treasure in a cave",
      },
      {
        id: 82,
        expression: "Она спрятала это сокровище под землей",
        transcription: "ʃi hɪd ðɪs ˈtrɛʒər ˈʌndə ðə graʊnd",
        translation: "She hid this treasure under the ground",
      },
      {
        id: 83,
        expression: "Она не хотела брать деньги",
        transcription: "ʃi dɪdnt wɒnt tə teɪk ˈmʌni",
        translation: "She didn't want to take money",
      },
      {
        id: 84,
        expression: "Она не хотела брать эти деньги",
        transcription: "ʃi dɪdnt wɒnt tə teɪk ðɪs ˈmʌni",
        translation: "She didn't want to take this money",
      },
      {
        id: 85,
        expression: "Она не хотела одалживать эти деньги",
        transcription: "ʃi dɪdnt wɒnt tə lɛnd ðɪs ˈmʌni",
        translation: "She didn't want to lend this money",
      },
      {
        id: 86,
        expression: "Я проснулся раньше, чем обычно",
        transcription: "aɪ wəʊk ʌp ˈɜːlɪə ðən ˈjuːʒʊəl",
        translation: "I woke up earlier than usual",
      },
      {
        id: 87,
        expression: "Я поехал домой после встречи",
        transcription: "aɪ drəʊv həʊm ˈɑːftə ˈmiːtɪŋ",
        translation: "I drove home after meeting",
      },
      {
        id: 88,
        expression: "Я мог там бесплатно учить английский",
        transcription: "aɪ kəd lɜːn ˈɪŋglɪʃ ðeə fə friː",
        translation: "I could learn English there for free",
      },
      {
        id: 89,
        expression: "Я потратил семьсот долларов на этот планшет",
        transcription: "aɪ spɛnt ˈsɛvn ˈhʌndrəd ˈdɒləz ɒn ðɪs ˈtæblɪt",
        translation: "I spent seven hundred dollars on this tablet",
      },
      {
        id: 90,
        expression: "Если я тебя правильно понял, это означает следующее",
        transcription: "ɪf aɪ ˌʌndəˈstʊd jʊ kəˈrɛktli, ɪt miːnz ðə ˈfɒləʊɪŋ",
        translation: "If I understood you correctly, it means the following",
      },
      {
        id: 91,
        expression:
          "Они украли очень большие деньги, но полиция поймала их вскоре",
        transcription: "ðeɪ stəʊl ˈvɛri bɪg ˈmʌni bət ðə pəˈliːs kɔːt ðəm suːn",
        translation:
          "They stole very big money but the police caught them soon",
      },
      {
        id: 92,
        expression: "Он хотел уехать из этой страны",
        transcription: "hi ˈwɒntɪd tə liːv ðɪs ˈkʌntri",
        translation: "He wanted to leave this country",
      },
      {
        id: 93,
        expression: "Она думала так, но я думал по-другому",
        transcription: "ʃi θɔːt səʊ bət aɪ θɔːt ˈʌðəwaɪz (ˈdɪfrəntli)",
        translation: "She thought so but I thought otherwise (differently)",
      },
      {
        id: 94,
        expression: "Сильный ветер подул внезапно",
        transcription: "ə strɒŋ wɪnd bluː ɔːl əv ə ˈsʌdn",
        translation: "A strong wind blew all of a sudden",
      },
      {
        id: 95,
        expression: "Мы знали, что это было невозможно",
        transcription: "wi njuː ðət ɪt wəz ɪmˈpɒsəbl",
        translation: "We knew that it was impossible",
      },
      {
        id: 96,
        expression: "Я проснулся позже, чем обычно",
        transcription: "aɪ wəʊk ʌp ˈleɪtə ðən ˈjuːʒʊəl",
        translation: "I woke up later than usual",
      },
      {
        id: 97,
        expression: "Он обычно ездил на машине на работу",
        transcription:
          "hi ˈjuːʒʊəli drəʊv tə wɜːk / hi ˈjuːʒʊəli wɛnt tə wɜːk baɪ kɑː",
        translation:
          "He usually drove to work / He usually went to work by car",
      },
      {
        id: 98,
        expression: "Никто не знал ответа",
        transcription: "nəʊ wʌn njuː ði ˈɑːnsə",
        translation: "No one knew the answer",
      },
      {
        id: 99,
        expression: "Он понял это сразу",
        transcription: "hi ˌʌndəˈstʊd ɪt ət wʌns",
        translation: "He understood it at once",
      },
      {
        id: 100,
        expression: "Холодный ветер подул внезапно",
        transcription: "ə kəʊld wɪnd bluː ˈsʌdnli",
        translation: "A cold wind blew suddenly",
      },
      {
        id: 101,
        expression: "Я нашел ключи",
        transcription: "aɪ faʊnd ðə kiːz",
        translation: "I found the keys",
      },
      {
        id: 102,
        expression: "Он поспал слишком мало",
        transcription: "hi slɛpt tuː ˈlɪtl",
        translation: "He slept too little",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Закрепление пройденного материала</h3>
</div>`,
    },
  ],
];
