export const contentLesson38 = [
  [
    {
      title: "Урок 38. Past Simple. Irregular verbs. Part 2",
    },
    [
      {
        id: 1,
        expression: "Я проснулся очень поздно",
        transcription: "aɪ wəʊk ʌp ˈvɛri leɪt",
        translation: "I woke up very late",
      },
      {
        id: 2,
        expression: "Я встал в восемь часов утра",
        transcription: "aɪ gɒt ʌp ət eɪt əˈklɒk ɪn ðə ˈmɔːnɪŋ",
        translation: "I got up at eight o'clock in the morning",
      },
      {
        id: 3,
        expression: "Мы встали в восемь часов утра",
        transcription: "wi gɒt ʌp ət eɪt əˈklɒk ɪn ðə ˈmɔːnɪŋ",
        translation: "We got up at eight o'clock in the morning",
      },
      {
        id: 4,
        expression: "Это стоило слишком много",
        transcription: "ɪt kɒst tuː mʌʧ",
        translation: "It cost too much",
      },
      {
        id: 5,
        expression: "Я выиграл в том чемпионате",
        transcription: "aɪ wʌn ðət ˈʧæmpiənʃɪp",
        translation: "I won that championship",
      },
      {
        id: 6,
        expression: "Я взял деньги с собой",
        transcription: "aɪ tʊk ˈmʌni wɪð miː",
        translation: "I took money with me",
      },
      {
        id: 7,
        expression: "Это стоило много",
        transcription: "ɪt kɒst ə lɒt",
        translation: "It cost a lot",
      },
      {
        id: 8,
        expression: "Когда начался этот урок?",
        transcription: "wɛn dɪd ðɪs ˈlɛsn bɪˈgɪn?",
        translation: "When did this lesson begin?",
      },
      {
        id: 9,
        expression: "Он заплатил за нее",
        transcription: "hi peɪd fə hə",
        translation: "He paid for her",
      },
      {
        id: 10,
        expression: "Я не знал этого слова",
        transcription: "aɪ dɪdnt nəʊ ðɪs wɜːd",
        translation: "I didn't know this word",
      },
      {
        id: 11,
        expression: "Я выиграл в этом соревновании",
        transcription: "aɪ wʌn ðɪs ˌkɒmpɪˈtɪʃən",
        translation: "I won this competition",
      },
      {
        id: 12,
        expression: "Что это значило?",
        transcription: "wɒt dɪd ɪt miːn?",
        translation: "What did it mean?",
      },
      {
        id: 13,
        expression: "Это стоило слишком мало",
        transcription: "ɪt kɒst tuː ˈlɪtl",
        translation: "It cost too little",
      },
      {
        id: 14,
        expression: "Я пошел на работу",
        transcription: "aɪ wɛnt tə wɜːk",
        translation: "I went to work",
      },
      {
        id: 15,
        expression: "Он часто ходил в кино",
        transcription: "hi ˈɒf(ə)n wɛnt tə ðə ˈsɪnəmə",
        translation: "He often went to the cinema",
      },
      {
        id: 16,
        expression: "Что ты имел в виду?",
        transcription: "wɒt dɪd jʊ miːn?",
        translation: "What did you mean?",
      },
      {
        id: 17,
        expression: "Они построили мост",
        transcription: "ðeɪ bɪlt ə brɪʤ",
        translation: "They built a bridge",
      },
      {
        id: 18,
        expression: "Ты выиграл в том конкурсе?",
        transcription: "dɪd jʊ wɪn ðət ˌkɒmpɪˈtɪʃən?",
        translation: "Did you win that competition?",
      },
      {
        id: 19,
        expression: "Этот бумажник стоил очень мало",
        transcription: "ðɪs ˈwɒlɪt kɒst ˈvɛri ˈlɪtl",
        translation: "This wallet cost very little",
      },
      {
        id: 20,
        expression: "Я взял эти деньги",
        transcription: "aɪ tʊk ðɪs ˈmʌni",
        translation: "I took this money",
      },
      {
        id: 21,
        expression: "Мы выиграли в этом чемпионате",
        transcription: "wi wʌn ðɪs ˈʧæmpiənʃɪp",
        translation: "We won this championship",
      },
      {
        id: 22,
        expression: "Я нашел свои ключи",
        transcription: "aɪ faʊnd maɪ kiːz",
        translation: "I found my keys",
      },
      {
        id: 23,
        expression: "Я знал это очень хорошо",
        transcription: "aɪ njuː ɪt ˈvɛri wɛl",
        translation: "I knew it very well",
      },
      {
        id: 24,
        expression: "Они пошли в школу",
        transcription: "ðeɪ wɛnt tə skuːl",
        translation: "They went to school",
      },
      {
        id: 25,
        expression: "Это стоило так много",
        transcription: "ɪt kɒst səʊ mʌʧ",
        translation: "It cost so much",
      },
      {
        id: 26,
        expression: "Извини, я не понял тебя",
        transcription: "ˈsɒri, aɪ dɪdnt ˌʌndəˈstænd juː",
        translation: "Sorry, I didn't understand you",
      },
      {
        id: 27,
        expression: "Она потеряла свою сумку",
        transcription: "ʃi lɒst hə bæg",
        translation: "She lost her bag",
      },
      {
        id: 28,
        expression: "Извини, я не понял этого",
        transcription: "ˈsɒri, aɪ dɪdnt ˌʌndəˈstænd ɪt",
        translation: "Sorry, I didn't understand it",
      },
      {
        id: 29,
        expression: "Я не знал эту фразу",
        transcription: "aɪ dɪdnt nəʊ ðɪs freɪz",
        translation: "I didn't know this phrase",
      },
      {
        id: 30,
        expression: "Он пошел в университет",
        transcription: "hi wɛnt tə ˌjuːnɪˈvɜːsɪti",
        translation: "He went to university",
      },
      {
        id: 31,
        expression: "Я нашел свою сумку",
        transcription: "aɪ faʊnd maɪ bæg",
        translation: "I found my bag",
      },
      {
        id: 32,
        expression: "Я не понял это предложение",
        transcription: "aɪ dɪdnt ˌʌndəˈstænd ðɪs ˈsɛntəns",
        translation: "I didn't understand this sentence",
      },
      {
        id: 33,
        expression: "Во сколько ты вчера встал?",
        transcription: "wɒt taɪm dɪd jʊ gɛt ʌp ˈjɛstədeɪ?",
        translation: "What time did you get up yesterday?",
      },
      {
        id: 34,
        expression: "Я принял его предложение с большим удовольствием",
        transcription: "aɪ tʊk ɪz ˈɒfə wɪð greɪt ˈplɛʒə",
        translation: "I took his offer with great pleasure",
      },
      {
        id: 35,
        expression: "Этот урок начался",
        transcription: "ðɪs ˈlɛsn bɪˈgæn",
        translation: "This lesson began",
      },
      {
        id: 36,
        expression: "Он поехал в Индию",
        transcription: "hi wɛnt tʊ ˈɪndɪə",
        translation: "He went to India",
      },
      {
        id: 37,
        expression: "Я поехал в Соединенное Королевство",
        transcription: "aɪ wɛnt tə ðə jʊˈnaɪtɪd ˈkɪŋdəm",
        translation: "I went to the United Kingdom",
      },
      {
        id: 38,
        expression: "Они построили дом",
        transcription: "ðeɪ bɪlt ə haʊs",
        translation: "They built a house",
      },
      {
        id: 39,
        expression: "Я пошел домой после работы",
        transcription: "aɪ wɛnt həʊm ˈɑːftə wɜːk",
        translation: "I went home after work",
      },
      {
        id: 40,
        expression: "Ты видел ее вчера?",
        transcription: "dɪd jʊ siː hə ˈjɛstədeɪ?",
        translation: "Did you see her yesterday?",
      },
      {
        id: 41,
        expression: "Это принесло ему очень большие деньги",
        transcription: "ɪt brɔːt ɪm ˈvɛri bɪg ˈmʌni",
        translation: "It brought him very big money",
      },
      {
        id: 42,
        expression: "Он потерял свой кошелек",
        transcription: "hi lɒst ɪz ˈwɒlɪt",
        translation: "He lost his wallet",
      },
      {
        id: 43,
        expression: "Он не мог найти эту информацию онлайн",
        transcription: "hi ˈkʊdnt faɪnd ðɪs ˌɪnfəˈmeɪʃən ˈɒnˌlaɪn",
        translation: "He couldn't find this information online",
      },
      {
        id: 44,
        expression: "Они говорили очень тихо",
        transcription: "ðeɪ spəʊk ˈvɛri ˈkwaɪətli",
        translation: "They spoke very quietly",
      },
      {
        id: 45,
        expression: "Мой друг стал программистом",
        transcription: "maɪ frɛnd bɪˈkeɪm ə ˈprəʊgræmə",
        translation: "My friend became a programmer",
      },
      {
        id: 46,
        expression: "Я часто видел ее на работе",
        transcription: "aɪ ˈɒf(ə)n sɔː hər ət wɜːk",
        translation: "I often saw her at work",
      },
      {
        id: 47,
        expression: "Она стала юристом",
        transcription: "ʃi bɪˈkeɪm ə ˈlɔːjə",
        translation: "She became a lawyer",
      },
      {
        id: 48,
        expression: "Во сколько ты проснулся вчера?",
        transcription: "wɒt taɪm dɪd jʊ weɪk ʌp ˈjɛstədeɪ?",
        translation: "What time did you wake up yesterday?",
      },
      {
        id: 49,
        expression: "Она преподавала этот предмет в школе",
        transcription: "ʃi tɔːt ðɪs ˈsʌbʤɪkt ət skuːl",
        translation: "She taught this subject at school",
      },
      {
        id: 50,
        expression: "Он преподавал математику в университете",
        transcription: "hi tɔːt mæθs ət ˌjuːnɪˈvɜːsɪti",
        translation: "He taught maths at university",
      },
      {
        id: 51,
        expression: "Я заплатил за эту услугу",
        transcription: "aɪ peɪd fə ðɪs ˈsɜːvɪs",
        translation: "I paid for this service",
      },
      {
        id: 52,
        expression: "Ты выбрал правильный ответ",
        transcription: "jʊ ʧəʊz ðə raɪt ˈɑːnsə",
        translation: "You chose the right answer",
      },
      {
        id: 53,
        expression: "Я думаю, он знал это лучше",
        transcription: "aɪ θɪŋk hi njuː ɪt ˈbɛtə",
        translation: "I think he knew it better",
      },
      {
        id: 54,
        expression: "Он сказал это очень тихо",
        transcription: "hi sɛd ɪt ˈvɛri ˈkwaɪətli",
        translation: "He said it very quietly",
      },
      {
        id: 55,
        expression: "Это означало другую вещь",
        transcription: "ɪt mɛnt əˈnʌðə θɪŋ",
        translation: "It meant another thing",
      },
      {
        id: 56,
        expression: "Он говорил очень громко",
        transcription: "hi spəʊk ˈvɛri ˈlaʊdli",
        translation: "He spoke very loudly",
      },
      {
        id: 57,
        expression: "Наш учитель сказал это очень громко",
        transcription: "ˈaʊə ˈtiːʧə sɛd ɪt ˈvɛri ˈlaʊdli",
        translation: "Our teacher said it very loudly",
      },
      {
        id: 58,
        expression: "Я не мог найти свои ключи",
        transcription: "aɪ ˈkʊdnt faɪnd maɪ kiːz",
        translation: "I couldn't find my keys",
      },
      {
        id: 59,
        expression: "Он не мог найти свои деньги",
        transcription: "hi ˈkʊdnt faɪnd ɪz ˈmʌni",
        translation: "He couldn't find his money",
      },
      {
        id: 60,
        expression: "Она не могла найти свои ключи",
        transcription: "ʃi ˈkʊdnt faɪnd hə kiːz",
        translation: "She couldn't find her keys",
      },
      {
        id: 61,
        expression: "Я не знал, что выбрать",
        transcription: "aɪ dɪd nəʊ wɒt tə ʧuːz",
        translation: "I did know what to choose",
      },
      {
        id: 62,
        expression: "Ты не знал, что ответить",
        transcription: "jʊ dɪdnt nəʊ wɒt tʊ ˈɑːnsə",
        translation: "You didn't know what to answer",
      },
      {
        id: 63,
        expression: "Мы не знали, что выбрать",
        transcription: "wi dɪdnt nəʊ wɒt tə ʧuːz",
        translation: "We didn't know what to choose",
      },
      {
        id: 64,
        expression: "Я проснулся вчера слишком поздно",
        transcription: "aɪ wəʊk ʌp tuː leɪt ˈjɛstədeɪ",
        translation: "I woke up too late yesterday",
      },
      {
        id: 65,
        expression: "Она встала очень поздно",
        transcription: "ʃi gɒt ʌp ˈvɛri leɪt",
        translation: "She got up very late",
      },
      {
        id: 66,
        expression: "Ты проснулся вчера поздно",
        transcription: "jʊ wəʊk ʌp leɪt ˈjɛstədeɪ",
        translation: "You woke up late yesterday",
      },
      {
        id: 67,
        expression: "Его родители платили за его образование",
        transcription: "hɪz ˈpeərənts peɪd fə hɪz ˌɛdju(ː)ˈkeɪʃən",
        translation: "His parents paid for his education",
      },
      {
        id: 68,
        expression: "Его мама заплатила за машину",
        transcription: "hɪz ˈmʌðə peɪd fə ðə kɑː",
        translation: "His mother paid for the car",
      },
      {
        id: 69,
        expression: "Ее родители платили за ее образование",
        transcription: "hə ˈpeərənts peɪd fə hər ˌɛdju(ː)ˈkeɪʃən",
        translation: "Her parents paid for her education",
      },
      {
        id: 70,
        expression: "Он хотел стать юристом",
        transcription: "hi ˈwɒntɪd tə bɪˈkʌm ə ˈlɔːjə",
        translation: "He wanted to become a lawyer",
      },
      {
        id: 71,
        expression: "Я хотел стать врачом",
        transcription: "aɪ ˈwɒntɪd tə bɪˈkʌm ə ˈdɒktə",
        translation: "I wanted to become a doctor",
      },
      {
        id: 72,
        expression: "Он не хотел стать программистом",
        transcription: "hi dɪdnt wɒnt tə bɪˈkʌm ə ˈprəʊgræmə",
        translation: "He didn't want to become a programmer",
      },
      {
        id: 73,
        expression: "Я проснулся слишком рано",
        transcription: "aɪ wəʊk ʌp tuː ˈɜːli",
        translation: "I woke up too early",
      },
      {
        id: 74,
        expression: "Она проснулась так рано",
        transcription: "ʃi wəʊk ʌp səʊ ˈɜːli",
        translation: "She woke up so early",
      },
      {
        id: 75,
        expression: "Он проснулся слишком рано",
        transcription: "hi wəʊk ʌp tuː ˈɜːli",
        translation: "He woke up too early",
      },
      {
        id: 76,
        expression: "Я знал, что делать",
        transcription: "aɪ njuː wɒt tə duː",
        translation: "I knew what to do",
      },
      {
        id: 77,
        expression: "Я знал, что сказать",
        transcription: "aɪ njuː wɒt tə seɪ",
        translation: "I knew what to say",
      },
      {
        id: 78,
        expression: "Мы не знали, что сказать",
        transcription: "wi dɪdnt nəʊ wɒt tə seɪ",
        translation: "We didn't know what to say",
      },
      {
        id: 79,
        expression: "Я не выбрал другой ответ",
        transcription: "aɪ dɪdnt ʧuːz əˈnʌðər ˈɑːnsə",
        translation: "I didn't choose another answer",
      },
      {
        id: 80,
        expression: "Я выбрал другой ответ",
        transcription: "aɪ ʧəʊz əˈnʌðər ˈɑːnsə",
        translation: "I chose another answer",
      },
      {
        id: 81,
        expression: "Мы выбрали другой отель",
        transcription: "wi ʧəʊz əˈnʌðə həʊˈtɛl",
        translation: "We chose another hotel",
      },
      {
        id: 82,
        expression: "Я не смог найти свою сумку",
        transcription: "aɪ ˈkʊdnt faɪnd maɪ bæg",
        translation: "I couldn't find my bag",
      },
      {
        id: 83,
        expression: "Он не мог найти свою машину",
        transcription: "hi ˈkʊdnt faɪnd ɪz kɑː",
        translation: "He couldn't find his car",
      },
      {
        id: 84,
        expression: "Она не могла найти свою сумку",
        transcription: "ʃi ˈkʊdnt faɪnd hə bæg",
        translation: "She couldn't find her bag",
      },
      {
        id: 85,
        expression: "Ты заплатил за это?",
        transcription: "dɪd jʊ peɪ fər ɪt?",
        translation: "Did you pay for it?",
      },
      {
        id: 86,
        expression: "Он заплатил за это?",
        transcription: "dɪd hi peɪ fər ɪt?",
        translation: "Did he pay for it?",
      },
      {
        id: 87,
        expression: "Мы заплатили за эту еду?",
        transcription: "dɪd wi peɪ fə ðɪs fuːd?",
        translation: "Did we pay for this food?",
      },
      {
        id: 88,
        expression: "Я не мог этого понять",
        transcription: "aɪ ˈkʊdnt ˌʌndəˈstænd ɪt",
        translation: "I couldn't understand it",
      },
      {
        id: 89,
        expression: "Она преподавала математику",
        transcription: "ʃi tɔːt mæθs (ˌmæθɪˈmætɪks)",
        translation: "She taught maths (mathematics)",
      },
      {
        id: 90,
        expression: "Ты понял это правило?",
        transcription: "dɪd jʊ ˌʌndəˈstænd ðɪs ruːl?",
        translation: "Did you understand this rule?",
      },
      {
        id: 91,
        expression: "Я выбрал правильный ответ",
        transcription: "aɪ ʧəʊz ðə raɪt ˈɑːnsə",
        translation: "I chose the right answer",
      },
      {
        id: 92,
        expression: "Она стала учителем",
        transcription: "ʃi bɪˈkeɪm ə ˈtiːʧə",
        translation: "She became a teacher",
      },
      {
        id: 93,
        expression: "Он стал певцом",
        transcription: "hi bɪˈkeɪm ə ˈsɪŋə",
        translation: "He became a singer",
      },
      {
        id: 94,
        expression: "Это означало одну очень интересную вещь",
        transcription: "ɪt mɛnt wʌn ˈvɛri ˈɪntrɪstɪŋ θɪŋ",
        translation: "It meant one very interesting thing",
      },
      {
        id: 95,
        expression: "Я решил заплатить за это позже",
        transcription: "aɪ dɪˈsaɪdɪd tə peɪ fər ɪt ˈleɪtə",
        translation: "I decided to pay for it later",
      },
      {
        id: 96,
        expression: "Мы выбрали третий ответ",
        transcription: "wi ʧəʊz ðə θɜːd ˈɑːnsə",
        translation: "We chose the third answer",
      },
      {
        id: 97,
        expression: "Она стала актрисой",
        transcription: "ʃi bɪˈkeɪm ən ˈæktrɪs",
        translation: "She became an actress",
      },
      {
        id: 98,
        expression: "Я потерял свои ключи и я не смог их найти",
        transcription: "aɪ lɒst maɪ kiːz ənd aɪ ˈkʊdnt faɪnd ðɛm",
        translation: "I lost my keys and I couldn't find them",
      },
      {
        id: 99,
        expression: "Я выбрал второй ответ",
        transcription: "aɪ ʧəʊz ðə ˈsɛkənd ˈɑːnsə",
        translation: "I chose the second answer",
      },
      {
        id: 100,
        expression: "Я не знал, что делать в той ситуации",
        transcription: "aɪ dɪdnt nəʊ wɒt tə dʊ ɪn ðət ˌsɪtjʊˈeɪʃən",
        translation: "I didn't know what to do in that situation",
      },
      {
        id: 101,
        expression: "Он выбрал неверный ответ",
        transcription: "hi ʧəʊz ðə rɒŋ ˈɑːnsə",
        translation: "He chose the wrong answer",
      },
      {
        id: 102,
        expression: "Я не заплатил за свою учебу",
        transcription: "aɪ dɪdnt peɪ fə maɪ ˌɛdju(ː)ˈkeɪʃən",
        translation: "I didn't pay for my education",
      },
    ],
    {
      theory: `<div class="theory-block">
<p>Устойчивые выражения:</p>
<h3>wake up</h3>
<p>- просыпаться</p>
<p>I woke up very late - Я проснулся очень поздно.</p>
<h3>get up</h3>
<p>- вставать (после сна)</p>
<p>We got up at eight today- Мы встали в восемь сегодня</p>
</div>`,
    },
  ],
];
