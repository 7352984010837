export const wordsLesson57 = [
  [
    {
      id: 1,
      word: 'ambitious',
      transcription: 'æmˈbɪʃəs',
      translation: 'амбициозный',
    },
    {
      id: 2,
      word: 'bed',
      transcription: 'bɛd',
      translation: 'кровать',
    },
    {
      id: 3,
      word: 'bottle',
      transcription: 'ˈbɒtl',
      translation: 'бутылка',
    },
    {
      id: 4,
      word: 'church',
      transcription: 'ʧɜːʧ',
      translation: 'церковь',
    },
    {
      id: 5,
      word: 'Earth',
      transcription: 'ɜːθ',
      translation: 'Земля',
    },
    {
      id: 6,
      word: 'empty',
      transcription: 'ˈɛmpti',
      translation: 'пустой',
    },
    {
      id: 7,
      word: 'energy',
      transcription: 'ˈɛnəʤi',
      translation: 'энергия',
    },
    {
      id: 8,
      word: 'enthusiasm',
      transcription: 'ɪnˈθjuːzɪæzm',
      translation: 'энтузиазм',
    },
    {
      id: 9,
      word: 'experienced',
      transcription: 'ɪksˈpɪərɪənst',
      translation: 'опытный',
    },
    {
      id: 10,
      word: 'feeling',
      transcription: 'ˈfiːlɪŋ',
      translation: 'чувство',
    },
    {
      id: 11,
      word: 'full',
      transcription: 'fʊl',
      translation: 'полный',
    },
    {
      id: 12,
      word: 'joke',
      transcription: 'ʤəʊk',
      translation: 'шутка',
    },
    {
      id: 13,
      word: 'narrow',
      transcription: 'ˈnærəʊ',
      translation: 'узкий',
    },
    {
      id: 14,
      word: 'obvious',
      transcription: 'ˈɒbvɪəs',
      translation: 'очевидный',
    },
    {
      id: 15,
      word: 'police',
      transcription: 'pəˈliːs',
      translation: 'полиция',
    },
    {
      id: 16,
      word: 'rainy',
      transcription: 'ˈreɪni',
      translation: 'дождливый',
    },
    {
      id: 17,
      word: 'road',
      transcription: 'rəʊd',
      translation: 'дорога',
    },
    {
      id: 18,
      word: 'round',
      transcription: 'raʊnd',
      translation: 'круглый',
    },
    {
      id: 19,
      word: 'slim',
      transcription: 'slɪm',
      translation: 'стройный',
    },
    {
      id: 20,
      word: 'sunny',
      transcription: 'ˈsʌni',
      translation: 'солнечный',
    },
    {
      id: 21,
      word: 'thin',
      transcription: 'θɪn',
      translation: 'тонкий',
    },
    {
      id: 22,
      word: 'understanding',
      transcription: 'ˌʌndəˈstændɪŋ',
      translation: 'понимание',
    },
    {
      id: 23,
      word: 'uninformative',
      transcription: 'ˌʌnɪnˈfɔːmətɪv',
      translation: 'неинформативный',
    },
    {
      id: 24,
      word: 'wide',
      transcription: 'waɪd',
      translation: 'широкий',
    },
  ],
];
