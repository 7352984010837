export const contentLesson108 = [
  [
    {
      title: "Урок 108. Prepositions in, at, on, of, to. Part 2",
    },
    [
      {
        id: 1,
        expression: "Я вернусь через минуту",
        transcription: "aɪl bi bæk ɪn ə ˈmɪnɪt",
        translation: "I'll be back in a minute",
      },
      {
        id: 2,
        expression: "Я обычно покупаю это на рынке",
        transcription: "aɪ ˈjuːʒʊəli baɪ ɪt ət ðə ˈmɑːkɪt",
        translation: "I usually buy it at the market",
      },
      {
        id: 3,
        expression: "Мы можем встретиться в субботу",
        transcription: "wi kən miːt ɒn ˈsætədeɪ",
        translation: "We can meet on Saturday",
      },
      {
        id: 4,
        expression: "Он часто ездит в свой загородный дом",
        transcription: "hi ˈɒf(ə)n gəʊz tə ɪz ˈkʌntri haʊs",
        translation: "He often goes to his country house",
      },
      {
        id: 5,
        expression: "Мы могли бы встретиться в субботу вечером",
        transcription: "wi kəd miːt ɒn ˈsætədeɪ ˈiːvnɪŋ",
        translation: "We could meet on Saturday evening",
      },
      {
        id: 6,
        expression: "Он обычно приходит домой из школы в три часа дня",
        transcription:
          "hi ˈjuːʒʊəli kʌmz həʊm frəm skuːl ət θriː əˈklɒk ɪn ði ˈɑːftəˈnuːn",
        translation:
          "He usually comes home from school at three o'clock in the afternoon",
      },
      {
        id: 7,
        expression: "Это рядом с дорогой",
        transcription: "ɪts nɪə ðə rəʊd",
        translation: "It's near the road",
      },
      {
        id: 8,
        expression: "Что ты обычно делаешь зимой?",
        transcription: "wɒt dʊ jʊ ˈjuːʒʊəli dʊ ɪn ˈwɪntə",
        translation: "What do you usually do in winter",
      },
      {
        id: 9,
        expression: "Она села в кресло",
        transcription: "ʃi sæt ɪn ən ˈɑːmˈʧeə",
        translation: "She sat in an armchair",
      },
      {
        id: 10,
        expression: "Она уехала в Англию",
        transcription: "ʃi wɛnt tʊ ˈɪŋglənd / ʃi lɛft fər ˈɪŋglənd",
        translation: "She went to England / She left for England",
      },
      {
        id: 11,
        expression: "Я обычно останавливаюсь в этом отеле",
        transcription: "aɪ ˈjuːʒʊəli steɪ æt (ɪn) ðɪs həʊˈtɛl",
        translation: "I usually stay at (in) this hotel",
      },
      {
        id: 12,
        expression: "Мы переехали в новую квартиру",
        transcription: "wi muːvd ˈɪntə ə njuː əˈpɑːtmənt",
        translation: "We moved into a new apartment",
      },
      {
        id: 13,
        expression: "Давайте встретимся во вторник утром!",
        transcription: "lɛts miːt ɒn ˈtjuːzdeɪ ˈmɔːnɪŋ!",
        translation: "Let's meet on Tuesday morning!",
      },
      {
        id: 14,
        expression: "Она обычно вставала очень рано утром",
        transcription: "ʃi ˈjuːʒʊəli gɒt ʌp ˈvɛri ˈɜːli ɪn ðə ˈmɔːnɪŋ",
        translation: "She usually got up very early in the morning",
      },
      {
        id: 15,
        expression: "Давай встретимся в пятницу!",
        transcription: "lɛts miːt ɪn ˈfraɪdeɪ!",
        translation: "Let's meet in Friday!",
      },
      {
        id: 16,
        expression: "Весной он часто ездит в свой загородный дом",
        transcription: "hi ˈɒf(ə)n gəʊz tə ɪz ˈkʌntri haʊs ɪn sprɪŋ",
        translation: "He often goes to his country house in spring",
      },
      {
        id: 17,
        expression: "Он обычно просыпается очень рано утром",
        transcription: "hi ˈjuːʒʊəli weɪks ʌp ˈvɛri ˈɜːli ɪn ðə ˈmɔːnɪŋ",
        translation: "He usually wakes up very early in the morning",
      },
      {
        id: 18,
        expression: "Обычно он уходит на работу в восемь часов утра",
        transcription: "hi ˈjuːʒʊəli liːvz fə wɜːk ət eɪt əˈklɒk ɪn ðə ˈmɔːnɪŋ",
        translation:
          "He usually leaves for work at eight o'clock in the morning",
      },
      {
        id: 19,
        expression: "Я переехал в новый дом",
        transcription: "aɪ muːvd ˈɪntə ə njuː haʊs",
        translation: "I moved into a new house",
      },
      {
        id: 20,
        expression: "Я взял такси",
        transcription: "aɪ tʊk ə ˈtæksi",
        translation: "I took a taxi",
      },
      {
        id: 21,
        expression: "Он переехал в Чикаго",
        transcription: "hi muːvd tə ʃɪˈkɑːgəʊ",
        translation: "He moved to Chicago",
      },
      {
        id: 22,
        expression: "Я не хочу работать ночью",
        transcription: "aɪ dəʊnt wɒnt tə wɜːk ət naɪt",
        translation: "I don't want to work at night",
      },
      {
        id: 23,
        expression: "Я обычно прихожу домой в семь часов вечера",
        transcription: "aɪ ˈjuːʒʊəli kʌm həʊm ət ˈsɛvn əˈklɒk ɪn ði ˈiːvnɪŋ",
        translation: "I usually come home at seven o'clock in the evening",
      },
      {
        id: 24,
        expression: "У меня встреча в шесть",
        transcription: "aɪ həv ə ˈmiːtɪŋ ət sɪks",
        translation: "I have a meeting at six",
      },
      {
        id: 25,
        expression: "Он уехал в Штаты",
        transcription: "hi lɛft fə ðə steɪts",
        translation: "He left for the States",
      },
      {
        id: 26,
        expression: "Это рядом с банком",
        transcription: "ɪts nɪə ðə bæŋk",
        translation: "It's near the bank",
      },
      {
        id: 27,
        expression: "Куда ты обычно ездишь осенью?",
        transcription: "weə dʊ jʊ ˈjuːʒʊəli gəʊ ɪn ˈɔːtəm?",
        translation: "Where do you usually go in autumn?",
      },
      {
        id: 28,
        expression: "Он переехал в Бирмингем",
        transcription: "hi muːvd tə ˈbɜːmɪŋəm",
        translation: "He moved to Birmingham",
      },
      {
        id: 29,
        expression: "Она уехала в Испанию",
        transcription: "ʃi lɛft fə speɪn",
        translation: "She left for Spain",
      },
      {
        id: 30,
        expression: "Я обычно езжу куда-то за границу летом",
        transcription: "aɪ ˈjuːʒʊəli gəʊ ˈsʌmweər əˈbrɔːd ɪn ˈsʌmə",
        translation: "I usually go somewhere abroad in summer",
      },
      {
        id: 31,
        expression: "Он покинул страну",
        transcription: "hi lɛft ðə ˈkʌntri",
        translation: "He left the country",
      },
      {
        id: 32,
        expression: "Он сел на стул",
        transcription: "hi sæt ɒn ə ʧeə",
        translation: "He sat on a chair",
      },
      {
        id: 33,
        expression: "Я сел в его машину",
        transcription: "aɪ gɒt ɪn (ˈɪntuː) hɪz kɑː",
        translation: "I got in (into) his car",
      },
      {
        id: 34,
        expression: "Это рядом с отелем",
        transcription: "ɪts nɪə ðə həʊˈtɛl",
        translation: "It's near the hotel",
      },
      {
        id: 35,
        expression: "Я обычно прихожу на работу в десять часов утра",
        transcription: "aɪ ˈjuːʒʊəli kʌm tə wɜːk ət tɛn əˈklɒk ɪn ðə ˈmɔːnɪŋ",
        translation: "I usually come to work at ten o'clock in the morning",
      },
      {
        id: 36,
        expression: "Я сел в такси",
        transcription: "aɪ gɒt ɪn ə ˈtæksi",
        translation: "I got in a taxi",
      },
      {
        id: 37,
        expression: "Она уехала в другой город",
        transcription: "ʃi wɛnt tʊ əˈnʌðə ˈsɪti / ʃi lɛft fər əˈnʌðə ˈsɪti",
        translation: "She went to another city / She left for another city",
      },
      {
        id: 38,
        expression: "Он ушел из дома очень рано",
        transcription: "hi lɛft həʊm ˈvɛri ˈɜːli",
        translation: "He left home very early",
      },
      {
        id: 39,
        expression: "Он в здании",
        transcription: "hiːz ɪn ðə ˈbɪldɪŋ",
        translation: "He's in the building",
      },
      {
        id: 40,
        expression: "Он уехал в Россию",
        transcription: "hi lɛft fə ˈrʌʃə",
        translation: "He left for Russia",
      },
      {
        id: 41,
        expression: "Мы в лесу",
        transcription: "wɪər ɪn ðə ˈfɒrɪst",
        translation: "We're in the forest",
      },
      {
        id: 42,
        expression: "Я сел в машину",
        transcription: "aɪ gɒt ɪn ə kɑː",
        translation: "I got in a car",
      },
      {
        id: 43,
        expression: "Я покинул город",
        transcription: "aɪ lɛft ðə ˈsɪti",
        translation: "I left the city",
      },
      {
        id: 44,
        expression: "Он родился в Лос-Анджелесе",
        transcription: "hi wəz bɔːn ɪn lɒs ˈeɪnʤəlz",
        translation: "He was born in Los Angeles",
      },
      {
        id: 45,
        expression: "Ты можешь дать эту ручку мне?",
        transcription: "kən jʊ gɪv ðɪs pɛn tə miː?",
        translation: "Can you give this pen to me?",
      },
      {
        id: 46,
        expression: "Она родилась первого января",
        transcription: "ʃi wəz bɔːn ɒn ðə fɜːst əv ˈʤænjʊəri",
        translation: "She was born on the first of January",
      },
      {
        id: 47,
        expression: "Я вернусь через минуту",
        transcription: "aɪl bi bæk ɪn ə ˈmɪnɪt",
        translation: "I'll be back in a minute",
      },
      {
        id: 48,
        expression: "Я обычно покупаю это на рынке",
        transcription: "aɪ ˈjuːʒʊəli baɪ ɪt ət ðə ˈmɑːkɪt",
        translation: "I usually buy it at the market",
      },
      {
        id: 49,
        expression: "Я перезвоню тебе через час",
        transcription: "aɪl kɔːl jʊ bæk ɪn ən ˈaʊə",
        translation: "I'll call you back in an hour",
      },
      {
        id: 50,
        expression: "Я обычно покупаю свежие фрукты и овощи на рынке",
        transcription:
          "aɪ ˈjuːʒʊəli baɪ frɛʃ fruːt ənd ˈvɛʤtəb(ə)lz ət ðə ˈmɑːkɪt",
        translation: "I usually buy fresh fruit and vegetables at the market",
      },
      {
        id: 51,
        expression: "Я свяжусь с тобой примерно через десять минут",
        transcription: "aɪl gɛt ɪn tʌʧ wɪð jʊ ɪn əˈbaʊt tɛn ˈmɪnɪts",
        translation: "I'll get in touch with you in about ten minutes",
      },
      {
        id: 52,
        expression: "Ему нравится кататься на лыжах зимой",
        transcription: "hi laɪks ˈskiːɪŋ ɪn ˈwɪntə",
        translation: "He likes skiing in winter",
      },
      {
        id: 53,
        expression: "Ей нужно перевести это с английского на русский",
        transcription: "ʃi niːdz tə trænsˈleɪt ɪt frəm ˈɪŋglɪʃ ˈɪntə ˈrʌʃə",
        translation: "She needs to translate it from English into Russia",
      },
      {
        id: 54,
        expression: "Я сошел с самолета",
        transcription: "aɪ gɒt ɒf ðə pleɪn",
        translation: "I got off the plane",
      },
      {
        id: 55,
        expression: "Она вошла в комнату",
        transcription: "ʃi ˈɛntəd ðə ruːm / ʃi keɪm ˈɪntə ðə ruːm",
        translation: "She entered the room / She came into the room",
      },
      {
        id: 56,
        expression: "Пациент должен быть в постели",
        transcription: "ðə ˈpeɪʃənt məst bi ɪn bɛd",
        translation: "The patient must be in bed",
      },
      {
        id: 57,
        expression: "Пульт дистанционно управления лежит на диване",
        transcription: "ðə rɪˈməʊt kənˈtrəʊl laɪz ɒn ðə ˈsəʊfə",
        translation: "The remote control lies on the sofa",
      },
      {
        id: 58,
        expression: "Я родился 15 марта",
        transcription: "aɪ wəz bɔːn ɒn ðə ˈfˈɪftiːnθ əv mɑːʧ",
        translation: "I was born on the fifteenth of March",
      },
      {
        id: 59,
        expression: "Он прочитал это в новостях",
        transcription: "hi red ɪt ɪn ðə njuːz",
        translation: "He read it in the news",
      },
      {
        id: 60,
        expression: "Что ты обычно делаешь в день Нового года?",
        transcription: "wɒt dʊ jʊ ˈjuːʒʊəli dʊ ɒn njuː jɪəz deɪ?",
        translation: "What do you usually do on New Year's Day?",
      },
      {
        id: 61,
        expression: "Где ты обычно празднуешь Новый год?",
        transcription: "weə dʊ jʊ ˈjuːʒʊəli ˈsɛlɪbreɪt njuː jɪə?",
        translation: "Where do you usually celebrate New Year?",
      },
      {
        id: 62,
        expression: "Что ты обычно делаешь по понедельникам?",
        transcription: "wɒt dʊ jʊ ˈjuːʒʊəli dʊ ɒn ˈmʌndeɪz",
        translation: "What do you usually do on Mondays?",
      },
      {
        id: 63,
        expression: "Я вышел из автобуса",
        transcription: "aɪ gɒt ɒf ðə bʌs",
        translation: "I got off the bus",
      },
      {
        id: 64,
        expression: "Ты вышел из автобуса?",
        transcription: "dɪd jʊ gɛt ɒf ðə bʌs?",
        translation: "Did you get off the bus?",
      },
      {
        id: 65,
        expression: "Я обычно ходил на рынок один раз в неделю",
        transcription: "aɪ ˈjuːʒʊəli wɛnt tə ðə ˈmɑːkɪt wʌns ə wiːk",
        translation: "I usually went to the market once a week",
      },
      {
        id: 66,
        expression: "Я всегда хожу на рынок два раза в неделю",
        transcription: "aɪ ˈɔːlweɪz gəʊ tə ðə ˈmɑːkɪt twaɪs ə wiːk",
        translation: "I always go to the market twice a week",
      },
      {
        id: 67,
        expression: "Как часто ты ходил на рынок?",
        transcription: "haʊ ˈɒf(ə)n dɪd jʊ gəʊ tə ðə ˈmɑːkɪt?",
        translation: "How often did you go to the market?",
      },
      {
        id: 68,
        expression: "Я вышел из машины",
        transcription: "aɪ gɒt aʊt əv ðə kɑː",
        translation: "I got out of the car",
      },
      {
        id: 69,
        expression: "Она вышла из машины?",
        transcription: "dɪd ʃi gɛt aʊt əv ðə kɑː?",
        translation: "Did she get out of the car?",
      },
      {
        id: 70,
        expression: "Я не выходил из этой машины",
        transcription: "aɪ dɪdnt gɛt aʊt əv ðɪs kɑː",
        translation: "I didn't get out of this car",
      },
      {
        id: 71,
        expression: "Что он обычно делает на Новый год?",
        transcription: "wɒt dəz hi ˈjuːʒʊəli dʊ ət njuː jɪə?",
        translation: "What does he usually do at New Year?",
      },
      {
        id: 72,
        expression: "Как ты обычно празднуешь Новый год?",
        transcription: "haʊ dʊ jʊ ˈjuːʒʊəli ˈsɛlɪbreɪt njuː jɪə?",
        translation: "How do you usually celebrate New Year?",
      },
      {
        id: 73,
        expression: "Что ты делал на Новый год?",
        transcription: "wɒt dɪd jʊ dʊ ət njuː jɪə?",
        translation: "What did you do at New Year?",
      },
      {
        id: 74,
        expression: "Мне нужно перевести это с русского на английский",
        transcription: "aɪ niːd tə trænsˈleɪt ɪt frəm ˈrʌʃ(ə)n ˈɪntə ˈɪŋglɪʃ",
        translation: "I need to translate it from Russian into English",
      },
      {
        id: 75,
        expression: "Я хочу перевести это с русского на английский",
        transcription: "aɪ wɒnt tə trænsˈleɪt ɪt frəm ˈrʌʃ(ə)n ˈɪntə ˈɪŋglɪʃ",
        translation: "I want to translate it from Russian into English",
      },
      {
        id: 76,
        expression: "Мне нужно перевести это с английского на русский",
        transcription: "aɪ niːd tə trænsˈleɪt ɪt frəm ˈɪŋglɪʃ ˈɪntə ˈrʌʃ(ə)n",
        translation: "I need to translate it from English into Russian",
      },
      {
        id: 77,
        expression: "Тебе нравится путешествовать на машине?",
        transcription: "dʊ jʊ laɪk ˈtrævlɪŋ baɪ kɑː?",
        translation: "Do you like travelling by car?",
      },
      {
        id: 78,
        expression: "Тебе нравится путешествовать на самолете?",
        transcription: "dʊ jʊ laɪk ˈtrævlɪŋ baɪ pleɪn?",
        translation: "Do you like travelling by plane?",
      },
      {
        id: 79,
        expression: "Как часто ты путешествуешь на машине?",
        transcription: "haʊ ˈɒf(ə)n dʊ jʊ ˈtrævl baɪ kɑː?",
        translation: "How often do you travel by car?",
      },
      {
        id: 80,
        expression: "Я часто вижу это в новостях",
        transcription: "aɪ ˈɒf(ə)n siː ɪt ɪn ðə njuːz",
        translation: "I often see it in the news",
      },
      {
        id: 81,
        expression: "Я часто слышу это в новостях",
        transcription: "aɪ ˈɒf(ə)n hɪər ɪt ɪn ðə njuːz",
        translation: "I often hear it in the news",
      },
      {
        id: 82,
        expression: "Как часто ты видишь это в новостях?",
        transcription: "haʊ ˈɒf(ə)n dʊ jʊ siː ɪt ɪn ðə njuːz?",
        translation: "How often do you see it in the news?",
      },
      {
        id: 83,
        expression: "Мой отец в офисе в данный момент",
        transcription: "maɪ ˈfɑːðə z ɪn ði ˈɒfɪs ət ðə ˈməʊmənt",
        translation: "My father is in the office at the moment",
      },
      {
        id: 84,
        expression: "Моего отца в этот момент не было в офисе",
        transcription: "maɪ ˈfɑːðə wɒznt ɪn ði ˈɒfɪs ət ðɪs ˈməʊmənt",
        translation: "My father wasn't in the office at this moment",
      },
      {
        id: 85,
        expression: "Мой отец был в своем офисе вчера",
        transcription: "maɪ ˈfɑːðə wəz ɪn ɪz ˈɒfɪs ˈjɛstədeɪ",
        translation: "My father was in his office yesterday",
      },
      {
        id: 86,
        expression: "Все продается по сниженным ценам",
        transcription: "ˈɛvrɪθɪŋ z ɒn seɪl",
        translation: "Everything is on sale",
      },
      {
        id: 87,
        expression: "Вся эта одежда продается по сниженным ценам",
        transcription: "ɔːl ðiːz kləʊðz ər ɒn seɪl",
        translation: "All these clothes are on sale",
      },
      {
        id: 88,
        expression: "Эта вещь продается по сниженным ценам?",
        transcription: "ɪz ðɪs θɪŋ ɒn seɪl?",
        translation: "Is this thing on sale?",
      },
      {
        id: 89,
        expression: "Пульт дистанционного управления лежит на столе",
        transcription: "ðə rɪˈməʊt kənˈtrəʊl laɪz ɒn ðə ˈteɪbl",
        translation: "The remote control lies on the table",
      },
      {
        id: 90,
        expression: "Я хочу перевести это на английский",
        transcription: "aɪ wɒnt tə trænsˈleɪt ɪt ˈɪntə ˈɪŋglɪʃ",
        translation: "I want to translate it into English",
      },
      {
        id: 91,
        expression: "Мне не нравится путешествовать на автобусе",
        transcription: "aɪ dəʊnt laɪk ˈtrævlɪŋ baɪ bʌs",
        translation: "I don't like travelling by bus",
      },
      {
        id: 92,
        expression: "Я сел в самолет",
        transcription: "aɪ gɒt ɒn ðə pleɪn",
        translation: "I got on the plane",
      },
      {
        id: 93,
        expression: "Ей нужно идти на рынок завтра",
        transcription: "ʃi niːdz tə gəʊ tə ðə ˈmɑːkɪt təˈmɒrəʊ",
        translation: "She needs to go to the market tomorrow",
      },
      {
        id: 94,
        expression: "Я свяжусь с тобой через пару минут",
        transcription: "aɪl gɛt ɪn tʌʧ wɪð jʊ ɪn ə ˈkʌpl əv ˈmɪnɪts",
        translation: "I'll get in touch with you in a couple of minutes",
      },
      {
        id: 95,
        expression: "Эта сумка продается по сниженным ценам",
        transcription: "ðɪs bæg z ɒn seɪl",
        translation: "This bag is on sale",
      },
      {
        id: 96,
        expression: "Он обычно покупает рыбу и мясо на рынке",
        transcription: "hi ˈjuːʒʊəli baɪz fɪʃ ənd miːt ət ðə ˈmɑːkɪt",
        translation: "He usually buys fish and meat at the market",
      },
      {
        id: 97,
        expression: "Он хочет пойти в театр",
        transcription: "hi wɒnts tə gəʊ tə ðə ˈθɪətə",
        translation: "He wants to go to the theatre",
      },
      {
        id: 98,
        expression: "Кто вошел в здание?",
        transcription: "huː keɪm ˈɪntə ðə ˈbɪldɪŋ? / huː ˈɛntəd ðə ˈbɪldɪŋ?",
        translation: "Who came into the building? / Who entered the building?",
      },
      {
        id: 99,
        expression: "Он родился 10 декабря",
        transcription: "hi wəz bɔːn ɒn ðə tɛnθ əv dɪˈsɛmbə",
        translation: "He was born on the tenth of December",
      },
      {
        id: 100,
        expression: "Ты можешь дать мне эту ручку",
        transcription: "kən jʊ gɪv ðɪs pɛn tə miː?",
        translation: "Can you give this pen to me?",
      },
      {
        id: 101,
        expression: "Все в этом магазине продается по сниженным ценам",
        transcription: "ˈɛvrɪθɪŋ ɪn ðɪs stɔː z ɒn seɪl",
        translation: "Everything in this store is on sale",
      },
      {
        id: 102,
        expression: "Я сел в автобус",
        transcription: "aɪ gɒt ɒn ðə bʌs",
        translation: "I got on the bus",
      },
      {
        id: 103,
        expression: "Я пойду на рынок сегодня",
        transcription: "aɪl gəʊ tə ðə ˈmɑːkɪt təˈdeɪ",
        translation: "I'll go to the market today",
      },
    ],
    {
      theory: `<div class="theory-block">
<p>В английском языке очень много всяких предлогов. Часть из них понимается и используется также, как в русском языке. Но иногда один и тот же предлог может быть переведен на русский язык по-разному.</p>
<h3>in - через (какой-то промежуток времени)</h3>
<p>I'll be there in a minute - Я буду там через минуту.</p>
<h3>at - у, около, в, на (указывает на нахождение где-либо)</h3>
<p>She usually buys products at the market - Она обычно поупает продукты на рынке.</p>
<h3>on - в (когда речь идет о днях недели или дате)</h3>
<p>we can meet on Saturday - Мы можем встретиться в субботу.</p>
<h3>to - к, в, на (указывает направление движения)</h3>
<p>He often goes to his country house on the weekend - По выходным он часто ездит на дачу.</p>
</div>`,
    },
  ],
];
