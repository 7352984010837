export const wordsLesson202 = [
  [
    {
      id: 1,
      word: 'basics',
      transcription: 'ˈbeɪsɪks',
      translation: 'основы',
    },
    {
      id: 2,
      word: 'fuel',
      transcription: 'fjʊəl',
      translation: 'топливо',
    },
    {
      id: 3,
      word: 'catch up with',
      transcription: 'kæʧ ʌp wɪð',
      translation: 'поспеть за',
    },
    {
      id: 4,
      word: 'keep up with',
      transcription: 'kiːp ʌp wɪð',
      translation: 'поспеть за',
    },
    {
      id: 5,
      word: 'be over',
      transcription: 'biː ˈəʊvə',
      translation: 'закончиться',
    },
    {
      id: 6,
      word: 'carry on',
      transcription: 'ˈkæri ɒn',
      translation: 'продолжать',
    },
    {
      id: 7,
      word: 'come across',
      transcription: 'kʌm əˈkrɒs',
      translation: 'встречать, сталкиваться',
    },
    {
      id: 8,
      word: 'run out of',
      transcription: 'rʌn aʊt ɒv',
      translation: 'заканчиваться',
    },
    {
      id: 9,
      word: 'look down on',
      transcription: 'lʊk daʊn ɒn',
      translation: 'смотреть свысока',
    },
    {
      id: 10,
      word: 'get on',
      transcription: 'gɛt ɒn',
      translation: 'поживать, сесть',
    },
    {
      id: 11,
      word: 'get along with',
      transcription: 'gɛt əˈlɒŋ wɪð',
      translation: 'ладить',
    },
    {
      id: 12,
      word: 'get on with',
      transcription: 'gɛt ɒn wɪð',
      translation: 'ладить',
    },
    {
      id: 13,
      word: 'get in',
      transcription: 'gɛt ɪn',
      translation: 'сесть',
    },
    {
      id: 14,
      word: 'get down to',
      transcription: 'gɛt daʊn tuː',
      translation: 'приступить к',
    },
    {
      id: 15,
      word: 'get down',
      transcription: 'gɛt daʊn',
      translation: 'расстраивать',
    },
    {
      id: 16,
      word: 'find out',
      transcription: 'faɪnd aʊt',
      translation: 'выяснить',
    },
    {
      id: 17,
      word: 'do about',
      transcription: 'duː əˈbaʊt',
      translation: 'делать с',
    },
  ],
];
