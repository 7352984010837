export const contentLesson148 = [
  [
    {
      title: 'Урок 148. Вопросы в отрицании',
    },
    [
      {
        id: 1,
        expression: 'Они не живут вместе?',
        transcription: 'dəʊnt ðeɪ lɪv təˈgɛðə?',
        translation: "Don't they live together?",
      },
      {
        id: 2,
        expression: 'Ты не счастлив?',
        transcription: 'ɑːnt jʊ ˈhæpi?',
        translation: "Aren't you happy?",
      },
      {
        id: 3,
        expression: 'Он не был там?',
        transcription: 'wɒznt hi ðeə?',
        translation: "Wasn't he there?",
      },
      {
        id: 4,
        expression: 'Он не жалеет об этом?',
        transcription: 'dʌznt hi rɪˈgrɛt ɪt?',
        translation: "Doesn't he regret it?",
      },
      {
        id: 5,
        expression: 'Тебя не интересует эта вещь?',
        transcription:
          'ɑːnt jʊ ˈɪntrɪstɪd ɪn ðɪs θɪŋ? / dʌznt ðɪs θɪŋ ˈɪntrɪst juː? / ˈɪznt ðɪs θɪŋ ˈɪntrɪstɪŋ fə juː?',
        translation:
          "Aren't you interested in this thing? / Doesn't this thing interest you? / Isn't this thing interesting for you?",
      },
      {
        id: 6,
        expression: 'Тебе не будет трудно?',
        transcription: 'wəʊnt ɪt bi ˈdɪfɪkəlt fə juː?',
        translation: "Won't it be difficult for you?",
      },
      {
        id: 7,
        expression: 'Он не ищет работу?',
        transcription: 'ˈɪznt hi ˈlʊkɪŋ fər ə ʤɒb?',
        translation: "Isn't he looking for a job?",
      },
      {
        id: 8,
        expression: 'Он не живет один?',
        transcription: 'dʌznt hi lɪv əˈlɒŋ?',
        translation: "Doesn't he live along?",
      },
      {
        id: 9,
        expression: 'Тебе это не интересно?',
        transcription: 'ˈɪznt ɪt ˈɪntrɪstɪŋ fə juː?',
        translation: "Isn't it interesting for you?",
      },
      {
        id: 10,
        expression: 'Ты не ждешь этого?',
        transcription: 'ɑːnt jʊ ˈweɪtɪŋ fər ɪt?',
        translation: "Aren't you waiting for it?",
      },
      {
        id: 11,
        expression: 'Ты не был там вчера?',
        transcription: 'wɜːnt jʊ ðeə ˈjɛstədeɪ?',
        translation: "Weren't you there yesterday?",
      },
      {
        id: 12,
        expression: 'Он не может сказать тебе эту вещь прямо?',
        transcription: 'kɑːnt hi tɛl jʊ ðɪs θɪŋ dɪˈrɛktli?',
        translation: "Can't he tell you this thing directly?",
      },
      {
        id: 13,
        expression: 'Ты не согласен с этим?',
        transcription: 'dəʊnt jʊ əˈgriː wɪð ɪt?',
        translation: "Don't you agree with it?",
      },
      {
        id: 14,
        expression: 'Ты не поедешь туда завтра?',
        transcription: 'wəʊnt jʊ gəʊ ðeə təˈmɒrəʊ?',
        translation: "Won't you go there tomorrow?",
      },
      {
        id: 15,
        expression: 'Ты этого не знаешь?',
        transcription: 'dəʊnt jʊ nəʊ ɪt?',
        translation: "Don't you know it?",
      },
      {
        id: 16,
        expression: 'Она не жалеет об этой вещи?',
        transcription: 'dʌznt ʃi rɪˈgrɛt ðɪs θɪŋ?',
        translation: "Doesn't she regret this thing?",
      },
      {
        id: 17,
        expression: 'Я не прав?',
        transcription: 'əm aɪ nɒt raɪt? / ɑːnt aɪ raɪt?',
        translation: "Am I not right? / Aren't I right?",
      },
      {
        id: 18,
        expression: 'Почему ты этого не делаешь?',
        transcription: 'waɪ dəʊnt jʊ dʊ ɪt?',
        translation: "Why don't you do it?",
      },
      {
        id: 19,
        expression: 'Он не врач?',
        transcription: 'ˈɪznt hi ə ˈdɒktə?',
        translation: "Isn't he a doctor?",
      },
      {
        id: 20,
        expression: 'Он не видит этого?',
        transcription: 'dʌznt hi siː ɪt?',
        translation: "Doesn't he see it?",
      },
      {
        id: 21,
        expression: 'Он не готов?',
        transcription: 'ˈɪznt hi ˈrɛdi?',
        translation: "Isn't he ready?",
      },
      {
        id: 22,
        expression: 'Ты не устал?',
        transcription: 'ɑːnt jʊ ˈtaɪəd?',
        translation: "Aren't you tired?",
      },
      {
        id: 23,
        expression: 'Тебе не нравится это?',
        transcription: 'dəʊnt jʊ laɪk ɪt?',
        translation: "Don't you like it?",
      },
      {
        id: 24,
        expression: 'Она не профессор?',
        transcription: 'ˈɪznt ʃi ə prəˈfɛsə?',
        translation: "Isn't she a professor?",
      },
      {
        id: 25,
        expression: 'Ты не сказал ему эту вещь?',
        transcription: 'dɪdnt jʊ tɛl ɪm ðɪs θɪŋ?',
        translation: "Didn't you tell him this thing?",
      },
      {
        id: 26,
        expression: 'Она не согласится сделать это?',
        transcription: 'wəʊnt ʃi əˈgriː tə dʊ ɪt?',
        translation: "Won't she agree to do it?",
      },
      {
        id: 27,
        expression: 'Ты не любишь пиццу?',
        transcription: 'dəʊnt jʊ laɪk ˈpiːtsə?',
        translation: "Don't you like pizza?",
      },
      {
        id: 28,
        expression: 'Они не счастливы вместе?',
        transcription: 'ɑːnt ðeɪ ˈhæpi təˈgɛðə?',
        translation: "Aren't they happy together?",
      },
      {
        id: 29,
        expression: 'Ты не знаешь этого правила?',
        transcription: 'dəʊnt jʊ nəʊ ðɪs ruːl?',
        translation: "Don't you know this rule?",
      },
      {
        id: 30,
        expression: 'Ты не любишь шоколад?',
        transcription: 'dəʊnt jʊ laɪk ˈʧɒkəlɪt?',
        translation: "Don't you like chocolate?",
      },
      {
        id: 31,
        expression: 'Она этого не понимает?',
        transcription: 'dʌznt ʃi ˌʌndəˈstænd ɪt?',
        translation: "Doesn't she understand it?",
      },
      {
        id: 32,
        expression: 'Ей не тринадцать?',
        transcription: 'ˈɪznt ʃi ˈθɜːˈtiːn?',
        translation: "Isn't she thirteen?",
      },
      {
        id: 33,
        expression: 'Ей не нравится эта идея?',
        transcription: 'dʌznt ʃi laɪk ðɪs aɪˈdɪə?',
        translation: "Doesn't she like this idea?",
      },
      {
        id: 34,
        expression: 'Они не живут в одном городе?',
        transcription: 'dʌznt ðeɪ lɪv ɪn ðə seɪm ˈsɪti?',
        translation: "Doesn't they live in the same city?",
      },
      {
        id: 35,
        expression: 'Ему не будет одиннадцать в этом году?',
        transcription: 'wəʊnt hi bi ɪˈlɛvn ðɪs jɪə?',
        translation: "Won't he be eleven this year?",
      },
      {
        id: 36,
        expression: 'Она не может сказать это прямо?',
        transcription: 'kɑːnt ʃi seɪ ɪt dɪˈrɛktli?',
        translation: "Can't she say it directly?",
      },
      {
        id: 37,
        expression: 'Ей не будет двенадцать в следующем году?',
        transcription: 'wəʊnt ʃi bi twɛlv nɛkst jɪə?',
        translation: "Won't she be twelve next year?",
      },
      {
        id: 38,
        expression: 'Твои родители не помогают ей?',
        transcription: 'dəʊnt jə ˈpeərənts hɛlp hɜː?',
        translation: "Don't your parents help her?",
      },
      {
        id: 39,
        expression: 'Ты не чувствуешь себя уставшим?',
        transcription: 'dəʊnt jʊ fiːl ˈtaɪəd?',
        translation: "Don't you feel tired?",
      },
      {
        id: 40,
        expression: 'Твоему брату не шестнадцать?',
        transcription: 'ˈɪznt jə ˈbrʌðə ˈsɪksˈtiːn?',
        translation: "Isn't your brother sixteen?",
      },
      {
        id: 41,
        expression: 'Разве он не профессор?',
        transcription: 'ˈɪznt hi ə prəˈfɛsə?',
        translation: "Isn't he a professor?",
      },
      {
        id: 42,
        expression: 'Почему ты не хочешь сделать это?',
        transcription: 'waɪ dəʊnt jʊ wɒnt tə dʊ ɪt?',
        translation: "Why don't you want to do it?",
      },
      {
        id: 43,
        expression: 'Твоему старшему брату не четырнадцать?',
        transcription: 'ˈɪznt jər ˈɛldə ˈbrʌðə ˈfɔːˈtiːn?',
        translation: "Isn't your elder brother fourteen?",
      },
      {
        id: 44,
        expression: 'У тебя нет друга, который живет в Англии?',
        transcription: 'dəʊnt jʊ həv ə frɛnd huː lɪvz ɪn ˈɪŋglənd?',
        translation: "Don't you have a friend who lives in England?",
      },
      {
        id: 45,
        expression: 'Тебе не хотелось бы жить в таком доме?',
        transcription: 'ˈwʊdnt jʊ laɪk tə lɪv ɪn sʌʧ ə haʊs?',
        translation: "Wouldn't you like to live in such a house?",
      },
      {
        id: 46,
        expression: 'Ты не был в Италии в прошлом месяце?',
        transcription: 'wɜːnt jʊ ɪn ˈɪtəli lɑːst mʌnθ?',
        translation: "Weren't you in Italy last month?",
      },
      {
        id: 47,
        expression: 'Эта сумка не твоя?',
        transcription: 'ˈɪznt ðɪs bæg jɔːz? / ˈɪznt ɪt jə bæg?',
        translation: "Isn't this bag yours? / Isn't it your bag?",
      },
      {
        id: 48,
        expression: 'Эта ошибка не была исправлена?',
        transcription: 'wɒznt ðɪs mɪsˈteɪk kəˈrɛktɪd?',
        translation: "Wasn't this mistake corrected?",
      },
      {
        id: 49,
        expression: 'Не лучше бы было сделать это сейчас?',
        transcription: 'ˈwʊdnt ɪt bi ˈbɛtə tə dʊ ɪt naʊ?',
        translation: "Wouldn't it be better to do it now?",
      },
      {
        id: 50,
        expression: 'Ты еще не съел обед?',
        transcription: 'hævnt jʊ ˈiːtn lʌnʧ jɛt?',
        translation: "Haven't you eaten lunch yet?",
      },
      {
        id: 51,
        expression: 'Ей не было семнадцати, когда они познакомились?',
        transcription: 'wɒznt ʃi ˈsɛvnˈtiːn wɛn ðeɪ mɛt?',
        translation: "Wasn't she seventeen when they met?",
      },
      {
        id: 52,
        expression: 'Тебе не хотелось бы жить в такой квартире?',
        transcription: 'ˈwʊdnt jʊ laɪk tə lɪv ɪn sʌʧ ən əˈpɑːtmənt?',
        translation: "Wouldn't you like to live in such an apartment?",
      },
      {
        id: 53,
        expression: 'У тебя нет друга, который живет в Соединенных Штатах?',
        transcription:
          'hævnt jʊ gɒt ə frɛnd huː lɪvz ɪn ðə jʊˈnaɪtɪd steɪts? / dəʊnt jʊ həv ə frɛnd huː lɪvz ɪn ðə jʊˈnaɪtɪd steɪts?',
        translation:
          "Haven't you got a friend who lives in the United States? / Don't you have a friend who lives in the United States?",
      },
      {
        id: 54,
        expression: 'Это не сделано в Японии?',
        transcription: 'ˈɪznt ɪt meɪd ɪn ʤəˈpæn?',
        translation: "Isn't it made in Japan?",
      },
      {
        id: 55,
        expression: 'Эти цифры не точные?',
        transcription: 'ɑːnt ðiːz ˈfɪgəz ɪgˈzækt?',
        translation: "Aren't these figures exact?",
      },
      {
        id: 56,
        expression: 'Это не будет скоро объявлено?',
        transcription: 'wəʊnt ɪt bi əˈnaʊnst suːn?',
        translation: "Won't it be announced soon?",
      },
      {
        id: 57,
        expression: 'Ему не было девятнадцати, когда он встретил ее?',
        transcription: 'wɒznt hi ˈnaɪnˈtiːn wɛn hi mɛt hɜː?',
        translation: "Wasn't he nineteen when he met her?",
      },
      {
        id: 58,
        expression: 'Почему ты его не послушал?',
        transcription: 'waɪ dɪdnt jʊ ˈlɪsn tə hɪm?',
        translation: "Why didn't you listen to him?",
      },
      {
        id: 59,
        expression: 'Он не работает менеджером?',
        transcription: 'dʌznt hi wɜːk əz ə ˈmænɪʤə?',
        translation: "Doesn't he work as a manager?",
      },
      {
        id: 60,
        expression: 'Он не работает тренером?',
        transcription: 'dʌznt hi wɜːk əz ə ˈtreɪnə?',
        translation: "Doesn't he work as a trainer?",
      },
      {
        id: 61,
        expression: 'Она не работает учителем?',
        transcription: 'dʌznt ʃi wɜːk əz ə ˈtiːʧə?',
        translation: "Doesn't she work as a teacher?",
      },
      {
        id: 62,
        expression: 'Этот дом не ее?',
        transcription: 'ˈɪznt ðɪs haʊs hɜːz? / ˈɪznt ɪt hə haʊs?',
        translation: "Isn't this house hers? / Isn't it her house?",
      },
      {
        id: 63,
        expression: 'Этот дом не твой?',
        transcription: 'ˈɪznt ðɪs haʊs jɔːz? / ˈɪznt ɪt jə haʊs?',
        translation: "Isn't this house yours? / Isn't it your house?",
      },
      {
        id: 64,
        expression: 'Этот дом не мой?',
        transcription: 'ˈɪznt ðɪs haʊs maɪn? / ˈɪznt ɪt maɪ haʊs?',
        translation: "Isn't this house mine? / Isn't it my house?",
      },
      {
        id: 65,
        expression: 'Тебе не следует идти на работу сейчас?',
        transcription: 'ʃʊdnt jʊ gəʊ tə wɜːk naʊ?',
        translation: "Shouldn't you go to work now?",
      },
      {
        id: 66,
        expression: 'Ты не пойдешь туда сейчас?',
        transcription: 'wəʊnt jʊ gəʊ ðeə naʊ?',
        translation: "Won't you go there now?",
      },
      {
        id: 67,
        expression: 'Ты не ходил на работу вчера?',
        transcription: 'dɪdnt jʊ gəʊ tə wɜːk ˈjɛstədeɪ?',
        translation: "Didn't you go to work yesterday?",
      },
      {
        id: 68,
        expression: 'Ты там не был?',
        transcription: 'wɜːnt jʊ bi ðeə? / hævnt jʊ biːn ðeə?',
        translation: "Weren't you be there? / Haven't you been there?",
      },
      {
        id: 69,
        expression: 'Она еще не была здесь?',
        transcription: 'ˈhæznt ʃi biːn hɪə jɛt?',
        translation: "Hasn't she been here yet?",
      },
      {
        id: 70,
        expression: 'Ты еще не сделал этого?',
        transcription: 'hævnt jʊ dʌn ɪt jɛt?',
        translation: "Haven't you done it yet?",
      },
      {
        id: 71,
        expression: 'Почему он не может этого сказать?',
        transcription: 'waɪ kɑːnt hi seɪ ɪt?',
        translation: "Why can't he say it?",
      },
      {
        id: 72,
        expression: 'Почему он не может этого сделать?',
        transcription: 'waɪ kɑːnt hi dʊ ɪt?',
        translation: "Why can't he do it?",
      },
      {
        id: 73,
        expression: 'Почему она не может встретить его?',
        transcription: 'waɪ kɑːnt ʃi miːt hɪm?',
        translation: "Why can't she meet him?",
      },
      {
        id: 74,
        expression: 'Он не может объяснить это нам?',
        transcription: 'kɑːnt hi ɪksˈpleɪn ɪt tʊ ʌs?',
        translation: "Can't he explain it to us?",
      },
      {
        id: 75,
        expression: 'Он не может показать это мне?',
        transcription: 'kɑːnt hi ʃəʊ ɪt tə miː?',
        translation: "Can't he show it to me?",
      },
      {
        id: 76,
        expression: 'Ты не можешь рекомендовать это нам?',
        transcription: 'kɑːnt jʊ ˌrɛkəˈmɛnd ɪt tʊ ʌs?',
        translation: "Can't you recommend it to us?",
      },
      {
        id: 77,
        expression: 'Он не ошибается?',
        transcription: 'ˈɪznt hi mɪsˈteɪkən?',
        translation: "Isn't he mistaken?",
      },
      {
        id: 78,
        expression: 'Я не был прав?',
        transcription: 'wɒznt aɪ raɪt?',
        translation: "Wasn't I right?",
      },
      {
        id: 79,
        expression: 'Ей не везет?',
        transcription: 'ˈɪznt ʃi ˈlʌki?',
        translation: "Isn't she lucky?",
      },
      {
        id: 80,
        expression: 'Этот сайт не популярен?',
        transcription: 'ˈɪznt ðɪs saɪt ˈpɒpjʊlə?',
        translation: "Isn't this site popular?",
      },
      {
        id: 81,
        expression: 'Этот фильм действительно не интересный?',
        transcription: 'ˈɪznt ðɪs ˈmuːvi ˈrɪəli ˈɪntrɪstɪŋ?',
        translation: "Isn't this movie really interesting?",
      },
      {
        id: 82,
        expression: 'Эта книга не новая?',
        transcription: 'ˈɪznt ðɪs bʊk njuː?',
        translation: "Isn't this book new?",
      },
      {
        id: 83,
        expression: 'У тебя нет наличных?',
        transcription: 'hævnt jʊ gɒt kæʃ? / dʊ jʊ həv kæʃ?',
        translation: "Haven't you got cash? / Do you have cash?",
      },
      {
        id: 84,
        expression: 'У тебя нет денег?',
        transcription: 'hævnt jʊ gɒt ˈɛni ˈmʌni? / dʊ jʊ həv ˈɛni ˈmʌni?',
        translation: "Haven't you got any money? / Do you have any money?",
      },
      {
        id: 85,
        expression: 'Ты не знаешь, что это правда?',
        transcription: 'dəʊnt jʊ nəʊ ðət ɪts truː',
        translation: "Don't you know that it's true?",
      },
      {
        id: 86,
        expression: 'Ты не знал, что это была правда?',
        transcription: 'dɪdnt jʊ nəʊ ðət ɪt wəz truː?',
        translation: "Didn't you know that it was true?",
      },
      {
        id: 87,
        expression: 'Ты не знаешь, что это очень плохо?',
        transcription: 'dəʊnt jʊ nəʊ ðət ɪts ˈvɛri bæd?',
        translation: "Don't you know that it's very bad?",
      },
      {
        id: 88,
        expression: 'Почему вы не откажетесь это сделать?',
        transcription: 'waɪ dəʊnt jʊ ˌriːˈfjuːz tə dʊ ɪt?',
        translation: "Why don't you refuse to do it?",
      },
      {
        id: 89,
        expression: 'Это еще не случилось?',
        transcription: 'ˈhæznt ɪt ˈhæpən jɛt?',
        translation: "Hasn't it happen yet?",
      },
      {
        id: 90,
        expression: 'Дети не в школе?',
        transcription: 'ɑːnt ðə ˈʧɪldrən ət skuːl?',
        translation: "Aren't the children at school?",
      },
      {
        id: 91,
        expression: 'Ты не хотел бы что-нибудь выпить?',
        transcription: 'ˈwʊdnt jʊ laɪk ˈsʌmθɪŋ tə drɪŋk?',
        translation: "Wouldn't you like something to drink?",
      },
      {
        id: 92,
        expression: 'Не было бы лучше сделать это завтра?',
        transcription: 'ˈwʊdnt ɪt bi ˈbɛtə tə dʊ ɪt təˈmɒrəʊ?',
        translation: "Wouldn't it be better to do it tomorrow?",
      },
      {
        id: 93,
        expression: 'Он не может этого понять?',
        transcription: 'kɑːnt hi ˌʌndəˈstænd ɪt?',
        translation: "Can't he understand it?",
      },
      {
        id: 94,
        expression: 'Ты еще не встретил его?',
        transcription: 'hævnt jʊ mɛt ɪm jɛt?',
        translation: "Haven't you met him yet?",
      },
      {
        id: 95,
        expression: 'Она еще там не была?',
        transcription: 'ˈhæznt ʃi biːn ðeə jɛt?',
        translation: "Hasn't she been there yet?",
      },
      {
        id: 96,
        expression: 'Он не может сказать тебе правду?',
        transcription: 'kɑːnt hi tɛl jʊ ðə truːθ?',
        translation: "Can't he tell you the truth?",
      },
      {
        id: 97,
        expression: 'Ты еще не сказал это?',
        transcription: 'hævnt jʊ sɛd ɪt jɛt?',
        translation: "Haven't you said it yet?",
      },
      {
        id: 98,
        expression: 'Я не прав?',
        transcription: 'ɑːnt aɪ raɪt?',
        translation: "Aren't I right?",
      },
      {
        id: 99,
        expression: 'Это не бесплатно?',
        transcription: 'ˈɪznt ɪt friː?',
        translation: "Isn't it free?",
      },
      {
        id: 100,
        expression: 'Мне не везет?',
        transcription: 'ɑːnt aɪ ˈlʌki',
        translation: "Aren't I lucky",
      },
      {
        id: 101,
        expression: 'Почему мы не можем этого сделать?',
        transcription: 'waɪ kɑːnt wi dʊ ɪt?',
        translation: "Why can't we do it?",
      },
      {
        id: 102,
        expression: 'Мне не следует этого делать?',
        transcription: 'ʃʊdnt aɪ dʊ ɪt?',
        translation: "Shouldn't I do it?",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Отрицательные вопросы используются, чтобы спросить, что человек не сделал, или он не собирается делать.</h3>
<p>Общая формула такая:</p>
<p><span>Don't / Doesn't / Isn't / Aren't / Won't / Wasn't / Weren't / Can't / Wouldn't / Shouldn't / Haven't / Hasn't + подлежащее</span></p>
<p>Don't they live together? - Они не живут вместе?</p>
<p>Wasn't she at school? - Ее не было в школе?</p>
<p>Aren't you busy? - Ты не занят?</p>
</div>`,
    },
  ],
];
