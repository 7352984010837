export const wordsLesson161 = [
  [
    {
      id: 1,
      word: 'so far',
      transcription: 'səʊ fɑː',
      translation: 'пока',
    },
    {
      id: 2,
      word: 'hockey',
      transcription: 'ˈhɒki',
      translation: 'хоккей',
    },
  ],
];
