export const wordsLesson14 = [
  [
    {
      id: 1,
      word: 'alright',
      transcription: 'ɔːlˈraɪt',
      translation: 'в порядке',
    },
    {
      id: 2,
      word: 'American',
      transcription: 'əˈmɛrɪkən',
      translation: 'американский',
    },
    {
      id: 3,
      word: 'beautiful',
      transcription: 'ˈbjuːtəfʊl',
      translation: 'красивый',
    },
    {
      id: 4,
      word: 'dangerous',
      transcription: 'ˈdeɪnʤrəs',
      translation: 'опасный',
    },
    {
      id: 5,
      word: 'difficult',
      transcription: 'ˈdɪfɪkəlt',
      translation: 'сложный, трудный',
    },
    {
      id: 6,
      word: 'easy',
      transcription: 'ˈiːzi',
      translation: 'легкий',
    },
    {
      id: 7,
      word: 'effective',
      transcription: 'ɪˈfɛktɪv',
      translation: 'эффективный',
    },
    {
      id: 8,
      word: 'eight',
      transcription: 'eɪt',
      translation: 'восемь',
    },
    {
      id: 9,
      word: 'eighteen',
      transcription: 'ˌeɪˈtiːn',
      translation: 'восемнадцать',
    },
    {
      id: 10,
      word: 'fine',
      transcription: 'faɪn',
      translation: 'хорошо, в порядке',
    },
    {
      id: 11,
      word: 'hard-working',
      transcription: 'ˈhɑːdˌwɜːkɪŋ',
      translation: 'трудолюбивый',
    },
    {
      id: 12,
      word: 'lazy',
      transcription: 'ˈleɪzi',
      translation: 'ленивый',
    },
    {
      id: 13,
      word: 'married',
      transcription: 'ˈmærɪd',
      translation: 'женат, замужем',
    },
    {
      id: 14,
      word: 'OK',
      transcription: 'ˈəʊˈkeɪ',
      translation: 'окей, в порядке',
    },
    {
      id: 15,
      word: 'parents',
      transcription: 'ˈpeərənts',
      translation: 'родители',
    },
    {
      id: 16,
      word: 'poor',
      transcription: 'pʊə',
      translation: 'бедный',
    },
    {
      id: 17,
      word: 'possible',
      transcription: 'ˈpɒsəbl',
      translation: 'возможный',
    },
    {
      id: 18,
      word: 'ready',
      transcription: 'ˈrɛdi',
      translation: 'готов',
    },
    {
      id: 19,
      word: 'rich',
      transcription: 'rɪʧ',
      translation: 'богатый',
    },
    {
      id: 20,
      word: 'seventy',
      transcription: 'ˈsɛvnti',
      translation: 'семьдесят',
    },
    {
      id: 21,
      word: 'single',
      transcription: 'ˈsɪŋgl',
      translation: 'холост',
    },
    {
      id: 22,
      word: 'smart',
      transcription: 'smɑːt',
      translation: 'умный',
    },
  ],
];
