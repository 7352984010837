export const wordsLesson297 = [
  [
    {
      id: 1,
      word: 'accordance',
      transcription: 'əˈkɔːdəns',
      translation: 'соответствие',
    },
    {
      id: 2,
      word: 'astonishingly',
      transcription: 'əsˈtɒnɪʃɪŋli',
      translation: 'удивительно',
    },
    {
      id: 3,
      word: 'boastful',
      transcription: 'ˈbəʊstfʊl',
      translation: 'хвастливый',
    },
    {
      id: 4,
      word: 'bureaucratic',
      transcription: 'ˌbjʊərəˈkrætɪk',
      translation: 'бюрократический',
    },
    {
      id: 5,
      word: 'co-founder',
      transcription: 'kəʊ-ˈfaʊndə',
      translation: 'соучредитель',
    },
    {
      id: 6,
      word: 'comprehension',
      transcription: 'ˌkɒmprɪˈhɛnʃᵊn',
      translation: 'понимание',
    },
    {
      id: 7,
      word: 'comprehensive',
      transcription: 'ˌkɒmprɪˈhɛnsɪv',
      translation: 'исчерпывающий, образовательный',
    },
    {
      id: 8,
      word: 'diligently',
      transcription: 'ˈdɪlɪʤəntli',
      translation: 'усердно',
    },
    {
      id: 9,
      word: 'disheartening',
      transcription: 'dɪsˈhɑːtnɪŋ',
      translation: 'удручающий',
    },
    {
      id: 10,
      word: 'disrespectful',
      transcription: 'ˌdɪsrɪsˈpɛktfʊl',
      translation: 'неуважительный',
    },
    {
      id: 11,
      word: 'disrespectfully',
      transcription: 'ˌdɪsrɪsˈpɛktfʊli',
      translation: 'неуважительно',
    },
    {
      id: 12,
      word: 'eradication',
      transcription: 'ɪˌrædɪˈkeɪʃᵊn',
      translation: 'искоренение',
    },
    {
      id: 13,
      word: 'excessive',
      transcription: 'ɪkˈsɛsɪv',
      translation: 'чрезмерный',
    },
    {
      id: 14,
      word: 'fashionably',
      transcription: 'ˈfæʃnəbᵊli',
      translation: 'модно',
    },
    {
      id: 15,
      word: 'greatness',
      transcription: 'ˈɡreɪtnəs',
      translation: 'величие',
    },
    {
      id: 16,
      word: 'indescribable',
      transcription: 'ˌɪndɪsˈkraɪbəbᵊl',
      translation: 'неописуемый',
    },
    {
      id: 17,
      word: 'insensitive',
      transcription: 'ɪnˈsɛnsɪtɪv',
      translation: 'бесчувственный',
    },
    {
      id: 18,
      word: 'insignificance',
      transcription: 'ˌɪnsɪɡˈnɪfɪkəns',
      translation: 'незначительность',
    },
    {
      id: 19,
      word: 'installation',
      transcription: 'ˌɪnstəˈleɪʃᵊn',
      translation: 'установка',
    },
    {
      id: 20,
      word: 'irreversible',
      transcription: 'ˌɪrɪˈvɜːsəbᵊl',
      translation: 'необратимый',
    },
    {
      id: 21,
      word: 'irritably',
      transcription: 'ˈɪrɪtəbᵊli',
      translation: 'раздраженно',
    },
    {
      id: 22,
      word: 'irritation',
      transcription: 'ˌɪrɪˈteɪʃᵊn',
      translation: 'раздражение',
    },
    {
      id: 23,
      word: 'linguist',
      transcription: 'ˈlɪŋɡwɪst',
      translation: 'лингвист',
    },
    {
      id: 24,
      word: 'mismanagement',
      transcription: 'ˌmɪsˈmænɪʤmənt',
      translation: 'неправильное управление',
    },
    {
      id: 25,
      word: 'murmur',
      transcription: 'ˈmɜːmə',
      translation: 'бормотать',
    },
    {
      id: 26,
      word: 'presence',
      transcription: 'ˈprɛzns',
      translation: 'присутствие',
    },
    {
      id: 27,
      word: 'reconsider',
      transcription: 'ˌriːkənˈsɪdə',
      translation: 'пересматривать',
    },
    {
      id: 28,
      word: 'respectful',
      transcription: 'rɪsˈpɛktfʊl',
      translation: 'уважительный',
    },
    {
      id: 29,
      word: 'romantically',
      transcription: 'rəʊˈmæntɪkᵊli',
      translation: 'романтически',
    },
    {
      id: 30,
      word: 'shareholder',
      transcription: 'ˈʃeəˌhəʊldə',
      translation: 'акционер',
    },
    {
      id: 31,
      word: 'sorrow',
      transcription: 'ˈsɒrəʊ',
      translation: 'неприятность',
    },
    {
      id: 32,
      word: 'stabilize',
      transcription: 'ˈsteɪbɪlaɪz',
      translation: 'стабилизировать',
    },
    {
      id: 33,
      word: 'suspect',
      transcription: 'ˈsʌspɛkt',
      translation: 'подозревать',
    },
    {
      id: 34,
      word: 'uncertainty',
      transcription: 'ʌnˈsɜːtnti',
      translation: 'неопределенность',
    },
    {
      id: 35,
      word: 'uninstall',
      transcription: 'ˌʌnɪnˈstɔːl',
      translation: 'удалять',
    },
    {
      id: 36,
      word: 'unmanageable',
      transcription: 'ʌnˈmænɪʤəbᵊl',
      translation: 'неуправляемый',
    },
    {
      id: 37,
      word: 'unsuitable',
      transcription: 'ʌnˈsjuːtəbᵊl',
      translation: 'неподходящий',
    },
    {
      id: 38,
      word: 'visibility',
      transcription: 'ˌvɪzəˈbɪləti',
      translation: 'видимость',
    },
    {
      id: 39,
      word: 'well-balanced',
      transcription: 'wɛl-ˈbælənst',
      translation: 'хорошо сбалансированный',
    },
    {
      id: 40,
      word: 'well-organized',
      transcription: 'wɛl-ˈɔːɡənaɪzd',
      translation: 'хорошо организованный',
    },
    {
      id: 41,
      word: 'well-qualified',
      transcription: 'wɛl-ˈkwɒlɪfaɪd',
      translation: 'высококвалифицированный',
    },
  ],
];
