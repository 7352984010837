export const contentLesson98 = [
  [
    {
      title: "Урок 98. 270 Verbs in Different Tenses. Part 6",
    },
    [
      {
        id: 1,
        expression: "Эта игра развивает наши аналитические способности",
        transcription: "ðɪs geɪm dɪˈvɛləps ˈaʊər ˌænəˈlɪtɪkəl əˈbɪlɪtiz",
        translation: "This game develops our analytical abilities",
      },
      {
        id: 2,
        expression: "Он присоединился к нам с большим удовольствием",
        transcription: "hi ʤɔɪnd əs wɪð greɪt ˈplɛʒə",
        translation: "He joined us with great pleasure",
      },
      {
        id: 3,
        expression: "Она окончила этот университет в прошлом году",
        transcription: "ʃi ˈgrædjʊeɪtɪd frəm ðɪs ˌjuːnɪˈvɜːsɪti lɑːst jɪə",
        translation: "She graduated from this university last year",
      },
      {
        id: 4,
        expression: "Я считаю эту информацию очень важной",
        transcription: "aɪ kənˈsɪdə ðɪs ˌɪnfəˈmeɪʃən ˈvɛri ɪmˈpɔːtənt",
        translation: "I consider this information very important",
      },
      {
        id: 5,
        expression: "Они так сильно ударили мужчину",
        transcription: "ðeɪ hɪt ðə mæn səʊ ˈbædli",
        translation: "They hit the man so badly",
      },
      {
        id: 6,
        expression: "Те условия действительно пугали его",
        transcription: "ðəʊz kənˈdɪʃənz ˈrɪəli ˈfraɪtnd hɪm",
        translation: "Those conditions really frightened him",
      },
      {
        id: 7,
        expression: "Это платье подходит ей идеально",
        transcription: "ðɪs drɛs sjuːts hə ˈpɜːfɪktli",
        translation: "This dress suits her perfectly",
      },
      {
        id: 8,
        expression: "Это может развить твои профессиональные навыки",
        transcription: "ɪt kən dɪˈvɛləp jʊ prəˈfɛʃənl skɪlz",
        translation: "It can develop you professional skills",
      },
      {
        id: 9,
        expression: "Дети слишком часто его перебивают",
        transcription: "ðə kɪdz ˌɪntəˈrʌpt ɪm tuː ˈɒf(ə)n",
        translation: "The kids interrupt him too often",
      },
      {
        id: 10,
        expression: "Он ведет себя очень грубо",
        transcription: "hi bɪˈheɪvz ˈvɛri ˈruːdli",
        translation: "He behaves very rudely",
      },
      {
        id: 11,
        expression: "Я хотел бы добавить пару вещей",
        transcription: "aɪd laɪk tʊ æd ə ˈkʌpl əv θɪŋz",
        translation: "I'd like to add a couple of things",
      },
      {
        id: 12,
        expression: "Я могу делать эти вещи в одно и то же время",
        transcription: "aɪ kən dʊ ðiːz θɪŋz ət ðə seɪm taɪm",
        translation: "I can do these things at the same time",
      },
      {
        id: 13,
        expression: "Они внезапно повысили цену",
        transcription: "ðeɪ ɪnˈkriːst ðə praɪs ˈsʌdnli",
        translation: "They increased the price suddenly",
      },
      {
        id: 14,
        expression: "Они вели себя очень невежливо",
        transcription: "ðeɪ bɪˈheɪvd ˈvɛri ˌɪmpəˈlaɪtli",
        translation: "They behaved very impolitely",
      },
      {
        id: 15,
        expression: "Как он может жить на эти деньги?",
        transcription: "haʊ kən hi lɪv ɒn ðɪs ˈmʌni",
        translation: "How can he live on this money",
      },
      {
        id: 16,
        expression: "Что она приготовила?",
        transcription: "wɒt dɪd ʃi kʊk?",
        translation: "What did she cook?",
      },
      {
        id: 17,
        expression: "Я оставил свой ноутбук дома",
        transcription: "aɪ lɛft maɪ ˈlæpˌtɒp ət həʊm",
        translation: "I left my laptop at home",
      },
      {
        id: 18,
        expression: "Они ничего не гарантируют",
        transcription: "ðeɪ dəʊnt ˌgærənˈtiː ˈɛnɪθɪŋ",
        translation: "They don't guarantee anything",
      },
      {
        id: 19,
        expression: "Он ударил по мячу",
        transcription: "hi hɪt ðə bɔːl",
        translation: "He hit the ball",
      },
      {
        id: 20,
        expression: "Когда ты начал изучать английский",
        transcription: "wɛn dɪd jʊ stɑːt ˈlɜːnɪŋ ˈɪŋglɪʃ?",
        translation: "When did you start learning English?",
      },
      {
        id: 21,
        expression: "Она примет участие в этом соревновании",
        transcription: "ʃiːl teɪk pɑːt ɪn ðɪs ˌkɒmpɪˈtɪʃən",
        translation: "She'll take part in this competition",
      },
      {
        id: 22,
        expression: "Она нарисовала слона",
        transcription: "ʃi druː ən ˈɛlɪfənt",
        translation: "She drew an elephant",
      },
      {
        id: 23,
        expression: "Я думаю, это может напугать ее",
        transcription: "aɪ θɪŋk ɪt kən ˈfraɪtn hɜː",
        translation: "I think it can frighten her",
      },
      {
        id: 24,
        expression: "Позволь мне добавить одну очень важную вещь!",
        transcription: "lɛt mi æd wʌn ˈvɛri ɪmˈpɔːtənt θɪŋ!",
        translation: "Let me add one very important thing!",
      },
      {
        id: 25,
        expression: "Он создал этот канал около пяти лет назад",
        transcription: "hi kri(ː)ˈeɪtɪd ðɪs ˈʧænl əˈbaʊt faɪv jɪəz əˈgəʊ",
        translation: "He created this channel about five years ago",
      },
      {
        id: 26,
        expression: "Я оставил ноутбук на моем столе",
        transcription: "aɪ lɛft maɪ ˈlæpˌtɒp ɒn maɪ dɛsk",
        translation: "I left my laptop on my desk",
      },
      {
        id: 27,
        expression: "Она забыла запереть дверь",
        transcription: "ʃi fəˈgɒt tə lɒk ðə dɔː",
        translation: "She forgot to lock the door",
      },
      {
        id: 28,
        expression: "Они могли бы сократить расходы",
        transcription: "ðeɪ kəd rɪˈdjuːs ðə kɒsts",
        translation: "They could reduce the costs",
      },
      {
        id: 29,
        expression: "Он нарисовал очень забавную картинку",
        transcription: "hi druː ə ˈvɛri ˈfʌni ˈpɪkʧə",
        translation: "He drew a very funny picture",
      },
      {
        id: 30,
        expression: "Это совсем не пугает меня",
        transcription: "ɪt dʌznt ˈfraɪtn mi ət ɔːl",
        translation: "It doesn't frighten me at all",
      },
      {
        id: 31,
        expression: "Он хорошо готовит?",
        transcription: "dəz hi kʊk wɛl?",
        translation: "Does he cook well?",
      },
      {
        id: 32,
        expression: "Я окончу свой университет в следующем году",
        transcription: "aɪl ˈgrædjʊət frəm maɪ ˌjuːnɪˈvɜːsɪti nɛkst jɪə",
        translation: "I'll graduate from my university next year",
      },
      {
        id: 33,
        expression: "Не переплачивай за эти вещи!",
        transcription: "dəʊnt ˌəʊvəˈpeɪ fə ðiːz θɪŋz!",
        translation: "Don't overpay for these things!",
      },
      {
        id: 34,
        expression: "Он запер дверь?",
        transcription: "dɪd hi lɒk ðə dɔː?",
        translation: "Did he lock the door?",
      },
      {
        id: 35,
        expression: "Кто украл эти деньги?",
        transcription: "huː stəʊl ðɪs ˈmʌni?",
        translation: "Who stole this money?",
      },
      {
        id: 36,
        expression: "Я присоединюсь к тебе чуть позже",
        transcription: "aɪl ʤɔɪn jʊ ə bɪt ˈleɪtə",
        translation: "I'll join you a bit later",
      },
      {
        id: 37,
        expression: "Когда ты начнешь работать над этим проектом?",
        transcription: "wɛn wɪl jʊ stɑːt ˈwɜːkɪŋ ɒn ðɪs ˈprɒʤɛkt?",
        translation: "When will you start working on this project?",
      },
      {
        id: 38,
        expression: "Она не будет участвовать в этом конкурсе",
        transcription: "ʃi wəʊnt pɑːˈtɪsɪpeɪt ɪn ðɪs ˈkɒntɛst",
        translation: "She won't participate in this contest",
      },
      {
        id: 39,
        expression: "Когда ты навестишь нас в следующий раз?",
        transcription: "wɛn wɪl jʊ ˈvɪzɪt əs nɛkst taɪm?",
        translation: "When will you visit us next time?",
      },
      {
        id: 40,
        expression: "Я решил продолжить изучать английский более интенсивно",
        transcription:
          "aɪ dɪˈsaɪdɪd tə kənˈtɪnju(ː) ˈlɜːnɪŋ ˈɪŋglɪʃ mɔːr ɪnˈtɛnsɪvli",
        translation: "I decided to continue learning English more intensively",
      },
      {
        id: 41,
        expression:
          "Я знаю, что усердная работа может помочь мне достичь успеха",
        transcription: "aɪ nəʊ ðət hɑːd wɜːk kən hɛlp mi tʊ əˈʧiːv səkˈsɛs",
        translation: "I know that hard work can help me to achieve success",
      },
      {
        id: 42,
        expression:
          "Я значительно увеличил свой словарный запас благодаря этому курсу",
        transcription:
          "aɪ ɪnˈkriːst maɪ vəʊˈkæbjʊləri ˈgreɪtli θæŋk tə ðɪs kɔːs",
        translation: "I increased my vocabulary greatly thank to this course",
      },
      {
        id: 43,
        expression:
          "Если ты хочешь зарабатывать больше денег, тебе нужно найти более хорошо оплачиваемую работу",
        transcription:
          "ɪf jʊ wɒnt tʊ ɜːn mɔː ˈmʌni, jʊ niːd tə faɪnd ə mɔː wɛl-peɪd ʤɒb",
        translation:
          "If you want to earn more money, you need to find a more well-paid job",
      },
      {
        id: 44,
        expression:
          "Он пытался украсть его бумажник, но затем полиция арестовала его",
        transcription:
          "hi traɪd tə stiːl ɪz ˈwɒlɪt bət ðɛn ðə pəˈliːs əˈrɛstɪd hɪm",
        translation:
          "He tried to steal his wallet but then the police arrested him",
      },
      {
        id: 45,
        expression: "Я ожидаю, что я довольно скоро достигну следующего уровня",
        transcription: "aɪ ɪksˈpɛkt ðət aɪl riːʧ ðə nɛkst ˈlɛvl kwaɪt suːn",
        translation: "I expect that I'll reach the next level quite soon",
      },
      {
        id: 46,
        expression: "Им удалось создать очень позитивную атмосферу",
        transcription: "ðeɪ ˈmænɪʤd tə kri(ː)ˈeɪt ə ˈvɛri ˈpɒzətɪv ˈætməsfɪə",
        translation: "They managed to create a very positive atmosphere",
      },
      {
        id: 47,
        expression: "Они создали особые условия для бедных людей",
        transcription: "ðeɪ kri(ː)ˈeɪtɪd ˈspɛʃəl kənˈdɪʃənz fə pʊə ˈpiːpl",
        translation: "They created special conditions for poor people",
      },
      {
        id: 48,
        expression: "Сколько денег ты вложил в этот проект?",
        transcription: "haʊ mʌʧ ˈmʌni dɪd jʊ ɪnˈvɛst ɪn ðɪs ˈprɒʤɛkt",
        translation: "How much money did you invest in this project?",
      },
      {
        id: 49,
        expression: "Как ты отреагировал на эти замечания?",
        transcription: "haʊ dɪd jʊ ri(ː)ˈækt tə ðiːz ˈrɪˈmɑːks?",
        translation: "How did you react to these remarks?",
      },
      {
        id: 50,
        expression: "Она обычно навещает своих родителей два раза в месяц",
        transcription: "ʃi ˈjuːʒʊəli ˈvɪzɪts hə ˈpeərənts twaɪs ə wiːk",
        translation: "She usually visits her parents twice a week",
      },
      {
        id: 51,
        expression: "Я услышал странный шум снаружи",
        transcription: "aɪ hɜːd ə streɪnʤ nɔɪz ˌaʊtˈsaɪd",
        translation: "I heard a strange noise outside",
      },
      {
        id: 52,
        expression: "Конечно, очень легко критиковать других людей",
        transcription: "ˈsɜːtnli, ɪts ˈvɛri ˈiːzi tə ˈkrɪtɪsaɪz ˈʌðə ˈpiːpl",
        translation: "Certainly, it's very easy to criticize other people",
      },
      {
        id: 53,
        expression: "Я абсолютно уверен, что Бог действительно существует",
        transcription: "aɪm ˈæbsəluːtli ʃʊə ðət gɒd ˈrɪəli ɪgˈzɪsts",
        translation: "I'm absolutely sure that God really exists",
      },
      {
        id: 54,
        expression: "Он украл ее кошелек, но полиция поймала его",
        transcription: "hi stəʊl hə pɜːs bət ðə pəˈliːs kɔːt hɪm",
        translation: "He stole her purse but the police caught him",
      },
      {
        id: 55,
        expression: "Они обычно навещали его два раза в год",
        transcription: "ðeɪ ˈjuːʒʊəli ˈvɪzɪtɪd ɪm tuː taɪmz ə jɪə",
        translation: "They usually visited him two times a year",
      },
      {
        id: 56,
        expression: "Это идеально подходит тебе",
        transcription: "ɪt sjuːts jʊ ˈpɜːfɪktli",
        translation: "It suits you perfectly",
      },
      {
        id: 57,
        expression: "Они навещали его в больнице",
        transcription: "ðeɪ ˈvɪzɪtɪd ɪm ɪn ˈhɒspɪtl",
        translation: "They visited him in hospital",
      },
      {
        id: 58,
        expression: "Они будут посещать меня два раза в месяц",
        transcription: "ðeɪl ˈvɪzɪt mi tuː taɪmz ə mʌnθ",
        translation: "They'll visit me two times a month",
      },
      {
        id: 59,
        expression: "Ты будешь работать на выходных?",
        transcription: "wɪl jʊ wɜːk ət ðə ˈwiːkˈɛnd?",
        translation: "Will you work at the weekend?",
      },
      {
        id: 60,
        expression: "Я буду работать на этих выходных",
        transcription: "aɪl wɜːk ət ðɪs ˈwiːkˈɛnd",
        translation: "I'll work at this weekend",
      },
      {
        id: 61,
        expression: "Ты работал на выходных?",
        transcription: "dɪd jʊ wɜːk ət ðə ˈwiːkˈɛnd?",
        translation: "Did you work at the weekend?",
      },
      {
        id: 62,
        expression: "Когда ты достигнешь следующего уровня?",
        transcription: "wɛn wɪl jʊ riːʧ ðə nɛkst ˈlɛvl?",
        translation: "When will you reach the next level?",
      },
      {
        id: 63,
        expression: "Я скоро достигну этого уровня",
        transcription: "aɪl riːʧ ðə nɛkst ˈlɛvl suːn",
        translation: "I'll reach the next level soon",
      },
      {
        id: 64,
        expression: "Как ты достиг продвинутого уровня?",
        transcription: "haʊ dɪd jʊ riːʧ ən ədˈvɑːnst ˈlɛvl",
        translation: "How did you reach an advanced level?",
      },
      {
        id: 65,
        expression: "Я полагаю, что я сделал правильный выбор",
        transcription: "aɪ səˈpəʊz ðət aɪ meɪd ðə raɪt ʧɔɪs",
        translation: "I suppose that I made the right choice",
      },
      {
        id: 66,
        expression: "Я знаю, что он сделал правильный выбор",
        transcription: "aɪ nəʊ ðət hi meɪd ðə raɪt ʧɔɪs",
        translation: "I know that he made the right choice",
      },
      {
        id: 67,
        expression: "Это будет правильный выбор?",
        transcription: "wɪl ɪt bi ðə raɪt ʧɔɪs?",
        translation: "Will it be the right choice?",
      },
      {
        id: 68,
        expression: "Я действительно обожаю смотреть такого рода видео",
        transcription: "aɪ ˈrɪəli ɪnˈʤɔɪ ˈwɒʧɪŋ ðɪs kaɪnd əv ˈvɪdɪəʊz",
        translation: "I really enjoy watching this kind of videos",
      },
      {
        id: 69,
        expression: "Я действительно обожаю слушать такого рода музыку",
        transcription: "aɪ ˈrɪəli ɪnˈʤɔɪ ˈlɪsnɪŋ tə ðɪs kaɪnd əv ˈmjuːzɪk",
        translation: "I really enjoy listening to this kind of music",
      },
      {
        id: 70,
        expression: "Я действительно обожал смотреть такого рода фильмы",
        transcription: "aɪ ˈrɪəli ɪnˈʤɔɪd ˈwɒʧɪŋ ðɪs kaɪnd əv ˈmuːviz",
        translation: "I really enjoyed watching this kind of movies",
      },
      {
        id: 71,
        expression: "Когда началась встреча?",
        transcription: "wɛn dɪd ðə ˈmiːtɪŋ bɪˈgɪn?",
        translation: "When did the meeting begin?",
      },
      {
        id: 72,
        expression: "Когда началась эта встреча?",
        transcription: "wɛn dɪd ðɪs ˈmiːtɪŋ bɪˈgɪn?",
        translation: "When did this meeting begin?",
      },
      {
        id: 73,
        expression: "Когда закончилась встреча?",
        transcription: "wɛn dɪd ðə ˈmiːtɪŋ ɛnd?",
        translation: "When did the meeting end?",
      },
      {
        id: 74,
        expression: "Я считаю это достижение большим успехом",
        transcription: "aɪ kənˈsɪdə ðɪs əˈʧiːvmənt ə greɪt səkˈsɛs",
        translation: "I consider this achievement a great success",
      },
      {
        id: 75,
        expression: "Я считал это достижение реальным успехом",
        transcription: "aɪ kənˈsɪdəd ðɪs əˈʧiːvmənt ə rɪəl səkˈsɛs",
        translation: "I considered this achievement a real success",
      },
      {
        id: 76,
        expression: "Я считаю этого человека очень успешным",
        transcription: "aɪ kənˈsɪdə ðɪs mæn ˈvɛri səkˈsɛsfʊl",
        translation: "I consider this man very successful",
      },
      {
        id: 77,
        expression: "Я решил продолжить работать здесь",
        transcription: "aɪ dɪˈsaɪdɪd tə kənˈtɪnju(ː) ˈwɜːkɪŋ hɪə",
        translation: "I decided to continue working here",
      },
      {
        id: 78,
        expression: "Я решил начать работать здесь",
        transcription: "aɪ dɪˈsaɪdɪd tə stɑːt ˈwɜːkɪŋ hɪə",
        translation: "I decided to start working here",
      },
      {
        id: 79,
        expression: "Он решил перестать работать здесь",
        transcription: "hi dɪˈsaɪdɪd tə stɒp ˈwɜːkɪŋ hɪə",
        translation: "He decided to stop working here",
      },
      {
        id: 80,
        expression: "Не критикуйте других слишком сильно",
        transcription: "dəʊnt ˈkrɪtɪsaɪz ˈʌðəz tuː mʌʧ!",
        translation: "Don't criticize others too much!",
      },
      {
        id: 81,
        expression: "Ты слишком сильно критикуешь остальных",
        transcription: "jʊ ˈkrɪtɪsaɪz ˈʌðə ˈpiːpl tuː mʌʧ",
        translation: "You criticize other people too much",
      },
      {
        id: 82,
        expression: "Не критикуй других людей так сильно",
        transcription: "dəʊnt ˈkrɪtɪsaɪz ˈʌðə ˈpiːpl səʊ mʌʧ!",
        translation: "Don't criticize other people so much!",
      },
      {
        id: 83,
        expression: "Я угадал правильный ответ",
        transcription: "aɪ gɛst ðə kəˈrɛkt ˈɑːnsə",
        translation: "I guessed the correct answer",
      },
      {
        id: 84,
        expression: "Я угадал этот ответ",
        transcription: "aɪ gɛst ðɪs ˈɑːnsə",
        translation: "I guessed this answer",
      },
      {
        id: 85,
        expression: "Я нашел правильный ответ",
        transcription: "aɪ faʊnd ðə kəˈrɛkt ˈɑːnsə",
        translation: "I found the correct answer",
      },
      {
        id: 86,
        expression: "Ему потребовались годы, чтобы достичь своей главной цели",
        transcription: "ɪt tʊk ɪm jɪəz tʊ əˈʧiːv ɪz meɪn eɪm",
        translation: "It took him years to achieve his main aim",
      },
      {
        id: 87,
        expression: "Я вижу, этот метод действительно работает",
        transcription: "aɪ siː ðɪs ˈmɛθəd ˈrɪəli wɜːks",
        translation: "I see this method really works",
      },
      {
        id: 88,
        expression:
          "Большинство людей чувствуют, что Бог действительно существует",
        transcription: "məʊst ˈpiːpl fiːl ðət gɒd ˈrɪəli ɪgˈzɪsts",
        translation: "Most people feel that God really exists",
      },
      {
        id: 89,
        expression: "Компания увеличила свои продажи",
        transcription: "ðə ˈkʌmpəni ɪnˈkriːst ɪts seɪlz",
        translation: "The company increased its sales",
      },
      {
        id: 90,
        expression: "Тебе не нужно перебивать других без причины",
        transcription: "jʊ dəʊnt niːd tʊ ˌɪntəˈrʌpt ˈʌðəz fə nəʊ ˈriːzn",
        translation: "You don't need to interrupt others for no reason",
      },
      {
        id: 91,
        expression: "Ты понимаешь, что он делает?",
        transcription: "dʊ jʊ ˌʌndəˈstænd wɒt hi dʌz?",
        translation: "Do you understand what he does?",
      },
      {
        id: 92,
        expression: "Я действительно обожал слушать такого рода музыку",
        transcription: "aɪ ˈrɪəli ɪnˈʤɔɪd ˈlɪsnɪŋ tə ðɪs sɔːt əv ˈmjuːzɪk",
        translation: "I really enjoyed listening to this sort of music",
      },
      {
        id: 93,
        expression: "Я полностью понял твою точку зрения",
        transcription: "aɪ ˈfʊli ˌʌndəˈstʊd jə pɔɪnt əv vjuː",
        translation: "I fully understood your point of view",
      },
      {
        id: 94,
        expression: "Он не хочет ничего гарантировать",
        transcription: "hi dʌznt wɒnt tə ˌgærənˈtiː ˈɛnɪθɪŋ",
        translation: "He doesn't want to guarantee anything",
      },
      {
        id: 95,
        expression: "Я полагаю, это лучший выбор",
        transcription: "aɪ səˈpəʊz ɪts ðə bɛst ʧɔɪs",
        translation: "I suppose it's the best choice",
      },
      {
        id: 96,
        expression: "Ты мог бы повторить свой вопрос?",
        transcription: "kəd jʊ rɪˈpiːt jə ˈkwɛsʧən?",
        translation: "Could you repeat your question?",
      },
      {
        id: 97,
        expression: "Я догадываюсь, это действительно так",
        transcription: "aɪ gɛs ɪts ˈrɪəli səʊ",
        translation: "I guess it's really so",
      },
      {
        id: 98,
        expression: "Как ты отреагировал на это?",
        transcription: "haʊ dɪd jʊ ri(ː)ˈækt tʊ ɪt?",
        translation: "How did you react to it?",
      },
      {
        id: 99,
        expression: "Когда начался урок?",
        transcription: "wɛn dɪd ðə ˈlɛsn bɪˈgɪn?",
        translation: "When did the lesson begin?",
      },
      {
        id: 100,
        expression: "Ты мог бы повторить это, пожалуйста?",
        transcription: "kəd jʊ rɪˈpiːt ɪt, pliːz?",
        translation: "Could you repeat it, please?",
      },
      {
        id: 101,
        expression: "Она посмотрела на него",
        transcription: "ʃi lʊkt ət hɪm",
        translation: "She looked at him",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Закрепление пройденного материала</h3>
</div>`,
    },
  ],
];
