export const wordsLesson58 = [
  [
    {
      id: 1,
      word: 'anymore',
      transcription: 'ˌɛniˈmɔː',
      translation: 'больше не',
    },
    {
      id: 2,
      word: 'belong',
      transcription: 'bɪˈlɒŋ',
      translation: 'принадлежать',
    },
    {
      id: 3,
      word: 'class',
      transcription: 'klɑːs',
      translation: 'занятие, урок',
    },
    {
      id: 4,
      word: 'clean',
      transcription: 'kliːn',
      translation: 'чистить, чистый',
    },
    {
      id: 5,
      word: 'difference',
      transcription: 'ˈdɪfrəns',
      translation: 'различие, разница',
    },
    {
      id: 6,
      word: 'guitar',
      transcription: 'gɪˈtɑː',
      translation: 'гитара',
    },
    {
      id: 7,
      word: 'jazz',
      transcription: 'ʤæz',
      translation: 'джаз',
    },
    {
      id: 8,
      word: 'lead',
      transcription: 'liːd',
      translation: 'вести',
    },
    {
      id: 9,
      word: 'lifestyle',
      transcription: 'ˈlaɪfˌstaɪl',
      translation: 'образ жизни',
    },
    {
      id: 10,
      word: 'magazine',
      transcription: 'ˌmægəˈziːn',
      translation: 'журнал',
    },
    {
      id: 11,
      word: 'newspaper',
      transcription: 'ˈnjuːzˌpeɪpə',
      translation: 'газета',
    },
    {
      id: 12,
      word: 'piano',
      transcription: 'pɪˈænəʊ',
      translation: 'пианино',
    },
    {
      id: 13,
      word: 'pop music',
      transcription: 'pɒp ˈmjuːzɪk',
      translation: 'поп-музыка',
    },
    {
      id: 14,
      word: 'public',
      transcription: 'ˈpʌblɪk',
      translation: 'общественный',
    },
    {
      id: 15,
      word: 'relative',
      transcription: 'ˈrɛlətɪv',
      translation: 'родственник',
    },
    {
      id: 16,
      word: 'rock',
      transcription: 'rɒk',
      translation: 'рок, скала',
    },
    {
      id: 17,
      word: 'snowboarding',
      transcription: 'ˈsnəʊˌbɔːdɪŋ',
      translation: 'катание на сноуборде',
    },
    {
      id: 18,
      word: 'transport',
      transcription: 'ˈtrænspɔːt',
      translation: 'транспорт',
    },
    {
      id: 19,
      word: 'violin',
      transcription: 'ˌvaɪəˈlɪn',
      translation: 'скрипка',
    },
    {
      id: 20,
      word: 'close',
      transcription: 'kləʊs',
      translation: 'близкий',
    },
  ],
];
