export const contentLesson94 = [
  [
    {
      title: "Урок 94. 270 Verbs in Different Tenses. Part 2",
    },
    [
      {
        id: 1,
        expression: "Я разделил свою работу на несколько частей",
        transcription: "aɪ dɪˈvaɪdɪd maɪ wɜːk ˈɪntə ˈsɛvrəl pɑːts",
        translation: "I divided my work into several parts",
      },
      {
        id: 2,
        expression: "Кто одолжил ему эти деньги?",
        transcription: "huː lɛnt ɪm ðɪs ˈmʌni?",
        translation: "Who lent him this money?",
      },
      {
        id: 3,
        expression: "Он в основном имеет дело с жалобами",
        transcription: "hi ˈməʊstli diːlz wɪð kəmˈpleɪnts",
        translation: "He mostly deals with complaints",
      },
      {
        id: 4,
        expression: "Ты дашь ей эти деньги?",
        transcription: "wɪl jʊ gɪv hə ðɪs ˈmʌni?",
        translation: "Will you give her this money?",
      },
      {
        id: 5,
        expression: "Я разбужу тебя завтра",
        transcription: "aɪl weɪk jʊ ʌp təˈmɒrəʊ",
        translation: "I'll wake you up tomorrow",
      },
      {
        id: 6,
        expression: "Я выбрал правильный путь",
        transcription: "aɪ ʧəʊz ðə raɪt weɪ",
        translation: "I chose the right way",
      },
      {
        id: 7,
        expression: "Когда ты установишь эту программу?",
        transcription: "wɛn wɪl jʊ ɪnˈstɔːl ðɪs ˈprəʊgræm?",
        translation: "When will you install this program?",
      },
      {
        id: 8,
        expression: "Ты все проверил?",
        transcription: "dɪd jʊ ʧɛk ˈɛvrɪθɪŋ?",
        translation: "Did you check everything?",
      },
      {
        id: 9,
        expression: "Они выпустили новый альбом?",
        transcription: "dɪd ðeɪ rɪˈliːs ə njuː ˈælbəm?",
        translation: "Did they release a new album?",
      },
      {
        id: 10,
        expression: "Я установлю эту программу завтра",
        transcription: "aɪl ɪnˈstɔːl ðɪs ˈprəʊgræm təˈmɒrəʊ",
        translation: "I'll install this program tomorrow",
      },
      {
        id: 11,
        expression: "Когда ты проснулся?",
        transcription: "wɛn dɪd jʊ weɪk ʌp?",
        translation: "When did you wake up?",
      },
      {
        id: 12,
        expression: "Она в основном имеет дело с поставщиками",
        transcription: "ʃi ˈməʊstli diːlz wɪð səˈplaɪəz",
        translation: "She mostly deals with suppliers",
      },
      {
        id: 13,
        expression: "Он скоро выпустит новый альбом",
        transcription: "hiːl rɪˈliːs ə njuː ˈælbəm suːn",
        translation: "He'll release a new album soon",
      },
      {
        id: 14,
        expression: "Ты проверишь свою работу?",
        transcription: "wɪl jʊ ʧɛk jə wɜːk?",
        translation: "Will you check your work?",
      },
      {
        id: 15,
        expression: "Что она тебе предложила?",
        transcription: "wɒt dɪd ʃi ˈɒfə juː?",
        translation: "What did she offer you?",
      },
      {
        id: 16,
        expression: "Я сохраняю все эти файлы довольно регулярно",
        transcription: "aɪ seɪv ɔːl ðiːz faɪlz kwaɪt ˈrɛgjʊləli",
        translation: "I save all these files quite regularly",
      },
      {
        id: 17,
        expression: "Я помыл свои руки",
        transcription: "aɪ wɒʃt maɪ hændz",
        translation: "I washed my hands",
      },
      {
        id: 18,
        expression: "Он сохранил все файлы",
        transcription: "hi seɪvd ɔːl ðə faɪlz",
        translation: "He saved all the files",
      },
      {
        id: 19,
        expression: "Мне не нравится это видео",
        transcription: "aɪ dɪsˈlaɪk ðɪs ˈvɪdɪəʊ",
        translation: "I dislike this video",
      },
      {
        id: 20,
        expression: "Я поблагодарил ее за все",
        transcription: "aɪ θæŋkt hə fər ˈɛvrɪθɪŋ",
        translation: "I thanked her for everything",
      },
      {
        id: 21,
        expression: "Эта рубашка лучше тебе подходит",
        transcription: "ðɪs ʃɜːt fɪts jʊ ˈbɛtə",
        translation: "This shirt fits you better",
      },
      {
        id: 22,
        expression: "Что тебя разбудило?",
        transcription: "wɒt wəʊk jʊ ʌp?",
        translation: "What woke you up?",
      },
      {
        id: 23,
        expression: "Где ты учился?",
        transcription: "weə dɪd jʊ ˈstʌdi?",
        translation: "Where did you study?",
      },
      {
        id: 24,
        expression: "Он забронировал лучшую квартиру",
        transcription: "hi bʊkt ðə bɛst əˈpɑːtmənt?",
        translation: "He booked the best apartment?",
      },
      {
        id: 25,
        expression: "Она вымыла волосы",
        transcription: "ʃi wɒʃt hə heə",
        translation: "She washed her hair",
      },
      {
        id: 26,
        expression: "Я хотел бы поблагодарить тебя за твою поддержку",
        transcription: "aɪd laɪk tə θæŋk jʊ fə jə səˈpɔːt",
        translation: "I'd like to thank you for your support",
      },
      {
        id: 27,
        expression: "Как ты учишься?",
        transcription: "haʊ dʊ jʊ ˈstʌdi?",
        translation: "How do you study?",
      },
      {
        id: 28,
        expression: "Ты проверишь это еще раз?",
        transcription: "wɪl jʊ ʧɛk ɪt wʌn mɔː taɪm?",
        translation: "Will you check it one more time?",
      },
      {
        id: 29,
        expression: "Она выпустила новую песню",
        transcription: "ʃi rɪˈliːst ə njuː sɒŋ",
        translation: "She released a new song",
      },
      {
        id: 30,
        expression: "Я сохраню этот документ",
        transcription: "aɪl seɪv ðɪs ˈdɒkjʊmənt",
        translation: "I'll save this document",
      },
      {
        id: 31,
        expression: "Они избили этого бедного мужчину",
        transcription: "ðeɪ biːt ðɪs pʊə mæn",
        translation: "They beat this poor man",
      },
      {
        id: 32,
        expression: "Мне нужно помыть посуду",
        transcription: "aɪ niːd tə wɒʃ ðə ˈdɪʃɪz",
        translation: "I need to wash the dishes",
      },
      {
        id: 33,
        expression: "Кто тебя разбудил?",
        transcription: "huː wəʊk jʊ ʌp?",
        translation: "Who woke you up?",
      },
      {
        id: 34,
        expression: "Я просто хотел выразить свою точку зрения",
        transcription: "aɪ ʤəst ˈwɒntɪd tʊ ɪksˈprɛs maɪ pɔɪnt əv vjuː",
        translation: "I just wanted to express my point of view",
      },
      {
        id: 35,
        expression: "Давай поужинаем вместе!",
        transcription: "lɛts həv ˈdɪnə təˈgɛðə!",
        translation: "Let's have dinner together!",
      },
      {
        id: 36,
        expression: "Он выбрал неправильный путь",
        transcription: "hi ʧəʊz ðə rɒŋ weɪ",
        translation: "He chose the wrong way",
      },
      {
        id: 37,
        expression: "Я увидел его там в первый раз",
        transcription: "aɪ sɔː ɪm ðeə fə ðə fɜːst taɪm",
        translation: "I saw him there for the first time",
      },
      {
        id: 38,
        expression: "Я забронировал номер в вашем отеле",
        transcription: "aɪ bʊkt ə ˈnʌmbər ɪn jə həʊˈtɛl",
        translation: "I booked a number in your hotel",
      },
      {
        id: 39,
        expression: "Полицейский спас ей жизнь",
        transcription: "ðə pəˈliːsmən seɪvd hə laɪf",
        translation: "The policeman saved her life",
      },
      {
        id: 40,
        expression: "Он заранее забронировал комнату",
        transcription: "hi bʊkt ə ruːm ɪn ədˈvɑːns",
        translation: "He booked a room in advance",
      },
      {
        id: 41,
        expression: "Я не думаю, что кто-то одолжит ему эти деньги",
        transcription: "aɪ dəʊnt θɪŋk ðət ˈsʌmwʌn wɪl lɛnd ɪm ðɪs ˈmʌni",
        translation: "I don't think that someone will lend him this money",
      },
      {
        id: 42,
        expression:
          "Мы имеем дело с разными компаниями, которые работают в этой же области",
        transcription:
          "wi diːl wɪð ˈdɪfrənt ˈkʌmpəniz wɪʧ wɜːk ɪn ðə seɪm ˈeərɪə",
        translation:
          "We deal with different companies which work in the same area",
      },
      {
        id: 43,
        expression:
          "Я думаю, что тебе нужно тренироваться намного усерднее, чтобы достичь эту цель",
        transcription:
          "aɪ θɪŋk ðət jʊ niːd tə treɪn mʌʧ ˈhɑːdə tʊ əˈʧiːv ðɪs eɪm",
        translation:
          "I think that you need to train much harder to achieve this aim",
      },
      {
        id: 44,
        expression:
          "Мне нужно некоторое время, чтобы решить, что делать в этой ситуации",
        transcription:
          "aɪ niːd səm taɪm tə dɪˈsaɪd wɒt tə dʊ ɪn ðɪs ˌsɪtjʊˈeɪʃən",
        translation: "I need some time to decide what to do in this situation",
      },
      {
        id: 45,
        expression: "Я думаю, лучше разделить эту работу на несколько частей",
        transcription:
          "aɪ θɪŋk ɪts ˈbɛtə tə dɪˈvaɪd ðɪs wɜːk ˈɪntə ˈsɛvrəl pɑːts",
        translation:
          "I think it's better to divide this work into several parts",
      },
      {
        id: 46,
        expression: "Это может привлечь внимание других людей",
        transcription: "ɪt kən əˈtrækt ˈʌðə ˈpiːplz əˈtɛnʃ(ə)n",
        translation: "It can attract other people's attention",
      },
      {
        id: 47,
        expression: "Мне не нравится этот формат обучения",
        transcription: "aɪ dɪsˈlaɪk ðɪs ˈfɔːmæt əv ˌɛdju(ː)ˈkeɪʃən",
        translation: "I dislike this format of education",
      },
      {
        id: 48,
        expression: "Я вижу, что эта вещь больше не работает",
        transcription: "aɪ siː ðət ðɪs θɪŋ dʌznt wɜːk ˌɛniˈmɔː",
        translation: "I see that this thing doesn't work anymore",
      },
      {
        id: 49,
        expression: "Сколько денег он ей дает?",
        transcription: "haʊ mʌʧ ˈmʌni dəz hi gɪv hɜː?",
        translation: "How much money does he give her?",
      },
      {
        id: 50,
        expression: "Мы прекрасно провели время вместе",
        transcription: "wi həd ə greɪt taɪm təˈgɛðə",
        translation: "We had a great time together",
      },
      {
        id: 51,
        expression: "Я вижу, что ты имеешь в виду",
        transcription: "aɪ siː wɒt jʊ miːn",
        translation: "I see what you mean",
      },
      {
        id: 52,
        expression: "Мне не нравится такая одежда",
        transcription: "aɪ dɪsˈlaɪk sʌʧ kləʊðz",
        translation: "I dislike such clothes",
      },
      {
        id: 53,
        expression: "Что ты можешь предложить?",
        transcription: "wɒt kən jʊ ˈɒfə?",
        translation: "What can you offer?",
      },
      {
        id: 54,
        expression: "Его родители никогда не били его",
        transcription: "hɪz ˈpeərənts ˈnɛvə biːt hɪm",
        translation: "His parents never beat him",
      },
      {
        id: 55,
        expression: "Эта машина часто ломается?",
        transcription: "dəz ðɪs kɑːr ˈɒf(ə)n breɪk daʊn?",
        translation: "Does this car often break down?",
      },
      {
        id: 56,
        expression: "Где ты хочешь учиться?",
        transcription: "weə dʊ jʊ wɒnt tə ˈstʌdi?",
        translation: "Where do you want to study?",
      },
      {
        id: 57,
        expression: "Ты хочешь учиться там?",
        transcription: "dʊ jʊ wɒnt tə ˈstʌdi ðeə?",
        translation: "Do you want to study there?",
      },
      {
        id: 58,
        expression: "Где он хочет учиться?",
        transcription: "weə dəz hi wɒnt tə ˈstʌdi?",
        translation: "Where does he want to study?",
      },
      {
        id: 59,
        expression: "Сколько часов в день ты тренировался?",
        transcription: "haʊ ˈmɛni ˈaʊəz ə deɪ dɪd jʊ treɪn?",
        translation: "How many hours a day did you train?",
      },
      {
        id: 60,
        expression: "Сколько часов в неделю ты учился?",
        transcription: "haʊ ˈmɛni ˈaʊəz ə wiːk dɪd jʊ ˈstʌdi?",
        translation: "How many hours a week did you study?",
      },
      {
        id: 61,
        expression: "Я обычно тренируюсь два раза в неделю",
        transcription: "aɪ ˈjuːʒʊəli treɪn tuː taɪmz ə wiːk",
        translation: "I usually train two times a week",
      },
      {
        id: 62,
        expression: "Позволь мне высказать свою точку зрения",
        transcription: "lɛt mi ɪksˈprɛs maɪ pɔɪnt əv vjuː!",
        translation: "Let me express my point of view!",
      },
      {
        id: 63,
        expression: "Я хочу выразить свою точку зрения",
        transcription: "aɪ wɒnt tʊ ɪksˈprɛs maɪ pɔɪnt əv vjuː",
        translation: "I want to express my point of view",
      },
      {
        id: 64,
        expression: "Ты вчера катался на велосипеде?",
        transcription: "dɪd jʊ raɪd ə baɪk ˈjɛstədeɪ?",
        translation: "Did you ride a bike yesterday?",
      },
      {
        id: 65,
        expression: "Ты катался на лошади вчера?",
        transcription: "dɪd jʊ raɪd ə hɔːs ˈjɛstədeɪ?",
        translation: "Did you ride a horse yesterday?",
      },
      {
        id: 66,
        expression: "Ты поедешь на велосипеде завтра?",
        transcription: "wɪl jʊ raɪd ə baɪk təˈmɒrəʊ?",
        translation: "Will you ride a bike tomorrow?",
      },
      {
        id: 67,
        expression: "Он переплыл реку",
        transcription: "hi swæm əˈkrɒs ðə ˈrɪvə",
        translation: "He swam across the river",
      },
      {
        id: 68,
        expression: "Я переплыл эту реку",
        transcription: "aɪ swæm əˈkrɒs ðɪs ˈrɪvə",
        translation: "I swam across this river",
      },
      {
        id: 69,
        expression: "Я могу переплыть ту реку",
        transcription: "aɪ kən swɪm əˈkrɒs ðət ˈrɪvə",
        translation: "I can swim across that river",
      },
      {
        id: 70,
        expression: "Я не тренируюсь достаточно интенсивно",
        transcription: "aɪ dəʊnt treɪn ɪnˈtɛnsɪvli ɪˈnʌf",
        translation: "I don't train intensively enough",
      },
      {
        id: 71,
        expression: "Он не тренируется достаточно интенсивно",
        transcription: "hi dʌznt treɪn ɪnˈtɛnsɪvli ɪˈnʌf",
        translation: "He doesn't train intensively enough",
      },
      {
        id: 72,
        expression: "Они не тренируются интенсивно",
        transcription: "ðeɪ dəʊnt treɪn ɪnˈtɛnsɪvli",
        translation: "They don't train intensively",
      },
      {
        id: 73,
        expression: "Это немедленно привлекло его внимание",
        transcription: "ɪt əˈtræktɪd ɪz əˈtɛnʃ(ə)n ɪˈmiːdiətli",
        translation: "It attracted his attention immediately",
      },
      {
        id: 74,
        expression: "Это немедленно привлекло мое внимание",
        transcription: "ɪt əˈtræktɪd maɪ əˈtɛnʃ(ə)n ɪˈmiːdiətli",
        translation: "It attracted my attention immediately",
      },
      {
        id: 75,
        expression: "Это действительно привлекло его внимание",
        transcription: "ɪt ˈrɪəli əˈtræktɪd ɪz əˈtɛnʃ(ə)n",
        translation: "It really attracted his attention",
      },
      {
        id: 76,
        expression: "Позволь мне высказать свою личную точку зрения",
        transcription: "lɛt mi ɪksˈprɛs maɪ ˈpɜːsnl pɔɪnt əv vjuː!",
        translation: "Let me express my personal point of view!",
      },
      {
        id: 77,
        expression: "Позволь ей высказать свою точку зрения",
        transcription: "lɛt hər ɪksˈprɛs hə pɔɪnt əv vjuː!",
        translation: "Let her express her point of view!",
      },
      {
        id: 78,
        expression: "Позволь мне высказать свое собственное мнение!",
        transcription: "lɛt mi ɪksˈprɛs maɪ əʊn əˈpɪnjən!",
        translation: "Let me express my own opinion!",
      },
      {
        id: 79,
        expression: "Я решил отменить нашу встречу",
        transcription: "aɪ dɪˈsaɪdɪd tə ˈkænsəl ˈaʊə ˈmiːtɪŋ",
        translation: "I decided to cancel our meeting",
      },
      {
        id: 80,
        expression: "Мне нужно отменить эту встречу",
        transcription: "aɪ niːd tə ˈkænsəl ðɪs ˈmiːtɪŋ",
        translation: "I need to cancel this meeting",
      },
      {
        id: 81,
        expression: "Он решил отменить эту презентацию",
        transcription: "hi dɪˈsaɪdɪd tə ˈkænsəl ðɪs ˌprɛzɛnˈteɪʃən",
        translation: "He decided to cancel this presentation",
      },
      {
        id: 82,
        expression: "Я боюсь, мы можем опоздать на автобус",
        transcription: "aɪm əˈfreɪd wi kən mɪs ðə bʌs",
        translation: "I'm afraid we can miss the bus",
      },
      {
        id: 83,
        expression: "Я боюсь, что могу опоздать на автобус",
        transcription: "aɪm əˈfreɪd ðət aɪ kən mɪs ðə bʌs",
        translation: "I'm afraid that I can miss the bus",
      },
      {
        id: 84,
        expression: "Он боится, что может опоздать на автобус",
        transcription: "hiːz əˈfreɪd ðət hi kən mɪs ðə bʌs",
        translation: "He's afraid that he can miss the bus",
      },
      {
        id: 85,
        expression: "Ветер так внезапно подул",
        transcription: "ðə wɪnd bluː səʊ ˈsʌdnli",
        translation: "The wind blew so suddenly",
      },
      {
        id: 86,
        expression: "Я плавал в море несколько раз в день",
        transcription: "aɪ swæm ɪn ðə siː ˈsɛvrəl taɪmz ə deɪ",
        translation: "I swam in the sea several times a day",
      },
      {
        id: 87,
        expression: "Сильный ветер часто здесь дует",
        transcription: "ə strɒŋ wɪnd ˈɒf(ə)n bləʊz hɪə",
        translation: "A strong wind often blows here",
      },
      {
        id: 88,
        expression: "Машина неожиданно сломалась",
        transcription: "ðə kɑː brəʊk daʊn ˌʌnɪksˈpɛktɪdli",
        translation: "The car broke down unexpectedly",
      },
      {
        id: 89,
        expression: "Этот размер тебе идеально подходит",
        transcription: "ðɪs saɪz fɪts jʊ ˈpɜːfɪktli",
        translation: "This size fits you perfectly",
      },
      {
        id: 90,
        expression: "Сколько дней в неделю он тренируется",
        transcription: "haʊ ˈmɛni deɪz ə wiːk dəz hi treɪn?",
        translation: "How many days a week does he train?",
      },
      {
        id: 91,
        expression: "Она так сильно по тебе скучает",
        transcription: "ʃi ˈmɪsɪz jʊ səʊ mʌʧ",
        translation: "She misses you so much",
      },
      {
        id: 92,
        expression: "Они дали ей еще один шанс",
        transcription: "ðeɪ geɪv hə wʌn mɔː ʧɑːns",
        translation: "They gave her one more chance",
      },
      {
        id: 93,
        expression: "Эта вещь сразу привлекла мое внимание",
        transcription: "ðɪs θɪŋ əˈtræktɪd maɪ əˈtɛnʃ(ə)n ət wʌns",
        translation: "This thing attracted my attention at once",
      },
      {
        id: 94,
        expression: "Я часто катался на велосипеде со своими друзьями",
        transcription: "aɪ ˈɒf(ə)n rəʊd ə baɪk wɪð maɪ frɛndz",
        translation: "I often rode a bike with my friends",
      },
      {
        id: 95,
        expression: "У нас было очень трудное время",
        transcription: "wi həd ə ˈvɛri ˈdɪfɪkəlt taɪm",
        translation: "We had a very difficult time",
      },
      {
        id: 96,
        expression: "Я никогда не думал об этом",
        transcription: "aɪ ˈnɛvə θɔːt əˈbaʊt ɪt",
        translation: "I never thought about it",
      },
      {
        id: 97,
        expression: "Она переплыла озеро",
        transcription: "ʃi swæm əˈkrɒs ðə leɪk",
        translation: "She swam across the lake",
      },
      {
        id: 98,
        expression: "Я решил не покупать это",
        transcription: "aɪ dɪˈsaɪdɪd nɒt tə baɪ ɪt",
        translation: "I decided not to buy it",
      },
      {
        id: 99,
        expression: "Я очень сильно по тебе скучаю",
        transcription: "aɪ mɪs jʊ ˈvɛri mʌʧ",
        translation: "I miss you very much",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Закрепление пройденного материала</h3>
</div>`,
    },
  ],
];
