export const contentLesson141 = [
  [
    {
      title: 'Урок 141. 230 Adjectives. Part 3',
    },
    [
      {
        id: 1,
        expression: 'Это здание такое уродливое',
        transcription: 'ðɪs ˈbɪldɪŋ z səʊ ˈʌgli',
        translation: 'This building is so ugly',
      },
      {
        id: 2,
        expression: 'Солнце сейчас такое яркое',
        transcription: 'ðə sʌn z səʊ braɪt naʊ',
        translation: 'The sun is so bright now',
      },
      {
        id: 3,
        expression: 'Это такая замечательная возможность',
        transcription: 'ɪts sʌʧ ə ˈwʌndəfʊl ˌɒpəˈtjuːnɪti',
        translation: "It's such a wonderful opportunity",
      },
      {
        id: 4,
        expression: 'Он такой странный мужчина',
        transcription: 'hiːz sʌʧ ə streɪnʤ mæn',
        translation: "He's such a strange man",
      },
      {
        id: 5,
        expression: 'Это гораздо более дорогая вещь',
        transcription: 'ɪts mʌʧ mɔːr ɪksˈpɛnsɪv θɪŋ',
        translation: "It's much more expensive thing",
      },
      {
        id: 6,
        expression: 'Это прекрасная возможность',
        transcription: 'ɪts ə ˈwʌndəfʊl ˌɒpəˈtjuːnɪti',
        translation: "It's a wonderful opportunity",
      },
      {
        id: 7,
        expression: 'Лампа очень яркая',
        transcription: 'ðə læmp s ˈvɛri braɪt',
        translation: 'The lamp is very bright',
      },
      {
        id: 8,
        expression: 'Я хочу чувствовать себя финансово независимым',
        transcription: 'aɪ wɒnt tə fiːl faɪˈnænʃəli ˌɪndɪˈpɛndənt',
        translation: 'I want to feel financially independent',
      },
      {
        id: 9,
        expression: 'Тебе нравится современная музыка?',
        transcription: 'dʊ jʊ laɪk ˈmɒdən ˈmjuːzɪk?',
        translation: 'Do you like modern music?',
      },
      {
        id: 10,
        expression: 'Он строгий учитель?',
        transcription: 'ɪz hi ə strɪkt ˈtiːʧə?',
        translation: 'Is he a strict teacher?',
      },
      {
        id: 11,
        expression: 'Этот человек такой странный',
        transcription: 'ðɪs mæn z səʊ streɪnʤ',
        translation: 'This man is so strange',
      },
      {
        id: 12,
        expression:
          'Тебе следует делать больше физических упражнений для твоего здоровья',
        transcription: 'jʊ ʃəd dʊ mɔː ˈfɪzɪkəl ˈɛksəsaɪzɪz fə jə hɛlθ',
        translation: 'You should do more physical exercises for your health',
      },
      {
        id: 13,
        expression: 'Это более дорогой отель',
        transcription: 'ɪts ə mɔːr ɪksˈpɛnsɪv həʊˈtɛl',
        translation: "It's a more expensive hotel",
      },
      {
        id: 14,
        expression: 'Он хочет чувствовать себя более независимым',
        transcription: 'hi wɒnts tə fiːl mɔːr ˌɪndɪˈpɛndənt',
        translation: 'He wants to feel more independent',
      },
      {
        id: 15,
        expression: 'Это единственно правильное решение',
        transcription: 'ɪts ði ˈəʊnli raɪt dɪˈsɪʒən',
        translation: "It's the only right decision",
      },
      {
        id: 16,
        expression: 'Он очень толстый',
        transcription: 'hiːz ˈvɛri fæt',
        translation: "He's very fat",
      },
      {
        id: 17,
        expression: 'Она чувствует себя такой одинокой',
        transcription: 'ʃi fiːlz səʊ ˈləʊnli',
        translation: 'She feels so lonely',
      },
      {
        id: 18,
        expression: 'Это был единственно правильный выбор',
        transcription: 'ɪt wəz ði ˈəʊnli raɪt ʧɔɪs',
        translation: 'It was the only right choice',
      },
      {
        id: 19,
        expression: 'Это очень мягкая сумка',
        transcription: 'ɪts ə ˈvɛri sɒft bæg',
        translation: "It's a very soft bag",
      },
      {
        id: 20,
        expression: 'Это его собственная квартира',
        transcription: 'ɪts ɪz əʊn əˈpɑːtmənt',
        translation: "It's his own apartment",
      },
      {
        id: 21,
        expression: 'Я посетил это место на прошлой неделе',
        transcription: 'aɪ ˈvɪzɪtɪd ðɪs pleɪs lɑːst wiːk',
        translation: 'I visited this place last week',
      },
      {
        id: 22,
        expression: 'Наш учитель не строгий',
        transcription: 'ˈaʊə ˈtiːʧər ˈɪznt strɪkt',
        translation: "Our teacher isn't strict",
      },
      {
        id: 23,
        expression: 'Это глобальная проблема',
        transcription: 'ɪts ə ˈgləʊbəl ˈprɒbləm',
        translation: "It's a global problem",
      },
      {
        id: 24,
        expression: 'Она спрятала это в секретное место',
        transcription: 'ʃi hɪd ɪt ɪn ə ˈsiːkrɪt pleɪs',
        translation: 'She hid it in a secret place',
      },
      {
        id: 25,
        expression: 'Стул очень мягкий',
        transcription: 'ðə ʧeə z ˈvɛri sɒft',
        translation: 'The chair is very soft',
      },
      {
        id: 26,
        expression: 'Он очень острожный водитель',
        transcription: 'hiːz ə ˈvɛri ˈkeəfʊl ˈdraɪvə',
        translation: "He's a very careful driver",
      },
      {
        id: 27,
        expression: 'Он очень нервный человек',
        transcription: 'hiːz ə ˈvɛri ˈnɜːvəs ˈpɜːsn',
        translation: "He's a very nervous person",
      },
      {
        id: 28,
        expression: 'Она так нервничает',
        transcription: 'ʃi fiːlz səʊ ˈnɜːvəs',
        translation: 'She feels so nervous',
      },
      {
        id: 29,
        expression: 'Тебе нужно быть более осторожным',
        transcription: 'jʊ niːd tə bi mɔː ˈkeəfʊl',
        translation: 'You need to be more careful',
      },
      {
        id: 30,
        expression: 'Он такой строгий учитель',
        transcription: 'hiːz sʌʧ ə strɪkt ˈtiːʧə',
        translation: "He's such a strict teacher",
      },
      {
        id: 31,
        expression: 'Она не боится этого',
        transcription: 'ʃi ˈɪznt əˈfreɪd əv ɪt',
        translation: "She isn't afraid of it",
      },
      {
        id: 32,
        expression: 'Это очень дорогие часы',
        transcription: 'ɪts ə ˈvɛri ɪksˈpɛnsɪv wɒʧ',
        translation: "It's a very expensive watch",
      },
      {
        id: 33,
        expression: 'Он держит это в секретном месте',
        transcription: 'hi kiːps ɪt ɪn ə ˈsiːkrɪt pleɪs',
        translation: 'He keeps it in a secret place',
      },
      {
        id: 34,
        expression: 'Они такие странные люди',
        transcription: 'ðeə sʌʧ streɪnʤ ˈpiːpl',
        translation: "They're such strange people",
      },
      {
        id: 35,
        expression: 'Он показал худший результат',
        transcription: 'hi ʃəʊd ðə wɜːst rɪˈzʌlt',
        translation: 'He showed the worst result',
      },
      {
        id: 36,
        expression: 'Это обычная вещь',
        transcription: 'ɪts ə ˈjuːʒʊəl θɪŋ',
        translation: "It's a usual thing",
      },
      {
        id: 37,
        expression: 'Она очень строгая учительница',
        transcription: 'ʃiːz ə ˈvɛri strɪkt ˈtiːʧə',
        translation: "She's a very strict teacher",
      },
      {
        id: 38,
        expression: 'Он очень одинокий человек',
        transcription: 'hiːz ə ˈvɛri ˈləʊnli ˈpɜːsn',
        translation: "He's a very lonely person",
      },
      {
        id: 39,
        expression: 'Это обычный ответ',
        transcription: 'ɪts ə ˈjuːʒʊəl ˈɑːnsə',
        translation: "It's a usual answer",
      },
      {
        id: 40,
        expression: 'Эти меры являются временными',
        transcription: 'ðiːz ˈmɛʒəz ə ˈtɛmpərəri',
        translation: 'These measures are temporary',
      },
      {
        id: 41,
        expression:
          'Он может очень быстро потолстеть, если не перестанет есть столько шоколада',
        transcription:
          'hi kən gɛt fæt ˈvɛri ˈkwɪkli ɪf hi dʌznt stɒp ˈiːtɪŋ səʊ mʌʧ ˈʧɒkəlɪt',
        translation:
          "He can get fat very quickly if he doesn't stop eating so much chocolate",
      },
      {
        id: 42,
        expression: 'Мы замечательно провели время вместе',
        transcription: 'wi həd ə ˈwʌndəfʊl taɪm təˈgɛðə',
        translation: 'We had a wonderful time together',
      },
      {
        id: 43,
        expression: 'Мой дорогой друг, спасибо за все!',
        transcription: 'maɪ dɪə frɛnd, θæŋks fər ˈɛvrɪθɪŋ!',
        translation: 'My dear friend, thanks for everything!',
      },
      {
        id: 44,
        expression: 'Я так рад узнать, что он все еще жив',
        transcription: 'aɪm səʊ ˈhæpi (glæd) tə nəʊ ðət hiːz stɪl əˈlaɪv',
        translation: "I'm so happy (glad) to know that he's still alive",
      },
      {
        id: 45,
        expression: 'Эти вещи были запрещены в Советском Союзе',
        transcription: 'ðiːz θɪŋz wə fəˈbɪdn ɪn ðə ˈsəʊvɪət ˈjuːnjən',
        translation: 'These things were forbidden in the Soviet Union',
      },
      {
        id: 46,
        expression: 'Это здание на противоположной стороне улицы',
        transcription: 'ðɪs ˈbɪldɪŋ z ɒn ði ˈɒpəzɪt saɪd əv ðə striːt',
        translation: 'This building is on the opposite side of the street',
      },
      {
        id: 47,
        expression: 'Я не боюсь этих трудностей',
        transcription: 'aɪm nɒt əˈfreɪd əv ðiːz ˈdɪfɪkəltiz',
        translation: "I'm not afraid of these difficulties",
      },
      {
        id: 48,
        expression: 'Такие вещи случаются очень редко в нашем современном мире',
        transcription: 'sʌʧ θɪŋz ˈhæpən ˈvɛri ˈreəli ɪn ˈaʊə ˈmɒdən wɜːld',
        translation: 'Such things happen very rarely in our modern world',
      },
      {
        id: 49,
        expression: 'Я отказался это сделать по разным причинам',
        transcription: 'aɪ ˌriːˈfjuːzd tə dʊ ɪt fə ˈdɪfrənt (ˈveərɪəs) ˈriːznz',
        translation: 'I refused to do it for different (various) reasons',
      },
      {
        id: 50,
        expression: 'Она сделала это по другой причине',
        transcription: 'ʃi dɪd ɪt fər əˈnʌðə ˈriːzn',
        translation: 'She did it for another reason',
      },
      {
        id: 51,
        expression: 'Он ждет следующего урока',
        transcription: 'hiːz ˈweɪtɪŋ fə ðə nɛkst ˈlɛsn',
        translation: "He's waiting for the next lesson",
      },
      {
        id: 52,
        expression: 'Это идеальное место для жизни',
        transcription: 'ɪts ən aɪˈdɪəl pleɪs fər ə ˈlɪvɪŋ',
        translation: "It's an ideal place for a living",
      },
      {
        id: 53,
        expression: 'Это один из самых эффективных курсов',
        transcription: 'ɪts wʌn əv ðə məʊst ɪˈfɛktɪv ˈkɔːsɪz',
        translation: "It's one of the most effective courses",
      },
      {
        id: 54,
        expression: 'Это было очень популярно в Советском Союзе',
        transcription: 'ɪt wəz ˈvɛri ˈpɒpjʊlər ɪn ðə ˈsəʊvɪət ˈjuːnjən',
        translation: 'It was very popular in the Soviet Union',
      },
      {
        id: 55,
        expression: 'Он единственный ребенок',
        transcription: 'hiːz ən ˈəʊnli ʧaɪld',
        translation: "He's an only child",
      },
      {
        id: 56,
        expression: 'Это будет объяснено в следующем уроке',
        transcription: 'ˈɪtl bi ɪksˈpleɪnd ɪn ðə nɛkst ˈlɛsn',
        translation: "It'll be explained in the next lesson",
      },
      {
        id: 57,
        expression: 'Это было объяснено в предыдущих уроках',
        transcription: 'ɪt wəz ɪksˈpleɪnd ɪn ðə ˈpriːviəs ˈlɛsnz',
        translation: 'It was explained in the previous lessons',
      },
      {
        id: 58,
        expression: 'Это будет объяснено в этом уроке?',
        transcription: 'wɪl ɪt bi ɪksˈpleɪnd ɪn ðɪs ˈlɛsn?',
        translation: 'Will it be explained in this lesson?',
      },
      {
        id: 59,
        expression: 'Что ты думаешь о пластической хирургии?',
        transcription: 'wɒt dʊ jʊ θɪŋk əˈbaʊt ˈplæstɪk ˈsɜːʤəri?',
        translation: 'What do you think about plastic surgery?',
      },
      {
        id: 60,
        expression: 'Ты когда-нибудь думал о пластической операции?',
        transcription:
          'həv jʊ ˈɛvə θɔːt əˈbaʊt ə ˈplæstɪk ˈsɜːʤəri ˌɒpəˈreɪʃən?',
        translation: 'Have you ever thought about a plastic surgery operation?',
      },
      {
        id: 61,
        expression: 'Какое твое отношение к пластической хирургии?',
        transcription: 'wɒts jər ˈætɪtjuːd tə ˈplæstɪk ˈsɜːʤəri?',
        translation: "What's your attitude to plastic surgery?",
      },
      {
        id: 62,
        expression:
          'Я стараюсь делать физические упражнения достаточно регулярно',
        transcription: 'aɪ traɪ tə dʊ ˈfɪzɪkəl ˈɛksəsaɪzɪz kwaɪt ˈrɛgjʊləli',
        translation: 'I try to do physical exercises quite regularly',
      },
      {
        id: 63,
        expression: 'Я делаю эти упражнения довольно регулярно',
        transcription: 'aɪ dʊ ðiːz ˈfɪzɪkəl ˈɛksəsaɪzɪz kwaɪt ˈrɛgjʊləli',
        translation: 'I do these physical exercises quite regularly',
      },
      {
        id: 64,
        expression: 'Я стараюсь делать физические упражнения чаще',
        transcription: 'aɪ traɪ tə dʊ ˈfɪzɪkəl ˈɛksəsaɪzɪz mɔːr ˈɒf(ə)n',
        translation: 'I try to do physical exercises more often',
      },
      {
        id: 65,
        expression: 'Эта фраза имеет противоположное значение',
        transcription: 'ðɪs freɪz həz ði ˈɒpəzɪt ˈmiːnɪŋ',
        translation: 'This phrase has the opposite meaning',
      },
      {
        id: 66,
        expression: 'Эта фраза имеет то же значение',
        transcription: 'ðɪs freɪz həz ðə seɪm ˈmiːnɪŋ',
        translation: 'This phrase has the same meaning',
      },
      {
        id: 67,
        expression: 'Это слово имеет противоположное значение',
        transcription: 'ðɪs wɜːd həz ði ˈɒpəzɪt ˈmiːnɪŋ',
        translation: 'This word has the opposite meaning',
      },
      {
        id: 68,
        expression: 'Он идеальный кандидат для этой работы',
        transcription: 'hiːz ən aɪˈdɪəl ˈkændɪˌdeɪt fə ðɪs ʤɒb',
        translation: "He's an ideal candidate for this job",
      },
      {
        id: 69,
        expression: 'Он единственный кандидат для этой работы',
        transcription: 'hiːz ði ˈəʊnli ˈkændɪˌdeɪt fə ðɪs ʤɒb',
        translation: "He's the only candidate for this job",
      },
      {
        id: 70,
        expression: 'Он идеальный кандидат на эту вакансию',
        transcription: 'hiːz ən aɪˈdɪəl ˈkændɪˌdeɪt fə ðɪs ˈveɪkənsi',
        translation: "He's an ideal candidate for this vacancy",
      },
      {
        id: 71,
        expression: 'Это связано с моей будущей профессией',
        transcription:
          'ɪts kəˈnɛktɪd wɪð maɪ ˈfjuːʧə prəˈfɛʃən / ɪts rɪˈleɪtɪd tə maɪ ˈfjuːʧə prəˈfɛʃən / ɪt rɪˈleɪts tə maɪ ˈfjuːʧə prəˈfɛʃən',
        translation:
          "It's connected with my future profession / It's related to my future profession / It relates to my future profession",
      },
      {
        id: 72,
        expression: 'Это было связано с моей работой',
        transcription: 'ɪt wəz kəˈnɛktɪd wɪð maɪ ʤɒb',
        translation: 'It was connected with my job',
      },
      {
        id: 73,
        expression: 'Это не связано с моей профессией',
        transcription: 'ɪt ˈɪznt kəˈnɛktɪd wɪð maɪ prəˈfɛʃən',
        translation: "It isn't connected with my profession",
      },
      {
        id: 74,
        expression: 'Я хочу поблагодарить тебя, мой дорогой друг!',
        transcription: 'aɪ wɒnt tə θæŋk juː, maɪ dɪə frɛnd!',
        translation: 'I want to thank you, my dear friend!',
      },
      {
        id: 75,
        expression: 'Мне нужно поблагодарить его за его помощь',
        transcription: 'aɪ niːd tə θæŋk ɪm fə hɪz hɛlp',
        translation: 'I need to thank him for his help',
      },
      {
        id: 76,
        expression: 'Он поблагодарил ее за ее поддержку',
        transcription: 'hi θæŋkt hə fə hə səˈpɔːt',
        translation: 'He thanked her for her support',
      },
      {
        id: 77,
        expression: 'Тебе нужно понять, что это временная мера',
        transcription: 'jʊ niːd tʊ ˌʌndəˈstænd ðət ɪts ə ˈtɛmpərəri ˈmɛʒə',
        translation: "You need to understand that it's a temporary measure",
      },
      {
        id: 78,
        expression: 'Я понимаю, что это была временная мера',
        transcription: 'aɪ ˌʌndəˈstænd ðət ɪt wəz ə ˈtɛmpərəri ˈmɛʒə',
        translation: 'I understand that it was a temporary measure',
      },
      {
        id: 79,
        expression: 'Тебе нужно понимать, что это необходимая мера',
        transcription: 'jʊ niːd tʊ ˌʌndəˈstænd ðət ɪts ə ˈnɛsɪsəri ˈmɛʒə',
        translation: "You need to understand that it's a necessary measure",
      },
      {
        id: 80,
        expression: 'Это связано с моей будущей работой',
        transcription: 'ɪts kəˈnɛktɪd wɪð maɪ ˈfjuːʧə prəˈfɛʃən',
        translation: "It's connected with my future profession",
      },
      {
        id: 81,
        expression: 'Это не связано с моей будущей профессией',
        transcription: 'ɪt ˈɪznt kəˈnɛktɪd wɪð maɪ ˈfjuːʧə prəˈfɛʃən',
        translation: "It isn't connected with my future profession",
      },
      {
        id: 82,
        expression: 'Мы знаем, что это глобальная проблема',
        transcription: 'wi nəʊ ðət ɪts ə ˈgləʊbəl ˈprɒbləm',
        translation: "We know that it's a global problem",
      },
      {
        id: 83,
        expression: 'Мы знаем, что это серьезная проблема',
        transcription: 'wi nəʊ ðət ɪts ə ˈsɪərɪəs ˈprɒbləm',
        translation: "We know that it's a serious problem",
      },
      {
        id: 84,
        expression: 'Я думаю, что это глобальная проблема',
        transcription: 'aɪ θɪŋk ðət ɪts ə ˈgləʊbəl ˈprɒbləm',
        translation: "I think that it's a global problem",
      },
      {
        id: 85,
        expression: 'Это один из лучших отелей',
        transcription: 'ɪts wʌn əv ðə bɛst həʊˈtɛlz',
        translation: "It's one of the best hotels",
      },
      {
        id: 86,
        expression: 'Это был очень тяжелый выбор',
        transcription: 'ɪt wəz ə hɑːd ʧɔɪs',
        translation: 'It was a hard choice',
      },
      {
        id: 87,
        expression: 'Он отказался сделать это по этой причине',
        transcription: 'hi ˌriːˈfjuːzd tə dʊ ɪt fə ðɪs ˈriːzn',
        translation: 'He refused to do it for this reason',
      },
      {
        id: 88,
        expression: 'Мы увидим это в следующем видео',
        transcription: 'wiːl siː ɪt ɪn ðə nɛkst ˈvɪdɪəʊ',
        translation: "We'll see it in the next video",
      },
      {
        id: 89,
        expression: 'Я думаю, это худшее решение',
        transcription: 'aɪ θɪŋk ɪts ðə wɜːst dɪˈsɪʒən',
        translation: "I think it's the worst decision",
      },
      {
        id: 90,
        expression: 'Это один из моих лучших результатов',
        transcription: 'ɪts wʌn əv maɪ bɛst rɪˈzʌlts',
        translation: "It's one of my best results",
      },
      {
        id: 91,
        expression: 'Ты уже выбрал свою будущую профессию?',
        transcription: 'həv jʊ ɔːlˈrɛdi ˈʧəʊzn jə ˈfjuːʧə prəˈfɛʃən?',
        translation: 'Have you already chosen your future profession?',
      },
      {
        id: 92,
        expression: 'Это решение было такое трудное для него',
        transcription: 'ðɪs dɪˈsɪʒən wəz səʊ hɑːd (ˈdɪfɪkəlt) fə hɪm',
        translation: 'This decision was so hard (difficult) for him',
      },
      {
        id: 93,
        expression: 'Я думаю, мы могли бы встретиться на следующей неделе',
        transcription: 'aɪ θɪŋk wi kəd miːt nɛkst wiːk',
        translation: 'I think we could meet next week',
      },
      {
        id: 94,
        expression: 'Это такое уродливое здание',
        transcription: 'ɪts sʌʧ ən ˈʌgli ˈbɪldɪŋ',
        translation: "It's such an ugly building",
      },
      {
        id: 95,
        expression: 'Это противоположное слово',
        transcription: 'ɪts ən ˈɒpəzɪt wɜːd',
        translation: "It's an opposite word",
      },
      {
        id: 96,
        expression: 'Она - единственный ребенок',
        transcription: 'ʃiːz ən ˈəʊnli ʧaɪld',
        translation: "She's an only child",
      },
      {
        id: 97,
        expression: 'Это было трудно представить',
        transcription: 'ɪt wəz hɑːd tʊ ɪˈmæʤɪn ɪt',
        translation: 'It was hard to imagine it',
      },
      {
        id: 98,
        expression: 'Полиция нашла его живым',
        transcription: 'ðə pəˈliːs faʊnd ɪm əˈlaɪv',
        translation: 'The police found him alive',
      },
      {
        id: 99,
        expression: 'Эта была пластическая операция',
        transcription: 'ɪt wəz ə ˈplæstɪk ˈsɜːʤəri ˌɒpəˈreɪʃən',
        translation: 'It was a plastic surgery operation',
      },
      {
        id: 100,
        expression: 'Я предполагаю, это не плохая идея',
        transcription: 'aɪ səˈpəʊz ɪt ˈɪznt ə bæd aɪˈdɪə',
        translation: "I suppose it isn't a bad idea",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Закрепление пройденного материала</h3>
</div>`,
    },
  ],
];
