export const wordsLesson255 = [
  [
    {
      id: 1,
      word: 'look in',
      transcription: 'lʊk ɪn',
      translation: 'заглядывать',
    },
    {
      id: 2,
      word: 'deprive of',
      transcription: 'dɪˈpraɪv ɒv',
      translation: 'лишать',
    },
    {
      id: 3,
      word: 'sell out',
      transcription: 'sɛl aʊt',
      translation: 'распродавать',
    },
    {
      id: 4,
      word: 'buy up',
      transcription: 'baɪ ʌp',
      translation: 'скупать',
    },
    {
      id: 5,
      word: 'wear out',
      transcription: 'weər aʊt',
      translation: 'изнашиваться, изматываться',
    },
    {
      id: 6,
      word: 'use up',
      transcription: 'juːz ʌp',
      translation: 'израсходовать',
    },
    {
      id: 7,
      word: 'show off',
      transcription: 'ʃəʊ ɒf',
      translation: 'выпендриваться',
    },
    {
      id: 8,
      word: 'take on',
      transcription: 'teɪk ɒn',
      translation: 'нанимать',
    },
    {
      id: 9,
      word: 'rip-off',
      transcription: 'rɪp ɒf',
      translation: 'грабить',
    },
    {
      id: 10,
      word: 'measure up',
      transcription: 'ˈmɛʒər ʌp',
      translation: 'соответствовать',
    },
    {
      id: 11,
      word: 'note down',
      transcription: 'nəʊt daʊn',
      translation: 'записывать',
    },
    {
      id: 12,
      word: 'keep off',
      transcription: 'kiːp ɒf',
      translation: 'воздерживаться от, не ходить по',
    },
    {
      id: 13,
      word: 'die down',
      transcription: 'daɪ daʊn',
      translation: 'утихать',
    },
    {
      id: 14,
      word: 'carry out',
      transcription: 'ˈkæri aʊt',
      translation: 'выполнять',
    },
    {
      id: 15,
      word: 'applause',
      transcription: 'əˈplɔːz',
      translation: 'аплодисменты',
    },
    {
      id: 16,
      word: 'breakthrough',
      transcription: 'ˈbreɪkˌθruː',
      translation: 'прорыв',
    },
    {
      id: 17,
      word: 'daylight',
      transcription: 'ˈdeɪlaɪt',
      translation: 'дневной свет',
    },
    {
      id: 18,
      word: 'eagerly',
      transcription: 'ˈiːɡəli',
      translation: 'охотно',
    },
    {
      id: 19,
      word: 'efficiently',
      transcription: 'ɪˈfɪʃəntli',
      translation: 'эффективно',
    },
    {
      id: 20,
      word: 'gossip',
      transcription: 'ˈɡɒsɪp',
      translation: 'сплетня',
    },
    {
      id: 21,
      word: 'grass',
      transcription: 'ɡrɑːs',
      translation: 'трава',
    },
    {
      id: 22,
      word: 'imprison',
      transcription: 'ɪmˈprɪzn',
      translation: 'заключать в тюрьму',
    },
    {
      id: 23,
      word: 'inspiring',
      transcription: 'ɪnˈspaɪərɪŋ',
      translation: 'вдохновляющий',
    },
    {
      id: 24,
      word: 'mask',
      transcription: 'mɑːsk',
      translation: 'маска',
    },
    {
      id: 25,
      word: 'persistent',
      transcription: 'pəˈsɪstənt',
      translation: 'настойчивый',
    },
    {
      id: 26,
      word: 'premiere',
      transcription: 'ˈprɛmɪeə',
      translation: 'премьера',
    },
    {
      id: 27,
      word: 'pursuit',
      transcription: 'pəˈsjuːt',
      translation: 'погоня',
    },
    {
      id: 28,
      word: 'reckless',
      transcription: 'ˈrɛklɪs',
      translation: 'безрассудный',
    },
    {
      id: 29,
      word: 'sadly',
      transcription: 'ˈsædli',
      translation: 'грустно',
    },
    {
      id: 30,
      word: 'trainee',
      transcription: 'treɪˈniː',
      translation: 'стажер',
    },
  ],
];
