export const contentLesson92 = [
  [
    {
      title: "Урок 92. Past Simple. 85 Irregular Verbs. Part 3",
    },
    [
      {
        id: 1,
        expression: "Она сдержала свое обещание",
        transcription: "ʃi kɛpt hə ˈprɒmɪs",
        translation: "She kept her promise",
      },
      {
        id: 2,
        expression: "Она обычно носила очень неформальную одежду",
        transcription: "ʃi ˈjuːʒʊəli wɔː ˈvɛri ɪnˈfɔːml kləʊðz",
        translation: "She usually wore very informal clothes",
      },
      {
        id: 3,
        expression: "Я в основном носил повседневную одежду",
        transcription: "aɪ ˈməʊstli wɔː ˈkæʒjʊəl kləʊðz",
        translation: "I mostly wore casual clothes",
      },
      {
        id: 4,
        expression: "Я получал так много сообщений каждый день",
        transcription: "aɪ gɒt səʊ ˈmɛni ˈmɛsɪʤɪz ˈɛvri deɪ",
        translation: "I got so many messages every day",
      },
      {
        id: 5,
        expression: "Он сказал очень важную вещь",
        transcription: "hi sɛd ə ˈvɛri ɪmˈpɔːtənt θɪŋ",
        translation: "He said a very important thing",
      },
      {
        id: 6,
        expression: "Он держал свои деньги в нескольких банках",
        transcription: "hi kɛpt ɪz ˈmʌni ɪn ˈsɛvrəl bæŋks",
        translation: "He kept his money in several banks",
      },
      {
        id: 7,
        expression: "Я имел в виду другую вещь",
        transcription: "aɪ mɛnt əˈnʌðə θɪŋ",
        translation: "I meant another thing",
      },
      {
        id: 8,
        expression: "Билет вчера стоил пятьдесят долларов",
        transcription: "ðə ˈtɪkɪt kɒst ˈfɪfti ˈdɒləz ˈjɛstədeɪ",
        translation: "The ticket cost fifty dollars yesterday",
      },
      {
        id: 9,
        expression: "Она улетела в США",
        transcription: "ʃi fluː tə ðə jʊˈnaɪtɪd steɪts",
        translation: "She flew to the United States",
      },
      {
        id: 10,
        expression: "Я чувствовал себя действительно счастливым",
        transcription: "aɪ fɛlt ˈrɪəli ˈhæpi",
        translation: "I felt really happy",
      },
      {
        id: 11,
        expression: "Они построили завод",
        transcription: "ðeɪ bɪlt ə ˈfæktəri",
        translation: "They built a factory",
      },
      {
        id: 12,
        expression: "Он купил маленькую квартиру",
        transcription: "hi bɔːt ə smɔːl əˈpɑːtmənt",
        translation: "He bought a small apartment",
      },
      {
        id: 13,
        expression: "Он потерял терпение",
        transcription: "hi lɒst ˈpeɪʃəns",
        translation: "He lost patience",
      },
      {
        id: 14,
        expression: "Сколько стоили билеты",
        transcription: "haʊ mʌʧ dɪd ðə ˈtɪkɪts kɒst?",
        translation: "How much did the tickets cost?",
      },
      {
        id: 15,
        expression: "Он поехал на работу на машине",
        transcription: "hi wɛnt tə wɜːk baɪ kɑː",
        translation: "He went to work by car",
      },
      {
        id: 16,
        expression: "Я внезапно потерял концентрацию",
        transcription: "aɪ lɒst ˌkɒnsənˈtreɪʃən ˈsʌdnli",
        translation: "I lost concentration suddenly",
      },
      {
        id: 17,
        expression: "Что означали ее слова?",
        transcription: "wɒt dɪd hə wɜːdz miːn?",
        translation: "What did her words mean?",
      },
      {
        id: 18,
        expression: "Сколько стоил компьютер?",
        transcription:
          "haʊ mʌʧ wəz ðə kəmˈpjuːtə? / haʊ mʌʧ dɪd ðə kəmˈpjuːtə kɒst?",
        translation:
          "How much was the computer? / How much did the computer cost?",
      },
      {
        id: 19,
        expression: "Я носил школьную форму",
        transcription: "aɪ wɔːr ə skuːl ˈjuːnɪfɔːm",
        translation: "I wore a school uniform",
      },
      {
        id: 20,
        expression: "Он сохранил этот секрет",
        transcription: "hi kɛpt ðɪs ˈsiːkrɪt",
        translation: "He kept this secret",
      },
      {
        id: 21,
        expression: "Они построили настоящий дворец",
        transcription: "ðeɪ bɪlt ə rɪəl ˈpælɪs",
        translation: "They built a real palace",
      },
      {
        id: 22,
        expression: "Он носил слишком формальную одежду",
        transcription: "hi wɔː tuː ˈfɔːməl kləʊðz",
        translation: "He wore too formal clothes",
      },
      {
        id: 23,
        expression: "Ты носил школьную форму?",
        transcription: "dɪd jʊ weər ə skuːl ˈjuːnɪfɔːm?",
        translation: "Did you wear a school uniform?",
      },
      {
        id: 24,
        expression: "Он улетел за границу на прошлой неделе",
        transcription: "hi fluː əˈbrɔːd lɑːst wiːk",
        translation: "He flew abroad last week",
      },
      {
        id: 25,
        expression: "Они продали свой старый дом",
        transcription: "ðeɪ səʊld ðeər əʊld haʊs",
        translation: "They sold their old house",
      },
      {
        id: 26,
        expression: "Она потеряла контроль",
        transcription: "ʃi lɒst kənˈtrəʊl",
        translation: "She lost control",
      },
      {
        id: 27,
        expression: "Я поехал на работу на автобусе",
        transcription: "aɪ wɛnt tə wɜːk baɪ bʌs",
        translation: "I went to work by bus",
      },
      {
        id: 28,
        expression: "Сколько стоила эта услуга?",
        transcription: "haʊ dɪd ðɪs ˈsɜːvɪs kɒst? / haʊ mʌʧ wəz ðɪs ˈsɜːvɪs?",
        translation: "How did this service cost? / How much was this service?",
      },
      {
        id: 29,
        expression: "Конференция началась десять минут назад",
        transcription: "ðə ˈkɒnfərəns bɪˈgæn tɛn ˈmɪnɪts əˈgəʊ",
        translation: "The conference began ten minutes ago",
      },
      {
        id: 30,
        expression: "Он потерял ключи",
        transcription: "hi lɒst ðə kiːz",
        translation: "He lost the keys",
      },
      {
        id: 31,
        expression: "Она потеряла свой кошелек",
        transcription: "ʃi lɒst hə pɜːs",
        translation: "She lost her purse",
      },
      {
        id: 32,
        expression: "Я поехал в эту страну на самолете",
        transcription: "aɪ wɛnt tə ðɪs ˈkʌntri baɪ pleɪn",
        translation: "I went to this country by plane",
      },
      {
        id: 33,
        expression: "Мы поехали в тот город на поезде",
        transcription: "wi wɛnt tə ðət ˈsɪti baɪ treɪn",
        translation: "We went to that city by train",
      },
      {
        id: 34,
        expression: "Урок начался",
        transcription: "ðə ˈlɛsn bɪˈgæn",
        translation: "The lesson began",
      },
      {
        id: 35,
        expression: "Билеты стоят так дорого",
        transcription: "ðə ˈtɪkɪts kɒst səʊ mʌʧ",
        translation: "The tickets cost so much",
      },
      {
        id: 36,
        expression: "Они построили дворец",
        transcription: "ðeɪ bɪlt ə ˈpælɪs",
        translation: "They built a palace",
      },
      {
        id: 37,
        expression: "Цена была такой высокой",
        transcription: "ðə praɪs wəz səʊ haɪ",
        translation: "The price was so high",
      },
      {
        id: 38,
        expression: "Сколько стоили билеты?",
        transcription: "haʊ mʌʧ dɪd ðə ˈtɪkɪts kɒst? / haʊ mʌʧ wə ðə ˈtɪkɪts?",
        translation:
          "How much did the tickets cost? / How much were the tickets?",
      },
      {
        id: 39,
        expression: "Они показали это по телевизору",
        transcription: "ðeɪ ʃəʊd ɪt ɒn ˈtɛlɪˌvɪʒən",
        translation: "They showed it on television",
      },
      {
        id: 40,
        expression: "Я мог делать эти вещи одновременно",
        transcription: "aɪ kəd dʊ ðiːz θɪŋz ət ðə seɪm taɪm",
        translation: "I could do these things at the same time",
      },
      {
        id: 41,
        expression: "Он одолжил ему эту сумму денег",
        transcription: "hi lɛnt ɪm ðɪs sʌm əv ˈmʌni",
        translation: "He lent him this sum of money",
      },
      {
        id: 42,
        expression: "Я мог слушать это, делая другие вещи",
        transcription: "aɪ kəd ˈlɪsn tʊ ɪt ˈdu(ː)ɪŋ ˈʌðə θɪŋz",
        translation: "I could listen to it doing other things",
      },
      {
        id: 43,
        expression: "Платье подходило ей очень хорошо",
        transcription: "ðə drɛs fɪt hə ˈvɛri wɛl",
        translation: "The dress fit her very well",
      },
      {
        id: 44,
        expression: "Она сказала следующую вещь",
        transcription: "ʃi sɛd ðə ˈfɒləʊɪŋ θɪŋ",
        translation: "She said the following thing",
      },
      {
        id: 45,
        expression: "Я лег",
        transcription: "aɪ leɪ daʊn",
        translation: "I lay down",
      },
      {
        id: 46,
        expression: "Он одолжил ей очень большую сумму денег",
        transcription: "hi lɛnt hər ə ˈvɛri bɪg sʌm əv ˈmʌni",
        translation: "He lent her a very big sum of money",
      },
      {
        id: 47,
        expression: "Он позвонил мне, когда я был в ванной",
        transcription: "hi ræŋ mi wɛn aɪ wəz ɪn ðə ˈbɑːθru(ː)m",
        translation: "He rang me when I was in the bathroom",
      },
      {
        id: 48,
        expression:
          "Я мог учить английский часами вовсе без каких-либо перерывов",
        transcription:
          "aɪ kəd lɜːn ˈɪŋglɪʃ fər ˈaʊəz wɪˈðaʊt ˈɛni breɪks ət ɔːl",
        translation:
          "I could learn English for hours without any breaks at all",
      },
      {
        id: 49,
        expression: "Обувь мне идеально подошла",
        transcription: "ðə ʃuːz fɪt mi ˈpɜːfɪktli",
        translation: "The shoes fit me perfectly",
      },
      {
        id: 50,
        expression: "Он не пришел на вечеринку",
        transcription: "hi dɪdnt kʌm tə ðə ˈpɑːti",
        translation: "He didn't come to the party",
      },
      {
        id: 51,
        expression: "Я мог делать это часами",
        transcription: "aɪ kəd dʊ ɪt fər ˈaʊəz",
        translation: "I could do it for hours",
      },
      {
        id: 52,
        expression: "Кого они побили?",
        transcription: "huː dɪd ðeɪ biːt?",
        translation: "Who did they beat?",
      },
      {
        id: 53,
        expression: "Я чувствовал себя таким счастливым, что смог сделать это",
        transcription: "aɪ fɛlt səʊ ˈhæpi ðət aɪ kəd dʊ ɪt",
        translation: "I felt so happy that I could do it",
      },
      {
        id: 54,
        expression: "Она сказала чрезвычайно важную вещь",
        transcription: "ʃi sɛd ən ɪksˈtriːmli ɪmˈpɔːtənt θɪŋ",
        translation: "She said an extremely important thing",
      },
      {
        id: 55,
        expression: "Я обычно получал более пятидесяти звонков в день",
        transcription: "aɪ ˈjuːʒʊəli gɒt ˈəʊvə ˈfɪfti kɔːlz ˈdeɪli",
        translation: "I usually got over fifty calls daily",
      },
      {
        id: 56,
        expression: "Он отказался одолжить им эти деньги",
        transcription: "hi ˌriːˈfjuːzd tə lɛnd ðəm ðɪs ˈmʌni",
        translation: "He refused to lend them this money",
      },
      {
        id: 57,
        expression: "Я отказался одолжить ему эти деньги",
        transcription: "aɪ ˌriːˈfjuːzd tə lɛnd ɪm ðɪs ˈmʌni",
        translation: "I refused to lend him this money",
      },
      {
        id: 58,
        expression: "Он отказался одолжить мне эти деньги",
        transcription: "hi ˌriːˈfjuːzd tə lɛnd mi ðɪs ˈmʌni",
        translation: "He refused to lend me this money",
      },
      {
        id: 59,
        expression: "Кто избил его так сильно?",
        transcription: "huː biːt ɪm səʊ ˈbædli?",
        translation: "Who beat him so badly?",
      },
      {
        id: 60,
        expression: "Кто избил их так сильно?",
        transcription: "huː biːt ðəm səʊ ˈbædli?",
        translation: "Who beat them so badly?",
      },
      {
        id: 61,
        expression: "Он лег на пол",
        transcription: "hi leɪ ɒn ðə flɔː",
        translation: "He lay on the floor",
      },
      {
        id: 62,
        expression: "Он лег на землю",
        transcription: "hi leɪ ɒn ðə graʊnd",
        translation: "He lay on the ground",
      },
      {
        id: 63,
        expression: "Он лежит на полу",
        transcription: "hi laɪz ɒn ðə flɔː",
        translation: "He lies on the floor",
      },
      {
        id: 64,
        expression: "Он пришел к нам час назад",
        transcription: "hi keɪm tʊ əs ən ˈaʊər əˈgəʊ",
        translation: "He came to us an hour ago",
      },
      {
        id: 65,
        expression: "Он пришел ко мне два часа назад",
        transcription: "hi keɪm tə mi tuː ˈaʊəz əˈgəʊ",
        translation: "He came to me two hours ago",
      },
      {
        id: 66,
        expression: "Она пришла туда час назад",
        transcription: "ʃi keɪm ðeər ən ˈaʊər əˈgəʊ",
        translation: "She came there an hour ago",
      },
      {
        id: 67,
        expression: "Я прочитал это в новостях",
        transcription: "aɪ red ɪt ɪn ðə njuːz",
        translation: "I read it in the news",
      },
      {
        id: 68,
        expression: "Он прочитал это в новостях",
        transcription: "hi red ɪt ɪn ðə njuːz",
        translation: "He read it in the news",
      },
      {
        id: 69,
        expression: "Она прочитала что-то в новостях",
        transcription: "ʃi red ˈsʌmθɪŋ ɪn ðə njuːz",
        translation: "She read something in the news",
      },
      {
        id: 70,
        expression: "Тот свитер подходил мне идеально",
        transcription: "ðæt ˈswɛtə fɪt ɪm ˈpɜːfɪktli",
        translation: "That sweater fit him perfectly",
      },
      {
        id: 71,
        expression: "Это свитер подходил ему идеально",
        transcription: "ðɪs ˈswɛtə fɪt ɪm ˈpɜːfɪktli",
        translation: "This sweater fit him perfectly",
      },
      {
        id: 72,
        expression: "Это было выдающееся достижение",
        transcription: "ɪt wəz ən aʊtˈstændɪŋ əˈʧiːvmənt",
        translation: "It was an outstanding achievement",
      },
      {
        id: 73,
        expression: "Это не было выдающимся достижением",
        transcription: "ɪt wɒznt ən aʊtˈstændɪŋ əˈʧiːvmənt",
        translation: "It wasn't an outstanding achievement",
      },
      {
        id: 74,
        expression: "Это был выдающийся результат",
        transcription: "ɪt wəz ən aʊtˈstændɪŋ rɪˈzʌlt",
        translation: "It was an outstanding result",
      },
      {
        id: 75,
        expression: "Собака лежала на полу",
        transcription: "ðə dɒg leɪ ɒn ðə flɔː",
        translation: "The dog lay on the floor",
      },
      {
        id: 76,
        expression: "Собака лежала на этом стуле",
        transcription: "ðə dɒg leɪ ɒn ðɪs ʧeə",
        translation: "The dog lay on this chair",
      },
      {
        id: 77,
        expression: "Собака лежала на земле",
        transcription: "ðə dɒg leɪ ɒn ðə graʊnd",
        translation: "The dog lay on the ground",
      },
      {
        id: 78,
        expression: "Они избили его очень сильно",
        transcription: "ðeɪ biːt ɪm ˈvɛri ˈbædli",
        translation: "They beat him very badly",
      },
      {
        id: 79,
        expression: "Они очень сильно меня избили",
        transcription: "ðeɪ biːt mi ˈvɛri ˈbædli",
        translation: "They beat me very badly",
      },
      {
        id: 80,
        expression: "Они избили его так сильно",
        transcription: "ðeɪ biːt ɪm səʊ ˈbædli",
        translation: "They beat him so badly",
      },
      {
        id: 81,
        expression: "Я пришел домой позже, чем обычно",
        transcription: "aɪ keɪm həʊm ˈleɪtə ðən ˈjuːʒʊəl",
        translation: "I came home later than usual",
      },
      {
        id: 82,
        expression: "Я пришел на работу позже, чем обычно",
        transcription: "aɪ keɪm tə wɜːk ˈleɪtə ðən ˈjuːʒʊəl",
        translation: "I came to work later than usual",
      },
      {
        id: 83,
        expression: "Я пришел домой раньше, чем обычно",
        transcription: "aɪ keɪm həʊm ˈɜːlɪə ðən ˈjuːʒʊəl",
        translation: "I came home earlier than usual",
      },
      {
        id: 84,
        expression: "Я получал около пятидесяти электронных писем в день",
        transcription: "aɪ gɒt əˈbaʊt ˈfɪfti ˈiːmeɪlz ˈdeɪli",
        translation: "I got about fifty emails daily",
      },
      {
        id: 85,
        expression: "Я решил выбрать второй ответ",
        transcription: "aɪ dɪˈsaɪdɪd tə ʧuːz ðə ˈsɛkənd ˈɑːnsə",
        translation: "I decided to choose the second answer",
      },
      {
        id: 86,
        expression: "Он проиграл в конце",
        transcription: "hi wʌn ɪn ði ɛnd",
        translation: "He won in the end",
      },
      {
        id: 87,
        expression: "Он показал мне, как сделать это",
        transcription: "hi ʃəʊd mi haʊ tə dʊ ɪt",
        translation: "He showed me how to do it",
      },
      {
        id: 88,
        expression: "Встреча началась полчаса назад",
        transcription: "ðə ˈmiːtɪŋ bɪˈgæn hɑːf ən ˈaʊər əˈgəʊ",
        translation: "The meeting began half an hour ago",
      },
      {
        id: 89,
        expression: "Они не были уверены в этом",
        transcription: "ðeɪ wɜːnt ʃʊər əˈbaʊt ɪt",
        translation: "They weren't sure about it",
      },
      {
        id: 90,
        expression: "Он сказал, что он был невероятно занят",
        transcription: "hi sɛd ðət hi wəz ɪnˈkrɛdəbli ˈbɪzi",
        translation: "He said that he was incredibly busy",
      },
      {
        id: 91,
        expression: "Джинсы ей действительно хорошо подошли",
        transcription: "ðə ʤiːnz fɪt hə ˈrɪəli wɛl",
        translation: "The jeans fit her really well",
      },
      {
        id: 92,
        expression: "Лекция началась минуту назад",
        transcription: "ðə ˈlɛkʧə bɪˈgæn ə ˈmɪnɪt əˈgəʊ",
        translation: "The lecture began a minute ago",
      },
      {
        id: 93,
        expression: "Он сказал следующее",
        transcription: "hi sɛd ðə ˈfɒləʊɪŋ",
        translation: "He said the following",
      },
      {
        id: 94,
        expression: "Звонок прозвенел внезапно",
        transcription: "ðə bɛl ræŋ ˈsʌdnli",
        translation: "The bell rang suddenly",
      },
      {
        id: 95,
        expression: "Он перезвонил мне",
        transcription: "hi ræŋ mi bæk",
        translation: "He rang me back",
      },
      {
        id: 96,
        expression: "Это была очень высокая цена",
        transcription: "ɪt wəz ə ˈvɛri haɪ praɪs",
        translation: "It was a very high price",
      },
      {
        id: 97,
        expression: "Я выбрал первый ответ",
        transcription: "aɪ ʧəʊz ðə fɜːst ˈɑːnsə",
        translation: "I chose the first answer",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Закрепление пройденного материала</h3>
</div>`,
    },
  ],
];
