export const wordsLesson204 = [
  [
    {
      id: 1,
      word: 'boxing',
      transcription: 'ˈbɒksɪŋ',
      translation: 'бокс',
    },
    {
      id: 2,
      word: 'electricity',
      transcription: 'ɪlɛkˈtrɪsɪti',
      translation: 'электричество',
    },
    {
      id: 3,
      word: 'forced',
      transcription: 'fɔːst',
      translation: 'вынужден',
    },
    {
      id: 4,
      word: 'industry',
      transcription: 'ˈɪndəstri',
      translation: 'отрасль, промышленность',
    },
    {
      id: 5,
      word: 'occasion',
      transcription: 'əˈkeɪʒən',
      translation: 'случай',
    },
    {
      id: 6,
      word: 'proposal',
      transcription: 'prəˈpəʊzəl',
      translation: 'предложение',
    },
    {
      id: 7,
      word: 'underestimate',
      transcription: 'ˌʌndəˈrɛstɪmɪt',
      translation: 'недооценивать',
    },
    {
      id: 8,
      word: 'let down',
      transcription: 'lɛt daʊn',
      translation: 'подводить',
    },
    {
      id: 9,
      word: 'put off',
      transcription: 'pʊt ɒf',
      translation: 'откладывать',
    },
    {
      id: 10,
      word: 'drop out',
      transcription: 'drɒp aʊt',
      translation: 'бросить',
    },
    {
      id: 11,
      word: 'think over',
      transcription: 'θɪŋk ˈəʊvə',
      translation: 'обдумывать',
    },
    {
      id: 12,
      word: 'talk over',
      transcription: 'tɔːk ˈəʊvə',
      translation: 'обсудить',
    },
    {
      id: 13,
      word: 'put up with',
      transcription: 'pʊt ʌp wɪð',
      translation: 'смириться с',
    },
    {
      id: 14,
      word: 'get ahead',
      transcription: 'gɛt əˈhɛd',
      translation: 'продвинуться',
    },
    {
      id: 15,
      word: 'get round',
      transcription: 'gɛt raʊnd',
      translation: 'обойти',
    },
    {
      id: 16,
      word: 'get around',
      transcription: 'gɛt əˈraʊnd',
      translation: 'обойти',
    },
    {
      id: 17,
      word: 'cut off',
      transcription: 'kʌt ɒf',
      translation: 'отрезать, отключать',
    },
    {
      id: 18,
      word: 'put up',
      transcription: 'pʊt ʌp',
      translation: 'повесить, поднять',
    },
    {
      id: 19,
      word: 'put on',
      transcription: 'pʊt ɒn',
      translation: 'надевать',
    },
    {
      id: 20,
      word: 'go by',
      transcription: 'gəʊ baɪ',
      translation: 'проходить',
    },
    {
      id: 21,
      word: 'go in for',
      transcription: 'gəʊ ɪn fɔː',
      translation: 'заниматься',
    },
    {
      id: 22,
      word: 'go off',
      transcription: 'gəʊ ɒf',
      translation: 'портиться',
    },
    {
      id: 23,
      word: 'go away',
      transcription: 'gəʊ əˈweɪ',
      translation: 'уезжать',
    },
    {
      id: 24,
      word: 'go out',
      transcription: 'gəʊ aʊt',
      translation: 'выходить',
    },
  ],
];
