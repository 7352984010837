export const wordsLesson203 = [
  [
    {
      id: 1,
      word: 'adventure',
      transcription: 'ədˈvɛnʧə',
      translation: 'приключение',
    },
    {
      id: 2,
      word: 'coupon',
      transcription: 'ˈkuːpɒn',
      translation: 'купон',
    },
    {
      id: 3,
      word: 'fault',
      transcription: 'fɔːlt',
      translation: 'вина',
    },
    {
      id: 4,
      word: 'note',
      transcription: 'nəʊt',
      translation: 'заметка, отмечать',
    },
    {
      id: 5,
      word: 'retirement',
      transcription: 'rɪˈtaɪəmənt',
      translation: 'выход на пенсию',
    },
    {
      id: 6,
      word: 'schoolwork',
      transcription: 'ˈskuːlwɜːk',
      translation: 'школьное задание',
    },
    {
      id: 7,
      word: 'uncle',
      transcription: 'ˈʌŋkl',
      translation: 'дядя',
    },
    {
      id: 8,
      word: 'come from',
      transcription: 'kʌm frɒm',
      translation: 'родом из',
    },
    {
      id: 9,
      word: 'bring up',
      transcription: 'brɪŋ ʌp',
      translation: 'воспитывать',
    },
    {
      id: 10,
      word: 'fill in',
      transcription: 'fɪl ɪn',
      translation: 'заполнить',
    },
    {
      id: 11,
      word: 'lag behind',
      transcription: 'læg bɪˈhaɪnd',
      translation: 'отставать',
    },
    {
      id: 12,
      word: 'look after',
      transcription: 'lʊk ˈɑːftə',
      translation: 'присматривать за, ухаживать за',
    },
    {
      id: 13,
      word: 'come in',
      transcription: 'kʌm ɪn',
      translation: 'зайти',
    },
    {
      id: 14,
      word: 'take after',
      transcription: 'teɪk ˈɑːftə',
      translation: 'похож на',
    },
    {
      id: 15,
      word: 'look ahead',
      transcription: 'lʊk əˈhɛd',
      translation: 'смотреть вперед',
    },
    {
      id: 16,
      word: 'look back',
      transcription: 'lʊk bæk',
      translation: 'оглядываться, вспоминать',
    },
    {
      id: 17,
      word: 'look forward to',
      transcription: 'lʊk ˈfɔːwəd tuː',
      translation: 'ждать с нетерпением',
    },
    {
      id: 18,
      word: 'look in',
      transcription: 'lʊk ɪn',
      translation: 'заглядывать',
    },
    {
      id: 19,
      word: 'look out',
      transcription: 'lʊk aʊt',
      translation: 'выглянуть, осторожно',
    },
    {
      id: 20,
      word: 'watch out',
      transcription: 'wɒʧ aʊt',
      translation: 'осторожно, берегись',
    },
    {
      id: 21,
      word: 'come back',
      transcription: 'kʌm bæk',
      translation: 'возвращаться',
    },
    {
      id: 22,
      word: 'get at',
      transcription: 'gɛt æt',
      translation: 'доставать, предлагать, иметь в виду',
    },
    {
      id: 23,
      word: 'get back',
      transcription: 'gɛt bæk',
      translation: 'возвращаться',
    },
    {
      id: 24,
      word: 'look through',
      transcription: 'lʊk θruː',
      translation: 'просматривать',
   },
  ],
];
