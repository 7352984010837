export const contentLesson114 = [
  [
    {
      title: "Урок 114. State Verbs",
    },
    [
      {
        id: 1,
        expression: "Я действительно хочу этого сейчас",
        transcription: "aɪ ˈrɪəli wɒnt ɪt naʊ",
        translation: "I really want it now",
      },
      {
        id: 2,
        expression: "Он видит тебя сейчас",
        transcription: "hi siːz jʊ naʊ",
        translation: "He sees you now",
      },
      {
        id: 3,
        expression: "Я помню это очень хорошо сейчас",
        transcription: "aɪ rɪˈmɛmbər ɪt ˈvɛri wɛl naʊ",
        translation: "I remember it very well now",
      },
      {
        id: 4,
        expression: "Я желаю этого сейчас",
        transcription: "aɪ wɪʃ ɪt naʊ",
        translation: "I wish it now",
      },
      {
        id: 5,
        expression: "Теперь это весит четыре килограмма",
        transcription: "ɪt weɪz fɔː ˈkiːləʊz naʊ",
        translation: "It weighs four kilos now",
      },
      {
        id: 6,
        expression: "Я действительно ненавижу это сейчас",
        transcription: "aɪ ˈrɪəli heɪt ɪt naʊ",
        translation: "I really hate it now",
      },
      {
        id: 7,
        expression: "Сейчас ему не хватает опыта работы",
        transcription: "hi læks wɜːk ɪksˈpɪərɪəns naʊ",
        translation: "He lacks work experience now",
      },
      {
        id: 8,
        expression: "Что тебе нужно сделать сейчас?",
        transcription: "wɒt dʊ jʊ niːd tə dʊ naʊ?",
        translation: "What do you need to do now?",
      },
      {
        id: 9,
        expression: "Он обычно очень резко критикует других людей",
        transcription: "hi ˈjuːʒʊəli ˈkrɪtɪsaɪzɪz ˈʌðə ˈpiːpl ˈvɛri ˈʃɑːpli",
        translation: "He usually criticizes other people very sharply",
      },
      {
        id: 10,
        expression: "Эти мужчины сейчас тратят время впустую",
        transcription: "ðiːz mɛn ə ˈweɪstɪŋ taɪm naʊ",
        translation: "These men are wasting time now",
      },
      {
        id: 11,
        expression: "Он сейчас обменивает деньги",
        transcription: "hiːz ɪksˈʧeɪnʤɪŋ ˈmʌni naʊ",
        translation: "He's exchanging money now",
      },
      {
        id: 12,
        expression: "Он должен ему очень большую сумму денег сейчас",
        transcription: "hi əʊz ɪm ˈvɛri bɪg sʌm əv ˈmʌni naʊ",
        translation: "He owes him very big sum of money now",
      },
      {
        id: 13,
        expression: "Они анализируют эти цифры сейчас",
        transcription: "ðeɪ ər ˈænəlaɪzɪŋ ðiːz ˈfɪgəz naʊ",
        translation: "They are analysing these figures now",
      },
      {
        id: 14,
        expression: "Я иду на некоторые риски сейчас",
        transcription: "aɪm ˈteɪkɪŋ səm rɪsks naʊ",
        translation: "I'm taking some risks now",
      },
      {
        id: 15,
        expression: "Ты действительно заслуживаешь этого сейчас",
        transcription: "jʊ ˈrɪəli dɪˈzɜːv ɪt naʊ",
        translation: "You really deserve it now",
      },
      {
        id: 16,
        expression: "Я очень хочу этого сейчас",
        transcription: "aɪ ˈrɪəli wɒnt ɪt naʊ",
        translation: "I really want it now",
      },
      {
        id: 17,
        expression: "Он одалживает ему очень большие деньги сейчас",
        transcription: "hiːz ˈlɛndɪŋ ɪm ˈvɛri bɪg ˈmʌni naʊ",
        translation: "He's lending him very big money now",
      },
      {
        id: 18,
        expression: "Он паркует свою машину сейчас",
        transcription: "hiːz ˈpɑːkɪŋ ɪz kɑː naʊ",
        translation: "He's parking his car now",
      },
      {
        id: 19,
        expression: "Он пытается это понять сейчас",
        transcription: "hiːz ˈtraɪɪŋ tʊ ˌʌndəˈstænd ɪt naʊ",
        translation: "He's trying to understand it now",
      },
      {
        id: 20,
        expression: "Я сейчас с ним не согласен",
        transcription: "aɪ ˌdɪsəˈgriː wɪð ɪm naʊ",
        translation: "I disagree with him now",
      },
      {
        id: 21,
        expression: "Его предложение сейчас звучит очень заманчиво",
        transcription: "hɪz ˈɒfə saʊndz ˈvɛri əˈtræktɪv naʊ",
        translation: "His offer sounds very attractive now",
      },
      {
        id: 22,
        expression: "Он нуждается в этом сейчас",
        transcription: "hi niːdz ɪt naʊ",
        translation: "He needs it now",
      },
      {
        id: 23,
        expression: "Он должен тебе триста долларов сейчас",
        transcription: "hi əʊz jʊ θriː ˈhʌndrəd ˈdɒləz naʊ",
        translation: "He owes you three hundred dollars now",
      },
      {
        id: 24,
        expression: "Ты знаешь ответ сейчас?",
        transcription: "dʊ jʊ nəʊ ði ˈɑːnsə naʊ?",
        translation: "Do you know the answer now?",
      },
      {
        id: 25,
        expression: "Мне не хватает свободного времени сейчас",
        transcription: "aɪ læk friː taɪm naʊ",
        translation: "I lack free time now",
      },
      {
        id: 26,
        expression: "Я перевожу этот текст сейчас",
        transcription: "aɪm trænsˈleɪtɪŋ ðɪs tɛkst naʊ",
        translation: "I'm translating this text now",
      },
      {
        id: 27,
        expression: "Он обещает это сейчас",
        transcription: "hi ˈprɒmɪsɪz ɪt naʊ",
        translation: "He promises it now",
      },
      {
        id: 28,
        expression: "Я полагаю, что это лучшая цена сейчас",
        transcription: "aɪ səˈpəʊz ðət ɪts ðə bɛst praɪs naʊ",
        translation: "I suppose that it's the best price now",
      },
      {
        id: 29,
        expression: "Он одалживает ей очень большие деньги сейчас",
        transcription: "hiːz ˈlɛndɪŋ hə ˈvɛri bɪg ˈmʌni naʊ",
        translation: "He's lending her very big money now",
      },
      {
        id: 30,
        expression: "Это зависит от тебя сейчас?",
        transcription: "dəz ɪt dɪˈpɛnd ɒn jʊ naʊ?",
        translation: "Does it depend on you now?",
      },
      {
        id: 31,
        expression: "Я прекрасно это понимаю сейчас",
        transcription: "aɪ ˈpɜːfɪktli ˌʌndəˈstænd ɪt naʊ",
        translation: "I perfectly understand it now",
      },
      {
        id: 32,
        expression: "Вторая версия кажется намного лучше сейчас",
        transcription: "ðə ˈsɛkənd ˈvɜːʃən siːmz mʌʧ ˈbɛtə naʊ",
        translation: "The second version seems much better now",
      },
      {
        id: 33,
        expression: "Я прекрасно осознаю это сейчас",
        transcription: "aɪ ˈpɜːfɪktli ˈrɪəlaɪz ɪt naʊ",
        translation: "I perfectly realise it now",
      },
      {
        id: 34,
        expression: "Эта квартира принадлежит ей сейчас",
        transcription: "ðɪs əˈpɑːtmənt bɪˈlɒŋz tə hə naʊ",
        translation: "This apartment belongs to her now",
      },
      {
        id: 35,
        expression: "Он идет на очень большой риск сейчас",
        transcription: "hiːz ˈteɪkɪŋ ə ˈvɛri bɪg rɪsk naʊ",
        translation: "He's taking a very big risk now",
      },
      {
        id: 36,
        expression: "Те женщины ждут этого сейчас",
        transcription: "ðəʊz ˈwɪmɪn ə ˈweɪtɪŋ fər ɪt naʊ",
        translation: "Those women are waiting for it now",
      },
      {
        id: 37,
        expression: "Я тебя очень хорошо слышу сейчас",
        transcription: "aɪ hɪə jʊ ˈvɛri wɛl naʊ",
        translation: "I hear you very well now",
      },
      {
        id: 38,
        expression: "Он очень резко их критикует сейчас",
        transcription: "hiːz ˈkrɪtɪsaɪzɪŋ ðəm ˈvɛri ˈʃɑːpli naʊ",
        translation: "He's criticizing them very sharply now",
      },
      {
        id: 39,
        expression: "Я сейчас анализирую это",
        transcription: "aɪm ˈænəlaɪzɪŋ ɪt naʊ",
        translation: "I'm analysing it now",
      },
      {
        id: 40,
        expression: "Мне сейчас действительно нравится эта идея",
        transcription: "aɪ ˈrɪəli laɪk ðɪs aɪˈdɪə naʊ",
        translation: "I really like this idea now",
      },
      {
        id: 41,
        expression: "Я верю ему сейчас",
        transcription: "aɪ bɪˈliːv ɪm naʊ",
        translation: "I believe him now",
      },
      {
        id: 42,
        expression: "Я понимаю это сейчас",
        transcription: "aɪ ˌʌndəˈstænd ɪt naʊ",
        translation: "I understand it now",
      },
      {
        id: 43,
        expression: "У него сейчас болит голова",
        transcription: "hi həz ə ˈhɛdeɪk naʊ",
        translation: "He has a headache now",
      },
      {
        id: 44,
        expression: "Мы прекрасно проводим время сейчас",
        transcription: "wɪə ˈhævɪŋ ə greɪt taɪm naʊ",
        translation: "We're having a great time now",
      },
      {
        id: 45,
        expression: "Еда очень вкусная сейчас",
        transcription:
          "ðə fuːd teɪsts ˈvɛri gʊd naʊ / ðə fuːd z ˈvɛri ˈteɪsti naʊ",
        translation:
          "The food tastes very good now / The food is very tasty now",
      },
      {
        id: 46,
        expression: "Я справляюсь с этими трудностями довольно хорошо сейчас",
        transcription: "aɪm ˈkəʊpɪŋ wɪð ðiːz ˈdɪfɪkəltiz ˈprɪti wɛl naʊ",
        translation: "I'm coping with these difficulties pretty well now",
      },
      {
        id: 47,
        expression:
          "У него сейчас сильная головная боль из-за этого ужасного шума",
        transcription:
          "hi həz ə ˈsplɪtɪŋ ˈhɛdeɪk naʊ bɪˈkəz əv ðɪs ˈtɛrəbl nɔɪz",
        translation:
          "He has a splitting headache now because of this terrible noise",
      },
      {
        id: 48,
        expression: "Что звучит очень подозрительно сейчас?",
        transcription: "wɒt saʊndz ˈvɛri səsˈpɪʃəs naʊ?",
        translation: "What sounds very suspicious now?",
      },
      {
        id: 49,
        expression: "Он заслуживает эти деньги сейчас",
        transcription: "hi dɪˈzɜːvz ðɪs ˈmʌni naʊ",
        translation: "He deserves this money now",
      },
      {
        id: 50,
        expression: "Новости сейчас такие захватывающие",
        transcription: "ðə njuːz ɪz səʊ ɪkˈsaɪtɪŋ naʊ",
        translation: "The news is so exciting now",
      },
      {
        id: 51,
        expression: "Что сейчас звучит подозрительно?",
        transcription: "wɒt saʊndz səsˈpɪʃəs naʊ?",
        translation: "What sounds suspicious now?",
      },
      {
        id: 52,
        expression: "Я думаю, ты действительно заслуживаешь этого",
        transcription: "aɪ θɪŋk jʊ ˈrɪəli dɪˈzɜːv ɪt",
        translation: "I think you really deserve it",
      },
      {
        id: 53,
        expression: "Еда действительно вкусная",
        transcription: "ðə fuːd teɪsts ˈrɪəli dɪˈlɪʃəs",
        translation: "The food tastes really delicious",
      },
      {
        id: 54,
        expression: "Что ты думаешь обо всем этом?",
        transcription: "wɒt dʊ jʊ θɪŋk əˈbaʊt ɔːl ðɪs?",
        translation: "What do you think about all this?",
      },
      {
        id: 55,
        expression: "Я вижу, что он имеет в виду",
        transcription: "aɪ siː wɒt hi miːnz",
        translation: "I see what he means",
      },
      {
        id: 56,
        expression: "У него есть двоюродный брат",
        transcription: "hi həz ə ˈkʌzn",
        translation: "He has a cousin",
      },
      {
        id: 57,
        expression: "О чем они думают?",
        transcription: "wɒt ə ðeɪ ˈθɪŋkɪŋ əˈbaʊt?",
        translation: "What are they thinking about?",
      },
      {
        id: 58,
        expression: "У супа прекрасный вкус",
        transcription: "ðə suːp teɪsts greɪt",
        translation: "The soup tastes great",
      },
      {
        id: 59,
        expression: "Суп очень вкусный сейчас",
        transcription: "ðə suːp teɪsts ˈvɛri gʊd naʊ",
        translation: "The soup tastes very good now",
      },
      {
        id: 60,
        expression: "Она пытается справиться с этими трудностями сейчас",
        transcription: "ʃiːz ˈtraɪɪŋ tə kəʊp wɪð ðiːz ˈdɪfɪkəltiz naʊ",
        translation: "She's trying to cope with these difficulties now",
      },
      {
        id: 61,
        expression: "Она пытается преодолеть все эти трудности сейчас",
        transcription: "ʃiːz ˈtraɪɪŋ tʊ ˌəʊvəˈkʌm ɔːl ðiːz ˈdɪfɪkəltiz naʊ",
        translation: "She's trying to overcome all these difficulties now",
      },
      {
        id: 62,
        expression: "Он пытается справиться с этими проблемами сейчас",
        transcription: "hiːz ˈtraɪɪŋ tə kəʊp wɪð ðiːz ˈprɒbləmz naʊ",
        translation: "He's trying to cope with these problems now",
      },
      {
        id: 63,
        expression: "У него есть специальное предложение для тебя",
        transcription: "hi həz ə ˈspɛʃəl ˈɒfə fə juː",
        translation: "He has a special offer for you",
      },
      {
        id: 64,
        expression: "У него есть очень привлекательное предложение для тебя",
        transcription: "hi həz ə ˈvɛri əˈtræktɪv ˈɒfə fə juː",
        translation: "He has a very attractive offer for you",
      },
      {
        id: 65,
        expression: "У меня есть несколько предложений для тебя",
        transcription: "aɪ həv səm ˈɒfəz fə juː",
        translation: "I have some offers for you",
      },
      {
        id: 66,
        expression: "Он обещает сделать это сейчас",
        transcription: "hi ˈprɒmɪsɪz tə dʊ ɪt naʊ",
        translation: "He promises to do it now",
      },
      {
        id: 67,
        expression: "Он хочет сделать это сейчас",
        transcription: "hi wɒnts tə dʊ ɪt naʊ",
        translation: "He wants to do it now",
      },
      {
        id: 68,
        expression: "Я обещаю сделать это сейчас",
        transcription: "aɪ ˈprɒmɪs tə dʊ ɪt naʊ",
        translation: "I promise to do it now",
      },
      {
        id: 69,
        expression: "У нас сейчас перерыв",
        transcription: "wɪə ˈhævɪŋ ə breɪk naʊ",
        translation: "We're having a break now",
      },
      {
        id: 70,
        expression: "У них сейчас перерыв",
        transcription: "ðeə ˈhævɪŋ ə breɪk naʊ",
        translation: "They're having a break now",
      },
      {
        id: 71,
        expression: "Еда восхитительная",
        transcription: "ðə fuːd teɪsts dɪˈlɪʃəs",
        translation: "The food tastes delicious",
      },
      {
        id: 72,
        expression: "Суп вкусный",
        transcription: "ðə suːp teɪsts gʊd",
        translation: "The soup tastes good",
      },
      {
        id: 73,
        expression: "Моя еда действительно вкусная",
        transcription: "maɪ fuːd teɪsts ˈrɪəli dɪˈlɪʃəs",
        translation: "My food tastes really delicious",
      },
      {
        id: 74,
        expression: "Я думаю, что он действительно этого заслуживает",
        transcription: "aɪ θɪŋk ðət hi ˈrɪəli dɪˈzɜːvz ɪt",
        translation: "I think that he really deserves it",
      },
      {
        id: 75,
        expression: "Я думаю, он действительно заслуживает этого",
        transcription: "aɪ θɪŋk hi ˈrɪəli dɪˈzɜːvz ɪt",
        translation: "I think he really deserves it",
      },
      {
        id: 76,
        expression: "Я знаю, что он действительно этого заслуживает",
        transcription: "aɪ nəʊ ðət hi ˈrɪəli dɪˈzɜːvz ɪt",
        translation: "I know that he really deserves it",
      },
      {
        id: 77,
        expression: "Он думает, тебе не следует делать это сейчас",
        transcription: "hi θɪŋks jʊ ʃʊdnt dʊ ɪt naʊ",
        translation: "He thinks you shouldn't do it now",
      },
      {
        id: 78,
        expression: "Он думает, тебе не нужно делать это сейчас",
        transcription: "hi θɪŋks jʊ dəʊnt niːd tə dʊ ɪt naʊ",
        translation: "He thinks you don't need to do it now",
      },
      {
        id: 79,
        expression: "Он думает, тебе следует сделать это сейчас",
        transcription: "hi θɪŋks jʊ ʃəd dʊ ɪt naʊ",
        translation: "He thinks you should do it now",
      },
      {
        id: 80,
        expression: "Игра такая захватывающая сейчас",
        transcription: "ðə geɪm z səʊ ˈθrɪlɪŋ naʊ",
        translation: "The game is so thrilling now",
      },
      {
        id: 81,
        expression: "Игра такая утомительная сейчас",
        transcription: "ðə geɪm z səʊ ˈtaɪərɪŋ naʊ",
        translation: "The game is so tiring now",
      },
      {
        id: 82,
        expression: "Игра такая скучная сейчас",
        transcription: "ðə geɪm z səʊ ˈbɔːrɪŋ naʊ",
        translation: "The game is so boring now",
      },
      {
        id: 83,
        expression: "Что кажется более важным сейчас?",
        transcription: "wɒt siːmz mɔːr ɪmˈpɔːtənt naʊ?",
        translation: "What seems more important now?",
      },
      {
        id: 84,
        expression: "Что кажется более полезным сейчас?",
        transcription: "wɒt siːmz mɔː ˈjuːsfʊl naʊ?",
        translation: "What seems more useful now?",
      },
      {
        id: 85,
        expression: "Кто кажется более важным сейчас?",
        transcription: "huː siːmz mɔːr ɪmˈpɔːtənt naʊ?",
        translation: "Who seems more important now?",
      },
      {
        id: 86,
        expression: "Та еда очень плохая на вкус",
        transcription: "ðæt fuːd teɪsts ˈvɛri bæd",
        translation: "That food tastes very bad",
      },
      {
        id: 87,
        expression: "У меня сейчас есть вопрос",
        transcription: "aɪ həv ə ˈkwɛsʧən naʊ",
        translation: "I have a question now",
      },
      {
        id: 88,
        expression: "Я пытаюсь справиться с этими трудностями сейчас",
        transcription: "aɪm ˈtraɪɪŋ tə kəʊp wɪð ðiːz ˈdɪfɪkəltiz naʊ",
        translation: "I'm trying to cope with these difficulties now",
      },
      {
        id: 89,
        expression:
          "У нее сейчас раскалывающаяся головная боль из-за этой громкой музыки",
        transcription:
          "ʃi həz ə ˈsplɪtɪŋ ˈhɛdeɪk naʊ bɪˈkəz əv ðɪs laʊd ˈmjuːzɪk",
        translation:
          "She has a splitting headache now because of this loud music",
      },
      {
        id: 90,
        expression: "Я твердо верю, что это возможно сейчас",
        transcription: "aɪ ˈfɜːmli bɪˈliːv ðət ɪts ˈpɒsəbl naʊ",
        translation: "I firmly believe that it's possible now",
      },
      {
        id: 91,
        expression: "Мне нужно сделать это прямо сейчас",
        transcription: "aɪ niːd tə dʊ ɪt raɪt naʊ",
        translation: "I need to do it right now",
      },
      {
        id: 92,
        expression: "Он сейчас справляется с этим довольно хорошо",
        transcription: "hiːz ˈkəʊpɪŋ wɪð ɪt ˈprɪti wɛl naʊ",
        translation: "He's coping with it pretty well now",
      },
      {
        id: 93,
        expression: "Он видит это сейчас",
        transcription: "hi siːz ɪt naʊ",
        translation: "He sees it now",
      },
      {
        id: 94,
        expression: "О чем ты думаешь сейчас?",
        transcription: "wɒt ə jʊ ˈθɪŋkɪŋ əˈbaʊt naʊ?",
        translation: "What are you thinking about now?",
      },
      {
        id: 95,
        expression: "Все сейчас зависит от меня",
        transcription: "ˈɛvrɪθɪŋ dɪˈpɛndz ɒn mi naʊ",
        translation: "Everything depends on me now",
      },
      {
        id: 96,
        expression: "Я думаю о моем следующем отпуске сейчас",
        transcription: "aɪm ˈθɪŋkɪŋ əˈbaʊt maɪ nɛkst ˈhɒlədeɪ naʊ",
        translation: "I'm thinking about my next holiday now",
      },
      {
        id: 97,
        expression: "Он не осознает важность этого события сейчас",
        transcription: "hi dʌznt ˈrɪəlaɪz ði ɪmˈpɔːtəns əv ðɪs ɪˈvɛnt naʊ",
        translation: "He doesn't realise the importance of this event now",
      },
      {
        id: 98,
        expression: "Я твердо верю, что это возможно сделать сейчас",
        transcription: "aɪ ˈfɜːmli bɪˈliːv ðət ɪts ˈpɒsəbl tə dʊ naʊ",
        translation: "I firmly believe that it's possible to do now",
      },
      {
        id: 99,
        expression: "Я думаю, тебе следует сделать это прямо сейчас",
        transcription: "aɪ θɪŋk jʊ ʃəd dʊ ɪt raɪt naʊ",
        translation: "I think you should do it right now",
      },
      {
        id: 100,
        expression: "Я думаю о его предложении сейчас",
        transcription: "aɪ θɪŋk əˈbaʊt ɪz ˈɒfə naʊ",
        translation: "I think about his offer now",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>State verbs</h3>
<p>В английском языке существуют глаголы-состояния, которые обычно не употребляются в Present Continuous.Для обозначения текущего действия или процесса их употребляют в форме Present Simple. Чаще всего глаголы-состояния означивают какое-то внутреннее состояние человека:</p>
<p>I really want it now - Я действительно хочу этого сейчас.</p>
<p>He sees you now - Он видит тебя сейчас.</p>
</div>`,
    },
  ],
];
