export const wordsLesson268 = [
  [
    {
      id: 1,
      word: 'fool about / fool around',
      transcription: 'fuːl əˈbaʊt / fuːl əˈraʊnd',
      translation: 'валять дурака, дурачиться',
    },
    {
      id: 2,
      word: 'zoom in',
      transcription: 'zuːm ɪn',
      translation: 'увеличивать изображение',
    },
    {
      id: 3,
      word: 'zoom out',
      transcription: 'zuːm aʊt',
      translation: 'уменьшать изображение',
    },
    {
      id: 4,
      word: 'hear from',
      transcription: 'hɪə frɒm',
      translation: 'слышать от, получать вести от',
    },
    {
      id: 5,
      word: 'grow on',
      transcription: 'ɡrəʊ ɒn',
      translation: 'нравиться все больше',
    },
    {
      id: 6,
      word: 'ring back',
      transcription: 'rɪŋ bæk',
      translation: 'перезвонить',
    },
    {
      id: 7,
      word: 'ask around',
      transcription: 'ɑːsk əˈraʊnd',
      translation: 'спрашивать вокруг',
    },
    {
      id: 8,
      word: 'keep away from',
      transcription: 'kiːp əˈweɪ frɒm',
      translation: 'держаться подальше от',
    },
    {
      id: 9,
      word: 'phone back',
      transcription: 'fəʊn bæk',
      translation: 'перезванивать',
    },
    {
      id: 10,
      word: 'eat in',
      transcription: 'iːt ɪn',
      translation: 'питаться дома',
    },
    {
      id: 11,
      word: 'tear up',
      transcription: 'teər ʌp',
      translation: 'разрывать',
    },
    {
      id: 12,
      word: 'boot up',
      transcription: 'buːt ʌp',
      translation: 'загружать',
    },
    {
      id: 13,
      word: 'laze about',
      transcription: 'leɪz əˈbaʊt',
      translation: 'бездельничать',
    },
    {
      id: 14,
      word: 'lie down',
      transcription: 'laɪ daʊn',
      translation: 'прилечь',
    },
    {
      id: 15,
      word: 'tidy up',
      transcription: 'ˈtaɪdi ʌp',
      translation: 'убирать',
    },
    {
      id: 16,
      word: 'call by',
      transcription: 'kɔːl baɪ',
      translation: 'зайти',
    },
    {
      id: 17,
      word: 'ardent',
      transcription: 'ˈɑːdənt',
      translation: 'ярый',
    },
    {
      id: 18,
      word: 'despair',
      transcription: 'dɪsˈpeə',
      translation: 'отчаяние',
    },
    {
      id: 19,
      word: 'edge',
      transcription: 'ɛʤ',
      translation: 'край',
    },
    {
      id: 20,
      word: 'examination',
      transcription: 'ɪɡˌzæmɪˈneɪʃᵊn',
      translation: 'экзамен',
    },
    {
      id: 21,
      word: 'fabulously',
      transcription: 'ˈfæbjʊləsli',
      translation: 'баснословно',
    },
    {
      id: 22,
      word: 'meaningful',
      transcription: 'ˈmiːnɪŋfʊl',
      translation: 'осмысленный',
    },
    {
      id: 23,
      word: 'observer',
      transcription: 'əbˈzɜːvə',
      translation: 'наблюдатель',
    },
    {
      id: 24,
      word: 'onlooker',
      transcription: 'ˈɒnˌlʊkə',
      translation: 'наблюдатель',
    },
    {
      id: 25,
      word: 'phenomenally',
      transcription: 'fɪˈnɒmɪnli',
      translation: 'феноменально',
    },
    {
      id: 26,
      word: 'notice',
      transcription: 'ˈnəʊtɪs',
      translation: 'уведомление',
    },
  ],
];
