export const contentLesson56 = [
  [
    {
      title: "Урок 56. Present Simple: сложные подлежащие + глагол to be",
    },
    [
      {
        id: 1,
        expression: "Этот дом очень дорогой",
        transcription: "ðɪs haʊs ɪz ˈvɛri ɪksˈpɛnsɪv",
        translation: "This house is very expensive",
      },
      {
        id: 2,
        expression: "Эти документы действительно полезны",
        transcription: "ðiːz ˈdɒkjʊmənts ə ˈrɪəli ˈjuːsfʊl",
        translation: "These documents are really useful",
      },
      {
        id: 3,
        expression: "У этого костюма хорошая цена",
        transcription: "ðɪs sjuːt həz ə gʊd praɪs",
        translation: "This suit has a good price",
      },
      {
        id: 4,
        expression: "Это очень модный костюм",
        transcription: "ɪts ə ˈvɛri ˈfæʃnəbl sjuːt",
        translation: "It's a very fashionable suit",
      },
      {
        id: 5,
        expression: "Мои друзья действительно шокированы",
        transcription: "maɪ frɛndz ə ˈrɪəli ʃɒkt",
        translation: "My friends are really shocked",
      },
      {
        id: 6,
        expression: "Эта еда очень дорогая",
        transcription: "ðɪs fuːd z ˈvɛri ɪksˈpɛnsɪv",
        translation: "This food is very expensive",
      },
      {
        id: 7,
        expression: "Вся эта одежда по распродаже",
        transcription: "ɔːl ðiːz kləʊðz ər ɒn seɪl",
        translation: "All these clothes are on sale",
      },
      {
        id: 8,
        expression: "Эти билеты очень дорогие",
        transcription: "ðiːz ˈtɪkɪts ə ˈvɛri ɪksˈpɛnsɪv",
        translation: "These tickets are very expensive",
      },
      {
        id: 9,
        expression: "Тот мальчик очень расстроен",
        transcription: "ðæt bɔɪ z ˈvɛri ʌpˈsɛt",
        translation: "That boy is very upset",
      },
      {
        id: 10,
        expression: "Ее брат ходит в колледж",
        transcription: "hə ˈbrʌðə gəʊz tə ˈkɒlɪʤ",
        translation: "Her brother goes to college",
      },
      {
        id: 11,
        expression: "Этот дом очень старый",
        transcription: "ðɪs haʊs ɪz ˈvɛri əʊld",
        translation: "This house is very old",
      },
      {
        id: 12,
        expression: "Эта одежда слишком формальная",
        transcription: "ðiːz kləʊðz ə tuː ˈfɔːməl",
        translation: "These clothes are too formal",
      },
      {
        id: 13,
        expression: "Ее муж бухгалтер",
        transcription: "hə ˈhʌzbənd z ən əˈkaʊntənt",
        translation: "Her husband is an accountant",
      },
      {
        id: 14,
        expression: "Этот отель слишком дорогой",
        transcription: "ðɪs həʊˈtɛl z tuː ɪksˈpɛnsɪv",
        translation: "This hotel is too expensive",
      },
      {
        id: 15,
        expression: "Эта женщина медсестра",
        transcription: "ðɪs ˈwʊmən z ə nɜːs",
        translation: "This woman is a nurse",
      },
      {
        id: 16,
        expression: "Этот мальчик очень сильный",
        transcription: "ðɪs bɔɪ z ˈvɛri strɒŋ",
        translation: "This boy is very strong",
      },
      {
        id: 17,
        expression: "Эти лекции такие скучные",
        transcription: "ðiːz ˈlɛkʧəz ə səʊ ˈbɔːrɪŋ",
        translation: "These lectures are so boring",
      },
      {
        id: 18,
        expression: "У тебя блестящая идея",
        transcription: "jʊ həv ə ˈbrɪljənt aɪˈdɪə",
        translation: "You have a brilliant idea",
      },
      {
        id: 19,
        expression: "Этот комментарий такой длинный",
        transcription: "ðɪs ˈkɒmɛnt s səʊ lɒŋ",
        translation: "This comment is so long",
      },
      {
        id: 20,
        expression: "Эти мужчины очень разочарованы",
        transcription: "ðiːz mɛn ə ˈvɛri ˌdɪsəˈpɔɪntɪd",
        translation: "These men are very disappointed",
      },
      {
        id: 21,
        expression: "Этот ресурс чрезвычайно полезен",
        transcription: "ðɪs ˌriːˈsaʊəсiː z ɪksˈtriːmli ˈjuːsfʊl",
        translation: "This resourсe is extremely useful",
      },
      {
        id: 22,
        expression: "Это повседневное платье",
        transcription: "ɪts ə ˈkæʒjʊəl drɛs",
        translation: "It's a casual dress",
      },
      {
        id: 23,
        expression: "Эта куртка по распродаже",
        transcription: "ðɪs ˈʤækɪt s ɒn seɪl",
        translation: "This jacket is on sale",
      },
      {
        id: 24,
        expression: "Его жена действительно удивлена",
        transcription: "hɪz waɪf s ˈrɪəli səˈpraɪzd",
        translation: "His wife is really surprised",
      },
      {
        id: 25,
        expression: "Все эти материалы чрезвычайно полезны",
        transcription: "ɔːl ðiːz məˈtɪərɪəlz ər ɪksˈtriːmli ˈjuːsfʊl",
        translation: "All these materials are extremely useful",
      },
      {
        id: 26,
        expression: "Этот курс очень эффективен",
        transcription: "ðɪs kɔːs ɪz ˈvɛri ɪˈfɛktɪv",
        translation: "This course is very effective",
      },
      {
        id: 27,
        expression: "Этот мужчина очень разочарован",
        transcription: "ðɪs mæn z ˈvɛri ˌdɪsəˈpɔɪntɪd",
        translation: "This man is very disappointed",
      },
      {
        id: 28,
        expression: "Это платье слишком неформальное",
        transcription: "ðɪs drɛs ɪz tuː ɪnˈfɔːml",
        translation: "This dress is too informal",
      },
      {
        id: 29,
        expression: "Твой дом такой красивый",
        transcription: "jə haʊs ɪz səʊ ˈbjuːtəfʊl",
        translation: "Your house is so beautiful",
      },
      {
        id: 30,
        expression: "Эти женщины коллеги",
        transcription: "ðiːz ˈwɪmɪn ə ˈkɒliːgz",
        translation: "These women are colleagues",
      },
      {
        id: 31,
        expression: "Этот эпизод очень интересный",
        transcription: "ðɪs ˈɛpɪsəʊd z ˈvɛri ˈɪntrɪstɪŋ",
        translation: "This episode is very interesting",
      },
      {
        id: 32,
        expression: "Те студенты очень трудолюбивы",
        transcription: "ðəʊz ˈstjuːdənts ə ˈvɛri ˈhɑːdˌwɜːkɪŋ",
        translation: "Those students are very hard-working",
      },
      {
        id: 33,
        expression: "Это место такое красивое",
        transcription: "ðɪs pleɪs ɪz səʊ ˈbjuːtəfʊl",
        translation: "This place is so beautiful",
      },
      {
        id: 34,
        expression: "Этот певец очень знаменитый",
        transcription: "ðɪs ˈsɪŋə z ˈvɛri ˈfeɪməs",
        translation: "This singer is very famous",
      },
      {
        id: 35,
        expression: "Эти комментарии такие смешные",
        transcription: "ðiːz ˈkɒmɛnts ə səʊ ˈfʌni",
        translation: "These comments are so funny",
      },
      {
        id: 36,
        expression: "Эта девочка очень грустная",
        transcription: "ðɪs gɜːl z ˈvɛri sæd",
        translation: "This girl is very sad",
      },
      {
        id: 37,
        expression: "Эти студенты очень ленивые",
        transcription: "ðiːz ˈstjuːdənts ə ˈvɛri ˈleɪzi",
        translation: "These students are very lazy",
      },
      {
        id: 38,
        expression: "Эта лампа такая яркая",
        transcription: "ðɪs læmp s səʊ braɪt",
        translation: "This lamp is so bright",
      },
      {
        id: 39,
        expression:
          "Его хобби - это езда на велосипеде, дайвинг и катание на лыжах",
        transcription: "hɪz ˈhɒbiz ə ˈsaɪklɪŋ, ˈdaɪvɪŋ ənd ˈskiːɪŋ",
        translation: "His hobbies are cycling, diving and skiing",
      },
      {
        id: 40,
        expression: "Эта вещь слишком дорогая для них",
        transcription: "ðɪs θɪŋ z tuː ɪksˈpɛnsɪv fə ðɛm",
        translation: "This thing is too expensive for them",
      },
      {
        id: 41,
        expression: "Моей сестре пятнадцать",
        transcription: "maɪ ˈsɪstə z ˈfɪfˈtiːn",
        translation: "My sister is fifteen",
      },
      {
        id: 42,
        expression: "Его уровень очень низкий",
        transcription: "hɪz ˈlɛvl z ˈvɛri ləʊ",
        translation: "His level is very low",
      },
      {
        id: 43,
        expression: "Эти воры сейчас в тюрьме",
        transcription: "ðiːz θiːvz ər ɪn ðə ˈprɪzn naʊ",
        translation: "These thieves are in the prison now",
      },
      {
        id: 44,
        expression: "Я думаю, этот мальчик настоящий гений",
        transcription: "aɪ θɪŋk ðɪs bɔɪ z ə rɪəl ˈʤiːniəs",
        translation: "I think this boy is a real genius",
      },
      {
        id: 45,
        expression: "Мое главное хобби - это изучение иностранных языков",
        transcription: "maɪ meɪn ˈhɒbi z ˈlɜːnɪŋ ˈfɒrɪn ˈlæŋgwɪʤɪz",
        translation: "My main hobby is learning foreign languages",
      },
      {
        id: 46,
        expression: "Моя мама на кухне сейчас",
        transcription: "maɪ ˈmʌðə z ɪn ðə ˈkɪʧɪn naʊ",
        translation: "My mother is in the kitchen now",
      },
      {
        id: 47,
        expression:
          "Я вижу, что это приложение чрезвычайно полезно для изучающих английский язык, потому что оно действительно помогает",
        transcription:
          "aɪ siː ðət ðɪs ˌæplɪˈkeɪʃ(ə)n z ɪksˈtriːmli ˈjuːsfʊl fər ˈɪŋglɪʃ ˈlɜːnəz bɪˈkəz ɪt ˈrɪəli hɛlps",
        translation:
          "I see that this application is extremely useful for English learners because it really helps",
      },
      {
        id: 48,
        expression: "Плавание - мое хобби",
        transcription: "ˈswɪmɪŋ z maɪ ˈhɒbi",
        translation: "Swimming is my hobby",
      },
      {
        id: 49,
        expression: "Эти выражения являются новыми для нас",
        transcription: "ðiːz ɪksˈprɛʃənz ə njuː fər ʌs",
        translation: "These expressions are new for us",
      },
      {
        id: 50,
        expression: "У твоего друга хорошее чувство юмора",
        transcription: "jə frɛnd həz ə gʊd sɛns əv ˈhjuːmə",
        translation: "Your friend has a good sense of humour",
      },
      {
        id: 51,
        expression: "Мои коллеги всегда помогают мне, когда мне нужно это",
        transcription: "maɪ ˈkɒliːgz ˈɔːlweɪz hɛlp mi wɛn aɪ niːd ɪt",
        translation: "My colleagues always help me when I need it",
      },
      {
        id: 52,
        expression: "Этот вор в тюрьме",
        transcription: "ðɪs θiːf s ɪn ˈprɪzn",
        translation: "This thief is in prison",
      },
      {
        id: 53,
        expression: "Эти результаты отличные",
        transcription: "ðiːz rɪˈzʌlts ər ˈɛksələnt",
        translation: "These results are excellent",
      },
      {
        id: 54,
        expression: "Эта еда дорогая, но она очень хорошая",
        transcription: "ðɪs fuːd z ɪksˈpɛnsɪv bət ɪts ˈvɛri gʊd",
        translation: "This food is expensive but it's very good",
      },
      {
        id: 55,
        expression: "У моих коллег хорошее чувство юмора",
        transcription: "maɪ ˈkɒliːgz həv ə gʊd sɛns əv ˈhjuːmə",
        translation: "My colleagues have a good sense of humour",
      },
      {
        id: 56,
        expression: "Эта коробка действительно тяжелая",
        transcription: "ðɪs bɒks ɪz ˈrɪəli ˈhɛvi",
        translation: "This box is really heavy",
      },
      {
        id: 57,
        expression: "Эти видеоуроки чрезвычайно полезны",
        transcription: "ðiːz ˈvɪdɪəʊ ˈlɛsnz ər ɪksˈtriːmli ˈjuːsfʊl",
        translation: "These video lessons are extremely useful",
      },
      {
        id: 58,
        expression: "Мое полотенце в ванной",
        transcription: "maɪ ˈtaʊəl z ɪn ðə ˈbɑːθru(ː)m",
        translation: "My towel is in the bathroom",
      },
      {
        id: 59,
        expression: "Моя чашка на кухне",
        transcription: "maɪ kʌp s ɪn ðə ˈkɪʧɪn",
        translation: "My cup is in the kitchen",
      },
      {
        id: 60,
        expression: "Моя чашка на столе",
        transcription: "maɪ kʌp s ɒn ðə ˈteɪbl",
        translation: "My cup is on the table",
      },
      {
        id: 61,
        expression: "Я думаю, английский легкий",
        transcription: "aɪ θɪŋk ˈɪŋglɪʃ ɪz ˈiːzi",
        translation: "I think English is easy",
      },
      {
        id: 62,
        expression: "Они думают, английский легкий",
        transcription: "ðeɪ θɪŋk ˈɪŋglɪʃ ɪz ˈiːzi",
        translation: "They think English is easy",
      },
      {
        id: 63,
        expression: "Она думает, что английский легкий",
        transcription: "ʃi θɪŋks ðət ˈɪŋglɪʃ ɪz ˈiːzi",
        translation: "She thinks that English is easy",
      },
      {
        id: 64,
        expression: "Я согласен, что деньги очень важны",
        transcription: "aɪ əˈgriː ðət ˈmʌni z ˈvɛri ɪmˈpɔːtənt",
        translation: "I agree that money is very important",
      },
      {
        id: 65,
        expression: "Я знаю, что деньги очень важны",
        transcription: "aɪ nəʊ ðət ˈmʌni z ˈvɛri ɪmˈpɔːtənt",
        translation: "I know that money is very important",
      },
      {
        id: 66,
        expression: "Она согласна, что деньги очень важны",
        transcription: "ʃi əˈgriːz ðət ˈmʌni z ˈvɛri ɪmˈpɔːtənt",
        translation: "She agrees that money is very important",
      },
      {
        id: 67,
        expression: "Мои хобби - готовка, бег трусцой и путешествия",
        transcription: "maɪ ˈhɒbiz ə ˈkʊkɪŋ, ˈʤɒgɪŋ ənd ˈtrævlɪŋ",
        translation: "My hobbies are cooking, jogging and travelling",
      },
      {
        id: 68,
        expression: "Ее хобби - плавание и чтение",
        transcription: "hə ˈhɒbiz ə ˈswɪmɪŋ ənd ˈriːdɪŋ",
        translation: "Her hobbies are swimming and reading",
      },
      {
        id: 69,
        expression: "Ее хобби - готовка",
        transcription: "hə ˈhɒbi z ˈkʊkɪŋ",
        translation: "Her hobby is cooking",
      },
      {
        id: 70,
        expression: "Английский также интересен ей",
        transcription: "ˈɪŋglɪʃ ɪz ˈɔːlsəʊ ˈɪntrɪstɪŋ fə hə",
        translation: "English is also interesting for her",
      },
      {
        id: 71,
        expression: "Это также интересно мне",
        transcription: "ɪt ˈɔːlsəʊ ˈɪntrɪstɪŋ fə miː",
        translation: "It also interesting for me",
      },
      {
        id: 72,
        expression: "Английский также очень важен для нас",
        transcription: "ˈɪŋglɪʃ ɪz ˈɔːlsəʊ ˈvɛri ɪmˈpɔːtənt fər ʌs",
        translation: "English is also very important for us",
      },
      {
        id: 73,
        expression: "Изучение английского - мое хобби",
        transcription: "ˈlɜːnɪŋ ˈɪŋglɪʃ ɪz maɪ ˈhɒbi",
        translation: "Learning English is my hobby",
      },
      {
        id: 74,
        expression: "Изучение английского языка и готовка - мои хобби",
        transcription: "ˈlɜːnɪŋ ˈɪŋglɪʃ ənd ˈkʊkɪŋ ə maɪ ˈhɒbiz",
        translation: "Learning English and cooking are my hobbies",
      },
      {
        id: 75,
        expression: "Мое хобби - изучение английского",
        transcription: "maɪ ˈhɒbi z ˈlɜːnɪŋ ˈɪŋglɪʃ",
        translation: "My hobby is learning English",
      },
      {
        id: 76,
        expression: "Эти выражения являются новыми для меня",
        transcription: "ðiːz ɪksˈprɛʃənz ə njuː fə miː",
        translation: "These expressions are new for me",
      },
      {
        id: 77,
        expression: "Многие выражения являются новыми для меня",
        transcription: "ˈmɛni ɪksˈprɛʃənz ə njuː fə miː",
        translation: "Many expressions are new for me",
      },
      {
        id: 78,
        expression: "То выражение является новым для меня",
        transcription: "ðæt ɪksˈprɛʃən z njuː fə miː",
        translation: "That expression is new for me",
      },
      {
        id: 79,
        expression: "Эта еда хороша на вкус",
        transcription: "ðɪs fuːd həz ə gʊd teɪst / ðɪs fuːd teɪsts gʊd",
        translation: "This food has a good taste / This food tastes good",
      },
      {
        id: 80,
        expression: "Эти яблоки хороши на вкус",
        transcription: "ðiːz əˈplaɪz həv gʊd teɪst / ðiːz ˈæplz teɪst gʊd",
        translation: "These applies have good taste / These apples taste good",
      },
      {
        id: 81,
        expression: "Это апельсин очень хорош на вкус",
        transcription: "ðɪs ˈɒrɪnʤ həz ə gʊd teɪst",
        translation: "This orange has a good taste",
      },
      {
        id: 82,
        expression: "Я часто слышу, что испанский язык также легкий",
        transcription: "aɪ ˈɒf(ə)n hɪə ðət ˈspænɪʃ ɪz ˈɔːlsəʊ ˈiːzi",
        translation: "I often hear that Spanish is also easy",
      },
      {
        id: 83,
        expression: "Она часто слышит, что испанский язык действительно легкий",
        transcription: "ʃi ˈɒf(ə)n hɪəz ðət ˈspænɪʃ ɪz ˈrɪəli ˈiːzi",
        translation: "She often hears that Spanish is really easy",
      },
      {
        id: 84,
        expression: "Они часто слышат, что английский также легкий",
        transcription: "ðeɪ ˈɒf(ə)n hɪə ðət ˈɪŋglɪʃ ɪz ˈɔːlsəʊ ˈiːzi",
        translation: "They often hear that English is also easy",
      },
      {
        id: 85,
        expression: "Мои хобби - плавание, теннис и английский язык",
        transcription: "maɪ ˈhɒbiz ə ˈswɪmɪŋ, ˈtɛnɪs ənd ˈɪŋglɪʃ",
        translation: "My hobbies are swimming, tennis and English",
      },
      {
        id: 86,
        expression: "Мои хобби - теннис и английский язык",
        transcription: "maɪ ˈhɒbiz ə ˈtɛnɪs ənd ˈɪŋglɪʃ",
        translation: "My hobbies are tennis and English",
      },
      {
        id: 87,
        expression: "Мое хобби - это футбол",
        transcription: "maɪ ˈhɒbi z ˈfʊtbɔːl",
        translation: "My hobby is football",
      },
      {
        id: 88,
        expression: "Люди обычно говорят, что китайский очень сложный",
        transcription: "ˈpiːpl ˈjuːʒʊəli seɪ ðət ˌʧaɪˈniːz ɪz ˈvɛri ˈdɪfɪkəlt",
        translation: "People usually say that Chinese is very difficult",
      },
      {
        id: 89,
        expression: "Эта игрушка в спальне",
        transcription: "ðɪs tɔɪ z ɪn ðə ˈbɛdru(ː)m",
        translation: "This toy is in the bedroom",
      },
      {
        id: 90,
        expression:
          "Некоторые люди говорят, что деньги - это все, но я так не думаю",
        transcription:
          "sʌm ˈpiːpl seɪ ðət ˈmʌni z ˈɛvrɪθɪŋ bət aɪ dəʊnt θɪŋk səʊ",
        translation:
          "Some people say that money is everything but I don't think so",
      },
      {
        id: 91,
        expression: "Мое хобби - изучение иностранных языков",
        transcription: "maɪ ˈhɒbi z ˈlɜːnɪŋ ˈfɒrɪn ˈlæŋgwɪʤɪz",
        translation: "My hobby is learning foreign languages",
      },
      {
        id: 92,
        expression: "Я думаю, что время - это больше, чем деньги",
        transcription: "aɪ θɪŋk ðət taɪm z mɔː ðən ˈmʌni",
        translation: "I think that time is more than money",
      },
      {
        id: 93,
        expression: "Эта цена очень высока",
        transcription: "ðɪs praɪs ɪz ˈvɛri haɪ",
        translation: "This price is very high",
      },
      {
        id: 94,
        expression: "Это выражение новое для меня",
        transcription: "ðɪs ɪksˈprɛʃən z njuː fə miː",
        translation: "This expression is new for me",
      },
      {
        id: 95,
        expression: "Английский язык - мое основное хобби",
        transcription: "ˈɪŋglɪʃ ɪz maɪ meɪn ˈhɒbi",
        translation: "English is my main hobby",
      },
      {
        id: 96,
        expression: "Мои хобби - это рисование, шахматы и иностранные языки",
        transcription: "maɪ ˈhɒbiz ə ˈdrɔːɪŋ, ʧɛs ənd ˈfɒrɪn ˈlæŋgwɪʤɪz",
        translation: "My hobbies are drawing, chess and foreign languages",
      },
      {
        id: 97,
        expression: "Мой отец в гостиной",
        transcription: "maɪ ˈfɑːðə z ɪn ðə ˈlɪvɪŋ ruːm",
        translation: "My father is in the living room",
      },
      {
        id: 98,
        expression: "Этот мальчик гений",
        transcription: "ðɪs bɔɪ z ə ˈʤiːniəs",
        translation: "This boy is a genius",
      },
      {
        id: 99,
        expression: "Эта компания очень большая и мы все видим ее успех",
        transcription: "ðɪs ˈkʌmpəni z ˈvɛri bɪg ənd wi ɔːl siː ɪts səkˈsɛs",
        translation: "This company is very big and we all see its success",
      },
      {
        id: 100,
        expression: "Вся эта информация чрезвычайно полезна для меня",
        transcription: "ɔːl ðɪs ˌɪnfəˈmeɪʃən z ɪksˈtriːmli ˈjuːsfʊl fə miː",
        translation: "All this information is extremely useful for me",
      },
      {
        id: 101,
        expression: "Я думаю, мой друг прав",
        transcription: "aɪ θɪŋk maɪ frɛnd z raɪt",
        translation: "I think my friend is right",
      },
      {
        id: 102,
        expression: "Ее основное хобби - садоводство",
        transcription: "hə meɪn ˈhɒbi z ˈgɑːdnɪŋ",
        translation: "Her main hobby is gardening",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>is vs are</h3>
<p>Если в предложении нет личного местоимения, и в Present Simple нужно выбрать, добавлять is или are, то определяем, какое местоимение подходит к подлежащему:</p>
<p>This car is very expensive - Эта машина очень дорогая.</p>
<p>These documents are really important - Эти документы действительно важны.</p>
</div>`,
    },
  ],
];
