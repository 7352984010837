export const contentLesson59 = [
  [
    {
      title: "Урок 59. Present Simple + Глагол to be: Negative Form",
    },
    [
      {
        id: 1,
        expression: "Я не дома сейчас",
        transcription: "aɪm nɒt ət həʊm naʊ",
        translation: "I'm not at home now",
      },
      {
        id: 2,
        expression: "Мы не в твоей группе",
        transcription: "wi ɑːnt ɪn jə gruːp",
        translation: "We aren't in your group",
      },
      {
        id: 3,
        expression: "Это не плохая идея",
        transcription: "ɪt ˈɪznt ə bæd aɪˈdɪə",
        translation: "It isn't a bad idea",
      },
      {
        id: 4,
        expression: "Это не опасное место",
        transcription: "ɪt ˈɪznt ə ˈdeɪnʤrəs pleɪs",
        translation: "It isn't a dangerous place",
      },
      {
        id: 5,
        expression: "Это не ее окончательный ответ",
        transcription: "ɪt ˈɪznt hə ˈfaɪnl ˈɑːnsə",
        translation: "It isn't her final answer",
      },
      {
        id: 6,
        expression: "Я не интересуюсь этим",
        transcription: "aɪm nɒt ˈɪntrɪstɪd ɪn ɪt",
        translation: "I'm not interested in it",
      },
      {
        id: 7,
        expression: "Это не новая машина",
        transcription: "ɪt ˈɪznt ə njuː kɑː",
        translation: "It isn't a new car",
      },
      {
        id: 8,
        expression: "Я не в кафе сейчас",
        transcription: "aɪm nɒt ɪn ə ˈkæfeɪ naʊ",
        translation: "I'm not in a cafe now",
      },
      {
        id: 9,
        expression: "Он совсем не устал",
        transcription: "hi ˈɪznt ˈtaɪəd ət ɔːl",
        translation: "He isn't tired at all",
      },
      {
        id: 10,
        expression: "Он не интересуется политикой",
        transcription: "hi ˈɪznt ˈɪntrɪstɪd ɪn ˈpɒlɪtɪks",
        translation: "He isn't interested in politics",
      },
      {
        id: 11,
        expression: "Это не высокий уровень",
        transcription: "ɪt ˈɪznt ə haɪ ˈlɛvl",
        translation: "It isn't a high level",
      },
      {
        id: 12,
        expression: "Она не интересуется экономикой",
        transcription: "ʃi ˈɪznt ˈɪntrɪstɪd ɪn ˌiːkəˈnɒmɪks",
        translation: "She isn't interested in economics",
      },
      {
        id: 13,
        expression: "Это нелегкий тест для нас",
        transcription: "ɪt ˈɪznt ən ˈiːzi tɛst fər ʌs",
        translation: "It isn't an easy test for us",
      },
      {
        id: 14,
        expression: "Это не хорошая идея",
        transcription: "ɪt ˈɪznt ə gʊd aɪˈdɪə",
        translation: "It isn't a good idea",
      },
      {
        id: 15,
        expression: "Я совсем не устал",
        transcription: "aɪm nɒt ˈtaɪəd ət ɔːl",
        translation: "I'm not tired at all",
      },
      {
        id: 16,
        expression: "Она не художник",
        transcription: "ʃi ˈɪznt ən ˈɑːtɪst",
        translation: "She isn't an artist",
      },
      {
        id: 17,
        expression: "Я не рад этому",
        transcription: "aɪm nɒt ˈhæpi əˈbaʊt ɪt",
        translation: "I'm not happy about it",
      },
      {
        id: 18,
        expression: "Он не писатель",
        transcription: "hi ˈɪznt ə ˈraɪtə",
        translation: "He isn't a writer",
      },
      {
        id: 19,
        expression: "Я не в отпуске сейчас",
        transcription: "aɪm nɒt ɒn ˈhɒlədeɪ naʊ",
        translation: "I'm not on holiday now",
      },
      {
        id: 20,
        expression: "Это не так смешно",
        transcription: "ɪt ˈɪznt səʊ ˈfʌni",
        translation: "It isn't so funny",
      },
      {
        id: 21,
        expression: "Это не большой риск",
        transcription: "ɪt ˈɪznt ə bɪg rɪsk",
        translation: "It isn't a big risk",
      },
      {
        id: 22,
        expression: "Я не заинтересован в этих вещах",
        transcription: "aɪm nɒt ˈɪntrɪstɪd ɪt ðiːz θɪŋz",
        translation: "I'm not interested it these things",
      },
      {
        id: 23,
        expression: "Я не опаздываю",
        transcription: "aɪm nɒt leɪt",
        translation: "I'm not late",
      },
      {
        id: 24,
        expression: "Это не большая проблема",
        transcription: "ɪt ˈɪznt ə bɪg ˈprɒbləm",
        translation: "It isn't a big problem",
      },
      {
        id: 25,
        expression: "Конечно, он не сумасшедший",
        transcription: "əv kɔːs, hi ˈɪznt ˈkreɪzi",
        translation: "Of course, he isn't crazy",
      },
      {
        id: 26,
        expression: "Он не бизнесмен",
        transcription: "hi ˈɪznt ə ˈbɪznɪsmən",
        translation: "He isn't a businessman",
      },
      {
        id: 27,
        expression: "Он не профессиональный певец",
        transcription: "hi ˈɪznt ə prəˈfɛʃənl ˈdɑːnsə",
        translation: "He isn't a professional dancer",
      },
      {
        id: 28,
        expression: "Он не сумасшедший",
        transcription: "hi ˈɪznt ˈkreɪzi",
        translation: "He isn't crazy",
      },
      {
        id: 29,
        expression: "Конечно, она не сумасшедшая",
        transcription: "əv kɔːs, ʃi ˈɪznt ˈkreɪzi",
        translation: "Of course, she isn't crazy",
      },
      {
        id: 30,
        expression: "Это не глубокое озеро",
        transcription: "ɪt ˈɪznt ə diːp leɪk",
        translation: "It isn't a deep lake",
      },
      {
        id: 31,
        expression: "Они не бедные люди",
        transcription: "ðeɪ ɑːnt pʊə ˈpiːpl",
        translation: "They aren't poor people",
      },
      {
        id: 32,
        expression: "Она не медсестра",
        transcription: "ʃi ˈɪznt ə nɜːs",
        translation: "She isn't a nurse",
      },
      {
        id: 33,
        expression: "Я не уверен",
        transcription: "aɪm nɒt ʃʊə",
        translation: "I'm not sure",
      },
      {
        id: 34,
        expression: "Это не сложное упражнение для меня",
        transcription: "ɪt ˈɪznt ə ˈdɪfɪkəlt ˈɛksəsaɪz fə miː",
        translation: "It isn't a difficult exercise for me",
      },
      {
        id: 35,
        expression: "Она не бухгалтер",
        transcription: "ʃi ˈɪznt ən əˈkaʊntənt",
        translation: "She isn't an accountant",
      },
      {
        id: 36,
        expression: "Это не главная проблема",
        transcription: "ɪt ˈɪznt ðə meɪn ˈprɒbləm",
        translation: "It isn't the main problem",
      },
      {
        id: 37,
        expression: "Это не мое окончательное решение",
        transcription: "ɪt ˈɪznt maɪ ˈfaɪnl dɪˈsɪʒən",
        translation: "It isn't my final decision",
      },
      {
        id: 38,
        expression: "Она не сумасшедшая",
        transcription: "ʃi ˈɪznt mæd",
        translation: "She isn't mad",
      },
      {
        id: 39,
        expression: "Это не смешная шутка",
        transcription: "ɪt ˈɪznt ə ˈfʌni ʤəʊk",
        translation: "It isn't a funny joke",
      },
      {
        id: 40,
        expression: "Он не удивлен",
        transcription: "hi ˈɪznt səˈpraɪzd",
        translation: "He isn't surprised",
      },
      {
        id: 41,
        expression: "Он не болен",
        transcription: "hi ˈɪznt ɪl",
        translation: "He isn't ill",
      },
      {
        id: 42,
        expression: "Это не глубокая река",
        transcription: "ɪt ˈɪznt ə diːp ˈrɪvə",
        translation: "It isn't a deep river",
      },
      {
        id: 43,
        expression: "Это не простой тест",
        transcription: "ɪt ˈɪznt ə ˈsɪmpl tɛst",
        translation: "It isn't a simple test",
      },
      {
        id: 44,
        expression: "Это не главный вопрос",
        transcription: "ɪt ˈɪznt ðə meɪn ˈkwɛsʧən",
        translation: "It isn't the main question",
      },
      {
        id: 45,
        expression: "Он не в школе сейчас",
        transcription: "hi ˈɪznt ət skuːl naʊ",
        translation: "He isn't at school now",
      },
      {
        id: 46,
        expression: "Это не мой лучший результат",
        transcription: "ɪt ˈɪznt maɪ bɛst rɪˈzʌlt",
        translation: "It isn't my best result",
      },
      {
        id: 47,
        expression: "Это не распространенная ошибка среди студентов",
        transcription: "ɪt ˈɪznt ə ˈkɒmən mɪsˈteɪk əˈmʌŋ ˈstjuːdənts",
        translation: "It isn't a common mistake among students",
      },
      {
        id: 48,
        expression: "Я думаю, это не лучшее решение этой проблемы",
        transcription: "aɪ θɪŋk ɪt ˈɪznt ðə bɛst səˈluːʃən tə ðɪs ˈprɒbləm",
        translation: "I think it isn't the best solution to this problem",
      },
      {
        id: 49,
        expression: "Он не носитель языка",
        transcription: "hi ˈɪznt ə ˈneɪtɪv ˈspiːkə",
        translation: "He isn't a native speaker",
      },
      {
        id: 50,
        expression: "Это не лучший ответ на этот вопрос",
        transcription: "ɪt ˈɪznt ðə bɛst ˈɑːnsə ðɪs ˈkwɛsʧən",
        translation: "It isn't the best answer this question",
      },
      {
        id: 51,
        expression: "Я не думаю, что это лучший ответ на тот вопрос",
        transcription: "aɪ dəʊnt θɪŋk ðət ɪt ðə bɛst ˈɑːnsə ðət ˈkwɛsʧən",
        translation: "I don't think that it the best answer that question",
      },
      {
        id: 52,
        expression: "Он не женат на ней",
        transcription: "hi ˈɪznt ˈmærɪd tə hɜː",
        translation: "He isn't married to her",
      },
      {
        id: 53,
        expression: "Он думает, это не лучше",
        transcription: "hi θɪŋks ɪt ˈɪznt ˈbɛtə",
        translation: "He thinks it isn't better",
      },
      {
        id: 54,
        expression: "Мне кажется, что она не носитель языка",
        transcription: "ɪt siːmz tə mi ðət ʃi ˈɪznt ə ˈneɪtɪv ˈspiːkə",
        translation: "It seems to me that she isn't a native speaker",
      },
      {
        id: 55,
        expression: "Это не натуральный ингредиент",
        transcription: "ɪt ˈɪznt ə ˈnæʧrəl ɪnˈgriːdiənt",
        translation: "It isn't a natural ingredient",
      },
      {
        id: 56,
        expression:
          "Это не очень распространенная ошибка среди изучающих английский язык",
        transcription: "ɪt ˈɪznt ə ˈvɛri ˈkɒmən mɪsˈteɪk əˈmʌŋ ˈɪŋglɪʃ ˈlɜːnəz",
        translation: "It isn't a very common mistake among English learners",
      },
      {
        id: 57,
        expression: "Это не моя главная мечта",
        transcription: "ɪt ˈɪznt maɪ meɪn driːm",
        translation: "It isn't my main dream",
      },
      {
        id: 58,
        expression: "Я не заинтересован в этой вещи",
        transcription: "aɪm nɒt ˈɪntrɪstɪd ɪn ðɪs θɪŋ",
        translation: "I'm not interested in this thing",
      },
      {
        id: 59,
        expression: "Это неправильный глагол",
        transcription: "ɪts ən ɪˈrɛgjʊlə vɜːb",
        translation: "It's an irregular verb",
      },
      {
        id: 60,
        expression: "Это не правильный глагол",
        transcription: "ɪt ˈɪznt ə ˈrɛgjʊlə vɜːb",
        translation: "It isn't a regular verb",
      },
      {
        id: 61,
        expression: "Это правильный глагол",
        transcription: "ɪts ə ˈrɛgjʊlə vɜːb",
        translation: "It's a regular verb",
      },
      {
        id: 62,
        expression: "Мы не в одной группе",
        transcription: "wi ɑːnt ɪn ðə seɪm gruːp",
        translation: "We aren't in the same group",
      },
      {
        id: 63,
        expression: "Мы в одной группе",
        transcription: "wɪər ɪn ðə seɪm gruːp",
        translation: "We're in the same group",
      },
      {
        id: 64,
        expression: "Они не в той группе",
        transcription: "ðeɪ ɑːnt ɪn ðət gruːp",
        translation: "They aren't in that group",
      },
      {
        id: 65,
        expression: "Это не очень популярный ответ",
        transcription: "ɪt ˈɪznt ə ˈvɛri ˈpɒpjʊlər ˈɑːnsə",
        translation: "It isn't a very popular answer",
      },
      {
        id: 66,
        expression: "Это очень популярный ответ",
        transcription: "ɪts ə ˈvɛri ˈpɒpjʊlər ˈɑːnsə",
        translation: "It's a very popular answer",
      },
      {
        id: 67,
        expression: "Это не очень популярный вопрос",
        transcription: "ɪt ˈɪznt ə ˈvɛri ˈpɒpjʊlə ˈkwɛsʧən",
        translation: "It isn't a very popular question",
      },
      {
        id: 68,
        expression: "Они не в аэропорту",
        transcription: "ðeɪ ɑːnt ət ði ˈeəpɔːt",
        translation: "They aren't at the airport",
      },
      {
        id: 69,
        expression: "Она не в аэропорту",
        transcription: "ʃi ˈɪznt ət ði ˈeəpɔːt",
        translation: "She isn't at the airport",
      },
      {
        id: 70,
        expression: "Мы в аэропорту",
        transcription: "wɪər ət ði ˈeəpɔːt",
        translation: "We're at the airport",
      },
      {
        id: 71,
        expression: "К сожалению, ты не прав",
        transcription: "ʌnˈfɔːʧnɪtli, jʊ ɑːnt raɪt",
        translation: "Unfortunately, you aren't right",
      },
      {
        id: 72,
        expression: "К сожалению, я не прав",
        transcription: "ʌnˈfɔːʧnɪtli, aɪm nɒt raɪt",
        translation: "Unfortunately, I'm not right",
      },
      {
        id: 73,
        expression: "К сожалению, они не правы",
        transcription: "ʌnˈfɔːʧnɪtli, ðeɪ ɑːnt raɪt",
        translation: "Unfortunately, they aren't right",
      },
      {
        id: 74,
        expression: "Это не правильный ответ",
        transcription: "ɪt ˈɪznt ðə kəˈrɛkt ˈɑːnsə",
        translation: "It isn't the correct answer",
      },
      {
        id: 75,
        expression: "Это правильный ответ",
        transcription: "ɪts ðə kəˈrɛkt ˈɑːnsə",
        translation: "It's the correct answer",
      },
      {
        id: 76,
        expression: "Это не самый лучший ответ",
        transcription: "ɪt ˈɪznt ðə bɛst ˈɑːnsə",
        translation: "It isn't the best answer",
      },
      {
        id: 77,
        expression: "Она не замужем за ним",
        transcription: "ʃi ˈɪznt ˈmærɪd tə hɪm",
        translation: "She isn't married to him",
      },
      {
        id: 78,
        expression: "Они не женаты",
        transcription: "ðeɪ ɑːnt ˈmærɪd",
        translation: "They aren't married",
      },
      {
        id: 79,
        expression: "Это не официальный документ",
        transcription: "ɪt ˈɪznt ən əˈfɪʃəl ˈdɒkjʊmənt",
        translation: "It isn't an official document",
      },
      {
        id: 80,
        expression: "Это официальный документ",
        transcription: "ɪts ən əˈfɪʃəl ˈdɒkjʊmənt",
        translation: "It's an official document",
      },
      {
        id: 81,
        expression: "Это не важный документ",
        transcription:
          "ɪt ˈɪznt ən ɪmˈpɔːtənt ˈdɒkjʊmənt / ɪts ən ˌʌnɪmˈpɒtənt ˈdɒkjʊmənt",
        translation:
          "It isn't an important document / It's an unimportant document",
      },
      {
        id: 82,
        expression: "Он не готов к этому экзамену сейчас",
        transcription: "hi ˈɪznt ˈrɛdi fə ðɪs ɪgˈzæm naʊ",
        translation: "He isn't ready for this exam now",
      },
      {
        id: 83,
        expression: "Я не готов к тому экзамену",
        transcription: "aɪm nɒt ˈrɛdi fə ðət ɪgˈzæm",
        translation: "I'm not ready for that exam",
      },
      {
        id: 84,
        expression: "Он не готов к экзаменам",
        transcription: "hi ˈɪznt ˈrɛdi fə ði ɪgˈzæmz",
        translation: "He isn't ready for the exams",
      },
      {
        id: 85,
        expression: "Я хочу сказать, что я не уверен",
        transcription: "aɪ wɒnt tə seɪ ðət aɪm nɒt ʃʊə",
        translation: "I want to say that I'm not sure",
      },
      {
        id: 86,
        expression: "Она хочет сказать, что она не уверена",
        transcription: "ʃi wɒnts tə seɪ ðət ʃi ˈɪznt ʃʊə",
        translation: "She wants to say that she isn't sure",
      },
      {
        id: 87,
        expression: "Они хотят сказать, что они не уверены",
        transcription: "ðeɪ wɒnt tə seɪ ðət ðeɪ ɑːnt ʃʊə",
        translation: "They want to say that they aren't sure",
      },
      {
        id: 88,
        expression: "Он не бухгалтер",
        transcription: "hi ˈɪznt ən əˈkaʊntənt",
        translation: "He isn't an accountant",
      },
      {
        id: 89,
        expression: "Это не мое главное хобби",
        transcription: "ɪt ˈɪznt maɪ meɪn ˈhɒbi",
        translation: "It isn't my main hobby",
      },
      {
        id: 90,
        expression: "Я не в парке сейчас",
        transcription: "aɪm nɒt ɪn ðə pɑːk naʊ",
        translation: "I'm not in the park now",
      },
      {
        id: 91,
        expression: "Мне кажется, это не лучшее решение",
        transcription: "ɪt siːmz tə mi ðət ɪt ˈɪznt ðə bɛst dɪˈsɪʒən",
        translation: "It seems to me that it isn't the best decision",
      },
      {
        id: 92,
        expression: "Они не в одном и том же классе",
        transcription: "ðeɪ ɑːnt ɪn ðə seɪm klɑːs",
        translation: "They aren't in the same class",
      },
      {
        id: 93,
        expression: "Это неофициальный документ",
        transcription: "ɪts ən ˌʌnəˈfɪʃəl ˈdɒkjʊmənt",
        translation: "It's an unofficial document",
      },
      {
        id: 94,
        expression: "Она не на кухне",
        transcription: "ʃi ˈɪznt ɪn ðə ˈkɪʧɪn",
        translation: "She isn't in the kitchen",
      },
      {
        id: 95,
        expression: "Я знаю, что это не возможно",
        transcription: "aɪ nəʊ ðət ɪt ˈɪznt ˈpɒsəbl",
        translation: "I know that it isn't possible",
      },
      {
        id: 96,
        expression: "Я действительно не уверен",
        transcription: "aɪm nɒt ˈrɪəli ʃʊə",
        translation: "I'm not really sure",
      },
      {
        id: 97,
        expression: "Она не на работе в данный момент",
        transcription: "ʃi ˈɪznt ət wɜːk ət ðə ˈməʊmənt",
        translation: "She isn't at work at the moment",
      },
      {
        id: 98,
        expression: "Он не заинтересован в этом предмете",
        transcription: "hi ˈɪznt ˈɪntrɪstɪd ɪn ðɪs ˈsʌbʤɪkt",
        translation: "He isn't interested in this subject",
      },
      {
        id: 99,
        expression: "К сожалению, это невозможно",
        transcription: "ʌnˈfɔːʧnɪtli, ɪts ɪmˈpɒsəbl",
        translation: "Unfortunately, it's impossible",
      },
      {
        id: 100,
        expression: "Это не правильный ответ",
        transcription: "ɪt ˈɪznt ðə raɪt ˈɑːnsə / ɪt ˈɪznt ðə kəˈrɛkt ˈɑːnsə",
        translation: "It ist'n the right answer / It isn't the correct answer",
      },
      {
        id: 101,
        expression: "К сожалению, это не так",
        transcription: "ʌnˈfɔːʧnɪtli, ɪt ˈɪznt səʊ",
        translation: "Unfortunately, it isn't so",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Закрепление пройденного материала</h3>
</div>`,
    },
  ],
];
