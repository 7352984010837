export const contentLesson161 = [
  [
    {
      title:
        'Урок 161. Comparison of Tenses. Present Perfect Continuous + Present Perfect + Past Continuous + Present Simple',
    },
    [
      {
        id: 1,
        expression: 'Я живу в этом городе в течение года',
        transcription: 'aɪv biːn ˈlɪvɪŋ ɪn ðɪs ˈsɪti fər ə jɪə',
        translation: "I've been living in this city for a year",
      },
      {
        id: 2,
        expression: 'Я изучаю английский около полугода',
        transcription: 'aɪv biːn ˈlɜːnɪŋ ˈɪŋglɪʃ fər əˈbaʊt hɑːf ə jɪə',
        translation: "I've been learning English for about half a year",
      },
      {
        id: 3,
        expression: 'Как долго ты знаешь его?',
        transcription: 'haʊ lɒŋ həv jʊ nəʊn hɪm?',
        translation: 'How long have you known him?',
      },
      {
        id: 4,
        expression: 'Они разговаривают по телефону почти два часа',
        transcription: 'ðeɪv biːn ˈtɔːkɪŋ ɒn ðə fəʊn fər ˈɔːlməʊst tuː ˈaʊəz',
        translation: "They've been talking on the phone for almost two hours",
      },
      {
        id: 5,
        expression: 'Как долго ты изучаешь этот язык?',
        transcription: 'haʊ lɒŋ həv jʊ biːn ˈlɜːnɪŋ ðɪs ˈlæŋgwɪʤ?',
        translation: 'How long have you been learning this language?',
      },
      {
        id: 6,
        expression: 'Я делаю это целый день',
        transcription: 'aɪv biːn ˈdu(ː)ɪŋ ɪt ɔːl deɪ lɒŋ',
        translation: "I've been doing it all day long",
      },
      {
        id: 7,
        expression: 'Я учу английский весь день',
        transcription: 'aɪv biːn ˈlɜːnɪŋ ˈɪŋglɪʃ ɔːl deɪ',
        translation: "I've been learning English all day",
      },
      {
        id: 8,
        expression: 'Они играли в футбол весь день вчера',
        transcription: 'ðeɪ wə ˈpleɪɪŋ ˈfʊtbɔːl ɔːl deɪ ˈjɛstədeɪ',
        translation: 'They were playing football all day yesterday',
      },
      {
        id: 9,
        expression: 'Я готовлюсь к этому тесту с понедельника',
        transcription: 'aɪv biːn prɪˈpeərɪŋ fə ðɪs tɛst sɪns ˈmʌndeɪ',
        translation: "I've been preparing for this test since Monday",
      },
      {
        id: 10,
        expression: 'Как долго ты работаешь в этом отделе?',
        transcription: 'haʊ lɒŋ həv jʊ biːn ˈwɜːkɪŋ ɪn ðɪs dɪˈpɑːtmənt?',
        translation: 'How long have you been working in this department?',
      },
      {
        id: 11,
        expression: 'Я живу здесь всю свою жизнь',
        transcription: 'aɪv biːn ˈlɪvɪŋ hɪər ɔːl maɪ laɪf',
        translation: "I've been living here all my life",
      },
      {
        id: 12,
        expression: 'Она не видела его такое долгое время',
        transcription: 'ʃi ˈhæznt siːn ɪm fə sʌʧ ə lɒŋ taɪm',
        translation: "She hasn't seen him for such a long time",
      },
      {
        id: 13,
        expression: 'Как долго ты думаешь об этом?',
        transcription: 'haʊ lɒŋ həv jʊ biːn ˈθɪŋkɪŋ əˈbaʊt ɪt?',
        translation: 'How long have you been thinking about it?',
      },
      {
        id: 14,
        expression: 'Я работаю весь день',
        transcription: 'aɪv biːn ˈwɜːkɪŋ ɔːl deɪ',
        translation: "I've been working all day",
      },
      {
        id: 15,
        expression: 'Как долго ты смотришь эту программу?',
        transcription: 'haʊ lɒŋ həv jʊ biːn ˈwɒʧɪŋ ðɪs ˈprəʊgræm?',
        translation: 'How long have you been watching this program?',
      },
      {
        id: 16,
        expression: 'Он так давно ее не видел',
        transcription: 'hi ˈhæznt siːn hə fə səʊ lɒŋ',
        translation: "He hasn't seen her for so long",
      },
      {
        id: 17,
        expression: 'Она была очень занята в последнее время',
        transcription: 'ʃiːz biːn ˈvɛri ˈbɪzi ˈleɪtli',
        translation: "She's been very busy lately",
      },
      {
        id: 18,
        expression: 'Она в Италии сейчас',
        transcription: 'ʃiːz ɪn ˈɪtəli naʊ',
        translation: "She's in Italy now",
      },
      {
        id: 19,
        expression: 'Как долго она здесь?',
        transcription: 'haʊ lɒŋ həz ʃi biːn hɪə?',
        translation: 'How long has she been here?',
      },
      {
        id: 20,
        expression: 'Они друзья много лет',
        transcription: 'ðeɪv biːn frɛndz fə ˈmɛni jɪəz',
        translation: "They've been friends for many years",
      },
      {
        id: 21,
        expression: 'Как давно они встречаются?',
        transcription: 'haʊ lɒŋ həv ðeɪ biːn ˈdeɪtɪŋ?',
        translation: 'How long have they been dating?',
      },
      {
        id: 22,
        expression: 'Она готовит с утра',
        transcription: 'ʃiːz biːn ˈkʊkɪŋ sɪns ˈmɔːnɪŋ',
        translation: "She's been cooking since morning",
      },
      {
        id: 23,
        expression: 'Как долго вы друг друга знаете?',
        transcription: 'haʊ lɒŋ həv jʊ nəʊn iːʧ ˈʌðə?',
        translation: 'How long have you known each other?',
      },
      {
        id: 24,
        expression: 'Мы тренируемся очень интенсивно в последнее время',
        transcription: 'wiːv biːn ˈtreɪnɪŋ ˈvɛri ɪnˈtɛnsɪvli ˈleɪtli',
        translation: "We've been training very intensively lately",
      },
      {
        id: 25,
        expression: 'Они встречаются с августа',
        transcription: 'ðeɪv biːn ˈdeɪtɪŋ sɪns ˈɔːgəst',
        translation: "They've been dating since August",
      },
      {
        id: 26,
        expression: 'Как давно он преподает английский?',
        transcription: 'haʊ lɒŋ həz hi biːn ˈtiːʧɪŋ ˈɪŋglɪʃ?',
        translation: 'How long has he been teaching English?',
      },
      {
        id: 27,
        expression: 'Как долго ты разговариваешь по телефону?',
        transcription: 'haʊ lɒŋ həv jʊ biːn ˈtɔːkɪŋ ɒn ðə fəʊn?',
        translation: 'How long have you been talking on the phone?',
      },
      {
        id: 28,
        expression: 'Я не думал об этом с нашего последнего разговора',
        transcription: 'aɪ hævnt θɔːt əˈbaʊt ɪt sɪns ˈaʊə lɑːst ˌkɒnvəˈseɪʃən',
        translation: "I haven't thought about it since our last conversation",
      },
      {
        id: 29,
        expression: 'Я не видел его очень долгое время',
        transcription: 'aɪ hævnt siːn ɪm fər ə ˈvɛri lɒŋ taɪm',
        translation: "I haven't seen him for a very long time",
      },
      {
        id: 30,
        expression: 'Я нахожусь здесь час',
        transcription: 'aɪv biːn ˈsteɪɪŋ hɪə fər ən ˈaʊə',
        translation: "I've been staying here for an hour",
      },
      {
        id: 31,
        expression: 'Как давно ты работаешь менеджером?',
        transcription: 'haʊ lɒŋ həv jʊ biːn ˈwɜːkɪŋ əz ə ˈmænɪʤə?',
        translation: 'How long have you been working as a manager?',
      },
      {
        id: 32,
        expression: 'Я пользуюсь этим приложением несколько месяцев',
        transcription: 'aɪv biːn ˈjuːzɪŋ ðɪs æp fə ˈsɛvrəl mʌnθs',
        translation: "I've been using this app for several months",
      },
      {
        id: 33,
        expression: 'Я не видел его целую вечность',
        transcription: 'aɪ hævnt siːn ɪm fər ˈeɪʤɪz',
        translation: "I haven't seen him for ages",
      },
      {
        id: 34,
        expression: 'Они играют в футбол весь день',
        transcription: 'ðeɪv biːn ˈpleɪɪŋ ˈfʊtbɔːl ɔːl deɪ',
        translation: "They've been playing football all day",
      },
      {
        id: 35,
        expression: 'Я живу здесь с прошлого лета',
        transcription: 'aɪv biːn ˈlɪvɪŋ hɪə sɪns lɑːst ˈsʌmə',
        translation: "I've been living here since last summer",
      },
      {
        id: 36,
        expression: 'Она плохо спит в последнее время',
        transcription: 'ʃiːz biːn ˈsliːpɪŋ ˈbædli ˈleɪtli',
        translation: "She's been sleeping badly lately",
      },
      {
        id: 37,
        expression: 'Она в Италии с марта',
        transcription: 'ʃiːz biːn ɪn ˈɪtəli sɪns mɑːʧ',
        translation: "She's been in Italy since March",
      },
      {
        id: 38,
        expression: 'Я еще не думал об этом',
        transcription: 'aɪ hævnt θɔːt əˈbaʊt ɪt jɛt',
        translation: "I haven't thought about it yet",
      },
      {
        id: 39,
        expression: 'Я нахожусь здесь минуту',
        transcription: 'aɪv biːn hɪə fər ə ˈmɪnɪt',
        translation: "I've been here for a minute",
      },
      {
        id: 40,
        expression: 'Я делаю это упражнение пять минут',
        transcription: 'aɪv biːn ˈdu(ː)ɪŋ ðɪs ˈɛksəsaɪz fə faɪv ˈmɪnɪts',
        translation: "I've been doing this exercise for five minutes",
      },
      {
        id: 41,
        expression:
          'Я использую это приложение в течение полугода, и я считаю его действительно полезным',
        transcription:
          'aɪv biːn ˈjuːzɪŋ ðɪs ˌæplɪˈkeɪʃ(ə)n fə hɑːf ə jɪər ənd aɪ faɪnd (kənˈsɪdə) ɪt ˈrɪəli ˈjuːsfʊl',
        translation:
          "I've been using this application for half a year and I find (consider) it really useful",
      },
      {
        id: 42,
        expression: 'Чем твой друг был занят в последнее время?',
        transcription: 'wɒt həz jə frɛnd biːn ˈbɪzi wɪð ˈleɪtli?',
        translation: 'What has your friend been busy with lately?',
      },
      {
        id: 43,
        expression: 'Чем ты увлекался в последнее время?',
        transcription: 'wɒt həv jʊ biːn kiːn ɒn ˈleɪtli?',
        translation: 'What have you been keen on lately?',
      },
      {
        id: 44,
        expression:
          'Он играл в хоккей каждый раз, когда у него была такая возможность',
        transcription: 'hi pleɪd ˈhɒki ˈɛvri taɪm hi həd sʌʧ ən ˌɒpəˈtjuːnɪti',
        translation: 'He played hockey every time he had such an opportunity',
      },
      {
        id: 45,
        expression: 'Они еще не нашли решение этой проблемы',
        transcription: 'ðeɪ hævnt faʊnd ə səˈluːʃən tə ðɪs ˈprɒbləm jɛt',
        translation: "They haven't found a solution to this problem yet",
      },
      {
        id: 46,
        expression: 'Чем ты был занят в последнее время?',
        transcription: 'wɒt həv jʊ biːn ˈbɪzi wɪð ˈleɪtli?',
        translation: 'What have you been busy with lately?',
      },
      {
        id: 47,
        expression: 'Что Энн делает весь день?',
        transcription: 'wɒt həz æn biːn ˈdu(ː)ɪŋ ɔːl deɪ?',
        translation: 'What has Ann been doing all day?',
      },
      {
        id: 48,
        expression: 'Они посетили этот собор во время их поездки в Италию',
        transcription:
          'ðeɪ ˈvɪzɪtɪd ðɪs kəˈθiːdrəl ˈdjʊərɪŋ ðeə trɪp tʊ ˈɪtəli',
        translation: 'They visited this cathedral during their trip to Italy',
      },
      {
        id: 49,
        expression: 'Со вчерашнего дня идет сильный снег',
        transcription: 'ɪts biːn ˈsnəʊɪŋ ˈhɛvɪli sɪns ˈjɛstədeɪ',
        translation: "It's been snowing heavily since yesterday",
      },
      {
        id: 50,
        expression: 'Чем ты интересуешься в последнее время?',
        transcription: 'wɒt həv jʊ biːn ˈɪntrɪstɪd ɪn ˈleɪtli?',
        translation: 'What have you been interested in lately?',
      },
      {
        id: 51,
        expression: 'Мы друзья около десяти лет',
        transcription: 'wiːv biːn frɛndz fər əˈbaʊt tɛn jɪəz',
        translation: "We've been friends for about ten years",
      },
      {
        id: 52,
        expression: 'Когда ты начал искать свои ключи?',
        transcription: 'wɛn dɪd jʊ stɑːt ˈlʊkɪŋ fə jə kiːz?',
        translation: 'When did you start looking for your keys?',
      },
      {
        id: 53,
        expression: 'Как долго ты добирался до работы?',
        transcription: 'haʊ lɒŋ dɪd ɪt teɪk jʊ tə gɛt tə wɜːk?',
        translation: 'How long did it take you to get to work?',
      },
      {
        id: 54,
        expression: 'Как долго ты добирался до этого места?',
        transcription: 'haʊ lɒŋ dɪd ɪt teɪk jʊ tə gɛt tə ðɪs pleɪs?',
        translation: 'How long did it take you to get to this place?',
      },
      {
        id: 55,
        expression: 'Я вижу, что дождь еще не прекратился',
        transcription: 'aɪ siː ðət ɪt ˈhæznt biːn stɒpt ˈreɪnɪŋ jɛt',
        translation: "I see that it hasn't been stopped raining yet",
      },
      {
        id: 56,
        expression: 'Что ты делаешь в последнее время?',
        transcription: 'wɒt həv jʊ biːn ˈdu(ː)ɪŋ ˈleɪtli?',
        translation: 'What have you been doing lately?',
      },
      {
        id: 57,
        expression: 'Что ты делаешь в течение трех часов?',
        transcription: 'wɒt həv jʊ biːn ˈdu(ː)ɪŋ fə θriː ˈaʊəz?',
        translation: 'What have you been doing for three hours?',
      },
      {
        id: 58,
        expression:
          'Я делаю свою домашнюю работу с тех пор, как я пришел домой',
        transcription: 'aɪv biːn ˈdu(ː)ɪŋ maɪ ˈhəʊmˌwɜːk sɪns aɪ keɪm həʊm',
        translation: "I've been doing my homework since I came home",
      },
      {
        id: 59,
        expression: 'Как долго ты ищешь свои ключи?',
        transcription: 'haʊ lɒŋ həv jʊ biːn ˈlʊkɪŋ fə jə kiːz?',
        translation: 'How long have you been looking for your keys?',
      },
      {
        id: 60,
        expression: 'Как долго ты ищешь новую работу?',
        transcription: 'haʊ lɒŋ həv jʊ biːn ˈlʊkɪŋ fər ə njuː ʤɒb?',
        translation: 'How long have you been looking for a new job?',
      },
      {
        id: 61,
        expression: 'Как долго ты этим занимаешься?',
        transcription: 'haʊ lɒŋ həv jʊ biːn ˈdu(ː)ɪŋ ɪt?',
        translation: 'How long have you been doing it?',
      },
      {
        id: 62,
        expression: 'Я занимаюсь с тех пор, как он ушел из дома',
        transcription: 'aɪv biːn ˈstʌdiɪŋ sɪns hi lɛft həʊm',
        translation: "I've been studying since he left home",
      },
      {
        id: 63,
        expression: 'Я учусь там в течение пяти лет',
        transcription: 'aɪv biːn ˈstʌdiɪŋ ðeə fə faɪv jɪəz',
        translation: "I've been studying there for five years",
      },
      {
        id: 64,
        expression: 'Я занимаюсь этим с тех пор, как пришел домой',
        transcription: 'aɪv biːn ˈdu(ː)ɪŋ ɪt sɪns aɪ keɪm həʊm',
        translation: "I've been doing it since I came home",
      },
      {
        id: 65,
        expression: 'Мы были друзьями с тех пор, как встретились',
        transcription: 'wiːv biːn frɛndz sɪns wi mɛt',
        translation: "We've been friends since we met",
      },
      {
        id: 66,
        expression: 'Они друзья с детства',
        transcription: 'ðeɪv biːn frɛndz sɪns ˈʧaɪldhʊd',
        translation: "They've been friends since childhood",
      },
      {
        id: 67,
        expression: 'Мы знаем друг друга со школы',
        transcription: 'wiːv biːn nəʊn iːʧ ˈʌðə sɪns skuːl',
        translation: "We've been known each other since school",
      },
      {
        id: 68,
        expression: 'Они еще не решили эту проблему',
        transcription: 'ðeɪ hævnt sɒlvd ðɪs ˈprɒbləm jɛt',
        translation: "They haven't solved this problem yet",
      },
      {
        id: 69,
        expression: 'Они уже решили свои проблемы',
        transcription: 'ðeɪv ɔːlˈrɛdi sɒlvd ðeə ˈprɒbləmz',
        translation: "They've already solved their problems",
      },
      {
        id: 70,
        expression: 'Я еще не решил эту задачу',
        transcription: 'aɪ hævnt sɒlvd ðɪs tɑːsk jɛt',
        translation: "I haven't solved this task yet",
      },
      {
        id: 71,
        expression: 'Как давно это было?',
        transcription: 'haʊ lɒŋ əˈgəʊ wəz ɪt?',
        translation: 'How long ago was it?',
      },
      {
        id: 72,
        expression: 'Это было два года назад',
        transcription: 'ɪt wəz tuː jɪəz əˈgəʊ',
        translation: 'It was two years ago',
      },
      {
        id: 73,
        expression: 'Сколько лет назад это было?',
        transcription: 'haʊ ˈmɛni jɪəz əˈgəʊ wəz ɪt?',
        translation: 'How many years ago was it?',
      },
      {
        id: 74,
        expression: 'Я до сих пор там не был',
        transcription: 'aɪ hævnt biːn ðeə səʊ fɑː',
        translation: "I haven't been there so far",
      },
      {
        id: 75,
        expression: 'Я никогда не был там',
        transcription: 'aɪv ˈnɛvə biːn ðeə',
        translation: "I've never been there",
      },
      {
        id: 76,
        expression: 'Ты когда-нибудь был здесь?',
        transcription: 'həv jʊ ˈɛvə biːn hɪə?',
        translation: 'Have you ever been here?',
      },
      {
        id: 77,
        expression: 'Весь день идет дождь',
        transcription: 'ɪts biːn ˈreɪnɪŋ ɔːl deɪ',
        translation: "It's been raining all day",
      },
      {
        id: 78,
        expression: 'Всю неделю идет снег',
        transcription: 'ɪts biːn ˈsnəʊɪŋ ɔːl wiːk',
        translation: "It's been snowing all week",
      },
      {
        id: 79,
        expression: 'Солнце светит весь день',
        transcription: 'ðə sʌn həz biːn ˈʃaɪnɪŋ ɔːl deɪ',
        translation: 'The sun has been shining all day',
      },
      {
        id: 80,
        expression: 'Она до сих пор этого не сделала',
        transcription: 'ʃi ˈhæznt dʌn ɪt səʊ fɑː',
        translation: "She hasn't done it so far",
      },
      {
        id: 81,
        expression: 'Она еще не сделала этого',
        transcription: 'ʃi ˈhæznt dʌn ɪt jɛt',
        translation: "She hasn't done it yet",
      },
      {
        id: 82,
        expression: 'Она уже сделала это',
        transcription: 'ʃiːz ɔːlˈrɛdi dʌn ɪt',
        translation: "She's already done it",
      },
      {
        id: 83,
        expression: 'Как долго он ищет работу?',
        transcription: 'haʊ lɒŋ həz hi biːn ˈlʊkɪŋ fər ə ʤɒb?',
        translation: 'How long has he been looking for a job?',
      },
      {
        id: 84,
        expression: 'Он ищет новую работу в настоящее время?',
        transcription: 'ɪz hi ˈlʊkɪŋ fər ə njuː ʤɒb ət ˈprɛznt?',
        translation: 'Is he looking for a new job at present?',
      },
      {
        id: 85,
        expression: 'Как долго он там работает?',
        transcription: 'haʊ lɒŋ həz hi biːn ˈwɜːkɪŋ ðeə?',
        translation: 'How long has he been working there?',
      },
      {
        id: 86,
        expression: 'Я ходил туда каждый раз, когда я имел эту возможность',
        transcription: 'aɪ wɛnt ðeər ˈɛvri taɪm aɪ həd ðɪs ˌɒpəˈtjuːnɪti',
        translation: 'I went there every time I had this opportunity',
      },
      {
        id: 87,
        expression: 'Я делал это вчера весь день',
        transcription: 'aɪ wəz ˈdu(ː)ɪŋ ɪt ɔːl deɪ ˈjɛstədeɪ',
        translation: 'I was doing it all day yesterday',
      },
      {
        id: 88,
        expression: 'Он вчера весь день сидел дома',
        transcription: 'hi wəz ˈsteɪɪŋ ət həʊm ɔːl deɪ ˈjɛstədeɪ',
        translation: 'He was staying at home all day yesterday',
      },
      {
        id: 89,
        expression: 'Они не ели восемь часов',
        transcription: 'ðeɪ hævnt biːn ˈiːtɪŋ fər eɪt ˈaʊəz',
        translation: "They haven't been eating for eight hours",
      },
      {
        id: 90,
        expression: 'Сколько времени идет дождь?',
        transcription: 'haʊ lɒŋ həz ɪt biːn ˈreɪnɪŋ?',
        translation: 'How long has it been raining?',
      },
      {
        id: 91,
        expression: 'Ее колено болит с той аварии',
        transcription: 'hə niː həz biːn ˈeɪkɪŋ sɪns ðət ˈæksɪdənt',
        translation: 'Her knee has been aching since that accident',
      },
      {
        id: 92,
        expression: 'Мой брат путешествует две недели',
        transcription: 'maɪ ˈbrʌðə həz biːn ˈtrævlɪŋ fə tuː wiːks',
        translation: 'My brother has been travelling for two weeks',
      },
      {
        id: 93,
        expression: 'Она весь день работает в саду и не устала',
        transcription:
          'ʃiːz biːn ˈwɜːkɪŋ ɪn ðə ˈgɑːdn ɔːl deɪ ənd ʃi ˈɪznt ˈtaɪəd',
        translation:
          "She's been working in the garden all day and she isn't tired",
      },
      {
        id: 94,
        expression: 'Я знаю Пола с детства',
        transcription: 'aɪv nəʊn pɔːl sɪns ˈʧaɪldhʊd',
        translation: "I've known Paul since childhood",
      },
      {
        id: 95,
        expression:
          'Каждый раз, когда я читал эту книгу, я находил что-то новое',
        transcription: 'ˈɛvri taɪm aɪ red ðɪs bʊk, aɪ faʊnd ˈsʌmθɪŋ njuː',
        translation: 'Every time I read this book, I found something new',
      },
      {
        id: 96,
        expression: 'Весь день идет снег',
        transcription: 'ɪts biːn ˈsnəʊɪŋ ɔːl deɪ',
        translation: "It's been snowing all day",
      },
      {
        id: 97,
        expression: 'Его нога болит весь день',
        transcription: 'hɪz lɛg həz biːn ˈeɪkɪŋ ɔːl deɪ',
        translation: 'His leg has been aching all day',
      },
      {
        id: 98,
        expression: 'Ветер дует весь день',
        transcription: 'ðə wɪnd həz biːn ˈbləʊɪŋ ɔːl deɪ',
        translation: 'The wind has been blowing all day',
      },
      {
        id: 99,
        expression: 'Как долго вы дружите?',
        transcription: 'haʊ lɒŋ həv jʊ biːn frɛndz?',
        translation: 'How long have you been friends?',
      },
      {
        id: 100,
        expression: 'Он пока не нашел работу',
        transcription: 'hi ˈhæznt faʊnd ðə ʤɒb səʊ fɑː',
        translation: "He hasn't found the job so far",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Закрепление пройденного материала</h3>
</div>`,
    },
  ],
];
