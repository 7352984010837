export const contentLesson89 = [
  [
    {
      title: "Урок 89. Past Simple. 150 Regular Verbs. Part 5",
    },
    [
      {
        id: 1,
        expression: "Я хотел общаться с людьми из других стран",
        transcription:
          "aɪ ˈwɒntɪd tuː kəˈmjuːnɪkeɪt wɪð ˈpiːpl frɒm ˈʌðə ˈkʌntriz",
        translation: "I wanted to communicate with people from other countries",
      },
      {
        id: 2,
        expression: "Я с большим удовольствием принял его приглашение",
        transcription: "aɪ əkˈsɛptɪd hɪz ˌɪnvɪˈteɪʃən wɪð greɪt ˈplɛʒə",
        translation: "I accepted his invitation with great pleasure",
      },
      {
        id: 3,
        expression: "Она улыбнулась ему",
        transcription: "ʃiː smaɪld æt hɪm",
        translation: "She smiled at him",
      },
      {
        id: 4,
        expression: "Он испытывал некоторые трудности в начале",
        transcription: "hiː ɪksˈpɪərɪənst sʌm ˈdɪfɪkəltiz ɪn ðə bɪˈgɪnɪŋ",
        translation: "He experienced some difficulties in the beginning",
      },
      {
        id: 5,
        expression: "Директор управлял компанией очень непрофессионально",
        transcription: "ðə dɪˈrɛktə ˈmænɪʤd ðə ˈkʌmpəni ˈvɛri ˌʌnprəˈfɛʃənli",
        translation: "The director managed the company very unprofessionally",
      },
      {
        id: 6,
        expression: "Он описал свою внешность",
        transcription: "hiː dɪsˈkraɪbd hɪz əˈpɪərəns",
        translation: "He described his appearance",
      },
      {
        id: 7,
        expression: "Я не мог представить свою жизнь без этого",
        transcription: "aɪ ˈkʊdnt ɪˈmæʤɪn maɪ laɪf wɪˈðaʊt ɪt",
        translation: "I couldn't imagine my life without it",
      },
      {
        id: 8,
        expression: "Я разделил это на четыре части",
        transcription: "aɪ dɪˈvaɪdɪd ɪt ˈɪntuː fɔː pɑːts",
        translation: "I divided it into four parts",
      },
      {
        id: 9,
        expression: "Я хотел знать все",
        transcription: "aɪ ˈwɒntɪd tuː nəʊ ˈɛvrɪθɪŋ",
        translation: "I wanted to know everything",
      },
      {
        id: 10,
        expression: "Они подписали соглашение",
        transcription: "ðeɪ saɪnd ən əˈgriːmənt",
        translation: "They signed an agreement",
      },
      {
        id: 11,
        expression: "Я не мог упустить этот шанс",
        transcription: "aɪ ˈkʊdnt mɪs ðɪs ʧɑːns",
        translation: "I couldn't miss this chance",
      },
      {
        id: 12,
        expression: "Я посетил это место во второй раз",
        transcription: "aɪ ˈvɪzɪtɪd ðɪs pleɪs fɔː ðə ˈsɛkənd taɪm",
        translation: "I visited this place for the second time",
      },
      {
        id: 13,
        expression: "Она выслушала мой совет",
        transcription: "ʃiː ˈlɪsnd tuː maɪ ədˈvaɪs",
        translation: "She listened to my advice",
      },
      {
        id: 14,
        expression: "Мы вместе работали над другим проектом",
        transcription: "wiː wɜːkt ɒn əˈnʌðə ˈprɒʤɛkt təˈgɛðə",
        translation: "We worked on another project together",
      },
      {
        id: 15,
        expression: "Они с большим удовольствием приняли мое предложение",
        transcription: "ðeɪ əkˈsɛptɪd maɪ ˈɒfə wɪð greɪt ˈplɛʒə",
        translation: "They accepted my offer with great pleasure",
      },
      {
        id: 16,
        expression: "Я этого не ожидал",
        transcription: "aɪ dɪdnt ɪkˈsɛpt ɪt",
        translation: "I didn't except it",
      },
      {
        id: 17,
        expression: "Мы начали работать",
        transcription: "wiː ˈstɑːtɪd ˈwɜːkɪŋ / wiː bɪˈgæn tuː wɜːk",
        translation: "We started working / We began to work",
      },
      {
        id: 18,
        expression: "Это случилось со мной однажды",
        transcription: "ɪt ˈhæpənd tuː miː wʌn deɪ",
        translation: "It happened to me one day",
      },
      {
        id: 19,
        expression: "Я работал над новым проектом",
        transcription: "aɪ wɜːkt ɒn ə njuː ˈprɒʤɛkt",
        translation: "I worked on a new project",
      },
      {
        id: 20,
        expression: "Мне нужно было немного расслабиться",
        transcription: "aɪ ˈniːdɪd tuː rɪˈlæks ə bɪt",
        translation: "I needed to relax a bit",
      },
      {
        id: 21,
        expression: "Я хотел общаться с разными людьми",
        transcription: "aɪ ˈwɒntɪd tuː kəˈmjuːnɪkeɪt wɪð ˈdɪfrənt ˈpiːpl",
        translation: "I wanted to communicate with different people",
      },
      {
        id: 22,
        expression: "Он провалил экзамен",
        transcription: "hiː feɪld ði ɪgˈzæm",
        translation: "He failed the exam",
      },
      {
        id: 23,
        expression: "Я часто гулял в парке",
        transcription: "aɪ ˈɒf(ə)n wɔːkt ɪn ðə pɑːk",
        translation: "I often walked in the park",
      },
      {
        id: 24,
        expression: "Он сообщил мне о той проблеме",
        transcription: "hiː ɪnˈfɔːmd miː ɒv ðæt ˈprɒbləm",
        translation: "He informed me of that problem",
      },
      {
        id: 25,
        expression: "Я не хотел убивать время",
        transcription: "aɪ dɪdnt wɒnt tuː kɪl ðə taɪm",
        translation: "I didn't want to kill the time",
      },
      {
        id: 26,
        expression: "Это разрушило деревню",
        transcription: "ɪt dɪsˈtrɔɪd ðə ˈvɪlɪʤ",
        translation: "It destroyed the village",
      },
      {
        id: 27,
        expression: "Она провалила тест",
        transcription: "ʃiː feɪld ðə tɛst",
        translation: "She failed the test",
      },
      {
        id: 28,
        expression: "Я опоздал на автобус",
        transcription: "aɪ mɪst ðə bʌs",
        translation: "I missed the bus",
      },
      {
        id: 29,
        expression: "Мальчики часто смеялись над ним",
        transcription: "ðə bɔɪz ˈɒf(ə)n lɑːft æt hɪm",
        translation: "The boys often laughed at him",
      },
      {
        id: 30,
        expression: "Это разрушило его здоровье",
        transcription: "ɪt dɪsˈtrɔɪd hɪz hɛlθ",
        translation: "It destroyed his health",
      },
      {
        id: 31,
        expression: "Я навещал там некоторых своих родственников",
        transcription: "aɪ ˈvɪzɪtɪd ðeə sʌm ɒv maɪ ˈrɛlətɪvz",
        translation: "I visited there some of my relatives",
      },
      {
        id: 32,
        expression: "Он улыбнулся ей",
        transcription: "hiː smaɪld æt hɜː",
        translation: "He smiled at her",
      },
      {
        id: 33,
        expression: "Я не мог упустить ту возможность",
        transcription: "aɪ ˈkʊdnt mɪs ðæt ˌɒpəˈtjuːnɪti",
        translation: "I couldn't miss that opportunity",
      },
      {
        id: 34,
        expression: "Я хотел отдохнуть только чуть-чуть",
        transcription: "aɪ ˈwɒntɪd tuː rɪˈlæks ʤʌst ə ˈlɪtl bɪt",
        translation: "I wanted to relax just a little bit",
      },
      {
        id: 35,
        expression: "Они разрушили здание",
        transcription: "ðeɪ dɪsˈtrɔɪd ðə ˈbɪldɪŋ",
        translation: "They destroyed the building",
      },
      {
        id: 36,
        expression: "Я пошел домой после работы",
        transcription: "aɪ wɛnt həʊm ˈɑːftə wɜːk / aɪ wɔːkt həʊm ˈɑːftə wɜːk",
        translation: "I went home after work / I walked home after work",
      },
      {
        id: 37,
        expression: "Я испытывал некоторые трудности",
        transcription: "aɪ ɪksˈpɪərɪənst sʌm ˈdɪfɪkəltiz",
        translation: "I experienced some difficulties",
      },
      {
        id: 38,
        expression: "Я часто ходил на работу",
        transcription: "aɪ ˈɒf(ə)n wɔːkt tuː wɜːk",
        translation: "I often walked to work",
      },
      {
        id: 39,
        expression: "Я ожидал более хорошие результаты",
        transcription: "aɪ ɪksˈpɛktɪd ˈbɛtə rɪˈzʌlts",
        translation: "I expected better results",
      },
      {
        id: 40,
        expression: "Я не мог представить это",
        transcription: "aɪ ˈkʊdnt ɪˈmæʤɪn ɪt",
        translation: "I couldn't imagine it",
      },
      {
        id: 41,
        expression: "Я начал думать о поездке в Соединенные Штаты",
        transcription:
          "aɪ ˈstɑːtɪd ˈθɪŋkɪŋ əˈbaʊt ˈgəʊɪŋ tuː ðə jʊˈnaɪtɪd steɪts",
        translation: "I started thinking about going to the United States",
      },
      {
        id: 42,
        expression:
          "У компании были большие убытки, потому что они управляли ей очень непрофессионально",
        transcription:
          "ðeɪ ˈkʌmpəni hæd bɪg ˈlɒsɪz bɪˈkɒz ðeɪ ˈmænɪʤd ɪt ˈvɛri ˌʌnprəˈfɛʃənli",
        translation:
          "They company had big losses because they managed it very unprofessionally",
      },
      {
        id: 43,
        expression:
          "Он слишком много курил, и он не понимал, как это было опасно для его здоровья",
        transcription:
          "hiː sməʊkt tuː mʌʧ ænd hiː dɪdnt ˌʌndəˈstænd haʊ ˈdeɪnʤrəs ɪt wɒz fɔː hɪz hɛlθ",
        translation:
          "He smoked too much and he didn't understand how dangerous it was for his health",
      },
      {
        id: 44,
        expression:
          "Я начал тратить больше времени на изучение английского языка",
        transcription: "aɪ ˈstɑːtɪd ˈspɛndɪŋ mɔː taɪm ɒn ˈlɜːnɪŋ ˈɪŋglɪʃ",
        translation: "I started spending more time on learning English",
      },
      {
        id: 45,
        expression: "Она описала внешний вид того мужчины",
        transcription: "ʃiː dɪsˈkraɪbd ði əˈpɪərəns ɒv ðæt mæn",
        translation: "She described the appearance of that man",
      },
      {
        id: 46,
        expression:
          "Ему приходилось очень усердно работать, чтобы заработать эти деньги",
        transcription: "hiː hæd tuː wɜːk ˈvɛri hɑːd tuː ɜːn ðɪs ˈmʌni",
        translation: "He had to work very hard to earn this money",
      },
      {
        id: 47,
        expression: "Я не мог сосредоточиться из-за того шума",
        transcription: "aɪ ˈkʊdnt ˈkɒnsəntreɪt bɪˈkɒz ɒv ðæt nɔɪz",
        translation: "I couldn't concentrate because of that noise",
      },
      {
        id: 48,
        expression: "Наш учитель все объяснил мне",
        transcription: "ˈaʊə ˈtiːʧər ɪksˈpleɪnd ˈɛvrɪθɪŋ tuː miː",
        translation: "Our teacher explained everything to me",
      },
      {
        id: 49,
        expression: "Они начали смеяться над ним",
        transcription: "ðeɪ ˈstɑːtɪd ˈlɑːfɪŋ æt hɪm",
        translation: "They started laughing at him",
      },
      {
        id: 50,
        expression: "Он не объяснил мне эту вещь",
        transcription: "hiː dɪdnt ɪksˈpleɪn ðɪs θɪŋ tuː miː",
        translation: "He didn't explain this thing to me",
      },
      {
        id: 51,
        expression: "Я осознавал, что это было очень важно",
        transcription: "aɪ ˈrɪəlaɪzd ðæt ɪt wɒz ˈvɛri ɪmˈpɔːtənt",
        translation: "I realised that it was very important",
      },
      {
        id: 52,
        expression: "Мои коллеги сообщили мне об этих трудностях",
        transcription: "maɪ ˈkɒliːgz ɪnˈfɔːmd miː ɒv ðiːz ˈdɪfɪkəltiz",
        translation: "My colleagues informed me of these difficulties",
      },
      {
        id: 53,
        expression: "Я осознал важность того решения",
        transcription: "aɪ ˈrɪəlaɪzd ði ɪmˈpɔːtəns ɒv ðæt dɪˈsɪʒən",
        translation: "I realised the importance of that decision",
      },
      {
        id: 54,
        expression: "Я хотел сделать это как можно скорее",
        transcription: "aɪ ˈwɒntɪd tuː duː ɪt æz suːn æz ˈpɒsəbl",
        translation: "I wanted to do it as soon as possible",
      },
      {
        id: 55,
        expression: "Я должен был идти на работу вчера",
        transcription: "aɪ hæd tuː gəʊ tuː wɜːk ˈjɛstədeɪ",
        translation: "I had to go to work yesterday",
      },
      {
        id: 56,
        expression: "Я начал думать об этом более серьезно",
        transcription: "aɪ ˈstɑːtɪd ˈθɪŋkɪŋ əˈbaʊt ɪt mɔː ˈsɪərɪəsli",
        translation: "I started thinking about it more seriously",
      },
      {
        id: 57,
        expression: "Он перестал думать об этом",
        transcription: "hiː stɒpt ˈθɪŋkɪŋ əˈbaʊt ɪt",
        translation: "He stopped thinking about it",
      },
      {
        id: 58,
        expression: "Я начал думать об этом чаще",
        transcription: "aɪ ˈstɑːtɪd ˈθɪŋkɪŋ əˈbaʊt ɪt mɔːr ˈɒf(ə)n",
        translation: "I started thinking about it more often",
      },
      {
        id: 59,
        expression: "Он решил бросить курить",
        transcription: "hi dɪˈsaɪdɪd tə stɒp ˈsməʊkɪŋ",
        translation: "He decided to stop smoking",
      },
      {
        id: 60,
        expression: "Я решил бросить курить",
        transcription: "aɪ dɪˈsaɪdɪd tə stɒp ˈsməʊkɪŋ",
        translation: "I decided to stop smoking",
      },
      {
        id: 61,
        expression: "Она решила бросить пить алкоголь",
        transcription: "ʃi dɪˈsaɪdɪd tə stɒp ˈdrɪŋkɪŋ ˈælkəhɒl",
        translation: "She decided to stop drinking alcohol",
      },
      {
        id: 62,
        expression: "Как это случилось с ним?",
        transcription: "haʊ dɪd ɪt ˈhæpən tə hɪm?",
        translation: "How did it happen to him?",
      },
      {
        id: 63,
        expression: "Когда это случилось с тобой?",
        transcription: "wɛn dɪd ɪt ˈhæpən tə juː?",
        translation: "When did it happen to you?",
      },
      {
        id: 64,
        expression: "Где это случилось с ней?",
        transcription: "weə dɪd ɪt ˈhæpən tə hɜː?",
        translation: "Where did it happen to her?",
      },
      {
        id: 65,
        expression: "Я решил не подписывать тот контракт",
        transcription: "aɪ dɪˈsaɪdɪd nɒt tə saɪn ðət ˈkɒntrækt",
        translation: "I decided not to sign that contract",
      },
      {
        id: 66,
        expression: "Я решил подписать этот контракт",
        transcription: "aɪ dɪˈsaɪdɪd tə sɪŋ ðɪs ˈkɒntrækt",
        translation: "I decided to sing this contract",
      },
      {
        id: 67,
        expression: "Он решил не подписывать тот документ",
        transcription: "hi dɪˈsaɪdɪd nɒt tə saɪn ðət ˈdɒkjʊmənt",
        translation: "He decided not to sign that document",
      },
      {
        id: 68,
        expression: "Когда это случилось с ними?",
        transcription: "wɛn dɪd ɪt ˈhæpən tə ðɛm?",
        translation: "When did it happen to them?",
      },
      {
        id: 69,
        expression: "Наш учитель объяснил нам все очень четко",
        transcription: "ˈaʊə ˈtiːʧər ɪksˈpleɪnd ˈɛvrɪθɪŋ tʊ əs ˈvɛri ˈklɪəli",
        translation: "Our teacher explained everything to us very clearly",
      },
      {
        id: 70,
        expression: "Их учитель объяснил им все",
        transcription: "ðeə ˈtiːʧər ɪksˈpleɪnd ˈɛvrɪθɪŋ tə ðɛm",
        translation: "Their teacher explained everything to them",
      },
      {
        id: 71,
        expression: "Мой учитель объяснил мне это очень четко",
        transcription: "maɪ ˈtiːʧər ɪksˈpleɪnd ɪt tə mi ˈvɛri ˈklɪəli",
        translation: "My teacher explained it to me very clearly",
      },
      {
        id: 72,
        expression: "Я слушал радио только иногда",
        transcription: "aɪ ˈlɪsnd tə ðə ˈreɪdɪəʊ ˈəʊnli ˈsʌmtaɪmz",
        translation: "I listened to the radio only sometimes",
      },
      {
        id: 73,
        expression: "Я часто слушал радио",
        transcription: "aɪ ˈɒf(ə)n ˈlɪsnd tə ðə ˈreɪdɪəʊ",
        translation: "I often listened to the radio",
      },
      {
        id: 74,
        expression: "Он не слушал радио",
        transcription: "hi dɪdnt ˈlɪsn tə ðə ˈreɪdɪəʊ",
        translation: "He didn't listen to the radio",
      },
      {
        id: 75,
        expression: "Я поспешил, чтобы не опоздать на автобус",
        transcription: "aɪ ˈhʌrɪd ʌp nɒt tə mɪs ðə bʌs",
        translation: "I hurried up not to miss the bus",
      },
      {
        id: 76,
        expression: "Я поспешил, чтобы не опоздать на этот автобус",
        transcription: "aɪ ˈhʌrɪd ʌp nɒt tə mɪs ðɪs bʌs",
        translation: "I hurried up not to miss this bus",
      },
      {
        id: 77,
        expression: "Он поспешил, чтобы не опоздать на поезд",
        transcription: "hi ˈhʌrɪd ʌp nɒt tə mɪs ðə treɪn",
        translation: "He hurried up not to miss the train",
      },
      {
        id: 78,
        expression: "Наш учитель разделил класс на пять групп",
        transcription: "ˈaʊə ˈtiːʧə dɪˈvaɪdɪd ðə klɑːs ˈɪntə faɪv gruːps",
        translation: "Our teacher divided the class into five groups",
      },
      {
        id: 79,
        expression: "Наш учитель разделил наш класс на четыре группы",
        transcription: "ˈaʊə ˈtiːʧə dɪˈvaɪdɪd ˈaʊə klɑːs ˈɪntə fɔː gruːps",
        translation: "Our teacher divided our class into four groups",
      },
      {
        id: 80,
        expression: "Твой учитель разделил класс на группы",
        transcription: "jə ˈtiːʧə dɪˈvaɪdɪd ðə klɑːs ˈɪntə gruːps",
        translation: "Your teacher divided the class into groups",
      },
      {
        id: 81,
        expression: "Мы начали работать вместе",
        transcription: "wi ˈstɑːtɪd ˈwɜːkɪŋ təˈgɛðə",
        translation: "We started working together",
      },
      {
        id: 82,
        expression: "Мы перестали работать вместе",
        transcription: "wi stɒpt ˈwɜːkɪŋ təˈgɛðə",
        translation: "We stopped working together",
      },
      {
        id: 83,
        expression: "Мы начали работать над этим проектом",
        transcription: "wi ˈstɑːtɪd ˈwɜːkɪŋ ɒn ðɪs ˈprɒʤɛkt",
        translation: "We started working on this project",
      },
      {
        id: 84,
        expression:
          "Он много курил и он не понимал, насколько плохо это было для его здоровья",
        transcription:
          "hi sməʊkt ə lɒt ənd hi dɪdnt ˌʌndəˈstænd haʊ bæd ɪt wəz fə hɪz hɛlθ",
        translation:
          "He smoked a lot and he didn't understand how bad it was for his health",
      },
      {
        id: 85,
        expression: "Я начал думать о переезде в другой город",
        transcription: "aɪ ˈstɑːtɪd ˈθɪŋkɪŋ əˈbaʊt ˈmuːvɪŋ tʊ əˈnʌðə ˈsɪti",
        translation: "I started thinking about moving to another city",
      },
      {
        id: 86,
        expression: "Она часто слушала радио",
        transcription: "ʃi ˈɒf(ə)n ˈlɪsnd tə ðə ˈreɪdɪəʊ",
        translation: "She often listened to the radio",
      },
      {
        id: 87,
        expression: "Учитель разделил нашу группу на три части",
        transcription: "ðə ˈtiːʧə dɪˈvaɪdɪd ˈaʊə gruːp ˈɪntə θriː pɑːts",
        translation: "The teacher divided our group into three parts",
      },
      {
        id: 88,
        expression: "Я осознавал важность этого события",
        transcription: "aɪ ˈrɪəlaɪzd ði ɪmˈpɔːtəns əv ðɪs ɪˈvɛnt",
        translation: "I realised the importance of this event",
      },
      {
        id: 89,
        expression: "Я ожидал более хорошего результата",
        transcription: "aɪ ɪksˈpɛktɪd ə ˈbɛtə rɪˈzʌlt",
        translation: "I expected a better result",
      },
      {
        id: 90,
        expression: "Я не хотел слушать это",
        transcription: "aɪ dɪdnt wɒnt tə ˈlɪsn tʊ ɪt",
        translation: "I didn't want to listen to it",
      },
      {
        id: 91,
        expression: "Я поторопился, потому что я не хотел опоздать на автобус",
        transcription: "aɪ ˈhʌrɪd ʌp bɪˈkəz aɪ dɪdnt wɒnt tə mɪs ðə bʌs",
        translation: "I hurried up because I didn't want to miss the bus",
      },
      {
        id: 92,
        expression: "Я не мог сосредоточиться из-за него",
        transcription: "aɪ ˈkʊdnt ˈkɒnsəntreɪt bɪˈkəz əv hɪm",
        translation: "I couldn't concentrate because of him",
      },
      {
        id: 93,
        expression: "Я сумел поступить в этот университет",
        transcription: "aɪ ˈmænɪʤd tʊ ˈɛntə ðɪs ˌjuːnɪˈvɜːsɪti",
        translation: "I managed to enter this university",
      },
      {
        id: 94,
        expression: "Я пытался забыть это",
        transcription: "aɪ traɪd tə fəˈgɛt ɪt",
        translation: "I tried to forget it",
      },
      {
        id: 95,
        expression: "Я отказался сделать это",
        transcription: "aɪ ˌriːˈfjuːzd tə dʊ ɪt",
        translation: "I refused to do it",
      },
      {
        id: 96,
        expression: "Мне пришлось работать весь день",
        transcription: "aɪ həd tə wɜːk ɔːl deɪ",
        translation: "I had to work all day",
      },
      {
        id: 97,
        expression: "Он объяснил это мне",
        transcription: "hi ɪksˈpleɪnd ɪt tə miː",
        translation: "He explained it to me",
      },
      {
        id: 98,
        expression: "Они сообщили мне об этой проблеме",
        transcription: "ðeɪ ɪnˈfɔːmd mi əv ðɪs ˈprɒbləm",
        translation: "They informed me of this problem",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Закрепление пройденного материала</h3>
</div>`,
    },
  ],
];
