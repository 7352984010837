export const contentLesson58 = [
  [
    {
      title: "Урок 58. Present Simple. Negative form",
    },
    [
      {
        id: 1,
        expression: "Он не ест шоколад",
        transcription: "hi dʌznt iːt ˈʧɒkəlɪt",
        translation: "He doesn't eat chocolate",
      },
      {
        id: 2,
        expression: "Мы не пьем кофе каждый день",
        transcription: "wi dəʊnt drɪŋk ˈkɒfi ˈɛvri deɪ",
        translation: "We don't drink coffee every day",
      },
      {
        id: 3,
        expression: "Я не читаю эти журналы",
        transcription: "aɪ dəʊnt riːd ðiːz ˌmægəˈziːnz",
        translation: "I don't read these magazines",
      },
      {
        id: 4,
        expression: "Она не пьет вино",
        transcription: "ʃi dʌznt drɪŋk waɪn",
        translation: "She doesn't drink wine",
      },
      {
        id: 5,
        expression: "Она не ест шоколад",
        transcription: "ʃi dʌznt iːt ˈʧɒkəlɪt",
        translation: "She doesn't eat chocolate",
      },
      {
        id: 6,
        expression: "Он не катается на лыжах",
        transcription: "hi dʌznt gəʊ ˈskiːɪŋ",
        translation: "He doesn't go skiing",
      },
      {
        id: 7,
        expression: "Он не читает книги",
        transcription: "hi dʌznt riːd bʊks",
        translation: "He doesn't read books",
      },
      {
        id: 8,
        expression: "Ей не нравится кататься на сноуборде",
        transcription: "ʃi dʌznt laɪk ˈsnəʊˌbɔːdɪŋ",
        translation: "She doesn't like snowboarding",
      },
      {
        id: 9,
        expression: "Она не убирает свою комнату",
        transcription: "ʃi dʌznt kliːn hə ruːm",
        translation: "She doesn't clean her room",
      },
      {
        id: 10,
        expression: "Ему не нравится его новая школа",
        transcription: "hi dʌznt laɪk ɪz njuː skuːl",
        translation: "He doesn't like his new school",
      },
      {
        id: 11,
        expression: "Он не играет в футбол",
        transcription: "hi dʌznt pleɪ ˈfʊtbɔːl",
        translation: "He doesn't play football",
      },
      {
        id: 12,
        expression: "Мы так не думаем",
        transcription: "wi dəʊnt θɪŋk səʊ",
        translation: "We don't think so",
      },
      {
        id: 13,
        expression: "Я не чувствую себя уставшим",
        transcription: "aɪ dəʊnt fiːl ˈtaɪəd",
        translation: "I don't feel tired",
      },
      {
        id: 14,
        expression: "Она не говорит по-английски",
        transcription: "ʃi dʌznt spiːk ˈɪŋglɪʃ",
        translation: "She doesn't speak English",
      },
      {
        id: 15,
        expression: "У него нет кота",
        transcription: "hi dʌznt həv ə kæt",
        translation: "He doesn't have a cat",
      },
      {
        id: 16,
        expression: "Он не работает каждый день",
        transcription: "hi dʌznt wɜːk ˈɛvri deɪ",
        translation: "He doesn't work every day",
      },
      {
        id: 17,
        expression: "Я не смотрю телевизор",
        transcription: "aɪ dəʊnt wɒʧ ˌtiːˈviː",
        translation: "I don't watch TV",
      },
      {
        id: 18,
        expression: "Они не живут в большом городе",
        transcription: "ðeɪ dəʊnt lɪv ɪn ə bɪg ˈsɪti",
        translation: "They don't live in a big city",
      },
      {
        id: 19,
        expression: "Она не любит компьютерные игры",
        transcription: "ʃi dʌznt laɪk kəmˈpjuːtə geɪmz",
        translation: "She doesn't like computer games",
      },
      {
        id: 20,
        expression: "Я не пью кофе каждое утро",
        transcription: "aɪ dəʊnt drɪŋk ˈkɒfi ˈɛvri ˈmɔːnɪŋ",
        translation: "I don't drink coffee every morning",
      },
      {
        id: 21,
        expression: "Она не чувствует никакого прогресса",
        transcription: "ʃi dʌznt fiːl ˈɛni ˈprəʊgrəs",
        translation: "She doesn't feel any progress",
      },
      {
        id: 22,
        expression: "У меня нет занятий в воскресенье",
        transcription: "aɪ dəʊnt həv ˈstʌdiz (ˈklɑːsɪz) ɒn ˈsʌndeɪ",
        translation: "I don't have studies (classes) on Sunday",
      },
      {
        id: 23,
        expression: "У них нет каких-либо вопросов",
        transcription: "ðeɪ dəʊnt həv ˈɛni ˈkwɛsʧənz",
        translation: "They don't have any questions",
      },
      {
        id: 24,
        expression: "Ему не нравится его работа",
        transcription: "hi dʌznt laɪk ɪz ʤɒb",
        translation: "He doesn't like his job",
      },
      {
        id: 25,
        expression: "Она не пьет кофе по утрам",
        transcription: "ʃi dʌznt drɪŋk ˈkɒfi ɪn ðə ˈmɔːnɪŋz",
        translation: "She doesn't drink coffee in the mornings",
      },
      {
        id: 26,
        expression: "Ей не нравится это платье",
        transcription: "ʃi dʌznt laɪk ðɪs drɛs",
        translation: "She doesn't like this dress",
      },
      {
        id: 27,
        expression: "У меня нет собаки",
        transcription: "aɪ dəʊnt həv ə dɒg",
        translation: "I don't have a dog",
      },
      {
        id: 28,
        expression: "Он не играет в шахматы",
        transcription: "hi dʌznt pleɪ ʧɛs",
        translation: "He doesn't play chess",
      },
      {
        id: 29,
        expression: "Я не помню ее номер телефона",
        transcription: "aɪ dəʊnt rɪˈmɛmbə hə fəʊn ˈnʌmbə",
        translation: "I don't remember her phone number",
      },
      {
        id: 30,
        expression: "Он не пьет чай утром",
        transcription: "hi dʌznt drɪŋk tiː ɪn ðə ˈmɔːnɪŋ",
        translation: "He doesn't drink tea in the morning",
      },
      {
        id: 31,
        expression: "Он не водит машину",
        transcription: "hi dʌznt draɪv ə kɑː",
        translation: "He doesn't drive a car",
      },
      {
        id: 32,
        expression: "Они не ходят в школу летом",
        transcription: "ðeɪ dəʊnt gəʊ tə skuːl ɪn ðə ˈsʌmə",
        translation: "They don't go to school in the summer",
      },
      {
        id: 33,
        expression: "Она не читает газеты",
        transcription: "ʃi dʌznt riːd ˈnjuːzˌpeɪpəz",
        translation: "She doesn't read newspapers",
      },
      {
        id: 34,
        expression: "Он не пьет пиво",
        transcription: "hi dʌznt drɪŋk bɪə",
        translation: "He doesn't drink beer",
      },
      {
        id: 35,
        expression: "Она не пьет молоко",
        transcription: "ʃi dʌznt drɪŋk mɪlk",
        translation: "She doesn't drink milk",
      },
      {
        id: 36,
        expression: "Она не живет в большом городе",
        transcription: "ʃi dʌznt lɪv ɪn ə bɪg ˈsɪti",
        translation: "She doesn't live in a big city",
      },
      {
        id: 37,
        expression: "Я не плачу за это",
        transcription: "aɪ dəʊnt peɪ fər ɪt",
        translation: "I don't pay for it",
      },
      {
        id: 38,
        expression: "Она не готовит каждый день",
        transcription: "ʃi dʌznt kʊk ˈɛvri deɪ",
        translation: "She doesn't cook every day",
      },
      {
        id: 39,
        expression: "Ему не нравится мясо",
        transcription: "hi dʌznt laɪk miːt",
        translation: "He doesn't like meat",
      },
      {
        id: 40,
        expression: "Она не курит",
        transcription: "ʃi dʌznt sməʊk",
        translation: "She doesn't smoke",
      },
      {
        id: 41,
        expression: "Я не пью алкоголь",
        transcription: "aɪ dəʊnt drɪŋk ˈælkəhɒl",
        translation: "I don't drink alcohol",
      },
      {
        id: 42,
        expression: "Я не ем слишком много по вечерам",
        transcription: "aɪ dəʊnt iːt tuː mʌʧ ɪn ði ˈiːvnɪŋz",
        translation: "I don't eat too much in the evenings",
      },
      {
        id: 43,
        expression:
          "Я не понимаю, почему так много людей не ведут здоровый образ жизни",
        transcription:
          "aɪ dəʊnt ˌʌndəˈstænd waɪ səʊ ˈmɛni ˈpiːpl dəʊnt liːd ə ˈhɛlθi ˈlaɪfˌstaɪl",
        translation:
          "I don't understand why so many people don't lead a healthy lifestyle",
      },
      {
        id: 44,
        expression: "Я не езжу на велосипеде каждую неделю",
        transcription: "aɪ dəʊnt gəʊ ˈsaɪklɪŋ ˈɛvri wiːk",
        translation: "I don't go cycling every week",
      },
      {
        id: 45,
        expression:
          "Он не работает вечером, потому что проводит время со своей семьей",
        transcription:
          "hi dʌznt wɜːk ɪn ði ˈiːvnɪŋ bɪˈkəz hi spɛndz taɪm wɪð ɪz ˈfæmɪli",
        translation:
          "He doesn't work in the evening because he spends time with his family",
      },
      {
        id: 46,
        expression: "Мы не встречаемся на выходных",
        transcription: "wi dəʊnt miːt ət ðə ˈwiːkˈɛndz",
        translation: "We don't meet at the weekends",
      },
      {
        id: 47,
        expression: "Я больше не хочу платить за это",
        transcription: "aɪ dəʊnt wɒnt tə peɪ fər ɪt ˌɛniˈmɔː",
        translation: "I don't want to pay for it anymore",
      },
      {
        id: 48,
        expression:
          "Он чувствует себя таким счастливым, потому что не платит за свое образование",
        transcription:
          "hi fiːlz səʊ ˈhæpi bɪˈkəz hi dʌznt peɪ fə hɪz ˌɛdju(ː)ˈkeɪʃən",
        translation:
          "He feels so happy because he doesn't pay for his education",
      },
      {
        id: 49,
        expression: "Она не играет в шахматы",
        transcription: "ʃi dʌznt pleɪ ʧɛs",
        translation: "She doesn't play chess",
      },
      {
        id: 50,
        expression: "Он больше не хочет это слышать",
        transcription: "hi dʌznt wɒnt tə hɪər ɪt ˌɛniˈmɔː",
        translation: "He doesn't want to hear it anymore",
      },
      {
        id: 51,
        expression: "Это не принадлежит ему",
        transcription: "ɪt dʌznt bɪˈlɒŋ tə hɪm",
        translation: "It doesn't belong to him",
      },
      {
        id: 52,
        expression: "Он не играет на скрипке, но он играет на гитаре",
        transcription: "hi dʌznt pleɪ ðə ˌvaɪəˈlɪn bət hi pleɪ ðə gɪˈtɑː",
        translation: "He doesn't play the violin but he play the guitar",
      },
      {
        id: 53,
        expression: "Она не хочет быть стоматологом",
        transcription: "ʃi dʌznt wɒnt tə bi ə ˈdɛntɪst",
        translation: "She doesn't want to be a dentist",
      },
      {
        id: 54,
        expression: "Мне не нравится рок музыка",
        transcription: "aɪ dəʊnt laɪk rɒk ˈmjuːzɪk",
        translation: "I don't like rock music",
      },
      {
        id: 55,
        expression: "Он не пытается ничего изменить",
        transcription: "hi dʌznt traɪ tə ʧeɪnʤ ˈɛnɪθɪŋ",
        translation: "He doesn't try to change anything",
      },
      {
        id: 56,
        expression: "Она не понимает, как это происходит",
        transcription: "ʃi dʌznt ˌʌndəˈstænd haʊ ɪt ˈhæpənz",
        translation: "She doesn't understand how it happens",
      },
      {
        id: 57,
        expression: "Ему не нравится джаз",
        transcription: "hi dʌznt laɪk ʤæz",
        translation: "He doesn't like jazz",
      },
      {
        id: 58,
        expression: "Я не прихожу туда позже",
        transcription: "aɪ dəʊnt kʌm ðeə ˈleɪtə",
        translation: "I don't come there later",
      },
      {
        id: 59,
        expression: "Она не приходит туда позже",
        transcription: "ʃi dʌznt kʌm ðeə ˈleɪtə",
        translation: "She doesn't come there later",
      },
      {
        id: 60,
        expression: "Я не прихожу сюда позже",
        transcription: "aɪ dəʊnt kʌm hɪə ˈleɪtə",
        translation: "I don't come here later",
      },
      {
        id: 61,
        expression: "Он не ходит в спортзал дважды в неделю",
        transcription: "hi dʌznt gəʊ tə ðə ʤɪm twaɪs ə wiːk",
        translation: "He doesn't go to the gym twice a week",
      },
      {
        id: 62,
        expression: "Я не хожу в спортзал дважды в месяц",
        transcription: "aɪ dəʊnt gəʊ tə ðə ʤɪm twaɪs ə mʌnθ",
        translation: "I don't go to the gym twice a month",
      },
      {
        id: 63,
        expression: "Она не ходит туда дважды в неделю",
        transcription: "ʃi dʌznt gəʊ ðeə twaɪs ə wiːk",
        translation: "She doesn't go there twice a week",
      },
      {
        id: 64,
        expression: "Я не езжу на работу",
        transcription: "aɪ dəʊnt draɪv tə wɜːk",
        translation: "I don't drive to work",
      },
      {
        id: 65,
        expression: "Он не ездит на работу",
        transcription: "hi dʌznt draɪv tə wɜːk",
        translation: "He doesn't drive to work",
      },
      {
        id: 66,
        expression: "Они не ездят в школу",
        transcription: "ðeɪ dəʊnt draɪv tə skuːl",
        translation: "They don't drive to school",
      },
      {
        id: 67,
        expression: "Они не ходят в спортзал",
        transcription: "ðeɪ dəʊnt gəʊ tə ðə ʤɪm",
        translation: "They don't go to the gym",
      },
      {
        id: 68,
        expression: "Он не ходит в спортзал",
        transcription: "hi dʌznt gəʊ tə ðə ʤɪm",
        translation: "He doesn't go to the gym",
      },
      {
        id: 69,
        expression: "Я не хожу в спортзал",
        transcription: "aɪ dəʊnt gəʊ tə ðə ʤɪm",
        translation: "I don't go to the gym",
      },
      {
        id: 70,
        expression: "Я не делаю покупки каждый день",
        transcription: "aɪ dəʊnt dʊ ðə ˈʃɒpɪŋ ˈɛvri deɪ",
        translation: "I don't do the shopping every day",
      },
      {
        id: 71,
        expression: "Она не делает покупки каждый день",
        transcription: "ʃi dʌznt dʊ ðə ˈʃɒpɪŋ ˈɛvri deɪ",
        translation: "She doesn't do the shopping every day",
      },
      {
        id: 72,
        expression: "Мы не делаем покупки каждую неделю",
        transcription: "wi dəʊnt dʊ ðə ˈʃɒpɪŋ ˈɛvri wiːk",
        translation: "We don't do the shopping every week",
      },
      {
        id: 73,
        expression: "Он не обедает на работе",
        transcription: "hi dʌznt həv lʌnʧ ət wɜːk",
        translation: "He doesn't have lunch at work",
      },
      {
        id: 74,
        expression: "Я не обедаю на работе",
        transcription: "aɪ dəʊnt həv lʌnʧ ət wɜːk",
        translation: "I don't have lunch at work",
      },
      {
        id: 75,
        expression: "Она не обедает дома",
        transcription: "ʃi dʌznt həv lʌnʧ ət həʊm",
        translation: "She doesn't have lunch at home",
      },
      {
        id: 76,
        expression: "Я не вижу никакой разницы между этими двумя вещами",
        transcription: "aɪ dəʊnt siː ˈɛni ˈdɪfrəns bɪˈtwiːn ðiːz tuː θɪŋz",
        translation: "I don't see any difference between these two things",
      },
      {
        id: 77,
        expression: "Я вижу некоторые различия между этими вещами",
        transcription: "aɪ siː səm ˈdɪfrənsɪz bɪˈtwiːn ðiːz θɪŋz",
        translation: "I see some differences between these things",
      },
      {
        id: 78,
        expression: "Он не видит никакой разницы между этими двумя вещами",
        transcription: "hi dʌznt siː ˈɛni ˈdɪfrəns bɪˈtwiːn ðiːz tuː θɪŋz",
        translation: "He doesn't see any difference between these two things",
      },
      {
        id: 79,
        expression: "У меня нет братьев или сестер",
        transcription: "aɪ dəʊnt həv ˈɛni ˈbrʌðəz ənd ˈsɪstəz",
        translation: "I don't have any brothers and sisters",
      },
      {
        id: 80,
        expression: "У него нет братьев или сестер",
        transcription: "hi dʌznt həv ˈɛni ˈbrʌðəz ɔː ˈsɪstəz",
        translation: "He doesn't have any brothers or sisters",
      },
      {
        id: 81,
        expression: "У него много братьев или сестер",
        transcription: "hi həz ə lɒt ɒv (ˈmɛni) ˈbrʌðəz ənd ˈsɪstəz",
        translation: "He has a lot of (many) brothers and sisters",
      },
      {
        id: 82,
        expression: "Это не кажется самой лучшей идеей",
        transcription: "ɪt dʌznt siːm ðə bɛst aɪˈdɪə",
        translation: "It doesn't seem the best idea",
      },
      {
        id: 83,
        expression: "Это не кажется хорошей идеей",
        transcription: "ɪt dʌznt siːm ə gʊd aɪˈdɪə",
        translation: "It doesn't seem a good idea",
      },
      {
        id: 84,
        expression: "Это кажется самой лучшей идеей",
        transcription: "ɪt siːmz ðə bɛst aɪˈdɪə",
        translation: "It seems the best idea",
      },
      {
        id: 85,
        expression: "Я не понимаю, почему это происходит",
        transcription: "aɪ dəʊnt ˌʌndəˈstænd waɪ ɪt ˈhæpənz",
        translation: "I don't understand why it happens",
      },
      {
        id: 86,
        expression: "Я не понимаю, когда это происходит",
        transcription: "aɪ dəʊnt ˌʌndəˈstænd wɛn ɪt ˈhæpənz",
        translation: "I don't understand when it happens",
      },
      {
        id: 87,
        expression: "Я не знаю, почему это происходит",
        transcription: "aɪ dəʊnt nəʊ waɪ ɪt ˈhæpənz",
        translation: "I don't know why it happens",
      },
      {
        id: 88,
        expression: "Она не работает по выходным",
        transcription: "ʃi dʌznt wɜːk ət ðə ˈwiːkˈɛndz",
        translation: "She doesn't work at the weekends",
      },
      {
        id: 89,
        expression: "Это не принадлежит им",
        transcription: "ɪt dʌznt bɪˈlɒŋ tə ðɛm",
        translation: "It doesn't belong to them",
      },
      {
        id: 90,
        expression: "Она не играет на пианино",
        transcription: "ʃi dʌznt pleɪ ðə pɪˈænəʊ",
        translation: "She doesn't play the piano",
      },
      {
        id: 91,
        expression: "Я не хожу за покупками так часто",
        transcription: "aɪ dəʊnt gəʊ ˈʃɒpɪŋ səʊ ˈɒf(ə)n",
        translation: "I don't go shopping so often",
      },
      {
        id: 92,
        expression: "Я не пользуюсь общественным транспортом так часто",
        transcription: "aɪ dəʊnt juːz ˈpʌblɪk ˈtrænspɔːt səʊ ˈɒf(ə)n",
        translation: "I don't use public transport so often",
      },
      {
        id: 93,
        expression: "У нее нет никаких близких родственников",
        transcription: "ʃi dʌznt həv ˈɛni kləʊs ˈrɛlətɪvz",
        translation: "She doesn't have any close relatives",
      },
      {
        id: 94,
        expression: "Ей не нравится поп музыка",
        transcription: "ʃi dʌznt laɪk pɒp ˈmjuːzɪk",
        translation: "She doesn't like pop music",
      },
      {
        id: 95,
        expression: "Я думаю, это не стоит этих денег",
        transcription: "aɪ θɪŋk ɪt dʌznt kɒst ðɪs ˈmʌni",
        translation: "I think it doesn't cost this money",
      },
      {
        id: 96,
        expression: "Он не играет на скрипке",
        transcription: "hi dʌznt pleɪ ðə ˌvaɪəˈlɪn",
        translation: "He doesn't play the violin",
      },
      {
        id: 97,
        expression: "Я не думаю, что это так хорошо",
        transcription: "aɪ dəʊnt θɪŋk ðət ɪts səʊ gʊd",
        translation: "I don't think that it's so good",
      },
      {
        id: 98,
        expression: "Я не работаю на выходных",
        transcription: "aɪ dəʊnt wɜːk ət ðə ˈwiːkˈɛndz",
        translation: "I don't work at the weekends",
      },
      {
        id: 99,
        expression: "Я не хожу плавать так часто",
        transcription: "aɪ dəʊnt gəʊ ˈswɪmɪŋ səʊ ˈɒf(ə)n",
        translation: "I don't go swimming so often",
      },
      {
        id: 100,
        expression:
          "Он не ведет здоровый образ жизни, и я думаю, что это очень плохо",
        transcription:
          "hi dʌznt liːd ə ˈhɛlθi ˈlaɪfˌstaɪl ənd aɪ θɪŋk ðət ɪts ˈvɛri bæd",
        translation:
          "He doesn't lead a healthy lifestyle and I think that it's very bad",
      },
      {
        id: 101,
        expression: "Я не играю на гитаре",
        transcription: "aɪ dəʊnt pleɪ ðə gɪˈtɑː",
        translation: "I don't play the guitar",
      },
      {
        id: 102,
        expression: "Я не езжу на работу на автобусе",
        transcription: "aɪ dəʊnt gəʊ tə wɜːk baɪ bʌs",
        translation: "I don't go to work by bus",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Закрепление пройденного материала</h3>
</div>`,
    },
  ],
];
