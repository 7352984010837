export const wordsLesson251 = [
  [
    {
      id: 1,
      word: 'lay off',
      transcription: 'leɪ ɒf',
      translation: 'увольнять',
    },
    {
      id: 2,
      word: 'cling to',
      transcription: 'klɪŋ tuː',
      translation: 'придерживаться',
    },
    {
      id: 3,
      word: 'figure out',
      transcription: 'ˈfɪɡər aʊt',
      translation: 'понимать, вникать',
    },
    {
      id: 4,
      word: 'mull over',
      transcription: 'mʌl ˈəʊvə',
      translation: 'обдумывать',
    },
    {
      id: 5,
      word: 'get back to',
      transcription: 'ɡɛt bæk tuː',
      translation: 'возвращаться к',
    },
    {
      id: 6,
      word: 'feel up to',
      transcription: 'fiːl ʌp tuː',
      translation: 'хотеть',
    },
    {
      id: 7,
      word: 'take up',
      transcription: 'teɪk ʌp',
      translation: 'начать заниматься',
    },
    {
      id: 8,
      word: 'dawn on',
      transcription: 'dɔːn ɒn',
      translation: "осенять, доходить [до 'мозга']",
    },
    {
      id: 9,
      word: 'face up to',
      transcription: 'feɪs ʌp tuː',
      translation: 'смириться с, принять',
    },
    {
      id: 10,
      word: 'come up with',
      transcription: 'kʌm ʌp wɪð',
      translation: 'приходить в голову',
    },
    {
      id: 11,
      word: 'bank on',
      transcription: 'bæŋk ɒn',
      translation: 'полагаться на',
    },
    {
      id: 12,
      word: 'pick up',
      transcription: 'pɪk ʌp',
      translation: 'поднимать, забирать, осваивать',
    },
    {
      id: 13,
      word: 'churn out',
      transcription: 'ʧɜːn aʊt',
      translation: 'штамповать, выпускать в больших количествах',
    },
    {
      id: 14,
      word: 'pick up on',
      transcription: 'pɪk ʌp ɒn',
      translation: 'улавливать',
    },
    {
      id: 15,
      word: 'pull in',
      transcription: 'pʊl ɪn',
      translation: 'зарабатывать',
    },
    {
      id: 16,
      word: 'own up to',
      transcription: 'əʊn ʌp tuː',
      translation: 'признаться в',
    },
    {
      id: 17,
      word: 'liven up',
      transcription: 'ˈlaɪvn ʌp',
      translation: 'оживлять, освежать',
    },
    {
      id: 18,
      word: 'point out',
      transcription: 'pɔɪnt aʊt',
      translation: 'замечать',
    },
    {
      id: 19,
      word: 'take to',
      transcription: 'teɪk tuː',
      translation: 'начать делать что-то на регулярной основе',
    },
    {
      id: 20,
      word: 'accomplishment',
      transcription: 'əˈkɒmplɪʃmənt',
      translation: 'достижение',
    },
    {
      id: 21,
      word: 'anxiety',
      transcription: 'æŋˈzaɪəti',
      translation: 'тревога',
    },
    {
      id: 22,
      word: 'deceit',
      transcription: 'dɪˈsiːt',
      translation: 'обман',
    },
    {
      id: 23,
      word: 'drastic',
      transcription: 'ˈdræstɪk',
      translation: 'решительный',
    },
    {
      id: 24,
      word: 'gradually',
      transcription: 'ˈɡrædjʊəli',
      translation: 'постепенно',
    },
    {
      id: 25,
      word: 'identical',
      transcription: 'aɪˈdɛntɪkəl',
      translation: 'идентичный',
    },
    {
      id: 26,
      word: 'instantly',
      transcription: 'ˈɪnstəntli',
      translation: 'мгновенный',
    },
    {
      id: 27,
      word: 'nervousness',
      transcription: 'ˈnɜːvəsnəs',
      translation: 'нервозность',
    },
    {
      id: 28,
      word: 'prolific',
      transcription: 'prəʊˈlɪfɪk',
      translation: 'плодовитый, плодотворный',
    },
    {
      id: 29,
      word: 'undeniably',
      transcription: 'ˌʌndɪˈnaɪəbᵊli',
      translation: 'бесспорно',
    },
  ],
];
