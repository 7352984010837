export const contentLesson240 = [
  [
    {
      title: 'Урок 240. Common Mistakes. Part 2',
    },
    [
      {
        id: 1,
        expression: 'Я хотел бы знать, насколько высок мой уровень',
        transcription: 'aɪd laɪk tuː nəʊ haʊ haɪ maɪ ˈlɛvl ɪz',
        translation: 'I’d like to know how high my level is',
      },
      {
        id: 2,
        expression: 'Ей не очень нравится слушать классическую музыку',
        transcription: 'ʃiː dʌznt ˈrɪəli laɪk ˈlɪsnɪŋ tuː ˈklæsɪkəl ˈmjuːzɪk',
        translation: 'She doesn’t really like listening to classical music',
      },
      {
        id: 3,
        expression: 'Он в основном расплачивается кредитной картой',
        transcription: 'hiː ˈməʊstli peɪz baɪ ˈkrɛdɪt kɑːd',
        translation: 'He mostly pays by credit card',
      },
      {
        id: 4,
        expression: 'Они установили новое электрооборудование',
        transcription: 'ðeɪ ɪnˈstɔːld ə njuː ɪˈlɛktrɪkəl ɪˈkwɪpmənt',
        translation: 'They installed a new electrical equipment',
      },
      {
        id: 5,
        expression: 'Я вижу, ты в прекрасной форме',
        transcription: 'aɪ siː jʊər ɪn ˈpɜːfɪkt ʃeɪp',
        translation: 'I see you’re in perfect shape',
      },
      {
        id: 6,
        expression: 'Я должен сказать, что это классический пример',
        transcription: 'aɪ hæv tuː seɪ ðæt ɪts ə ˈklæsɪk ɪɡˈzɑːmpl',
        translation: 'I have to say that it’s a classic example',
      },
      {
        id: 7,
        expression: 'Я бы хотел заплатить кредитной картой',
        transcription: 'aɪd laɪk tuː peɪ baɪ ˈkrɛdɪt kɑːd',
        translation: 'I’d like to pay by credit card',
      },
      {
        id: 8,
        expression: 'Я хочу знать, как далеко это',
        transcription: 'aɪ wɒnt tuː nəʊ haʊ fɑːr ɪt ɪz',
        translation: 'I want to know how far it is',
      },
      {
        id: 9,
        expression: 'В самом начале я не обратил на это внимания',
        transcription: 'aɪ dɪdnt peɪ əˈtɛnʃᵊn tuː ɪt ɪn ðə bɪˈɡɪnɪŋ',
        translation: 'I didn’t pay attention to it in the beginning',
      },
      {
        id: 10,
        expression: 'Он спросил меня, сколько было времени',
        transcription: 'hiː ɑːskt miː wɒt ðə taɪm wɒz',
        translation: 'He asked me what the time was',
      },
      {
        id: 11,
        expression: 'То, что мы видим, это классический пример',
        transcription: 'wɒt wiː siː ɪz ə ˈklæsɪk ɪɡˈzɑːmpl',
        translation: 'What we see is a classic example',
      },
      {
        id: 12,
        expression: 'У нас был электрический обогреватель',
        transcription: 'wiː hæd ən ɪˈlɛktrɪk ˈhiːtə',
        translation: 'We had an electric heater',
      },
      {
        id: 13,
        expression: 'Никто из нас не хотел делать это',
        transcription: 'nʌn ɒv ʌs ˈwɒntɪd tuː duː ɪt',
        translation: 'None of us wanted to do it',
      },
      {
        id: 14,
        expression: 'Я обычно предпочитаю платить наличными',
        transcription: 'aɪ ˈjuːʒʊəli priˈfɜː tuː peɪ ɪn kæʃ',
        translation: 'I usually prefer to pay in cash',
      },
      {
        id: 15,
        expression: 'Я вижу, что он очень плохо говорит по-английски',
        transcription: 'aɪ siː ðæt hiː spiːks ˈɪŋɡlɪʃ ˈvɛri ˈbædli',
        translation: 'I see that he speaks English very badly',
      },
      {
        id: 16,
        expression: 'Он едва мог дышать',
        transcription: 'hiː kʊd ˈhɑːdli briːð',
        translation: 'He could hardly breathe',
      },
      {
        id: 17,
        expression: 'Эта машина очень экономична',
        transcription: 'ðɪs kɑːr ɪz ˈvɛri ˌiːkəˈnɒmɪkəl',
        translation: 'This car is very economical',
      },
      {
        id: 18,
        expression: 'Дом был в ужасном состоянии',
        transcription: 'ðə haʊs wɒz ɪn ˈtɛrəbᵊl kənˈdɪʃᵊn',
        translation: 'The house was in terrible condition',
      },
      {
        id: 19,
        expression: 'Я едва мог понять это',
        transcription: 'aɪ kʊd ˈhɑːdli ˌʌndəˈstænd ɪt',
        translation: 'I could hardly understand it',
      },
      {
        id: 20,
        expression: 'Они купили некоторые электрические кухонные приборы',
        transcription: 'ðeɪ bɔːt sʌm ɪˈlɛktrɪkəl ˈkɪʧɪn əˈplaɪənsɪz',
        translation: 'They bought some electrical kitchen appliances',
      },
      {
        id: 21,
        expression: 'Их машина в хорошем состоянии',
        transcription: 'ðeə kɑːr ɪz ɪn ɡʊd kənˈdɪʃᵊn',
        translation: 'Their car is in good condition',
      },
      {
        id: 22,
        expression: 'Нам также нужно учитывать экономические факторы',
        transcription: 'wiː ˈɔːlsəʊ niːd tuː kənˈsɪdər ˌiːkəˈnɒmɪk ˈfæktəz',
        translation: 'We also need to consider economic factors',
      },
      {
        id: 23,
        expression: 'Я хотел бы знать, насколько это дорого',
        transcription: 'aɪd laɪk tuː nəʊ haʊ ɪksˈpɛnsɪv ɪt ɪz',
        translation: 'I’d like to know how expensive it is',
      },
      {
        id: 24,
        expression: 'Эта модель до сих пор доступна на рынке',
        transcription: 'ðɪs ˈmɒdl ɪz stɪl əˈveɪləbᵊl ɒn ðə ˈmɑːkɪt',
        translation: 'This model is still available on the market',
      },
      {
        id: 25,
        expression: 'Я бы хотел заплатить наличными',
        transcription: 'aɪd laɪk tuː peɪ ɪn kæʃ',
        translation: 'I’d like to pay in cash',
      },
      {
        id: 26,
        expression: 'Никто из нас не хочет этого',
        transcription: 'nʌn ɒv ʌs wɒnts ɪt',
        translation: 'None of us wants it',
      },
      {
        id: 27,
        expression: 'У него была электрогитара',
        transcription: 'hiː hæd ən ɪˈlɛktrɪk ɡɪˈtɑː',
        translation: 'He had an electric guitar',
      },
      {
        id: 28,
        expression: 'Это классическая ошибка',
        transcription: 'ɪts ə ˈklæsɪk mɪsˈteɪk',
        translation: 'It’s a classic mistake',
      },
      {
        id: 29,
        expression: 'Это электрический провод',
        transcription: 'ɪts ən ɪˈlɛktrɪk ˈwaɪə',
        translation: 'It’s an electric wire',
      },
      {
        id: 30,
        expression: 'Она в хорошей форме',
        transcription: 'ʃiːz ɪn ɡʊd ʃeɪp',
        translation: 'She’s in good shape',
      },
      {
        id: 31,
        expression: 'Ты проделал отличную работу',
        transcription: 'juː dɪd ə ɡreɪt ʤɒb',
        translation: 'You did a great job',
      },
      {
        id: 32,
        expression: 'Ты можешь купить это на рынке',
        transcription: 'juː kæn baɪ ɪt ɒn ðə ˈmɑːkɪt',
        translation: 'You can buy it on the market',
      },
      {
        id: 33,
        expression: 'Эта страна нуждается в экономической поддержке',
        transcription: 'ðɪs ˈkʌntri niːdz ˌiːkəˈnɒmɪk səˈpɔːt',
        translation: 'This country needs economic support',
      },
      {
        id: 34,
        expression:
          'Я хотел бы знать, насколько хороши мои знания английского языка',
        transcription: 'aɪd laɪk tuː nəʊ haʊ maɪ ˈɪŋɡlɪʃ ˈnɒlɪʤ ɪz',
        translation: 'I’d like to know how my English knowledge is',
      },
      {
        id: 35,
        expression: 'Она спросила меня, который час',
        transcription: 'ʃiː ɑːskt miː wɒt taɪm ɪt wɒz',
        translation: 'She asked me what time it was',
      },
      {
        id: 36,
        expression: 'Никто из них, кажется, не знает об этих опасностях',
        transcription: 'nʌn ɒv ðɛm siːmz əˈweər ɒv ðiːz ˈdeɪnʤəz',
        translation: 'None of them seems aware of these dangers',
      },
      {
        id: 37,
        expression: 'Я обычно плачу наличными',
        transcription: 'aɪ ˈjuːʒʊəli peɪ ɪn kæʃ',
        translation: 'I usually pay in cash',
      },
      {
        id: 38,
        expression: 'Я в основном использую электронный калькулятор',
        transcription: 'aɪ ˈməʊstli juːz ən ɪlɛkˈtrɒnɪk ˈkælkjʊleɪtə',
        translation: 'I mostly use an electronic calculator',
      },
      {
        id: 39,
        expression: 'Его машина в идеальном состоянии',
        transcription: 'hɪz kɑːr ɪz ɪn ˈpɜːfɪkt kənˈdɪʃᵊn',
        translation: 'His car is in perfect condition',
      },
      {
        id: 40,
        expression: 'Эта стиральная машина очень экономична',
        transcription: 'ðɪs ˈwɒʃɪŋ məˈʃiːn ɪz ˈvɛri ˌiːkəˈnɒmɪkəl',
        translation: 'This washing machine is very economical',
      },
      {
        id: 41,
        expression:
          'Это неправда, что только крупные компании нуждаются в поддержке со стороны государства',
        transcription:
          'ɪt ˈɪznt truː ðæt ˈəʊnli bɪɡ ˈkʌmpəniz niːd səˈpɔːt frɒm ðə ˈɡʌvnmənt',
        translation:
          'It isn’t true that only big companies need support from the government',
      },
      {
        id: 42,
        expression: 'Чистый лист бумаги был выдан каждому из студентов',
        transcription:
          'ə kliːn ʃiːt ɒv ˈpeɪpə wɒz ˈɡɪvn tuː iːʧ ɒv ðə ˈstjuːdənts',
        translation: 'A clean sheet of paper was given to each of the students',
      },
      {
        id: 43,
        expression:
          'Это заставляет меня чувствовать, что большинство людей поддержат эту идею',
        transcription:
          'ɪt meɪks miː fiːl ðə məˈʤɒrɪti ɒv ˈpiːpl wɪl səˈpɔːt ðɪs aɪˈdɪə',
        translation:
          'It makes me feel the majority of people will support this idea',
      },
      {
        id: 44,
        expression:
          'Ожидается, что скоро экономика будет находиться в лучшем состоянии',
        transcription:
          'ɪts ɪksˈpɛktɪd ðæt ði iˈkɒnəmi wɪl biː ɪn ˈbɛtə ʃeɪp suːn',
        translation:
          'It’s expected that the economy will be in better shape soon',
      },
      {
        id: 45,
        expression: 'Они говорят, что они осознают эти возможные риски',
        transcription: 'ðeɪ seɪ ðæt ðeɪ ɑː ˈkɒnʃəs ɒv ðiːz ˈpɒsəbᵊl rɪsks',
        translation: 'They say that they are conscious of these possible risks',
      },
      {
        id: 46,
        expression: 'На выставке было большое количество людей',
        transcription: 'ðeə wɒz ə lɑːʤ ˈnʌmbər ɒv ˈpiːpl æt ði ˌɛksɪˈbɪʃᵊn',
        translation: 'There was a large number of people at the exhibition',
      },
      {
        id: 47,
        expression: 'Это не самая лучшая модель, которая доступна на рынке',
        transcription: 'ɪt ˈɪznt ðə bɛst ˈmɒdl wɪʧ ɪz əˈveɪləbᵊl ɒn ðə ˈmɑːkɪt',
        translation: 'It isn’t the best model which is available on the market',
      },
      {
        id: 48,
        expression:
          'Эта страна испытывает очень серьезные экономические проблемы',
        transcription:
          'ðɪs ˈkʌntri ɪz ɪksˈpɪərɪənsɪŋ ˈvɛri ˈsɪərɪəs ˌiːkəˈnɒmɪk ˈprɒbləmz',
        translation:
          'This country is experiencing very serious economic problems',
      },
      {
        id: 49,
        expression: 'Каждому из студентов был выдан чистый лист бумаги',
        transcription: 'iːʧ ɒv ðə ˈstjuːdənts wɒz ˈɡɪvn ə kliːn ʃiːt ɒv ˈpeɪpə',
        translation: 'Each of the students was given a clean sheet of paper',
      },
      {
        id: 50,
        expression: 'Ты можешь скачать все эти материалы бесплатно',
        transcription: 'juː kæn ˌdaʊnˈləʊd ɔːl ðiːz məˈtɪərɪəlz friː ɒv ʧɑːʤ',
        translation: 'You can download all these materials free of charge',
      },
      {
        id: 51,
        expression: 'Я сомневаюсь в эффективности того метода',
        transcription: 'aɪ daʊt ði ɪˈfɪʃənsi ɒv ðæt ˈmɛθəd',
        translation: 'I doubt the efficiency of that method',
      },
      {
        id: 52,
        expression: 'Тебе нравится читать исторические романы?',
        transcription: 'duː juː laɪk ˈriːdɪŋ hɪsˈtɒrɪkəl ˈnɒvəlz?',
        translation: 'Do you like reading historical novels?',
      },
      {
        id: 53,
        expression: 'Как мы все знаем, внешность обманчива',
        transcription: 'æz wiː ɔːl nəʊ, ði əˈpɪərənsɪz ɑː dɪˈsɛptɪv',
        translation: 'As we all know, the appearances are deceptive',
      },
      {
        id: 54,
        expression: 'Эти услуги бесплатны',
        transcription: 'ðiːz ˈsɜːvɪsɪz ɑː friː ɒv ʧɑːʤ',
        translation: 'These services are free of charge',
      },
      {
        id: 55,
        expression: 'Она была действительно раздражена им',
        transcription: 'ʃiː wɒz ˈrɪəli əˈnɔɪd wɪð hɪm',
        translation: 'She was really annoyed with him',
      },
      {
        id: 56,
        expression: 'Это действительно стоит больших денег',
        transcription: 'ɪt dʌz kɒst ə ɡreɪt diːl ɒv ˈmʌni',
        translation: 'It does cost a great deal of money',
      },
      {
        id: 57,
        expression: 'У нас очень много денег',
        transcription: 'wiː hæv ə ɡreɪt diːl ɒv ˈmʌni',
        translation: 'We have a great deal of money',
      },
      {
        id: 58,
        expression: 'Это будет стоить очень много денег',
        transcription: 'ˈɪtl kɒst ə ɡreɪt diːl ɒv ˈmʌni',
        translation: 'It’ll cost a great deal of money',
      },
      {
        id: 59,
        expression: 'В настоящее время экономика кажется в худшем состоянии',
        transcription: 'ði iˈkɒnəmi siːmz ɪn wɜːs ʃeɪp æt ˈprɛznt',
        translation: 'The economy seems in worse shape at present',
      },
      {
        id: 60,
        expression: 'В настоящее время она находится в прекрасной форме',
        transcription: 'ʃiːz ɪn ˈpɜːfɪkt ʃeɪp æt ˈprɛznt',
        translation: 'She’s in perfect shape at present',
      },
      {
        id: 61,
        expression: 'Я думаю, что экономика скоро будет в лучшем состоянии',
        transcription: 'aɪ θɪŋk ðæt ði iˈkɒnəmi wɪl biː ɪn ˈbɛtə ʃeɪp suːn',
        translation: 'I think that the economy will be in better shape soon',
      },
      {
        id: 62,
        expression: 'Ты проделал великолепную работу',
        transcription: 'juː dɪd ə ˈmɑːvələs ʤɒb',
        translation: 'You did a marvelous job',
      },
      {
        id: 63,
        expression: 'Ты проделал отличную работу',
        transcription: 'juː dɪd ən ˈɛksələnt ʤɒb',
        translation: 'You did an excellent job',
      },
      {
        id: 64,
        expression: 'Они проделали невероятную работу',
        transcription: 'ðeɪ dɪd ən ɪnˈkrɛdəbᵊl ʤɒb',
        translation: 'They did an incredible job',
      },
      {
        id: 65,
        expression: 'У меня и моего друга много общего',
        transcription: 'maɪ frɛnd ænd aɪ hæv mʌʧ ɪn ˈkɒmən',
        translation: 'My friend and I have much in common',
      },
      {
        id: 66,
        expression: 'У них очень много общего',
        transcription: 'ðeɪ hæv ə lɒt ɪn ˈkɒmən',
        translation: 'They have a lot in common',
      },
      {
        id: 67,
        expression: 'Я думаю, что у нас много общего',
        transcription: 'aɪ θɪŋk ðæt wiː hæv mʌʧ ɪn ˈkɒmən',
        translation: 'I think that we have much in common',
      },
      {
        id: 68,
        expression: 'У них очень много денег',
        transcription: 'ðeɪ hæv ə ɡreɪt diːl ɒv ˈmʌni',
        translation: 'They have a great deal of money',
      },
      {
        id: 69,
        expression: 'Это требует больших денег',
        transcription: 'ɪt rɪˈkwaɪəz ə ɡreɪt diːl ɒv ˈmʌni',
        translation: 'It requires a great deal of money',
      },
      {
        id: 70,
        expression: 'Он занял очень много денег',
        transcription: 'hiː ˈbɒrəʊd ə ɡreɪt diːl ɒv ˈmʌni',
        translation: 'He borrowed a great deal of money',
      },
      {
        id: 71,
        expression: 'У нас очень много общего',
        transcription: 'wiː hæv ə lɒt ɪn ˈkɒmən',
        translation: 'We have a lot in common',
      },
      {
        id: 72,
        expression: 'У нас нет ничего общего',
        transcription: 'wiː hæv ˈnʌθɪŋ ɪn ˈkɒmən',
        translation: 'We have nothing in common',
      },
      {
        id: 73,
        expression: 'У этих людей много общего',
        transcription: 'ðiːz ˈpiːpl hæv mʌʧ ɪn ˈkɒmən',
        translation: 'These people have much in common',
      },
      {
        id: 74,
        expression: 'Это историческое место',
        transcription: 'ɪts ə hɪsˈtɒrɪk pleɪs',
        translation: 'It’s a historic place',
      },
      {
        id: 75,
        expression: 'Мне нравится читать исторические романы',
        transcription: 'aɪ laɪk ˈriːdɪŋ hɪsˈtɒrɪkəl ˈnɒvəlz',
        translation: 'I like reading historical novels',
      },
      {
        id: 76,
        expression: 'Это исторический документ?',
        transcription: 'ɪz ɪt ən hɪsˈtɒrɪkəl ˈdɒkjʊmənt?',
        translation: 'Is it an historical document?',
      },
      {
        id: 77,
        expression: 'Есть другая вещь, которую стоит обсудить',
        transcription: 'ðeər ɪz əˈnʌðə θɪŋ wɪʧ ɪz wɜːθ dɪsˈkʌsɪŋ',
        translation: 'There is another thing which is worth discussing',
      },
      {
        id: 78,
        expression: 'Есть другие проблемы, которые стоит обсудить',
        transcription: 'ðeər ɑːr ˈʌðə ˈprɒbləmz wɪʧ ɑː wɜːθ dɪsˈkʌsɪŋ',
        translation: 'There are other problems which are worth discussing',
      },
      {
        id: 79,
        expression: 'Есть другой вопрос, который стоит рассмотреть',
        transcription: 'ðeər ɪz əˈnʌðər ˈɪʃuː wɪʧ ɪz wɜːθ kənˈsɪdərɪŋ',
        translation: 'There is another issue which is worth considering',
      },
      {
        id: 80,
        expression: 'Доставка этого заказа будет бесплатна',
        transcription: 'ðə dɪˈlɪvəri ɒv ðɪs ˈɔːdə wɪl biː friː ɒv ʧɑːʤ',
        translation: 'The delivery of this order will be free of charge',
      },
      {
        id: 81,
        expression: 'Доставка этих товаров будет бесплатной',
        transcription: 'ðə dɪˈlɪvəri ɒv ðiːz ɡʊdz wɪl biː friː ɒv ʧɑːʤ',
        translation: 'The delivery of these goods will be free of charge',
      },
      {
        id: 82,
        expression: 'Доставка этого заказа будет бесплатной?',
        transcription: 'wɪl ðə dɪˈlɪvəri ɒv ðɪs ˈɔːdə biː friː ɒv ʧɑːʤ?',
        translation: 'Will the delivery of this order be free of charge?',
      },
      {
        id: 83,
        expression: 'Я осознаю эти риски',
        transcription: 'aɪ ˈkɒnʃəs ɒv ðiːz rɪsks',
        translation: 'I conscious of these risks',
      },
      {
        id: 84,
        expression: 'Я осознаю свои возможности',
        transcription: 'aɪ ˈkɒnʃəs ɒv maɪ ˌɒpəˈtjuːnɪtiz',
        translation: 'I conscious of my opportunities',
      },
      {
        id: 85,
        expression: 'Они говорят, что осознают эти риски',
        transcription: 'ðeɪ seɪ ðeɪ ɑː ˈkɒnʃəs ɒv ðiːz rɪsks',
        translation: 'They say they are conscious of these risks',
      },
      {
        id: 86,
        expression: 'Мы посетили несколько исторических мест',
        transcription: 'wiː ˈvɪzɪtɪd ˈsɛvrəl hɪsˈtɒrɪk ˈpleɪsɪz',
        translation: 'We visited several historic places',
      },
      {
        id: 87,
        expression: 'Как мы все знаем, внешность может быть обманчива',
        transcription: 'æz wiː ɔːl nəʊ ði əˈpɪərənsɪz kæn biː dɪˈsɛptɪv',
        translation: 'As we all know the appearances can be deceptive',
      },
      {
        id: 88,
        expression: 'Есть еще один момент, который следует упомянуть',
        transcription: 'ðeər ɪz əˈnʌðə pɔɪnt wɪʧ ʃʊd biː ˈmɛnʃᵊnd',
        translation: 'There is another point which should be mentioned',
      },
      {
        id: 89,
        expression: 'Мне нужно перевести эти деньги на мой счет',
        transcription: 'aɪ niːd tuː ˈtrænsfɜː ðɪs ˈmʌni tuː maɪ əˈkaʊnt',
        translation: 'I need to transfer this money to my account',
      },
      {
        id: 90,
        expression: 'Она была действительно раздражена теми вопросами',
        transcription: 'ʃiː wɒz ˈrɪəli ˈɪrɪteɪtɪd baɪ ðəʊz ˈkwɛsʧənz',
        translation: 'She was really irritated by those questions',
      },
      {
        id: 91,
        expression: 'Я не вижу содержания книги',
        transcription: 'aɪ dəʊnt siː ðə ˈkɒntɛnts ɒv ðə bʊk',
        translation: 'I don’t see the contents of the book',
      },
      {
        id: 92,
        expression: 'Я потратил на это очень много времени',
        transcription: 'aɪ spɛnt ə ɡreɪt diːl ɒv taɪm ɒn ɪt',
        translation: 'I spent a great deal of time on it',
      },
      {
        id: 93,
        expression: 'Услуга бесплатная',
        transcription: 'ðə ˈsɜːvɪs ɪz friː ɒv ʧɑːʤ',
        translation: 'The service is free of charge',
      },
      {
        id: 94,
        expression: 'Это историческая традиция',
        transcription: 'ɪts ə hɪsˈtɒrɪk trəˈdɪʃᵊn',
        translation: 'It’s a historic tradition',
      },
      {
        id: 95,
        expression: 'Он действительно раздражен этими комментариями',
        transcription: 'hiːz ˈrɪəli əˈnɔɪd əˈbaʊt ðiːz ˈkɒmɛnts',
        translation: 'He’s really annoyed about these comments',
      },
      {
        id: 96,
        expression: 'Математика - один из моих любимых предметов',
        transcription: 'ˌmæθɪˈmætɪks ɪz wʌn ɒv maɪ ˈfeɪvərɪt ˈsʌbʤɪkts',
        translation: 'Mathematics is one of my favorite subjects',
      },
      {
        id: 97,
        expression: 'Это исторический документ',
        transcription: 'ɪts ə hɪsˈtɒrɪkəl ˈdɒkjʊmənt',
        translation: 'It’s a historical document',
      },
      {
        id: 98,
        expression: 'На концерте было большое количество людей',
        transcription: 'ðeə wɜːr ə lɑːʤ ˈnʌmbər ɒv ˈpiːpl æt ðə ˈkɒnsət',
        translation: 'There were a large number of people at the concert',
      },
      {
        id: 99,
        expression: 'Он был ужасно раздражен этим',
        transcription: 'hiː wɒz ˈtɛrəbᵊli əˈnɔɪd əˈbaʊt ɪt',
        translation: 'He was terribly annoyed about it',
      },
      {
        id: 100,
        expression: 'Он проделал великолепную работу',
        transcription: 'hiː dɪd ə ˈmɑːvələs ʤɒb',
        translation: 'He did a marvelous job',
      },
    ],
    {
      theory: `<div class="theory-block">
      <h3>Обратите внимание на следующие грамматические структуры во фразах</h3>
      </div>`,
    },
  ],
];
