export const contentLesson50 = [
  [
    {
      title: "Урок 50. 150 verbs. Part 6",
    },
    [
      {
        id: 1,
        expression: "Я часто жду его",
        transcription: "aɪ ˈɒf(ə)n weɪt fə hɪm",
        translation: "I often wait for him",
      },
      {
        id: 2,
        expression: "Я посмотрю тот видеоурок завтра",
        transcription: "aɪl wɒʧ ðət ˈvɪdɪəʊ ˈlɛsn təˈmɒrəʊ",
        translation: "I'll watch that video lesson tomorrow",
      },
      {
        id: 3,
        expression: "Я предпочитаю тренироваться очень интенсивно",
        transcription: "aɪ priˈfɜː tə treɪn ˈvɛri ɪnˈtɛnsɪvli",
        translation: "I prefer to train very intensively",
      },
      {
        id: 4,
        expression: "Они не объясняют этого",
        transcription: "ðeɪ dəʊnt ɪksˈpleɪn ɪt",
        translation: "They don't explain it",
      },
      {
        id: 5,
        expression: "Я покажу тебе эту вещь",
        transcription: "aɪl ʃəʊ jʊ ðɪs θɪŋ",
        translation: "I'll show you this thing",
      },
      {
        id: 6,
        expression: "Ты будешь ждать этого?",
        transcription: "wɪl jʊ weɪt fər ɪt?",
        translation: "Will you wait for it?",
      },
      {
        id: 7,
        expression: "Я напишу ему письмо",
        transcription: "aɪl raɪt ɪm ə ˈlɛtə",
        translation: "I'll write him a letter",
      },
      {
        id: 8,
        expression: "Я могу пойти туда с тобой",
        transcription: "aɪ kən gəʊ ðeə wɪð juː",
        translation: "I can go there with you",
      },
      {
        id: 9,
        expression: "Я написал сообщение",
        transcription: "aɪ rəʊt ə ˈmɛsɪʤ",
        translation: "I wrote a message",
      },
      {
        id: 10,
        expression: "Следуй за мной!",
        transcription: "ˈfɒləʊ miː!",
        translation: "Follow me!",
      },
      {
        id: 11,
        expression: "Два яблока упали с дерева",
        transcription: "tuː ˈæplz fɛl frəm ə triː",
        translation: "Two apples fell from a tree",
      },
      {
        id: 12,
        expression: "Она хочет танцевать сейчас",
        transcription: "ʃi wɒnts tə dɑːns naʊ",
        translation: "She wants to dance now",
      },
      {
        id: 13,
        expression: "Мне нужно тренироваться намного больше",
        transcription: "aɪ niːd tə treɪn mʌʧ mɔː",
        translation: "I need to train much more",
      },
      {
        id: 14,
        expression: "Он построил дом",
        transcription: "hi bɪlt ə haʊs",
        translation: "He built a house",
      },
      {
        id: 15,
        expression: "Это может упасть",
        transcription: "ɪt kən fɔːl",
        translation: "It can fall",
      },
      {
        id: 16,
        expression: "Я могу забыть это очень легко",
        transcription: "aɪ kən fəˈgɛt ɪt ˈvɛri ˈiːzɪli",
        translation: "I can forget it very easily",
      },
      {
        id: 17,
        expression: "Он часто улыбается",
        transcription: "hi ˈɒf(ə)n smaɪlz",
        translation: "He often smiles",
      },
      {
        id: 18,
        expression: "Я могу все объяснить",
        transcription: "aɪ kən ɪksˈpleɪn ˈɛvrɪθɪŋ",
        translation: "I can explain everything",
      },
      {
        id: 19,
        expression: "Он дал мне этот совет",
        transcription: "hi geɪv mi ðɪs ədˈvaɪs",
        translation: "He gave me this advice",
      },
      {
        id: 20,
        expression: "Я в основном пользовался тем словарем",
        transcription: "aɪ ˈməʊstli (ˈmeɪnli) juːzd ðət ˈdɪkʃ(ə)n(ə)ri",
        translation: "I mostly (mainly) used that dictionary",
      },
      {
        id: 21,
        expression: "Как ты тренировался?",
        transcription: "haʊ dɪd jʊ treɪn?",
        translation: "How did you train?",
      },
      {
        id: 22,
        expression: "Давай пойдем в другое место!",
        transcription: "lɛts gəʊ tʊ əˈnʌðə pleɪs!",
        translation: "Let's go to another place!",
      },
      {
        id: 23,
        expression: "Я хочу посмотреть этот фильм",
        transcription: "aɪ wɒnt tə wɒʧ ðɪs ˈmuːvi",
        translation: "I want to watch this movie",
      },
      {
        id: 24,
        expression: "Она танцевала профессионально",
        transcription: "ʃi dɑːnst prəˈfɛʃnəli",
        translation: "She danced professionally",
      },
      {
        id: 25,
        expression: "Она часто пользуется этим сервисом",
        transcription: "ʃi ˈɒf(ə)n ˈjuːzɪz ðɪs ˈsɜːvɪs",
        translation: "She often uses this service",
      },
      {
        id: 26,
        expression: "Я смотрел это видео прошлой ночью",
        transcription: "aɪ wɒʧt ðɪs ˈvɪdɪəʊ lɑːst naɪt",
        translation: "I watched this video last night",
      },
      {
        id: 27,
        expression: "Он пошел в цирк",
        transcription: "hi wɛnt tə ðə ˈsɜːkəs",
        translation: "He went to the circus",
      },
      {
        id: 28,
        expression: "Ей нужно идти домой",
        transcription: "ʃi niːdz tə gəʊ həʊm",
        translation: "She needs to go home",
      },
      {
        id: 29,
        expression: "Ты можешь улыбнуться",
        transcription: "jʊ kən smaɪl",
        translation: "You can smile",
      },
      {
        id: 30,
        expression: "Что ты выберешь?",
        transcription: "wɒt wɪl jʊ ʧuːz?",
        translation: "What will you choose?",
      },
      {
        id: 31,
        expression: "Он в основном показывает хорошие результаты",
        transcription: "hi ˈməʊstli ʃəʊz gʊd rɪˈzʌlts",
        translation: "He mostly shows good results",
      },
      {
        id: 32,
        expression: "Она всегда готовила очень вкусную еду",
        transcription: "ʃi ˈɔːlweɪz kʊkt ˈvɛri ˈteɪsti fuːd",
        translation: "She always cooked very tasty food",
      },
      {
        id: 33,
        expression: "Я часто пользуюсь этим словарем",
        transcription: "aɪ ˈɒf(ə)n juːz ðɪs ˈdɪkʃ(ə)n(ə)ri",
        translation: "I often use this dictionary",
      },
      {
        id: 34,
        expression: "Она не хотела ждать этого",
        transcription: "ʃi dɪdnt wɒnt tə weɪt fər ɪt",
        translation: "She didn't want to wait for it",
      },
      {
        id: 35,
        expression: "Ты даешь ему эти деньги?",
        transcription: "wɪl jʊ gɪv ɪm ðɪs ˈmʌni?",
        translation: "Will you give him this money?",
      },
      {
        id: 36,
        expression: "Мы скоро пойдем на пикник",
        transcription: "wiːl gəʊ ɒn ə ˈpɪknɪk suːn",
        translation: "We'll go on a picnic soon",
      },
      {
        id: 37,
        expression: "Давай пойдем туда вместе!",
        transcription: "lɛts gəʊ ðeə təˈgɛðə",
        translation: "Let's go there together",
      },
      {
        id: 38,
        expression: "Я все забыл",
        transcription: "aɪ fəˈgɒt ˈɛvrɪθɪŋ",
        translation: "I forgot everything",
      },
      {
        id: 39,
        expression: "Этот мальчик упал внезапно",
        transcription: "ðɪs bɔɪ fɛl ˈsʌdnli",
        translation: "This boy fell suddenly",
      },
      {
        id: 40,
        expression: "Как она готовит?",
        transcription: "haʊ dəz ʃi kʊk?",
        translation: "How does she cook?",
      },
      {
        id: 41,
        expression: "Она не может понять этого",
        transcription: "ʃi kɑːnt ˌʌndəˈstænd ɪt",
        translation: "She can't understand it",
      },
      {
        id: 42,
        expression: "Этот урок продлится сорок минут",
        transcription: "ðɪs ˈlɛsn wɪl lɑːst ˈfɔːti ˈmɪnɪts",
        translation: "This lesson will last forty minutes",
      },
      {
        id: 43,
        expression: "Я не могу объяснить эту вещь",
        transcription: "aɪ kɑːnt ɪksˈpleɪn ðɪs θɪŋ",
        translation: "I can't explain this thing",
      },
      {
        id: 44,
        expression:
          "Ты можешь использовать мой сотовый телефон, если тебе нужно",
        transcription: "jʊ kən juːz maɪ sɛl fəʊn ɪf jʊ niːd ɪt",
        translation: "You can use my cell phone if you need it",
      },
      {
        id: 45,
        expression: "Как долго это длилось?",
        transcription: "haʊ lɒŋ dɪd ɪt lɑːst?",
        translation: "How long did it last?",
      },
      {
        id: 46,
        expression: "Наш урок длился очень долго",
        transcription: "ˈaʊə ˈlɛsn ˈlɑːstɪd ˈvɛri lɒŋ",
        translation: "Our lesson lasted very long",
      },
      {
        id: 47,
        expression: "Мы могли говорить по телефону часами",
        transcription: "wi kəd tɔːk ɒn ðə fəʊn fər ˈaʊəz",
        translation: "We could talk on the phone for hours",
      },
      {
        id: 48,
        expression: "Я правильно это написал?",
        transcription: "dɪd aɪ raɪt ɪt kəˈrɛktli?",
        translation: "Did I write it correctly?",
      },
      {
        id: 49,
        expression: "Я не мог понять эту речь",
        transcription: "aɪ ˈkʊdnt ˌʌndəˈstænd ðɪs spiːʧ",
        translation: "I couldn't understand this speech",
      },
      {
        id: 50,
        expression: "Он не умеет говорить по-английски",
        transcription: "hi kɑːnt spiːk ˈɪŋglɪʃ",
        translation: "He can't speak English",
      },
      {
        id: 51,
        expression: "Я могу дать тебе одну очень важную рекомендацию",
        transcription: "aɪ kən gɪv jʊ wʌn ˈvɛri ɪmˈpɔːtənt ˌrɛkəmɛnˈdeɪʃən",
        translation: "I can give you one very important recommendation",
      },
      {
        id: 52,
        expression: "Этот видео блогер показал это в своем видео",
        transcription: "ðɪs ˈvɪdɪəʊ ˈblɒgə ʃəʊd ɪt ɪn ɪz ˈvɪdɪəʊ",
        translation: "This video blogger showed it in his video",
      },
      {
        id: 53,
        expression: "Когда они поженятся?",
        transcription: "wɛn wɪl ðeɪ gɛt ˈmærɪd?",
        translation: "When will they get married?",
      },
      {
        id: 54,
        expression: "Они показали это по телевизору",
        transcription: "ðeɪ ʃəʊd ɪt ɒn ˌtiːˈviː",
        translation: "They showed it on TV",
      },
      {
        id: 55,
        expression: "Время проходило так медленно",
        transcription: "taɪm pɑːst səʊ ˈsləʊli",
        translation: "Time passed so slowly",
      },
      {
        id: 56,
        expression: "Я мог учить английский часами",
        transcription: "aɪ kəd lɜːn ˈɪŋglɪʃ fər ˈaʊəz",
        translation: "I could learn English for hours",
      },
      {
        id: 57,
        expression: "Он мог учить русский часами",
        transcription: "hi kəd lɜːn ˈrʌʃ(ə)n fər ˈaʊəz",
        translation: "He could learn Russian for hours",
      },
      {
        id: 58,
        expression: "Она могла учить английские слова очень быстро",
        transcription: "ʃi kəd lɜːn ˈɪŋglɪʃ wɜːdz ˈvɛri ˈkwɪkli",
        translation: "She could learn English words very quickly",
      },
      {
        id: 59,
        expression: "Я помню, что наш учитель объяснял это",
        transcription: "aɪ rɪˈmɛmbə ðət ˈaʊə ˈtiːʧər ɪksˈpleɪnd ɪt",
        translation: "I remember that our teacher explained it",
      },
      {
        id: 60,
        expression: "Он помнит, что его учитель не объяснял это",
        transcription: "hi rɪˈmɛmbəz ðət ɪz ˈtiːʧə dɪdnt ɪksˈpleɪn ɪt",
        translation: "He remembers that his teacher didn't explain it",
      },
      {
        id: 61,
        expression: "Я правильно это сказал?",
        transcription: "dɪd aɪ seɪ ɪt kəˈrɛktli?",
        translation: "Did I say it correctly?",
      },
      {
        id: 62,
        expression: "Ты нашел это легко?",
        transcription: "dɪd jʊ faɪnd ɪt ˈiːzɪli?",
        translation: "Did you find it easily?",
      },
      {
        id: 63,
        expression: "Я делаю это правильно?",
        transcription: "dʊ aɪ dʊ ɪt raɪt?",
        translation: "Do I do it right?",
      },
      {
        id: 64,
        expression: "Он не может объяснить это",
        transcription: "hi kɑːnt ɪksˈpleɪn ɪt",
        translation: "He can't explain it",
      },
      {
        id: 65,
        expression: "Я не могу объяснить это",
        transcription: "aɪ kɑːnt ɪksˈpleɪn ɪt",
        translation: "I can't explain it",
      },
      {
        id: 66,
        expression: "Он может объяснить эти вещи очень легко",
        transcription: "hi kən ɪksˈpleɪn ðiːz θɪŋz ˈvɛri ˈiːzɪli",
        translation: "He can explain these things very easily",
      },
      {
        id: 67,
        expression: "Она выбрала первый ответ",
        transcription: "ʃi ʧəʊz ðə fɜːst ˈɑːnsə",
        translation: "She chose the first answer",
      },
      {
        id: 68,
        expression: "Он выбрал второй ответ",
        transcription: "hi ʧəʊz ðə ˈsɛkənd ˈɑːnsə",
        translation: "He chose the second answer",
      },
      {
        id: 69,
        expression: "Я выбрал третий ответ",
        transcription: "aɪ ʧəʊz ðə θɜːd ˈɑːnsə",
        translation: "I chose the third answer",
      },
      {
        id: 70,
        expression: "Ты будешь танцевать или нет?",
        transcription: "wɪl jʊ dɑːns ɔː nɒt?",
        translation: "Will you dance or not?",
      },
      {
        id: 71,
        expression: "Она будет петь или нет?",
        transcription: "wɪl ʃi sɪŋ ɔː nɒt?",
        translation: "Will she sing or not?",
      },
      {
        id: 72,
        expression: "Они строят очень модный дом",
        transcription: "ðeɪ bɪld ˈvɛri ˈfæʃnəbl haʊs",
        translation: "They build very fashionable house",
      },
      {
        id: 73,
        expression: "Они не строят очень модные дома",
        transcription: "ðeɪ dəʊnt bɪld ˈvɛri ˈfæʃnəbl ˈhaʊzɪz",
        translation: "They don't build very fashionable houses",
      },
      {
        id: 74,
        expression: "Он строит очень модные дома",
        transcription: "hi bɪldz ˈvɛri ˈfæʃnəbl ˈhaʊzɪz",
        translation: "He builds very fashionable houses",
      },
      {
        id: 75,
        expression: "Я выбрал второй ответ",
        transcription: "aɪ ʧəʊz ðə ˈsɛkənd ˈɑːnsə",
        translation: "I chose the second answer",
      },
      {
        id: 76,
        expression: "Ты сказал это правильно",
        transcription: "jʊ sɛd ɪt kəˈrɛktli",
        translation: "You said it correctly",
      },
      {
        id: 77,
        expression: "Я мог купить тот дом, но я этого не сделал",
        transcription: "aɪ kəd baɪ ðət haʊs bət aɪ dɪdnt dʊ ɪt",
        translation: "I could buy that house but I didn't do it",
      },
      {
        id: 78,
        expression: "Он мог купить тот дом, но он этого не сделал",
        transcription: "hi kəd baɪ ðət haʊs bət hi dɪdnt dʊ ɪt",
        translation: "He could buy that house but he didn't do it",
      },
      {
        id: 79,
        expression: "Я мог купить этот дом, но я этого не сделал",
        transcription: "aɪ kəd baɪ ðət haʊs bət aɪ dɪdnt dʊ ɪt",
        translation: "I could buy that house but I didn't do it",
      },
      {
        id: 80,
        expression: "Я не думаю, что цена упадет",
        transcription: "aɪ dəʊnt θɪŋk ðət ðə praɪs wɪl fɔːl",
        translation: "I don't think that the price will fall",
      },
      {
        id: 81,
        expression: "Я не думаю, что эти цены упадут",
        transcription: "aɪ dəʊnt θɪŋk ðət ðiːz ˈpraɪsɪz wɪl fɔːl",
        translation: "I don't think that these prices will fall",
      },
      {
        id: 82,
        expression: "Я не думаю, что эта цена упадет",
        transcription: "aɪ dəʊnt θɪŋk ðət ðɪs praɪs wɪl fɔːl",
        translation: "I don't think that this price will fall",
      },
      {
        id: 83,
        expression: "Время проходит очень быстро",
        transcription: "taɪm ˈpɑːsɪz ˈvɛri ˈkwɪkli",
        translation: "Time passes very quickly",
      },
      {
        id: 84,
        expression: "Он говорит, что они построят этот мост в следующем году",
        transcription: "hi sɛz ðət ðeɪl bɪld ðɪs brɪʤ nɛkst jɪə",
        translation: "He says that they'll build this bridge next year",
      },
      {
        id: 85,
        expression: "Они хотят пожениться",
        transcription: "ðeɪ wɒnt tə gɛt ˈmærɪd",
        translation: "They want to get married",
      },
      {
        id: 86,
        expression: "Он женился на ней в прошлом году",
        transcription: "hi ˈmærɪd hə lɑːst jɪə",
        translation: "He married her last year",
      },
      {
        id: 87,
        expression: "Как она танцевала?",
        transcription: "haʊ dɪd ʃi dɑːns?",
        translation: "How did she dance?",
      },
      {
        id: 88,
        expression: "Она готовит вкусную еду",
        transcription: "ʃi kʊks ˈteɪsti fuːd / ʃi kʊks dɪˈlɪʃəs fuːd",
        translation: "She cooks tasty food / She cooks delicious food",
      },
      {
        id: 89,
        expression: "Я постараюсь сдать этот экзамен",
        transcription: "aɪl traɪ tə pɑːs ðɪs ɪgˈzæm",
        translation: "I'll try to pass this exam",
      },
      {
        id: 90,
        expression: "Тот урок длился час",
        transcription: "ðæt ˈlɛsn ˈlɑːstɪd ən ˈaʊə",
        translation: "That lesson lasted an hour",
      },
      {
        id: 91,
        expression: "Я не могу выбрать между этими двумя вещами",
        transcription: "aɪ kɑːnt ʧuːz bɪˈtwiːn ðiːz tuː θɪŋz",
        translation: "I can't choose between these two things",
      },
      {
        id: 92,
        expression: "Я думаю, я последую твоему совету",
        transcription: "aɪ θɪŋk aɪl ˈfɒləʊ jər ədˈvaɪs",
        translation: "I think I'll follow your advice",
      },
      {
        id: 93,
        expression: "Просто следуй за мной!",
        transcription: "ʤəst ˈfɒləʊ miː!",
        translation: "Just follow me!",
      },
      {
        id: 94,
        expression: "Ты написал все правильно",
        transcription: "jʊ rəʊt ˈɛvrɪθɪŋ kəˈrɛktli",
        translation: "You wrote everything correctly",
      },
      {
        id: 95,
        expression: "Что ты будешь готовить на ужин?",
        transcription: "wɒt wɪl jʊ kʊk fə ˈdɪnə?",
        translation: "What will you cook for dinner?",
      },
      {
        id: 96,
        expression: "Она улыбнулась мне",
        transcription: "ʃi smaɪld ət miː",
        translation: "She smiled at me",
      },
      {
        id: 97,
        expression: "Я часто пишу по-английски",
        transcription: "aɪ ˈɒf(ə)n raɪt ɪn ˈɪŋglɪʃ",
        translation: "I often write in English",
      },
      {
        id: 98,
        expression: "Я пытался понять это",
        transcription: "aɪ traɪd tʊ ˌʌndəˈstænd ɪt",
        translation: "I tried to understand it",
      },
      {
        id: 99,
        expression: "Я плавал в море вчера",
        transcription: "aɪ swæm ɪn ðə siː ˈjɛstədeɪ",
        translation: "I swam in the sea yesterday",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Закрепление пройденного материала</h3>
</div>`,
    },
  ],
];
