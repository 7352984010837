export const contentLesson112 = [
  [
    {
      title: "Урок 112. Present Continuous. General and Special Question",
    },
    [
      {
        id: 1,
        expression: "Я делаю это очень хорошо?",
        transcription: "əm aɪ ˈdu(ː)ɪŋ ɪt ˈvɛri wɛl?",
        translation: "Am I doing it very well?",
      },
      {
        id: 2,
        expression: "Они ждут нас сейчас?",
        transcription: "ɑː ðeɪ ˈweɪtɪŋ fər əs naʊ?",
        translation: "Are they waiting for us now?",
      },
      {
        id: 3,
        expression: "Он ест свой завтрак сейчас?",
        transcription: "ɪz hi ˈiːtɪŋ ɪz ˈbrɛkfəst naʊ?",
        translation: "Is he eating his breakfast now?",
      },
      {
        id: 4,
        expression: "Куда я иду сейчас?",
        transcription: "weər əm aɪ ˈgəʊɪŋ naʊ?",
        translation: "Where am I going now?",
      },
      {
        id: 5,
        expression: "Что ты делаешь сейчас?",
        transcription: "wɒt ə jʊ ˈdu(ː)ɪŋ naʊ?",
        translation: "What are you doing now?",
      },
      {
        id: 6,
        expression: "Почему он читает эту скучную книгу сейчас?",
        transcription: "waɪ z hi ˈriːdɪŋ ðɪs ˈbɔːrɪŋ bʊk naʊ?",
        translation: "Why is he reading this boring book now?",
      },
      {
        id: 7,
        expression: "Что ты ешь?",
        transcription: "wɒt ə jʊ ˈiːtɪŋ?",
        translation: "What are you eating?",
      },
      {
        id: 8,
        expression: "Он сейчас изучает алгебру?",
        transcription: "ɪz hi ˈstʌdiɪŋ ˈælʤɪbrə naʊ?",
        translation: "Is he studying algebra now?",
      },
      {
        id: 9,
        expression: "Почему она бежит?",
        transcription: "waɪ z ʃi ˈrʌnɪŋ?",
        translation: "Why is she running?",
      },
      {
        id: 10,
        expression: "Ты меня ждешь?",
        transcription: "ɑː jʊ ˈweɪtɪŋ fə miː?",
        translation: "Are you waiting for me?",
      },
      {
        id: 11,
        expression: "Он чинит эти часы?",
        transcription: "ɪz hi ˈfɪksɪŋ ðɪs wɒʧ?",
        translation: "Is he fixing this watch?",
      },
      {
        id: 12,
        expression: "Она разговаривает с незнакомцем?",
        transcription: "ɪz ʃi ˈtɔːkɪŋ tʊ ə ˈstreɪnʤə?",
        translation: "Is she talking to a stranger?",
      },
      {
        id: 13,
        expression: "Ты ждешь ее ответа?",
        transcription: "ɑː jʊ ˈweɪtɪŋ fə hər ˈɑːnsə?",
        translation: "Are you waiting for her answer?",
      },
      {
        id: 14,
        expression: "Он покидает город сейчас?",
        transcription: "ɪz hi ˈliːvɪŋ ə ˈsɪti naʊ?",
        translation: "Is he leaving a city now?",
      },
      {
        id: 15,
        expression: "Он ждет поезда?",
        transcription: "ɪz hi ˈweɪtɪŋ fər ə treɪn?",
        translation: "Is he waiting for a train?",
      },
      {
        id: 16,
        expression: "Что ты сейчас делаешь?",
        transcription: "wɒt ə jʊ ˈdu(ː)ɪŋ naʊ?",
        translation: "What are you doing now?",
      },
      {
        id: 17,
        expression: "Она сейчас принимает ванну?",
        transcription: "ɪz ʃi ˈhævɪŋ ə bɑːθ naʊ?",
        translation: "Is she having a bath now?",
      },
      {
        id: 18,
        expression: "Он учится сейчас?",
        transcription: "ɪz hi ˈstʌdiɪŋ naʊ?",
        translation: "Is he studying now?",
      },
      {
        id: 19,
        expression: "Ты учишься или работаешь?",
        transcription: "ɑː jʊ ˈstʌdiɪŋ ɔː ˈwɜːkɪŋ?",
        translation: "Are you studying or working?",
      },
      {
        id: 20,
        expression: "Она сейчас печет печенье?",
        transcription: "ɪz ʃi ˈbeɪkɪŋ ˈkʊkiz naʊ?",
        translation: "Is she baking cookies now?",
      },
      {
        id: 21,
        expression: "Она работает сейчас?",
        transcription: "ɪz ʃi ˈwɜːkɪŋ naʊ?",
        translation: "Is she working now?",
      },
      {
        id: 22,
        expression: "Ты идешь за покупками сейчас?",
        transcription: "ɑː jʊ ˈgəʊɪŋ ˈʃɒpɪŋ naʊ?",
        translation: "Are you going shopping now?",
      },
      {
        id: 23,
        expression: "Что она готовит сейчас?",
        transcription: "wɒt s ʃi ˈkʊkɪŋ naʊ?",
        translation: "What is she cooking now?",
      },
      {
        id: 24,
        expression: "Ты сейчас остаешься дома?",
        transcription: "ɑː jʊ ˈsteɪɪŋ ət həʊm?",
        translation: "Are you staying at home?",
      },
      {
        id: 25,
        expression: "Она сейчас изучает геометрию?",
        transcription: "ɪz ʃi ˈstʌdiɪŋ ʤɪˈɒmɪtri?",
        translation: "Is she studying geometry?",
      },
      {
        id: 26,
        expression: "Он наблюдает за своей машиной?",
        transcription: "ɪz hi ˈwɒʧɪŋ ɪz kɑː?",
        translation: "Is he watching his car?",
      },
      {
        id: 27,
        expression: "Зачем ты это делаешь?",
        transcription: "waɪ ə jʊ ˈdu(ː)ɪŋ ɪt?",
        translation: "Why are you doing it?",
      },
      {
        id: 28,
        expression: "Ты уходишь из дома?",
        transcription: "ɑː jʊ ˈliːvɪŋ həʊm?",
        translation: "Are you leaving home?",
      },
      {
        id: 29,
        expression: "Ты снимаешь квартиру?",
        transcription: "ɑː jʊ ˈrɛntɪŋ ən əˈpɑːtmənt?",
        translation: "Are you renting an apartment?",
      },
      {
        id: 30,
        expression: "Куда ты направляешься?",
        transcription: "weər ə jʊ ˈgəʊɪŋ?",
        translation: "Where are you going?",
      },
      {
        id: 31,
        expression: "Что ты делаешь?",
        transcription: "wɒt ə jʊ ˈdu(ː)ɪŋ?",
        translation: "What are you doing?",
      },
      {
        id: 32,
        expression: "Она убирает дом?",
        transcription: "ɪz ʃi ˈkliːnɪŋ ðə haʊs?",
        translation: "Is she cleaning the house?",
      },
      {
        id: 33,
        expression: "Куда ты идешь?",
        transcription: "weər ə jʊ ˈgəʊɪŋ?",
        translation: "Where are you going?",
      },
      {
        id: 34,
        expression: "Почему ты так мало ешь?",
        transcription: "waɪ ə jʊ ˈiːtɪŋ səʊ ˈlɪtl?",
        translation: "Why are you eating so little?",
      },
      {
        id: 35,
        expression: "Она играет с куклой?",
        transcription: "ɪz ʃi ˈpleɪɪŋ wɪð ə dɒl?",
        translation: "Is she playing with a doll?",
      },
      {
        id: 36,
        expression: "Ты ждешь гостей?",
        transcription: "ɑː jʊ ˈweɪtɪŋ fə ðə gɛsts?",
        translation: "Are you waiting for the guests?",
      },
      {
        id: 37,
        expression: "Ты его ждешь?",
        transcription: "ɑː jʊ ˈweɪtɪŋ fə hɪm?",
        translation: "Are you waiting for him?",
      },
      {
        id: 38,
        expression: "Куда она идет?",
        transcription: "weə z ʃi ˈgəʊɪŋ?",
        translation: "Where is she going?",
      },
      {
        id: 39,
        expression: "Он чинит свой велосипед?",
        transcription: "ɪz hi ˈfɪksɪŋ ɪz baɪk?",
        translation: "Is he fixing his bike?",
      },
      {
        id: 40,
        expression: "Ты пьешь чай сейчас?",
        transcription: "ɑː jʊ ˈdrɪŋkɪŋ tiː naʊ?",
        translation: "Are you drinking tea now?",
      },
      {
        id: 41,
        expression: "Он сейчас смотрит футбольный матч?",
        transcription: "ɪz hi ˈwɒʧɪŋ ə ˈfʊtbɔːl mæʧ naʊ?",
        translation: "Is he watching a football match now?",
      },
      {
        id: 42,
        expression: "Ты идешь домой сейчас?",
        transcription: "ɑː jʊ ˈgəʊɪŋ həʊm naʊ?",
        translation: "Are you going home now?",
      },
      {
        id: 43,
        expression: "Она идет на работу сейчас?",
        transcription: "ɪz ʃi ˈgəʊɪŋ tə wɜːk naʊ?",
        translation: "Is she going to work now?",
      },
      {
        id: 44,
        expression: "Ты сейчас принимаешь ванну?",
        transcription: "ɑː jʊ ˈhævɪŋ ə bɑːθ naʊ?",
        translation: "Are you having a bath now?",
      },
      {
        id: 45,
        expression: "Он арендует дом?",
        transcription: "ɪz hi ˈrɛntɪŋ ə haʊs?",
        translation: "Is he renting a house?",
      },
      {
        id: 46,
        expression: "Ты ждешь автобус?",
        transcription: "ɑː jʊ ˈweɪtɪŋ fə ðə bʌs?",
        translation: "Are you waiting for the bus?",
      },
      {
        id: 47,
        expression: "Это становится все более и более популярным в наши дни?",
        transcription: "ɪz ɪt ˈgɛtɪŋ mɔːr ənd mɔː ˈpɒpjʊlə ˈnaʊədeɪz?",
        translation: "Is it getting more and more popular nowadays?",
      },
      {
        id: 48,
        expression: "Какую песню слушает твоя сестра?",
        transcription: "wɒt sɒŋ z jə ˈsɪstə ˈlɪsnɪŋ tʊ?",
        translation: "What song is your sister listening to?",
      },
      {
        id: 49,
        expression: "Твоя мама сейчас печет печенье?",
        transcription: "ɪz jə ˈmʌðə ˈbeɪkɪŋ ˈkʊkiz naʊ?",
        translation: "Is your mother baking cookies now?",
      },
      {
        id: 50,
        expression: "Какой предмет ты изучаешь сейчас?",
        transcription: "wɒt ˈsʌbʤɪkt ə jʊ ˈstʌdiɪŋ naʊ?",
        translation: "What subject are you studying now?",
      },
      {
        id: 51,
        expression: "Какие вещи ты сейчас обсуждаешь?",
        transcription: "wɒt θɪŋz ə jʊ dɪsˈkʌsɪŋ naʊ?",
        translation: "What things are you discussing now?",
      },
      {
        id: 52,
        expression: "Звенит звонок?",
        transcription: "ɪz ðə bɛl ˈrɪŋɪŋ?",
        translation: "Is the bell ringing?",
      },
      {
        id: 53,
        expression: "Твой брат сегодня работает?",
        transcription: "ɪz jə ˈbrʌðə ˈwɜːkɪŋ təˈdeɪ?",
        translation: "Is your brother working today?",
      },
      {
        id: 54,
        expression: "О чем ты говоришь?",
        transcription: "wɒt ə jʊ ˈtɔːkɪŋ əˈbaʊt?",
        translation: "What are you talking about?",
      },
      {
        id: 55,
        expression: "Почему малыш плачет?",
        transcription: "waɪ z ðə ˈbeɪbi ˈkraɪɪŋ?",
        translation: "Why is the baby crying?",
      },
      {
        id: 56,
        expression: "Ты ждешь последних новостей?",
        transcription: "ɑː jʊ ˈweɪtɪŋ fə ðə ˈleɪtɪst njuːz?",
        translation: "Are you waiting for the latest news?",
      },
      {
        id: 57,
        expression: "Чего ты ждешь?",
        transcription: "wɒt ə jʊ ˈweɪtɪŋ fɔː?",
        translation: "What are you waiting for?",
      },
      {
        id: 58,
        expression: "У тебя сейчас встреча?",
        transcription: "ɑː jʊ ˈhævɪŋ ə ˈmiːtɪŋ naʊ?",
        translation: "Are you having a meeting now?",
      },
      {
        id: 59,
        expression: "Самолет приземляется?",
        transcription: "ɪz ði ˈeəpleɪn ˈlændɪŋ?",
        translation: "Is the airplane landing?",
      },
      {
        id: 60,
        expression: "Девочка играет с куклой?",
        transcription: "ɪz ðə gɜːl ˈpleɪɪŋ wɪð ə dɒl?",
        translation: "Is the girl playing with a doll?",
      },
      {
        id: 61,
        expression: "Что ты слушаешь?",
        transcription: "wɒt ə jʊ ˈlɪsnɪŋ tʊ?",
        translation: "What are you listening to?",
      },
      {
        id: 62,
        expression: "О чем он говорит?",
        transcription: "wɒt s hi ˈtɔːkɪŋ əˈbaʊt?",
        translation: "What is he talking about?",
      },
      {
        id: 63,
        expression: "О ком он говорит?",
        transcription: "huː z hi ˈtɔːkɪŋ əˈbaʊt?",
        translation: "Who is he talking about?",
      },
      {
        id: 64,
        expression: "С кем он разговаривает?",
        transcription: "huː z hi ˈtɔːkɪŋ tʊ?",
        translation: "Who is he talking to?",
      },
      {
        id: 65,
        expression: "Какую песню ты слушаешь?",
        transcription: "wɒt sɒŋ ə jʊ ˈlɪsnɪŋ tʊ?",
        translation: "What song are you listening to?",
      },
      {
        id: 66,
        expression: "Какого рода песни ты слушаешь?",
        transcription: "wɒt kaɪnd əv sɒŋz ə jʊ ˈlɪsnɪŋ tʊ?",
        translation: "What kind of songs are you listening to?",
      },
      {
        id: 67,
        expression: "Какой фильм ты смотришь?",
        transcription: "wɒt ˈmuːvi ə jʊ ˈwɒʧɪŋ?",
        translation: "What movie are you watching?",
      },
      {
        id: 68,
        expression: "Кого ждет твой брат?",
        transcription: "huː z jə ˈbrʌðə ˈweɪtɪŋ fɔː?",
        translation: "Who is your brother waiting for?",
      },
      {
        id: 69,
        expression: "Чего ждет твоя сестра?",
        transcription: "wɒt s jə ˈsɪstə ˈweɪtɪŋ fɔː?",
        translation: "What is your sister waiting for?",
      },
      {
        id: 70,
        expression: "Кого ты ждешь?",
        transcription: "huː ə jʊ ˈweɪtɪŋ fɔː?",
        translation: "Who are you waiting for?",
      },
      {
        id: 71,
        expression: "Какую музыку ты слушаешь?",
        transcription: "wɒt ˈmjuːzɪk ə jʊ ˈlɪsnɪŋ tʊ?",
        translation: "What music are you listening to?",
      },
      {
        id: 72,
        expression: "Какое радио она слушает?",
        transcription: "wɒt ˈreɪdɪəʊ z ʃi ˈlɪsnɪŋ tʊ?",
        translation: "What radio is she listening to?",
      },
      {
        id: 73,
        expression: "Какую музыку он слушает?",
        transcription: "wɒt ˈmjuːzɪk s hi ˈlɪsnɪŋ tʊ?",
        translation: "What music is he listening to?",
      },
      {
        id: 74,
        expression: "Дети играют в саду?",
        transcription: "ɑː ðə ˈʧɪldrən ˈpleɪɪŋ ɪn ðə ˈgɑːdn?",
        translation: "Are the children playing in the garden?",
      },
      {
        id: 75,
        expression: "Дети играют в футбол?",
        transcription: "ɑː ðə kɪdz ˈpleɪɪŋ ˈfʊtbɔːl?",
        translation: "Are the kids playing football?",
      },
      {
        id: 76,
        expression: "Они сейчас играют в бейсбол?",
        transcription: "ɑː ðeɪ ˈpleɪɪŋ ˈbeɪsbɔːl naʊ?",
        translation: "Are they playing baseball now?",
      },
      {
        id: 77,
        expression: "На что она смотрит?",
        transcription: "wɒts ʃi ˈlʊkɪŋ æt?",
        translation: "What's she looking at?",
      },
      {
        id: 78,
        expression: "Что он сейчас делает?",
        transcription: "wɒts hi ˈdu(ː)ɪŋ naʊ?",
        translation: "What's he doing now?",
      },
      {
        id: 79,
        expression: "На кого она смотрит?",
        transcription: "huː z ʃi ˈlʊkɪŋ æt?",
        translation: "Who is she looking at?",
      },
      {
        id: 80,
        expression: "Что ты ищешь?",
        transcription: "wɒt ə jʊ ˈlʊkɪŋ fɔː?",
        translation: "What are you looking for?",
      },
      {
        id: 81,
        expression: "Кого ты ищешь?",
        transcription: "huː ə jʊ ˈlʊkɪŋ fɔː?",
        translation: "Who are you looking for?",
      },
      {
        id: 82,
        expression: "Что он ищет там?",
        transcription: "wɒts hi ˈlʊkɪŋ fə ðeə?",
        translation: "What's he looking for there?",
      },
      {
        id: 83,
        expression: "Сегодня идет дождь?",
        transcription: "ɪz ɪt ˈreɪnɪŋ təˈdeɪ?",
        translation: "Is it raining today?",
      },
      {
        id: 84,
        expression: "Сегодня идет снег?",
        transcription: "ɪz ɪt ˈsnəʊɪŋ təˈdeɪ?",
        translation: "Is it snowing today?",
      },
      {
        id: 85,
        expression: "Сейчас идет дождь?",
        transcription: "ɪz ɪt ˈreɪnɪŋ naʊ?",
        translation: "Is it raining now?",
      },
      {
        id: 86,
        expression: "Кто тебе звонит?",
        transcription: "huːz ˈkɔːlɪŋ juː?",
        translation: "Who's calling you?",
      },
      {
        id: 87,
        expression: "Кто ему сейчас звонит?",
        transcription: "huːz ˈkɔːlɪŋ ɪm naʊ?",
        translation: "Who's calling him now?",
      },
      {
        id: 88,
        expression: "Кто тебя ждет?",
        transcription: "huːz ˈweɪtɪŋ fə juː?",
        translation: "Who's waiting for you?",
      },
      {
        id: 89,
        expression: "Фабрика производит все больше и больше товаров?",
        transcription: "ɪz ðə ˈfæktəri prəˈdjuːsɪŋ mɔːr ənd mɔː gʊdz?",
        translation: "Is the factory producing more and more goods?",
      },
      {
        id: 90,
        expression: "Фабрика производит много товаров?",
        transcription: "ɪz ðə ˈfæktəri prəˈdjuːsɪŋ ˈmɛni gʊdz?",
        translation: "Is the factory producing many goods?",
      },
      {
        id: 91,
        expression: "Эта фабрика производит много разных товаров?",
        transcription: "ɪz ðɪs ˈfæktəri prəˈdjuːsɪŋ ˈmɛni ˈdɪfrənt gʊdz?",
        translation: "Is this factory producing many different goods?",
      },
      {
        id: 92,
        expression: "Самолет взлетает?",
        transcription: "ɪz ði ˈeəpleɪn ˈteɪkɪŋ ɒf?",
        translation: "Is the airplane taking off?",
      },
      {
        id: 93,
        expression: "Какую дисциплину ты изучаешь сейчас?",
        transcription: "wɒt ˈdɪsɪplɪn ə jʊ ˈstʌdiɪŋ naʊ?",
        translation: "What discipline are you studying now?",
      },
      {
        id: 94,
        expression: "На что ты смотришь?",
        transcription: "wɒt ə jʊ ˈlʊkɪŋ æt?",
        translation: "What are you looking at?",
      },
      {
        id: 95,
        expression: "У него сейчас встреча?",
        transcription: "ɪz hi ˈhævɪŋ ə ˈmiːtɪŋ naʊ?",
        translation: "Is he having a meeting now?",
      },
      {
        id: 96,
        expression: "Дети играют или читают книгу?",
        transcription: "ɑː ðə ˈʧɪldrən ˈpleɪɪŋ ɔː ˈriːdɪŋ ə bʊk?",
        translation: "Are the children playing or reading a book?",
      },
      {
        id: 97,
        expression: "Кого он ждет?",
        transcription: "huː z hi ˈweɪtɪŋ fɔː?",
        translation: "Who is he waiting for?",
      },
      {
        id: 98,
        expression: "О чем они говорят?",
        transcription: "wɒt ə ðeɪ ˈtɔːkɪŋ əˈbaʊt?",
        translation: "What are they talking about?",
      },
      {
        id: 99,
        expression: "На что он смотрит?",
        transcription: "wɒt s hi ˈlʊkɪŋ æt?",
        translation: "What is he looking at?",
      },
      {
        id: 100,
        expression: "Мой английский становится лучше?",
        transcription: "ɪz maɪ ˈɪŋglɪʃ ˈgɛtɪŋ ˈbɛtə?",
        translation: "Is my English getting better?",
      },
      {
        id: 101,
        expression: "Что дети делают?",
        transcription: "wɒt ə ðə ˈʧɪldrən ˈdu(ː)ɪŋ?",
        translation: "What are the children doing?",
      },
      {
        id: 102,
        expression: "Телефон звонит?",
        transcription: "ɪz ðə bɛl ˈrɪŋɪŋ?",
        translation: "Is the bell ringing?",
      },
      {
        id: 103,
        expression: "Дождь идет?",
        transcription: "ɪz ɪt ˈreɪnɪŋ?",
        translation: "Is it raining?",
      },
      {
        id: 104,
        expression: "Снег идет?",
        transcription: "ɪz ɪt ˈsnəʊɪŋ?",
        translation: "Is it snowing?",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Общие вопросы в Present Continuous</h3>
<p>Строятся по формуле:</p>
<p><span>форма глагола to be в настоящем времени + подлежащее + глагол+ing + ...</span></p>
<p>Is he sleepng? - Он спит сейчас?</p>
<p>Are we going? - Мы идем сейчас?</p>
<p>Am I saying loudly? - Я говорю сейчас громко?</p>
<h3>Специальные вопросы в Present Continuous</h3>
<p>Строятся по формуле:</p>
<p><span>вопросительное слово + форма глагола to be в настоящем времени + подлежащее + глагол+ing + ...</span></p>
<p>What are you doing? - Что ты делаешь сейчас?</p>
<p>Where are we going? - Куда мы идем сейчас?</p>
<p>How loudly am I saying? - Как громко я говорю сейчас?</p>
</div>`,
    },
  ],
];
