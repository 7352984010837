export const wordsLesson245 = [
  [
    {
      id: 1,
      word: 'idealize',
      transcription: 'aɪˈdɪəlaɪz',
      translation: 'идеализировать',
    },
    {
      id: 2,
      word: 'bench',
      transcription: 'bɛnʧ',
      translation: 'скамья',
    },
    {
      id: 3,
      word: 'emphasise',
      transcription: 'ˈɛmfəsaɪz',
      translation: 'подчеркнуть',
    },
    {
      id: 4,
      word: 'fry',
      transcription: 'fraɪ',
      translation: 'жарить',
    },
    {
      id: 5,
      word: 'including',
      transcription: 'ɪnˈkluːdɪŋ',
      translation: 'включая',
    },
    {
      id: 6,
      word: 'inefficiency',
      transcription: 'ˌɪnɪˈfɪʃənsi',
      translation: 'неэффективность',
    },
    {
      id: 7,
      word: 'liquid',
      transcription: 'ˈlɪkwɪd',
      translation: 'жидкий, жидкость',
    },
    {
      id: 8,
      word: 'merry',
      transcription: 'ˈmɛri',
      translation: 'счастливый',
    },
    {
      id: 9,
      word: 'nation',
      transcription: 'ˈneɪʃᵊn',
      translation: 'нация',
    },
    {
      id: 10,
      word: 'platform',
      transcription: 'ˈplætfɔːm',
      translation: 'платформа',
    },
    {
      id: 11,
      word: 'seventh',
      transcription: 'ˈsɛvnθ',
      translation: 'седьмой',
    },
    {
      id: 12,
      word: 'significance',
      transcription: 'sɪɡˈnɪfɪkəns',
      translation: 'важность',
    },
    {
      id: 13,
      word: 'sixth',
      transcription: 'sɪksθ',
      translation: 'шестой',
    },
    {
      id: 14,
      word: 'soap',
      transcription: 'səʊp',
      translation: 'мыло',
    },
    {
      id: 15,
      word: 'unkind',
      transcription: 'ʌnˈkaɪnd',
      translation: 'недобрый',
    },
    {
      id: 16,
      word: 'unluckily',
      transcription: 'ʌnˈlʌkɪli',
      translation: 'к несчастью',
    },
    {
      id: 17,
      word: 'virtually',
      transcription: 'ˈvɜːtjʊəli',
      translation: 'фактически',
    },
    {
      id: 18,
      word: 'wage',
      transcription: 'weɪʤ',
      translation: 'зарплата',
    },
    {
      id: 19,
      word: 'fire',
      transcription: 'ˈfaɪə',
      translation: 'увольнять',
    },
  ],
];
