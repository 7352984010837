export const wordsLesson230 = [
  [
    {
      id: 1,
      word: 'admit',
      transcription: 'ədˈmɪt',
      translation: 'признавать',
    },
    {
      id: 2,
      word: 'advertising',
      transcription: 'ˈædvətaɪzɪŋ',
      translation: 'реклама',
    },
    {
      id: 3,
      word: 'beneficial',
      transcription: 'ˌbɛnɪˈfɪʃəl',
      translation: 'выгодный',
    },
    {
      id: 4,
      word: 'criteria',
      transcription: 'kraɪˈtɪərɪə',
      translation: 'критерий',
    },
    {
      id: 5,
      word: 'definition',
      transcription: 'ˌdɛfɪˈnɪʃᵊn',
      translation: 'определение',
    },
    {
      id: 6,
      word: 'desirable',
      transcription: 'dɪˈzaɪərəbᵊl',
      translation: 'желательный',
    },
    {
      id: 7,
      word: 'desire',
      transcription: 'dɪˈzaɪə',
      translation: 'желание',
    },
    {
      id: 8,
      word: 'entertainment',
      transcription: 'ˌɛntəˈteɪnmənt',
      translation: 'развлечение',
    },
    {
      id: 9,
      word: 'invention',
      transcription: 'ɪnˈvɛnʃᵊn',
      translation: 'изобретение',
    },
    {
      id: 10,
      word: 'investment',
      transcription: 'ɪnˈvɛstmənt',
      translation: 'инвестиция',
    },
    {
      id: 11,
      word: 'license',
      transcription: 'ˈlaɪsəns',
      translation: 'лицензия',
    },
    {
      id: 12,
      word: 'lover',
      transcription: 'ˈlʌvə',
      translation: 'любитель',
    },
    {
      id: 13,
      word: 'monotonous',
      transcription: 'məˈnɒtnəs',
      translation: 'монотонный',
    },
    {
      id: 14,
      word: 'perceive',
      transcription: 'pəˈsiːv',
      translation: 'воспринимать',
    },
    {
      id: 15,
      word: 'radically',
      transcription: 'ˈrædɪkəli',
      translation: 'радикально',
    },
    {
      id: 16,
      word: 'relate',
      transcription: 'rɪˈleɪt',
      translation: 'относиться',
    },
  ],
];
