export const contentLesson41 = [
  [
    {
      title: "Урок 41. Past Simple: wasn't / weren't",
    },
    [
      {
        id: 1,
        expression: "Это не было ошибкой",
        transcription: "ɪt wɒznt ə mɪsˈteɪk",
        translation: "It wasn't a mistake",
      },
      {
        id: 2,
        expression: "Они не были бедными мальчиками",
        transcription: "ðeɪ wɜːnt pʊə bɔɪz",
        translation: "They weren't poor boys",
      },
      {
        id: 3,
        expression: "Я был там зимой",
        transcription: "aɪ wəz ðeər ɪn ˈwɪntə",
        translation: "I was there in winter",
      },
      {
        id: 4,
        expression: "Это не было так легко",
        transcription: "ɪt wɒznt səʊ ˈiːzi",
        translation: "It wasn't so easy",
      },
      {
        id: 5,
        expression: "Я не был в банке",
        transcription: "aɪ wɒznt ɪn ə bæŋk",
        translation: "I wasn't in a bank",
      },
      {
        id: 6,
        expression: "Это не было так дешево",
        transcription: "ɪt wɒznt səʊ ʧiːp",
        translation: "It wasn't so cheap",
      },
      {
        id: 7,
        expression: "Он не был бизнесменом",
        transcription: "hi wɒznt ə ˈbɪznɪsmən",
        translation: "He wasn't a businessman",
      },
      {
        id: 8,
        expression: "Это не было дешево",
        transcription: "ɪt wɒznt ʧiːp",
        translation: "It wasn't cheap",
      },
      {
        id: 9,
        expression: "Это не было правильно",
        transcription: "ɪt wɒznt raɪt",
        translation: "It wasn't right",
      },
      {
        id: 10,
        expression: "Это не было в моей сумке",
        transcription: "ɪt wɒznt ɪn maɪ bæg",
        translation: "It wasn't in my bag",
      },
      {
        id: 11,
        expression: "Я не был одинок",
        transcription: "aɪ wɒznt əˈləʊn",
        translation: "I wasn't alone",
      },
      {
        id: 12,
        expression: "Он не был прав",
        transcription: "hi wɒznt raɪt",
        translation: "He wasn't right",
      },
      {
        id: 13,
        expression: "Это не было ошибкой",
        transcription: "ɪt wɒznt ə mɪsˈteɪk",
        translation: "It wasn't a mistake",
      },
      {
        id: 14,
        expression: "Она не была занята",
        transcription: "ʃi wɒznt ˈbɪzi",
        translation: "She wasn't busy",
      },
      {
        id: 15,
        expression: "Это не было дорого",
        transcription: "ɪt wɒznt ɪksˈpɛnsɪv",
        translation: "It wasn't expensive",
      },
      {
        id: 16,
        expression: "Это не было рядом",
        transcription: "ɪt wɒznt nɪə",
        translation: "It wasn't near",
      },
      {
        id: 17,
        expression: "Этого не было достаточно",
        transcription: "ɪt wɒznt ɪˈnʌf",
        translation: "It wasn't enough",
      },
      {
        id: 18,
        expression: "Они не были бедными мальчиками",
        transcription: "ðeɪ wɜːnt pʊə bɔɪz",
        translation: "They weren't poor boys",
      },
      {
        id: 19,
        expression: "Это не было далеко",
        transcription: "ɪt wɒznt fɑː",
        translation: "It wasn't far",
      },
      {
        id: 20,
        expression: "Она не была в Англии в прошлом году",
        transcription: "ʃi wɒznt ɪn ˈɪŋglənd lɑːst jɪə",
        translation: "She wasn't in England last year",
      },
      {
        id: 21,
        expression: "Он не был один",
        transcription: "hi wɒznt əˈləʊn",
        translation: "He wasn't alone",
      },
      {
        id: 22,
        expression: "Это не было в моих планах",
        transcription: "ɪt wɒznt ɪn maɪ plænz",
        translation: "It wasn't in my plans",
      },
      {
        id: 23,
        expression: "Он не был здоров",
        transcription: "hi wɒznt ˈhɛlθi",
        translation: "He wasn't healthy",
      },
      {
        id: 24,
        expression: "Это было так неэффективно",
        transcription: "ɪt wəz səʊ ˌɪnɪˈfɛktɪv / ɪt wɒznt ɪˈfɛktɪv",
        translation: "It was so ineffective / It wasn't effective",
      },
      {
        id: 25,
        expression: "Он не был ленивым",
        transcription: "hi wɒznt ˈleɪzi",
        translation: "He wasn't lazy",
      },
      {
        id: 26,
        expression: "Это было трудно для нас",
        transcription: "ɪt wəz ˈdɪfɪkəlt fər ʌs",
        translation: "It was difficult for us",
      },
      {
        id: 27,
        expression: "Это не было эффективно",
        transcription: "ɪt wɒznt ɪˈfɛktɪv",
        translation: "It wasn't effective",
      },
      {
        id: 28,
        expression: "Она не была в магазине",
        transcription: "ʃi wɒznt ɪn ə ʃɒp",
        translation: "She wasn't in a shop",
      },
      {
        id: 29,
        expression: "Ты не был там",
        transcription: "jʊ wɜːnt ðeə",
        translation: "You weren't there",
      },
      {
        id: 30,
        expression: "Они не были счастливы",
        transcription: "ðeɪ wɜːnt ˈhæpi",
        translation: "They weren't happy",
      },
      {
        id: 31,
        expression: "Это не было полезно",
        transcription: "ɪt wɒznt ˈjuːsfʊl",
        translation: "It wasn't useful",
      },
      {
        id: 32,
        expression: "Это не было бесплатно",
        transcription: "ɪt wɒznt friː",
        translation: "It wasn't free",
      },
      {
        id: 33,
        expression: "Он не был врачом",
        transcription: "hi wɒznt ə ˈdɒktə",
        translation: "He wasn't a doctor",
      },
      {
        id: 34,
        expression: "Он не был очень трудолюбивым",
        transcription: "hi wɒznt ˈvɛri ˈhɑːdˌwɜːkɪŋ",
        translation: "He wasn't very hard-working",
      },
      {
        id: 35,
        expression: "Я не был дома",
        transcription: "aɪ wɒznt ət həʊm",
        translation: "I wasn't at home",
      },
      {
        id: 36,
        expression: "Он не был свободен вчера",
        transcription: "hi wɒznt friː ˈjɛstədeɪ",
        translation: "He wasn't free yesterday",
      },
      {
        id: 37,
        expression: "Они не были богатыми людьми",
        transcription: "ðeɪ wɜːnt rɪʧ ˈpiːpl",
        translation: "They weren't rich people",
      },
      {
        id: 38,
        expression: "Это не была моя идея",
        transcription: "ɪt wɒznt maɪ aɪˈdɪə",
        translation: "It wasn't my idea",
      },
      {
        id: 39,
        expression: "Они не были вместе",
        transcription: "ðeɪ wɜːnt təˈgɛðə",
        translation: "They weren't together",
      },
      {
        id: 40,
        expression: "Это не было интересно для меня",
        transcription: "ɪt wɒznt ˈɪntrɪstɪŋ fə miː",
        translation: "It wasn't interesting for me",
      },
      {
        id: 41,
        expression: "Они не были с нами",
        transcription: "ðeɪ wɜːnt wɪð ʌs",
        translation: "They weren't with us",
      },
      {
        id: 42,
        expression: "Это не было важно",
        transcription: "ɪt wɒznt ɪmˈpɔːtənt",
        translation: "It wasn't important",
      },
      {
        id: 43,
        expression: "Он не был бедным",
        transcription: "hi wɒznt pʊə",
        translation: "He wasn't poor",
      },
      {
        id: 44,
        expression: "Это не было в сумке",
        transcription: "ɪt wɒznt ɪn ðə bæg",
        translation: "It wasn't in the bag",
      },
      {
        id: 45,
        expression: "Я не был там зимой",
        transcription: "aɪ wɒznt ðeər ɪn ˈwɪntə",
        translation: "I wasn't there in winter",
      },
      {
        id: 46,
        expression: "Они не были в зоопарке",
        transcription: "ðeɪ wɜːnt ɪn ðə zuː",
        translation: "They weren't in the zoo",
      },
      {
        id: 47,
        expression: "Этого не было в коробке",
        transcription: "ɪt wɒznt ɪn ðə bɒks",
        translation: "It wasn't in the box",
      },
      {
        id: 48,
        expression: "Он не был в этой ситуации",
        transcription: "hi wɒznt ɪn ðɪs ˌsɪtjʊˈeɪʃən",
        translation: "He wasn't in this situation",
      },
      {
        id: 49,
        expression: "Я не был готов к этому",
        transcription: "aɪ wɒznt ˈrɛdi fər ɪt",
        translation: "I wasn't ready for it",
      },
      {
        id: 50,
        expression: "Мы были там прошлой весной",
        transcription: "wi wə ðeə lɑːst sprɪŋ",
        translation: "We were there last spring",
      },
      {
        id: 51,
        expression: "Они не были голодными",
        transcription: "ðeɪ wɜːnt ˈhʌŋgri",
        translation: "They weren't hungry",
      },
      {
        id: 52,
        expression: "Он купил это, потому что это не было слишком дорого",
        transcription: "hi bɔːt ɪt bɪˈkəz ɪt wɒznt tuː ɪksˈpɛnsɪv",
        translation: "He bought it because it wasn't too expensive",
      },
      {
        id: 53,
        expression: "Это не было лучшим выбором",
        transcription: "ɪt wɒznt ðə bɛst ʧɔɪs",
        translation: "It wasn't the best choice",
      },
      {
        id: 54,
        expression: "Это не было возможно",
        transcription: "ɪt wɒznt ˈpɒsəbl",
        translation: "It wasn't possible",
      },
      {
        id: 55,
        expression: "Она не была в саду",
        transcription: "ʃi wɒznt ɪn ðə ˈgɑːdn",
        translation: "She wasn't in the garden",
      },
      {
        id: 56,
        expression: "Этого не было в моем кармане",
        transcription: "ɪt wɒznt ɪn maɪ ˈpɒkɪt",
        translation: "It wasn't in my pocket",
      },
      {
        id: 57,
        expression: "Я думаю, что это не было так важно",
        transcription: "aɪ θɪŋk ðət ɪt wɒznt səʊ ɪmˈpɔːtənt",
        translation: "I think that it wasn't so important",
      },
      {
        id: 58,
        expression: "Это не было его лучшим результатом",
        transcription: "ɪt wɒznt ɪz bɛst rɪˈzʌlt",
        translation: "It wasn't his best result",
      },
      {
        id: 59,
        expression: "Этого не было на моем столе",
        transcription: "ɪt wɒznt ɒn maɪ ˈteɪbl",
        translation: "It wasn't on my table",
      },
      {
        id: 60,
        expression: "Это было на моем письменном столе",
        transcription: "ɪt wəz ɒn maɪ dɛsk",
        translation: "It was on my desk",
      },
      {
        id: 61,
        expression: "Этого там не было",
        transcription: "ɪt wɒznt ðeə",
        translation: "It wasn't there",
      },
      {
        id: 62,
        expression: "Я понял, что это было не так дешево",
        transcription: "aɪ ˌʌndəˈstʊd ðət ɪt wɒznt səʊ ʧiːp",
        translation: "I understood that it wasn't so cheap",
      },
      {
        id: 63,
        expression: "Он понял, что это было очень дешево",
        transcription: "hi ˌʌndəˈstʊd ðət ɪt wəz ˈvɛri ʧiːp",
        translation: "He understood that it was very cheap",
      },
      {
        id: 64,
        expression: "Я не понял, что это было слишком дешево",
        transcription: "aɪ dɪdnt ˌʌndəˈstænd ðət ɪt wəz tuː ʧiːp",
        translation: "I didn't understand that it was too cheap",
      },
      {
        id: 65,
        expression: "Они не были женаты",
        transcription: "ðeɪ wɜːnt ˈmærɪd",
        translation: "They weren't married",
      },
      {
        id: 66,
        expression: "Мы не были женаты",
        transcription: "wi wɜːnt ˈmærɪd",
        translation: "We weren't married",
      },
      {
        id: 67,
        expression: "Я знаю, что это было не бесплатно",
        transcription: "aɪ nəʊ ðət ɪt wɒznt friː",
        translation: "I know that it wasn't free",
      },
      {
        id: 68,
        expression: "Он знает, что это было бесплатно",
        transcription: "hi nəʊz ðət ɪt wəz friː",
        translation: "He knows that it was free",
      },
      {
        id: 69,
        expression: "Я знаю, что это не было дорого",
        transcription: "aɪ nəʊ ðət ɪt wɒznt ɪksˈpɛnsɪv",
        translation: "I know that it wasn't expensive",
      },
      {
        id: 70,
        expression: "Я был действительно голоден",
        transcription: "aɪ wəz ˈrɪəli ˈhʌŋgri",
        translation: "I was really hungry",
      },
      {
        id: 71,
        expression: "Она не была голодна вчера",
        transcription: "ʃi wɒznt ˈhʌŋgri ˈjɛstədeɪ",
        translation: "She wasn't hungry yesterday",
      },
      {
        id: 72,
        expression: "Я думаю, это не было полезно для меня",
        transcription: "aɪ θɪŋk ɪt wɒznt ˈjuːsfʊl fə miː",
        translation: "I think it wasn't useful for me",
      },
      {
        id: 73,
        expression: "Я думаю, что это не было полезно для нее",
        transcription: "aɪ θɪŋk ðət ɪt wɒznt ˈjuːsfʊl fə hə",
        translation: "I think that it wasn't useful for her",
      },
      {
        id: 74,
        expression: "Он думает, это было очень полезно",
        transcription: "hi θɪŋks ɪt wəz ˈvɛri ˈjuːsfʊl",
        translation: "He thinks it was very useful",
      },
      {
        id: 75,
        expression: "Он не был готов к этому тесту",
        transcription: "hi wɒznt ˈrɛdi fə ðɪs tɛst",
        translation: "He wasn't ready for this test",
      },
      {
        id: 76,
        expression: "Она была готова к экзамену",
        transcription: "ʃi wəz ˈrɛdi fə ði ɪgˈzæm",
        translation: "She was ready for the exam",
      },
      {
        id: 77,
        expression: "Я не был готов к этой ситуации",
        transcription: "aɪ wɒznt ˈrɛdi fə ðɪs ˌsɪtjʊˈeɪʃən",
        translation: "I wasn't ready for this situation",
      },
      {
        id: 78,
        expression: "Я думаю, это был не лучший ответ",
        transcription: "aɪ θɪŋk ɪt wɒznt ðə bɛst ˈɑːnsə",
        translation: "I think it wasn't the best answer",
      },
      {
        id: 79,
        expression: "Он думает, это не было так хорошо",
        transcription: "hi θɪŋks ɪt wɒznt səʊ gʊd",
        translation: "He thinks it wasn't so good",
      },
      {
        id: 80,
        expression: "Я думаю, это была лучшая идея",
        transcription: "aɪ θɪŋk ɪt wəz ðə bɛst aɪˈdɪə",
        translation: "I think it was the best idea",
      },
      {
        id: 81,
        expression: "Это был не лучший ответ",
        transcription: "ɪt wɒznt ðə bɛst ˈɑːnsə",
        translation: "It wasn't the best answer",
      },
      {
        id: 82,
        expression: "Он был лучшим певцом",
        transcription: "hi wəz ðə bɛst ˈsɪŋə",
        translation: "He was the best singer",
      },
      {
        id: 83,
        expression: "Она не была лучшей танцовщицей",
        transcription: "ʃi wɒznt ðə bɛst ˈdɑːnsə",
        translation: "She wasn't the best dancer",
      },
      {
        id: 84,
        expression: "Они не были злы",
        transcription: "ðeɪ wɜːnt ˈæŋgri",
        translation: "They weren't angry",
      },
      {
        id: 85,
        expression: "Я не был голоден",
        transcription: "aɪ wɒznt ˈhʌŋgri",
        translation: "I wasn't hungry",
      },
      {
        id: 86,
        expression: "Мы не были счастливы",
        transcription: "wi wɜːnt ˈhæpi",
        translation: "We weren't happy",
      },
      {
        id: 87,
        expression: "Я знал, что это не было возможно",
        transcription: "aɪ njuː ðət ɪt wɒznt ˈpɒsəbl",
        translation: "I knew that it wasn't possible",
      },
      {
        id: 88,
        expression: "Этого не было на бумаге",
        transcription: "ɪt wɒznt ɒn ˈpeɪpə",
        translation: "It wasn't on paper",
      },
      {
        id: 89,
        expression: "Это было в сумке",
        transcription: "ɪt wəz ɪn ðə bæg",
        translation: "It was in the bag",
      },
      {
        id: 90,
        expression: "Это не было дорого для нас",
        transcription: "ɪt wɒznt ɪksˈpɛnsɪv fər ʌs",
        translation: "It wasn't expensive for us",
      },
      {
        id: 91,
        expression: "Я не был готов к этому экзамену",
        transcription: "aɪ wɒznt ˈrɛdi fə ðɪs ɪgˈzæm",
        translation: "I wasn't ready for this exam",
      },
      {
        id: 92,
        expression: "Я думаю, это была не лучшая идея",
        transcription: "aɪ θɪŋk ɪt wɒznt ðə bɛst aɪˈdɪə",
        translation: "I think it wasn't the best idea",
      },
      {
        id: 93,
        expression: "Я не был в парке",
        transcription: "aɪ wɒznt ɪn ðə pɑːk",
        translation: "I wasn't in the park",
      },
      {
        id: 94,
        expression: "Этого не было на столе",
        transcription: "ɪt wɒznt ɒn ðə ˈteɪbl",
        translation: "It wasn't on the table",
      },
      {
        id: 95,
        expression: "Я думаю, это не было интересно для него",
        transcription: "aɪ θɪŋk ɪt wɒznt ˈɪntrɪstɪŋ fə hɪm",
        translation: "I think it wasn't interesting for him",
      },
      {
        id: 96,
        expression: "Это было летом",
        transcription: "ɪt wəz ɪn ˈsʌmə",
        translation: "It was in summer",
      },
      {
        id: 97,
        expression: "Это не было нам интересно",
        transcription: "ɪt wɒznt ˈɪntrɪstɪŋ fər ʌs",
        translation: "It wasn't interesting for us",
      },
      {
        id: 98,
        expression: "Я видел, что это было не легко",
        transcription: "aɪ sɔː ðət ɪt wɒznt ˈiːzi",
        translation: "I saw that it wasn't easy",
      },
      {
        id: 99,
        expression: "Это не было проблемой для меня",
        transcription: "ɪt wɒznt ə ˈprɒbləm fə miː",
        translation: "It wasn't a problem for me",
      },
      {
        id: 100,
        expression: "Это было осенью",
        transcription: "ɪt wəz ɪn ˈɔːtəm",
        translation: "It was in autumn",
      },
      {
        id: 101,
        expression: "Я думаю, они были не правы",
        transcription: "aɪ θɪŋk ðeɪ wɜːnt raɪt / aɪ θɪŋk ðeɪ wə rɒŋ",
        translation: "I think they weren't right / I think they were wrong",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Was / were в негативной форме в Past Simple</h3>
<p>Образуется по формуле:</p>
<p><span>подлежащее + were not / was not + ...</span></p>
<p>Сокращенный вариант: <span>подлежащее + weren't / wasn't + ...</span></p>
<p>It wasn't a big mistake - Это не было большой ошибкой</p>
<p>He wasn't kind to her - Он не был добр к ней</p>
<p>К устойчивым выражениям:</p>
<h3>in winter / spring / summer / autumn</h3>
<p>- змиой / весной / летом / осенью</p>
<p>We weren't there in winter - Мы не были там зимой</p>
</div>`,
    },
  ],
];
