export const contentLesson175 = [
  [
    {
      title: 'Урок 175. To / ing. Part 1',
    },
    [
      {
        id: 1,
        expression: 'Она пообещала сохранить его секрет',
        transcription: 'ʃi ˈprɒmɪst tə kiːp ɪz ˈsiːkrɪt',
        translation: 'She promised to keep his secret',
      },
      {
        id: 2,
        expression: 'Он избегает встречать ее',
        transcription: 'hi əˈvɔɪdz ˈmiːtɪŋ hɜː',
        translation: 'He avoids meeting her',
      },
      {
        id: 3,
        expression: 'Я хочу пойти в этот парк',
        transcription: 'aɪ wɒnt tə gəʊ tə ðɪs pɑːk',
        translation: 'I want to go to this park',
      },
      {
        id: 4,
        expression: 'Я обожаю читать книги',
        transcription: 'aɪ ɪnˈʤɔɪ ˈriːdɪŋ bʊks',
        translation: 'I enjoy reading books',
      },
      {
        id: 5,
        expression: 'Он обещал не делать этого',
        transcription: 'hi ˈprɒmɪst nɒt tə dʊ ɪt',
        translation: 'He promised not to do it',
      },
      {
        id: 6,
        expression: 'Я не рекомендую покупать еду там',
        transcription: 'aɪ dəʊnt ˌrɛkəˈmɛnd ˈbaɪɪŋ fuːd ðeə',
        translation: "I don't recommend buying food there",
      },
      {
        id: 7,
        expression: 'Что ты любишь делать в свое свободное время?',
        transcription: 'wɒt dʊ jʊ laɪk ˈdu(ː)ɪŋ ɪn jə speə taɪm?',
        translation: 'What do you like doing in your spare time?',
      },
      {
        id: 8,
        expression: 'Я хочу пойти домой сейчас',
        transcription: 'aɪ wɒnt tə gəʊ həʊm naʊ',
        translation: 'I want to go home now',
      },
      {
        id: 9,
        expression: 'Что ты предлагаешь делать?',
        transcription: 'wɒt dʊ jʊ səˈʤɛst ˈdu(ː)ɪŋ?',
        translation: 'What do you suggest doing?',
      },
      {
        id: 10,
        expression: 'Я надеюсь сдать этот тест',
        transcription: 'aɪ həʊp tə pɑːs ðɪs tɛst',
        translation: 'I hope to pass this test',
      },
      {
        id: 11,
        expression: 'Он попросил меня пойти с ним',
        transcription: 'hi ɑːskt mi tə gəʊ wɪð hɪm',
        translation: 'He asked me to go with him',
      },
      {
        id: 12,
        expression: 'Я не хотел бы говорить об этом',
        transcription: 'aɪ ˈwʊdnt laɪk tə tɔːk əˈbaʊt ɪt',
        translation: "I wouldn't like to talk about it",
      },
      {
        id: 13,
        expression: 'Я рекомендую прочитать эту книгу',
        transcription: 'aɪ ˌrɛkəˈmɛnd ˈriːdɪŋ ðɪs bʊk',
        translation: 'I recommend reading this book',
      },
      {
        id: 14,
        expression: 'Я решил не делать этого',
        transcription: 'aɪ dɪˈsaɪdɪd nɒt tə dʊ ɪt',
        translation: 'I decided not to do it',
      },
      {
        id: 15,
        expression: 'Я действительно обожаю заниматься спортом',
        transcription: 'aɪ ˈrɪəli ɪnˈʤɔɪ ˈdu(ː)ɪŋ (ˈpleɪɪŋ, ˈpræktɪsɪŋ) spɔːts',
        translation: 'I really enjoy doing (playing, practicing) sports',
      },
      {
        id: 16,
        expression: 'Ты хотел бы жить за границей?',
        transcription: 'wəd jʊ laɪk tə lɪv əˈbrɔːd?',
        translation: 'Would you like to live abroad?',
      },
      {
        id: 17,
        expression: 'Я хотел бы сказать тебе одну вещь',
        transcription: 'aɪd laɪk tə tɛl jʊ wʌn θɪŋ',
        translation: "I'd like to tell you one thing",
      },
      {
        id: 18,
        expression: 'Я предлагаю пойти туда сейчас',
        transcription: 'aɪ səˈʤɛst ˈgəʊɪŋ ðeə naʊ',
        translation: 'I suggest going there now',
      },
      {
        id: 19,
        expression: 'Он надеется сдать этот тест',
        transcription: 'hi həʊps tə pɑːs ðɪs tɛst',
        translation: 'He hopes to pass this test',
      },
      {
        id: 20,
        expression: 'Я рекомендую посмотреть это видео',
        transcription: 'aɪ ˌrɛkəˈmɛnd ˈwɒʧɪŋ ðɪs ˈvɪdɪəʊ',
        translation: 'I recommend watching this video',
      },
      {
        id: 21,
        expression: 'Я действительно обожаю изучать иностранные языки',
        transcription: 'aɪ ˈrɪəli ɪnˈʤɔɪ ˈlɜːnɪŋ ˈfɒrɪn ˈlæŋgwɪʤɪz',
        translation: 'I really enjoy learning foreign languages',
      },
      {
        id: 22,
        expression: 'Он хочет видеть врача',
        transcription: 'hi wɒnts tə siː ə ˈdɒktə',
        translation: 'He wants to see a doctor',
      },
      {
        id: 23,
        expression: 'Он рекомендовал подождать',
        transcription: 'hi ˌrɛkəˈmɛndɪd ˈweɪtɪŋ',
        translation: 'He recommended waiting',
      },
      {
        id: 24,
        expression: 'Она согласилась помочь нам',
        transcription: 'ʃi əˈgriːd tə hɛlp ʌs',
        translation: 'She agreed to help us',
      },
      {
        id: 25,
        expression: 'Она действительно любит танцевать',
        transcription: 'ʃi ˈrɪəli lʌvz ˈdɑːnsɪŋ',
        translation: 'She really loves dancing',
      },
      {
        id: 26,
        expression: 'Я предлагаю подождать',
        transcription: 'aɪ səˈʤɛst ˈweɪtɪŋ',
        translation: 'I suggest waiting',
      },
      {
        id: 27,
        expression: 'Я избегаю обсуждать такого рода вещи',
        transcription: 'aɪ əˈvɔɪd dɪsˈkʌsɪŋ ðɪs kaɪnd əv θɪŋz',
        translation: 'I avoid discussing this kind of things',
      },
      {
        id: 28,
        expression: 'Я люблю играть в настольный теннис',
        transcription: 'aɪ lʌv ˈpleɪɪŋ ˈteɪbl ˈtɛnɪs',
        translation: 'I love playing table tennis',
      },
      {
        id: 29,
        expression: 'Он обещал сдержать свои слова',
        transcription: 'hi ˈprɒmɪst tə kiːp ɪz wɜːdz',
        translation: 'He promised to keep his words',
      },
      {
        id: 30,
        expression: 'Она только что закончила готовить',
        transcription: 'ʃiːz ʤəst ˈfɪnɪʃt ˈkʊkɪŋ',
        translation: "She's just finished cooking",
      },
      {
        id: 31,
        expression: 'Я закончил делать свое домашнее задание',
        transcription: 'aɪ ˈfɪnɪʃt ˈdu(ː)ɪŋ maɪ ˈhəʊmˌwɜːk',
        translation: 'I finished doing my homework',
      },
      {
        id: 32,
        expression: 'Ему нравится бегать трусцой',
        transcription: 'hi laɪks ˈʤɒgɪŋ',
        translation: 'He likes jogging',
      },
      {
        id: 33,
        expression: 'Я предлагаю сходить посмотреть это',
        transcription: 'aɪ səˈʤɛst ˈgəʊɪŋ tə siː ɪt',
        translation: 'I suggest going to see it',
      },
      {
        id: 34,
        expression: 'Он действительно обожает заниматься спортом',
        transcription: 'hi ˈrɪəli ɪnˈʤɔɪz ˈdu(ː)ɪŋ spɔːts',
        translation: 'He really enjoys doing sports',
      },
      {
        id: 35,
        expression: 'Я обожаю заниматься спортом',
        transcription: 'aɪ ɪnˈʤɔɪ ˈpleɪɪŋ spɔːts',
        translation: 'I enjoy playing sports',
      },
      {
        id: 36,
        expression: 'Я действительно обожаю проводить время с тобой',
        transcription: 'aɪ ˈrɪəli ɪnˈʤɔɪ ˈspɛndɪŋ taɪm wɪð juː',
        translation: 'I really enjoy spending time with you',
      },
      {
        id: 37,
        expression: 'Мы еще не закончили это обсуждать',
        transcription: 'wi hævnt ˈfɪnɪʃt dɪsˈkʌsɪŋ ɪt jɛt',
        translation: "We haven't finished discussing it yet",
      },
      {
        id: 38,
        expression: 'Ты хотел бы жить в другой стране?',
        transcription: 'wəd jʊ laɪk tə lɪv ɪn əˈnʌðə ˈkʌntri?',
        translation: 'Would you like to live in another country?',
      },
      {
        id: 39,
        expression: 'Я начал думать об этом действительно серьезно',
        transcription: 'aɪ ˈstɑːtɪd ˈθɪŋkɪŋ əˈbaʊt ɪt ˈrɪəli ˈsɪərɪəsli',
        translation: 'I started thinking about it really seriously',
      },
      {
        id: 40,
        expression: 'Она обещала сохранить этот секрет',
        transcription: 'ʃi ˈprɒmɪst tə kiːp ðɪs ˈsiːkrɪt',
        translation: 'She promised to keep this secret',
      },
      {
        id: 41,
        expression: 'Когда ты закончишь это делать?',
        transcription: 'wɛn wɪl jʊ ˈfɪnɪʃ ˈdu(ː)ɪŋ ɪt?',
        translation: 'When will you finish doing it?',
      },
      {
        id: 42,
        expression: 'Он посоветовал мне не делать этого',
        transcription: 'hi ədˈvaɪzd mi nɒt tə dʊ ɪt',
        translation: 'He advised me not to do it',
      },
      {
        id: 43,
        expression: 'Она еще не закончила говорить',
        transcription: 'ʃi ˈhæznt ˈfɪnɪʃt ˈspiːkɪŋ jɛt',
        translation: "She hasn't finished speaking yet",
      },
      {
        id: 44,
        expression: 'Я хочу сказать следующее',
        transcription: 'aɪ wɒnt tə seɪ ðə ˈfɒləʊɪŋ',
        translation: 'I want to say the following',
      },
      {
        id: 45,
        expression: 'Я не против пойти туда',
        transcription: 'aɪ dəʊnt maɪnd ˈgəʊɪŋ ðeə',
        translation: "I don't mind going there",
      },
      {
        id: 46,
        expression: 'Ты хочешь пойти в ресторан сегодня вечером?',
        transcription: 'dʊ jʊ ˈfænsi ˈgəʊɪŋ tə ðə ˈrɛstrɒnt təˈnaɪt?',
        translation: 'Do you fancy going to the restaurant tonight?',
      },
      {
        id: 47,
        expression: 'Я избегаю рассказывать другим людям о своих планах',
        transcription: 'aɪ əˈvɔɪd ˈtɛlɪŋ ˈʌðə ˈpiːpl əˈbaʊt maɪ plænz',
        translation: 'I avoid telling other people about my plans',
      },
      {
        id: 48,
        expression: 'Он избегает говорить об этом',
        transcription: 'hi əˈvɔɪdz ˈtɔːkɪŋ əˈbaʊt ɪt',
        translation: 'He avoids talking about it',
      },
      {
        id: 49,
        expression: 'Я не могу не восхищаться красотой этого места',
        transcription: 'aɪ kɑːnt hɛlp ədˈmaɪərɪŋ ðə ˈbjuːti əv ðɪs pleɪs',
        translation: "I can't help admiring the beauty of this place",
      },
      {
        id: 50,
        expression: 'Она не может не восхищаться таким человеком',
        transcription: 'ʃi kɑːnt hɛlp ədˈmaɪərɪŋ sʌʧ ə ˈpɜːsn',
        translation: "She can't help admiring such a person",
      },
      {
        id: 51,
        expression: 'Ты когда-нибудь рассматривал жизнь за границей?',
        transcription: 'həv jʊ ˈɛvə kənˈsɪdəd ˈlɪvɪŋ əˈbrɔːd?',
        translation: 'Have you ever considered living abroad?',
      },
      {
        id: 52,
        expression: 'Он не против ждать этого',
        transcription: 'hi dʌznt maɪnd ˈweɪtɪŋ fər ɪt',
        translation: "He doesn't mind waiting for it",
      },
      {
        id: 53,
        expression: 'Эта страна хотела стать членом Европейского Союза',
        transcription:
          'ðɪs ˈkʌntri ˈwɒntɪd tə bɪˈkʌm ə ˈmɛmbər əv ðə ˌjʊərəˈpi(ː)ən ˈjuːnjən',
        translation:
          'This country wanted to become a member of the European Union',
      },
      {
        id: 54,
        expression: 'Они отложили обсуждение этих событий',
        transcription: 'ðeɪ pəʊstˈpəʊnd dɪsˈkʌsɪŋ ðiːz ɪˈvɛnts',
        translation: 'They postponed discussing these events',
      },
      {
        id: 55,
        expression: 'Ты хочешь выпить чашку кофе?',
        transcription: 'dʊ jʊ ˈfænsi ˈdrɪŋkɪŋ ə kʌp əv ˈkɒfi?',
        translation: 'Do you fancy drinking a cup of coffee?',
      },
      {
        id: 56,
        expression: 'Она избегает говорить с ним',
        transcription: 'ʃi əˈvɔɪdz ˈtɔːkɪŋ tə hɪm',
        translation: 'She avoids talking to him',
      },
      {
        id: 57,
        expression: 'Определенно, я рекомендую посмотреть этот фильм',
        transcription: 'ˈdɛfɪnɪtli, aɪ ˌrɛkəˈmɛnd ˈwɒʧɪŋ ðɪs ˈmuːvi',
        translation: 'Definitely, I recommend watching this movie',
      },
      {
        id: 58,
        expression: 'Мне нравится гулять в парке, дыша свежим воздухом',
        transcription: 'aɪ laɪk ˈwɔːkɪŋ ɪn ðə pɑːk ˈbriːðɪŋ ðə frɛʃ eə',
        translation: 'I like walking in the park breathing the fresh air',
      },
      {
        id: 59,
        expression: 'Я настоятельно рекомендую посетить это место',
        transcription: 'aɪ ˈstrɒŋli ˌrɛkəˈmɛnd ˈvɪzɪtɪŋ ðɪs pleɪs',
        translation: 'I strongly recommend visiting this place',
      },
      {
        id: 60,
        expression: 'Ты знаешь, я действительно обожаю это делать',
        transcription: 'jʊ nəʊ, aɪ ˈrɪəli ɪnˈʤɔɪ ˈdu(ː)ɪŋ ɪt',
        translation: 'You know, I really enjoy doing it',
      },
      {
        id: 61,
        expression: 'Ты знаешь, я действительно обожаю плавать',
        transcription: 'jʊ nəʊ, aɪ ˈrɪəli ɪnˈʤɔɪ ˈswɪmɪŋ',
        translation: 'You know, I really enjoy swimming',
      },
      {
        id: 62,
        expression: 'Ты знаешь, он действительно обожает петь',
        transcription: 'jʊ nəʊ, hi ˈrɪəli ɪnˈʤɔɪ ˈsɪŋɪŋ',
        translation: 'You know, he really enjoy singing',
      },
      {
        id: 63,
        expression: 'Он рассматривает обучение за границей',
        transcription: 'hi kənˈsɪdəz ˈstʌdiɪŋ əˈbrɔːd',
        translation: 'He considers studying abroad',
      },
      {
        id: 64,
        expression: 'Он рассматривает переезд за границу',
        transcription: 'hi kənˈsɪdəz ˈmuːvɪŋ əˈbrɔːd',
        translation: 'He considers moving abroad',
      },
      {
        id: 65,
        expression: 'Он рассматривал обучение там',
        transcription: 'hi kənˈsɪdəd ˈstʌdiɪŋ ðeə',
        translation: 'He considered studying there',
      },
      {
        id: 66,
        expression: 'Начался снег',
        transcription: 'ɪt ˈstɑːtɪd ˈsnəʊɪŋ',
        translation: 'It started snowing',
      },
      {
        id: 67,
        expression: 'Начался дождь',
        transcription: 'ɪt ˈstɑːtɪd ˈreɪnɪŋ',
        translation: 'It started raining',
      },
      {
        id: 68,
        expression: 'Дождь уже закончился?',
        transcription: 'həz ɪt ɔːlˈrɛdi stɒpt ˈreɪnɪŋ?',
        translation: 'Has it already stopped raining?',
      },
      {
        id: 69,
        expression: 'Я избегаю делать это',
        transcription: 'aɪ əˈvɔɪd ˈdu(ː)ɪŋ ɪt',
        translation: 'I avoid doing it',
      },
      {
        id: 70,
        expression: 'Я избегаю говорить об этом',
        transcription: 'aɪ əˈvɔɪd ˈtɔːkɪŋ əˈbaʊt ɪt',
        translation: 'I avoid talking about it',
      },
      {
        id: 71,
        expression: 'Я избегаю обсуждать это',
        transcription: 'aɪ əˈvɔɪd dɪsˈkʌsɪŋ ɪt',
        translation: 'I avoid discussing it',
      },
      {
        id: 72,
        expression: 'Я еще не закончил делать это',
        transcription: 'aɪ hævnt ˈfɪnɪʃt ˈdu(ː)ɪŋ ɪt jɛt',
        translation: "I haven't finished doing it yet",
      },
      {
        id: 73,
        expression: 'Я уже закончил это делать',
        transcription: 'aɪv ɔːlˈrɛdi ˈfɪnɪʃt ˈdu(ː)ɪŋ ɪt',
        translation: "I've already finished doing it",
      },
      {
        id: 74,
        expression: 'Я еще не прочитал эту книгу',
        transcription: 'aɪ hævnt rɛd ðɪs bʊk jɛt',
        translation: "I haven't read this book yet",
      },
      {
        id: 75,
        expression: 'Я хочу плавать',
        transcription: 'aɪ ˈfænsi ˈswɪmɪŋ',
        translation: 'I fancy swimming',
      },
      {
        id: 76,
        expression: 'Я хочу бегать',
        transcription: 'aɪ ˈfænsi ˈrʌnɪŋ',
        translation: 'I fancy running',
      },
      {
        id: 77,
        expression: 'Я не хочу плавать',
        transcription: 'aɪ dəʊnt ˈfænsi ˈswɪmɪŋ',
        translation: "I don't fancy swimming",
      },
      {
        id: 78,
        expression: 'Я не хотел плавать там',
        transcription: 'aɪ dɪdnt ˈfænsi ˈswɪmɪŋ ðeə',
        translation: "I didn't fancy swimming there",
      },
      {
        id: 79,
        expression: 'Я не хочу посещать этот клуб',
        transcription: 'aɪ dəʊnt ˈfænsi ˈvɪzɪtɪŋ ðɪs klʌb',
        translation: "I don't fancy visiting this club",
      },
      {
        id: 80,
        expression: 'Я не хотел петь эту песню',
        transcription: 'aɪ dɪdnt ˈfænsi ˈsɪŋɪŋ ðɪs sɒŋ',
        translation: "I didn't fancy singing this song",
      },
      {
        id: 81,
        expression: 'Определенно, я рекомендую посетить это место',
        transcription: 'ˈdɛfɪnɪtli, aɪ ˌrɛkəˈmɛnd ˈvɪzɪtɪŋ ðɪs pleɪs',
        translation: 'Definitely, I recommend visiting this place',
      },
      {
        id: 82,
        expression: 'Конечно, я рекомендую пойти в это место',
        transcription: 'əv kɔːs, aɪ ˌrɛkəˈmɛnd ˈgəʊɪŋ tə ðɪs pleɪs',
        translation: 'Of course, I recommend going to this place',
      },
      {
        id: 83,
        expression: 'Они отложили представление этих материалов',
        transcription: 'ðeɪ pəʊstˈpəʊnd prɪˈzɛntɪŋ ðiːz məˈtɪərɪəlz',
        translation: 'They postponed presenting these materials',
      },
      {
        id: 84,
        expression: 'Они отложили показ этих материалов',
        transcription: 'ðeɪ pəʊstˈpəʊnd ˈʃəʊɪŋ ðiːz məˈtɪərɪəlz',
        translation: 'They postponed showing these materials',
      },
      {
        id: 85,
        expression: 'Они отложили это дело',
        transcription: 'ðeɪ pəʊstˈpəʊnd ˈdu(ː)ɪŋ ɪt',
        translation: 'They postponed doing it',
      },
      {
        id: 86,
        expression: 'Я не против сделать это прямо сейчас',
        transcription: 'aɪ dəʊnt maɪnd ˈdu(ː)ɪŋ ɪt raɪt naʊ',
        translation: "I don't mind doing it right now",
      },
      {
        id: 87,
        expression: 'Я не против пойти туда прямо сейчас',
        transcription: 'aɪ dəʊnt maɪnd ˈgəʊɪŋ ðeə raɪt naʊ',
        translation: "I don't mind going there right now",
      },
      {
        id: 88,
        expression: 'Я не против посетить этот музей прямо сейчас',
        transcription: 'aɪ dəʊnt maɪnd ˈvɪzɪtɪŋ ðɪs mju(ː)ˈzɪəm raɪt naʊ',
        translation: "I don't mind visiting this museum right now",
      },
      {
        id: 89,
        expression: 'Малыш начал плакать',
        transcription: 'ðə ˈbeɪbi ˈstɑːtɪd ˈkraɪɪŋ',
        translation: 'The baby started crying',
      },
      {
        id: 90,
        expression: 'Я не могу не восхищаться такими людьми',
        transcription: 'aɪ kɑːnt hɛlp ədˈmaɪərɪŋ sʌʧ ˈpiːpl',
        translation: "I can't help admiring such people",
      },
      {
        id: 91,
        expression: 'Ты уже закончил это делать?',
        transcription: 'həv jʊ ɔːlˈrɛdi ˈfɪnɪʃt ˈdu(ː)ɪŋ ɪt?',
        translation: 'Have you already finished doing it?',
      },
      {
        id: 92,
        expression: 'Я настоятельно советую тебе не делать этого',
        transcription: 'aɪ ˈstrɒŋli ədˈvaɪz jʊ nɒt tə dʊ ɪt',
        translation: 'I strongly advise you not to do it',
      },
      {
        id: 93,
        expression: 'Ты не против помочь мне?',
        transcription: 'dʊ jʊ maɪnd ˈhɛlpɪŋ miː?',
        translation: 'Do you mind helping me?',
      },
      {
        id: 94,
        expression: 'Он избегает рассказывать другим о своих планах',
        transcription: 'hi əˈvɔɪdz ˈtɛlɪŋ ˈʌðəz əˈbaʊt ɪz plænz',
        translation: 'He avoids telling others about his plans',
      },
      {
        id: 95,
        expression: 'Я хочу встретиться с тобой здесь',
        transcription: 'aɪ ˈfænsi ˈmiːtɪŋ jʊ hɪə',
        translation: 'I fancy meeting you here',
      },
      {
        id: 96,
        expression: 'Они отложили строительство новой больницы',
        transcription: 'ðeɪ pəʊstˈpəʊnd ˈbɪldɪŋ ə njuː ˈhɒspɪtl',
        translation: 'They postponed building a new hospital',
      },
      {
        id: 97,
        expression: 'Он избегает встречи с ней',
        transcription: 'hi əˈvɔɪdz ˈmiːtɪŋ hɜː',
        translation: 'He avoids meeting her',
      },
      {
        id: 98,
        expression: 'Он отложил строительство дома',
        transcription: 'hi pəʊstˈpəʊnd ˈbɪldɪŋ ə haʊs',
        translation: 'He postponed building a house',
      },
      {
        id: 99,
        expression: 'Он хочет стать членом этой организации',
        transcription: 'hi wɒnts tə bɪˈkʌm ə ˈmɛmbər əv ðɪs ˌɔːgənaɪˈzeɪʃən',
        translation: 'He wants to become a member of this organisation',
      },
      {
        id: 100,
        expression: 'Ты хочешь выйти сегодня вечером?',
        transcription: 'dʊ jʊ ˈfænsi ˈgəʊɪŋ aʊt təˈnaɪt?',
        translation: 'Do you fancy going out tonight?',
      },
      {
        id: 101,
        expression: 'Скоро начнется дождь',
        transcription: 'ˈɪtl stɑːt ˈreɪnɪŋ suːn',
        translation: "It'll start raining soon",
      },
      {
        id: 102,
        expression: 'Я не против сделать это',
        transcription: 'aɪ dəʊnt maɪnd ˈdu(ː)ɪŋ ɪt',
        translation: "I don't mind doing it",
      },
      {
        id: 103,
        expression: 'Я начал изучать английский, когда я был ребенком',
        transcription: 'aɪ ˈstɑːtɪd ˈlɜːnɪŋ ˈɪŋglɪʃ wɛn aɪ wəz ə ʧaɪld',
        translation: 'I started learning English when I was a child',
      },
    ],
    {
      theory: `<div class="theory-block">
<p>Сочетания двух подряд стоящих глаголов могут образовываться двумя способами:</p>
<p>Формула 1:</p>
<p><span>глагол 1 + to + глагол 2</span></p>
<p>She promised me to keep this secret - Она пообещала мне сохранить этот секрет.</p>
<p>Формула 2:</p>
<p><span>глагол 1 + глагол 2+ing</span></p>
<p>He avoids meeting her - Он избегает встречать ее.</p>
<p>Выбор способа зависит от глагола 1. После одних глаголов всегда ставится только инфинитив (to + глагол-состояние (state verb - см. урок 114)), после других - только глагол+ing, после третьих возможны оба варианта.</p>
</div>`,
    },
  ],
];
