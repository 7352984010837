export const contentLesson39 = [
  [
    {
      title: "Урок 39. Past Simple. Irregular verbs. Part 3",
    },
    [
      {
        id: 1,
        expression: "Я купил килограмм яблок",
        transcription: "aɪ bɔːt ə ˈkiːləʊ əv ˈæplz",
        translation: "I bought a kilo of apples",
      },
      {
        id: 2,
        expression: "Он встал, и затем он сел",
        transcription: "hi stʊd ʌp ənd ðɛn hi sæt daʊn",
        translation: "He stood up and then he sat down",
      },
      {
        id: 3,
        expression: "Я написал это на бумаге",
        transcription: "aɪ rəʊt ɪt ɒn ˈpeɪpə",
        translation: "I wrote it on paper",
      },
      {
        id: 4,
        expression: "Он сел",
        transcription: "hi sæt daʊn",
        translation: "He sat down",
      },
      {
        id: 5,
        expression: "Я слышал это тоже",
        transcription: "aɪ hɜːd ɪt, tuː",
        translation: "I heard it, too",
      },
      {
        id: 6,
        expression: "Он пил молоко",
        transcription: "hi dræŋk mɪlk",
        translation: "He drank milk",
      },
      {
        id: 7,
        expression: "Она отправила нам копию",
        transcription: "ʃi sɛnt əs ə ˈkɒpi",
        translation: "She sent us a copy",
      },
      {
        id: 8,
        expression: "Он выпил немного воды",
        transcription: "hi dræŋk səm ˈwɔːtə",
        translation: "He drank some water",
      },
      {
        id: 9,
        expression: "Они продавали очень дорогие товары",
        transcription: "ðeɪ səʊld ˈvɛri ɪksˈpɛnsɪv gʊdz",
        translation: "They sold very expensive goods",
      },
      {
        id: 10,
        expression: "Он сделал ошибку",
        transcription: "hi meɪd ə mɪsˈteɪk",
        translation: "He made a mistake",
      },
      {
        id: 11,
        expression: "Он пришел домой поздно",
        transcription: "hi keɪm həʊm leɪt",
        translation: "He came home late",
      },
      {
        id: 12,
        expression: "Он написал письмо",
        transcription: "hi rəʊt ə ˈlɛtə",
        translation: "He wrote a letter",
      },
      {
        id: 13,
        expression: "Где ты встретил ее?",
        transcription: "weə dɪd jʊ miːt hɜː?",
        translation: "Where did you meet her?",
      },
      {
        id: 14,
        expression: "У него был вопрос",
        transcription: "hi həd ə ˈkwɛsʧən",
        translation: "He had a question",
      },
      {
        id: 15,
        expression: "Она продала свой дом",
        transcription: "ʃi səʊld hə haʊs",
        translation: "She sold her house",
      },
      {
        id: 16,
        expression: "Я отправил ей письмо",
        transcription: "aɪ sɛnt hər ə ˈlɛtə",
        translation: "I sent her a letter",
      },
      {
        id: 17,
        expression: "Они сделали несколько ошибок",
        transcription: "ðeɪ meɪd səm mɪsˈteɪks",
        translation: "They made some mistakes",
      },
      {
        id: 18,
        expression: "Он рассказал мне все",
        transcription: "hi təʊld mi ˈɛvrɪθɪŋ",
        translation: "He told me everything",
      },
      {
        id: 19,
        expression: "Он тоже пришел на вечеринку",
        transcription:
          "hi ˈɔːlsəʊ keɪm tə ðə ˈpɑːti / hi keɪm tə ðə ˈpɑːti, tuː",
        translation: "He also came to the party / He came to the party, too",
      },
      {
        id: 20,
        expression: "Они пили сок",
        transcription: "ðeɪ dræŋk ʤuːs",
        translation: "They drank juice",
      },
      {
        id: 21,
        expression: "У меня была одна интересная идея",
        transcription: "aɪ həd wʌn ˈɪntrɪstɪŋ aɪˈdɪə",
        translation: "I had one interesting idea",
      },
      {
        id: 22,
        expression: "Я купил там немного еды",
        transcription: "aɪ bɔːt səm fuːd ðeə",
        translation: "I bought some food there",
      },
      {
        id: 23,
        expression: "Я положил это сюда",
        transcription: "aɪ pʊt ɪt hɪə",
        translation: "I put it here",
      },
      {
        id: 24,
        expression: "Она продавала дешевые вещи",
        transcription: "ʃi səʊld ʧiːp θɪŋz",
        translation: "She sold cheap things",
      },
      {
        id: 25,
        expression: "Она положила это туда",
        transcription: "ʃi pʊt ɪt ðeə",
        translation: "She put it there",
      },
      {
        id: 26,
        expression: "Он сделал слишком много ошибок",
        transcription: "hi meɪd tuː ˈmɛni mɪsˈteɪks",
        translation: "He made too many mistakes",
      },
      {
        id: 27,
        expression: "Я написал имейл",
        transcription: "aɪ rəʊt ən ˈiːmeɪl",
        translation: "I wrote an email",
      },
      {
        id: 28,
        expression: "Он купил квартиру",
        transcription: "hi bɔːt ən əˈpɑːtmənt",
        translation: "He bought an apartment",
      },
      {
        id: 29,
        expression: "Я отправил ей копию",
        transcription: "aɪ sɛnt hər ə ˈkɒpi",
        translation: "I sent her a copy",
      },
      {
        id: 30,
        expression: "Я встретил ее в том месте",
        transcription: "aɪ mɛt hər ɪn ðət pleɪs",
        translation: "I met her in that place",
      },
      {
        id: 31,
        expression: "Он продавал дорогую одежду",
        transcription: "hi səʊld ɪksˈpɛnsɪv kləʊðz",
        translation: "He sold expensive clothes",
      },
      {
        id: 32,
        expression: "Я выучил все эти слова",
        transcription: "aɪ lɜːnt ɔːl ðiːz wɜːdz",
        translation: "I learnt all these words",
      },
      {
        id: 33,
        expression: "Этот мальчик упал",
        transcription: "ðɪs bɔɪ fɛl",
        translation: "This boy fell",
      },
      {
        id: 34,
        expression: "Я все понял",
        transcription: "aɪ ˌʌndəˈstʊd ˈɛvrɪθɪŋ",
        translation: "I understood everything",
      },
      {
        id: 35,
        expression: "Я встал",
        transcription: "aɪ stʊd ʌp",
        translation: "I stood up",
      },
      {
        id: 36,
        expression: "Он написал книгу",
        transcription: "hi rəʊt ə bʊk",
        translation: "He wrote a book",
      },
      {
        id: 37,
        expression: "Он встал",
        transcription: "hi stʊd ʌp",
        translation: "He stood up",
      },
      {
        id: 38,
        expression: "Я купил машину",
        transcription: "aɪ bɔːt ə kɑː",
        translation: "I bought a car",
      },
      {
        id: 39,
        expression: "Как ты выучил китайский?",
        transcription: "haʊ dɪd jʊ lɜːn ʧaɪˈniːz?",
        translation: "How did you learn Chinees?",
      },
      {
        id: 40,
        expression: "Она не пришла на ту вечеринку",
        transcription: "ʃi dɪdnt kʌm tə ðət ˈpɑːti",
        translation: "She didn't come to that party",
      },
      {
        id: 41,
        expression: "Она сказала мне это",
        transcription: "ʃi sɛd mi ɪt",
        translation: "She said me it",
      },
      {
        id: 42,
        expression: "Я купил немного яблок",
        transcription: "aɪ bɔːt səm ˈæplz",
        translation: "I bought some apples",
      },
      {
        id: 43,
        expression: "Я выучил английский онлайн",
        transcription: "aɪ lɜːnt ˈɪŋglɪʃ ˈɒnˌlaɪn",
        translation: "I learnt English online",
      },
      {
        id: 44,
        expression: "Я все понял очень легко",
        transcription: "aɪ ˌʌndəˈstʊd ˈɛvrɪθɪŋ ˈvɛri ˈiːzɪli",
        translation: "I understood everything very easily",
      },
      {
        id: 45,
        expression: "Я обнаружил несколько новых слов в этом тексте",
        transcription: "aɪ dɪsˈkʌvəd səm njuː wɜːdz ɪn ðɪs tɛkst",
        translation: "I discovered some new words in this text",
      },
      {
        id: 46,
        expression: "Я выпил чашку чая",
        transcription: "aɪ dræŋk ə kʌp əv tiː",
        translation: "I drank a cup of tea",
      },
      {
        id: 47,
        expression: "Я купил килограмм яблок",
        transcription: "aɪ bɔːt ə ˈkiːləʊ əv ˈæplz",
        translation: "I bought a kilo of apples",
      },
      {
        id: 48,
        expression: "Я мог бы сделать это очень легко",
        transcription: "aɪ kəd dʊ ɪt ˈvɛri ˈiːzɪli",
        translation: "I could do it very easily",
      },
      {
        id: 49,
        expression: "Мы купили килограмм бананов",
        transcription: "wi bɔːt ə ˈkiːləʊ əv bəˈnɑːnəz",
        translation: "We bought a kilo of bananas",
      },
      {
        id: 50,
        expression: "Я оставил свою сумку дома",
        transcription: "aɪ lɛft maɪ bæg ət həʊm",
        translation: "I left my bag at home",
      },
      {
        id: 51,
        expression: "Он оставил свои ключи в том месте",
        transcription: "hi lɛft ɪz kiːz ɪn ðət pleɪs",
        translation: "He left his keys in that place",
      },
      {
        id: 52,
        expression: "Я обнаружил так много новых слов там",
        transcription: "aɪ dɪsˈkʌvəd səʊ ˈmɛni njuː wɜːdz ðeə",
        translation: "I discovered so many new words there",
      },
      {
        id: 53,
        expression: "Эта девушка упала на пол",
        transcription: "ðɪs gɜːl fɛl ɒn ðə flɔː",
        translation: "This girl fell on the floor",
      },
      {
        id: 54,
        expression: "Я положил это на письменный стол",
        transcription: "aɪ pʊt ɪt ɒn ðə dɛsk",
        translation: "I put it on the desk",
      },
      {
        id: 55,
        expression: "Он рассказал мне, как это сделать",
        transcription: "hi təʊld mi haʊ tə dʊ ɪt",
        translation: "He told me how to do it",
      },
      {
        id: 56,
        expression: "Я ушел из дома очень рано",
        transcription: "aɪ lɛft həʊm ˈvɛri ˈɜːli",
        translation: "I left home very early",
      },
      {
        id: 57,
        expression: "Я положил это под эту коробку",
        transcription: "aɪ pʊt ɪt ˈʌndə ðɪs bɒks",
        translation: "I put it under this box",
      },
      {
        id: 58,
        expression: "Я купил два килограмма картошки",
        transcription: "aɪ bɔːt tuː ˈkiːləʊz pəˈteɪtəʊz",
        translation: "I bought two kilos of potatoes",
      },
      {
        id: 59,
        expression: "Я встал, а затем сел",
        transcription: "aɪ stʊd ʌp ənd ðɛn sæt daʊn",
        translation: "I stood up and then sat down",
      },
      {
        id: 60,
        expression: "Он встал, а затем он сел",
        transcription: "hi stʊd ʌp ənd ðɛn hi sæt daʊn",
        translation: "He stood up and then he sat down",
      },
      {
        id: 61,
        expression: "Она не встала",
        transcription: "ʃi dɪdnt stænd ʌp",
        translation: "She didn't stand up",
      },
      {
        id: 62,
        expression: "Он положил это на стол",
        transcription: "hi pʊt ɪn ɒn ðə ˈteɪbl",
        translation: "He put in on the table",
      },
      {
        id: 63,
        expression: "Я не клал это туда",
        transcription: "aɪ dɪdnt pʊt ɪt ðeə",
        translation: "I didn't put it there",
      },
      {
        id: 64,
        expression: "Она положила это на стол",
        transcription: "ʃi pʊt ɪt ɒn ðə ˈteɪbl",
        translation: "She put it on the table",
      },
      {
        id: 65,
        expression: "Он положил это на его стол",
        transcription: "hi pʊt ɪt ɒn ɪz ˈteɪbl",
        translation: "He put it on his table",
      },
      {
        id: 66,
        expression: "Это яблоко упало с дерева",
        transcription: "ðɪs ˈæpl fɛl frəm ə triː",
        translation: "This apple fell from a tree",
      },
      {
        id: 67,
        expression: "Эти яблоки упали с дерева",
        transcription: "ðiːz ˈæplz fɛl frəm ə triː",
        translation: "These apples fell from a tree",
      },
      {
        id: 68,
        expression: "Эти бананы не падали с дерева",
        transcription: "ðiːz bəˈnɑːnəz dɪdnt fɔːl frəm ə triː",
        translation: "These bananas didn't fall from a tree",
      },
      {
        id: 69,
        expression: "Я купил один апельсин",
        transcription: "aɪ bɔːt wʌn ˈɒrɪnʤ",
        translation: "I bought one orange",
      },
      {
        id: 70,
        expression: "Я купил два яблока",
        transcription: "aɪ bɔːt tuː ˈæplz",
        translation: "I bought two apples",
      },
      {
        id: 71,
        expression: "Она купила пять бананов",
        transcription: "ʃi bɔːt faɪv bəˈnɑːnəz",
        translation: "She bought five bananas",
      },
      {
        id: 72,
        expression: "Я не плавал в море",
        transcription: "aɪ dɪdnt swɪm ɪn ðə siː",
        translation: "I didn't swim in the sea",
      },
      {
        id: 73,
        expression: "Я плавал в озере",
        transcription: "aɪ swɒm ɪn ðə leɪk",
        translation: "I swam in the lake",
      },
      {
        id: 74,
        expression: "Он плавает в море",
        transcription: "hi swɪm ɪn ðə siː",
        translation: "He swim in the sea",
      },
      {
        id: 75,
        expression: "Она не могла поверить этому",
        transcription: "ʃi ˈkʊdnt bɪˈliːv ɪt",
        translation: "She couldn't believe it",
      },
      {
        id: 76,
        expression: "Он мог поверить мне",
        transcription: "hi kəd bɪˈliːv miː",
        translation: "He could believe me",
      },
      {
        id: 77,
        expression: "Она не могла поверить ему",
        transcription: "ʃi ˈkʊdnt bɪˈliːv hɪm",
        translation: "She couldn't believe him",
      },
      {
        id: 78,
        expression: "Я хотел плавать в море",
        transcription: "aɪ ˈwɒntɪd tə swɪm ɪn ðə siː",
        translation: "I wanted to swim in the sea",
      },
      {
        id: 79,
        expression: "Он хотел плавать в море",
        transcription: "hi ˈwɒntɪd tə swɪm ɪn ðə siː",
        translation: "He wanted to swim in the sea",
      },
      {
        id: 80,
        expression: "Я не хотел плавать там",
        transcription: "aɪ dɪdnt wɒnt tə swɪm ðeə",
        translation: "I didn't want to swim there",
      },
      {
        id: 81,
        expression: "Я выучил эти слова очень легко",
        transcription: "aɪ lɜːnt ðiːz wɜːdz ˈvɛri ˈiːzɪli",
        translation: "I learnt these words very easily",
      },
      {
        id: 82,
        expression: "Я не выучил эти правила",
        transcription: "aɪ dɪdnt lɜːn ðiːz ruːlz",
        translation: "I didn't learn these rules",
      },
      {
        id: 83,
        expression: "Она выучила очень много новых слов там",
        transcription: "ʃi lɜːnt ˈvɛri ˈmɛni njuː wɜːdz ðeə",
        translation: "She learnt very many new words there",
      },
      {
        id: 84,
        expression: "Я слышал это по телевизору тоже",
        transcription: "aɪ hɜːd ɪt ɒn ˌtiːˈviː, tuː",
        translation: "I heard it on TV, too",
      },
      {
        id: 85,
        expression: "Я также слышал это по телевизору",
        transcription: "aɪ ˈɔːlsəʊ hɜːd ɪt ɒn ˌtiːˈviː",
        translation: "I also heard it on TV",
      },
      {
        id: 86,
        expression: "Я мог тратить все свое свободное время на это",
        transcription: "aɪ kəd spɛnd ɔːl maɪ friː taɪm ɒn ɪt",
        translation: "I could spend all my free time on it",
      },
      {
        id: 87,
        expression: "Он не умел плавать",
        transcription: "hi ˈkʊdnt swɪm",
        translation: "He couldn't swim",
      },
      {
        id: 88,
        expression: "Я хотел выпить немного воды",
        transcription: "aɪ ˈwɒntɪd tə drɪŋk səm ˈwɔːtə",
        translation: "I wanted to drink some water",
      },
      {
        id: 89,
        expression: "Я мог понимать все очень легко",
        transcription: "aɪ kəd ˌʌndəˈstænd ˈɛvrɪθɪŋ ˈvɛri ˈiːzɪli",
        translation: "I could understand everything very easily",
      },
      {
        id: 90,
        expression: "Сколько ошибок она сделала?",
        transcription: "haʊ ˈmɛni mɪsˈteɪks dɪd ʃi meɪk?",
        translation: "How many mistakes did she make?",
      },
      {
        id: 91,
        expression: "Она положила это под свой стол",
        transcription: "ʃi pʊt ɪt ˈʌndə hə ˈteɪbl",
        translation: "She put it under her table",
      },
      {
        id: 92,
        expression: "Я положил это на пол",
        transcription: "aɪ pʊt ɪt ɒn ðə flɔː",
        translation: "I put it on the floor",
      },
      {
        id: 93,
        expression: "Я не хотел покупать это в том магазине",
        transcription: "aɪ dɪdnt wɒnt tə baɪ ɪt ɪn ðət ʃɒp",
        translation: "I didn't want to buy it in that shop",
      },
      {
        id: 94,
        expression: "Я выпил стакан сока",
        transcription: "aɪ dræŋk ə glɑːs əv ʤuːs",
        translation: "I drank a glass of juice",
      },
      {
        id: 95,
        expression: "Он не пил вино",
        transcription: "hi dɪdnt drɪŋk waɪn",
        translation: "He didn't drink wine",
      },
      {
        id: 96,
        expression: "Он написал это на бумаге",
        transcription: "hi rəʊt ɪt ɒn ˈpeɪpə",
        translation: "He wrote it on paper",
      },
      {
        id: 97,
        expression: "Я положил это около моего компьютера",
        transcription: "aɪ pʊt ɪt nɪə maɪ kəmˈpjuːtə",
        translation: "I put it near my computer",
      },
      {
        id: 98,
        expression: "Я не хотел пить пиво",
        transcription: "aɪ dɪdnt wɒnt tə drɪŋk bɪə",
        translation: "I didn't want to drink beer",
      },
      {
        id: 99,
        expression: "Я написал это на бумаге",
        transcription: "aɪ rəʊt ɪt ɒn ˈpeɪpə",
        translation: "I wrote it on paper",
      },
      {
        id: 100,
        expression: "Он выпил чашку кофе",
        transcription: "hi dræŋk ə kʌp əv ˈkɒfi",
        translation: "He drank a cup of coffee",
      },
    ],
    {
      theory: `<div class="theory-block">
<p>Устойчивые выражения:</p>
<h3>a kilo of</h3>
<p>- килограмм чего-либо</p>
<p>He bought a kilo of apples - Он купил килограмм яблок</p>
<h3>stand up</h3>
<p>- вставать</p>
<p>I stood up to open the window - Я встал открыть окно</p>
<h3>on paper</h3>
<p>- на бумаге</p>
<p>Он написал что-то на бумаге - He wrote something on paper</p>
</div>`,
    },
  ],
];
