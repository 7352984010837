export const contentLesson35 = [
  [
    {
      title: "Урок 35. Past Simple. Regular verbs. Part 2",
    },
    [
      {
        id: 1,
        expression: "Все зависело от нас",
        transcription: "ˈɛvrɪθɪŋ dɪˈpɛndɪd ɒn ʌs",
        translation: "Everything depended on us",
      },
      {
        id: 2,
        expression: "Я запер дверь",
        transcription: "aɪ lɒkt ðə dɔː",
        translation: "I locked the door",
      },
      {
        id: 3,
        expression: "Я не запомнил его имя",
        transcription: "aɪ dɪdnt rɪˈmɛmbə hɪz neɪm",
        translation: "I didn't remember his name",
      },
      {
        id: 4,
        expression: "Эта игра началась тридцать минут назад",
        transcription: "ðɪs geɪm ˈstɑːtɪd ˈθɜːti ˈmɪnɪts əˈgəʊ",
        translation: "This game started thirty minutes ago",
      },
      {
        id: 5,
        expression: "Я часто ждал ее",
        transcription: "aɪ ˈɒf(ə)n ˈweɪtɪd fə hə",
        translation: "I often waited for her",
      },
      {
        id: 6,
        expression: "Они играли в настольный теннис",
        transcription: "ðeɪ pleɪd ˈteɪbl ˈtɛnɪs",
        translation: "They played table tennis",
      },
      {
        id: 7,
        expression: "Они не хотели обсуждать это",
        transcription: "ðeɪ dɪdnt wɒnt tə dɪsˈkʌs ɪt",
        translation: "They didn't want to discuss it",
      },
      {
        id: 8,
        expression: "Я не мог вспомнить это",
        transcription: "aɪ ˈkʊdnt rɪˈmɛmbər ɪt",
        translation: "I couldn't remember it",
      },
      {
        id: 9,
        expression: "Что вы обсуждали с ней?",
        transcription: "wɒt dɪd jʊ dɪsˈkʌs wɪð hɜː?",
        translation: "What did you discuss with her?",
      },
      {
        id: 10,
        expression: "Ты запер дверь?",
        transcription: "dɪd jʊ lɒk ðə dɔː?",
        translation: "Did you lock the door?",
      },
      {
        id: 11,
        expression: "Как ты улучшил свою грамматику?",
        transcription: "haʊ dɪd jʊ ɪmˈpruːv jə ˈgræmə?",
        translation: "How did you improve your grammar?",
      },
      {
        id: 12,
        expression: "Я ждал этого",
        transcription: "aɪ ˈweɪtɪd fər ɪt",
        translation: "I waited for it",
      },
      {
        id: 13,
        expression: "Мы работали вместе",
        transcription: "wi wɜːkt təˈgɛðə",
        translation: "We worked together",
      },
      {
        id: 14,
        expression: "Она готовила очень хорошо",
        transcription: "ʃi kʊkt ˈvɛri wɛl",
        translation: "She cooked very well",
      },
      {
        id: 15,
        expression: "Вы обсуждали эту вещь с ним?",
        transcription: "dɪd jʊ dɪsˈkʌs ðɪs θɪŋ wɪð hɪm?",
        translation: "Did you discuss this thing with him?",
      },
      {
        id: 16,
        expression: "Она заплакала внезапно",
        transcription: "ʃi kraɪd ˈsʌdnli",
        translation: "She cried suddenly",
      },
      {
        id: 17,
        expression: "Я помыл свои волосы",
        transcription: "aɪ wɒʃt maɪ heə",
        translation: "I washed my hair",
      },
      {
        id: 18,
        expression: "Мы часто путешествовали",
        transcription: "wi ˈɒf(ə)n ˈtrævld",
        translation: "We often travelled",
      },
      {
        id: 19,
        expression: "Я последовал твоему совету",
        transcription: "aɪ ˈfɒləʊd jər ədˈvaɪs",
        translation: "I followed your advice",
      },
      {
        id: 20,
        expression: "Я все помнил",
        transcription: "aɪ rɪˈmɛmbəd ˈɛvrɪθɪŋ",
        translation: "I remembered everything",
      },
      {
        id: 21,
        expression: "Ты часто гулял в парке?",
        transcription: "dɪd jʊ ˈɒf(ə)n wɔːk ɪn ðə pɑːk?",
        translation: "Did you often walk in the park?",
      },
      {
        id: 22,
        expression: "Наши уроки начались десять минут назад",
        transcription: "ˈaʊə ˈlɛsnz ˈstɑːtɪd tɛn ˈmɪnɪts əˈgəʊ",
        translation: "Our lessons started ten minutes ago",
      },
      {
        id: 23,
        expression: "Это изменило мою жизнь",
        transcription: "ɪt ʧeɪnʤd maɪ laɪf",
        translation: "It changed my life",
      },
      {
        id: 24,
        expression: "Когда начался этот урок?",
        transcription: "wɛn dɪd ðɪs ˈlɛsn stɑːt?",
        translation: "When did this lesson start?",
      },
      {
        id: 25,
        expression: "Они поцеловали друг друга",
        transcription: "ðeɪ kɪst iːʧ ˈʌðə",
        translation: "They kissed each other",
      },
      {
        id: 26,
        expression: "Он помыл свое тело",
        transcription: "hi wɒʃt ɪz ˈbɒdi",
        translation: "He washed his body",
      },
      {
        id: 27,
        expression: "Мы играли в бадминтон",
        transcription: "wi pleɪd ˈbædmɪntən",
        translation: "We played badminton",
      },
      {
        id: 28,
        expression: "Он не курил",
        transcription: "hi dɪdnt sməʊk",
        translation: "He didn't smoke",
      },
      {
        id: 29,
        expression: "Я изменил свое решение",
        transcription: "aɪ ʧeɪnʤd maɪ dɪˈsɪʒən",
        translation: "I changed my decision",
      },
      {
        id: 30,
        expression: "Я следовал этой рекомендации",
        transcription: "aɪ ˈfɒləʊd ðɪs ˌrɛkəmɛnˈdeɪʃən",
        translation: "I followed this recommendation",
      },
      {
        id: 31,
        expression: "Я умыл свое лицо",
        transcription: "aɪ wɒʃt maɪ feɪs",
        translation: "I washed my face",
      },
      {
        id: 32,
        expression: "Мы часто играли вместе",
        transcription: "wi ˈɒf(ə)n pleɪd təˈgɛðə",
        translation: "We often played together",
      },
      {
        id: 33,
        expression: "Как ты улучшил свое аудирование?",
        transcription: "haʊ dɪd jʊ ɪmˈpruːv jə ˈlɪsnɪŋ?",
        translation: "How did you improve your listening?",
      },
      {
        id: 34,
        expression: "Они гуляли в лесу",
        transcription: "ðeɪ wɔːkt ɪn ðə ˈfɒrɪst",
        translation: "They walked in the forest",
      },
      {
        id: 35,
        expression: "Я действительно улучшил свою речь",
        transcription: "aɪ ˈrɪəli ɪmˈpruːvd maɪ spiːʧ",
        translation: "I really improved my speech",
      },
      {
        id: 36,
        expression: "Где ты работал?",
        transcription: "weə dɪd jʊ wɜːk?",
        translation: "Where did you work?",
      },
      {
        id: 37,
        expression: "Я ждал его",
        transcription: "aɪ ˈweɪtɪd fə hɪm",
        translation: "I waited for him",
      },
      {
        id: 38,
        expression: "Наш учитель проверил мою работу",
        transcription: "ˈaʊə ˈtiːʧə ʧɛkt maɪ wɜːk",
        translation: "Our teacher checked my work",
      },
      {
        id: 39,
        expression: "Мы часто гуляли в парке",
        transcription: "wi ˈɒf(ə)n wɔːkt ɪn ðə pɑːk",
        translation: "We often walked in the park",
      },
      {
        id: 40,
        expression: "Я помылся",
        transcription: "aɪ wɒʃt maɪˈsɛlf",
        translation: "I washed myself",
      },
      {
        id: 41,
        expression: "Он поцеловал ее",
        transcription: "hi kɪst hɜː",
        translation: "He kissed her",
      },
      {
        id: 42,
        expression: "Я предпочитал заказывать это онлайн",
        transcription: "aɪ priˈfɜːd tʊ ˈɔːdə ɪt ˈɒnˌlaɪn",
        translation: "I prefered to order it online",
      },
      {
        id: 43,
        expression: "Они не хотели убивать это животное",
        transcription: "ðeɪ dɪdnt wɒnt tə kɪl ðɪs ˈænɪməl",
        translation: "They didn't want to kill this animal",
      },
      {
        id: 44,
        expression: "Все зависело от нас",
        transcription: "ˈɛvrɪθɪŋ dɪˈpɛndɪd ɒn ʌs",
        translation: "Everything depended on us",
      },
      {
        id: 45,
        expression: "Он курил слишком много, и это было очень плохо",
        transcription: "hi sməʊkt tuː mʌʧ ənd ɪt wəz ˈvɛri bæd",
        translation: "He smoked too much and it was very bad",
      },
      {
        id: 46,
        expression: "Что вы хотели сказать?",
        transcription: "wɒt dɪd jʊ wɒnt tə seɪ?",
        translation: "What did you want to say?",
      },
      {
        id: 47,
        expression: "Я не хотел готовить",
        transcription: "aɪ dɪdnt wɒnt tə kʊk",
        translation: "I didn't want to cook",
      },
      {
        id: 48,
        expression: "Они убили этого человека",
        transcription: "ðeɪ kɪld ðɪs ˈpɜːsn",
        translation: "They killed this person",
      },
      {
        id: 49,
        expression: "Это не зависело от меня",
        transcription: "ɪt dɪdnt dɪˈpɛnd ɒn miː",
        translation: "It didn't depend on me",
      },
      {
        id: 50,
        expression: "Я надеялся, что я был прав",
        transcription: "aɪ həʊpt ðət aɪ wəz raɪt",
        translation: "I hoped that I was right",
      },
      {
        id: 51,
        expression: "Это зависело от меня",
        transcription: "ɪt dɪˈpɛndɪd ɒn miː",
        translation: "It depended on me",
      },
      {
        id: 52,
        expression: "Мои друзья поддерживали меня",
        transcription: "maɪ frɛndz səˈpɔːtɪd miː",
        translation: "My friends supported me",
      },
      {
        id: 53,
        expression: "Я решил изучать английский более интенсивно",
        transcription: "aɪ dɪˈsaɪdɪd tə lɜːn ˈɪŋglɪʃ mɔːr ɪnˈtɛnsɪvli",
        translation: "I decided to learn English more intensively",
      },
      {
        id: 54,
        expression: "Я окончил университет в прошлом году",
        transcription: "aɪ ˈgrædjʊeɪtɪd frəm ˌjuːnɪˈvɜːsɪti lɑːst jɪə",
        translation: "I graduated from university last year",
      },
      {
        id: 55,
        expression: "Я надеялся победить",
        transcription: "aɪ həʊpt tə wɪn",
        translation: "I hoped to win",
      },
      {
        id: 56,
        expression: "Эти мальчики часто смеялись над ней",
        transcription: "ðiːz bɔɪz ˈɒf(ə)n lɑːft ət hɜː",
        translation: "These boys often laughed at her",
      },
      {
        id: 57,
        expression: "Те мальчики часто смеялись над ней",
        transcription: "ðəʊz bɔɪz ˈɒf(ə)n lɑːft ət hɜː",
        translation: "Those boys often laughed at her",
      },
      {
        id: 58,
        expression: "Я решил подождать это",
        transcription: "aɪ dɪˈsaɪdɪd tə weɪt fər ɪt",
        translation: "I decided to wait for it",
      },
      {
        id: 59,
        expression: "Я решил подождать тебя",
        transcription: "aɪ dɪˈsaɪdɪd tə weɪt fə juː",
        translation: "I decided to wait for you",
      },
      {
        id: 60,
        expression: "Они решили подождать это",
        transcription: "ðeɪ dɪˈsaɪdɪd tə weɪt fər ɪt",
        translation: "They decided to wait for it",
      },
      {
        id: 61,
        expression: "Почему они смеялись над этим?",
        transcription: "waɪ dɪd ðeɪ lɑːf ət ɪt?",
        translation: "Why did they laugh at it?",
      },
      {
        id: 62,
        expression: "Почему они смеялись над ним?",
        transcription: "waɪ dɪd ðeɪ lɑːf ət hɪm?",
        translation: "Why did they laugh at him?",
      },
      {
        id: 63,
        expression: "Когда они смеялись над этим?",
        transcription: "wɛn dɪd ðeɪ lɑːf ət ɪt?",
        translation: "When did they laugh at it?",
      },
      {
        id: 64,
        expression: "Я сильно улучшил свою грамматику",
        transcription: "aɪ ɪmˈpruːvd maɪ ˈgræmər ə lɒt",
        translation: "I improved my grammar a lot",
      },
      {
        id: 65,
        expression: "Она сильно улучшила свою грамматику",
        transcription: "ʃi ɪmˈpruːvd hə ˈgræmər ə lɒt",
        translation: "She improved her grammar a lot",
      },
      {
        id: 66,
        expression: "Я улучшил свою грамматику",
        transcription: "aɪ ɪmˈpruːvd maɪ ˈgræmə",
        translation: "I improved my grammar",
      },
      {
        id: 67,
        expression: "Твои друзья поддержали твою идею?",
        transcription: "dɪd jə frɛndz səˈpɔːt jər aɪˈdɪə?",
        translation: "Did your friends support your idea?",
      },
      {
        id: 68,
        expression: "Твоим друзьям понравилась эта идея?",
        transcription: "dɪd jə frɛndz laɪk ðɪs aɪˈdɪə?",
        translation: "Did your friends like this idea?",
      },
      {
        id: 69,
        expression: "Она готовила, когда у нее было свободное время",
        transcription: "ʃi kʊkt wɛn ʃi həd friː taɪm",
        translation: "She cooked when she had free time",
      },
      {
        id: 70,
        expression: "Они готовили, когда у них было свободное время",
        transcription: "ðeɪ kʊkt wɛn ðeɪ həd friː taɪm",
        translation: "They cooked when they had free time",
      },
      {
        id: 71,
        expression: "Я готовил, когда у меня было свободное время",
        transcription: "aɪ kʊkt wɛn aɪ həd friː taɪm",
        translation: "I cooked when I had free time",
      },
      {
        id: 72,
        expression:
          "Он чувствовал себя очень плохо, потому что курил слишком много",
        transcription: "hi fɛlt ˈvɛri bæd bɪˈkəz hi sməʊkt tuː mʌʧ",
        translation: "He felt very bad because he smoked too much",
      },
      {
        id: 73,
        expression:
          "Он чувствовал себя очень плохо, потому что курил так много",
        transcription: "hi fɛlt ˈvɛri bæd bɪˈkəz hi sməʊkt səʊ mʌʧ",
        translation: "He felt very bad because he smoked so much",
      },
      {
        id: 74,
        expression: "Ты подписал тот документ?",
        transcription: "dɪd jʊ saɪn ðət ˈdɒkjʊmənt?",
        translation: "Did you sign that document?",
      },
      {
        id: 75,
        expression: "Ты подписал этот документ?",
        transcription: "dɪd jʊ saɪn ðɪs ˈdɒkjʊmənt?",
        translation: "Did you sign this document?",
      },
      {
        id: 76,
        expression: "Ты подписал эти документы?",
        transcription: "dɪd jʊ saɪn ðiːz ˈdɒkjʊmənts?",
        translation: "Did you sign these documents?",
      },
      {
        id: 77,
        expression: "Они не путешествовали, потому что у них не было денег",
        transcription: "ðeɪ dɪdnt ˈtrævl bɪˈkəz ðeɪ dɪdnt həv ˈmʌni",
        translation: "They didn't travel because they didn't have money",
      },
      {
        id: 78,
        expression: "Я не путешествовал, потому что у меня не было денег",
        transcription: "aɪ dɪdnt ˈtrævl bɪˈkəz aɪ dɪdnt həv ˈmʌni",
        translation: "I didn't travel because I didn't have money",
      },
      {
        id: 79,
        expression:
          "Она не путешествовала, потому что у нее не было достаточно денег",
        transcription: "ʃi dɪdnt ˈtrævl bɪˈkəz ʃi dɪdnt həv ɪˈnʌf ˈmʌni",
        translation: "She didn't travel because she didn't have enough money",
      },
      {
        id: 80,
        expression: "Он окончил университет два года назад",
        transcription: "hi ˈgrædjʊeɪtɪd frəm ˌjuːnɪˈvɜːsɪti tuː jɪəz əˈgəʊ",
        translation: "He graduated from university two years ago",
      },
      {
        id: 81,
        expression: "Она окончила университет три года назад",
        transcription: "ʃi ˈgrædjʊeɪtɪd frəm ˌjuːnɪˈvɜːsɪti θriː jɪəz əˈgəʊ",
        translation: "She graduated from university three years ago",
      },
      {
        id: 82,
        expression: "Мы вчера хотели встретиться",
        transcription: "wi ˈwɒntɪd tə miːt ˈjɛstədeɪ",
        translation: "We wanted to meet yesterday",
      },
      {
        id: 83,
        expression: "Когда ты окончил университет?",
        transcription: "wɛn dɪd jʊ ˈgrædjʊət frəm ˌjuːnɪˈvɜːsɪti?",
        translation: "When did you graduate from university?",
      },
      {
        id: 84,
        expression: "Я не подписал этот документ",
        transcription: "aɪ dɪdnt saɪn ðɪs ˈdɒkjʊmənt",
        translation: "I didn't sign this document",
      },
      {
        id: 85,
        expression: "Они смеялись дома",
        transcription: "ðeɪ lɑːft ət həʊm",
        translation: "They laughed at home",
      },
      {
        id: 86,
        expression: "Почему они убили этого мужчину?",
        transcription: "waɪ dɪd ðeɪ kɪl ðɪs mæn?",
        translation: "Why did they kill this man?",
      },
      {
        id: 87,
        expression: "Я предпочел остаться дома",
        transcription: "aɪ prɪˈfɜːd tə steɪ ət həʊm",
        translation: "I preferred to stay at home",
      },
      {
        id: 88,
        expression: "Я улучшил свою грамматику, аудирование и речь здесь",
        transcription: "aɪ ɪmˈpruːvd maɪ ˈgræmə, ˈlɪsnɪŋ ənd ˈspiːkɪŋ hɪə",
        translation: "I improved my grammar, listening and speaking here",
      },
      {
        id: 89,
        expression: "Я надеялся, что это было так",
        transcription: "aɪ həʊpt ðət ɪt wəz səʊ",
        translation: "I hoped that it was so",
      },
      {
        id: 90,
        expression: "Я решил подождать их",
        transcription: "aɪ dɪˈsaɪdɪd tə weɪt fə ðɛm",
        translation: "I decided to wait for them",
      },
      {
        id: 91,
        expression: "Я чувствую, что я значительно улучшил свой английский",
        transcription: "aɪ fiːl ðət aɪ ɪmˈpruːvd maɪ ˈɪŋglɪʃ ə lɒt",
        translation: "I feel that I improved my English a lot",
      },
      {
        id: 92,
        expression: "Я не хотел смеяться",
        transcription: "aɪ dɪdnt wɒnt tə lɑːf",
        translation: "I didn't want to laugh",
      },
      {
        id: 93,
        expression: "Как часто ты путешествовал?",
        transcription: "haʊ ˈɒf(ə)n dɪd jʊ ˈtrævl?",
        translation: "How often did you travel?",
      },
      {
        id: 94,
        expression: "Это зависело от разных вещей",
        transcription: "ɪt dɪˈpɛndɪd ɒn ˈdɪfrənt θɪŋz",
        translation: "It depended on different things",
      },
      {
        id: 95,
        expression: "Я не хотел рассказывать ей эту вещь",
        transcription: "aɪ dɪdnt wɒnt tə tɛl hə ðɪs θɪŋ",
        translation: "I didn't want to tell her this thing",
      },
      {
        id: 96,
        expression: "Это зависело от тебя?",
        transcription: "dɪd ɪt dɪˈpɛnd ɒn juː?",
        translation: "Did it depend on you?",
      },
    ],
    {
      theory: `<div class="theory-block">
<p>Устойчивое выражение:</p>
<h3>depend on</h3>
<p>- зависеть от</p>
<p>He depends on his mother - Он зависел от своей матери.</p>
</div>`,
    },
  ],
];
