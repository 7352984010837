export const contentLesson61 = [
  [
    {
      title:
        "Урок 61. Present Simple: сложные подлежащие + глаголы: Negative Form",
    },
    [
      {
        id: 1,
        expression: "Моя мама этого не знает",
        transcription: "maɪ ˈmʌðə dʌznt nəʊ ɪt",
        translation: "My mother doesn't know it",
      },
      {
        id: 2,
        expression: "Некоторые люди этого не понимают",
        transcription: "sʌm ˈpiːpl dəʊnt ˌʌndəˈstænd ɪt",
        translation: "Some people don't understand it",
      },
      {
        id: 3,
        expression: "Билеты не стоят так много",
        transcription: "ðə ˈtɪkɪts dəʊnt kɒst səʊ mʌʧ",
        translation: "The tickets don't cost so much",
      },
      {
        id: 4,
        expression: "Коробка не выглядит такой тяжелой",
        transcription: "ðə bɒks dʌznt lʊk səʊ ˈhɛvi",
        translation: "The box doesn't look so heavy",
      },
      {
        id: 5,
        expression: "Большинство людей не хотят здесь жить",
        transcription: "məʊst ˈpiːpl dəʊnt wɒnt tə lɪv hɪə",
        translation: "Most people don't want to live here",
      },
      {
        id: 6,
        expression: "Их поведение не кажется таким удивительным",
        transcription: "ðeə bɪˈheɪvjə dʌznt siːm səʊ səˈpraɪzɪŋ",
        translation: "Their behavior doesn't seem so surprising",
      },
      {
        id: 7,
        expression: "Некоторые люди этого не понимают",
        transcription: "sʌm ˈpiːpl dəʊnt ˌʌndəˈstænd ɪt",
        translation: "Some people don't understand it",
      },
      {
        id: 8,
        expression: "Эксперт этого не объясняет",
        transcription: "ði ˈɛkspɜːt dʌznt ɪksˈpleɪn ɪt",
        translation: "The expert doesn't explain it",
      },
      {
        id: 9,
        expression: "Банк не работает двадцать четыре часа в сутки",
        transcription: "ðə bæŋk dʌznt wɜːk ˈtwɛnti fɔːr ˈaʊəz ə deɪ",
        translation: "The bank doesn't work twenty four hours a day",
      },
      {
        id: 10,
        expression: "Мой друг этого не знает",
        transcription: "maɪ frɛnd dʌznt nəʊ ɪt",
        translation: "My friend doesn't know it",
      },
      {
        id: 11,
        expression: "Этот размер мне не подходит",
        transcription: "ðɪs saɪz dʌznt sjuːt miː",
        translation: "This size doesn't suit me",
      },
      {
        id: 12,
        expression: "Этот отель кажется очень популярным среди туристов",
        transcription: "ðɪs həʊˈtɛl siːmz ˈvɛri ˈpɒpjʊlər əˈmʌŋ ˈtʊərɪsts",
        translation: "This hotel seems very popular among tourists",
      },
      {
        id: 13,
        expression: "Озеро выглядит не очень глубоким",
        transcription: "ðə leɪk dʌznt lʊk ˈvɛri diːp",
        translation: "The lake doesn't look very deep",
      },
      {
        id: 14,
        expression: "Коробка не весит больше, чем два килограмма",
        transcription: "ðə bɒks dʌznt weɪ mɔː ðən tuː ˈkiːləʊz",
        translation: "The box doesn't weigh more than two kilos",
      },
      {
        id: 15,
        expression: "Ситуация не кажется лучше",
        transcription: "ðə ˌsɪtjʊˈeɪʃən dʌznt siːm ˈbɛtə",
        translation: "The situation doesn't seem better",
      },
      {
        id: 16,
        expression: "Дизайн не кажется таким хорошим",
        transcription: "ðə dɪˈzaɪn dʌznt siːm səʊ gʊd",
        translation: "The design doesn't seem so good",
      },
      {
        id: 17,
        expression: "Эти вещи меня не интересуют",
        transcription: "ðiːz θɪŋz dəʊnt ˈɪntrɪst miː",
        translation: "These things don't interest me",
      },
      {
        id: 18,
        expression: "У агентства много клиентов",
        transcription: "ði ˈeɪʤənsi həz ˈmɛni (ə lɒt ɒv, lɒts ɒv) ˈklaɪənts",
        translation: "The agency has many (a lot of, lots of) clients",
      },
      {
        id: 19,
        expression: "Девочки не учатся в одной группе",
        transcription: "ðə gɜːlz dəʊnt ˈstʌdi ɪn wʌn (ɪn ðə seɪm) gruːp",
        translation: "The girls don't study in one (in the same) group",
      },
      {
        id: 20,
        expression: "Агентство помогает людям с этой проблемой",
        transcription: "ði ˈeɪʤənsi hɛlps ˈpiːplz wɪð ðɪs ˈprɒbləm",
        translation: "The agency helps peoples with this problem",
      },
      {
        id: 21,
        expression: "Девочка не чувствует себя такой счастливой",
        transcription: "ðə gɜːl dʌznt fiːl səʊ ˈhæpi",
        translation: "The girl doesn't feel so happy",
      },
      {
        id: 22,
        expression: "Эта вещь не случается так часто",
        transcription: "ðɪs θɪŋ dʌznt ˈhæpən səʊ ˈɒf(ə)n",
        translation: "This thing doesn't happen so often",
      },
      {
        id: 23,
        expression: "Это кресло не выглядит очень удобным",
        transcription: "ðɪs ˈɑːmˈʧeə dʌznt lʊk ˈvɛri ˈkʌmf(ə)təbl",
        translation: "This armchair doesn't look very comfortable",
      },
      {
        id: 24,
        expression: "Менеджер не работает профессионально",
        transcription: "ðə ˈmænɪʤə dʌznt wɜːk prəˈfɛʃnəli",
        translation: "The manager doesn't work professionally",
      },
      {
        id: 25,
        expression: "Ее муж не работает в банке",
        transcription: "hə ˈhʌzbənd dʌznt wɜːk ɪn ə bæŋk",
        translation: "Her husband doesn't work in a bank",
      },
      {
        id: 26,
        expression: "Тот стул не кажется удобным",
        transcription: "ðæt ʧeə dʌznt siːm ˈkʌmf(ə)təbl",
        translation: "That chair doesn't seem comfortable",
      },
      {
        id: 27,
        expression: "Проблема не кажется такой большой",
        transcription: "ðə ˈprɒbləm dʌznt siːm səʊ bɪg",
        translation: "The problem doesn't seem so big",
      },
      {
        id: 28,
        expression: "Эти вещи не помогают мне в действительности",
        transcription: "ðiːz θɪŋz dəʊnt hɛlp mi ɪn ri(ː)ˈælɪti",
        translation: "These things don't help me in reality",
      },
      {
        id: 29,
        expression: "Ее друг не верит ему",
        transcription: "hə frɛnd dʌznt bɪˈliːv hɪm",
        translation: "Her friend doesn't believe him",
      },
      {
        id: 30,
        expression: "Эти люди очень бедные",
        transcription: "ðiːz ˈpiːpl ə ˈvɛri pʊə",
        translation: "These people are very poor",
      },
      {
        id: 31,
        expression: "Их продажи не кажутся такими большими",
        transcription: "ðeə seɪlz dəʊnt siːm səʊ bɪg",
        translation: "Their sales don't seem so big",
      },
      {
        id: 32,
        expression: "Его жене не нравится эта идея",
        transcription: "hɪz waɪf dʌznt laɪk ðɪs aɪˈdɪə",
        translation: "His wife doesn't like this idea",
      },
      {
        id: 33,
        expression: "Эта машина не кажется надежной",
        transcription: "ðɪs kɑː dʌznt siːm rɪˈlaɪəbl",
        translation: "This car doesn't seem reliable",
      },
      {
        id: 34,
        expression: "Звук кажется очень хорошим",
        transcription: "ðə saʊnd siːmz ˈvɛri gʊd",
        translation: "The sound seems very good",
      },
      {
        id: 35,
        expression: "Эта вещь не сильно помогает мне",
        transcription: "ðɪs θɪŋ dʌznt hɛlp mi mʌʧ",
        translation: "This thing doesn't help me much",
      },
      {
        id: 36,
        expression: "Многие люди не знают этого",
        transcription: "ˈmɛni ˈpiːpl dəʊnt nəʊ ɪt",
        translation: "Many people don't know it",
      },
      {
        id: 37,
        expression: "Эта прибыль не кажется большой",
        transcription: "ðɪs ˈprɒfɪt dʌznt siːm bɪg",
        translation: "This profit doesn't seem big",
      },
      {
        id: 38,
        expression: "Мои родители не живут здесь",
        transcription: "maɪ ˈpeərənts dəʊnt lɪv hɪə",
        translation: "My parents don't live here",
      },
      {
        id: 39,
        expression: "Это платье не подходит ей",
        transcription: "ðɪs drɛs dʌznt sjuːt hɜː",
        translation: "This dress doesn't suit her",
      },
      {
        id: 40,
        expression: "Магазин не работает каждый день",
        transcription: "ðə ʃɒp dʌznt wɜːk ˈɛvri deɪ",
        translation: "The shop doesn't work every day",
      },
      {
        id: 41,
        expression: "Эти джинсы не подходят ему",
        transcription: "ðiːz ʤiːnz dəʊnt sjuːt hɪm",
        translation: "These jeans don't suit him",
      },
      {
        id: 42,
        expression: "Пациент не чувствует себя хуже",
        transcription: "ðə ˈpeɪʃənt dʌznt fiːl wɜːs",
        translation: "The patient doesn't feel worse",
      },
      {
        id: 43,
        expression: "Мой друг не хочет работать в этих условиях",
        transcription: "maɪ frɛnd dʌznt wɒnt tə wɜːk ɪn ðiːz kənˈdɪʃənz",
        translation: "My friend doesn't want to work in these conditions",
      },
      {
        id: 44,
        expression: "Только деньги интересуют его",
        transcription: "ˈəʊnli ˈmʌni ˈɪntrɪsts hɪm",
        translation: "Only money interests him",
      },
      {
        id: 45,
        expression: "Мои одногруппники не считают это очень важным",
        transcription: "maɪ gruːpmeɪts dəʊnt kənˈsɪdər ɪt ˈvɛri ɪmˈpɔːtənt",
        translation: "My groupmates don't consider it very important",
      },
      {
        id: 46,
        expression: "Один из моих одногруппников не знает этого",
        transcription: "wʌn əv maɪ gruːpmeɪts dʌznt nəʊ ɪt",
        translation: "One of my groupmates doesn't know it",
      },
      {
        id: 47,
        expression: "Мой отец не ездит на работу на автобусе",
        transcription: "maɪ ˈfɑːðə dʌznt gəʊ tə wɜːk baɪ bʌs",
        translation: "My father doesn't go to work by bus",
      },
      {
        id: 48,
        expression: "Качество этого образования не кажется очень хорошим",
        transcription:
          "ðə ˈkwɒlɪti əv ðɪs ˌɛdju(ː)ˈkeɪʃən dʌznt siːm ˈvɛri gʊd",
        translation: "The quality of this education doesn't seem very good",
      },
      {
        id: 49,
        expression: "Деньги его не мотивируют",
        transcription: "ˈmʌni dʌznt ˈməʊtɪveɪt hɪm",
        translation: "Money doesn't motivate him",
      },
      {
        id: 50,
        expression: "Мои одноклассники не думают об этом серьезно",
        transcription: "maɪ ˈklɑːsmeɪts dəʊnt θɪŋk əˈbaʊt ɪt ˈsɪərɪəsli",
        translation: "My classmates don't think about it seriously",
      },
      {
        id: 51,
        expression: "Это действительно не имеет значения",
        transcription: "ɪt dʌznt ˈrɪəli ˈmætə",
        translation: "It doesn't really matter",
      },
      {
        id: 52,
        expression:
          "Один из моих друзей совсем не пользуется общественным транспортом",
        transcription: "wʌn əv maɪ frɛndz dʌznt juːz ˈpʌblɪk ˈtrænspɔːt ət ɔːl",
        translation: "One of my friends doesn't use public transport at all",
      },
      {
        id: 53,
        expression: "Мой друг ничего не хочет менять в своей жизни",
        transcription: "maɪ frɛnd dʌznt wɒnt tə ʧeɪnʤ ˈɛnɪθɪŋ ɪn ɪz laɪf",
        translation: "My friend doesn't want to change anything in his life",
      },
      {
        id: 54,
        expression: "Мой одногруппник не считает это действительно важным",
        transcription: "maɪ gruːpmeɪt dʌznt kənˈsɪdər ɪt ˈrɪəli ɪmˈpɔːtənt",
        translation: "My groupmate doesn't consider it really important",
      },
      {
        id: 55,
        expression: "Эта клиника не существует",
        transcription: "ðɪs ˈklɪnɪk dʌznt ɪgˈzɪst",
        translation: "This clinic doesn't exist",
      },
      {
        id: 56,
        expression: "Эта вещь не приносит ему удовлетворения",
        transcription: "ðɪs θɪŋ dʌznt brɪŋ ɪm ˌsætɪsˈfækʃən",
        translation: "This thing doesn't bring him satisfaction",
      },
      {
        id: 57,
        expression: "Рабочие не жалуются",
        transcription: "ðə ˈwɜːkəz dəʊnt kəmˈpleɪn",
        translation: "The workers don't complain",
      },
      {
        id: 58,
        expression: "Эти роботы не существуют в реальности",
        transcription: "ðiːz ˈrəʊbɒts dəʊnt ɪgˈzɪst ɪn ri(ː)ˈælɪti",
        translation: "These robots don't exist in reality",
      },
      {
        id: 59,
        expression: "Этот робот не существует в действительности",
        transcription: "ðɪs ˈrəʊbɒt dʌznt ɪgˈzɪst ɪn ri(ː)ˈælɪti",
        translation: "This robot doesn't exist in reality",
      },
      {
        id: 60,
        expression: "Эти роботы не существуют сейчас",
        transcription: "ðiːz ˈrəʊbɒts dəʊnt ɪgˈzɪst naʊ",
        translation: "These robots don't exist now",
      },
      {
        id: 61,
        expression: "Мне кажется, что эта вещь действительно не имеет значения",
        transcription: "ɪt siːmz tə mi ðət ðɪs θɪŋ dʌznt ˈrɪəli ˈmætə",
        translation: "It seems to me that this thing doesn't really matter",
      },
      {
        id: 62,
        expression: "Ему кажется, что эта вещь не имеет значения",
        transcription: "ɪt siːmz tə ɪm ðət ðɪs θɪŋ dʌznt ˈmætə",
        translation: "It seems to him that this thing doesn't matter",
      },
      {
        id: 63,
        expression: "Мне кажется, что эти вещи действительно не имеют значения",
        transcription: "ɪt siːmz tə mi ðət ðiːz θɪŋz dəʊnt ˈrɪəli ˈmætə",
        translation: "It seems to me that these things don't really matter",
      },
      {
        id: 64,
        expression: "Ее одноклассник не разделяет ее точку зрения",
        transcription: "hə ˈklɑːsmeɪt dʌznt ʃeə hə pɔɪnt əv vjuː",
        translation: "Her classmate doesn't share her point of view",
      },
      {
        id: 65,
        expression: "Мой одноклассник не разделяет мою точку зрения",
        transcription: "maɪ ˈklɑːsmeɪt dʌznt ʃeə maɪ pɔɪnt əv vjuː",
        translation: "My classmate doesn't share my point of view",
      },
      {
        id: 66,
        expression: "Твой одногруппник не разделяет мою точку зрения",
        transcription: "jə gruːpmeɪt dʌznt ʃeə maɪ pɔɪnt əv vjuː",
        translation: "Your groupmate doesn't share my point of view",
      },
      {
        id: 67,
        expression: "Деньги интересуют ее",
        transcription: "ˈmʌni ˈɪntrɪsts hɜː / ˈmʌni z ˈɪntrɪstɪŋ fə hə",
        translation: "Money interests her / Money is interesting for her",
      },
      {
        id: 68,
        expression: "Эти деньги интересуют ее",
        transcription: "ðɪs ˈmʌni ˈɪntrɪsts hɜː",
        translation: "This money interests her",
      },
      {
        id: 69,
        expression: "Деньги не интересуют ее",
        transcription: "ˈmʌni dʌznt ˈɪntrɪst hɜː",
        translation: "Money doesn't interest her",
      },
      {
        id: 70,
        expression: "Работа не приносит ему удовлетворения",
        transcription: "ðə wɜːk dʌznt brɪŋ ɪm ˌsætɪsˈfækʃən",
        translation: "The work doesn't bring him satisfaction",
      },
      {
        id: 71,
        expression: "Эта работа не приносит мне удовлетворения",
        transcription: "ðɪs wɜːk dʌznt brɪŋ mi ˌsætɪsˈfækʃən",
        translation: "This work doesn't bring me satisfaction",
      },
      {
        id: 72,
        expression: "Работа не приносит им удовлетворения",
        transcription: "ðə wɜːk dʌznt brɪŋ ðəm ˌsætɪsˈfækʃən",
        translation: "The work doesn't bring them satisfaction",
      },
      {
        id: 73,
        expression: "Эта рубашка не выглядит очень модно",
        transcription: "ðɪs ʃɜːt dʌznt lʊk ˈvɛri ˈfæʃnəbl",
        translation: "This shirt doesn't look very fashionable",
      },
      {
        id: 74,
        expression: "Эта одежда не выглядит так модно",
        transcription: "ðiːz kləʊðz dəʊnt lʊk səʊ ˈfæʃnəbl",
        translation: "These clothes don't look so fashionable",
      },
      {
        id: 75,
        expression: "Я думаю, что юбка не стоит так много денег",
        transcription: "aɪ θɪŋk ðət ðə skɜːt dʌznt kɒst səʊ mʌʧ ˈmʌni",
        translation: "I think that the skirt doesn't cost so much money",
      },
      {
        id: 76,
        expression: "Я думаю, что эти юбки не стоят так много денег",
        transcription: "aɪ θɪŋk ðət ðiːz skɜːts dəʊnt kɒst səʊ mʌʧ ˈmʌni",
        translation: "I think that these skirts don't cost so much money",
      },
      {
        id: 77,
        expression: "Я думаю, что твоя юбка не стоит так много денег",
        transcription: "aɪ θɪŋk ðət jə skɜːt dʌznt kɒst səʊ mʌʧ ˈmʌni",
        translation: "I think that your skirt doesn't cost so much money",
      },
      {
        id: 78,
        expression:
          "Инвестор не хочет вкладывать свои последние деньги в этот проект",
        transcription:
          "ði ɪnˈvɛstə dʌznt wɒnt tʊ ɪnˈvɛst ɪz lɑːst ˈmʌni ɪn ðɪs ˈprɒʤɛkt",
        translation:
          "The investor doesn't want to invest his last money in this project",
      },
      {
        id: 79,
        expression:
          "Инвестор не хочет вкладывать свои последние деньги в эти проекты",
        transcription:
          "ði ɪnˈvɛstə dʌznt wɒnt tʊ ɪnˈvɛst ɪz lɑːst ˈmʌni ɪn ðiːz ˈprɒʤɛkts",
        translation:
          "The investor doesn't want to invest his last money in these projects",
      },
      {
        id: 80,
        expression:
          "Этот инвестор не хочет вкладывать свои последние деньги в мой проект",
        transcription:
          "ðɪs ɪnˈvɛstə dʌznt wɒnt tʊ ɪnˈvɛst ɪz lɑːst ˈmʌni ɪn maɪ ˈprɒʤɛkt",
        translation:
          "This investor doesn't want to invest his last money in my project",
      },
      {
        id: 81,
        expression: "Я думаю, эта вещь действительно не имеет значения",
        transcription: "aɪ θɪŋk ðɪs θɪŋ dʌznt ˈrɪəli ˈmætə",
        translation: "I think this thing doesn't really matter",
      },
      {
        id: 82,
        expression: "Я думаю, многие вещи действительно не имеют значения",
        transcription: "aɪ θɪŋk ˈmɛni θɪŋz dəʊnt ˈrɪəli ˈmætə",
        translation: "I think many things don't really matter",
      },
      {
        id: 83,
        expression:
          "Я думаю, что некоторые вещи действительно не имеют значения",
        transcription: "aɪ θɪŋk ðət səm θɪŋz dəʊnt ˈrɪəli ˈmætə",
        translation: "I think that some things don't really matter",
      },
      {
        id: 84,
        expression: "Эта клиника не работает по воскресеньям",
        transcription: "ðɪs ˈklɪnɪk dʌznt wɜːk ɒn ˈsʌndeɪz",
        translation: "This clinic doesn't work on Sundays",
      },
      {
        id: 85,
        expression: "Эта клиника не работает по понедельникам",
        transcription: "ðɪs ˈklɪnɪk dʌznt wɜːk ɒn ˈmʌndeɪz",
        translation: "This clinic doesn't work on Mondays",
      },
      {
        id: 86,
        expression: "Этот магазин не работает двадцать четыре часа в сутки",
        transcription: "ðɪs ʃɒp dʌznt wɜːk ˈtwɛnti fɔːr ˈaʊəz ə deɪ",
        translation: "This shop doesn't work twenty four hours a day",
      },
      {
        id: 87,
        expression: "Мой одноклассник не хочет думать об этом",
        transcription: "maɪ ˈklɑːsmeɪt dʌznt wɒnt tə θɪŋk əˈbaʊt ɪt",
        translation: "My classmate doesn't want to think about it",
      },
      {
        id: 88,
        expression: "Один из моих друзей совсем не пользуется интернетом",
        transcription: "wʌn əv maɪ frɛndz dʌznt juːz ði ˈɪntəˌnɛt ət ɔːl",
        translation: "One of my friends doesn't use the Internet at all",
      },
      {
        id: 89,
        expression: "Те люди живут в ужасных условиях",
        transcription: "ðəʊz ˈpiːpl lɪv ɪn ˈtɛrəbl kənˈdɪʃənz",
        translation: "Those people live in terrible conditions",
      },
      {
        id: 90,
        expression: "Та машина не кажется достаточно надежной",
        transcription: "ðæt kɑː dʌznt siːm rɪˈlaɪəbl ɪˈnʌf",
        translation: "That car doesn't seem reliable enough",
      },
      {
        id: 91,
        expression: "Мои родители не хотят переплачивать за это",
        transcription: "maɪ ˈpeərənts dəʊnt wɒnt tʊ ˌəʊvəˈpeɪ fər ɪt",
        translation: "My parents don't want to overpay for it",
      },
      {
        id: 92,
        expression: "У этих рабочих недостаточно знаний",
        transcription: "ðiːz ˈwɜːkəz dəʊnt həv ɪˈnʌf ˈnɒlɪʤ",
        translation: "These workers don't have enough knowledge",
      },
      {
        id: 93,
        expression: "Его одногруппник не хочет там учиться",
        transcription: "hɪz gruːpmeɪt dʌznt wɒnt tə ˈstʌdi ðeə",
        translation: "His groupmate doesn't want to study there",
      },
      {
        id: 94,
        expression: "Его английский не кажется идеальным",
        transcription: "hɪz ˈɪŋglɪʃ dʌznt siːm ˈpɜːfɪkt",
        translation: "His English doesn't seem perfect",
      },
      {
        id: 95,
        expression: "Мой одноклассник больше не хочет делать это",
        transcription: "maɪ ˈklɑːsmeɪt dʌznt wɒnt tə dʊ ɪt ˈɛni ˈlɒŋgə",
        translation: "My classmate doesn't want to do it any longer",
      },
      {
        id: 96,
        expression: "Эта вещь не имеет значения",
        transcription: "ðɪs θɪŋ dʌznt ˈmætə",
        translation: "This thing doesn't matter",
      },
      {
        id: 97,
        expression: "Наука не интересует ее",
        transcription: "ˈsaɪəns dʌznt ˈɪntrɪst hɜː",
        translation: "Science doesn't interest her",
      },
      {
        id: 98,
        expression: "У этого человека нет акцента",
        transcription: "ðɪs ˈpɜːsn dʌznt həv ən ˈæksənt",
        translation: "This person doesn't have an accent",
      },
      {
        id: 99,
        expression: "У этого сотрудника недостаточно знаний",
        transcription: "ðɪs ˌɛmplɔɪˈiː dʌznt həv ɪˈnʌf ˈnɒlɪʤ",
        translation: "This employee doesn't have enough knowledge",
      },
      {
        id: 100,
        expression: "Мальчик не понимает, почему это происходит",
        transcription: "ðə bɔɪ dʌznt ˌʌndəˈstænd waɪ ɪt ˈhæpənz",
        translation: "The boy doesn't understand why it happens",
      },
      {
        id: 101,
        expression: "Мои коллеги не жалуются на это",
        transcription: "maɪ ˈkɒliːgz dəʊnt kəmˈpleɪn əˈbaʊt ɪt",
        translation: "My colleagues don't complain about it",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Закрепление пройденного материала</h3>
</div>`,
    },
  ],
];
