export const wordsLesson288 = [
  [
    {
      id: 1,
      word: 'adequate',
      transcription: 'ˈædɪkwɪt',
      translation: 'адекватный, достаточный',
    },
    {
      id: 2,
      word: 'allocation',
      transcription: 'ˌæləʊˈkeɪʃᵊn',
      translation: 'распределение',
    },
    {
      id: 3,
      word: 'aptitude',
      transcription: 'ˈæptɪtjuːd',
      translation: 'склонность',
    },
    {
      id: 4,
      word: 'deforestation',
      transcription: 'dɪˌfɒrɪˈsteɪʃᵊn',
      translation: 'вырубка лесов',
    },
    {
      id: 5,
      word: 'disproportionate',
      transcription: 'ˌdɪsprəˈpɔːʃnɪt',
      translation: 'непропорциональный',
    },
    {
      id: 6,
      word: 'drunk',
      transcription: 'drʌŋk',
      translation: 'пьяный',
    },
    {
      id: 7,
      word: 'flashcard',
      transcription: 'flæʃ kɑːd',
      translation: 'флэшка',
    },
    {
      id: 8,
      word: 'groundless',
      transcription: 'ˈɡraʊndləs',
      translation: 'беспочвенный',
    },
    {
      id: 9,
      word: 'horrified',
      transcription: 'ˈhɒrɪfaɪd',
      translation: 'в ужасе',
    },
    {
      id: 10,
      word: 'humanity',
      transcription: 'hjuːˈmænəti',
      translation: 'человечество',
    },
    {
      id: 11,
      word: 'infinite',
      transcription: 'ˈɪnfɪnɪt',
      translation: 'бесконечный',
    },
    {
      id: 12,
      word: 'lucrative',
      transcription: 'ˈluːkrətɪv',
      translation: 'выгодный',
    },
    {
      id: 13,
      word: 'major',
      transcription: 'ˈmeɪʤə',
      translation: 'главный',
    },
    {
      id: 14,
      word: 'miscalculate',
      transcription: 'mɪsˈkælkjəleɪt',
      translation: 'неверно посчитать',
    },
    {
      id: 15,
      word: 'misinform',
      transcription: 'ˌmɪsɪnˈfɔːm',
      translation: 'дезинформировать',
    },
    {
      id: 16,
      word: 'nonetheless',
      transcription: 'ˌnʌnðəˈlɛs',
      translation: 'тем не менее',
    },
    {
      id: 17,
      word: 'non-traditional',
      transcription: 'nɒn-trəˈdɪʃᵊnᵊl',
      translation: 'нетрадиционный',
    },
    {
      id: 18,
      word: 'obligation',
      transcription: 'ˌɒblɪˈɡeɪʃᵊn',
      translation: 'обязательство',
    },
    {
      id: 19,
      word: 'prevail',
      transcription: 'prɪˈveɪl',
      translation: 'преобладать',
    },
    {
      id: 20,
      word: 'reassure',
      transcription: 'ˌriːəˈʃʊə',
      translation: 'заверять',
    },
    {
      id: 21,
      word: 'shadow',
      transcription: 'ˈʃædəʊ',
      translation: 'тень',
    },
    {
      id: 22,
      word: 'simplicity',
      transcription: 'sɪmˈplɪsəti',
      translation: 'простота',
    },
    {
      id: 23,
      word: 'spontaneously',
      transcription: 'spɒnˈteɪniəsli',
      translation: 'спонтанно',
    },
    {
      id: 24,
      word: 'sufficient',
      transcription: 'səˈfɪʃənt',
      translation: 'достаточный',
    },
    {
      id: 25,
      word: 'suspicion',
      transcription: 'səsˈpɪʃᵊn',
      translation: 'подозрительность',
    },
    {
      id: 26,
      word: 'tension',
      transcription: 'ˈtɛnʃᵊn',
      translation: 'напряженность',
    },
    {
      id: 27,
      word: 'undecided',
      transcription: 'ˌʌndɪˈsaɪdɪd',
      translation: 'нерешенный',
    },
    {
      id: 28,
      word: 'unsystematic',
      transcription: 'ˌʌnˌsɪstɪˈmætɪk',
      translation: 'бессистемный',
    },
    {
      id: 29,
      word: 'utmost',
      transcription: 'ˈʌtməʊst',
      translation: 'чрезвычайный',
    },
  ],
];
