export const wordsLesson77 = [
  [
    {
      id: 1,
      word: 'able',
      transcription: 'ˈeɪbl',
      translation: 'мочь, суметь',
    },
    {
      id: 2,
      word: 'anniversary',
      transcription: 'ˌænɪˈvɜːsəri',
      translation: 'годовщина',
    },
    {
      id: 3,
      word: 'cash',
      transcription: 'kæʃ',
      translation: 'наличные',
    },
    {
      id: 4,
      word: 'concert',
      transcription: 'ˈkɒnsə(ː)t',
      translation: 'концерт',
    },
    {
      id: 5,
      word: 'discovery',
      transcription: 'dɪsˈkʌvəri',
      translation: 'открытие',
    },
    {
      id: 6,
      word: 'field',
      transcription: 'fiːld',
      translation: 'поле',
    },
    {
      id: 7,
      word: 'laptop',
      transcription: 'ˈlæpˌtɒp',
      translation: 'ноутбук',
    },
    {
      id: 8,
      word: 'maybe',
      transcription: 'ˈmeɪbiː',
      translation: 'возможно',
    },
    {
      id: 9,
      word: 'medicine',
      transcription: 'ˈmɛdsɪn',
      translation: 'медицина',
    },
    {
      id: 10,
      word: 'recording',
      transcription: 'rɪˈkɔːdɪŋ',
      translation: 'запись',
    },
    {
      id: 11,
      word: 'revolution',
      transcription: 'ˌrɛvəˈluːʃən',
      translation: 'революция',
    },
    {
      id: 12,
      word: 'get rid of',
      transcription: 'gɛt rɪd ɒv',
      translation: 'избавиться от',
    },
    {
      id: 13,
      word: 'rise',
      transcription: 'raɪz',
      translation: 'подъем, поднимать',
    },
    {
      id: 14,
      word: 'silly',
      transcription: 'ˈsɪli',
      translation: 'глупый',
    },
    {
      id: 15,
      word: 'stadium',
      transcription: 'ˈsteɪdiəm',
      translation: 'стадион',
    },
    {
      id: 16,
      word: 'sun',
      transcription: 'sʌn',
      translation: 'солнце',
    },
    {
      id: 17,
      word: 'tablet',
      transcription: 'ˈtæblɪt',
      translation: 'планшет, таблетка',
    },
    {
      id: 18,
      word: 'unforgettable',
      transcription: 'ˌʌnfəˈgɛtəbl',
      translation: 'незабываемый',
    },
    {
      id: 19,
      word: 'go up',
      transcription: 'gəʊ ʌp',
      translation: 'подниматься',
    },
    {
      id: 20,
      word: 'go down',
      transcription: 'gəʊ daʊn',
      translation: 'снижаться',
    },
    { id: 21, word: 'up', transcription: 'ʌp', translation: 'вверх', },
    {
      id: 22,
      word: 'down',
      transcription: 'daʊn',
      translation: 'вниз',
    },
  ],
];
