export const wordsLesson253 = [
  [
    {
      id: 1,
      word: 'pour down',
      transcription: 'pɔː daʊn',
      translation: 'льет, льет дождь',
    },
    {
      id: 2,
      word: 'plug into',
      transcription: 'plʌɡ ˈɪntuː',
      translation: 'включить в, подключить к',
    },
    {
      id: 3,
      word: 'plug in',
      transcription: 'plʌɡ ɪn',
      translation: 'подключить к сети',
    },
    {
      id: 4,
      word: 'write off',
      transcription: 'raɪt ɒf',
      translation: 'списать',
    },
    {
      id: 5,
      word: 'put across',
      transcription: 'pʊt əˈkrɒs',
      translation: 'донести',
    },
    {
      id: 6,
      word: 'get across',
      transcription: 'ɡɛt əˈkrɒs',
      translation: 'донести',
    },
    {
      id: 7,
      word: 'bump into',
      transcription: 'bʌmp ˈɪntuː',
      translation: 'наткнуться на',
    },
    {
      id: 8,
      word: 'run across',
      transcription: 'rʌn əˈkrɒs',
      translation: 'наткнуться',
    },
    {
      id: 9,
      word: 'clamp down',
      transcription: 'klæmp daʊn',
      translation: 'подавить, расправиться с',
    },
    {
      id: 10,
      word: 'crack down',
      transcription: 'kræk daʊn',
      translation: 'подавить, расправиться с',
    },
    {
      id: 11,
      word: 'run through',
      transcription: 'rʌn θruː',
      translation: 'пробежаться по',
    },
    {
      id: 12,
      word: 'hold up',
      transcription: 'həʊld ʌp',
      translation: 'застрять',
    },
    {
      id: 13,
      word: 'work out',
      transcription: 'wɜːk aʊt',
      translation: 'заниматься, понять',
    },
    {
      id: 14,
      word: 'dwell on',
      transcription: 'dwɛl ɒn',
      translation: 'останавливаться на',
    },
    {
      id: 15,
      word: 'doze off',
      transcription: 'dəʊz ɒf',
      translation: 'задремать',
    },
    {
      id: 16,
      word: 'nod off',
      transcription: 'nɒd ɒf',
      translation: 'задремать',
    },
    {
      id: 17,
      word: 'run up against',
      transcription: 'rʌn ʌp əˈɡɛnst',
      translation: 'столкнуться с',
    },
    {
      id: 18,
      word: 'battery',
      transcription: 'ˈbætəri',
      translation: 'батарея',
    },
    {
      id: 19,
      word: 'vacuum cleaner',
      transcription: 'ˈvækjʊəm ˈkliːnə',
      translation: 'пылесос',
    },
    {
      id: 20,
      word: 'evasion',
      transcription: 'ɪˈveɪʒən',
      translation: 'уклонение',
    },
    {
      id: 21,
      word: 'fierce',
      transcription: 'fɪəs',
      translation: 'свирепый',
    },
    {
      id: 22,
      word: 'fog',
      transcription: 'fɒɡ',
      translation: 'туман',
    },
    {
      id: 23,
      word: 'former',
      transcription: 'ˈfɔːmə',
      translation: 'бывший',
    },
    {
      id: 24,
      word: 'hairdryer',
      transcription: 'ˈheədraɪə',
      translation: 'фен',
    },
    {
      id: 25,
      word: 'immigration',
      transcription: 'ˌɪmɪˈɡreɪʃᵊn',
      translation: 'иммиграция',
    },
    {
      id: 26,
      word: 'obstacle',
      transcription: 'ˈɒbstəkl',
      translation: 'препятствие',
    },
    {
      id: 27,
      word: 'protestor',
      transcription: 'prəˈtɛstə',
      translation: 'протестующий',
    },
    {
      id: 28,
      word: 'riot',
      transcription: 'ˈraɪət',
      translation: 'беспорядок',
    },
    {
      id: 29,
      word: 'soak',
      transcription: 'səʊk',
      translation: 'промокать',
    },
    {
      id: 30,
      word: 'socket',
      transcription: 'ˈsɒkɪt',
      translation: 'розетка',
    },
    {
      id: 31,
      word: 'tackle',
      transcription: 'ˈtækl',
      translation: 'решать',
    },
    {
      id: 32,
      word: 'toaster',
      transcription: 'ˈtəʊstə',
      translation: 'тостер',
    },
  ],
];
