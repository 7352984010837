export const contentLesson40 = [
  [
    {
      title: "Урок 40. Past Simple: was / were",
    },
    [
      {
        id: 1,
        expression: "Ты был прав",
        transcription: "jʊ wə raɪt",
        translation: "You were right",
      },
      {
        id: 2,
        expression: "Это было хорошо",
        transcription: "ɪt wəz gʊd",
        translation: "It was good",
      },
      {
        id: 3,
        expression: "Она была так добра к нам",
        transcription: "ʃi wəz səʊ kaɪnd tʊ ʌs",
        translation: "She was so kind to us",
      },
      {
        id: 4,
        expression: "Мы были там днем",
        transcription: "wi wə ðeər ət ði ˈɑːftəˈnuːn",
        translation: "We were there at the afternoon",
      },
      {
        id: 5,
        expression: "Это было вечером",
        transcription: "ɪt wəz ɪn ði ˈiːvnɪŋ",
        translation: "It was in the evening",
      },
      {
        id: 6,
        expression: "Он был там утром",
        transcription: "hi wəz ðeər ɪn ðə ˈmɔːnɪŋ",
        translation: "He was there in the morning",
      },
      {
        id: 7,
        expression: "Он был очень известным",
        transcription: "hi wəz ˈvɛri ˈfeɪməs",
        translation: "He was very famous",
      },
      {
        id: 8,
        expression: "Это была проблема",
        transcription: "ɪt wəz ə ˈprɒbləm",
        translation: "It was a problem",
      },
      {
        id: 9,
        expression: "Они были очень голодные",
        transcription: "ðeɪ wə ˈvɛri ˈhʌŋgri",
        translation: "They were very hungry",
      },
      {
        id: 10,
        expression: "Он был очень занят",
        transcription: "hi wəz ˈvɛri ˈbɪzi",
        translation: "He was very busy",
      },
      {
        id: 11,
        expression: "Это было очень плохо",
        transcription: "ɪt wəz ˈvɛri bæd",
        translation: "It was very bad",
      },
      {
        id: 12,
        expression: "Ты был прав",
        transcription: "jʊ wə raɪt",
        translation: "You were right",
      },
      {
        id: 13,
        expression: "Это было хорошо",
        transcription: "ɪt wəz gʊd",
        translation: "It was good",
      },
      {
        id: 14,
        expression: "Они были в другом месте",
        transcription: "ðeɪ wər ɪn əˈnʌðə pleɪs",
        translation: "They were in another place",
      },
      {
        id: 15,
        expression: "Он был так зол",
        transcription: "hi wəz səʊ ˈæŋgri",
        translation: "He was so angry",
      },
      {
        id: 16,
        expression: "Я был дома",
        transcription: "aɪ wəz ət həʊm",
        translation: "I was at home",
      },
      {
        id: 17,
        expression: "Это было так скучно",
        transcription: "ɪt wəz səʊ ˈbɔːrɪŋ",
        translation: "It was so boring",
      },
      {
        id: 18,
        expression: "Он был болен",
        transcription: "hi wəz ɪl",
        translation: "He was ill",
      },
      {
        id: 19,
        expression: "Было очень опасно в том месте",
        transcription: "ɪt wəz ˈvɛri ˈdeɪnʤrəs ɪn ðət pleɪs",
        translation: "It was very dangerous in that place",
      },
      {
        id: 20,
        expression: "Ему было действительно жаль",
        transcription: "hi wəz ˈrɪəli ˈsɒri",
        translation: "He was really sorry",
      },
      {
        id: 21,
        expression: "Я был на работе",
        transcription: "aɪ wəz ət wɜːk",
        translation: "I was at work",
      },
      {
        id: 22,
        expression: "Это было очень интересно мне",
        transcription: "ɪt wəz ˈvɛri ˈɪntrɪstɪŋ fə miː",
        translation: "It was very interesting for me",
      },
      {
        id: 23,
        expression: "Она была больна на прошлой неделе",
        transcription: "ʃi wəz ɪl lɑːst wiːk",
        translation: "She was ill last week",
      },
      {
        id: 24,
        expression: "Мне было действительно жаль",
        transcription: "aɪ wəz ˈrɪəli ˈsɒri",
        translation: "I was really sorry",
      },
      {
        id: 25,
        expression: "Там было очень опасно",
        transcription: "ɪt wəz ˈvɛri ˈdeɪnʤrəs ðeə",
        translation: "It was very dangerous there",
      },
      {
        id: 26,
        expression: "Она была очень голодная",
        transcription: "ʃi wəz ˈvɛri ˈhʌŋgri",
        translation: "She was very hungry",
      },
      {
        id: 27,
        expression: "Он был директором",
        transcription: "hi wəz ə dɪˈrɛktə",
        translation: "He was a director",
      },
      {
        id: 28,
        expression: "Это была ошибка",
        transcription: "ɪt wəz ə mɪsˈteɪk",
        translation: "It was a mistake",
      },
      {
        id: 29,
        expression: "Они были нашими гостями",
        transcription: "ðeɪ wər ˈaʊə gɛsts",
        translation: "They were our guests",
      },
      {
        id: 30,
        expression: "Это было так сложно",
        transcription: "ɪt wəz səʊ ˈdɪfɪkəlt",
        translation: "It was so difficult",
      },
      {
        id: 31,
        expression: "Она была такая добрая",
        transcription: "ʃi wəz səʊ kaɪnd",
        translation: "She was so kind",
      },
      {
        id: 32,
        expression: "Это было действительно замечательно",
        transcription: "ɪt wəz ˈrɪəli ˈwʌndəfʊl",
        translation: "It was really wonderful",
      },
      {
        id: 33,
        expression: "Я был за границей прошлым летом",
        transcription: "aɪ wəz əˈbrɔːd lɑːst ˈsʌmə",
        translation: "I was abroad last summer",
      },
      {
        id: 34,
        expression: "Это было так хорошо",
        transcription: "ɪt wəz səʊ gʊd",
        translation: "It was so good",
      },
      {
        id: 35,
        expression: "Она была очень известной",
        transcription: "ʃi wəz ˈvɛri ˈpɒpjʊlə",
        translation: "She was very popular",
      },
      {
        id: 36,
        expression: "Он был в Англии на прошлой неделе",
        transcription: "hi wəz ɪn ˈɪŋglənd lɑːst wiːk",
        translation: "He was in England last week",
      },
      {
        id: 37,
        expression: "Мы были так счастливы",
        transcription: "wi wə səʊ ˈhæpi",
        translation: "We were so happy",
      },
      {
        id: 38,
        expression: "Она была действительно зла",
        transcription: "ʃi wəz ˈrɪəli ˈæŋgri",
        translation: "She was really angry",
      },
      {
        id: 39,
        expression: "Это было так интересно",
        transcription: "ɪt wəz səʊ ˈɪntrɪstɪŋ",
        translation: "It was so interesting",
      },
      {
        id: 40,
        expression: "Я был в другой стране прошлой зимой",
        transcription: "aɪ wəz ɪn əˈnʌðə ˈkʌntri lɑːst ˈwɪntə",
        translation: "I was in another country last winter",
      },
      {
        id: 41,
        expression: "Это было замечательно",
        transcription: "ɪt wəz ˈwʌndəfʊl",
        translation: "It was wonderful",
      },
      {
        id: 42,
        expression: "Он был нашим директором",
        transcription: "hi wəz ˈaʊə dɪˈrɛktə",
        translation: "He was our director",
      },
      {
        id: 43,
        expression: "Это было очень легко",
        transcription: "ɪt wəz ˈvɛri ˈiːzi",
        translation: "It was very easy",
      },
      {
        id: 44,
        expression: "Она была в Соединенных Штатах в прошлом месяце",
        transcription: "ʃi wəz ɪn ðə jʊˈnaɪtɪd steɪts lɑːst mʌnθ",
        translation: "She was in the United States last month",
      },
      {
        id: 45,
        expression: "Ты был абсолютно прав",
        transcription: "jʊ wər ˈæbsəluːtli raɪt",
        translation: "You were absolutely right",
      },
      {
        id: 46,
        expression: "Это было действительно скучно",
        transcription: "ɪt wəz ˈrɪəli ˈbɔːrɪŋ",
        translation: "It was really boring",
      },
      {
        id: 47,
        expression: "Она была так добра к нам",
        transcription: "ʃi wəz səʊ kaɪnd tʊ ʌs",
        translation: "She was so kind to us",
      },
      {
        id: 48,
        expression: "Это был подарок для него",
        transcription: "ɪt wəz ə gɪft fə hɪm / ɪt wəz ə ˈprɛznt fə hɪm",
        translation: "It was a gift for him / It was a present for him",
      },
      {
        id: 49,
        expression: "Он боялся этого",
        transcription: "hi wəz əˈfreɪd əv ɪt",
        translation: "He was afraid of it",
      },
      {
        id: 50,
        expression: "Это были очень большие деньги",
        transcription: "ɪt wəz ˈvɛri bɪg ˈmʌni",
        translation: "It was very big money",
      },
      {
        id: 51,
        expression: "Я думаю, это было нормально",
        transcription: "aɪ θɪŋk ɪt wəz ˈnɔːməl",
        translation: "I think it was normal",
      },
      {
        id: 52,
        expression: "Это был подарок для нее",
        transcription: "ɪt wəz ə gɪft fə hə",
        translation: "It was a gift for her",
      },
      {
        id: 53,
        expression: "Мы были там днем",
        transcription: "wi wə ðeər ət ði ˈɑːftəˈnuːn",
        translation: "We were there at the afternoon",
      },
      {
        id: 54,
        expression: "Это было проблемой для него",
        transcription: "ɪt wəz ə ˈprɒbləm fə hɪm",
        translation: "It was a problem for him",
      },
      {
        id: 55,
        expression: "Она думает, что это было что-то особенное",
        transcription: "ʃi θɪŋks ðət ɪt wəz ˈsʌmθɪŋ ˈspɛʃəl",
        translation: "She thinks that it was something special",
      },
      {
        id: 56,
        expression: "Это было ужасно",
        transcription: "ɪt wəz ˈtɛrəbl",
        translation: "It was terrible",
      },
      {
        id: 57,
        expression: "Это было вечером",
        transcription: "ɪt wəz ɪn ði ˈiːvnɪŋ",
        translation: "It was in the evening",
      },
      {
        id: 58,
        expression: "Он думает, это было действительно ужасно",
        transcription: "hi θɪŋks ɪt wəz ˈrɪəli ˈtɛrəbl",
        translation: "He thinks it was really terrible",
      },
      {
        id: 59,
        expression: "Это был его подарок",
        transcription: "ɪt wəz ɪz gɪft (ˈprɛznt)",
        translation: "It was his gift (present)",
      },
      {
        id: 60,
        expression: "Она была такой уставшей вчера",
        transcription: "ʃi wəz səʊ ˈtaɪəd ˈjɛstədeɪ",
        translation: "She was so tired yesterday",
      },
      {
        id: 61,
        expression: "Это был лучший результат",
        transcription: "ɪt wəz ðə bɛst rɪˈzʌlt",
        translation: "It was the best result",
      },
      {
        id: 62,
        expression: "Это был мой подарок",
        transcription: "ɪt wəz maɪ gɪft",
        translation: "It was my gift",
      },
      {
        id: 63,
        expression: "Это был мой первый подарок",
        transcription: "ɪt wəz maɪ fɜːst gɪft",
        translation: "It was my first gift",
      },
      {
        id: 64,
        expression: "Я отсутствовал на прошлой неделе",
        transcription: "aɪ wəz ˈæbsənt lɑːst wiːk / aɪ wəz əˈweɪ lɑːst wiːk",
        translation: "I was absent last week / I was away last week",
      },
      {
        id: 65,
        expression: "Она отсутствовала на прошлой неделе",
        transcription: "ʃi wəz ˈæbsənt lɑːst wiːk / ʃi wəz əˈweɪ lɑːst wiːk",
        translation: "She was absent last week / She was away last week",
      },
      {
        id: 66,
        expression: "Они отсутствовали на прошлой неделе",
        transcription: "ðeɪ wər ˈæbsənt lɑːst wiːk / ðeɪ wər əˈweɪ lɑːst wiːk",
        translation: "They were absent last week / They were away last week",
      },
      {
        id: 67,
        expression: "Я думаю, это было лучше",
        transcription: "aɪ θɪŋk ɪt wəz ˈbɛtə",
        translation: "I think it was better",
      },
      {
        id: 68,
        expression: "Она думает, это было очень хорошо",
        transcription: "ʃi θɪŋks ɪt wəz ˈvɛri gʊd",
        translation: "She thinks it was very good",
      },
      {
        id: 69,
        expression: "Они думают, это было очень хорошо",
        transcription: "ðeɪ θɪŋk ɪt wəz ˈvɛri gʊd",
        translation: "They think it was very good",
      },
      {
        id: 70,
        expression: "Он был уставшим",
        transcription: "hi wəz ˈtaɪəd",
        translation: "He was tired",
      },
      {
        id: 71,
        expression: "Они действительно устали",
        transcription: "ðeɪ wə ˈrɪəli ˈtaɪəd",
        translation: "They were really tired",
      },
      {
        id: 72,
        expression: "Я был очень уставшим",
        transcription: "aɪ wəz ˈvɛri ˈtaɪəd",
        translation: "I was very tired",
      },
      {
        id: 73,
        expression: "Это было худшее решение",
        transcription: "ɪt wəz ðə wɜːst dɪˈsɪʒən",
        translation: "It was the worst decision",
      },
      {
        id: 74,
        expression: "Это было ваше худшее решение",
        transcription: "ɪt wəz jə wɜːst dɪˈsɪʒən",
        translation: "It was your worst decision",
      },
      {
        id: 75,
        expression: "Он отсутствовал вчера",
        transcription: "hi wəz ˈæbsənt ˈjɛstədeɪ",
        translation: "He was absent yesterday",
      },
      {
        id: 76,
        expression: "Они отсутствовали вчера",
        transcription: "ðeɪ wər ˈæbsənt ˈjɛstədeɪ",
        translation: "They were absent yesterday",
      },
      {
        id: 77,
        expression: "Она отсутствовала вчера",
        transcription: "ʃi wəz ˈæbsənt ˈjɛstədeɪ",
        translation: "She was absent yesterday",
      },
      {
        id: 78,
        expression: "Ее не было",
        transcription: "ʃi wəz aʊt",
        translation: "She was out",
      },
      {
        id: 79,
        expression: "Его не было",
        transcription: "hi wəz aʊt",
        translation: "He was out",
      },
      {
        id: 80,
        expression: "Их не было",
        transcription: "ðeɪ wər aʊt",
        translation: "They were out",
      },
      {
        id: 81,
        expression: "Это был мой лучший результат",
        transcription: "ɪt wəz maɪ bɛst rɪˈzʌlt",
        translation: "It was my best result",
      },
      {
        id: 82,
        expression: "Это был мой худший результат",
        transcription: "ɪt wəz maɪ wɜːst rɪˈzʌlt",
        translation: "It was my worst result",
      },
      {
        id: 83,
        expression: "Нас не было",
        transcription: "wi wər aʊt",
        translation: "We were out",
      },
      {
        id: 84,
        expression: "Это был подарок",
        transcription: "ɪt wəz ə gɪft",
        translation: "It was a gift",
      },
      {
        id: 85,
        expression: "Этой был мой подарок",
        transcription: "ɪt wəz maɪ gɪft",
        translation: "It was my gift",
      },
      {
        id: 86,
        expression: "Это был его подарок",
        transcription: "ɪt wəz ɪz gɪft",
        translation: "It was his gift",
      },
      {
        id: 87,
        expression: "Они были в отъезде",
        transcription: "ðeɪ wər əˈweɪ",
        translation: "They were away",
      },
      {
        id: 88,
        expression: "Я думаю, это было ужасно",
        transcription: "aɪ θɪŋk ɪt wəz ˈtɛrəbl",
        translation: "I think it was terrible",
      },
      {
        id: 89,
        expression: "Мне кажется, что ты был прав",
        transcription: "ɪt siːmz tə mi ðət jʊ wə raɪt",
        translation: "It seems to me that you were right",
      },
      {
        id: 90,
        expression: "Это была деревня",
        transcription: "ɪt wəz ə ˈvɪlɪʤ",
        translation: "It was a village",
      },
      {
        id: 91,
        expression: "Это было утром",
        transcription: "ɪt wəz ɪn ðə ˈmɔːnɪŋ",
        translation: "It was in the morning",
      },
      {
        id: 92,
        expression: "Я был не дома",
        transcription: "aɪ wəz aʊt",
        translation: "I was out",
      },
      {
        id: 93,
        expression: "Она присутствовала",
        transcription: "ʃi wəz ˈprɛznt",
        translation: "She was present",
      },
      {
        id: 94,
        expression: "Это был худший результат",
        transcription: "ɪt wəz ðə wɜːst rɪˈzʌlt",
        translation: "It was the worst result",
      },
      {
        id: 95,
        expression: "Она была действительно напугана",
        transcription: "ʃi wəz ˈrɪəli əˈfreɪd",
        translation: "She was really afraid",
      },
      {
        id: 96,
        expression: "Он был очень добр к нам",
        transcription: "hi wəz ˈvɛri kaɪnd tʊ ʌs",
        translation: "He was very kind to us",
      },
      {
        id: 97,
        expression: "Это был худший выбор",
        transcription: "ɪt wəz ðə wɜːst ʧɔɪs",
        translation: "It was the worst choice",
      },
      {
        id: 98,
        expression: "Это было действительно ужасно",
        transcription: "ɪt wəz ˈrɪəli ˈtɛrəbl",
        translation: "It was really terrible",
      },
      {
        id: 99,
        expression: "Я думаю, это было действительно плохо",
        transcription: "aɪ θɪŋk ɪt wəz ˈrɪəli bæd",
        translation: "I think it was really bad",
      },
      {
        id: 100,
        expression: "Они боялись этих изменений",
        transcription: "ðeɪ wər əˈfreɪd əv ðiːz ˈʧeɪnʤɪz",
        translation: "They were afraid of these changes",
      },
      {
        id: 101,
        expression: "Я был действительно счастлив",
        transcription: "aɪ wəz ˈrɪəli ˈhæpi",
        translation: "I was really happy",
      },
      {
        id: 102,
        expression: "Мы были вместе целый день",
        transcription: "wi wə təˈgɛðər ɔːl deɪ",
        translation: "We were together all day",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Was / were в Past Simple</h3>
<p>Рассмотрим форму глагола to be в простом прошедшем времени.</p>
<p>Она образуется по формуле:</p>
<p><span>подлежащее + were / was + ...</span></p>
<p>We were right - Мы были правы</p>
<p>I was very tired - Я был очень уставшим</p>
<p>К устойчивым выражениям:</p>
<h3>kind to me / us / him / her / them</h3>
<p>- быть добрым ко мне / к нам / к нему / к ней / к ним</p>
<p>She was too kind to him - Она была слишком добра к нему</p>
<h3>in the morning / in the afternoon / in the evening</h3>
<p>- утром / днем / вечером</p>
<p>I was there in the afternoon - Я был там днем</p>
</div>`,
    },
  ],
];
