export const wordsLesson81 = [
  [
    {
      id: 1,
      word: 'brown',
      transcription: 'braʊn',
      translation: 'коричневый',
    },
    {
      id: 2,
      word: 'citizen',
      transcription: 'ˈsɪtɪzn',
      translation: 'гражданин',
    },
    {
      id: 3,
      word: 'coin',
      transcription: 'kɔɪn',
      translation: 'монета',
    },
    {
      id: 4,
      word: 'collect',
      transcription: 'kəˈlɛkt',
      translation: 'собирать',
    },
    {
      id: 5,
      word: 'cottage',
      transcription: 'ˈkɒtɪʤ',
      translation: 'коттедж',
    },
    {
      id: 6,
      word: 'foreigner',
      transcription: 'ˈfɒrɪnə',
      translation: 'иностранец',
    },
    {
      id: 7,
      word: 'fully',
      transcription: 'ˈfʊli',
      translation: 'полностью',
    },
    {
      id: 8,
      word: 'impress',
      transcription: 'ˈɪmprɛs',
      translation: 'впечатлять',
    },
    {
      id: 9,
      word: 'lucky',
      transcription: 'ˈlʌki',
      translation: 'удачливый',
    },
    {
      id: 10,
      word: 'manage',
      transcription: 'ˈmænɪʤ',
      translation: 'суметь, управлять',
    },
    {
      id: 11,
      word: 'measure',
      transcription: 'ˈmɛʒə',
      translation: 'мера',
    },
    {
      id: 12,
      word: 'protest',
      transcription: 'ˈprəʊtɛst',
      translation: 'протест',
    },
    {
      id: 13,
      word: 'require',
      transcription: 'rɪˈkwaɪə',
      translation: 'требовать',
    },
    {
      id: 14,
      word: 'rest',
      transcription: 'rɛst',
      translation: 'отдых',
    },
    {
      id: 15,
      word: 'satisfied',
      transcription: 'ˈsætɪsfaɪd',
      translation: 'удовлетворенный',
    },
    {
      id: 16,
      word: 'true',
      transcription: 'truː',
      translation: 'верный, истинный',
    },
    {
      id: 17,
      word: 'unpleasant',
      transcription: 'ʌnˈplɛznt',
      translation: 'неприятный',
    },
    {
      id: 18,
      word: 'wise',
      transcription: 'waɪz',
      translation: 'мудрый',
    },
  ],
];
