export const contentLesson135 = [
  [
    {
      title: 'Урок 135. 360 Verbs. Part 9',
    },
    [
      {
        id: 1,
        expression: 'Мне было очень приятно получить твое приглашение',
        transcription: 'ɪt wəz ˈvɛri pliːzd tə rɪˈsiːv jər ˌɪnvɪˈteɪʃən',
        translation: 'It was very pleased to receive your invitation',
      },
      {
        id: 2,
        expression: 'Я хочу пожелать тебе настоящего счастья и удачи',
        transcription: 'aɪ wɒnt tə wɪʃ jʊ truː ˈhæpɪnɪs ənd gʊd lʌk',
        translation: 'I want to wish you true happiness and good luck',
      },
      {
        id: 3,
        expression: 'Я хотел бы пожелать тебе удачи и успехов в этом проекте',
        transcription: 'aɪd laɪk tə wɪʃ jʊ gʊd lʌk ənd səkˈsɛs ɪn ðɪs ˈprɒʤɛkt',
        translation:
          "I'd like to wish you good luck and success in this project",
      },
      {
        id: 4,
        expression: 'Она начала внезапно плакать',
        transcription: 'ʃi bɪˈgæn tə kraɪ ˈsʌdnli / ʃi bɪˈgæn ˈkraɪɪŋ ˈsʌdnli',
        translation: 'She began to cry suddenly / She began crying suddenly',
      },
      {
        id: 5,
        expression: 'Тебе нравится ходить по магазинам?',
        transcription: 'dʊ jʊ laɪk gəʊ ˈʃɒpɪŋ?',
        translation: 'Do you like go shopping?',
      },
      {
        id: 6,
        expression: 'Я отправил это ей по почте',
        transcription: 'aɪ sɛnt ɪt tə hə baɪ pəʊst',
        translation: 'I sent it to her by post',
      },
      {
        id: 7,
        expression: 'Я полностью поддерживаю эту идею',
        transcription: 'aɪ ˈfʊli səˈpɔːt ðɪs aɪˈdɪə',
        translation: 'I fully support this idea',
      },
      {
        id: 8,
        expression: 'Это платье подходило ей идеально',
        transcription: 'ðɪs drɛs fɪt hə ˈpɜːfɪktli',
        translation: 'This dress fit her perfectly',
      },
      {
        id: 9,
        expression: 'Он вдохнул, а затем он выдохнул',
        transcription: 'hi briːðd ɪn ənd ðɛn hi briːðd aʊt',
        translation: 'He breathed in and then he breathed out',
      },
      {
        id: 10,
        expression: 'Я хотел сосредоточиться на этой задаче',
        transcription: 'aɪ ˈwɒntɪd tə ˈkɒnsəntreɪt ɒn ðɪs tɑːsk',
        translation: 'I wanted to concentrate on this task',
      },
      {
        id: 11,
        expression: 'Тебе следует контролировать себя',
        transcription: 'jʊ ʃəd kənˈtrəʊl jɔːˈsɛlf',
        translation: 'You should control yourself',
      },
      {
        id: 12,
        expression: 'Я был в полном восторге получить твое письмо',
        transcription:
          'aɪ wəz ˈæbsəluːtli (ˈrɪəli) ɪkˈsaɪtɪd (dɪˈlaɪtɪd) tə rɪˈsiːv jə ˈlɛtə',
        translation:
          'I was absolutely (really) excited (delighted) to receive your letter',
      },
      {
        id: 13,
        expression: 'Я обычно хожу туда пешком',
        transcription: 'aɪ ˈjuːʒʊəli gəʊ ðeər ɒn fʊt',
        translation: 'I usually go there on foot',
      },
      {
        id: 14,
        expression: 'Я буду ждать твоего звонка с нетерпением',
        transcription: 'aɪl bi ˈweɪtɪŋ fə jə kɔːl wɪð ɪmˈpeɪʃəns',
        translation: "I'll be waiting for your call with impatience",
      },
      {
        id: 15,
        expression: 'Я как-нибудь исправлю эту проблему',
        transcription: 'aɪl fɪks ðɪs ˈprɒbləm ˈsʌmhaʊ',
        translation: "I'll fix this problem somehow",
      },
      {
        id: 16,
        expression: 'Прекрати это делать!',
        transcription: 'stɒp ˈdu(ː)ɪŋ ɪt!',
        translation: 'Stop doing it!',
      },
      {
        id: 17,
        expression: 'Я ему не верю сейчас',
        transcription: 'aɪ dəʊnt bɪˈliːv ɪm naʊ',
        translation: "I don't believe him now",
      },
      {
        id: 18,
        expression: 'Он не может себя контролировать',
        transcription: 'hi kɑːnt kənˈtrəʊl hɪmˈsɛlf',
        translation: "He can't control himself",
      },
      {
        id: 19,
        expression: 'Я закажу тебе такси',
        transcription: 'aɪl ˈɔːdə jʊ ə ˈtæksi',
        translation: "I'll order you a taxi",
      },
      {
        id: 20,
        expression: 'Мне нужно было это сделать',
        transcription: 'aɪ ˈniːdɪd tə dʊ ɪt',
        translation: 'I needed to do it',
      },
      {
        id: 21,
        expression: 'Я нажал на ту кнопку',
        transcription: 'aɪ klɪkt (ɒn) ðæt ˈbʌtn',
        translation: 'I clicked (on) that button',
      },
      {
        id: 22,
        expression: 'Он вырыл яму в земле',
        transcription: 'hi dʌg ə həʊl ɪn ðə graʊnd',
        translation: 'He dug a hole in the ground',
      },
      {
        id: 23,
        expression: 'Ему нужно отсканировать этот документ',
        transcription: 'hi niːdz tə skæn ðɪs ˈdɒkjʊmənt',
        translation: 'He needs to scan this document',
      },
      {
        id: 24,
        expression: 'Я хотел бы забронировать эти билеты заранее',
        transcription: 'aɪd laɪk tə bʊk ðiːz ˈtɪkɪts ɪn ədˈvɑːns',
        translation: "I'd like to book these tickets in advance",
      },
      {
        id: 25,
        expression: 'Что ты заказал?',
        transcription: 'wɒt dɪd jʊ ˈɔːdə?',
        translation: 'What did you order?',
      },
      {
        id: 26,
        expression: 'Они сделали очень интересное открытие',
        transcription: 'ðeɪ meɪd ə ˈvɛri ˈɪntrɪstɪŋ dɪsˈkʌvəri',
        translation: 'They made a very interesting discovery',
      },
      {
        id: 27,
        expression: 'Я нахожу это предложение действительно привлекательным',
        transcription: 'aɪ faɪnd ðɪs ˈɒfə ˈrɪəli əˈtræktɪv',
        translation: 'I find this offer really attractive',
      },
      {
        id: 28,
        expression: 'Они пригласили тебя на эту вечеринку?',
        transcription: 'dɪd ðeɪ ɪnˈvaɪt jʊ tə ðɪs ˈpɑːti?',
        translation: 'Did they invite you to this party?',
      },
      {
        id: 29,
        expression: 'Они сражались героически',
        transcription: 'ðeɪ fɔːt hɪˈrəʊɪk(ə)li',
        translation: 'They fought heroically',
      },
      {
        id: 30,
        expression: 'Ему не нравится носить костюм',
        transcription: 'hi dʌznt laɪk ˈweərɪŋ ə sjuːt',
        translation: "He doesn't like wearing a suit",
      },
      {
        id: 31,
        expression: 'Тебе нужно нажать на ссылку ниже',
        transcription: 'jʊ niːd tə klɪk ɒn ðə lɪŋk bɪˈləʊ',
        translation: 'You need to click on the link below',
      },
      {
        id: 32,
        expression: 'Он полностью поддерживает это решение',
        transcription: 'hi ˈfʊli səˈpɔːts ðɪs dɪˈsɪʒən',
        translation: 'He fully supports this decision',
      },
      {
        id: 33,
        expression: 'Эта одежда ей не подходит',
        transcription: 'ðiːz kləʊðz dəʊnt fɪt hɜː',
        translation: "These clothes don't fit her",
      },
      {
        id: 34,
        expression: 'Я начал думать об этом более серьезно',
        transcription: 'aɪ bɪˈgæn ˈθɪŋkɪŋ əˈbaʊt ɪt mɔː ˈsɪərɪəsli',
        translation: 'I began thinking about it more seriously',
      },
      {
        id: 35,
        expression: 'Я так долго этого жду',
        transcription: 'aɪv biːn ˈweɪtɪŋ fər ɪt fə səʊ lɒŋ',
        translation: "I've been waiting for it for so long",
      },
      {
        id: 36,
        expression: 'Солдаты сражались очень смело и мужественно',
        transcription: 'ðə ˈsəʊlʤəz wə ˈfaɪtɪŋ ˈvɛri ˈbreɪvli ənd kəˈreɪʤəsli',
        translation: 'The soldiers were fighting very bravely and courageously',
      },
      {
        id: 37,
        expression: 'Кто заплатил за билеты?',
        transcription: 'huː peɪd fə ðə ˈtɪkɪts?',
        translation: 'Who paid for the tickets?',
      },
      {
        id: 38,
        expression: 'Дети копаются в земле',
        transcription: 'ðə kɪdz ə ˈdɪgɪŋ ɪn ðə graʊnd',
        translation: 'The kids are digging in the ground',
      },
      {
        id: 39,
        expression: 'Он сделал несколько ошибок',
        transcription: 'hi meɪd ˈsɛvrəl mɪsˈteɪks',
        translation: 'He made several mistakes',
      },
      {
        id: 40,
        expression: 'Они носили слишком формальную одежду',
        transcription: 'ðeɪ wɔː tuː ˈfɔːməl kləʊðz',
        translation: 'They wore too formal clothes',
      },
      {
        id: 41,
        expression:
          'Он чувствовал себя очень разочарованным, потому что они его не пригласили',
        transcription:
          'hi fɛlt ˈvɛri ˌdɪsəˈpɔɪntɪd bɪˈkəz ðeɪ ˈhædnt ɪnˈvaɪtɪd hɪm',
        translation:
          "He felt very disappointed because they hadn't invited him",
      },
      {
        id: 42,
        expression: 'Очень трудно сосредоточиться из-за этого шума',
        transcription: 'ɪts ˈdɪfɪkəlt tə ˈkɒnsəntreɪt bɪˈkəz əv ðɪs nɔɪz',
        translation: "It's difficult to concentrate because of this noise",
      },
      {
        id: 43,
        expression: 'Я пытаюсь связаться с ней с утра',
        transcription: 'aɪv biːn ˈtraɪɪŋ tə gɛt ɪn tʌʧ wɪð hə sɪns ˈmɔːnɪŋ',
        translation: "I've been trying to get in touch with her since morning",
      },
      {
        id: 44,
        expression: 'Преступники были пойманы и наказаны',
        transcription: 'ðə ˈkrɪmɪnlz wə kɔːt ənd ˈpʌnɪʃt',
        translation: 'The criminals were caught and punished',
      },
      {
        id: 45,
        expression: 'Что происходит в данный момент?',
        transcription: 'wɒts ˈhæpnɪŋ ət ðə ˈməʊmənt?',
        translation: "What's happening at the moment?",
      },
      {
        id: 46,
        expression: 'Ярко светит солнце и птицы поют',
        transcription: 'ðə sʌn z ˈʃaɪnɪŋ ˈbraɪtli ənd ðə bɜːdz ə ˈsɪŋɪŋ',
        translation: 'The sun is shining brightly and the birds are singing',
      },
      {
        id: 47,
        expression: 'Как тебе удалось найти это место?',
        transcription: 'haʊ dɪd jʊ ˈmænɪʤ tə faɪnd ðɪs pleɪs?',
        translation: 'How did you manage to find this place?',
      },
      {
        id: 48,
        expression: 'Он сказал, что это уже случилось',
        transcription: 'hi sɛd ðət ˈɪtəd ɔːlˈrɛdi ˈhæpənd',
        translation: "He said that it'd already happened",
      },
      {
        id: 49,
        expression: 'Когда я вошел в комнату, она рисовала',
        transcription: 'wɛn aɪ ˈɛntəd ðə ruːm, ʃi wəz ˈdrɔːɪŋ',
        translation: 'When I entered the room, she was drawing',
      },
      {
        id: 50,
        expression:
          'Мне нужно больше времени, чтобы принять правильное решение',
        transcription: 'aɪ niːd mɔː taɪm tə meɪk ðə raɪt dɪˈsɪʒən',
        translation: 'I need more time to make the right decision',
      },
      {
        id: 51,
        expression: 'Как долго ты тренируешься?',
        transcription: 'haʊ lɒŋ həv jʊ biːn ˈtreɪnɪŋ?',
        translation: 'How long have you been training?',
      },
      {
        id: 52,
        expression: 'Они были пойманы, когда они пытались сбежать',
        transcription: 'ðeɪ wə kɔːt wɛn ðeɪ wə ˈtraɪɪŋ tʊ ɪsˈkeɪp',
        translation: 'They were caught when they were trying to escape',
      },
      {
        id: 53,
        expression: 'Это была моя величайшая мечта поступить в тот университет',
        transcription: 'ɪt wəz maɪ ˈgreɪtɪst driːm tʊ ˈɛntə ðət ˌjuːnɪˈvɜːsɪti',
        translation: 'It was my greatest dream to enter that university',
      },
      {
        id: 54,
        expression: 'Сложно сравнивать эти вещи',
        transcription: 'ɪts ˈdɪfɪkəlt tə kəmˈpeə ðiːz θɪŋz',
        translation: "It's difficult to compare these things",
      },
      {
        id: 55,
        expression: 'Я тренируюсь намного больше в последнее время',
        transcription: 'aɪv biːn ˈtreɪnɪŋ mʌʧ mɔː ˈleɪtli',
        translation: "I've been training much more lately",
      },
      {
        id: 56,
        expression: 'Оборудование было установлено правильно',
        transcription: 'ði ɪˈkwɪpmənt wəz ɪnˈstɔːld ˈprɒpəli',
        translation: 'The equipment was installed properly',
      },
      {
        id: 57,
        expression: 'Программа не установлена здесь',
        transcription: 'ðə ˈprəʊgræm ˈɪznt ɪnˈstɔːld hɪə',
        translation: "The program isn't installed here",
      },
      {
        id: 58,
        expression: 'Эта программа не была установлена на моем компьютере',
        transcription: 'ðɪs ˈprəʊgræm wɒznt ɪnˈstɔːld ɒn maɪ kəmˈpjuːtə',
        translation: "This program wasn't installed on my computer",
      },
      {
        id: 59,
        expression: 'Он всегда интересовался музыкой',
        transcription: 'hiːz ˈɔːlweɪz biːn ˈɪntrɪstɪd ɪn ˈmjuːzɪk',
        translation: "He's always been interested in music",
      },
      {
        id: 60,
        expression: 'Он никогда не интересовался футболом',
        transcription: 'hiːz ˈnɛvə biːn ˈɪntrɪstɪd ɪn ˈfʊtbɔːl',
        translation: "He's never been interested in football",
      },
      {
        id: 61,
        expression: 'Я всегда интересовался готовкой',
        transcription: 'aɪv ˈɔːlweɪz biːn ˈɪntrɪstɪd ɪn ˈkʊkɪŋ',
        translation: "I've always been interested in cooking",
      },
      {
        id: 62,
        expression: 'Он пытался сбежать из тюрьмы, но вскоре он был пойман',
        transcription:
          'hi wəz ˈtraɪɪŋ tʊ ɪsˈkeɪp frəm ˈprɪzn bət hi wəz kɔːt suːn',
        translation:
          'He was trying to escape from prison but he was caught soon',
      },
      {
        id: 63,
        expression: 'Он пытался сбежать из тюрьмы, но у него не получилось',
        transcription:
          'hi wəz ˈtraɪɪŋ tʊ ɪsˈkeɪp frəm ˈprɪzn bət hi feɪld / hi wəz ˈtraɪɪŋ tʊ ɪsˈkeɪp frəm ˈprɪzn bət hi dɪdnt ˈmænɪʤ tə dʊ ɪt',
        translation:
          "He was trying to escape from prison but he failed / He was trying to escape from prison but he didn't manage to do it",
      },
      {
        id: 64,
        expression: 'Он никогда не пытался сбежать из тюрьмы',
        transcription: 'hiːz ˈnɛvə traɪd tʊ ɪsˈkeɪp frəm ˈprɪzn',
        translation: "He's never tried to escape from prison",
      },
      {
        id: 65,
        expression: 'Я пользуюсь этим сервисом на регулярной основе',
        transcription: 'aɪ juːz ðɪs ˈsɜːvɪs ɒn ə ˈrɛgjʊlə ˈbeɪsɪs',
        translation: 'I use this service on a regular basis',
      },
      {
        id: 66,
        expression: 'Я пользуюсь этим сервисом регулярно',
        transcription: 'aɪ juːz ðɪs ˈsɜːvɪs ˈrɛgjʊləli',
        translation: 'I use this service regularly',
      },
      {
        id: 67,
        expression: 'Я часто пользуюсь этим сервисом',
        transcription: 'aɪ ˈɒf(ə)n juːz ðɪs ˈsɜːvɪs',
        translation: 'I often use this service',
      },
      {
        id: 68,
        expression: 'Сообщение было отправлено ему',
        transcription: 'ðə ˈmɛsɪʤ wəz sɛnt tə hɪm',
        translation: 'The message was sent to him',
      },
      {
        id: 69,
        expression: 'Сообщение не было отправлено ему вовремя',
        transcription: 'ðə ˈmɛsɪʤ wɒznt sɛnt tə ɪm ɒn taɪm',
        translation: "The message wasn't sent to him on time",
      },
      {
        id: 70,
        expression: 'Это письмо отправлено ей',
        transcription: 'ðɪs ˈlɛtə z sɛnt tə hɜː',
        translation: 'This letter is sent to her',
      },
      {
        id: 71,
        expression:
          'Ему потребовалось некоторое время, чтобы починить велосипед',
        transcription: 'ɪt tʊk ɪm səm taɪm tə fɪks ðə baɪk',
        translation: 'It took him some time to fix the bike',
      },
      {
        id: 72,
        expression:
          'Ему понадобилось некоторое время, чтобы купить новый велосипед',
        transcription: 'ɪt tʊk ɪm səm taɪm tə baɪ ə njuː baɪk',
        translation: 'It took him some time to buy a new bike',
      },
      {
        id: 73,
        expression: 'Это займет некоторое время',
        transcription: 'ˈɪtl teɪk səm taɪm',
        translation: "It'll take some time",
      },
      {
        id: 74,
        expression: 'Дождь еще не прекратился',
        transcription:
          'ɪt ˈhæznt biːn stɒpt ˈreɪnɪŋ jɛt / ðə reɪn ˈhæznt stɒpt jɛt',
        translation:
          "It hasn't been stopped raining yet / The rain hasn't stopped yet",
      },
      {
        id: 75,
        expression: 'Дождь идет весь день',
        transcription: 'ɪt həz biːn ˈreɪnɪŋ ɔːl deɪ',
        translation: 'It has been raining all day',
      },
      {
        id: 76,
        expression: 'Дождь уже прекратился',
        transcription: 'ɪt həz ɔːlˈrɛdi stɒpt ˈreɪnɪŋ',
        translation: 'It has already stopped raining',
      },
      {
        id: 77,
        expression: 'Я не могу сказать, что я печатаю очень быстро',
        transcription: 'aɪ kɑːnt seɪ ðət aɪ taɪp ˈvɛri fɑːst',
        translation: "I can't say that I type very fast",
      },
      {
        id: 78,
        expression: 'Я могу сказать, что я печатаю действительно быстро',
        transcription: 'aɪ kən seɪ ðət aɪ taɪp ˈrɪəli fɑːst',
        translation: 'I can say that I type really fast',
      },
      {
        id: 79,
        expression: 'Я не могу сказать, что я читаю очень быстро',
        transcription: 'aɪ kɑːnt seɪ ðət aɪ riːd ˈvɛri fɑːst',
        translation: "I can't say that I read very fast",
      },
      {
        id: 80,
        expression: 'Эти документы были отсканированы',
        transcription: 'ðiːz ˈdɒkjʊmənts wə skænd',
        translation: 'These documents were scanned',
      },
      {
        id: 81,
        expression: 'Эти документы не подписаны',
        transcription: 'ðiːz ˈdɒkjʊmənts ɑːnt saɪnd',
        translation: "These documents aren't signed",
      },
      {
        id: 82,
        expression: 'Эти фотографии были напечатаны',
        transcription: 'ðiːz ˈfəʊtəʊz wə ˈprɪntɪd',
        translation: 'These photos were printed',
      },
      {
        id: 83,
        expression: 'Ты нарисовал настоящий шедевр',
        transcription: 'jʊ druː ə rɪəl ˈmɑːstəpiːs',
        translation: 'You drew a real masterpiece',
      },
      {
        id: 84,
        expression: 'Я покрасил стену в красный',
        transcription: 'aɪ ˈpeɪntɪd ðə wɔːl ɪn rɛd',
        translation: 'I painted the wall in red',
      },
      {
        id: 85,
        expression: 'Ты нарисовал очень красивую картинку',
        transcription: 'jʊ druː ə ˈvɛri ˈbjuːtəfʊl ˈpɪkʧə',
        translation: 'You drew a very beautiful picture',
      },
      {
        id: 86,
        expression: 'Я всегда был заинтересован в этом',
        transcription: 'aɪv biːn ˈɔːlweɪz ˈɪntrɪstɪd ɪn ɪt',
        translation: "I've been always interested in it",
      },
      {
        id: 87,
        expression: 'Он сказал, что заплатил за все',
        transcription: 'hi sɛd ðət hiːd peɪd fər ˈɛvrɪθɪŋ',
        translation: "He said that he'd paid for everything",
      },
      {
        id: 88,
        expression: 'Луна светила очень ярко',
        transcription: 'ðə muːn wəz ˈʃaɪnɪŋ ˈvɛri ˈbraɪtli',
        translation: 'The moon was shining very brightly',
      },
      {
        id: 89,
        expression:
          'Все больше и больше людей используют интернет в настоящее время',
        transcription: 'mɔːr ənd mɔː ˈpiːpl ə ˈjuːzɪŋ ði ˈɪntəˌnɛt ˈnaʊədeɪz',
        translation: 'More and more people are using the Internet nowadays',
      },
      {
        id: 90,
        expression: 'Я много печатаю в последнее время',
        transcription: 'aɪv biːn ˈtaɪpɪŋ ə lɒt ˈleɪtli',
        translation: "I've been typing a lot lately",
      },
      {
        id: 91,
        expression: 'Я еще не установил эту программу',
        transcription: 'aɪ hævnt ɪnˈstɔːld ðɪs ˈprəʊgræm jɛt',
        translation: "I haven't itstalled this program yet",
      },
      {
        id: 92,
        expression: 'Они предпочитают жить в деревне и дышать свежим воздухом',
        transcription: 'ðeɪ priˈfɜː tə lɪv ɪn ðə ˈkʌntri ənd brɛθ frɛʃ eə',
        translation: 'They prefer to live in the country and breath fresh air',
      },
      {
        id: 93,
        expression: 'Я еще не забронировал рейс',
        transcription: 'aɪ hævnt bʊkt ðə flaɪt jɛt',
        translation: "I haven't booked the flight yet",
      },
      {
        id: 94,
        expression: 'Мои сверстники и я часто сравниваем наши результаты',
        transcription: 'maɪ pɪəz ənd aɪ ˈɒf(ə)n kəmˈpeər ˈaʊə rɪˈzʌlts',
        translation: 'My peers and I often compare our results',
      },
      {
        id: 95,
        expression: 'Я был так удивлен, что я не мог поверить своим глазам',
        transcription: 'aɪ wəz səʊ səˈpraɪzd ðət aɪ ˈkʊdnt bɪˈliːvd maɪ aɪz',
        translation: "I was so surprised that I couldn't believed my eyes",
      },
      {
        id: 96,
        expression: 'Я отправил ей текстовое сообщение',
        transcription: 'aɪ sɛnt hər ə tɛkst ˈmɛsɪʤ',
        translation: 'I sent her a text message',
      },
      {
        id: 97,
        expression: 'Все включено',
        transcription: 'ˈɛvrɪθɪŋ z ɪnˈkluːdɪd',
        translation: 'Everything is included',
      },
      {
        id: 98,
        expression: 'Это включено в счет',
        transcription: 'ɪts ɪnˈkluːdɪd ɪn ðə bɪl',
        translation: "It's included in the bill",
      },
      {
        id: 99,
        expression: 'Я вошел в здание',
        transcription: 'aɪ ˈɛntəd ðə ˈbɪldɪŋ',
        translation: 'I entered the building',
      },
      {
        id: 100,
        expression: 'Никто не был наказан',
        transcription: 'nəʊ wʌn wəz ˈpʌnɪʃt',
        translation: 'No one was punished',
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Закрепление пройденного материала</h3>
</div>`,
    },
  ],
];
