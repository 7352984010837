export const contentLesson55 = [
  [
    {
      title: "Урок 55. Present Simple: сложные подлежащие + глаголы",
    },
    [
      {
        id: 1,
        expression: "Моим друзьям нравится эта идея",
        transcription: "maɪ frɛndz laɪk ðɪs aɪˈdɪə",
        translation: "My friends like this idea",
      },
      {
        id: 2,
        expression: "Та игра кажется такой интересной",
        transcription: "ðæt geɪm siːmz səʊ ˈɪntrɪstɪŋ",
        translation: "That game seems so interesting",
      },
      {
        id: 3,
        expression: "Эти мужчины выглядят очень слабыми",
        transcription: "ðiːz mɛn lʊk ˈvɛri wiːk",
        translation: "These men look very weak",
      },
      {
        id: 4,
        expression: "Эти идеи выглядят так интересно",
        transcription: "ðiːz aɪˈdɪəz lʊk səʊ ˈɪntrɪstɪŋ",
        translation: "These ideas look so interesting",
      },
      {
        id: 5,
        expression: "Твой результат выглядит очень хорошо",
        transcription: "jə rɪˈzʌlt lʊks ˈvɛri gʊd",
        translation: "Your result looks very good",
      },
      {
        id: 6,
        expression: "У его брата есть девушка",
        transcription: "hɪz ˈbrʌðə həz ə ˈgɜːlˌfrɛnd",
        translation: "His brother has a girlfriend",
      },
      {
        id: 7,
        expression: "Эта вещь очень сильно мотивирует меня",
        transcription: "ðɪs θɪŋ ˈməʊtɪveɪts mi ə lɒt (ˈgreɪtli)",
        translation: "This thing motivates me a lot (greatly)",
      },
      {
        id: 8,
        expression: "Моим родителям действительно нравится эта идея",
        transcription: "maɪ ˈpeərənts ˈrɪəli laɪk ðɪs aɪˈdɪə",
        translation: "My parents really like this idea",
      },
      {
        id: 9,
        expression: "Этот билет стоит больше",
        transcription: "ðɪs ˈtɪkɪt kɒsts mɔː",
        translation: "This ticket costs more",
      },
      {
        id: 10,
        expression: "Этот студент учится лучше",
        transcription: "ðɪs ˈstjuːdənt ˈstʌdiz ˈbɛtə",
        translation: "This student studies better",
      },
      {
        id: 11,
        expression: "Моему брату нравится эта музыка тоже",
        transcription: "maɪ ˈbrʌðə laɪks ðɪs ˈmjuːzɪk, tuː",
        translation: "My brother likes this music, too",
      },
      {
        id: 12,
        expression: "Те билеты стоят меньше",
        transcription: "ðəʊz ˈtɪkɪts kɒst lɛs",
        translation: "Those tickets cost less",
      },
      {
        id: 13,
        expression: "Моя сестра работает ветеринаром",
        transcription: "maɪ ˈsɪstə wɜːks əz ə vɛt",
        translation: "My sister works as a vet",
      },
      {
        id: 14,
        expression: "Наш учитель часто говорит это",
        transcription: "ˈaʊə ˈtiːʧər ˈɒf(ə)n sɛz ɪt",
        translation: "Our teacher often says it",
      },
      {
        id: 15,
        expression: "У его сестры есть парень",
        transcription: "hɪz ˈsɪstə həz ə ˈbɔɪˌfrɛnd",
        translation: "His sister has a boyfriend",
      },
      {
        id: 16,
        expression: "Мои друзья согласны со мной",
        transcription: "maɪ frɛndz əˈgriː wɪð miː",
        translation: "My friends agree with me",
      },
      {
        id: 17,
        expression: "Эта идея кажется очень интересной",
        transcription: "ðɪs aɪˈdɪə siːmz ˈvɛri ˈɪntrɪstɪŋ",
        translation: "This idea seems very interesting",
      },
      {
        id: 18,
        expression: "Этот год кажется нам очень сложным",
        transcription: "ðɪs jɪə siːmz ˈvɛri ˈdɪfɪkəlt fər ʌs",
        translation: "This year seems very difficult for us",
      },
      {
        id: 19,
        expression: "У нашего учителя много студентов",
        transcription: "ˈaʊə ˈtiːʧə həz ˈmɛni ˈstjuːdənts",
        translation: "Our teacher has many students",
      },
      {
        id: 20,
        expression: "Тот студент показывает лучше результаты",
        transcription: "ðæt ˈstjuːdənt ʃəʊz ˈbɛtə rɪˈzʌlts",
        translation: "That student shows better results",
      },
      {
        id: 21,
        expression: "Эти вещи так сильно мотивируют меня",
        transcription: "ðiːz θɪŋz ˈməʊtɪveɪt mi səʊ mʌʧ (ə lɒt, greɪt)",
        translation: "These things motivate me so much (a lot, great)",
      },
      {
        id: 22,
        expression: "У этого мальчика очень богатые родители",
        transcription: "ðɪs bɔɪ həz ˈvɛri rɪʧ ˈpeərənts",
        translation: "This boy has very rich parents",
      },
      {
        id: 23,
        expression: "Моя мама работает врачом",
        transcription: "maɪ ˈmʌðə wɜːks əz ə ˈdɒktə",
        translation: "My mother works as a doctor",
      },
      {
        id: 24,
        expression: "Эти вещи кажутся действительно важными",
        transcription: "ðiːz θɪŋz siːm ˈrɪəli ɪmˈpɔːtənt",
        translation: "These things seem really important",
      },
      {
        id: 25,
        expression: "Моей сестре тоже нравится эта песня",
        transcription: "maɪ ˈsɪstər ˈɔːlsəʊ laɪks ðɪs sɒŋ",
        translation: "My sister also likes this song",
      },
      {
        id: 26,
        expression: "Эта работа кажется такой сложной",
        transcription: "ðɪs wɜːk siːmz səʊ ˈdɪfɪkəlt",
        translation: "This work seems so difficult",
      },
      {
        id: 27,
        expression: "Эта женщина выглядит такой красивой",
        transcription: "ðɪs ˈwʊmən lʊks səʊ ˈbjuːtəfʊl",
        translation: "This woman looks so beautiful",
      },
      {
        id: 28,
        expression: "Мой брат работает фрилансером",
        transcription: "maɪ ˈbrʌðə wɜːks əz ə ˈfriːˌlɑːnsə",
        translation: "My brother works as a freelancer",
      },
      {
        id: 29,
        expression: "У этого мальчика много вопросов",
        transcription: "ðɪs bɔɪ həz ˈmɛni ˈkwɛsʧənz",
        translation: "This boy has many questions",
      },
      {
        id: 30,
        expression: "Мои друзья часто говорят мне эти вещи",
        transcription: "maɪ frɛndz ˈɒf(ə)n tɛl mi ðiːz θɪŋz",
        translation: "My friends often tell me these things",
      },
      {
        id: 31,
        expression: "Эта игра кажется такой скучной",
        transcription: "ðɪs geɪm siːmz səʊ ˈbɔːrɪŋ",
        translation: "This game seems so boring",
      },
      {
        id: 32,
        expression: "Этот курс действительно помогает мне",
        transcription: "ðɪs kɔːs ˈrɪəli hɛlps miː",
        translation: "This course really helps me",
      },
      {
        id: 33,
        expression: "Эти женщины выглядят так красиво",
        transcription: "ðiːz ˈwɪmɪn lʊk səʊ ˈbjuːtəfʊl",
        translation: "These women look so beautiful",
      },
      {
        id: 34,
        expression: "Наша жизнь кажется очень короткой",
        transcription: "ˈaʊə laɪf siːmz ˈvɛri ʃɔːt",
        translation: "Our life seems very short",
      },
      {
        id: 35,
        expression: "Этот парень работает с ними",
        transcription: "ðɪs gaɪ wɜːks wɪð ðɛm",
        translation: "This guy works with them",
      },
      {
        id: 36,
        expression: "Тот путь кажется короче",
        transcription: "ðæt weɪ siːmz ˈʃɔːtə",
        translation: "That way seems shorter",
      },
      {
        id: 37,
        expression: "Эта вещь стоит слишком много",
        transcription: "ðɪs θɪŋ kɒsts tuː mʌʧ",
        translation: "This thing costs too much",
      },
      {
        id: 38,
        expression: "Мой отец работает менеджером по продажам",
        transcription: "maɪ ˈfɑːðə wɜːks əz ə seɪlz ˈmænɪʤə",
        translation: "My father works as a sales manager",
      },
      {
        id: 39,
        expression: "Этот мужчина выглядит очень сильным",
        transcription: "ðɪs mæn lʊks ˈvɛri strɒŋ",
        translation: "This man looks very strong",
      },
      {
        id: 40,
        expression: "У этого доктора много пациентов",
        transcription: "ðɪs ˈdɒktə həz ˈmɛni ˈpeɪʃənts",
        translation: "This doctor has many patients",
      },
      {
        id: 41,
        expression: "Мой друг поддерживает меня",
        transcription: "maɪ frɛnd səˈpɔːts miː",
        translation: "My friend supports me",
      },
      {
        id: 42,
        expression: "У этой девочки очень бедные родители",
        transcription: "ðɪs gɜːl həz ˈvɛri pʊə ˈpeərənts",
        translation: "This girl has very poor parents",
      },
      {
        id: 43,
        expression:
          "Я хочу сказать тебе, что эта речь очень сильно вдохновляет меня",
        transcription: "aɪ wɒnt tə tɛl jʊ ðət ðɪs spiːʧ ɪnˈspaɪəz mi ˈgreɪtli",
        translation: "I want to tell you that this speech inspires me greatly",
      },
      {
        id: 44,
        expression: "Мой начальник хочет купить новую машину",
        transcription: "maɪ bɒs wɒnt tə baɪ ə njuː kɑː",
        translation: "My boss want to buy a new car",
      },
      {
        id: 45,
        expression: "Студенты часто копируют эти ошибки",
        transcription: "ˈstjuːdənts ˈɒf(ə)n ˈkɒpi ðiːz mɪsˈteɪks",
        translation: "Students often copy these mistakes",
      },
      {
        id: 46,
        expression: "Этот магазин принимает кредитные карты тоже",
        transcription: "ðɪs stɔː teɪks ˈkrɛdɪt kɑːdz, tuː",
        translation: "This store takes credit cards, too",
      },
      {
        id: 47,
        expression: "Я вижу, что время проходит очень быстро",
        transcription: "aɪ siː ðət taɪm ˈpɑːsɪz ˈvɛri ˈkwɪkli",
        translation: "I see that time passes very quickly",
      },
      {
        id: 48,
        expression: "Эта коробка лежит на полу",
        transcription: "ðɪs bɒks laɪz ɒn ðə flɔː",
        translation: "This box lies on the floor",
      },
      {
        id: 49,
        expression: "У моих родителей другое мнение",
        transcription: "maɪ ˈpeərənts həv əˈnʌðər əˈpɪnjən",
        translation: "My parents have another opinion",
      },
      {
        id: 50,
        expression: "Его сестра танцует профессионально",
        transcription: "hɪz ˈsɪstə ˈdɑːnsɪz prəˈfɛʃnəli",
        translation: "His sister dances professionally",
      },
      {
        id: 51,
        expression: "Этот подросток часто перебивает других людей",
        transcription: "ðɪs ˈtiːnˌeɪʤər ˈɒf(ə)n ˌɪntəˈrʌpts ˈʌðə ˈpiːplz",
        translation: "This teenager often interrupts other peoples",
      },
      {
        id: 52,
        expression: "Тот проект приносит им слишком мало денег",
        transcription: "ðæt ˈprɒʤɛkt brɪŋz ðəm tuː ˈlɪtl ˈmʌni",
        translation: "That project brings them too little money",
      },
      {
        id: 53,
        expression: "Моя мама выходит в отпуск несколько раз в год",
        transcription: "maɪ ˈmʌðə gəʊz ɒn ˈhɒlədeɪ ˈsɛvrəl taɪmz ə jɪə",
        translation: "My mother goes on holiday several times a year",
      },
      {
        id: 54,
        expression: "Этот проект приносит ему большие деньги",
        transcription: "ðɪs ˈprɒʤɛkt brɪŋz ɪm bɪg ˈmʌni",
        translation: "This project brings him big money",
      },
      {
        id: 55,
        expression: "Тот мальчик всегда перебивает других",
        transcription: "ðæt bɔɪ ˈɔːlweɪz ˌɪntəˈrʌpts ˈʌðəz",
        translation: "That boy always interrupts others",
      },
      {
        id: 56,
        expression: "Я вижу, что этот метод действительно работает",
        transcription: "aɪ siː ðət ðɪs ˈmɛθəd ˈrɪəli wɜːks",
        translation: "I see that this method really works",
      },
      {
        id: 57,
        expression: "Этот человек пытается найти новую работу",
        transcription: "ðɪs ˈpɜːsn traɪz tə faɪnd ə njuː ʤɒb",
        translation: "This person tries to find a new job",
      },
      {
        id: 58,
        expression: "Девочка часто перебивает других",
        transcription: "ðə gɜːl ˈɒf(ə)n ˌɪntəˈrʌpts ˈʌðəz",
        translation: "The girl often interrupts others",
      },
      {
        id: 59,
        expression: "Девочка часто перебивает других людей",
        transcription: "ðə gɜːl ˈɒf(ə)n ˌɪntəˈrʌpts ˈʌðə ˈpiːpl",
        translation: "The girl often interrupts other people",
      },
      {
        id: 60,
        expression: "Ее дочь часто перебивала других",
        transcription: "hə ˈdɔːtər ˈɒf(ə)n ˌɪntəˈrʌptɪd ˈʌðəz",
        translation: "Her daughter often interrupted others",
      },
      {
        id: 61,
        expression: "Картина висит на стене",
        transcription: "ðə ˈpɪkʧə hæŋz ɒn ðə wɔːl",
        translation: "The picture hangs on the wall",
      },
      {
        id: 62,
        expression: "Две картины висят на стене",
        transcription: "tuː ˈpɪkʧəz hæŋ ɒn ðə wɔːl",
        translation: "Two pictures hang on the wall",
      },
      {
        id: 63,
        expression: "Некоторые картины висят на стене",
        transcription: "sʌm ˈpɪkʧəz hæŋ ɒn ðə wɔːl",
        translation: "Some pictures hang on the wall",
      },
      {
        id: 64,
        expression: "Я вижу, что время действительно летит",
        transcription: "aɪ siː ðət taɪm ˈrɪəli flaɪz",
        translation: "I see that time really flies",
      },
      {
        id: 65,
        expression: "Она видит, что время действительно летит",
        transcription: "ʃi siːz ðət taɪm ˈrɪəli flaɪz",
        translation: "She sees that time really flies",
      },
      {
        id: 66,
        expression: "Они видят, что время летит",
        transcription: "ðeɪ siː ðət taɪm flaɪz",
        translation: "They see that time flies",
      },
      {
        id: 67,
        expression: "Этот магазин принимает кредитные карты",
        transcription: "ðɪs ʃɒp teɪks ˈkrɛdɪt kɑːdz",
        translation: "This shop takes credit cards",
      },
      {
        id: 68,
        expression: "Эти магазины принимают кредитные карты",
        transcription: "ðiːz ʃɒps teɪk ˈkrɛdɪt kɑːdz",
        translation: "These shops take credit cards",
      },
      {
        id: 69,
        expression: "Этот интернет-магазин принимает кредитные карты",
        transcription: "ðɪs ˈɒnˌlaɪn stɔː teɪks ˈkrɛdɪt kɑːdz",
        translation: "This online store takes credit cards",
      },
      {
        id: 70,
        expression: "Мой друг пытается водить очень осторожно",
        transcription: "maɪ frɛnd traɪz tə draɪv ˈvɛri ˈkeəfli",
        translation: "My friend tries to drive very carefully",
      },
      {
        id: 71,
        expression: "Мой друг всегда водит очень осторожно",
        transcription: "maɪ frɛnd ˈɔːlweɪz draɪvz ˈvɛri ˈkeəfli",
        translation: "My friend always drives very carefully",
      },
      {
        id: 72,
        expression: "Мои друзья стараются водить очень осторожно",
        transcription: "maɪ frɛndz traɪ tə draɪv ˈvɛri ˈkeəfli",
        translation: "My friends try to drive very carefully",
      },
      {
        id: 73,
        expression: "Слово означает другую вещь",
        transcription: "ðə wɜːd miːnz əˈnʌðə θɪŋ",
        translation: "The word means another thing",
      },
      {
        id: 74,
        expression: "Эти слова означают другие вещи",
        transcription: "ðiːz wɜːdz miːn ˈʌðə θɪŋz",
        translation: "These words mean other things",
      },
      {
        id: 75,
        expression: "Это слово означает другую вещь",
        transcription: "ðɪs wɜːd miːnz əˈnʌðə θɪŋ",
        translation: "This word means another thing",
      },
      {
        id: 76,
        expression: "Такие вещи часто случаются",
        transcription: "sʌʧ θɪŋz ˈɒf(ə)n ˈhæpən",
        translation: "Such things often happen",
      },
      {
        id: 77,
        expression: "Эта вещь часто случается",
        transcription: "ðɪs θɪŋ ˈɒf(ə)n ˈhæpənz",
        translation: "This thing often happens",
      },
      {
        id: 78,
        expression: "Мой младший брат думает так",
        transcription: "maɪ ˈjʌŋə ˈbrʌðə θɪŋks səʊ",
        translation: "My younger brother thinks so",
      },
      {
        id: 79,
        expression: "Мои братья думают так",
        transcription: "maɪ ˈbrʌðəz θɪŋk səʊ",
        translation: "My brothers think so",
      },
      {
        id: 80,
        expression: "Мой старший брат думает так",
        transcription: "maɪ ˈɛldə ˈbrʌðə θɪŋk səʊ",
        translation: "My elder brother think so",
      },
      {
        id: 81,
        expression:
          "Этот интернет-магазин работает двадцать четыре часа в сутки",
        transcription: "ðɪs ˈɒnˌlaɪn stɔː wɜːks ˈtwɛnti fɔːr ˈaʊəz ə deɪ",
        translation: "This online store works twenty four hours a day",
      },
      {
        id: 82,
        expression:
          "Тот интернет-магазин работает двадцать четыре часа в сутки",
        transcription: "ðæt ˈɒnˌlaɪn stɔː wɜːks ˈtwɛnti fɔːr ˈaʊəz ə deɪ",
        translation: "That online store works twenty four hours a day",
      },
      {
        id: 83,
        expression: "Этот магазин работает двадцать четыре часа в сутки",
        transcription: "ðɪs ʃɒp wɜːks ˈtwɛnti fɔːr ˈaʊəz ə deɪ",
        translation: "This shop works twenty four hours a day",
      },
      {
        id: 84,
        expression: "Много людей делают это",
        transcription:
          "ˈmɛni ˈpiːpl dʊ ɪt / ə lɒt əv ˈpiːpl dʊ ɪt / lɒts əv ˈpiːpl dʊ ɪt",
        translation:
          "Many people do it / A lot of people do it / Lots of people do it",
      },
      {
        id: 85,
        expression: "Некоторые люди делают это",
        transcription: "sʌm ˈpiːpl dʊ ɪt",
        translation: "Some people do it",
      },
      {
        id: 86,
        expression: "Много людей делали это",
        transcription:
          "ˈmɛni ˈpiːpl dɪd ɪt / ə lɒt əv ˈpiːpl dɪd ɪt / lɒts əv ˈpiːpl dɪd ɪt",
        translation:
          "Many people did it / A lot of people did it / Lots of people did it",
      },
      {
        id: 87,
        expression: "У моей старшей сестры другое мнение",
        transcription: "maɪ ˈɛldə ˈsɪstə həz əˈnʌðər əˈpɪnjən",
        translation: "My elder sister has another opinion",
      },
      {
        id: 88,
        expression: "У этой компании очень большие проблемы",
        transcription: "ðɪs ˈkʌmpəni həz ˈvɛri bɪg ˈprɒbləmz",
        translation: "This company has very big problems",
      },
      {
        id: 89,
        expression: "Я хочу сказать, что эта речь так сильно вдохновляет меня",
        transcription: "aɪ wɒnt tə seɪ ðət ðɪs spiːʧ ɪnˈspaɪəz mi səʊ mʌʧ",
        translation: "I want to say that this speech inspires me so much",
      },
      {
        id: 90,
        expression: "Этих денег кажется достаточно",
        transcription: "ðɪs ˈmʌni siːmz ɪˈnʌf",
        translation: "This money seems enough",
      },
      {
        id: 91,
        expression: "Много людей пытаются сделать это",
        transcription: "ˈmɛni ˈpiːpl traɪ tə dʊ ɪt",
        translation: "Many people try to do it",
      },
      {
        id: 92,
        expression: "Эта фраза означает следующее",
        transcription: "ðɪs freɪz miːnz ðə ˈfɒləʊɪŋ",
        translation: "This phrase means the following",
      },
      {
        id: 93,
        expression: "Эта новость кажется такой интересной",
        transcription: "ðɪs njuːz siːmz səʊ ˈɪntrɪstɪŋ",
        translation: "This news seems so interesting",
      },
      {
        id: 94,
        expression: "Мой отец обычно ездит в отпуск дважды в год",
        transcription: "maɪ ˈfɑːðə ˈjuːʒʊəli gəʊz ɒn ˈhɒlədeɪ twaɪs ə jɪə",
        translation: "My father usually goes on holiday twice a year",
      },
      {
        id: 95,
        expression: "Тот плакат висит на стене",
        transcription: "ðæt ˈpəʊstə hæŋz ɒn ðə wɔːl",
        translation: "That poster hangs on the wall",
      },
      {
        id: 96,
        expression: "Этот пример действительно вдохновляет меня",
        transcription: "ðɪs ɪgˈzɑːmpl ˈrɪəli ɪnˈspaɪəz miː",
        translation: "This example really inspires me",
      },
      {
        id: 97,
        expression: "Эта речь действительно вдохновляет меня",
        transcription: "ðɪs spiːʧ ˈrɪəli ɪnˈspaɪəz miː",
        translation: "This speech really inspires me",
      },
      {
        id: 98,
        expression: "Тот магазин также принимает кредитные карты",
        transcription: "ðæt ʃɒp ˈɔːlsəʊ teɪks ˈkrɛdɪt kɑːdz",
        translation: "That shop also takes credit cards",
      },
      {
        id: 99,
        expression: "Эти часы стоят триста долларов",
        transcription: "ðɪs wɒʧ kɒsts θriː ˈhʌndrəd ˈdɒləz",
        translation: "This watch costs three hundred dollars",
      },
      {
        id: 100,
        expression: "Моей сестре действительно нравятся эти туфли",
        transcription: "maɪ ˈsɪstə ˈrɪəli laɪks ðiːz ʃuːz",
        translation: "My sister really likes these shoes",
      },
      {
        id: 101,
        expression: "Такие вещи иногда случаются",
        transcription: "sʌʧ θɪŋz ˈsʌmtaɪmz ˈhæpən",
        translation: "Such things sometimes happen",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Сложные подлежащие (именные формы)</h3>
<p>Если в предложении нет личного местоимения, и в Present Simple нужно выбрать, добавлять "s" или нет, то определяем, какое местоимение подходит к подлежащему:</p>
<p>My friends like this idea - Моим друзьям нравится эта идея.</p>
<p>Her dog likes going for a walk - Ее собаке нравится гулять.</p>
</div>`,
    },
  ],
];
