export const contentLesson84 = [
  [
    {
      title: "Урок 84. Past Simple. Special Question",
    },
    [
      {
        id: 1,
        expression: "Почему ты выбрал эту профессию?",
        transcription: "waɪ dɪd hi ʧuːz ðɪs prəˈfɛʃən?",
        translation: "Why did he choose this profession?",
      },
      {
        id: 2,
        expression: "Где он остановился?",
        transcription: "weə dɪd hi steɪ?",
        translation: "Where did he stay?",
      },
      {
        id: 3,
        expression: "Где он был?",
        transcription: "weə wəz hiː?",
        translation: "Where was he?",
      },
      {
        id: 4,
        expression: "Почему ты был дома вчера?",
        transcription: "waɪ wə jʊ ət həʊm ˈjɛstədeɪ?",
        translation: "Why were you at home yesterday?",
      },
      {
        id: 5,
        expression: "Когда ты родился?",
        transcription: "wɛn wə jʊ bɔːn?",
        translation: "When were you born?",
      },
      {
        id: 6,
        expression: "Как ты обычно отдыхал летом?",
        transcription: "haʊ dɪd jʊ ˈjuːʒʊəli rɪˈlæks (həv ə rɛst) ɪn ˈsʌmə?",
        translation: "How did you usually relax (have a rest) in summer?",
      },
      {
        id: 7,
        expression: "Кому ты звонил?",
        transcription: "huː dɪd jʊ kɔːl?",
        translation: "Who did you call?",
      },
      {
        id: 8,
        expression: "Как прошел твой отпуск?",
        transcription: "haʊ wəz jə ˈhɒlədeɪ (vəˈkeɪʃən)?",
        translation: "How was your holiday (vacation)?",
      },
      {
        id: 9,
        expression: "Насколько интересным был урок?",
        transcription: "haʊ ˈɪntrɪstɪŋ wəz ðə ˈlɛsn?",
        translation: "How interesting was the lesson?",
      },
      {
        id: 10,
        expression: "Насколько эффективным был курс?",
        transcription: "haʊ ɪˈfɛktɪv wəz ðə kɔːs?",
        translation: "How effective was the course?",
      },
      {
        id: 11,
        expression: "Насколько это было дорого?",
        transcription: "haʊ ɪksˈpɛnsɪv wəz ɪt?",
        translation: "How expensive was it?",
      },
      {
        id: 12,
        expression: "Когда был твой день рождения?",
        transcription: "wɛn wəz jə ˈbɜːθdeɪ?",
        translation: "When was your birthday?",
      },
      {
        id: 13,
        expression: "Ты путешествовал на самолете?",
        transcription: "dɪd jʊ ˈtrævl baɪ pleɪn?",
        translation: "Did you travel by plane?",
      },
      {
        id: 14,
        expression: "Почему ты выбрал эту профессию?",
        transcription: "waɪ dɪd jʊ ʧuːz ðɪs prəˈfɛʃən?",
        translation: "Why did you choose this profession?",
      },
      {
        id: 15,
        expression: "Почему ты установил эту программу?",
        transcription: "waɪ dɪd jʊ ɪnˈstɔːl ðɪs ˈprəʊgræm?",
        translation: "Why did you install this program?",
      },
      {
        id: 16,
        expression: "Что он делал на прошлых выходных?",
        transcription: "wɒt dɪd hi dʊ lɑːst ˈwiːkˈɛnd?",
        translation: "What did he do last weekend?",
      },
      {
        id: 17,
        expression: "Ты путешествовал на поезде?",
        transcription: "dɪd jʊ ˈtrævl baɪ treɪn?",
        translation: "Did you travel by train?",
      },
      {
        id: 18,
        expression: "Они также проинформировали тебя?",
        transcription:
          "dɪd ðeɪ ɪnˈfɔːm juː, tuː? / dɪd ðeɪ ˈɔːlsəʊ ɪnˈfɔːm juː?",
        translation: "Did they inform you, too? / Did they also inform you?",
      },
      {
        id: 19,
        expression: "Где ты родился?",
        transcription: "weə wə jʊ bɔːn?",
        translation: "Where were you born?",
      },
      {
        id: 20,
        expression: "Кто тебе это сказал?",
        transcription: "huː təʊld jʊ ɪt?",
        translation: "Who told you it?",
      },
      {
        id: 21,
        expression: "Как был фильм?",
        transcription: "haʊ wəz ðə ˈmuːvi?",
        translation: "How was the movie?",
      },
      {
        id: 22,
        expression: "Кто это сказал?",
        transcription: "huː sɛd ɪt?",
        translation: "Who said it?",
      },
      {
        id: 23,
        expression: "Насколько это было дешево?",
        transcription: "haʊ ʧiːp wəz ɪt?",
        translation: "How cheap was it?",
      },
      {
        id: 24,
        expression: "Как это случилось с тобой?",
        transcription: "haʊ dɪd ɪt ˈhæpən tə juː?",
        translation: "How did it happen to you?",
      },
      {
        id: 25,
        expression: "Как прошел твой летний отпуск?",
        transcription: "haʊ wəz jə ˈsʌmə vəˈkeɪʃən?",
        translation: "How was your summer vacation?",
      },
      {
        id: 26,
        expression: "Как долго длился один урок английского?",
        transcription: "haʊ lɒŋ dɪd wʌn ˈɪŋglɪʃ ˈlɛsn lɑːst?",
        translation: "How long did one English lesson last?",
      },
      {
        id: 27,
        expression: "Как прошло интервью?",
        transcription: "haʊ əz ði ˈɪntəvjuː?",
        translation: "How as the interview?",
      },
      {
        id: 28,
        expression: "Где ты остановился?",
        transcription: "weə dɪd jʊ steɪ?",
        translation: "Where did you stay?",
      },
      {
        id: 29,
        expression: "Как ты отпраздновал Новый год?",
        transcription: "haʊ dɪd jʊ ˈsɛlɪbreɪt njuː jɪə?",
        translation: "How did you celebrate New Year?",
      },
      {
        id: 30,
        expression: "Насколько глубоким было озеро?",
        transcription: "haʊ diːp wəz ðə leɪk?",
        translation: "How deep was the lake?",
      },
      {
        id: 31,
        expression: "Насколько хороша была эта рекомендация?",
        transcription: "haʊ gʊd wəz ðɪs ˌrɛkəmɛnˈdeɪʃən?",
        translation: "How good was this recommendation?",
      },
      {
        id: 32,
        expression: "Как прошла игра?",
        transcription: "haʊ wəz ðə geɪm?",
        translation: "How was the game?",
      },
      {
        id: 33,
        expression: "Ты закончил всю свою работу?",
        transcription: "dɪd jʊ ˈfɪnɪʃ ɔːl jə wɜːk?",
        translation: "Did you finish all your work?",
      },
      {
        id: 34,
        expression: "Где была твоя сумка?",
        transcription: "weə wəz jə bæg?",
        translation: "Where was your bag?",
      },
      {
        id: 35,
        expression: "Как ты обычно отдыхал после работы?",
        transcription: "haʊ dɪd jə ˈjuːʒʊəli rɪˈlæks ˈɑːftə wɜːk?",
        translation: "How did your usually relax after work?",
      },
      {
        id: 36,
        expression: "Что ты делал в прошлое воскресенье?",
        transcription: "wɒt dɪd jʊ dʊ lɑːst ˈsʌndeɪ?",
        translation: "What did you do last Sunday?",
      },
      {
        id: 37,
        expression: "Как долго ты оставался там?",
        transcription: "haʊ lɒŋ dɪd jʊ steɪ ðeə?",
        translation: "How long did you stay there?",
      },
      {
        id: 38,
        expression: "Почему машина сломалась?",
        transcription: "waɪ dɪd ðə kɑː breɪk daʊn?",
        translation: "Why did the car break down?",
      },
      {
        id: 39,
        expression: "Кто звонил тебе?",
        transcription: "huː kɔːld juː?",
        translation: "Who called you?",
      },
      {
        id: 40,
        expression: "Как прошла встреча?",
        transcription: "haʊ wəz ðə ˈmiːtɪŋ?",
        translation: "How was the meeting?",
      },
      {
        id: 41,
        expression: "Почему компьютер сломался?",
        transcription: "waɪ dɪd ðə kəmˈpjuːtə breɪk daʊn?",
        translation: "Why did the computer break down?",
      },
      {
        id: 42,
        expression: "Когда случилась эта авария?",
        transcription: "wɛn dɪd ðɪs ˈæksɪdənt ˈhæpən?",
        translation: "When did this accident happen?",
      },
      {
        id: 43,
        expression: "Насколько он был заинтересован?",
        transcription: "haʊ ˈɪntrɪstɪd wəz hiː?",
        translation: "How interested was he?",
      },
      {
        id: 44,
        expression: "Кого они проинформировали?",
        transcription: "huː dɪd ðeɪ ɪnˈfɔːm?",
        translation: "Who did they inform?",
      },
      {
        id: 45,
        expression: "Почему ты перестал это делать?",
        transcription: "waɪ dɪd jʊ stɒp ˈdu(ː)ɪŋ ɪt?",
        translation: "Why did you stop doing it?",
      },
      {
        id: 46,
        expression: "Ты думал, что это действительно необходимо было сделать?",
        transcription: "dɪd jʊ θɪŋk ðət ɪt wəz ˈrɪəli ˈnɛsɪsəri tə dʊ ɪt?",
        translation: "Did you think that it was really necessary to do it?",
      },
      {
        id: 47,
        expression: "Что тебе больше всего помогло?",
        transcription: "wɒt hɛlpt jʊ məʊst əv ɔːl?",
        translation: "What helped you most of all?",
      },
      {
        id: 48,
        expression: "Что ты решил купить?",
        transcription: "wɒt dɪd jʊ dɪˈsaɪd tə baɪ?",
        translation: "What did you decide to buy?",
      },
      {
        id: 49,
        expression: "Как тебе удалось найти решение этой проблемы?",
        transcription:
          "haʊ dɪd jʊ ˈmænɪʤ tə faɪnd ə səˈluːʃən tə ðɪs ˈprɒbləm?",
        translation: "How did you manage to find a solution to this problem?",
      },
      {
        id: 50,
        expression: "Почему ты решил сделать это позже?",
        transcription: "waɪ dɪd jʊ dɪˈsaɪd tə dʊ ɪt ˈleɪtə?",
        translation: "Why did you decide to do it later?",
      },
      {
        id: 51,
        expression: "Почему он сказал тебе не делать этого?",
        transcription: "waɪ dɪd hi tɛl jʊ nɒt tə dʊ ɪt?",
        translation: "Why did he tell you not to do it?",
      },
      {
        id: 52,
        expression: "Почему еда так плохо пахла?",
        transcription: "waɪ dɪd ðə fuːd smɛl səʊ bæd?",
        translation: "Why did the food smell so bad?",
      },
      {
        id: 53,
        expression: "Сколько предложений ты перевел?",
        transcription: "haʊ ˈmɛni ˈsɛntənsɪz dɪd jʊ trænsˈleɪt?",
        translation: "How many sentences did you translate?",
      },
      {
        id: 54,
        expression: "Что она решила не покупать?",
        transcription: "wɒt dɪd ʃi dɪˈsaɪd nɒt tə baɪ?",
        translation: "What did she decide not to buy?",
      },
      {
        id: 55,
        expression: "Какие упражнения ты сделал?",
        transcription: "wɒt ˈɛksəsaɪzɪz dɪd jʊ duː?",
        translation: "What exercises did you do?",
      },
      {
        id: 56,
        expression: "Насколько успешной была операция?",
        transcription: "haʊ səkˈsɛsfʊl wəz ði ˌɒpəˈreɪʃən?",
        translation: "How successful was the operation?",
      },
      {
        id: 57,
        expression: "Какой отель ты выбрал?",
        transcription: "wɒt həʊˈtɛl dɪd jʊ ʧuːz?",
        translation: "What hotel did you choose?",
      },
      {
        id: 58,
        expression: "Как они разрешили этот конфликт?",
        transcription: "haʊ dɪd ðeɪ rɪˈzɒlv ðɪs ˈkɒnflɪkt?",
        translation: "How did they resolve this conflict?",
      },
      {
        id: 59,
        expression: "Что исчезло?",
        transcription: "wɒt ˌdɪsəˈpɪəd?",
        translation: "What disappeared?",
      },
      {
        id: 60,
        expression: "Почему он предпочел не покупать это?",
        transcription: "waɪ dɪd hi priˈfɜː nɒt tə baɪ ɪt?",
        translation: "Why did he prefer not to buy it?",
      },
      {
        id: 61,
        expression: "Почему он решил не покупать это?",
        transcription: "waɪ dɪd hi dɪˈsaɪd nɒt tə baɪ ɪt?",
        translation: "Why did he decide not to buy it?",
      },
      {
        id: 62,
        expression: "Почему ты предпочел не покупать эту вещь?",
        transcription: "waɪ dɪd jʊ priˈfɜː nɒt tə baɪ ðɪs θɪŋ?",
        translation: "Why did you prefer not to buy this thing?",
      },
      {
        id: 63,
        expression: "Что действительно удивило тебя?",
        transcription: "wɒt ˈrɪəli səˈpraɪzd juː?",
        translation: "What really surprised you?",
      },
      {
        id: 64,
        expression: "Кто удивил тебя?",
        transcription: "huː səˈpraɪzd juː?",
        translation: "Who surprised you?",
      },
      {
        id: 65,
        expression: "Что удивило тебя так сильно?",
        transcription: "wɒt səˈpraɪzd jʊ səʊ mʌʧ?",
        translation: "What surprised you so much?",
      },
      {
        id: 66,
        expression: "Почему ты решил начать изучать английский здесь?",
        transcription: "waɪ dɪd jʊ dɪˈsaɪd tə stɑːt ˈlɜːnɪŋ ˈɪŋglɪʃ hɪə?",
        translation: "Why did you decide to start learning English here?",
      },
      {
        id: 67,
        expression: "Почему ты решил начать изучать английский там?",
        transcription: "waɪ dɪd jʊ dɪˈsaɪd tə stɑːt ˈlɜːnɪŋ ˈɪŋglɪʃ ðeə?",
        translation: "Why did you decide to start learning English there?",
      },
      {
        id: 68,
        expression: "Почему ты решил учиться здесь?",
        transcription: "waɪ dɪd jʊ dɪˈsaɪd tə ˈstʌdi hɪə?",
        translation: "Why did you decide to study here?",
      },
      {
        id: 69,
        expression: "Как ты сумел это сделать?",
        transcription: "haʊ dɪd jʊ ˈmænɪʤ tə dʊ ɪt?",
        translation: "How did you manage to do it?",
      },
      {
        id: 70,
        expression: "Как он это сделал?",
        transcription: "haʊ dɪd hi dʊ ɪt?",
        translation: "How did he do it?",
      },
      {
        id: 71,
        expression: "Как ты предпочел это сделать?",
        transcription: "haʊ dɪd jʊ priˈfɜː tə dʊ ɪt?",
        translation: "How did you prefer to do it?",
      },
      {
        id: 72,
        expression: "Как он вошел в здание?",
        transcription: "haʊ dɪd hi ˈɛntə ðə ˈbɪldɪŋ?",
        translation: "How did he enter the building?",
      },
      {
        id: 73,
        expression: "Когда он вошел в здание?",
        transcription: "wɛn dɪd hi ˈɛntə ðə ˈbɪldɪŋ?",
        translation: "When did he enter the building?",
      },
      {
        id: 74,
        expression: "Как они вошли в здание?",
        transcription: "haʊ dɪd ðeɪ ˈɛntə ðə ˈbɪldɪŋ?",
        translation: "How did they enter the building?",
      },
      {
        id: 75,
        expression: "Кого удивила эта новость?",
        transcription: "huː dɪd ðɪs njuːz səˈpraɪz?",
        translation: "Who did this news surprise?",
      },
      {
        id: 76,
        expression: "Кого больше всего удивила эта новость?",
        transcription: "huː dɪd ðɪs njuːz səˈpraɪz məʊst əv ɔːl?",
        translation: "Who did this news surprise most of all?",
      },
      {
        id: 77,
        expression: "Кого удивила эта ситуация?",
        transcription: "huː dɪd ðɪs ˌsɪtjʊˈeɪʃən səˈpraɪz?",
        translation: "Who did this situation surprise?",
      },
      {
        id: 78,
        expression: "Как пахла еда?",
        transcription: "haʊ dɪd ðə fuːd smɛl?",
        translation: "How did the food smell?",
      },
      {
        id: 79,
        expression: "Как пахнет эта еда?",
        transcription: "haʊ dəz ðɪs fuːd smɛl?",
        translation: "How does this food smell?",
      },
      {
        id: 80,
        expression: "Как пахли эти продукты?",
        transcription: "haʊ dɪd ðiːz ˈprɒdʌkts smɛl?",
        translation: "How did these products smell?",
      },
      {
        id: 81,
        expression: "Как ты сумел решить эту проблему?",
        transcription: "haʊ dɪd jʊ ˈmænɪʤ tə sɒlv ðɪs ˈprɒbləm?",
        translation: "How did you manage to solve this problem?",
      },
      {
        id: 82,
        expression: "Как тебе удалось решить проблему?",
        transcription: "haʊ dɪd jʊ ˈmænɪʤ tə sɒlv ðə ˈprɒbləm?",
        translation: "How did you manage to solve the problem?",
      },
      {
        id: 83,
        expression: "Как ему удалось решить эту проблему?",
        transcription: "haʊ dɪd hi ˈmænɪʤ tə sɒlv ðɪs ˈprɒbləm?",
        translation: "How did he manage to solve this problem?",
      },
      {
        id: 84,
        expression: "Сколько стоило платье?",
        transcription: "haʊ mʌʧ wəz ðə drɛs? / haʊ dɪd ðə drɛs kɒst?",
        translation: "How much was the dress? / How did the dress cost?",
      },
      {
        id: 85,
        expression: "Сколько стоила эта одежда?",
        transcription: "haʊ mʌʧ dɪd ðiːz kləʊðz kɒst?",
        translation: "How much did these clothes cost?",
      },
      {
        id: 86,
        expression: "Сколько стоила эта вещь?",
        transcription: "haʊ dɪd ðɪs θɪŋ kɒst?",
        translation: "How did this thing cost?",
      },
      {
        id: 87,
        expression: "Как ты бросил курить?",
        transcription: "haʊ dɪd jʊ stɒp ˈsməʊkɪŋ?",
        translation: "How did you stop smoking?",
      },
      {
        id: 88,
        expression: "Когда ты бросил курить?",
        transcription: "wɛn dɪd jʊ stɒp ˈsməʊkɪŋ?",
        translation: "When did you stop smoking?",
      },
      {
        id: 89,
        expression: "Как ты прекратил это делать?",
        transcription: "haʊ dɪd jʊ stɒp ˈdu(ː)ɪŋ ɪt?",
        translation: "How did you stop doing it?",
      },
      {
        id: 90,
        expression: "Сколько времени ты потратил на это?",
        transcription: "haʊ mʌʧ taɪm dɪd jʊ spɛnd ɒn ɪt?",
        translation: "How much time did you spend on it?",
      },
      {
        id: 91,
        expression: "Что случилось с тобой?",
        transcription: "wɒt ˈhæpənd tə juː?",
        translation: "What happened to you?",
      },
      {
        id: 92,
        expression: "Что ты предпочел сделать?",
        transcription: "wɒt dɪd jʊ priˈfɜː tə duː?",
        translation: "What did you prefer to do?",
      },
      {
        id: 93,
        expression: "Почему ты перестал читать эту книгу?",
        transcription: "waɪ dɪd jʊ stɒp ˈriːdɪŋ ðɪs bʊk?",
        translation: "Why did you stop reading this book?",
      },
      {
        id: 94,
        expression: "Почему они исчезли?",
        transcription: "waɪ dɪd ðeɪ ˌdɪsəˈpɪə?",
        translation: "Why did they disappear?",
      },
      {
        id: 95,
        expression: "Кого удивило это решение?",
        transcription: "huː dɪd ðɪs dɪˈsɪʒən səˈpraɪz?",
        translation: "Who did this decision surprise?",
      },
      {
        id: 96,
        expression: "Почему ты начал изучать английский?",
        transcription: "waɪ dɪd jʊ stɑːt ˈlɜːnɪŋ ˈɪŋglɪʃ?",
        translation: "Why did you start learning English?",
      },
      {
        id: 97,
        expression: "Почему ты начал делать это?",
        transcription: "waɪ dɪd jʊ stɑːt ˈdu(ː)ɪŋ ɪt?",
        translation: "Why did you start doing it?",
      },
      {
        id: 98,
        expression: "Как ты поступил в этот университет?",
        transcription: "haʊ dɪd jʊ ˈɛntə ðɪs ˌjuːnɪˈvɜːsɪti?",
        translation: "How did you enter this university?",
      },
      {
        id: 99,
        expression: "Почему ты предпочел не делать этого?",
        transcription: "waɪ dɪd jʊ priˈfɜː nɒt tə dʊ ɪt?",
        translation: "Why did you prefer not to do it?",
      },
      {
        id: 100,
        expression: "Когда ты вошел в комнату?",
        transcription: "wɛn dɪd jʊ ˈɛntə ðə ruːm?",
        translation: "When did you enter the room?",
      },
      {
        id: 101,
        expression: "Кто исчез?",
        transcription: "huː ˌdɪsəˈpɪəd?",
        translation: "Who disappeared?",
      },
      {
        id: 102,
        expression: "Как долго длилась операция?",
        transcription: "haʊ lɒŋ dɪd ði ˌɒpəˈreɪʃən lɑːst?",
        translation: "How long did the operation last?",
      },
      {
        id: 103,
        expression: "Что удивило тебя больше всего?",
        transcription: "wɒt səˈpraɪzd jʊ məʊst əv ɔːl?",
        translation: "What surprised you most of all?",
      },
      {
        id: 104,
        expression: "Сколько стоило платье?",
        transcription: "haʊ mʌʧ wəz ðə drɛs?",
        translation: "How much was the dress?",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Закрепление пройденного материала</h3>
</div>`,
    },
  ],
];
