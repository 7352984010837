export const wordsLesson258 = [
  [
    {
      id: 1,
      word: 'sort out',
      transcription: 'sɔːt aʊt',
      translation: 'уладить',
    },
    {
      id: 2,
      word: 'bail out',
      transcription: 'beɪl aʊt',
      translation: 'выручить',
    },
    {
      id: 3,
      word: 'do up',
      transcription: 'duː ʌp',
      translation: 'чинить, ремонтировать, уладить',
    },
    {
      id: 4,
      word: 'pop into',
      transcription: 'pɒp ˈɪntuː',
      translation: 'заскочить в',
    },
    {
      id: 5,
      word: 'splash out',
      transcription: 'splæʃ aʊt',
      translation: 'сорить деньгами, тратиться',
    },
    {
      id: 6,
      word: 'fall for',
      transcription: 'fɔːl fɔː',
      translation: 'влюбиться, попадать',
    },
    {
      id: 7,
      word: 'take out',
      transcription: 'teɪk aʊt',
      translation: 'выносить, вынимать',
    },
    {
      id: 8,
      word: 'mop up',
      transcription: 'mɒp ʌp',
      translation: 'вытирать',
    },
    {
      id: 9,
      word: 'cloth',
      transcription: 'klɒθ',
      translation: 'тряпка',
    },
    {
      id: 10,
      word: 'garbage',
      transcription: 'ˈɡɑːbɪʤ',
      translation: 'мусор',
    },
    {
      id: 11,
      word: 'lad',
      transcription: 'læd',
      translation: 'парень',
    },
    {
      id: 12,
      word: 'originally',
      transcription: 'əˈrɪʤɪnəli',
      translation: 'изначально',
    },
    {
      id: 13,
      word: 'persistence',
      transcription: 'pəˈsɪstəns',
      translation: 'настойчивость',
    },
    {
      id: 14,
      word: 'rural',
      transcription: 'ˈrʊərəl',
      translation: 'сельский',
    },
    {
      id: 15,
      word: 'seatbelt',
      transcription: 'ˈsiːtbɛlt',
      translation: 'ремень безопасности',
    },
    {
      id: 16,
      word: 'spilt',
      transcription: 'ˈspɪlt',
      translation: 'разлитый',
    },
    {
      id: 17,
      word: 'trash',
      transcription: 'træʃ',
      translation: 'мусор',
    },
    {
      id: 18,
      word: 'trick',
      transcription: 'trɪk',
      translation: 'трюк',
    },
    {
      id: 19,
      word: 'unsatisfactory',
      transcription: 'ˌʌnˌsætɪsˈfæktəri',
      translation: 'неудовлетворительный',
    },
  ],
];
