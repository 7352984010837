export const wordsLesson250 = [
  [
    {
      id: 1,
      word: 'talk show',
      transcription: 'tɔːk ʃəʊ',
      translation: 'ток-шоу',
    },
    {
      id: 2,
      word: 'agility',
      transcription: 'əˈʤɪlɪti',
      translation: 'ловкость',
    },
    {
      id: 3,
      word: 'association',
      transcription: 'əˌsəʊsɪˈeɪʃᵊn',
      translation: 'ассоциация',
    },
    {
      id: 4,
      word: 'berry',
      transcription: 'ˈbɛri',
      translation: 'ягода',
    },
    {
      id: 5,
      word: 'bomb',
      transcription: 'bɒm',
      translation: 'бомба',
    },
    {
      id: 6,
      word: 'camp',
      transcription: 'kæmp',
      translation: 'лагерь',
    },
    {
      id: 7,
      word: 'category',
      transcription: 'ˈkætɪɡəri',
      translation: 'категория',
    },
    {
      id: 8,
      word: 'clown',
      transcription: 'klaʊn',
      translation: 'клоун',
    },
    {
      id: 9,
      word: 'collection',
      transcription: 'kəˈlɛkʃᵊn',
      translation: 'коллекция',
    },
    {
      id: 10,
      word: 'combine',
      transcription: 'ˈkɒmbaɪn',
      translation: 'совмещать',
    },
    {
      id: 11,
      word: 'communication',
      transcription: 'kəˌmjuːnɪˈkeɪʃᵊn',
      translation: 'общение',
    },
    {
      id: 12,
      word: 'confiscate',
      transcription: 'ˈkɒnfɪskeɪt',
      translation: 'конфисковывать',
    },
    {
      id: 13,
      word: 'defend',
      transcription: 'dɪˈfɛnd',
      translation: 'защищать',
    },
    {
      id: 14,
      word: 'endurance',
      transcription: 'ɪnˈdjʊərəns',
      translation: 'выносливость',
    },
    {
      id: 15,
      word: 'establish',
      transcription: 'ɪsˈtæblɪʃ',
      translation: 'основывать, создавать, учреждать',
    },
    {
      id: 16,
      word: 'expire',
      transcription: 'ɪksˈpaɪə',
      translation: 'истекать',
    },
    {
      id: 17,
      word: 'explode',
      transcription: 'ɪksˈpləʊd',
      translation: 'взрывать',
    },
    {
      id: 18,
      word: 'female',
      transcription: 'ˈfiːmeɪl',
      translation: 'женский',
    },
    {
      id: 19,
      word: 'flash',
      transcription: 'flæʃ',
      translation: 'вспышка',
    },
    {
      id: 20,
      word: 'force',
      transcription: 'fɔːs',
      translation: 'вынуждать',
    },
    {
      id: 21,
      word: 'gather',
      transcription: 'ˈɡæðə',
      translation: 'собирать',
    },
    {
      id: 22,
      word: 'geography',
      transcription: 'ʤɪˈɒɡrəfi',
      translation: 'география',
    },
    {
      id: 23,
      word: 'humiliating',
      transcription: 'hjuːˈmɪlieɪtɪŋ',
      translation: 'унизительный',
    },
    {
      id: 24,
      word: 'insert',
      transcription: 'ˈɪnsət',
      translation: 'вставлять',
    },
    {
      id: 25,
      word: 'locate',
      transcription: 'ləʊˈkeɪt',
      translation: 'разместить',
    },
    {
      id: 26,
      word: 'mushroom',
      transcription: 'ˈmʌʃrʊm',
      translation: 'гриб',
    },
    {
      id: 27,
      word: 'nick',
      transcription: 'nɪk',
      translation: 'ник',
    },
    {
      id: 28,
      word: 'obey',
      transcription: 'əˈbeɪ',
      translation: 'подчиняться',
    },
    {
      id: 29,
      word: 'okay',
      transcription: 'ˈəʊˈkeɪ',
      translation: 'окей',
    },
    {
      id: 30,
      word: 'practise',
      transcription: 'ˈpræktɪs',
      translation: 'практика',
    },
    {
      id: 31,
      word: 'promote',
      transcription: 'prəˈməʊt',
      translation: 'продвигать',
    },
    {
      id: 32,
      word: 'railway station',
      transcription: 'ˈreɪlweɪ ˈsteɪʃᵊn',
      translation: 'железнодорожная станция',
    },
    {
      id: 33,
      word: 'resign',
      transcription: 'rɪˈzaɪn',
      translation: 'уходить в отставку',
    },
    {
      id: 34,
      word: 'shake',
      transcription: 'ʃeɪk',
      translation: 'трясти',
    },
    {
      id: 35,
      word: 'specifically',
      transcription: 'spəˈsɪfɪkᵊli',
      translation: 'специально',
    },
    {
      id: 36,
      word: 'speechless',
      transcription: 'ˈspiːʧlɪs',
      translation: 'немой',
    },
    {
      id: 37,
      word: 'stretching',
      transcription: 'ˈstrɛʧɪŋ',
      translation: 'растяжка',
    },
    {
      id: 38,
      word: 'technique',
      transcription: 'tɛkˈniːk',
      translation: 'техника',
    },
    {
      id: 39,
      word: 'terrorism',
      transcription: 'ˈtɛrərɪzm',
      translation: 'терроризм',
    },
    {
      id: 40,
      word: 'trial',
      transcription: 'ˈtraɪəl',
      translation: 'пробный',
    },
    {
      id: 41,
      word: 'vast',
      transcription: 'vɑːst',
      translation: 'огромный, обширный',
    },
    {
      id: 42,
      word: 'zebra',
      transcription: 'ˈzɛbrə',
      translation: 'зебра',
    },
  ],
];
