export const contentLesson129 = [
  [
    {
      title: 'Урок 129. 360 Verbs. Part 3',
    },
    [
      {
        id: 1,
        expression: 'Он ответил на твое письмо?',
        transcription: 'dɪd hi rɪˈplaɪ tə jə ˈlɛtə?',
        translation: 'Did he reply to your letter?',
      },
      {
        id: 2,
        expression: 'Тебе нужно разделить это на три части',
        transcription: 'jʊ niːd tə dɪˈvaɪd ɪt ˈɪntə θriː pɑːt',
        translation: 'You need to divide it into three part',
      },
      {
        id: 3,
        expression: 'Они выпустили новый альбом на этой неделе',
        transcription: 'ðeɪv rɪˈliːst ə njuː ˈælbəm ðɪs wiːk',
        translation: "They've released a new album this week",
      },
      {
        id: 4,
        expression: 'Это стало причиной его смерти',
        transcription: 'ɪt kɔːzd ɪz dɛθ',
        translation: 'It caused his death',
      },
      {
        id: 5,
        expression: 'Учитель разделил нас на четыре группы',
        transcription: 'ðə ˈtiːʧə dɪˈvaɪdɪd əs ˈɪntə fɔː gruːps',
        translation: 'The teacher divided us into four groups',
      },
      {
        id: 6,
        expression: 'Он ненавидит вставать рано',
        transcription: 'hi heɪts ˈgɛtɪŋ ʌp ˈɜːli',
        translation: 'He hates getting up early',
      },
      {
        id: 7,
        expression: 'Я учу английский самостоятельно',
        transcription: 'aɪ lɜːn ˈɪŋglɪʃ ɒn maɪ əʊn',
        translation: 'I learn English on my own',
      },
      {
        id: 8,
        expression: 'Во сколько ты встал позавчера?',
        transcription: 'wɒt taɪm dɪd jʊ gɛt ʌp ðə deɪ bɪˈfɔː ˈjɛstədeɪ?',
        translation: 'What time did you get up the day before yesterday?',
      },
      {
        id: 9,
        expression: 'Он обнял ее очень крепко',
        transcription: 'hi hʌgd hə ˈvɛri ˈtaɪtli',
        translation: 'He hugged her very tightly',
      },
      {
        id: 10,
        expression: 'Когда они поженились?',
        transcription: 'wɛn dɪd ðeɪ gɛt ˈmærɪd?',
        translation: 'When did they get married?',
      },
      {
        id: 11,
        expression: 'Я не шучу сейчас',
        transcription: 'aɪm nɒt ˈʤəʊkɪŋ naʊ / aɪm nɒt ˈkɪdɪŋ naʊ',
        translation: "I'm not joking now / I'm not kidding now",
      },
      {
        id: 12,
        expression: 'Это может привлечь новых клиентов',
        transcription: 'ɪt kən əˈtrækt njuː ˈklaɪənts (ˈkʌstəməz)',
        translation: 'It can attract new clients (customers)',
      },
      {
        id: 13,
        expression: 'Ты можешь угадать ее возраст?',
        transcription: 'kən jʊ gɛs hər eɪʤ?',
        translation: 'Can you guess her age?',
      },
      {
        id: 14,
        expression: 'Я протестировал свои знания',
        transcription: 'aɪ ˈtɛstɪd maɪ ˈnɒlɪʤ',
        translation: 'I tested my knowledge',
      },
      {
        id: 15,
        expression: 'Как долго он изучает английский?',
        transcription: 'haʊ lɒŋ həz hi biːn ˈlɜːnɪŋ ˈɪŋglɪʃ?',
        translation: 'How long has he been learning English?',
      },
      {
        id: 16,
        expression: 'Мальчик посчитал от одного до десяти',
        transcription: 'ðə bɔɪ ˈkaʊntɪd frəm wʌn tə tɛn',
        translation: 'The boy counted from one to ten',
      },
      {
        id: 17,
        expression: 'Это кажется невозможным сейчас',
        transcription: 'ɪt siːmz ɪmˈpɒsəbl naʊ',
        translation: 'It seems impossible now',
      },
      {
        id: 18,
        expression: 'Они обняли друг друга в конце',
        transcription: 'ðeɪ hʌgd iːʧ ˈʌðər ɪn ði ɛnd',
        translation: 'They hugged each other in the end',
      },
      {
        id: 19,
        expression: 'Она решила выйти за него замуж',
        transcription: 'ʃi dɪˈsaɪdɪd tə ˈmæri hɪm',
        translation: 'She decided to marry him',
      },
      {
        id: 20,
        expression: 'Она хочет стать переводчиком',
        transcription: 'ʃi wɒnts tə bɪˈkʌm ə trænsˈleɪtə',
        translation: 'She wants to become a translator',
      },
      {
        id: 21,
        expression: 'Сколько рыб ты поймал?',
        transcription: 'haʊ ˈmɛni fɪʃ dɪd jʊ kæʧ?',
        translation: 'How many fish did you catch?',
      },
      {
        id: 22,
        expression: 'Он не угадал правильный ответ',
        transcription: 'hi dɪdnt gɛs ðə kəˈrɛkt ˈɑːnsə',
        translation: "He didn't guess the correct answer",
      },
      {
        id: 23,
        expression: 'Он часто страдал от депрессии',
        transcription: 'hi ˈɒf(ə)n ˈsʌfəd frəm dɪˈprɛʃən',
        translation: 'He often suffered from depression',
      },
      {
        id: 24,
        expression: 'Та вещь принадлежит тебе?',
        transcription: 'dəz ðət θɪŋ bɪˈlɒŋ tə juː?',
        translation: 'Does that thing belong to you?',
      },
      {
        id: 25,
        expression: 'Они напечатали каталог',
        transcription: 'ðeɪ ˈprɪntɪd ə ˈkætəlɒg',
        translation: 'They printed a catalogue',
      },
      {
        id: 26,
        expression: 'Вода скоро закипит',
        transcription: 'ðə ˈwɔːtə wɪl bɔɪl suːn',
        translation: 'The water will boil soon',
      },
      {
        id: 27,
        expression: 'Она страдает от этой болезни',
        transcription: 'hi ˈsʌfəz frəm ðɪs dɪˈziːz',
        translation: 'He suffers from this disease',
      },
      {
        id: 28,
        expression: 'Мне нужно повесить эту картину',
        transcription: 'aɪ niːd tə hæŋ ðɪs ˈpɪkʧə',
        translation: 'I need to hang this picture',
      },
      {
        id: 29,
        expression: 'Это может вызвать серьезные проблемы в будущем',
        transcription: 'ɪt kən kɔːz ˈsɪərɪəs ˈprɒbləmz ɪn ðə ˈfjuːʧə',
        translation: 'It can cause serious problems in the future',
      },
      {
        id: 30,
        expression: 'Они ударили его очень сильно',
        transcription: 'ðeɪ hɪt ɪm ˈvɛri ˈbædli',
        translation: 'They hit him very badly',
      },
      {
        id: 31,
        expression: 'Ты меня хорошо слышишь сейчас?',
        transcription: 'dʊ jʊ hɪə mi wɛl naʊ?',
        translation: 'Do you hear me well now?',
      },
      {
        id: 32,
        expression: 'Это становится все более и более дорогим',
        transcription: 'ɪts bɪˈkʌmɪŋ mɔːr ənd mɔːr ɪksˈpɛnsɪv',
        translation: "It's becoming more and more expensive",
      },
      {
        id: 33,
        expression: 'Что принесло им больше денег?',
        transcription: 'wɒt brɔːt ðəm mɔː ˈmʌni?',
        translation: 'What brought them more money?',
      },
      {
        id: 34,
        expression: 'Ты всегда можешь рассчитывать на нас',
        transcription: 'jʊ kən ˈɔːlweɪz kaʊnt ɒn ʌs',
        translation: 'You can always count on us',
      },
      {
        id: 35,
        expression: 'Что принадлежит тебе?',
        transcription: 'wɒt bɪˈlɒŋz tə juː?',
        translation: 'What belongs to you?',
      },
      {
        id: 36,
        expression: 'Мне нужно распечатать этот документ',
        transcription: 'aɪ niːd tə prɪnt ðɪs ˈdɒkjʊmənt',
        translation: 'I need to print this document',
      },
      {
        id: 37,
        expression: 'Я рассматриваю разные возможности сейчас',
        transcription: 'aɪm kənˈsɪdərɪŋ ˈdɪfrənt ˌpɒsəˈbɪlɪtiz',
        translation: "I'm considering different possibilities",
      },
      {
        id: 38,
        expression: 'Он был действительно шокирован',
        transcription: 'hi wəz ˈrɪəli ʃɒkt',
        translation: 'He was really shocked',
      },
      {
        id: 39,
        expression: 'Он решил протестировать свои знания',
        transcription: 'hi dɪˈsaɪdɪd tə tɛst ɪz ˈnɒlɪʤ',
        translation: 'He decided to test his knowledge',
      },
      {
        id: 40,
        expression: 'Ты шутишь?',
        transcription: 'ɑː jʊ ˈʤəʊkɪŋ?',
        translation: 'Are you joking?',
      },
      {
        id: 41,
        expression: 'Воры были пойманы и отправлены в тюрьму',
        transcription: 'ðə θiːvz wə kɔːt ənd sɛnt tə ˈprɪzn',
        translation: 'The thieves were caught and sent to prison',
      },
      {
        id: 42,
        expression: 'Он преподает английский более десяти лет',
        transcription: 'hiːz biːn ˈtiːʧɪŋ ˈɪŋglɪʃ fər ˈəʊvə (mɔː ðæn) tɛn jɪəz',
        translation:
          "He's been teaching English for over (more than) ten years",
      },
      {
        id: 43,
        expression:
          'Я знаю, что мы должны адаптироваться к этим обстоятельствам',
        transcription: 'aɪ nəʊ ðət wi məst tʊ əˈdæpt tə ðiːz ˈsɜːkəmstənsɪz',
        translation: 'I know that we must to adapt to these circumstances',
      },
      {
        id: 44,
        expression: 'Вода кипит около двадцати минут',
        transcription: 'ðə ˈwɔːtə həz biːn ˈbɔɪlɪŋ fər əˈbaʊt ˈtwɛnti ˈmɪnɪts',
        translation: 'The water has been boiling for about twenty minutes',
      },
      {
        id: 45,
        expression: 'Мы с другом обсуждаем эту проблему более часа',
        transcription:
          'maɪ frɛnd ənd aɪ həv biːn dɪsˈkʌsɪŋ ðɪs ˈprɒbləm fər ˈəʊvə wʌn ˈaʊə',
        translation:
          'My friend and I have been discussing this problem for over one hour',
      },
      {
        id: 46,
        expression:
          'Он сказал, что всегда хотел сделать что-то полезное для других людей',
        transcription:
          'hi sɛd ðət hiːd ˈɔːlweɪz ˈwɒntɪd tə dʊ ˈsʌmθɪŋ ˈjuːsfʊl fər ˈʌðə ˈpiːpl',
        translation:
          "He said that he'd always wanted to do something useful for other people",
      },
      {
        id: 47,
        expression: 'Их новый альбом скоро будет выпущен',
        transcription: 'ðeə njuː ˈælbəm wɪl bi rɪˈliːst suːn',
        translation: 'Their new album will be released soon',
      },
      {
        id: 48,
        expression: 'Я еще не исправил эти ошибки',
        transcription: 'aɪ hævnt kəˈrɛktɪd ðiːz mɪsˈteɪks jɛt',
        translation: "I haven't corrected these mistakes yet",
      },
      {
        id: 49,
        expression: 'Слишком много времени тратится на это',
        transcription: 'tuː mʌʧ taɪm z spɛnt ɒn ɪt',
        translation: 'Too much time is spent on it',
      },
      {
        id: 50,
        expression: 'Она еще не ответила на мое сообщение',
        transcription: 'ʃi ˈhæznt rɪˈplaɪd maɪ ˈmɛsɪʤ jɛt',
        translation: "She hasn't replied my message yet",
      },
      {
        id: 51,
        expression: 'Я слышал это так много раз',
        transcription: 'aɪv hɜːd ɪt səʊ ˈmɛni taɪmz',
        translation: "I've heard it so many times",
      },
      {
        id: 52,
        expression: 'Я не думаю, что это принесет ему настоящее счастье',
        transcription: 'aɪ dəʊnt θɪŋk ðət ˈɪtl brɪŋ ɪm rɪəl ˈhæpɪnɪs',
        translation: "I don't think that it'll bring him real happiness",
      },
      {
        id: 53,
        expression: 'Мы будем обсуждать этот проект завтра с пяти до шести',
        transcription:
          'wiːl bi dɪsˈkʌsɪŋ ðɪs ˈprɒʤɛkt təˈmɒrəʊ frəm faɪv tə sɪks',
        translation:
          "We'll be discussing this project tomorrow from five to six",
      },
      {
        id: 54,
        expression: 'Я поздравил его со сдачей теста',
        transcription: 'aɪ kənˈgrætjʊleɪtɪd ɪm ɒn ˈpɑːsɪŋ ðə tɛst',
        translation: 'I congratulated him on passing the test',
      },
      {
        id: 55,
        expression: 'Он не видел ее в последнее время',
        transcription: 'hi ˈhæznt siːn hə ˈleɪtli',
        translation: "He hasn't seen her lately",
      },
      {
        id: 56,
        expression: 'Слишком много денег было потрачено на это',
        transcription: 'tuː mʌʧ ˈmʌni wəz spɛnt ɒn ɪt',
        translation: 'Too much money was spent on it',
      },
      {
        id: 57,
        expression: 'Слишком много денег тратится на это',
        transcription: 'tuː mʌʧ ˈmʌni z spɛnt ɒn ɪt',
        translation: 'Too much money is spent on it',
      },
      {
        id: 58,
        expression: 'Сколько денег было потрачено на это?',
        transcription: 'haʊ mʌʧ ˈmʌni wəz spɛnt ɒn ɪt?',
        translation: 'How much money was spent on it?',
      },
      {
        id: 59,
        expression: 'Было не так легко адаптироваться к тем условиям',
        transcription: 'ɪt wɒznt ˈiːzi tʊ əˈdæpt tə ðəʊz kənˈdɪʃənz',
        translation: "It wasn't easy to adapt to those conditions",
      },
      {
        id: 60,
        expression: 'Было слишком легко адаптироваться к этим изменениям',
        transcription: 'ɪt wəz tuː ˈiːzi tʊ əˈdæpt tə ðiːz ˈʧeɪnʤɪz',
        translation: 'It was too easy to adapt to these changes',
      },
      {
        id: 61,
        expression: 'Не легко адаптироваться к этим условиям',
        transcription: 'ɪt ˈɪznt ˈiːzi tʊ əˈdæpt tə ðiːz kənˈdɪʃənz',
        translation: "It isn't easy to adapt to these conditions",
      },
      {
        id: 62,
        expression: 'Кто-то стучит в дверь',
        transcription: 'ˈsʌmwʌn z ˈnɒkɪŋ ət ðə dɔː',
        translation: 'Someone is knocking at the door',
      },
      {
        id: 63,
        expression: 'Кто-то стучал в окно',
        transcription: 'ˈsʌmwʌn wəz ˈnɒkɪŋ ət ðə ˈwɪndəʊ',
        translation: 'Someone was knocking at the window',
      },
      {
        id: 64,
        expression: 'Кто стучит в дверь сейчас?',
        transcription: 'huː z ˈnɒkɪŋ ət ðə dɔː naʊ?',
        translation: 'Who is knocking at the door now?',
      },
      {
        id: 65,
        expression: 'Дай это письмо ему!',
        transcription: 'gɪv ðɪs ˈlɛtə tə hɪm!',
        translation: 'Give this letter to him!',
      },
      {
        id: 66,
        expression: 'Я дам это письмо ему',
        transcription: 'aɪl gɪv ðɪs ˈlɛtə tə hɪm',
        translation: "I'll give this letter to him",
      },
      {
        id: 67,
        expression: 'Ты можешь дать эту вещь мне?',
        transcription: 'kən jʊ gɪv ðɪs θɪŋ tə miː?',
        translation: 'Can you give this thing to me?',
      },
      {
        id: 68,
        expression: 'Он всегда хотел быть музыкантом',
        transcription: 'hiːz ˈɔːlweɪz ˈwɒntɪd tə bi ə mju(ː)ˈzɪʃən',
        translation: "He's always wanted to be a musician",
      },
      {
        id: 69,
        expression: 'Он никогда не хотел стать учителем',
        transcription: 'hiːz ˈnɛvə ˈwɒntɪd tə bɪˈkʌm ə ˈtiːʧə',
        translation: "He's never wanted to become a teacher",
      },
      {
        id: 70,
        expression: 'Ты когда-нибудь хотел стать музыкантом?',
        transcription: 'həv jʊ ˈɛvə ˈwɒntɪd tə bɪˈkʌm ə mju(ː)ˈzɪʃən?',
        translation: 'Have you ever wanted to become a musician?',
      },
      {
        id: 71,
        expression: 'Я решил не выбрасывать это',
        transcription: 'aɪ dɪˈsaɪdɪd nɒt tə θrəʊ ɪt əˈweɪ',
        translation: 'I decided not to throw it away',
      },
      {
        id: 72,
        expression: 'Я решил сделать это тем не менее',
        transcription: 'aɪ dɪˈsaɪdɪd tə dʊ ɪt ˈɛnɪweɪ',
        translation: 'I decided to do it anyway',
      },
      {
        id: 73,
        expression: 'Я решил больше не делать этого',
        transcription: 'aɪ dɪˈsaɪdɪd nɒt tə dʊ ɪt ˌɛniˈmɔː',
        translation: 'I decided not to do it anymore',
      },
      {
        id: 74,
        expression: 'Я думаю, эта новость шокирует его',
        transcription: 'aɪ θɪŋk ðɪs njuːz wɪl ʃɒk hɪm',
        translation: 'I think this news will shock him',
      },
      {
        id: 75,
        expression: 'Я боюсь, что эта новость шокирует его',
        transcription: 'aɪm əˈfreɪd ðət ðɪs njuːz wɪl ʃɒk hɪm',
        translation: "I'm afraid that this news will shock him",
      },
      {
        id: 76,
        expression: 'Я думаю, эта новость напугает его',
        transcription: 'aɪ θɪŋk ðɪs njuːz wɪl ˈfraɪtn hɪm',
        translation: 'I think this news will frighten him',
      },
      {
        id: 77,
        expression: 'Он предложил пойти в кино',
        transcription: 'hi səˈʤɛstɪd ˈgəʊɪŋ tə ðə ˈsɪnəmə',
        translation: 'He suggested going to the cinema',
      },
      {
        id: 78,
        expression: 'Он предложил посмотреть фильм',
        transcription: 'hi səˈʤɛstɪd ˈwɒʧɪŋ ə ˈmuːvi',
        translation: 'He suggested watching a movie',
      },
      {
        id: 79,
        expression: 'Он решил пойти в кино',
        transcription: 'hi dɪˈsaɪdɪd tə gəʊ tə ðə ˈsɪnəmə',
        translation: 'He decided to go to the cinema',
      },
      {
        id: 80,
        expression: 'Как долго ты преподаешь английский?',
        transcription: 'haʊ lɒŋ həv jʊ biːn ˈtiːʧɪŋ ˈɪŋglɪʃ?',
        translation: 'How long have you been teaching English?',
      },
      {
        id: 81,
        expression: 'Как долго ты изучаешь английский там?',
        transcription: 'haʊ lɒŋ həv jʊ biːn ˈlɜːnɪŋ ˈɪŋglɪʃ ðeə?',
        translation: 'How long have you been learning English there?',
      },
      {
        id: 82,
        expression: 'Я изучаю английский около двух лет',
        transcription: 'aɪv biːn ˈlɜːnɪŋ ˈɪŋglɪʃ fər əˈbaʊt tuː jɪəz',
        translation: "I've been learning English for about two years",
      },
      {
        id: 83,
        expression: 'Кто стучит в дверь?',
        transcription: 'huː z ˈnɒkɪŋ ət ðə dɔː?',
        translation: 'Who is knocking at the door?',
      },
      {
        id: 84,
        expression: 'Я не знаю, кто стучал в дверь',
        transcription: 'aɪ dəʊnt nəʊ huː wəz ˈnɒkɪŋ ət ðə dɔː',
        translation: "I don't know who was knocking at the door",
      },
      {
        id: 85,
        expression: 'Кто-то стучит в мою дверь',
        transcription: 'ˈsʌmwʌn z ˈnɒkɪŋ ət maɪ dɔː',
        translation: 'Someone is knocking at my door',
      },
      {
        id: 86,
        expression: 'Я видел его недавно',
        transcription: 'aɪv siːn ɪm ˈriːsntli',
        translation: "I've seen him recently",
      },
      {
        id: 87,
        expression: 'Она сказала нам, что он уже все решил',
        transcription: 'ʃi təʊld əs ðət hiːd ɔːlˈrɛdi dɪˈsaɪdɪd ˈɛvrɪθɪŋ',
        translation: "She told us that he'd already decided everything",
      },
      {
        id: 88,
        expression: 'Мне кажется, мы не можем это изменить',
        transcription: 'ɪt siːmz tə mi wi kɑːnt ʧeɪnʤ ɪt',
        translation: "It seems to me we can't change it",
      },
      {
        id: 89,
        expression: 'Она повесила плакат на стену',
        transcription: 'ʃi hʌŋ ðə ˈpəʊstər ɒn ðə wɔːl',
        translation: 'She hung the poster on the wall',
      },
      {
        id: 90,
        expression: 'Ты можешь выбросить это, если тебе это не нужно',
        transcription: 'jʊ kən θrəʊ ɪt əˈweɪ ɪf jʊ dəʊnt niːd ɪt',
        translation: "You can throw it away if you don't need it",
      },
      {
        id: 91,
        expression: 'Я хотел бы поздравить тебя со сдачей экзамена',
        transcription: 'aɪd laɪk tə kənˈgrætjʊleɪt jʊ ɒn ˈpɑːsɪŋ ði ɪgˈzæm',
        translation: "I'd like to congratulate you on passing the exam",
      },
      {
        id: 92,
        expression:
          'Он часто повторяет, что усердная работа привела его к успеху',
        transcription: 'hi ˈɒf(ə)n rɪˈpiːts ðət hɑːd wɜːk lɛd ɪm tə səkˈsɛs',
        translation: 'He often repeats that hard work led him to success',
      },
      {
        id: 93,
        expression: 'Ему был дан еще один шанс',
        transcription: 'hi wəz ˈgɪvn wʌn mɔː ʧɑːns',
        translation: 'He was given one more chance',
      },
      {
        id: 94,
        expression: 'Лестница ведет на крышу',
        transcription: 'ðə steəz liːdz tə ðə ruːf',
        translation: 'The stairs leads to the roof',
      },
      {
        id: 95,
        expression: 'Все ошибки были исправлены',
        transcription: 'ɔːl ðə mɪsˈteɪks wə kəˈrɛktɪd',
        translation: 'All the mistakes were corrected',
      },
      {
        id: 96,
        expression: 'Я предлагаю пойти в парк сейчас',
        transcription: 'aɪ səˈʤɛst ˈgəʊɪŋ tə ðə pɑːk naʊ',
        translation: 'I suggest going to the park now',
      },
      {
        id: 97,
        expression: 'Я всегда считал это неважным',
        transcription: 'aɪv ˈɔːlweɪz kənˈsɪdəd ɪt ˌʌnɪmˈpɒtənt',
        translation: "I've always considered it unimportant",
      },
      {
        id: 98,
        expression: 'Я решу что делать позже',
        transcription: 'aɪl dɪˈsaɪd tə dʊ ˈleɪtə',
        translation: "I'll decide to do later",
      },
      {
        id: 99,
        expression: 'Она была сбита машиной',
        transcription: 'ʃi wəz hɪt baɪ ə kɑː',
        translation: 'She was hit by a car',
      },
      {
        id: 100,
        expression: 'Что обычно привлекает наше внимание?',
        transcription: 'wɒt ˈjuːʒʊəli əˈtrækts ˈaʊər əˈtɛnʃ(ə)n?',
        translation: 'What usually attracts our attention?',
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Закрепление пройденного материала</h3>
</div>`,
    },
  ],
];
