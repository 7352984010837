export const contentLesson126 = [
  [
    {
      title: 'Урок 126. Конструкция "to be going"',
    },
    [
      {
        id: 1,
        expression: 'Я собираюсь прочесть книгу',
        transcription: 'aɪm ˈgəʊɪŋ tə riːd ə bʊk / aɪ ˈgɒnə riːd ə bʊk',
        translation: "I'm going to read a book / I gonna read a book",
      },
      {
        id: 2,
        expression: 'Он собирается играть в футбол',
        transcription: 'hiːz ˈgəʊɪŋ tə pleɪ ˈfʊtbɔːl',
        translation: "He's going to play football",
      },
      {
        id: 3,
        expression: 'Я хочу проверить это',
        transcription: 'aɪ wɒnt tə ʧɛk ɪt / aɪ ˈwɒnə ʧɛk ɪt',
        translation: 'I want to check it / I wanna check it',
      },
      {
        id: 4,
        expression: 'Мы собираемся позвонить тебе',
        transcription: 'wɪə ˈgəʊɪŋ tə kɔːl juː',
        translation: "We're going to call you",
      },
      {
        id: 5,
        expression: 'Что я собираюсь делать?',
        transcription: 'wɒt əm aɪ ˈgəʊɪŋ tə duː?',
        translation: 'What am I going to do?',
      },
      {
        id: 6,
        expression: 'Где он собирается есть?',
        transcription: 'weə z hi ˈgəʊɪŋ tʊ iːt?',
        translation: 'Where is he going to eat?',
      },
      {
        id: 7,
        expression: 'Что ты собираешься сделать?',
        transcription: 'wɒt ə jʊ ˈgəʊɪŋ tə duː?',
        translation: 'What are you going to do?',
      },
      {
        id: 8,
        expression: 'Он собирался прийти к нам',
        transcription: 'hi wəz ˈgəʊɪŋ tə kʌm tʊ ʌs',
        translation: 'He was going to come to us',
      },
      {
        id: 9,
        expression: 'Они собирались удвоить свои продажи',
        transcription: 'ðeɪ wə ˈgəʊɪŋ tə ˈdʌbl ðeə seɪlz',
        translation: 'They were going to double their sales',
      },
      {
        id: 10,
        expression: 'Что он собирался сказать?',
        transcription: 'wɒt wəz hi ˈgəʊɪŋ tə seɪ?',
        translation: 'What was he going to say?',
      },
      {
        id: 11,
        expression: 'Что ты собирался сделать?',
        transcription: 'wɒt wə jəʊ ˈgəʊɪŋ tə duː?',
        translation: 'What were yo going to do?',
      },
      {
        id: 12,
        expression: 'Я не собираюсь этого делать',
        transcription: 'aɪm nɒt ˈgəʊɪŋ tə dʊ ɪt',
        translation: "I'm not going to do it",
      },
      {
        id: 13,
        expression: 'Я собираюсь заплатить за это заранее',
        transcription: 'aɪm ˈgəʊɪŋ tə peɪ fər ɪt bɪˈfɔːhænd',
        translation: "I'm going to pay for it beforehand",
      },
      {
        id: 14,
        expression: 'Он собирается искать там новую работу',
        transcription: 'hiːz ˈgəʊɪŋ tə lʊk fər ə njuː ʤɒb ðeə',
        translation: "He's going to look for a new job there",
      },
      {
        id: 15,
        expression: 'Он собирается бросить пить',
        transcription: 'hiːz ˈgəʊɪŋ tə gɪv ʌp ˈdrɪŋkɪŋ',
        translation: "He's going to give up drinking",
      },
      {
        id: 16,
        expression: 'Я собираюсь провести там свой отпуск',
        transcription: 'aɪm ˈgəʊɪŋ tə spɛnd maɪ ˈhɒlədeɪ ðeə',
        translation: "I'm going to spend my holiday there",
      },
      {
        id: 17,
        expression: 'Я собираюсь сделать свою домашнюю работу сейчас',
        transcription: 'aɪm ˈgəʊɪŋ tə dʊ maɪ ˈhəʊmˌwɜːk naʊ',
        translation: "I'm going to do my homework now",
      },
      {
        id: 18,
        expression: 'Я собирался тебе позвонить',
        transcription: 'aɪ wəz ˈgəʊɪŋ tə gɪv jʊ ə kɔːl',
        translation: 'I was going to give you a call',
      },
      {
        id: 19,
        expression: 'Я собираюсь забронировать эти билеты заранее',
        transcription: 'aɪm ˈgəʊɪŋ tə bʊk ðiːz ˈtɪkɪts ɪn ədˈvɑːns',
        translation: "I'm going to book these tickets in advance",
      },
      {
        id: 20,
        expression: 'Я собираюсь приехать на их свадьбу',
        transcription: 'aɪm ˈgəʊɪŋ tə kʌm tə ðeə ˈwɛdɪŋ',
        translation: "I'm going to come to their wedding",
      },
      {
        id: 21,
        expression: 'Он собирается жениться на ней',
        transcription: 'hiːz ˈgəʊɪŋ tə ˈmæri hɜː',
        translation: "He's going to marry her",
      },
      {
        id: 22,
        expression: 'Она собирается бросить свою работу',
        transcription: 'ʃiːz ˈgəʊɪŋ tə kwɪt hə ʤɒb',
        translation: "She's going to quit her job",
      },
      {
        id: 23,
        expression: 'Я собираюсь быть там в ноябре',
        transcription: 'aɪm ˈgəʊɪŋ tə bi ðeər ɪn nəʊˈvɛmbə',
        translation: "I'm going to be there in November",
      },
      {
        id: 24,
        expression:
          'Он собирается работать в Соединенном Королевстве этим летом',
        transcription: 'hiːz ˈgəʊɪŋ tə wɜːk ɪn ðə jʊˈnaɪtɪd ˈkɪŋdəm ðɪs ˈsʌmə',
        translation: "He's going to work in the United Kingdom this summer",
      },
      {
        id: 25,
        expression: 'Я собираюсь рассказать ему все',
        transcription: 'aɪm ˈgəʊɪŋ tə tɛl ɪm ˈɛvrɪθɪŋ',
        translation: "I'm going to tell him everything",
      },
      {
        id: 26,
        expression: 'Я собираюсь сделать это заранее',
        transcription: 'aɪm ˈgəʊɪŋ tə dʊ ɪt bɪˈfɔːhænd',
        translation: "I'm going to do it beforehand",
      },
      {
        id: 27,
        expression: 'Я собираюсь увидеть ее сегодня вечером',
        transcription: 'aɪm ˈgəʊɪŋ tə siː hə təˈnaɪt',
        translation: "I'm going to see her tonight",
      },
      {
        id: 28,
        expression: 'Я не собирался поступать в этот университет',
        transcription: 'aɪ wɒznt ˈgəʊɪŋ tʊ ˈɛntə ðɪs ˌjuːnɪˈvɜːsɪti',
        translation: "I wasn't going to enter this university",
      },
      {
        id: 29,
        expression: 'Она собирается приехать к нам',
        transcription: 'ʃiːz ˈgəʊɪŋ tə kʌm tʊ ʌs',
        translation: "She's going to come to us",
      },
      {
        id: 30,
        expression: 'Я собираюсь поблагодарить его за его помощь',
        transcription: 'aɪm ˈgəʊɪŋ tə θæŋk ɪm fə hɪz hɛlp',
        translation: "I'm going to thank him for his help",
      },
      {
        id: 31,
        expression: 'Что ты собираешься делать сегодня вечером?',
        transcription: 'wɒt ə jʊ ˈgəʊɪŋ tə dʊ təˈnaɪt?',
        translation: 'What are you going to do tonight?',
      },
      {
        id: 32,
        expression: 'Он собирался получить ту стипендию',
        transcription: 'hi wəz ˈgəʊɪŋ tə gɛt ðət ˈskɒləʃɪp',
        translation: 'He was going to get that scholarship',
      },
      {
        id: 33,
        expression: 'Я собираюсь заработать эти деньги',
        transcription: 'aɪm ˈgəʊɪŋ tʊ ɜːn ðɪs ˈmʌni',
        translation: "I'm going to earn this money",
      },
      {
        id: 34,
        expression: 'Он собирается бросить свою работу',
        transcription: 'hiːz ˈgəʊɪŋ tə kwɪt ɪz ʤɒb',
        translation: "He's going to quit his job",
      },
      {
        id: 35,
        expression: 'Я собираюсь поговорить с ним',
        transcription: 'aɪm ˈgəʊɪŋ tə tɔːk tə hɪm',
        translation: "I'm going to talk to him",
      },
      {
        id: 36,
        expression: 'Я собираюсь забронировать этот номер заранее',
        transcription: 'aɪm ˈgəʊɪŋ tə bʊk ðɪs ruːm ɪn ədˈvɑːns',
        translation: "I'm going to book this room in advance",
      },
      {
        id: 37,
        expression: 'Я собираюсь закончить свою работу скоро',
        transcription: 'aɪm ˈgəʊɪŋ tə ˈfɪnɪʃ maɪ wɜːk suːn',
        translation: "I'm going to finish my work soon",
      },
      {
        id: 38,
        expression: 'Я собираюсь тренироваться гораздо интенсивнее',
        transcription: 'aɪm ˈgəʊɪŋ tə treɪn mʌʧ mɔːr ɪnˈtɛnsɪvli',
        translation: "I'm going to train much more intensively",
      },
      {
        id: 39,
        expression: 'Она собирается быть учительницей',
        transcription: 'ʃiːz ˈgəʊɪŋ tə bi ə ˈtiːʧə',
        translation: "She's going to be a teacher",
      },
      {
        id: 40,
        expression: 'Я собирался позвонить тебе',
        transcription: 'aɪ wəz ˈgəʊɪŋ tə kɔːl juː',
        translation: 'I was going to call you',
      },
      {
        id: 41,
        expression: 'Я собираюсь сменить свою работу',
        transcription: 'aɪm ˈgəʊɪŋ tə ʧeɪnʤ maɪ ʤɒb',
        translation: "I'm going to change my job",
      },
      {
        id: 42,
        expression: 'Я собираюсь путешествовать на самолете',
        transcription: 'aɪm ˈgəʊɪŋ tə ˈtrævl baɪ pleɪn',
        translation: "I'm going to travel by plane",
      },
      {
        id: 43,
        expression: 'Он собирается получить эту стипендию',
        transcription: 'hiːz ˈgəʊɪŋ tə gɛt ðɪs ˈskɒləʃɪp',
        translation: "He's going to get this scholarship",
      },
      {
        id: 44,
        expression: 'Он собирается быть бизнесменом',
        transcription: 'hiːz ˈgəʊɪŋ tə bi ə ˈbɪznɪsmən',
        translation: "He's going to be a businessman",
      },
      {
        id: 45,
        expression: 'Я собираюсь навестить одного из моих друзей завтра',
        transcription: 'aɪm ˈgəʊɪŋ tə ˈvɪzɪt wʌn əv maɪ frɛndz təˈmɒrəʊ',
        translation: "I'm going to visit one of my friends tomorrow",
      },
      {
        id: 46,
        expression: 'Я не собираюсь тратить всю свою жизнь на это',
        transcription: 'aɪm nɒt ˈgəʊɪŋ tə spɛnd ɔːl maɪ laɪf ɒn ɪt',
        translation: "I'm not going to spend all my life on it",
      },
      {
        id: 47,
        expression: 'Я собираюсь сделать это завтра',
        transcription: 'aɪm ˈgəʊɪŋ tə dʊ ɪt təˈmɒrəʊ',
        translation: "I'm going to do it tomorrow",
      },
      {
        id: 48,
        expression: 'Я собираюсь потратить больше времени на английский',
        transcription: 'aɪm ˈgəʊɪŋ tə spɛnd mɔː taɪm ɒn ˈɪŋglɪʃ',
        translation: "I'm going to spend more time on English",
      },
      {
        id: 49,
        expression: 'Я не собираюсь тратить время впустую здесь',
        transcription: 'aɪm nɒt ˈgəʊɪŋ tə weɪst taɪm hɪə',
        translation: "I'm not going to waste time here",
      },
      {
        id: 50,
        expression: 'Он собирается бросить курить',
        transcription: 'hiːz ˈgəʊɪŋ tə stɒp ˈsməʊkɪŋ',
        translation: "He's going to stop smoking",
      },
      {
        id: 51,
        expression: 'Она скоро собирается переехать в Соединенные Штаты',
        transcription: 'ʃi z ˈgəʊɪŋ tə muːv tə ðə jʊˈnaɪtɪd steɪts suːn',
        translation: 'She is going to move to the United States soon',
      },
      {
        id: 52,
        expression: 'Она собирается работать официанткой',
        transcription: 'ʃiːz ˈgəʊɪŋ tə wɜːk əz ə ˈweɪtrɪs',
        translation: "She's going to work as a waitress",
      },
      {
        id: 53,
        expression: 'Что она собирается делать в случае, если это произойдет?',
        transcription: 'wɒt s ʃi ˈgəʊɪŋ tə dʊ ɪn keɪs ɪt ˈhæpənz?',
        translation: 'What is she going to do in case it happens?',
      },
      {
        id: 54,
        expression: 'Он собирается работать официантом',
        transcription: 'hiːz ˈgəʊɪŋ tə wɜːk əz ə ˈweɪtə',
        translation: "He's going to work as a waiter",
      },
      {
        id: 55,
        expression: 'Как ты собираешься получить эту информацию?',
        transcription: 'haʊ ə jʊ ˈgəʊɪŋ tə gɛt ðɪs ˌɪnfəˈmeɪʃən?',
        translation: 'How are you going to get this information?',
      },
      {
        id: 56,
        expression: 'Что ты собираешься делать в выходные?',
        transcription: 'wɒt ə jʊ ˈgəʊɪŋ tə dʊ ət ðə ˈwiːkˈɛnd?',
        translation: 'What are you going to do at the weekend?',
      },
      {
        id: 57,
        expression:
          'Я собираюсь связаться с ней, когда у меня будет свободное время',
        transcription: 'aɪm ˈgəʊɪŋ tə ˈkɒntækt hə wɛn aɪ həv friː taɪm',
        translation: "I'm going to contact her when I have free time",
      },
      {
        id: 58,
        expression: 'Я собираюсь выяснить, где она живет',
        transcription: 'aɪm ˈgəʊɪŋ tə faɪnd aʊt weə ʃi lɪvz',
        translation: "I'm going to find out where she lives",
      },
      {
        id: 59,
        expression: 'Что ты собираешься делать в этой ситуации?',
        transcription: 'wɒt ə jʊ ˈgəʊɪŋ tə dʊ ɪn ðɪs ˌsɪtjʊˈeɪʃən?',
        translation: 'What are you going to do in this situation?',
      },
      {
        id: 60,
        expression: 'Я собираюсь остаться там до 10',
        transcription: 'aɪm ˈgəʊɪŋ tə steɪ ðeə tɪl 10',
        translation: "I'm going to stay there till 10",
      },
      {
        id: 61,
        expression: 'Что ты собираешься делать, если эта вещь случится?',
        transcription: 'wɒt ə jʊ ˈgəʊɪŋ tə dʊ ɪf ðɪs θɪŋ ˈhæpənz?',
        translation: 'What are you going to do if this thing happens?',
      },
      {
        id: 62,
        expression: 'Что ты собирался спросить его?',
        transcription: 'wɒt wə jʊ ˈgəʊɪŋ tʊ ɑːsk hɪm?',
        translation: 'What were you going to ask him?',
      },
      {
        id: 63,
        expression: 'Где она собирается учиться?',
        transcription: 'weə z ʃi ˈgəʊɪŋ tə ˈstʌdi?',
        translation: 'Where is she going to study?',
      },
      {
        id: 64,
        expression: 'Когда ты собираешься присоединиться ко мне?',
        transcription: 'wɛn ə jʊ ˈgəʊɪŋ tə ʤɔɪn miː?',
        translation: 'When are you going to join me?',
      },
      {
        id: 65,
        expression: 'Что ты собираешься делать в отпуске?',
        transcription: 'wɒt ə jʊ ˈgəʊɪŋ tə dʊ ɒn ˈhɒlədeɪ?',
        translation: 'What are you going to do on holiday?',
      },
      {
        id: 66,
        expression: 'Ты собираешься путешествовать на самолете?',
        transcription: 'ɑː jʊ ˈgəʊɪŋ tə ˈtrævl baɪ pleɪn?',
        translation: 'Are you going to travel by plane?',
      },
      {
        id: 67,
        expression: 'Я думаю, она сейчас упадет в обморок',
        transcription: 'aɪ θɪŋk ʃiːz ˈgəʊɪŋ tə feɪnt naʊ',
        translation: "I think she's going to faint now",
      },
      {
        id: 68,
        expression: 'Мне кажется, она сейчас упадет в обморок',
        transcription: 'ɪt siːmz tə mi ʃiːz ˈgəʊɪŋ tə feɪnt naʊ',
        translation: "It seems to me she's going to faint now",
      },
      {
        id: 69,
        expression: 'Я думаю, она собирается это сделать',
        transcription: 'aɪ θɪŋk ʃiːz ˈgəʊɪŋ tə dʊ ɪt',
        translation: "I think she's going to do it",
      },
      {
        id: 70,
        expression: 'Я собирался представить его тебе',
        transcription: 'aɪ wəz ˈgəʊɪŋ tʊ ˌɪntrəˈdjuːs ɪm tə juː',
        translation: 'I was going to introduce him to you',
      },
      {
        id: 71,
        expression: 'Я собираюсь представить ее тебе',
        transcription: 'aɪm ˈgəʊɪŋ tʊ ˌɪntrəˈdjuːs hə tə juː',
        translation: "I'm going to introduce her to you",
      },
      {
        id: 72,
        expression: 'Я не собираюсь рассказывать тебе это',
        transcription: 'aɪm nɒt ˈgəʊɪŋ tə tɛl jʊ ɪt',
        translation: "I'm not going to tell you it",
      },
      {
        id: 73,
        expression: 'Я собираюсь выяснить, что он знает',
        transcription: 'aɪm ˈgəʊɪŋ tə faɪnd aʊt wɒt hi nəʊz',
        translation: "I'm going to find out what he knows",
      },
      {
        id: 74,
        expression: 'Я собираюсь выяснить, что он сделал',
        transcription: 'aɪm ˈgəʊɪŋ tə faɪnd aʊt wɒt hi dɪd',
        translation: "I'm going to find out what he did",
      },
      {
        id: 75,
        expression: 'Я собираюсь выяснить, что с ним случилось',
        transcription: 'aɪm ˈgəʊɪŋ tə faɪnd aʊt wɒt ˈhæpənd tə hɪm',
        translation: "I'm going to find out what happened to him",
      },
      {
        id: 76,
        expression: 'Что ты собираешься мне рассказать?',
        transcription: 'wɒt ə jʊ ˈgəʊɪŋ tə tɛl miː?',
        translation: 'What are you going to tell me?',
      },
      {
        id: 77,
        expression: 'Что ты собираешься с этим делать?',
        transcription: 'wɒt ə jʊ ˈgəʊɪŋ tə dʊ wɪð ɪt?',
        translation: 'What are you going to do with it?',
      },
      {
        id: 78,
        expression: 'Когда ты собирался мне это рассказать?',
        transcription: 'wɛn wə jʊ ˈgəʊɪŋ tə tɛl mi ɪt?',
        translation: 'When were you going to tell me it?',
      },
      {
        id: 79,
        expression: 'Он говорит, что собирается бросить курить',
        transcription: 'hi sɛz ðət hiːz ˈgəʊɪŋ tə gɪv ʌp ˈsməʊkɪŋ',
        translation: "He says that he's going to give up smoking",
      },
      {
        id: 80,
        expression: 'Он говорит, что собирается бросить пить алкоголь',
        transcription: 'hi sɛz ðət hiːz ˈgəʊɪŋ tə gɪv ʌp ˈdrɪŋkɪŋ ˈælkəhɒl',
        translation: "He says that he's going to give up drinking alcohol",
      },
      {
        id: 81,
        expression: 'Он говорит, что хочет бросить курить',
        transcription: 'hi sɛz ðət hi wɒnts tə gɪv ʌp ˈsməʊkɪŋ',
        translation: 'He says that he wants to give up smoking',
      },
      {
        id: 82,
        expression: 'Я собираюсь сделать это, когда тебя не будет',
        transcription: 'aɪm ˈgəʊɪŋ tə dʊ ɪt wɛn jʊər aʊt',
        translation: "I'm going to do it when you're out",
      },
      {
        id: 83,
        expression: 'Я постараюсь сделать это, когда тебя не будет',
        transcription: 'aɪl traɪ tə dʊ ɪt wɛn jʊər aʊt',
        translation: "I'll try to do it when you're out",
      },
      {
        id: 84,
        expression: 'Я сделаю это, когда тебя не будет',
        transcription: 'aɪl dʊ ɪt wɛn jʊər aʊt',
        translation: "I'll do it when you're out",
      },
      {
        id: 85,
        expression: 'Что ты собираешься делать вместо этого?',
        transcription: 'wɒt ə jʊ ˈgəʊɪŋ tə dʊ ɪnˈstɛd (əv ɪt)?',
        translation: 'What are you going to do instead (of it)?',
      },
      {
        id: 86,
        expression: 'Что он собирается делать вместо этого?',
        transcription: 'wɒt s hi ˈgəʊɪŋ tə dʊ ɪnˈstɛd?',
        translation: 'What is he going to do instead?',
      },
      {
        id: 87,
        expression: 'Что ты сделаешь вместо этого?',
        transcription: 'wɒt wɪl jʊ dʊ ɪnˈstɛd?',
        translation: 'What will you do instead?',
      },
      {
        id: 88,
        expression: 'Я собираюсь сделать это, пока ты будешь в отъезде',
        transcription: 'aɪm ˈgəʊɪŋ tə dʊ ɪt waɪl jʊər əˈweɪ',
        translation: "I'm going to do it while you're away",
      },
      {
        id: 89,
        expression: 'Я собираюсь сделать это, когда ты будешь в отъезде',
        transcription: 'aɪm ˈgəʊɪŋ tə dʊ ɪt wɛn jʊər əˈweɪ',
        translation: "I'm going to do it when you're away",
      },
      {
        id: 90,
        expression: 'Я сделаю это, пока бы будешь в отъезде',
        transcription: 'aɪl dʊ ɪt waɪl jʊər əˈweɪ',
        translation: "I'll do it while you're away",
      },
      {
        id: 91,
        expression:
          'Я собираюсь взять с собой зонт на случай, если пойдет дождь',
        transcription: 'aɪm ˈgəʊɪŋ tə teɪk ən ʌmˈbrɛlə wɪð mi ɪn keɪs ɪt reɪnz',
        translation: "I'm going to take an umbrella with me in case it rains",
      },
      {
        id: 92,
        expression:
          'Я собираюсь взять теплое пальто с собой на случай, если пойдет снег',
        transcription: 'aɪm ˈgəʊɪŋ tə teɪk ə wɔːm kəʊt wɪð mi ɪn keɪs ɪt snəʊz',
        translation: "I'm going to take a warm coat with me in case it snows",
      },
      {
        id: 93,
        expression: 'Я не собираюсь брать это с собой',
        transcription: 'aɪm nɒt ˈgəʊɪŋ tə teɪk ɪt wɪð miː',
        translation: "I'm not going to take it with me",
      },
      {
        id: 94,
        expression: 'Я собираюсь связаться с ним чуть позже',
        transcription: 'aɪm ˈgəʊɪŋ tə ˈkɒntækt ɪm ə bɪt ˈleɪtə',
        translation: "I'm going to contact him a bit later",
      },
      {
        id: 95,
        expression: 'Я собирался связаться с тобой позже',
        transcription: 'aɪ wəz ˈgəʊɪŋ tə ˈkɒntækt jʊ ˈleɪtə',
        translation: 'I was going to contact you later',
      },
      {
        id: 96,
        expression: 'Я собираюсь спросить его чуть позже',
        transcription: 'aɪm ˈgəʊɪŋ tʊ ɑːsk ɪm ə bɪt ˈleɪtə',
        translation: "I'm going to ask him a bit later",
      },
      {
        id: 97,
        expression: 'Он говорит, что он собирается бросить пить',
        transcription: 'hi sɛz ðət hiːz ˈgəʊɪŋ tə gɪv ʌp ˈdrɪŋkɪŋ',
        translation: "He says that he's going to give up drinking",
      },
      {
        id: 98,
        expression: 'Он собирается продать свой старый мотоцикл',
        transcription: 'hiːz ˈgəʊɪŋ tə sɛl ɪz əʊld ˈməʊtəˈsaɪkl',
        translation: "He's going to sell his old motorcycle",
      },
      {
        id: 99,
        expression: 'Я не собираюсь продолжать делать это',
        transcription: 'aɪm nɒt ˈgəʊɪŋ tə kənˈtɪnju(ː) ˈdu(ː)ɪŋ ɪt',
        translation: "I'm not going to continue doing it",
      },
      {
        id: 100,
        expression: 'Что ты собираешься делать на этих выходных?',
        transcription: 'wɒt ə jʊ ˈgəʊɪŋ tə dʊ ðɪs ˈwiːkˈɛnd?',
        translation: 'What are you going to do this weekend?',
      },
      {
        id: 101,
        expression: 'Что ты собираешься делать сейчас?',
        transcription: 'wɒt ə jʊ ˈgəʊɪŋ tə dʊ naʊ?',
        translation: 'What are you going to do now?',
      },
      {
        id: 102,
        expression: 'Скоро будет дождь',
        transcription: 'ɪts ˈgəʊɪŋ tə reɪn suːn',
        translation: "It's going to rain soon",
      },
      {
        id: 103,
        expression: 'Где ты собираешься жить?',
        transcription: 'weər ə jʊ ˈgəʊɪŋ tə lɪv?',
        translation: 'Where are you going to live?',
      },
      {
        id: 104,
        expression: 'Когда ты собираешься зарегистрироваться?',
        transcription: 'wɛn ə jʊ ˈgəʊɪŋ tə ˈrɛʤɪstə?',
        translation: 'When are you going to register?',
      },
      {
        id: 105,
        expression: 'Как ты собираешься ответить на этот вопрос?',
        transcription: 'haʊ ə jʊ ˈgəʊɪŋ tʊ ˈɑːnsə ðɪs ˈkwɛsʧən?',
        translation: 'How are you going to answer this question?',
      },
      {
        id: 106,
        expression: 'Что ты собираешься делать вместо этого?',
        transcription: 'wɒt ə jʊ ˈgəʊɪŋ tə dʊ ɪnˈstɛd?',
        translation: 'What are you going to do instead?',
      },
      {
        id: 107,
        expression: 'Я собираюсь это выяснить',
        transcription: 'aɪm ˈgəʊɪŋ tə faɪnd ɪt aʊt',
        translation: "I'm going to find it out",
      },
      {
        id: 108,
        expression: 'Я собираюсь быть там до 8',
        transcription: 'aɪm ˈgəʊɪŋ tə bi ðeə tɪl 8',
        translation: "I'm going to be there till 8",
      },
      {
        id: 109,
        expression: 'Кто собирается прийти?',
        transcription: 'huː z ˈgəʊɪŋ tə kʌm?',
        translation: 'Who is going to come?',
      },
      {
        id: 110,
        expression: 'Я думаю, будет дождь',
        transcription: 'aɪ θɪŋk ɪts ˈgəʊɪŋ tə reɪn',
        translation: "I think it's going to rain",
      },
      {
        id: 111,
        expression: 'Ты собираешься присоединиться к нам?',
        transcription: 'ɑː jʊ ˈgəʊɪŋ tə ʤɔɪn ʌs?',
        translation: 'Are you going to join us?',
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>to be going to</h3>
<p>- собираться что-то делать</p>
<p>Формула образования утвердительного предложения:</p>
<p><span>подлежащее + форма глагола to be + going to + глагол</span></p>
<p>I'm going to have a rest - Я собираюсь отдохнуть.</p>
<p>Формула образования вопросительного предложения:</p>
<p><span>вопросительное слово +  форма глагола to be + подлежащее + going to + глагол</span></p>
<p>What are you going to do? - Что ты собираешься делать?</p>
<h3>to be going to в других временах также употребляется</h3>
<p>He was going to come to us - Он собирался прийти к нам.</p>
<p>They were going to eat out - Они собирались поесть вне дома.</p>
<p>What was he going to tell (to) us? - Что он собирался сказать нам?</p>
</div>`,
    },
  ],
];
