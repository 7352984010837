export const contentLesson49 = [
  [
    {
      title: "Урок 49. 150 verbs. Part 5",
    },
    [
      {
        id: 1,
        expression: "Наша встреча началась час назад",
        transcription: "ˈaʊə ˈmiːtɪŋ bɪˈgæn ən ˈaʊər əˈgəʊ",
        translation: "Our meeting began an hour ago",
      },
      {
        id: 2,
        expression: "Она съела два яйца",
        transcription: "ʃi ɛt tuː ɛgz",
        translation: "She ate two eggs",
      },
      {
        id: 3,
        expression: "Я пытаюсь улучшить свою грамматику онлайн",
        transcription: "aɪ traɪ tʊ ɪmˈpruːv maɪ ˈgræmər ˈɒnˌlaɪn",
        translation: "I try to improve my grammar online",
      },
      {
        id: 4,
        expression: "Я видел свой реальный прогресс",
        transcription: "aɪ sɔː maɪ rɪəl ˈprəʊgrəs",
        translation: "I saw my real progress",
      },
      {
        id: 5,
        expression: "Я проверил все",
        transcription: "aɪ ʧɛkt ˈɛvrɪθɪŋ",
        translation: "I checked everything",
      },
      {
        id: 6,
        expression: "Я хочу улучшить свои знания",
        transcription: "aɪ wɒnt tʊ ɪmˈpruːv maɪ ˈnɒlɪʤ",
        translation: "I want to improve my knowledge",
      },
      {
        id: 7,
        expression: "Он катался на верблюде в пустыне",
        transcription: "hi rəʊd ə ˈkæməl ɪn ə ˈdɛzət",
        translation: "He rode a camel in a desert",
      },
      {
        id: 8,
        expression: "Он поддерживает твою идею?",
        transcription: "dəz hi səˈpɔːt jər aɪˈdɪə?",
        translation: "Does he support your idea?",
      },
      {
        id: 9,
        expression: "Я читаю разные статьи онлайн",
        transcription: "aɪ riːd ˈdɪfrənt ˈɑːtɪklz ˈɒnˌlaɪn",
        translation: "I read different articles online",
      },
      {
        id: 10,
        expression: "Я слышу тебя очень хорошо",
        transcription: "aɪ hɪə jʊ ˈvɛri wɛl",
        translation: "I hear you very well",
      },
      {
        id: 11,
        expression: "Я всегда проверяю это несколько раз",
        transcription: "aɪ ˈɔːlweɪz ʧɛk ɪt ˈsɛvrəl taɪmz",
        translation: "I always check it several times",
      },
      {
        id: 12,
        expression: "Я часто слышу это от него",
        transcription: "aɪ ˈɒf(ə)n hɪər ɪt frəm hɪm",
        translation: "I often hear it from him",
      },
      {
        id: 13,
        expression: "Я слышу тебя намного лучше сейчас",
        transcription: "aɪ hɪə jʊ mʌʧ ˈbɛtə naʊ",
        translation: "I hear you much better now",
      },
      {
        id: 14,
        expression: "Он читал эту книгу",
        transcription: "hi red ðɪs bʊk",
        translation: "He read this book",
      },
      {
        id: 15,
        expression: "Когда это начнется?",
        transcription: "wɛn wɪl ɪt bɪˈgɪn?",
        translation: "When will it begin?",
      },
      {
        id: 16,
        expression: "Ты видишь каких-либо людей там?",
        transcription: "dʊ jʊ siː ˈɛni ˈpiːpl ðeə?",
        translation: "Do you see any people there?",
      },
      {
        id: 17,
        expression: "Он приехал домой",
        transcription: "hi əˈraɪvd həʊm",
        translation: "He arrived home",
      },
      {
        id: 18,
        expression: "Я хочу съесть бутерброд с сыром и маслом",
        transcription: "aɪ wɒnt tʊ iːt ˈsænwɪʤ wɪð ʧiːz ənd ˈbʌtə",
        translation: "I want to eat sandwich with cheese and butter",
      },
      {
        id: 19,
        expression: "Мои родители скоро прибудут",
        transcription: "maɪ ˈpeərənts wɪl əˈraɪv suːn",
        translation: "My parents will arrive soon",
      },
      {
        id: 20,
        expression: "Он мне очень помог",
        transcription: "hi hɛlpt mi ə lɒt",
        translation: "He helped me a lot",
      },
      {
        id: 21,
        expression: "Я делюсь этими материалами со своими друзьями",
        transcription: "aɪ ʃeə ðiːz məˈtɪərɪəlz wɪð maɪ frɛndz",
        translation: "I share these materials with my friends",
      },
      {
        id: 22,
        expression: "Я получил твое письмо",
        transcription: "aɪ gɒt jə ˈlɛtə",
        translation: "I got your letter",
      },
      {
        id: 23,
        expression: "Это действительно помогло мне",
        transcription: "ɪt ˈrɪəli hɛlpt miː",
        translation: "It really helped me",
      },
      {
        id: 24,
        expression: "Он съел все",
        transcription: "hi ɛt ˈɛvrɪθɪŋ",
        translation: "He ate everything",
      },
      {
        id: 25,
        expression: "Он прибыл в США",
        transcription: "hi əˈraɪvd ɪn ðə juː-ɛs-eɪ",
        translation: "He arrived in the USA",
      },
      {
        id: 26,
        expression: "Помой свои руки!",
        transcription: "wɒʃ jə hændz!",
        translation: "Wash your hands!",
      },
      {
        id: 27,
        expression: "Я умею кататься на велосипеде",
        transcription: "aɪ kən raɪd ə baɪk",
        translation: "I can ride a bike",
      },
      {
        id: 28,
        expression: "Она умыла свое лицо",
        transcription: "ʃi wɒʃt hə feɪs",
        translation: "She washed her face",
      },
      {
        id: 29,
        expression: "Я слышал это по телевизору",
        transcription: "aɪ hɜːd ɪt ɒn ˌtiːˈviː",
        translation: "I heard it on TV",
      },
      {
        id: 30,
        expression: "Я улучшил свою речь здесь",
        transcription: "aɪ ɪmˈpruːvd maɪ ˈspiːkɪŋ hɪə",
        translation: "I improved my speaking here",
      },
      {
        id: 31,
        expression: "Ты можешь поделиться этой ссылкой со своими друзьями",
        transcription: "jʊ kən ʃeə ðɪs lɪŋk wɪð jə frɛndz",
        translation: "You can share this link with your friends",
      },
      {
        id: 32,
        expression: "Это может сохранить ей жизнь",
        transcription: "ɪt kən seɪv hə laɪf",
        translation: "It can save her life",
      },
      {
        id: 33,
        expression: "Ты так много помогаешь мне",
        transcription: "jʊ hɛlp mi səʊ mʌʧ",
        translation: "You help me so much",
      },
      {
        id: 34,
        expression: "Эта встреча скоро начнется",
        transcription: "ðɪs ˈmiːtɪŋ wɪl bɪˈgɪn suːn",
        translation: "This meeting will begin soon",
      },
      {
        id: 35,
        expression: "Он умер в прошлом году",
        transcription: "hi daɪd lɑːst jɪə",
        translation: "He died last year",
      },
      {
        id: 36,
        expression: "Я поделился с ней этой фотографией",
        transcription: "aɪ ʃeəd ðɪs ˈfəʊtəʊ wɪð hɜː",
        translation: "I shared this photo with her",
      },
      {
        id: 37,
        expression: "Я заказал свой компьютер онлайн",
        transcription: "aɪ ˈɔːdəd maɪ kəmˈpjuːtər ˈɒnˌlaɪn",
        translation: "I ordered my computer online",
      },
      {
        id: 38,
        expression: "Я не заказываю одежду онлайн",
        transcription: "aɪ dəʊnt ˈɔːdə kləʊðz ˈɒnˌlaɪn",
        translation: "I don't order clothes online",
      },
      {
        id: 39,
        expression: "Мы можем сделать это позже",
        transcription: "wi kən dʊ ɪt ˈleɪtə",
        translation: "We can do it later",
      },
      {
        id: 40,
        expression: "Когда ты слышал это?",
        transcription: "wɛn dɪd jʊ hɪər ɪt?",
        translation: "When did you hear it?",
      },
      {
        id: 41,
        expression: "Я мог видеть это очень хорошо",
        transcription: "aɪ kəd siː ɪt ˈvɛri wɛl",
        translation: "I could see it very well",
      },
      {
        id: 42,
        expression: "Я понял, что это ее не интересовало",
        transcription: "aɪ ˌʌndəˈstʊd ðət ɪt dɪdnt ˈɪntrɪst hɜː",
        translation: "I understood that it didn't interest her",
      },
      {
        id: 43,
        expression: "Ты можешь прийти ко мне сейчас",
        transcription: "jʊ kən kʌm tə mi naʊ",
        translation: "You can come to me now",
      },
      {
        id: 44,
        expression: "Я читаю для удовольствия",
        transcription: "aɪ riːd fə ˈplɛʒə",
        translation: "I read for pleasure",
      },
      {
        id: 45,
        expression: "Я хочу задать тебе несколько вопросов",
        transcription: "aɪ wɒnt tʊ ɑːsk jʊ səm ˈkwɛsʧənz",
        translation: "I want to ask you some questions",
      },
      {
        id: 46,
        expression: "Он поцеловал ее внезапно",
        transcription: "hi kɪst hə ˈsʌdnli",
        translation: "He kissed her suddenly",
      },
      {
        id: 47,
        expression: "Он катался на верблюде, и это казалось таким необычным",
        transcription: "hi rəʊd ə ˈkæməl ənd ɪt siːmd səʊ ʌnˈjuːʒʊəl",
        translation: "He rode a camel and it seemed so unusual",
      },
      {
        id: 48,
        expression: "Она знает, что он может умереть скоро",
        transcription: "ʃi nəʊz ðət hi kən daɪ suːn",
        translation: "She knows that he can die soon",
      },
      {
        id: 49,
        expression: "Я чувствую, что мне нужно улучшить свою речь",
        transcription: "aɪ fiːl ðət aɪ niːd tʊ ɪmˈpruːv maɪ ˈspiːkɪŋ",
        translation: "I feel that I need to improve my speaking",
      },
      {
        id: 50,
        expression: "Она каталась на осле, и это было так необычно",
        transcription: "ʃi rəʊd ə ˈdɒŋki ənd ɪt wəz səʊ ʌnˈjuːʒʊəl",
        translation: "She rode a donkey and it was so unusual",
      },
      {
        id: 51,
        expression: "Они поцеловались в конце",
        transcription: "ðeɪ kɪst ɪn ði ɛnd",
        translation: "They kissed in the end",
      },
      {
        id: 52,
        expression: "Не забудьте сохранить этот файл!",
        transcription: "dəʊnt fəˈgɛt tə seɪv ðɪs faɪl!",
        translation: "Don't forget to save this file!",
      },
      {
        id: 53,
        expression:
          "Она часто путешествует, потому что ей это действительно нравится",
        transcription: "ʃi ˈɒf(ə)n ˈtrævlz bɪˈkəz ʃi ˈrɪəli laɪks ɪt",
        translation: "She often travels because she really likes it",
      },
      {
        id: 54,
        expression:
          "Он провалил свой экзамен, и это было действительно странно",
        transcription: "hi feɪld ɪz ɪgˈzæm ənd ɪt wəz ˈrɪəli streɪnʤ",
        translation: "He failed his exam and it was really strange",
      },
      {
        id: 55,
        expression: "Я не хотел провалить тот экзамен",
        transcription: "aɪ dɪdnt wɒnt tə feɪl ðət ɪgˈzæm",
        translation: "I didn't want to fail that exam",
      },
      {
        id: 56,
        expression: "Я часто читаю на английском для удовольствия",
        transcription: "aɪ ˈɒf(ə)n riːd ɪn ˈɪŋglɪʃ fə ˈplɛʒə",
        translation: "I often read in English for pleasure",
      },
      {
        id: 57,
        expression: "Он часто читает на испанском для удовольствия",
        transcription: "hi ˈɒf(ə)n riːdz ɪn ˈspænɪʃ fə ˈplɛʒə",
        translation: "He often reads in Spanish for pleasure",
      },
      {
        id: 58,
        expression: "Он часто читает книги",
        transcription: "hi ˈɒf(ə)n riːdz bʊks",
        translation: "He often reads books",
      },
      {
        id: 59,
        expression: "Я путешествовал очень редко, потому что я был очень занят",
        transcription: "aɪ ˈtrævld ˈvɛri ˌriːˈrɑːli bɪˈkəz aɪ wəz ˈvɛri ˈbɪzi",
        translation: "I travelled very reraly because I was very busy",
      },
      {
        id: 60,
        expression: "Я путешествую очень редко, потому что я очень занят",
        transcription: "aɪ ˈtrævl ˈvɛri ˌriːˈrɑːli bɪˈkəz aɪm ˈvɛri ˈbɪzi",
        translation: "I travel very reraly because I'm very busy",
      },
      {
        id: 61,
        expression: "Она не много путешествовала, потому что была очень занята",
        transcription: "ʃi dɪdnt ˈtrævl mʌʧ bɪˈkəz ʃi wəz ˈvɛri ˈbɪzi",
        translation: "She didn't travel much because she was very busy",
      },
      {
        id: 62,
        expression: "Я постараюсь потратить меньше денег на это",
        transcription: "aɪl traɪ tə spɛnd lɛs ˈmʌni ɒn ɪt",
        translation: "I'll try to spend less money on it",
      },
      {
        id: 63,
        expression: "Он постарается тратить больше денег на это",
        transcription: "hiːl traɪ tə spɛnd mɔː ˈmʌni ɒn ɪt",
        translation: "He'll try to spend more money on it",
      },
      {
        id: 64,
        expression: "Ты будешь стараться тратить меньше денег на это?",
        transcription: "wɪl jʊ traɪ tə spɛnd lɛs ˈmʌni ɒn ɪt?",
        translation: "Will you try to spend less money on it?",
      },
      {
        id: 65,
        expression: "Когда ты к нам придешь?",
        transcription: "wɛn wɪl jʊ kʌm tʊ ʌs?",
        translation: "When will you come to us?",
      },
      {
        id: 66,
        expression: "Когда она придет к тебе?",
        transcription: "wɛn wɪl ʃi kʌm tə juː?",
        translation: "When will she come to you?",
      },
      {
        id: 67,
        expression: "Ты придешь ко мне?",
        transcription: "wɪl jʊ kʌm tə miː?",
        translation: "Will you come to me?",
      },
      {
        id: 68,
        expression: "Она может говорить на двух иностранных языках",
        transcription: "ʃi kən spiːk tuː ˈfɒrɪn ˈlæŋgwɪʤɪz",
        translation: "She can speak two foreign languages",
      },
      {
        id: 69,
        expression: "Ты говоришь на каких-либо иностранных языках?",
        transcription: "dʊ jʊ spiːk ˈɛni ˈfɒrɪn ˈlæŋgwɪʤɪz?",
        translation: "Do you speak any foreign languages?",
      },
      {
        id: 70,
        expression: "Ты можешь говорить на каких-либо иностранных языках?",
        transcription: "kən jʊ spiːk ˈɛni ˈfɒrɪn ˈlæŋgwɪʤɪz?",
        translation: "Can you speak any foreign languages?",
      },
      {
        id: 71,
        expression: "Я думаю, он провалит этот экзамен",
        transcription: "aɪ θɪŋk hiːl feɪld ðɪs ɪgˈzæm",
        translation: "I think he'll failed this exam",
      },
      {
        id: 72,
        expression: "Я не думаю, что он провалит этот тест",
        transcription: "aɪ dəʊnt θɪŋk ðət hiːl feɪl ðɪs tɛst",
        translation: "I don't think that he'll fail this test",
      },
      {
        id: 73,
        expression: "Я думаю, она провалит этот экзамен",
        transcription: "aɪ θɪŋk ʃiːl feɪl ðɪs ɪgˈzæm",
        translation: "I think she'll fail this exam",
      },
      {
        id: 74,
        expression: "Не забудь сохранить этот документ!",
        transcription: "dəʊnt fəˈgɛt tə seɪv ðɪs ˈdɒkjʊmənt!",
        translation: "Don't forget to save this document!",
      },
      {
        id: 75,
        expression: "Не забудь прийти сюда!",
        transcription: "dəʊnt fəˈgɛt tə kʌm hɪə!",
        translation: "Don't forget to come here!",
      },
      {
        id: 76,
        expression: "Ты забыл сохранить этот документ?",
        transcription: "dɪd jʊ fəˈgɛt tə seɪv ðɪs ˈdɒkjʊmənt?",
        translation: "Did you forget to save this document?",
      },
      {
        id: 77,
        expression: "Что он у тебя спросил?",
        transcription: "wɒt dɪd hi ɑːsk juː?",
        translation: "What did he ask you?",
      },
      {
        id: 78,
        expression: "Что она у тебя спросила?",
        transcription: "wɒt dɪd ʃi ɑːsk juː?",
        translation: "What did she ask you?",
      },
      {
        id: 79,
        expression: "Что ты у нее спросил?",
        transcription: "wɒt dɪd jʊ ɑːsk hɜː?",
        translation: "What did you ask her?",
      },
      {
        id: 80,
        expression: "Ты тратишь какие-либо деньги на свое образование?",
        transcription: "dʊ jʊ spɛnd ˈɛni ˈmʌni ɒn jər ˌɛdju(ː)ˈkeɪʃən?",
        translation: "Do you spend any money on your education?",
      },
      {
        id: 81,
        expression: "Он тратит много денег на свое образование?",
        transcription: "dəz hi spɛnd mʌʧ ˈmʌni ɒn ɪz ˌɛdju(ː)ˈkeɪʃən?",
        translation: "Does he spend much money on his education?",
      },
      {
        id: 82,
        expression: "Я вернулся домой очень поздно",
        transcription: "aɪ rɪˈtɜːnd (keɪm bæk) həʊm ˈvɛri leɪt",
        translation: "I returned (came back) home very late",
      },
      {
        id: 83,
        expression: "Он вернулся домой очень рано",
        transcription: "hi rɪˈtɜːnd (keɪm bæk) həʊm ˈvɛri ˈɜːli",
        translation: "He returned (came back) home very early",
      },
      {
        id: 84,
        expression: "Я обычно возвращаюсь поздно",
        transcription: "aɪ ˈjuːʒʊəli rɪˈtɜːn leɪt",
        translation: "I usually return late",
      },
      {
        id: 85,
        expression: "Я знал это в начале",
        transcription: "aɪ njuː ɪt ɪn ðə bɪˈgɪnɪŋ",
        translation: "I knew it in the beginning",
      },
      {
        id: 86,
        expression: "Мы не знали, сколько это стоило",
        transcription: "wi dɪdnt nəʊ haʊ mʌʧ ɪt kɒst",
        translation: "We didn't know how much it cost",
      },
      {
        id: 87,
        expression: "Я могу читать и писать на английском",
        transcription: "aɪ kən riːd ənd raɪt ɪn ˈɪŋglɪʃ",
        translation: "I can read and write in English",
      },
      {
        id: 88,
        expression: "Он умеет говорить по-английски",
        transcription: "hi kən spiːk ˈɪŋglɪʃ",
        translation: "He can speak English",
      },
      {
        id: 89,
        expression: "Не забудь вымыть свои руки!",
        transcription: "dəʊnt fəˈgɛt tə wɒʃ jə hændz!",
        translation: "Don't forget to wash your hands!",
      },
      {
        id: 90,
        expression: "Ты будешь есть или нет?",
        transcription: "wɪl jʊ iːt ɔː nɒt?",
        translation: "Will you eat or not?",
      },
      {
        id: 91,
        expression: "Я не знаю, сколько это стоит",
        transcription: "aɪ dəʊnt nəʊ haʊ mʌʧ ɪt kɒsts",
        translation: "I don't know how much it costs",
      },
      {
        id: 92,
        expression: "Сколько денег ты получал, когда ты работал тут?",
        transcription: "haʊ mʌʧ ˈmʌni dɪd jʊ gɛt wɛn jʊ wɜːkt hɪə?",
        translation: "How much money did you get when you worked here?",
      },
      {
        id: 93,
        expression: "Я знаю, что это не интересует моих друзей",
        transcription: "aɪ nəʊ ðət ɪt dʌznt ˈɪntrɪst maɪ frɛndz",
        translation: "I know that it doesn't interest my friends",
      },
      {
        id: 94,
        expression: "Я уверен, тебе это поможет тоже",
        transcription: "aɪm ʃʊər ˈɪtl hɛlp juː, tuː",
        translation: "I'm sure it'll help you, too",
      },
      {
        id: 95,
        expression: "Я помню, как это начиналось",
        transcription: "aɪ rɪˈmɛmbə haʊ ɪt bɪˈgæn",
        translation: "I remember how it began",
      },
      {
        id: 96,
        expression: "Я думаю, я получу эту работу",
        transcription: "aɪ θɪŋk aɪl gɛt ðɪs ʤɒb",
        translation: "I think I'll get this job",
      },
      {
        id: 97,
        expression: "Я не хочу провалить этот тест",
        transcription: "aɪ dəʊnt wɒnt tə feɪl ðɪs tɛst",
        translation: "I don't want to fail this test",
      },
      {
        id: 98,
        expression: "Я думаю, она ест слишком много",
        transcription: "aɪ θɪŋk ʃi iːts tuː mʌʧ",
        translation: "I think she eats too much",
      },
      {
        id: 99,
        expression: "Она тратит очень большие деньги на одежду",
        transcription: "ʃi spɛndz ˈvɛri bɪg ˈmʌni ɒn kləʊðz",
        translation: "She spends very big money on clothes",
      },
      {
        id: 100,
        expression: "Мы играли в различные игры",
        transcription: "wi pleɪd ˈdɪfrənt geɪmz",
        translation: "We played different games",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Закрепление пройденного материала</h3>
</div>`,
    },
  ],
];
