export const wordsLesson90 = [
  [
    {
      id: 1,
      word: 'ball',
      transcription: 'bɔːl',
      translation: 'мяч',
    },
    {
      id: 2,
      word: 'blow',
      transcription: 'bləʊ',
      translation: 'дуть',
    },
    {
      id: 3,
      word: 'catch',
      transcription: 'kæʧ',
      translation: 'поймать',
    },
    {
      id: 4,
      word: 'cave',
      transcription: 'keɪv',
      translation: 'пещера',
    },
    {
      id: 5,
      word: 'grow up',
      transcription: 'grəʊ ʌp',
      translation: 'вырасти',
    },
    {
      id: 6,
      word: 'hide',
      transcription: 'haɪd',
      translation: 'прятать',
    },
    {
      id: 7,
      word: 'hit',
      transcription: 'hɪt',
      translation: 'ударить, удар',
    },
    {
      id: 8,
      word: 'at once',
      transcription: 'ət wʌns',
      translation: 'однажды',
    },
    {
      id: 9,
      word: 'otherwise',
      transcription: 'ˈʌðəwaɪz',
      translation: 'по-другому, иначе',
    },
    {
      id: 10,
      word: 'overcome',
      transcription: 'ˌəʊvəˈkʌm',
      translation: 'преодолевать',
    },
    {
      id: 11,
      word: 'shelf',
      transcription: 'ʃɛlf',
      translation: 'полка',
    },
    {
      id: 12,
      word: 'sportsman',
      transcription: 'ˈspɔːtsmən',
      translation: 'спортсмен',
    },
    {
      id: 13,
      word: 'steal',
      transcription: 'stiːl',
      translation: 'красть, воровать',
    },
    {
      id: 14,
      word: 'sudden',
      transcription: 'ˈsʌdn',
      translation: 'внезапный',
    },
    {
      id: 15,
      word: 'treasure',
      transcription: 'ˈtrɛʒə',
      translation: 'сокровище',
    },
    {
      id: 16,
      word: 'wind',
      transcription: 'wɪnd',
      translation: 'ветер',
    },
    {
      id: 17,
      word: 'withdraw',
      transcription: 'wɪðˈdrɔː',
      translation: 'снять [со счета]',
    },
  ],
];
