export const contentLesson144 = [
  [
    {
      title: 'Урок 144. 230 Adjectives. Part 6',
    },
    [
      {
        id: 1,
        expression: 'Кто сегодня отсутствует?',
        transcription: 'huːz ˈæbsənt təˈdeɪ?',
        translation: "Who's absent today?",
      },
      {
        id: 2,
        expression: 'Он очень худой мальчик',
        transcription: 'hiːz ə ˈvɛri θɪn bɔɪ',
        translation: "He's a very thin boy",
      },
      {
        id: 3,
        expression: 'Он не хотел выглядеть глупо',
        transcription: 'hi dɪdnt wɒnt tə lʊk ˈstjuːpɪd',
        translation: "He didn't want to look stupid",
      },
      {
        id: 4,
        expression: 'Это была необычная вечеринка',
        transcription: 'ɪt wəz ən ʌnˈjuːʒʊəl ˈpɑːti',
        translation: 'It was an unusual party',
      },
      {
        id: 5,
        expression: 'Это такой необычный ответ',
        transcription: 'ɪts sʌʧ ən ʌnˈjuːʒʊəl ˈɑːnsə',
        translation: "It's such an unusual answer",
      },
      {
        id: 6,
        expression: 'Я хочу быть честным с тобой',
        transcription: 'aɪ wɒnt tə bi ˈɒnɪst wɪð juː',
        translation: 'I want to be honest with you',
      },
      {
        id: 7,
        expression: 'Это такое красивое место',
        transcription: 'ɪts sʌʧ ə ˈbjuːtəfʊl pleɪs',
        translation: "It's such a beautiful place",
      },
      {
        id: 8,
        expression: 'Это один из самых частых вопросов',
        transcription: 'ɪts wʌn əv ðə məʊst ˈfriːkwənt ˈkwɛsʧənz',
        translation: "It's one of the most frequent questions",
      },
      {
        id: 9,
        expression: 'Она очень слаба после своей болезни',
        transcription: 'ʃiːz ˈvɛri wiːk ˈɑːftə hər ˈɪlnɪs',
        translation: "She's very weak after her illness",
      },
      {
        id: 10,
        expression: 'Давайте посмотрим на предыдущий слайд!',
        transcription: 'lɛts lʊk ət ðə ˈpriːviəs slaɪd!',
        translation: "Let's look at the previous slide!",
      },
      {
        id: 11,
        expression: 'Ты можешь посмотреть на часто задаваемые вопросы',
        transcription: 'jʊ kən lʊk ət ðə ˈfriːkwəntli ɑːskt ˈkwɛsʧən',
        translation: 'You can look at the frequently asked question',
      },
      {
        id: 12,
        expression: 'Мы можем прочитать это в официальном документе',
        transcription: 'wi kən riːd ɪt ɪn ən əˈfɪʃəl ˈdɒkjʊmənt',
        translation: 'We can read it in an official document',
      },
      {
        id: 13,
        expression: 'У нее светло-голубые глаза',
        transcription: 'ʃi həz laɪt bluː aɪz',
        translation: 'She has light blue eyes',
      },
      {
        id: 14,
        expression: 'Ты очень умный человек',
        transcription: 'jʊər ə ˈvɛri ɪnˈtɛlɪʤənt ˈpɜːsn',
        translation: "You're a very intelligent person",
      },
      {
        id: 15,
        expression: 'Это очень сложная проблема',
        transcription: 'ɪts ə ˈvɛri ˈkɒmplɛks ˈprɒbləm',
        translation: "It's a very complex problem",
      },
      {
        id: 16,
        expression: 'Это базовая цена',
        transcription: 'ɪts ə ˈbeɪsɪk praɪs',
        translation: "It's a basic price",
      },
      {
        id: 17,
        expression: 'Он честный человек',
        transcription: 'hiːz ən ˈɒnɪst mæn',
        translation: "He's an honest man",
      },
      {
        id: 18,
        expression: 'Это потрясающая возможность',
        transcription: 'ɪts ən əˈmeɪzɪŋ ˌɒpəˈtjuːnɪti',
        translation: "It's an amazing opportunity",
      },
      {
        id: 19,
        expression: 'Это классный велосипед',
        transcription: 'ɪts ə kuːl baɪk',
        translation: "It's a cool bike",
      },
      {
        id: 20,
        expression: 'Это очень прохладное место',
        transcription: 'ɪts ə ˈvɛri kuːl pleɪs',
        translation: "It's a very cool place",
      },
      {
        id: 21,
        expression: 'Они женаты?',
        transcription: 'ɑː ðeɪ ˈmærɪd?',
        translation: 'Are they married?',
      },
      {
        id: 22,
        expression: 'Это очень медленная скорость',
        transcription: 'ɪts ə ˈvɛri sləʊ spiːd',
        translation: "It's a very slow speed",
      },
      {
        id: 23,
        expression: 'Кто отсутствовал?',
        transcription: 'huː wəz ˈæbsənt?',
        translation: 'Who was absent?',
      },
      {
        id: 24,
        expression: 'Здесь не светло',
        transcription: 'ɪt ˈɪznt laɪt hɪə',
        translation: "It isn't light here",
      },
      {
        id: 25,
        expression: 'Наша жизнь действительно удивительная',
        transcription: 'ˈaʊə laɪf s ˈrɪəli əˈmeɪzɪŋ',
        translation: 'Our life is really amazing',
      },
      {
        id: 26,
        expression: 'Они такие умные мужчины',
        transcription: 'ðeə sʌʧ ɪnˈtɛlɪʤənt mɛn',
        translation: "They're such intelligent men",
      },
      {
        id: 27,
        expression: 'Это более удобный диван',
        transcription: 'ɪts ə mɔː ˈkʌmf(ə)təbl ˈsəʊfə',
        translation: "It's a more comfortable sofa",
      },
      {
        id: 28,
        expression: 'Это самый базовый уровень',
        transcription: 'ɪts ðə məʊst ˈbeɪsɪk ˈlɛvl',
        translation: "It's the most basic level",
      },
      {
        id: 29,
        expression: 'Он находит эту информацию очень полезной',
        transcription: 'hi faɪndz ðɪs ˌɪnfəˈmeɪʃən ˈvɛri ˈjuːsfʊl',
        translation: 'He finds this information very useful',
      },
      {
        id: 30,
        expression: 'Это такая тяжелая коробка',
        transcription: 'ɪts sʌʧ ə ˈhɛvi bɒks',
        translation: "It's such a heavy box",
      },
      {
        id: 31,
        expression: 'Он часто носит такие тяжелые коробки',
        transcription: 'hi ˈɒf(ə)n ˈkæriz sʌʧ ˈhɛvi ˈbɒksɪz',
        translation: 'He often carries such heavy boxes',
      },
      {
        id: 32,
        expression: 'Это типично для него',
        transcription: 'ɪts ˈtɪpɪk(ə)l əv hɪm',
        translation: "It's typical of him",
      },
      {
        id: 33,
        expression: 'Это такая глупая ошибка',
        transcription: 'ɪts sʌʧ ə ˈstjuːpɪd mɪsˈteɪk',
        translation: "It's such a stupid mistake",
      },
      {
        id: 34,
        expression: 'Я думаю, это отличная идея',
        transcription: 'aɪ θɪŋk ɪts ə greɪt aɪˈdɪə',
        translation: "I think it's a great idea",
      },
      {
        id: 35,
        expression: 'Это очень неудобный стул',
        transcription: 'ɪts ə ˈvɛri ʌnˈkʌmfətəbl ʧeə',
        translation: "It's a very uncomfortable chair",
      },
      {
        id: 36,
        expression: 'Мне там было очень неудобно',
        transcription: 'aɪ fɛlt ˈvɛri ʌnˈkʌmfətəbl hɪə',
        translation: 'I felt very uncomfortable here',
      },
      {
        id: 37,
        expression: 'Это место свободно?',
        transcription: 'ɪz ðɪs siːt friː?',
        translation: 'Is this seat free?',
      },
      {
        id: 38,
        expression: 'Ее комната всегда чистая',
        transcription: 'hə ruːm z ˈɔːlweɪz kliːn',
        translation: 'Her room is always clean',
      },
      {
        id: 39,
        expression: 'Эта особенность характерна для него',
        transcription: 'ðɪs ˈfiːʧə z ˈtɪpɪk(ə)l əv hɪm',
        translation: 'This feature is typical of him',
      },
      {
        id: 40,
        expression: 'Мальчик очень слабый',
        transcription: 'ðə bɔɪ z ˈvɛri wiːk',
        translation: 'The boy is very weak',
      },
      {
        id: 41,
        expression: 'Как давно твой друг женат?',
        transcription: 'haʊ lɒŋ həz jə frɛnd biːn ˈmærɪd?',
        translation: 'How long has your friend been married?',
      },
      {
        id: 42,
        expression: 'Я хочу достичь более высокого уровня в английском',
        transcription: 'aɪ wɒnt tə riːʧ ə ˈhaɪə ˈlɛvl ɪn ˈɪŋglɪʃ',
        translation: 'I want to reach a higher level in English',
      },
      {
        id: 43,
        expression: 'Это одно из величайших открытий двадцать первого века',
        transcription:
          'ɪts wʌn əv ðə ˈgreɪtɪst dɪsˈkʌvəriz əv ðə ˈtwɛnti fɜːst ˈsɛnʧʊri',
        translation:
          "It's one of the greatest discoveries of the twenty first century",
      },
      {
        id: 44,
        expression: 'Эта статья полна полезной информации',
        transcription: 'ðɪs ˈɑːtɪkl z fʊl əv ˈjuːsfʊl ˌɪnfəˈmeɪʃən',
        translation: 'This article is full of useful information',
      },
      {
        id: 45,
        expression: 'Это одна из самых бедных стран в мире',
        transcription: 'ɪts wʌn əv ðə ˈpʊərɪst ˈkʌntriz ɪn ðə wɜːld',
        translation: "It's one of the poorest countries in the world",
      },
      {
        id: 46,
        expression: 'Это было очень важное научное открытие',
        transcription: 'ɪt wəz ə ˈvɛri ɪmˈpɔːtənt ˌsaɪənˈtɪfɪk dɪsˈkʌvəri',
        translation: 'It was a very important scientific discovery',
      },
      {
        id: 47,
        expression: 'Ее прогресс намного медленнее, чем это ожидалось',
        transcription: 'hə ˈprəʊgrəs ɪz mʌʧ ˈsləʊə ðən ɪt wəz ɪksˈpɛktɪd',
        translation: 'Her progress is much slower than it was expected',
      },
      {
        id: 48,
        expression: 'Мне нравится идея бесплатного образования',
        transcription: 'aɪ laɪk ði aɪˈdɪə əv friː ˌɛdju(ː)ˈkeɪʃən',
        translation: 'I like the idea of free education',
      },
      {
        id: 49,
        expression: 'Судья признал его виновным',
        transcription: 'ðə ˈʤʌʤ faʊnd ɪm ˈgɪlti',
        translation: 'The judge found him guilty',
      },
      {
        id: 50,
        expression: 'У него гораздо более высокий уровень',
        transcription: 'hi həz ə mʌʧ ˈhaɪə ˈlɛvl',
        translation: 'He has a much higher level',
      },
      {
        id: 51,
        expression:
          'Студент обычно изучает основную английскую грамматику в первую очередь',
        transcription:
          'ə ˈstjuːdənt ˈjuːʒʊəli lɜːnz ˈbeɪsɪk ˈɪŋglɪʃ ˈgræmə fɜːst əv ɔːl',
        translation:
          'A student usually learns basic English grammar first of all',
      },
      {
        id: 52,
        expression: 'Утверждение верное или ложное?',
        transcription: 'ɪz ðə ˈsteɪtmənt truː ɔː fɔːls?',
        translation: 'Is the statement true or false?',
      },
      {
        id: 53,
        expression: 'Она предпочитает вести активный образ жизни',
        transcription: 'ʃi priˈfɜːz tə liːd ən ˈæktɪv ˈlaɪfˌstaɪl',
        translation: 'She prefers to lead an active lifestyle',
      },
      {
        id: 54,
        expression: 'Он честный человек',
        transcription: 'hiːz ən ˈɒnɪst ˈpɜːsn',
        translation: "He's an honest person",
      },
      {
        id: 55,
        expression: 'Все присутствуют?',
        transcription: 'ɪz ˈɛvrɪwʌn ˈprɛznt?',
        translation: 'Is everyone present?',
      },
      {
        id: 56,
        expression: 'Все присутствовали?',
        transcription: 'wəz ˈɛvrɪwʌn ˈprɛznt?',
        translation: 'Was everyone present?',
      },
      {
        id: 57,
        expression: 'Она присутствовала на этом уроке',
        transcription: 'ʃi wəz ˈprɛznt ət ðɪs ˈlɛsn',
        translation: 'She was present at this lesson',
      },
      {
        id: 58,
        expression: 'Все присутствовали',
        transcription: 'ˈɛvrɪwʌn wəz ˈprɛznt',
        translation: 'Everyone was present',
      },
      {
        id: 59,
        expression: 'Этот тест не такой легкий, как тот',
        transcription: 'ðɪs tɛst ˈɪznt əz ˈiːzi əz ðət wʌn',
        translation: "This test isn't as easy as that one",
      },
      {
        id: 60,
        expression: 'Этот тест был легче, чем тот',
        transcription: 'ðɪs tɛst wəz ˈiːzɪə ðən ðət wʌn',
        translation: 'This test was easier than that one',
      },
      {
        id: 61,
        expression: 'Это не так легко, как кажется',
        transcription: 'ɪts nɒt əz ˈiːzi əz ɪt siːmz',
        translation: "It's not as easy as it seems",
      },
      {
        id: 62,
        expression: 'Его уровень не такой высокий, как у нее',
        transcription: 'hɪz ˈlɛvl ˈɪznt əz haɪ əz hɜːz',
        translation: "His level isn't as high as hers",
      },
      {
        id: 63,
        expression: 'Его уровень такой же, как у нее',
        transcription: 'hɪz ˈlɛvl z ðə seɪm əz hɜːz',
        translation: 'His level is the same as hers',
      },
      {
        id: 64,
        expression: 'У нее был самый высокий уровень',
        transcription: 'ʃi həd ðə ˈhaɪɪst ˈlɛvl',
        translation: 'She had the highest level',
      },
      {
        id: 65,
        expression: 'Это самый высокий уровень',
        transcription: 'ɪts ðə ˈhaɪɪst ˈlɛvl',
        translation: "It's the highest level",
      },
      {
        id: 66,
        expression: 'У нее был уровень выше его',
        transcription: 'ʃi həd ə ˈhaɪə ˈlɛvl ðən hɪm',
        translation: 'She had a higher level than him',
      },
      {
        id: 67,
        expression: 'Его уровень английского выше',
        transcription: 'hɪz ˈɪŋglɪʃ ˈlɛvl z ˈhaɪə',
        translation: 'His English level is higher',
      },
      {
        id: 68,
        expression: 'Мне нужно ответить на следующие вопросы',
        transcription: 'aɪ niːd tʊ ˈɑːnsə ðə ˈfɒləʊɪŋ ˈkwɛsʧənz',
        translation: 'I need to answer the following questions',
      },
      {
        id: 69,
        expression: 'Ответь на следующий вопрос!',
        transcription: 'ˈɑːnsə ðə ˈfɒləʊɪŋ ˈkwɛsʧən',
        translation: 'Answer the following question',
      },
      {
        id: 70,
        expression: 'Ты можешь ответить на эти вопросы?',
        transcription: 'kən jʊ ˈɑːnsə ðiːz ˈkwɛsʧənz?',
        translation: 'Can you answer these questions?',
      },
      {
        id: 71,
        expression: 'Как долго они женаты?',
        transcription: 'haʊ lɒŋ həv ðeɪ biːn ˈmærɪd?',
        translation: 'How long have they been married?',
      },
      {
        id: 72,
        expression: 'Когда они поженились?',
        transcription: 'wɛn dɪd ðeɪ gɛt ˈmærɪd?',
        translation: 'When did they get married?',
      },
      {
        id: 73,
        expression: 'Полиция считает, что он виновен',
        transcription: 'ðə pəˈliːs kənˈsɪdə ðət hiːz ˈgɪlti',
        translation: "The police consider that he's guilty",
      },
      {
        id: 74,
        expression: 'Она чувствовала себя виноватой',
        transcription: 'ʃi fɛlt ˈgɪlti',
        translation: 'She felt guilty',
      },
      {
        id: 75,
        expression: 'Он был признан виновным',
        transcription: 'hi wəz faʊnd ˈgɪlti',
        translation: 'He was found guilty',
      },
      {
        id: 76,
        expression: 'Он был настолько беден, что не мог купить это',
        transcription: 'hi wəz səʊ pʊə ðət hi ˈkʊdnt baɪ ɪt',
        translation: "He was so poor that he couldn't buy it",
      },
      {
        id: 77,
        expression: 'Он не мог себе этого позволить',
        transcription: 'hi ˈkʊdnt əˈfɔːd ɪt',
        translation: "He couldn't afford it",
      },
      {
        id: 78,
        expression: 'Это было официальное объявление',
        transcription: 'ɪt wəz ən əˈfɪʃəl əˈnaʊnsmənt',
        translation: 'It was an official announcment',
      },
      {
        id: 79,
        expression: 'Он сделал официальное заявление',
        transcription: 'hi meɪd ən əˈfɪʃəl ˈsteɪtmənt',
        translation: 'He made an official statement',
      },
      {
        id: 80,
        expression: 'Это была неофициальная информация',
        transcription: 'ɪt wəz ˌʌnəˈfɪʃəl ˌɪnfəˈmeɪʃən',
        translation: 'It was unofficial information',
      },
      {
        id: 81,
        expression: 'Это было научное исследование',
        transcription: 'ɪt wəz ə ˌsaɪənˈtɪfɪk rɪˈsɜːʧ',
        translation: 'It was a scientific research',
      },
      {
        id: 82,
        expression: 'Ученые провели некоторые исследования',
        transcription: 'ˈsaɪəntɪsts meɪd səm rɪˈsɜːʧɪz',
        translation: 'Scientists made some researches',
      },
      {
        id: 83,
        expression: 'Это научное исследование было успешным',
        transcription:
          'ðɪs ˌsaɪənˈtɪfɪk rɪˈsɜːʧ wəz səkˈsɛsfʊl / ðɪs ˌsaɪənˈtɪfɪk rɪˈsɜːʧ wəz ə səkˈsɛs',
        translation:
          'This scientific research was successful / This scientific research was a success',
      },
      {
        id: 84,
        expression: 'Это было объяснено в предыдущем уроке',
        transcription: 'ɪt wəz ɪksˈpleɪnd ɪn ðə ˈpriːviəs ˈlɛsn',
        translation: 'It was explained in the previous lesson',
      },
      {
        id: 85,
        expression: 'Это очень сложный подход',
        transcription: 'ɪts ə ˈvɛri ˈkɒmplɛks əˈprəʊʧ',
        translation: "It's a very complex approach",
      },
      {
        id: 86,
        expression: 'Это было упомянуто в предыдущем видео',
        transcription: 'ɪt wəz ˈmɛnʃənd ɪn ðə ˈpriːviəs ˈvɪdɪəʊ',
        translation: 'It was mentioned in the previous video',
      },
      {
        id: 87,
        expression: 'Он говорит, что это крутой фильм',
        transcription: 'hi sɛz ðət ɪts ə kuːl ˈmuːvi',
        translation: "He says that it's a cool movie",
      },
      {
        id: 88,
        expression: 'Мы встретились на следующий день',
        transcription: 'wi mɛt ðə ˈfɒləʊɪŋ deɪ',
        translation: 'We met the following day',
      },
      {
        id: 89,
        expression: 'Она моложе меня',
        transcription: 'ʃiːz ˈjʌŋə ðən miː',
        translation: "She's younger than me",
      },
      {
        id: 90,
        expression:
          'Она была настолько бедная, что она не могла себе позволить купить это',
        transcription: 'ʃi wəz səʊ pʊə ðət ʃi ˈkʊdnt əˈfɔːd tə baɪ ɪt',
        translation: "She was so poor that she couldn't afford to buy it",
      },
      {
        id: 91,
        expression: 'Ее прогресс медленнее, чем он ожидал',
        transcription: 'hə ˈprəʊgrəs ɪz ˈsləʊə ðən hi ɪksˈpɛktɪd',
        translation: 'Her progress is slower than he expected',
      },
      {
        id: 92,
        expression: 'Они прекрасно провели время вместе',
        transcription: 'ðeɪ həd ə greɪt taɪm təˈgɛðə',
        translation: 'They had a great time together',
      },
      {
        id: 93,
        expression: 'Концерт прошел с большим успехом',
        transcription: 'ðə ˈkɒnsə(ː)t wəz ə greɪt (hjuːʤ, trɪˈmɛndəs) səkˈsɛs',
        translation: 'The concert was a great (huge, tremendous) success',
      },
      {
        id: 94,
        expression: 'Они встретились на следующий день',
        transcription: 'ðeɪ mɛt ðə ˈfɒləʊɪŋ (nɛkst) deɪ',
        translation: 'They met the following (next) day',
      },
      {
        id: 95,
        expression: 'Я моложе его',
        transcription: 'aɪm ˈjʌŋə ðən hɪm',
        translation: "I'm younger than him",
      },
      {
        id: 96,
        expression: 'Это правда или ложь?',
        transcription: 'ɪz ɪt truː ɔː fɔːls?',
        translation: 'Is it true or false?',
      },
      {
        id: 97,
        expression: 'Все хорошо',
        transcription: 'ˈɛvrɪθɪŋ z gʊd',
        translation: 'Everything is good',
      },
      {
        id: 98,
        expression: 'Все присутствуют',
        transcription: 'ˈɛvrɪwʌn z ˈprɛznt',
        translation: 'Everyone is present',
      },
      {
        id: 99,
        expression: 'Это так далеко отсюда',
        transcription: 'ɪts səʊ fɑː frəm hɪə',
        translation: "It's so far from here",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Закрепление пройденного материала</h3>
</div>`,
    },
  ],
];
