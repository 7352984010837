export const contentLesson111 = [
  [
    {
      title: "Урок 111. Present Continuous. Negative form",
    },
    [
      {
        id: 1,
        expression: "Я не пью кофе сейчас",
        transcription: "aɪm nɒt ˈdrɪŋkɪŋ ˈkɒfi",
        translation: "I'm not drinking coffee",
      },
      {
        id: 2,
        expression: "Они не ждут его сейчас",
        transcription: "ðeɪ ɑːnt ˈweɪtɪŋ fə hɪm",
        translation: "They aren't waiting for him",
      },
      {
        id: 3,
        expression: "Я не танцую сейчас",
        transcription: "aɪm nɒt ˈdɑːnsɪŋ",
        translation: "I'm not dancing",
      },
      {
        id: 4,
        expression: "Они не смотрят телевизор сейчас",
        transcription: "ðeɪ ɑːnt ˈwɒʧɪŋ ˌtiːˈviː",
        translation: "They aren't watching TV",
      },
      {
        id: 5,
        expression: "Они не учат английский сейчас",
        transcription: "ðeɪ ɑːnt ˈlɜːnɪŋ ˈɪŋglɪʃ",
        translation: "They aren't learning English",
      },
      {
        id: 6,
        expression: "Она не ждет тебя сейчас",
        transcription: "ʃi ˈɪznt ˈweɪtɪŋ fə juː",
        translation: "She isn't waiting for you",
      },
      {
        id: 7,
        expression: "Она не печет хлеб сейчас",
        transcription: "ʃi ˈɪznt ˈbeɪkɪŋ brɛd naʊ",
        translation: "She isn't baking bread now",
      },
      {
        id: 8,
        expression: "Я не думаю об этом в данный момент",
        transcription: "aɪm nɒt ˈθɪŋkɪŋ əˈbaʊt ɪt ət ðə ˈməʊmənt",
        translation: "I'm not thinking about it at the moment",
      },
      {
        id: 9,
        expression: "Он нам ничего не говорит",
        transcription: "hi ˈɪznt ˈtɛlɪŋ əs ˈɛnɪθɪŋ",
        translation: "He isn't telling us anything",
      },
      {
        id: 10,
        expression: "Его английский не становится лучше",
        transcription: "hɪz ˈɪŋglɪʃ ˈɪznt ˈgɛtɪŋ ˈbɛtə",
        translation: "His English isn't getting better",
      },
      {
        id: 11,
        expression: "Он не пытается ничего изменить",
        transcription: "hi ˈɪznt ˈtraɪɪŋ tə ʧeɪnʤ ˈɛnɪθɪŋ",
        translation: "He isn't trying to change anything",
      },
      {
        id: 12,
        expression: "Это не помогает мне сейчас",
        transcription: "ɪt ˈɪznt ˈhɛlpɪŋ mi naʊ",
        translation: "It isn't helping me now",
      },
      {
        id: 13,
        expression: "Он там не работает в данный момент",
        transcription: "hi ˈɪznt ˈwɜːkɪŋ ðeər ət ðə ˈməʊmənt",
        translation: "He isn't working there at the moment",
      },
      {
        id: 14,
        expression: "Сегодня не идет снег",
        transcription: "ɪt ˈɪznt ˈsnəʊɪŋ təˈdeɪ",
        translation: "It isn't snowing today",
      },
      {
        id: 15,
        expression: "Она не носит джинсы сегодня",
        transcription: "ʃi ˈɪznt ˈweərɪŋ ʤiːnz təˈdeɪ",
        translation: "She isn't wearing jeans today",
      },
      {
        id: 16,
        expression: "Я сейчас не разговариваю по телефону",
        transcription: "aɪm nɒt ˈtɔːkɪŋ ɒn ðə fəʊn naʊ",
        translation: "I'm not talking on the phone now",
      },
      {
        id: 17,
        expression: "Я не ухожу из дома сейчас",
        transcription: "aɪm nɒt ˈliːvɪŋ həʊm naʊ",
        translation: "I'm not leaving home now",
      },
      {
        id: 18,
        expression: "Они не готовят сосиски сейчас",
        transcription: "ðeɪ ɑːnt ˈkʊkɪŋ ˈsɒsɪʤɪz naʊ",
        translation: "They aren't cooking sausages now",
      },
      {
        id: 19,
        expression: "Я сейчас не иду по улице",
        transcription: "aɪm nɒt ˈwɔːkɪŋ ɪn ðə striːt naʊ",
        translation: "I'm not walking in the street now",
      },
      {
        id: 20,
        expression: "Он не разговаривает со своим другом",
        transcription: "hi ˈɪznt ˈtɔːkɪŋ tə ɪz frɛnd",
        translation: "He isn't talking to his friend",
      },
      {
        id: 21,
        expression: "Она не идет с нами в парк",
        transcription: "ʃi ˈɪznt ˈgəʊɪŋ tə ðə pɑːk wɪð ʌs",
        translation: "She isn't going to the park with us",
      },
      {
        id: 22,
        expression: "Они не играют во дворе",
        transcription: "ðeɪ ɑːnt ˈpleɪɪŋ ɪn ðə ˈgɑːdn",
        translation: "They aren't playing in the garden",
      },
      {
        id: 23,
        expression: "Я делаю эти упражнения сейчас",
        transcription: "aɪm ˈdu(ː)ɪŋ ðiːz ˈɛksəsaɪzɪz naʊ",
        translation: "I'm doing these exercises now",
      },
      {
        id: 24,
        expression: "Они не критикуют его",
        transcription: "ðeɪ ɑːnt ˈkrɪtɪsaɪzɪŋ hɪm",
        translation: "They aren't criticizing him",
      },
      {
        id: 25,
        expression: "Она не улыбается сейчас",
        transcription: "ʃi ˈɪznt ˈsmaɪlɪŋ",
        translation: "She isn't smiling",
      },
      {
        id: 26,
        expression: "Мы сейчас не ужинаем",
        transcription: "wi ɑːnt ˈhævɪŋ ˈdɪnə",
        translation: "We aren't having dinner",
      },
      {
        id: 27,
        expression: "Он не слушает меня",
        transcription: "hi ˈɪznt ˈlɪsnɪŋ tə miː",
        translation: "He isn't listening to me",
      },
      {
        id: 28,
        expression: "Он не готовит сосиски",
        transcription: "hi ˈɪznt ˈkʊkɪŋ ˈsɒsɪʤɪz",
        translation: "He isn't cooking sausages",
      },
      {
        id: 29,
        expression: "Это не происходит в настоящее время",
        transcription: "ɪt ˈɪznt ˈhæpnɪŋ ət ˈprɛznt",
        translation: "It isn't happening at present",
      },
      {
        id: 30,
        expression: "Она не печет торт",
        transcription: "ʃi ˈɪznt ˈbeɪkɪŋ ə keɪk",
        translation: "She isn't baking a cake",
      },
      {
        id: 31,
        expression: "Дождь не идет",
        transcription: "ɪt ˈɪznt ˈreɪnɪŋ",
        translation: "It isn't raining",
      },
      {
        id: 32,
        expression: "Ты не говоришь это прямо",
        transcription: "jʊ ɑːnt ˈseɪɪŋ ɪt dɪˈrɛktli",
        translation: "You aren't saying it directly",
      },
      {
        id: 33,
        expression: "Я не трачу время впустую",
        transcription: "aɪm nɒt ˈweɪstɪŋ taɪm",
        translation: "I'm not wasting time",
      },
      {
        id: 34,
        expression: "Снег не идет",
        transcription: "ɪt ˈɪznt ˈsnəʊɪŋ",
        translation: "It isn't snowing",
      },
      {
        id: 35,
        expression: "Он ничего не говорит",
        transcription: "hi ˈɪznt ˈseɪɪŋ ˈɛnɪθɪŋ",
        translation: "He isn't saying anything",
      },
      {
        id: 36,
        expression: "Они не задают никаких вопросов",
        transcription: "ðeɪ ɑːnt ˈɑːskɪŋ ˈɛni ˈkwɛsʧənz",
        translation: "They aren't asking any questions",
      },
      {
        id: 37,
        expression: "Я не ухожу сейчас",
        transcription: "aɪm nɒt ˈliːvɪŋ naʊ",
        translation: "I'm not leaving now",
      },
      {
        id: 38,
        expression: "Мы не обсуждаем это в данный момент",
        transcription: "wi ɑːnt dɪsˈkʌsɪŋ ɪt ət ðə ˈməʊmənt",
        translation: "We aren't discussing it at the moment",
      },
      {
        id: 39,
        expression: "Сегодня не идет дождь",
        transcription: "ɪt ˈɪznt ˈreɪnɪŋ təˈdeɪ",
        translation: "It isn't raining today",
      },
      {
        id: 40,
        expression: "Он не носит галстук сегодня",
        transcription: "hi ˈɪznt ˈweərɪŋ ə taɪ təˈdeɪ",
        translation: "He isn't wearing a tie today",
      },
      {
        id: 41,
        expression: "Он не едет быстро",
        transcription: "hi ˈɪznt ˈdraɪvɪŋ fɑːst",
        translation: "He isn't driving fast",
      },
      {
        id: 42,
        expression: "Мы не ждем этого",
        transcription: "wi ɑːnt ˈweɪtɪŋ fər ɪt",
        translation: "We aren't waiting for it",
      },
      {
        id: 43,
        expression: "Я не обедаю сейчас",
        transcription: "aɪm nɒt ˈhævɪŋ lʌnʧ naʊ",
        translation: "I'm not having lunch now",
      },
      {
        id: 44,
        expression: "Он сейчас не идет в спортзал",
        transcription: "hi ˈɪznt ˈgəʊɪŋ tə ðə ʤɪm naʊ",
        translation: "He isn't going to the gym now",
      },
      {
        id: 45,
        expression: "Я сейчас не смотрю телевизор",
        transcription: "aɪm nɒt ˈwɒʧɪŋ ˌtiːˈviː naʊ",
        translation: "I'm not watching TV now",
      },
      {
        id: 46,
        expression: "Она сейчас не работает в этом отделе",
        transcription: "ʃi ˈɪznt ˈwɜːkɪŋ ɪn ðɪs dɪˈpɑːtmənt naʊ",
        translation: "She isn't working in this department now",
      },
      {
        id: 47,
        expression: "Он не сидит в кресле. Он сидит на табуретке",
        transcription: "hi ˈɪznt ˈsɪtɪŋ ɪn ən ˈɑːmˈʧeə. hiːz ˈsɪtɪŋ ɒn ə stuːl",
        translation: "He isn't sitting in an armchair. He's sitting on a stool",
      },
      {
        id: 48,
        expression: "Я не читаю книгу. Я читаю статью",
        transcription: "aɪm nɒt ˈriːdɪŋ ə bʊk. aɪm ˈriːdɪŋ ən ˈɑːtɪkl",
        translation: "I'm not reading a book. I'm reading an article",
      },
      {
        id: 49,
        expression: "Она сейчас не играет на пианино. Она играет на скрипке",
        transcription:
          "ʃi ˈɪznt ˈpleɪɪŋ ðə pɪˈænəʊ naʊ. ʃiːz ˈpleɪɪŋ ðə ˌvaɪəˈlɪn",
        translation:
          "She isn't playing the piano now. She's playing the violin",
      },
      {
        id: 50,
        expression:
          "Мальчики сейчас не играют в футбол. Они играют в баскетбол",
        transcription:
          "ðə bɔɪz ɑːnt ˈpleɪɪŋ ˈfʊtbɔːl naʊ. ðeə ˈpleɪɪŋ ˈbɑːskɪtˌbɔːl",
        translation:
          "The boys aren't playing football now. They're playing basketball",
      },
      {
        id: 51,
        expression: "Сейчас он не играет на скрипке. Он играет на гитаре",
        transcription:
          "hi ˈɪznt ˈpleɪɪŋ ðə ˌvaɪəˈlɪn naʊ. hiːz ˈpleɪɪŋ ðə gɪˈtɑː",
        translation: "He isn't playing the violin now. He's playing the guitar",
      },
      {
        id: 52,
        expression: "Я не думаю об этом. Я думаю о других вещах",
        transcription:
          "aɪm nɒt ˈθɪŋkɪŋ əˈbaʊt ɪt. aɪm ˈθɪŋkɪŋ əˈbaʊt ˈʌðə θɪŋz",
        translation:
          "I'm not thinking about it. I'm thinking about other things",
      },
      {
        id: 53,
        expression: "Я не читаю книгу в данный момент. Я читаю журнал",
        transcription: "aɪm nɒt ˈriːdɪŋ ə bʊk naʊ. aɪm ˈriːdɪŋ ə ˌmægəˈziːn",
        translation: "I'm not reading a book now. I'm reading a magazine",
      },
      {
        id: 54,
        expression: "Она не читает журнал сейчас. Она читает газету",
        transcription:
          "ʃi ˈɪznt ˈriːdɪŋ ə ˌmægəˈziːn naʊ. ʃiːz ˈriːdɪŋ ə ˈnjuːzˌpeɪpə",
        translation:
          "She isn't reading a magazine now. She's reading a newspaper",
      },
      {
        id: 55,
        expression: "Они не плавают. Они лежат на пляже",
        transcription: "ðeɪ ɑːnt ˈswɪmɪŋ. ðeə ˈlaɪɪŋ ɒn ðə biːʧ",
        translation: "They aren't swimming. They're lying on the beach",
      },
      {
        id: 56,
        expression: "Он не работает. Он отдыхает",
        transcription: "hi ˈɪznt ˈwɜːkɪŋ. hiːz ˈhævɪŋ ə rɛst",
        translation: "He isn't working. He's having a rest",
      },
      {
        id: 57,
        expression: "Он не спит. Он учится",
        transcription: "hi ˈɪznt ˈsliːpɪŋ. hiːz ˈstʌdiɪŋ",
        translation: "He isn't sleeping. He's studying",
      },
      {
        id: 58,
        expression: "Погода не улучшается",
        transcription: "ðə ˈwɛðər ˈɪznt ɪmˈpruːvɪŋ",
        translation: "The weather isn't improving",
      },
      {
        id: 59,
        expression: "Я стараюсь не думать об этом",
        transcription: "aɪm ˈtraɪɪŋ nɒt tə θɪŋk əˈbaʊt ɪt",
        translation: "I'm trying not to think about it",
      },
      {
        id: 60,
        expression: "Она не танцует. Она поет",
        transcription: "ʃi ˈɪznt ˈdɑːnsɪŋ. ʃiːz ˈsɪŋɪŋ",
        translation: "She isn't dancing. She's singing",
      },
      {
        id: 61,
        expression: "Большинство людей не становятся богаче",
        transcription: "məʊst ˈpiːpl ˈɪznt ˈgɛtɪŋ ˈrɪʧə",
        translation: "Most people isn't getting richer",
      },
      {
        id: 62,
        expression: "Дни не становятся теплее",
        transcription: "ðə deɪz ɑːnt ˈgɛtɪŋ ˈwɔːmə",
        translation: "The days aren't getting warmer",
      },
      {
        id: 63,
        expression: "Погода не становится лучше",
        transcription: "ðə ˈwɛðər ˈɪznt ˈgɛtɪŋ ˈbɛtə",
        translation: "The weather isn't getting better",
      },
      {
        id: 64,
        expression: "Дни не становятся длиннее",
        transcription: "ðə deɪz ɑːnt ˈgɛtɪŋ ˈlɒŋgə",
        translation: "The days aren't getting longer",
      },
      {
        id: 65,
        expression: "Эти вещи не помогают мне сейчас",
        transcription: "ðiːz θɪŋz ɑːnt ˈhɛlpɪŋ mi naʊ",
        translation: "These things aren't helping me now",
      },
      {
        id: 66,
        expression: "Он не помогает мне сейчас",
        transcription: "hi ˈɪznt ˈhɛlpɪŋ mi naʊ",
        translation: "He isn't helping me now",
      },
      {
        id: 67,
        expression: "Эти уроки не помогают ему сейчас",
        transcription: "ðiːz ˈlɛsnz ɑːnt ˈhɛlpɪŋ ɪm naʊ",
        translation: "These lessons aren't helping him now",
      },
      {
        id: 68,
        expression: "Он не пишет письмо. Он пишет сообщение",
        transcription: "hi ˈɪznt ˈraɪtɪŋ ə ˈlɛtə. hiːz ˈraɪtɪŋ ə ˈmɛsɪʤ",
        translation: "He isn't writing a letter. He's writing a message",
      },
      {
        id: 69,
        expression: "Он не пишет сообщение. Он пишет письмо",
        transcription: "hi ˈɪznt ˈraɪtɪŋ ə ˈmɛsɪʤ. hiːz ˈraɪtɪŋ ə ˈlɛtə",
        translation: "He isn't writing a message. He's writing a letter",
      },
      {
        id: 70,
        expression: "Он не читает книгу. Он читает журнал",
        transcription: "hi ˈɪznt ˈriːdɪŋ ə bʊk. hiːz ˈriːdɪŋ ə ˌmægəˈziːn",
        translation: "He isn't reading a book. He's reading a magazine",
      },
      {
        id: 71,
        expression: "Мальчики сейчас не играют во дворе",
        transcription: "ðə bɔɪz ɑːnt ˈpleɪɪŋ ɪn ðə jɑːd naʊ",
        translation: "The boys aren't playing in the yard now",
      },
      {
        id: 72,
        expression: "Дети играют во дворе сейчас",
        transcription: "ðə ˈʧɪldrən ə ˈpleɪɪŋ ɪn ðə jɑːd naʊ",
        translation: "The children are playing in the yard now",
      },
      {
        id: 73,
        expression: "Мальчики не играют в футбол сейчас",
        transcription: "ðə bɔɪz ɑːnt ˈpleɪɪŋ ˈfʊtbɔːl naʊ",
        translation: "The boys aren't playing football now",
      },
      {
        id: 74,
        expression: "Я стараюсь не тратить время впустую",
        transcription: "aɪm ˈtraɪɪŋ nɒt tə weɪst taɪm",
        translation: "I'm trying not to waste time",
      },
      {
        id: 75,
        expression: "Я стараюсь не делать этого",
        transcription: "aɪm ˈtraɪɪŋ nɒt tə dʊ ɪt",
        translation: "I'm trying not to do it",
      },
      {
        id: 76,
        expression: "Я пытаюсь делать это очень хорошо",
        transcription: "aɪm ˈtraɪɪŋ tə dʊ ɪt ˈvɛri wɛl",
        translation: "I'm trying to do it very well",
      },
      {
        id: 77,
        expression: "Она не лежит на диване. Она лежит в кровати",
        transcription: "ʃi ˈɪznt ˈlaɪɪŋ ɒn ðə ˈsəʊfə. ʃiːz ˈlaɪɪŋ ɪn bɛd",
        translation: "She isn't lying on the sofa. She's lying in bed",
      },
      {
        id: 78,
        expression: "Я не сижу на стуле. Я сижу в кресле",
        transcription: "aɪm nɒt ˈsɪtɪŋ ɒn ə ʧeə. aɪm ˈsɪtɪŋ ɪn ən ˈɑːmˈʧeə",
        translation: "I'm not sitting on a chair. I'm sitting in an armchair",
      },
      {
        id: 79,
        expression: "Она лежит на диване в данный момент",
        transcription: "ʃiːz ˈlaɪɪŋ ɒn ðə ˈsəʊfə ət ðə ˈməʊmənt",
        translation: "She's lying on the sofa at the moment",
      },
      {
        id: 80,
        expression: "Собака не лает",
        transcription: "ðə dɒg ˈɪznt ˈbɑːkɪŋ",
        translation: "The dog isn't barking",
      },
      {
        id: 81,
        expression: "Собака лает",
        transcription: "ðə dɒg z ˈbɑːkɪŋ",
        translation: "The dog is barking",
      },
      {
        id: 82,
        expression: "Собака не лает очень громко",
        transcription: "ðə dɒg ˈɪznt ˈbɑːkɪŋ ˈvɛri ˈlaʊdli",
        translation: "The dog isn't barking very loudly",
      },
      {
        id: 83,
        expression: "Он не пьет пиво сейчас. Он пьет сок",
        transcription: "hi ˈɪznt ˈdrɪŋkɪŋ bɪə naʊ. hiːz ˈdrɪŋkɪŋ ʤuːs",
        translation: "He isn't drinking beer now. He's drinking juice",
      },
      {
        id: 84,
        expression: "Я не пью кофе сейчас. Я пью чай",
        transcription: "aɪm nɒt ˈdrɪŋkɪŋ ˈkɒfi naʊ. aɪm ˈdrɪŋkɪŋ tiː",
        translation: "I'm not drinking coffee now. I'm drinking tea",
      },
      {
        id: 85,
        expression: "Он не пьет сок сейчас. Он пьет пиво",
        transcription: "hi ˈɪznt ˈdrɪŋkɪŋ ʤuːs naʊ. hiːz ˈdrɪŋkɪŋ bɪə",
        translation: "He isn't drinking juice now. He's drinking beer",
      },
      {
        id: 86,
        expression: "Это не приносит ему каких-либо денег сейчас",
        transcription: "ɪt ˈɪznt ˈbrɪŋɪŋ ɪm ˈɛni ˈmʌni naʊ",
        translation: "It isn't bringing him any money now",
      },
      {
        id: 87,
        expression: "Это приносит ему много денег сейчас",
        transcription: "ɪts ˈbrɪŋɪŋ ɪm mʌʧ ˈmʌni naʊ",
        translation: "It's bringing him much money now",
      },
      {
        id: 88,
        expression: "Он не дает мне каких-либо денег сейчас",
        transcription: "hi ˈɪznt ˈgɪvɪŋ mi ˈɛni ˈmʌni naʊ",
        translation: "He isn't giving me any money now",
      },
      {
        id: 89,
        expression: "Эта вещь мне сейчас не помогает",
        transcription: "ðɪs θɪŋ ˈɪznt ˈhɛlpɪŋ mi naʊ",
        translation: "This thing isn't helping me now",
      },
      {
        id: 90,
        expression: "Эта вещь сейчас действительно мне помогает",
        transcription: "ðɪs θɪŋ z ˈrɪəli ˈhɛlpɪŋ mi naʊ",
        translation: "This thing is really helping me now",
      },
      {
        id: 91,
        expression: "Она сейчас мне не помогает",
        transcription: "ʃi ˈɪznt ˈhɛlpɪŋ mi naʊ",
        translation: "She isn't helping me now",
      },
      {
        id: 92,
        expression: "Люди не покупают это сейчас",
        transcription: "ˈpiːpl ɑːnt ˈbaɪɪŋ ɪt naʊ",
        translation: "People aren't buying it now",
      },
      {
        id: 93,
        expression: "Цены [конкретные] не становятся ниже",
        transcription: "ðə ˈpraɪsɪz ɑːnt ˈgɛtɪŋ ˈləʊə",
        translation: "The prices aren't getting lower",
      },
      {
        id: 94,
        expression: "Дождь не идет. Идет снег",
        transcription: "ɪt ˈɪznt ˈreɪnɪŋ. ɪts ˈsnəʊɪŋ",
        translation: "It isn't raining. It's snowing",
      },
      {
        id: 95,
        expression: "Я не завтракаю сейчас",
        transcription: "aɪm nɒt ˈhævɪŋ ˈbrɛkfəst naʊ",
        translation: "I'm not having breakfast now",
      },
      {
        id: 96,
        expression: "Он не изучает французский. Он изучает немецкий",
        transcription: "hi ˈɪznt ˈlɜːnɪŋ frɛnʧ. hiːz ˈlɜːnɪŋ ˈʤɜːmən",
        translation: "He isn't learning French. He's learning German",
      },
      {
        id: 97,
        expression: "Я не отдыхаю. Я работаю",
        transcription: "aɪm nɒt ˈhævɪŋ ə rɛst. aɪm ˈwɜːkɪŋ",
        translation: "I'm not having a rest. I'm working",
      },
      {
        id: 98,
        expression: "Я не думаю об этом. Я думаю о другой вещи",
        transcription:
          "aɪm nɒt ˈθɪŋkɪŋ əˈbaʊt ɪt. aɪm ˈθɪŋkɪŋ əˈbaʊt əˈnʌðə θɪŋ",
        translation:
          "I'm not thinking about it. I'm thinking about another thing",
      },
      {
        id: 99,
        expression: "Я не пью кофе сейчас. Я пью чай",
        transcription: "aɪm nɒt ˈdrɪŋkɪŋ ˈkɒfi naʊ. aɪm ˈdrɪŋkɪŋ tiː",
        translation: "I'm not drinking coffee now. I'm drinking tea",
      },
      {
        id: 100,
        expression: "Дни не становятся солнечнее",
        transcription: "ðə deɪz ɑːnt ˈgɛtɪŋ ˈsʌnɪə",
        translation: "The days aren't getting sunnier",
      },
      {
        id: 101,
        expression: "Ветер не дует сейчас",
        transcription: "ðə wɪnd ˈɪznt ˈbləʊɪŋ naʊ",
        translation: "The wind isn't blowing now",
      },
      {
        id: 102,
        expression: "Собаки не лают",
        transcription: "ðə dɒgz ɑːnt ˈbɑːkɪŋ",
        translation: "The dogs aren't barking",
      },
      {
        id: 103,
        expression: "Снег не идет. Идет дождь",
        transcription: "ɪt ˈɪznt ˈsnəʊɪŋ. ɪts ˈreɪnɪŋ",
        translation: "It isn't snowing. It's raining",
      },
      {
        id: 104,
        expression: "Солнце не светит",
        transcription: "ðə sʌn ˈɪznt ˈʃaɪnɪŋ",
        translation: "The sun isn't shining",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Отрицание в Present Continuous</h3>
<p>Строится по формуле:</p>
<p><span>подлежащее + форма глагола to be в настоящем времени + not + глагол+ing + ...</span></p>
<p>He is not (isn't) sleepng - Он не спит сейчас.</p>
<p>I am not (I'm not) working - Я не работаю сейчас.</p>
<p>They are not (aren't) playing football - Они не играют в футбол сейчас.</p>
</div>`,
    },
  ],
];
