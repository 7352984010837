export const wordsLesson61 = [
  [
    {
      id: 1,
      word: 'agency',
      transcription: 'ˈeɪʤənsi',
      translation: 'агентство',
    },
    {
      id: 2,
      word: 'behaviour',
      transcription: 'bɪˈheɪvjə',
      translation: 'поведение',
    },
    {
      id: 3,
      word: 'classmate',
      transcription: 'ˈklɑːsmeɪt',
      translation: 'одноклассник',
    },
    {
      id: 4,
      word: 'client',
      transcription: 'ˈklaɪənt',
      translation: 'клиент',
    },
    {
      id: 5,
      word: 'comfortable',
      transcription: 'ˈkʌmf(ə)təbl',
      translation: 'удобный',
    },
    {
      id: 6,
      word: 'complain',
      transcription: 'kəmˈpleɪn',
      translation: 'жаловаться',
    },
    {
      id: 7,
      word: 'condition',
      transcription: 'kənˈdɪʃən',
      translation: 'условие',
    },
    {
      id: 8,
      word: 'consider',
      transcription: 'kənˈsɪdə',
      translation: 'считать, рассматривать',
    },
    {
      id: 9,
      word: 'design',
      transcription: 'dɪˈzaɪn',
      translation: 'дизайн',
    },
    {
      id: 10,
      word: 'employee',
      transcription: 'ˌɛmplɔɪˈiː',
      translation: 'сотрудник',
    },
    {
      id: 11,
      word: 'exist',
      transcription: 'ɪgˈzɪst',
      translation: 'существовать',
    },
    {
      id: 12,
      word: 'groupmate',
      transcription: 'gruːpmeɪt',
      translation: 'одногруппник',
    },
    {
      id: 13,
      word: 'invest',
      transcription: 'ɪnˈvɛst',
      translation: 'вкладывать, инвестировать',
    },
    {
      id: 14,
      word: 'investor',
      transcription: 'ɪnˈvɛstə',
      translation: 'инвестор',
    },
    {
      id: 15,
      word: 'matter',
      transcription: 'ˈmætə',
      translation: 'иметь значение',
    },
    {
      id: 16,
      word: 'overpay',
      transcription: 'ˌəʊvəˈpeɪ',
      translation: 'переплачивать',
    },
    {
      id: 17,
      word: 'perfect',
      transcription: 'ˈpɜːfɪkt',
      translation: 'идеальный',
    },
    {
      id: 18,
      word: 'profit',
      transcription: 'ˈprɒfɪt',
      translation: 'прибыль',
    },
    {
      id: 19,
      word: 'quality',
      transcription: 'ˈkwɒlɪti',
      translation: 'качество',
    },
    {
      id: 20,
      word: 'reliable',
      transcription: 'rɪˈlaɪəbl',
      translation: 'надежный',
    },
    {
      id: 21,
      word: 'robot',
      transcription: 'ˈrəʊbɒt',
      translation: 'робот',
    },
    {
      id: 22,
      word: 'sales',
      transcription: 'seɪlz',
      translation: 'продажи',
    },
    {
      id: 23,
      word: 'satisfaction',
      transcription: 'ˌsætɪsˈfækʃən',
      translation: 'удовлетворение',
    },
    {
      id: 24,
      word: 'science',
      transcription: 'ˈsaɪəns',
      translation: 'наука',
    },
    {
      id: 25,
      word: 'seriously',
      transcription: 'ˈsɪərɪəsli',
      translation: 'серьезно',
    },
    {
      id: 26,
      word: 'shirt',
      transcription: 'ʃɜːt',
      translation: 'рубашка',
    },
    {
      id: 27,
      word: 'size',
      transcription: 'saɪz',
      translation: 'размер',
    },
    {
      id: 28,
      word: 'skirt',
      transcription: 'skɜːt',
      translation: 'юбка',
    },
    {
      id: 29,
      word: 'surprising',
      transcription: 'səˈpraɪzɪŋ',
      translation: 'удивительный',
    },
    {
      id: 30,
      word: 'tourist',
      transcription: 'ˈtʊərɪst',
      translation: 'турист',
    },
    {
      id: 31,
      word: 'weight',
      transcription: 'weɪt',
      translation: 'взвешивать',
    },
  ],
];
