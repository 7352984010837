export const contentLesson143 = [
  [
    {
      title: 'Урок 143. 230 Adjectives. Part 5',
    },
    [
      {
        id: 1,
        expression: 'Это очень распространенный вопрос',
        transcription: 'ɪts ə ˈvɛri ˈkɒmən ˈkwɛsʧən',
        translation: "It's a very common question",
      },
      {
        id: 2,
        expression: 'Этот человек выглядит очень подозрительным',
        transcription: 'ðɪs mæn lʊks ˈvɛri səsˈpɪʃəs',
        translation: 'This man looks very suspicious',
      },
      {
        id: 3,
        expression: 'У него очень сильный характер',
        transcription: 'hi həz ə ˈvɛri strɒŋ ˈkærɪktə',
        translation: 'He has a very strong character',
      },
      {
        id: 4,
        expression: 'Это может быть опасным для нашего здоровья',
        transcription: 'ɪts kən bi ˈdeɪnʤrəs fər ˈaʊə hɛlθ',
        translation: "It's can be dangerous for our health",
      },
      {
        id: 5,
        expression: 'Ему нужно быть менее агрессивным',
        transcription: 'hi niːdz tə bi lɛs əˈgrɛsɪv',
        translation: 'He needs to be less aggressive',
      },
      {
        id: 6,
        expression: 'Это очень опасное место',
        transcription: 'ɪts ə ˈvɛri ˈdeɪnʤrəs pleɪs',
        translation: "It's a very dangerous place",
      },
      {
        id: 7,
        expression: 'Это более формальный ответ',
        transcription: 'ɪts ə mɔː ˈfɔːməl ˈɑːnsə',
        translation: "It's a more formal answer",
      },
      {
        id: 8,
        expression: 'Это такая длинная история',
        transcription: 'ɪts sʌʧ ə lɒŋ ˈstɔːri',
        translation: "It's such a long story",
      },
      {
        id: 9,
        expression: 'Их поведение такое агрессивное',
        transcription: 'ðeə bɪˈheɪvjə z səʊ əˈgrɛsɪv',
        translation: 'Their behaviour is so aggressive',
      },
      {
        id: 10,
        expression: 'Он очень ценный работник',
        transcription: 'hiːz ə ˈvɛri ˈvæljʊəbl ˈwɜːkə',
        translation: "He's a very valuable worker",
      },
      {
        id: 11,
        expression: 'Это более дешевый отель',
        transcription: 'ɪts ə ˈʧiːpə həʊˈtɛl',
        translation: "It's a cheaper hotel",
      },
      {
        id: 12,
        expression: 'Когда открылся этот магазин?',
        transcription: 'wɛn dɪd ðɪs ʃɒp ˈəʊpən? / wɛn wəz ðɪs ʃɒp ˈəʊpən?',
        translation: 'When did this shop open? / When was this shop open?',
      },
      {
        id: 13,
        expression: 'Это очень формальный ответ',
        transcription: 'ɪts ə ˈvɛri ˈfɔːməl ˈɑːnsə',
        translation: "It's a very formal answer",
      },
      {
        id: 14,
        expression: 'Это не так очевидно для каждого',
        transcription: 'ɪt ˈɪznt səʊ ˈɒbvɪəs fər ˈɛvrɪwʌn',
        translation: "It isn't so obvious for everyone",
      },
      {
        id: 15,
        expression: 'Это более жаркая страна',
        transcription: 'ɪts ə ˈhɒtə ˈkʌntri',
        translation: "It's a hotter country",
      },
      {
        id: 16,
        expression: 'Он очень храбрый солдат',
        transcription: 'hiːz ə ˈvɛri breɪv ˈsəʊlʤə',
        translation: "He's a very brave soldier",
      },
      {
        id: 17,
        expression: 'Этот дом довольно большой',
        transcription: 'ðɪs haʊs ɪz kwaɪt lɑːʤ',
        translation: 'This house is quite large',
      },
      {
        id: 18,
        expression: 'Это неправильно',
        transcription: 'ɪts rɒŋ',
        translation: "It's wrong",
      },
      {
        id: 19,
        expression: 'Погода прекрасная',
        transcription: 'ðə ˈwɛðə z faɪn',
        translation: 'The weather is fine',
      },
      {
        id: 20,
        expression: 'Девочке очень грустно',
        transcription: 'ðə gɜːl fiːlz ˈvɛri sæd',
        translation: 'The girl feels very sad',
      },
      {
        id: 21,
        expression: 'Он такой ценный работник',
        transcription: 'hiːz sʌʧ ə ˈvæljʊəbl ˈwɜːkə',
        translation: "He's such a valuable worker",
      },
      {
        id: 22,
        expression: 'Это местная газета',
        transcription: 'ɪts ə ˈləʊkəl ˈnjuːzˌpeɪpə',
        translation: "It's a local newspaper",
      },
      {
        id: 23,
        expression: 'Это очень глупый вопрос',
        transcription: 'ɪts ə ˈvɛri ˈsɪli ˈkwɛsʧən',
        translation: "It's a very silly question",
      },
      {
        id: 24,
        expression: 'Они такие храбрые люди',
        transcription: 'ðeə sʌʧ breɪv ˈpiːpl',
        translation: "They're such brave people",
      },
      {
        id: 25,
        expression: 'Это более холодное место',
        transcription: 'ɪts ə ˈkəʊldə pleɪs',
        translation: "It's a colder place",
      },
      {
        id: 26,
        expression: 'Здесь так темно',
        transcription: 'ɪts səʊ dɑːk hɪə',
        translation: "It's so dark here",
      },
      {
        id: 27,
        expression: 'Здесь очень холодно',
        transcription: 'ɪts ˈvɛri kəʊld hɪə',
        translation: "It's very cold here",
      },
      {
        id: 28,
        expression: 'Это не кажется таким очевидным',
        transcription: 'ɪt dʌznt siːm səʊ ˈɒbvɪəs',
        translation: "It doesn't seem so obvious",
      },
      {
        id: 29,
        expression: 'Мальчики голодны?',
        transcription: 'ɑː ðə bɔɪz ˈhʌŋgri?',
        translation: 'Are the boys hungry?',
      },
      {
        id: 30,
        expression: 'Это необходимый шаг',
        transcription: 'ɪts ə ˈnɛsɪsəri stɛp',
        translation: "It's a necessary step",
      },
      {
        id: 31,
        expression: 'Они очень сильные мужчины',
        transcription: 'ðeə ˈvɛri strɒŋ mɛn',
        translation: "They're very strong men",
      },
      {
        id: 32,
        expression: 'Это очень узкая улица',
        transcription: 'ɪts ə ˈvɛri ˈnærəʊ striːt',
        translation: "It's a very narrow street",
      },
      {
        id: 33,
        expression: 'Эти материалы очень ценные',
        transcription: 'ðiːz məˈtɪərɪəlz ə ˈvɛri ˈvæljʊəbl',
        translation: 'These materials are very valuable',
      },
      {
        id: 34,
        expression: 'Незнакомец выглядит очень подозрительно',
        transcription: 'ðə ˈstreɪnʤə lʊks ˈvɛri səsˈpɪʃəs',
        translation: 'The stranger looks very suspicious',
      },
      {
        id: 35,
        expression: 'Он живет в большом доме',
        transcription: 'hi lɪvz ɪn ə lɑːʤ haʊs',
        translation: 'He lives in a large house',
      },
      {
        id: 36,
        expression: 'Магазин открыт',
        transcription: 'ðə ʃɒp s ˈəʊpən',
        translation: 'The shop is open',
      },
      {
        id: 37,
        expression: 'Все хорошо',
        transcription: 'ˈɛvrɪθɪŋ z faɪn',
        translation: 'Everything is fine',
      },
      {
        id: 38,
        expression: 'Это был коммерческий проект',
        transcription: 'ɪt wəz ə kəˈmɜːʃəl ˈprɒʤɛkt',
        translation: 'It was a commercial project',
      },
      {
        id: 39,
        expression: 'Он здоров сейчас',
        transcription: 'hiːz ˈhɛlθi naʊ',
        translation: "He's healthy now",
      },
      {
        id: 40,
        expression: 'Это такой простой метод',
        transcription: 'ɪts sʌʧ ə ˈsɪmpl ˈmɛθəd',
        translation: "It's such a simple method",
      },
      {
        id: 41,
        expression: 'Ты действительно этим гордишься?',
        transcription: 'ɑː jʊ ˈrɪəli praʊd əv ɪt',
        translation: 'Are you really proud of it?',
      },
      {
        id: 42,
        expression: 'Это одно из самых замечательных недавних открытий',
        transcription: 'ɪts wʌn əv ðə məʊst ˈwʌndəfʊl ˈriːsnt dɪsˈkʌvəriz',
        translation: "It's one of the most wonderful recent discoveries",
      },
      {
        id: 43,
        expression: 'Я бы хотел пожелать тебе настоящего счастья',
        transcription: 'aɪd laɪk tə wɪʃ jʊ truː ˈhæpɪnɪs',
        translation: "I'd like to wish you true happiness",
      },
      {
        id: 44,
        expression: 'Я абсолютно уверен, что я смогу это сделать',
        transcription: 'aɪm ˈæbsəluːtli ˈsɜːtn ðət ɪl bi ˈeɪbl tə dʊ ɪt',
        translation: "I'm absolutely certain that I'll be able to do it",
      },
      {
        id: 45,
        expression: 'Какая главная проблема?',
        transcription: 'wɒts ðə meɪn ˈprɒbləm?',
        translation: "What's the main problem?",
      },
      {
        id: 46,
        expression: 'Он хочет жить в более тихом месте',
        transcription: 'hi wɒnts tə lɪv ɪn ə ˈkwaɪətə pleɪs',
        translation: 'He wants to live in a quieter place',
      },
      {
        id: 47,
        expression:
          'Наш учитель говорит, что это очень распространенная ошибка',
        transcription: 'ˈaʊə ˈtiːʧə sɛz ðət ɪts ə ˈvɛri ˈkɒmən mɪsˈteɪk',
        translation: "Our teacher says that it's a very common mistake",
      },
      {
        id: 48,
        expression:
          'Некоторые студенты изучают английский язык для определенных целей',
        transcription: 'sʌm ˈstjuːdənts ˈstʌdi ˈɪŋglɪʃ fə spɪˈsɪfɪk ˈpɜːpəsɪz',
        translation: 'Some students study English for specific purposes',
      },
      {
        id: 49,
        expression: 'Это одно из величайших недавних открытий',
        transcription: 'ɪts wʌn əv ðə ˈgreɪtɪst ˈriːsnt dɪsˈkʌvəriz',
        translation: "It's one of the greatest recent discoveries",
      },
      {
        id: 50,
        expression:
          'Я знаю, что некоторые люди изучают иностранный язык для определенных целей',
        transcription:
          'aɪ nəʊ ðət səm ˈpiːpl lɜːn ˈfɒrɪn ˈlæŋgwɪʤ fə spɪˈsɪfɪk ˈriːznz',
        translation:
          'I know that some people learn foreign language for specific reasons',
      },
      {
        id: 51,
        expression: 'Это одна из главных проблем',
        transcription: 'ɪts wʌn əv ðə meɪn ˈprɒbləmz',
        translation: "It's one of the main problems",
      },
      {
        id: 52,
        expression: 'Это один из самых распространенных вопросов',
        transcription: 'ɪts wʌn əv ðə məʊst ˈkɒmən ˈkwɛsʧənz',
        translation: "It's one of the most common questions",
      },
      {
        id: 53,
        expression: 'Это была главная проблема',
        transcription: 'ɪt wəz ðə meɪn ˈprɒbləm',
        translation: 'It was the main problem',
      },
      {
        id: 54,
        expression: 'Это такая важная вещь для всех нас',
        transcription: 'ɪts sʌʧ ən ɪmˈpɔːtənt θɪŋ fər ɔːl əv ʌs',
        translation: "It's such an important thing for all of us",
      },
      {
        id: 55,
        expression: 'Это одна из самых распространенных ошибок',
        transcription: 'ɪts wʌn əv ðə məʊst ˈkɒmən mɪsˈteɪks',
        translation: "It's one of the most common mistakes",
      },
      {
        id: 56,
        expression: 'Я горжусь твоими достижениями',
        transcription: 'aɪm praʊd əv jər əˈʧiːvmənts',
        translation: "I'm proud of your achievements",
      },
      {
        id: 57,
        expression: 'Я горжусь своими результатами',
        transcription: 'aɪm praʊd əv maɪ rɪˈzʌlts',
        translation: "I'm proud of my results",
      },
      {
        id: 58,
        expression: 'Ты гордишься своими детьми?',
        transcription: 'ɑː jʊ praʊd əv jə ˈʧɪldrən?',
        translation: 'Are you proud of your children?',
      },
      {
        id: 59,
        expression: 'Я хочу вести здоровый образ жизни',
        transcription: 'aɪ wɒnt tə liːd ə ˈhɛlθi ˈlaɪfˌstaɪl',
        translation: 'I want to lead a healthy lifestyle',
      },
      {
        id: 60,
        expression: 'Я хочу быть более здоровым',
        transcription: 'aɪ wɒnt tə bi ˈhɛlθɪə',
        translation: 'I want to be healthier',
      },
      {
        id: 61,
        expression: 'Я стараюсь вести более здоровый образ жизни',
        transcription: 'aɪ traɪ tə liːd ə ˈhɛlθɪə ˈlaɪfˌstaɪl',
        translation: 'I try to lead a healthier lifestyle',
      },
      {
        id: 62,
        expression: 'Ты опоздал на урок?',
        transcription: 'wə jʊ leɪt fə ðə ˈlɛsn?',
        translation: 'Were you late for the lesson?',
      },
      {
        id: 63,
        expression: 'Почему ты опоздал на этот урок?',
        transcription: 'waɪ wə jʊ leɪt fə ðɪs ˈlɛsn?',
        translation: 'Why were you late for this lesson?',
      },
      {
        id: 64,
        expression: 'Я опоздал на последний урок',
        transcription: 'aɪ wəz leɪt fə ðə lɑːst ˈlɛsn',
        translation: 'I was late for the last lesson',
      },
      {
        id: 65,
        expression: 'Я опоздал на встречу',
        transcription: 'aɪ wəz leɪt fə ðə ˈmiːtɪŋ',
        translation: 'I was late for the meeting',
      },
      {
        id: 66,
        expression: 'Я опоздал на эту конференцию',
        transcription: 'aɪ wəz leɪt fə ðɪs ˈkɒnfərəns',
        translation: 'I was late for this conference',
      },
      {
        id: 67,
        expression: 'Ты опоздал на встречу?',
        transcription: 'wə jʊ leɪt fə ðə ˈmiːtɪŋ?',
        translation: 'Were you late for the meeting?',
      },
      {
        id: 68,
        expression: 'Будет необходимо сделать это',
        transcription: 'ˈɪtl bi ˈnɛsɪsəri tə dʊ ɪt',
        translation: "It'll be necessary to do it",
      },
      {
        id: 69,
        expression: 'Необходимо сделать это вовремя',
        transcription: 'ɪts ˈnɛsɪsəri tə dʊ ɪt ɒn taɪm',
        translation: "It's necessary to do it on time",
      },
      {
        id: 70,
        expression: 'Необходимо будет найти это',
        transcription: 'ˈɪtl bi ˈnɛsɪsəri tə faɪnd ɪt',
        translation: "It'll be necessary to find it",
      },
      {
        id: 71,
        expression: 'Это одна из самых интересных вещей',
        transcription: 'ɪts wʌn əv ðə məʊst ˈɪntrɪstɪŋ θɪŋz',
        translation: "It's one of the most interesting things",
      },
      {
        id: 72,
        expression: 'Это одна из наименее важных вещей',
        transcription: 'ɪts wʌn əv ðə liːst ɪmˈpɔːtənt θɪŋz',
        translation: "It's one of the least important things",
      },
      {
        id: 73,
        expression: 'Это один из наиболее популярных певцов',
        transcription: 'ɪts wʌn əv ðə məʊst ˈpɒpjʊlə ˈsɪŋəz',
        translation: "It's one of the most popular singers",
      },
      {
        id: 74,
        expression: 'В этой стране климат намного жарче',
        transcription: 'ðɪs ˈkʌntri həz ə mʌʧ ˈhɒtə ˈklaɪmɪt',
        translation: 'This country has a much hotter climate',
      },
      {
        id: 75,
        expression: 'В этой стране самый жаркий климат',
        transcription: 'ðɪs ˈkʌntri həz ðə ˈhɒtɪst ˈklaɪmɪt',
        translation: 'This country has the hottest climate',
      },
      {
        id: 76,
        expression: 'В этой стране самые высокие небоскребы',
        transcription: 'ðɪs ˈkʌntri həz ðə ˈhaɪɪst ˈskaɪˌskreɪpəz',
        translation: 'This country has the highest skyscrapers',
      },
      {
        id: 77,
        expression: 'Этот мальчик не такой высокий, как тот',
        transcription: 'ðɪs bɔɪ ˈɪznt əz tɔːl əz ðət wʌn',
        translation: "This boy isn't as tall as that one",
      },
      {
        id: 78,
        expression: 'Эта женщина не такая молодая, как та',
        transcription: 'ðɪs ˈwʊmən ˈɪznt əz jʌŋ əz ðət wʌn',
        translation: "This woman isn't as young as that one",
      },
      {
        id: 79,
        expression: 'Я не такой смелый, как ты',
        transcription: 'aɪm nɒt əz breɪv əz juː',
        translation: "I'm not as brave as you",
      },
      {
        id: 80,
        expression: 'Это было главной причиной',
        transcription: 'ɪt wəz ðə meɪn ˈriːzn',
        translation: 'It was the main reason',
      },
      {
        id: 81,
        expression: 'Это было самое важное решение',
        transcription: 'ɪt wəz ðə məʊst ɪmˈpɔːtənt dɪˈsɪʒən',
        translation: 'It was the most important decision',
      },
      {
        id: 82,
        expression: 'Это главное правило',
        transcription: 'ɪts ðə meɪn ruːl',
        translation: "It's the main rule",
      },
      {
        id: 83,
        expression: 'Это один из самых дешевых отелей в этом районе',
        transcription: 'ɪts wʌn əv ðə ˈʧiːpɪst həʊˈtɛlz ɪn ðɪs ˈeərɪə',
        translation: "It's one of the cheapest hotels in this area",
      },
      {
        id: 84,
        expression: 'Этот отель дешевле, чем тот',
        transcription: 'ðɪs həʊˈtɛl z ˈʧiːpə ðən ðət wʌn',
        translation: 'This hotel is cheaper than that one',
      },
      {
        id: 85,
        expression: 'Это один из самых популярных отелей в этом городе',
        transcription: 'ɪts wʌn əv ðə məʊst ˈpɒpjʊlə həʊˈtɛlz ɪn ðɪs ˈsɪti',
        translation: "It's one of the most popular hotels in this city",
      },
      {
        id: 86,
        expression: 'Необходимо сделать это сейчас',
        transcription: 'ɪts ˈnɛsɪsəri tə dʊ ɪt naʊ',
        translation: "It's necessary to do it now",
      },
      {
        id: 87,
        expression: 'Все понимают, что это очень глупо делать',
        transcription: 'ˈɛvrɪwʌn ˌʌndəˈstændz ðət ɪts ˈvɛri ˈsɪli tə duː',
        translation: "Everyone understands that it's very silly to do",
      },
      {
        id: 88,
        expression: 'Это одна из главных причин',
        transcription: 'ɪts wʌn əv ðə meɪn ˈriːznz',
        translation: "It's one of the main reasons",
      },
      {
        id: 89,
        expression: 'Я действительно горжусь этим',
        transcription: 'aɪm ˈrɪəli praʊd əv ɪt',
        translation: "I'm really proud of it",
      },
      {
        id: 90,
        expression: 'Эта улица не такая узкая, как та',
        transcription: 'ðɪs striːt ˈɪznt əz ˈnærəʊ əz ðət wʌn',
        translation: "This street isn't as narrow as that one",
      },
      {
        id: 91,
        expression: 'Тот человек не такой сильный, как этот',
        transcription: 'ðæt mæn ˈɪznt əz strɒŋ əz ðɪs wʌn',
        translation: "That man isn't as strong as this one",
      },
      {
        id: 92,
        expression: 'Тот метод не такой простой, как этот',
        transcription: 'ðæt ˈmɛθəd ˈɪznt əz ˈsɪmpl əz ðɪs wʌn',
        translation: "That method isn't as simple as this one",
      },
      {
        id: 93,
        expression: 'Я думаю, он не на правильном пути',
        transcription: 'aɪ θɪŋk hi z ɒn ðə rɒŋ weɪ',
        translation: 'I think he is on the wrong way',
      },
      {
        id: 94,
        expression: 'Какая главная причина?',
        transcription: 'wɒts ðə meɪn ˈriːzn?',
        translation: "What's the main reason?",
      },
      {
        id: 95,
        expression: 'Какой у тебя рост?',
        transcription: 'haʊ tɔːl ə juː?',
        translation: 'How tall are you?',
      },
      {
        id: 96,
        expression: 'Он часто опаздывает',
        transcription: 'hiːz ˈɒf(ə)n leɪt',
        translation: "He's often late",
      },
      {
        id: 97,
        expression: 'Я уверен, что это правда',
        transcription: 'aɪm ˈsɜːtn ðət ɪts truː',
        translation: "I'm certain that it's true",
      },
      {
        id: 98,
        expression: 'Здесь гораздо тише',
        transcription: 'ɪts mʌʧ ˈkwaɪətə hɪə',
        translation: "It's much quieter here",
      },
      {
        id: 99,
        expression: 'Здесь так жарко',
        transcription: 'ɪts səʊ hɒt hɪə',
        translation: "It's so hot here",
      },
      {
        id: 100,
        expression: 'Я надеюсь, это правда',
        transcription: 'aɪm həʊp ɪts truː',
        translation: "I'm hope it's true",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Закрепление пройденного материала</h3>
</div>`,
    },
  ],
];
