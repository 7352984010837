export const contentLesson78 = [
  [
    {
      title: "Урок 78. Future Simple. Negative Form",
    },
    [
      {
        id: 1,
        expression: "Я не буду смотреть этот фильм",
        transcription: "aɪ wəʊnt wɒʧ ðɪs ˈmuːvi",
        translation: "I won't watch this movie",
      },
      {
        id: 2,
        expression: "Он не будет жаловаться",
        transcription: "hi wəʊnt kəmˈpleɪn",
        translation: "He won't complain",
      },
      {
        id: 3,
        expression: "Обсуждение не закончится через десять минут",
        transcription: "ðə dɪsˈkʌʃən wəʊnt ˈfɪnɪʃ ɪn tɛn ˈmɪnɪts",
        translation: "The discussion won't finish in ten minutes",
      },
      {
        id: 4,
        expression: "Полиция не закроет на это свои глаза",
        transcription: "ðə pəˈliːs wəʊnt kləʊs ðeər aɪz ɒn ɪt",
        translation: "The police won't close their eyes on it",
      },
      {
        id: 5,
        expression: "Он не будет принимать участие в этом чемпионате",
        transcription: "hi wəʊnt teɪk pɑːt ɪn ðɪs ˈʧæmpiənʃɪp",
        translation: "He won't take part in this championship",
      },
      {
        id: 6,
        expression: "Я не буду впустую тратить свое время на это",
        transcription: "aɪ wəʊnt weɪst taɪm ɒn ɪt",
        translation: "I won't waste time on it",
      },
      {
        id: 7,
        expression: "Возможно, это не будет так легко для тебя",
        transcription: "pəˈhæps, ɪt wəʊnt bi səʊ ˈiːzi fə juː",
        translation: "Perhaps, it won't be so easy for you",
      },
      {
        id: 8,
        expression: "Игра не будет так интересна для тебя",
        transcription: "ðə geɪm wəʊnt bi səʊ ˈɪntrɪstɪŋ fə juː",
        translation: "The game won't be so interesting for you",
      },
      {
        id: 9,
        expression: "Он не будет его больше ждать",
        transcription: "hi wəʊnt weɪt fə hɪm ˈɛni ˈlɒŋgə",
        translation: "He won't wait for him any longer",
      },
      {
        id: 10,
        expression: "Она не будет слушать его совет",
        transcription: "ʃi wəʊnt ˈlɪsnɪŋ tə ɪz ədˈvaɪs",
        translation: "She won't listening to his advice",
      },
      {
        id: 11,
        expression: "Я не буду участвовать в этом соревновании",
        transcription: "aɪ wəʊnt teɪk pɑːt ɪn ðɪs ˌkɒmpɪˈtɪʃən",
        translation: "I won't take part in this competition",
      },
      {
        id: 12,
        expression: "Эта идея не будет новой для других",
        transcription: "ðɪs aɪˈdɪə wəʊnt bi njuː fər ˈʌðəz",
        translation: "This idea won't be new for others",
      },
      {
        id: 13,
        expression: "Мои друзья не пойдут туда со мной",
        transcription: "maɪ frɛndz wəʊnt gəʊ ðeə wɪð miː",
        translation: "My friends won't go there with me",
      },
      {
        id: 14,
        expression: "Она не придет на вечеринку",
        transcription: "ʃi wəʊnt kʌm tə ðə ˈpɑːti",
        translation: "She won't come to the party",
      },
      {
        id: 15,
        expression: "Конференция не начнется немедленно",
        transcription: "ðə ˈkɒnfərəns wəʊnt stɑːt (bɪˈgɪn) ɪˈmiːdiətli",
        translation: "The conference won't start (begin) immediately",
      },
      {
        id: 16,
        expression: "Она не пойдет в театр завтра",
        transcription: "ʃi wəʊnt gəʊ tə ðə ˈθɪətə təˈmɒrəʊ",
        translation: "She won't go to the theatre tomorrow",
      },
      {
        id: 17,
        expression: "Лекция не продлится так долго",
        transcription: "ðə ˈlɛkʧə wəʊnt lɑːst səʊ lɒŋ",
        translation: "The lecture won't last so long",
      },
      {
        id: 18,
        expression: "Я не буду на это реагировать",
        transcription: "aɪ wəʊnt ri(ː)ˈækt tʊ ɪt",
        translation: "I won't react to it",
      },
      {
        id: 19,
        expression: "Это его не остановит",
        transcription: "ɪt wəʊnt stɒp hɪm",
        translation: "It won't stop him",
      },
      {
        id: 20,
        expression: "Я думаю, он этого не скажет",
        transcription: "aɪ θɪŋk hi wəʊnt seɪ ɪt",
        translation: "I think he won't say it",
      },
      {
        id: 21,
        expression: "День не будет дождливым",
        transcription: "ðə deɪ wəʊnt bi ˈreɪni",
        translation: "The day won't be rainy",
      },
      {
        id: 22,
        expression: "Я не закрою дверь",
        transcription: "aɪ wəʊnt kləʊs ðə dɔː",
        translation: "I won't close the door",
      },
      {
        id: 23,
        expression: "Дни не будут такими солнечными",
        transcription: "ðə deɪz wəʊnt bi səʊ ˈsʌni",
        translation: "The days won't be so sunny",
      },
      {
        id: 24,
        expression: "Цена не будет ниже",
        transcription: "ðə praɪs wəʊnt bi ˈləʊə",
        translation: "The price won't be lower",
      },
      {
        id: 25,
        expression: "Это не улучшит ситуацию",
        transcription: "ɪt wəʊnt ɪmˈpruːv ðə ˌsɪtjʊˈeɪʃən",
        translation: "It won't improve the situation",
      },
      {
        id: 26,
        expression:
          "Возможно, я не буду покупать новый компьютер на этой неделе",
        transcription: "pəˈhæps, aɪ wəʊnt baɪ ə njuː kəmˈpjuːtə ðɪs wiːk",
        translation: "Perhaps, I won't buy a new computer this week",
      },
      {
        id: 27,
        expression: "Моему другу не понравится эта идея",
        transcription: "maɪ frɛnd wəʊnt laɪk ðɪs aɪˈdɪə",
        translation: "My friend won't like this idea",
      },
      {
        id: 28,
        expression: "Они не поддержат эту инициативу",
        transcription: "ðeɪ wəʊnt səˈpɔːt ðɪs ɪˈnɪʃɪətɪv",
        translation: "They won't support this initiative",
      },
      {
        id: 29,
        expression: "Она не придет к нам",
        transcription: "ʃi wəʊnt kʌm tʊ ʌs",
        translation: "She won't come to us",
      },
      {
        id: 30,
        expression: "Уроки не будут длиться долго",
        transcription: "ðə ˈlɛsnz wəʊnt lɑːst lɒŋ",
        translation: "The lessons won't last long",
      },
      {
        id: 31,
        expression: "Ему не понадобиться эта информация",
        transcription: "hi wəʊnt niːd ðɪs ˌɪnfəˈmeɪʃən",
        translation: "He won't need this information",
      },
      {
        id: 32,
        expression: "Мы не узнаем наши результаты немедленно",
        transcription: "wi wəʊnt nəʊ ˈaʊə rɪˈzʌlts ɪˈmiːdiətli",
        translation: "We won't know our results immediately",
      },
      {
        id: 33,
        expression: "Мы не будем реагировать на это",
        transcription: "wi wəʊnt ri(ː)ˈækt tʊ ɪt",
        translation: "We won't react to it",
      },
      {
        id: 34,
        expression: "Он не будет слушать ее",
        transcription: "hi wəʊnt ˈlɪsn tə hɜː",
        translation: "He won't listen to her",
      },
      {
        id: 35,
        expression: "Эта вещь не сильно тебе поможет",
        transcription: "ðɪs θɪŋ wəʊnt hɛlp jʊ mʌʧ",
        translation: "This thing won't help you much",
      },
      {
        id: 36,
        expression: "Он не купит машину в этом году",
        transcription: "hi wəʊnt baɪ ə kɑː ðɪs jɪə",
        translation: "He won't buy a car this year",
      },
      {
        id: 37,
        expression: "Она не примет участие в этом конкурсе",
        transcription: "ʃi wəʊnt pɑːˈtɪsɪpeɪt ɪn ðɪs ˈkɒntɛst",
        translation: "She won't participate in this contest",
      },
      {
        id: 38,
        expression: "Я больше не буду этого делать",
        transcription: "aɪ wəʊnt dʊ ɪt ˌɛniˈmɔː",
        translation: "I won't do it anymore",
      },
      {
        id: 39,
        expression: "Она больше не будет ждать этого",
        transcription: "ʃi wəʊnt weɪt fər ɪt ˈɛni ˈlɒŋgə",
        translation: "She won't wait for it any longer",
      },
      {
        id: 40,
        expression: "Она не купит квартиру в этом месяце",
        transcription: "ʃi wəʊnt baɪ ən əˈpɑːtmənt ðɪs mʌnθ",
        translation: "She won't buy an apartment this month",
      },
      {
        id: 41,
        expression: "Это не будет интересно им",
        transcription: "ɪt wəʊnt bi ˈɪntrɪstɪŋ fə ðɛm",
        translation: "It won't be interesting for them",
      },
      {
        id: 42,
        expression: "У него не будет этой суммы денег завтра",
        transcription: "hi wəʊnt həv ðɪs sʌm əv ˈmʌni təˈmɒrəʊ",
        translation: "He won't have this sum of money tomorrow",
      },
      {
        id: 43,
        expression: "Она говорит, что не сможет прийти к нам сегодня вечером",
        transcription: "ʃi sɛz ðət ʃi wəʊnt bi ˈeɪbl tə kʌm tʊ əs təˈnaɪt",
        translation: "She says that she won't be able to come to us tonight",
      },
      {
        id: 44,
        expression: "Я постараюсь больше не делать этих ошибок",
        transcription: "aɪl traɪ nɒt tə meɪk ðiːz mɪsˈteɪks",
        translation: "I'll try not to make these mistakes",
      },
      {
        id: 45,
        expression: "Никто не заплатит за это",
        transcription: "nəʊ wʌn (ˈnəʊbədi) wɪl peɪ fər ɪt",
        translation: "No one (Nobody) will pay for it",
      },
      {
        id: 46,
        expression: "Мне кажется, что никто этого не сделает",
        transcription: "ɪt siːmz tə mi ðət nəʊ wʌn (ˈnəʊbədi) wɪl dʊ ɪt",
        translation: "It seems to me that no one (nobody) will do it",
      },
      {
        id: 47,
        expression:
          "Он не выучит английский язык через месяц, потому что мы все знаем, что это невозможно",
        transcription:
          "hi wəʊnt lɜːn ˈɪŋglɪʃ ɪn ə mʌnθ bɪˈkəz wi ɔːl nəʊ ðət ɪts ɪmˈpɒsəbl",
        translation:
          "He won't learn English in a month because we all know that it's impossible",
      },
      {
        id: 48,
        expression:
          "Это действительно странно, что он ничего не будет гарантировать нам",
        transcription:
          "ɪt ˈrɪəli streɪnʤ ðət hi wəʊnt ˌgærənˈtiː ˈɛnɪθɪŋ tʊ ʌs / ɪt ˈrɪəli streɪnʤ ðət hi wəʊnt ˌgærənˈtiː əs ˈɛnɪθɪŋ",
        translation:
          "It really strange that he won't guarantee anything to us / It really strange that he won't guarantee us anything",
      },
      {
        id: 49,
        expression:
          "Он говорит, что они не смогут разрешить этот конфликт через пару дней",
        transcription:
          "hi sɛz ðət ðeɪ wəʊnt bi ˈeɪbl tə rɪˈzɒlv ðɪs ˈkɒnflɪkt ɪn ə ˈkʌpl əv deɪz",
        translation:
          "He says that they won't be able to resolve this conflict in a couple of days",
      },
      {
        id: 50,
        expression: "Возможно, я скоро найду гораздо лучшую работу",
        transcription: "pəˈhæps, aɪl faɪnd ə mʌʧ ˈbɛtə ʤɒb suːn",
        translation: "Perhaps, I'll find a much better job soon",
      },
      {
        id: 51,
        expression: "Она думает, что он не скажет нам эту вещь",
        transcription: "ʃi θɪŋks ðət hi wəʊnt tɛl əs ðɪs θɪŋ",
        translation: "She thinks that he won't tell us this thing",
      },
      {
        id: 52,
        expression: "Я не буду обращать внимание на такие вещи",
        transcription: "aɪ wəʊnt peɪ əˈtɛnʃ(ə)n tə sʌʧ θɪŋz",
        translation: "I won't pay attention to such things",
      },
      {
        id: 53,
        expression: "Я постараюсь не реагировать на те вещи",
        transcription: "aɪl traɪ nɒt tə ri(ː)ˈækt tə ðəʊz θɪŋz",
        translation: "I'll try not to react to those things",
      },
      {
        id: 54,
        expression: "Я думаю, мужчина не получит эту работу",
        transcription: "aɪ θɪŋk ðə mæn wəʊnt gɛt ðɪs ʤɒb",
        translation: "I think the man won't get this job",
      },
      {
        id: 55,
        expression: "Курс не будет длиться так долго",
        transcription: "ðə kɔːs wəʊnt lɑːst səʊ lɒŋ",
        translation: "The course won't last so long",
      },
      {
        id: 56,
        expression: "Он никогда не пойдет туда снова",
        transcription: "hiːl ˈnɛvə gəʊ ðeər əˈgɛn",
        translation: "He'll never go there again",
      },
      {
        id: 57,
        expression: "Я постараюсь не думать об этом больше",
        transcription: "aɪl traɪ nɒt tə θɪŋk əˈbaʊt ɪt ˌɛniˈmɔː",
        translation: "I'll try not to think about it anymore",
      },
      {
        id: 58,
        expression: "Он думает, это не случится с нами",
        transcription: "hi θɪŋks ɪt wəʊnt ˈhæpən tʊ ʌs",
        translation: "He thinks it won't happen to us",
      },
      {
        id: 59,
        expression: "Я думаю, это не случится с тобой",
        transcription: "aɪ θɪŋk ɪt wəʊnt ˈhæpən tə juː",
        translation: "I think it won't happen to you",
      },
      {
        id: 60,
        expression: "Он думает, это случится с каждым",
        transcription: "hi θɪŋks ˈɪtl ˈhæpən tʊ ˈɛvrɪwʌn",
        translation: "He thinks it'll happen to everyone",
      },
      {
        id: 61,
        expression: "Я постараюсь не повторять эти ошибки",
        transcription: "aɪl traɪ nɒt tə rɪˈpiːt ðiːz mɪsˈteɪks",
        translation: "I'll try not to repeat these mistakes",
      },
      {
        id: 62,
        expression: "Я постараюсь не повторять их ошибок",
        transcription: "aɪl traɪ nɒt tə rɪˈpiːt ðeə mɪsˈteɪks",
        translation: "I'll try not to repeat their mistakes",
      },
      {
        id: 63,
        expression: "Он постарается не повторять мою ошибку",
        transcription: "hiːl traɪ nɒt tə rɪˈpiːt maɪ mɪsˈteɪk",
        translation: "He'll try not to repeat my mistake",
      },
      {
        id: 64,
        expression: "Они ничего не будут гарантировать",
        transcription: "ðeɪ wəʊnt ˌgærənˈtiː ˈɛnɪθɪŋ",
        translation: "They won't guarantee anything",
      },
      {
        id: 65,
        expression: "Они ничего нам не будут гарантировать",
        transcription: "ðeɪ wəʊnt ˌgærənˈtiː əs ˈɛnɪθɪŋ",
        translation: "They won't guarantee us anything",
      },
      {
        id: 66,
        expression: "Они будут гарантировать это",
        transcription: "ðeɪl ˌgærənˈtiː ɪt",
        translation: "They'll guarantee it",
      },
      {
        id: 67,
        expression: "Он постарается больше не делать этого",
        transcription: "hiːl traɪ nɒt tə dʊ ɪt ˌɛniˈmɔː",
        translation: "He'll try not to do it anymore",
      },
      {
        id: 68,
        expression: "Он постарается сделать это снова",
        transcription: "hiːl traɪ tə dʊ ɪt əˈgɛn",
        translation: "He'll try to do it again",
      },
      {
        id: 69,
        expression: "Я постараюсь сделать это снова",
        transcription: "aɪl traɪ tə dʊ ɪt əˈgɛn",
        translation: "I'll try to do it again",
      },
      {
        id: 70,
        expression: "Я не решу эту проблему за день",
        transcription: "aɪ wəʊnt sɒlv ðɪs ˈprɒbləm ɪn ə deɪ",
        translation: "I won't solve this problem in a day",
      },
      {
        id: 71,
        expression: "Я не решу эту задачу за минуту",
        transcription: "aɪ wəʊnt sɒlv ðɪs tɑːsk ɪn ə ˈmɪnɪt",
        translation: "I won't solve this task in a minute",
      },
      {
        id: 72,
        expression: "Я решу эту проблему за день",
        transcription: "aɪl sɒlv ðɪs ˈprɒbləm ɪn ə deɪ",
        translation: "I'll solve this problem in a day",
      },
      {
        id: 73,
        expression: "Никто не сможет понять это",
        transcription: "nəʊ wʌn wɪl bi ˈeɪbl tʊ ˌʌndəˈstænd ɪt",
        translation: "No one will be able to understand it",
      },
      {
        id: 74,
        expression: "Никто не сможет его понять",
        transcription: "nəʊ wʌn bi ˈeɪbl tʊ ˌʌndəˈstænd hɪm",
        translation: "No one be able to understand him",
      },
      {
        id: 75,
        expression: "Он не сможет этого понять",
        transcription: "hi wəʊnt bi ˈeɪbl tʊ ˌʌndəˈstænd ɪt",
        translation: "He won't be able to understand it",
      },
      {
        id: 76,
        expression: "Я не буду обращать внимание на это",
        transcription: "aɪ wəʊnt peɪ əˈtɛnʃ(ə)n tʊ ɪt",
        translation: "I won't pay attention to it",
      },
      {
        id: 77,
        expression: "Он думает, он не решит эту задачу так быстро",
        transcription: "hi θɪŋks hi wəʊnt sɒlv ðɪs tɑːsk səʊ ˈkwɪkli",
        translation: "He thinks he won't solve this task so quickly",
      },
      {
        id: 78,
        expression: "Он думает, он решит эту задачу очень быстро",
        transcription: "hi θɪŋks hiːl sɒlv ðɪs tɑːsk ˈvɛri ˈkwɪkli",
        translation: "He thinks he'll solve this task very quickly",
      },
      {
        id: 79,
        expression: "Я думаю, я не решу эту задачу так быстро",
        transcription: "aɪ θɪŋk aɪ wəʊnt sɒlv ðɪs tɑːsk səʊ ˈkwɪkli",
        translation: "I think I won't solve this task so quickly",
      },
      {
        id: 80,
        expression: "Я надеюсь, это никогда не произойдет снова",
        transcription: "aɪ həʊp ˈɪtl ˈnɛvə ˈhæpən əˈgɛn",
        translation: "I hope it'll never happen again",
      },
      {
        id: 81,
        expression: "Я знаю, это никогда не произойдет",
        transcription: "aɪ nəʊ ˈɪtl ˈnɛvə ˈhæpən",
        translation: "I know it'll never happen",
      },
      {
        id: 82,
        expression: "Я надеюсь, это никогда не случится с тобой",
        transcription: "aɪ həʊp ˈɪtl ˈnɛvə ˈhæpən tə juː",
        translation: "I hope it'll never happen to you",
      },
      {
        id: 83,
        expression: "Он не будет рад слышать это",
        transcription: "hi wəʊnt bi glæd tə hɪər ɪt",
        translation: "He won't be glad to hear it",
      },
      {
        id: 84,
        expression: "Я буду рад узнать это",
        transcription: "aɪl bi glæd tə nəʊ ɪt",
        translation: "I'll be glad to know it",
      },
      {
        id: 85,
        expression: "Он будет рад услышать это",
        transcription: "hiːl bi glæd tə hɪər ɪt",
        translation: "He'll be glad to hear it",
      },
      {
        id: 86,
        expression: "Они не разрешат этот конфликт так быстро",
        transcription: "ðeɪ wəʊnt rɪˈzɒlv ðɪs ˈkɒnflɪkt səʊ ˈkwɪkli",
        translation: "They won't resolve this conflict so quickly",
      },
      {
        id: 87,
        expression: "Он говорит, он не будет жаловаться на эту вещь",
        transcription: "hi sɛz hi wəʊnt kəmˈpleɪn əˈbaʊt ðɪs θɪŋ",
        translation: "He says he won't complain about this thing",
      },
      {
        id: 88,
        expression: "Она не будет рада видеть его",
        transcription: "ʃi wəʊnt bi glæd tə siː hɪm",
        translation: "She won't be glad to see him",
      },
      {
        id: 89,
        expression: "Я постараюсь не обращать внимание на это",
        transcription: "aɪl traɪ nɒt tə peɪ əˈtɛnʃ(ə)n tʊ ɪt",
        translation: "I'll try not to pay attention to it",
      },
      {
        id: 90,
        expression: "Я полагаю, это не случится с нами снова",
        transcription: "aɪ səˈpəʊz ɪt wəʊnt ˈhæpən tʊ əs əˈgɛn",
        translation: "I suppose it won't happen to us again",
      },
      {
        id: 91,
        expression: "Я не смогу прийти на вечеринку",
        transcription: "aɪ wəʊnt bi ˈeɪbl tə kʌm tə ðə ˈpɑːti",
        translation: "I won't be able to come to the party",
      },
      {
        id: 92,
        expression: "Нам кажется, он не откажется сделать это",
        transcription: "ɪt siːmz tʊ əs hi wəʊnt ˌriːˈfjuːz tə dʊ ɪt",
        translation: "It seems to us he won't refuse to do it",
      },
      {
        id: 93,
        expression: "Я думаю, мальчик не сможет сдать этот экзамен",
        transcription: "aɪ θɪŋk ðə bɔɪ wəʊnt bi ˈeɪbl tə pɑːs ðɪs ɪgˈzæm",
        translation: "I think the boy won't be able to pass this exam",
      },
      {
        id: 94,
        expression: "Я боюсь, будет не так легко изменить это",
        transcription: "aɪm əˈfreɪd ɪt wəʊnt bi səʊ ˈiːzi tə ʧeɪnʤ ɪt",
        translation: "I'm afraid it won't be so easy to change it",
      },
      {
        id: 95,
        expression: "Возможно, он не сможет сделать это так быстро",
        transcription: "pəˈhæps, hi wəʊnt bi ˈeɪbl tə dʊ ɪt səʊ ˈkwɪkli",
        translation: "Perhaps, he won't be able to do it so quickly",
      },
      {
        id: 96,
        expression: "Никто не догадается об этом",
        transcription: "nəʊ wʌn wɪl gɛs ɪt",
        translation: "No one will guess it",
      },
      {
        id: 97,
        expression: "Он не согласится сделать это",
        transcription: "hi wəʊnt əˈgriː tə dʊ ɪt",
        translation: "He won't agree to do it",
      },
      {
        id: 98,
        expression: "Я никогда не забуду это",
        transcription: "aɪl ˈnɛvə fəˈgɛt ɪt",
        translation: "I'll never forget it",
      },
      {
        id: 99,
        expression: "Я не буду жаловаться на это",
        transcription: "aɪ wəʊnt kəmˈpleɪn əˈbaʊt ɪt",
        translation: "I won't complain about it",
      },
      {
        id: 100,
        expression: "Никто не будет знать это",
        transcription: "nəʊ wʌn wɪl nəʊ ɪt",
        translation: "No one will know it",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Закрепление пройденного материала</h3>
</div>`,
    },
  ],
];
