export const contentLesson46 = [
  [
    {
      title: 'Урок 46. 150 verbs. Part 2',
    },
    [
      {
        id: 1,
        expression: 'Я буду готов',
        transcription: 'aɪl bi ˈrɛdi',
        translation: "I'll be ready",
      },
      {
        id: 2,
        expression: 'Он не будет курить',
        transcription: 'hi wəʊnt sməʊk',
        translation: "He won't smoke",
      },
      {
        id: 3,
        expression: 'Я расскажу тебе одну историю',
        transcription: 'aɪl tɛl jʊ wʌn ˈstɔːri',
        translation: "I'll tell you one story",
      },
      {
        id: 4,
        expression: 'Она часто посещает новые страны и города',
        transcription: 'ʃi ˈɒf(ə)n ˈvɪzɪts njuː ˈkʌntriz ənd ˈsɪtiz',
        translation: 'She often visits new countries and cities',
      },
      {
        id: 5,
        expression: 'Это стоит намного меньше сейчас',
        transcription: 'ɪt kɒsts mʌʧ lɛs naʊ',
        translation: 'It costs much less now',
      },
      {
        id: 6,
        expression: 'Он не хотел курить',
        transcription: 'hi dɪdnt wɒnt tə sməʊk',
        translation: "He didn't want to smoke",
      },
      {
        id: 7,
        expression: 'Давай поговорим о той вещи!',
        transcription: 'lɛts tɔːk əˈbaʊt ðət θɪŋ!',
        translation: "Let's talk about that thing!",
      },
      {
        id: 8,
        expression: 'Когда вы будете готовы к этому?',
        transcription: 'wɛn wɪl jʊ bi ˈrɛdi fər ɪt?',
        translation: 'When will you be ready for it?',
      },
      {
        id: 9,
        expression: 'Иногда он говорит слишком много',
        transcription: 'ˈsʌmtaɪmz hi tɔːks tuː mʌʧ',
        translation: 'Sometimes he talks too much',
      },
      {
        id: 10,
        expression: 'Мне нужно подумать об этом',
        transcription: 'aɪ niːd tə θɪŋk əˈbaʊt ɪt',
        translation: 'I need to think about it',
      },
      {
        id: 11,
        expression: 'Наш учитель говорил очень громко',
        transcription: 'ˈaʊə ˈtiːʧə spəʊk ˈvɛri ˈlaʊdli',
        translation: 'Our teacher spoke very loudly',
      },
      {
        id: 12,
        expression: 'Мы можем поговорить об этом прямо сейчас',
        transcription: 'wi kən tɔːk əˈbaʊt ɪt raɪt naʊ',
        translation: 'We can talk about it right now',
      },
      {
        id: 13,
        expression:
          'Я подготовлюсь к своему последнему экзамену на следующей неделе',
        transcription: 'aɪl prɪˈpeə fə maɪ ˈfaɪnl ɪgˈzæm nɛkst wiːk',
        translation: "I'll prepare for my final exam next week",
      },
      {
        id: 14,
        expression: 'Тебе следует подумать об этом',
        transcription: 'jʊ ʃəd θɪŋk əˈbaʊt ɪt',
        translation: 'You should think about it',
      },
      {
        id: 15,
        expression: 'Мы можем обсудить все',
        transcription: 'wi kən dɪsˈkʌs ˈɛvrɪθɪŋ',
        translation: 'We can discuss everything',
      },
      {
        id: 16,
        expression: 'Подумай об этом!',
        transcription: 'θɪŋk əˈbaʊt ɪt!',
        translation: 'Think about it!',
      },
      {
        id: 17,
        expression: 'Носители языка говорят очень быстро',
        transcription: 'ˈneɪtɪv ˈspiːkəz spiːk ˈvɛri fɑːst',
        translation: 'Native speakers speak very fast',
      },
      {
        id: 18,
        expression: 'Я навещу ее на следующей неделе',
        transcription: 'aɪl ˈvɪzɪt hə nɛkst wiːk',
        translation: "I'll visit her next week",
      },
      {
        id: 19,
        expression: 'Мне не понравился этот отель',
        transcription: 'aɪ dɪdnt laɪk ðɪs həʊˈtɛl',
        translation: "I didn't like this hotel",
      },
      {
        id: 20,
        expression: 'Какое видео тебе нравится больше?',
        transcription: 'wɒt ˈvɪdɪəʊ dʊ jʊ laɪk mɔː?',
        translation: 'What video do you like more?',
      },
      {
        id: 21,
        expression: 'Его машина часто ломается',
        transcription: 'hɪz kɑːr ˈɒf(ə)n breɪks daʊn',
        translation: 'His car often breaks down',
      },
      {
        id: 22,
        expression: 'Он не курит',
        transcription: 'hi dʌznt sməʊk',
        translation: "He doesn't smoke",
      },
      {
        id: 23,
        expression: 'Мне нужно подготовиться к этому экзамену',
        transcription: 'aɪ niːd tə prɪˈpeə fə ðɪs ɪgˈzæm',
        translation: 'I need to prepare for this exam',
      },
      {
        id: 24,
        expression: 'Я встал за ней',
        transcription: 'aɪ stʊd bɪˈhaɪnd hɜː',
        translation: 'I stood behind her',
      },
      {
        id: 25,
        expression: 'Ты можешь отправить мне это по электронной почте',
        transcription: 'jʊ kən sɛnd mi ɪt baɪ ˈiːmeɪl',
        translation: 'You can send me it by email',
      },
      {
        id: 26,
        expression: 'Это стоит пятьдесят евро',
        transcription: 'ɪt kɒsts ˈfɪfti ˈjʊərəʊz',
        translation: 'It costs fifty euros',
      },
      {
        id: 27,
        expression: 'Я отправлю тебе сообщение',
        transcription: 'aɪl sɛnd jʊ ə ˈmɛsɪʤ',
        translation: "I'll send you a message",
      },
      {
        id: 28,
        expression: 'Тебе следует думать о своем будущем',
        transcription: 'jʊ ʃəd θɪŋk əˈbaʊt jə ˈfjuːʧə',
        translation: 'You should think about your future',
      },
      {
        id: 29,
        expression: 'Как ты готовился к этому тесту?',
        transcription: 'haʊ dɪd jʊ prɪˈpeə fə ðɪs tɛst?',
        translation: 'How did you prepare for this test?',
      },
      {
        id: 30,
        expression: 'Они прислали мне эту информацию',
        transcription: 'ðeɪ sɛnt mi ðɪs ˌɪnfəˈmeɪʃən',
        translation: 'They sent me this information',
      },
      {
        id: 31,
        expression: 'Она встала',
        transcription: 'ʃi stʊd ʌp',
        translation: 'She stood up',
      },
      {
        id: 32,
        expression: 'Я хочу сказать тебе одну вещь',
        transcription: 'aɪ wɒnt tə tɛl wʌn θɪŋ',
        translation: 'I want to tell one thing',
      },
      {
        id: 33,
        expression: 'Говори медленно, пожалуйста!',
        transcription: 'spiːk ˈsləʊli, pliːz!',
        translation: 'Speak slowly, please!',
      },
      {
        id: 34,
        expression: 'Эта машина так часто ломается',
        transcription: 'ðɪs kɑː breɪks daʊn səʊ ˈɒf(ə)n',
        translation: 'This car breaks down so often',
      },
      {
        id: 35,
        expression: 'Что тебе больше всего нравилось?',
        transcription: 'wɒt dɪd jʊ laɪk məʊst əv ɔːl?',
        translation: 'What did you like most of all?',
      },
      {
        id: 36,
        expression: 'Я часто думал об этой вещи',
        transcription: 'aɪ ˈɒf(ə)n θɔːt əˈbaʊt ðɪs θɪŋ',
        translation: 'I often thought about this thing',
      },
      {
        id: 37,
        expression: 'Она все мне рассказала',
        transcription: 'ʃi təʊld mi ˈɛvrɪθɪŋ',
        translation: 'She told me everything',
      },
      {
        id: 38,
        expression: 'Ты можешь встать сейчас',
        transcription: 'jʊ kən stænd ʌp naʊ',
        translation: 'You can stand up now',
      },
      {
        id: 39,
        expression: 'Они сломали окно',
        transcription: 'ðeɪ brəʊk ðə ˈwɪndəʊ',
        translation: 'They broke the window',
      },
      {
        id: 40,
        expression: 'Я очень легко выучил все те слова',
        transcription: 'aɪ lɜːnt ɔːl ðəʊz wɜːdz ˈvɛri ˈiːzɪli',
        translation: 'I learnt all those words very easily',
      },
      {
        id: 41,
        expression: 'Ты можешь повернуть налево сейчас',
        transcription: 'jʊ kən tɜːn lɛft naʊ',
        translation: 'You can turn left now',
      },
      {
        id: 42,
        expression: 'Я думаю, ему следует сделать это',
        transcription: 'aɪ θɪŋk hi ʃəd dʊ ɪt',
        translation: 'I think he should do it',
      },
      {
        id: 43,
        expression: 'Поверните направо!',
        transcription: 'tɜːn raɪt!',
        translation: 'Turn right!',
      },
      {
        id: 44,
        expression: 'Тебе нужно повернуть прямо сейчас',
        transcription: 'jʊ niːd tə tɜːn raɪt naʊ',
        translation: 'You need to turn right now',
      },
      {
        id: 45,
        expression: 'Я тебе перезвоню',
        transcription: 'aɪl kɔːl jʊ bæk',
        translation: "I'll call you back",
      },
      {
        id: 46,
        expression: 'Это зависит от меня',
        transcription: 'ɪt dɪˈpɛndz ɒn miː',
        translation: 'It depends on me',
      },
      {
        id: 47,
        expression: 'Он не может водить машину',
        transcription: 'hi kɑːnt draɪv ə kɑː',
        translation: "He can't drive a car",
      },
      {
        id: 48,
        expression: 'Это будет стоить более двух тысяч долларов скоро',
        transcription: 'ˈɪtl kɒst mɔː ðən tuː ˈθaʊzənd ˈdɒləz suːn',
        translation: "It'll cost more than two thousand dollars soon",
      },
      {
        id: 49,
        expression: 'Это зависело от разных факторов',
        transcription: 'ɪt dɪˈpɛndɪd ɒn ˈdɪfrənt ˈfæktəz',
        translation: 'It depended on different factors',
      },
      {
        id: 50,
        expression: 'Я обычно отмечаю свой день рождения со своими друзьями',
        transcription: 'aɪ ˈjuːʒʊəli ˈsɛlɪbreɪt maɪ ˈbɜːθdeɪ wɪð maɪ frɛndz',
        translation: 'I usually celebrate my birthday with my friends',
      },
      {
        id: 51,
        expression: 'Она водила машину',
        transcription: 'ʃi drəʊv ə kɑː',
        translation: 'She drove a car',
      },
      {
        id: 52,
        expression: 'Он делает домашнее задание после школы',
        transcription: 'hi dəz ˈhəʊmˌwɜːk ˈɑːftə skuːl',
        translation: 'He does homework after school',
      },
      {
        id: 53,
        expression: 'Я встал, потому что я не хотел сидеть',
        transcription: 'aɪ stʊd ʌp bɪˈkəz aɪ dɪdnt wɒnt tə sɪt',
        translation: "I stood up because I didn't want to sit",
      },
      {
        id: 54,
        expression: 'Я делаю домашнее задание днем',
        transcription: 'aɪ dʊ ˈhəʊmˌwɜːk ɪn ði ˈɑːftəˈnuːn',
        translation: 'I do homework in the afternoon',
      },
      {
        id: 55,
        expression: 'Он поехал домой',
        transcription: 'hi drəʊv həʊm / hi wɛnt həʊm',
        translation: 'He drove home / He went home',
      },
      {
        id: 56,
        expression: 'Я уверен, это скоро будет стоить намного больше',
        transcription: 'aɪm ʃʊər ˈɪtl kɒst mʌʧ mɔː suːn',
        translation: "I'm sure it'll cost much more soon",
      },
      {
        id: 57,
        expression: 'Я уверен, что это скоро будет стоить больше',
        transcription: 'aɪm ʃʊə ðət ˈɪtl kɒst mɔː suːn',
        translation: "I'm sure that it'll cost more soon",
      },
      {
        id: 58,
        expression: 'Я уверен, это будет стоить намного меньше завтра',
        transcription: 'aɪm ʃʊər ˈɪtl kɒst mʌʧ lɛs təˈmɒrəʊ',
        translation: "I'm sure it'll cost much less tomorrow",
      },
      {
        id: 59,
        expression: 'Поверни налево сейчас!',
        transcription: 'tɜːn lɛft naʊ!',
        translation: 'Turn left now!',
      },
      {
        id: 60,
        expression: 'Поверни назад!',
        transcription: 'tɜːn bæk!',
        translation: 'Turn back!',
      },
      {
        id: 61,
        expression: 'Где ты остановишься?',
        transcription: 'weə wɪl jʊ steɪ?',
        translation: 'Where will you stay?',
      },
      {
        id: 62,
        expression: 'Где они остановятся?',
        transcription: 'weə wɪl ðeɪ steɪ?',
        translation: 'Where will they stay?',
      },
      {
        id: 63,
        expression: 'Когда ты остановишься там?',
        transcription: 'wɛn wɪl jʊ steɪ ðeə?',
        translation: 'When will you stay there?',
      },
      {
        id: 64,
        expression: 'Ему нравиться водить',
        transcription: 'hi laɪks ˈdraɪvɪŋ',
        translation: 'He likes driving',
      },
      {
        id: 65,
        expression: 'Ей нравится водить',
        transcription: 'ʃi laɪks ˈdraɪvɪŋ',
        translation: 'She likes driving',
      },
      {
        id: 66,
        expression: 'Мне нравится водить',
        transcription: 'aɪ laɪk ˈdraɪvɪŋ',
        translation: 'I like driving',
      },
      {
        id: 67,
        expression: 'Я остановился недалеко от того места',
        transcription: 'aɪ stɒpt nɒt fɑː frəm ðət pleɪs',
        translation: 'I stopped not far from that place',
      },
      {
        id: 68,
        expression: 'Я остановился далеко от того места',
        transcription: 'aɪ stɒpt fɑː frəm ðət pleɪs',
        translation: 'I stopped far from that place',
      },
      {
        id: 69,
        expression: 'Я остановился возле того места',
        transcription: 'aɪ stɒpt nɪə ðət pleɪs',
        translation: 'I stopped near that place',
      },
      {
        id: 70,
        expression: 'Как ты обычно празднуешь свой день рождения?',
        transcription: 'haʊ dʊ jʊ ˈjuːʒʊəli ˈsɛlɪbreɪt jə ˈbɜːθdeɪ?',
        translation: 'How do you usually celebrate your birthday?',
      },
      {
        id: 71,
        expression: 'Как она обычно празднует свой день рождения?',
        transcription: 'haʊ dəz ʃi ˈjuːʒʊəli ˈsɛlɪbreɪt hə ˈbɜːθdeɪ?',
        translation: 'How does she usually celebrate her birthday?',
      },
      {
        id: 72,
        expression: 'Где ты обычно празднуешь свой день рождения?',
        transcription: 'weə dʊ jʊ ˈjuːʒʊəli ˈsɛlɪbreɪt jə ˈbɜːθdeɪ?',
        translation: 'Where do you usually celebrate your birthday?',
      },
      {
        id: 73,
        expression: 'Это будет зависеть от разных вещей',
        transcription: 'ˈɪtl dɪˈpɛnd ɒn ˈdɪfrənt θɪŋz',
        translation: "It'll depend on different things",
      },
      {
        id: 74,
        expression: 'Это будет зависеть от многих вещей',
        transcription: 'ˈɪtl dɪˈpɛnd ɒn ˈmɛni θɪŋz',
        translation: "It'll depend on many things",
      },
      {
        id: 75,
        expression: 'Это будет зависеть от тебя',
        transcription: 'ˈɪtl dɪˈpɛnd ɒn juː',
        translation: "It'll depend on you",
      },
      {
        id: 76,
        expression: 'Я знаю, что носители языка обычно говорят очень быстро',
        transcription:
          'aɪ nəʊ ðət ˈneɪtɪv ˈspiːkəz ˈjuːʒʊəli spiːk ˈvɛri fɑːst',
        translation: 'I know that native speakers usually speak very fast',
      },
      {
        id: 77,
        expression: 'Мы знаем, что носители языка часто говорят очень быстро',
        transcription: 'wi nəʊ ðət ˈneɪtɪv ˈspiːkəz ˈɒf(ə)n spiːk ˈvɛri fɑːst',
        translation: 'We know that native speakers often speak very fast',
      },
      {
        id: 78,
        expression: 'Когда ты будешь праздновать свой день рождения?',
        transcription: 'wɛn wɪl jʊ ˈsɛlɪbreɪt jə ˈbɜːθdeɪ?',
        translation: 'When will you celebrate your birthday?',
      },
      {
        id: 79,
        expression: 'Где она будет праздновать свой день рождения?',
        transcription: 'weə wɪl ʃi ˈsɛlɪbreɪt hə ˈbɜːθdeɪ?',
        translation: 'Where will she celebrate her birthday?',
      },
      {
        id: 80,
        expression: 'Он сказал это еще один раз',
        transcription: 'hi sɛd ɪt wʌn mɔː taɪm',
        translation: 'He said it one more time',
      },
      {
        id: 81,
        expression: 'Они говорили это снова и снова',
        transcription: 'ðeɪ sɛd ɪt əˈgɛn ənd əˈgɛn',
        translation: 'They said it again and again',
      },
      {
        id: 82,
        expression: 'Я не был готов услышать это',
        transcription: 'aɪ wɒznt ˈrɛdi tə hɪər ɪt',
        translation: "I wasn't ready to hear it",
      },
      {
        id: 83,
        expression: 'Как долго ты будешь оставаться в этом отеле?',
        transcription: 'haʊ lɒŋ wɪl jʊ steɪ ɪn ðə həʊˈtɛl?',
        translation: 'How long will you stay in the hotel?',
      },
      {
        id: 84,
        expression: 'Я не готов к этим изменениям',
        transcription: 'aɪm nɒt ˈrɛdi fə ðiːz ˈʧeɪnʤɪz',
        translation: "I'm not ready for these changes",
      },
      {
        id: 85,
        expression: 'Он хочет перестать курить',
        transcription: 'hi wɒnts tə stɒp ˈsməʊkɪŋ',
        translation: 'He wants to stop smoking',
      },
      {
        id: 86,
        expression: 'Я думаю, ей следует сменить свою работу',
        transcription: 'aɪ θɪŋk ʃi ʃəd ʧeɪnʤ hə ʤɒb',
        translation: 'I think she should change her job',
      },
      {
        id: 87,
        expression: 'Мы не были готовы узнать это',
        transcription: 'wi wɜːnt ˈrɛdi tə nəʊ ɪt',
        translation: "We weren't ready to know it",
      },
      {
        id: 88,
        expression: 'Я сделаю эту вещь с большим удовольствием',
        transcription: 'aɪl dʊ ðɪs θɪŋ wɪð greɪt ˈplɛʒə',
        translation: "I'll do this thing with great pleasure",
      },
      {
        id: 89,
        expression: 'Он перестал курить',
        transcription: 'hi stɒpt ˈsməʊkɪŋ',
        translation: 'He stopped smoking',
      },
      {
        id: 90,
        expression: 'Перезвони мне, пожалуйста!',
        transcription: 'kɔːl mi bæk, pliːz!',
        translation: 'Call me back, please!',
      },
      {
        id: 91,
        expression: 'Я не знаю, почему она это сказала',
        transcription: 'aɪ dəʊnt naʊ wɒt ʃi sɛd ɪt',
        translation: "I don't know what she said it",
      },
      {
        id: 92,
        expression: 'Мы праздновали этот праздник вместе',
        transcription: 'wi ˈsɛlɪbreɪtɪd ðɪs ˈhɒlədeɪ təˈgɛðə',
        translation: 'We celebrated this holiday together',
      },
      {
        id: 93,
        expression: 'Я хочу обсудить эту тему с вами',
        transcription: 'aɪ wɒnt tə dɪsˈkʌs ðɪs ˈtɒpɪk wɪð juː',
        translation: 'I want to discuss this topic with you',
      },
      {
        id: 94,
        expression: 'Мы остались в том месте',
        transcription: 'wi steɪd ɪn ðət pleɪs',
        translation: 'We stayed in that place',
      },
      {
        id: 95,
        expression: 'Те мужчины говорили очень тихо',
        transcription: 'ðəʊz mɛn spəʊk ˈvɛri ˈkwaɪətli',
        translation: 'Those men spoke very quietly',
      },
      {
        id: 96,
        expression: 'Я посещал Испанию, когда я был ребенком',
        transcription: 'aɪ ˈvɪzɪtɪd speɪn wɛn aɪ wəz ə ʧaɪld',
        translation: 'I visited Spain when I was a child',
      },
    ],
    {
      theory: `<div class="theory-block">
<p>Устойчивое выражение:</p>
<h3>far from</h3>
<p>- далеко</p>
<p>I stopped not far from that place - Я остановился недалеко от того места</p>
</div>`,
    },
  ],
];
