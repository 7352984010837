export const contentLesson153 = [
  [
    {
      title: 'Урок 153. Dependent prepositions (adjectives). Part 3',
    },
    [
      {
        id: 1,
        expression: 'Он полон энтузиазма',
        transcription: 'hiːz fʊl ɒv ɪnˈθjuːzɪæzm',
        translation: "He's full of enthusiasm",
      },
      {
        id: 2,
        expression: 'Я действительно рад за тебя',
        transcription: 'aɪm ˈrɪəli glæd fɔː juː',
        translation: "I'm really glad for you",
      },
      {
        id: 3,
        expression: 'Это сделано из хлопка',
        transcription: 'ɪts meɪd ɒv ˈkɒtn',
        translation: "It's made of cotton",
      },
      {
        id: 4,
        expression: 'Он очень раздражен ею',
        transcription: 'hiːz ˈvɛri əˈnɔɪd wɪð hɜː',
        translation: "He's very annoyed with her",
      },
      {
        id: 5,
        expression: 'Я так рад за тебя',
        transcription: 'aɪm səʊ ˈhæpi fɔː juː',
        translation: "I'm so happy for you",
      },
      {
        id: 6,
        expression: 'Это сделано из стали',
        transcription: 'ɪts meɪd ɒv stiːl',
        translation: "It's made of steel",
      },
      {
        id: 7,
        expression: 'Он очень нервничает из-за своего будущего',
        transcription: 'hiːz ˈvɛri ˈnɜːvəs əˈbaʊt hɪz ˈfjuːʧə',
        translation: "He's very nervous about his future",
      },
      {
        id: 8,
        expression: 'Я действительно сожалею обо всем',
        transcription: 'aɪm ˈrɪəli ˈsɒri əˈbaʊt ˈɛvrɪθɪŋ',
        translation: "I'm really sorry about everything",
      },
      {
        id: 9,
        expression: 'Она очень раздражена им',
        transcription: 'ʃiːz ˈvɛri ˈɪrɪteɪtɪd (əˈnɔɪd) wɪð (baɪ) hɪm',
        translation: "She's very irritated (annoyed) with (by) him",
      },
      {
        id: 10,
        expression: 'Она безнадежна в катании на коньках',
        transcription: 'ʃiːz ˈhəʊplɪs æt ˈskeɪtɪŋ',
        translation: "She's hopeless at skating",
      },
      {
        id: 11,
        expression: 'Я так рад за тебя',
        transcription: 'aɪm səʊ ˈhæpi (glæd) fɔː juː',
        translation: "I'm so happy (glad) for you",
      },
      {
        id: 12,
        expression: 'Мне ясно, что мне нужно что-то делать прямо сейчас',
        transcription: 'ɪts klɪə tuː miː ðæt aɪ niːd tuː duː ˈsʌmθɪŋ raɪt naʊ',
        translation: "It's clear to me that I need to do something right now",
      },
      {
        id: 13,
        expression: 'Она зависима от своих родителей',
        transcription: 'ʃiːz dɪˈpɛndənt ɒn hɜː ˈpeərənts',
        translation: "She's dependent on her parents",
      },
      {
        id: 14,
        expression: 'Он помолвлен с ней',
        transcription: 'hiːz ɪnˈgeɪʤd tuː hɜː',
        translation: "He's engaged to her",
      },
      {
        id: 15,
        expression: 'Он абсолютно безнадежен в этом',
        transcription: 'hiːz ˈæbsəluːtli ˈhəʊplɪs æt ɪt',
        translation: "He's absolutely hopeless at it",
      },
      {
        id: 16,
        expression: 'Ему не все ясно',
        transcription: 'nɒt ˈɛvrɪθɪŋ ɪz klɪə tuː hɪm',
        translation: 'Not everything is clear to him',
      },
      {
        id: 17,
        expression: 'Он был очень раздражен детьми',
        transcription: 'hiː wɒz ˈvɛri əˈnɔɪd wɪð ðə kɪdz',
        translation: 'He was very annoyed with the kids',
      },
      {
        id: 18,
        expression: 'Они были очень раздражены его грубостью',
        transcription: 'ðeɪ wɜː ˈvɛri ˈɪrɪteɪtɪd baɪ hɪz ˈruːdnɪs',
        translation: 'They were very irritated by his rudeness',
      },
      {
        id: 19,
        expression: 'Мне все ясно',
        transcription: 'ˈɛvrɪθɪŋ ɪz klɪə tuː miː',
        translation: 'Everything is clear to me',
      },
      {
        id: 20,
        expression: 'Я действительно рад твоему успеху',
        transcription: 'aɪm ˈrɪəli glæd əˈbaʊt jɔː səkˈsɛs',
        translation: "I'm really glad about your success",
      },
      {
        id: 21,
        expression: 'Он был очень разочарован результатом',
        transcription: 'hiː wɒz ˈvɛri ˌdɪsəˈpɔɪntɪd əˈbaʊt ðə rɪˈzʌlt',
        translation: 'He was very disappointed about the result',
      },
      {
        id: 22,
        expression: 'Он действительно уверен в этом?',
        transcription: 'ɪz hiː ˈrɪəli ʃʊər əˈbaʊt ɪt?',
        translation: 'Is he really sure about it?',
      },
      {
        id: 23,
        expression: 'Она помолвлена с ним',
        transcription: 'ʃiːz ɪnˈgeɪʤd tuː hɪm',
        translation: "She's engaged to him",
      },
      {
        id: 24,
        expression: 'Это сделано из шерсти',
        transcription: 'ɪts meɪd ɒv wʊl',
        translation: "It's made of wool",
      },
      {
        id: 25,
        expression: 'Я так рад твоим достижениям',
        transcription: 'aɪm səʊ ˈhæpi əˈbaʊt jɔːr əˈʧiːvmənts',
        translation: "I'm so happy about your achievements",
      },
      {
        id: 26,
        expression: 'Он очень раздражен ее комментариями',
        transcription: 'hiːz ˈvɛri ˈɪrɪteɪtɪd baɪ hɜː ˈkɒmɛnts',
        translation: "He's very irritated by her comments",
      },
      {
        id: 27,
        expression: 'Она действительно без ума от музыки',
        transcription: 'ʃiːz ˈrɪəli ˈkreɪzi (mæd) əˈbaʊt ˈmjuːzɪk',
        translation: "She's really crazy (mad) about music",
      },
      {
        id: 28,
        expression: 'Он без ума от нее',
        transcription: 'hiːz ˈkreɪzi (mæd) əˈbaʊt hɜː',
        translation: "He's crazy (mad) about her",
      },
      {
        id: 29,
        expression: 'Он полон энтузиазма',
        transcription: 'hiːz fʊl ɒv ɪnˈθjuːzɪæzm',
        translation: "He's full of enthusiasm",
      },
      {
        id: 30,
        expression: 'Я не совсем уверен в этом',
        transcription: 'aɪm nɒt ˈrɪəli ʃʊər əˈbaʊt ɪt',
        translation: "I'm not really sure about it",
      },
      {
        id: 31,
        expression: 'Она безнадежна в кулинарии',
        transcription: 'ʃiːz ˈhəʊplɪs æt ˈkʊkɪŋ',
        translation: "She's hopeless at cooking",
      },
      {
        id: 32,
        expression: 'Это сделано из пластика',
        transcription: 'ɪts meɪd ɒv ˈplæstɪk',
        translation: "It's made of plastic",
      },
      {
        id: 33,
        expression: 'Она все еще злится на него',
        transcription: 'ʃiːz stɪl ˈfjʊərɪəs wɪð hɪm',
        translation: "She's still furious with him",
      },
      {
        id: 34,
        expression: 'Это мне не ясно',
        transcription: 'ɪt ˈɪznt klɪə tuː miː',
        translation: "It isn't clear to me",
      },
      {
        id: 35,
        expression: 'Я так рад таким результатам',
        transcription: 'aɪm səʊ glæd (ˈhæpi) əˈbaʊt sʌʧ rɪˈzʌlts',
        translation: "I'm so glad (happy) about such results",
      },
      {
        id: 36,
        expression: 'Она очень нервничает из-за этого',
        transcription: 'ʃiːz ˈvɛri ˈnɜːvəs əˈbaʊt ɪt',
        translation: "She's very nervous about it",
      },
      {
        id: 37,
        expression: 'Я действительно раздражен этой вещью',
        transcription: 'aɪm ˈrɪəli əˈnɔɪd əˈbaʊt ðɪs θɪŋ',
        translation: "I'm really annoyed about this thing",
      },
      {
        id: 38,
        expression: 'Это сделано из металла',
        transcription: 'ɪts meɪd ɒv ˈmɛtl',
        translation: "It's made of metal",
      },
      {
        id: 39,
        expression: 'Я действительно рад за тебя',
        transcription: 'aɪm ˈrɪəli glæd fɔː juː',
        translation: "I'm really glad for you",
      },
      {
        id: 40,
        expression: 'Сумка сделана из кожи',
        transcription: 'ðə bæg ɪz meɪd ɒv ˈlɛðə',
        translation: 'The bag is made of leather',
      },
      {
        id: 41,
        expression: 'Она очень нервничает из-за интервью',
        transcription: 'ʃiː fiːlz ˈvɛri ˈnɜːvəs əˈbaʊt ði ˈɪntəvjuː',
        translation: 'She feels very nervous about the interview',
      },
      {
        id: 42,
        expression: 'Мне это стало ясно',
        transcription: 'ɪt bɪˈkeɪm klɪə tuː miː',
        translation: 'It became clear to me',
      },
      {
        id: 43,
        expression: 'Она полна энергии, несмотря на свой возраст',
        transcription: 'ʃiːz fʊl ɒv ˈɛnəʤi dɪsˈpaɪt hɜːr eɪʤ',
        translation: "She's full of energy despite her age",
      },
      {
        id: 44,
        expression: 'Несмотря на свой возраст, он зависит от своих родителей',
        transcription:
          'dɪsˈpaɪt hɪz eɪʤ hiː dɪˈpɛndz (ɪz dɪˈpɛndənt) ɒn hɪz ˈpeərənts',
        translation: 'Despite his age he depends (is dependent) on his parents',
      },
      {
        id: 45,
        expression: 'Ты всегда очень добр ко мне',
        transcription: 'jʊər ˈɔːlweɪz ˈvɛri kaɪnd tuː miː',
        translation: "You're always very kind to me",
      },
      {
        id: 46,
        expression: 'Некоторые из его коллег присутствовали на конференции',
        transcription: 'sʌm ɒv hɪz ˈkɒliːgz wɜː ˈprɛznt æt ðə ˈkɒnfərəns',
        translation: 'Some of his colleagues were present at the conference',
      },
      {
        id: 47,
        expression: 'Я хочу сказать, что благодарен тебе за все',
        transcription:
          'aɪ wɒnt tuː seɪ ðæt aɪm ˈgreɪtfʊl tuː juː fɔːr ˈɛvrɪθɪŋ',
        translation: "I want to say that I'm grateful to you for everything",
      },
      {
        id: 48,
        expression: 'Я знаком с такого рода проблемами',
        transcription: 'aɪm əˈkweɪntɪd wɪð ðɪs kaɪnd ɒv ˈprɒbləmz',
        translation: "I'm acquainted with this kind of problems",
      },
      {
        id: 49,
        expression: 'Он так рад такому результату',
        transcription: 'hiːz səʊ glæd (ˈhæpi) əˈbaʊt sʌʧ ə rɪˈzʌlt',
        translation: "He's so glad (happy) about such a result",
      },
      {
        id: 50,
        expression: 'Она всегда очень добра к нам',
        transcription: 'ʃiːz ˈɔːlweɪz ˈvɛri kaɪnd tuː ʌs',
        translation: "She's always very kind to us",
      },
      {
        id: 51,
        expression: 'Несмотря на свой возраст, он зависит от своего отца',
        transcription: 'ɪn spaɪt ɒv hɪz eɪʤ hiː ɪz dɪˈpɛndənt ɒn hɪz ˈfɑːðə',
        translation: 'In spite of his age he is dependent on his father',
      },
      {
        id: 52,
        expression: 'Он полон энергии, несмотря на свой возраст',
        transcription: 'hiːz fʊl ɒv ˈɛnəʤi ɪn spaɪt ɒv hɪz eɪʤ',
        translation: "He's full of energy in spite of his age",
      },
      {
        id: 53,
        expression: 'Я опоздал на урок',
        transcription: 'aɪ wɒz leɪt fɔː ðə ˈlɛsn',
        translation: 'I was late for the lesson',
      },
      {
        id: 54,
        expression: 'Его коллеги завидовали его успехам',
        transcription: 'hɪz ˈkɒliːgz wɜːr ˈɛnvɪəs ɒv hɪz səkˈsɛs',
        translation: 'His colleagues were envious of his success',
      },
      {
        id: 55,
        expression: 'Он хочет познакомиться с ней',
        transcription: 'hiː wɒnts tuː gɛt əˈkweɪntɪd wɪð hɜː',
        translation: 'He wants to get acquainted with her',
      },
      {
        id: 56,
        expression: 'Он немного опоздал на урок',
        transcription: 'hiː wɒz leɪt fɔː ðə ˈlɛsn ə ˈlɪtl',
        translation: 'He was late for the lesson a little',
      },
      {
        id: 57,
        expression: 'Я благодарен тебе за твое гостеприимство',
        transcription: 'aɪm ˈgreɪtfʊl tuː juː fɔː jɔː ˌhɒspɪˈtælɪti',
        translation: "I'm grateful to you for your hospitality",
      },
      {
        id: 58,
        expression: 'У него аллергия на орехи',
        transcription: 'hiːz əˈlɜːʤɪk tuː nʌts',
        translation: "He's allergic to nuts",
      },
      {
        id: 59,
        expression: 'У нее нет аллергии на это',
        transcription: 'ʃiː ˈɪznt əˈlɜːʤɪk tuː ɪt',
        translation: "She isn't allergic to it",
      },
      {
        id: 60,
        expression: 'У меня аллергия на эту еду',
        transcription: 'aɪm əˈlɜːʤɪk tuː ðɪs fuːd',
        translation: "I'm allergic to this food",
      },
      {
        id: 61,
        expression: 'Ей не хватает денег',
        transcription: 'ʃiːz ʃɔːt ɒv ˈmʌni',
        translation: "She's short of money",
      },
      {
        id: 62,
        expression: 'Мне не хватает времени',
        transcription: 'aɪm ʃɔːt ɒv taɪm',
        translation: "I'm short of time",
      },
      {
        id: 63,
        expression: 'Ему не хватает наличных?',
        transcription: 'ɪz hiː ʃɔːt ɒv kæʃ?',
        translation: 'Is he short of cash?',
      },
      {
        id: 64,
        expression: 'Было очень мило с твоей стороны помочь нам',
        transcription: 'ɪt wɒz ˈvɛri naɪs ɒv juː tuː hɛlp ʌs',
        translation: 'It was very nice of you to help us',
      },
      {
        id: 65,
        expression: 'Было так мило с твоей стороны помочь мне',
        transcription: 'ɪt wɒz səʊ naɪs ɒv juː tuː hɛlp miː',
        translation: 'It was so nice of you to help me',
      },
      {
        id: 66,
        expression: 'Было действительно мило с его стороны встретить нас',
        transcription: 'ɪt wɒz ˈrɪəli naɪs ɒv hɪm tuː miːt ʌs',
        translation: 'It was really nice of him to meet us',
      },
      {
        id: 67,
        expression: 'Он часто грустит из-за этого',
        transcription: 'hiːz ˈɒf(ə)n sæd əˈbaʊt ɪt',
        translation: "He's often sad about it",
      },
      {
        id: 68,
        expression: 'Она действительно грустит из-за этой вещи',
        transcription: 'ʃiːz ˈrɪəli sæd əˈbaʊt ðɪs θɪŋ',
        translation: "She's really sad about this thing",
      },
      {
        id: 69,
        expression: 'Он так грустит из-за своих ошибок',
        transcription: 'hiːz səʊ sæd əˈbaʊt hɪz mɪsˈteɪks',
        translation: "He's so sad about his mistakes",
      },
      {
        id: 70,
        expression: 'Все завидуют ему',
        transcription: 'ˈɛvrɪwʌn ɪz ˈɛnvɪəs ɒv hɪm',
        translation: 'Everyone is envious of him',
      },
      {
        id: 71,
        expression: 'Никто ей не завидует',
        transcription: 'nəʊ wʌn ɪz ˈɛnvɪəs ɒv hɜː / nəʊ wʌn ˈɛnviz hɜː',
        translation: 'No one is envious of her / No one envies her',
      },
      {
        id: 72,
        expression: 'Я не завидую тебе',
        transcription: 'aɪm nɒt ˈɛnvɪəs ɒv juː',
        translation: "I'm not envious of you",
      },
      {
        id: 73,
        expression: 'Он отсутствовал на работе',
        transcription: 'hiː wɒz ˈæbsənt frɒm wɜːk',
        translation: 'He was absent from work',
      },
      {
        id: 74,
        expression: 'Я отсутствовал в университете',
        transcription: 'aɪ wɒz ˈæbsənt frɒm ðə ˌjuːnɪˈvɜːsɪti',
        translation: 'I was absent from the university',
      },
      {
        id: 75,
        expression: 'Он отсутствует на работе?',
        transcription: 'ɪz hiː ˈæbsənt frɒm wɜːk?',
        translation: 'Is he absent from work?',
      },
      {
        id: 76,
        expression: 'Ты хочешь с ним познакомиться?',
        transcription: 'duː juː wɒnt tuː gɛt əˈkweɪntɪd wɪð hɪm?',
        translation: 'Do you want to get acquainted with him?',
      },
      {
        id: 77,
        expression: 'Я действительно хочу с ним познакомиться',
        transcription: 'aɪm ˈrɪəli wɒnt tuː gɛt əˈkweɪntɪd wɪð hɪm',
        translation: "I'm really want to get acquainted with him",
      },
      {
        id: 78,
        expression: 'Я никогда не хотел с ними познакомиться',
        transcription: 'aɪv ˈnɛvə ˈwɒntɪd tuː gɛt əˈkweɪntɪd wɪð ðɛm',
        translation: "I've never wanted to get acquainted with them",
      },
      {
        id: 79,
        expression: 'Ему наскучил этот формат образования',
        transcription: 'hiːz bɔːd wɪð ðɪs ˈfɔːmæt ɒv ˌɛdju(ː)ˈkeɪʃən',
        translation: "He's bored with this format of education",
      },
      {
        id: 80,
        expression: 'Ей наскучила эта работа',
        transcription: 'ʃiːz bɔːd wɪð ðɪs ʤɒb',
        translation: "She's bored with this job",
      },
      {
        id: 81,
        expression: 'Ему наскучил этот фильм?',
        transcription: 'ɪz hiː bɔːd wɪð ðɪs ˈmuːvi?',
        translation: 'Is he bored with this movie?',
      },
      {
        id: 82,
        expression: 'Эта вещь нам непонятна',
        transcription: 'ðɪs θɪŋ ˈɪznt klɪə tuː ʌs',
        translation: "This thing isn't clear to us",
      },
      {
        id: 83,
        expression: 'Эта вещь мне понятна',
        transcription: 'ðɪs θɪŋ ɪz klɪə tuː miː',
        translation: 'This thing is clear to me',
      },
      {
        id: 84,
        expression: 'Эта вещь тебе понятна?',
        transcription: 'ɪz ðɪs θɪŋ klɪə tuː juː?',
        translation: 'Is this thing clear to you?',
      },
      {
        id: 85,
        expression: 'Им не хватает воды',
        transcription: 'ðeə ʃɔːt ɒv ˈwɔːtə',
        translation: "They're short of water",
      },
      {
        id: 86,
        expression: 'Мне не хватает денег',
        transcription: 'aɪm ʃɔːt ɒv ˈmʌni',
        translation: "I'm short of money",
      },
      {
        id: 87,
        expression: 'Тебе не хватило времени?',
        transcription: 'wɜː juː ʃɔːt ɒv taɪm?',
        translation: 'Were you short of time?',
      },
      {
        id: 88,
        expression: 'Им не хватает времени',
        transcription: 'ðeə ʃɔːt ɒv taɪm',
        translation: "They're short of time",
      },
      {
        id: 89,
        expression: 'Было очень мило с твоей стороны предложить это',
        transcription: 'ɪt wɒz ˈvɛri naɪs ɒv juː tuː ˈɒfər ɪt',
        translation: 'It was very nice of you to offer it',
      },
      {
        id: 90,
        expression: 'У мальчика аллергия на эту еду',
        transcription: 'ðə bɔɪ ɪz əˈlɜːʤɪk tuː ðɪs fuːd',
        translation: 'The boy is allergic to this food',
      },
      {
        id: 91,
        expression: 'Я отсутствовал на встрече',
        transcription: 'aɪ wɒz ˈæbsənt frɒm ðə ˈmiːtɪŋ',
        translation: 'I was absent from the meeting',
      },
      {
        id: 92,
        expression: 'Я хочу с ним познакомиться',
        transcription: 'aɪ wɒnt tuː gɛt əˈkweɪntɪd wɪð hɪm',
        translation: 'I want to get acquainted with him',
      },
      {
        id: 93,
        expression: 'Почему он так завидует ей?',
        transcription: 'waɪ ɪz hiː səʊ ˈɛnvɪəs ɒv hɜː?',
        translation: 'Why is he so envious of her?',
      },
      {
        id: 94,
        expression: 'Она присутствовала на уроке',
        transcription: 'ʃiː wɒz ˈprɛznt æt ðə ˈlɛsn',
        translation: 'She was present at the lesson',
      },
      {
        id: 95,
        expression: 'Ты знаком с ней?',
        transcription: 'ɑː juː əˈkweɪntɪd wɪð hɜː?',
        translation: 'Are you acquainted with her?',
      },
      {
        id: 96,
        expression: 'Она отсутствовала в школе',
        transcription: 'ʃiː wɒz ˈæbsənt frɒm skuːl',
        translation: 'She was absent from school',
      },
      {
        id: 97,
        expression: 'У нее аллергия на домашнюю пыль',
        transcription: 'ʃiː ɪz əˈlɜːʤɪk tuː haʊs dʌst',
        translation: 'She is allergic to house dust',
      },
      {
        id: 98,
        expression: 'Это очень мило с твоей стороны',
        transcription: 'ɪts ˈvɛri naɪs ɒv juː',
        translation: "It's very nice of you",
      },
      {
        id: 99,
        expression: 'Он присутствовал на встрече',
        transcription: 'hiː wɒz ˈprɛznt æt ðə ˈmiːtɪŋ',
        translation: 'He was present at the meeting',
      },
      {
        id: 100,
        expression: 'Он не благодарен за это?',
        transcription: 'ˈɪznt hiː ˈgreɪtfʊl fɔːr ɪt?',
        translation: "Isn't he grateful for it?",
      },
      {
        id: 101,
        expression: 'Ему не хватает наличных',
        transcription: 'hiːz ʃɔːt ɒv kæʃ',
        translation: "He's short of cash",
      },
      {
        id: 102,
        expression: 'Она грустит об этом',
        transcription: 'ʃiːz sæd əˈbaʊt ɪt',
        translation: "She's sad about it",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Продолжаем изучать зависимые предлоги</h3>
</div>`,
    },
  ],
];
