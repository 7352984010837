export const contentLesson103 = [
  [
    {
      title: "Урок 103. Зависимые предлоги (глаголы). Part 2",
    },
    [
      {
        id: 1,
        expression: "Это зависит от меня",
        transcription: "ɪt dɪˈpɛndz ɒn miː",
        translation: "It depends on me",
      },
      {
        id: 2,
        expression: "Она подождет нас?",
        transcription: "wɪl ʃi weɪt fər ʌs?",
        translation: "Will she wait for us?",
      },
      {
        id: 3,
        expression: "Он прибыл на вечеринку?",
        transcription: "dɪd hi əˈraɪv ət ðə ˈpɑːti?",
        translation: "Did he arrive at the party?",
      },
      {
        id: 4,
        expression: "Я попросил совета",
        transcription: "aɪ ɑːskt fər ədˈvaɪs",
        translation: "I asked for advice",
      },
      {
        id: 5,
        expression: "Когда ты прибудешь в аэропорт?",
        transcription: "wɛn wɪl jʊ əˈraɪv ət ði ˈeəpɔːt?",
        translation: "When will you arrive at the airport?",
      },
      {
        id: 6,
        expression: "Ты подписан на тот канал?",
        transcription: "ɑː jʊ səbˈskraɪbd tə ðət ˈʧænl?",
        translation: "Are you subscribed to that channel?",
      },
      {
        id: 7,
        expression: "Все зависит от меня",
        transcription: "ˈɛvrɪθɪŋ dɪˈpɛndz ɒn miː",
        translation: "Everything depends on me",
      },
      {
        id: 8,
        expression: "Мой отец верит в Бога",
        transcription: "maɪ ˈfɑːðə bɪˈliːvz ɪn gɒd",
        translation: "My father believes in God",
      },
      {
        id: 9,
        expression: "Я не могу положиться на него",
        transcription: "aɪ kɑːnt rɪˈlaɪ ɒn hɪm",
        translation: "I can't rely on him",
      },
      {
        id: 10,
        expression: "Он хочет вложить свои деньги в этот проект",
        transcription: "hi wɒnts tʊ ɪnˈvɛst ɔːl ɪz ˈmʌni ɪn ðɪs ˈprɒʤɛkt",
        translation: "He wants to invest all his money in this project",
      },
      {
        id: 11,
        expression: "Ты можешь рассчитывать на его помощь",
        transcription: "jʊ kən kaʊnt ɒn ɪz hɛlp",
        translation: "You can count on his help",
      },
      {
        id: 12,
        expression: "Когда ты прибыл в Соединенные Штаты?",
        transcription: "wɛn dɪd jʊ əˈraɪv ɪn ðə jʊˈnaɪtɪd steɪts?",
        translation: "When did you arrive in the United States?",
      },
      {
        id: 13,
        expression: "Как он отреагирует на это?",
        transcription: "haʊ wɪl hi ri(ː)ˈækt tʊ ɪt?",
        translation: "How will he react to it?",
      },
      {
        id: 14,
        expression: "Я потратил так много денег на это",
        transcription: "aɪ spɛnt səʊ mʌʧ ˈmʌni ɒn ɪt",
        translation: "I spent so much money on it",
      },
      {
        id: 15,
        expression: "Ты всегда можешь рассчитывать на мою поддержку",
        transcription: "jʊ kən ˈɔːlweɪz kaʊnt ɒn maɪ səˈpɔːt",
        translation: "You can always count on my support",
      },
      {
        id: 16,
        expression: "Она улыбнулась ему, и он улыбнулся ей",
        transcription: "ʃi smaɪld ət ɪm ənd hi smaɪld ət hɜː",
        translation: "She smiled at him and he smiled at her",
      },
      {
        id: 17,
        expression: "Что зависит от меня?",
        transcription: "wɒt dɪˈpɛndz ɒn miː?",
        translation: "What depends on me?",
      },
      {
        id: 18,
        expression: "Я разговаривал с ней",
        transcription: "aɪ tɔːkt (spəʊk) tuː (wɪð) hɜː",
        translation: "I talked (spoke) to (with) her",
      },
      {
        id: 19,
        expression: "Почему ты смеешься над этим?",
        transcription: "waɪ dʊ jʊ lɑːf ət ɪt?",
        translation: "Why do you laugh at it?",
      },
      {
        id: 20,
        expression: "Подожди меня немного!",
        transcription: "weɪt fə mi ə ˈlɪtl!",
        translation: "Wait for me a little!",
      },
      {
        id: 21,
        expression: "Ты будешь его ждать?",
        transcription: "wɪl jʊ weɪt fə hɪm?",
        translation: "Will you wait for him?",
      },
      {
        id: 22,
        expression: "Подожди секунду!",
        transcription: "weɪt fər ə ˈsɛkənd?",
        translation: "Wait for a second?",
      },
      {
        id: 23,
        expression: "Он не может ждать этого больше",
        transcription: "hi kɑːnt weɪt fər ɪt ˈɛni ˈlɒŋgə",
        translation: "He can't wait for it any longer",
      },
      {
        id: 24,
        expression: "Ты можешь рассчитывать на меня",
        transcription: "jʊ kən kaʊnt ɒn miː",
        translation: "You can count on me",
      },
      {
        id: 25,
        expression: "Я подписан на этот канал",
        transcription: "aɪm səbˈskraɪbd tə ðɪs ˈʧænl",
        translation: "I'm subscribed to this channel",
      },
      {
        id: 26,
        expression: "Ты подписался на этот канал?",
        transcription: "dɪd jʊ səbˈskraɪb tə ðɪs ˈʧænl?",
        translation: "Did you subscribe to this channel?",
      },
      {
        id: 27,
        expression: "Я разговаривал с ним вчера",
        transcription: "aɪ tɔːkt tə ɪm ˈjɛstədeɪ",
        translation: "I talked to him yesterday",
      },
      {
        id: 28,
        expression: "Как он реагирует на эту критику?",
        transcription: "haʊ dəz hi ri(ː)ˈækt tə ðɪs ˈkrɪtɪsɪzm?",
        translation: "How does he react to this criticism?",
      },
      {
        id: 29,
        expression: "Ты платишь за свое образование?",
        transcription: "dʊ jʊ peɪ fə jər ˌɛdju(ː)ˈkeɪʃən?",
        translation: "Do you pay for your education?",
      },
      {
        id: 30,
        expression: "Это принадлежит ей?",
        transcription: "dəz ɪt bɪˈlɒŋ tə hɜː?",
        translation: "Does it belong to her?",
      },
      {
        id: 31,
        expression: "Он попросил помощи",
        transcription: "hi ɑːskt fə hɛlp",
        translation: "He asked for help",
      },
      {
        id: 32,
        expression: "Посмотри на эту страницу!",
        transcription: "lʊk ət ðɪs peɪʤ!",
        translation: "Look at this page!",
      },
      {
        id: 33,
        expression: "Я больше не хочу ждать этого",
        transcription: "aɪ dəʊnt wɒnt tə weɪt fər ɪt ˌɛniˈmɔː",
        translation: "I don't want to wait for it anymore",
      },
      {
        id: 34,
        expression: "Он извинился за это",
        transcription: "hi əˈpɒləʤaɪzd fər ɪt",
        translation: "He apologised for it",
      },
      {
        id: 35,
        expression: "Как он отреагировал на это?",
        transcription: "haʊ dɪd hi ri(ː)ˈækt tʊ ɪt?",
        translation: "How did he react to it?",
      },
      {
        id: 36,
        expression: "Мы обычно ждем этого с нетерпением",
        transcription: "wi ˈjuːʒʊəli weɪt fər ɪt wɪð ɪmˈpeɪʃəns",
        translation: "We usually wait for it with impatience",
      },
      {
        id: 37,
        expression: "Это случилось со мной очень неожиданно",
        transcription: "ɪt ˈhæpənd tə mi ˈvɛri ˌʌnɪksˈpɛktɪdli",
        translation: "It happened to me very unexpectedly",
      },
      {
        id: 38,
        expression: "Я подписался на этот канал",
        transcription: "aɪ səbˈskraɪbd tə ðɪs ˈʧænl",
        translation: "I subscribed to this channel",
      },
      {
        id: 39,
        expression: "Послушай его!",
        transcription: "ˈlɪsn tə hɪm!",
        translation: "Listen to him!",
      },
      {
        id: 40,
        expression: "Он не платит за это",
        transcription: "hi dʌznt peɪ fər ɪt",
        translation: "He doesn't pay for it",
      },
      {
        id: 41,
        expression: "Он очень странно отреагировал на это замечание",
        transcription: "hi ri(ː)ˈæktɪd tə ðɪs ˈrɪˈmɑːk ˈvɛri ˈstreɪnʤli",
        translation: "He reacted to this remark very strangely",
      },
      {
        id: 42,
        expression: "Мне нужно посмотреть на это",
        transcription: "aɪ niːd tə lʊk ət ɪt",
        translation: "I need to look at it",
      },
      {
        id: 43,
        expression: "Учителя часто кричат на него, что, конечно, недопустимо",
        transcription:
          "ðə ˈtiːʧəz ˈɒf(ə)n ʃaʊt ət ɪm wɪʧ ɪz, əv kɔːs, ˌʌnəkˈsɛptəbl",
        translation:
          "The teachers often shout at him which is, of course, unacceptable",
      },
      {
        id: 44,
        expression: "Мне нужно как-то адаптироваться к этим условиям",
        transcription: "aɪ niːd tʊ əˈdæpt tə ðiːz kənˈdɪʃənz ˈsʌmhaʊ",
        translation: "I need to adapt to these conditions somehow",
      },
      {
        id: 45,
        expression: "Она с нетерпением ждет поездки в Соединенные Штаты",
        transcription: "ʃi lʊks ˈfɔːwəd tə ˈgəʊɪŋ tə ðə jʊˈnaɪtɪd steɪts",
        translation: "She looks forward to going to the United States",
      },
      {
        id: 46,
        expression: "Она хотела бы сосредоточиться на следующих вопросах",
        transcription: "ʃiːd laɪk tə ˈkɒnsəntreɪt ɒn ðə ˈfɒləʊɪŋ ˈɪʃuːz",
        translation: "She'd like to concentrate on the following issues",
      },
      {
        id: 47,
        expression: "Он бросил в него камень",
        transcription: "hi θruː ət ɪm ə stəʊn",
        translation: "He threw at him a stone",
      },
      {
        id: 48,
        expression: "Он крикнул ей, но она не слышала его",
        transcription: "hi ˈʃaʊtɪd tə hə bət ʃi dɪdnt hɪə hɪm",
        translation: "He shouted to her but she didn't hear him",
      },
      {
        id: 49,
        expression: "Я с нетерпением жду моих каникул",
        transcription:
          "aɪ lʊk ˈfɔːwəd tə maɪ ˈhɒlədeɪz / aɪ lʊk ˈfɔːwəd tə ˈgəʊɪŋ ɒn ˈhɒlədeɪ",
        translation:
          "I look forward to my holidays / I look forward to going on holiday",
      },
      {
        id: 50,
        expression: "Я решил поговорить с ним",
        transcription: "aɪ dɪˈsaɪdɪd tə tɔːk tə hɪm",
        translation: "I decided to talk to him",
      },
      {
        id: 51,
        expression: "Они обвинили его в убийстве",
        transcription: "ðeɪ əˈkjuːzd ɪm əv ˈmɜːdə",
        translation: "They accused him of murder",
      },
      {
        id: 52,
        expression: "Я бы послушал его совет",
        transcription: "aɪd ˈlɪsn tə ɪz ədˈvaɪs",
        translation: "I'd listen to his advice",
      },
      {
        id: 53,
        expression: "Я с нетерпением жду твоего ответа",
        transcription: "aɪ lʊk ˈfɔːwəd tə jə rɪˈplaɪ",
        translation: "I look forward to your reply",
      },
      {
        id: 54,
        expression: "Он бросил то полотенце ему",
        transcription: "hi θruː ðət ˈtaʊəl tə hɪm",
        translation: "He threw that towel to him",
      },
      {
        id: 55,
        expression: "Мне пришлось адаптироваться к этим новым условиям",
        transcription: "aɪ həd tʊ əˈdæpt tə ðiːz njuː kənˈdɪʃənz",
        translation: "I had to adapt to these new conditions",
      },
      {
        id: 56,
        expression: "Он предпочитает не жаловаться на это",
        transcription: "hi priˈfɜːz nɒt tə kəmˈpleɪn əˈbaʊt ɪt",
        translation: "He prefers not to complain about it",
      },
      {
        id: 57,
        expression: "Он обвинил их в грабеже",
        transcription: "hi əˈkjuːzd ðəm əv ˈrɒbəri",
        translation: "He accused them of robbery",
      },
      {
        id: 58,
        expression: "Я решил начать искать новую работу",
        transcription: "aɪ dɪˈsaɪdɪd tə stɑːt ˈlʊkɪŋ fər ə njuː ʤɒb",
        translation: "I decided to start looking for a new job",
      },
      {
        id: 59,
        expression: "Мне нужно начать искать лучшую работу",
        transcription: "aɪ niːd tə stɑːt ˈlʊkɪŋ fər ə ˈbɛtə ʤɒb",
        translation: "I need to start looking for a better job",
      },
      {
        id: 60,
        expression: "Ты решил начать искать новую работу?",
        transcription: "dɪd jʊ dɪˈsaɪd tə stɑːt ˈlʊkɪŋ fər ə njuː ʤɒb?",
        translation: "Did you decide to start looking for a new job?",
      },
      {
        id: 61,
        expression: "Учитель часто кричит на своих учеников, что недопустимо",
        transcription:
          "ðə ˈtiːʧər ˈɒf(ə)n ʃaʊts ət ɪz ˈstjuːdənts (ˈpjuːplz) wɪʧ ɪz ˌʌnəkˈsɛptəbl",
        translation:
          "The teacher often shouts at his students (pupils) which is unacceptable",
      },
      {
        id: 62,
        expression: "Мой учитель иногда кричит на меня",
        transcription: "maɪ ˈtiːʧə ˈsʌmtaɪmz ʃaʊts ət miː",
        translation: "My teacher sometimes shouts at me",
      },
      {
        id: 63,
        expression: "Как часто твой учитель кричит на тебя?",
        transcription: "haʊ ˈɒf(ə)n dəz jə ˈtiːʧə ʃaʊt ət juː?",
        translation: "How often does your teacher shout at you?",
      },
      {
        id: 64,
        expression: "Я хотел бы сосредоточиться на следующих проблемах",
        transcription: "aɪd laɪk tə ˈkɒnsəntreɪt ɒn ðə ˈfɒləʊɪŋ ˈprɒbləmz",
        translation: "I'd like to concentrate on the following problems",
      },
      {
        id: 65,
        expression: "Мы должны сосредоточиться на следующих вопросах",
        transcription: "wi məst ˈkɒnsəntreɪt ɒn ðə ˈfɒləʊɪŋ ˈɪʃuːz",
        translation: "We must concentrate on the following issues",
      },
      {
        id: 66,
        expression: "Мне нужно сосредоточиться на этих задачах",
        transcription: "aɪ niːd tə ˈkɒnsəntreɪt ɒn ðiːz tɑːsks",
        translation: "I need to concentrate on these tasks",
      },
      {
        id: 67,
        expression: "Он с нетерпением ждет твоего ответа",
        transcription: "hi lʊks ˈfɔːwəd tə jər ˈɑːnsə",
        translation: "He looks forward to your answer",
      },
      {
        id: 68,
        expression: "Он с нетерпением ждет своих каникул",
        transcription: "hi lʊks ˈfɔːwəd tə ɪz ˈhɒlədeɪz",
        translation: "He looks forward to his holidays",
      },
      {
        id: 69,
        expression: "Я не хотел бы на этом настаивать",
        transcription: "aɪ ˈwʊdnt laɪk tʊ ɪnˈsɪst ɒn ɪt",
        translation: "I wouldn't like to insist on it",
      },
      {
        id: 70,
        expression: "Тебе нужно было настоять на этом",
        transcription: "jʊ ˈniːdɪd tʊ ɪnˈsɪst ɒn ɪt",
        translation: "You needed to insist on it",
      },
      {
        id: 71,
        expression: "Он не будет настаивать на этом",
        transcription: "hi wəʊnt ɪnˈsɪst ɒn ɪt",
        translation: "He won't insist on it",
      },
      {
        id: 72,
        expression: "Я с нетерпением жду следующего урока",
        transcription: "aɪ lʊk ˈfɔːwəd tə ðə nɛkst ˈlɛsn",
        translation: "I look forward to the next lesson",
      },
      {
        id: 73,
        expression: "Я с нетерпением жду встречи с тобой снова",
        transcription: "aɪ lʊk ˈfɔːwəd tə ˈsiːɪŋ jʊ əˈgɛn",
        translation: "I look forward to seeing you again",
      },
      {
        id: 74,
        expression: "Она с нетерпением ждет этих фотографий",
        transcription: "ʃi lʊks ˈfɔːwəd tə ðiːz ˈfəʊtəʊz",
        translation: "She looks forward to these photos",
      },
      {
        id: 75,
        expression: "Я не хочу на этом настаивать",
        transcription: "aɪ dəʊnt wɒnt tʊ ɪnˈsɪst ɒn ɪt",
        translation: "I don't want to insist on it",
      },
      {
        id: 76,
        expression: "Она не хотела на этом настаивать",
        transcription: "ʃi dɪdnt wɒnt tʊ ɪnˈsɪst ɒn ɪt",
        translation: "She didn't want to insist on it",
      },
      {
        id: 77,
        expression: "Я не буду на этом настаивать",
        transcription: "aɪ wəʊnt ɪnˈsɪst ɒn ɪt",
        translation: "I won't insist on it",
      },
      {
        id: 78,
        expression: "Он никогда не жалуется на это",
        transcription: "hi ˈnɛvə kəmˈpleɪnz əˈbaʊt ɪt",
        translation: "He never complains about it",
      },
      {
        id: 79,
        expression: "Он жаловался на это?",
        transcription: "dɪd hi kəmˈpleɪn əˈbaʊt ɪt?",
        translation: "Did he complain about it?",
      },
      {
        id: 80,
        expression: "Я никогда не буду жаловаться на это",
        transcription: "aɪl ˈnɛvə kəmˈpleɪn əˈbaʊt ɪt",
        translation: "I'll never complain about it",
      },
      {
        id: 81,
        expression: "Я не хочу настаивать на том, чтобы сделать это",
        transcription: "aɪ dəʊnt wɒnt tʊ ɪnˈsɪst ɒn ˈdu(ː)ɪŋ ɪt",
        translation: "I don't want to insist on doing it",
      },
      {
        id: 82,
        expression: "Он не хочет на этом настаивать",
        transcription: "hi dʌznt wɒnt tʊ ɪnˈsɪst ɒn ɪt",
        translation: "He doesn't want to insist on it",
      },
      {
        id: 83,
        expression: "Я должен настаивать на том, чтобы сделать это",
        transcription: "aɪ məst ɪnˈsɪst ɒn ˈdu(ː)ɪŋ ɪt",
        translation: "I must insist on doing it",
      },
      {
        id: 84,
        expression: "Он крикнул ей очень тихо",
        transcription: "hi ˈʃaʊtɪd tə hə ˈvɛri ˈkwaɪətli",
        translation: "He shouted to her very quietly",
      },
      {
        id: 85,
        expression: "Тебе нужно перестать кричать на него",
        transcription: "jʊ niːd tə stɒp ˈʃaʊtɪŋ ət hɪm",
        translation: "You need to stop shouting at him",
      },
      {
        id: 86,
        expression: "Он тебе что-то крикнул?",
        transcription: "dɪd hi ʃaʊt ˈɛnɪθɪŋ tə juː?",
        translation: "Did he shout anything to you?",
      },
      {
        id: 87,
        expression: "Что он бросил в него?",
        transcription: "wɒt dɪd hi θruː ət hɪm?",
        translation: "What did he threw at him?",
      },
      {
        id: 88,
        expression: "Я с нетерпением жду того, чтобы пойти в отпуск",
        transcription: "aɪ lʊk ˈfɔːwəd tə ˈgəʊɪŋ ɒn ˈhɒlədeɪ",
        translation: "I look forward to going on holiday",
      },
      {
        id: 89,
        expression: "Они обвинили его в преступлении",
        transcription: "ðeɪ əˈkjuːzd ɪm əv ə kraɪm",
        translation: "They accused him of a crime",
      },
      {
        id: 90,
        expression: "Я с нетерпением жду твоего сообщения",
        transcription: "aɪ lʊk ˈfɔːwəd tə jʊ ˈmɛsɪʤ",
        translation: "I look forward to you message",
      },
      {
        id: 91,
        expression: "Я не хотел бы настаивать на том, чтобы сделать это",
        transcription: "aɪ ˈwʊdnt laɪk tʊ ɪnˈsɪst ɒn ˈdu(ː)ɪŋ ɪt",
        translation: "I wouldn't like to insist on doing it",
      },
      {
        id: 92,
        expression: "Я начал искать новую работу",
        transcription: "aɪ stɑːt ˈlʊkɪŋ fər ə njuː ʤɒb",
        translation: "I start looking for a new job",
      },
      {
        id: 93,
        expression: "Они обвинили его в очень серьезном преступлении",
        transcription: "ðeɪ əˈkjuːzd ɪm əv ə ˈvɛri ˈsɪərɪəs kraɪm",
        translation: "They accused him of a very serious crime",
      },
      {
        id: 94,
        expression: "Мы должны были приспособиться к этим изменениям",
        transcription: "wi həd tʊ əˈdæpt tə ðiːz ˈʧeɪnʤɪz",
        translation: "We had to adapt to these changes",
      },
      {
        id: 95,
        expression: "Я с нетерпением жду твоего ответа в ближайшее время",
        transcription: "aɪ lʊk ˈfɔːwəd tə ˈhɪərɪŋ frəm jʊ suːn",
        translation: "I look forward to hearing from you soon",
      },
      {
        id: 96,
        expression: "Он предпочитает не говорить об этом",
        transcription: "hi priˈfɜːz nɒt tə tɔːk əˈbaʊt ɪt",
        translation: "He prefers not to talk about it",
      },
      {
        id: 97,
        expression: "Он бросил ему то полотенце",
        transcription: "hi θruː ðət ˈtaʊəl tə hɪm / hi θruː ɪm ðət ˈtaʊəl",
        translation: "He threw that towel to him / He threw him that towel",
      },
      {
        id: 98,
        expression: "Она обвинила его во лжи",
        transcription: "ʃi əˈkjuːzd ɪm əv ə laɪ",
        translation: "She accused him of a lie",
      },
      {
        id: 99,
        expression: "Я с нетерпением жду следующей встречи",
        transcription: "aɪ lʊk ˈfɔːwəd tə ðə nɛkst ˈmiːtɪŋ",
        translation: "I look forward to the next meeting",
      },
      {
        id: 100,
        expression: "Он может пожаловаться на это",
        transcription: "hi kən kəmˈpleɪn əˈbaʊt ɪt",
        translation: "He can complain about it",
      },
      {
        id: 101,
        expression: "Не кричи на него!",
        transcription: "dəʊnt ʃaʊt ət hɪm!",
        translation: "Don't shout at him!",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>depend + on</h3>
<p>- зависеть от чего-то / кого-то</p>
<p>It doesn't depend on me - Это не зависит от меня.</p>
<h3>wait + for</h3>
<p>- ждать чего-то / кого-то</p>
<p>Will you wait for me? - Ты подождешь меня?</p>
</div>`,
    },
  ],
];
