export const contentLesson53 = [
  [
    {
      title: "Урок 53. Построение словосочетаний с to be",
    },
    [
      {
        id: 1,
        expression: "Он умный мальчик",
        transcription: "hiːz ə smɑːt bɔɪ",
        translation: "He's a smart boy",
      },
      {
        id: 2,
        expression: "Она красивая девушка",
        transcription: "ʃiːz ə ˈbjuːtəfʊl gɜːl",
        translation: "She's a beautiful girl",
      },
      {
        id: 3,
        expression: "Это холодная вода",
        transcription: "ɪts kəʊld ˈwɔːtə",
        translation: "It's cold water",
      },
      {
        id: 4,
        expression: "Это горячий кофе",
        transcription: "ɪts hɒt ˈkɒfi",
        translation: "It's hot coffee",
      },
      {
        id: 5,
        expression: "Они гостеприимные люди",
        transcription: "ðeə ˈhɒspɪtəbl ˈpiːpl",
        translation: "They're hospitable people",
      },
      {
        id: 6,
        expression: "Вы хорошие друзья",
        transcription: "jʊə gʊd frɛndz",
        translation: "You're good friends",
      },
      {
        id: 7,
        expression: "Он очень богатый человек",
        transcription: "hiːz ə ˈvɛri rɪʧ ˈpɜːsn",
        translation: "He's a very rich person",
      },
      {
        id: 8,
        expression: "Она умная девушка",
        transcription: "ʃiːz ə smɑːt gɜːl",
        translation: "She's a smart girl",
      },
      {
        id: 9,
        expression: "Это долгий процесс",
        transcription: "ɪts ə lɒŋ ˈprəʊsɛs",
        translation: "It's a long process",
      },
      {
        id: 10,
        expression: "Это наше лучшее достижение",
        transcription: "ɪts ˈaʊə bɛst əˈʧiːvmənt",
        translation: "It's our best achievement",
      },
      {
        id: 11,
        expression: "Они очень профессиональные работники",
        transcription: "ðeə ˈvɛri prəˈfɛʃənl ˈwɜːkəz",
        translation: "They're very professional workers",
      },
      {
        id: 12,
        expression: "Это лучший выбор",
        transcription: "ɪts ðə bɛst ʧɔɪs",
        translation: "It's the best choice",
      },
      {
        id: 13,
        expression: "Это большая ошибка",
        transcription: "ɪts ə bɪg mɪsˈteɪk",
        translation: "It's a big mistake",
      },
      {
        id: 14,
        expression: "Это смешное видео",
        transcription: "ɪts ə ˈfʌni ˈvɪdɪəʊ",
        translation: "It's a funny video",
      },
      {
        id: 15,
        expression: "Я абсолютно уверен",
        transcription: "aɪm ˈæbsəluːtli ʃʊə",
        translation: "I'm absolutely sure",
      },
      {
        id: 16,
        expression: "Это высокая цена",
        transcription: "ɪts ə haɪ praɪs",
        translation: "It's a high price",
      },
      {
        id: 17,
        expression: "Ты такой умный",
        transcription: "jʊə səʊ smɑːt",
        translation: "You're so smart",
      },
      {
        id: 18,
        expression: "Это маленькая ошибка",
        transcription: "ɪts ə smɔːl mɪsˈteɪk",
        translation: "It's a small mistake",
      },
      {
        id: 19,
        expression: "Они партнеры",
        transcription: "ðeə ˈpɑːtnəz",
        translation: "They're partners",
      },
      {
        id: 20,
        expression: "Он прав",
        transcription: "hiːz raɪt",
        translation: "He's right",
      },
      {
        id: 21,
        expression: "Это интересный факт",
        transcription: "ɪts ən ˈɪntrɪstɪŋ fækt",
        translation: "It's an interesting fact",
      },
      {
        id: 22,
        expression: "Он очень дружелюбный",
        transcription: "hiːz ˈvɛri ˈfrɛndli",
        translation: "He's very friendly",
      },
      {
        id: 23,
        expression: "Они очень гостеприимны",
        transcription: "ðeə ˈvɛri ˈhɒspɪtəbl",
        translation: "They're very hospitable",
      },
      {
        id: 24,
        expression: "Это мой лучший результат",
        transcription: "ɪts maɪ bɛst rɪˈzʌlt",
        translation: "It's my best result",
      },
      {
        id: 25,
        expression: "Они женаты",
        transcription: "ðeə ˈmærɪd",
        translation: "They're married",
      },
      {
        id: 26,
        expression: "Это слишком дорого для него",
        transcription: "ɪts tuː ɪksˈpɛnsɪv fə hɪm",
        translation: "It's too expensive for him",
      },
      {
        id: 27,
        expression: "Она профессиональный учитель",
        transcription: "ʃiːz ə prəˈfɛʃənl ˈtiːʧə",
        translation: "She's a professional teacher",
      },
      {
        id: 28,
        expression: "Это бесплатный курс",
        transcription: "ɪts ə friː kɔːs",
        translation: "It's a free course",
      },
      {
        id: 29,
        expression: "Они очень молоды",
        transcription: "ðeə ˈvɛri jʌŋ",
        translation: "They're very young",
      },
      {
        id: 30,
        expression: "Это большие деньги",
        transcription: "ɪts bɪg ˈmʌni",
        translation: "It's big money",
      },
      {
        id: 31,
        expression: "Он умный парень",
        transcription: "hiːz ə smɑːt gaɪ",
        translation: "He's a smart guy",
      },
      {
        id: 32,
        expression: "Это лучший результат",
        transcription: "ɪts ðə bɛst rɪˈzʌlt",
        translation: "It's the best result",
      },
      {
        id: 33,
        expression: "Это интересное предложение",
        transcription: "ɪts ən ˈɪntrɪstɪŋ ˈɒfə",
        translation: "It's an interesting offer",
      },
      {
        id: 34,
        expression: "Мы друзья",
        transcription: "wɪə frɛndz",
        translation: "We're friends",
      },
      {
        id: 35,
        expression: "Это лучшее решение",
        transcription: "ɪts ðə bɛst dɪˈsɪʒən",
        translation: "It's the best decision",
      },
      {
        id: 36,
        expression: "Это очень большие деньги",
        transcription: "ɪts ˈvɛri bɪg ˈmʌni",
        translation: "It's very big money",
      },
      {
        id: 37,
        expression: "Это маленький город",
        transcription: "ɪts ə smɔːl taʊn",
        translation: "It's a small town",
      },
      {
        id: 38,
        expression: "Я в парке",
        transcription: "aɪm ɪn ðə pɑːk",
        translation: "I'm in the park",
      },
      {
        id: 39,
        expression: "Это так хорошо",
        transcription: "ɪts səʊ gʊd",
        translation: "It's so good",
      },
      {
        id: 40,
        expression: "Это дорогой отель",
        transcription: "ɪts ən ɪksˈpɛnsɪv həʊˈtɛl",
        translation: "It's an expensive hotel",
      },
      {
        id: 41,
        expression: "Это важная вещь",
        transcription: "ɪts ən ɪmˈpɔːtənt θɪŋ",
        translation: "It's an important thing",
      },
      {
        id: 42,
        expression: "Это бесплатный урок",
        transcription: "ɪts ə friː ˈlɛsn",
        translation: "It's a free lesson",
      },
      {
        id: 43,
        expression: "Это дешевый отель",
        transcription: "ɪts ə ʧiːp həʊˈtɛl",
        translation: "It's a cheap hotel",
      },
      {
        id: 44,
        expression: "Это скучная книга",
        transcription: "ɪts ə ˈbɔːrɪŋ bʊk",
        translation: "It's a boring book",
      },
      {
        id: 45,
        expression: "Это его лучшее достижение",
        transcription: "ɪts ɪz bɛst əˈʧiːvmənt",
        translation: "It's his best achievement",
      },
      {
        id: 46,
        expression: "Ты такой щедрый",
        transcription: "jʊə səʊ ˈʤɛnərəs",
        translation: "You're so generous",
      },
      {
        id: 47,
        expression: "Это интересная идея",
        transcription: "ɪts ən ˈɪntrɪstɪŋ aɪˈdɪə",
        translation: "It's an interesting idea",
      },
      {
        id: 48,
        expression: "Это очень важная деталь",
        transcription: "ɪts ə ˈvɛri ɪmˈpɔːtənt ˈdiːteɪl",
        translation: "It's a very important detail",
      },
      {
        id: 49,
        expression: "Я думаю, это очень скучная книга",
        transcription: "aɪ θɪŋk ɪts ə ˈvɛri ˈbɔːrɪŋ bʊk",
        translation: "I think it's a very boring book",
      },
      {
        id: 50,
        expression: "Это очень богатая компания",
        transcription: "ɪts ə ˈvɛri rɪʧ ˈkʌmpəni",
        translation: "It's a very rich company",
      },
      {
        id: 51,
        expression: "Это потрясающий результат",
        transcription: "ɪts ən əˈmeɪzɪŋ rɪˈzʌlt",
        translation: "It's an amazing result",
      },
      {
        id: 52,
        expression: "Ты очень хороший человек",
        transcription: "jʊər ə ˈvɛri gʊd ˈpɜːsn",
        translation: "You're a very good person",
      },
      {
        id: 53,
        expression: "Я прекрасно понимаю, что это уникальная возможность",
        transcription:
          "aɪ ˈpɜːfɪktli ˌʌndəˈstænd ðət ɪts ə juːˈniːk ˌɒpəˈtjuːnɪti",
        translation: "I perfectly understand that it's a unique opportunity",
      },
      {
        id: 54,
        expression: "Я чувствую, что она настоящий эксперт в этой области",
        transcription: "aɪ fiːl ðət ʃiːz ə rɪəl ˈɛkspɜːt ɪn ðɪs ˈeərɪə",
        translation: "I feel that she's a real expert in this area",
      },
      {
        id: 55,
        expression: "Он очень профессиональный учитель",
        transcription: "hiːz ə ˈvɛri prəˈfɛʃənl ˈtiːʧə",
        translation: "He's a very professional teacher",
      },
      {
        id: 56,
        expression: "Я прекрасно осознаю, что это очень важное решение",
        transcription:
          "aɪ ˈpɜːfɪktli ˈrɪəlaɪz ðət ɪts ə ˈvɛri ɪmˈpɔːtənt dɪˈsɪʒən",
        translation: "I perfectly realise that it's a very important decision",
      },
      {
        id: 57,
        expression: "Это настоящее достижение для меня",
        transcription: "ɪts ə rɪəl əˈʧiːvmənt fə miː",
        translation: "It's a real achievement for me",
      },
      {
        id: 58,
        expression: "Это очень дорогой отель",
        transcription: "ɪts ə ˈvɛri ɪksˈpɛnsɪv həʊˈtɛl",
        translation: "It's a very expensive hotel",
      },
      {
        id: 59,
        expression: "Она очень красивая девушка",
        transcription: "ʃiːz ə ˈvɛri ˈbjuːtəfʊl gɜːl",
        translation: "She's a very beautiful girl",
      },
      {
        id: 60,
        expression: "У него очень большой дом",
        transcription: "hi həz ə ˈvɛri lɑːʤ haʊs",
        translation: "He has a very large house",
      },
      {
        id: 61,
        expression: "Это очень хороший вопрос",
        transcription: "ɪts ə ˈvɛri gʊd ˈkwɛsʧən",
        translation: "It's a very good question",
      },
      {
        id: 62,
        expression: "Это важное событие в моей жизни",
        transcription: "ɪts ən ɪmˈpɔːtənt ɪˈvɛnt ɪn maɪ laɪf",
        translation: "It's an important event in my life",
      },
      {
        id: 63,
        expression: "Он очень позитивный человек",
        transcription: "hiːz ə ˈvɛri ˈpɒzətɪv ˈpɜːsn",
        translation: "He's a very positive person",
      },
      {
        id: 64,
        expression: "Они очень позитивные люди",
        transcription: "ðeə ˈvɛri ˈpɒzətɪv ˈpiːpl",
        translation: "They're very positive people",
      },
      {
        id: 65,
        expression: "Она очень позитивный человек",
        transcription: "ʃiːz ə ˈvɛri ˈpɒzətɪv ˈpɜːsn",
        translation: "She's a very positive person",
      },
      {
        id: 66,
        expression: "Это важное событие",
        transcription: "ɪts ən ɪmˈpɔːtənt ɪˈvɛnt",
        translation: "It's an important event",
      },
      {
        id: 67,
        expression: "Это очень важное событие",
        transcription: "ɪts ə ˈvɛri ɪmˈpɔːtənt ɪˈvɛnt",
        translation: "It's a very important event",
      },
      {
        id: 68,
        expression: "Это самое важное событие",
        transcription: "ɪts ðə məʊst ɪmˈpɔːtənt ɪˈvɛnt",
        translation: "It's the most important event",
      },
      {
        id: 69,
        expression: "У нее потрясающий голос",
        transcription: "ʃi həz ən əˈmeɪzɪŋ vɔɪs",
        translation: "She has an amazing voice",
      },
      {
        id: 70,
        expression: "У них потрясающие голоса",
        transcription: "ðeɪ həv əˈmeɪzɪŋ ˈvɔɪsɪz",
        translation: "They have amazing voices",
      },
      {
        id: 71,
        expression: "У него потрясающий голос",
        transcription: "hi həz ən əˈmeɪzɪŋ vɔɪs",
        translation: "He has an amazing voice",
      },
      {
        id: 72,
        expression: "Он очень ленивый студент",
        transcription: "hiːz ə ˈvɛri ˈleɪzi ˈstjuːdənt",
        translation: "He's a very lazy student",
      },
      {
        id: 73,
        expression: "Она очень ленивая студентка",
        transcription: "ʃiːz ə ˈvɛri ˈleɪzi ˈstjuːdənt",
        translation: "She's a very lazy student",
      },
      {
        id: 74,
        expression: "Они очень ленивые студенты",
        transcription: "ðeə ˈvɛri ˈleɪzi ˈstjuːdənts",
        translation: "They're very lazy students",
      },
      {
        id: 75,
        expression: "Это бесплатный курс английского языка",
        transcription: "ɪts ə friː ˈɪŋglɪʃ kɔːs",
        translation: "It's a free English course",
      },
      {
        id: 76,
        expression: "Это хороший курс английского языка",
        transcription: "ɪts ə gʊd ˈɪŋglɪʃ kɔːs",
        translation: "It's a good English course",
      },
      {
        id: 77,
        expression: "Это бесплатный урок английского",
        transcription: "ɪts ə friː ˈɪŋglɪʃ ˈlɛsn",
        translation: "It's a free English lesson",
      },
      {
        id: 78,
        expression: "Это уникальная возможность",
        transcription: "ɪts ə juːˈniːk ˌɒpəˈtjuːnɪti",
        translation: "It's a unique opportunity",
      },
      {
        id: 79,
        expression: "Это действительно уникальная возможность",
        transcription: "ɪts ə ˈrɪəli juːˈniːk ˌɒpəˈtjuːnɪti",
        translation: "It's a really unique opportunity",
      },
      {
        id: 80,
        expression: "Это прекрасная возможность",
        transcription: "ɪts ə greɪt ˌɒpəˈtjuːnɪti",
        translation: "It's a great opportunity",
      },
      {
        id: 81,
        expression: "Я думаю, это очень интересный факт",
        transcription: "aɪ θɪŋk ɪts ə ˈvɛri ˈɪntrɪstɪŋ fækt",
        translation: "I think it's a very interesting fact",
      },
      {
        id: 82,
        expression: "Я думаю, что это очень интересный факт",
        transcription: "aɪ θɪŋk ðət ɪts ə ˈvɛri ˈɪntrɪstɪŋ fækt",
        translation: "I think that it's a very interesting fact",
      },
      {
        id: 83,
        expression: "Она думает, это очень интересный факт",
        transcription: "ʃi θɪŋks ɪts ə ˈvɛri ˈɪntrɪstɪŋ fækt",
        translation: "She thinks it's a very interesting fact",
      },
      {
        id: 84,
        expression: "Я прекрасно осознаю, что это уникальный шанс",
        transcription: "aɪ ˈpɜːfɪktli ˈrɪəlaɪz ðət ɪts ə juːˈniːk ʧɑːns",
        translation: "I perfectly realise that it's a unique chance",
      },
      {
        id: 85,
        expression: "Она прекрасно осознает, что это уникальный шанс",
        transcription: "ʃi ˈpɜːfɪktli ˈrɪəlaɪzɪz ðət ɪts ə juːˈniːk ʧɑːns",
        translation: "She perfectly realises that it's a unique chance",
      },
      {
        id: 86,
        expression: "Я прекрасно осознаю, что это лучший шанс",
        transcription: "aɪ ˈpɜːfɪktli ˈrɪəlaɪz ðət ɪts ðə bɛst ʧɑːns",
        translation: "I perfectly realise that it's the best chance",
      },
      {
        id: 87,
        expression: "Он очень щедрый человек",
        transcription: "hiːz ə ˈvɛri ˈʤɛnərəs ˈpɜːsn",
        translation: "He's a very generous person",
      },
      {
        id: 88,
        expression: "Ты очень щедрый человек",
        transcription: "jʊər ə ˈvɛri ˈʤɛnərəs ˈpɜːsn",
        translation: "You're a very generous person",
      },
      {
        id: 89,
        expression: "Она очень щедрый человек",
        transcription: "ʃiːz ə ˈvɛri ˈʤɛnərəs ˈpɜːsn",
        translation: "She's a very generous person",
      },
      {
        id: 90,
        expression: "Это распространенная ошибка",
        transcription: "ɪts ə ˈkɒmən mɪsˈteɪk",
        translation: "It's a common mistake",
      },
      {
        id: 91,
        expression: "Это действительно распространенная ошибка",
        transcription: "ɪts ə ˈrɪəli ˈkɒmən mɪsˈteɪk",
        translation: "It's a really common mistake",
      },
      {
        id: 92,
        expression: "Это самая распространенная ошибка",
        transcription: "ɪts ðə məʊst ˈkɒmən mɪsˈteɪk",
        translation: "It's the most common mistake",
      },
      {
        id: 93,
        expression: "Это важная деталь",
        transcription: "ɪts ən ɪmˈpɔːtənt ˈdiːteɪl",
        translation: "It's an important detail",
      },
      {
        id: 94,
        expression: "Это очень высокая цена",
        transcription: "ɪts ə ˈvɛri ɪksˈpɛnsɪv praɪs",
        translation: "It's a very expensive price",
      },
      {
        id: 95,
        expression: "Я думаю, это реальное достижение для нас",
        transcription: "aɪ θɪŋk ɪts ə rɪəl əˈʧiːvmənt fər ʌs",
        translation: "I think it's a real achievement for us",
      },
      {
        id: 96,
        expression: "Это очень важная вещь",
        transcription: "ɪts ə ˈvɛri ɪmˈpɔːtənt θɪŋ",
        translation: "It's a very important thing",
      },
      {
        id: 97,
        expression: "Она очень добрый человек",
        transcription: "ʃiːz ə ˈvɛri kaɪnd ˈpɜːsn",
        translation: "She's a very kind person",
      },
      {
        id: 98,
        expression: "Мне кажется, что это плохая идея",
        transcription: "ɪt siːmz tə mi ðət ɪts ə bæd aɪˈdɪə",
        translation: "It seems to me that it's a bad idea",
      },
      {
        id: 99,
        expression: "Это очень смешное видео",
        transcription: "ɪts ə ˈvɛri ˈfʌni ˈvɪdɪəʊ",
        translation: "It's a very funny video",
      },
      {
        id: 100,
        expression: "Он очень сильный мужчина",
        transcription: "hiːz ə ˈvɛri strɒŋ mæn",
        translation: "He's a very strong man",
      },
      {
        id: 101,
        expression: "Это очень распространенная ошибка",
        transcription: "ɪts ə ˈvɛri ˈkɒmən mɪsˈteɪk",
        translation: "it's a very common mistake",
      },
      {
        id: 102,
        expression: "Это очень большое число",
        transcription: "ɪts ə ˈvɛri lɑːʤ ˈnʌmbə",
        translation: "It's a very large number",
      },
      {
        id: 103,
        expression: "Он очень добр к нам",
        transcription: "hiːz ˈvɛri kaɪnd tʊ ʌs",
        translation: "He's very kind to us",
      },
      {
        id: 104,
        expression: "Он настоящий эксперт в этой области",
        transcription: "hiːz ə rɪəl ˈɛkspɜːt ɪn ðɪs ˈeərɪə",
        translation: "He's a real expert in this area",
      },
      {
        id: 105,
        expression: "Ты очень добр ко мне",
        transcription: "jʊə ˈvɛri kaɪnd tə miː",
        translation: "You're very kind to me",
      },
      {
        id: 106,
        expression: "Это уникальный шанс",
        transcription: "ɪts ə juːˈniːk ʧɑːns",
        translation: "It's a unique chance",
      },
      {
        id: 107,
        expression: "Это удивительная вещь",
        transcription: "ɪts ən əˈmeɪzɪŋ θɪŋ",
        translation: "It's an amazing thing",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Построение словосочетаний с прилагательным и с глаголом to be</h3>
<p>1. Формула для построения словосочетаний с прилагательным:</p>
<p><span>a / an + прилагательное + исчисляемое существительное в единственном числе</span></p>
<p>He is a smart boy - Он умный мальчик.</p>
<p>It's cold water - Это холодная вода.</p>
<p>They are hospitable children - Они воспитанные дети.</p>
<p>2. Ситуация с наречиями very / really:</p>
<p>He's a very rich person - Он очень богатый человек.</p>
</div>`,
    },
  ],
];
