export const wordsLesson256 = [
  [
    {
      id: 1,
      word: 'drop off',
      transcription: 'drɒp ɒf',
      translation: 'задремать',
    },
    {
      id: 2,
      word: 'copy down',
      transcription: 'ˈkɒpi daʊn',
      translation: 'переписать',
    },
    {
      id: 3,
      word: 'cut up',
      transcription: 'kʌt ʌp',
      translation: 'разрезать',
    },
    {
      id: 4,
      word: 'clear up',
      transcription: 'klɪər ʌp',
      translation: 'убрать',
    },
    {
      id: 5,
      word: 'drop in',
      transcription: 'drɒp ɪn',
      translation: 'зайти, заглянуть',
    },
    {
      id: 6,
      word: 'call in',
      transcription: 'kɔːl ɪn',
      translation: 'зайти, заглянуть',
    },
    {
      id: 7,
      word: 'call on',
      transcription: 'kɔːl ɒn',
      translation: 'навещать',
    },
    {
      id: 8,
      word: 'grow out of',
      transcription: 'ɡrəʊ aʊt ɒv',
      translation: 'вырасти из',
    },
    {
      id: 9,
      word: 'hand out',
      transcription: 'hænd aʊt',
      translation: 'раздавать',
    },
    {
      id: 10,
      word: 'cut in',
      transcription: 'kʌt ɪn',
      translation: 'перебивать, прерывать',
    },
    {
      id: 11,
      word: 'bring off',
      transcription: 'brɪŋ ɒf',
      translation: 'успешно выполнять',
    },
    {
      id: 12,
      word: 'black out',
      transcription: 'blæk aʊt',
      translation: 'терять сознание',
    },
    {
      id: 13,
      word: 'pass out',
      transcription: 'pɑːs aʊt',
      translation: 'терять сознание',
    },
    {
      id: 14,
      word: 'anytime',
      transcription: 'ˈɛniˌtaɪm',
      translation: 'в любое время',
    },
    {
      id: 15,
      word: 'blood',
      transcription: 'blʌd',
      translation: 'кровь',
    },
    {
      id: 16,
      word: 'breakdown',
      transcription: 'ˈbreɪkˌdaʊn',
      translation: 'срыв',
    },
    {
      id: 17,
      word: 'brink',
      transcription: 'brɪŋk',
      translation: 'грань',
    },
    {
      id: 18,
      word: 'CEO',
      transcription: 'siː-iː-əʊ',
      translation: 'генеральный директор',
    },
    {
      id: 19,
      word: 'clutter',
      transcription: 'ˈklʌtə',
      translation: 'хлам',
    },
    {
      id: 20,
      word: 'helper',
      transcription: 'ˈhɛlpə',
      translation: 'помощник',
    },
    {
      id: 21,
      word: 'pot',
      transcription: 'pɒt',
      translation: 'кастрюля',
    },
    {
      id: 22,
      word: 'tragic',
      transcription: 'ˈtræʤɪk',
      translation: 'трагический',
    },
    {
      id: 23,
      word: 'whenever',
      transcription: 'wɛnˈɛvə',
      translation: 'всякий раз, когда',
    },
    {
      id: 24,
      word: 'wonderfully',
      transcription: 'ˈwʌndəfʊli',
      translation: 'замечательно',
    },
    {
      id: 25,
      word: 'worksheet',
      transcription: 'ˈwɜːkˌʃiːt',
      translation: 'рабочий материал',
    },
  ],
];
