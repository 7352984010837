export const wordsLesson264 = [
  [
    {
      id: 1,
      word: 'knock out',
      transcription: 'nɒk aʊt',
      translation: 'нокаутировать, вырубить',
    },
    {
      id: 2,
      word: 'break off',
      transcription: 'breɪk ɒf',
      translation: 'разорвать',
    },
    {
      id: 3,
      word: 'run over',
      transcription: 'rʌn ˈəʊvə',
      translation: 'сбить, пробежаться',
    },
    {
      id: 4,
      word: 'do without',
      transcription: 'duː wɪˈðaʊt',
      translation: 'обойтись',
    },
    {
      id: 5,
      word: 'sum up',
      transcription: 'sʌm ʌp',
      translation: 'подводить итог',
    },
    {
      id: 6,
      word: 'throw up',
      transcription: 'θrəʊ ʌp',
      translation: 'рвать',
    },
    {
      id: 7,
      word: 'move in',
      transcription: 'muːv ɪn',
      translation: 'въехать',
    },
    {
      id: 8,
      word: 'call round',
      transcription: 'kɔːl raʊnd',
      translation: 'заходить',
    },
    {
      id: 9,
      word: 'jot down',
      transcription: 'ʤɒt daʊn',
      translation: 'записать',
    },
    {
      id: 10,
      word: 'warm up',
      transcription: 'wɔːm ʌp',
      translation: 'разогреть, разминаться',
    },
    {
      id: 11,
      word: 'hang around',
      transcription: 'hæŋ əˈraʊnd',
      translation: 'слоняться',
    },
    {
      id: 12,
      word: 'act up',
      transcription: 'ækt ʌp',
      translation: 'капризничать',
    },
    {
      id: 13,
      word: 'go together',
      transcription: 'ɡəʊ təˈɡɛðə',
      translation: 'сочетаться',
    },
    {
      id: 14,
      word: 'do over',
      transcription: 'duː ˈəʊvə',
      translation: 'переделать, сделать заново',
    },
    {
      id: 15,
      word: 'see to',
      transcription: 'siː tuː',
      translation: 'позаботиться',
    },
    {
      id: 16,
      word: 'blouse',
      transcription: 'blaʊz',
      translation: 'блузка',
    },
    {
      id: 17,
      word: 'breath',
      transcription: 'brɛθ',
      translation: 'дыхание',
    },
    {
      id: 18,
      word: 'diplomatic',
      transcription: 'ˌdɪpləˈmætɪk',
      translation: 'дипломатический',
    },
    {
      id: 19,
      word: 'distant',
      transcription: 'ˈdɪstənt',
      translation: 'отдаленный',
    },
    {
      id: 20,
      word: 'engagement',
      transcription: 'ɪnˈɡeɪʤmənt',
      translation: 'помолвка',
    },
    {
      id: 21,
      word: 'formality',
      transcription: 'fɔːˈmælɪti',
      translation: 'формальный',
    },
    {
      id: 22,
      word: 'lorry',
      transcription: 'ˈlɒri',
      translation: 'грузовик',
    },
    {
      id: 23,
      word: 'poison',
      transcription: 'ˈpɔɪzn',
      translation: 'яд',
    },
    {
      id: 24,
      word: 'upstairs',
      transcription: 'ˌʌpˈsteəz',
      translation: 'вверх по лестнице',
    },
    {
      id: 25,
      word: 'youth',
      transcription: 'juːθ',
      translation: 'молодежь',
    },
    {
      id: 26,
      word: 'will',
      transcription: 'wɪl',
      translation: 'воля',
    },
  ],
];
