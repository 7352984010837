export const contentLesson93 = [
  [
    {
      title: "Урок 93. 270 Verbs in Different Tenses. Part 1",
    },
    [
      {
        id: 1,
        expression: "Он выпил бутылку молока",
        transcription: "hi dræŋk ə ˈbɒtl əv mɪlk",
        translation: "He drank a bottle of milk",
      },
      {
        id: 2,
        expression: "Мой друг преподает английский",
        transcription: "maɪ frɛnd ˈtiːʧəz ˈɪŋglɪʃ",
        translation: "My friend teachers English",
      },
      {
        id: 3,
        expression: "Учитель исправил эти ошибки",
        transcription: "ðə ˈtiːʧə kəˈrɛktɪd ðiːz mɪsˈteɪks",
        translation: "The teacher corrected these mistakes",
      },
      {
        id: 4,
        expression: "Он неожиданно повредил ногу",
        transcription: "hi hɜːt ɪz lɛg ˌʌnɪksˈpɛktɪdli",
        translation: "He hurt his leg unexpectedly",
      },
      {
        id: 5,
        expression: "Что тебе нравилось делать в свое свободное время?",
        transcription: "wɒt dɪd jʊ laɪk ˈdu(ː)ɪŋ ɪn jə friː taɪm?",
        translation: "What did you like doing in your free time?",
      },
      {
        id: 6,
        expression: "Он не хотел извиняться",
        transcription: "hi dɪdnt wɒnt tʊ əˈpɒləʤaɪz",
        translation: "He didn't want to apologise",
      },
      {
        id: 7,
        expression: "Я действительно обожаю путешествовать",
        transcription: "aɪ ˈrɪəli ɪnˈʤɔɪ ˈtrævlɪŋ",
        translation: "I really enjoy travelling",
      },
      {
        id: 8,
        expression: "Тебе понравилось представление?",
        transcription: "dɪd jʊ laɪk ðə pəˈfɔːməns?",
        translation: "Did you like the performance?",
      },
      {
        id: 9,
        expression: "Он вырос в Америке",
        transcription: "hi gruː ʌp ɪn əˈmɛrɪkə",
        translation: "He grew up in America",
      },
      {
        id: 10,
        expression: "Что ты любишь делать в свободное время?",
        transcription: "wɒt dʊ jʊ laɪk ˈdu(ː)ɪŋ ɪn jə friː taɪm?",
        translation: "What do you like doing in your free time?",
      },
      {
        id: 11,
        expression: "Я вспомнил свой логин",
        transcription: "aɪ rɪˈmɛmbəd maɪ ˈlɒgɪn",
        translation: "I remembered my login",
      },
      {
        id: 12,
        expression: "Мне так сильно понравилось выступление",
        transcription: "aɪ laɪkt ðə pəˈfɔːməns səʊ mʌʧ",
        translation: "I liked the performance so much",
      },
      {
        id: 13,
        expression: "Он открыл мне глаза на это",
        transcription: "hi ˈəʊpənd maɪ aɪz ɒn ɪt",
        translation: "He opened my eyes on it",
      },
      {
        id: 14,
        expression: "Ты поделишься этой ссылкой со своими друзьями?",
        transcription: "wɪl jʊ ʃeə ðɪs lɪŋk wɪð jə frɛndz?",
        translation: "Will you share this link with your friends?",
      },
      {
        id: 15,
        expression: "Ее родители позволили ей выйти",
        transcription: "hə ˈpeərənts lɛt hə gəʊ aʊt",
        translation: "Her parents let her go out",
      },
      {
        id: 16,
        expression: "Позволь мне сказать это",
        transcription: "lɛt mi seɪ ɪt!",
        translation: "Let me say it!",
      },
      {
        id: 17,
        expression: "Подожди секунду!",
        transcription: "weɪt ə ˈsɛkənd!",
        translation: "Wait a second!",
      },
      {
        id: 18,
        expression: "Она кормила детей полчаса назад",
        transcription: "ʃi fɛd hə ˈʧɪldrən hɑːf ən ˈaʊər əˈgəʊ",
        translation: "She fed her children half an hour ago",
      },
      {
        id: 19,
        expression: "Мне очень понравилась эта игра",
        transcription: "aɪ ˈrɪəli laɪk ðɪs geɪm",
        translation: "I really like this game",
      },
      {
        id: 20,
        expression: "Ты поделишься этим со своими друзьями?",
        transcription: "wɪl jʊ ʃeər ɪt wɪð jə frɛndz?",
        translation: "Will you share it with your friends?",
      },
      {
        id: 21,
        expression: "Позволь мне сказать тебе одну вещь",
        transcription: "lɛt mi tɛl jʊ wʌn θɪŋ!",
        translation: "Let me tell you one thing!",
      },
      {
        id: 22,
        expression: "Подожди минуту!",
        transcription: "weɪt ə ˈmɪnɪt!",
        translation: "Wait a minute!",
      },
      {
        id: 23,
        expression: "Где ты вырос?",
        transcription: "weə dɪd jʊ grəʊ ʌp?",
        translation: "Where did you grow up?",
      },
      {
        id: 24,
        expression: "Я вспомнил свой логин и пароль",
        transcription: "aɪ rɪˈmɛmbəd maɪ ˈlɒgɪn ənd ˈpɑːswɜːd",
        translation: "I remembered my login and password",
      },
      {
        id: 25,
        expression: "Она преподавала нам математику",
        transcription: "ʃi tɔːt əs mæθs",
        translation: "She taught us maths",
      },
      {
        id: 26,
        expression: "Это могло полностью изменить его жизнь",
        transcription: "ɪt kəd ʧeɪnʤ ɪz laɪf kəmˈpliːtli",
        translation: "It could change his life completely",
      },
      {
        id: 27,
        expression: "Я стоял в том месте",
        transcription: "aɪ stʊd ɪn ðət pleɪs",
        translation: "I stood in that place",
      },
      {
        id: 28,
        expression: "Они делят одну комнату",
        transcription: "ðeɪ ʃeə wʌn ruːm",
        translation: "They share one room",
      },
      {
        id: 29,
        expression: "Он выпил пакет молока",
        transcription: "hi dræŋk ə ˈkɑːtən əv mɪlk",
        translation: "He drank a carton of milk",
      },
      {
        id: 30,
        expression: "Я стоял недалеко от нее",
        transcription: "aɪ stʊd nɒt fɑː frəm hɜː",
        translation: "I stood not far from her",
      },
      {
        id: 31,
        expression: "Цена включает это",
        transcription: "ðə praɪs ɪnˈkluːdz ɪt",
        translation: "The price includes it",
      },
      {
        id: 32,
        expression: "Это действительно задело ее чувства",
        transcription: "ɪt ˈrɪəli hɜːt hə ˈfiːlɪŋz",
        translation: "It really hurt her feelings",
      },
      {
        id: 33,
        expression: "Как ты предпочитаешь путешествовать?",
        transcription: "haʊ dʊ jʊ priˈfɜː tə ˈtrævl?",
        translation: "How do you prefer to travel?",
      },
      {
        id: 34,
        expression: "Я хотел бы извиниться за это",
        transcription: "aɪ wɒnt tʊ əˈpɒləʤaɪz fər ɪt",
        translation: "I want to apologise for it",
      },
      {
        id: 35,
        expression: "Он извинился или нет?",
        transcription: "dɪd hi əˈpɒləʤaɪz ɔː nɒt?",
        translation: "Did he apologise or not?",
      },
      {
        id: 36,
        expression: "Я не хотел ранить ее чувства",
        transcription: "aɪ dɪdnt wɒnt tə hɜːt hə ˈfiːlɪŋz",
        translation: "I didn't want to hurt her feelings",
      },
      {
        id: 37,
        expression: "Он будет ждать нас?",
        transcription: "wɪl hi weɪt fər ʌs?",
        translation: "Will he wait for us?",
      },
      {
        id: 38,
        expression: "Я хотел бы выпить немного чая",
        transcription: "aɪd laɪk tə drɪŋk səm tiː",
        translation: "I'd like to drink some tea",
      },
      {
        id: 39,
        expression: "Я люблю путешествовать",
        transcription: "aɪ laɪk ˈtrævlɪŋ",
        translation: "I like travelling",
      },
      {
        id: 40,
        expression: "Ты исправишь эти ошибки?",
        transcription: "wɪl jʊ kəˈrɛkt ðiːz mɪsˈteɪks?",
        translation: "Will you correct these mistakes?",
      },
      {
        id: 41,
        expression: "Я использую это устройство для других целей",
        transcription:
          "aɪ juːz ðɪs dɪˈvaɪs fər ˈʌðə ˈpɜːpəsɪz (eɪm, gəʊl, ˈtɑːgɪt)",
        translation: "I use this device for other purposes (aim, goal, target)",
      },
      {
        id: 42,
        expression: "Он проводит много времени в социальных сетях",
        transcription: "hi spɛndz lɒts əv taɪm ɪn ˈsəʊʃəl ˈnɛtwɜːks",
        translation: "He spends lots of time in social networks",
      },
      {
        id: 43,
        expression: "Я хотел бы быть более независимым",
        transcription: "aɪd laɪk tə bi mɔːr ˌɪndɪˈpɛndənt",
        translation: "I'd like to be more independent",
      },
      {
        id: 44,
        expression: "Они поженились в конце года",
        transcription: "ðeɪ gɒt ˈmærɪd ət ði ɛnd əv ðə jɪə",
        translation: "They got married at the end of the year",
      },
      {
        id: 45,
        expression: "Я скачал последнюю версию этого приложения",
        transcription:
          "aɪ ˌdaʊnˈləʊdɪd ðə ˈleɪtɪst ˈvɜːʃən əv ðɪs ˌæplɪˈkeɪʃ(ə)n",
        translation: "I downloaded the latest version of this application",
      },
      {
        id: 46,
        expression: "Я вырос в большом городе",
        transcription: "aɪ gruː ʌp ɪn ə bɪg ˈsɪti",
        translation: "I grew up in a big city",
      },
      {
        id: 47,
        expression: "Я хотел иметь результат гораздо лучше",
        transcription: "aɪ ˈwɒntɪd tə həv ə mʌʧ ˈbɛtə rɪˈzʌlt",
        translation: "I wanted to have a much better result",
      },
      {
        id: 48,
        expression: "Я знаю, что мне следует исправить эти ошибки",
        transcription: "aɪ nəʊ ðət aɪ ʃəd tə kəˈrɛkt ðiːz mɪsˈteɪks",
        translation: "I know that I should to correct these mistakes",
      },
      {
        id: 49,
        expression: "Какие вещи тебя интересовали больше?",
        transcription: "wɒt θɪŋz ˈɪntrɪstɪd jʊ mɔː?",
        translation: "What things interested you more?",
      },
      {
        id: 50,
        expression: "Я скачал последнюю версию",
        transcription: "aɪ ˌdaʊnˈləʊdɪd ðə ˈleɪtɪst ˈvɜːʃən",
        translation: "I downloaded the latest version",
      },
      {
        id: 51,
        expression: "Я использую это для других целей",
        transcription: "aɪ juːz ɪt fər ˈʌðə ˈpɜːpəsɪz",
        translation: "I use it for other purposes",
      },
      {
        id: 52,
        expression: "Какую одежду ты предпочитаешь носить?",
        transcription: "wɒt kləʊðz dʊ jʊ priˈfɜː tə weə?",
        translation: "What clothes do you prefer to wear?",
      },
      {
        id: 53,
        expression: "Он вырос в той стране",
        transcription: "hi gruː ʌp ɪn ðət ˈkʌntri",
        translation: "He grew up in that country",
      },
      {
        id: 54,
        expression: "Они включили мое имя в список",
        transcription: "ðeɪ ɪnˈkluːdɪd maɪ neɪm ɪn ðə lɪst",
        translation: "They included my name in the list",
      },
      {
        id: 55,
        expression: "Не забудь покормить своего кота",
        transcription: "dəʊnt fəˈgɛt tə fiːd jə kæt!",
        translation: "Don't forget to feed your cat!",
      },
      {
        id: 56,
        expression: "Мы скачали последнюю версию этой программы",
        transcription: "wi ˌdaʊnˈləʊdɪd ðə ˈleɪtɪst ˈvɜːʃən əv ðɪs ˈprəʊgræm",
        translation: "We downloaded the latest version of this program",
      },
      {
        id: 57,
        expression: "Они купили последнюю версию этой программы",
        transcription: "ðeɪ bɔːt ðə ˈleɪtɪst ˈvɜːʃən əv ðɪs ˈprəʊgræm",
        translation: "They bought the latest version of this program",
      },
      {
        id: 58,
        expression: "Тебе нужно скачать последнюю версию этого приложения",
        transcription:
          "aɪ niːd tə ˌdaʊnˈləʊd ðə ˈleɪtɪst ˈvɜːʃən əv ðɪs ˌæplɪˈkeɪʃ(ə)n",
        translation:
          "I need to download the latest version of this application",
      },
      {
        id: 59,
        expression: "Я думаю, что он откажется делать это",
        transcription: "aɪ θɪŋk ðət hiːl ˌriːˈfjuːz tə dʊ ɪt",
        translation: "I think that he'll refuse to do it",
      },
      {
        id: 60,
        expression: "Она думает, что они откажутся делать это",
        transcription: "ʃi θɪŋks ðət ðeɪl ˌriːˈfjuːz tə dʊ ɪt",
        translation: "She thinks that they'll refuse to do it",
      },
      {
        id: 61,
        expression: "Я знаю, что она сделает это с большим удовольствием",
        transcription: "aɪ nəʊ ðət ʃiːl dʊ ɪt wɪð greɪt ˈplɛʒə",
        translation: "I know that she'll do it with great pleasure",
      },
      {
        id: 62,
        expression: "Мой знакомый вырос в деревне",
        transcription: "maɪ əˈkweɪntəns gruː ʌp ɪn ðə ˈkʌntri",
        translation: "My acquaintance grew up in the country",
      },
      {
        id: 63,
        expression: "Мой лучший друг вырос в маленьком городке",
        transcription: "maɪ bɛst frɛnd gruː ʌp ɪn ə smɔːl taʊn",
        translation: "My best friend grew up in a small town",
      },
      {
        id: 64,
        expression: "Он, возможно, займет первое место",
        transcription: "hi meɪ teɪk ðə fɜːst pleɪs",
        translation: "He may take the first place",
      },
      {
        id: 65,
        expression: "К сожалению, я занял последнее место",
        transcription: "ʌnˈfɔːʧnɪtli, aɪ tʊk ðə lɑːst pleɪs",
        translation: "Unfortunately, I took the last place",
      },
      {
        id: 66,
        expression: "Я думаю, что она займет первое место",
        transcription: "aɪ θɪŋk ʃiːl teɪk ðə fɜːst pleɪs",
        translation: "I think she'll take the first place",
      },
      {
        id: 67,
        expression: "Я часто пользуюсь социальными сетями",
        transcription: "aɪ ˈɒf(ə)n juːz ˈsəʊʃəl ˈnɛtwɜːks",
        translation: "I often use social networks",
      },
      {
        id: 68,
        expression: "Я использую социальные сети довольно часто",
        transcription: "aɪ juːz ˈsəʊʃəl ˈnɛtwɜːks kwaɪt ˈɒf(ə)n",
        translation: "I use social networks quite often",
      },
      {
        id: 69,
        expression: "Они часто используют социальные сети",
        transcription: "ðeɪ ˈɒf(ə)n juːz ˈsəʊʃəl ˈnɛtwɜːks",
        translation: "They often use social networks",
      },
      {
        id: 70,
        expression: "Эти вещи, возможно, скоро произойдут",
        transcription: "ðiːz θɪŋz meɪ ˈhæpən suːn",
        translation: "These things may happen soon",
      },
      {
        id: 71,
        expression: "Эта вещь случится рано или поздно",
        transcription: "ðɪs θɪŋ wɪl ˈhæpən ˈsuːnər ɔː ˈleɪtə",
        translation: "This thing will happen sooner or later",
      },
      {
        id: 72,
        expression: "Эта вещь, возможно, скоро случится",
        transcription: "ðɪs θɪŋ meɪ ˈhæpən suːn",
        translation: "This thing may happen soon",
      },
      {
        id: 73,
        expression: "Мы повернули назад, потому что было очень поздно",
        transcription: "wi tɜːnd bæk bɪˈkəz ɪt wəz ˈvɛri leɪt",
        translation: "We turned back because it was very late",
      },
      {
        id: 74,
        expression: "Мы повернули назад на перекрестке",
        transcription: "wi tɜːnd bæk ət ðə ˈkrɒsˌrəʊdz",
        translation: "We turned back at the crossroads",
      },
      {
        id: 75,
        expression: "Мне нужно повернуть назад, потому что очень поздно",
        transcription: "aɪ niːd tə tɜːn bæk bɪˈkəz ɪts ˈvɛri leɪt",
        translation: "I need to turn back because it's very late",
      },
      {
        id: 76,
        expression: "Я вырос в маленьком городке",
        transcription: "aɪ gruː ʌp ɪn ə smɔːl taʊn",
        translation: "I grew up in a small town",
      },
      {
        id: 77,
        expression: "Я родился в маленьком городке",
        transcription: "aɪ wəz bɔːn ɪn ə smɔːl taʊn",
        translation: "I was born in a small town",
      },
      {
        id: 78,
        expression: "Он вырос в этом городке",
        transcription: "hi gruː ʌp ɪn ðɪs taʊn",
        translation: "He grew up in this town",
      },
      {
        id: 79,
        expression: "Я не хотел больше ждать этого",
        transcription: "aɪ dɪdnt wɒnt tə weɪt fər ɪt ˌɛniˈmɔː",
        translation: "I didn't want to wait for it anymore",
      },
      {
        id: 80,
        expression: "Я не хочу тебя больше ждать",
        transcription: "aɪ dəʊnt wɒnt tə weɪt fə jʊ ˌɛniˈmɔː",
        translation: "I don't want to wait for you anymore",
      },
      {
        id: 81,
        expression: "Тебе не нужно ждать этого",
        transcription: "juː dəʊnt niːd tə weɪt fər ɪt",
        translation: "You don't need to wait for it",
      },
      {
        id: 82,
        expression: "Какая вещь тебя больше интересует?",
        transcription: "wɒt θɪŋ ˈɪntrɪsts jʊ mɔː?",
        translation: "What thing interests you more?",
      },
      {
        id: 83,
        expression: "Какая вещь его больше интересует?",
        transcription: "wɒt θɪŋ ˈɪntrɪsts ɪm mɔː?",
        translation: "What thing interests him more?",
      },
      {
        id: 84,
        expression: "Какие вещи ее больше интересуют?",
        transcription: "wɒt θɪŋz ˈɪntrɪst hə mɔː?",
        translation: "What things interest her more?",
      },
      {
        id: 85,
        expression: "Ему нужно сделать это к завтрашнему дню",
        transcription: "hi niːdz tə dʊ ɪt baɪ təˈmɒrəʊ",
        translation: "He needs to do it by tomorrow",
      },
      {
        id: 86,
        expression: "Они поженились в начале года",
        transcription: "ðeɪ gɒt ˈmærɪd ət ðə bɪˈgɪnɪŋ əv ðə jɪə",
        translation: "They got married at the beginning of the year",
      },
      {
        id: 87,
        expression: "Они, возможно, как-то разрешат этот конфликт",
        transcription: "ðeɪ meɪ rɪˈzɒlv ðɪs ˈkɒnflɪkt ˈsʌmhaʊ",
        translation: "They may resolve this conflict somehow",
      },
      {
        id: 88,
        expression: "Это полностью разрушило мои планы",
        transcription: "ɪt dɪsˈtrɔɪd maɪ plænz kəmˈpliːtli",
        translation: "It destroyed my plans completely",
      },
      {
        id: 89,
        expression: "Они забыли включить его имя в список",
        transcription: "ðeɪ fəˈgɒt tʊ ɪnˈkluːd ɪz neɪm ɪn ðə lɪst",
        translation: "They forgot to include his name in the list",
      },
      {
        id: 90,
        expression: "Какого рода вещи ты предпочитаешь носить?",
        transcription: "wɒt kaɪnd əv kləʊðz dʊ jʊ priˈfɜː tə weə?",
        translation: "What kind of clothes do you prefer to wear?",
      },
      {
        id: 91,
        expression: "Я хотел улучшить свой уровень",
        transcription: "aɪ ˈwɒntɪd tʊ ɪmˈpruːv maɪ ˈlɛvl",
        translation: "I wanted to improve my level",
      },
      {
        id: 92,
        expression: "Он обычно носил костюм",
        transcription: "hi ˈjuːʒʊəli wɔːr ə sjuːt",
        translation: "He usually wore a suit",
      },
      {
        id: 93,
        expression: "Я повернул назад, потому что стало очень темно",
        transcription: "aɪ tɜːnd bæk bɪˈkəz ɪt bɪˈkeɪm ˈvɛri dɑːk",
        translation: "I turned back because it became very dark",
      },
      {
        id: 94,
        expression: "Ты часто пользуешься социальными сетями?",
        transcription: "dʊ jʊ ˈɒf(ə)n juːz ˈsəʊʃəl ˈnɛtwɜːks?",
        translation: "Do you often use social networks?",
      },
      {
        id: 95,
        expression: "Он как-то может решить эту проблему",
        transcription: "hi meɪ sɒlv ðɪs ˈprɒbləm ˈsʌmhaʊ",
        translation: "He may solve this problem somehow",
      },
      {
        id: 96,
        expression: "Они поженились очень неожиданно",
        transcription: "ðeɪ (gɒt) ˈmærɪd ˈvɛri ˌʌnɪksˈpɛktɪdli",
        translation: "They (got) married very unexpectedly",
      },
      {
        id: 97,
        expression: "Я решил встать",
        transcription: "aɪ dɪˈsaɪdɪd tə stænd ʌp",
        translation: "I decided to stand up",
      },
      {
        id: 98,
        expression: "Это, возможно, скоро произойдет",
        transcription: "ɪt meɪ ˈhæpən suːn",
        translation: "It may happen soon",
      },
      {
        id: 99,
        expression: "Мне действительно нравится гулять",
        transcription: "aɪ ˈrɪəli laɪk ˈwɔːkɪŋ",
        translation: "I really like walking",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Закрепление пройденного материала</h3>
</div>`,
    },
  ],
];
