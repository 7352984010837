export const contentLesson88 = [
  [
    {
      title: "Урок 88. Past Simple. 150 Regular Verbs. Part 4",
    },
    [
      {
        id: 1,
        expression: "Эта вещь совсем меня не пугала",
        transcription: "ðɪs θɪŋ dɪdnt ˈfraɪtn mi ət ɔːl",
        translation: "This thing didn't frighten me at all",
      },
      {
        id: 2,
        expression: "Он перепрыгнул через камень",
        transcription: "hi ʤʌmpt ˈəʊvə ðə stəʊn",
        translation: "He jumped over the stone",
      },
      {
        id: 3,
        expression: "Ты последовал этому совету?",
        transcription: "dɪd jʊ ˈfɒləʊ ðɪs ədˈvaɪs?",
        translation: "Did you follow this advice?",
      },
      {
        id: 4,
        expression: "Они владели этим домом",
        transcription: "ðeɪ əʊnd ðɪs haʊs",
        translation: "They owned this house",
      },
      {
        id: 5,
        expression: "Я следовал тем инструкциям",
        transcription: "aɪ ˈfɒləʊd ðəʊz ɪnˈstrʌkʃənz",
        translation: "I followed those instructions",
      },
      {
        id: 6,
        expression: "Покупатель пожаловался на этот сервис",
        transcription: "ðə ˈkʌstəmə kəmˈpleɪnd əˈbaʊt ðɪs ˈsɜːvɪs",
        translation: "The customer complained about this service",
      },
      {
        id: 7,
        expression: "Я повторил вопрос еще раз",
        transcription: "aɪ rɪˈpiːtɪd ðə ˈkwɛsʧən wʌn mɔː taɪm",
        translation: "I repeated the question one more time",
      },
      {
        id: 8,
        expression: "Она прибыла в Лондон в полдень",
        transcription: "ʃi əˈraɪvd ɪn ˈlʌndən ət ˈmɪddeɪ",
        translation: "She arrived in London at midday",
      },
      {
        id: 9,
        expression: "Они записали его речь",
        transcription: "ðeɪ rɪˈkɔːdɪd ɪz spiːʧ",
        translation: "They recorded his speech",
      },
      {
        id: 10,
        expression: "Она слишком сильно волновалась об этом",
        transcription: "ʃi ˈwʌrid tuː mʌʧ əˈbaʊt ɪt",
        translation: "She worried too much about it",
      },
      {
        id: 11,
        expression: "Мне нужно было идти в банк",
        transcription: "aɪ ˈniːdɪd tə gəʊ tə ðə bæŋk",
        translation: "I needed to go to the bank",
      },
      {
        id: 12,
        expression: "Она перепрыгнула через забор",
        transcription: "ʃi ʤʌmpt ˈəʊvə ðə fɛns",
        translation: "She jumped over the fence",
      },
      {
        id: 13,
        expression: "Он прибыл позже, чем обычно",
        transcription: "hi əˈraɪvd ˈleɪtə ðən ˈjuːʒʊəl",
        translation: "He arrived later than usual",
      },
      {
        id: 14,
        expression: "Мне действительно нужна была твоя помощь",
        transcription: "aɪ ˈrɪəli ˈniːdɪd jə hɛlp",
        translation: "I really needed your help",
      },
      {
        id: 15,
        expression: "Это действительно пугало ее",
        transcription: "ɪt ˈrɪəli ˈfraɪtnd hɜː",
        translation: "It really frightened her",
      },
      {
        id: 16,
        expression: "Мы надеялись выиграть",
        transcription: "wi həʊpt tə wɪn",
        translation: "We hoped to win",
      },
      {
        id: 17,
        expression: "Я много путешествовал",
        transcription: "aɪ ˈtrævld ə lɒt",
        translation: "I travelled a lot",
      },
      {
        id: 18,
        expression: "Я нес ее сумку",
        transcription: "aɪ ˈkærid hə bæg",
        translation: "I carried her bag",
      },
      {
        id: 19,
        expression: "Они открыли ворота",
        transcription: "ðeɪ ˈəʊpənd ðə geɪt",
        translation: "They opened the gate",
      },
      {
        id: 20,
        expression: "Я часто носил тяжелые вещи",
        transcription: "aɪ ˈɒf(ə)n ˈkærid ˈhɛvi θɪŋz",
        translation: "I often carried heavy things",
      },
      {
        id: 21,
        expression: "Она прыгнула очень высоко",
        transcription: "ʃi ʤʌmpt ˈvɛri haɪ",
        translation: "She jumped very high",
      },
      {
        id: 22,
        expression: "Этот дом принадлежал им?",
        transcription: "dɪd ðɪs haʊs bɪˈlɒŋ tə ðɛm?",
        translation: "Did this house belong to them?",
      },
      {
        id: 23,
        expression: "Проблема не исчезла",
        transcription: "ðə ˈprɒbləm dɪdnt ˌdɪsəˈpɪə",
        translation: "The problem didn't disappear",
      },
      {
        id: 24,
        expression: "Он владел этой квартирой",
        transcription: "hi əʊnd ðɪs əˈpɑːtmənt",
        translation: "He owned this apartment",
      },
      {
        id: 25,
        expression: "Мы играли в другую игру",
        transcription: "wiː pleɪd əˈnʌðə geɪm",
        translation: "We played another game",
      },
      {
        id: 26,
        expression: "Он прибыл в Нью-Йорк в полдень",
        transcription: "hi əˈraɪvd ɪn njuː-jɔːk ət nuːn",
        translation: "He arrived in New-York at noon",
      },
      {
        id: 27,
        expression: "Он часто тренировался в спортивном зале",
        transcription: "hi ˈɒf(ə)n treɪnd ɪn ðə ʤɪm",
        translation: "He often trained in the gym",
      },
      {
        id: 28,
        expression: "Он действительно беспокоился о своем будущем",
        transcription: "hi ˈrɪəli ˈwʌrid əˈbaʊt ɪz ˈfjuːʧə",
        translation: "He really worried about his future",
      },
      {
        id: 29,
        expression: "Мы путешествовали там каждое лето",
        transcription: "wi ˈtrævld ðeər ˈɛvri ˈsʌmə",
        translation: "We travelled there every summer",
      },
      {
        id: 30,
        expression: "Он перепрыгнул через реку",
        transcription: "hi ʤʌmpt ˈəʊvə ðə ˈrɪvə",
        translation: "He jumped over the river",
      },
      {
        id: 31,
        expression: "Нам действительно нравится путешествовать вместе",
        transcription: "wi ˈrɪəli laɪk ˈtrævlɪŋ təˈgɛðə",
        translation: "We really like travelling together",
      },
      {
        id: 32,
        expression: "Мне просто нужна была некоторая информация",
        transcription: "aɪ ʤəst ˈniːdɪd səm ˌɪnfəˈmeɪʃən",
        translation: "I just needed some information",
      },
      {
        id: 33,
        expression: "Преступники внезапно исчезли",
        transcription: "ðə ˈkrɪmɪnlz ˌdɪsəˈpɪəd ˈsʌdnli",
        translation: "The criminals disappeared suddenly",
      },
      {
        id: 34,
        expression: "Я звонил своему брату вчера",
        transcription: "aɪ kɔːld maɪ ˈbrʌðə ˈjɛstədeɪ",
        translation: "I called my brother yesterday",
      },
      {
        id: 35,
        expression: "Я повторил фразу несколько раз",
        transcription: "aɪ rɪˈpiːtɪd ðə freɪz ˈsɛvrəl taɪmz",
        translation: "I repeated the phrase several times",
      },
      {
        id: 36,
        expression: "Двери открылись автоматически",
        transcription: "ðə dɔːr ˈəʊpənd ˌɔːtəˈmætɪkəli",
        translation: "The door opened automatically",
      },
      {
        id: 37,
        expression: "Они записали тот разговор",
        transcription: "ðeɪ rɪˈkɔːdɪd ðət ˌkɒnvəˈseɪʃən",
        translation: "They recorded that conversation",
      },
      {
        id: 38,
        expression: "Я достиг следующего уровня очень легко",
        transcription: "aɪ riːʧt ðə nɛkst ˈlɛvl ˈvɛri ˈiːzɪli",
        translation: "I reached the next level very easily",
      },
      {
        id: 39,
        expression: "Он играл в шахматы со своим братом",
        transcription: "hi pleɪd ʧɛs wɪð ɪz ˈbrʌðə",
        translation: "He played chess with his brother",
      },
      {
        id: 40,
        expression: "Я перезвонил ему позже",
        transcription: "aɪ kɔːld ɪm bæk ˈleɪtə",
        translation: "I called him back later",
      },
      {
        id: 41,
        expression:
          "Она никогда не жаловалась, хотя это было очень трудно для нее",
        transcription:
          "ʃi ˈnɛvə kəmˈpleɪnd ɔːlˈðəʊ ɪt wəz ˈvɛri ˈdɪfɪkəlt fə hə",
        translation:
          "She never complained although it was very difficult for her",
      },
      {
        id: 42,
        expression: "Я предполагал, что это был единственно правильный выбор",
        transcription: "aɪ səˈpəʊzd ðət ɪt wəz ði ˈəʊnli raɪt ʧɔɪs",
        translation: "I supposed that it was the only right choice",
      },
      {
        id: 43,
        expression: "Я пытался сделать все возможное, чтобы достичь этой цели",
        transcription: "aɪ traɪd tə dʊ maɪ bɛst tʊ əˈʧiːv ðɪs eɪm",
        translation: "I tried to do my best to achieve this aim",
      },
      {
        id: 44,
        expression:
          "Он не заработал достаточно денег, чтобы купить новую квартиру в той области",
        transcription:
          "hi dɪdnt ɜːn ɪˈnʌf ˈmʌni tə baɪ ə njuː əˈpɑːtmənt ɪn ðət ˈeərɪə",
        translation:
          "He didn't earn enough money to buy a new apartment in that area",
      },
      {
        id: 45,
        expression: "Он зарабатывал слишком мало денег, когда работал там",
        transcription: "hi ɜːnd tuː ˈlɪtl ˈmʌni wɛn hi wɜːkt ðeə",
        translation: "He earned too little money when he worked there",
      },
      {
        id: 46,
        expression: "Я очень тщательно все проанализировал",
        transcription: "aɪ ˈænəlaɪzd ˈɛvrɪθɪŋ ˈvɛri ˈθʌrəli",
        translation: "I analysed everything very thoroughly",
      },
      {
        id: 47,
        expression: "Воры пытались исчезнуть, но они не могли это сделать",
        transcription: "ðə θiːvz traɪd tə ˌdɪsəˈpɪə bət ðeɪ ˈkʊdnt dʊ ɪt",
        translation: "The thieves tried to disappear but they couldn't do it",
      },
      {
        id: 48,
        expression: "Его соседи часто жаловались на шум",
        transcription: "hɪz ˈneɪbəz ˈɒf(ə)n kəmˈpleɪnd əˈbaʊt ðə nɔɪz",
        translation: "His neighbours often complained about the noise",
      },
      {
        id: 49,
        expression: "Я решил последовать его рекомендации",
        transcription: "aɪ dɪˈsaɪdɪd tə ˈfɒləʊ ɪz ˌrɛkəmɛnˈdeɪʃən",
        translation: "I decided to follow his recommendation",
      },
      {
        id: 50,
        expression: "Я никогда не жаловался на это",
        transcription: "aɪ ˈnɛvə kəmˈpleɪnd əˈbaʊt ɪt",
        translation: "I never complained about it",
      },
      {
        id: 51,
        expression: "Я не мог поверить своим глазам",
        transcription: "aɪ ˈkʊdnt bɪˈliːv maɪ aɪz",
        translation: "I couldn't believe my eyes",
      },
      {
        id: 52,
        expression: "Я решил перестать тратить время впустую",
        transcription: "aɪ dɪˈsaɪdɪd tə stɒp ˈweɪstɪŋ taɪm",
        translation: "I decided to stop wasting time",
      },
      {
        id: 53,
        expression: "Я старался не беспокоиться об этом",
        transcription: "aɪ traɪd nɒt tə ˈwʌri əˈbaʊt ɪt",
        translation: "I tried not to worry about it",
      },
      {
        id: 54,
        expression: "Я пытался контролировать свои эмоции",
        transcription: "aɪ traɪd tə kənˈtrəʊl maɪ ɪˈməʊʃənz",
        translation: "I tried to control my emotions",
      },
      {
        id: 55,
        expression: "Я сделал все возможное, чтобы достичь своей цели",
        transcription: "aɪ dɪd maɪ bɛst tʊ əˈʧiːv maɪ eɪm",
        translation: "I did my best to achieve my aim",
      },
      {
        id: 56,
        expression: "Мы прибыли на станцию",
        transcription: "wi əˈraɪvd ət ðə ˈsteɪʃən",
        translation: "We arrived at the station",
      },
      {
        id: 57,
        expression: "Мы прибыли в город",
        transcription: "wi əˈraɪvd ɪn ðə ˈsɪti",
        translation: "We arrived in the city",
      },
      {
        id: 58,
        expression: "Они прибыли в это место",
        transcription: "ðeɪ əˈraɪvd ɪn ðɪs pleɪs",
        translation: "They arrived in this place",
      },
      {
        id: 59,
        expression: "Я очень усердно старался достичь этой цели",
        transcription: "aɪ traɪd ˈvɛri hɑːd tʊ əˈʧiːv ðɪs eɪm",
        translation: "I tried very hard to achieve this aim",
      },
      {
        id: 60,
        expression: "Я пытался достичь этой цели",
        transcription: "aɪ traɪd tʊ əˈʧiːv ðɪs eɪm",
        translation: "I tried to achieve this aim",
      },
      {
        id: 61,
        expression: "Он так усердно старался достичь своей цели",
        transcription: "hi traɪd səʊ hɑːd tʊ əˈʧiːv ɪz eɪm",
        translation: "He tried so hard to achieve his aim",
      },
      {
        id: 62,
        expression: "Я решил сделать это чуть позже",
        transcription: "aɪ dɪˈsaɪdɪd tə dʊ ɪt ə ˈlɪtl (ə bɪt) ˈleɪtə",
        translation: "I decided to do it a little (a bit) later",
      },
      {
        id: 63,
        expression: "Он решил не делать этого",
        transcription: "hi dɪˈsaɪdɪd nɒt tə dʊ ɪt",
        translation: "He decided not to do it",
      },
      {
        id: 64,
        expression: "Я не хотел больше впустую тратить время",
        transcription: "aɪ dɪdnt wɒnt tə weɪst taɪm ˌɛniˈmɔː",
        translation: "I didn't want to waste time anymore",
      },
      {
        id: 65,
        expression: "Я решил больше не тратить время впустую",
        transcription: "aɪ dɪˈsaɪdɪd nɒt tə weɪst taɪm ˌɛniˈmɔː",
        translation: "I decided not to waste time anymore",
      },
      {
        id: 66,
        expression: "Я не хотел больше тратить впустую свои деньги",
        transcription: "aɪ dɪdnt wɒnt tə weɪst maɪ ˈmʌni ˌɛniˈmɔː",
        translation: "I didn't want to waste my money anymore",
      },
      {
        id: 67,
        expression: "Я предполагал, что это была ложь",
        transcription: "aɪ səˈpəʊzd ðət ɪt wəz ə laɪ",
        translation: "I supposed that it was a lie",
      },
      {
        id: 68,
        expression: "Я надеялся, что это была ложь",
        transcription: "aɪ həʊpt ðət ɪt wəz ə laɪ",
        translation: "I hoped that it was a lie",
      },
      {
        id: 69,
        expression: "Я открыл крышку бутылки",
        transcription: "aɪ ˈəʊpənd ðə lɪd əv ðə ˈbɒtl",
        translation: "I opened the lid of the bottle",
      },
      {
        id: 70,
        expression: "Я открыл крышку этой бутылки",
        transcription: "aɪ ˈəʊpənd ðə lɪd əv ðɪs ˈbɒtl",
        translation: "I opened the lid of this bottle",
      },
      {
        id: 71,
        expression: "Он открыл крышку бутылки",
        transcription: "hi ˈəʊpənd ðə lɪd əv ðə ˈbɒtl",
        translation: "He opened the lid of the bottle",
      },
      {
        id: 72,
        expression: "Я пытался все контролировать",
        transcription: "aɪ traɪd tə kənˈtrəʊl ˈɛvrɪθɪŋ",
        translation: "I tried to control everything",
      },
      {
        id: 73,
        expression: "Я пытался все сделать",
        transcription: "aɪ traɪd tə dʊ ˈɛvrɪθɪŋ",
        translation: "I tried to do everything",
      },
      {
        id: 74,
        expression: "Он пытался контролировать каждого",
        transcription: "hi traɪd tə kənˈtrəʊl ˈɛvrɪwʌn",
        translation: "He tried to control everyone",
      },
      {
        id: 75,
        expression: "Я старался тренироваться как можно интенсивнее",
        transcription: "aɪ traɪd tə treɪn əz ɪnˈtɛnsɪvli əz ˈpɒsəbl",
        translation: "I tried to train as intensively as possible",
      },
      {
        id: 76,
        expression: "Я хотел учиться как можно интенсивнее",
        transcription: "aɪ ˈwɒntɪd tə ˈstʌdi əz ɪnˈtɛnsɪvli əz ˈpɒsəbl",
        translation: "I wanted to study as intensively as possible",
      },
      {
        id: 77,
        expression: "Я старался тренироваться интенсивно",
        transcription: "aɪ traɪd tə treɪn ɪnˈtɛnsɪvli",
        translation: "I tried to train intensively",
      },
      {
        id: 78,
        expression: "Он прибыл на вечеринку чуть позже",
        transcription: "hi əˈraɪvd ət ðə ˈpɑːti ə bɪt (ə ˈlɪtl) ˈleɪtə",
        translation: "He arrived at the party a bit (a little) later",
      },
      {
        id: 79,
        expression: "Он прибыл на вечеринку позже",
        transcription: "hi əˈraɪvd ət ðə ˈpɑːti ˈleɪtə",
        translation: "He arrived at the party later",
      },
      {
        id: 80,
        expression: "Я согласился с ним в конце концов",
        transcription: "aɪ əˈgriːd wɪð ɪm ɪn ði ɛnd",
        translation: "I agreed with him in the end",
      },
      {
        id: 81,
        expression: "Мы обсудили это в конце концов",
        transcription: "wi dɪsˈkʌst ɪt ɪn ði ɛnd",
        translation: "We discussed it in the end",
      },
      {
        id: 82,
        expression: "Я согласился с ним в конце разговора",
        transcription: "aɪ əˈgriːd wɪð ɪm ət ði ɛnd əv ðə ˌkɒnvəˈseɪʃən",
        translation: "I agreed with him at the end of the conversation",
      },
      {
        id: 83,
        expression: "Я не хотел копировать ошибки других студентов",
        transcription: "aɪ dɪdnt wɒnt tuː ˈkɒpi ˈʌðə ˈstjuːdənts mɪsˈteɪks",
        translation: "I didn't want to copy other students' mistakes",
      },
      {
        id: 84,
        expression: "Почему ты решил не делать этого?",
        transcription: "waɪ dɪd jʊ dɪˈsaɪd nɒt tə dʊ ɪt?",
        translation: "Why did you decide not to do it?",
      },
      {
        id: 85,
        expression: "Я надеялся на лучшее, но ожидал худшего",
        transcription: "aɪ həʊpt fə ðə bɛst bət ɪksˈpɛktɪd ðə wɜːst",
        translation: "I hoped for the best but expected the worst",
      },
      {
        id: 86,
        expression: "Я полагал, что это было единственно правильное решение",
        transcription: "aɪ səˈpəʊzd ðət ɪt wəz ði ˈəʊnli raɪt dɪˈsɪʒən",
        translation: "I supposed that it was the only right decision",
      },
      {
        id: 87,
        expression: "Ничто не интересовало ее так сильно",
        transcription: "ˈnʌθɪŋ ˈɪntrɪstɪd hə səʊ mʌʧ",
        translation: "Nothing interested her so much",
      },
      {
        id: 88,
        expression: "Я решил достичь высшего уровня в английском",
        transcription: "aɪ dɪˈsaɪdɪd tə riːʧ ðə ˈhaɪɪst ˈlɛvl ɪn ˈɪŋglɪʃ",
        translation: "I decided to reach the highest level in English",
      },
      {
        id: 89,
        expression: "Я анализировал результаты, которые у меня были",
        transcription: "aɪ ˈænəlaɪzd ðə rɪˈzʌlts wɪʧ aɪ hæd",
        translation: "I analysed the results which I had",
      },
      {
        id: 90,
        expression: "Я пожаловался менеджеру на это",
        transcription: "aɪ kəmˈpleɪnd tə ðə ˈmænɪʤər əˈbaʊt ɪt",
        translation: "I complained to the manager about it",
      },
      {
        id: 91,
        expression: "Они скопировали дизайн этого продукта",
        transcription: "ðeɪ ˈkɒpid ðə dɪˈzaɪn əv ðɪs ˈprɒdʌkt",
        translation: "They copied the design of this product",
      },
      {
        id: 92,
        expression: "Они не согласились в начале",
        transcription: "ðeɪ dɪdnt əˈgriː ɪn ðə bɪˈgɪnɪŋ",
        translation: "They didn't agree in the beginning",
      },
      {
        id: 93,
        expression: "Он не мог достичь этой цели",
        transcription: "hi ˈkʊdnt əˈʧiːv ðɪs eɪm",
        translation: "He couldn't achieve this aim",
      },
      {
        id: 94,
        expression: "Ничего не произошло",
        transcription: "ˈnʌθɪŋ ˈhæpənd",
        translation: "Nothing happened",
      },
      {
        id: 95,
        expression: "Я ненавидел тратить время впустую",
        transcription: "aɪ ˈheɪtɪd ˈweɪstɪŋ təʊm",
        translation: "I hated wasting tome",
      },
      {
        id: 96,
        expression: "Я надеялся на лучшее",
        transcription: "aɪ həʊpt fə ðə bɛst",
        translation: "I hoped for the best",
      },
      {
        id: 97,
        expression: "Ничего не интересовало его",
        transcription: "ˈnʌθɪŋ ˈɪntrɪstɪd hɪm",
        translation: "Nothing interested him",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Закрепление пройденного материала</h3>
</div>`,
    },
  ],
];
