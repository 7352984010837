export const wordsLesson105 = [
  [
    {
      id: 1,
      word: 'addiction to',
      transcription: 'əˈdɪkʃ(ə)n tʊ',
      translation: 'зависимость от',
    },
    {
      id: 2,
      word: 'advantage',
      transcription: 'ədˈvɑːntɪʤ',
      translation: 'преимущество',
    },
    {
      id: 3,
      word: 'associate',
      transcription: 'əˈsəʊʃɪɪt',
      translation: 'ассоциировать',
    },
    {
      id: 4,
      word: 'attitude to',
      transcription: 'ˈætɪtjuːd tʊ',
      translation: 'отношение к',
    },
    {
      id: 5,
      word: 'blackboard',
      transcription: 'ˈblækbɔːd',
      translation: 'классная доска',
    },
    {
      id: 6,
      word: 'cancer',
      transcription: 'ˈkænsə',
      translation: 'рак',
    },
    {
      id: 7,
      word: 'cause',
      transcription: 'kɔːz',
      translation: 'причина',
    },
    {
      id: 8,
      word: 'cheating',
      transcription: 'ˈʧiːtɪŋ',
      translation: 'мошенничество, списывание',
    },
    {
      id: 9,
      word: 'cure',
      transcription: 'kjʊə',
      translation: 'лечить, лечение',
    },
    {
      id: 10,
      word: 'disadvantage',
      transcription: 'ˌdɪsədˈvɑːntɪʤ',
      translation: 'недостаток',
    },
    {
      id: 11,
      word: 'discipline',
      transcription: 'ˈdɪsɪplɪn',
      translation: 'дисциплина',
    },
    {
      id: 12,
      word: 'disease',
      transcription: 'dɪˈziːz',
      translation: 'болезнь',
    },
    {
      id: 13,
      word: 'forever',
      transcription: 'fəˈrɛvə',
      translation: 'навсегда',
    },
    {
      id: 14,
      word: 'lack',
      transcription: 'læk',
      translation: 'нехватка, не хватать',
    },
    {
      id: 15,
      word: 'linguistics',
      transcription: 'lɪŋˈgwɪstɪks',
      translation: 'лингвистика',
    },
    {
      id: 16,
      word: 'lung',
      transcription: 'lʌŋ',
      translation: 'легкое',
    },
    {
      id: 17,
      word: 'negative',
      transcription: 'ˈnɛgətɪv',
      translation: 'негативный',
    },
    {
      id: 18,
      word: 'refusal',
      transcription: 'rɪˈfjuːzəl',
      translation: 'отказ',
    },
    {
      id: 19,
      word: 'search',
      transcription: 'sɜːʧ',
      translation: 'поиск, искать',
    },
    {
      id: 20,
      word: 'specialise',
      transcription: 'ˈspɛʃ(ə)laɪz',
      translation: 'специализироваться',
    },
    {
      id: 21,
      word: 'temporary',
      transcription: 'ˈtɛmpərəri',
      translation: 'временный',
    },
    {
      id: 22,
      word: 'whiteboard',
      transcription: 'ˈwaɪtbɔːd',
      translation: 'доска',
    },
  ],
];
