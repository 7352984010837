export const wordsLesson156 = [
  [
    {
      id: 1,
      word: 'absurd',
      transcription: 'əbˈsɜːd',
      translation: 'абсурд',
    },
    {
      id: 2,
      word: 'aqua park',
      transcription: 'ˈækwə pɑːk',
      translation: 'аквапарк',
    },
    {
      id: 3,
      word: 'career',
      transcription: 'kəˈrɪə',
      translation: 'карьера',
    },
    {
      id: 4,
      word: 'centre',
      transcription: 'ˈsɛntə',
      translation: 'центр',
    },
    {
      id: 5,
      word: 'construction',
      transcription: 'kənˈstrʌkʃən',
      translation: 'конструкция',
    },
    {
      id: 6,
      word: 'curiosity',
      transcription: 'ˌkjʊərɪˈɒsɪti',
      translation: 'любопытство',
    },
    {
      id: 7,
      word: 'eastern',
      transcription: 'ˈiːstən',
      translation: 'восточный',
    },
    {
      id: 8,
      word: 'effort',
      transcription: 'ˈɛfət',
      translation: 'усилие',
    },
    {
      id: 9,
      word: 'extent',
      transcription: 'ɪksˈtɛnt',
      translation: 'степень',
    },
    {
      id: 10,
      word: 'instance',
      transcription: 'ˈɪnstəns',
      translation: 'пример',
    },
    {
      id: 11,
      word: 'northern',
      transcription: 'ˈnɔːðən',
      translation: 'северный',
    },
    {
      id: 12,
      word: 'railroad',
      transcription: 'ˈreɪlrəʊd',
      translation: 'железная дорога',
    },
    {
      id: 13,
      word: 'rationally',
      transcription: 'ˈræʃnəli',
      translation: 'рационально',
    },
    {
      id: 14,
      word: 'southern',
      transcription: 'ˈsʌðən',
      translation: 'южный',
    },
    {
      id: 15,
      word: 'vain',
      transcription: 'veɪn',
      translation: 'напрасно',
    },
    {
      id: 16,
      word: 'western',
      transcription: 'ˈwɛstən',
      translation: 'западный',
    },
  ],
];
