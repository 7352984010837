export const contentLesson87 = [
  [
    {
      title: "Урок 87. Past Simple. 150 Regular Verbs. Part 3",
    },
    [
      {
        id: 1,
        expression: "Эти новости меня совсем не удивили",
        transcription: "ðɪs njuːz dɪdnt səˈpraɪz mi ət ɔːl",
        translation: "This news didn't surprise me at all",
      },
      {
        id: 2,
        expression: "Мой друг поделился этой ссылкой со мной",
        transcription: "maɪ frɛnd ʃeəd ðɪs lɪŋk wɪð miː",
        translation: "My friend shared this link with me",
      },
      {
        id: 3,
        expression: "Он часто смотрел в зеркало",
        transcription: "hi ˈɒf(ə)n lʊkt ɪn ðə ˈmɪrə",
        translation: "He often looked in the mirror",
      },
      {
        id: 4,
        expression: "Кто-то атаковал эту бедную женщину",
        transcription: "ˈsʌmwʌn əˈtækt ðɪs pʊə ˈwʊmən",
        translation: "Someone attacked this poor woman",
      },
      {
        id: 5,
        expression: "Они жили очень тихо и мирно",
        transcription: "ðeɪ lɪvd ˈvɛri ˈkwaɪətli ənd ˈpiːsfʊli",
        translation: "They lived very quietly and peacefully",
      },
      {
        id: 6,
        expression: "Я внезапно заметил ее",
        transcription: "aɪ ˈnəʊtɪst hə ˈsʌdnli",
        translation: "I noticed her suddenly",
      },
      {
        id: 7,
        expression: "Враг внезапно напал на них",
        transcription: "ði ˈɛnɪmi əˈtækt ðəm ˈsʌdnli",
        translation: "The enemy attacked them suddenly",
      },
      {
        id: 8,
        expression: "Компания доставила все вовремя",
        transcription: "ðə ˈkʌmpəni dɪˈlɪvəd ˈɛvrɪθɪŋ ɒn taɪm",
        translation: "The company delivered everything on time",
      },
      {
        id: 9,
        expression: "Он ничего не обещал",
        transcription: "hi ˈprɒmɪst ˈnʌθɪŋ / hi dɪdnt ˈprɒmɪs ˈɛnɪθɪŋ",
        translation: "He promised nothing / He didn't promise anything",
      },
      {
        id: 10,
        expression: "Это действительно удивило каждого",
        transcription: "ɪt ˈrɪəli səˈpraɪzd ˈɛvrɪwʌn",
        translation: "It really surprised everyone",
      },
      {
        id: 11,
        expression: "Моя жизнь полностью изменилась",
        transcription: "maɪ laɪf ʧeɪnʤd kəmˈpliːtli",
        translation: "My life changed completely",
      },
      {
        id: 12,
        expression: "Я включил несколько примеров в свое сочинение",
        transcription: "aɪ ɪnˈkluːdɪd səm ɪgˈzɑːmplz ɪn maɪ ˌkɒmpəˈzɪʃən",
        translation: "I included some examples in my composition",
      },
      {
        id: 13,
        expression: "Я заметил что-то действительно странное",
        transcription: "aɪ ˈnəʊtɪst ˈsʌmθɪŋ ˈrɪəli streɪnʤ",
        translation: "I noticed something really strange",
      },
      {
        id: 14,
        expression: "Я выразил свои мысли в нашем разговоре",
        transcription: "aɪ ɪksˈprɛst maɪ θɔːts ɪn ˈaʊə ˌkɒnvəˈseɪʃən",
        translation: "I expressed my thoughts in our conversation",
      },
      {
        id: 15,
        expression: "Она поделилась со мной этим секретом",
        transcription: "ʃi ʃeəd ðɪs ˈsiːkrɪt wɪð miː",
        translation: "She shared this secret with me",
      },
      {
        id: 16,
        expression: "Он целовал ее в щеку",
        transcription: "hi kɪst hə ʧiːk",
        translation: "He kissed her cheek",
      },
      {
        id: 17,
        expression: "Я успешно сдал тот экзамен",
        transcription: "aɪ pɑːst ðət ɪgˈzæm səkˈsɛsfʊli",
        translation: "I passed that exam successfully",
      },
      {
        id: 18,
        expression: "Он внезапно прикоснулся ко мне",
        transcription: "hi tʌʧt mi ˈsʌdnli",
        translation: "He touched me suddenly",
      },
      {
        id: 19,
        expression: "Цена включала все",
        transcription: "ðə praɪs ɪnˈkluːdɪd ˈɛvrɪθɪŋ",
        translation: "The price included everything",
      },
      {
        id: 20,
        expression: "Они протестировали мой английский в начале",
        transcription: "ðeɪ ˈtɛstɪd maɪ ˈɪŋglɪʃ ɪn ðə bɪˈgɪnɪŋ",
        translation: "They tested my English in the beginning",
      },
      {
        id: 21,
        expression: "Она посмотрела на него и улыбнулась",
        transcription: "ʃi lʊkt ət ɪm ənd smaɪld",
        translation: "She looked at him and smiled",
      },
      {
        id: 22,
        expression: "Они доставили посылку",
        transcription: "ðeɪ dɪˈlɪvəd ðə ˈpɑːsl",
        translation: "They delivered the parcel",
      },
      {
        id: 23,
        expression: "Тур включал посещение того музея",
        transcription: "ðə tʊər ɪnˈkluːdɪd ə ˈvɪzɪt tə ðət mju(ː)ˈzɪəm",
        translation: "The tour included a visit to that museum",
      },
      {
        id: 24,
        expression: "Мне действительно нравилось плавать в море",
        transcription: "aɪ ˈrɪəli laɪkt ˈswɪmɪŋ ɪn ðə siː",
        translation: "I really liked swimming in the sea",
      },
      {
        id: 25,
        expression: "Молодая пара жила очень счастливо и мирно",
        transcription: "ə jʌŋ ˈkʌpl lɪvd ˈvɛri ˈhæpɪli ənd ˈpiːsfʊli",
        translation: "A young couple lived very happily and peacefully",
      },
      {
        id: 26,
        expression: "Я не трогал те документы",
        transcription: "aɪ dɪdnt tʌʧ ðəʊz ˈdɒkjʊmənts",
        translation: "I didn't touch those documents",
      },
      {
        id: 27,
        expression: "Я посмотрел в зеркало",
        transcription: "aɪ lʊkt ɪn ðə ˈmɪrə",
        translation: "I looked in the mirror",
      },
      {
        id: 28,
        expression: "Он дотронулся до нее",
        transcription: "hi tʌʧt hɜː",
        translation: "He touched her",
      },
      {
        id: 29,
        expression: "Он помыл свою машину",
        transcription: "hi wɒʃt ɪz kɑː",
        translation: "He washed his car",
      },
      {
        id: 30,
        expression: "Я задал ему вопрос",
        transcription: "aɪ ɑːskt ɪm ə ˈkwɛsʧən",
        translation: "I asked him a question",
      },
      {
        id: 31,
        expression: "Я выразил свое собственное мнение",
        transcription: "aɪ ɪksˈprɛst maɪ ˈpɜːsnl əˈpɪnjən",
        translation: "I expressed my personal opinion",
      },
      {
        id: 32,
        expression: "Когда ты заметил это?",
        transcription: "wɛn dɪd jʊ ˈnəʊtɪs ɪt?",
        translation: "When did you notice it?",
      },
      {
        id: 33,
        expression: "Я заполнил форму",
        transcription: "aɪ fɪld ɪn ðə fɔːm",
        translation: "I filled in the form",
      },
      {
        id: 34,
        expression: "Он не задал мне никаких вопросов",
        transcription: "hi dɪdnt ɑːsk mi ˈɛni ˈkwɛsʧənz",
        translation: "He didn't ask me any questions",
      },
      {
        id: 35,
        expression: "Они доставили коробку",
        transcription: "ðeɪ dɪˈlɪvəd ðə bɒks",
        translation: "They delivered the box",
      },
      {
        id: 36,
        expression: "Мне не нравилось это в начале",
        transcription: "aɪ dɪdnt laɪk ɪt ɪn ðə bɪˈgɪnɪŋ",
        translation: "I didn't like it in the beginning",
      },
      {
        id: 37,
        expression: "Он поцеловал ее в губы",
        transcription: "hi kɪst hə lɪps",
        translation: "He kissed her lips",
      },
      {
        id: 38,
        expression: "Я немедленно это заметил",
        transcription: "aɪ ˈnəʊtɪst ɪt ɪˈmiːdiətli",
        translation: "I noticed it immediately",
      },
      {
        id: 39,
        expression: "Он жил и работал на ферме",
        transcription: "hi lɪvd ənd wɜːkt ɒn ə fɑːm",
        translation: "He lived and worked on a farm",
      },
      {
        id: 40,
        expression: "Это полностью изменило мою жизнь",
        transcription: "ɪt ʧeɪnʤd maɪ laɪf kəmˈpliːtli",
        translation: "It changed my life completely",
      },
      {
        id: 41,
        expression:
          "Я скачал это приложение бесплатно и нахожу его чрезвычайно полезным",
        transcription:
          "aɪ ˌdaʊnˈləʊdɪd ðɪs ˌæplɪˈkeɪʃ(ə)n fə friː ənd aɪ faɪnd ɪt ɪksˈtriːmli ˈjuːsfʊl",
        translation:
          "I downloaded this application for free and I find it extremely useful",
      },
      {
        id: 42,
        expression: "Власти пообещали как можно скорее решить эту проблему",
        transcription:
          "ði ɔːˈθɒrɪtiz ˈprɒmɪst tə sɒlv ðɪs ˈprɒbləm əz suːn əz ˈpɒsəbl",
        translation:
          "The authorities promised to solve this problem as soon as possible",
      },
      {
        id: 43,
        expression: "Я просто хотел выразить свою личную точку зрения",
        transcription: "aɪ ʤəst ˈwɒntɪd tʊ ɪksˈprɛs maɪ ˈpɜːsnl pɔɪnt əv vjuː",
        translation: "I just wanted to express my personal point of view",
      },
      {
        id: 44,
        expression: "Он задал мне несколько вопросов во время интервью",
        transcription: "hi ɑːskt mi ə fjuː ˈkwɛsʧənz ˈdjʊərɪŋ ði ˈɪntəvjuː",
        translation: "He asked me a few questions during the interview",
      },
      {
        id: 45,
        expression:
          "Они протестировали мой английский, чтобы понять мой уровень",
        transcription: "ðeɪ ˈtɛstɪd maɪ ˈɪŋglɪʃ tʊ ˌʌndəˈstænd maɪ ˈlɛvl",
        translation: "They tested my English to understand my level",
      },
      {
        id: 46,
        expression: "К счастью, я успешно сдал этот экзамен",
        transcription: "ˈfɔːʧnɪtli, aɪ pɑːst ðɪs ɪgˈzæm səkˈsɛsfʊli",
        translation: "Fortunately, I passed this exam successfully",
      },
      {
        id: 47,
        expression:
          "Моя жизнь полностью изменилась после того, как это произошло",
        transcription: "maɪ laɪf ʧeɪnʤd kəmˈpliːtli ˈɑːftər ɪt ˈhæpənd",
        translation: "My life changed completely after it happened",
      },
      {
        id: 48,
        expression: "Я хотел перестать думать об этом",
        transcription: "aɪ ˈwɒntɪd tə stɒp ˈθɪŋkɪŋ əˈbaʊt ɪt",
        translation: "I wanted to stop thinking about it",
      },
      {
        id: 49,
        expression: "Я посмотрел это в словаре",
        transcription: "aɪ lʊkt ɪt ʌp ɪn ə ˈdɪkʃ(ə)n(ə)ri",
        translation: "I looked it up in a dictionary",
      },
      {
        id: 50,
        expression: "Я скачал это приложение бесплатно",
        transcription: "aɪ ˌdaʊnˈləʊdɪd ðɪs ˌæplɪˈkeɪʃ(ə)n fə friː",
        translation: "I downloaded this application for free",
      },
      {
        id: 51,
        expression: "Мне пришлось заполнить форму",
        transcription: "aɪ həd tə fɪl ɪn ðə fɔːm",
        translation: "I had to fill in the form",
      },
      {
        id: 52,
        expression: "Мой друг рекомендовал остановиться в этом отеле",
        transcription: "maɪ frɛnd ˌrɛkəˈmɛndɪd ˈsteɪɪŋ ɪn (æt) ðɪs həʊˈtɛl",
        translation: "My friend recommended staying in (at) this hotel",
      },
      {
        id: 53,
        expression: "Он помылся",
        transcription: "hi wɒʃt hɪmˈsɛlf",
        translation: "He washed himself",
      },
      {
        id: 54,
        expression: "Я должен был заполнить пробелы в этом документе",
        transcription: "aɪ həd tə fɪl ɪn ðə gæps ɪn ðɪs ˈdɒkjʊmənt",
        translation: "I had to fill in the gaps in this document",
      },
      {
        id: 55,
        expression: "Я решил продолжить изучать английский здесь",
        transcription: "aɪ dɪˈsaɪdɪd tə kənˈtɪnju(ː) ˈlɜːnɪŋ ˈɪŋglɪʃ hɪə",
        translation: "I decided to continue learning English here",
      },
      {
        id: 56,
        expression: "Мы перестали говорить об этом",
        transcription: "wi stɒpt ˈtɔːkɪŋ əˈbaʊt ɪt",
        translation: "We stopped talking about it",
      },
      {
        id: 57,
        expression: "Мы перестали делать это",
        transcription: "wi stɒpt ˈdu(ː)ɪŋ ɪt",
        translation: "We stopped doing it",
      },
      {
        id: 58,
        expression: "Они перестали говорить об этом",
        transcription: "ðeɪ stɒpt ˈtɔːkɪŋ əˈbaʊt ɪt",
        translation: "They stopped talking about it",
      },
      {
        id: 59,
        expression: "Я рекомендовал использовать тот сервис",
        transcription: "aɪ ˌrɛkəˈmɛndɪd ˈjuːzɪŋ ðət ˈsɜːvɪs",
        translation: "I recommended using that service",
      },
      {
        id: 60,
        expression: "Она рекомендовала нам использовать этот сервис",
        transcription: "ʃi ˌrɛkəˈmɛndɪd əs tə juːz ðɪs ˈsɜːvɪs",
        translation: "She recommended us to use this service",
      },
      {
        id: 61,
        expression: "Я решил использовать тот сервис",
        transcription: "aɪ dɪˈsaɪdɪd tə juːz ðət ˈsɜːvɪs",
        translation: "I decided to use that service",
      },
      {
        id: 62,
        expression: "Я задал ей несколько вопросов",
        transcription: "aɪ ɑːskt hə səm ˈkwɛsʧənz",
        translation: "I asked her some questions",
      },
      {
        id: 63,
        expression: "Я задал ему несколько вопросов",
        transcription: "aɪ ɑːskt ɪm səm ˈkwɛsʧənz",
        translation: "I asked him some questions",
      },
      {
        id: 64,
        expression: "Я задал ей вопрос",
        transcription: "aɪ ɑːskt hər ə ˈkwɛsʧən",
        translation: "I asked her a question",
      },
      {
        id: 65,
        expression: "Я решил остаться там",
        transcription: "aɪ dɪˈsaɪdɪd tə steɪ ðeə",
        translation: "I decided to stay there",
      },
      {
        id: 66,
        expression: "Я хотел остаться там",
        transcription: "aɪ ˈwɒntɪd tə steɪ ðeə",
        translation: "I wanted to stay there",
      },
      {
        id: 67,
        expression: "Она предпочла остаться здесь",
        transcription: "ʃi prɪˈfɜːd tə steɪ hɪə",
        translation: "She preferred to stay here",
      },
      {
        id: 68,
        expression: "Я рекомендовал прочитать ту книгу",
        transcription: "aɪ ˌrɛkəˈmɛndɪd ˈriːdɪŋ ðət bʊk",
        translation: "I recommended reading that book",
      },
      {
        id: 69,
        expression: "Я рекомендовал ту книгу ей",
        transcription: "aɪ ˌrɛkəˈmɛndɪd ðət bʊk tə hɜː",
        translation: "I recommended that book to her",
      },
      {
        id: 70,
        expression: "Я рекомендовал посмотреть этот фильм",
        transcription: "aɪ ˌrɛkəˈmɛndɪd ˈwɒʧɪŋ ðɪs ˈmuːvi",
        translation: "I recommended watching this movie",
      },
      {
        id: 71,
        expression: "Я посмотрел это слово в словаре",
        transcription: "aɪ lʊkt ʌp ðɪs wɜːd ɪn ə ˈdɪkʃ(ə)n(ə)ri",
        translation: "I looked up this word in a dictionary",
      },
      {
        id: 72,
        expression: "Я нашел это слово в словаре",
        transcription: "aɪ faʊnd ðɪs wɜːd ɪn ə ˈdɪkʃ(ə)n(ə)ri",
        translation: "I found this word in a dictionary",
      },
      {
        id: 73,
        expression: "Он посмотрел эти слова в словаре",
        transcription: "hi lʊkt ʌp ðiːz wɜːdz ɪn ə ˈdɪkʃ(ə)n(ə)ri",
        translation: "He looked up these words in a dictionary",
      },
      {
        id: 74,
        expression: "Я решил выглянуть в окно",
        transcription: "aɪ dɪˈsaɪdɪd tə lʊk aʊt əv ðə ˈwɪndəʊ",
        translation: "I decided to look out of the window",
      },
      {
        id: 75,
        expression: "Я выглянул в окно",
        transcription: "aɪ lʊkt aʊt əv ðə ˈwɪndəʊ",
        translation: "I looked out of the window",
      },
      {
        id: 76,
        expression: "Он решил не выглядывать из окна",
        transcription: "hi dɪˈsaɪdɪd nɒt tə lʊk aʊt əv ðɪs ˈwɪndəʊ",
        translation: "He decided not to look out of this window",
      },
      {
        id: 77,
        expression:
          "Мне нужно учиться очень усердно, чтобы поступить в этот университет",
        transcription:
          "aɪ niːd tə ˈstʌdi ˈvɛri hɑːd tʊ ˈɛntə ðɪs ˌjuːnɪˈvɜːsɪti",
        translation: "I need to study very hard to enter this university",
      },
      {
        id: 78,
        expression:
          "Мне нужно учиться усерднее, чтобы поступить в этот университет",
        transcription: "aɪ niːd tə ˈstʌdi ˈhɑːdə tʊ ˈɛntə ðɪs ˌjuːnɪˈvɜːsɪti",
        translation: "I need to study harder to enter this university",
      },
      {
        id: 79,
        expression:
          "Он должен учиться действительно усердно, чтобы поступить в этот университет",
        transcription:
          "hi niːd tə ˈstʌdi ˈrɪəli hɑːd tʊ ˈɛntə ðɪs ˌjuːnɪˈvɜːsɪti",
        translation: "He need to study really hard to enter this university",
      },
      {
        id: 80,
        expression: "Я, наконец, вспомнил это слово",
        transcription: "aɪ rɪˈmɛmbəd ðɪs wɜːd ət lɑːst",
        translation: "I remembered this word at last",
      },
      {
        id: 81,
        expression: "Я, наконец, вспомнил это правило",
        transcription: "aɪ rɪˈmɛmbəd ðɪs ruːl ət lɑːst",
        translation: "I remembered this rule at last",
      },
      {
        id: 82,
        expression: "Она попросила меня сделать это снова",
        transcription: "ʃi ɑːskt mi tə dʊ ɪt əˈgɛn",
        translation: "She asked me to do it again",
      },
      {
        id: 83,
        expression: "Я попросил его не делать это снова",
        transcription: "aɪ ɑːskt ɪm nɒt tə dʊ ɪt əˈgɛn",
        translation: "I asked him not to do it again",
      },
      {
        id: 84,
        expression: "Он попросил меня делать это снова и снова",
        transcription: "hi ɑːskt mi tə dʊ ɪt əˈgɛn ənd əˈgɛn",
        translation: "He asked me to do it again and again",
      },
      {
        id: 85,
        expression: "Я сделал заказ в этом онлайн магазине",
        transcription: "aɪ meɪd ən ˈɔːdər ɪn ðɪs ˈɒnˌlaɪn stɔː",
        translation: "I made an order in this online store",
      },
      {
        id: 86,
        expression: "Я должен был заполнить пробелы в том упражнении",
        transcription: "aɪ həd tə fɪl ɪn ðə gæps ɪn ðət ˈɛksəsaɪz",
        translation: "I had to fill in the gaps in that exercise",
      },
      {
        id: 87,
        expression: "Я решил продолжить учиться там",
        transcription: "aɪ dɪˈsaɪdɪd tə kənˈtɪnju(ː) ˈstʌdiɪŋ ðeə",
        translation: "I decided to continue studying there",
      },
      {
        id: 88,
        expression: "Учитель указал на ошибку студента",
        transcription: "ðə ˈtiːʧə ˈpɔɪntɪd aʊt ðə ˈstjuːdənts mɪsˈteɪk",
        translation: "The teacher pointed out the student's mistake",
      },
      {
        id: 89,
        expression: "Я был готов сделать заказ",
        transcription: "aɪ wəz ˈrɛdi tə meɪk ən ˈɔːdə",
        translation: "I was ready to make an order",
      },
      {
        id: 90,
        expression: "Дом принадлежал ему",
        transcription: "ðə haʊs bɪˈlɒŋd tə hɪm",
        translation: "The house belonged to him",
      },
      {
        id: 91,
        expression: "Она указала на карту",
        transcription: "ʃi ˈpɔɪntɪd ət ðə mæp",
        translation: "She pointed at the map",
      },
      {
        id: 92,
        expression: "Я говорил с ним не так давно",
        transcription: "aɪ tɔːkt tə ɪm nɒt ə lɒŋ taɪm əˈgəʊ",
        translation: "I talked to him not a long time ago",
      },
      {
        id: 93,
        expression: "Он рекомендовал посмотреть этот фильм",
        transcription: "hi ˌrɛkəˈmɛndɪd ˈwɒʧɪŋ ðɪs ˈmuːvi",
        translation: "He recommended watching this movie",
      },
      {
        id: 94,
        expression: "Она помылась",
        transcription: "ʃi wɒʃt hɜːˈsɛlf",
        translation: "She washed herself",
      },
      {
        id: 95,
        expression: "Она попросила меня остаться с ней",
        transcription: "ʃi ɑːskt mi tə steɪ wɪð hɜː",
        translation: "She asked me to stay with her",
      },
      {
        id: 96,
        expression: "Мы продолжали разговаривать",
        transcription: "wi kənˈtɪnju(ː)d ˈtɔːkɪŋ",
        translation: "We continued talking",
      },
      {
        id: 97,
        expression: "Он обещал сделать это",
        transcription: "hi ˈprɒmɪst tə dʊ ɪt",
        translation: "He promised to do it",
      },
      {
        id: 98,
        expression: "Я был готов заказать это",
        transcription: "aɪ wəz ˈrɛdi tʊ ˈɔːdər ɪt",
        translation: "I was ready to order it",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Закрепление пройденного материала</h3>
</div>`,
    },
  ],
];
