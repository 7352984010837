export const wordsLesson22 = [
  [
    {
      id: 1,
      word: 'afternoon',
      transcription: 'ˈɑːftəˈnuːn',
      translation: 'днем',
    },
    {
      id: 2,
      word: 'ask',
      transcription: 'ɑːsk',
      translation: 'спрашивать, просить',
    },
    {
      id: 3,
      word: 'bike',
      transcription: 'baɪk',
      translation: 'велосипед',
    },
    {
      id: 4,
      word: 'careful',
      transcription: 'ˈkeəfʊl',
      translation: 'осторожный',
    },
    {
      id: 5,
      word: 'check',
      transcription: 'ʧɛk',
      translation: 'проверять',
    },
    {
      id: 6,
      word: 'continue',
      transcription: 'kənˈtɪnju(ː)',
      translation: 'продолжать',
    },
    {
      id: 7,
      word: 'email',
      transcription: 'ˈiːmeɪl',
      translation: 'электронная почта',
    },
    {
      id: 8,
      word: 'evening',
      transcription: 'ˈiːvnɪŋ',
      translation: 'вечер',
    },
    {
      id: 9,
      word: 'horse',
      transcription: 'hɔːs',
      translation: 'лошадь',
    },
    {
      id: 10,
      word: 'information',
      transcription: 'ˌɪnfəˈmeɪʃən',
      translation: 'информация',
    },
    {
      id: 11,
      word: 'lose',
      transcription: 'luːz',
      translation: 'терять',
    },
    {
      id: 12,
      word: 'meet',
      transcription: 'miːt',
      translation: 'встречаться',
    },
    {
      id: 13,
      word: 'must',
      transcription: 'mʌst',
      translation: 'должен',
    },
    {
      id: 14,
      word: "o'clock",
      transcription: 'əˈklɒk',
      translation: 'час',
    },
    {
      id: 15,
      word: 'please',
      transcription: 'pliːz',
      translation: 'пожалуйста',
    },
    {
      id: 16,
      word: 'ride',
      transcription: 'raɪd',
      translation: 'ездить, кататься',
    },
    {
      id: 17,
      word: 'should',
      transcription: 'ʃʊd',
      translation: 'следует',
    },
    {
      id: 18,
      word: 'something',
      transcription: 'sʌmθɪŋ',
      translation: 'что-то',
    },
    {
      id: 19,
      word: 'stay',
      transcription: 'steɪ',
      translation: 'оставаться',
    },
    {
      id: 20,
      word: 'today',
      transcription: 'təˈdeɪ',
      translation: 'сегодня',
    },
    {
      id: 21,
      word: 'try',
      transcription: 'traɪ',
      translation: 'пытаться, стараться',
    },
    {
      id: 22,
      word: 'urgently',
      transcription: 'ˈɜːʤəntli',
      translation: 'срочно',
    },
    {
      id: 23,
      word: 'win',
      transcription: 'wɪn',
      translation: 'выигрывать, побеждать',
    },
    {
      id: 24,
      word: 'right now',
      transcription: 'raɪt naʊ',
      translation: 'прямо сейчас',
    },
  ],
];
