export const wordsLesson267 = [
  [
    {
      id: 1,
      word: 'be up to',
      transcription: 'biː ʌp tuː',
      translation: 'решать',
    },
    {
      id: 2,
      word: 'sign in',
      transcription: 'saɪn ɪn',
      translation: 'зарегистрироваться, войти в систему',
    },
    {
      id: 3,
      word: 'let out',
      transcription: 'lɛt aʊt',
      translation: 'выпускать',
    },
    {
      id: 4,
      word: 'pay off',
      transcription: 'peɪ ɒf',
      translation: 'окупаться',
    },
    {
      id: 5,
      word: 'plan ahead',
      transcription: 'plæn əˈhɛd',
      translation: 'планировать заранее',
    },
    {
      id: 6,
      word: 'fill out',
      transcription: 'fɪl aʊt',
      translation: 'заполнять',
    },
    {
      id: 7,
      word: 'heat up',
      transcription: 'hiːt ʌp',
      translation: 'нагревать, подогревать',
    },
    {
      id: 8,
      word: 'shop around',
      transcription: 'ʃɒp əˈraʊnd',
      translation: 'присмотреться, подыскивать',
    },
    {
      id: 9,
      word: 'try out',
      transcription: 'traɪ aʊt',
      translation: 'опробовать',
    },
    {
      id: 10,
      word: 'taken aback',
      transcription: 'ˈteɪkən əˈbæk',
      translation: 'ошеломлен',
    },
    {
      id: 11,
      word: 'dwell upon',
      transcription: 'dwɛl əˈpɒn',
      translation: 'останавливаться',
    },
    {
      id: 12,
      word: 'mess about',
      transcription: 'mɛs əˈbaʊt',
      translation: 'бездельничать',
    },
    {
      id: 13,
      word: 'keep on',
      transcription: 'kiːp ɒn',
      translation: 'продолжать',
    },
    {
      id: 14,
      word: 'go with',
      transcription: 'ɡəʊ wɪð',
      translation: 'сочетаться',
    },
    {
      id: 15,
      word: 'laze around',
      transcription: 'leɪz əˈraʊnd',
      translation: 'бездельничать',
    },
    {
      id: 16,
      word: 'move up to',
      transcription: 'muːv ʌp tuː',
      translation: 'придвинуться',
    },
    {
      id: 17,
      word: 'switch on',
      transcription: 'swɪʧ ɒn',
      translation: 'включать',
    },
    {
      id: 18,
      word: 'pick at',
      transcription: 'pɪk æt',
      translation: 'придираться',
    },
    {
      id: 19,
      word: 'comfortably',
      transcription: 'ˈkʌmfᵊtəbᵊli',
      translation: 'удобно',
    },
    {
      id: 20,
      word: 'declaration',
      transcription: 'ˌdɛkləˈreɪʃᵊn',
      translation: 'декларация',
    },
  ],
];
