export const contentLesson65 = [
  [
    {
      title: "Урок 65. Present Simple: verb + to be: Question",
    },
    [
      {
        id: 1,
        expression: "У тебя плохой результат?",
        transcription: "dʊ jʊ həv ə bæd rɪˈzʌlt?",
        translation: "Do you have a bad result?",
      },
      {
        id: 2,
        expression: "Он уважает наши традиции?",
        transcription: "dəz hi rɪsˈpɛkt ˈaʊə trəˈdɪʃənz?",
        translation: "Does he respect our traditions?",
      },
      {
        id: 3,
        expression: "Я твой друг?",
        transcription: "əm aɪ jə frɛnd?",
        translation: "Am I your friend?",
      },
      {
        id: 4,
        expression: "Они очень ленивые студенты?",
        transcription: "ɑː ðeɪ ˈvɛri ˈleɪzi ˈstjuːdənts?",
        translation: "Are they very lazy students?",
      },
      {
        id: 5,
        expression: "Это более дорогой отель?",
        transcription: "ɪz ɪt ə mɔːr ɪksˈpɛnsɪv həʊˈtɛl?",
        translation: "Is it a more expensive hotel?",
      },
      {
        id: 6,
        expression: "Это дорогой билет?",
        transcription: "ɪz ɪt ən ɪksˈpɛnsɪv ˈtɪkɪt?",
        translation: "Is it an expensive ticket?",
      },
      {
        id: 7,
        expression: "Тебе также это очень интересно?",
        transcription: "ɪz ɪt ˈɔːlsəʊ ˈvɛri ˈɪntrɪstɪŋ fə juː?",
        translation: "Is it also very interesting for you?",
      },
      {
        id: 8,
        expression: "Ты менеджер?",
        transcription: "ɑː jʊ ə ˈmænɪʤə?",
        translation: "Are you a manager?",
      },
      {
        id: 9,
        expression: "У него есть маленькая сумка?",
        transcription: "dəz hi həv ə smɔːl bæg?",
        translation: "Does he have a small bag?",
      },
      {
        id: 10,
        expression: "Он трудолюбивый студент?",
        transcription: "ɪz hi ə ˈhɑːdˌwɜːkɪŋ ˈstjuːdənt?",
        translation: "Is he a hard-working student?",
      },
      {
        id: 11,
        expression: "Они живут счастливо вместе?",
        transcription: "dʊ ðeɪ lɪv ˈhæpɪli təˈgɛðə?",
        translation: "Do they live happily together?",
      },
      {
        id: 12,
        expression: "Он популярный блогер?",
        transcription: "ɪz hi ə ˈpɒpjʊlə ˈblɒgə?",
        translation: "Is he a popular blogger?",
      },
      {
        id: 13,
        expression: "Они слишком мало занимаются?",
        transcription: "dʊ ðeɪ ˈstʌdi tuː ˈlɪtl?",
        translation: "Do they study too little?",
      },
      {
        id: 14,
        expression: "Это общественная проблема?",
        transcription: "ɪz ɪt ə ˈsəʊʃəl ˈprɒbləm?",
        translation: "Is it a social problem?",
      },
      {
        id: 15,
        expression: "Она профессиональная певица?",
        transcription: "ɪz ʃi ə prəˈfɛʃənl ˈsɪŋə?",
        translation: "Is she a professional singer?",
      },
      {
        id: 16,
        expression: "Это так дорого?",
        transcription: "ɪz ɪt səʊ ɪksˈpɛnsɪv?",
        translation: "Is it so expensive?",
      },
      {
        id: 17,
        expression: "Ты смотришь этого видео блогера?",
        transcription: "dʊ jʊ wɒʧ ðɪs ˈvɪdɪəʊ ˈblɒgə?",
        translation: "Do you watch this video blogger?",
      },
      {
        id: 18,
        expression: "Он ведет себя очень странно?",
        transcription: "dəz hi bɪˈheɪv ˈvɛri ˈstreɪnʤli?",
        translation: "Does he behave very strangely?",
      },
      {
        id: 19,
        expression: "Он странный человек?",
        transcription: "ɪz hi ə streɪnʤ ˈpɜːsn?",
        translation: "Is he a strange person?",
      },
      {
        id: 20,
        expression: "Она профессионально поет?",
        transcription: "dəz ʃi sɪŋ prəˈfɛʃnəli?",
        translation: "Does she sing professionally?",
      },
      {
        id: 21,
        expression: "Это действительно дешевле?",
        transcription: "ɪz ɪt ˈrɪəli ˈʧiːpə?",
        translation: "Is it really cheaper?",
      },
      {
        id: 22,
        expression: "Это много стоит?",
        transcription: "dəz ɪt kɒst ə lɒt?",
        translation: "Does it cost a lot?",
      },
      {
        id: 23,
        expression: "Она хороший танцор?",
        transcription: "ɪz ʃi ə gʊd ˈdɑːnsə?",
        translation: "Is she a good dancer?",
      },
      {
        id: 24,
        expression: "Это уникальная возможность?",
        transcription: "ɪz ɪt ə juːˈniːk ˌɒpəˈtjuːnɪti?",
        translation: "Is it a unique opportunity?",
      },
      {
        id: 25,
        expression: "Это тоже очень важно?",
        transcription: "ɪz ɪt ˈɔːlsəʊ ˈvɛri ɪmˈpɔːtənt?",
        translation: "Is it also very important?",
      },
      {
        id: 26,
        expression: "Это уникальный шанс для нас?",
        transcription: "ɪz ɪt ə juːˈniːk ʧɑːns fər ʌs?",
        translation: "Is it a unique chance for us?",
      },
      {
        id: 27,
        expression: "Тебе нравятся такого рода шутки?",
        transcription: "dʊ jʊ laɪk ðɪs kaɪnd əv ʤəʊks?",
        translation: "Do you like this kind of jokes?",
      },
      {
        id: 28,
        expression: "Эти билеты дешевые?",
        transcription: "ɑː ðiːz ˈtɪkɪts ʧiːp?",
        translation: "Are these tickets cheap?",
      },
      {
        id: 29,
        expression: "Это удобный диван?",
        transcription: "ɪz ɪt ə ˈkʌmf(ə)təbl ˈsəʊfə?",
        translation: "Is it a comfortable sofa?",
      },
      {
        id: 30,
        expression: "Ты тоже очень удивлен?",
        transcription: "ɑː jʊ ˈɔːlsəʊ ˈvɛri səˈpraɪzd?",
        translation: "Are you also very surprised?",
      },
      {
        id: 31,
        expression: "Они учатся в разных группах?",
        transcription: "dʊ ðeɪ ˈstʌdi ɪn ˈdɪfrənt gruːps?",
        translation: "Do they study in different groups?",
      },
      {
        id: 32,
        expression: "Ты уважаешь этого человека?",
        transcription: "dʊ jʊ rɪsˈpɛkt ðɪs ˈpɜːsn?",
        translation: "Do you respect this person?",
      },
      {
        id: 33,
        expression: "Они очень ленивые студенты?",
        transcription: "ɑː ðeɪ ˈvɛri ˈleɪzi ˈstjuːdənts?",
        translation: "Are they very lazy students?",
      },
      {
        id: 34,
        expression: "Ты часто опаздываешь?",
        transcription: "ɑː jʊ ˈɒf(ə)n leɪt?",
        translation: "Are you often late?",
      },
      {
        id: 35,
        expression: "Это правильный ответ?",
        transcription: "ɪz ɪt ðə kəˈrɛkt ˈɑːnsə? / ɪz ɪt ðə raɪt ˈɑːnsə?",
        translation: "Is it the correct answer? / Is it the right answer?",
      },
      {
        id: 36,
        expression: "Он очень усердно учится?",
        transcription: "dəz hi ˈstʌdi ˈvɛri hɑːd?",
        translation: "Does he study very hard?",
      },
      {
        id: 37,
        expression: "Это комфортная кровать?",
        transcription: "ɪz ɪt ə ˈkʌmf(ə)təbl bɛd?",
        translation: "Is it a comfortable bed?",
      },
      {
        id: 38,
        expression: "Она очень хорошо танцует?",
        transcription: "dəz ʃi dɑːns ˈvɛri wɛl?",
        translation: "Does she dance very well?",
      },
      {
        id: 39,
        expression: "Они мало стоят?",
        transcription: "dʊ ðeɪ kɒst ˈlɪtl?",
        translation: "Do they cost little?",
      },
      {
        id: 40,
        expression: "Вы в одной группе?",
        transcription: "ɑː jʊ ɪn wʌn gruːp?",
        translation: "Are you in one group?",
      },
      {
        id: 41,
        expression: "Ты уважаешь такого рода людей?",
        transcription: "dʊ jʊ rɪsˈpɛkt ðɪs kaɪnd əv ˈpiːpl?",
        translation: "Do you respect this kind of people?",
      },
      {
        id: 42,
        expression: "Это хороший супермаркет?",
        transcription: "ɪz ɪt ə gʊd ˈsjuːpəˌmɑːkɪt?",
        translation: "Is it a good supermarket?",
      },
      {
        id: 43,
        expression: "Это экономический кризис?",
        transcription: "ɪz ɪt ən ˌiːkəˈnɒmɪk ˈkraɪsɪs?",
        translation: "Is it an economic crisis?",
      },
      {
        id: 44,
        expression: "У него лучше результат?",
        transcription: "dəz hi həv ə ˈbɛtə rɪˈzʌlt?",
        translation: "Does he have a better result?",
      },
      {
        id: 45,
        expression: "Ты работаешь менеджером?",
        transcription: "dʊ jʊ wɜːk əz ə ˈmænɪʤə?",
        translation: "Do you work as a manager?",
      },
      {
        id: 46,
        expression: "Ты согласен, что это очень важно?",
        transcription: "dʊ jʊ əˈgriː ðət ɪts ˈvɛri ɪmˈpɔːtənt?",
        translation: "Do you agree that it's very important?",
      },
      {
        id: 47,
        expression: "Она зарабатывает достаточно денег, чтобы купить дом?",
        transcription: "dəz ʃi ɜːn ɪˈnʌf ˈmʌni tə baɪ ə haʊs?",
        translation: "Does she earn enough money to buy a house?",
      },
      {
        id: 48,
        expression: "Это бесплатная версия?",
        transcription: "ɪz ɪt ə friː ˈvɜːʃən?",
        translation: "Is it a free version?",
      },
      {
        id: 49,
        expression: "У тебя есть более стабильный доход?",
        transcription: "dʊ jʊ həv ə mɔː ˈsteɪbl ˈɪnkʌm?",
        translation: "Do you have a more stable income?",
      },
      {
        id: 50,
        expression: "Ты специально это делаешь?",
        transcription: "dʊ jʊ dʊ ɪt ɒn ˈpɜːpəs?",
        translation: "Do you do it on purpose?",
      },
      {
        id: 51,
        expression: "У тебя есть премиум аккаунт?",
        transcription: "dʊ jʊ həv ə ˈpriːmiəm əˈkaʊnt?",
        translation: "Do you have a premium account?",
      },
      {
        id: 52,
        expression: "Это более дорогой отель?",
        transcription: "ɪz ɪt ə mɔːr ɪksˈpɛnsɪv həʊˈtɛl?",
        translation: "Is it a more expensive hotel?",
      },
      {
        id: 53,
        expression: "Ты понимаешь, почему это происходит с нами?",
        transcription: "dʊ jʊ ˌʌndəˈstænd wɒt ɪt ˈhæpənz wɪð ʌs?",
        translation: "Do you understand what it happens with us?",
      },
      {
        id: 54,
        expression: "Это более важная вещь?",
        transcription: "ɪz ɪt ə mɔːr ɪmˈpɔːtənt θɪŋ?",
        translation: "Is it a more important thing?",
      },
      {
        id: 55,
        expression: "Ты показываешь более хороший результат?",
        transcription: "dʊ jʊ ʃəʊ ə ˈbɛtə rɪˈzʌlt?",
        translation: "Do you show a better result?",
      },
      {
        id: 56,
        expression: "У него более дорогая машина?",
        transcription: "dəz hi həv ə mɔːr ɪksˈpɛnsɪv kɑː?",
        translation: "Does he have a more expensive car?",
      },
      {
        id: 57,
        expression: "У тебя есть последняя модель?",
        transcription: "dʊ jʊ həv ðə ˈleɪtɪst ˈmɒdl?",
        translation: "Do you have the latest model?",
      },
      {
        id: 58,
        expression: "Это более хорошая машина?",
        transcription: "ɪz ɪt ə ˈbɛtə kɑː?",
        translation: "Is it a better car?",
      },
      {
        id: 59,
        expression: "Ты думаешь, это уникальная возможность?",
        transcription: "dʊ jʊ θɪŋk ɪts ə juːˈniːk ˌɒpəˈtjuːnɪti?",
        translation: "Do you think it's a unique opportunity?",
      },
      {
        id: 60,
        expression: "У тебя есть премиум версия?",
        transcription: "dʊ jʊ həv ə ˈpriːmiəm ˈvɜːʃən?",
        translation: "Do you have a premium version?",
      },
      {
        id: 61,
        expression: "Он делает это нарочно?",
        transcription: "dəz hi dʊ ɪt ɒn ˈpɜːpəs?",
        translation: "Does he do it on purpose?",
      },
      {
        id: 62,
        expression: "Они делают это нарочно?",
        transcription: "dʊ ðeɪ dʊ ɪt ɒn ˈpɜːpəs?",
        translation: "Do they do it on purpose?",
      },
      {
        id: 63,
        expression: "Она твоя младшая сестра?",
        transcription: "ɪz ʃi jə ˈjʌŋə ˈsɪstə?",
        translation: "Is she your younger sister?",
      },
      {
        id: 64,
        expression: "Он твой младший брат?",
        transcription: "ɪz hi jə ˈjʌŋə ˈbrʌðə?",
        translation: "Is he your younger brother?",
      },
      {
        id: 65,
        expression: "Он ее старший брат?",
        transcription: "ɪz hi hər ˈɛldə ˈbrʌðə?",
        translation: "Is he her elder brother?",
      },
      {
        id: 66,
        expression: "Это худший результат?",
        transcription: "ɪz ɪt ðə wɜːst rɪˈzʌlt?",
        translation: "Is it the worst result?",
      },
      {
        id: 67,
        expression: "Это лучший результат?",
        transcription: "ɪz ɪt ðə ˈbɛtə rɪˈzʌlt?",
        translation: "Is it the better result?",
      },
      {
        id: 68,
        expression: "Это более плохой результат?",
        transcription: "ɪz ɪt ə wɜːs rɪˈzʌlt?",
        translation: "Is it a worse result?",
      },
      {
        id: 69,
        expression: "Ты обычно останавливаешься в более дешевом отеле?",
        transcription: "dʊ jʊ ˈjuːʒʊəli steɪ ɪn ə ˈʧiːpə həʊˈtɛl?",
        translation: "Do you usually stay in a cheaper hotel?",
      },
      {
        id: 70,
        expression: "Он обычно останавливается в самом дорогом отеле?",
        transcription: "dəz hi ˈjuːʒʊəli steɪ ɪn ðə məʊst ɪksˈpɛnsɪv həʊˈtɛl?",
        translation: "Does he usually stay in the most expensive hotel?",
      },
      {
        id: 71,
        expression: "Они часто останавливаются в более дорогом отеле?",
        transcription: "dʊ ðeɪ ˈɒf(ə)n steɪ ɪn ə mɔːr ɪksˈpɛnsɪv həʊˈtɛl?",
        translation: "Do they often stay in a more expensive hotel?",
      },
      {
        id: 72,
        expression: "Это более дешевый отель?",
        transcription: "ɪz ɪt ə ˈʧiːpə həʊˈtɛl?",
        translation: "Is it a cheaper hotel?",
      },
      {
        id: 73,
        expression: "Это самый дешевый отель?",
        transcription: "ɪz ɪt ðə ˈʧiːpɪst həʊˈtɛl?",
        translation: "Is it the cheapest hotel?",
      },
      {
        id: 74,
        expression: "У него хуже результат?",
        transcription: "dəz hi həv ə wɜːs rɪˈzʌlt?",
        translation: "Does he have a worse result?",
      },
      {
        id: 75,
        expression: "У тебя хуже результат?",
        transcription: "dʊ jʊ həv ə wɜːs rɪˈzʌlt?",
        translation: "Do you have a worse result?",
      },
      {
        id: 76,
        expression: "У нее худший результат?",
        transcription: "dəz ʃi həv ðə wɜːst rɪˈzʌlt?",
        translation: "Does she have the worst result?",
      },
      {
        id: 77,
        expression: "Ты вегетарианец?",
        transcription: "ɑː jʊ ə ˌvɛʤɪˈteərɪən?",
        translation: "Are you a vegetarian?",
      },
      {
        id: 78,
        expression: "Они вегетарианцы?",
        transcription: "ɑː ðeɪ ˌvɛʤɪˈteərɪənz?",
        translation: "Are they vegetarians?",
      },
      {
        id: 79,
        expression: "Она вегетарианка?",
        transcription: "ɪz ʃi ə ˌvɛʤɪˈteərɪən?",
        translation: "Is she a vegetarian?",
      },
      {
        id: 80,
        expression: "Он понимает, почему это происходит с ним?",
        transcription: "dəz hi ˌʌndəˈstænd waɪ ɪt ˈhæpənz tə hɪm?",
        translation: "Does he understand why it happens to him?",
      },
      {
        id: 81,
        expression: "Ты понимаешь, почему это происходит с тобой?",
        transcription: "dʊ jʊ ˌʌndəˈstænd waɪ ɪt ˈhæpənz tə juː?",
        translation: "Do you understand why it happens to you?",
      },
      {
        id: 82,
        expression: "Она понимает, почему это происходит с ней?",
        transcription: "dəz ʃi ˌʌndəˈstænd waɪ ɪt ˈhæpənz tə hɜː?",
        translation: "Does she understand why it happens to her?",
      },
      {
        id: 83,
        expression: "Это последняя модель?",
        transcription: "ɪz ɪt ðə ˈleɪtɪst ˈmɒdl?",
        translation: "Is it the latest model?",
      },
      {
        id: 84,
        expression: "Это новая модель?",
        transcription: "ɪz ɪt ə njuː ˈmɒdl?",
        translation: "Is it a new model?",
      },
      {
        id: 85,
        expression: "Это лучшая модель?",
        transcription: "ɪz ɪt ðə bɛst ˈmɒdl?",
        translation: "Is it the best model?",
      },
      {
        id: 86,
        expression: "Это бесплатная программа?",
        transcription: "ɪz ɪt ə friː ˈprəʊgræm?",
        translation: "Is it a free program?",
      },
      {
        id: 87,
        expression: "Это дешевая программа?",
        transcription: "ɪz ɪt ə ʧiːp ˈprəʊgræm?",
        translation: "Is it a cheap program?",
      },
      {
        id: 88,
        expression: "Это новая программа?",
        transcription: "ɪz ɪt ə njuː ˈprəʊgræm?",
        translation: "Is it a new program?",
      },
      {
        id: 89,
        expression: "Ты используешь премиум аккаунт?",
        transcription: "dʊ jʊ juːz ə ˈpriːmiəm əˈkaʊnt?",
        translation: "Do you use a premium account?",
      },
      {
        id: 90,
        expression: "Они специально все портят?",
        transcription: "dʊ ðeɪ spɔːɪl ˈɛvrɪθɪŋ ɒn ˈpɜːpəs?",
        translation: "Do they spoil everything on purpose?",
      },
      {
        id: 91,
        expression: "Ты тоже видишь, что время действительно летит?",
        transcription: "dʊ jʊ ˈɔːlsəʊ siː ðət taɪm ˈrɪəli flaɪz?",
        translation: "Do you also see that time really flies?",
      },
      {
        id: 92,
        expression: "У него лучше знания?",
        transcription: "dəz hi həv ˈbɛtə ˈnɒlɪʤ?",
        translation: "Does he have better knowledge?",
      },
      {
        id: 93,
        expression: "Он вегетарианец?",
        transcription: "ɪz hi ə ˌvɛʤɪˈteərɪən?",
        translation: "Is he a vegetarian?",
      },
      {
        id: 94,
        expression: "Ты думаешь, это большой успех?",
        transcription: "dʊ jʊ θɪŋk ɪts ə bɪg səkˈsɛs?",
        translation: "Do you think it's a big success?",
      },
      {
        id: 95,
        expression: "У нее лучше машина?",
        transcription: "dəz ʃi həv ə ˈbɛtə kɑː?",
        translation: "Does she have a better car?",
      },
      {
        id: 96,
        expression: "У них хуже результаты?",
        transcription: "dʊ ðeɪ həv wɜːs rɪˈzʌlts?",
        translation: "Do they have worse results?",
      },
      {
        id: 97,
        expression: "Это премиум версия?",
        transcription: "ɪz ɪt ə ˈpriːmiəm ˈvɜːʃən?",
        translation: "Is it a premium version?",
      },
      {
        id: 98,
        expression: "У него достаточно денег, чтобы купить квартиру?",
        transcription: "dəz hi həv ɪˈnʌf ˈmʌni tə baɪ ən əˈpɑːtmənt?",
        translation: "Does he have enough money to buy an apartment?",
      },
      {
        id: 99,
        expression: "Это более хороший результат?",
        transcription: "ɪz ɪt ə ˈbɛtə rɪˈzʌlt?",
        translation: "Is it a better result?",
      },
      {
        id: 100,
        expression: "Она твоя старшая сестра?",
        transcription: "ɪz ʃi jər ˈɛldə ˈsɪstə?",
        translation: "Is she your elder sister?",
      },
      {
        id: 101,
        expression: "Он уважает эти традиции?",
        transcription: "dəz hi rɪsˈpɛkt ðiːz trəˈdɪʃənz?",
        translation: "Does he respect these traditions?",
      },
      {
        id: 102,
        expression: "Они портят еду?",
        transcription: "dʊ ðeɪ spɔːɪl fuːd?",
        translation: "Do they spoil food?",
      },
      {
        id: 103,
        expression: "Ты используешь бесплатную версию?",
        transcription: "dʊ jʊ juːz ə friː ˈvɜːʃən?",
        translation: "Do you use a free version?",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Закрепление пройденного материала</h3>
</div>`,
    },
  ],
];
