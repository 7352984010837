export const contentLesson26 = [
  [
    {
      title: "Урок 26. Future Simple. Negative Form",
    },
    [
      {
        id: 1,
        expression: "Мы не будем жить в том доме",
        transcription: "wi wəʊnt lɪv ɪn ðət haʊs",
        translation: "We won't live in that house",
      },
      {
        id: 2,
        expression: "Я не буду делать те упражнения",
        transcription: "aɪ wəʊnt dʊ ðəʊz ˈɛksəsaɪzɪz",
        translation: "I won't do those exercises",
      },
      {
        id: 3,
        expression: "Это не будет ошибкой",
        transcription: "ɪt wəʊnt bi ə mɪsˈteɪk",
        translation: "It won't be a mistake",
      },
      {
        id: 4,
        expression: "Это не будет интересно ему",
        transcription: "ɪt wəʊnt bi ˈɪntrɪstɪŋ fə hɪm",
        translation: "It won't be interesting for him",
      },
      {
        id: 5,
        expression: "Он не узнает этого",
        transcription: "hi wəʊnt nəʊ əˈbaʊt ɪt",
        translation: "He won't know about it",
      },
      {
        id: 6,
        expression: "Мне не понадобится это",
        transcription: "aɪ wəʊnt niːd ɪt",
        translation: "I won't need it",
      },
      {
        id: 7,
        expression: "Я не буду делать эти упражнения",
        transcription: "aɪ wəʊnt dʊ ðiːz ˈɛksəsaɪzɪz",
        translation: "I won't do these exercises",
      },
      {
        id: 8,
        expression: "Мы не будем жить в том доме",
        transcription: "wi wəʊnt lɪv ɪn ðət haʊs",
        translation: "We won't live in that house",
      },
      {
        id: 9,
        expression: "Я не буду жить в этой квартире",
        transcription: "aɪ wəʊnt lɪv ɪn ðɪs əˈpɑːtmənt",
        translation: "I won't live in this apartment",
      },
      {
        id: 10,
        expression: "Это не будет ошибкой",
        transcription: "ɪt wəʊnt bi ə mɪsˈteɪk",
        translation: "It won't be a mistake",
      },
      {
        id: 11,
        expression: "Тебе не понравится эта идея",
        transcription: "jʊ wəʊnt laɪk ðɪs aɪˈdɪə",
        translation: "You won't like this idea",
      },
      {
        id: 12,
        expression: "Это не будет так дешево",
        transcription: "ɪt wəʊnt bi səʊ ʧiːp",
        translation: "It won't be so cheap",
      },
      {
        id: 13,
        expression: "Я не буду парковаться здесь",
        transcription: "aɪ wəʊnt ˈpɑːkɪŋ hɪə",
        translation: "I won't parking here",
      },
      {
        id: 14,
        expression: "Ты не увидишь это сейчас",
        transcription: "jʊ wəʊnt siː ɪt naʊ",
        translation: "You won't see it now",
      },
      {
        id: 15,
        expression: "Он не пойдет на работу завтра",
        transcription: "hi wəʊnt gəʊ tə wɜːk təˈmɒrəʊ",
        translation: "He won't go to work tomorrow",
      },
      {
        id: 16,
        expression: "У меня не будет много свободного времени",
        transcription: "aɪ wəʊnt həv mʌʧ friː taɪm",
        translation: "I won't have much free time",
      },
      {
        id: 17,
        expression: "Тебе не понравится ее новое платье",
        transcription: "jʊ wəʊnt laɪk hə njuː drɛs",
        translation: "You won't like her new dress",
      },
      {
        id: 18,
        expression: "Это тебе не сильно поможет",
        transcription: "ɪt wəʊnt hɛlp jʊ mʌʧ",
        translation: "It won't help you much",
      },
      {
        id: 19,
        expression: "Они не пойдут в театр",
        transcription: "ðeɪ wəʊnt gəʊ tə ðə ˈθɪətə",
        translation: "They won't go to the theatre",
      },
      {
        id: 20,
        expression: "Это не будет правильно",
        transcription: "ɪt wəʊnt bi raɪt",
        translation: "It won't be right",
      },
      {
        id: 21,
        expression: "Я не буду покупать машину",
        transcription: "aɪ wəʊnt baɪ ə kɑː",
        translation: "I won't buy a car",
      },
      {
        id: 22,
        expression: "Я не скажу ему эту вещь",
        transcription: "aɪ wəʊnt tɛl ɪm ðɪs θɪŋ",
        translation: "I won't tell him this thing",
      },
      {
        id: 23,
        expression: "Она не согласится",
        transcription: "ʃi wəʊnt əˈgriː",
        translation: "She won't agree",
      },
      {
        id: 24,
        expression: "Они не будут играть в теннис",
        transcription: "ðeɪ wəʊnt pleɪ ˈtɛnɪs",
        translation: "They won't play tennis",
      },
      {
        id: 25,
        expression: "Это не будет слишком дорого",
        transcription: "ɪt wəʊnt bi tuː ɪksˈpɛnsɪv",
        translation: "It won't be too expensive",
      },
      {
        id: 26,
        expression: "Это не принесет ему хороших денег",
        transcription: "ɪt wəʊnt brɪŋ ɪm gʊd ˈmʌni",
        translation: "It won't bring him good money",
      },
      {
        id: 27,
        expression: "Мы не будем играть в эту игру",
        transcription: "wi wəʊnt pleɪ ðɪs geɪm",
        translation: "We won't play this game",
      },
      {
        id: 28,
        expression: "Это не поможет тебе в этой ситуации",
        transcription: "ɪt wəʊnt hɛlp jʊ ɪn ðɪs ˌsɪtjʊˈeɪʃən",
        translation: "It won't help you in this situation",
      },
      {
        id: 29,
        expression: "Я не скажу это",
        transcription: "aɪ wəʊnt seɪ ɪt",
        translation: "I won't say it",
      },
      {
        id: 30,
        expression: "Я не пойду в университет завтра",
        transcription: "aɪ wəʊnt gəʊ tə ˌjuːnɪˈvɜːsɪti təˈmɒrəʊ",
        translation: "I won't go to university tomorrow",
      },
      {
        id: 31,
        expression: "Это не будет стоить меньше",
        transcription: "ɪt wəʊnt kɒst lɛs",
        translation: "It won't cost less",
      },
      {
        id: 32,
        expression: "Я не буду покупать эти билеты",
        transcription: "aɪ wəʊnt baɪ ðiːz ˈtɪkɪts",
        translation: "I won't buy these tickets",
      },
      {
        id: 33,
        expression: "Мы не поедем в Испанию в этом году",
        transcription: "wi wəʊnt gəʊ tə speɪn ðɪs jɪə",
        translation: "We won't go to Spain this year",
      },
      {
        id: 34,
        expression: "Я не куплю этот мобильный телефон",
        transcription: "aɪ wəʊnt baɪ ðɪs sɛl (ˈməʊbaɪl fəʊn)",
        translation: "I won't buy this cell (mobile phone)",
      },
      {
        id: 35,
        expression: "Я не буду свободен",
        transcription: "aɪ wəʊnt bi friː",
        translation: "I won't be free",
      },
      {
        id: 36,
        expression: "Он не поймет меня",
        transcription: "hi wəʊnt ˌʌndəˈstænd miː",
        translation: "He won't understand me",
      },
      {
        id: 37,
        expression: "Меня не будет там завтра",
        transcription: "aɪ wəʊnt bi ðeə təˈmɒrəʊ",
        translation: "I won't be there tomorrow",
      },
      {
        id: 38,
        expression: "Это не будет стоить меньше",
        transcription: "ɪt wəʊnt kɒst lɛs",
        translation: "It won't cost less",
      },
      {
        id: 39,
        expression: "Мы не будем говорить об этом",
        transcription: "wi wəʊnt tɔːk əˈbaʊt ɪt",
        translation: "We won't talk about it",
      },
      {
        id: 40,
        expression: "Мне не нужна будет твоя помощь",
        transcription: "aɪ wəʊnt niːd jə hɛlp",
        translation: "I won't need your help",
      },
      {
        id: 41,
        expression: "Это не поможет тебе",
        transcription: "ɪt wəʊnt hɛlp juː",
        translation: "It won't help you",
      },
      {
        id: 42,
        expression: "Я не пойду в ресторан",
        transcription: "aɪ wəʊnt gəʊ tə ðə ˈrɛstrɒnt",
        translation: "I won't go to the restaurant",
      },
      {
        id: 43,
        expression: "Они не поймут это",
        transcription: "ðeɪ wəʊnt ˌʌndəˈstænd ɪt",
        translation: "They won't understand it",
      },
      {
        id: 44,
        expression: "Я не буду в офисе завтра",
        transcription: "aɪ wəʊnt bi ɪn ði ˈɒfɪs təˈmɒrəʊ",
        translation: "I won't be in the office tomorrow",
      },
      {
        id: 45,
        expression: "Ситуация не изменится немедленно",
        transcription: "ðə ˌsɪtjʊˈeɪʃən wəʊnt ʧeɪnʤ ɪˈmiːdiətli",
        translation: "The situation won't change immediately",
      },
      {
        id: 46,
        expression: "Это не будет лучшим выбором",
        transcription: "ɪt wəʊnt bi ðə bɛst ʧɔɪs",
        translation: "It won't be the best choice",
      },
      {
        id: 47,
        expression: "Я не пойду в супермаркет",
        transcription: "aɪ wəʊnt gəʊ tə ðə ˈsjuːpəˌmɑːkɪt",
        translation: "I won't go to the supermarket",
      },
      {
        id: 48,
        expression: "У меня не будет времени на это",
        transcription: "aɪ wəʊnt həv taɪm fər ɪt",
        translation: "I won't have time for it",
      },
      {
        id: 49,
        expression: "Мы не пойдем в магазин прямо сейчас",
        transcription: "wi wəʊnt gəʊ tə ðə ʃɒp raɪt naʊ",
        translation: "We won't go to the shop right now",
      },
      {
        id: 50,
        expression: "Я думаю, это не случится с нами",
        transcription: "aɪ θɪŋk ɪt wəʊnt ˈhæpən wɪð ʌs",
        translation: "I think it won't happen with us",
      },
      {
        id: 51,
        expression: "Он не придет к нам завтра",
        transcription: "hi wəʊnt kʌm tʊ əs təˈmɒrəʊ",
        translation: "He won't come to us tomorrow",
      },
      {
        id: 52,
        expression: "Мне кажется, она не откажется помочь нам",
        transcription: "ɪt siːmz tə mi ʃi wəʊnt ˌriːˈfjuːz tə hɛlp ʌs",
        translation: "It seems to me she won't refuse to help us",
      },
      {
        id: 53,
        expression: "Мы не пойдем в ресторан сегодня вечером",
        transcription: "wi wəʊnt gəʊ tə ðə ˈrɛstrɒnt təˈnaɪt",
        translation: "We won't go to the restaurant tonight",
      },
      {
        id: 54,
        expression: "Я не пойду на эту вечеринку",
        transcription: "aɪ wəʊnt gəʊ tə ðɪs ˈpɑːti",
        translation: "I won't go to this party",
      },
      {
        id: 55,
        expression: "Тот проект не будет таким успешным",
        transcription: "ðæt ˈprɒʤɛkt wəʊnt bi səʊ səkˈsɛsfʊl",
        translation: "That project won't be so successful",
      },
      {
        id: 56,
        expression: "Мы не пойдем в тот магазин",
        transcription: "wi wəʊnt gəʊ tə ðət ʃɒp",
        translation: "We won't go to that shop",
      },
      {
        id: 57,
        expression: "Это не изменится немедленно",
        transcription: "ɪt wəʊnt ʧeɪnʤ ɪˈmiːdiətli",
        translation: "It won't change immediately",
      },
      {
        id: 58,
        expression: "Я думаю, что он не согласится сделать это",
        transcription: "aɪ θɪŋk ðət hi wəʊnt əˈgriː tə dʊ ɪt",
        translation: "I think that he won't agree to do it",
      },
      {
        id: 59,
        expression: "Я думаю, он не согласится сделать это",
        transcription: "aɪ θɪŋk hi wəʊnt əˈgriː tə dʊ ɪt",
        translation: "I think he won't agree to do it",
      },
      {
        id: 60,
        expression: "Он не заплатит за это",
        transcription: "hi wəʊnt peɪ fər ɪt",
        translation: "He won't pay for it",
      },
      {
        id: 61,
        expression: "Она не будет платить за меня",
        transcription: "ʃi wəʊnt peɪ fə miː",
        translation: "She won't pay for me",
      },
      {
        id: 62,
        expression: "Его не будет в офисе завтра",
        transcription: "hi wəʊnt bi ɪn ði ˈɒfɪs təˈmɒrəʊ",
        translation: "He won't be in the office tomorrow",
      },
      {
        id: 63,
        expression: "Ее не будет в офисе завтра",
        transcription: "ʃi wəʊnt bi ɪn ði ˈɒfɪs təˈmɒrəʊ",
        translation: "She won't be in the office tomorrow",
      },
      {
        id: 64,
        expression: "Меня не будет на работе послезавтра",
        transcription: "aɪ wəʊnt bi ət wɜːk ðə deɪ ˈɑːftə təˈmɒrəʊ",
        translation: "I won't be at work the day after tomorrow",
      },
      {
        id: 65,
        expression: "Это не будет лучшим решением",
        transcription: "ɪt wəʊnt bi ðə bɛst dɪˈsɪʒən",
        translation: "It won't be the best decision",
      },
      {
        id: 66,
        expression: "Это не будет важным решением",
        transcription: "ɪt wəʊnt bi ən ɪmˈpɔːtənt dɪˈsɪʒən",
        translation: "It won't be an important decision",
      },
      {
        id: 67,
        expression: "Я не буду покупать такого рода вещи",
        transcription: "aɪ wəʊnt baɪ ðɪs kaɪnd əv θɪŋz",
        translation: "I won't buy this kind of things",
      },
      {
        id: 68,
        expression: "Я не куплю эти вещи",
        transcription: "aɪ wəʊnt baɪ ðiːz θɪŋz",
        translation: "I won't buy these things",
      },
      {
        id: 69,
        expression: "Он думает, у меня не будет много времени на это",
        transcription: "hi θɪŋks aɪ wəʊnt həv mʌʧ taɪm ɒn ɪt",
        translation: "He thinks I won't have much time on it",
      },
      {
        id: 70,
        expression: "Она думает, у нее не будет много времени на это",
        transcription: "ʃi θɪŋks ʃi wəʊnt həv mʌʧ taɪm ɒn ɪt",
        translation: "She thinks she won't have much time on it",
      },
      {
        id: 71,
        expression: "Они думают, у них не будет много времени на это",
        transcription: "ðeɪ θɪŋk ðeɪ wəʊnt həv mʌʧ taɪm ɒn ɪt",
        translation: "They think they won't have much time on it",
      },
      {
        id: 72,
        expression: "Меня не будет в университете завтра",
        transcription: "aɪ wəʊnt bi ət ˌjuːnɪˈvɜːsɪti təˈmɒrəʊ",
        translation: "I won't be at university tomorrow",
      },
      {
        id: 73,
        expression: "Меня не будет в офисе завтра",
        transcription: "aɪ wəʊnt bi ɪn ði ˈɒfɪs təˈmɒrəʊ",
        translation: "I won't be in the office tomorrow",
      },
      {
        id: 74,
        expression: "Мне кажется, она не поймет этого",
        transcription: "ɪt siːmz tə mi ʃi wəʊnt ˌʌndəˈstænd ɪt",
        translation: "It seems to me she won't understand it",
      },
      {
        id: 75,
        expression: "Ей кажется, я этого не пойму",
        transcription: "ɪt siːmz tə hər aɪ wəʊnt ˌʌndəˈstænd ɪt",
        translation: "It seems to her I won't understand it",
      },
      {
        id: 76,
        expression: "Ее не будет в школе сегодня",
        transcription: "ʃi wəʊnt bi ət skuːl təˈdeɪ",
        translation: "She won't be at school today",
      },
      {
        id: 77,
        expression: "Меня не будет в школе завтра",
        transcription: "aɪ wəʊnt bi ət skuːl təˈmɒrəʊ",
        translation: "I won't be at school tomorrow",
      },
      {
        id: 78,
        expression: "Ее не будет на работе сегодня",
        transcription: "ʃi wəʊnt bi ət wɜːk təˈdeɪ",
        translation: "She won't be at work today",
      },
      {
        id: 79,
        expression: "Я думаю, что ему не понравится это предложение",
        transcription: "aɪ θɪŋk ðət hi wəʊnt laɪk ðɪs ˈɒfə",
        translation: "I think that he won't like this offer",
      },
      {
        id: 80,
        expression: "Мне кажется, ему не понравится это предложение",
        transcription: "ɪt siːmz tə mi hi wəʊnt laɪk ðɪs ˈɒfə",
        translation: "It seems to me he won't like this offer",
      },
      {
        id: 81,
        expression: "Я не пойду в этот супермаркет",
        transcription: "aɪ wəʊnt gəʊ tə ðɪs ˈsjuːpəˌmɑːkɪt",
        translation: "I won't go to this supermarket",
      },
      {
        id: 82,
        expression: "Я думаю, он не согласится",
        transcription: "aɪ θɪŋk hi wəʊnt əˈgriː",
        translation: "I think he won't agree",
      },
      {
        id: 83,
        expression: "Я не буду тратить свое время на эти вещи",
        transcription: "aɪ wəʊnt spɛnd maɪ taɪm ɒn ðiːz θɪŋz",
        translation: "I won't spend my time on these things",
      },
      {
        id: 84,
        expression: "Я думаю, она не сдаст этот тест",
        transcription: "aɪ θɪŋk ʃi wəʊnt pɑːs ðɪs tɛst",
        translation: "I think she won't pass this test",
      },
      {
        id: 85,
        expression: "Она не согласится сделать это",
        transcription: "ʃi wəʊnt əˈgriː tə dʊ ɪt",
        translation: "She won't agree to do it",
      },
      {
        id: 86,
        expression: "Я думаю, он не откажется сделать это",
        transcription: "aɪ θɪŋk hi wəʊnt ˌriːˈfjuːz tə dʊ ɪt",
        translation: "I think he won't refuse to do it",
      },
      {
        id: 87,
        expression: "Я не пойду в кино сегодня вечером",
        transcription: "aɪ wəʊnt gəʊ tə ðə ˈsɪnəmə təˈnaɪt",
        translation: "I won't go to the cinema tonight",
      },
      {
        id: 88,
        expression: "Он не откажется сделать это",
        transcription: "hi wəʊnt ˌriːˈfjuːz tə dʊ ɪt",
        translation: "He won't refuse to do it",
      },
      {
        id: 89,
        expression: "Это не будет то же самое",
        transcription: "ɪt wəʊnt bi ðə seɪm",
        translation: "It won't be the same",
      },
      {
        id: 90,
        expression: "Я думаю, что он не сдаст этот экзамен",
        transcription: "aɪ θɪŋk ðət hi wəʊnt pɑːs ðɪs ɪgˈzæm",
        translation: "I think that he won't pass this exam",
      },
      {
        id: 91,
        expression: "Я не буду платить за это",
        transcription: "aɪ wəʊnt peɪ fər ɪt",
        translation: "I won't pay for it",
      },
      {
        id: 92,
        expression: "Он думает, что это не будет ошибкой",
        transcription: "hi θɪŋks ðət ɪt wəʊnt bi ə mɪsˈteɪk",
        translation: "He thinks that it won't be a mistake",
      },
      {
        id: 93,
        expression: "Я думаю, она не подпишет те документы",
        transcription: "aɪ θɪŋk ʃi wəʊnt saɪn ðəʊz ˈdɒkjʊmənts",
        translation: "I think she won't sign those documents",
      },
      {
        id: 94,
        expression: "Этот проект не будет успешным",
        transcription: "ðɪs ˈprɒʤɛkt wəʊnt bi səkˈsɛsfʊl",
        translation: "This project won't be successful",
      },
      {
        id: 95,
        expression: "Он не подпишет этот документ",
        transcription: "hi wəʊnt saɪn ðɪs ˈdɒkjʊmənt",
        translation: "He won't sign this document",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Отрицание в Future Simple</h3>
<p>Образуется путем добавления отрицательной частицы "will not" или сокращенной формы "won't" после вспомогательного глагола "will". Например:</p>
<p>Утверждение: I will go to the store - Я пойду в магазин.</p>
<p>Отрицание: I will not (won't) go to the store - Я не пойду в магазин.</p>
<p>Еще пример: It won't be a big mistake - Это не будет большой ошибкой.</p>
</div>`,
    },
  ],
];
