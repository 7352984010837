export const contentLesson82 = [
  [
    {
      title: "Урок 82. Past Simple. Negative Form",
    },
    [
      {
        id: 1,
        expression: "Он не ел рис",
        transcription: "hi dɪdnt iːt raɪs",
        translation: "He didn't eat rice",
      },
      {
        id: 2,
        expression: "Мой брат не помог мне",
        transcription: "maɪ ˈbrʌðə dɪdnt hɛlp miː",
        translation: "My brother didn't help me",
      },
      {
        id: 3,
        expression: "Я не был дома вчера",
        transcription: "aɪ wɒznt ət həʊm ˈjɛstədeɪ",
        translation: "I wasn't at home yesterday",
      },
      {
        id: 4,
        expression: "Они не были со мной в тот момент",
        transcription: "ðeɪ wɜːnt wɪð mi ət ðət ˈməʊmənt",
        translation: "They weren't with me at that moment",
      },
      {
        id: 5,
        expression: "Этих денег было недостаточно",
        transcription: "ðɪs ˈmʌni wɒznt ɪˈnʌf",
        translation: "This money wasn't enough",
      },
      {
        id: 6,
        expression: "Мальчики не были уставшими",
        transcription: "ðə bɔɪz wɜːnt ˈtaɪəd",
        translation: "The boys weren't tired",
      },
      {
        id: 7,
        expression: "Посылка не была тяжелой",
        transcription: "ðə ˈpɑːsl wɒznt ˈhɛvi",
        translation: "The parcel wasn't heavy",
      },
      {
        id: 8,
        expression: "Она не могла ответить на мой звонок",
        transcription: "ʃi ˈkʊdnt ˈɑːnsə maɪ kɔːl",
        translation: "She couldn't answer my call",
      },
      {
        id: 9,
        expression: "Он не окончил университет",
        transcription: "hi dɪdnt ˈgrædjʊət frəm ˌjuːnɪˈvɜːsɪti",
        translation: "He didn't graduate from university",
      },
      {
        id: 10,
        expression: "Я не был на работе вчера",
        transcription: "aɪ wɒznt ət wɜːk ˈjɛstədeɪ",
        translation: "I wasn't at work yesterday",
      },
      {
        id: 11,
        expression: "Квартира была недостаточно светлой",
        transcription: "ði əˈpɑːtmənt wɒznt laɪt ɪˈnʌf",
        translation: "The apartment wasn't light enough",
      },
      {
        id: 12,
        expression: "Этих денег было недостаточно",
        transcription: "ðɪs ˈmʌni wɒznt ɪˈnʌf",
        translation: "This money wasn't enough",
      },
      {
        id: 13,
        expression: "Она не хотела стать учителем",
        transcription: "ʃi dɪdnt wɒnt tə bɪˈkʌm ə ˈtiːʧə",
        translation: "She didn't want to become a teacher",
      },
      {
        id: 14,
        expression: "Мы не тратили время впустую",
        transcription: "wi dɪdnt weɪst taɪm",
        translation: "We didn't waste time",
      },
      {
        id: 15,
        expression: "Они не пошли на пляж",
        transcription: "ðeɪ dɪdnt gəʊ tə ðə biːʧ",
        translation: "They didn't go to the beach",
      },
      {
        id: 16,
        expression: "Эта компания не принадлежала ему",
        transcription: "ðɪs ˈkʌmpəni dɪdnt bɪˈlɒŋ tə hɪm",
        translation: "This company didn't belong to him",
      },
      {
        id: 17,
        expression: "Они не хотели переезжать в другую страну",
        transcription: "ðeɪ dɪdnt wɒnt tə muːv tʊ əˈnʌðə ˈkʌntri",
        translation: "They didn't want to move to another country",
      },
      {
        id: 18,
        expression: "Это было совсем не дорого",
        transcription: "ɪt wɒznt ɪksˈpɛnsɪv ət ɔːl",
        translation: "It wasn't expensive at all",
      },
      {
        id: 19,
        expression: "Он не жил в Соединенных Штатах",
        transcription: "hi dɪdnt lɪv ɪn ðə jʊˈnaɪtɪd steɪts",
        translation: "He didn't live in the United States",
      },
      {
        id: 20,
        expression: "Ее не было в университете",
        transcription: "ʃi wɒznt ət ˌjuːnɪˈvɜːsɪti",
        translation: "She wasn't at university",
      },
      {
        id: 21,
        expression: "Мы не ходили на море вчера",
        transcription: "wi dɪdnt gəʊ tə ðə siː ˈjɛstədeɪ",
        translation: "We didn't go to the sea yesterday",
      },
      {
        id: 22,
        expression: "Поездка была совсем не утомительной",
        transcription: "ðə trɪp wɒznt ˈtaɪərɪŋ ət ɔːl",
        translation: "The trip wasn't tiring at all",
      },
      {
        id: 23,
        expression: "Я не хотел платить за все",
        transcription: "aɪ dɪdnt wɒnt tə peɪ fər ˈɛvrɪθɪŋ",
        translation: "I didn't want to pay for everything",
      },
      {
        id: 24,
        expression: "Того времени казалось недостаточно",
        transcription: "ðæt taɪm dɪdnt siːm ɪˈnʌf",
        translation: "That time didn't seem enough",
      },
      {
        id: 25,
        expression: "Этой практики мне не было достаточно",
        transcription: "ðɪs ˈpræktɪs wɒznt ɪˈnʌf fə miː",
        translation: "This practice wasn't enough for me",
      },
      {
        id: 26,
        expression: "Он не ел рис",
        transcription: "hi dɪdnt iːt raɪs",
        translation: "He didn't eat rice",
      },
      {
        id: 27,
        expression: "Это не имело значения",
        transcription: "ɪt dɪdnt ˈmætə",
        translation: "It didn't matter",
      },
      {
        id: 28,
        expression: "Рис не был свежим",
        transcription: "ðə raɪs wɒznt frɛʃ",
        translation: "The rice wasn't fresh",
      },
      {
        id: 29,
        expression: "Стиль не был современным",
        transcription: "ðə staɪl wɒznt ˈmɒdən",
        translation: "The style wasn't modern",
      },
      {
        id: 30,
        expression: "Билеты не были дешевыми",
        transcription: "ðə ˈtɪkɪts wɜːnt ʧiːp",
        translation: "The tickets weren't cheap",
      },
      {
        id: 31,
        expression: "Эта вещь не имела значения",
        transcription: "ðɪs θɪŋ dɪdnt ˈmætə",
        translation: "This thing didn't matter",
      },
      {
        id: 32,
        expression: "Музыкант не был богатым",
        transcription: "ðə mju(ː)ˈzɪʃən wɒznt rɪʧ",
        translation: "The musician wasn't rich",
      },
      {
        id: 33,
        expression: "Ему не нужна была какая-либо помощь",
        transcription: "hi dɪdnt niːd ˈɛni hɛlp",
        translation: "He didn't need any help",
      },
      {
        id: 34,
        expression: "Вирус не был опасным",
        transcription: "ðə ˈvaɪərəs wɒznt ˈdeɪnʤrəs",
        translation: "The virus wasn't dangerous",
      },
      {
        id: 35,
        expression: "Сумка не была пустой",
        transcription: "ðə bæg wɒznt ˈɛmpti",
        translation: "The bag wasn't empty",
      },
      {
        id: 36,
        expression: "Он не был на той вечеринке",
        transcription: "hi wɒznt ət ðət ˈpɑːti",
        translation: "He wasn't at that party",
      },
      {
        id: 37,
        expression: "Я не пошел на работу вчера",
        transcription: "aɪ dɪdnt gəʊ tə wɜːk ˈjɛstədeɪ",
        translation: "I didn't go to work yesterday",
      },
      {
        id: 38,
        expression: "Ребенок не закончил свою домашнюю работу",
        transcription: "ðə ʧaɪld dɪdnt ˈfɪnɪʃ ɪz ˈhəʊmˌwɜːk",
        translation: "The child didn't finish his homework",
      },
      {
        id: 39,
        expression: "Они не были на пляже",
        transcription: "ðeɪ wɜːnt ət ðə biːʧ",
        translation: "They weren't at the beach",
      },
      {
        id: 40,
        expression: "У меня совсем не было времени",
        transcription: "aɪ dɪdnt həv taɪm ət ɔːl",
        translation: "I didn't have time at all",
      },
      {
        id: 41,
        expression: "Он не пришел на ту вечеринку",
        transcription: "hi dɪdnt kʌm tə ðɪs ˈpɑːti",
        translation: "He didn't come to this party",
      },
      {
        id: 42,
        expression: "Это не было важно для меня",
        transcription: "ɪt wɒznt ɪmˈpɔːtənt fə miː",
        translation: "It wasn't important for me",
      },
      {
        id: 43,
        expression: "Я не платил за свое образование",
        transcription: "aɪ dɪdnt peɪ fə maɪ ˌɛdju(ː)ˈkeɪʃən",
        translation: "I didn't pay for my education",
      },
      {
        id: 44,
        expression: "Он не нашел новую работу",
        transcription: "hi dɪdnt faɪnd ə njuː ʤɒb",
        translation: "He didn't find a new job",
      },
      {
        id: 45,
        expression: "Я не был в Соединенных Штатах в прошлом году",
        transcription: "aɪ wɒznt ɪn ðə jʊˈnaɪtɪd steɪts lɑːst jɪə",
        translation: "I wasn't in the United States last year",
      },
      {
        id: 46,
        expression: "Машина не была достаточно надежной",
        transcription: "ðə kɑː wɒznt rɪˈlaɪəbl ɪˈnʌf",
        translation: "The car wasn't reliable enough",
      },
      {
        id: 47,
        expression: "Это не было так неожиданно для меня",
        transcription: "ɪt wɒznt səʊ ˌʌnɪksˈpɛktɪd fə miː",
        translation: "It wasn't so unexpected for me",
      },
      {
        id: 48,
        expression: "Мужчина не выглядел уставшим",
        transcription: "ðə mæn dɪdnt lʊk ˈtaɪəd",
        translation: "The man didn't look tired",
      },
      {
        id: 49,
        expression: "Качество было недостаточно хорошим",
        transcription: "ðə ˈkwɒlɪti wɒznt gʊd ɪˈnʌf",
        translation: "The quality wasn't good enough",
      },
      {
        id: 50,
        expression: "Учитель не объяснил все правила",
        transcription: "ðə ˈtiːʧə dɪdnt ɪksˈpleɪn ɔːl ðə ruːlz",
        translation: "The teacher didn't explain all the rules",
      },
      {
        id: 51,
        expression: "Это не привлекло внимание людей в начале",
        transcription: "ɪt dɪdnt əˈtrækt ˈpiːplz əˈtɛnʃ(ə)n ɪn ðə bɪˈgɪnɪŋ",
        translation: "It didn't attract people's attention in the beginning",
      },
      {
        id: 52,
        expression: "Компания не была удовлетворена качеством этих продуктов",
        transcription:
          "ðə ˈkʌmpəni wɒznt ˈsætɪsfaɪd wɪð ðə ˈkwɒlɪti əv ðiːz ˈprɒdʌkts",
        translation:
          "The company wasn't satisfired with the quality of these products",
      },
      {
        id: 53,
        expression:
          "К сожалению, тех денег было недостаточно, чтобы купить квартиру",
        transcription:
          "ʌnˈfɔːʧnɪtli, ðæt ˈmʌni wɒznt ɪˈnʌf tə baɪ ən əˈpɑːtmənt",
        translation:
          "Unfortunately, that money wasn't enough to buy an apartment",
      },
      {
        id: 54,
        expression: "Он не перевел весь текст",
        transcription: "hi dɪdnt trænsˈleɪt ɔːl ðə tɛkst",
        translation: "He didn't translate all the text",
      },
      {
        id: 55,
        expression:
          "Тех знаний было недостаточно для того, чтобы сдать экзамен",
        transcription: "ðæt ˈnɒlɪʤ wɒznt ɪˈnʌf tə pɑːs ði ɪgˈzæm",
        translation: "That knowledge wasn't enough to pass the exam",
      },
      {
        id: 56,
        expression: "Урок не казался таким утомительным",
        transcription: "ðə ˈlɛsn dɪdnt siːm səʊ ˈtaɪərɪŋ",
        translation: "The lesson didn't seem so tiring",
      },
      {
        id: 57,
        expression: "Презентация не была достаточно красочной",
        transcription: "ðə ˌprɛzɛnˈteɪʃən wɒznt ˈkʌləfʊl ɪˈnʌf",
        translation: "The presentation wasn't colourful enough",
      },
      {
        id: 58,
        expression: "Эта сумма денег не была достаточно большой",
        transcription: "ðɪs sʌm əv ˈmʌni wɒznt bɪg ɪˈnʌf",
        translation: "This sum of money wasn't big enough",
      },
      {
        id: 59,
        expression: "Их ответ не был предсказуемым",
        transcription: "ðeər ˈɑːnsə wɒznt prɪˈdɪktəbl",
        translation: "Their answer wasn't predictable",
      },
      {
        id: 60,
        expression: "Это не был неожиданный результат",
        transcription: "ɪt wɒznt ən ˌʌnɪksˈpɛktɪd rɪˈzʌlt",
        translation: "It wasn't an unexpected result",
      },
      {
        id: 61,
        expression: "Я не чувствовал себя уставшим",
        transcription: "aɪ dɪdnt fiːl ˈtaɪəd",
        translation: "I didn't feel tired",
      },
      {
        id: 62,
        expression: "Полиция не была на станции",
        transcription: "ðə pəˈliːs wɜːnt ət ðə ˈsteɪʃən",
        translation: "The police weren't at the station",
      },
      {
        id: 63,
        expression: "Полиция не была на этой станции",
        transcription: "ðə pəˈliːs wɜːnt ət ðɪs ˈsteɪʃən",
        translation: "The police weren't at this station",
      },
      {
        id: 64,
        expression: "Полиции не было там",
        transcription: "ðə pəˈliːs wɜːnt ðeə",
        translation: "The police weren't there",
      },
      {
        id: 65,
        expression: "Я не прочел книгу",
        transcription: "aɪ dɪdnt riːd ðə bʊk",
        translation: "I didn't read the book",
      },
      {
        id: 66,
        expression: "Он не читал все эти книги",
        transcription: "hi dɪdnt riːd ɔːl ðiːz bʊks",
        translation: "He didn't read all these books",
      },
      {
        id: 67,
        expression: "Я не читал тот журнал",
        transcription: "aɪ dɪdnt riːd ðət ˌmægəˈziːn",
        translation: "I didn't read that magazine",
      },
      {
        id: 68,
        expression: "Полиция не прибыла туда",
        transcription: "ðə pəˈliːs dɪdnt əˈraɪv ðeə",
        translation: "The police didn't arrive there",
      },
      {
        id: 69,
        expression: "Полиция не прибыла туда вовремя",
        transcription: "ðə pəˈliːs dɪdnt əˈraɪv ðeər ɒn taɪm",
        translation: "The police didn't arrive there on time",
      },
      {
        id: 70,
        expression: "Полиция не прибыла туда вчера",
        transcription: "ðə pəˈliːs dɪdnt əˈraɪv ðeə ˈjɛstədeɪ",
        translation: "The police didn't arrive there yesterday",
      },
      {
        id: 71,
        expression: "Мальчик вовсе не был уставшим",
        transcription: "ðə bɔɪ wɒznt ˈtaɪəd ət ɔːl",
        translation: "The boy wasn't tired at all",
      },
      {
        id: 72,
        expression: "Мальчики совсем не были уставшими",
        transcription: "ðə bɔɪz wɜːnt ˈtaɪəd ət ɔːl",
        translation: "The boys weren't tired at all",
      },
      {
        id: 73,
        expression: "Тот мальчик не был уставшим",
        transcription: "ðæt bɔɪ wɒznt ˈtaɪəd",
        translation: "That boy wasn't tired",
      },
      {
        id: 74,
        expression: "Иллюстрации не были достаточно красочными",
        transcription: "ði ˌɪləsˈtreɪʃənz wɜːnt ˈkʌləfʊl ɪˈnʌf",
        translation: "The illustrations weren't colourful enough",
      },
      {
        id: 75,
        expression: "Эти иллюстрации были достаточно красочными",
        transcription: "ðiːz ˌɪləsˈtreɪʃənz wə ˈkʌləfʊl ɪˈnʌf",
        translation: "These illustrations were colourful enough",
      },
      {
        id: 76,
        expression: "Иллюстрация не была красочной",
        transcription: "ði ˌɪləsˈtreɪʃən wɒznt ˈkʌləfʊl",
        translation: "The illustration wasn't colourful",
      },
      {
        id: 77,
        expression: "Урок не был достаточно интересным",
        transcription: "ðə ˈlɛsn wɒznt ˈɪntrɪstɪŋ ɪˈnʌf",
        translation: "The lesson wasn't interesting enough",
      },
      {
        id: 78,
        expression: "Его уроки не были интересными",
        transcription: "hɪz ˈlɛsnz wɜːnt ˈɪntrɪstɪŋ",
        translation: "His lessons weren't interesting",
      },
      {
        id: 79,
        expression: "Уроки не были достаточно полезными",
        transcription: "ðə ˈlɛsnz wɜːnt ˈjuːsfʊl ɪˈnʌf",
        translation: "The lessons weren't useful enough",
      },
      {
        id: 80,
        expression: "Было не сложно пройти этот тест",
        transcription: "ɪt wɒznt ˈdɪfɪkəlt tə pɑːs ðɪs tɛst",
        translation: "It wasn't difficult to pass this test",
      },
      {
        id: 81,
        expression: "Было не легко сдать этот тест",
        transcription: "ɪt wɒznt ˈiːzi tə pɑːs ðɪs tɛst",
        translation: "It wasn't easy to pass this test",
      },
      {
        id: 82,
        expression: "Было не так сложно сдать этот экзамен",
        transcription: "ɪt wɒznt səʊ ˈdɪfɪkəlt tə pɑːs ðət ɪgˈzæm",
        translation: "It wasn't so difficult to pass that exam",
      },
      {
        id: 83,
        expression: "Та новость вовсе не удивила меня",
        transcription: "ðæt njuːz dɪdnt səˈpraɪz mi ət ɔːl",
        translation: "That news didn't surpise me at all",
      },
      {
        id: 84,
        expression: "Та новость не удивила меня",
        transcription: "ðæt njuːz dɪdnt səˈpraɪz miː",
        translation: "That news didn't surprise me",
      },
      {
        id: 85,
        expression: "Та новость вовсе не шокировала их",
        transcription: "ðæt njuːz dɪdnt ʃɒk ðəm ət ɔːl",
        translation: "That news didn't shock them at all",
      },
      {
        id: 86,
        expression: "Результат не был таким неожиданным для нас",
        transcription: "ðə rɪˈzʌlt wɒznt səʊ ˌʌnɪksˈpɛktɪd fər ʌs",
        translation: "The result wasn't so unexpected for us",
      },
      {
        id: 87,
        expression: "Результаты не были такими неожиданными для меня",
        transcription: "ðə rɪˈzʌlts wɜːnt səʊ ˌʌnɪksˈpɛktɪd fə miː",
        translation: "The results weren't so unexpected for me",
      },
      {
        id: 88,
        expression: "Для него это не было так неожиданно",
        transcription: "ɪt wɒznt səʊ ˌʌnɪksˈpɛktɪd fə hɪm",
        translation: "It wasn't so unexpected for him",
      },
      {
        id: 89,
        expression: "Было не так легко сдать экзамен",
        transcription: "ɪt wɒznt səʊ ˈiːzi tə pɑːs ði ɪgˈzæm",
        translation: "It wasn't so easy to pass the exam",
      },
      {
        id: 90,
        expression: "Ему было не так легко сдать этот экзамен",
        transcription: "ɪt wɒznt səʊ ˈiːzi fə hɪm tə pɑːs ðɪs ɪgˈzæm",
        translation: "It wasn't so easy for him to pass this exam",
      },
      {
        id: 91,
        expression: "Было не так легко сдать эти экзамены",
        transcription: "ɪt wɒznt səʊ ˈiːzi tə pɑːs ðiːz ɪgˈzæmz",
        translation: "It wasn't so easy to pass these exams",
      },
      {
        id: 92,
        expression: "Результаты совсем не были предсказуемыми",
        transcription: "ðə rɪˈzʌlts wɜːnt prɪˈdɪktəbl ɪˈnʌf",
        translation: "The results weren't predictable enough",
      },
      {
        id: 93,
        expression: "Описание не было достаточно точным",
        transcription: "ðə dɪsˈkrɪpʃən wɒznt ˈækjʊrɪt ɪˈnʌf",
        translation: "The description wasn't accurate enough",
      },
      {
        id: 94,
        expression: "Этот результат не был таким предсказуемым",
        transcription: "ðɪs rɪˈzʌlt wɒznt səʊ prɪˈdɪktəbl",
        translation: "This result wasn't so predictable",
      },
      {
        id: 95,
        expression: "Полиция не прибыла немедленно на станцию",
        transcription: "ðə pəˈliːs dɪdnt əˈraɪv ət ðə ˈsteɪʃən ɪˈmiːdiətli",
        translation: "The police didn't arrive at the station immediately",
      },
      {
        id: 96,
        expression: "Перевод не был достаточно точным",
        transcription: "ðə trænsˈleɪʃən wɒznt ˈækjʊrɪt ɪˈnʌf",
        translation: "The translation wasn't accurate enough",
      },
      {
        id: 97,
        expression: "Я думаю, это не было пустой тратой времени",
        transcription: "aɪ θɪŋk ɪt wɒznt ə weɪst əv taɪm",
        translation: "I think it wasn't a waste of time",
      },
      {
        id: 98,
        expression: "У меня не было этой возможности, когда я был ребенком",
        transcription: "aɪ dɪdnt həv ðɪs ˌɒpəˈtjuːnɪti wɛn aɪ wəz ə ʧaɪld",
        translation: "I didn't have this opportunity when I was a child",
      },
      {
        id: 99,
        expression: "Объяснения не были достаточно ясными",
        transcription: "ði ˌɛkspləˈneɪʃənz wɜːnt klɪər ɪˈnʌf",
        translation: "The explanations weren't clear enough",
      },
      {
        id: 100,
        expression: "Объяснение не казалось таким простым",
        transcription: "ði ˌɛkspləˈneɪʃən dɪdnt siːm səʊ ˈsɪmpl",
        translation: "The explanation didn't seem so simple",
      },
      {
        id: 101,
        expression: "Я не хотел смотреть весь фильм",
        transcription: "aɪ dɪdnt wɒnt tuː wɒʧ ɔːl ðə ˈmuːvi",
        translation: "I didn't want to watch all the movie",
      },
      {
        id: 102,
        expression: "Эта новость не была столь удивительной",
        transcription: "ðɪs njuːz wɒznt səʊ səˈpraɪzɪŋ",
        translation: "This news wasn't so surprising",
      },
      {
        id: 103,
        expression: "Мальчики не были уставшими",
        transcription: "ðə bɔɪz wɜːnt ˈtaɪəd",
        translation: "The boys weren't tired",
      },
      {
        id: 104,
        expression: "Тренировка была совсем не утомительной",
        transcription: "ðə ˈtreɪnɪŋ wɒznt ˈtaɪərɪŋ ət ɔːl",
        translation: "The training wasn't tiring at all",
      },
      {
        id: 105,
        expression: "Я не хотел тратить время впустую",
        transcription: "aɪ dɪdnt wɒnt tə weɪst əv taɪm",
        translation: "I didn't want to waste of time",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Закрепление пройденного материала</h3>
</div>`,
    },
  ],
];
