export const contentLesson107 = [
  [
    {
      title: "Урок 107. Prepositions in, at, on, of, to. Part 1",
    },
    [
      {
        id: 1,
        expression: "Он в комнате",
        transcription: "hiːz ɪn ðə ruːm",
        translation: "He's in the room",
      },
      {
        id: 2,
        expression: "Мы учимся в школе",
        transcription: "wi ˈstʌdi ət skuːl",
        translation: "We study at school",
      },
      {
        id: 3,
        expression: "Моя сумка на этом столе",
        transcription: "maɪ bæg z ɒn ðɪs ˈteɪbl",
        translation: "My bag is on this table",
      },
      {
        id: 4,
        expression: "Я хожу на работу",
        transcription: "aɪ gəʊ tə wɜːk",
        translation: "I go to work",
      },
      {
        id: 5,
        expression: "Я в комнате",
        transcription: "aɪm ɪn ðə ruːm",
        translation: "I'm in the room",
      },
      {
        id: 6,
        expression: "Я была там днем",
        transcription: "aɪ wəz ðeər ɪn ði ˈɑːftəˈnuːn",
        translation: "I was there in the afternoon",
      },
      {
        id: 7,
        expression: "Мы обычно ездим туда в марте",
        transcription: "wi ˈjuːʒʊəli gəʊ ðeər ɪn mɑːʧ",
        translation: "We usually go there in March",
      },
      {
        id: 8,
        expression: "Она хотела бы выпить стакан воды",
        transcription: "ʃiːd laɪk tə drɪŋk ə glɑːs əv ˈwɔːtə",
        translation: "She'd like to drink a glass of water",
      },
      {
        id: 9,
        expression: "Ручка на столе [письменном]",
        transcription: "ðə pɛn z ɒn ðə dɛsk / ðə z ə pɛn ɒn ðə dɛsk",
        translation: "The pen is on the desk / There is a pen on the desk",
      },
      {
        id: 10,
        expression: "Я был очень занят в январе",
        transcription: "aɪ wəz ˈvɛri ˈbɪzi ɪn ˈʤænjʊəri",
        translation: "I was very busy in January",
      },
      {
        id: 11,
        expression: "Она учится в школе",
        transcription: "ʃi ˈstʌdiz ət skuːl",
        translation: "She studies at school",
      },
      {
        id: 12,
        expression: "Я ходил в то место в прошлом октябре",
        transcription: "aɪ wɛnt tə ðət pleɪs lɑːst ɒkˈtəʊbə",
        translation: "I went to that place last October",
      },
      {
        id: 13,
        expression: "Она ходит на работу",
        transcription: "ʃi gəʊz tə wɜːk",
        translation: "She goes to work",
      },
      {
        id: 14,
        expression: "Он ходит в школу?",
        transcription: "dəz hi gəʊ tə skuːl?",
        translation: "Does he go to school?",
      },
      {
        id: 15,
        expression: "Я могу видеть это в небе",
        transcription: "aɪ kən siː ɪt ɪn ðə skaɪ",
        translation: "I can see it in the sky",
      },
      {
        id: 16,
        expression: "Окно дома разбито",
        transcription: "ðə ˈwɪndəʊ əv ðə haʊs ɪz ˈbrəʊkən",
        translation: "The window of the house is broken",
      },
      {
        id: 17,
        expression: "Мы поедем в Италию в следующем феврале",
        transcription: "wiːl gəʊ tʊ ˈɪtəli nɛkst ˈfɛbrʊəri",
        translation: "We'll go to Italy next February",
      },
      {
        id: 18,
        expression: "Я буду здесь до сентября",
        transcription: "aɪl bi hɪə tɪl (ənˈtɪl) sɛpˈtɛmbə",
        translation: "I'll be here till (until) September",
      },
      {
        id: 19,
        expression: "Он пришел домой в семь часов вечера",
        transcription: "hi keɪm həʊm ət ˈsɛvn əˈklɒk ɪn ði ˈiːvnɪŋ",
        translation: "He came home at seven o'clock in the evening",
      },
      {
        id: 20,
        expression: "Ты ходил на эту вечеринку?",
        transcription: "dɪd jʊ gəʊ tə ðɪs ˈpɑːti?",
        translation: "Did you go to this party?",
      },
      {
        id: 21,
        expression: "Мой день рождения в июне",
        transcription: "maɪ ˈbɜːθdeɪ z ɪn ʤu(ː)ˈlaɪ",
        translation: "My birthday is in July",
      },
      {
        id: 22,
        expression: "Я был там в апреле прошлого года",
        transcription: "aɪ wəz ðeə lɑːst ˈeɪprəl",
        translation: "I was there last April",
      },
      {
        id: 23,
        expression: "Это в углу",
        transcription: "ɪts ɪn ðə ˈkɔːnə",
        translation: "It's in the corner",
      },
      {
        id: 24,
        expression: "Я вижу это на небе",
        transcription: "aɪ siː ɪt ɪn ðə skaɪ",
        translation: "I see it in the sky",
      },
      {
        id: 25,
        expression: "Я родился в Москве",
        transcription: "aɪ wəz bɔːn ɪn ˈmɒskəʊ",
        translation: "I was born in Moscow",
      },
      {
        id: 26,
        expression: "Он живет на ферме",
        transcription: "hi lɪvz ɒn ə fɑːm",
        translation: "He lives on a farm",
      },
      {
        id: 27,
        expression: "Это на полу",
        transcription: "ɪts ɒn ðə flɔː",
        translation: "It's on the floor",
      },
      {
        id: 28,
        expression: "Его день рождения в июле",
        transcription: "hɪz ˈbɜːθdeɪ z ɪn ʤu(ː)ˈlaɪ",
        translation: "His birthday is in Jule",
      },
      {
        id: 29,
        expression: "Пациент в кровати",
        transcription: "ðə ˈpeɪʃənt s ɪn bɛd",
        translation: "The patient is in bed",
      },
      {
        id: 30,
        expression: "Крыша дома сломана",
        transcription: "ðə ruːf əv ðə haʊs ɪz ˈbrəʊkən",
        translation: "The roof of the house is broken",
      },
      {
        id: 31,
        expression: "Она родилась в Мексике",
        transcription: "ʃi wəz bɔːn ɪn ˈmɛksɪkəʊ",
        translation: "She was born in Mexico",
      },
      {
        id: 32,
        expression: "Ты увидишь это слева",
        transcription: "juːl siː ɪt ɒn ðə lɛft",
        translation: "You'll see it on the left",
      },
      {
        id: 33,
        expression: "Окно дома открыто",
        transcription: "ðə ˈwɪndəʊ əv ðə haʊs ɪz ˈəʊpən",
        translation: "The window of the house is open",
      },
      {
        id: 34,
        expression: "Дети на полу",
        transcription: "ðə kɪdz (ˈʧɪldrən) ɑːr ɒn ðə flɔː",
        translation: "The kids (children) are on the floor",
      },
      {
        id: 35,
        expression: "Мы будем отмечать этот праздник в мае",
        transcription: "wiːl ˈsɛlɪbreɪt ðɪs ˈhɒlədeɪ ɪn meɪ",
        translation: "We'll celebrate this holiday in May",
      },
      {
        id: 36,
        expression: "Я проснулся очень рано утром",
        transcription: "aɪ wəʊk ʌp ˈvɛri ˈɜːli ɪn ðə ˈmɔːnɪŋ",
        translation: "I woke up very early in the morning",
      },
      {
        id: 37,
        expression: "Я буду там до августа",
        transcription: "aɪl bi ðeə tɪl (ənˈtɪl) ˈɔːgəst",
        translation: "I'll be there till (until) August",
      },
      {
        id: 38,
        expression: "Игрушка в коробке",
        transcription: "ðə tɔɪ z ɪn ðə bɒks",
        translation: "The toy is in the box",
      },
      {
        id: 39,
        expression: "Он был на этой вечеринке",
        transcription: "hi wəz ət ðɪs ˈpɑːti",
        translation: "He was at this party",
      },
      {
        id: 40,
        expression: "Я хочу выпить стакан сока",
        transcription: "aɪ wɒnt tə drɪŋk ə glɑːs əv ʤuːs",
        translation: "I want to drink a glass of juice",
      },
      {
        id: 41,
        expression: "Мы можем встретиться вечером",
        transcription: "wi kən miːt ɪn ði ˈiːvnɪŋ",
        translation: "We can meet in the evening",
      },
      {
        id: 42,
        expression: "Он поедет в Германию в этом декабре",
        transcription: "hiːl gəʊ tə ˈʤɜːməni ðɪs dɪˈsɛmbə",
        translation: "He'll go to Germany this December",
      },
      {
        id: 43,
        expression: "Он будет отсутствовать в ноябре",
        transcription: "hiːl bi əˈweɪ ɪn nəʊˈvɛmbə",
        translation: "He'll be away in November",
      },
      {
        id: 44,
        expression: "Мальчик в кровати",
        transcription: "ðə bɔɪ z ɪn bɛd",
        translation: "The boy is in bed",
      },
      {
        id: 45,
        expression: "Я вижу это на этой картинке",
        transcription: "aɪ siː ɪt ɪn ðɪs ˈpɪkʧə",
        translation: "I see it in this picture",
      },
      {
        id: 46,
        expression: "Ты самая красивая девушка в мире",
        transcription: "jʊə ðə məʊst ˈbjuːtəfʊl gɜːl ɪn ðə wɜːld",
        translation: "You're the most beautiful girl in the world",
      },
      {
        id: 47,
        expression: "Мы живем на втором этаже",
        transcription: "wi lɪv ɒn ðə ˈsɛkənd flɔː",
        translation: "We live on the second floor",
      },
      {
        id: 48,
        expression: "Ты можешь показать мне эту вещь?",
        transcription: "kən jʊ ʃəʊ mi ðɪs θɪŋ?",
        translation: "Can you show me this thing?",
      },
      {
        id: 49,
        expression: "Я вижу это в углу комнаты",
        transcription: "aɪ siː ɪt ɪn ðə ˈkɔːnər əv ðə ruːm",
        translation: "I see it in the corner of the room",
      },
      {
        id: 50,
        expression: "Я хочу найти это на карте",
        transcription: "aɪ wɒnt tə faɪnd ɪt ɒn ðə mæp",
        translation: "I want to find it on the map",
      },
      {
        id: 51,
        expression: "Мы увидим друг друга на Новый Год",
        transcription: "wiːl siː iːʧ ˈʌðər ət njuː jɪə",
        translation: "We'll see each other at New Year",
      },
      {
        id: 52,
        expression: "Он полетит в Лондон в марте",
        transcription: "hiːl flaɪ tə ˈlʌndən ɪn mɑːʧ",
        translation: "He'll fly to London in March",
      },
      {
        id: 53,
        expression: "Мы встретимся на Рождество?",
        transcription: "wɪl wi miːt ət ˈkrɪsməs?",
        translation: "Will we meet at Christmas?",
      },
      {
        id: 54,
        expression: "Это слева",
        transcription: "ɪts ɒn ðə lɛft",
        translation: "It's on the left",
      },
      {
        id: 55,
        expression: "Моя мама живет в деревне",
        transcription: "maɪ ˈmʌðə lɪvz ɪn ðə ˈkʌntri",
        translation: "My mother lives in the country",
      },
      {
        id: 56,
        expression: "Ты можешь показать это мне?",
        transcription: "kən jʊ ʃəʊ ɪt tə miː?",
        translation: "Can you show it to me?",
      },
      {
        id: 57,
        expression: "Мы можем видеть это на этой картинке",
        transcription: "wi kən siː ɪt ɪn ðɪs ˈpɪkʧə",
        translation: "We can see it in this picture",
      },
      {
        id: 58,
        expression: "Это справа",
        transcription: "ɪts ɒn ðə raɪt",
        translation: "It's on the right",
      },
      {
        id: 59,
        expression: "Дай мне ту вещь!",
        transcription: "gɪv mi ðət θɪŋ!",
        translation: "Give me that thing!",
      },
      {
        id: 60,
        expression: "Это самая популярная песня в мире",
        transcription: "ɪts ðə məʊst ˈpɒpjʊlə sɒŋ ɪn ðə wɜːld",
        translation: "It's the most popular song in the world",
      },
      {
        id: 61,
        expression: "Это самая известная песня в мире",
        transcription: "ɪts ðə məʊst ˈfeɪməs sɒŋ ɪn ðə wɜːld",
        translation: "It's the most famous song in the world",
      },
      {
        id: 62,
        expression: "Это самая популярная песня в мире?",
        transcription: "ɪz ɪt ðə məʊst ˈpɒpjʊlə sɪŋ ɪn ðə wɜːld?",
        translation: "Is it the most popular sing in the world?",
      },
      {
        id: 63,
        expression: "Мы увидим друг друга на Новый Год",
        transcription: "wɪl wi siː iːʧ ˈʌðər ɒn njuː jɪəz deɪ?",
        translation: "Will we see each other on New Year's Day?",
      },
      {
        id: 64,
        expression: "Мы увидим друг друга в следующем месяце",
        transcription: "wiːl siː iːʧ ˈʌðə nɛkst mʌnθ",
        translation: "We'll see each other next month",
      },
      {
        id: 65,
        expression: "Мы увидим друг друга в сентябре",
        transcription: "wiːl siː iːʧ ˈʌðər ɪn sɛpˈtɛmbə",
        translation: "We'll see each other in September",
      },
      {
        id: 66,
        expression: "Это на первом этаже?",
        transcription: "ɪz ɪt ɒn ðə graʊnd flɔː?",
        translation: "Is it on the ground floor?",
      },
      {
        id: 67,
        expression: "Он живет на втором этаже?",
        transcription: "dəz hi lɪv ɪn ðə ˈsɛkənd flɔː?",
        translation: "Does he live in the second floor?",
      },
      {
        id: 68,
        expression: "Он живет на третьем этаже",
        transcription: "hi lɪvz ɒn ðə θɜːd flɔː",
        translation: "He lives on the third floor",
      },
      {
        id: 69,
        expression: "Он часто лежит в постели по утрам",
        transcription: "hi ˈɒf(ə)n laɪz ɪn bɛd ɪn ðə ˈmɔːnɪŋz",
        translation: "He often lies in bed in the mornings",
      },
      {
        id: 70,
        expression: "Он часто делает это по утрам",
        transcription: "hi ˈɒf(ə)n dəz ɪt ɪn ðə ˈmɔːnɪŋz",
        translation: "He often does it in the mornings",
      },
      {
        id: 71,
        expression: "Он часто лежит в постели по вечерам",
        transcription: "hi ˈɒf(ə)n laɪz ɪn bɛd ɪn ði ˈiːvnɪŋz",
        translation: "He often lies in bed in the evenings",
      },
      {
        id: 72,
        expression: "Тебе нравится гулять на улице?",
        transcription: "dʊ jʊ laɪk ˈwɔːkɪŋ ɪn ðə striːt?",
        translation: "Do you like walking in the street?",
      },
      {
        id: 73,
        expression: "Тебе нравится гулять в парке?",
        transcription: "dʊ jʊ laɪk ˈwɔːkɪŋ ɪn ðə pɑːk?",
        translation: "Do you like walking in the park?",
      },
      {
        id: 74,
        expression: "Я люблю гулять там",
        transcription: "aɪ laɪk ˈwɔːkɪŋ ðeə",
        translation: "I like walking there",
      },
      {
        id: 75,
        expression: "Покажи мне эту вещь",
        transcription: "ʃəʊ mi ðɪs θɪŋ! / ʃəʊ ðɪs θɪŋ tə miː!",
        translation: "Show me this thing! / Show this thing to me!",
      },
      {
        id: 76,
        expression: "Ты можешь показать эту вещь мне?",
        transcription: "kən jʊ ʃəʊ ðɪs θɪŋ tə miː?",
        translation: "Can you show this thing to me?",
      },
      {
        id: 77,
        expression: "Покажи это мне!",
        transcription: "ʃəʊ ɪt tə miː!",
        translation: "Show it to me!",
      },
      {
        id: 78,
        expression: "Мы остановились на середине видео",
        transcription: "wi stɒpt ɪn ðə ˈmɪdl əv ðə ˈvɪdɪəʊ",
        translation: "We stopped in the middle of the video",
      },
      {
        id: 79,
        expression: "Мы остановились на середине фильма",
        transcription: "wi stɒpt ɪn ðə ˈmɪdl əv ðə ˈmuːvi",
        translation: "We stopped in the middle of the movie",
      },
      {
        id: 80,
        expression: "Они остановились на середине видео",
        transcription: "ðeɪ stɒpt ɪn ðə ˈmɪdl əv ðə ˈvɪdɪəʊ",
        translation: "They stopped in the middle of the video",
      },
      {
        id: 81,
        expression: "Супермаркет будет справа",
        transcription: "ðə ˈsjuːpəˌmɑːkɪt wɪl bi ɒn ðə raɪt",
        translation: "The supermarket will be on the right",
      },
      {
        id: 82,
        expression: "Музей будет слева",
        transcription: "ðə mju(ː)ˈzɪəm wɪl bi ɒn ðə raɪt",
        translation: "The museum will be on the right",
      },
      {
        id: 83,
        expression: "Это будет справа",
        transcription: "ˈɪtl bi ɒn ðə raɪt",
        translation: "It'll be on the right",
      },
      {
        id: 84,
        expression: "Он самый богатый человек в мире",
        transcription: "hiːz ðə ˈrɪʧɪst ˈpɜːsn ɪn ðə wɜːld",
        translation: "He's the richest person in the world",
      },
      {
        id: 85,
        expression: "Он самый богатый человек в этом городе",
        transcription: "hiːz ðə ˈrɪʧɪst ˈpɜːsn ɪn ðɪs ˈsɪti",
        translation: "He's the richest person in this city",
      },
      {
        id: 86,
        expression: "Он самый популярный певец в мире",
        transcription: "hiːz ðə məʊst ˈpɒpjʊlə ˈsɪŋər ɪn ðə wɜːld",
        translation: "He's the most popular singer in the world",
      },
      {
        id: 87,
        expression: "Дай эту вещь мне",
        transcription: "gɪv ðɪs θɪŋ tə miː!",
        translation: "Give this thing to me!",
      },
      {
        id: 88,
        expression: "Ты можешь дать эту книгу мне?",
        transcription: "kən jʊ gɪv ðɪs bʊk tə miː?",
        translation: "Can you give this book to me?",
      },
      {
        id: 89,
        expression: "Дай эти вещи ему!",
        transcription: "gɪv ðiːz θɪŋz tə hɪm!",
        translation: "Give these things to him!",
      },
      {
        id: 90,
        expression: "Я остановился посередине",
        transcription: "aɪ stɒpt ɪn ðə ˈmɪdl",
        translation: "I stopped in the middle",
      },
      {
        id: 91,
        expression: "Это на первом этаже",
        transcription: "ɪts ɒn ðə graʊnd flɔː",
        translation: "It's on the ground floor",
      },
      {
        id: 92,
        expression: "Я родился в апреле",
        transcription: "aɪ wəz bɔːn ɪn ˈeɪprəl",
        translation: "I was born in April",
      },
      {
        id: 93,
        expression: "Вторая Мировая война началась в 1939 году",
        transcription: "ðə ˈsɛkənd wɜːld wɔː ˈstɑːtɪd (bɪˈgæn) ɪn 1939",
        translation: "The Second World War started (began) in 1939",
      },
      {
        id: 94,
        expression: "Я остановился на середине книги",
        transcription: "aɪ stɒpt ɪn ðə ˈmɪdl əv ðə bʊk",
        translation: "I stopped in the middle of the book",
      },
      {
        id: 95,
        expression: "Это самое красивое место в мире",
        transcription: "ɪts ðə məʊst ˈbjuːtəfʊl pleɪs ɪn ðə wɜːld",
        translation: "It's the most beautiful place in the world",
      },
      {
        id: 96,
        expression: "Я люблю гулять на улице",
        transcription: "aɪ laɪk ˈwɔːkɪŋ ɪn ðə striːt",
        translation: "I like walking in the street",
      },
      {
        id: 97,
        expression: "Покажи мне эту вещь!",
        transcription: "ʃəʊ ðɪs θɪŋ tə miː!",
        translation: "Show this thing to me!",
      },
      {
        id: 98,
        expression: "Мы встретимся на Рождество",
        transcription: "wiːl miːt ɒn ˈkrɪsməs deɪ",
        translation: "We'll meet on Christmas Day",
      },
      {
        id: 99,
        expression: "Я люблю гулять на улице со своими друзьями",
        transcription: "aɪ laɪk ˈwɔːkɪŋ ɪn ðə striːt wɪð maɪ frɛndz",
        translation: "I like walking in the street with my friends",
      },
      {
        id: 100,
        expression: "Он родился в 1987 году",
        transcription: "hi wəz bɔːn ɪn 1987",
        translation: "He was born in 1987",
      },
      {
        id: 101,
        expression: "Это есть на фото",
        transcription: "ɪts ɪn ðə ˈfəʊtəʊ",
        translation: "It's in the photo",
      },
      {
        id: 102,
        expression: "Посмотри на фото!",
        transcription: "lʊk ət ðə ˈfəʊtəʊ!",
        translation: "Look at the photo!",
      },
      {
        id: 103,
        expression: "Дай это мне!",
        transcription: "gɪv ɪt tə miː!",
        translation: "Give it to me!",
      },
      {
        id: 104,
        expression: "Я живу на первом этаже",
        transcription: "aɪ lɪv ɒn ðə fɜːst flɔː",
        translation: "I live on the first floor",
      },
    ],
    {
      theory: `<div class="theory-block">
<p>В английском языке очень много всяких предлогов. Часть из них понимается и используется также, как в русском языке. Но иногда один и тот же предлог может быть переведен на русский язык по-разному.</p>
<h3>in - в (указывает на местоположение внутри чего-то)</h3>
<p>He is in the room - Он в комнате.</p>
<h3>at - у, около, в, на (указывает на нахождение где-либо)</h3>
<p>I'm at the airport - Я в аэропорту.</p>
<h3>on - на (поверхности чего-то)</h3>
<p>The cat is on the table - Кот на столе.</p>
<h3>to - к, в, на (указывает направление движения)</h3>
<p>I went to work - Я ушел на работу.</p>
</div>`,
    },
  ],
];
