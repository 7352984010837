export const wordsLesson183 = [
  [
    {
      id: 1,
      word: 'annual',
      transcription: 'ˈænjʊəl',
      translation: 'годовой, ежегодный',
    },
    {
      id: 2,
      word: 'appropriate',
      transcription: 'əˈprəʊprɪɪt',
      translation: 'подходящий',
    },
    {
      id: 3,
      word: 'cultural',
      transcription: 'ˈkʌlʧərəl',
      translation: 'культурный',
    },
    {
      id: 4,
      word: 'curly',
      transcription: 'ˈkɜːli',
      translation: 'кудрявый',
    },
    {
      id: 5,
      word: 'dreadful',
      transcription: 'ˈdrɛdfʊl',
      translation: 'ужасный',
    },
    {
      id: 6,
      word: 'environmental',
      transcription: 'ɪnˌvaɪərənˈmɛntl',
      translation: 'касающийся окружающей среды',
    },
    {
      id: 7,
      word: 'especially',
      transcription: 'ɪsˈpɛʃəli',
      translation: 'особенно',
    },
    {
      id: 8,
      word: 'exceed',
      transcription: 'ɪkˈsiːd',
      translation: 'превосходить',
    },
    {
      id: 9,
      word: 'expectation',
      transcription: 'ˌɛkspɛkˈteɪʃən',
      translation: 'ожидание',
    },
    {
      id: 10,
      word: 'flavour',
      transcription: 'ˈfleɪvə',
      translation: 'ароматный, вкусный',
    },
    {
      id: 11,
      word: 'heritage',
      transcription: 'ˈhɛrɪtɪʤ',
      translation: 'наследие',
    },
    {
      id: 12,
      word: 'impression',
      transcription: 'ɪmˈprɛʃən',
      translation: 'впечатление',
    },
    {
      id: 13,
      word: 'impressive',
      transcription: 'ɪmˈprɛsɪv',
      translation: 'впечатляющий',
    },
    {
      id: 14,
      word: 'maximum',
      transcription: 'ˈmæksɪməm',
      translation: 'максимальный',
    },
    {
      id: 15,
      word: 'modest',
      transcription: 'ˈmɒdɪst',
      translation: 'скромный',
    },
    {
      id: 16,
      word: 'old-fashioned',
      transcription: 'əʊld-ˈfæʃənd',
      translation: 'старомодный',
    },
    {
      id: 17,
      word: 'per cent',
      transcription: 'pɜː sɛnt',
      translation: 'процент',
    },
    {
      id: 18,
      word: 'productive',
      transcription: 'prəˈdʌktɪv',
      translation: 'продуктивный',
    },
    {
      id: 19,
      word: 'punctual',
      transcription: 'ˈpʌŋktjʊəl',
      translation: 'пунктуальный',
    },
    {
      id: 20,
      word: 'relatively',
      transcription: 'ˈrɛlətɪvli',
      translation: 'относительно',
    },
    {
      id: 21,
      word: 'surface',
      transcription: 'ˈsɜːfɪs',
      translation: 'поверхность',
    },
    {
      id: 22,
      word: 'surprisingly',
      transcription: 'səˈpraɪzɪŋli',
      translation: 'удивительно',
    },
    {
      id: 23,
      word: 'tidy',
      transcription: 'ˈtaɪdi',
      translation: 'аккуратный, опрятный',
    },
    {
      id: 24,
      word: 'turnover',
      transcription: 'ˈtɜːnˌəʊvə',
      translation: 'оборот',
    },
    {
      id: 25,
      word: 'the United Nations',
      transcription: 'ðə jʊˈnaɪtɪd ˈneɪʃənz',
      translation: 'Организация Объединенных Наций',
    },
    {
      id: 26,
      word: 'unlikely',
      transcription: 'ʌnˈlaɪkli',
      translation: 'маловероятный',
    },
    {
      id: 27,
      word: 'user',
      transcription: 'ˈjuːzə',
      translation: 'пользователь',
    },
    {
      id: 28,
      word: 'fine',
      transcription: 'faɪn',
      translation: 'штраф',
    },
  ],
];
