export const contentLesson62 = [
  [
    {
      title:
        "Урок 62. Present Simple: сложные подлежащие + глагол to be: Negative Form",
    },
    [
      {
        id: 1,
        expression: "Отель не такой дорогой",
        transcription: "ðə həʊˈtɛl ˈɪznt səʊ ɪksˈpɛnsɪv",
        translation: "The hotel isn't so expensive",
      },
      {
        id: 2,
        expression: "Эти налоги не такие большие",
        transcription: "ðiːz ˈtæksɪz ɑːnt səʊ bɪg",
        translation: "These taxes aren't so big",
      },
      {
        id: 3,
        expression: "Эти правила не такие сложные",
        transcription: "ðiːz ruːlz ɑːnt səʊ ˈdɪfɪkəlt",
        translation: "These rules aren't so difficult",
      },
      {
        id: 4,
        expression: "Эта информация не такая важная",
        transcription: "ðɪs ˌɪnfəˈmeɪʃən ˈɪznt səʊ ɪmˈpɔːtənt",
        translation: "This information isn't so important",
      },
      {
        id: 5,
        expression: "Компьютер не такой мощный",
        transcription: "ðə kəmˈpjuːtər ˈɪznt səʊ ˈpaʊəfʊl",
        translation: "The computer isn't so powerful",
      },
      {
        id: 6,
        expression: "Учитель не старый",
        transcription: "ðə ˈtiːʧər ˈɪznt əʊld",
        translation: "The teacher isn't old",
      },
      {
        id: 7,
        expression: "Моя мама не дома",
        transcription: "maɪ ˈmʌðər ˈɪznt ət həʊm",
        translation: "My mother isn't at home",
      },
      {
        id: 8,
        expression: "Правило не такое простое",
        transcription: "ðə ruːl ˈɪznt səʊ ˈsɪmpl",
        translation: "The rule isn't so simple",
      },
      {
        id: 9,
        expression: "Наш директор в данный момент не свободен",
        transcription: "ˈaʊə dɪˈrɛktər ˈɪznt friː ət ðə ˈməʊmənt",
        translation: "Our director isn't free at the moment",
      },
      {
        id: 10,
        expression: "Проблема не такая большая",
        transcription: "ðə ˈprɒbləm ˈɪznt səʊ bɪg",
        translation: "The problem isn't so big",
      },
      {
        id: 11,
        expression: "Эти выражения для нас не новые",
        transcription: "ðiːz ɪksˈprɛʃənz ɑːnt njuː fər ʌs",
        translation: "These expressions aren't new for us",
      },
      {
        id: 12,
        expression: "Окно не закрыто",
        transcription: "ðə ˈwɪndəʊ ˈɪznt kləʊzd",
        translation: "The window isn't closed",
      },
      {
        id: 13,
        expression: "Их поддержка не так велика",
        transcription: "ðeə səˈpɔːt ˈɪznt səʊ bɪg",
        translation: "Their support isn't so big",
      },
      {
        id: 14,
        expression: "Программа не скучная",
        transcription: "ðə ˈprəʊgræm ˈɪznt ˈbɔːrɪŋ",
        translation: "The program isn't boring",
      },
      {
        id: 15,
        expression: "Это сообщество сейчас не такое большое",
        transcription: "ðɪs kəˈmjuːnɪti ˈɪznt səʊ bɪg naʊ",
        translation: "This community isn't so big now",
      },
      {
        id: 16,
        expression: "Отель не такой дорогой",
        transcription: "ðə həʊˈtɛl ˈɪznt səʊ ɪksˈpɛnsɪv",
        translation: "The hotel isn't so expensive",
      },
      {
        id: 17,
        expression: "Это изображение не такое большое",
        transcription: "ðɪs ˈɪmɪʤ ˈɪznt səʊ bɪg",
        translation: "This image isn't so big",
      },
      {
        id: 18,
        expression: "Это сочинение не плохое",
        transcription: "ðɪs ˌkɒmpəˈzɪʃən ˈɪznt bæd",
        translation: "This composition isn't bad",
      },
      {
        id: 19,
        expression: "Мальчики не в одной группе",
        transcription: "ðə bɔɪz ɑːnt ɪn ðə seɪm gruːp",
        translation: "The boys aren't in the same group",
      },
      {
        id: 20,
        expression: "Его эссе не такое плохое",
        transcription: "hɪz ˈɛseɪ ˈɪznt səʊ bæd",
        translation: "His essay isn't so bad",
      },
      {
        id: 21,
        expression: "Это слово не новое для меня",
        transcription: "ðɪs wɜːd ˈɪznt njuː fə miː",
        translation: "This word isn't new for me",
      },
      {
        id: 22,
        expression: "Ребенок не снаружи",
        transcription: "ðə ʧaɪld ˈɪznt ˌaʊtˈsaɪd",
        translation: "The child isn't outside",
      },
      {
        id: 23,
        expression: "Дом не маленький",
        transcription: "ðə haʊs ˈɪznt smɔːl",
        translation: "The house isn't small",
      },
      {
        id: 24,
        expression: "День не ветренный",
        transcription: "ðə deɪ ˈɪznt ˈwɪndi",
        translation: "The day isn't windy",
      },
      {
        id: 25,
        expression: "Его сочинение не такое плохое",
        transcription: "hɪz ˌkɒmpəˈzɪʃən ˈɪznt səʊ bæd",
        translation: "His composition isn't so bad",
      },
      {
        id: 26,
        expression: "Эта вещь не такая важная",
        transcription: "ðɪs θɪŋ ˈɪznt səʊ ɪmˈpɔːtənt",
        translation: "This thing isn't so important",
      },
      {
        id: 27,
        expression: "День не такой солнечный",
        transcription: "ðə deɪ ˈɪznt səʊ ˈsʌni",
        translation: "The day isn't so sunny",
      },
      {
        id: 28,
        expression: "Эта организация не известная",
        transcription: "ðɪs ˌɔːgənaɪˈzeɪʃən ˈɪznt ˈfeɪməs",
        translation: "This organization isn't famous",
      },
      {
        id: 29,
        expression: "Эта идея не новая",
        transcription: "ðɪs aɪˈdɪə ˈɪznt njuː",
        translation: "This idea isn't new",
      },
      {
        id: 30,
        expression: "Дни не дождливые",
        transcription: "ðə deɪz ɑːnt ˈreɪni",
        translation: "The days aren't rainy",
      },
      {
        id: 31,
        expression: "Цена не такая высокая",
        transcription: "ðə praɪs ˈɪznt səʊ haɪ",
        translation: "The price isn't so high",
      },
      {
        id: 32,
        expression: "Эта работа ей не интересна",
        transcription: "ðɪs ʤɒb ˈɪznt ˈɪntrɪstɪŋ fə hə",
        translation: "This job isn't interesting for her",
      },
      {
        id: 33,
        expression: "Стол не круглый",
        transcription: "ðə ˈteɪbl ˈɪznt raʊnd",
        translation: "The table isn't round",
      },
      {
        id: 34,
        expression: "Той практики не достаточно",
        transcription: "ðæt ˈpræktɪs ˈɪznt ɪˈnʌf",
        translation: "That practice isn't enough",
      },
      {
        id: 35,
        expression: "Мой друг не уверен",
        transcription: "maɪ frɛnd ˈɪznt ʃʊə",
        translation: "My friend isn't sure",
      },
      {
        id: 36,
        expression: "Дети не дома",
        transcription: "ðə ˈʧɪldrən ɑːnt ət həʊm",
        translation: "The children aren't at home",
      },
      {
        id: 37,
        expression: "Ее дом не такой маленький",
        transcription: "hə haʊs ˈɪznt səʊ smɔːl",
        translation: "Her house isn't so small",
      },
      {
        id: 38,
        expression: "Погода не такая плохая",
        transcription: "ðə ˈwɛðər ˈɪznt səʊ bæd",
        translation: "The weather isn't so bad",
      },
      {
        id: 39,
        expression: "Его бизнес не прибыльный",
        transcription: "hɪz ˈbɪznɪs ˈɪznt ˈprɒfɪtəbl",
        translation: "His business isn't profitable",
      },
      {
        id: 40,
        expression: "Этой информации не достаточно",
        transcription: "ðɪs ˌɪnfəˈmeɪʃən ˈɪznt ɪˈnʌf",
        translation: "This information isn't enough",
      },
      {
        id: 41,
        expression: "Дверь не открыта",
        transcription: "ðə dɔːr ˈɪznt ˈəʊpən",
        translation: "The door isn't open",
      },
      {
        id: 42,
        expression: "Это предложение работы не так интересно мне",
        transcription: "ðɪs ʤɒb ˈɒfər ˈɪznt səʊ ˈɪntrɪstɪŋ fə miː",
        translation: "This job offer isn't so interesting for me",
      },
      {
        id: 43,
        expression: "Мужчина недостаточно сильный",
        transcription: "ðə mæn ˈɪznt strɒŋ ɪˈnʌf",
        translation: "The man isn't strong enough",
      },
      {
        id: 44,
        expression:
          "Я знаю, что многие люди говорят, что время - это деньги, но я думаю, что время - это намного больше, чем деньги, потому что мы не можем вернуть его назад",
        transcription:
          "aɪ nəʊ ðət ˈmɛni ˈpiːpl seɪ ðət taɪm z ˈmʌni bət aɪ θɪŋk ðət taɪm z mʌʧ mɔː ðən ˈmʌni bɪˈkəz wi kɑːnt tɜːn ɪt bæk",
        translation:
          "I know that many people say that time is money but I think that time is much more than money because we can't turn it back",
      },
      {
        id: 45,
        expression: "Сообщество не очень большое в настоящее время",
        transcription: "ðə kəˈmjuːnɪti ˈɪznt ˈvɛri bɪg ət ˈprɛznt",
        translation: "The community isn't very big at present",
      },
      {
        id: 46,
        expression: "Качество этих продуктов недостаточно хорошее",
        transcription: "ðə ˈkwɒlɪti əv ðiːz ˈprɒdʌkts ˈɪznt gʊd ɪˈnʌf",
        translation: "The quality of these products isn't good enough",
      },
      {
        id: 47,
        expression: "То качество не достаточно хорошее",
        transcription: "ðæt ˈkwɒlɪti ˈɪznt gʊd ɪˈnʌf",
        translation: "That quality isn't good enough",
      },
      {
        id: 48,
        expression: "Я думаю, что твой друг не прав",
        transcription: "aɪ θɪŋk ðət jə frɛnd ˈɪznt raɪt",
        translation: "I think that your friend isn't right",
      },
      {
        id: 49,
        expression: "Эти условия не идеальные",
        transcription: "ðiːz kənˈdɪʃənz ɑːnt ˈpɜːfɪkt",
        translation: "These conditions aren't perfect",
      },
      {
        id: 50,
        expression: "Химия ей не так интересна",
        transcription: "ˈkɛmɪstri ˈɪznt səʊ ˈɪntrɪstɪŋ fə hə",
        translation: "Chemistry isn't so interesting for her",
      },
      {
        id: 51,
        expression: "Их образ недостаточно хорош",
        transcription: "ðeər ˈɪmɪʤ ˈɪznt gʊd ɪˈnʌf",
        translation: "Their image isn't good enough",
      },
      {
        id: 52,
        expression: "Этот бизнесмен не успешный в настоящее время",
        transcription: "ðɪs ˈbɪznɪsmən ˈɪznt səkˈsɛsfʊl ət ˈprɛznt",
        translation: "This businessman isn't successful at present",
      },
      {
        id: 53,
        expression: "Мальчик не высокий",
        transcription: "ðə bɔɪ ˈɪznt tɔːl",
        translation: "The boy isn't tall",
      },
      {
        id: 54,
        expression: "Эта пара джинсов не такая дешевая",
        transcription: "ðɪs peər əv ʤiːnz ˈɪznt səʊ ʧiːp",
        translation: "This pair of jeans isn't so cheap",
      },
      {
        id: 55,
        expression: "Модель не новая",
        transcription: "ðə ˈmɒdl ˈɪznt njuː",
        translation: "The model isn't new",
      },
      {
        id: 56,
        expression: "Здание не такое высокое",
        transcription: "ðə ˈbɪldɪŋ ˈɪznt səʊ haɪ",
        translation: "The building isn't so high",
      },
      {
        id: 57,
        expression: "Их репутация не плохая",
        transcription: "ðeə ˌrɛpju(ː)ˈteɪʃən ˈɪznt bæd",
        translation: "Their reputation isn't bad",
      },
      {
        id: 58,
        expression: "Я думаю, что немецкий не такой легкий",
        transcription: "aɪ θɪŋk ðət ˈʤɜːmən ˈɪznt səʊ ˈiːzi",
        translation: "I think that German isn't so easy",
      },
      {
        id: 59,
        expression: "Она думает, что английский не такой легкий",
        transcription: "ʃi θɪŋks ðət ˈɪŋglɪʃ ˈɪznt səʊ ˈiːzi",
        translation: "She thinks that English isn't so easy",
      },
      {
        id: 60,
        expression: "Я думаю, что этот тест не такой легкий",
        transcription: "aɪ θɪŋk ðət ðɪs tɛst ˈɪznt səʊ ˈiːzi",
        translation: "I think that this test isn't so easy",
      },
      {
        id: 61,
        expression: "Этот налог не такой маленький",
        transcription: "ðɪs tæks ˈɪznt səʊ ˈlɪtl",
        translation: "This tax isn't so little",
      },
      {
        id: 62,
        expression: "Налоги не такие маленькие",
        transcription: "ðə ˈtæksɪz ɑːnt səʊ ˈlɪtl",
        translation: "The taxes aren't so little",
      },
      {
        id: 63,
        expression: "Тот налог не такой большой",
        transcription: "ðæt tæks ˈɪznt səʊ bɪg",
        translation: "That tax isn't so big",
      },
      {
        id: 64,
        expression: "Те брюки не модные",
        transcription: "ðəʊz ˈtraʊzəz ɑːnt ˈfæʃnəbl",
        translation: "Those trousers aren't fashionable",
      },
      {
        id: 65,
        expression: "Эта одежда не модная",
        transcription: "ðiːz kləʊðz ɑːnt ˈfæʃnəbl",
        translation: "These clothes aren't fashionable",
      },
      {
        id: 66,
        expression: "Мои брюки не модные",
        transcription: "maɪ ˈtraʊzəz ɑːnt ˈfæʃnəbl",
        translation: "My trousers aren't fashionable",
      },
      {
        id: 67,
        expression: "Курение не хорошо для здоровья",
        transcription: "ˈsməʊkɪŋ ˈɪznt gʊd fə hɛlθ",
        translation: "Smoking isn't good for health",
      },
      {
        id: 68,
        expression: "Курение плохо для здоровья",
        transcription: "ˈsməʊkɪŋ z bæd fə hɛlθ",
        translation: "Smoking is bad for health",
      },
      {
        id: 69,
        expression: "Употребление алкоголя не хорошо для твоего здоровья",
        transcription: "ˈdrɪŋkɪŋ ˈælkəhɒl ˈɪznt gʊd fə jə hɛlθ",
        translation: "Drinking alcohol isn't good for your health",
      },
      {
        id: 70,
        expression: "Яблоки не свежие",
        transcription: "ði ˈæplz ɑːnt frɛʃ",
        translation: "The apples aren't fresh",
      },
      {
        id: 71,
        expression: "Твои яблоки не свежие",
        transcription: "jər ˈæplz ɑːnt frɛʃ",
        translation: "Your apples aren't fresh",
      },
      {
        id: 72,
        expression: "Эти яблоки не свежие",
        transcription: "ðiːz ˈæplz ɑːnt frɛʃ",
        translation: "These apples aren't fresh",
      },
      {
        id: 73,
        expression: "Девочка не высокая",
        transcription: "ðə gɜːl ˈɪznt tɔːl",
        translation: "The girl isn't tall",
      },
      {
        id: 74,
        expression: "Эти девочки не высокие",
        transcription: "ðiːz gɜːlz ɑːnt tɔːl",
        translation: "These girls aren't tall",
      },
      {
        id: 75,
        expression: "Девочка не очень высокая",
        transcription: "ðə gɜːl ˈɪznt ˈvɛri tɔːl",
        translation: "The girl isn't very tall",
      },
      {
        id: 76,
        expression: "Тот метод недостаточно хороший",
        transcription: "ðæt ˈmɛθəd ˈɪznt gʊd ɪˈnʌf",
        translation: "That method isn't good enough",
      },
      {
        id: 77,
        expression: "Те методы недостаточно хорошие",
        transcription: "ðəʊz ˈmɛθədz ɑːnt gʊd ɪˈnʌf",
        translation: "Those methods aren't good enough",
      },
      {
        id: 78,
        expression: "Тот метод не такой хороший",
        transcription: "ðæt ˈmɛθəd ˈɪznt səʊ gʊd",
        translation: "That method isn't so good",
      },
      {
        id: 79,
        expression: "Еда не вкусная",
        transcription: "ðə fuːd ˈɪznt ˈteɪsti",
        translation: "The food isn't tasty",
      },
      {
        id: 80,
        expression: "Эти продукты не вкусные",
        transcription: "ðiːz ˈprɒdʌkts ɑːnt ˈteɪsti",
        translation: "These products aren't tasty",
      },
      {
        id: 81,
        expression: "Еда не очень вкусная",
        transcription: "ðə fuːd ˈɪznt ˈvɛri ˈteɪsti",
        translation: "The food isn't very tasty",
      },
      {
        id: 82,
        expression: "История - не его любимый предмет",
        transcription: "ˈhɪstəri ˈɪznt ɪz ˈfeɪvərɪt ˈsʌbʤɪkt",
        translation: "History isn't his favourite subject",
      },
      {
        id: 83,
        expression: "История - не мой любимый предмет",
        transcription: "ˈhɪstəri ˈɪznt maɪ ˈfeɪvərɪt ˈsʌbʤɪkt",
        translation: "History isn't my favourite subject",
      },
      {
        id: 84,
        expression: "Математика и история - не его любимые предметы",
        transcription: "mæθs ənd ˈhɪstəri ɑːnt ɪz ˈfeɪvərɪt ˈsʌbʤɪkts",
        translation: "Maths and history aren't his favourite subjects",
      },
      {
        id: 85,
        expression: "Их дочь не замужем",
        transcription: "ðeə ˈdɔːtər ˈɪznt ˈmærɪd",
        translation: "Their daughter isn't married",
      },
      {
        id: 86,
        expression: "Их дочери не замужем",
        transcription: "ðeə ˈdɔːtəz ɑːnt ˈmærɪd",
        translation: "Their daughters aren't married",
      },
      {
        id: 87,
        expression: "Их дочь замужем",
        transcription: "ðeə ˈdɔːtə z ˈmærɪd",
        translation: "Their daughter is married",
      },
      {
        id: 88,
        expression: "Экономика этой страны достаточно сильная",
        transcription: "ði i(ː)ˈkɒnəmi əv ðɪs ˈkʌntri z strɒŋ ɪˈnʌf",
        translation: "The economy of this country is strong enough",
      },
      {
        id: 89,
        expression: "Я вижу, что дверь не закрыта",
        transcription: "aɪ siː ðət ðə dɔːr ˈɪznt kləʊzd",
        translation: "I see that the door isn't closed",
      },
      {
        id: 90,
        expression: "Размер этих ботинок слишком маленький",
        transcription: "ðə saɪz əv ðiːz ʃuːz ɪz tuː smɔːl",
        translation: "The size of these shoes is too small",
      },
      {
        id: 91,
        expression: "Эти джинсы не дорогие",
        transcription: "ðiːz ʤiːnz ɑːnt ɪksˈpɛnsɪv",
        translation: "These jeans aren't expensive",
      },
      {
        id: 92,
        expression: "Я думаю, что английский язык не такой сложный",
        transcription: "aɪ θɪŋk ðət ˈɪŋglɪʃ ˈɪznt səʊ ˈdɪfɪkəlt",
        translation: "I think that English isn't so difficult",
      },
      {
        id: 93,
        expression: "Размер тех джинсов слишком большой",
        transcription: "ðə saɪz əv ðəʊz ʤiːnz ɪz tuː bɪg",
        translation: "The size of those jeans is too big",
      },
      {
        id: 94,
        expression: "Тот курс не достаточно эффективный",
        transcription: "ðæt kɔːs ˈɪznt ɪˈfɛktɪv ɪˈnʌf",
        translation: "That course isn't effective enough",
      },
      {
        id: 95,
        expression: "Я думаю, что деньги - это не все",
        transcription: "aɪ θɪŋk ðət ˈmʌni ˈɪznt ˈɛvrɪθɪŋ",
        translation: "I think that money isn't everything",
      },
      {
        id: 96,
        expression: "Рыба не свежая",
        transcription: "ðə fɪʃ ˈɪznt frɛʃ",
        translation: "The fish isn't fresh",
      },
      {
        id: 97,
        expression: "Этот певец не так популярен в настоящее время",
        transcription: "ðɪs ˈsɪŋər ˈɪznt səʊ ˈpɒpjʊlər ət ðə ˈprɛznt taɪm",
        translation: "This singer isn't so popular at the present time",
      },
      {
        id: 98,
        expression: "Качество тех уроков недостаточно высокое",
        transcription: "ðə ˈkwɒlɪti əv ðəʊz ˈlɛsnz ˈɪznt haɪ ɪˈnʌf",
        translation: "The quality of those lessons isn't high enough",
      },
      {
        id: 99,
        expression: "Я думаю, что те условия не идеальны",
        transcription: "aɪ θɪŋk ðət ðəʊz kənˈdɪʃənz ɑːnt ˈpɜːfɪkt",
        translation: "I think that those conditions aren't perfect",
      },
      {
        id: 100,
        expression: "Эти налоги не такие большие",
        transcription: "ðiːz ˈtæksɪz ɑːnt səʊ bɪg",
        translation: "These taxes aren't so big",
      },
      {
        id: 101,
        expression: "Эта песня не популярна в настоящее время",
        transcription: "ðɪs sɒŋ ˈɪznt ˈpɒpjʊlər ət ðə ˈprɛznt taɪm",
        translation: "This song isn't popular at the present time",
      },
      {
        id: 102,
        expression: "Математика не ее любимый предмет",
        transcription: "mæθs ˈɪznt hə ˈfeɪvərɪt ˈsʌbʤɪkt",
        translation: "Maths isn't her favourite subject",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Закрепление пройденного материала</h3>
</div>`,
    },
  ],
];
