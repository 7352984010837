export const contentLesson199 = [
  [
    {
      title: 'Урок 199. Adverbs and Adjectives. Part 2',
    },
    [
      {
        id: 1,
        expression: 'Он почти сдал экзамен',
        transcription: 'hiː ˈnɪəli pɑːst ði ɪgˈzæm',
        translation: 'He nearly passed the exam',
      },
      {
        id: 2,
        expression: 'Ты говоришь по-английски очень естественно',
        transcription: 'juː spiːk ˈɪŋglɪʃ ˈvɛri ˈnæʧrəli',
        translation: 'You speak English very naturally',
      },
      {
        id: 3,
        expression: 'Это прямой рейс в Нью-Йорк',
        transcription: 'ɪts ə dɪˈrɛkt flaɪt tuː njuː jɔːk',
        translation: "It's a direct flight to New York",
      },
      {
        id: 4,
        expression: 'Я был очень рад в самом деле',
        transcription: 'aɪ wɒz ˈvɛri glæd ɪnˈdiːd',
        translation: 'I was very glad indeed',
      },
      {
        id: 5,
        expression: 'Эти цветы очень хорошо пахнут',
        transcription: 'ðiːz ˈflaʊəz smɛl ˈvɛri gʊd',
        translation: 'These flowers smell very good',
      },
      {
        id: 6,
        expression: 'Его английский звучит очень естественно',
        transcription: 'hɪz ˈɪŋglɪʃ saʊndz ˈvɛri ˈnæʧrəl',
        translation: 'His English sounds very natural',
      },
      {
        id: 7,
        expression: 'Я хочу говорить по-английски быстро и правильно',
        transcription: 'aɪ wɒnt tuː spiːk ˈɪŋglɪʃ fɑːst ænd kəˈrɛktli',
        translation: 'I want to speak English fast and correctly',
      },
      {
        id: 8,
        expression: 'Кто сказал это правильно?',
        transcription: 'huː sɛd ɪt raɪt?',
        translation: 'Who said it right?',
      },
      {
        id: 9,
        expression: 'Это совершенно неправильно',
        transcription:
          'ɪts kəmˈpliːtli (ˈtəʊtli, ˈʌtəli) rɒŋɪts kəmˈpliːtli (ˈtəʊtli, ˈʌtəli) rɒŋ',
        translation: "It's completely (totally, utterly) wrong",
      },
      {
        id: 10,
        expression: 'В самом деле, ты прав',
        transcription: 'ɪnˈdiːd, jʊə raɪt',
        translation: "Indeed, you're right",
      },
      {
        id: 11,
        expression: 'Это правильно или неправильно?',
        transcription: 'ɪz ɪt raɪt ɔː rɒŋ?',
        translation: 'Is it right or wrong?',
      },
      {
        id: 12,
        expression: 'Это место довольно рядом',
        transcription: 'ðɪs pleɪs ɪz kwaɪt nɪə',
        translation: 'This place is quite near',
      },
      {
        id: 13,
        expression: 'Они слегка повысили цену',
        transcription: 'ðeɪ ɪnˈkriːst ðə praɪs ˈslaɪtli',
        translation: 'They increased the price slightly',
      },
      {
        id: 14,
        expression: 'Это высокооплачиваемая работа',
        transcription: 'ɪts ə ˈhaɪli peɪd ʤɒb',
        translation: "It's a highly paid job",
      },
      {
        id: 15,
        expression: 'Это правильно или неправильно?',
        transcription: 'ɪz ɪt kəˈrɛkt ɔːr ˌɪnkəˈrɛkt?',
        translation: 'Is it correct or incorrect?',
      },
      {
        id: 16,
        expression: 'Я почти получил этот балл',
        transcription: 'aɪ ˈnɪəli gɒt ðɪs skɔː',
        translation: 'I nearly got this score',
      },
      {
        id: 17,
        expression: 'Это рядом с этим местом',
        transcription: 'ɪt ɪz nɪə ðɪs pleɪs',
        translation: 'It is near this place',
      },
      {
        id: 18,
        expression: 'Это очень глубокое озеро',
        transcription: 'ɪts ə ˈvɛri diːp leɪk',
        translation: "It's a very deep lake",
      },
      {
        id: 19,
        expression: 'Это правильно или нет?',
        transcription: 'ɪz ɪt raɪt ɔː nɒt?',
        translation: 'Is it right or not?',
      },
      {
        id: 20,
        expression: 'Та еда очень плохо пахнет',
        transcription: 'ðæt fuːd smɛlz ˈvɛri bæd',
        translation: 'That food smells very bad',
      },
      {
        id: 21,
        expression: 'Она сказала это неправильно',
        transcription: 'ʃiː sɛd ɪt ˌɪnkəˈrɛktli',
        translation: 'She said it incorrectly',
      },
      {
        id: 22,
        expression: 'Здесь очень глубоко',
        transcription: 'ɪts ˈvɛri diːp hɪə',
        translation: "It's very deep here",
      },
      {
        id: 23,
        expression: 'У нее небольшая зубная боль',
        transcription: 'ʃiː hæz ə slaɪt ˈtuːθeɪk',
        translation: 'She has a slight toothache',
      },
      {
        id: 24,
        expression: 'В самом деле, все может измениться очень быстро',
        transcription: 'ɪnˈdiːd, ˈɛvrɪθɪŋ kæn ʧeɪnʤ ˈvɛri ˈkwɪkli',
        translation: 'Indeed, everything can change very quickly',
      },
      {
        id: 25,
        expression: 'Ты можешь сказать мне эту вещь напрямую',
        transcription: 'juː kæn tɛl miː ðɪs θɪŋ dɪˈrɛktli',
        translation: 'You can tell me this thing directly',
      },
      {
        id: 26,
        expression: 'Почему ты пришел так поздно?',
        transcription: 'waɪ dɪd juː kʌm səʊ leɪt?',
        translation: 'Why did you come so late?',
      },
      {
        id: 27,
        expression: 'Я почти достиг этой цели',
        transcription: 'aɪ ˈnɪəli əˈʧiːvd ðɪs eɪm',
        translation: 'I nearly achieved this aim',
      },
      {
        id: 28,
        expression: 'У него была небольшая головная боль',
        transcription: 'hiː hæd ə slaɪt ˈhɛdeɪk',
        translation: 'He had a slight headache',
      },
      {
        id: 29,
        expression: 'Все было неправильно',
        transcription: 'ˈɛvrɪθɪŋ wɒz rɒŋ',
        translation: 'Everything was wrong',
      },
      {
        id: 30,
        expression: 'Рыба плохо пахнет',
        transcription: 'ðə fɪʃ smɛlz bæd',
        translation: 'The fish smells bad',
      },
      {
        id: 31,
        expression: 'Это совершенно неправильное предложение',
        transcription: 'ɪts ə kəmˈpliːtli rɒŋ ˈsɛntəns',
        translation: "It's a completely wrong sentence",
      },
      {
        id: 32,
        expression: 'Была небольшая задержка',
        transcription: 'ðeə wɒz ə slaɪt dɪˈleɪ',
        translation: 'There was a slight delay',
      },
      {
        id: 33,
        expression: 'Она написала это правильно',
        transcription: 'ʃiː rəʊt ɪt kəˈrɛktli',
        translation: 'She wrote it correctly',
      },
      {
        id: 34,
        expression: 'Тебе не следует ехать так быстро',
        transcription: 'juː ʃʊdnt draɪv səʊ fɑːst',
        translation: "You shouldn't drive so fast",
      },
      {
        id: 35,
        expression: 'Она глубоко религиозный человек',
        transcription: 'ʃiːz ə ˈdiːpli rɪˈlɪʤəs ˈpɜːsn',
        translation: "She's a deeply religious person",
      },
      {
        id: 36,
        expression: 'Мальчик прыгнул очень высоко',
        transcription: 'ðə bɔɪ ʤʌmpt ˈvɛri haɪ',
        translation: 'The boy jumped very high',
      },
      {
        id: 37,
        expression: 'Здесь очень высоко',
        transcription: 'ɪts ˈvɛri haɪ hɪə',
        translation: "It's very high here",
      },
      {
        id: 38,
        expression: 'Я глубоко сожалею об этом',
        transcription: 'aɪ ˈdiːpli rɪˈgrɛt ɪt',
        translation: 'I deeply regret it',
      },
      {
        id: 39,
        expression: 'Он был прав на самом деле',
        transcription: 'hiː wɒz raɪt ɪnˈdiːd',
        translation: 'He was right indeed',
      },
      {
        id: 40,
        expression: 'Он сказал это неправильно',
        transcription: 'hiː sɛd ɪt ˈrɒŋli',
        translation: 'He said it wrongly',
      },
      {
        id: 41,
        expression: 'Несомненно, это играет очень важную роль в моей жизни',
        transcription:
          'ʌnˈdaʊtɪdli, ɪt pleɪz ə ˈvɛri ɪmˈpɔːtənt rəʊl ɪn maɪ laɪf',
        translation: 'Undoubtedly, it plays a very important role in my life',
      },
      {
        id: 42,
        expression: 'Только высококвалифицированные преподаватели работают там',
        transcription: 'ˈəʊnli ˈhaɪli prəˈfɛʃənl ˈtiːʧəz wɜːk ðeə',
        translation: 'Only highly professional teachers work there',
      },
      {
        id: 43,
        expression: 'Несомненно, это стоит нашего внимания',
        transcription: 'ʌnˈdaʊtɪdli, ɪts wɜːθ ˈaʊər əˈtɛnʃ(ə)n',
        translation: "Undoubtedly, it's worth our attention",
      },
      {
        id: 44,
        expression: 'Тем не менее, я смог сделать то, что хотел',
        transcription: 'ˌnɛvəðəˈlɛs, aɪ wɒz ˈeɪbl tuː duː wɒt aɪ ˈwɒntɪd',
        translation: 'Nevertheless, I was able to do what I wanted',
      },
      {
        id: 45,
        expression: 'Трудно поверить, что это может быть возможно',
        transcription: 'ɪts hɑːd tuː bɪˈliːv ðæt ɪt kæn biː ˈpɒsəbl',
        translation: "It's hard to believe that it can be possible",
      },
      {
        id: 46,
        expression: 'Несомненно, это стоит восхищения',
        transcription: 'ʌnˈdaʊtɪdli, ɪts wɜːθ ˌædməˈreɪʃ(ə)n',
        translation: "Undoubtedly, it's worth admiration",
      },
      {
        id: 47,
        expression: 'Ответ правильный или нет?',
        transcription: 'ɪz ɪt ði ˈɑːnsə kəˈrɛkt ɔː nɒt?',
        translation: 'Is it the answer correct or not?',
      },
      {
        id: 48,
        expression: 'Где ты был в последнее время?',
        transcription: 'weə hæv juː biːn ˈleɪtli?',
        translation: 'Where have you been lately?',
      },
      {
        id: 49,
        expression: 'Несомненно, это была настоящая сенсация',
        transcription: 'ʌnˈdaʊtɪdli, ɪt wɒz ə rɪəl sɛnˈseɪʃən',
        translation: 'Undoubtedly, it was a real sensation',
      },
      {
        id: 50,
        expression: 'Она ищет высокооплачиваемую работу',
        transcription: 'ʃiːz ˈlʊkɪŋ fɔːr ə ˈhaɪli peɪd ʤɒb',
        translation: "She's looking for a highly paid job",
      },
      {
        id: 51,
        expression: 'Тем не менее, это было немного несправедливо',
        transcription: 'ˌnɛvəðəˈlɛs, ɪt wɒz ə ˈlɪtl ʌnˈfeə',
        translation: 'Nevertheless, it was a little unfair',
      },
      {
        id: 52,
        expression: 'Он очень успешный предприниматель',
        transcription: 'hiːz ə ˈhaɪli səkˈsɛsfʊl ˌɒntrəprəˈnɜː',
        translation: "He's a highly successful entrepreneur",
      },
      {
        id: 53,
        expression: 'Это немного формальное предложение',
        transcription: 'ɪts ə ˈslaɪtli ˈfɔːməl ˈsɛntəns',
        translation: "It's a slightly formal sentence",
      },
      {
        id: 54,
        expression: 'Однако, эта ситуация может повториться',
        transcription: 'haʊˈɛvə, ðɪs ˌsɪtjʊˈeɪʃən kæn rɪˈpiːt ɪtˈsɛlf',
        translation: 'However, this situation can repeat itself',
      },
      {
        id: 55,
        expression: 'Что он делал в последнее время?',
        transcription: 'wɒt hæz hiː biːn ˈdu(ː)ɪŋ ˈleɪtli?',
        translation: 'What has he been doing lately?',
      },
      {
        id: 56,
        expression: 'Он высокообразованный человек',
        transcription: 'hiːz ə ˈhaɪli ˈɛʤu(ː)keɪtɪd ˈpɜːsn',
        translation: "He's a highly educated person",
      },
      {
        id: 57,
        expression: 'Он высокопрофессиональный работник',
        transcription: 'hiːz ə ˈhaɪli prəˈfɛʃənl ˈwɜːkə',
        translation: "He's a highly professional worker",
      },
      {
        id: 58,
        expression: 'Однако, мы полны оптимизма',
        transcription: 'haʊˈɛvə, wiː ɑː fʊl ɒv ˈɒptɪmɪzm',
        translation: 'However, we are full of optimism',
      },
      {
        id: 59,
        expression: 'Однако, мы полны энергии',
        transcription: 'haʊˈɛvə, wiː ɑː fʊl ɒv ˈɛnəʤi',
        translation: 'However, we are full of energy',
      },
      {
        id: 60,
        expression: 'Честно говоря, я полон оптимизма',
        transcription: 'ˈɒnɪstli, aɪ æm fʊl ɒv ˈɒptɪmɪzm',
        translation: 'Honestly, I am full of optimism',
      },
      {
        id: 61,
        expression: 'Я полностью согласен с тобой',
        transcription: 'aɪ ˈfʊli əˈgriː wɪð juː',
        translation: 'I fully agree with you',
      },
      {
        id: 62,
        expression: 'Я абсолютно согласен с тобой',
        transcription: 'aɪ ˈæbsəluːtli əˈgriː wɪð juː',
        translation: 'I absolutely agree with you',
      },
      {
        id: 63,
        expression: 'Отчасти я согласен с тобой',
        transcription: 'aɪ ˈpɑːtli əˈgriː wɪð juː',
        translation: 'I partly agree with you',
      },
      {
        id: 64,
        expression: 'Он очень сильно повредил ногу',
        transcription: 'hiː hɜːt hɪz lɛg ˈbædli',
        translation: 'He hurt his leg badly',
      },
      {
        id: 65,
        expression: 'Он очень сильно ушибся',
        transcription: 'hiː hɜːt hɪmˈsɛlf ˈvɛri ˈbædli',
        translation: 'He hurt himself very badly',
      },
      {
        id: 66,
        expression: 'Он сильно повредил руку?',
        transcription: 'dɪd hiː hɜːt hɪz ɑːm ˈbædli?',
        translation: 'Did he hurt his arm badly?',
      },
      {
        id: 67,
        expression: 'Несомненно, это был огромный успех',
        transcription: 'ʌnˈdaʊtɪdli, ɪt wɒz ə hjuːʤ səkˈsɛs',
        translation: 'Undoubtedly, it was a huge success',
      },
      {
        id: 68,
        expression: 'Несомненно, это настоящий успех',
        transcription: 'ʌnˈdaʊtɪdli. ɪts ə rɪəl səkˈsɛs',
        translation: "Undoubtedly, it's a real success",
      },
      {
        id: 69,
        expression: 'Тем не менее, это был отличный результат',
        transcription: 'ˌnɛvəðəˈlɛs, ɪt wɒz ə greɪt rɪˈzʌlt',
        translation: 'Nevertheless, it was a great result',
      },
      {
        id: 70,
        expression: 'Я частично согласно с ним',
        transcription: 'aɪ ˈpɑːtli əˈgriː wɪð hɪm',
        translation: 'I partly agree with him',
      },
      {
        id: 71,
        expression: 'Я полностью согласен с ним',
        transcription: 'aɪ ˈfʊli əˈgriː wɪð hɪm',
        translation: 'I fully agree with him',
      },
      {
        id: 72,
        expression: 'Я абсолютно согласен с ним',
        transcription: 'aɪ ˈæbsəluːtli əˈgriː wɪð hɪm',
        translation: 'I absolutely agree with him',
      },
      {
        id: 73,
        expression: 'Наконец, я добрался до этого места',
        transcription: 'ˈfaɪnəli, aɪ gɒt tuː ðɪs pleɪs',
        translation: 'Finally, I got to this place',
      },
      {
        id: 74,
        expression: 'Наконец, я покинул это место',
        transcription: 'ˈfaɪnəli, aɪ lɛft ðɪs pleɪs',
        translation: 'Finally, I left this place',
      },
      {
        id: 75,
        expression: 'Наконец, я закончил свою работу',
        transcription: 'ˈfaɪnəli, aɪ ˈfɪnɪʃt maɪ wɜːk',
        translation: 'Finally, I finished my work',
      },
      {
        id: 76,
        expression: 'Тем не менее, мне удалось это сделать',
        transcription: 'ˌnɛvəðəˈlɛs, aɪ ˈmænɪʤd tuː duː ɪt',
        translation: 'Nevertheless, I managed to do it',
      },
      {
        id: 77,
        expression: 'Тем не менее, я предпочел этого не делать',
        transcription: 'ˌnɛvəðəˈlɛs, aɪ prɪˈfɜːd nɒt tuː duː ɪt',
        translation: 'Nevertheless, I preferred not to do it',
      },
      {
        id: 78,
        expression: 'Тем не менее, мне удалось заработать эти деньги',
        transcription: 'ˌnɛvəðəˈlɛs, aɪ ˈmænɪʤd tuː ɜːn ðɪs ˈmʌni',
        translation: 'Nevertheless, I managed to earn this money',
      },
      {
        id: 79,
        expression: 'Это было сказано неправильно',
        transcription: 'ɪt wɒz sɛd ˈrɒŋli (ˌɪnkəˈrɛktli)',
        translation: 'It was said wrongly (incorrectly)',
      },
      {
        id: 80,
        expression: 'Это было сказано правильно',
        transcription: 'ɪt wɒz sɛd kəˈrɛktli',
        translation: 'It was said correctly',
      },
      {
        id: 81,
        expression: 'Это было написано неправильно',
        transcription: 'ɪt wɒz ˈrɪtn ˈrɒŋli / ɪt wɒz ˈrɪtn ˌɪnkəˈrɛktli',
        translation: 'It was written wrongly / It was written incorrectly',
      },
      {
        id: 82,
        expression: 'Это было написано правильно',
        transcription: 'ɪt wɒz ˈrɪtn kəˈrɛktli',
        translation: 'It was written correctly',
      },
      {
        id: 83,
        expression: 'Она посещает это место довольно часто',
        transcription: 'ʃiː ˈvɪzɪts ðɪs pleɪs kwaɪt ˈɒf(ə)n',
        translation: 'She visits this place quite often',
      },
      {
        id: 84,
        expression: 'Это очень эффективный метод',
        transcription: 'ɪts ə ˈhaɪli ɪˈfɛktɪv ˈmɛθəd',
        translation: "It's a highly effective method",
      },
      {
        id: 85,
        expression: 'Я, наконец, получил его разрешение',
        transcription: 'aɪ ˈfaɪnəli gɒt hɪz pəˈmɪʃən',
        translation: 'I finally got his permission',
      },
      {
        id: 86,
        expression: 'У него легкий иностранный акцент',
        transcription: 'hiː hæz ə slaɪt ˈfɒrɪn ˈæksənt',
        translation: 'He has a slight foreign accent',
      },
      {
        id: 87,
        expression: 'Однако, ситуация все еще нестабильна',
        transcription: 'haʊˈɛvə, ðə ˌsɪtjʊˈeɪʃən ɪz stɪl ʌnˈsteɪbl',
        translation: 'However, the situation is still unstable',
      },
      {
        id: 88,
        expression: 'Он очень успешный бизнесмен',
        transcription: 'hiːz ə ˈvɛri səkˈsɛsfʊl ˈbɪznɪsmən',
        translation: "He's a very successful businessman",
      },
      {
        id: 89,
        expression: 'Это было сказано правильно или неправильно?',
        transcription: 'wɒz ɪt sɛd kəˈrɛktli ɔːr ˌɪnkəˈrɛktli?',
        translation: 'Was it said correctly or incorrectly?',
      },
      {
        id: 90,
        expression: 'Кто-то очень сильно его избил',
        transcription: 'ˈsʌmwʌn biːt hɪm ˈvɛri ˈbædli',
        translation: 'Someone beat him very badly',
      },
      {
        id: 91,
        expression: 'Он высокопрофессиональный учитель',
        transcription: 'hiː ɪz ə ˈhaɪli prəˈfɛʃənl ˈtiːʧə',
        translation: 'He is a highly professional teacher',
      },
      {
        id: 92,
        expression: 'Он ходит в спортзал довольно регулярно',
        transcription: 'hiː gəʊz tuː ðə ʤɪm kwaɪt ˈrɛgjʊləli',
        translation: 'He goes to the gym quite regularly',
      },
      {
        id: 93,
        expression: 'Все эти вещи были сделаны неправильно',
        transcription: 'ɔːl ðiːz θɪŋz wɜː dʌn ˈrɒŋli',
        translation: 'All these things were done wrongly',
      },
      {
        id: 94,
        expression: 'Наконец, она все осознала',
        transcription: 'ˈfaɪnəli, ʃiː ˈrɪəlaɪzd ˈɛvrɪθɪŋ',
        translation: 'Finally, she realised everything',
      },
      {
        id: 95,
        expression: 'Это было сделано правильно или неправильно?',
        transcription: 'wɒz ɪt dʌn kəˈrɛktli ɔːr ˌɪnkəˈrɛktli?',
        translation: 'Was it done correctly or incorrectly?',
      },
      {
        id: 96,
        expression: 'Это немного неформальный ответ',
        transcription: 'ɪts ə ˈslaɪtli ɪnˈfɔːml ˈɑːnsə',
        translation: "It's a slightly informal answer",
      },
      {
        id: 97,
        expression: 'Он частично согласен с этим утверждением',
        transcription: 'hiː ˈpɑːtli əˈgriːz wɪð ðɪs ˈsteɪtmənt',
        translation: 'He partly agrees with this statement',
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Закрепление пройденного материала</h3>
</div>`,
    },
  ],
];
