export const contentLesson106 = [
  [
    {
      title: "Урок 106. Устойчивые фразы с предлогами",
    },
    [
      {
        id: 1,
        expression: "Это место на юге",
        transcription: "ðɪs pleɪs ɪz ɪn ðə saʊθ",
        translation: "This place is in the south",
      },
      {
        id: 2,
        expression: "Прежде всего, я хотел бы поблагодарить его за все",
        transcription: "fɜːst əv ɔːl, aɪd laɪk tə θæŋk ɪm fər ˈɛvrɪθɪŋ",
        translation: "First of all, I'd like to thank him for everything",
      },
      {
        id: 3,
        expression: "Он, наконец, сдал этот экзамен",
        transcription: "hi pɑːst ðɪs ɪgˈzæm ət lɑːst",
        translation: "He passed this exam at last",
      },
      {
        id: 4,
        expression: "Я много говорил по телефону в прошлом",
        transcription: "aɪ tɔːkt ɒn ðə fəʊn ə lɒt ɪn ðə pɑːst",
        translation: "I talked on the phone a lot in the past",
      },
      {
        id: 5,
        expression: "Он уехал в Китай по делам",
        transcription: "hi wɛnt tə ˈʧaɪnə ɒn ˈbɪznɪs",
        translation: "He went to China on business",
      },
      {
        id: 6,
        expression: "Это на юге",
        transcription: "ɪts ɪn ðə saʊθ",
        translation: "It's in the south",
      },
      {
        id: 7,
        expression: "Программа начнется в полночь",
        transcription: "ðə ˈprəʊgræm wɪl stɑːt ət ˈmɪdnaɪt",
        translation: "The program will start at midnight",
      },
      {
        id: 8,
        expression: "Он делает это для удовольствия",
        transcription: "hi dəz ɪt fə ˈplɛʒə",
        translation: "He does it for pleasure",
      },
      {
        id: 9,
        expression: "Это место на севере",
        transcription: "ðɪs pleɪs ɪz ɪn ðə nɔːθ",
        translation: "This place is in the north",
      },
      {
        id: 10,
        expression: "Я приду туда вовремя",
        transcription: "aɪl kʌm ðeər ɒn taɪm",
        translation: "I'll come there on time",
      },
      {
        id: 11,
        expression: "Мне нужно проверить эту информацию в первую очередь",
        transcription: "aɪ niːd tə ʧɛk ðɪs ˌɪnfəˈmeɪʃən fɜːst əv ɔːl",
        translation: "I need to check this information first of all",
      },
      {
        id: 12,
        expression: "Тебе не нужно учить все эти предложения наизусть",
        transcription: "jʊ dəʊnt niːd tə lɜːn ɔːl ðiːz ˈsɛntənsɪz baɪ hɑːt",
        translation: "You don't need to learn all these sentences by heart",
      },
      {
        id: 13,
        expression: "Прежде всего, я хотел бы поблагодарить тебя за все",
        transcription: "fɜːst əv ɔːl, aɪd laɪk tə θæŋk jʊ fər ˈɛvrɪθɪŋ",
        translation: "First of all, I'd like to thank you for everything",
      },
      {
        id: 14,
        expression: "Я позвонил ему по ошибке",
        transcription: "aɪ kɔːld ɪm baɪ mɪsˈteɪk",
        translation: "I called him by mistake",
      },
      {
        id: 15,
        expression: "Я вернусь до полуночи",
        transcription: "aɪl bi bæk bɪˈfɔː ˈmɪdnaɪt",
        translation: "I'll be back before midnight",
      },
      {
        id: 16,
        expression: "Это на востоке",
        transcription: "ɪts ɪn ði iːst",
        translation: "It's in the east",
      },
      {
        id: 17,
        expression: "Он знает эту песню наизусть",
        transcription: "hi nəʊz ðɪs sɒŋ baɪ hɑːt",
        translation: "He knows this song by heart",
      },
      {
        id: 18,
        expression: "Мне сначала не понравилось это место",
        transcription:
          "aɪ dɪdnt laɪk ðɪs pleɪs ət fɜːst / aɪ dɪdnt laɪk ðɪs pleɪs ɪn ðə bɪˈgɪnɪŋ",
        translation:
          "I didn't like this place at first / I didn't like this place in the beginning",
      },
      {
        id: 19,
        expression: "На самом деле это не так",
        transcription: "ɪn fækt, ɪt ˈɪznt səʊ",
        translation: "In fact, it isn't so",
      },
      {
        id: 20,
        expression: "Это на севере",
        transcription: "ɪts ɪn ðə nɔːθ",
        translation: "It's in the north",
      },
      {
        id: 21,
        expression: "Они, наконец, выиграли",
        transcription: "ðeɪ wʌn ət lɑːst / ðeɪ wʌn ˈfaɪnəli",
        translation: "They won at last / They won finally",
      },
      {
        id: 22,
        expression: "Мы могли бы встретиться в полдень",
        transcription: "wi kəd miːt ət ˈmɪddeɪ / wi kəd miːt ət nuːn",
        translation: "We could meet at midday / We could meet at noon",
      },
      {
        id: 23,
        expression: "Поначалу мне это не понравилось",
        transcription: "ət fɜːst, aɪ dɪdnt laɪk ɪt",
        translation: "At first, I didn't like it",
      },
      {
        id: 24,
        expression: "Что мы будем есть на обед?",
        transcription: "wɒt wɪl wi iːt fə lʌnʧ?",
        translation: "What will we eat for lunch?",
      },
      {
        id: 25,
        expression: "Церковь находится на востоке",
        transcription: "ðə ʧɜːʧ ɪz ɪn ði iːst",
        translation: "The church is in the east",
      },
      {
        id: 26,
        expression: "Она выучила этот текст наизусть",
        transcription: "ʃi lɜːnt ðɪs tɛkst baɪ hɑːt",
        translation: "She learnt this text by heart",
      },
      {
        id: 27,
        expression: "Он на диете",
        transcription: "hiːz ɒn ə ˈdaɪət",
        translation: "He's on a diet",
      },
      {
        id: 28,
        expression: "Это находится на западе",
        transcription: "ɪts ɪn ðə wɛst",
        translation: "It's in the west",
      },
      {
        id: 29,
        expression: "Я отправил это тебе по ошибке",
        transcription: "aɪ sɛnt ɪt tə jʊ baɪ mɪsˈteɪk",
        translation: "I sent it to you by mistake",
      },
      {
        id: 30,
        expression: "Я поехал в Соединенные Штаты по делам",
        transcription: "aɪ wɛnt tə ðə jʊˈnaɪtɪd steɪts ɒn ˈbɪznɪs",
        translation: "I went to the United States on business",
      },
      {
        id: 31,
        expression: "Это произошло случайно",
        transcription: "ɪt ˈhæpənd baɪ ʧɑːns",
        translation: "It happened by chance",
      },
      {
        id: 32,
        expression: "Я встретил его там случайно",
        transcription: "aɪ mɛt ɪm ðeə baɪ ʧɑːns",
        translation: "I met him there by chance",
      },
      {
        id: 33,
        expression: "Что было на завтрак?",
        transcription: "wɒt wəz fə ˈbrɛkfəst?",
        translation: "What was for breakfast?",
      },
      {
        id: 34,
        expression: "Аэропорт находится на юге",
        transcription: "ði ˈeəpɔːt s ɪn ðə saʊθ",
        translation: "The airport is in the south",
      },
      {
        id: 35,
        expression: "Что ты будешь есть на обед?",
        transcription: "wɒt wɪl jʊ iːt fə lʌnʧ?",
        translation: "What will you eat for lunch?",
      },
      {
        id: 36,
        expression: "Бар находится на западе",
        transcription: "ðə bɑː z ɪn ðə wɛst",
        translation: "The bar is in the west",
      },
      {
        id: 37,
        expression: "На самом деле, это очень сложное решение",
        transcription: "ɪn fækt, ɪts ə ˈvɛri ˈdɪfɪkəlt dɪˈsɪʒən",
        translation: "In fact, it's a very difficult decision",
      },
      {
        id: 38,
        expression: "Этот собор находится на севере",
        transcription: "ðɪs kəˈθiːdrəl z ɪn ðə nɔːθ",
        translation: "This cathedral is in the north",
      },
      {
        id: 39,
        expression: "Я в основном учу английский для удовольствия",
        transcription: "aɪ ˈməʊstli lɜːn ˈɪŋglɪʃ fə ˈplɛʒə",
        translation: "I mostly learn English for pleasure",
      },
      {
        id: 40,
        expression: "Я отправил это тебе по ошибке",
        transcription: "aɪ sɛnt jʊ ɪt baɪ mɪsˈteɪk",
        translation: "I sent you it by mistake",
      },
      {
        id: 41,
        expression: "Мы можем встретиться в полночь",
        transcription: "wi kən miːt ət ˈmɪdnaɪt",
        translation: "We can meet at midnight",
      },
      {
        id: 42,
        expression: "Мы разговаривали по телефону",
        transcription: "wi tɔːkt ɒn ðə fəʊn",
        translation: "We talked on the phone",
      },
      {
        id: 43,
        expression:
          "Он предпочитает изучать английский язык самостоятельно, потому что он находит это очень эффективным",
        transcription:
          "hi priˈfɜːz tə lɜːn ˈɪŋglɪʃ ɒn ɪz əʊn bɪˈkəz hi faɪndz ɪt ˈvɛri ɪˈfɛktɪv",
        translation:
          "He prefers to learn English on his own because he finds it very effective",
      },
      {
        id: 44,
        expression: "Могу я заплатить кредитной картой?",
        transcription: "kən aɪ peɪ baɪ ˈkrɛdɪt kɑːd?",
        translation: "Can I pay by credit card?",
      },
      {
        id: 45,
        expression: "Я не думаю, что он в опасности",
        transcription: "aɪ dəʊnt θɪŋk ðət hiːz ɪn ˈdeɪnʤə",
        translation: "I don't think that he's in danger",
      },
      {
        id: 46,
        expression: "Во всяком случае, тебе нужно подумать об этом",
        transcription:
          "ət ˈɛni reɪt, jʊ niːd tə θɪŋk əˈbaʊt ɪt / ɪn ˈɛni keɪs, jʊ niːd tə θɪŋk əˈbaʊt ɪt",
        translation:
          "At any rate, you need to think about it / In any case, you need to think about it",
      },
      {
        id: 47,
        expression: "Мы обсуждали это в начале",
        transcription: "wi dɪsˈkʌst ɪt ɪn ðə bɪˈgɪnɪŋ",
        translation: "We discussed it in the beginning",
      },
      {
        id: 48,
        expression: "Это было в начале",
        transcription: "ɪt wəz ɪn ðə bɪˈgɪnɪŋ",
        translation: "It was in the beginning",
      },
      {
        id: 49,
        expression: "В целом (в общем), это очень эффективная мера",
        transcription: "ɒn ðə həʊl (ɪn ˈʤɛnərəl), ɪts ə ˈvɛri ɪˈfɛktɪv ˈmɛʒə",
        translation: "On the whole (in general), it's a very effective measure",
      },
      {
        id: 50,
        expression: "Например, ты можешь сделать это сегодня",
        transcription: "fər ɪgˈzɑːmpl, jʊ kən dʊ ɪt təˈdeɪ",
        translation: "For example, you can do it today",
      },
      {
        id: 51,
        expression: "Он очень занят в данный момент",
        transcription: "hiːz ˈvɛri ˈbɪzi ət ðə ˈməʊmənt",
        translation: "He's very busy at the moment",
      },
      {
        id: 52,
        expression: "В общем (в целом), результат удовлетворительный",
        transcription: "ɪn ˈʤɛnərəl (ɒn ðə həʊl), ðə rɪˈzʌlt ɪz ˌsætɪsˈfæktəri",
        translation: "In general (on the whole), the result is satisfactory",
      },
      {
        id: 53,
        expression: "Она на очень строгой диете",
        transcription: "ʃiːz ɒn ə ˈvɛri strɪkt ˈdaɪət",
        translation: "She's on a very strict diet",
      },
      {
        id: 54,
        expression: "В общем, это очень хорошая идея",
        transcription: "ɪn ˈʤɛnərəl, ɪts ə ˈvɛri gʊd aɪˈdɪə",
        translation: "In general, it's a very good idea",
      },
      {
        id: 55,
        expression: "Он сделал это нарочно",
        transcription: "hi dɪd ɪt ɒn ˈpɜːpəs",
        translation: "He did it on purpose",
      },
      {
        id: 56,
        expression: "В любом случае, мне придется идти туда",
        transcription: "ɪn ˈɛni keɪs (ət ˈɛni reɪt), aɪl həv tə gəʊ ðeə",
        translation: "In any case (at any rate), I'll have to go there",
      },
      {
        id: 57,
        expression: "Я видел это в начале фильма",
        transcription: "aɪ sɔː ɪt ət ðə bɪˈgɪnɪŋ əv ðə ˈmuːvi",
        translation: "I saw it at the beginning of the movie",
      },
      {
        id: 58,
        expression: "Например, ты можешь попробовать сделать другую вещь",
        transcription: "fər ɪgˈzɑːmpl, jʊ kən traɪ tə dʊ əˈnʌðə θɪŋ",
        translation: "For example, you can try to do another thing",
      },
      {
        id: 59,
        expression: "В любом случае, ты можешь попробовать сделать другую вещь",
        transcription:
          "ət ˈɛni reɪt (ɪn ˈɛni keɪs, ˈɛnɪweɪ), jʊ kən traɪ tə dʊ əˈnʌðə θɪŋ",
        translation:
          "At any rate (in any case, anyway), you can try to do another thing",
      },
      {
        id: 60,
        expression: "Конечно, ты можешь попробовать сделать другую вещь",
        transcription: "əv kɔːs (ˈsɜːtnli), jʊ kən traɪ dʊ dʊ əˈnʌðə θɪŋ",
        translation: "Of course (certainly), you can try do do another thing",
      },
      {
        id: 61,
        expression: "В общем, отель достаточно хороший",
        transcription: "ɪn ˈʤɛnərəl, ðə həʊˈtɛl z kwaɪt gʊd",
        translation: "In general, the hotel is quite good",
      },
      {
        id: 62,
        expression: "В целом, отель достаточно хороший",
        transcription: "ɒn ðə həʊl, ðə həʊˈtɛl z gʊd ɪˈnʌf",
        translation: "On the whole, the hotel is good enough",
      },
      {
        id: 63,
        expression: "Я думаю, что отель достаточно хороший",
        transcription: "aɪ θɪŋk, ðæt ðə həʊˈtɛl z gʊd ɪˈnʌf",
        translation: "I think, that the hotel is good enough",
      },
      {
        id: 64,
        expression: "Мы можем пойти туда пешком",
        transcription: "wi kən gəʊ ðeər ɒn fʊt",
        translation: "We can go there on foot",
      },
      {
        id: 65,
        expression: "Мы не можем пойти туда пешком",
        transcription: "wi kɑːnt gəʊ ðeər ɒn fʊt",
        translation: "We can't go there on foot",
      },
      {
        id: 66,
        expression: "Мы можем пойти туда пешком?",
        transcription: "kən wi gəʊ ðeər ɒn fʊt?",
        translation: "Can we go there on foot?",
      },
      {
        id: 67,
        expression: "Мне придется сделать это в любом случае",
        transcription: "aɪl həv tə dʊ ɪt ɪn ˈɛni keɪs",
        translation: "I'll have to do it in any case",
      },
      {
        id: 68,
        expression: "Я должен сделать это в любом случае",
        transcription: "aɪ məst dʊ ɪt ət ˈɛni reɪt",
        translation: "I must do it at any rate",
      },
      {
        id: 69,
        expression: "Мне нужно сделать это в любом случае",
        transcription: "aɪ niːd tə dʊ ɪt ɪn ˈɛni keɪs",
        translation: "I need to do it in any case",
      },
      {
        id: 70,
        expression: "Мы обсудили это в конце нашего разговора",
        transcription: "wi dɪsˈkʌst ɪt ət ði ɛnd əv ˈaʊə ˌkɒnvəˈseɪʃən",
        translation: "We discussed it at the end of our conversation",
      },
      {
        id: 71,
        expression: "Мы обсудили это в конце",
        transcription: "wi dɪsˈkʌst ɪt ɪn ði ɛnd",
        translation: "We discussed it in the end",
      },
      {
        id: 72,
        expression: "Мы обсудили это в конце нашей встречи",
        transcription: "wi dɪsˈkʌst ɪt ət ði ɛnd əv ˈaʊə ˈmiːtɪŋ",
        translation: "We discussed it at the end of our meeting",
      },
      {
        id: 73,
        expression: "Он ходил в школу пешком",
        transcription: "hi wɛnt tə skuːl ɒn fʊt",
        translation: "He went to school on foot",
      },
      {
        id: 74,
        expression: "Он пошел туда пешком",
        transcription: "hi wɛnt ðeər ɒn fʊt",
        translation: "He went there on foot",
      },
      {
        id: 75,
        expression: "Он ходил туда пешком?",
        transcription: "dɪd hi gəʊ ðeər ɒn fʊt?",
        translation: "Did he go there on foot?",
      },
      {
        id: 76,
        expression: "Я встретил его в конце нашей поездки",
        transcription: "aɪ mɛt ɪm ət ði ɛnd əv ˈaʊə trɪp",
        translation: "I met him at the end of our trip",
      },
      {
        id: 77,
        expression: "Я встретил его в конце",
        transcription: "aɪ mɛt ɪm ɪn ði ɛnd",
        translation: "I met him in the end",
      },
      {
        id: 78,
        expression: "Я встретил ее в конце дня",
        transcription: "aɪ mɛt hər ət ði ɛnd əv ðə deɪ",
        translation: "I met her at the end of the day",
      },
      {
        id: 79,
        expression: "Я пойду туда пешком",
        transcription: "aɪl gəʊ ðeər ɒn fʊt",
        translation: "I'll go there on foot",
      },
      {
        id: 80,
        expression: "Ты поедешь туда на машине?",
        transcription: "wɪl jʊ gəʊ ðeə baɪ kɑː?",
        translation: "Will you go there by car?",
      },
      {
        id: 81,
        expression: "Я поеду туда на машине",
        transcription: "aɪl gəʊ ðeə baɪ kɑː",
        translation: "I'll go there by car",
      },
      {
        id: 82,
        expression: "Это случилось с ним в конце",
        transcription: "ɪt ˈhæpənd tə ɪm ɪn ði ɛnd",
        translation: "It happened to him in the end",
      },
      {
        id: 83,
        expression: "Это случилось с ним в конце нашей встречи",
        transcription: "ɪt ˈhæpənd tə ɪm ət ði ɛnd əv ˈaʊə ˈmiːtɪŋ",
        translation: "It happened to him at the end of our meeting",
      },
      {
        id: 84,
        expression: "Когда это случилось с ним?",
        transcription: "wɛn dɪd ɪt ˈhæpən tə hɪm?",
        translation: "When did it happen to him?",
      },
      {
        id: 85,
        expression: "Мы можем это видеть внизу страницы",
        transcription: "wi kən siː ɪt ət ðə ˈbɒtəm əv ðə peɪʤ",
        translation: "We can see it at the bottom of the page",
      },
      {
        id: 86,
        expression: "Мы видели это внизу той страницы",
        transcription: "wi sɔː ɪt ət ðə ˈbɒtəm əv ðət peɪʤ",
        translation: "We saw it at the bottom of that page",
      },
      {
        id: 87,
        expression: "Мы можем увидеть эту информацию внизу страницы",
        transcription: "wi kən siː ðɪs ˌɪnfəˈmeɪʃən ət ðə ˈbɒtəm əv ðə peɪʤ",
        translation: "We can see this information at the bottom of the page",
      },
      {
        id: 88,
        expression: "В целом, эта работа вполне удовлетворительная",
        transcription: "ɒn ðə həʊl, ðɪs wɜːk s kwaɪt ˌsætɪsˈfæktəri",
        translation: "On the whole, this work is quite satisfactory",
      },
      {
        id: 89,
        expression: "Пошли туда пешком!",
        transcription: "lɛts gəʊ ðeər ɒn fʊt!",
        translation: "Let's go there on foot!",
      },
      {
        id: 90,
        expression: "Он обычно платит наличными",
        transcription: "hi ˈjuːʒʊəli peɪz ɪn kæʃ",
        translation: "He usually pays in cash",
      },
      {
        id: 91,
        expression: "Я думаю, он сделал это нарочно",
        transcription: "aɪ θɪŋk, hi dɪd ɪt ɒn ˈpɜːpəs",
        translation: "I think, he did it on purpose",
      },
      {
        id: 92,
        expression: "Он в любом случае не сможет сделать это",
        transcription: "hi wəʊnt bi ˈeɪbl tə dʊ ɪt ət ˈɛni reɪt",
        translation: "He won't be able to do it at any rate",
      },
      {
        id: 93,
        expression: "На мой взгляд, это было правильное решение",
        transcription: "ɪn maɪ əˈpɪnjən, ɪt wəz ðə raɪt dɪˈsɪʒən",
        translation: "In my opinion, it was the right decision",
      },
      {
        id: 94,
        expression: "В целом, эти меры очень эффективны",
        transcription: "ɒn ðə həʊl, ðiːz ˈmɛʒəz ə ˈvɛri ɪˈfɛktɪv",
        translation: "On the whole, these measures are very effective",
      },
      {
        id: 95,
        expression: "Это внизу страницы",
        transcription: "ɪts ət ðə ˈbɒtəm əv ðə peɪʤ",
        translation: "It's at the bottom of the page",
      },
      {
        id: 96,
        expression: "Добро пожаловать в любом случае!",
        transcription: "jʊə ˈwɛlkəm ɪn ˈɛni keɪs!",
        translation: "You're welcome in any case!",
      },
      {
        id: 97,
        expression: "Я сделаю это в любом случае",
        transcription: "!aɪl dʊ ɪt ət ˈɛni reɪt",
        translation: "I'll do it at any rate",
      },
      {
        id: 98,
        expression: "Я предпочитаю учить английский самостоятельно",
        transcription: "aɪ priˈfɜː tə lɜːn ˈɪŋglɪʃ ɒn maɪ əʊn",
        translation: "I prefer to learn English on my own",
      },
      {
        id: 99,
        expression: "Я решил пойти в отпуск",
        transcription: "aɪ dɪˈsaɪdɪd tə gəʊ ɒn ˈhɒlədeɪ",
        translation: "I decided to go on holiday",
      },
      {
        id: 100,
        expression: "Я заплачу кредитной картой",
        transcription: "aɪl peɪ baɪ ˈkrɛdɪt kɑːd",
        translation: "I'll pay by credit card",
      },
      {
        id: 101,
        expression: "По моему мнению, это блестящая идея",
        transcription: "ɪn maɪ əˈpɪnjən, ɪts ə ˈbrɪljənt aɪˈdɪə",
        translation: "In my opinion, it's a brilliant idea",
      },
      {
        id: 102,
        expression: "Я заплачу наличными",
        transcription: "aɪl peɪ ɪn kæʃ",
        translation: "I'll pay in cash",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>in the south</h3>
<p>- на юге</p>
<p>We are in the south - Мы на юге.</p>
<h3>first of all</h3>
<p>- прежде всего</p>
<p>First of all, I'd like to thank him for everything - Прежде всего, я хотел бы поблагодарить его за все.</p>
</div>`,
    },
  ],
];
