export const contentLesson70 = [
  [
    {
      title:
        'Урок 70. Present Simple: verb + глагол to be: Special Question with What, Who, Which',
    },
    [
      {
        id: 1,
        expression: 'Что шокирует тебя?',
        transcription: 'wɒt ʃɒks juː?',
        translation: 'What shocks you?',
      },
      {
        id: 2,
        expression: 'Кто поддерживает его?',
        transcription: 'huː səˈpɔːts hɪm?',
        translation: 'Who supports him?',
      },
      {
        id: 3,
        expression: 'Что ты знаешь об этом?',
        transcription: 'wɒt dʊ jʊ nəʊ əˈbaʊt ɪt?',
        translation: 'What do you know about it?',
      },
      {
        id: 4,
        expression: 'Что он читает каждый день?',
        transcription: 'wɒt dəz hi riːd ˈɛvri deɪ?',
        translation: 'What does he read every day?',
      },
      {
        id: 5,
        expression: 'Кого ты поддерживаешь?',
        transcription: 'huː dʊ jʊ səˈpɔːt?',
        translation: 'Who do you support?',
      },
      {
        id: 6,
        expression: 'Кого он больше любит?',
        transcription: 'huː dəz hi lʌv mɔː?',
        translation: 'Who does he love more?',
      },
      {
        id: 7,
        expression: 'Что это?',
        transcription: 'wɒt s ɪt?',
        translation: 'What is it?',
      },
      {
        id: 8,
        expression: 'Кто здесь?',
        transcription: 'huː z hɪə?',
        translation: 'Who is here?',
      },
      {
        id: 9,
        expression: 'Который твой дом?',
        transcription: 'wɪʧ ɪz jə haʊs?',
        translation: 'Which is your house?',
      },
      {
        id: 10,
        expression: 'Что тебе больше помогает?',
        transcription: 'wɒt hɛlps jʊ mɔː?',
        translation: 'What hepls you more?',
      },
      {
        id: 11,
        expression: 'Что беспокоит тебя?',
        transcription: 'wɒt ˈwʌriz juː?',
        translation: 'What worries you?',
      },
      {
        id: 12,
        expression: 'Что его больше интересует?',
        transcription: 'wɒt ˈɪntrɪsts ɪm mɔː?',
        translation: 'What interests him more?',
      },
      {
        id: 13,
        expression: 'Кто тебе рассказывает эту вещь?',
        transcription: 'huː tɛlz jʊ ðɪs θɪŋ?',
        translation: 'Who tells you this thing?',
      },
      {
        id: 14,
        expression: 'Что там такого шокирующего?',
        transcription: 'wɒt s səʊ ˈʃɒkɪŋ ðeə?',
        translation: 'What is so shocking there?',
      },
      {
        id: 15,
        expression: 'Какие видео тебе нравятся больше?',
        transcription: 'wɒt ˈvɪdɪəʊz dʊ jʊ laɪk mɔː?',
        translation: 'What videos do you like more?',
      },
      {
        id: 16,
        expression: 'Что тебя больше всего пугает?',
        transcription: 'wɒt ˈfraɪtnz jʊ məʊst əv ɔːl?',
        translation: 'What frightens you most of all?',
      },
      {
        id: 17,
        expression: 'Что тебя больше мотивирует?',
        transcription: 'wɒt ˈməʊtɪveɪts jʊ mɔː?',
        translation: 'What motivates you more?',
      },
      {
        id: 18,
        expression: 'Кто ходит туда с тобой?',
        transcription: 'huː gəʊz ðeə wɪð juː?',
        translation: 'Who goes there with you?',
      },
      {
        id: 19,
        expression: 'Что кажется таким удивительным?',
        transcription: 'wɒt siːmz səʊ səˈpraɪzɪŋ?',
        translation: 'What seems so surprising?',
      },
      {
        id: 20,
        expression: 'Кто так думает?',
        transcription: 'huː θɪŋks səʊ?',
        translation: 'Who thinks so?',
      },
      {
        id: 21,
        expression: 'Что действительно помогает тебе?',
        transcription: 'wɒt ˈrɪəli hɛlps juː?',
        translation: 'What really hepls you?',
      },
      {
        id: 22,
        expression: 'Кто ему верит?',
        transcription: 'huː bɪˈliːvz hɪm?',
        translation: 'Who believes him?',
      },
      {
        id: 23,
        expression: 'Кто продает этот дом?',
        transcription: 'huː sɛlz ðɪs haʊs?',
        translation: 'Who sells this house?',
      },
      {
        id: 24,
        expression: 'Что тебя пугает?',
        transcription: 'wɒt ˈfraɪtnz juː?',
        translation: 'What frightens you?',
      },
      {
        id: 25,
        expression: 'Кто его поддерживает?',
        transcription: 'huː səˈpɔːts hɪm?',
        translation: 'Who supports him?',
      },
      {
        id: 26,
        expression: 'Кому это нужно?',
        transcription: 'huː niːdz ɪt?',
        translation: 'Who needs it?',
      },
      {
        id: 27,
        expression: 'Кому это нравится?',
        transcription: 'huː laɪks ɪt?',
        translation: 'Who likes it?',
      },
      {
        id: 28,
        expression: 'Что тебя шокирует?',
        transcription: 'wɒt ʃɒks juː?',
        translation: 'What shocks you?',
      },
      {
        id: 29,
        expression: 'Кто знает ее?',
        transcription: 'huː nəʊz hɜː?',
        translation: 'Who knows her?',
      },
      {
        id: 30,
        expression: 'Кто знает?',
        transcription: 'huː nəʊz?',
        translation: 'Who knows?',
      },
      {
        id: 31,
        expression: 'Что работает лучше?',
        transcription: 'wɒt wɜːks ˈbɛtə?',
        translation: 'What works better?',
      },
      {
        id: 32,
        expression: 'Что он ест?',
        transcription: 'wɒt dəz hi iːt?',
        translation: 'What does he eat?',
      },
      {
        id: 33,
        expression: 'Что ты понимаешь?',
        transcription: 'wɒt dʊ jʊ ˌʌndəˈstænd?',
        translation: 'What do you understand?',
      },
      {
        id: 34,
        expression: 'Кому ты веришь?',
        transcription: 'huː dʊ jʊ bɪˈliːv?',
        translation: 'Who do you believe?',
      },
      {
        id: 35,
        expression: 'Что тебя удивляет?',
        transcription: 'wɒt səˈpraɪzɪz juː?',
        translation: 'What surprises you?',
      },
      {
        id: 36,
        expression: 'Что так удивительно?',
        transcription: 'wɒts səʊ səˈpraɪzɪŋ?',
        translation: "What's so surprising?",
      },
      {
        id: 37,
        expression: 'Что делает тебя счастливым?',
        transcription: 'wɒt meɪks jʊ ˈhæpi?',
        translation: 'What makes you happy?',
      },
      {
        id: 38,
        expression: 'Кто говорит это?',
        transcription: 'huː sɛz ɪt?',
        translation: 'Who says it?',
      },
      {
        id: 39,
        expression: 'Кто ест больше?',
        transcription: 'huː iːts mɔː?',
        translation: 'Who eats more?',
      },
      {
        id: 40,
        expression: 'Что ты слышишь?',
        transcription: 'wɒt dʊ jʊ hɪə?',
        translation: 'What do you hear?',
      },
      {
        id: 41,
        expression: 'Кто еще знает это?',
        transcription: 'huː ɛls nəʊz ɪt?',
        translation: 'Who else knows it?',
      },
      {
        id: 42,
        expression: 'Что останавливает тебя?',
        transcription: 'wɒt stɒps juː?',
        translation: 'What stops you?',
      },
      {
        id: 43,
        expression: 'Что интересует тебя?',
        transcription: 'wɒt ˈɪntrɪsts juː?',
        translation: 'What interests you?',
      },
      {
        id: 44,
        expression: 'Что здесь такого удивительного?',
        transcription: 'wɒt s səʊ səˈpraɪzɪŋ hɪə?',
        translation: 'What is so surprising here?',
      },
      {
        id: 45,
        expression: 'Кого он поддерживает?',
        transcription: 'huː dəz hi səˈpɔːt?',
        translation: 'Who does he support?',
      },
      {
        id: 46,
        expression: 'Кто знает это?',
        transcription: 'huː nəʊz ɪt?',
        translation: 'Who knows it?',
      },
      {
        id: 47,
        expression: 'Какое место тебе больше нравится?',
        transcription: 'wɒt pleɪs dʊ jʊ laɪk mɔː?',
        translation: 'What place do you like more?',
      },
      {
        id: 48,
        expression: 'Что кажется таким шокирующим?',
        transcription: 'wɒt siːmz səʊ ˈʃɒkɪŋ?',
        translation: 'What seems so shocking?',
      },
      {
        id: 49,
        expression: 'Чья одежда выглядит более модной?',
        transcription: 'huːz kləʊðz lʊk mɔː ˈfæʃnəbl?',
        translation: 'Whose clothes look more fashionable?',
      },
      {
        id: 50,
        expression: 'Что обычно привлекает твое внимание?',
        transcription: 'wɒt ˈjuːʒʊəli əˈtrækts jər əˈtɛnʃ(ə)n?',
        translation: 'What usually attracts your attention?',
      },
      {
        id: 51,
        expression: 'Что зависит от тебя?',
        transcription: 'wɒt dɪˈpɛndz ɒn juː?',
        translation: 'What depends on you?',
      },
      {
        id: 52,
        expression: 'Какая юбка выглядит более стильной?',
        transcription: 'wɪʧ skɜːt lʊks mɔː ˈstaɪlɪʃ?',
        translation: 'Which skirt looks more stylish?',
      },
      {
        id: 53,
        expression: 'Чье описание более точное?',
        transcription: 'huːz dɪsˈkrɪpʃən z mɔːr ˈækjʊrɪt?',
        translation: 'Whose description is more accurate?',
      },
      {
        id: 54,
        expression: 'Кто обычно звонит тебе после работы?',
        transcription: 'huː ˈjuːʒʊəli kɔːlz jʊ ˈɑːftə wɜːk?',
        translation: 'Who usually calls you after work?',
      },
      {
        id: 55,
        expression: 'Что помогает тебе с твоим английским?',
        transcription: 'wɒt hɛlps jʊ wɪð jər ˈɪŋglɪʃ?',
        translation: 'What helps you with your English?',
      },
      {
        id: 56,
        expression: 'Какое предложение выглядит более привлекательным?',
        transcription: 'wɪʧ ˈɒfə lʊks mɔːr əˈtræktɪv?',
        translation: 'Which offer looks more attractive?',
      },
      {
        id: 57,
        expression: 'Какой отель кажется лучше?',
        transcription: 'wɪʧ həʊˈtɛl siːmz ˈbɛtə?',
        translation: 'Which hotel seems better?',
      },
      {
        id: 58,
        expression: 'Чей велосипед дороже?',
        transcription: 'huːz baɪk s mɔːr ɪksˈpɛnsɪv?',
        translation: 'Whose bike is more expensive?',
      },
      {
        id: 59,
        expression: 'Какие вещи его интересуют?',
        transcription: 'wɒt θɪŋz ˈɪntrɪst hɪm?',
        translation: 'What things interest him?',
      },
      {
        id: 60,
        expression: 'Что ты ожидаешь от него?',
        transcription: 'wɒt dʊ jʊ ɪksˈpɛkt frəm hɪm?',
        translation: 'What do you expect from him?',
      },
      {
        id: 61,
        expression: 'Какое предложение более привлекательное?',
        transcription: 'wɪʧ ˈɒfə z mɔːr əˈtræktɪv?',
        translation: 'Which offer is more attractive?',
      },
      {
        id: 62,
        expression: 'Что звучит лучше?',
        transcription: 'wɒt saʊndz ˈbɛtə?',
        translation: 'What sounds better?',
      },
      {
        id: 63,
        expression: 'Какое место кажется более красивым?',
        transcription: 'wɪʧ pleɪs siːmz mɔː ˈbjuːtəfʊl?',
        translation: 'Which place seems more beautiful?',
      },
      {
        id: 64,
        expression: 'Кого ты посещаешь каждые выходные?',
        transcription: 'huː dʊ jʊ ˈvɪzɪt ˈɛvri ˈwiːkˈɛnd?',
        translation: 'Who do you visit every weekend?',
      },
      {
        id: 65,
        expression: 'Что кажется дороже?',
        transcription: 'wɒt siːmz mɔːr ɪksˈpɛnsɪv?',
        translation: 'What seems more expensive?',
      },
      {
        id: 66,
        expression: 'Что кажется дешевле?',
        transcription: 'wɒt siːmz ˈʧiːpə?',
        translation: 'What seems cheaper?',
      },
      {
        id: 67,
        expression: 'Что кажется самым дорогим?',
        transcription: 'wɒt siːmz ðə məʊst ɪksˈpɛnsɪv?',
        translation: 'What seems the most expensive?',
      },
      {
        id: 68,
        expression: 'Какое описание выглядит более точным?',
        transcription: 'wɪʧ dɪsˈkrɪpʃən lʊks mɔːr ˈækjʊrɪt?',
        translation: 'Which description looks more accurate?',
      },
      {
        id: 69,
        expression: 'Какое описание кажется более точным?',
        transcription: 'wɪʧ dɪsˈkrɪpʃən siːmz mɔːr ˈækjʊrɪt?',
        translation: 'Which description seems more accurate?',
      },
      {
        id: 70,
        expression: 'Какие описания выглядят более точными?',
        transcription: 'wɪʧ dɪsˈkrɪpʃənz lʊk mɔːr ˈækjʊrɪt?',
        translation: 'Which descriptions look more accurate?',
      },
      {
        id: 71,
        expression: 'Что мотивирует тебя лучше?',
        transcription: 'wɒt ˈməʊtɪveɪts jʊ ˈbɛtə?',
        translation: 'What motivates you better?',
      },
      {
        id: 72,
        expression: 'Кто мотивирует тебя?',
        transcription: 'huː ˈməʊtɪveɪts juː?',
        translation: 'Who motivates you?',
      },
      {
        id: 73,
        expression: 'Кто мотивирует тебя лучше?',
        transcription: 'huː ˈməʊtɪveɪts jʊ ˈbɛtə?',
        translation: 'Who motivates you better?',
      },
      {
        id: 74,
        expression: 'Чей велосипед стоит больше?',
        transcription: 'huːz baɪk kɒsts mɔː?',
        translation: 'Whose bike costs more?',
      },
      {
        id: 75,
        expression: 'Какой велосипед стоит дороже?',
        transcription: 'wɪʧ baɪk kɒsts mɔː?',
        translation: 'Which bike costs more?',
      },
      {
        id: 76,
        expression: 'Что делает людей счастливыми?',
        transcription: 'wɒt meɪks ˈpiːpl ˈhæpi?',
        translation: 'What makes people happy?',
      },
      {
        id: 77,
        expression: 'Что делает их счастливыми?',
        transcription: 'wɒt meɪks ðəm ˈhæpi?',
        translation: 'What makes them happy?',
      },
      {
        id: 78,
        expression: 'Кто делает тебя счастливым?',
        transcription: 'huː meɪks jʊ ˈhæpi?',
        translation: 'Who makes you happy?',
      },
      {
        id: 79,
        expression: 'Кому ты помогаешь с английским?',
        transcription: 'huː dʊ jʊ hɛlp wɪð ˈɪŋglɪʃ?',
        translation: 'Who do you help with English?',
      },
      {
        id: 80,
        expression: 'Кому он помогает с английским?',
        transcription: 'huː dəz hi hɛlp wɪð ˈɪŋglɪʃ?',
        translation: 'Who does he help with English?',
      },
      {
        id: 81,
        expression: 'Кто помогает тебе с английским?',
        transcription: 'huː hɛlps jʊ wɪð ˈɪŋglɪʃ?',
        translation: 'Who helps you with English?',
      },
      {
        id: 82,
        expression: 'Какая песня более популярна?',
        transcription: 'wɪʧ sɒŋ z mɔː ˈpɒpjʊlə?',
        translation: 'Which song is more popular?',
      },
      {
        id: 83,
        expression: 'Чья песня популярнее?',
        transcription: 'huːz sɒŋ z mɔː ˈpɒpjʊlə?',
        translation: 'Whose song is more popular?',
      },
      {
        id: 84,
        expression: 'Какая песня самая популярная?',
        transcription: 'wɪʧ sɒŋ z ðə məʊst ˈpɒpjʊlə?',
        translation: 'Which song is the most popular?',
      },
      {
        id: 85,
        expression: 'Кому ты обычно звонишь после работы?',
        transcription: 'huː dʊ jʊ ˈjuːʒʊəli kɔːl ˈɑːftə wɜːk?',
        translation: 'Who do you usually call after work?',
      },
      {
        id: 86,
        expression: 'Кому он обычно звонит после работы?',
        transcription: 'huː dəz hi ˈjuːʒʊəli kɔːl ˈɑːftə wɜːk?',
        translation: 'Who does he usually call after work?',
      },
      {
        id: 87,
        expression: 'Кому ты обычно звонишь перед работой?',
        transcription: 'huː dʊ jʊ ˈjuːʒʊəli kɔːl bɪˈfɔː wɜːk?',
        translation: 'Who do you usually call before work?',
      },
      {
        id: 88,
        expression: 'Какое твое окончательное решение?',
        transcription: 'wɒts jə ˈfaɪnl dɪˈsɪʒən?',
        translation: "What's your final decision?",
      },
      {
        id: 89,
        expression: 'Какое наше окончательное решение?',
        transcription: 'wɒts ˈaʊə ˈfaɪnl dɪˈsɪʒən?',
        translation: "What's our final decision?",
      },
      {
        id: 90,
        expression: 'Какое верное решение?',
        transcription: 'wɒts ðə raɪt dɪˈsɪʒən?',
        translation: "What's the right decision?",
      },
      {
        id: 91,
        expression: 'Какое место привлекает тебя больше?',
        transcription: 'wɪʧ pleɪs əˈtrækts jʊ mɔː?',
        translation: 'Which place attracts you more?',
      },
      {
        id: 92,
        expression: 'Какие места привлекают тебя больше?',
        transcription: 'wɒt ˈpleɪsɪz əˈtrækt jʊ mɔː?',
        translation: 'What places attract you more?',
      },
      {
        id: 93,
        expression: 'Какие вещи привлекают тебя больше?',
        transcription: 'wɒt θɪŋz əˈtrækt jʊ mɔː?',
        translation: 'What things attract you more?',
      },
      {
        id: 94,
        expression: 'Какие уроки длятся дольше?',
        transcription: 'wɪʧ ˈlɛsnz lɑːst ˈlɒŋgə?',
        translation: 'Which lessons last longer?',
      },
      {
        id: 95,
        expression: 'Кто посещает тебя каждые выходные?',
        transcription: 'huː ˈvɪzɪts jʊ ˈɛvri ˈwiːkˈɛnd?',
        translation: 'Who visits you every weekend?',
      },
      {
        id: 96,
        expression: 'Кто понимает, что делать?',
        transcription: 'huː ˌʌndəˈstændz wɒt tə duː?',
        translation: 'Who understands what to do?',
      },
      {
        id: 97,
        expression: 'Что выглядит более стильным?',
        transcription: 'wɒt lʊks mɔː ˈstaɪlɪʃ?',
        translation: 'What looks more stylish?',
      },
      {
        id: 98,
        expression: 'Чьи описания кажутся менее точными?',
        transcription: 'huːz dɪsˈkrɪpʃənz siːm lɛs ˈækjʊrɪt?',
        translation: 'Whose descriptions seem less accurate?',
      },
      {
        id: 99,
        expression: 'Что кажется сложнее?',
        transcription: 'wɒt siːmz mɔː ˈdɪfɪkəlt?',
        translation: 'What seems more difficult?',
      },
      {
        id: 100,
        expression: 'Какой ответ правильный?',
        transcription: 'wɪʧ ˈɑːnsə z kəˈrɛkt?',
        translation: 'Which answer is correct?',
      },
      {
        id: 101,
        expression: 'Что такого привлекательного в этом месте?',
        transcription: 'wɒt s səʊ əˈtræktɪv ɪn ðɪs pleɪs?',
        translation: 'What is so attractive in this place?',
      },
      {
        id: 102,
        expression: 'Что принадлежит ему?',
        transcription: 'wɒt bɪˈlɒŋz tə hɪm?',
        translation: 'What belongs to him?',
      },
      {
        id: 103,
        expression: 'Чья идея правильная?',
        transcription: 'huːz aɪˈdɪə z raɪt?',
        translation: 'Whose idea is right?',
      },
      {
        id: 104,
        expression: 'Что привлекает внимание людей?',
        transcription: 'wɒt əˈtrækts ˈpiːplz əˈtɛnʃ(ə)n?',
        translation: "What attracts people's attention?",
      },
      {
        id: 105,
        expression: 'Какой ответ кажется правильным?',
        transcription: 'wɪʧ ˈɑːnsə siːmz kəˈrɛkt?',
        translation: 'Which answer seems correct?',
      },
      {
        id: 106,
        expression: 'Что выглядит более модным?',
        transcription: 'wɒt lʊks mɔː ˈfæʃnəbl?',
        translation: 'What looks more fashionable?',
      },
      {
        id: 107,
        expression: 'Какое описание является более точным?',
        transcription: 'wɪʧ dɪsˈkrɪpʃən z mɔːr ˈækjʊrɪt?',
        translation: 'Which description is more accurate?',
      },
      {
        id: 108,
        expression: 'Какие вещи интересуют тебя?',
        transcription: 'wɒt θɪŋz ˈɪntrɪst juː?',
        translation: 'What things interest you?',
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Очень важные правила!</h3>
<p>1. Если в специальном вопросе с глаголом to be после what (что), who (кто), which (который) не стоит подлежащее, то после всегда ставится is:</p>
<p>What is it? - Что это?</p>
<p>А также: Which is your house? - Который дом твой?</p>
<p>2. Если после what (что, какой), who (кого) стоит подлежащее, то do или does ставятся, а глагол остается без окончания:</p>
<p>What does he read every night? - Что он читает каждую ночь?</p>
<p>3. Если в специальном вопросе после what (что), who (кто) не стоит подлежащее, то do или does могут не ставиться, а к глаголу всегда добавляется "s":</p>
<p>What shocks you? - Что шокирует тебя?</p>
<p>Сравните:</p>
<p>Who supports you? - Кто тебя поддерживает?</p>
<p>Who does support you? - Кто тебя поддерживает?</p>
<p>Who do you support? - Кого ты поддерживаешь?</p>
</div>`,
    },
  ],
];
