export const wordsLesson205 = [
  [
    {
      id: 1,
      word: 'banknote',
      transcription: 'ˈbæŋknəʊt',
      translation: 'банкнота',
    },
    {
      id: 2,
      word: 'brigade',
      transcription: 'brɪˈgeɪd',
      translation: 'бригада',
    },
    {
      id: 3,
      word: 'crowd',
      transcription: 'kraʊd',
      translation: 'толпа',
    },
    {
      id: 4,
      word: 'facility',
      transcription: 'fəˈsɪlɪti',
      translation: 'установка',
    },
    {
      id: 5,
      word: 'failure',
      transcription: 'ˈfeɪljə',
      translation: 'провал',
    },
    {
      id: 6,
      word: 'firefighter',
      transcription: 'ˈfaɪəˌfaɪtə',
      translation: 'пожарный',
    },
    {
      id: 7,
      word: 'flu',
      transcription: 'fluː',
      translation: 'грипп',
    },
    {
      id: 8,
      word: 'inattentive',
      transcription: 'ˌɪnəˈtɛntɪv',
      translation: 'невнимательный',
    },
    {
      id: 9,
      word: 'inexperience',
      transcription: 'ˌɪnɪksˈpɪərɪəns',
      translation: 'неопытность',
    },
    {
      id: 10,
      word: 'investigation',
      transcription: 'ɪnˌvɛstɪˈgeɪʃən',
      translation: 'расследование',
    },
    {
      id: 11,
      word: 'laziness',
      transcription: 'ˈleɪzɪnɪs',
      translation: 'лень',
    },
    {
      id: 12,
      word: 'operator',
      transcription: 'ˈɒpəreɪtə',
      translation: 'оператор',
    },
    {
      id: 13,
      word: 'savings',
      transcription: 'ˈseɪvɪŋz',
      translation: 'сбережения',
    },
    {
      id: 14,
      word: 'tiredness',
      transcription: 'ˈtaɪədnəs',
      translation: 'усталость',
    },
    {
      id: 15,
      word: 'unprofessionalism',
      transcription: 'ˌʌnprəˈfɛʃənlɪz(ə)m',
      translation: 'непрофессионализм',
    },
    {
      id: 16,
      word: 'get into',
      transcription: 'gɛt ˈɪntuː',
      translation: 'проникать в, попасть в',
    },
    {
      id: 17,
      word: 'cheer up',
      transcription: 'ʧɪər ʌp',
      translation: 'взбодриться',
    },
    {
      id: 18,
      word: 'get by',
      transcription: 'gɛt baɪ',
      translation: 'обходиться',
    },
    {
      id: 19,
      word: 'get over',
      transcription: 'gɛt ˈəʊvə',
      translation: 'справиться, выздороветь',
    },
    {
      id: 20,
      word: 'get away',
      transcription: 'gɛt əˈweɪ',
      translation: 'убежать, скрыться',
    },
    {
      id: 21,
      word: 'cut down',
      transcription: 'kʌt daʊn',
      translation: 'вырубать',
    },
    {
      id: 22,
      word: 'break in',
      transcription: 'breɪk ɪn',
      translation: 'врываться, вломиться',
    },
    {
      id: 23,
      word: 'break into',
      transcription: 'breɪk ˈɪntuː',
      translation: 'вломиться в, проникнуть в',
    },
    {
      id: 24,
      word: 'carry out',
      transcription: 'ˈkæri aʊt',
      translation: 'проводить',
    },
    {
      id: 25,
      word: 'make up',
      transcription: 'meɪk ʌp',
      translation: 'выдумать, решить',
    },
    {
      id: 26,
      word: 'make out',
      transcription: 'meɪk aʊt',
      translation: 'понимать',
    },
    {
      id: 27,
      word: 'put down to',
      transcription: 'pʊt daʊn tuː',
      translation: 'списывать на',
    },
    {
      id: 28,
      word: 'put forward',
      transcription: 'pʊt ˈfɔːwəd',
      translation: 'выдвигать',
    },
    {
      id: 29,
      word: 'put out',
      transcription: 'pʊt aʊt',
      translation: 'тушить',
    },
    {
      id: 30,
      word: 'put through',
      transcription: 'pʊt θruː',
      translation: 'соединять, связаться',
    },
    {
      id: 31,
      word: 'give away',
      transcription: 'gɪv əˈweɪ',
      translation: 'отдавать',
    },
    {
      id: 32,
      word: 'cut down on',
      transcription: 'kʌt daʊn ɒn',
      translation: 'сократить',
    },
    {
      id: 33,
      word: 'stand up',
      transcription: 'stænd ʌp',
      translation: 'встать',
    },
  ],
];
