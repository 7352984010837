export const contentLesson149 = [
  [
    {
      title: 'Урок 149. Разделительные вопросы - Tag questions',
    },
    [
      {
        id: 1,
        expression: 'Это хорошо, не так ли?',
        transcription: 'ɪts gʊd, ˈɪznt ɪt?',
        translation: "It's good, isn't it?",
      },
      {
        id: 2,
        expression: 'Это так интересно, не так ли?',
        transcription: 'ɪts səʊ ˈɪntrɪstɪŋ, ˈɪznt ɪt?',
        translation: "It's so interesting, isn't it?",
      },
      {
        id: 3,
        expression: 'Они знают это, не так ли?',
        transcription: 'ðeɪ nəʊ ɪt, dəʊnt ðeɪ?',
        translation: "They know it, don't they?",
      },
      {
        id: 4,
        expression: 'Она помогла тебе, не так ли?',
        transcription: 'ʃi hɛlpt juː, dɪdnt ʃiː?',
        translation: "She helped you, didn't she?",
      },
      {
        id: 5,
        expression: 'Он не ходил туда, не так ли?',
        transcription: 'hi dɪdnt gəʊ ðeə, dɪd hiː?',
        translation: "He didn't go there, did he?",
      },
      {
        id: 6,
        expression: 'Они не знают это, не так ли?',
        transcription: 'ðeɪ dəʊnt nəʊ ɪt, dʊ ðeɪ?',
        translation: "They don't know it, do they?",
      },
      {
        id: 7,
        expression: 'Она не помогла тебе, не так ли?',
        transcription: 'ʃi dɪdnt hɛlp juː, dɪd ʃiː?',
        translation: "She didn't help you, did she?",
      },
      {
        id: 8,
        expression: 'Ты никогда туда не ходишь, не так ли?',
        transcription: 'jʊ ˈnɛvə gəʊ ðeə, dʊ juː?',
        translation: 'You never go there, do you?',
      },
      {
        id: 9,
        expression: 'У него есть машина, не так ли?',
        transcription:
          'hi həz ə kɑː, dʌznt hiː? / hi həz gɒt ə kɑː, ˈhæznt hiː?',
        translation: "He has a car, doesn't he? / He has got a car, hasn't he?",
      },
      {
        id: 10,
        expression: 'Ты действительно счастлив, не так ли?',
        transcription: 'jʊə ˈrɪəli ˈhæpi, ɑːnt juː?',
        translation: "You're really happy, aren't you?",
      },
      {
        id: 11,
        expression: 'Он будет там до трех, не так ли?',
        transcription: 'hi wɪl bi ðeə tɪl θriː, wəʊnt hiː?',
        translation: "He will be there till three, won't he?",
      },
      {
        id: 12,
        expression: 'Я поступил правильно, не так ли?',
        transcription: 'aɪ dɪd ðə raɪt θɪŋ, dɪdnt aɪ?',
        translation: "I did the right thing, didn't I?",
      },
      {
        id: 13,
        expression: 'Это хорошая идея, не так ли?',
        transcription: 'ɪts ə gʊd aɪˈdɪə, ˈɪznt ɪt?',
        translation: "It's a good idea, isn't it?",
      },
      {
        id: 14,
        expression: 'Он изучает архитектуру, не так ли?',
        transcription: 'hiːz ˈstʌdiɪŋ ˈɑːkɪtɛkʧə, ˈɪznt hiː?',
        translation: "He's studying architecture, isn't he?",
      },
      {
        id: 15,
        expression: 'У него есть идея, не так ли?',
        transcription: 'hi həz ən aɪˈdɪə, dʌznt hiː?',
        translation: "He has an idea, doesn't he?",
      },
      {
        id: 16,
        expression: 'Это действительно помогает тебе, не так ли?',
        transcription: 'ɪt ˈrɪəli hɛlps juː, dʌznt ɪt?',
        translation: "It really helps you, doesn't it?",
      },
      {
        id: 17,
        expression: 'Тебе нужны вилка и нож, не так ли?',
        transcription: 'jʊ niːd ə fɔːk ənd ə naɪf, dəʊnt juː?',
        translation: "You need a fork and a knife, don't you?",
      },
      {
        id: 18,
        expression: 'Ты этого не понимаешь, не так ли?',
        transcription: 'jʊ dəʊnt ˌʌndəˈstænd ɪt, dʊ juː?',
        translation: "You don't understand it, do you?",
      },
      {
        id: 19,
        expression: 'Он больше не покупает сигарет, не так ли?',
        transcription: 'hi dʌznt baɪ ˌsɪgəˈrɛts ˌɛniˈmɔː, dəz hiː?',
        translation: "He doesn't buy cigarettes anymore, does he?",
      },
      {
        id: 20,
        expression: 'Это действительно интересно, не так ли?',
        transcription: 'ɪts ˈrɪəli ˈɪntrɪstɪŋ, ˈɪznt ɪt?',
        translation: "It's really interesting, isn't it?",
      },
      {
        id: 21,
        expression: 'Он также хочет туда поехать, не так ли?',
        transcription: 'hi ˈɔːlsəʊ wɒnt tə gəʊ ðeə, dʌznt hiː?',
        translation: "He also want to go there, doesn't he?",
      },
      {
        id: 22,
        expression: 'У тебя есть машина, не так ли?',
        transcription: 'jʊ həv ə kɑː, dəʊnt juː?',
        translation: "You have a car, don't you?",
      },
      {
        id: 23,
        expression: 'Мне не следует этого делать, не так ли?',
        transcription: 'aɪ ʃʊdnt dʊ ɪt, ʃəd aɪ?',
        translation: "I shouldn't do it, should I?",
      },
      {
        id: 24,
        expression: 'У него высокая температура, не так ли?',
        transcription: 'hi həz ə haɪ ˈtɛmprɪʧə, dʌznt hiː?',
        translation: "He has a high temperature, doesn't he?",
      },
      {
        id: 25,
        expression: 'Это хорошо, не так ли?',
        transcription: 'ɪts gʊd, ˈɪznt ɪt?',
        translation: "It's good, isn't it?",
      },
      {
        id: 26,
        expression: 'Ты учишь английский самостоятельно, не так ли?',
        transcription: 'jʊ lɜːn ˈɪŋglɪʃ ɒn jər əʊn (baɪ jɔːˈsɛlf), dəʊnt juː?',
        translation: "You learn English on your own (by yourself), don't you?",
      },
      {
        id: 27,
        expression: 'Температура сейчас ниже ноля, не так ли?',
        transcription: 'ðə ˈtɛmprɪʧə z bɪˈləʊ ˈzɪərəʊ naʊ, ˈɪznt ɪt?',
        translation: "The temperature is below zero now, isn't it?",
      },
      {
        id: 28,
        expression: 'У тебя есть кот, не так ли?',
        transcription: 'jʊ həv ə kæt, dəʊnt juː?',
        translation: "You have a cat, don't you?",
      },
      {
        id: 29,
        expression: 'Тебе нужен лимон, не так ли?',
        transcription: 'jʊ niːd ə ˈlɛmən. dəʊnt juː?',
        translation: "You need a lemon, don't you?",
      },
      {
        id: 30,
        expression: 'Она работает официанткой, не так ли?',
        transcription: 'ʃi wɜːks əz ə ˈweɪtrɪs, dʌznt ʃiː?',
        translation: "She works as a waitress, doesn't she?",
      },
      {
        id: 31,
        expression: 'Ты не из США, не так ли?',
        transcription: 'jʊ ɑːnt frəm ðə juː-ɛs-eɪ, ɑː juː?',
        translation: "You aren't from the USA, are you?",
      },
      {
        id: 32,
        expression: 'Это действительно тебя интересует, не так ли?',
        transcription: 'ɪt ˈrɪəli ˈɪntrɪsts juː, dʌznt ɪt?',
        translation: "It really interests you, doesn't it?",
      },
      {
        id: 33,
        expression: 'Ты чувствуешь себя действительно счастливым, не так ли?',
        transcription: 'jʊ fiːl ˈrɪəli ˈhæpi, dəʊnt juː?',
        translation: "You feel really happy, don't you?",
      },
      {
        id: 34,
        expression: 'Ты хочешь чай с лимоном, не так ли?',
        transcription: 'jʊ wɒnt tiː wɪð ˈlɛmən, dəʊnt juː?',
        translation: "You want tea with lemon, don't you?",
      },
      {
        id: 35,
        expression: 'Он действительно этого хочет, не так ли?',
        transcription: 'hi ˈrɪəli wɒnts ɪt, dʌznt hiː?',
        translation: "He really wants it, doesn't he?",
      },
      {
        id: 36,
        expression: 'Это действительно невероятно, не так ли?',
        transcription: 'ɪts ˈrɪəli ˌʌnbɪˈliːvəbl, ˈɪznt ɪt?',
        translation: "It's really unbelievable, isn't it?",
      },
      {
        id: 37,
        expression: 'Он не должен этого делать, не так ли?',
        transcription: 'hi ˈmʌsnt dʊ ɪt, məst hiː?',
        translation: "He mustn't do it, must he?",
      },
      {
        id: 38,
        expression: 'У тебя есть машина, не так ли?',
        transcription: 'jʊ həv ə kɑː, dəʊnt juː?',
        translation: "You have a car, don't you?",
      },
      {
        id: 39,
        expression: 'Сегодня очень ветрено, не так ли?',
        transcription: 'ɪts ˈvɛri ˈwɪndi təˈdeɪ, ˈɪznt ɪt?',
        translation: "It's very windy today, isn't it?",
      },
      {
        id: 40,
        expression: 'Он купил новый мотоцикл, не так ли?',
        transcription: 'hi bɔːt ə njuː ˈməʊtəˈsaɪkl, dɪdnt hiː?',
        translation: "He bought a new motorcycle, didn't he?",
      },
      {
        id: 41,
        expression: 'Она не права, не так ли?',
        transcription: 'ʃi ˈɪznt raɪt, ɪz ʃiː?',
        translation: "She isn't right, is she?",
      },
      {
        id: 42,
        expression: 'Они ничего не ответили, не так ли?',
        transcription: 'ðeɪ dɪdnt ˈɑːnsər ˈɛnɪθɪŋ, dɪd ðeɪ?',
        translation: "They didn't answer anything, did they?",
      },
      {
        id: 43,
        expression: 'Это тебе совсем не помогает, не так ли?',
        transcription: 'ɪt dʌznt hɛlp jʊ ət ɔːl, dəz ɪt?',
        translation: "It doesn't help you at all, does it?",
      },
      {
        id: 44,
        expression: 'Это кажется действительно невероятным, не так ли?',
        transcription: 'ɪt siːmz ˈrɪəli ˌʌnbɪˈliːvəbl, dʌznt ɪt?',
        translation: "It seems really unbelieveable, doesn't it?",
      },
      {
        id: 45,
        expression: 'Ты изучаешь графический дизайн, не так ли?',
        transcription: 'jʊə ˈstʌdiɪŋ ˈgræfɪk dɪˈzaɪn, ɑːnt juː?',
        translation: "You're studying graphic design, aren't you?",
      },
      {
        id: 46,
        expression: 'Тебе нужна вилка, не так ли?',
        transcription: 'jʊ niːd ə fɔːk, dəʊnt juː?',
        translation: "You need a fork, don't you?",
      },
      {
        id: 47,
        expression: 'Ты хочешь чай с сахаром, не так ли?',
        transcription: 'jʊ wɒnt tiː wɪð ˈʃʊgə, dəʊnt juː?',
        translation: "You want tea with sugar, don't you?",
      },
      {
        id: 48,
        expression: 'Ты не опоздал, не так ли?',
        transcription: 'jʊ wɜːnt leɪt, wə juː?',
        translation: "You weren't late, were you?",
      },
      {
        id: 49,
        expression: 'Изучение английского языка - это интересно, не так ли?',
        transcription: 'ˈlɜːnɪŋ ˈɪŋglɪʃ ɪz ˈɪntrɪstɪŋ, ˈɪznt ɪt?',
        translation: "Learning English is interesting, isn't it?",
      },
      {
        id: 50,
        expression:
          'Обычно рекомендуется чистить зубы два раза в день, не так ли?',
        transcription:
          'ɪts ˈjuːʒʊəli ˌrɛkəˈmɛndɪd ˈbrʌʃɪŋ ˈaʊə tiːθ twaɪs ə deɪ, ˈɪznt ɪt?',
        translation:
          "It's usually recommended brushing our teeth twice a day, isn't it?",
      },
      {
        id: 51,
        expression: 'В этом доме никто не живет, не так ли?',
        transcription: 'ˈnəʊbədi lɪvz ɪn ðɪs haʊs, dʊ ðeɪ?',
        translation: 'Nobody lives in this house, do they?',
      },
      {
        id: 52,
        expression: 'Вы прекрасно провели время вместе, не так ли?',
        transcription: 'jʊ həd ə greɪt taɪm təˈgɛðə, dɪdnt juː?',
        translation: "You had a great time together, didn't you?",
      },
      {
        id: 53,
        expression:
          'Эта женщина выращивает фрукты и овощи в своем собственном саду, не так ли?',
        transcription:
          'ðɪs ˈwʊmən grəʊz fruːt ənd ˈvɛʤtəb(ə)lz ɪn hər əʊn ˈgɑːdn, dʌznt ʃiː?',
        translation:
          "This woman grows fruit and vegetables in her own garden, doesn't she?",
      },
      {
        id: 54,
        expression: 'Ты никогда не был в этом месте, не так ли?',
        transcription: 'juːv ˈnɛvə biːn tə ðɪs pleɪs, həv juː?',
        translation: "You've never been to this place, have you?",
      },
      {
        id: 55,
        expression: 'Не было никаких серьезных трудностей, не так ли?',
        transcription: 'ðə wɜːnt ˈɛni ˈsɪərɪəs ˈdɪfɪkəltiz, wə ðeə?',
        translation: "There weren't any serious difficulties, were there?",
      },
      {
        id: 56,
        expression: 'Он никогда не пьет алкоголь, не так ли?',
        transcription: 'hi ˈnɛvə drɪŋks ˈælkəhɒl, dəz hiː?',
        translation: 'He never drinks alcohol, does he?',
      },
      {
        id: 57,
        expression: 'У него были очень серьезные проблемы, не так ли?',
        transcription: 'hi həd ˈvɛri ˈsɪərɪəs ˈprɒbləmz, dɪdnt hiː?',
        translation: "He had very serious problems, didn't he?",
      },
      {
        id: 58,
        expression: 'Ты работаешь весь день, не так ли?',
        transcription: 'juːv biːn ˈwɜːkɪŋ ɔːl deɪ, hævnt juː?',
        translation: "You've been working all day, haven't you?",
      },
      {
        id: 59,
        expression: 'Ты уже был там, не так ли?',
        transcription: 'juːv ɔːlˈrɛdi biːn ðeə, hævnt juː?',
        translation: "You've already been there, haven't you?",
      },
      {
        id: 60,
        expression: 'Я нахожусь в нужном месте, не так ли?',
        transcription: 'aɪm ɪn ðə raɪt pleɪs, ɑːnt aɪ?',
        translation: "I'm in the right place, aren't I?",
      },
      {
        id: 61,
        expression: 'Все там, не так ли?',
        transcription: 'ˈɛvrɪwʌn z ðeə, ɑːnt ðeɪ?',
        translation: "Everyone is there, aren't they?",
      },
      {
        id: 62,
        expression: 'В твоей работе не было никаких ошибок, не так ли?',
        transcription: 'ðə wɜːnt ˈɛni mɪsˈteɪks ɪn jə wɜːk, wə ðeə?',
        translation: "There weren't any mistakes in your work, were there?",
      },
      {
        id: 63,
        expression: 'Она опоздала на встречу, не так ли?',
        transcription: 'ʃi wəz leɪt fə ðə ˈmiːtɪŋ, wɒznt ʃiː?',
        translation: "She was late for the meeting, wasn't she?",
      },
      {
        id: 64,
        expression: 'На дорогах много льда, не так ли?',
        transcription: 'ðə z mʌʧ aɪs ɒn ðə rəʊdz, ˈɪznt ðeə?',
        translation: "There is much ice on the roads, isn't there?",
      },
      {
        id: 65,
        expression: 'В этой комнате много грязи, не так ли?',
        transcription: 'ðə z mʌʧ dɜːt ɪn ðɪs ruːm, ˈɪznt ðeə?',
        translation: "There is much dirt in this room, isn't there?",
      },
      {
        id: 66,
        expression: 'На полу было так много воды, не так ли?',
        transcription: 'ðə wəz səʊ mʌʧ ˈwɔːtər ɒn ðə flɔː, wɒznt ðeə?',
        translation: "There was so much water on the floor, wasn't there?",
      },
      {
        id: 67,
        expression: 'Мы никогда не узнаем этого, не так ли?',
        transcription: 'wiːl ˈnɛvə nəʊ ɪt, wɪl wiː?',
        translation: "We'll never know it, will we?",
      },
      {
        id: 68,
        expression: 'Мы узнаем это, не так ли?',
        transcription: 'wiːl nəʊ ɪt, wəʊnt wiː?',
        translation: "We'll know it, won't we?",
      },
      {
        id: 69,
        expression: 'Мы никогда этого не сделаем, не так ли?',
        transcription: 'wiːl ˈnɛvə dʊ ɪt, wɪl wiː?',
        translation: "We'll never do it, will we?",
      },
      {
        id: 70,
        expression: 'Здесь слишком много соли, не так ли?',
        transcription: 'ðə z tuː mʌʧ sɒlt hɪə, ˈɪznt ðeə?',
        translation: "There is too much salt here, isn't there?",
      },
      {
        id: 71,
        expression: 'У нее слишком много одежды, не так ли?',
        transcription: 'ʃi həz tuː ˈmɛni kləʊðz, dʌznt ʃiː?',
        translation: "She has too many clothes, doesn't she?",
      },
      {
        id: 72,
        expression: 'Здесь слишком много сахара, не так ли?',
        transcription: 'ðə z tuː mʌʧ ˈʃʊgə hɪə, ˈɪznt ðeə?',
        translation: "There is too much sugar here, isn't there?",
      },
      {
        id: 73,
        expression: 'Давай сделаем это, хорошо?',
        transcription: 'lɛts dʊ ɪt, ʃəl wiː?',
        translation: "Let's do it, shall we?",
      },
      {
        id: 74,
        expression: 'Давай пойдем туда, хорошо?',
        transcription: 'lɛts gəʊ ðeə, ʃəl wiː?',
        translation: "Let's go there, shall we?",
      },
      {
        id: 75,
        expression: 'Давай сделаем это вместе, хорошо?',
        transcription: 'lɛts dʊ ɪt təˈgɛðə, ʃəl wiː?',
        translation: "Let's do it together, shall we?",
      },
      {
        id: 76,
        expression: 'Все дети любят сладости, не так ли?',
        transcription: 'ɔːl ˈʧɪldrən lʌv swiːts, dəʊnt ðeɪ?',
        translation: "All children love sweets, don't they?",
      },
      {
        id: 77,
        expression: 'Твой сын любит сладости, не так ли?',
        transcription: 'jə sʌn lʌvz swiːts, dʌznt hiː?',
        translation: "Your son loves sweets, doesn't he?",
      },
      {
        id: 78,
        expression: 'Все люди любят вкусную еду, не так ли?',
        transcription: 'ɔːl ˈpiːpl lʌv ˈteɪsti fuːd, dəʊnt ðeɪ?',
        translation: "All people love tasty food, don't they?",
      },
      {
        id: 79,
        expression: 'Давай поедем на следующем автобусе, хорошо?',
        transcription: 'lɛts teɪk ðə nɛkst bʌs, ʃəl wiː?',
        translation: "Let's take the next bus, shall we?",
      },
      {
        id: 80,
        expression: 'Давай возьмем такси, ладно?',
        transcription: 'lɛts teɪk ə ˈtæksi, ʃəl wiː?',
        translation: "Let's take a taxi, shall we?",
      },
      {
        id: 81,
        expression: 'Давай пойдем в кино, хорошо?',
        transcription: 'lɛts gəʊ tə ðə ˈsɪnəmə, ʃəl wiː?',
        translation: "Let's go to the cinema, shall we?",
      },
      {
        id: 82,
        expression: 'Ничего плохого не случилось, не так ли?',
        transcription: 'ˈnʌθɪŋ bæd ˈhæpənd, dɪd ɪt?',
        translation: 'Nothing bad happened, did it?',
      },
      {
        id: 83,
        expression: 'Случилось что-то плохое, не так ли?',
        transcription: 'ˈsʌmθɪŋ bæd ˈhæpənd, dɪdnt ɪt?',
        translation: "Something bad happened, didn't it?",
      },
      {
        id: 84,
        expression: 'Ничего плохого не случится, правда?',
        transcription: 'ˈnʌθɪŋ bæd wɪl ˈhæpən, wɪl ɪt?',
        translation: 'Nothing bad will happen, will it?',
      },
      {
        id: 85,
        expression: 'Давай подождем, пока дождь прекратится, хорошо?',
        transcription: 'lɛts weɪt tɪl ðə reɪn stɒps, ʃəl wiː?',
        translation: "Let's wait till the rain stops, shall we?",
      },
      {
        id: 86,
        expression: 'Давай пойдем в парк, хорошо?',
        transcription: 'lɛts gəʊ tə ðə pɑːk, ʃəl wiː?',
        translation: "Let's go to the park, shall we?",
      },
      {
        id: 87,
        expression: 'Давай пойдем туда вместе, хорошо?',
        transcription: 'lɛts gəʊ ðeə təˈgɛðə, ʃəl wiː?',
        translation: "Let's go there together, shall we?",
      },
      {
        id: 88,
        expression: 'Давай подождем, пока снег не прекратится, хорошо?',
        transcription: 'lɛts weɪt tɪl ðə snəʊ stɒps, ʃəl wiː?',
        translation: "Let's wait till the snow stops, shall we?",
      },
      {
        id: 89,
        expression: 'Давай подождем его вместе, хорошо?',
        transcription: 'lɛts weɪt fə hɪm təˈgɛðə, ʃəl wiː?',
        translation: "Let's wait for him together, shall we?",
      },
      {
        id: 90,
        expression: 'Все это знают, не так ли?',
        transcription: 'ˈɛvrɪwʌn nəʊz ɪt, dəʊnt ðeɪ?',
        translation: "Everyone knows it, don't they?",
      },
      {
        id: 91,
        expression: 'Никто этого не знает, не так ли?',
        transcription: 'ˈnəʊbədi nəʊz ɪt, dʊ ðeɪ?',
        translation: 'Nobody knows it, do they?',
      },
      {
        id: 92,
        expression: 'Ты это знаешь, не так ли?',
        transcription: 'jʊ nəʊ ɪt, dəʊnt juː?',
        translation: "You know it, don't you?",
      },
      {
        id: 93,
        expression: 'Никто не пошел на встречу, не так ли?',
        transcription: 'ˈnəʊbədi wɛnt tə ðə ˈmiːtɪŋ, dɪd ðeɪ?',
        translation: 'Nobody went to the meeting, did they?',
      },
      {
        id: 94,
        expression: 'Ты еще не посетил это место, не так ли?',
        transcription: 'jʊ hævnt ˈvɪzɪtɪd ðɪs pleɪs jɛt, həv juː?',
        translation: "You haven't visited this place yet, have you?",
      },
      {
        id: 95,
        expression: 'Давай позовем официанта, хорошо?',
        transcription: 'lɛts kɔːl ðə ˈweɪtə, ʃəl wiː?',
        translation: "Let's call the waiter, shall we?",
      },
      {
        id: 96,
        expression: 'Мне действительно везет, не так ли?',
        transcription: 'aɪm ˈrɪəli ˈlʌki, ɑːnt aɪ?',
        translation: "I'm really lucky, aren't I?",
      },
      {
        id: 97,
        expression: 'Он не опоздал на встречу, не так ли?',
        transcription: 'hi wɒznt leɪt fə ðə ˈmiːtɪŋ, wəz hiː?',
        translation: "He wasn't late for the meeting, was he?",
      },
      {
        id: 98,
        expression: 'Там никого не было, не так ли?',
        transcription: 'nəʊ wʌn wəz ðeə, wə ðeɪ?',
        translation: 'No one was there, were they?',
      },
      {
        id: 99,
        expression: 'Постарайся быть осторожнее, ладно?',
        transcription:
          'traɪ tə bi mɔː ˈkeəfʊl, wɪl juː? / traɪ tə bi mɔː ˈkeəfʊl, wəʊnt juː?',
        translation:
          "Try to be more careful, will you? / Try to be more careful, won't you?",
      },
      {
        id: 100,
        expression: 'Не забудь сделать это, ладно?',
        transcription: 'dəʊnt fəˈgɛt tə dʊ ɪt, wɪl juː?',
        translation: "Don't forget to do it, will you?",
      },
      {
        id: 101,
        expression: 'В супе недостаточно соли, не так ли?',
        transcription: 'ðər ˈɪznt ɪˈnʌf sɒlt ɪn ðə suːp, ɪz ðeə?',
        translation: "There isn't enough salt in the soup, is there?",
      },
      {
        id: 102,
        expression: 'Ты никогда этого не делаешь, не так ли?',
        transcription: 'jʊ ˈnɛvə dʊ ɪt, dʊ juː?',
        translation: 'You never do it, do you?',
      },
      {
        id: 103,
        expression: 'Никто этого не знает, не так ли?',
        transcription: 'nəʊ wʌn nəʊz ɪt, dʊ ðeɪ?',
        translation: 'No one knows it, do they?',
      },
      {
        id: 104,
        expression: 'Я прав, не так ли?',
        transcription: 'aɪm raɪt, ɑːnt aɪ?',
        translation: "I'm right, aren't I?",
      },
      {
        id: 105,
        expression: 'Ничего не готово, не так ли?',
        transcription: 'ˈnʌθɪŋ z ˈrɛdi, ɪz ɪt?',
        translation: 'Nothing is ready, is it?',
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Разделительные вопросы</h3>
<p>В английском языке существует особый тип вопросов - разделительные вопросы. Они используются для уточнения чего-либо. Разделительный вопрос состоит из двух частей, разделенных запятой: основной части и "хвостика", который всегда переводится "не так ли".</p>
<p>It's good, isn't it? - Это хорошо, не так ли?</p>
<p>It isn't good, is it? - Это не хорошо, не так ли?</p>
<p>He didn't help you, did she? - Она не помогла тебе, не так ли?</p>
<p>Как видим, "хвостик" всегда состоит из двух слов: вспомогательный / модальный глагол + местоимение, используемое в первой части. Вспомогательный или модальный глагол в "хвостике" ставится в том времени, в котором стоит глагол в основной части. Если первая часть утвердительная, то хвостик будет содержать отрицание. И наоборот.</p>
<p>You never go there, do you? - Ты никогда туда не ходишь, не так ли?</p>
</div>`,
    },
  ],
];
