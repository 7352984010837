export const contentLesson109 = [
  [
    {
      title: "Урок 109. Phrasal Verbs, Prepositions",
    },
    [
      {
        id: 1,
        expression: "Я начал искать новую работу",
        transcription: "aɪ ˈstɑːtɪd ˈlʊkɪŋ fər ə njuː ʤɒb",
        translation: "I started looking for a new job",
      },
      {
        id: 2,
        expression: "Я часто смотрю на этот новый дом",
        transcription: "aɪ ˈɒf(ə)n lʊk ət ðɪs njuː haʊs",
        translation: "I often look at this new house",
      },
      {
        id: 3,
        expression: "Подожди немного!",
        transcription: "həʊld ɒn ə ˈlɪtl!",
        translation: "Hold on a little!",
      },
      {
        id: 4,
        expression: "Она отсутствует в данный момент",
        transcription: "ʃiːz aʊt ət ðə ˈməʊmənt",
        translation: "She's out at the moment",
      },
      {
        id: 5,
        expression: "Я хотел бы примерить эту рубашку",
        transcription: "aɪd laɪk tə traɪ ɒn ðɪs ʃɜːt",
        translation: "I'd like to try on this shirt",
      },
      {
        id: 6,
        expression: "Я обдумаю это",
        transcription: "aɪl θɪŋk ɪt ˈəʊvə",
        translation: "I'll think it over",
      },
      {
        id: 7,
        expression: "Его машина внезапно сломалась",
        transcription: "hɪz kɑː brəʊk daʊn ɔːl əv ə ˈsʌdn",
        translation: "His car broke down all of a sudden",
      },
      {
        id: 8,
        expression: "Я заеду в отель в шесть часов вечера",
        transcription: "aɪl ʧɛk ɪn ðə həʊˈtɛl ət sɪks əˈklɒk ɪn ði ˈiːvnɪŋ",
        translation: "I'll check in the hotel at six o'clock in the evening",
      },
      {
        id: 9,
        expression: "Она хотела бы примерить это платье",
        transcription: "ʃiːd laɪk tə traɪ ɒn ðɪs drɛs",
        translation: "She'd like to try on this dress",
      },
      {
        id: 10,
        expression: "Она сняла свою шляпу",
        transcription: "ʃi tʊk ɒf hə hæt",
        translation: "She took off her hat",
      },
      {
        id: 11,
        expression: "Мне нужно правильно заполнить эти документы",
        transcription: "aɪ niːd tə fɪl ɪn ðiːz ˈdɒkjʊmənts kəˈrɛktli",
        translation: "I need to fill in these documents correctly",
      },
      {
        id: 12,
        expression: "Ее мать отсутствует / Ее мать в отъезде",
        transcription: "hə ˈmʌðə z aʊt / hə ˈmʌðə z əˈweɪ",
        translation: "Her mother is out / Her mother is away",
      },
      {
        id: 13,
        expression: "Я встал очень рано",
        transcription: "aɪ gɒt ʌp ˈvɛri ˈɜːli",
        translation: "I got up very early",
      },
      {
        id: 14,
        expression: "Тебе нужно заполнить эту форму",
        transcription: "jʊ niːd tə fɪl ɪn ðɪs fɔːm",
        translation: "You need to fill in this form",
      },
      {
        id: 15,
        expression: "Во сколько она обычно встает?",
        transcription: "wɒt taɪm dəz ʃi ˈjuːʒʊəli gɛt ʌp?",
        translation: "What time does she usually get up?",
      },
      {
        id: 16,
        expression: "Ему нужно обдумать это",
        transcription: "hi niːdz tə θɪŋk ɪt ˈəʊvə",
        translation: "He needs to think it over",
      },
      {
        id: 17,
        expression: "Мои родители в отъезде",
        transcription: "maɪ ˈpeərənts ər əˈweɪ",
        translation: "My parents are away",
      },
      {
        id: 18,
        expression: "Заполните форму",
        transcription: "fɪl ɪn ðə fɔːm!",
        translation: "Fill in the form!",
      },
      {
        id: 19,
        expression: "Могу я это примерить?",
        transcription: "kən aɪ traɪ ɪt ɒn?",
        translation: "Can I try it on?",
      },
      {
        id: 20,
        expression: "Она вышла",
        transcription: "ʃiːz aʊt / ʃi wɛnt aʊt",
        translation: "She's out / She went out",
      },
      {
        id: 21,
        expression: "Его нет дома",
        transcription: "hiːz aʊt",
        translation: "He's out",
      },
      {
        id: 22,
        expression: "Во сколько он обычно просыпается?",
        transcription: "wɒt taɪm dəz hi ˈjuːʒʊəli weɪk ʌp?",
        translation: "What time does he usually wake up?",
      },
      {
        id: 23,
        expression: "Он вырос в маленьком городке",
        transcription: "hi gruː ʌp ɪn ə smɔːl taʊn",
        translation: "He grew up in a small town",
      },
      {
        id: 24,
        expression: "В какое время ты будешь регистрироваться?",
        transcription: "wɒt taɪm wɪl jʊ ʧɛk ɪn?",
        translation: "What time will you check in?",
      },
      {
        id: 25,
        expression: "Он надел свитер",
        transcription: "hi pʊt ɒn ðə ˈswɛtə",
        translation: "He put on the sweater",
      },
      {
        id: 26,
        expression: "Его отец уехал",
        transcription: "hɪz ˈfɑːðə z əˈweɪ / hɪz ˈfɑːðə wɛnt əˈweɪ",
        translation: "His father is away / His father went away",
      },
      {
        id: 27,
        expression: "Подожди минуту!",
        transcription: "həʊld ɒn ə ˈmɪnɪt!",
        translation: "Hold on a minute!",
      },
      {
        id: 28,
        expression: "Я с нетерпением жду нашей встречи",
        transcription: "aɪ lʊk ˈfɔːwəd tʊ ˈaʊə ˈmiːtɪŋ",
        translation: "I look forward to our meeting",
      },
      {
        id: 29,
        expression: "Я хотел бы примерить эту футболку",
        transcription: "aɪd laɪk tə traɪ ɒn ðɪs ˈtiːʃɜːt",
        translation: "I'd like to try on this T-shirt",
      },
      {
        id: 30,
        expression: "Он снял свое пальто",
        transcription: "hi tʊk ɒf ɪz kəʊt",
        translation: "He took off his coat",
      },
      {
        id: 31,
        expression: "Он вырос в Канаде",
        transcription: "hi gruː ʌp ɪn ˈkænədə",
        translation: "He grew up in Canada",
      },
      {
        id: 32,
        expression: "Эта машина часто ломается",
        transcription: "ðɪs kɑːr ˈɒf(ə)n breɪks daʊn",
        translation: "This car often breaks down",
      },
      {
        id: 33,
        expression: "Во сколько ты будешь выписываться?",
        transcription: "wɒt taɪm wɪl jʊ ʧɛk aʊt?",
        translation: "What time will you check out?",
      },
      {
        id: 34,
        expression: "Я надел теплое пальто",
        transcription: "aɪ pʊt ɒn ə wɔːm kəʊt",
        translation: "I put on a warm coat",
      },
      {
        id: 35,
        expression: "Он ушел",
        transcription: "hi wɛnt aʊt",
        translation: "He went out",
      },
      {
        id: 36,
        expression: "Я снял свою куртку",
        transcription: "aɪ tʊk ɒf maɪ ˈʤækɪt",
        translation: "I took off my jacket",
      },
      {
        id: 37,
        expression: "Я надел свою обувь",
        transcription: "aɪ pʊt ɒn maɪ ʃuːz",
        translation: "I put on my shoes",
      },
      {
        id: 38,
        expression: "Я с нетерпением жду твоего ответа",
        transcription: "aɪ lʊk ˈfɔːwəd tə jər ˈɑːnsə",
        translation: "I look forward to your answer",
      },
      {
        id: 39,
        expression: "Подожди секунду!",
        transcription: "həʊld ɒn ə ˈsɛkənd!",
        translation: "Hold on a second!",
      },
      {
        id: 40,
        expression: "Она выросла в Соединенных Штатах",
        transcription: "ʃi gruː ʌp ɪn ðə jʊˈnaɪtɪd steɪts",
        translation: "She grew up in the United States",
      },
      {
        id: 41,
        expression: "Мы выпишемся в одиннадцать часов утра",
        transcription: "wiːl ʧɛk aʊt ət ɪˈlɛvn əˈklɒk ɪn ðə ˈmɔːnɪŋ",
        translation: "We'll check out at eleven o'clock in the morning",
      },
      {
        id: 42,
        expression: "Я хотел бы примерить это",
        transcription: "aɪd laɪk tə traɪ ɪt ɒn",
        translation: "I'd like to try it on",
      },
      {
        id: 43,
        expression: "Они решили расстаться",
        transcription: "ðeɪ dɪˈsaɪdɪd tə breɪk ʌp",
        translation: "They decided to break up",
      },
      {
        id: 44,
        expression: "Я хотел бы понять, что с этим делать",
        transcription: "aɪd laɪk tə ˈfɪgər aʊt wɒt tə dʊ wɪð ɪt",
        translation: "I'd like to figure out what to do with it",
      },
      {
        id: 45,
        expression: "Ты можешь посмотреть это в словаре",
        transcription: "jʊ kən lʊk ɪt ʌp ɪn ə ˈdɪkʃ(ə)n(ə)ri",
        translation: "You can look it up in a dictionary",
      },
      {
        id: 46,
        expression: "Составьте свои собственные предложения",
        transcription: "meɪk ʌp jər əʊn ˈsɛntənsɪz",
        translation: "Make up your own sentences",
      },
      {
        id: 47,
        expression: "Я не могу понять, как это работает",
        transcription: "aɪ kɑːnt ˈfɪgər aʊt haʊ ɪt wɜːks",
        translation: "I can't figure out how it works",
      },
      {
        id: 48,
        expression:
          "Это хорошая идея, составить наши собственные предложения для большей практики",
        transcription:
          "ɪts ə gʊd aɪˈdɪə tə meɪk ʌp ˈaʊər əʊn ˈsɛntənsɪz fə mɔː ˈpræktɪs",
        translation:
          "It's a good idea to make up our own sentences for more practice",
      },
      {
        id: 49,
        expression: "Они внезапно отменили конференцию",
        transcription: "ðeɪ kɔːld ɒf ðə ˈkɒnfərəns ɔːl əv ə ˈsʌdn",
        translation: "They called off the conference all of a sudden",
      },
      {
        id: 50,
        expression: "Мне нужно как-то решить эту проблему",
        transcription: "aɪ niːd tə sɔːt aʊt ðɪs ˈprɒbləm ˈsʌmhaʊ",
        translation: "I need to sort out this problem somehow",
      },
      {
        id: 51,
        expression: "Он не может продолжать это делать",
        transcription: "hi kɑːnt gəʊ ɒn ˈdu(ː)ɪŋ ɪt",
        translation: "He can't go on doing it",
      },
      {
        id: 52,
        expression: "Их брак был счастливым в начале, но затем они расстались",
        transcription:
          "ðeə ˈmærɪʤ wəz ˈhæpi ɪn ðə bɪˈgɪnɪŋ bət ðɛn ðeɪ brəʊk ʌp",
        translation:
          "Their marriage was happy in the beginning but then they broke up",
      },
      {
        id: 53,
        expression: "Я с нетерпением жду посещения этого места",
        transcription: "aɪ lʊk ˈfɔːwəd tə ˈvɪzɪtɪŋ ðɪs pleɪs",
        translation: "I look forward to visiting this place",
      },
      {
        id: 54,
        expression: "Мне нужно некоторое время, чтобы обдумать это",
        transcription: "aɪ niːd səm taɪm tə θɪŋk ɪt ˈəʊvə",
        translation: "I need some time to think it over",
      },
      {
        id: 55,
        expression: "Я хотел бы продолжать это делать",
        transcription: "aɪd laɪk tə gəʊ ɒn ˈdu(ː)ɪŋ ɪt",
        translation: "I'd like to go on doing it",
      },
      {
        id: 56,
        expression:
          "Если ты хочешь больше прогресса, тебе нужно составить свои собственные предложения",
        transcription:
          "ɪf jʊ wɒnt mɔː ˈprəʊgrəs, jʊ niːd tə meɪk ʌp jər əʊn ˈsɛntənsɪz",
        translation:
          "If you want more progress, you need to make up your own sentences",
      },
      {
        id: 57,
        expression: "Я посмотрю это слово в словаре",
        transcription: "aɪl lʊk ʌp ðɪs wɜːd ɪn ə ˈdɪkʃ(ə)n(ə)ri",
        translation: "I'll look up this word in a dictionary",
      },
      {
        id: 58,
        expression: "Я разберусь с этой проблемой",
        transcription: "aɪl sɔːt aʊt ðɪs ˈprɒbləm",
        translation: "I'll sort out this problem",
      },
      {
        id: 59,
        expression: "Тебе следует разобраться с этой проблемой",
        transcription: "jʊ ʃəd sɔːt aʊt ðɪs ˈprɒbləm",
        translation: "You should sort out this problem",
      },
      {
        id: 60,
        expression: "Я разберусь со своими проблемами",
        transcription: "aɪl sɔːt aʊt maɪ ˈprɒbləmz",
        translation: "I'll sort out my problems",
      },
      {
        id: 61,
        expression: "Ты можешь посмотреть это слово в словаре",
        transcription: "jʊ kən lʊk ʌp ðɪs wɜːd ɪn ə ˈdɪkʃ(ə)n(ə)ri",
        translation: "You can look up this word in a dictionary",
      },
      {
        id: 62,
        expression: "Ей нужно посмотреть эти слова в словаре",
        transcription: "ʃi niːdz tə lʊk ʌp ðiːz wɜːdz ɪn ə ˈdɪkʃ(ə)n(ə)ri",
        translation: "She needs to look up these words in a dictionary",
      },
      {
        id: 63,
        expression: "Ты посмотрел это слово в словаре?",
        transcription: "dɪd jʊ lʊk ʌp ðɪs wɜːd ɪn ə ˈdɪkʃ(ə)n(ə)ri?",
        translation: "Did you look up this word in a dictionary?",
      },
      {
        id: 64,
        expression: "Давай продолжим говорить об этом!",
        transcription: "lɛts gəʊ ɒn ˈtɔːkɪŋ əˈbaʊt ɪt!",
        translation: "Let's go on talking about it!",
      },
      {
        id: 65,
        expression: "Давай продолжим делать это!",
        transcription: "lɛts gəʊ ɒn ˈdu(ː)ɪŋ ɪt!",
        translation: "Let's go on doing it!",
      },
      {
        id: 66,
        expression: "Давай поговорим об этом!",
        transcription: "lɛts tɔːk əˈbaʊt ɪt!",
        translation: "Let's talk about it!",
      },
      {
        id: 67,
        expression: "Я постараюсь разобраться с этой проблемой",
        transcription: "aɪl traɪ tə sɔːt aʊt ðɪs ˈprɒbləm",
        translation: "I'll try to sort out this problem",
      },
      {
        id: 68,
        expression: "Она постарается разобраться с этой проблемой",
        transcription: "ʃiːl traɪ tə sɔːt aʊt ðɪs ˈprɒbləm",
        translation: "She'll try to sort out this problem",
      },
      {
        id: 69,
        expression: "Я постараюсь разобраться с этими трудностями",
        transcription: "aɪl traɪ tə sɔːt aʊt ðiːz ˈdɪfɪkəltiz",
        translation: "I'll try to sort out these difficulties",
      },
      {
        id: 70,
        expression: "Он бросил пить алкоголь",
        transcription: "hi geɪv ʌp ˈdrɪŋkɪŋ ˈælkəhɒl",
        translation: "He gave up drinking alcohol",
      },
      {
        id: 71,
        expression: "Он бросил курить",
        transcription: "hi geɪv ʌp ˈsməʊkɪŋ",
        translation: "He gave up smoking",
      },
      {
        id: 72,
        expression: "Она бросила курить?",
        transcription: "dɪd ʃi gɪv ʌp ˈsməʊkɪŋ?",
        translation: "Did she give up smoking?",
      },
      {
        id: 73,
        expression: "Нам нужно составить наши собственные предложения",
        transcription: "wi niːd tə meɪk ʌp ˈaʊər əʊn ˈsɛntənsɪz",
        translation: "We need to make up our own sentences",
      },
      {
        id: 74,
        expression: "Тебе нужно составить несколько новых предложений",
        transcription: "jʊ niːd tə meɪk ʌp səm njuː ˈsɛntənsɪz",
        translation: "You need to make up some new sentences",
      },
      {
        id: 75,
        expression: "Мне нужно составить мои собственные предложения",
        transcription: "aɪ niːd tə meɪk ʌp maɪ əʊn ˈsɛntənsɪz",
        translation: "I need to make up my own sentences",
      },
      {
        id: 76,
        expression: "Он бросил есть сахар",
        transcription: "hi geɪv ʌp ˈiːtɪŋ ˈʃʊgə",
        translation: "He gave up eating sugar",
      },
      {
        id: 77,
        expression: "Он бросил пить кофе",
        transcription: "hi geɪv ʌp ˈdrɪŋkɪŋ ˈkɒfi",
        translation: "He gave up drinking coffee",
      },
      {
        id: 78,
        expression: "Он сказал мне немного подождать",
        transcription: "hi təʊld mi tə həʊld ɒn ə ˈlɪtl",
        translation: "He told me to hold on a little",
      },
      {
        id: 79,
        expression: "Он сказал тебе подождать?",
        transcription: "dɪd hi tɛl jʊ tə həʊld ɒn?",
        translation: "Did he tell you to hold on?",
      },
      {
        id: 80,
        expression: "Он попросил меня немного подождать",
        transcription: "hi ɑːskt mi tə həʊld ɒn ə ˈlɪtl",
        translation: "He asked me to hold on a little",
      },
      {
        id: 81,
        expression: "Я вошел в систему, а затем я вышел",
        transcription: "aɪ lɒgd ɪn ənd ðɛn aɪ lɒgd aʊt",
        translation: "I logged in and then I logged out",
      },
      {
        id: 82,
        expression: "Я вошел в систему некоторое время назад",
        transcription: "aɪ lɒgd ɪn səm taɪm əˈgəʊ",
        translation: "I logged in some time ago",
      },
      {
        id: 83,
        expression: "Я вышел из системы некоторое время назад",
        transcription: "aɪ lɒgd aʊt səm taɪm əˈgəʊ",
        translation: "I logged out some time ago",
      },
      {
        id: 84,
        expression: "Я не могу выяснить, как это сделать",
        transcription: "aɪ kɑːnt ˈfɪgər aʊt haʊ tə dʊ ɪt",
        translation: "I can't figure out how to do it",
      },
      {
        id: 85,
        expression: "Мне нужно выяснить, как это сделать",
        transcription: "aɪ niːd tə ˈfɪgər aʊt haʊ tə dʊ ɪt",
        translation: "I need to figure out how to do it",
      },
      {
        id: 86,
        expression: "Я с нетерпением жду поездки в Соединенные Штаты",
        transcription: "aɪ lʊk ˈfɔːwəd tə ˈgəʊɪŋ tə ðə jʊˈnaɪtɪd steɪts",
        translation: "I look forward to going to the United States",
      },
      {
        id: 87,
        expression: "Я посмотрю это в словаре",
        transcription: "aɪl lʊk ɪt ʌp ɪn ə ˈdɪkʃ(ə)n(ə)ri",
        translation: "I'll look it up in a dictionary",
      },
      {
        id: 88,
        expression: "Он продолжал объяснять это",
        transcription: "hi wɛnt ɒn ɪksˈpleɪnɪŋ ɪt",
        translation: "He went on explaining it",
      },
      {
        id: 89,
        expression: "Он решил бросить курить",
        transcription: "hi dɪˈsaɪdɪd tə gɪv ʌp ˈsməʊkɪŋ",
        translation: "He decided to give up smoking",
      },
      {
        id: 90,
        expression: "Я не могу понять, что делать дальше",
        transcription: "aɪ kɑːnt ˈfɪgər aʊt wɒt tə dʊ nɛkst",
        translation: "I can't figure out what to do next",
      },
      {
        id: 91,
        expression: "Почему они расстались?",
        transcription: "waɪ dɪd ðeɪ breɪk ʌp?",
        translation: "Why did they break up?",
      },
      {
        id: 92,
        expression: "Он отменил встречу",
        transcription: "hi kɔːld ɒf ðə ˈmiːtɪŋ",
        translation: "He called off the meeting",
      },
      {
        id: 93,
        expression: "Игра окончена",
        transcription: "ðə geɪm z ˈəʊvə",
        translation: "The game is over",
      },
      {
        id: 94,
        expression: "Я посмотрю это выражение в словаре",
        transcription: "aɪl lʊk ʌp ðɪs ɪksˈprɛʃən ɪn ə ˈdɪkʃ(ə)n(ə)ri",
        translation: "I'll look up this expression in a dictionary",
      },
      {
        id: 95,
        expression: "Я хочу продолжать изучать английский здесь",
        transcription: "aɪ wɒnt tə gəʊ ɒn ˈlɜːnɪŋ ˈɪŋglɪʃ hɪə",
        translation: "I want to go on learning English here",
      },
      {
        id: 96,
        expression: "Продолжай говорить!",
        transcription: "gəʊ ɒn ˈtɔːkɪŋ!",
        translation: "Go on talking!",
      },
      {
        id: 97,
        expression: "Мне нужно войти в систему",
        transcription: "aɪ niːd tə lɒg ɪn",
        translation: "I need to log in",
      },
      {
        id: 98,
        expression: "Игра закончена",
        transcription: "geɪm z ˈəʊvə",
        translation: "Game is over",
      },
      {
        id: 99,
        expression: "Урок окончен",
        transcription: "ðə ˈlɛsn z ˈəʊvə",
        translation: "The lesson is over",
      },
      {
        id: 100,
        expression: "Они расстались",
        transcription: "ðeɪ brəʊk ʌp / ðeɪ splɪt ʌp",
        translation: "They broke up / They split up",
      },
    ],
    {
      theory: `<div class="theory-block">
<p>В английском языке существуют фразовые глаголы, которые представляют собой неотъемлемое сочетание глагола и предлога.</p>
<h3>look + for</h3>
<p>- искать кого-то / что-то</p>
<p>We are looking for an unmrella - Мы ищем зонтик.</p>
<p>And then he started looking for a new job - И тогда он начал искать новую работу.</p>
<h3>look + at</h3>
<p>- смотреть на кого-то / что-то</p>
<p>Look at me, please! - Посмотри на меня, пожалуйста!</p>
<p>I like looking at the fire - Мне нравится смотреть на огонь.</p>
</div>`,
    },
  ],
];
