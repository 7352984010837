export const wordsLesson235 = [
  [
    {
      id: 1,
      word: 'adjective',
      transcription: 'ˈæʤɪktɪv',
      translation: 'прилагательное',
    },
    {
      id: 2,
      word: 'adverb',
      transcription: 'ˈædvɜːb',
      translation: 'наречие',
    },
    {
      id: 3,
      word: 'bluntly',
      transcription: 'ˈblʌntli',
      translation: 'прямо, резко',
    },
    {
      id: 4,
      word: 'cloud',
      transcription: 'klaʊd',
      translation: 'облако',
    },
    {
      id: 5,
      word: 'commonly',
      transcription: 'ˈkɒmənli',
      translation: 'часто',
    },
    {
      id: 6,
      word: 'competitive',
      transcription: 'kəmˈpɛtɪtɪv',
      translation: 'конкурентный',
    },
    {
      id: 7,
      word: 'consequently',
      transcription: 'ˈkɒnsɪkwəntli',
      translation: 'следовательно',
    },
    {
      id: 8,
      word: 'contrast',
      transcription: 'ˈkɒntrɑːst',
      translation: 'отличие',
    },
    {
      id: 9,
      word: 'disagreement',
      transcription: 'ˌdɪsəˈɡriːmənt',
      translation: 'разногласие',
    },
    {
      id: 10,
      word: 'employer',
      transcription: 'ɪmˈplɔɪə',
      translation: 'работодатель',
    },
    {
      id: 11,
      word: 'evidence',
      transcription: 'ˈɛvɪdəns',
      translation: 'доказательство (улика)',
    },
    {
      id: 12,
      word: 'furthermore',
      transcription: 'ˈfɜːðəˈmɔː',
      translation: 'более того',
    },
    {
      id: 13,
      word: 'influential',
      transcription: 'ˌɪnflʊˈɛnʃəl',
      translation: 'влиятельный',
    },
    {
      id: 14,
      word: 'kindergarten',
      transcription: 'ˈkɪndəˌɡɑːtn',
      translation: 'детский сад',
    },
    {
      id: 15,
      word: 'limited',
      transcription: 'ˈlɪmɪtɪd',
      translation: 'ограниченный',
    },
    {
      id: 16,
      word: 'linking words',
      transcription: 'ˈlɪŋkɪŋ wɜːdz',
      translation: 'связывающие слова',
    },
    {
      id: 17,
      word: 'lion',
      transcription: 'ˈlaɪən',
      translation: 'лев',
    },
    {
      id: 18,
      word: 'mildly',
      transcription: 'ˈmaɪldli',
      translation: 'мягко',
    },
    {
      id: 19,
      word: 'minus',
      transcription: 'ˈmaɪnəs',
      translation: 'минус',
    },
    {
      id: 20,
      word: 'mouth',
      transcription: 'maʊθ',
      translation: 'рот',
    },
    {
      id: 21,
      word: 'noun',
      transcription: 'naʊn',
      translation: 'существительное',
    },
    {
      id: 22,
      word: 'in a nutshell',
      transcription: 'ɪn ə ˈnʌtʃɛl',
      translation: 'в двух словах',
    },
    {
      id: 23,
      word: 'plus',
      transcription: 'plʌs',
      translation: 'плюс',
    },
    {
      id: 24,
      word: 'space',
      transcription: 'speɪs',
      translation: 'пространство',
    },
    {
      id: 25,
      word: 'stuff',
      transcription: 'stʌf',
      translation: 'вещи',
    },
    {
      id: 26,
      word: 'tip',
      transcription: 'tɪp',
      translation: 'совет',
    },
    {
      id: 27,
      word: 'unlucky',
      transcription: 'ʌnˈlʌki',
      translation: 'неудачный',
    },
    {
      id: 28,
      word: 'unnoticed',
      transcription: 'ʌnˈnəʊtɪst',
      translation: 'незамеченный',
    },
    {
      id: 29,
      word: 'wisely',
      transcription: 'ˈwaɪzli',
      translation: 'мудро',
    },
  ],
];
