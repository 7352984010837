export const contentLesson113 = [
  [
    {
      title: "Урок 113. Present Simple and Present Continuous: difference",
    },
    [
      {
        id: 1,
        expression: "Она ест рис каждый день",
        transcription: "ʃi iːts raɪs ˈɛvri deɪ",
        translation: "She eats rice every day",
      },
      {
        id: 2,
        expression: "Они часто играют в футбол?",
        transcription: "dʊ ðeɪ ˈɒf(ə)n pleɪ ˈfʊtbɔːl?",
        translation: "Do they often play football?",
      },
      {
        id: 3,
        expression: "Она идет в школу сейчас",
        transcription: "ʃiːz ˈgəʊɪŋ tə skuːl naʊ",
        translation: "She's going to school now",
      },
      {
        id: 4,
        expression: "Вода кипит сейчас?",
        transcription: "ɪz ðə ˈwɔːtə ˈbɔɪlɪŋ naʊ?",
        translation: "Is the water boiling now?",
      },
      {
        id: 5,
        expression: "Она ест рис каждый день?",
        transcription: "dəz ʃi iːt raɪs ˈɛvri deɪ?",
        translation: "Does she eat rice every day?",
      },
      {
        id: 6,
        expression: "Они часто играют в гольф?",
        transcription: "dʊ ðeɪ ˈɒf(ə)n pleɪ gɒlf?",
        translation: "Do they often play golf?",
      },
      {
        id: 7,
        expression: "Смотри! Что-то странное происходит",
        transcription: "lʊk! ˈsʌmθɪŋ streɪnʤ ɪz ˈhæpnɪŋ",
        translation: "Look! Something strange is happening",
      },
      {
        id: 8,
        expression: "Она сейчас идет в школу",
        transcription: "ʃiːz ˈgəʊɪŋ tə skuːl naʊ",
        translation: "She's going to school now",
      },
      {
        id: 9,
        expression: "Я обычно сижу перед компьютером",
        transcription: "aɪ ˈjuːʒʊəli sɪt ɪn frʌnt əv ðə kəmˈpjuːtə",
        translation: "I usually sit in front of the computer",
      },
      {
        id: 10,
        expression: "Послушай! Кто-то плачет",
        transcription: "ˈlɪsn! ˈsʌmbədi z ˈkraɪɪŋ",
        translation: "Listen! Somebody is crying",
      },
      {
        id: 11,
        expression:
          "Подростки проводят много времени в социальных сетях в наше время",
        transcription:
          "ˈtiːnˌeɪʤəz ə ˈspɛndɪŋ ə lɒt əv taɪm ɪn ˈsəʊʃəl ˈnɛtwɜːks ˈnaʊədeɪz",
        translation:
          "Teenagers are spending a lot of time in social networks nowadays",
      },
      {
        id: 12,
        expression: "Мой папа сейчас ведет машину",
        transcription: "maɪ ˈfɑːðə z ˈdraɪvɪŋ ə kɑːr ət ðə ˈməʊmənt",
        translation: "My father is driving a car at the moment",
      },
      {
        id: 13,
        expression: "Близнецы играют в компьютерные игры в данный момент",
        transcription: "ðə twɪnz ə ˈpleɪɪŋ kəmˈpjuːtə geɪmz ət ðə ˈməʊmənt",
        translation: "The twins are playing computer games at the moment",
      },
      {
        id: 14,
        expression: "Я читаю очень интересную книгу в данный момент",
        transcription: "aɪm ˈriːdɪŋ ə ˈvɛri ˈɪntrɪstɪŋ bʊk ət ðə ˈməʊmənt",
        translation: "I'm reading a very interesting book at the moment",
      },
      {
        id: 15,
        expression: "Они сейчас играют в гольф?",
        transcription: "ɑː ðeɪ ˈpleɪɪŋ gɒlf naʊ?",
        translation: "Are they playing golf now?",
      },
      {
        id: 16,
        expression:
          "Подростки обычно проводят много времени в социальных сетях",
        transcription:
          "ˈtiːnˌeɪʤəz ˈjuːʒʊəli spɛnd lɒts əv taɪm ɪn ˈsəʊʃəl ˈnɛtwɜːks",
        translation: "Teenagers usually spend lots of time in social networks",
      },
      {
        id: 17,
        expression: "Ты покидаешь вечеринку сейчас?",
        transcription: "ɑː jʊ ˈliːvɪŋ ðə ˈpɑːti naʊ?",
        translation: "Are you leaving the party now?",
      },
      {
        id: 18,
        expression: "Сейчас я упаковываю свою одежду",
        transcription: "aɪm ˈpækɪŋ maɪ kləʊðz naʊ",
        translation: "I'm packing my clothes now",
      },
      {
        id: 19,
        expression: "Он всегда приходит в школу поздно",
        transcription: "hi ˈɔːlweɪz əˈraɪvz ət skuːl leɪt",
        translation: "He always arrives at school late",
      },
      {
        id: 20,
        expression: "Он сейчас слушает радио",
        transcription: "hiːz ˈlɪsnɪŋ tə ðə ˈreɪdɪəʊ naʊ",
        translation: "He's listening to the radio now",
      },
      {
        id: 21,
        expression: "Моя мама часто готовит",
        transcription: "maɪ ˈmʌðər ˈɒf(ə)n kʊks",
        translation: "My mother often cooks",
      },
      {
        id: 22,
        expression: "Она сейчас собирает эти сумки",
        transcription: "ʃiːz ˈpækɪŋ ðiːz bægz naʊ",
        translation: "She's packing these bags now",
      },
      {
        id: 23,
        expression: "Помедленнее! Ты едешь слишком быстро",
        transcription: "sləʊ daʊn! jʊə ˈdraɪvɪŋ tuː fɑːst",
        translation: "Slow down! You're driving too fast",
      },
      {
        id: 24,
        expression: "Где ты живешь?",
        transcription: "weə dʊ jʊ lɪv?",
        translation: "Where do you live?",
      },
      {
        id: 25,
        expression: "Я много тренируюсь на этой неделе",
        transcription: "aɪm ˈtreɪnɪŋ ə lɒt ðɪs wiːk",
        translation: "I'm training a lot this week",
      },
      {
        id: 26,
        expression: "Мой друг и я играет в эту игру сейчас",
        transcription: "maɪ frɛnd ənd aɪ ə ˈpleɪɪŋ ðɪs geɪm naʊ",
        translation: "My friend and I are playing this game now",
      },
      {
        id: 27,
        expression: "Мой папа часто водит машину",
        transcription: "maɪ dæd ˈɒf(ə)n draɪvz ə kɑː",
        translation: "My dad often drives a car",
      },
      {
        id: 28,
        expression: "Помедленнее! Ты едешь так быстро",
        transcription: "sləʊ daʊn! jʊə ˈdraɪvɪŋ səʊ fɑːst",
        translation: "Slow down! You're driving so fast",
      },
      {
        id: 29,
        expression: "Кейт сейчас отдыхает",
        transcription: "keɪt s ˈhævɪŋ ə rɛst naʊ",
        translation: "Kate is having a rest now",
      },
      {
        id: 30,
        expression: "Солнце встает сейчас",
        transcription: "ðə sʌn z ˈraɪzɪŋ naʊ",
        translation: "The sun is rising now",
      },
      {
        id: 31,
        expression: "Я часто ложусь в кровать поздно",
        transcription: "aɪ ˈɒf(ə)n gəʊ tə bɛd leɪt",
        translation: "I often go to bed late",
      },
      {
        id: 32,
        expression: "Мой друг и я сейчас разговариваем по телефону",
        transcription: "maɪ frɛnd ənd aɪ ə ˈtɔːkɪŋ ɒn ðə fəʊn naʊ",
        translation: "My friend and I are talking on the phone now",
      },
      {
        id: 33,
        expression: "Он часто рисует в свое свободное время",
        transcription: "hi ˈɒf(ə)n drɔːz ɪn ɪz friː taɪm",
        translation: "He often draws in his free time",
      },
      {
        id: 34,
        expression: "Я тренируюсь невероятно усердно в эти дни",
        transcription: "aɪm ˈtreɪnɪŋ ɪnˈkrɛdəbli hɑːd ðiːz deɪz",
        translation: "I'm training incredibly hard these days",
      },
      {
        id: 35,
        expression: "Моя мама готовит сейчас",
        transcription: "maɪ ˈmʌðə z ˈkʊkɪŋ naʊ",
        translation: "My mother is cooking now",
      },
      {
        id: 36,
        expression: "Она делает торт в данный момент",
        transcription: "ʃiːz ˈmeɪkɪŋ ə keɪk ət ðə ˈməʊmənt",
        translation: "She's making a cake at the moment",
      },
      {
        id: 37,
        expression: "Где ты живешь сейчас?",
        transcription: "weə dʊ jʊ lɪv?",
        translation: "Where do you live?",
      },
      {
        id: 38,
        expression: "Он рисует картину",
        transcription: "hiːz ˈdrɔːɪŋ ə ˈpɪkʧə",
        translation: "He's drawing a picture",
      },
      {
        id: 39,
        expression: "Мой дедушка ходит гулять каждый день",
        transcription: "maɪ ˈgrænpɑː gəʊz fər ə wɔːk ˈɛvri deɪ",
        translation: "My grandpa goes for a walk every day",
      },
      {
        id: 40,
        expression: "Она иногда читает комиксы",
        transcription: "ʃi ˈsʌmtaɪmz riːdz ˈkɒmɪks",
        translation: "She sometimes reads comics",
      },
      {
        id: 41,
        expression: "Я сижу перед компьютером",
        transcription: "aɪm ˈsɪtɪŋ ɪn frʌnt əv ðə kəmˈpjuːtə",
        translation: "I'm sitting in front of the computer",
      },
      {
        id: 42,
        expression: "Я сейчас иду гулять",
        transcription: "aɪm ˈgəʊɪŋ fər ə wɔːk naʊ",
        translation: "I'm going for a walk now",
      },
      {
        id: 43,
        expression: "Я печатаю в данный момент",
        transcription: "aɪm ˈtaɪpɪŋ ət ðə ˈməʊmənt",
        translation: "I'm typing at the moment",
      },
      {
        id: 44,
        expression: "Она обычно ездит в школу на автобусе",
        transcription: "ʃi ˈjuːʒʊəli gəʊz tə skuːl baɪ bʌs",
        translation: "She usually goes to school by bus",
      },
      {
        id: 45,
        expression: "Она обычно ходит по магазинам на выходных",
        transcription: "ʃi ˈjuːʒʊəli gəʊz ˈʃɒpɪŋ ət ðə ˈwiːkˈɛnd",
        translation: "She usually goes shopping at the weekend",
      },
      {
        id: 46,
        expression: "Солнце встает на востоке",
        transcription: "ðə sʌn ˈraɪzɪz ɪn ði iːst",
        translation: "The sun rises in the east",
      },
      {
        id: 47,
        expression: "Я обычно добираюсь на работу на метро",
        transcription: "aɪ ˈjuːʒʊəli gɛt tə wɜːk baɪ ˈʌndəgraʊnd",
        translation: "I usually get to work by underground",
      },
      {
        id: 48,
        expression: "Вода кипит при ста градусах",
        transcription: "ðə ˈwɔːtə bɔɪlz ət wʌn ˈhʌndrəd dɪˈgriːz",
        translation: "The water boils at one hundred degrees",
      },
      {
        id: 49,
        expression: "Он делает звонок в данный момент",
        transcription: "hiːz ˈmeɪkɪŋ ə kɔːl ət ðə ˈməʊmənt",
        translation: "He's making a call at the moment",
      },
      {
        id: 50,
        expression: "Не входи в ее комнату! Она молится",
        transcription: "dəʊnt ˈɛntə hə ruːm! ʃiːz ˈpreɪɪŋ",
        translation: "Don't enter her room! She's praying",
      },
      {
        id: 51,
        expression: "Чайник кипит сейчас",
        transcription: "ðə ˈkɛtl z ˈbɔɪlɪŋ naʊ",
        translation: "The kettle is boiling now",
      },
      {
        id: 52,
        expression: "Я делаю звонок сейчас",
        transcription: "aɪm ˈmeɪkɪŋ ə kɔːl naʊ",
        translation: "I'm making a call now",
      },
      {
        id: 53,
        expression: "Он играет на гитаре почти каждый день",
        transcription: "hi pleɪz ðə gɪˈtɑːr ˈɔːlməʊst ˈɛvri deɪ",
        translation: "He plays the guitar almost every day",
      },
      {
        id: 54,
        expression: "Урок начинается в восемь часов",
        transcription: "ðə ˈlɛsn stɑːts æt 8 əˈklɒk",
        translation: "The lesson starts at 8 o'clock",
      },
      {
        id: 55,
        expression: "Там часто идет снег",
        transcription: "ɪt ˈɒf(ə)n snəʊz ðeə",
        translation: "It often snows there",
      },
      {
        id: 56,
        expression: "Послушай! Мальчик поет",
        transcription: "ˈlɪsn! ðə bɔɪ z ˈsɪŋɪŋ",
        translation: "Listen! The boy is singing",
      },
      {
        id: 57,
        expression: "Девочки идут домой",
        transcription: "ðə gɜːl ə ˈkʌmɪŋ həʊm",
        translation: "The girl are coming home",
      },
      {
        id: 58,
        expression: "Его брат часто слушает музыку",
        transcription: "hɪz ˈbrʌðər ˈɒf(ə)n ˈlɪsnz tə ˈmjuːzɪk",
        translation: "His brother often listens to music",
      },
      {
        id: 59,
        expression: "Я обычно чищу зубы дважды в день",
        transcription: "aɪ ˈjuːʒʊəli brʌʃ maɪ tiːθ twaɪs ə deɪ",
        translation: "I usually brush my teeth twice a day",
      },
      {
        id: 60,
        expression: "Моя сестра слушает музыку сейчас",
        transcription: "maɪ ˈsɪstə z ˈlɪsnɪŋ tə ˈmjuːzɪk naʊ",
        translation: "My sister is listening to music now",
      },
      {
        id: 61,
        expression: "Мой брат сейчас смотрит видео",
        transcription: "maɪ ˈbrʌðə z ˈwɒʧɪŋ ˈvɪdɪəʊ naʊ",
        translation: "My brother is watching video now",
      },
      {
        id: 62,
        expression: "Моя сестра не слушает музыку сейчас",
        transcription: "maɪ ˈsɪstər ˈɪznt ˈlɪsnɪŋ tə ˈmjuːzɪk naʊ",
        translation: "My sister isn't listening to music now",
      },
      {
        id: 63,
        expression: "Ей действительно нравится играть на скрипке",
        transcription: "ʃi ˈrɪəli laɪks ˈpleɪɪŋ ðə ˌvaɪəˈlɪn",
        translation: "She really likes playing the violin",
      },
      {
        id: 64,
        expression: "Ей не нравится играть на гитаре",
        transcription: "ʃi dʌznt laɪk ˈpleɪɪŋ ðə gɪˈtɑː",
        translation: "She doesn't like playing the guitar",
      },
      {
        id: 65,
        expression: "Мне действительно нравится играть в эту игру",
        transcription: "aɪ ˈrɪəli laɪk ˈpleɪɪŋ ðɪs geɪm",
        translation: "I really like playing this game",
      },
      {
        id: 66,
        expression: "Она обычно ездит домой на автобусе",
        transcription: "ʃi ˈjuːʒʊəli gəʊz həʊm baɪ bʌs",
        translation: "She usually goes home by bus",
      },
      {
        id: 67,
        expression: "Она обычно ездила в школу на автобусе",
        transcription: "ʃi ˈjuːʒʊəli wɛnt tə skuːl baɪ bʌs",
        translation: "She usually went to school by bus",
      },
      {
        id: 68,
        expression: "Она никогда не ездит домой на автобусе",
        transcription: "ʃi ˈnɛvə gəʊz həʊm baɪ bʌs",
        translation: "She never goes home by bus",
      },
      {
        id: 69,
        expression: "Она часто смотрит сериалы по телевизору",
        transcription: "ʃi ˈɒf(ə)n ˈwɒʧɪz ˈsɪəriːz ɒn ˌtiːˈviː",
        translation: "She often watches series on TV",
      },
      {
        id: 70,
        expression: "Как часто она смотрит сериалы по телевизору?",
        transcription: "haʊ ˈɒf(ə)n dəz ðə wɒʧ ˈsɪəriːz ɒn ˌtiːˈviː?",
        translation: "How often does the watch series on TV?",
      },
      {
        id: 71,
        expression: "Я обычно не смотрю сериалы по телевизору",
        transcription: "aɪ ˈjuːʒʊəli dəʊnt wɒʧ ˈsɪəriːz ɒn ˌtiːˈviː",
        translation: "I usually don't watch series on TV",
      },
      {
        id: 72,
        expression: "Здесь часто идет дождь",
        transcription: "ɪt ˈɒf(ə)n reɪnz hɪə",
        translation: "It often rains here",
      },
      {
        id: 73,
        expression: "Сейчас идет дождь",
        transcription: "ɪts ˈreɪnɪŋ naʊ",
        translation: "It's raining now",
      },
      {
        id: 74,
        expression: "Сейчас идет снег?",
        transcription: "ɪz ɪt ˈsnəʊɪŋ naʊ?",
        translation: "Is it snowing now?",
      },
      {
        id: 75,
        expression: "Не беспокой ее! Она молится",
        transcription: "dəʊnt dɪsˈtɜːb hɜː! ʃiːz ˈpreɪɪŋ",
        translation: "Don't disturb her! She's praying",
      },
      {
        id: 76,
        expression: "Не беспокой меня! Я молюсь",
        transcription: "dəʊnt dɪsˈtɜːb miː! aɪm ˈpreɪɪŋ",
        translation: "Don't disturb me! I'm praying",
      },
      {
        id: 77,
        expression: "Послушай! Кто-то стучит в дверь",
        transcription: "ˈlɪsn! ˈsʌmwʌn z ˈnɒkɪŋ",
        translation: "Listen! Someone is knocking",
      },
      {
        id: 78,
        expression: "Смотри! Кто-то находится возле двери",
        transcription: "lʊk! ˈsʌmwʌn z ˈsteɪɪŋ nɪə ðə dɔː",
        translation: "Look! Someone is staying near the door",
      },
      {
        id: 79,
        expression: "Послушай! Кто-то кричит",
        transcription: "ˈlɪsn! ˈsʌmwʌn z ˈʃaʊtɪŋ",
        translation: "Listen! Someone is shouting",
      },
      {
        id: 80,
        expression: "Я болтаю с моим другом в данный момент",
        transcription: "aɪm ˈʧætɪŋ wɪð maɪ frɛnd ət ðə ˈməʊmənt",
        translation: "I'm chatting with my friend at the moment",
      },
      {
        id: 81,
        expression: "Я разговариваю с моим другом в данный момент",
        transcription: "aɪm ˈtɔːkɪŋ tə maɪ frɛnd ət ðə ˈməʊmənt",
        translation: "I'm talking to my friend at the moment",
      },
      {
        id: 82,
        expression: "Я болтаю с моими друзьями сейчас",
        transcription: "aɪm ˈʧætɪŋ wɪð maɪ frɛndz naʊ",
        translation: "I'm chatting with my friends now",
      },
      {
        id: 83,
        expression: "Он навещает своих родителей по воскресеньям",
        transcription: "hi ˈvɪzɪts ɪz ˈpeərənts ɒn ˈsʌndeɪz",
        translation: "He visits his parents on Sundays",
      },
      {
        id: 84,
        expression: "Я обычно навещаю своих родителей осенью",
        transcription: "aɪ ˈjuːʒʊəli ˈvɪzɪt maɪ ˈpeərənts ɪn ˈɔːtəm",
        translation: "I usually visit my parents in autumn",
      },
      {
        id: 85,
        expression: "Он никогда не навещает своих родителей по понедельникам",
        transcription: "hi ˈnɛvə ˈvɪzɪts ɪz ˈpeərənts ɒn ˈmʌndeɪz",
        translation: "He never visits his parents on Mondays",
      },
      {
        id: 86,
        expression: "Чайник кипит сейчас?",
        transcription: "ɪz ðə ˈkɛtl ˈbɔɪlɪŋ naʊ?",
        translation: "Is the kettle boiling now?",
      },
      {
        id: 87,
        expression: "Вода кипит сейчас?",
        transcription: "ɪz ðə ˈwɔːtə ˈbɔɪlɪŋ naʊ?",
        translation: "Is the water boiling now?",
      },
      {
        id: 88,
        expression: "Послушай! Кто-то играет на скрипке",
        transcription: "ˈlɪsn! ˈsʌmwʌn z ˈpleɪɪŋ ðə ˌvaɪəˈlɪn",
        translation: "Listen! Someone is playing the violin",
      },
      {
        id: 89,
        expression: "Он обычно ходит по магазинам на выходных",
        transcription: "hi ˈjuːʒʊəli gəʊz ˈʃɒpɪŋ ət ðə ˈwiːkˈɛndz",
        translation: "He usually goes shopping at the weekends",
      },
      {
        id: 90,
        expression: "Я обычно иду туда через парк",
        transcription: "aɪ ˈjuːʒʊəli gəʊ ðeə θruː ðə pɑːk",
        translation: "I usually go there through the park",
      },
      {
        id: 91,
        expression: "Я сейчас чищу зубы",
        transcription: "aɪm ˈbrʌʃɪŋ maɪ tiːθ naʊ",
        translation: "I'm brushing my teeth now",
      },
      {
        id: 92,
        expression: "Послушай! Кто-то играет на гитаре",
        transcription: "ˈlɪsn! ˈsʌmwʌn z ˈpleɪɪŋ ðə gɪˈtɑː",
        translation: "Listen! Someone is playing the guitar",
      },
      {
        id: 93,
        expression: "Мы идем через парк сейчас",
        transcription: "wɪə ˈgəʊɪŋ θruː ðə pɑːk naʊ",
        translation: "We're going through the park now",
      },
      {
        id: 94,
        expression: "Они ужинают сейчас",
        transcription: "ðeə ˈhævɪŋ ə ˈdɪnə naʊ",
        translation: "They're having a dinner now",
      },
      {
        id: 95,
        expression: "Я еду на работу на метро сегодня",
        transcription: "aɪm ˈgəʊɪŋ tə wɜːk baɪ ˈʌndəgraʊnd təˈdeɪ",
        translation: "I'm going to work by underground today",
      },
      {
        id: 96,
        expression: "Посмотри на собаку! Она лает",
        transcription: "lʊk ət ðə dɒg! ɪts ˈbɑːkɪŋ",
        translation: "Look at the dog! It's barking",
      },
      {
        id: 97,
        expression: "Становится поздно. Я должен идти домой",
        transcription: "ɪts ˈgɛtɪŋ leɪt naʊ. aɪ məst gəʊ həʊm",
        translation: "It's getting late now. I must go home",
      },
      {
        id: 98,
        expression: "Там идет снег",
        transcription: "ɪts ˈsnəʊɪŋ ðeə",
        translation: "It's snowing there",
      },
      {
        id: 99,
        expression: "Здесь идет дождь",
        transcription: "ɪts ˈreɪnɪŋ hɪə",
        translation: "It's raining here",
      },
      {
        id: 100,
        expression: "Мы идем туда через поле",
        transcription: "wɪə ˈgəʊɪŋ ðeər əˈkrɒs ðə fiːld",
        translation: "We're going there across the field",
      },
      {
        id: 101,
        expression: "Он обычно идет туда через поле",
        transcription: "hi ˈjuːʒʊəli gəʊz ðeər əˈkrɒs ðə fiːld",
        translation: "He usually goes there across the field",
      },
      {
        id: 102,
        expression: "Вода сейчас кипит",
        transcription: "ðə ˈwɔːtə z ˈbɔɪlɪŋ naʊ",
        translation: "The water is boiling now",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Отличия Present Simple и Present Continuous</h3>
<p>Время Present Simple используется для обозначения действий, которые происходят регулярно, постоянно, обычно. Напомним формулу</p>
<p><span>подлежащее + глагол(+s) + every day (hour, month, year...)</span></p>
<p>She does physical exercises every day - Она делает физические упражнения каждый день</p>
<p>Время Present Continuous используется для обозначения действий, который происходят сейчас, в данный момент:</p>
<p>What are you going to do? - Что ты собираешься делать сейчас?</p>
<p>She is going to school - Она идет в школу сейчас.</p>
<p>She goes to school - Она ходит в школу.</p>
<p>Is the water boiling? - Вода кипит?</p>
</div>`,
    },
  ],
];
