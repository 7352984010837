export const wordsLesson290 = [
  [
    {
      id: 1,
      word: 'accessible',
      transcription: 'əkˈsɛsəbᵊl',
      translation: 'доступный',
    },
    {
      id: 2,
      word: 'adverse',
      transcription: 'ˈædvɜːs',
      translation: 'неблагоприятный',
    },
    {
      id: 3,
      word: 'alteration',
      transcription: 'ˌɔːltəˈreɪʃᵊn',
      translation: 'изменение',
    },
    {
      id: 4,
      word: 'ambition',
      transcription: 'æmˈbɪʃᵊn',
      translation: 'амбиция',
    },
    {
      id: 5,
      word: 'barbarian',
      transcription: 'bɑːˈbeəriən',
      translation: 'варварский',
    },
    {
      id: 6,
      word: 'conversely',
      transcription: 'ˈkɒnvɜːsli',
      translation: 'наоборот',
    },
    {
      id: 7,
      word: 'e-book',
      transcription: 'ˈiːbʊk',
      translation: 'электронная книга',
    },
    {
      id: 8,
      word: 'entertaining',
      transcription: 'ˌɛntəˈteɪnɪŋ',
      translation: 'развлекательный',
    },
    {
      id: 9,
      word: 'evil',
      transcription: 'ˈiːvl',
      translation: 'зло',
    },
    {
      id: 10,
      word: 'harvest',
      transcription: 'ˈhɑːvɪst',
      translation: 'урожай',
    },
    {
      id: 11,
      word: 'intermediary',
      transcription: 'ˌɪntəˈmiːdiəri',
      translation: 'посредник',
    },
    {
      id: 12,
      word: 'irresponsibly',
      transcription: 'ˌɪrɪsˈpɒnsəbᵊli',
      translation: 'безответственно',
    },
    {
      id: 13,
      word: 'leak',
      transcription: 'liːk',
      translation: 'утечка',
    },
    {
      id: 14,
      word: 'longevity',
      transcription: 'lɒnˈʤɛvəti',
      translation: 'долголетие',
    },
    {
      id: 15,
      word: 'nerve',
      transcription: 'nɜːv',
      translation: 'нерв',
    },
    {
      id: 16,
      word: 'radiation',
      transcription: 'ˌreɪdiˈeɪʃᵊn',
      translation: 'радиация',
    },
    {
      id: 17,
      word: 'regarding',
      transcription: 'rɪˈɡɑːdɪŋ',
      translation: 'касательно',
    },
    {
      id: 18,
      word: 'scornful',
      transcription: 'ˈskɔːnfʊl',
      translation: 'презрительный',
    },
    {
      id: 19,
      word: 'self-evident',
      transcription: 'sɛlf-ˈɛvɪdənt',
      translation: 'самоочевидно',
    },
    {
      id: 20,
      word: 'shatter',
      transcription: 'ˈʃætə',
      translation: 'разбить, разрушить',
    },
    {
      id: 21,
      word: 'spectacular',
      transcription: 'spɛkˈtækjələ',
      translation: 'захватывающий, впечатляющий',
    },
    {
      id: 22,
      word: 'suspiciously',
      transcription: 'səsˈpɪʃəsli',
      translation: 'подозрительно',
    },
    {
      id: 23,
      word: 'tedious',
      transcription: 'ˈtiːdiəs',
      translation: 'утомительный',
    },
    {
      id: 24,
      word: 'toddler',
      transcription: 'ˈtɒdlə',
      translation: 'ребенок, начинающий ходить',
    },
    {
      id: 25,
      word: 'tolerate',
      transcription: 'ˈtɒləreɪt',
      translation: 'терпеть',
    },
    {
      id: 26,
      word: 'tribute',
      transcription: 'ˈtrɪbjuːt',
      translation: 'дань',
    },
    {
      id: 27,
      word: 'youngster',
      transcription: 'ˈjʌŋstə',
      translation: 'молодежь',
    },
    {
      id: 28,
      word: 'will',
      transcription: 'wɪl',
      translation: 'завещание',
    },
  ],
];
