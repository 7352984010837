export const contentLesson77 = [
  [
    {
      title: "Урок 77. Future Simple. Statements",
    },
    [
      {
        id: 1,
        expression: "Он будет готов скоро",
        transcription: "hiːl biː ˈrɛdi suːn",
        translation: "He'll be ready soon",
      },
      {
        id: 2,
        expression: "Это произойдет скоро",
        transcription: "hiːl kʌm tuː ðə ˈpɑːti",
        translation: "It'll happen soon",
      },
      {
        id: 3,
        expression: "Он придет на вечеринку",
        transcription: "hiːl kʌm tuː ðə ˈpɑːti",
        translation: "He'll come to the party",
      },
      {
        id: 4,
        expression: "Они купят мою машину",
        transcription: "ðeɪl baɪ maɪ kɑː",
        translation: "They'll buy my car",
      },
      {
        id: 5,
        expression: "Я всегда буду любить тебя",
        transcription: "aɪl ˈɔːlweɪz lʌv juː",
        translation: "I'll always love you",
      },
      {
        id: 6,
        expression: "Будет очень скучно в конце",
        transcription: "ˈɪtl biː ˈvɛri ˈbɔːrɪŋ ɪn ði ɛnd",
        translation: "It'll be very boring in the end",
      },
      {
        id: 7,
        expression: "Я встречу его в офисе завтра",
        transcription: "aɪl miːt hɪm ɪn ði ˈɒfɪs təˈmɒrəʊ",
        translation: "I'll meet him in the office tomorrow",
      },
      {
        id: 8,
        expression: "Она пойдет за покупками сегодня",
        transcription: "ʃiːl gəʊ ˈʃɒpɪŋ təˈdeɪ",
        translation: "She'll go shopping today",
      },
      {
        id: 9,
        expression: "Будет интересно только в начале",
        transcription: "ˈɪtl biː ˈɪntrɪstɪŋ ˈəʊnli ɪn ðə bɪˈgɪnɪŋ",
        translation: "It'll be interesting only in the beginning",
      },
      {
        id: 10,
        expression: "Скоро придет зима",
        transcription: "ˈwɪntə wɪl kʌm suːn",
        translation: "Winter will come soon",
      },
      {
        id: 11,
        expression: "Я думаю, он купит другую машину",
        transcription: "aɪ θɪŋk hiːl baɪ əˈnʌðə kɑː",
        translation: "I think he'll buy another car",
      },
      {
        id: 12,
        expression: "Она прочтет несколько статей по медицине",
        transcription: "ʃiːl riːd sʌm ˈɑːtɪklz ɒn ˈmɛdsɪn",
        translation: "She'll read some articles on medicine",
      },
      {
        id: 13,
        expression: "Я думаю, это будет очень интересно для тебя",
        transcription: "aɪ θɪŋk ˈɪtl biː ˈvɛri ˈɪntrɪstɪŋ fɔː juː",
        translation: "I think it'll be very interesting for you",
      },
      {
        id: 14,
        expression: "Я позвоню тебе чуть позже",
        transcription: "aɪl kɔːl juː ə bɪt ˈleɪtə",
        translation: "I'll call you a bit later",
      },
      {
        id: 15,
        expression: "Твой отец придет домой через час",
        transcription: "jɔː ˈfɑːðə wɪl kʌm həʊm ɪn ən ˈaʊə",
        translation: "Your father will come home in an hour",
      },
      {
        id: 16,
        expression: "Я куплю другую модель",
        transcription: "aɪl baɪ əˈnʌðə ˈmɒdl",
        translation: "I'll buy another model",
      },
      {
        id: 17,
        expression: "Я понесу твою сумку",
        transcription: "aɪl ˈkæri jɔː bæg",
        translation: "I'll carry your bag",
      },
      {
        id: 18,
        expression: "Я навещу тебя послезавтра",
        transcription: "aɪl ˈvɪzɪt juː ðə deɪ ˈɑːftə təˈmɒrəʊ",
        translation: "I'll visit you the day after tomorrow",
      },
      {
        id: 19,
        expression: "Я увижу своего друга на выходных",
        transcription: "aɪl siː maɪ frɛnd æt ðə ˈwiːkˈɛnd",
        translation: "I'll see my friend at the weekend",
      },
      {
        id: 20,
        expression: "Это случится рано или поздно",
        transcription: "ˈɪtl ˈhæpən ˈsuːnər ɔː ˈleɪtə",
        translation: "It'll happen sooner or later",
      },
      {
        id: 21,
        expression: "Она пойдет с ним в театр",
        transcription: "ʃiːl gəʊ tuː ðə ˈθɪətə wɪð hɪm",
        translation: "She'll go to the theatre with him",
      },
      {
        id: 22,
        expression: "Он скоро будет готов",
        transcription: "hiːl biː ˈrɛdi suːn",
        translation: "He'll be ready soon",
      },
      {
        id: 23,
        expression: "Он пойдет на концерт вместе с ней",
        transcription: "hiːl gəʊ tuː ðə ˈkɒnsə(ː)t wɪð hɜː",
        translation: "He'll go to the concert with her",
      },
      {
        id: 24,
        expression: "Он придет на вечеринку",
        transcription: "hiːl kʌm tuː ðə ˈpɑːti",
        translation: "He'll come to the party",
      },
      {
        id: 25,
        expression: "Я буду слушать запись дважды",
        transcription: "aɪl ˈlɪsn tuː ðə rɪˈkɔːdɪŋ twaɪs",
        translation: "I'll listen to the recording twice",
      },
      {
        id: 26,
        expression: "Они будут играть в футбол на стадионе",
        transcription: "ðeɪl pleɪ ˈfʊtbɔːl æt ðə ˈsteɪdiəm",
        translation: "They'll play football at the stadium",
      },
      {
        id: 27,
        expression: "Я не думаю, я куплю эту машину",
        transcription: "aɪ dəʊnt θɪŋk aɪl baɪ ðɪs kɑː",
        translation: "I don't think I'll buy this car",
      },
      {
        id: 28,
        expression: "Я буду читать статьи на разные темы онлайн",
        transcription: "aɪl riːd ˈɑːtɪklz ɒn ˈdɪfrənt ˈtɒpɪks ˈɒnˌlaɪn",
        translation: "I'll read articles on different topics online",
      },
      {
        id: 29,
        expression: "Я буду изучать новый язык",
        transcription: "aɪl lɜːn ə njuː ˈlæŋgwɪʤ",
        translation: "I'll learn a new language",
      },
      {
        id: 30,
        expression: "Ты увидишь его через минуту",
        transcription: "juːl siː hɪm ɪn ə ˈmɪnɪt",
        translation: "You'll see him in a minute",
      },
      {
        id: 31,
        expression: "Я куплю ей маленький подарок",
        transcription: "aɪl baɪ hɜːr ə smɔːl ˈprɛznt",
        translation: "I'll buy her a small present",
      },
      {
        id: 32,
        expression: "Он пропустит матч",
        transcription: "hiːl mɪs ðə mæʧ",
        translation: "He'll miss the match",
      },
      {
        id: 33,
        expression: "Это произойдет через десять минут",
        transcription: "ˈɪtl ˈhæpən ɪn tɛn ˈmɪnɪts",
        translation: "It'll happen in ten minutes",
      },
      {
        id: 34,
        expression: "Они прибудут вовремя",
        transcription: "ðeɪl əˈraɪv ɒn taɪm",
        translation: "They'll arrive on time",
      },
      {
        id: 35,
        expression: "Я пойду в магазин",
        transcription: "aɪl gəʊ tuː ðə stɔː",
        translation: "I'll go to the store",
      },
      {
        id: 36,
        expression: "Я позвоню ему немного позже",
        transcription: "aɪl kɔːl hɪm ə ˈlɪtl ˈleɪtə",
        translation: "I'll call him a little later",
      },
      {
        id: 37,
        expression: "Я останусь дома на выходных",
        transcription: "aɪl steɪ æt həʊm æt ðə ˈwiːkˈɛnd",
        translation: "I'll stay at home at the weekend",
      },
      {
        id: 38,
        expression: "Он будет играть в футбол на том поле",
        transcription: "hiːl pleɪ ˈfʊtbɔːl ɪn ðæt fiːld",
        translation: "He'll play football in that field",
      },
      {
        id: 39,
        expression: "Я прочитаю несколько статей по бизнесу",
        transcription: "aɪl riːd sʌm ˈɑːtɪklz ɒn ˈbɪznɪs",
        translation: "I'll read some articles on business",
      },
      {
        id: 40,
        expression: "У меня будет отпуск на следующей неделе",
        transcription: "aɪl hæv ə ˈhɒlədeɪ nɛkst wiːk",
        translation: "I'll have a holiday next week",
      },
      {
        id: 41,
        expression: "Лето скоро наступит",
        transcription: "ˈsʌmə wɪl kʌm suːn",
        translation: "Summer will come soon",
      },
      {
        id: 42,
        expression: "Твоя мама вернется домой примерно через полчаса",
        transcription: "jɔː ˈmʌðə wɪl rɪˈtɜːn həʊm ɪn əˈbaʊt ə hɑːf ən ˈaʊə",
        translation: "Your mother will return home in about a half an hour",
      },
      {
        id: 43,
        expression: "Мой друг присоединиться к нам с большим удовольствием",
        transcription: "maɪ frɛnd wɪl ʤɔɪn ʌs wɪð greɪt ˈplɛʒə",
        translation: "My friend will join us with great pleasure",
      },
      {
        id: 44,
        expression: "Я снова послушаю эту песню",
        transcription: "aɪl ˈlɪsn tuː ðɪs sɒŋ əˈgɛn",
        translation: "I'll listen to this song again",
      },
      {
        id: 45,
        expression: "Он ожидает, что купит планшет в конце месяца",
        transcription:
          "hiː ɪksˈpɛkts ðæt hiːl baɪ ə ˈtæblɪt æt ði ɛnd ɒv ðə mʌnθ",
        translation:
          "He expects that he'll buy a tablet at the end of the month",
      },
      {
        id: 46,
        expression: "Может быть, я останусь дома сегодня вечером",
        transcription: "ˈmeɪbiː aɪl steɪ æt həʊm təˈnaɪt",
        translation: "Maybe I'll stay at home tonight",
      },
      {
        id: 47,
        expression: "Я cмогу избавиться от этой ошибки",
        transcription: "aɪl biː ˈeɪbl tuː gɛt rɪd ɒv ðɪs mɪsˈteɪk",
        translation: "I'll be able to get rid of this mistake",
      },
      {
        id: 48,
        expression: "Я смогу это сделать",
        transcription: "aɪl biː ˈeɪbl tuː duː ɪt",
        translation: "I'll be able to do it",
      },
      {
        id: 49,
        expression: "Я прибуду в Лондон в 6 часов вечера",
        transcription: "aɪl əˈraɪv ɪn ˈlʌndən æt sɪks əˈklɒk piː.ɛm.",
        translation: "I'll arrive in London at 6 o'clock p.m.",
      },
      {
        id: 50,
        expression: "Он обещает, что сделает это однажды",
        transcription: "hiː ˈprɒmɪsɪz ðæt hiːl duː ɪt wʌn deɪ",
        translation: "He promises that he'll do it one day",
      },
      {
        id: 51,
        expression: "Может быть, это произойдет однажды",
        transcription: "ˈmeɪbiː ˈɪtl ˈhæpən wʌn deɪ",
        translation: "Maybe it'll happen one day",
      },
      {
        id: 52,
        expression: "Я скоро закончу читать эту книгу",
        transcription: "aɪl ˈfɪnɪʃ ˈriːdɪŋ ðɪs bʊk suːn",
        translation: "I'll finish reading this book soon",
      },
      {
        id: 53,
        expression: "Я думаю, они выиграют игру",
        transcription: "aɪ θɪŋk ðeɪl wɪn ðə geɪm",
        translation: "I think they'll win the game",
      },
      {
        id: 54,
        expression: "Солнце встанет через час",
        transcription: "ðə sʌn wɪl gəʊ ʌp ɪn ən ˈaʊə",
        translation: "The sun will go up in an hour",
      },
      {
        id: 55,
        expression: "Может быть, он скоро сделает новое открытие",
        transcription: "ˈmeɪbiː hiːl meɪk ə njuː dɪsˈkʌvəri suːn",
        translation: "Maybe he'll make a new discovery soon",
      },
      {
        id: 56,
        expression: "Он думает, это будет незабываемая вечеринка",
        transcription: "hiː θɪŋks ˈɪtl biː ən ˌʌnfəˈgɛtəbl ˈpɑːti",
        translation: "He thinks it'll be an unforgettable party",
      },
      {
        id: 57,
        expression: "Солнце скоро сядет",
        transcription: "ðə sʌn wɪl gəʊ daʊn suːn",
        translation: "The sun will go down soon",
      },
      {
        id: 58,
        expression:
          "Я полагаю, что я накоплю немного денег, чтобы купить новый компьютер в этом году",
        transcription:
          "aɪ səˈpəʊz ðæt aɪl seɪv ʌp sʌm ˈmʌni tuː baɪ ə njuː kəmˈpjuːtə ðɪs jɪə",
        translation:
          "I suppose that I'll save up some money to buy a new computer this year",
      },
      {
        id: 59,
        expression: "Может быть, они скоро сделают новые открытия",
        transcription: "ˈmeɪbiː ðeɪl meɪk njuː dɪsˈkʌvəriz suːn",
        translation: "Maybe they'll make new discoveries soon",
      },
      {
        id: 60,
        expression: "Может быть, это будет настоящая революция в образовании",
        transcription:
          "ˈmeɪbiː ˈɪtl biː ə rɪəl ˌrɛvəˈluːʃən ɪn ˌɛdju(ː)ˈkeɪʃən",
        translation: "Maybe it'll be a real revolution in education",
      },
      {
        id: 61,
        expression: "Я думаю, это будет настоящая революция в медицине",
        transcription: "aɪ θɪŋk ˈɪtl biː ə rɪəl ˌrɛvəˈluːʃən ɪn ˈmɛdsɪn",
        translation: "I think it'll be a real revolution in medicine",
      },
      {
        id: 62,
        expression: "Это будет настоящая революция в образовании?",
        transcription: "wɪl ɪt biː ə rɪəl ˌrɛvəˈluːʃən ɪn ˌɛdju(ː)ˈkeɪʃən?",
        translation: "Will it be a real revolution in education?",
      },
      {
        id: 63,
        expression: "Она не думает, что он сможет купить это",
        transcription: "ʃiː dʌznt θɪŋk ðæt hiːl biː ˈeɪbl tuː baɪ aɪ",
        translation: "She doesn't think that he'll be able to buy it",
      },
      {
        id: 64,
        expression: "Он не знает, что она сможет купить ту вещь",
        transcription: "hiː dʌznt nəʊ ðæt ʃiːl biː ˈeɪbl tuː baɪ ðɪs θɪŋ",
        translation: "He doesn't know that she'll be able to buy this thing",
      },
      {
        id: 65,
        expression: "Я не думаю, что смогу купить эту машину",
        transcription: "aɪ dəʊnt θɪŋk ðæt aɪl biː ˈeɪbl tuː baɪ ðɪs kɑː",
        translation: "I don't think that I'll be able to buy this car",
      },
      {
        id: 66,
        expression: "Мы будем отмечать нашу годовщину завтра",
        transcription: "wiːl ˈsɛlɪbreɪt ˈaʊər ˌænɪˈvɜːsəri təˈmɒrəʊ",
        translation: "We'll celebrate our anniversary tomorrow",
      },
      {
        id: 67,
        expression: "Они будут отмечать свой юбилей через неделю",
        transcription: "ðeɪl ˈsɛlɪbreɪt ðeər ˌænɪˈvɜːsəri ɪn ə wiːk",
        translation: "They'll celebrate their anniversary in a week",
      },
      {
        id: 68,
        expression: "Мы не будем отмечать нашу годовщину в этом месяце",
        transcription: "wiː wəʊnt ˈsɛlɪbreɪt ˈaʊər ˌænɪˈvɜːsəri ðɪs mʌnθ",
        translation: "We won't celebrate our anniversary this month",
      },
      {
        id: 69,
        expression: "Я думаю, ты снова опоздаешь",
        transcription: "aɪ θɪŋk juːl biː leɪt əˈgɛn",
        translation: "I think you'll be late again",
      },
      {
        id: 70,
        expression: "Я не думаю, ты опоздаешь сегодня вечером",
        transcription: "aɪ dəʊnt θɪŋk juːl biː leɪt təˈnaɪt",
        translation: "I don't think you'll be late tonight",
      },
      {
        id: 71,
        expression: "Он думает, он опоздает",
        transcription: "hiː θɪŋks hiːl biː leɪt",
        translation: "He thinks he'll be late",
      },
      {
        id: 72,
        expression: "Конечно, я смогу избавиться от этих глупых ошибок",
        transcription:
          "ɒv kɔːs, aɪl biː ˈeɪbl tuː gɛt rɪd ɒv ðiːz ˈsɪli mɪsˈteɪks",
        translation:
          "Of course, I'll be able to get rid of these silly mistakes",
      },
      {
        id: 73,
        expression: "Конечно, он сможет избавиться от этой глупой ошибки",
        transcription:
          "ɒv kɔːs, hiːl biː ˈeɪbl tuː gɛt rɪd ɒv ðɪs ˈsɪli mɪsˈteɪk",
        translation:
          "Of course, he'll be able to get rid of this silly mistake",
      },
      {
        id: 74,
        expression: "Ты думаешь, ты сможешь избавиться от этих глупых ошибок?",
        transcription:
          "duː juː θɪŋk juːl biː ˈeɪbl tuː gɛt rɪd ɒv ðiːz ˈsɪli mɪsˈteɪks?",
        translation:
          "Do you think you'll be able to get rid of these silly mistakes?",
      },
      {
        id: 75,
        expression: "Он думает, что он сможет заработать эти деньги",
        transcription: "hiː θɪŋks hiːl biː ˈeɪbl tuː ɜːn ðɪs ˈmʌni",
        translation: "He thinks he'll be able to earn this money",
      },
      {
        id: 76,
        expression: "Я думаю, что смогу заработать те деньги",
        transcription: "aɪ θɪŋk aɪl biː ˈeɪbl tuː ɜːn ðæt ˈmʌni",
        translation: "I think I'll be able to earn that money",
      },
      {
        id: 77,
        expression: "Она уверена, что сможет заработать эти деньги",
        transcription: "ʃiːz ʃʊə ðæt ʃiːl biː ˈeɪbl tuː ɜːn ðɪs ˈmʌni",
        translation: "She's sure that she'll be able to earn this money",
      },
      {
        id: 78,
        expression: "Я прибуду в аэропорт в 8 утра",
        transcription: "aɪl əˈraɪv æt ði ˈeəpɔːt æt 8 eɪ.ɛm.",
        translation: "I'll arrive at the airport at 8 a.m.",
      },
      {
        id: 79,
        expression: "Он прибудет на станцию в 9 часов утра",
        transcription: "hiːl əˈraɪv æt ðə ˈsteɪʃən æt 9 eɪ.ɛm.",
        translation: "He'll arrive at the station at 9 a.m.",
      },
      {
        id: 80,
        expression: "Я не прибуду в аэропорт вовремя",
        transcription: "aɪ wəʊnt əˈraɪv æt ði ˈeəpɔːt ɒn taɪm",
        translation: "I won't arrive at the airport on time",
      },
      {
        id: 81,
        expression: "Она думает, что вечеринка будет чем-то незабываемым",
        transcription: "ʃiː θɪŋks ðæt ðə ˈpɑːti wɪl biː ˈsʌmθɪŋ ˌʌnfəˈgɛtəbl",
        translation:
          "She thinks that the party will be something unforgettable",
      },
      {
        id: 82,
        expression: "Я думаю, вечеринка будет чем-то незабываемым",
        transcription: "aɪ θɪŋk ðə ˈpɑːti wɪl biː ˈsʌmθɪŋ ˌʌnfəˈgɛtəbl",
        translation: "I think the party will be something unforgettable",
      },
      {
        id: 83,
        expression: "Ты думаешь, вечеринка будет чем-то незабываемым?",
        transcription: "duː juː θɪŋk ðə ˈpɑːti wɪl biː ˈsʌmθɪŋ ˌʌnfəˈgɛtəbl?",
        translation: "Do you think the party will be something unforgettable?",
      },
      {
        id: 84,
        expression: "Я буду учиться очень усердно, чтобы сдать этот экзамен",
        transcription: "aɪl ˈstʌdi ˈvɛri hɑːd tuː pɑːs ðɪs ɪgˈzæm",
        translation: "I'll study very hard to pass this exam",
      },
      {
        id: 85,
        expression: "Я буду учиться усердно, чтобы сдать этот тест",
        transcription: "aɪl ˈstʌdi hɑːd tuː pɑːs ðɪs tɛst",
        translation: "I'll study hard to pass this test",
      },
      {
        id: 86,
        expression: "Она будет учиться очень усердно, чтобы сдать свой тест",
        transcription: "ʃiːl ˈstʌdi ˈvɛri hɑːd tuː pɑːs hɜː tɛst",
        translation: "She'll study very hard to pass her test",
      },
      {
        id: 87,
        expression: "Я знаю, что я смогу достичь своей цели",
        transcription: "aɪ nəʊ ðæt aɪl biː ˈeɪbl tuː əˈʧiːv maɪ eɪm",
        translation: "I know that I'll be able to achieve my aim",
      },
      {
        id: 88,
        expression: "Он знает, что он сможет добраться на работу вовремя",
        transcription: "hiː nəʊ ðæt hiːl biː ˈeɪbl tuː gɛt tuː wɜːk ɒn taɪm",
        translation: "He know that he'll be able to get to work on time",
      },
      {
        id: 89,
        expression: "Я знаю, что я смогу приехать домой рано",
        transcription: "aɪ nəʊ ðæt aɪl biː ˈeɪbl tuː kʌm həʊm ˈɜːli",
        translation: "I know that I'll be able to come home early",
      },
      {
        id: 90,
        expression: "Он скоро закончит делать свою домашнюю работу",
        transcription: "hiːl ˈfɪnɪʃ ˈdu(ː)ɪŋ hɪz ˈhəʊmˌwɜːk suːn",
        translation: "He'll finish doing his homework soon",
      },
      {
        id: 91,
        expression: "Я думаю, я смогу сделать это",
        transcription: "aɪ θɪŋk aɪl biː ˈeɪbl tuː duː ɪt",
        translation: "I think I'll be able to do it",
      },
      {
        id: 92,
        expression: "Я знаю, что я смогу выучить английский",
        transcription: "aɪ nəʊ ðæt aɪl biː ˈeɪbl tuː lɜːn ˈɪŋglɪʃ",
        translation: "I know that I'll be able to learn English",
      },
      {
        id: 93,
        expression: "Я заплачу за билеты наличными",
        transcription: "aɪl peɪ fɔː ðə ˈtɪkɪts ɪn kæʃ",
        translation: "I'll pay for the tickets in cash",
      },
      {
        id: 94,
        expression: "Я постараюсь избавиться от этих ошибок",
        transcription: "aɪl traɪ tuː gɛt rɪd ɒv ðiːz mɪsˈteɪks",
        translation: "I'll try to get rid of these mistakes",
      },
      {
        id: 95,
        expression: "Они будут отмечать их годовщину на следующей неделе",
        transcription: "ðeɪl ˈsɛlɪbreɪt ðeər ˌænɪˈvɜːsəri nɛkst wiːk",
        translation: "They'll celebrate their anniversary next week",
      },
      {
        id: 96,
        expression: "Я постараюсь решить эту проблему как можно скорее",
        transcription: "aɪl traɪ tuː sɒlv ðɪs ˈprɒbləm æz suːn æz ˈpɒsəbl",
        translation: "I'll try to solve this problem as soon as possible",
      },
      {
        id: 97,
        expression: "Я думаю, я куплю ноутбук в конце недели",
        transcription: "aɪ θɪŋk aɪl baɪ ə ˈlæpˌtɒp æt ði ɛnd ɒv ðə wiːk",
        translation: "I think I'll buy a laptop at the end of the week",
      },
      {
        id: 98,
        expression: "Я полагаю, я накоплю немного денег, чтобы купить это",
        transcription: "aɪ səˈpəʊz aɪl seɪv ʌp sʌm ˈmʌni tuː baɪ ɪt",
        translation: "I suppose I'll save up some money to buy it",
      },
      {
        id: 99,
        expression: "Я буду действительно счастлив помочь тебе",
        transcription: "aɪl biː ˈrɪəli ˈhæpi tuː hɛlp juː",
        translation: "I'll be really happy to help you",
      },
      {
        id: 100,
        expression: "Он оплатит билеты кредитной картой",
        transcription: "hiːl peɪ fɔː ðə ˈtɪkɪts baɪ ˈkrɛdɪt kɑːd",
        translation: "He'll pay for the tickets by credit card",
      },
      {
        id: 101,
        expression:
          "Я буду стараться работать изо всех сил, чтобы заработать эти деньги",
        transcription: "aɪl traɪ tuː wɜːk ˈvɛri hɑːd tuː ɜːn ðɪs ˈmʌni",
        translation: "I'll try to work very hard to earn this money",
      },
      {
        id: 102,
        expression: "Это будет очень скучная встреча, как обычно",
        transcription: "ˈɪtl biː ə ˈvɛri ˈbɔːrɪŋ ˈmiːtɪŋ æz ˈjuːʒʊəl",
        translation: "It'll be a very boring meeting as usual",
      },
      {
        id: 103,
        expression: "Солнце скоро взойдет",
        transcription: "ðə sʌn wɪl gəʊ ʌp (raɪz) suːn",
        translation: "The sun will go up (rise) soon",
      },
      {
        id: 104,
        expression: "Это будет очень скучная лекция, как всегда",
        transcription: "ˈɪtl biː ə ˈvɛri ˈbɔːrɪŋ ˈlɛkʧər æz ˈɔːlweɪz",
        translation: "It'll be a very boring lecture as always",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Закрепление пройденного материала</h3>
</div>`,
    },
  ],
];
