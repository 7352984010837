export const contentLesson3 = [
  [
    { title: 'Урок 3. Построение предложений и артикли' },
    [
      {
        id: 1,
        expression: 'Я вижу девочку',
        transcription: 'aɪ siː ə gɜːl',
        translation: 'I see a girl',
      },
      {
        id: 2,
        expression: 'Я хочу яблоко',
        transcription: 'aɪ wɒnt ən ˈæpl',
        translation: 'I want an apple',
      },
      {
        id: 3,
        expression: 'Я вижу одного человека',
        transcription: 'aɪ siː wʌn ˈpɜːsn',
        translation: 'I see one person',
      },
      {
        id: 4,
        expression: 'У них есть деньги / У них есть доллар',
        transcription: 'ðeɪ həv ˈmʌni / ðeɪ həv ə ˈdɒlə',
        translation: 'They have money / They have a dollar',
      },
      {
        id: 5,
        expression: 'Мне нравится эта машина',
        transcription: 'aɪ laɪk ðɪs kɑː',
        translation: 'I like this car',
      },
      {
        id: 6,
        expression: 'У меня есть несколько вопросов',
        transcription: 'aɪ həv səm ˈkwɛsʧənz',
        translation: 'I have some questions',
      },
      {
        id: 7,
        expression: 'Ему нравятся эти машины',
        transcription: 'hi laɪks ðiːz kɑːz',
        translation: 'He likes these cars',
      },
      {
        id: 8,
        expression: 'У нее есть немного времени',
        transcription: 'ʃi həz səm taɪm',
        translation: 'She has some time',
      },
      {
        id: 9,
        expression: 'Я вижу автобус',
        transcription: 'aɪ siː ə bʌs',
        translation: 'I see a bus',
      },
      {
        id: 10,
        expression: 'У меня есть книга',
        transcription: 'aɪ həv ə bʊk',
        translation: 'I have a book',
      },
      {
        id: 11,
        expression: 'У меня есть идея',
        transcription: 'aɪ həv ən aɪˈdɪə',
        translation: 'I have an idea',
      },
      {
        id: 12,
        expression: 'Я вижу яблоко',
        transcription: 'aɪ siː ən ˈæpl',
        translation: 'I see an apple',
      },
      {
        id: 13,
        expression: 'У меня есть брат',
        transcription: 'aɪ həv ə ˈbrʌðə',
        translation: 'I have a brother',
      },
      {
        id: 14,
        expression: 'Я вижу учителя',
        transcription: 'aɪ siː ə ˈtiːʧə',
        translation: 'I see a teacher',
      },
      {
        id: 15,
        expression: 'Я вижу отель',
        transcription: 'aɪ siː ə həʊˈtɛl',
        translation: 'I see a hotel',
      },
      {
        id: 16,
        expression: 'Я знаю этого мужчину',
        transcription: 'aɪ nəʊ ðɪs mæn',
        translation: 'I know this man',
      },
      {
        id: 17,
        expression: 'Я вижу девочку',
        transcription: 'aɪ siː ə gɜːl',
        translation: 'I see a girl',
      },
      {
        id: 18,
        expression: 'У меня есть некоторые результаты',
        transcription: 'aɪ həv səm rɪˈzʌlts',
        translation: 'I have some results',
      },
      {
        id: 19,
        expression: 'Я хочу яблоко',
        transcription: 'aɪ wɒnt ən ˈæpl',
        translation: 'I want an apple',
      },
      {
        id: 20,
        expression: 'У меня есть апельсин',
        transcription: 'aɪ həv ən ˈɒrɪnʤ',
        translation: 'I have an orange',
      },
      {
        id: 21,
        expression: 'Я понимаю твой вопрос',
        transcription: 'aɪ ˌʌndəˈstænd jə ˈkwɛsʧən',
        translation: 'I understand your question',
      },
      {
        id: 22,
        expression: 'У меня есть проблема',
        transcription: 'aɪ həv ə ˈprɒbləm',
        translation: 'I have a problem',
      },
      {
        id: 23,
        expression: 'Я вижу женщину',
        transcription: 'aɪ siː ə ˈwʊmən',
        translation: 'I see a woman',
      },
      {
        id: 24,
        expression: 'У меня есть время',
        transcription: 'aɪ həv taɪm',
        translation: 'I have time',
      },
      {
        id: 25,
        expression: 'Я вижу университет',
        transcription: 'aɪ siː ə ˌjuːnɪˈvɜːsɪti',
        translation: 'I see a university',
      },
      {
        id: 26,
        expression: 'У меня есть некоторые вопросы',
        transcription: 'aɪ həv səm ˈkwɛsʧənz',
        translation: 'I have some questions',
      },
      {
        id: 27,
        expression: 'Я вижу автобус',
        transcription: 'aɪ siː ə bʌs',
        translation: 'I see a bus',
      },
      {
        id: 28,
        expression: 'Мне нравится эта машина',
        transcription: 'aɪ laɪk ðɪs kɑː',
        translation: 'I like this car',
      },
      {
        id: 29,
        expression: 'У меня есть одна проблема',
        transcription: 'aɪ həv wʌn ˈprɒbləm',
        translation: 'I have one problem',
      },
      {
        id: 30,
        expression: 'Мы видим отель',
        transcription: 'wi siː ə həʊˈtɛl',
        translation: 'We see a hotel',
      },
      {
        id: 31,
        expression: 'Мне нравится этот вопрос',
        transcription: 'aɪ laɪk ðɪs ˈkwɛsʧən',
        translation: 'I like this question',
      },
      {
        id: 32,
        expression: 'Я знаю этот университет',
        transcription: 'aɪ nəʊ ðɪs ˌjuːnɪˈvɜːsɪti',
        translation: 'I know this university',
      },
      {
        id: 33,
        expression: 'У них есть деньги',
        transcription: 'ðeɪ həv ˈmʌni',
        translation: 'They have money',
      },
      {
        id: 34,
        expression: 'Я вижу одного человека',
        transcription: 'aɪ siː wʌn ˈpɜːsn',
        translation: 'I see one person',
      },
      {
        id: 35,
        expression: 'Мы видим автобус',
        transcription: 'wi siː ə bʌs',
        translation: 'We see a bus',
      },
      {
        id: 36,
        expression: 'У них есть проблема',
        transcription: 'ðeɪ həv ə ˈprɒbləm',
        translation: 'They have a problem',
      },
      {
        id: 37,
        expression: 'Мне нравится этот университет',
        transcription: 'aɪ laɪk ðɪs ˌjuːnɪˈvɜːsɪti',
        translation: 'I like this university',
      },
      {
        id: 38,
        expression: 'Я хочу апельсин',
        transcription: 'aɪ wɒnt ən ˈɒrɪnʤ',
        translation: 'I want an orange',
      },
      {
        id: 39,
        expression: 'У меня есть работа',
        transcription: 'aɪ həv ə ʤɒb',
        translation: 'I have a job',
      },
      {
        id: 40,
        expression: 'Я вижу мужчину',
        transcription: 'aɪ siː ə mæn',
        translation: 'I see a man',
      },
      {
        id: 41,
        expression: 'У меня есть вопрос',
        transcription: 'aɪ həv ə ˈkwɛsʧən',
        translation: 'I have a question',
      },
      {
        id: 42,
        expression: 'Я вижу школу',
        transcription: 'aɪ siː ə skuːl',
        translation: 'I see a school',
      },
      {
        id: 43,
        expression: 'Мы видим учителя',
        transcription: 'wi siː ə ˈtiːʧə',
        translation: 'We see a teacher',
      },
      {
        id: 44,
        expression: 'У меня есть сестра',
        transcription: 'aɪ həv ə ˈsɪstə',
        translation: 'I have a sister',
      },
      {
        id: 45,
        expression: 'Я вижу двоих людей',
        transcription: 'aɪ siː tuː ˈpiːpl',
        translation: 'I see two people',
      },
      {
        id: 46,
        expression: 'У меня мало свободного времени',
        transcription: 'aɪ həv ˈlɪtl friː taɪm',
        translation: 'I have little free time',
      },
      {
        id: 47,
        expression: 'У меня есть яблоко',
        transcription: 'aɪ həv ən ˈæpl',
        translation: 'I have an apple',
      },
      {
        id: 48,
        expression: 'Я вижу дом',
        transcription: 'aɪ siː ə haʊs',
        translation: 'I see a house',
      },
      {
        id: 49,
        expression: 'У меня есть машина',
        transcription: 'aɪ həv ə kɑː',
        translation: 'I have a car',
      },
      {
        id: 50,
        expression: 'Я знаю этого человека',
        transcription: 'aɪ nəʊ ðɪs ˈpɜːsn',
        translation: 'I know this person',
      },
      {
        id: 51,
        expression: 'Я вижу мальчика',
        transcription: 'aɪ siː ə bɔɪ',
        translation: 'I see a boy',
      },
      {
        id: 52,
        expression: 'У меня есть некоторые идеи',
        transcription: 'aɪ həv səm aɪˈdɪəz',
        translation: 'I have some ideas',
      },
      {
        id: 53,
        expression: 'Я вижу двух студентов',
        transcription: 'aɪ siː tuː ˈstjuːdənts',
        translation: 'I see two students',
      },
      {
        id: 54,
        expression: 'У меня есть семья',
        transcription: 'aɪ həv ə ˈfæmɪli',
        translation: 'I have a family',
      },
      {
        id: 55,
        expression: 'Мы видим банк',
        transcription: 'wi siː ə bæŋk',
        translation: 'We see a bank',
      },
      {
        id: 56,
        expression: 'Я вижу студента',
        transcription: 'aɪ siː ə ˈstjuːdənt',
        translation: 'I see a student',
      },
      {
        id: 57,
        expression: 'Они часто пьют воду',
        transcription: 'ðeɪ ˈɒf(ə)n drɪŋk ˈwɔːtə',
        translation: 'They often drink water',
      },
      {
        id: 58,
        expression: 'У них есть компьютер',
        transcription: 'ðeɪ həv ə kəmˈpjuːtə',
        translation: 'They have a computer',
      },
      {
        id: 59,
        expression: 'Я понимаю эти вещи',
        transcription: 'aɪ ˌʌndəˈstænd ðiːz θɪŋz',
        translation: 'I understand these things',
      },
      {
        id: 60,
        expression: 'Мне нравится эта книга',
        transcription: 'aɪ laɪk ðɪs bʊk',
        translation: 'I like this book',
      },
      {
        id: 61,
        expression: 'У меня есть компьютер',
        transcription: 'aɪ həv ə kəmˈpjuːtə',
        translation: 'I have a computer',
      },
      {
        id: 62,
        expression: 'Я очень люблю музыку',
        transcription: 'aɪ ˈrɪəli lʌv ˈmjuːzɪk',
        translation: 'I really love music',
      },
      {
        id: 63,
        expression: 'Я ем рыбу',
        transcription: 'aɪ iːt fɪʃ',
        translation: 'I eat fish',
      },
      {
        id: 64,
        expression: 'Я делаю разные упражнения',
        transcription: 'aɪ dʊ ˈdɪfrənt ˈɛksəsaɪzɪz',
        translation: 'I do different exercises',
      },
      {
        id: 65,
        expression: 'Мы видим такси',
        transcription: 'wi siː ə ˈtæksi',
        translation: 'We see a taxi',
      },
      {
        id: 66,
        expression: 'Я пью чай',
        transcription: 'aɪ drɪŋk tiː',
        translation: 'I drink tea',
      },
      {
        id: 67,
        expression: 'Мне нравятся эти упражнения',
        transcription: 'aɪ laɪk ðiːz ˈɛksəsaɪzɪz',
        translation: 'I like these exercises',
      },
      {
        id: 68,
        expression: 'У меня есть одна книга',
        transcription: 'aɪ həv wʌn bʊk',
        translation: 'I have one book',
      },
      {
        id: 69,
        expression: 'У нее есть одна книга',
        transcription: 'ʃi həz wʌn bʊk',
        translation: 'She has one book',
      },
      {
        id: 70,
        expression: 'У меня есть один компьютер',
        transcription: 'aɪ həv wʌn kəmˈpjuːtə',
        translation: 'I have one computer',
      },
      {
        id: 71,
        expression: 'Я люблю спорт',
        transcription: 'aɪ laɪk spɔːt',
        translation: 'I like sport',
      },
      {
        id: 72,
        expression: 'Он любит чай',
        transcription: 'hi lʌvz tiː',
        translation: 'He loves tea',
      },
      {
        id: 73,
        expression: 'Мне нравится спорт',
        transcription: 'aɪ laɪk spɔːt',
        translation: 'I like sport',
      },
      {
        id: 74,
        expression: 'Я вижу банк',
        transcription: 'aɪ siː ə bæŋk',
        translation: 'I see a bank',
      },
      {
        id: 75,
        expression: 'Она видит банк',
        transcription: 'ʃi siːz ə bæŋk',
        translation: 'She sees a bank',
      },
      {
        id: 76,
        expression: 'Я вижу этот банк',
        transcription: 'aɪ siː ðɪs bæŋk',
        translation: 'I see this bank',
      },
      {
        id: 77,
        expression: 'Я понимаю эти вещи',
        transcription: 'aɪ ˌʌndəˈstænd ðiːz θɪŋz',
        translation: 'I understand these things',
      },
      {
        id: 78,
        expression: 'Я понимаю эту вещь',
        transcription: 'aɪ ˌʌndəˈstænd ðɪs θɪŋ',
        translation: 'I understand this thing',
      },
      {
        id: 79,
        expression: 'У нас есть две книги',
        transcription: 'wi həv tuː bʊks',
        translation: 'We have two books',
      },
      {
        id: 80,
        expression: 'У них есть мотивация',
        transcription: 'ðeɪ həv ˌməʊtɪˈveɪʃən',
        translation: 'They have motivation',
      },
      {
        id: 81,
        expression: 'У нас есть мотивация',
        transcription: 'wi həv ˌməʊtɪˈveɪʃən',
        translation: 'We have motivation',
      },
      {
        id: 82,
        expression: 'У нее есть мотивация',
        transcription: 'ʃi həz ˌməʊtɪˈveɪʃən',
        translation: 'She has motivation',
      },
      {
        id: 83,
        expression: 'У меня есть мотивация',
        transcription: 'aɪ həv ˌməʊtɪˈveɪʃən',
        translation: 'I have motivation',
      },
      {
        id: 84,
        expression: 'У него есть мотивация',
        transcription: 'hi həz ˌməʊtɪˈveɪʃən',
        translation: 'He has motivation',
      },
      {
        id: 85,
        expression: 'У тебя есть мотивация',
        transcription: 'jʊ həv ˌməʊtɪˈveɪʃən',
        translation: 'You have motivation',
      },
      {
        id: 86,
        expression: 'У нас есть результат',
        transcription: 'wi həv ə rɪˈzʌlt',
        translation: 'We have a result',
      },
      {
        id: 87,
        expression: 'Мне нравится мой результат',
        transcription: 'aɪ laɪk maɪ rɪˈzʌlt',
        translation: 'I like my result',
      },
      {
        id: 88,
        expression: 'Мне нравится этот результат',
        transcription: 'aɪ laɪk ðɪs rɪˈzʌlt',
        translation: 'I like this result',
      },
      {
        id: 89,
        expression: 'Я вижу свой прогресс',
        transcription: 'aɪ siː maɪ ˈprəʊgrəs',
        translation: 'I see my progress',
      },
      {
        id: 90,
        expression: 'Я вижу твой прогресс',
        transcription: 'aɪ siː maɪ ˈprəʊgrəs',
        translation: 'I see your progress',
      },
      {
        id: 91,
        expression: 'Он видит мой прогресс',
        transcription: 'hi siːz maɪ ˈprəʊgrəs',
        translation: 'He sees my progress',
      },
      {
        id: 92,
        expression: 'Мне нравятся мои результаты',
        transcription: 'aɪ laɪk maɪ rɪˈzʌlts',
        translation: 'I like my results',
      },
      {
        id: 93,
        expression: 'Ей нравятся ее результаты',
        transcription: 'ʃi laɪks hə rɪˈzʌlts',
        translation: 'She likes her results',
      },
      {
        id: 94,
        expression: 'Им нравятся мои результаты',
        transcription: 'ðeɪ laɪk maɪ rɪˈzʌlts',
        translation: 'They like my results',
      },
      {
        id: 95,
        expression: 'Они часто пьют молоко',
        transcription: 'ðeɪ ˈɒf(ə)n drɪŋk mɪlk',
        translation: 'They often drink milk',
      },
      {
        id: 96,
        expression: 'Я вижу такси',
        transcription: 'aɪ siː ə ˈtæksi',
        translation: 'I see a taxi',
      },
      {
        id: 97,
        expression: 'Я люблю музыку',
        transcription: 'aɪ lʌv ˈmjuːzɪk',
        translation: 'I love music',
      },
      {
        id: 98,
        expression: 'Они пьют молоко',
        transcription: 'ðeɪ drɪŋk mɪlk',
        translation: 'They drink milk',
      },
      {
        id: 99,
        expression: 'Я часто ем рыбу',
        transcription: 'aɪ ˈɒf(ə)n iːt fɪʃ',
        translation: 'I often eat fish',
      },
      {
        id: 100,
        expression: 'Я хочу сок',
        transcription: 'aɪ wɒnt ʤuːs',
        translation: 'I want juice',
      },
      {
        id: 101,
        expression: 'У меня есть результат',
        transcription: 'aɪ həv ə rɪˈzʌlt',
        translation: 'I have a result',
      },
      {
        id: 102,
        expression: 'Мне нравится сок',
        transcription: 'aɪ laɪk ʤuːs',
        translation: 'I like juice',
      },
      {
        id: 103,
        expression: 'У них есть свободное время',
        transcription: 'ðeɪ həv friː taɪm',
        translation: 'They have free time',
      },
      {
        id: 104,
        expression: 'Я вижу книги',
        transcription: 'aɪ siː bʊks',
        translation: 'I see books',
      },
      {
        id: 105,
        expression: 'Я действительно люблю спорт',
        transcription: 'aɪ ˈrɪəli lʌv spɔːt',
        translation: 'I really love sport',
      },
      {
        id: 106,
        expression: 'Они пьют кофе',
        transcription: 'ðeɪ drɪŋk ˈkɒfi',
        translation: 'They drink coffee',
      },
      {
        id: 107,
        expression: 'Я хочу больше упражнений',
        transcription: 'aɪ wɒnt mɔːr ˈɛksəsaɪzɪz',
        translation: 'I want more exercises',
      },
      {
        id: 108,
        expression: 'Мне нравится музыка',
        transcription: 'aɪ laɪk ˈmjuːzɪk',
        translation: 'I like music',
      },
      {
        id: 109,
        expression: 'Я вижу того студента',
        transcription: 'aɪ siː ðət ˈstjuːdənt',
        translation: 'I see that student',
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Построение предложений</h3>
<p>В английском языке построение предложений подчиняется четким правилам. Изучающих английский язык иногда это настолько сбивает с толку, что можно "угадать" все слова в предложении, но не понять его смысл. Именно этот момент у начинающих зачастую вызывает ступор, когда они пытаются перевести русскоязычные конструкции на английский. Все потому, что в английском языке в любом предложении всегда есть подлежащее и сказуемое. В любом, даже в том, где, казалось бы, в этом нет никакой необходимости.</p>
<p>Например, некоторые русскоязычные предложения могут состоять всего из одного слова: "Холодало". Но перевести это простейшее предложение на английский язык с использованием всего одного английского слова не получится. Языковые паттерны у англичан всегда такие, что "холодать" должно "что-то". То есть, в любом англоязычном предложении есть объект (подлежащее) и его интенция к действию (сказуемое). По правде говоря (to tell the truth), объект и его интенция к действию есть в любом предложении, таковы свойства нашего мышления. Однако в английском языке подлежащее и сказуемое всегда эксплицитны (проговариваются), а в русском они могут быть имплицитными (подразумеваться).</p>
<p>Таким образом, "холодало" на английский переводится как It was getting colder, что дословно еще может быть переведено как "Становилось холоднее", а совсем буквально как "Это было получать холод".</p>
<p>Или такой пример: предложение "Холодно" переводится на английский как "It is cold", что при буквальном переводе может означать "Это есть холодный".</p>
<h3>Артикли</h3>
<p>"a / an" - Это неопределенный артикль (indefinite article), то есть часть речи, которая выделяет в предложении существительное. Помимо неопределенного артикля а есть еще артикль определенный - "the". В русском языке такой части речи, как артикль, не существует. Нам это просто не надо, поскольку у нас нет слов, которые могут быть и существительными, и глаголами. А в английском языке это очень распространено. Так, слово try может означать и глагол ("пытаться" - to try), и существительное (a try - "попытка").</p>
<p>Неопределенный артикль не употребляется с неисчисляемыми существительными: They have money - У них есть деньги.</p>
<p>Определенный артикль the употребляется как с неисчисляемыми, так и с исчисляемыми существительными, и число для него не имеет значения. В английском языке определенный артикль the представляет собой редуцированное слово this. Поэтому определенный артикль помогает показать, насколько та или иная вещь знакома собеседнику. Например, когда мы говорим "I read the text", то имеем в виду конкретный текст, "этот текст". Когда же мы говорим "I read a text", то не имеем в виду какой-то конкретный текст и, получается, что в предложении делаем акцент на сам процесс чтения (read). Если перед существительным ставится прилагательное, то артикль ставится перед прилагательным: I read a difficult text ("Я читаю сложный текст"). А если перед прилагательным ставится наречие, то артикль ставится перед наречием: I read a very difficult text ("Я читаю очень сложный тест").</p>
</div>`,
    },
  ],
];
