export const contentLesson166 = [
  [
    {
      title: 'Урок 166. Active and Passive Voice',
    },
    [
      {
        id: 1,
        expression: 'Она обычно объясняет это очень ясно',
        transcription: 'ʃi ˈjuːʒʊəli ɪksˈpleɪnz ɪt ˈvɛri ˈklɪəli',
        translation: 'She usually explains it very clearly',
      },
      {
        id: 2,
        expression: 'Я окончил университет в прошлом году',
        transcription: 'aɪ ˈgrædjʊeɪtɪd frəm ˌjuːnɪˈvɜːsɪti lɑːst jɪə',
        translation: 'I graduated from university last year',
      },
      {
        id: 3,
        expression: 'Этот стол сделан из дерева',
        transcription: 'ðɪs ˈteɪbl z meɪd əv wʊd',
        translation: 'This table is made of wood',
      },
      {
        id: 4,
        expression: 'Эта фотография была сделана мною',
        transcription: 'ðɪs ˈfəʊtəʊ wəz ˈteɪkən baɪ miː',
        translation: 'This photo was taken by me',
      },
      {
        id: 5,
        expression: 'Их офис расположен в центре',
        transcription: 'ðeər ˈɒfɪs ɪz ləʊˈkeɪtɪd ɪn ðə ˈsɛntə',
        translation: 'Their office is located in the centre',
      },
      {
        id: 6,
        expression: 'Они удалят эти файлы',
        transcription: 'ðeɪl dɪˈliːt ðiːz faɪlz',
        translation: "They'll delete these files",
      },
      {
        id: 7,
        expression: 'Я только что купил новую пару кроссовок',
        transcription: 'aɪv ʤəst bɔːt ə njuː peər əv ˈtreɪnəz',
        translation: "I've just bought a new pair of trainers",
      },
      {
        id: 8,
        expression: 'Они продали эту мебель',
        transcription: 'ðeɪ səʊld ðɪs ˈfɜːnɪʧə',
        translation: 'They sold this furniture',
      },
      {
        id: 9,
        expression: 'Конференция состоится летом',
        transcription: 'ðə ˈkɒnfərəns wɪl teɪk pleɪs ɪn ˈsʌmə',
        translation: 'The conference will take place in summer',
      },
      {
        id: 10,
        expression: 'Он купил килограмм груш',
        transcription: 'hi bɔːt ə ˈkiːləʊ əv peəz',
        translation: 'He bought a kilo of pears',
      },
      {
        id: 11,
        expression: 'Зеркало было разбито вчера',
        transcription: 'ðə ˈmɪrə wəz ˈbrəʊkən ˈjɛstədeɪ',
        translation: 'The mirror was broken yesterday',
      },
      {
        id: 12,
        expression: 'Этот курс будет закончен к следующему году',
        transcription: 'ðɪs kɔːs wɪl həv biːn ˈfɪnɪʃt baɪ nɛkst jɪə',
        translation: 'This course will have been finished by next year',
      },
      {
        id: 13,
        expression: 'Когда он пришел домой, еда готовилась',
        transcription: 'wɛn hi keɪm həʊm, ðə fuːd wəz ˈbiːɪŋ kʊkt',
        translation: 'When he came home, the food was being cooked',
      },
      {
        id: 14,
        expression: 'Их рейс был задержан',
        transcription: 'ðeə flaɪt wəz dɪˈleɪd',
        translation: 'Their flight was delayed',
      },
      {
        id: 15,
        expression: 'Когда он вернулся, она что-то готовила',
        transcription: 'wɛn hi keɪm bæk, ʃi wəz ˈkʊkɪŋ ˈsʌmθɪŋ',
        translation: 'When he came back, she was cooking something',
      },
      {
        id: 16,
        expression: 'Он купил новую пару кроссовок',
        transcription: 'hi bɔːt ə njuː peər əv ˈtreɪnəz',
        translation: 'He bought a new pair of trainers',
      },
      {
        id: 17,
        expression: 'Килограмм груш был куплен им',
        transcription: 'ə ˈkiːləʊ əv peəz wəz bɔːt baɪ hɪm',
        translation: 'A kilo of pears was bought by him',
      },
      {
        id: 18,
        expression: 'Это часто объясняется очень непонятно',
        transcription: 'ɪt s ˈɒf(ə)n ɪksˈpleɪnd ˈvɛri ʌnˈklɪəli',
        translation: 'It is often explained very unclearly',
      },
      {
        id: 19,
        expression: 'Он сказал, что его уже поздравили',
        transcription: 'hi sɛd ðət hiːd ɔːlˈrɛdi biːn kənˈgrætjʊleɪtɪd',
        translation: "He said that he'd already been congratulated",
      },
      {
        id: 20,
        expression: 'Те товары экспортируются',
        transcription: 'ðəʊz gʊdz ər ɛksˈpɔːtɪd',
        translation: 'Those goods are exported',
      },
      {
        id: 21,
        expression: 'Он только что это сказал',
        transcription: 'hiːz ʤəst sɛd ɪt',
        translation: "He's just said it",
      },
      {
        id: 22,
        expression: 'Их мебель еще не продана',
        transcription: 'ðeə ˈfɜːnɪʧə ˈhæznt biːn səʊld jɛt',
        translation: "Their furniture hasn't been sold yet",
      },
      {
        id: 23,
        expression: 'Он сказал, что уже поздравил ее',
        transcription: 'hi sɛd ðət hiːd ɔːlˈrɛdi kənˈgrætjʊleɪtɪd hɜː',
        translation: "He said that he'd already congratulated her",
      },
      {
        id: 24,
        expression: 'Новая пара кроссовок была куплена им',
        transcription: 'ə njuː peər əv ˈtreɪnəz wəz bɔːt baɪ hɪm',
        translation: 'A new pair of trainers was bought by him',
      },
      {
        id: 25,
        expression: 'Они отправили тех грабителей в тюрьму',
        transcription: 'ðeɪ sɛnt ðiːz ˈbɜːgləz (ˈrɒbəz) tə ʤeɪl (ˈprɪzn)',
        translation: 'They sent these burglars (robbers) to jail (prison)',
      },
      {
        id: 26,
        expression: 'Это сейчас показывают по телевизору',
        transcription: 'ɪt s ˈbiːɪŋ ʃəʊn ɒn ˌtiːˈviː',
        translation: 'It is being shown on TV',
      },
      {
        id: 27,
        expression: 'Это основано на реальных фактах',
        transcription: 'ɪts beɪst ɒn rɪəl fækts',
        translation: "It's based on real facts",
      },
      {
        id: 28,
        expression: 'Я закончу этот курс к следующему году',
        transcription: 'aɪl həv ˈfɪnɪʃt ðɪs kɔːs baɪ nɛkst jɪə',
        translation: "I'll have finished this course by next year",
      },
      {
        id: 29,
        expression: 'Что-то задержало рейс',
        transcription: 'ˈsʌmθɪŋ dɪˈleɪd ðə flaɪt',
        translation: 'Something delayed the flight',
      },
      {
        id: 30,
        expression: 'Конференция будет проведена летом',
        transcription: 'ðə ˈkɒnfərəns wɪl bi hɛld ɪn ˈsʌmə',
        translation: 'The conference will be held in summer',
      },
      {
        id: 31,
        expression: 'Они импортируют те товары из Европы',
        transcription: 'ðeɪ ˈɪmpɔːt ðəʊz gʊdz frəm ˈjʊərəp',
        translation: 'They import those goods from Europe',
      },
      {
        id: 32,
        expression: 'Мебель была продана',
        transcription: 'ðə ˈfɜːnɪʧə wəz səʊld',
        translation: 'The furniture was sold',
      },
      {
        id: 33,
        expression: 'Они экспортируют эти товары в Соединенные Штаты',
        transcription: 'ðeɪ ˈɛkspɔːt ðiːz gʊdz tə ðə jʊˈnaɪtɪd steɪts',
        translation: 'They export these goods to the United States',
      },
      {
        id: 34,
        expression: 'Эта история основана на реальных фактах',
        transcription: 'ðɪs ˈstɔːri z beɪst ɒn rɪəl fækts',
        translation: 'This story is based on real facts',
      },
      {
        id: 35,
        expression: 'Он исправил ошибку',
        transcription: 'hi kəˈrɛktɪd ðə mɪsˈteɪk',
        translation: 'He corrected the mistake',
      },
      {
        id: 36,
        expression: 'Это только что было сказано',
        transcription: 'ɪts ʤəst biːn sɛd',
        translation: "It's just been said",
      },
      {
        id: 37,
        expression: 'Они показывают это по телевизору сейчас',
        transcription: 'ðeə ˈʃəʊɪŋ ɪt ɒn ˌtiːˈviː naʊ',
        translation: "They're showing it on TV now",
      },
      {
        id: 38,
        expression: 'Грабители были отправлены в тюрьму',
        transcription: 'ðə ˈbɜːgləz wə sɛnt tə ʤeɪl',
        translation: 'The burglars were sent to jail',
      },
      {
        id: 39,
        expression: 'Кроссовки только что были куплены',
        transcription: 'ðə ˈtreɪnəz həz ʤəst biːn bɔːt',
        translation: 'The trainers has just been bought',
      },
      {
        id: 40,
        expression: 'Он часто объясняет это очень ясно',
        transcription: 'hi ˈɒf(ə)n ɪksˈpleɪnz ɪt ˈvɛri ˈklɪəli',
        translation: 'He often explains it very clearly',
      },
      {
        id: 41,
        expression: 'Эти файлы будут удалены',
        transcription: 'ðiːz faɪlz wɪl bi dɪˈliːtɪd',
        translation: 'These files will be deleted',
      },
      {
        id: 42,
        expression: 'Эти товары импортируются',
        transcription: 'ðiːz gʊdz ər ɪmˈpɔːtɪd',
        translation: 'These goods are imported',
      },
      {
        id: 43,
        expression: 'Ошибка была исправлена им',
        transcription: 'ðə mɪsˈteɪk wəz kəˈrɛktɪd baɪ hɪm',
        translation: 'The mistake was corrected by him',
      },
      {
        id: 44,
        expression: 'Кто-то разбил зеркало',
        transcription: 'ˈsʌmbədi brəʊk ðə ˈmɪrə',
        translation: 'Somebody broke the mirror',
      },
      {
        id: 45,
        expression: 'Дом будет покрашен к следующей неделе',
        transcription:
          'ðə haʊs wɪl həv biːn ˈpeɪntɪd baɪ ðə bɪˈgɪnɪŋ əv nɛkst wiːk',
        translation:
          'The house will have been painted by the beginning of next week',
      },
      {
        id: 46,
        expression: 'Штаб-квартира этой компании находится в Калифорнии',
        transcription:
          'ðə ˈhɛdˈkwɔːtəz əv ðɪs ˈkʌmpəni ɪz (ɑː) ləʊˈkeɪtɪd ɪn ˌkæləˈfɔːniə',
        translation:
          'The headquarters of this company is (are) located in California',
      },
      {
        id: 47,
        expression: 'Пока работа проверялась, мы ждали наших результатов',
        transcription:
          'waɪl ðə wɜːk wəz ˈbiːɪŋ ʧɛkt, wi wə ˈweɪtɪŋ fər ˈaʊə rɪˈzʌlts',
        translation:
          'While the work was being checked, we were waiting for our results',
      },
      {
        id: 48,
        expression: 'Было сказано, что рабочие уже закончили красить дом',
        transcription:
          'ɪt wəz sɛd ðət ðə ˈwɜːkəz həd ɔːlˈrɛdi ˈfɪnɪʃt ˈpeɪntɪŋ ðə haʊs',
        translation:
          'It was said that the workers had already finished painting the house',
      },
      {
        id: 49,
        expression: 'Решение этой проблемы еще не найдено',
        transcription: 'ə səˈluːʃən tə ðɪs ˈprɒbləm ˈhæznt biːn faʊnd jɛt',
        translation: "A solution to this problem hasn't been found yet",
      },
      {
        id: 50,
        expression:
          'Пока учитель проверял мою работу, я ждал своих результатов',
        transcription:
          'waɪl ðə ˈtiːʧə wəz ˈʧɛkɪŋ maɪ wɜːk, aɪ wəz ˈweɪtɪŋ fə maɪ rɪˈzʌlts',
        translation:
          'While the teacher was checking my work, I was waiting for my results',
      },
      {
        id: 51,
        expression: 'Они еще не нашли решение такой проблемы',
        transcription: 'ðeɪ hævnt faʊnd ə səˈluːʃən tə sʌʧ ə ˈprɒbləm jɛt',
        translation: "They haven't found a solution to such a problem yet",
      },
      {
        id: 52,
        expression: 'Стены будут покрашены ко вторнику',
        transcription: 'ðə wɔːlz wɪl həv biːn ˈpeɪntɪd baɪ ˈtjuːzdeɪ',
        translation: 'The walls will have been painted by Tuesday',
      },
      {
        id: 53,
        expression: 'Я полагаю, кто-то скоро купит этот дом',
        transcription: 'aɪ səˈpəʊz ˈsʌmwʌn wɪl baɪ ðɪs haʊs suːn',
        translation: 'I suppose someone will buy this house soon',
      },
      {
        id: 54,
        expression: 'Было сказано, что дом уже покрашен',
        transcription: 'ɪt wəz sɛd ðət ðə haʊs həd ɔːlˈrɛdi biːn ˈpeɪntɪd',
        translation: 'It was said that the house had already been painted',
      },
      {
        id: 55,
        expression: 'Мой дом находится недалеко отсюда',
        transcription: 'maɪ haʊs ɪz ləʊˈkeɪtɪd (ˈsɪtjʊeɪtɪd) nɒt fɑː frəm hɪə',
        translation: 'My house is located (situated) not far from here',
      },
      {
        id: 56,
        expression: 'Ожидается, что они построят мост к следующей неделе',
        transcription:
          'ɪt s ɪksˈpɛktɪd ðət ðeɪl həv kənˈstrʌktɪd (bɪlt) ðə brɪʤ baɪ nɛkst wiːk',
        translation:
          "It is expected that they'll have constructed (built) the bridge by next week",
      },
      {
        id: 57,
        expression: 'Я думаю, правильный выбор будет сделан',
        transcription: 'aɪ θɪŋk ðə raɪt ʧɔɪs wɪl bi meɪd',
        translation: 'I think the right choice will be made',
      },
      {
        id: 58,
        expression: 'Банк находится достаточно близко',
        transcription: 'ðə bæŋk s ləʊˈkeɪtɪd kwaɪt nɪə',
        translation: 'The bank is located quite near',
      },
      {
        id: 59,
        expression: 'Эта задача будет закончена завтра',
        transcription: 'ðɪs tɑːsk wɪl bi ˈfɪnɪʃt təˈmɒrəʊ',
        translation: 'This task will be finished tomorrow',
      },
      {
        id: 60,
        expression: 'Часы сейчас ремонтируются',
        transcription: 'ðə klɒk (wɒʧ) ɪz ˈbiːɪŋ rɪˈpeəd naʊ',
        translation: 'The clock (watch) is being repaired now',
      },
      {
        id: 61,
        expression: 'Устройство сейчас ремонтируется',
        transcription: 'ðə dɪˈvaɪs ɪz ˈbiːɪŋ rɪˈpeəd naʊ',
        translation: 'The device is being repaired now',
      },
      {
        id: 62,
        expression: 'Часы ремонтируются сейчас?',
        transcription: 'ɪz ðə klɒk (wɒʧ) ˈbiːɪŋ rɪˈpeəd naʊ?',
        translation: 'Is the clock (watch) being repaired now?',
      },
      {
        id: 63,
        expression: 'Они сделали правильный вывод',
        transcription: 'ðeɪ meɪd ðə raɪt kənˈkluːʒən',
        translation: 'They made the right conclusion',
      },
      {
        id: 64,
        expression: 'Они нашли правильное решение',
        transcription: 'ðeɪ faʊnd ðə raɪt dɪˈsɪʒən',
        translation: 'They found the right decision',
      },
      {
        id: 65,
        expression: 'Они сделали правильный вывод?',
        transcription: 'dɪd ðeɪ meɪk ðə raɪt kənˈkluːʒən?',
        translation: 'Did they make the right conclusion?',
      },
      {
        id: 66,
        expression: 'Они дали ей куклу в подарок',
        transcription: 'ðeɪ geɪv hər ə dɒl əz ə ˈprɛznt',
        translation: 'They gave her a doll as a present',
      },
      {
        id: 67,
        expression: 'Они дали ему игрушку в подарок',
        transcription: 'ðeɪ geɪv ɪm ə tɔɪ əz ə ˈprɛznt',
        translation: 'They gave him a toy as a present',
      },
      {
        id: 68,
        expression: 'Они дали ей куклу в подарок?',
        transcription: 'dɪd ðeɪ gɪv hər ə dɒl əz ə ˈprɛznt?',
        translation: 'Did they give her a doll as a present?',
      },
      {
        id: 69,
        expression: 'Где это расположено?',
        transcription: 'weə z ɪt ləʊˈkeɪtɪd?',
        translation: 'Where is it located?',
      },
      {
        id: 70,
        expression: 'Где это было расположено?',
        transcription: 'weə wəz ɪt ləʊˈkeɪtɪd?',
        translation: 'Where was it located?',
      },
      {
        id: 71,
        expression: 'Где находится это здание?',
        transcription: 'weə z ðɪs ˈbɪldɪŋ ləʊˈkeɪtɪd?',
        translation: 'Where is this building located?',
      },
      {
        id: 72,
        expression: 'Правильное решение было принято',
        transcription: 'ðə raɪt dɪˈsɪʒən wəz meɪd',
        translation: 'The right decision was made',
      },
      {
        id: 73,
        expression: 'Было найдено правильное решение',
        transcription: 'ðə raɪt dɪˈsɪʒən wəz faʊnd',
        translation: 'The right decision was found',
      },
      {
        id: 74,
        expression: 'Правильный вывод был сделан',
        transcription: 'ðə raɪt kənˈkluːʒən wəz meɪd',
        translation: 'The right conclusion was made',
      },
      {
        id: 75,
        expression: 'Что они выяснили?',
        transcription: 'wɒt dɪd ðeɪ faɪnd aʊt?',
        translation: 'What did they find out?',
      },
      {
        id: 76,
        expression: 'Они что-то выяснили?',
        transcription: 'dɪd ðeɪ faɪnd aʊt ˈsʌmθɪŋ?',
        translation: 'Did they find out something?',
      },
      {
        id: 77,
        expression: 'Когда они выяснили это?',
        transcription: 'wɛn dɪd ðeɪ faɪnd ɪt aʊt?',
        translation: 'When did they find it out?',
      },
      {
        id: 78,
        expression: 'Я закончу эту задачу завтра',
        transcription: 'aɪl ˈfɪnɪʃ ðɪs tɑːsk təˈmɒrəʊ',
        translation: "I'll finish this task tomorrow",
      },
      {
        id: 79,
        expression: 'Я закончу работать над этим проектом завтра',
        transcription: 'aɪl ˈfɪnɪʃ ˈwɜːkɪŋ ɒn ðɪs ˈprɒʤɛkt təˈmɒrəʊ',
        translation: "I'll finish working on this project tomorrow",
      },
      {
        id: 80,
        expression: 'Я закончу делать свое домашнее задание завтра',
        transcription: 'aɪl ˈfɪnɪʃ ˈdu(ː)ɪŋ maɪ ˈhəʊmˌwɜːk təˈmɒrəʊ',
        translation: "I'll finish doing my homework tomorrow",
      },
      {
        id: 81,
        expression: 'Они собрали данные',
        transcription: 'ðeɪ kəˈlɛktɪd ðə deɪt',
        translation: 'They collected the data',
      },
      {
        id: 82,
        expression: 'Они собрали эту информацию',
        transcription: 'ðeɪ kəˈlɛktɪd ðɪs ˌɪnfəˈmeɪʃən',
        translation: 'They collected this information',
      },
      {
        id: 83,
        expression: 'Они собрали данные?',
        transcription: 'dɪd ðeɪ kəˈlɛkt ðə ˈdeɪtə?',
        translation: 'Did they collect the data?',
      },
      {
        id: 84,
        expression: 'Что было обнаружено?',
        transcription: 'wɒt wəz faʊnd aʊt?',
        translation: 'What was found out?',
      },
      {
        id: 85,
        expression: 'Кто это обнаружил?',
        transcription: 'huː faʊnd ɪt aʊt?',
        translation: 'Who found it out?',
      },
      {
        id: 86,
        expression: 'Когда это было обнаружено?',
        transcription: 'wɛn wəz faʊnd ɪt aʊt?',
        translation: 'When was found it out?',
      },
      {
        id: 87,
        expression: 'Ничего еще не исправлено',
        transcription: 'ˈnʌθɪŋ həz biːn kəˈrɛktɪd jɛt',
        translation: 'Nothing has been corrected yet',
      },
      {
        id: 88,
        expression: 'Все уже исправлено',
        transcription: 'ˈɛvrɪθɪŋ həz ɔːlˈrɛdi biːn kəˈrɛktɪd',
        translation: 'Everything has already been corrected',
      },
      {
        id: 89,
        expression: 'Кое-что уже исправлено',
        transcription: 'ˈsʌmθɪŋ həz ɔːlˈrɛdi biːn kəˈrɛktɪd',
        translation: 'Something has already been corrected',
      },
      {
        id: 90,
        expression: 'Смотри! Новое здание строится сейчас',
        transcription: 'lʊk! ə njuː ˈbɪldɪŋ z ˈbiːɪŋ bɪlt naʊ',
        translation: 'Look! A new building is being built now',
      },
      {
        id: 91,
        expression: 'Много вреда было нанесено нашей окружающей среде',
        transcription: 'ə lɒt əv hɑːm wəz dʌn tʊ ˈaʊər ɪnˈvaɪərənmənt',
        translation: 'A lot of harm was done to our environment',
      },
      {
        id: 92,
        expression: 'Штаб-квартира располагается в Нью-Йорке',
        transcription: 'ðə ˈhɛdˈkwɔːtəz ɪz ləʊˈkeɪtɪd ɪn njuː jɔːk',
        translation: 'The headquarters is located in New York',
      },
      {
        id: 93,
        expression: 'Это выяснилось только вчера',
        transcription: 'ɪt wəz faʊnd aʊt ˈəʊnli ˈjɛstədeɪ',
        translation: 'It was found out only yesterday',
      },
      {
        id: 94,
        expression: 'Я полагаю, дом скоро будет продан',
        transcription: 'aɪ səˈpəʊz ðə haʊs wɪl bi səʊld suːn',
        translation: 'I suppose the house will be sold soon',
      },
      {
        id: 95,
        expression: 'Они еще ничего не исправили',
        transcription:
          'ðeɪ həv kəˈrɛktɪd ˈnʌθɪŋ jɛt / ðeɪ hævnt kəˈrɛktɪd ˈɛnɪθɪŋ jɛt',
        translation:
          "They have corrected nothing yet / They haven't corrected anything yet",
      },
      {
        id: 96,
        expression: 'Кукла была дана ей в подарок',
        transcription: 'ðə dɒl wəz ˈgɪvn tə hər əz ə ˈprɛznt',
        translation: 'The doll was given to her as a present',
      },
      {
        id: 97,
        expression: 'Мост будет построен к следующей неделе',
        transcription: 'ðə brɪʤ wɪl həv biːn kənˈstrʌktɪd baɪ nɛkst wiːk',
        translation: 'The bridge will have been constructed by next week',
      },
      {
        id: 98,
        expression: 'Рабочие покрасят дом к субботе',
        transcription: 'ðə ˈwɜːkəz wɪl həv ˈpeɪntɪd ðə haʊs baɪ ˈsætədeɪ',
        translation: 'The workers will have painted the house by Saturday',
      },
      {
        id: 99,
        expression: 'Они нанесли большой вред нашей окружающей среде',
        transcription: 'ðeɪ dɪd lɒts əv hɑːm tʊ ˈaʊər ɪnˈvaɪərənmənt',
        translation: 'They did lots of harm to our environment',
      },
      {
        id: 100,
        expression: 'Где это находится?',
        transcription: 'weə z ɪt ˈsɪtjʊeɪtɪd?',
        translation: 'Where is it situated?',
      },
      {
        id: 101,
        expression: 'Они пытаются починить эти часы',
        transcription: 'ðeə ˈtraɪɪŋ tə rɪˈpeə ðɪs wɒʧ (klɒk)',
        translation: "They're trying to repair this watch (clock)",
      },
      {
        id: 102,
        expression: 'Я принял правильное решение',
        transcription: 'aɪ meɪd ðə raɪt dɪˈsɪʒən',
        translation: 'I made the right decision',
      },
      {
        id: 103,
        expression: 'Данные были собраны',
        transcription: 'ðə ˈdeɪtə wɒz (wɜː) kəˈlɛktɪd',
        translation: 'The data was (were) collected',
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Закрепление пройденного материала</h3>
</div>`,
    },
  ],
];
