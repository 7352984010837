export const contentLesson91 = [
  [
    {
      title: "Урок 91. Past Simple. 85 Irregular Verbs. Part 2",
    },
    [
      {
        id: 1,
        expression: "Это принесло ему успех и счастье",
        transcription: "ɪt brɔːt ɪm səkˈsɛs ənd ˈhæpɪnɪs",
        translation: "It brought him success and happiness",
      },
      {
        id: 2,
        expression: "Я забыл это полностью",
        transcription: "aɪ fəˈgɒt ɪt kəmˈpliːtli",
        translation: "I forgot it completely",
      },
      {
        id: 3,
        expression: "Он боролся и победил",
        transcription: "hi fɔːt ənd wʌn",
        translation: "He fought and won",
      },
      {
        id: 4,
        expression: "Я встретил его в парке",
        transcription: "aɪ mɛt ɪm ɪn ðə pɑːk",
        translation: "I met him in the park",
      },
      {
        id: 5,
        expression: "Это принесло ему настоящий успех",
        transcription: "ɪt brɔːt ɪm ə rɪəl səkˈsɛs",
        translation: "It brought him a real success",
      },
      {
        id: 6,
        expression: "Она рассказала мне эту историю",
        transcription: "ʃi təʊld mi ðɪs ˈstɔːri",
        translation: "She told me this story",
      },
      {
        id: 7,
        expression: "Она простила его полностью",
        transcription: "ʃi fəˈgeɪv ɪm kəmˈpliːtli",
        translation: "She forgave him completely",
      },
      {
        id: 8,
        expression: "Я вчера отдыхал",
        transcription: "aɪ həd ə rɛst ˈjɛstədeɪ",
        translation: "I had a rest yesterday",
      },
      {
        id: 9,
        expression: "Я стоял возле окна",
        transcription: "aɪ stʊd bɪˈsaɪd ðə ˈwɪndəʊ",
        translation: "I stood beside the window",
      },
      {
        id: 10,
        expression: "Она случайно порезала палец",
        transcription: "ʃi kʌt hə ˈfɪŋgə baɪ ˈæksɪdənt",
        translation: "She cut her finger by accident",
      },
      {
        id: 11,
        expression: "Она накормила ребенка",
        transcription: "ʃi fɛd ðə ˈbeɪbi",
        translation: "She fed the baby",
      },
      {
        id: 12,
        expression: "Она сломала руку",
        transcription: "ʃi brəʊk hər ɑːm",
        translation: "She broke her arm",
      },
      {
        id: 13,
        expression: "Он написал что-то на стене",
        transcription: "hi rəʊt ˈsʌmθɪŋ ɒn ðə wɔːl",
        translation: "He wrote something on the wall",
      },
      {
        id: 14,
        expression: "Я много говорил по-английски",
        transcription: "aɪ spəʊk ˈɪŋglɪʃ ə lɒt",
        translation: "I spoke English a lot",
      },
      {
        id: 15,
        expression: "Она стояла возле кровати",
        transcription: "ʃi stʊd bɪˈsaɪd ðə bɛd",
        translation: "She stood beside the bed",
      },
      {
        id: 16,
        expression: "У меня были наличные",
        transcription: "aɪ həd kæʃ",
        translation: "I had cash",
      },
      {
        id: 17,
        expression: "Мы перерезали веревку",
        transcription: "wi kʌt ðə rəʊp",
        translation: "We cut the rope",
      },
      {
        id: 18,
        expression: "Он сломал ногу",
        transcription: "hi brəʊk ɪz lɛg",
        translation: "He broke his leg",
      },
      {
        id: 19,
        expression: "Он пел песню",
        transcription: "hi sæŋ ə sɒŋ",
        translation: "He sang a song",
      },
      {
        id: 20,
        expression: "Она покормила своего питомца",
        transcription: "ʃi fɛd hə pɛt",
        translation: "She fed her pet",
      },
      {
        id: 21,
        expression: "Она упала и ушибла руку",
        transcription: "ʃi fɛl ənd hɜːt hər ɑːm",
        translation: "She fell and hurt her arm",
      },
      {
        id: 22,
        expression: "Я имел дело с разными клиентами",
        transcription: "aɪ dɛlt wɪð ˈdɪfrənt ˈklaɪənts",
        translation: "I dealt with different clients",
      },
      {
        id: 23,
        expression: "Мальчик нарисовал дом",
        transcription: "ðə bɔɪ druː ə haʊs",
        translation: "The boy drew a house",
      },
      {
        id: 24,
        expression: "Она не могла простить его",
        transcription: "ʃi ˈkʊdnt fəˈgɪv hɪm",
        translation: "She couldn't forgive him",
      },
      {
        id: 25,
        expression: "Он сказал нам подождать",
        transcription: "hi təʊld əs tə weɪt",
        translation: "He told us to wait",
      },
      {
        id: 26,
        expression: "Мы встречались каждую неделю",
        transcription: "wi mɛt ˈɛvri wiːk",
        translation: "We met every week",
      },
      {
        id: 27,
        expression: "Она разбила чашку",
        transcription: "ʃi brəʊk ðə kʌp",
        translation: "She broke the cup",
      },
      {
        id: 28,
        expression: "Он упал и ушиб ногу",
        transcription: "hi fɛl ənd hɜːt ɪz lɛg",
        translation: "He fell and hurt his leg",
      },
      {
        id: 29,
        expression: "Она имела дело с покупателями",
        transcription: "ʃi dɛlt wɪð ˈkʌstəməz",
        translation: "She dealt with customers",
      },
      {
        id: 30,
        expression: "Он написал очень длинное письмо",
        transcription: "hi rəʊt ə ˈvɛri lɒŋ ˈlɛtə",
        translation: "He wrote a very long letter",
      },
      {
        id: 31,
        expression: "Этот проект принес ему много клиентов",
        transcription: "ðɪs ˈprɒʤɛkt brɔːt ɪm lɒts əv ˈklaɪənts",
        translation: "This project brought him lots of clients",
      },
      {
        id: 32,
        expression: "Я написал очень длинный комментарий",
        transcription: "aɪ rəʊt ə ˈvɛri lɒŋ ˈkɒmɛnt",
        translation: "I wrote a very long comment",
      },
      {
        id: 33,
        expression: "Он случайно порезал свой палец",
        transcription: "hi kʌt ɪz ˈfɪŋgər ˌæksɪˈdɛntəli",
        translation: "He cut his finger accidentally",
      },
      {
        id: 34,
        expression: "Они боролись за независимость",
        transcription: "ðeɪ fɔːt fər ˌɪndɪˈpɛndəns",
        translation: "They fought for independence",
      },
      {
        id: 35,
        expression: "Он упал и ушиб свое колено",
        transcription: "hi fɛl ənd hɜːt ɪz niː",
        translation: "He fell and hurt his knee",
      },
      {
        id: 36,
        expression: "Он имел дело с поставщиками",
        transcription: "hi dɛlt wɪð səˈplaɪəz",
        translation: "He dealt with suppliers",
      },
      {
        id: 37,
        expression: "Я увидел ее в университете",
        transcription: "aɪ sɔː hər ət ˌjuːnɪˈvɜːsɪti",
        translation: "I saw her at university",
      },
      {
        id: 38,
        expression: "Они боролись за свободу",
        transcription: "ðeɪ fɔːt fə ˈfriːdəm",
        translation: "They fought for freedom",
      },
      {
        id: 39,
        expression: "Он разбил тарелку",
        transcription: "hi brəʊk ðə pleɪt",
        translation: "He broke the plate",
      },
      {
        id: 40,
        expression: "Он разрезал это на четыре маленьких кусочка",
        transcription: "hi kʌt ɪt ˈɪntə fɔː smɔːl ˈpiːsɪz",
        translation: "He cut it into four small pieces",
      },
      {
        id: 41,
        expression: "Солдаты сражались очень смело и отважно",
        transcription: "ðə ˈsəʊlʤəz fɔːt ˈvɛri ˈbreɪvli ənd kəˈreɪʤəsli",
        translation: "The soldiers fought very bravely and courageously",
      },
      {
        id: 42,
        expression:
          "У него было слишком мало денег, поэтому он решил не покупать это",
        transcription: "hi həd tuː ˈlɪtl ˈmʌni səʊ hi dɪˈsaɪdɪd nɒt tə baɪ ɪt",
        translation: "He had too little money so he decided not to buy it",
      },
      {
        id: 43,
        expression: "Они боролись за свободу и независимость",
        transcription: "ðeɪ fɔːt fə ˈfriːdəm ənd ˌɪndɪˈpɛndəns",
        translation: "They fought for freedom and independence",
      },
      {
        id: 44,
        expression:
          "У меня было слишком мало свободного времени, поэтому я решил остаться дома",
        transcription:
          "aɪ həd tuː ˈlɪtl friː taɪm səʊ aɪ dɪˈsaɪdɪd tə steɪ ət həʊm",
        translation: "I had too little free time so I decided to stay at home",
      },
      {
        id: 45,
        expression: "Большинство носителей языка говорили очень быстро",
        transcription: "məʊst ˈneɪtɪv ˈspiːkəz spəʊk ˈvɛri fɑːst",
        translation: "Most native speakers spoke very fast",
      },
      {
        id: 46,
        expression: "Солдат сражался действительно героически",
        transcription: "ðə ˈsəʊlʤə fɔːt ˈrɪəli hɪˈrəʊɪk(ə)li",
        translation: "The soldier fought really heroically",
      },
      {
        id: 47,
        expression: "Я довольно часто ездил на велосипеде",
        transcription: "aɪ rəʊd ə baɪk kwaɪt ˈɒf(ə)n",
        translation: "I rode a bike quite often",
      },
      {
        id: 48,
        expression: "Я решил выбросить это",
        transcription: "aɪ dɪˈsaɪdɪd tə θrəʊ ɪt əˈweɪ",
        translation: "I decided to throw it away",
      },
      {
        id: 49,
        expression: "Я сделал все возможное, чтобы сдать этот экзамен",
        transcription: "aɪ dɪd maɪ bɛst tə pɑːs ðɪs ɪgˈzæm",
        translation: "I did my best to pass this exam",
      },
      {
        id: 50,
        expression: "Солдаты тренировались сражаться",
        transcription: "ðə ˈsəʊlʤəz treɪnd tə faɪt",
        translation: "The soldiers trained to fight",
      },
      {
        id: 51,
        expression: "Его родители не позволили ему сделать это",
        transcription: "hɪz ˈpeərənts dɪdnt lɛt ɪm dʊ ɪt",
        translation: "His parents didn't let him do it",
      },
      {
        id: 52,
        expression: "Я выиграл в конце",
        transcription: "aɪ wʌn ɪn ði ɛnd",
        translation: "I won in the end",
      },
      {
        id: 53,
        expression: "Она выпила чашку горячего кофе",
        transcription: "ʃi dræŋk ə kʌp əv hɒt ˈkɒfi",
        translation: "She drank a cup of hot coffee",
      },
      {
        id: 54,
        expression: "Он позволял мне делать то, что я хотел",
        transcription: "hi lɛt mi dʊ wɒt aɪ ˈwɒntɪd",
        translation: "He let me do what I wanted",
      },
      {
        id: 55,
        expression: "Мальчики катались на пони",
        transcription: "ðə bɔɪz rəʊd ə ˈpəʊni",
        translation: "The boys rode a pony",
      },
      {
        id: 56,
        expression: "Я бросил ключи туда",
        transcription: "aɪ θruː ðə kiːz ðeə",
        translation: "I threw the keys there",
      },
      {
        id: 57,
        expression: "Я бросил свои ключи туда",
        transcription: "aɪ θruː maɪ kiːz ðeə",
        translation: "I threw my keys there",
      },
      {
        id: 58,
        expression: "Я бросил ключи куда-то",
        transcription: "aɪ θruː ðə kiːz ˈsʌmweə",
        translation: "I threw the keys somewhere",
      },
      {
        id: 59,
        expression: "Я забыл название улицы",
        transcription: "aɪ fəˈgɒt ðə neɪm əv ðə striːt",
        translation: "I forgot the name of the street",
      },
      {
        id: 60,
        expression: "Я забыл название книги",
        transcription: "aɪ fəˈgɒt ðə neɪm əv ðə bʊk",
        translation: "I forgot the name of the book",
      },
      {
        id: 61,
        expression: "Я вспомнил название этой улицы",
        transcription: "aɪ rɪˈmɛmbəd ðə neɪm əv ðɪs striːt",
        translation: "I remembered the name of this street",
      },
      {
        id: 62,
        expression: "Его родители позволили ему сделать это",
        transcription: "hɪz ˈpeərənts lɛt ɪm dʊ ɪt",
        translation: "His parents let him do it",
      },
      {
        id: 63,
        expression: "Мои родители позволили мне сделать это",
        transcription: "maɪ ˈpeərənts lɛt mi dʊ ɪt",
        translation: "My parents let me do it",
      },
      {
        id: 64,
        expression: "Ее родители не позволили ей сделать это",
        transcription: "hə ˈpeərənts dɪdnt lɛt hə dʊ ɪt",
        translation: "Her parents didn't let her do it",
      },
      {
        id: 65,
        expression: "Его родители не позволили ему выходить так поздно",
        transcription: "hɪz ˈpeərənts dɪdnt lɛt ɪm gəʊ aʊt səʊ leɪt",
        translation: "His parents didn't let him go out so late",
      },
      {
        id: 66,
        expression: "Он не позволил ей идти туда",
        transcription: "hi dɪdnt lɛt hə gəʊ ðeə",
        translation: "He didn't let her go there",
      },
      {
        id: 67,
        expression: "Их родители не позволяли им выходить так поздно",
        transcription: "ðeə ˈpeərənts dɪdnt lɛt ðəm gəʊ aʊt səʊ leɪt",
        translation: "Their parents didn't let them go out so late",
      },
      {
        id: 68,
        expression: "Я видел ее на вечеринке",
        transcription: "aɪ sɔː hər ət ðə ˈpɑːti",
        translation: "I saw her at the party",
      },
      {
        id: 69,
        expression: "Он видел меня на вечеринке",
        transcription: "hi sɔː mi ət ðə ˈpɑːti",
        translation: "He saw me at the party",
      },
      {
        id: 70,
        expression: "Я видел его на этой вечеринке",
        transcription: "aɪ sɔː ɪm ət ðɪs ˈpɑːti",
        translation: "I saw him at this party",
      },
      {
        id: 71,
        expression: "Я забыл имя твоего друга",
        transcription: "aɪ fəˈgɒt ðə neɪm əv jə frɛnd",
        translation: "I forgot the name of your friend",
      },
      {
        id: 72,
        expression: "Я не забыл имя твоего друга",
        transcription: "aɪ dɪdnt fəˈgɛt ðə neɪm əv jə frɛnd",
        translation: "I didn't forget the name of your friend",
      },
      {
        id: 73,
        expression: "Я забыл твое имя",
        transcription: "aɪ fəˈgɒt jə neɪm",
        translation: "I forgot your name",
      },
      {
        id: 74,
        expression: "Я сказал ему, что это была пустая трата времени",
        transcription: "aɪ təʊld ɪm ðət ɪt wəz ə weɪst əv taɪm",
        translation: "I told him that it was a waste of time",
      },
      {
        id: 75,
        expression: "Я сказал ей, что это была пустая трата времени",
        transcription: "aɪ təʊld hə ðət ɪt wəz ə weɪst əv taɪm",
        translation: "I told her that it was a waste of time",
      },
      {
        id: 76,
        expression: "Он сказал, что это была пустая трата времени",
        transcription: "hi sɛd ðət ɪt wəz ə weɪst əv taɪm",
        translation: "He said that it was a waste of time",
      },
      {
        id: 77,
        expression: "Ты все сделал правильно",
        transcription: "jʊ dɪd ˈɛvrɪθɪŋ ˈraɪtli (kəˈrɛktli)",
        translation: "You did everything rightly (correctly)",
      },
      {
        id: 78,
        expression: "Они сделали все очень хорошо",
        transcription: "ðeɪ dɪd ˈɛvrɪθɪŋ ˈvɛri wɛl",
        translation: "They did everything very well",
      },
      {
        id: 79,
        expression: "Я все сделал правильно",
        transcription: "aɪ dɪd ˈɛvrɪθɪŋ ˈraɪtli (kəˈrɛktli)",
        translation: "I did everything rightly (correctly)",
      },
      {
        id: 80,
        expression: "Я плавал в пруду",
        transcription: "aɪ swæm ɪn ðə pɒnd",
        translation: "I swam in the pond",
      },
      {
        id: 81,
        expression: "Я плавал в море",
        transcription: "aɪ swæm ɪn ðə siː",
        translation: "I swam in the sea",
      },
      {
        id: 82,
        expression: "Я плавал в озере",
        transcription: "aɪ swæm ɪn ðə leɪk",
        translation: "I swam in the lake",
      },
      {
        id: 83,
        expression: "Я сел на мягкий стул",
        transcription: "aɪ sæt ɒn ə sɒft ʧeə",
        translation: "I sat on a soft chair",
      },
      {
        id: 84,
        expression: "Я сел на новый стул",
        transcription: "aɪ sæt ɒn ə njuː ʧeə",
        translation: "I sat on a new chair",
      },
      {
        id: 85,
        expression: "Он сел в мягкое кресло",
        transcription: "hi sæt ɪn ə sɒft ˈɑːmˈʧeə",
        translation: "He sat in a soft armchair",
      },
      {
        id: 86,
        expression: "Я переплыл реку",
        transcription: "aɪ swæm əˈkrɒs ðə ˈrɪvə",
        translation: "I swam across the river",
      },
      {
        id: 87,
        expression: "Я думаю, она нарисовала настоящий шедевр",
        transcription: "aɪ θɪŋk ʃi druː ə rɪəl ˈmɑːstəpiːs",
        translation: "I think she drew a real masterpiece",
      },
      {
        id: 88,
        expression: "Я сел на очень удобный стул",
        transcription: "aɪ sæt ɒn ə ˈvɛri ˈkʌmf(ə)təbl ʧeə",
        translation: "I sat on a very comfortable chair",
      },
      {
        id: 89,
        expression: "Он выпил стакан сока",
        transcription: "hi dræŋk ə glɑːs əv ʤuːs",
        translation: "He drank a glass of juice",
      },
      {
        id: 90,
        expression: "Я встал позже, чем обычно",
        transcription: "aɪ gɒt ʌp ˈleɪtə ðən ˈjuːʒʊəl",
        translation: "I got up later than usual",
      },
      {
        id: 91,
        expression: "Его родители позволили ему пойти туда",
        transcription: "hɪz ˈpeərənts lɛt ɪm gəʊ ðeə",
        translation: "His parents let him go there",
      },
      {
        id: 92,
        expression: "Я не смог встретить его вчера",
        transcription: "aɪ ˈkʊdnt miːt ɪm ˈjɛstədeɪ",
        translation: "I couldn't meet him yesterday",
      },
      {
        id: 93,
        expression: "Мужчина переплыл озеро",
        transcription: "ðə mæn swæm əˈkrɒs ðə leɪk",
        translation: "The man swam across the lake",
      },
      {
        id: 94,
        expression: "Он заболел на прошлой неделе",
        transcription: "hi fɛl ɪl lɑːst wiːk",
        translation: "He fell ill last week",
      },
      {
        id: 95,
        expression: "Ты сделал все идеально",
        transcription: "jʊ dɪd ˈɛvrɪθɪŋ ˈpɜːfɪktli",
        translation: "You did everything perfectly",
      },
      {
        id: 96,
        expression: "Я выбросил это",
        transcription: "aɪ θruː ɪt əˈweɪ",
        translation: "I threw it away",
      },
      {
        id: 97,
        expression: "Она каталась на пони",
        transcription: "ʃi rəʊd ə ˈpəʊni",
        translation: "She rode a pony",
      },
      {
        id: 98,
        expression: "Он нарисовал настоящий шедевр",
        transcription: "hi drɔː ə rɪəl ˈmɑːstəpiːs",
        translation: "He draw a real masterpiece",
      },
      {
        id: 99,
        expression: "Он позволил мне сделать это",
        transcription: "hi lɛt mi dʊ ɪt",
        translation: "He let me do it",
      },
      {
        id: 100,
        expression: "Я хотел сесть",
        transcription: "aɪ ˈwɒntɪd tə sɪt daʊn",
        translation: "I wanted to sit down",
      },
      {
        id: 101,
        expression: "Я проснулся так поздно",
        transcription: "aɪ wəʊk ʌp səʊ leɪt",
        translation: "I woke up so late",
      },
      {
        id: 102,
        expression: "Она преподавала английский очень профессионально",
        transcription: "ʃi tɔːt ˈɪŋglɪʃ ˈvɛri prəˈfɛʃnəli",
        translation: "She taught English very professionally",
      },
    ],
    {
      theory: `<div class="theory-block">
<h3>Закрепление пройденного материала</h3>
</div>`,
    },
  ],
];
