export const wordsLesson244 = [
  [
    {
      id: 1,
      word: 'Bible',
      transcription: 'ˈbaɪbᵊl',
      translation: 'Библия',
    },
    {
      id: 2,
      word: 'compete',
      transcription: 'kəmˈpiːt',
      translation: 'соревноваться, конкурировать',
    },
    {
      id: 3,
      word: 'cucumber',
      transcription: 'ˈkjuːkʌmbə',
      translation: 'огурец',
    },
    {
      id: 4,
      word: 'diligence',
      transcription: 'ˈdɪlɪʤəns',
      translation: 'стараться',
    },
    {
      id: 5,
      word: 'dozen',
      transcription: 'ˈdʌzn',
      translation: 'десяток',
    },
    {
      id: 6,
      word: 'erase',
      transcription: 'ɪˈreɪz',
      translation: 'стирать',
    },
    {
      id: 7,
      word: 'expand',
      transcription: 'ɪksˈpænd',
      translation: 'расширяться',
    },
    {
      id: 8,
      word: 'extend',
      transcription: 'ɪksˈtɛnd',
      translation: 'тянуться, простираться',
    },
    {
      id: 9,
      word: 'fulfil',
      transcription: 'fʊlˈfɪl',
      translation: 'выполнять',
    },
    {
      id: 10,
      word: 'modesty',
      transcription: 'ˈmɒdɪsti',
      translation: 'скромность',
    },
    {
      id: 11,
      word: 'neck',
      transcription: 'nɛk',
      translation: 'шея',
    },
    {
      id: 12,
      word: 'obtain',
      transcription: 'əbˈteɪn',
      translation: 'получать',
    },
    {
      id: 13,
      word: 'peninsula',
      transcription: 'pɪˈnɪnsjʊlə',
      translation: 'полуостров',
    },
    {
      id: 14,
      word: 'postcard',
      transcription: 'ˈpəʊstkɑːd',
      translation: 'почтовая открытка',
    },
    {
      id: 15,
      word: 'profoundly',
      transcription: 'prəˈfaʊndli',
      translation: 'глубоко',
    },
    {
      id: 16,
      word: 'rapidly',
      transcription: 'ˈræpɪdli',
      translation: 'быстро',
    },
    {
      id: 17,
      word: 'readiness',
      transcription: 'ˈrɛdɪnɪs',
      translation: 'готовность',
    },
    {
      id: 18,
      word: 'relevant',
      transcription: 'ˈrɛlɪvənt',
      translation: 'актуальный, подходящий, соответствующий',
    },
    {
      id: 19,
      word: 'remove',
      transcription: 'rɪˈmuːv',
      translation: 'удалять',
    },
    {
      id: 20,
      word: 'resell',
      transcription: 'ˌriːˈsɛl',
      translation: 'перепродавать',
    },
    {
      id: 21,
      word: 'righteous',
      transcription: 'ˈraɪʧəs',
      translation: 'праведный',
    },
    {
      id: 22,
      word: 'scarf',
      transcription: 'skɑːf',
      translation: 'шарф',
    },
    {
      id: 23,
      word: 'surround',
      transcription: 'səˈraʊnd',
      translation: 'окружать',
    },
    {
      id: 24,
      word: 'tomato',
      transcription: 'təˈmɑːtəʊ',
      translation: 'помидор',
    },
    {
      id: 25,
      word: 'united',
      transcription: 'jʊˈnaɪtɪd',
      translation: 'единый, объединенный',
    },
    {
      id: 26,
      word: 'wheel',
      transcription: 'wiːl',
      translation: 'колесо',
    },
    {
      id: 27,
      word: 'worship',
      transcription: 'ˈwɜːʃɪp',
      translation: 'поклоняться, почитать',
    },
  ],
];
