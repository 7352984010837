export const wordsLesson63 = [
  [
    {
      id: 1,
      word: 'around',
      transcription: 'əˈraʊnd',
      translation: 'вокруг',
    },
    {
      id: 2,
      word: 'develop',
      transcription: 'dɪˈvɛləp',
      translation: 'развивать',
    },
    {
      id: 3,
      word: 'fitness',
      transcription: 'ˈfɪtnɪs',
      translation: 'фитнес',
    },
    {
      id: 4,
      word: 'French',
      transcription: 'frɛnʧ',
      translation: 'французский',
    },
    {
      id: 5,
      word: 'imagination',
      transcription: 'ɪˌmæʤɪˈneɪʃən',
      translation: 'воображение',
    },
    {
      id: 6,
      word: 'income',
      transcription: 'ˈɪnkʌm',
      translation: 'доход',
    },
    {
      id: 7,
      word: 'instructor',
      transcription: 'ɪnˈstrʌktə',
      translation: 'инструктор',
    },
    {
      id: 8,
      word: 'marketing',
      transcription: 'ˈmɑːkɪtɪŋ',
      translation: 'маркетинг',
    },
    {
      id: 9,
      word: 'musician',
      transcription: 'mju(ː)ˈzɪʃən',
      translation: 'музыкант',
    },
    {
      id: 10,
      word: 'on your own',
      transcription: 'ɒn jər əʊn',
      translation: 'сам, самостоятельно',
    },
    {
      id: 11,
      word: 'personal',
      transcription: 'ˈpɜːsnl',
      translation: 'персональный, личный',
    },
    {
      id: 12,
      word: 'president',
      transcription: 'ˈprɛzɪdənt',
      translation: 'президент',
    },
    {
      id: 13,
      word: 'scientist',
      transcription: 'ˈsaɪəntɪst',
      translation: 'ученый',
    },
    {
      id: 14,
      word: 'separately',
      transcription: 'ˈsɛprɪtli',
      translation: 'отдельно',
    },
    {
      id: 15,
      word: 'series',
      transcription: 'ˈsɪəriːz',
      translation: 'сериал',
    },
    {
      id: 16,
      word: 'skill',
      transcription: 'skɪl',
      translation: 'навык',
    },
    {
      id: 17,
      word: 'specialist',
      transcription: 'ˈspɛʃəlɪst',
      translation: 'специалист',
    },
    {
      id: 18,
      word: 'stable',
      transcription: 'ˈsteɪbl',
      translation: 'стабильный',
    },
    {
      id: 19,
      word: 'world',
      transcription: 'wɜːld',
      translation: 'мир',
    },
    {
      id: 20,
      word: 'by yourself',
      transcription: 'baɪ jɔːˈsɛlf',
      translation: 'сам, самостоятельно',
    },
    {
      id: 21,
      word: 'by myself',
      transcription: 'baɪ maɪˈsɛlf',
      translation: 'сам, самостоятельно',
    },
  ],
];
